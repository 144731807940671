import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c(VCard,{staticClass:"py-3 px-3 mx-2 my-2",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c(VDataTable,{attrs:{"headers":_vm.headerChoices,"items":_vm.users.edges,"item-key":"node.id","loading-text":_vm.$t('loading.loading1'),"loading":_vm.$apollo.queries.users.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","loader-height":"2","mobile-breakpoint":0,"no-data-text":_vm.$t('loading.no_data')},on:{"click:row":function (item) { return this$1.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.node.user.lastLogin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.user.lastLogin)))])]}},{key:"item.node.user.isTenantAdmin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-self-center d-flex"},[(item.node.user.isTenantAdmin)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])],1)]}},{key:"item.node.user.enabled2fa",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-self-center d-flex"},[(item.node.user.enabled2fa)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[(_vm.canLoginAsOtherUser)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","outlined":"","small":"","color":"warning","disabled":_vm.isLoggingInAsOtherUser},on:{"click":function($event){return _vm.loginAsOtherUser(item.node.user.id)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mouse")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("permissions.login_user")))])]):_vm._e(),(_vm.canEdit)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editUser(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("partners.edit_user")))])]):_vm._e(),(_vm.canDelete)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","outlined":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteUser(item.node)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("users.delete_user")))])]):_vm._e()],1)]}}])}),_c('EditUserDialog',{ref:"editUserDialog",attrs:{"showUserSelect":true,"isStaffEditing":true,"openEditUserDialog":_vm.openEditUserDialog},on:{"refreshUpdatedUser":_vm.refetch,"closeEditUserDialog":function($event){_vm.openEditUserDialog = false}}}),_c('DeleteUserDialog',{attrs:{"openDeleteUserDialog":_vm.openDeleteUserDialog,"userId":_vm.userToDelete,"isStaffDeleting":true},on:{"refreshDataTable":_vm.refetch,"closeDeleteDialog":function($event){_vm.openDeleteUserDialog = false;
                    _vm.userToDelete = null;}}})],1),_c(VCardActions,{staticClass:"justify-center"},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.users.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }