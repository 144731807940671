<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" flat>
            <v-row align="start" class="d-flex align-center background px-3">
                <!---- SEARCH BAR ---->
                <v-col>
                    <v-row>
                        <v-col>
                            <SearchBar v-model="searchInput" :label="$t('general.search')"> </SearchBar>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" outlined rounded>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mr-1" @click="openAddGroupUserDialog = true">
                    {{ $t("partners.add_user1") }}
                </v-btn>
            </v-card-title>
            <v-card-text>
                <!-- TABLE -->
                <div class="px-10 pt-4" v-if="loading && !hasData">
                    <LoadingBar></LoadingBar>
                </div>
                <div v-else-if="hasData">
                    <v-card
                        v-for="user in groupUsers.edges"
                        :key="user.node.id"
                        class="mb-4 rounded-lg px-3 backgroundContent"
                        outlined
                        flat
                    >
                        <v-card-title>
                            <v-row>
                                <v-col
                                    v-for="item in mainCard(user)"
                                    :key="item.text"
                                    :cols="item.cols.xs"
                                    :xs="item.cols.xs"
                                    :sm="item.cols.sm"
                                    :md="item.cols.md"
                                    :lg="item.cols.lg"
                                    :xl="item.cols.xl"
                                >
                                    <div class="">
                                        <div class="textHeader text-truncate">
                                            {{ item.title }}
                                        </div>
                                        <div class="textBody text-truncate" :style="item.style">
                                            <v-icon
                                                v-if="item.icon"
                                                class="mr-1"
                                                :size="item.iconSize"
                                                :color="item.colorIcon"
                                                >{{ item.icon }}</v-icon
                                            >
                                            <span v-if="item.value || item.value > -1">
                                                {{ item.value }}
                                            </span>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>
                </div>
                <div v-else-if="!loading && !hasData">
                    No Data
                </div>
            </v-card-text>
        </v-card>

        <!---- ADD GROUP USER DIALOG ---->
        <AddGroupUserDialog
            :openAddGroupUserDialog="openAddGroupUserDialog"
            :group="group"
            :groupUsers="groupUsers"
            v-on:refreshGroupUsersTable="refreshDataTable"
            v-on:closeAddGroupUserDialog="openAddGroupUserDialog = false"
        ></AddGroupUserDialog>

        <!---- EDIT GROUP USER DIALOG ---->
        <EditUserDialog
            ref="editUserDialog"
            :openEditUserDialog="openEditUserDialog"
            v-on:closeEditUserDialog="openEditUserDialog = false"
            v-on:refreshDataTable="refreshDataTable"
        ></EditUserDialog>

        <!---- DELETE GROUP USER DIALOG ---->
        <DeleteGroupUserDialog
            :openDeleteDialog="openDeleteGroupUserDialog"
            :group="group"
            :usersID="selectedUsers"
            v-on:refreshDataTable="refreshDataTable"
            v-on:closeDeleteGroupUserDialog="closeAndCleanDeletedUsers"
        >
            <template #title>{{ $t("groups.delete_partner") }}</template>
            <template #content>{{ $t("groups.sure_user") }}</template>
        </DeleteGroupUserDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddGroupUserDialog from "@/components/groups/create/AddGroupUserDialog.vue";
import DeleteGroupUserDialog from "@/components/groups/delete/DeleteGroupUserDialog.vue";
import EditUserDialog from "@/components/users/update/EditUserDialog.vue";
import SearchBar from "@/components/base/SearchBar.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";
import Config from "@/utils/config.json";

export default {
    name: "GroupUsersTab",
    components: {
        AddGroupUserDialog,
        DeleteGroupUserDialog,
        EditUserDialog,
        SearchBar,
        LoadingBar
    },
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    apollo: {
        groupUsers: {
            query: gql`
                query TenantUserGroup($tenantUserGroupId: ID!, $first: Int, $search: String) {
                    tenantUserGroup(id: $tenantUserGroupId) {
                        id
                        name
                        staffs(first: $first, search: $search) {
                            pageInfo {
                                endCursor
                                hasNextPage
                                hasPreviousPage
                                startCursor
                            }
                            edges {
                                node {
                                    id
                                    user {
                                        id
                                        fullName
                                        firstName
                                        lastName
                                        email
                                        isTenantAdmin
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantUserGroupId: this.group.node.id,
                    first: 20,
                    search: this.searchInput
                };
            },
            watchLoading(isLoading) {
                this.loading = isLoading;
                this.$emit("loading", isLoading);
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUserGroup.staffs;
            },
            skip: false
        }
    },
    data() {
        return {
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /*********************************/
            openAddGroupUserDialog: false,
            openDeleteGroupUserDialog: false,
            openEditUserDialog: false,
            /******************************/
            /* ---- USER INPUT DATA ---- */
            /****************************/
            selectedUsers: [],
            searchInput: null,
            groupUsers: { edges: [] },
            loading: false

            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
        };
    },
    watch: {
        searchInput() {
            this.$apollo.groupUsers ? this.$apollo.groupUsers.refetch() : null;
        }
    },
    computed: {
        enableOrDisableRemove() {
            return this.removedUsers?.length > 0 ? false : true;
        },
        hasData() {
            return this.groupUsers?.edges?.length > 0;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "group_users_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("group_users_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("group_users_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        editUser(item) {
            this.openEditUserDialog = true;
            this.$refs.editUserDialog.initializeUser(item);
        },
        removeUser(item) {
            this.openDeleteGroupUserDialog = true;
            this.selectedUsers.push(item.node.id);
        },
        closeAndCleanDeletedUsers() {
            this.openDeleteGroupUserDialog = false;
            this.selectedUsers = [];
        },
        refreshDataTable() {
            this.$apollo.queries.groupUsers.refetch();
        },
        mainCard(item) {
            const { firstName, lastName, email } = item.node.user;
            
            let _main = [
                {
                    title: this.$t("headers.first_name"),
                    tag: "firstName",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: firstName,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        xl: "3"
                    }
                },
                {
                    title: this.$t("headers.last_name"),
                    tag: "lastName",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: lastName,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        xl: "3"
                    }
                },
                {
                    title: "email",
                    tag: "email",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: email,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        xl: "3"
                    }
                }
            ];

            return _main;
        }
    }
};
</script>
