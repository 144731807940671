<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12">
                <div class="d-flex justify-center mb-5">
                    <a href="https://www.coldcha.com/" target="blank">
                        <v-img
                            :src="
                                require('../assets/new_coldcha_logo.png')
                            "
                            max-height="83px"
                            max-width="352px"
                            contain
                        ></v-img>
                    </a>
                </div>
            </v-col>
            <v-col v-if="isVerifyingToken" cols="12">
                <div class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        class="mx-2"
                    ></v-progress-circular>
                </div>
            </v-col>
            <v-col
                v-else
                cols="12"
                sm="8"
                md="6"
                lg="4"
                class="d-flex justify-center"
            >
                <v-card v-if="!isTokenValid" class="elevation-10 pt-3">
                    <v-card-text class="mt-5 pb-0 px-10">
                        {{ $t("login.invalid_link") }}
                        <br />
                        <br />
                        <a href="/#/login">Login</a>
                        <br />
                        <br />
                    </v-card-text>
                </v-card>

                <v-card v-else-if="isPasswordReset" class="elevation-10 pt-3">
                    <v-card-text class="mt-5 pb-0 px-10">
                        {{ $t("login.password_set") }}
                        <br />
                        <br />
                        <a href="/#/login">Login</a>
                        <br />
                        <br />
                    </v-card-text>
                </v-card>

                <v-card v-else class="elevation-10 pt-3" width="520px">
                    <v-card-text class="mt-10 pb-0 px-10">
                        <v-form v-model="valid" ref="resetPasswordForm">
                            <v-text-field
                                id="newPassword"
                                :label="$t('login.new_password')"
                                :type="showNewPassword ? 'text' : 'password'"
                                :rules="[rules.password]"
                                outlined
                                v-model="newPassword"
                                :error-messages="resetPasswordError"
                                hint="*Passwords should contain at least one special character Ex.(_!@#$%^&*) "
                                :persistent-hint="true"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="primary"
                                        @click="
                                            showNewPassword = !showNewPassword
                                        "
                                        tabindex="-1"
                                        >{{
                                            showNewPassword
                                                ? "visibility"
                                                : "visibility_off"
                                        }}</v-icon
                                    >
                                </template>
                            </v-text-field>
                            <v-text-field
                                id="newPasswordConfirm"
                                :label="$t('login.confirm_password')"
                                :type="showNewPassword ? 'text' : 'password'"
                                :rules="[rules.required]"
                                outlined
                                hide-details
                                v-model="newPasswordConfirm"
                                :error-messages="resetPasswordError"
                                
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="primary"
                                        @click="
                                            showNewPassword = !showNewPassword
                                        "
                                        tabindex="-1"
                                        >{{
                                            showNewPassword
                                                ? "visibility"
                                                : "visibility_off"
                                        }}</v-icon
                                    >
                                </template></v-text-field
                            >
                        </v-form>
                    </v-card-text>

                    <v-card-actions class="pb-5">
                        <v-spacer></v-spacer>
                        <v-btn
                            rounded
                            :disabled="
                                newPassword.length == 0 ||
                                    newPassword !== newPasswordConfirm ||
                                    !valid
                            "
                            :loading="isResettingPassword"
                            color="primary"
                            width="400"
                            @click="resetPassword()"
                            >{{ $t('login.password_set')}}</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
export default {
    title: "Set Password",
    data: function() {
        return {
            valid: false,
            isVerifyingToken: true,
            isTokenValid: false,
            isPasswordReset: false,

            isResettingPassword: false,
            newPassword: "",
            newPasswordConfirm: "",
            showNewPassword: "",
            resetPasswordError: "",

            rules: {
                required: v => !helper.isEmpty(v) || this.$t('rules.required'),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t('rules.required'),
                minLength: len => v =>
                    (v || "").length >= len || this.$t('rules.at_least') + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t('rules.too_long'),
                password: value => {
                    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#$%^&*])(?=.{8,})/;
                    return (
                        pattern.test(value) ||
                        this.$t('rules.min')
                    );
                }
                // nameRules: [
                //     v => !!v || "Name is required",
                //     v =>
                //         v.length <= 50 || "Name must be less than 50 characters"
                // ]
            }
        };
    },
    computed: {
        uidb64() {
            return this.$route.params.uidb64;
        },
        token() {
            return this.$route.params.token;
        }
    },
    created() {
        this.verifyResetPasswordToken();
    },
    methods: {
        verifyResetPasswordToken() {
            this.isVerifyingToken = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation verifyResetPasswordToken(
                            $input: VerifyResetPasswordTokenInput!
                        ) {
                            verifyResetPasswordToken(input: $input) {
                                success
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            uidb64: this.uidb64,
                            token: this.token
                        }
                    }
                })
                .then(response => {
                    this.isTokenValid =
                        response.data.verifyResetPasswordToken.success;
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            this.requestPasswordResetError = err.message;
                        }
                    }
                })
                .finally(() => {
                    this.isVerifyingToken = false;
                });
        },

        logout() {
            this.$store
                .dispatch("user/logout")
                .then(() => {
                    this.drawer = false;
                    this.pushTo("login");
                })
                .catch(error => {
                    console.log("Logout failed", error);
                });
        },

        resetPassword() {
            if (!this.$refs.resetPasswordForm.validate()) {
                return;
            }

            this.isResettingPassword = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation resetPassword($input: ResetPasswordInput!) {
                            resetPassword(input: $input) {
                                success
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            uidb64: this.uidb64,
                            token: this.token,
                            password: this.newPassword
                        }
                    }
                })
                .then(response => {
                    this.isPasswordReset = response.data.resetPassword.success;
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        this.requestPasswordResetError =
                            error.graphQLErrors[0].message;
                    }
                })
                .finally(() => {
                    this.isResettingPassword = false;
                });
        }
    }
};
</script>
