<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="background"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                {{ t.name }}
            </v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"        
        >
            <!-- GROUP USERS TAB -->
            <v-tab-item value="tab-users" :transition="false"
                ><GroupUsersTab :group="group"></GroupUsersTab
            ></v-tab-item>
            <!-- GROUP PERMISSIONS TAB -->
            <v-tab-item value="tab-permissions" :transition="false">
                <GroupPermissionsTab :group="group"></GroupPermissionsTab>
            </v-tab-item>
            <!-- GROUP PARTNERS TAB -->
            <v-tab-item value="tab-partners" :transition="false">
                <GroupPartnersTab :group="group"></GroupPartnersTab>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import GroupUsersTab from "@/components/groups/tabs/GroupUsersTab.vue";
import GroupPartnersTab from "@/components/groups/tabs/GroupPartnersTab.vue";
import GroupPermissionsTab from "@/components/groups/tabs/GroupPermissionsTab.vue";
export default {
    props: {
        group: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        GroupUsersTab,
        GroupPartnersTab,
        GroupPermissionsTab
    },
    data() {
        return {
            tab: null
        };
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225 ;
            return  _break;
        }
    },
    mounted() {}
};
</script>