<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4 mb-4" flat>
            <v-row align="start" class="d-flex align-center background px-4">
                <!---- SEARCH BAR ---->
                <v-col cols="12" lg="12" sm="12" class="py-2">
                    <SearchBar
                        v-model="search"
                        :label="this.$t('general.search') + ' ' + this.$t('home.users')"
                    ></SearchBar>
                </v-col>
            </v-row>
        </v-card>
        <v-card flat class="px-4 rounded-lg mb-2" outlined>
            <v-card-title>
                <v-row>
                    <v-col cols="auto">
                        <v-row dense>
                            <v-col>
                                <div>
                                    <v-img :src="require('@/assets/person.png')" width="26"></v-img>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                {{ $t("home.users") }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <slot name="addPartnerUser"></slot>
            </v-card-title>
            <v-card-text>
                <v-card v-if="computedUsers.lenght === 0">
                    No users found
                </v-card>
                <v-card
                    v-for="(user, i) in computedUsers"
                    :key="i"
                    class="mb-4 rounded-lg px-3 backgroundContent"
                    outlined
                    flat
                >
                    <v-card-text>
                        <!-- TABLE -->
                        <v-row>
                            <v-col
                                v-for="item in cardBody(user)"
                                :key="item.tag"
                                :cols="item.cols.xs"
                                :xs="item.cols.xs"
                                :sm="item.cols.sm"
                                :md="item.cols.md"
                                :lg="item.cols.lg"
                                :xl="item.cols.xl"
                            >
                                <div class="">
                                    <div class="textHeader ">
                                        {{ item.title }}
                                    </div>
                                    <div class="textBody " :style="item.style">
                                        <v-icon v-if="item.icon" size="8" class="mr-1" :color="item.colorIcon">{{
                                            item.icon
                                        }}</v-icon>
                                        <span class="text-truncate">
                                            {{ item.value }}
                                        </span>
                                    </div>
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-card flat max-width="20px" class="d-flex justify-start" color="transparent">
                                    <v-menu
                                        offset-y
                                        transition="slide-x-reverse-transition"
                                        width="250px"
                                        attach
                                        left
                                        bottom
                                        close-on-click
                                        nudge-top="-3"
                                    >
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="white">
                                            <v-list-item>
                                                <v-btn plain elevation="0" @click.stop.prevent="editUser(user)">
                                                    <v-icon class="mr-2">edit</v-icon>
                                                    {{ $t("general.edit") }}
                                                </v-btn>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-btn plain elevation="0" @click.stop.prevent="deleteUser(user)">
                                                    <v-icon class="mr-2">delete</v-icon>
                                                    {{ $t("general.delete") }}
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import helper from "@/utils/helper.js";
import SearchBar from "@/components/base/SearchBar.vue";
import Config from "@/utils/config.json";

export default {
    name: "PartnerUsersForm",
    components: { SearchBar },
    props: {
        users: {
            type: Array,
            default: () => []
        },
        searchInput: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            isLoadingSavePermission: false,
            enableOrDisablePermissionBtn: true,
            selectedPermissions: [],
            selectedPermissionsCopy: [],
            search: ""
        };
    },
    watch: {
        search(val) {
            if (val.length > 0) {
                this.$emit("update:searchInput", val);
            } else {
                this.$emit("update:searchInput", null);
            }
        }
    },
    computed: {
        computedUsers() {
            let _users = [];
            if (this.users?.length > 0) {
                _users = this.users.map(item => {
                    return item.node.user;
                });
            }
            return _users;
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
    },
    mounted() {},
    methods: {
        cardBody(item) {
            const { firstName, lastName, email, require2fa, lastLogin } = item;

            let containerStyle = `
                    background-color: ${this.statusColor(require2fa).color1};	
                    border-radius: 4px;
                    width: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            `;

            let _body = [
                {
                    title: this.$t("headers.first_name"),
                    tag: "firstName",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: firstName,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 2,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.last_name"),
                    tag: "lastName",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: lastName,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 2,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    title: "Email",
                    tag: "email",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: email,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 2,
                        lg: 3,
                        xl: 3
                    }
                },
                {
                    title: this.$t("headers.2fa_enabled"),
                    tag: "require2fa",
                    icon: "circle",
                    colorIcon: this.statusColor(require2fa).color2,
                    style: containerStyle,
                    align: "center",
                    value: this.statusText(require2fa),
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 2,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.last_login"),
                    tag: "lastLogin",
                    icon: null,
                    colorIcon: "",
                    style: "",
                    align: "center",
                    value: lastLogin ? this.formatDateTime(lastLogin) : "",
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 2,
                        lg: 2,
                        xl: 2
                    }
                }
            ];

            return _body;
        },
        statusColor(status) {
            if (status) {
                return {
                    color1: "#C8E6C9",
                    color2: "#1B5E20"
                };
            } else {
                return {
                    color1: "#FFCDD2",
                    color2: "#F44336"
                };
            }
        },
        statusText(status) {
            if (status) {
                return this.$t("headers.enabled");
            } else {
                return this.$t("headers.disabled");
            }
        },
        editUser(editUser) {
            let _user = this.users.filter(item => item.node.user.id === editUser.id);
            this.$emit("update-user", _user[0]);
        },
        deleteUser(editUser) {
            let _user = this.users.filter(item => item.node.user.id === editUser.id);
            this.$emit("delete-user", _user[0]);
        }
    }
};
</script>
<style scoped>
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
.outline {
    border: 1px solid #ebebf5;
    box-sizing: border-box;
    border-radius: 4px;
}
.backgroundContent {
    background: #f9fafe;
}
</style>
