import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('left')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_back")])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('right')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_forward")])],1)],1),_c('FilterMenu',{attrs:{"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display'),"choices":_vm.headerChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1),_c(VDataTable,{staticClass:"row-pointer",attrs:{"headers":_vm.dynamicHeaders,"items":_vm.bookingOrderTemplatesQx ? _vm.bookingOrderTemplatesQx.edges : [],"item-key":"node.id","loading-text":_vm.$t('loading.loading1'),"loading":_vm.$apollo.queries.bookingOrderTemplatesQx.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","loader-height":"2","show-select":_vm.selectMode,"mobile-breakpoint":_vm.screenSize === 'xs' || _vm.screenSize === 'sm' ? 800 : 0,"no-data-text":_vm.$t('loading.no_data'),"fixed-header":"","height":_vm.tableHeight},on:{"click:row":function (item) { return this$1.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson(item.node.bookingJson, "customer") !== "None" ? _vm.triggerJson(item.node.bookingJson, "customer") : "")+" ")])]}},{key:"item.exporter",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson(item.node.bookingJson, "exporter") !== "None" ? _vm.triggerJson(item.node.bookingJson, "exporter") : "")+" ")])]}},{key:"item.receiver",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson(item.node.bookingJson, "receiver") !== "None" ? _vm.triggerJson(item.node.bookingJson, "receiver") : "")+" ")])]}},{key:"item.vesselName",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson(item.node.bookingJson, "vesselName") !== "None" ? _vm.triggerJson( item.node.bookingJson, "vesselName" ) : "")+" ")])]}},{key:"item.commodity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson(item.node.bookingJson, "commodity") !== "None" ? _vm.triggerJson( item.node.bookingJson, "commodity" ) : "")+" ")])]}},{key:"item.shippingLine",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson( item.node.bookingJson, "shippingLine" ) !== "None" ? _vm.triggerJson( item.node.bookingJson, "shippingLine" ) : "")+" ")])]}},{key:"item.containerType",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson( item.node.bookingJson, "containerType" ) !== "None" ? _vm.triggerJson( item.node.bookingJson, "containerType" ) : "")+" ")])]}},{key:"item.steri",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson(item.node.bookingJson, "steri") !== "None" ? _vm.triggerJson(item.node.bookingJson, "steri") : "")+" ")])]}},{key:"item.incoTerm",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson(item.node.bookingJson, "incoTerm") !== "None" ? _vm.triggerJson(item.node.bookingJson, "incoTerm") : "")+" ")])]}},{key:"item.transportType",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson( item.node.bookingJson, "transportType" ) !== "None" ? _vm.triggerJson( item.node.bookingJson, "transportType" ) : "")+" ")])]}},{key:"item.pol",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson(item.node.bookingJson, "pol") !== "None" ? _vm.triggerJson(item.node.bookingJson, "pol") : "")+" ")])]}},{key:"item.pod",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson(item.node.bookingJson, "pod") !== "None" ? _vm.triggerJson(item.node.bookingJson, "pod") : "")+" ")])]}},{key:"item.weighBridge",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.triggerJson( item.node.bookingJson, "weighBridge" ) !== "None" ? _vm.triggerJson( item.node.bookingJson, "weighBridge" ) : "")+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.dynamicHeaders.length}},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.bookingOrderTemplatesQx
                                    .loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }