import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VTimeline,{staticClass:"ml-n7",attrs:{"dense":""}},[_c(VForm,{ref:"seaForm"},_vm._l((_vm.tripSeaTransport),function(item,i){return _c('div',{key:i},[(item.sequence === 0)?_c(VTimelineItem,{attrs:{"small":""}},[(_vm.disabled)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mb-1",attrs:{"flat":""}},[_c(VRow,{staticClass:"px-4",attrs:{"dense":""}},[_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("trips.port_type"))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.portTransportType)+" ")])]),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("trips.port_code"))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.portCode ? item.portCode : "-")+" ")])]),(
                                            item.portTransportType ===
                                                'POL' &&
                                                item.actualDepartureDate
                                        )?_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" ATD "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.iconSource( item.actualDepartureDateSource ))+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.convertUnderscore( item.actualDepartureDateSource ))+" ")])])],1),(item.actualDepartureDate)?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm._f("moment")(item.actualDepartureDate, "YYYY-MM-DD" ))+" ")]),_c(VCol,[_c('div',{class:_vm.getDelay(item,'etd').color},[_c('span',[_vm._v(_vm._s(_vm.getDelay(item, 'etd').text))])])])],1)],1)]}}],null,true)},[_c('span',[_vm._v("ETD:"+_vm._s(_vm._f("moment")(item.estimatedDepartureDate,"YYYY-MM-DD")))])])],1):_vm._e()]):_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" ETD "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.iconSource( item.estimatedDepartureDateSource ))+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.convertUnderscore( item.estimatedDepartureDateSource )))])])],1),(
                                                item.estimatedDepartureDate &&
                                                    item.originalEstimatedDepartureDate
                                            )?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm._f("moment")(item.estimatedDepartureDate, "YYYY-MM-DD" ))+" ")]),_c(VCol,[_c('div',{class:_vm.getDelay(item,'etd').color},[_c('span',[_vm._v(_vm._s(_vm.getDelay(item, 'etd').text))])])])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Original ETD: "+_vm._s(_vm._f("moment")(item.originalEstimatedDepartureDate, "YYYY-MM-DD" )))])])],1):(
                                                item.estimatedDepartureDate &&
                                                    (item.originalEstimatedDepartureDate ===
                                                        null ||
                                                        item.originalEstimatedDepartureDate ===
                                                            undefined)
                                            )?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.estimatedDepartureDate,"YYYY-MM-DD"))+" ")]):(
                                                item.originalEstimatedDepartureDate &&
                                                    (item.estimatedDepartureDate ===
                                                        null ||
                                                        item.estimatedDepartureDate ===
                                                            undefined)
                                            )?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.originalEstimatedDepartureDate,"YYYY-MM-DD"))+" ")]):_c('div',[_vm._v(" - ")])]),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.vesselInputType ? _vm.getInputTypeText( item.vesselInputType ) : _vm.$t( "trips.vessel_type" ))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.vesselInputValue ? item.vesselInputValue : "-")+" ")])]),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("trips.voyage"))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.voyage ? item.voyage : "-")+" ")])])],1)],1)],1)],1):_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VTextField,{attrs:{"dense":"","label":_vm.$t('trips.port_type'),"outlined":"","filled":"","readonly":"","hide-details":""},model:{value:(item.portTransportType),callback:function ($$v) {_vm.$set(item, "portTransportType", $$v)},expression:"item.portTransportType"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('DateComponent',{attrs:{"label":_vm.$t('trips.original_etd'),"rules":[],"outlined":true,"dense":true},model:{value:(
                                            item.originalEstimatedDepartureDate
                                        ),callback:function ($$v) {_vm.$set(item, "originalEstimatedDepartureDate", $$v)},expression:"\n                                            item.originalEstimatedDepartureDate\n                                        "}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('DateComponent',{attrs:{"label":"ETD","rules":[],"outlined":true,"dense":true},model:{value:(
                                            item.estimatedDepartureDate
                                        ),callback:function ($$v) {_vm.$set(item, "estimatedDepartureDate", $$v)},expression:"\n                                            item.estimatedDepartureDate\n                                        "}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('DateComponent',{attrs:{"label":"ATD","rules":[],"outlined":true,"dense":true},model:{value:(item.actualDepartureDate),callback:function ($$v) {_vm.$set(item, "actualDepartureDate", $$v)},expression:"item.actualDepartureDate"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VCombobox,{attrs:{"error":item.portCode === '' ||
                                                item.portCode === null,"items":_vm.seaPorts.edges,"no-data-text":_vm.$t('loading.no_available'),"item-value":function (item) { return item.node.code; },"item-text":"node.code","loading":_vm.$apollo.queries.seaPorts.loading,"search-input":item.portCode,"return-object":false,"label":_vm.$t('trips.port_code'),"clearable":"","outlined":"","dense":"","error-messages":item.portCode === '' ||
                                            item.portCode === null
                                                ? ['Port Code is missing']
                                                : []},on:{"update:searchInput":function($event){return _vm.$set(item, "portCode", $event)},"update:search-input":function($event){return _vm.$set(item, "portCode", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                                var item = ref.item;
return [_c(VRow,{staticClass:"ma-0 body-2"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.node.code)+" ")]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"8"}},[_c('span',{staticClass:"grey--text body-2 mr-1"},[_c('i',[_vm._v(_vm._s(item.node.name))])])])],1)]}}],null,true),model:{value:(item.portCode),callback:function ($$v) {_vm.$set(item, "portCode", $$v)},expression:"item.portCode"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VSelect,{attrs:{"dense":"","error-messages":item.vesselInputType === '' ||
                                            item.vesselInputType === null
                                                ? _vm.$t(
                                                      'rules.vessel_type_missing'
                                                  )
                                                : '',"items":_vm.type,"label":_vm.$t('trips.vessel_type'),"outlined":""},on:{"change":function($event){return _vm.updateVesselInputValueChoices(
                                                item,
                                                i
                                            )}},model:{value:(item.vesselInputType),callback:function ($$v) {_vm.$set(item, "vesselInputType", $$v)},expression:"item.vesselInputType"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VCombobox,{attrs:{"label":item.vesselInputType
                                                ? "" + (_vm.getInputTypeText(
                                                      item.vesselInputType
                                                  )) + ' '
                                                : _vm.$t('trips.vessel_no'),"items":_vm.vesselInputValueChoices.length >
                                            i
                                                ? _vm.vesselInputValueChoices[i]
                                                : [],"no-data-text":_vm.$t('loading.no_available'),"loading":_vm.$apollo.queries.seaPorts.loading,"disabled":!(
                                                item.vesselInputType !==
                                                    null &&
                                                item.vesselInputType
                                                    .length > 0
                                            ),"error-messages":item.vesselInputValue === '' ||
                                            item.vesselInputValue === null
                                                ? 'Vessel input is missing'
                                                : '',"clearable":"","outlined":"","dense":""},model:{value:(item.vesselInputValue),callback:function ($$v) {_vm.$set(item, "vesselInputValue", $$v)},expression:"item.vesselInputValue"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VTextField,{attrs:{"error-messages":item.voyage
                                                ? ''
                                                : 'Voyage is missing',"dense":"","label":_vm.$t('trips.voyage'),"outlined":""},model:{value:(item.voyage),callback:function ($$v) {_vm.$set(item, "voyage", $$v)},expression:"item.voyage"}})],1)],1)],1)],1)],1):_vm._e(),(
                        item.sequence > 0 &&
                            item.sequence < _vm.tripSeaTransport.length - 1
                    )?_c(VTimelineItem,{class:!_vm.disabled ? 'pb-0' : '',attrs:{"color":_vm.disabled ? 'primary' : 'white',"small":_vm.disabled},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.disabled)?_c(VBtn,{attrs:{"icon":"","small":"","rounded":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.remove(i)}}},[_c(VIcon,[_vm._v(" delete ")])],1):_vm._e()]},proxy:true}],null,true)},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.disabled)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VRow,{staticClass:"px-4",attrs:{"dense":""}},[_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t( "trips.port_type" ))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.portTransportType)+" ")])]),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t( "trips.port_code" ))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.portCode ? item.portCode : "-")+" ")])]),(
                                                    item.portTransportType ===
                                                        'T/S' &&
                                                        item.actualDepartureDate
                                                )?_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" ATD "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',
                                                                    attrs
                                                                ,false),on),[_vm._v(" "+_vm._s(_vm.iconSource( item.actualDepartureDateSource ))+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.convertUnderscore( item.actualDepartureDateSource )))])])],1),(
                                                        item.actualDepartureDate
                                                    )?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm._f("moment")(item.actualDepartureDate, "YYYY-MM-DD" ))+" ")]),(item.originalEstimatedDepartureDate || item.estimatedDepartureDate)?_c(VCol,[_c('div',{class:_vm.getDelay(item,'etd').color},[_c('span',[_vm._v(_vm._s(_vm.getDelay(item, 'etd').text))])])]):_vm._e()],1)],1)]}}],null,true)},[_c('span',[_vm._v("Original ETD: "+_vm._s(_vm._f("moment")(item.originalEstimatedDepartureDate, "YYYY-MM-DD" )))])])],1):_vm._e()]):_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" ETD "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',
                                                                    attrs
                                                                ,false),on),[_vm._v(" "+_vm._s(_vm.iconSource( item.estimatedDepartureDateSource ))+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.convertUnderscore( item.estimatedDepartureDateSource )))])])],1),(
                                                        item.estimatedDepartureDate &&
                                                            item.originalEstimatedDepartureDate
                                                    )?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',
                                                                    attrs
                                                                ,false),on),[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm._f("moment")(item.estimatedDepartureDate, "YYYY-MM-DD" ))+" ")]),_c(VCol,[_c('div',{class:_vm.getDelay(item,'etd').color},[_c('span',[_vm._v(_vm._s(_vm.getDelay(item, 'etd').text))])])])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Original ETD: "+_vm._s(_vm._f("moment")(item.originalEstimatedDepartureDate, "YYYY-MM-DD" )))])])],1):(
                                                        item.estimatedDepartureDate &&
                                                            (item.originalEstimatedDepartureDate ===
                                                                null ||
                                                                item.originalEstimatedDepartureDate ===
                                                                    undefined)
                                                    )?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.estimatedDepartureDate, "YYYY-MM-DD" ))+" ")]):(
                                                        item.originalEstimatedDepartureDate &&
                                                            (item.estimatedDepartureDate ===
                                                                null ||
                                                                item.estimatedDepartureDate ===
                                                                    undefined)
                                                    )?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.originalEstimatedDepartureDate, "YYYY-MM-DD" ))+" ")]):_c('div',[_vm._v(" - ")])]),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.vesselInputType ? _vm.getInputTypeText( item.vesselInputType ) : "Vessel Type")+" ")]),_c('div',[_vm._v(" "+_vm._s(item.vesselInputValue ? item.vesselInputValue : "-")+" ")])]),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("trips.voyage"))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.voyage ? item.voyage : "-")+" ")])])],1)],1)],1)],1):_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VTextField,{attrs:{"dense":"","label":_vm.$t('trips.port_type'),"outlined":"","filled":"","readonly":"","hide-details":""},model:{value:(
                                                    item.portTransportType
                                                ),callback:function ($$v) {_vm.$set(item, "portTransportType", $$v)},expression:"\n                                                    item.portTransportType\n                                                "}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('DateComponent',{attrs:{"label":_vm.$t('trips.original_etd'),"rules":[],"outlined":true,"dense":true},model:{value:(
                                                    item.originalEstimatedDepartureDate
                                                ),callback:function ($$v) {_vm.$set(item, "originalEstimatedDepartureDate", $$v)},expression:"\n                                                    item.originalEstimatedDepartureDate\n                                                "}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('DateComponent',{attrs:{"label":"ETD","rules":[],"outlined":true,"dense":true},model:{value:(
                                                    item.estimatedDepartureDate
                                                ),callback:function ($$v) {_vm.$set(item, "estimatedDepartureDate", $$v)},expression:"\n                                                    item.estimatedDepartureDate\n                                                "}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('DateComponent',{attrs:{"label":"ATD","rules":[],"outlined":true,"dense":true},model:{value:(
                                                    item.actualDepartureDate
                                                ),callback:function ($$v) {_vm.$set(item, "actualDepartureDate", $$v)},expression:"\n                                                    item.actualDepartureDate\n                                                "}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VCombobox,{attrs:{"items":_vm.seaPorts.edges,"no-data-text":_vm.$t(
                                                        'loading.no_available'
                                                    ),"error-messages":item.portCode === null
                                                        ? 'Port Code is missing'
                                                        : '',"item-value":function (item) { return item.node.code; },"item-text":function (item) { return item.node.code; },"loading":_vm.$apollo.queries.seaPorts
                                                        .loading,"label":_vm.$t('trips.port_code'),"search-input":item.portCode,"return-object":false,"clearable":"","outlined":"","dense":""},on:{"update:searchInput":function($event){return _vm.$set(item, "portCode", $event)},"update:search-input":function($event){return _vm.$set(item, "portCode", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                                        var item = ref.item;
return [_c(VRow,{staticClass:"ma-0 body-2"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.node .code)+" ")]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"8"}},[_c('span',{staticClass:"grey--text body-2 mr-1"},[_c('i',[_vm._v(_vm._s(item .node .name))])])])],1)]}}],null,true),model:{value:(item.portCode),callback:function ($$v) {_vm.$set(item, "portCode", $$v)},expression:"item.portCode"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VSelect,{attrs:{"dense":"","error-messages":item.vesselInputType
                                                        ? ''
                                                        : _vm.$t(
                                                              'rules.vessel_type_missing'
                                                          ),"items":_vm.type,"label":_vm.$t('trips.vessel_type'),"outlined":"","persistent-hint":""},on:{"change":function($event){return _vm.updateVesselInputValueChoices(
                                                        item,
                                                        i
                                                    )}},model:{value:(
                                                    item.vesselInputType
                                                ),callback:function ($$v) {_vm.$set(item, "vesselInputType", $$v)},expression:"\n                                                    item.vesselInputType\n                                                "}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VCombobox,{attrs:{"label":item.vesselInputType
                                                        ? "" + (_vm.getInputTypeText(
                                                              item.vesselInputType
                                                          )) + ' '
                                                        : _vm.$t(
                                                              'trips.vessel_no'
                                                          ),"items":_vm.vesselInputValueChoices.length >
                                                    i
                                                        ? _vm.vesselInputValueChoices[
                                                              i
                                                          ]
                                                        : [],"no-data-text":_vm.$t(
                                                        'loading.no_available'
                                                    ),"loading":_vm.$apollo.queries.seaPorts
                                                        .loading,"disabled":!(
                                                        item.vesselInputType !==
                                                            null &&
                                                        item.vesselInputType
                                                            .length > 0
                                                    ),"error-messages":item.vesselInputValue
                                                        ? ''
                                                        : 'Vessel input is missing',"clearable":"","outlined":"","dense":""},model:{value:(
                                                    item.vesselInputValue
                                                ),callback:function ($$v) {_vm.$set(item, "vesselInputValue", $$v)},expression:"\n                                                    item.vesselInputValue\n                                                "}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VTextField,{attrs:{"error-messages":item.voyage
                                                        ? ''
                                                        : 'Voyage is missing',"dense":"","label":_vm.$t('trips.voyage'),"outlined":""},model:{value:(item.voyage),callback:function ($$v) {_vm.$set(item, "voyage", $$v)},expression:"item.voyage"}})],1)],1)],1)],1)],1)],1)],1):_vm._e(),(item.portTransportType === 'POD' && !_vm.disabled)?_c(VTimelineItem,{staticClass:"pb-6",attrs:{"small":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VBtn,{staticClass:"primary",attrs:{"icon":"","small":"","rounded":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.add()}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("add")])],1)]},proxy:true}],null,true)}):_vm._e(),(item.portTransportType === 'POD')?_c(VTimelineItem,{attrs:{"small":""}},[(_vm.disabled)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VRow,{staticClass:"px-4",attrs:{"dense":""}},[_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("trips.port_type"))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.portTransportType)+" ")])]),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("trips.port_code"))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.portCode ? item.portCode : "-")+" ")])]),(
                                            item.portTransportType ===
                                                'POD' &&
                                                item.actualArrivalDate
                                        )?_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" ATA "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.iconSource( item.actualArrivalDateSource ))+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.convertUnderscore( item.actualArrivalDateSource )))])])],1),(item.actualArrivalDate)?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm._f("moment")(item.estimatedArrivalDate, "YYYY-MM-DD" ))+" ")]),_c(VCol,[_c('div',{class:_vm.getDelay(item,'eta').color},[_c('span',[_vm._v(_vm._s(_vm.getDelay(item, 'eta').text))])])])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Original ETA: "+_vm._s(_vm._f("moment")(item.originalEstimatedArrivalDate, "YYYY-MM-DD" )))])])],1):_vm._e()]):_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" ETA "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.iconSource( item.estimatedArrivalDateSource ))+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.convertUnderscore( item.estimatedArrivalDateSource )))])])],1),(
                                                item.originalEstimatedArrivalDate &&
                                                    item.estimatedArrivalDate
                                            )?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm._f("moment")(item.estimatedArrivalDate, "YYYY-MM-DD" ))+" ")]),_c(VCol,[_c('div',{class:_vm.getDelay(item,'eta').color},[_c('span',[_vm._v(_vm._s(_vm.getDelay(item, 'eta').text))])])])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Original ETA: "+_vm._s(_vm._f("moment")(item.originalEstimatedArrivalDate, "YYYY-MM-DD" )))])])],1):(
                                                item.estimatedArrivalDate &&
                                                    (item.originalEstimatedArrivalDate ===
                                                        null ||
                                                        item.originalEstimatedArrivalDate ===
                                                            undefined)
                                            )?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.estimatedArrivalDate,"YYYY-MM-DD"))+" ")]):(
                                                item.originalEstimatedArrivalDate &&
                                                    (item.estimatedArrivalDate ===
                                                        null ||
                                                        item.estimatedArrivalDate ===
                                                            undefined)
                                            )?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.originalEstimatedArrivalDate,"YYYY-MM-DD"))+" ")]):_c('div',[_vm._v(" - ")])]),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.vesselInputType ? _vm.getInputTypeText( item.vesselInputType ) : _vm.$t( "trips.vessel_type" ))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.vesselInputValue ? item.vesselInputValue : "-")+" ")])]),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("trips.voyage"))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.voyage ? item.voyage : "-")+" ")])])],1)],1)],1)],1):_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VTextField,{attrs:{"dense":"","label":_vm.$t('trips.port_type'),"outlined":"","filled":"","readonly":"","hide-details":""},model:{value:(item.portTransportType),callback:function ($$v) {_vm.$set(item, "portTransportType", $$v)},expression:"item.portTransportType"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('DateComponent',{attrs:{"label":"Original ETA*","rules":[],"outlined":true,"dense":true},model:{value:(
                                            item.originalEstimatedArrivalDate
                                        ),callback:function ($$v) {_vm.$set(item, "originalEstimatedArrivalDate", $$v)},expression:"\n                                            item.originalEstimatedArrivalDate\n                                        "}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('DateComponent',{attrs:{"label":"ETA","rules":[],"outlined":true,"dense":true},model:{value:(item.estimatedArrivalDate),callback:function ($$v) {_vm.$set(item, "estimatedArrivalDate", $$v)},expression:"item.estimatedArrivalDate"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('DateComponent',{attrs:{"label":"ATA","rules":[],"outlined":true,"dense":true},model:{value:(item.actualArrivalDate),callback:function ($$v) {_vm.$set(item, "actualArrivalDate", $$v)},expression:"item.actualArrivalDate"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VCombobox,{attrs:{"error-messages":item.portCode === null ||
                                            item.portCode === undefined
                                                ? 'Port Code is missing'
                                                : '',"items":_vm.seaPorts.edges,"no-data-text":_vm.$t('loading.no_available'),"item-value":function (item) { return item.node.code; },"item-text":function (item) { return item.node.code; },"loading":_vm.$apollo.queries.seaPorts.loading,"label":_vm.$t('trips.port_code'),"search-input":item.portCode,"return-object":false,"clearable":"","outlined":"","dense":""},on:{"update:searchInput":function($event){return _vm.$set(item, "portCode", $event)},"update:search-input":function($event){return _vm.$set(item, "portCode", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                                var item = ref.item;
return [_c(VRow,{staticClass:"ma-0 body-2"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.node.code)+" ")]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"8"}},[_c('span',{staticClass:"grey--text body-2 mr-1"},[_c('i',[_vm._v(_vm._s(item.node.name))])])])],1)]}}],null,true),model:{value:(item.portCode),callback:function ($$v) {_vm.$set(item, "portCode", $$v)},expression:"item.portCode"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VSelect,{attrs:{"dense":"","items":_vm.type,"label":_vm.$t('trips.vessel_input'),"outlined":"","disabled":"","filled":""},on:{"change":function($event){return _vm.updateVesselInputValueChoices(
                                                item,
                                                i
                                            )}},model:{value:(item.vesselInputType),callback:function ($$v) {_vm.$set(item, "vesselInputType", $$v)},expression:"item.vesselInputType"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VCombobox,{attrs:{"label":item.vesselInputType
                                                ? "" + (_vm.getInputTypeText(
                                                      item.vesselInputType
                                                  )) + ' '
                                                : _vm.$t('trips.vessel_no'),"items":_vm.vesselInputValueChoices.length >
                                            i
                                                ? _vm.vesselInputValueChoices[i]
                                                : [],"no-data-text":_vm.$t('loading.no_available'),"loading":_vm.$apollo.queries.seaPorts.loading,"clearable":"","outlined":"","disabled":"","filled":"","dense":"","hide-details":""},model:{value:(item.vesselInputValue),callback:function ($$v) {_vm.$set(item, "vesselInputValue", $$v)},expression:"item.vesselInputValue"}})],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VTextField,{attrs:{"dense":"","disabled":"","label":_vm.$t('trips.voyage'),"outlined":"","hide-details":""},model:{value:(item.voyage),callback:function ($$v) {_vm.$set(item, "voyage", $$v)},expression:"item.voyage"}})],1)],1)],1)],1)],1):_vm._e()],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }