<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="background"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                {{ t.name }}
            </v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <!-- USER GROUP TAB -->
            <v-tab-item value="tab-groups" :transition="false"
                ><StaffUserGroups :user="user"></StaffUserGroups
            ></v-tab-item>
            <!-- USER LOGIN HISTORY TAB -->
            <v-tab-item value="tab-loginHistory" :transition="false">
                <UserLoginHistoryTab :user="user" :isStaff="true"></UserLoginHistoryTab>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import StaffUserGroups from "@/components/staff/users/details/StaffUserGroups.vue";
import UserLoginHistoryTab from "@/components/users/tabs/UserLoginHistoryTab.vue";
export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        StaffUserGroups,
        UserLoginHistoryTab
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225 ;
            return  _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    mounted() {}
};
</script>
