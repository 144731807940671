<template>
    <v-dialog max-width="600" v-model="openDeleteGroupDialog" persistent>
        <v-card class="pb-4">
            <v-card-title class="primary">
                <v-icon color="white" class="mr-2">warning</v-icon>
                <div>
                    <span class="white--text">
                        {{ $t("groups.delete_group") }}
                    </span>
                </div>
            </v-card-title>
            <v-card-text>
                <div class="mt-3">
                    <h4>
                        {{ $t("groups.sure_group") }}
                    </h4>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="$emit('closeDeleteDialog')">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isDeleting"
                    @click="deleteGroup"
                    >{{ $t("general.delete") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";

export default {
    name: "DeleteGroup",
    props: ["openDeleteGroupDialog", "groupId", "isStaffGroup"],
    data: function() {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteGroup() {
            this.isDeleting = true;
            if (this.isStaffGroup) {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation DeleteStaffGroup(
                                $input: DeleteStaffGroupInput!
                            ) {
                                deleteStaffGroup(input: $input) {
                                    success
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ids: [this.groupId]
                            }
                        }
                    })
                    .then(() => {
                        this.$emit("refreshDataTable");
                        this.$emit("closeDeleteDialog");
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.isDeleting = false;
                    });
            } else {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation DeleteTenantUserGroup(
                                $input: DeleteTenantUserGroupInput!
                            ) {
                                deleteTenantUserGroup(input: $input) {
                                    tenantStaffGroup {
                                        id
                                        name
                                        staffs {
                                            totalCount
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                id: this.groupId
                            }
                        }
                    })
                    .then(() => {
                        this.$emit("refreshDataTable");
                        this.$emit("closeDeleteDialog");
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.isDeleting = false;
                    });
            }
        }
    }
};
</script>
