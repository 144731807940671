<template>
    <v-dialog v-model="openDeleteDialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="primary">
                <v-icon color="white" class="mr-2">warning</v-icon>
                <div>
                    <span class="white--text">
                        {{ $t("partners.delete_location") }}
                    </span>
                </div>
            </v-card-title>
            <v-card-text>
                <div class="mt-3 font-weight-medium">
                    <!-- <h3> -->
                        {{ $t("partners.sure_location") }}
                    <!-- </h3> -->
                </div>
                <div class="mt-3 font-weight-bold">
                    <!-- <h4> -->
                        **{{ $t("general.delete_location_warning") }}**
                    <!-- </h4> -->
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="$emit('closeDeleteDialog')">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isDeleting"
                    @click="deleteLocationMutation"
                    >{{ $t("general.delete") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "delete-dialog",
    props: ["openDeleteDialog", "locationID"],
    data: function() {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteLocationMutation() {
            this.isDeleting = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteLocation(
                            $input: DeleteTenantLocationInput!
                        ) {
                            deleteTenantLocation(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantLocationsIds: this.locationID
                        }
                    }
                })
                .then(() => {
                    this.$emit("refreshDataTable");
                    this.$emit("closeDeleteDialog");
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isDeleting = false;
                    this.$emit("locationDeleted");
                });
        }
    }
};
</script>
