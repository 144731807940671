import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"py-3 px-3 mx-2 my-2",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c(VDataTable,{staticClass:"row-pointer",attrs:{"headers":_vm.headerChoices,"items":_vm.tableItems,"item-key":"node.id","loading-text":_vm.$t('loading.loading1'),"loading":_vm.tableLoading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","loader-height":"2","mobile-breakpoint":_vm.screenSize === 'xs' || _vm.screenSize === 'sm' ? 800 : 0,"no-data-text":_vm.$t('loading.no_data'),"fixed-header":"","height":_vm.tableHeight},on:{"click:row":function (item) { return this$1.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.node.locationType",fn:function(ref){
var item = ref.item;
return [(item.node.locationType === 'TENANT_LOCATION')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("alert_rules.public"))+" ")]):(
                            item.node.locationType === 'PRIVATE_LOCATION'
                        )?_c('span',{attrs:{"color":"grey lighten-2"}},[_vm._v(" "+_vm._s(_vm.$t("alert_rules.nonpublic")))]):(
                            item.node.locationType.trim() === 'AIR_PORT'
                        )?_c('span',[_vm._v(_vm._s(_vm.$t("headers.airport")))]):(
                            item.node.locationType.trim() === 'SEA_PORT'
                        )?_c('span',[_vm._v(_vm._s(_vm.$t("headers.sea_port")))]):_vm._e()]}},{key:"item.node.tenantPartner.partnerType",fn:function(ref){
                        var item = ref.item;
return [(item.node.tenantPartner)?_c('div',[(item.node.tenantPartner.partnerType === 'consignor')?_c('span',[_vm._v(_vm._s(_vm.$t("headers.consignor")))]):(item.node.tenantPartner.partnerType === 'consignee')?_c('span',[_vm._v(_vm._s(_vm.$t("headers.consignee")))]):(item.node.tenantPartner.partnerType === 'carrier')?_c('span',[_vm._v(_vm._s(_vm.$t("headers.carrier")))]):(item.node.tenantPartner.partnerType === 'forwarder')?_c('span',[_vm._v(_vm._s(_vm.$t("headers.forwarder")))]):(item.node.tenantPartner.partnerType === 'other')?_c('span',[_vm._v(_vm._s(_vm.$t("home.other")))]):_vm._e()]):_vm._e()]}},{key:"item.node.tags",fn:function(ref){
                        var item = ref.item;
return [_c('div',_vm._l((_vm.getTags(item.node.tags)),function(tag){return _c(VChip,{key:tag,staticClass:"mr-1 my-1",attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(tag))])}),1)]}},{key:"item.node.isTrackerStockLocation",fn:function(ref){
                        var item = ref.item;
return [(item.node.isTrackerStockLocation === true)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.headerChoices.length}},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.tableLoading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }