<template>
    <v-container fluid class="pa-0">
        <!-- tutorial -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>

        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col cols="5" sm="5" md="5" lg="5">
                    <h2
                        class="ml-3 display-3 font-weight-medium d-flex align-top"
                    >
                        {{ $t('home.freights') }}
                    </h2>
                </v-col>
                <!-- <v-col cols="7" sm="8" md="9" lg="10">
                      <TripHeaderKPIs></TripHeaderKPIs>
                  </v-col> -->
            </v-row>
        </div>

        <!-- filter -->
        <FreightsFilter
            class="mx-3"
            :filterCode="filterCode"
            v-on:filter-changed="onFilterChanged"
        ></FreightsFilter>

        <!-- list -->
        <FreightsTable
            ref="tripTable"
            class="mx-3 mt-3 mb-3"
            :freightFilter="freightFilter"
            v-on:click:row="onFreightSelected"
        ></FreightsTable>

        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="freightDetailDrawer"
            :width="freightDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            disable-route-watcher
            temporary
        >
            <div>
                <FreightDetail
                    v-if="selectedFreight || idFromRouter"
                    :key="selectedFreight ? selectedFreight.id : idFromRouter"
                    :freightProp.sync="selectedFreight"
                    :freightId="idFromRouter"
                    :isFullScreen="isFreightDetailDrawerFullScreen"
                    v-on:changeFullScreen="
                        onFreightDetailDrawerFullScreenChange
                    "
                    v-on:close="freightDetailDrawer = false"
                ></FreightDetail>
            </div>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import FreightsFilter from "@/components/freights/FreightsFilter.vue";
import FreightsTable from "@/components/freights/FreightsTable.vue";
import FreightDetail from "@/components/freights/FreightDetail.vue";
import { eventBus } from "@/main.js";

export default {
    title: "Freights",
    components: {
        TutorialDialog,
        FreightsFilter,
        FreightsTable,
        FreightDetail
    },

    apollo: {},

    data() {
        return {
            filterCode: "",
            freightDetailDrawer: false,
            goTopShow: false,
            isLoading: false,
            menu: false,
            idFromRouter: null,
            isRouting: false,

            freightFilter: {
                first: 10
            },

            selectedFreight: null,
            freightDetailFullScreen: false,

            tutorialDialog: false
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        freightDetailDrawerWidth() {
            if (this.freightDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isFreightDetailDrawerFullScreen() {
            return this.freightDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },
    watch: {
        freightDetailDrawer(val) {
            if (!val) {
                this.resetRoute();
            }
        },
        $route(to) {
            if (to.fullPath === "/freights") {
                return;
            }
            if (to.query.id) {
                this.idFromRouter = to.query.id;
                this.freightDetailDrawer = true;
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.checkIfTripIsShared = helper.checkIfTripIsShared;
        this.isTenantUser = helper.isTenantUser;
        this.isTenantPartner = helper.isTenantPartner;
    },
    mounted() {
        if (this.$route.query.code) this.filterCode = this.$route.query.code;
        if (this.$route.query.id) {
            this.idFromRouter = this.$route.query.id;
            this.freightDetailDrawer = true;
        }
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        },

        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        loadMore() {
            this.isLoading = false;
        },
        onFilterChanged(filter) {
            this.freightFilter = filter;
        },
        onFreightSelected(freight) {
            this.selectedFreight = freight.node;
            this.$router.push({ query: { id: freight.node.id } });
            this.freightDetailDrawer = true;
        },
        onFreightDetailDrawerFullScreenChange(val) {
            this.freightDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        resetRoute() {
            this.selectedFreight = null;
            this.freightDetailFullScreen = false;
            this.idFromRouter = null;
            this.$router.push({ query: null });
        }
    }
};
</script>
