<template>
    <v-container fluid v-scroll="handleScroll">
        <div>
            <v-row justify="center">
                <v-col cols="10">
                    <v-row align="center" class="d-flex justify-center align-center">
                        <v-col cols="12">
                            <v-card class="align-end" height="150" color="transparent" flat>
                                <v-row>
                                    <v-col cols="12">
                                        <v-toolbar flat color="transparent">
                                            <v-btn icon @click="cancel">
                                                <v-icon>arrow_back_ios_new</v-icon>
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <!-- <v-btn icon @click="cancel">
                                                <v-icon>cancel</v-icon>
                                            </v-btn> -->
                                        </v-toolbar>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="textTitle">
                                            {{
                                                this.editMode ? $t("trackers.edit_tracker") : $t("trackers.add_tracker")
                                            }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <!-- 
                                    <div class="textTitle">
                                        {{ this.editMode ? $t("trackers.edit_tracker") : $t("trackers.add_tracker")  }}
                                    </div>
                                -->
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-tabs v-model="tab" background-color="transparent">
                                <v-tabs-slider color="primary"></v-tabs-slider>
                                <v-tab v-for="tab in tabs" :key="tab.id">
                                    <template v-slot:default>
                                        <div class="text-capitalize ">
                                            {{ tab.name }}
                                        </div>
                                    </template>
                                </v-tab>
                            </v-tabs>
                            <v-divider></v-divider>
                            <v-tabs-items v-model="tab" style="background-color: transparent;">
                                <v-tab-item :transition="false">
                                    <v-card
                                        flat
                                        outLined
                                        class="mt-12"
                                        v-for="(tracker, index) in trackersInfo"
                                        :key="index"
                                        ref="trackerCard"
                                    >
                                        <v-card-title class="mb-4">
                                            <div class="textTitle pl-6 ">
                                                {{ $t("trackers.tracker_info") }}
                                            </div>
                                            <v-spacer></v-spacer>
                                            <!-- <v-spacer></v-spacer> -->
                                            <div v-if="isStaff">
                                                <v-autocomplete
                                                    v-model="tenant"
                                                    :items="tenants ? tenants.edges : []"
                                                    :no-data-text="$t('loading.no_available')"
                                                    :label="$t('trackers.tenant') + '*'"
                                                    item-text="node.name"
                                                    item-value="node.id"
                                                    outlined
                                                    dense
                                                    :readonly="isAvailable"
                                                    :rules="[rules.required]"
                                                ></v-autocomplete>
                                            </div>
                                            <div :class="isStaff ? 'pl-2' : ''">
                                                <button
                                                    icon
                                                    @click="removeTracker(index)"
                                                    color="red"
                                                    v-if="trackersInfo.length > 1"
                                                >
                                                    <v-icon>delete</v-icon>
                                                </button>
                                            </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-form ref="trackerForm" v-model="trackerFormValid">
                                                <v-row class="px-6">
                                                    <!-- <v-col cols="12">
                                                        <v-row>
                                                            <v-col md="6">
                                                                <span class="subtitle-2">Tracker {{index + 1}}</span>
                                                            </v-col>
                                                            <v-col md="6" align="end">
                                                                <button icon @click="removeTracker(index)" color="red">
                                                                    <v-icon>delete</v-icon>
                                                                </button>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col> -->
                                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <v-row>
                                                            <v-col cols="12" class="py-0">
                                                                <div class="pb-6 textBody labelContent">
                                                                    <span>
                                                                        {{ $t("headers.brand") }} *
                                                                    </span>
                                                                </div>
                                                                <v-autocomplete
                                                                    v-model="tracker.brand"
                                                                    :items="brandsChoices"
                                                                    item-text="text"
                                                                    item-value="value"
                                                                    :readonly="!isAvailable"
                                                                    outlined
                                                                    dense
                                                                    class="inputContent"
                                                                    :rules="[rules.required]"
                                                                >
                                                                </v-autocomplete>
                                                            </v-col>
                                                            <v-col cols="12" class="py-0">
                                                                <div class="pb-6 textBody labelContent">
                                                                    <span>
                                                                        {{ $t("headers.model") }}
                                                                    </span>
                                                                </div>
                                                                <v-text-field
                                                                    v-model="tracker.model"
                                                                    outlined
                                                                    dense
                                                                    class="inputContent"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" class="py-0">
                                                                <div class="pb-6 textBody labelContent">
                                                                    <span>
                                                                        {{ $t("headers.package_number") }}
                                                                    </span>
                                                                </div>
                                                                <v-text-field
                                                                    v-model="tracker.packageNumber"
                                                                    outlined
                                                                    dense
                                                                    class="inputContent"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" class="py-0">
                                                                <div class="pb-6 textBody labelContent">
                                                                    <span>
                                                                        {{ $t("headers.expiry_date1") }}
                                                                    </span>
                                                                </div>
                                                                <DateComponent
                                                                    v-model="tracker.expiryDate"
                                                                    :outlined="true"
                                                                    :dense="true"
                                                                    class="inputContent"
                                                                ></DateComponent>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <v-row>
                                                            <v-col cols="12" class="py-0">
                                                                <div class="pb-6 textBody labelContent">
                                                                    <span>
                                                                        {{ $t("headers.serial_number") }} *
                                                                    </span>
                                                                </div>
                                                                <v-text-field
                                                                    v-model="tracker.serialNumber"
                                                                    :readonly="!isAvailable"
                                                                    outlined
                                                                    dense
                                                                    class="inputContent"
                                                                    :rules="[rules.required, rules.uniqueSerialNumbers]"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" class="py-0">
                                                                <div class="pb-6 textBody labelContent">
                                                                    <span>
                                                                        {{ $t("headers.main_tracker") }}
                                                                    </span>
                                                                </div>
                                                                <v-text-field
                                                                    v-model="tracker.mainTracker"
                                                                    outlined
                                                                    dense
                                                                    class="inputContent"
                                                                    :rules="[]"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" class="py-0">
                                                                <div class="pb-6 textBody labelContent">
                                                                    <span>
                                                                        {{ $t("headers.comment") }}
                                                                    </span>
                                                                </div>
                                                                <v-text-field
                                                                    v-model="tracker.comment"
                                                                    outlined
                                                                    dense
                                                                    class="inputContent"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" class="py-0">
                                                                <div class="pb-6 textBody labelContent">
                                                                    <span>
                                                                        {{ $t("trackers.stock_location") }}
                                                                    </span>
                                                                </div>
                                                                <v-autocomplete
                                                                    clearable
                                                                    hide-selected
                                                                    auto-select-first
                                                                    dense
                                                                    outlined
                                                                    :items="tenantLocations.edges"
                                                                    :no-data-text="$t('loading.no_available')"
                                                                    v-model="tracker.stockLocation"
                                                                    item-text="node.name"
                                                                    item-value="node.id"
                                                                    attach
                                                                >
                                                                    <template #append-item>
                                                                        <v-divider
                                                                            class="mt-4 append-divider"
                                                                        ></v-divider>
                                                                        <v-list-item dense class="append">
                                                                            <v-list-item-content>
                                                                                <v-btn
                                                                                    small
                                                                                    color="primary"
                                                                                    @click="openAddLocationDialog"
                                                                                    class="text-capitalize textBody"
                                                                                >
                                                                                    {{ $t("general.create") }}
                                                                                </v-btn>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                    </template>
                                                                </v-autocomplete>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-card-text>
                                    </v-card>
                                    <v-card flat color="background">
                                        <v-card-actions class="justify-center">
                                            <v-btn color="primary" @click="addTracker">
                                                {{ $t("trackers.add_tracker") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    <v-card style="margin-top: 12%;">
                                        <v-card-actions
                                            ref="overlapCard"
                                            class="fixed-bottom-card containerFooter px-8 outlineD "
                                            :style="`width: ${parentWidth}px`"
                                            v-show="!isCardHidden"
                                        >
                                            <v-btn text @click="closeAndResetInput" outlined>
                                                {{ $t("general.discard") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                elevation="0"
                                                color="primary"
                                                @click="save"
                                                :loading="isLoadingAddTrackerDialog || isLoadingCheckSerialNumber"
                                                :disabled="!trackerFormValid"
                                            >
                                                {{ $t("general.save") }}
                                            </v-btn>
                                        </v-card-actions>
                                        <v-card-actions ref="card" class="card-container rounded-lg px-8">
                                            <v-btn text @click="cancel" outlined>
                                                {{ $t("general.discard") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                elevation="0"
                                                color="primary"
                                                :disabled="!trackerFormValid"
                                                @click="save()"
                                                :loading="isSaving"
                                            >
                                                {{ $t("general.save") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item :transition="false">
                                    <!--add a card with dotted border-->
                                    <v-card
                                        flat
                                        outLined
                                        class="my-12"
                                        color="background"
                                        v-if="!showDataTable"
                                        max-width="100%"
                                    >
                                        <v-card-text class="d-flex justify-center">
                                            <DragAndDropFileUpload
                                                :showSelectedFile="false"
                                                v-on:upload="uploadDocuments"
                                                v-on:disabledUpload="disabledUploadMethod"
                                                ref="uploadFile"
                                            >
                                            </DragAndDropFileUpload>
                                        </v-card-text>
                                    </v-card>
                                    <v-card flat outLined class="mt-3" color="background" width="100%" v-else>
                                        <v-card-text class="px-0">
                                            <v-row class="mb-3" align="center" justify="end">
                                                <v-badge
                                                    :value="existingTrackers.length"
                                                    :content="existingTrackers.length"
                                                    bordered
                                                    color="primary"
                                                    overlap
                                                    class="mr-3"
                                                    ><v-btn
                                                        color="primary"
                                                        rounded
                                                        outlined
                                                        :disabled="existingTrackers.length == 0"
                                                        @click="cleanExistingTrackers"
                                                        ><v-icon left>delete_sweep</v-icon
                                                        >{{ $t("general.clear_existing") }}</v-btn
                                                    ></v-badge
                                                >
                                            </v-row>
                                            <v-data-table
                                                :headers="defaultHeaderChoices"
                                                :items.sync="sortedTrackers"
                                                :editable="true"
                                                :no-data-text="$t('trackers.no_add')"
                                                mobile-breakpoint="1285"
                                                ref="trackerTable"
                                            >
                                                <template v-slot:item.serialNumber="{ item }">
                                                    <v-form :ref="'serialNumberForm-' + item.serialNumber">
                                                        <v-text-field
                                                            v-model="item.serialNumber"
                                                            dense
                                                            :rules="[
                                                                rules.required,
                                                                rules.unique({
                                                                    checked: item.checked,
                                                                    valid: item.valid,
                                                                    serialNumber: item.serialNumber
                                                                })
                                                            ]"
                                                            @change="updateCheckedStatus(item.serialNumber)"
                                                        ></v-text-field>
                                                    </v-form>
                                                </template>
                                                <template v-slot:item.mainTracker="{ item }">
                                                    <v-text-field v-model="item.mainTracker" dense></v-text-field>
                                                </template>
                                                <template v-slot:item.model="{ item }">
                                                    <v-text-field v-model="item.model" dense></v-text-field>
                                                </template>
                                                <template v-slot:item.packageNumber="{ item }">
                                                    <v-text-field v-model="item.packageNumber" dense></v-text-field>
                                                </template>
                                                <template v-slot:item.expiryDate="{ item }">
                                                    <DateComponent
                                                        v-model="item.expiryDate"
                                                        :rules="[]"
                                                        :outlined="false"
                                                        :dense="true"
                                                        class="ma-0 pa-0"
                                                    >
                                                    </DateComponent>
                                                </template>
                                                <template v-slot:item.comment="{ item }">
                                                    <v-text-field v-model="item.comment" dense></v-text-field>
                                                </template>
                                                <template v-slot:item.actions="{ item }">
                                                    <v-icon class="mr-2" @click="deleteRow(item)">delete</v-icon>
                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                        <v-card flat color="transparent" height="100px"></v-card>
                                        <v-card-actions
                                            ref="overlapCard"
                                            class="fixed-bottom-card containerFooter px-8 outlineD "
                                            :style="`width: ${parentWidth}px`"
                                            v-show="!isCardHidden"
                                        >
                                            <v-btn text @click="closeAndResetInput" outlined>
                                                {{ $t("general.discard") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                elevation="0"
                                                color="primary"
                                                @click="save"
                                                :loading="isLoadingAddTrackerDialog || isLoadingCheckSerialNumber"
                                                :disabled="invalidTrackers || isBrandEmpty"
                                            >
                                                {{ $t("general.save") }}
                                            </v-btn>
                                        </v-card-actions>
                                        <v-card-actions ref="card" class="card-container rounded-lg px-8">
                                            <v-btn text @click="closeAndResetInput" outlined>
                                                {{ $t("general.discard") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                elevation="0"
                                                color="primary"
                                                @click="save"
                                                :loading="isLoadingAddTrackerDialog || isLoadingCheckSerialNumber"
                                                :disabled="invalidTrackers || isBrandEmpty"
                                            >
                                                {{ $t("general.save") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <PreviewAddTrackerColumnSelector
            :openAddTrackerColumnsSelectorDialog="openAddTrackerColumnsSelectorDialog"
            :headers="headersValuesFromExcel"
            :brandsChoices="brandsChoices"
            :defaultHeaders="defaultHeaderChoicesWithoutActions"
            :trackersToAdd="trackersFromExcel"
            v-on:closeAddTrackerColumnsSelectorDialog="cancelAddTrackers"
            v-on:updateTrackers="addTrackersFromExcel"
            :brand.sync="brand"
            :tenant.sync="tenantId"
            :isLoadingFile.sync="isLoadingFile"
        ></PreviewAddTrackerColumnSelector>
    </v-container>
</template>

<script>
import { read, utils } from "xlsx";
import gql from "graphql-tag";
import helper from "@/utils/helper";
import DateComponent from "@/components/base/DateComponent.vue";
import DragAndDropFileUpload from "@/components/base/DragAndDropFileUpload.vue";
import PreviewAddTrackerColumnSelector from "@/components/trackers/create/PreviewAddTrackerColumnSelector.vue";
// import PreviewAddLocation from "@/components/trackers/create/PreviewAddLocation.vue";
import moment from "moment";

export default {
    // Your script code here
    name: "PreviewAddTracker",
    components: {
        DateComponent,
        DragAndDropFileUpload,
        PreviewAddTrackerColumnSelector
    },
    data() {
        return {
            trackerFormValid: false,
            editMode: false,
            isSaving: false,
            isCardHidden: false,
            tab: null,
            tabs: [
                {
                    id: 1,
                    name: "Add Tracker"
                },
                {
                    id: 2,
                    name: "Upload from CSV/excel"
                }
            ],
            trackersInfo: [
                {
                    brand: null,
                    serialNumber: null,
                    mainTracker: null,
                    expiryDate: null,
                    model: null,
                    packageNumber: null,
                    comments: null,
                    stockLocation: null,
                    comment: null,
                    tenant: null
                }
            ],
            trackers: [
                {
                    brand: null,
                    serialNumber: null,
                    mainTracker: null,
                    expiryDate: null,
                    model: null,
                    packageNumber: null,
                    comments: null,
                    stockLocation: null,
                    comment: null,
                    tenant: null
                }
            ],
            brandsChoices: [
                { text: "Sensitech", value: "Sensitech" },
                { text: "Tive", value: "Tive" },
                { text: "DeltaTrak", value: "DeltaTrak" },
                { text: "Copeland(Emerson)", value: "Copeland" },
                { text: "Frigga", value: "Frigga" }
            ],
            tenantLocations: { edges: [] },
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                uniqueSerialNumbers: v => {
                    let serialNumbers = [];
                    if (this.tab === 1) {
                        serialNumbers = this.trackers.filter(t => t.serialNumber === v);
                    } else {
                        serialNumbers = this.trackersInfo.filter(t => t.serialNumber === v);
                    }
                    if (serialNumbers.length > 1) {
                        return this.$t("rules.duplicated");
                    }
                    let checkSerialNumber = this.validateSerialNumber(v);
                    if (checkSerialNumber) {
                        return this.$t("rules.serial");
                    }
                    return true;
                },
                unique: v => {
                    if (v.checked && !v.valid) {
                        return this.$t("rules.serial");
                    }
                    let serialNumbers = [];
                    if (this.tab === 1) {
                        serialNumbers = this.trackers.filter(t => t.serialNumber === v.serialNumber);
                    } else {
                        serialNumbers = this.trackersInfo.filter(t => t.serialNumber === v.serialNumber);
                    }
                    if (serialNumbers.length > 1) {
                        return this.$t("rules.duplicated");
                    }

                    // const serialNumbers = this.trackers.map(
                    //     t => t.serialNumber
                    // );
                    // const matchingSerialNumbers = serialNumbers.filter(
                    //     sn => sn === v.serialNumber
                    // );
                    // if (matchingSerialNumbers.length > 1) {
                    //     return this.$t("rules.duplicated");
                    // }
                    return true;
                },
                listRequired: v => (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.required") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            },
            tenantId: "",
            brand: "",
            fileName: null,
            trackerFile: null,
            uploadedFiles: [],
            disabledUpload: false,
            parentWidth: 0,
            defaultHeaderChoices: [
                // {
                //     text: this.$t("headers.brand"),
                //     align: "left",
                //     value: "brand"
                // },
                {
                    text: this.$t("headers.serial_number"),
                    align: "left",
                    value: "serialNumber"
                },
                {
                    text: this.$t("headers.main_tracker"),
                    align: "left",
                    value: "mainTracker"
                },
                {
                    text: this.$t("headers.model"),
                    align: "left",
                    value: "model"
                },
                {
                    text: this.$t("headers.package_number"),
                    align: "left",
                    value: "packageNumber"
                },
                {
                    text: this.$t("headers.expiration"),
                    align: "left",
                    value: "expiryDate"
                },
                {
                    text: this.$t("headers.comment"),
                    align: "left",
                    value: "comment"
                },
                {
                    text: "",
                    align: "right",
                    value: "actions",
                    sortable: false
                }
            ],
            isLoadingAddTrackerDialog: false,
            openAddTrackerColumnsSelectorDialog: false,
            trackersFromExcel: [],
            headersValuesFromExcel: [],
            isLoadingCheckSerialNumber: false,
            isLoadingFile: false,
            dateFormats: [
                "YYYY-MM-DD", // e.g., 2023-06-30
                "MM/DD/YYYY", // e.g., 06/30/2023
                "DD/MM/YYYY", // e.g., 30/06/2023
                "YYYY/MM/DD", // e.g., 2023/06/30
                "DD-MM-YYYY", // e.g., 30-06-2023
                "MM-DD-YYYY", // e.g., 06-30-2023
                "YYYY-MM-DD HH:mm", // e.g., 2023-06-30 12:30
                "MM/DD/YYYY HH:mm", // e.g., 06/30/2023 12:30
                "DD/MM/YYYY HH:mm", // e.g., 30/06/2023 12:30
                "YYYY/MM/DD HH:mm" // e.g., 2023/06/30 12:30
            ],
            showDataTable: false
        };
    },
    apollo: {
        tenantLocations: {
            query: gql`
                query tenantLocations($isTrackerStockLocation: Boolean) {
                    tenantLocations(isTrackerStockLocation: $isTrackerStockLocation) {
                        edges {
                            node {
                                name
                                id
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    isTrackerStockLocation: true
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantLocations,
            debounce: 0
            // skip() {
            //     return !this.me.isStaff;
            // }
            // pollInterval: 60000 // ms
        },
        tenants: {
            query: gql`
                query tenants {
                    tenants {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenants,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        screen() {
            return this.$vuetify.breakpoint.width;
        },
        isStaff() {
            return this.me?.isStaff || false;
        },
        isAvailable() {
            // return this.$store.state.isAvailable;
            return !this.editMode;
        },
        invalidTrackers() {
            let isValid = false;
            //check if there are any invalid or not checked trackers

            for (let i = 0; i < this.trackers.length; i++) {
                const item = this.trackers[i];
                if (item.checked && !item.valid) {
                    isValid = true;
                } else if (!item.checked) {
                    isValid = true;
                }
            }

            //check for duplicates in trackers
            const serialNumbers = this.trackers.map(t => t.serialNumber);
            const uniqueSerialNumbers = [...new Set(serialNumbers)];
            if (serialNumbers.length !== uniqueSerialNumbers.length) {
                isValid = true;
            }

            return isValid;
        },
        tenant: {
            get() {
                let _tenant = this.tracker?.tenant?.id;
                return _tenant ? _tenant : null;
            },
            set(value) {
                if (value !== null) {
                    this.tracker.tenant.id = value;
                }
            }
        },
        defaultHeaderChoicesWithoutActions() {
            let _allValues = [...this.defaultHeaderChoices];
          
            _allValues.pop();
            return _allValues;
        },
        existingTrackers() {
            return this.trackers.filter(t => t.checked && !t.valid);
        },
        isBrandEmpty() {
            return helper.isEmpty(this.brand);
        },
        sortedTrackers() {
            let sorted = [...this.trackers].sort((a, b) => (a.valid === b.valid ? 0 : a.valid ? 1 : -1));
            return sorted;
        }
    },
    mounted() {
        // Your mounted hook code here
        this.$nextTick(() => {
            this.overlapWidthCard(300);
        });
    },
    watch: {
        trackers: {
            handler: function(val) {
                if (val.length > 0) {
                    let _serialNumbersToCheck = [];

                    val.forEach(item => {
                        this.$nextTick(() => {
                            if (this.$refs[`serialNumberForm-${item.serialNumber}`] !== undefined) {
                                item.valid = this.$refs[`serialNumberForm-${item.serialNumber}`].validate();
                            }
                        });

                        if (item.checked === false) {
                            _serialNumbersToCheck.push(item.serialNumber);
                        }
                    });

                    this.checkSerialNumbers(_serialNumbersToCheck);
                } else {
                    this.fileName = null;
                }
            },
            deep: true
        },
        trackersInfo: {
            handler: function(val) {
                if (val.length > 0) {
                    let _serialNumbersToCheck = [];

                    val.forEach(item => {
                        this.$nextTick(() => {
                            if (this.$refs[`serialNumberForm-${item.serialNumber}`] !== undefined) {
                                item.valid = this.$refs[`serialNumberForm-${item.serialNumber}`].validate();
                            }
                        });

                        if (item.checked === false) {
                            _serialNumbersToCheck.push(item.serialNumber);
                        }
                    });

                    this.checkSerialNumbers(_serialNumbersToCheck);
                } else {
                    this.fileName = null;
                }
            },
            deep: true
        },
        screen() {
            this.overlapWidthCard(100);
        },
        sortedTrackers() {
            this.overlapWidthCard(100);
        }
    },
    methods: {
        overlapWidthCard(setTime) {
            /* This set width ovelap component base on the parent component width  */
            setTimeout(() => {
                let scope = this;
                let set = scope.$refs.trackerCard[0].$el.clientWidth;

                if(this.tab === 1){
                    set = scope.$refs.trackerTable.$el.clientWidth;
                }

                if (set) {
                    this.parentWidth = set;
                }
            }, setTime);
        },
        openAddLocationDialog() {
            //TODO: open the add location dialog
            //console.log("openAddLocationDialog");
        },
        disabledUploadMethod(val) {
            this.disabledUpload = val;
        },
        showMessage(payload) {
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        validArray(list) {
            let validParms = list != undefined && list != null && list.length > 0 ? true : false;

            return validParms;
        },
        cancelAddTrackers() {
            this.openAddTrackerColumnsSelectorDialog = false;
            this.trackersFromExcel = [];
            this.headersValuesFromExcel = [];
            this.isLoadingFile = false;
            this.fileName = null;
        },
        extractHeaders(sheet) {
            const headers = [];
            const range = utils.decode_range(sheet["!ref"]);

            for (let column = range.s.c; column <= range.e.c; column++) {
                const cell = sheet[utils.encode_cell({ r: 0, c: column })];
                const header = cell?.w || utils.format_cell(cell);
                if (header != undefined && header != null && header != "") {
                    headers.push(header);
                }
            }

            return headers;
        },
        readFileAsArrayBuffer(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = event => resolve(new Uint8Array(event.target.result));
                reader.onerror = event => reject(event.target.error);
                reader.readAsArrayBuffer(file);
            });
        },
        cleanExistingTrackers() {
            this.trackers = this.trackers.filter(item => {
                return item.checked && item.valid;
            });
        },
        async uploadDocuments(file) {
            if (!file) {
                return;
            }
            this.fileName = file.name;
            try {
                const data = await this.readFileAsArrayBuffer(file);
                const workbook = read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = utils.sheet_to_json(sheet);

                if (!this.validArray(jsonData)) {
                    const errorMessage = this.$t("rules.file_valid");
                    this.showMessage({ color: "error", message: errorMessage });
                    return;
                }

                const headers = this.extractHeaders(sheet);

                this.showTrackerColumnsSelectorDialog(jsonData, headers);
            } catch (error) {
                console.error("Error importing data:", error);
            } finally {
                this.resetFileInput();
            }
        },
        resetFileInput() {},
        showTrackerColumnsSelectorDialog(jsonData, headers) {
            this.trackersFromExcel = jsonData;
            this.headersValuesFromExcel = headers;
            this.openAddTrackerColumnsSelectorDialog = true;
        },
        validateSerialNumber(serialNumber) {
            let isValid = false;
            this.isLoadingCheckSerialNumber = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation CheckIfTrackersExist($input: CheckIfTrackersExistInput!) {
                            checkIfTrackersExist(input: $input) {
                                response {
                                    serialNumber
                                    exists
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            serialNumbers: [serialNumber]
                        }
                    }
                })
                .then(res => {
                    let _trackersChecked = res.data.checkIfTrackersExist.response;
                    const _item = _trackersChecked[0];
                    isValid = _item.exists;
                    return isValid;
                    // this.isLoadingCheckSerialNumber = false;
                })
                .catch(err => {
                    console.log(err);
                    // this.isLoadingCheckSerialNumber = false;
                })
                .finally(() => {
                    this.isLoadingCheckSerialNumber = false;
                });
        },
        checkSerialNumbers(serialNumbers) {
            if (serialNumbers.length > 0) {
                this.isLoadingCheckSerialNumber = true;
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CheckIfTrackersExist($input: CheckIfTrackersExistInput!) {
                                checkIfTrackersExist(input: $input) {
                                    response {
                                        serialNumber
                                        exists
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                serialNumbers: serialNumbers
                            }
                        }
                    })
                    .then(res => {
                        let _trackersChecked = res.data.checkIfTrackersExist.response;
                        for (let i = 0; i < this.trackers.length; i++) {
                            let tracker = this.trackers[i];
                            for (let j = 0; j < _trackersChecked.length; j++) {
                                const _item = _trackersChecked[j];
                                if (tracker.serialNumber == _item.serialNumber) {
                                    tracker.checked = true;
                                    tracker.valid = !_item.exists;

                                    this.$nextTick(() => {
                                        if (this.$refs[`serialNumberForm-${_item.serialNumber}`] !== undefined) {
                                            _item.valid = this.$refs[
                                                `serialNumberForm-${_item.serialNumber}`
                                            ].validate();
                                        }
                                    });
                                }
                            }
                        }
                        // this.isLoadingCheckSerialNumber = false;
                    })
                    .catch(err => {
                        console.log(err);
                        // this.isLoadingCheckSerialNumber = false;
                    })
                    .finally(() => {
                        this.isLoadingCheckSerialNumber = false;
                    });
            }
        },
        addTrackersFromExcel(val) {

            if (val == null || val.length == 0) {
                return;
            }
            this.isLoadingFile = true;
            
            for (let i = 0; i < val.length; i++) {
                const item = val[i];
                item["checked"] = false;
                item["valid"] = false;

                if (item.expiryDate) {
                    try {
                        let convertedDate = null;
                        for (let i = 0; i < this.dateFormats.length; i++) {
                            const dateFormat = this.dateFormats[i];
                            const parsedDate = moment(item.expiryDate, dateFormat, true);
                            if (parsedDate.isValid()) {
                                convertedDate = parsedDate.format("YYYY-MM-DD");
                                break;
                            }
                        }
                        if (convertedDate == null) {
                            if (
                                helper.isDateValid(item.expiryDate) == true &&
                                item.expiryDate != "Invalid Date" &&
                                !isNaN(item.expiryDate)
                            ) {
                                convertedDate = new Date(item.expiryDate).toISOString();
                            }
                        }
                        item.expiryDate = convertedDate;
                    } catch (error) {
                        item.expiryDate = null;
                    }
                }
            }
            this.trackers = [];
            this.trackers = this.trackers.concat(val);
            
            this.trackersFromExcel = [];
            this.openAddTrackerColumnsSelectorDialog = false;
            this.isLoadingFile = false;
            this.showDataTable = true;
            //Todo: hide the upload card and show the trackers table card
        },
        addTracker() {
            this.trackers.push({
                brand: null,
                serialNumber: null,
                mainTracker: null,
                expiryDate: null,
                model: null,
                packageNumber: null,
                comments: null,
                stockLocation: null,
                comment: null
            });
        },
        removeTracker(index) {
            this.trackers.splice(index, 1);
        },
        save() {
            if (this.trackers.length == 0) {
                return;
            }

            if (this.tab === 0) {
                // if (this.$refs.trackerForm.validate() == false){
                if (!this.trackerFormValid) {
                    return;
                }
                this.trackers = [...this.trackersInfo];
            } else {
                for (let i = 0; i < this.trackers.length; i++) {
                    const item = this.trackers[i];
                    if (item.valid == false) {
                        this.$refs[`serialNumberForm-${item.serialNumber}`].validate();
                        return;
                    }
                }
            }

            //prepare the input
            let _trackerInput = this.trackers.map(item => {
                return {
                    brand: this.tab === 0 ? item.brand : this.brand,
                    serialNumber: item.serialNumber,
                    mainTracker: item.mainTracker,
                    model: item.model,
                    packageNumber: item.packageNumber,
                    expiryDate: new Date(item.expiryDate),
                    comment: item.comment,
                    stockLocationId: this.tab === 0 ? item.stockLocation : this.stockLocation
                };
            });

            this.isLoadingAddTrackerDialog = true;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation CreateTrackersV1($input: CreateTrackers_v1Input!) {
                            createTrackersV1(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            trackers: _trackerInput,
                            tenantId: this.isStaff ? this.tenantId : this.me.tenant.id,
                            fileName: this.fileName
                        }
                    }
                })
                .then(() => {
                    this.isLoadingAddTrackerDialog = false;
                    this.closeAndResetInput();
                    this.$emit("updateTrackersTable");
                })
                .catch(err => {
                    this.isLoadingAddTrackerDialog = false;
                    console.log(err);
                });
        },
        saveTrackers() {
            for (let i = 0; i < this.trackers.length; i++) {
                const item = this.trackers[i];
                if (item.valid == false) {
                    this.$refs[`serialNumberForm-${item.serialNumber}`].validate();
                    return;
                }
            }

            if (this.$refs.brandForm.validate() == false) {
                return;
            }

            if (this.trackers.length == 0) {
                return;
            }

            let _trackerInput = this.trackers.map(item => {
                return {
                    serialNumber: item.serialNumber,
                    mainTracker: item.mainTracker,
                    model: item.model,
                    packageNumber: item.packageNumber,
                    expiryDate: new Date(item.expiryDate),
                    comment: item.comment
                };
            });

            this.isLoadingAddTrackerDialog = true;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation CreateTrackers($input: CreateTrackersInput!) {
                            createTrackers(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            trackers: _trackerInput,
                            brand: this.brand,
                            stockLocationId: this.stockLocation,
                            tenantId: this.isStaff ? this.tenantId : this.me.tenant.id,
                            fileName: this.fileName
                        }
                    }
                })
                .then(() => {
                    this.isLoadingAddTrackerDialog = false;
                    this.closeAndResetInput();
                    this.$emit("updateTrackersTable");
                })
                .catch(err => {
                    this.isLoadingAddTrackerDialog = false;
                    console.log(err);
                });
        },
        cancel() {
            this.$router.push({ name: "previewtrackers" });
        },
        deleteRow(item) {
            const index = this.trackers.indexOf(item);
            if (index >= 0) {
                this.trackers.splice(index, 1);
            }
        },
        closeAndResetInput() {
            this.trackers = [];
            if (this.tab === 0) {
                this.trackersInfo.push({
                    brand: null,
                    serialNumber: null,
                    mainTracker: null,
                    expiryDate: null,
                    model: null,
                    packageNumber: null,
                    comments: null,
                    stockLocation: null,
                    comment: null
                });
            }
            this.brand = null;
            this.stockLocation = null;
            this.tenantId = null;
            this.fileName = null;
            // this.$refs.brandForm.resetValidation();
            // this.$refs.trackerForm.resetValidation();
            this.cancel();
            // this.$emit("closeAddTrackerDialog");
        },
        handleScroll() {
            const overlapCard = this.$refs.overlapCard;
            const otherCard = this.$refs.card;
            const overlapRect = overlapCard.getBoundingClientRect();
            const cardRect = otherCard.getBoundingClientRect();

            let _overlapRect = overlapRect.top > 0 ? overlapRect : this.storeCardCoordinates;

            if (overlapRect.top > 0) {
                this.storeCardCoordinates = overlapRect;
            }

            if (this.isLessThan(_overlapRect, cardRect)) {
                this.isCardHidden = false;
            } else if (this.isMoreThan(_overlapRect, cardRect)) {
                this.isCardHidden = true;
            }
        },
        isLessThan(rect1, rect2) {
            let rectTop = rect2.top - 20;
            return rect1.bottom <= rectTop;
        },
        isMoreThan(rect1, rect2) {
            return rect1.bottom > rect2.top;
        }
    }
    // Other Vue script options here
};
</script>

<style scoped>
.append {
    position: sticky;
    bottom: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
}
.append-divider {
    position: sticky;
}
.maxHeight {
    max-height: 460px;
}
/* .form-input >>> .error--text {
        color: yellow !important;
    }
    .v-application .error--text {
        color: yellow !important;
    } */
.primaryText {
    font-size: 12px !important;
    color: "#868599" !important;
    height: 23px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.secondaryText {
    font-size: 10px !important;
    color: "#0e1e46" !important;
    height: 21px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
}
/* .inputContent {
        height: 52px;
    } */
.textSubtitle {
    font-size: 12px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outLined {
    border: 1px solid #9fa5b5 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
}
.backgroundColor {
    background-color: #f9fafe !important;
}

.stepperContent {
    height: 30px;
    padding: 16px 8px 16px 8px !important;
}

.textLeft {
    text-align: left !important;
}
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}
.fixed-bottom-card {
    position: fixed;
    bottom: 1.2px;
    z-index: 9999;
}
.fixed-bottom-card-2 {
    position: fixed;
    bottom: 50px;
    z-index: 1000;
}

.fixed-bottom-card-1 {
    bottom: 50px;
}
.containerFooter {
    height: 80px;
    background-color: #ffffff;
    border-radius: 5px;
}
.card-container {
    position: relative;
    height: 80px;
    background-color: #ffffff;
    border-radius: 5px;
}
.disabledClass {
    background-color: #f9fafe !important;
    color: #9fa5b5 !important;
}

.bottom-action {
    padding-top: 40px;
}
</style>
