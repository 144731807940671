<template>
    <v-card fill-height flat width="100%" v-if="actionGroup" color="background">
        <v-toolbar flat color="background">
            <!----- TOP LEFT ICONS ---->
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn
                v-if="shouldShowFullScreenButton"
                icon
                @click="changeFullScreen"
            >
                <v-icon>{{
                    isFullScreen ? "fullscreen_exit" : "crop_free"
                }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <!---- TOP RIGHT ICONS ---->
            <v-btn icon v-if="canEdit">
                <v-icon @click="editGroupDialogHandler(actionGroup)">
                    edit
                </v-icon>
            </v-btn>
            <v-btn icon v-if="canDelete">
                <v-icon @click="openDeleteGroupDialog = true"> delete </v-icon>
            </v-btn>

            <!---- EDIT PARTNER DIALOG ---->
            <EditActionGroupDialog
                ref="editGroupDialog"
                :showGroupSelect="true"
                :openEditGroupDialog="openEditGroupDialog"
                v-on:updatedGroup="updateGroupValues"
                v-on:closeEditGroupDialog="openEditGroupDialog = false"
                :actionGroup="updatedGroup ? updatedGroup : actionGroup"
            ></EditActionGroupDialog>

            <!---- DELETE PARTNER DIALOG ---->
            <DeleteActionGroupDialog
                :openDeleteGroupDialog="openDeleteGroupDialog"
                :groupId="actionGroup.id"
                v-on:refreshDataTable="closeDrawerAndRefresh"
                v-on:closeDeleteDialog="openDeleteGroupDialog = false"
            >
                <template v-slot:title>
                    {{ $t("alert_rules.delete_action_group") }}
                </template>
                <template #content>
                    {{ $t("alert_rules.confirm_action_group") }}
                </template>
            </DeleteActionGroupDialog>

            <!---- FILTER DIALOG ---->
            <FilterMenu
                :title="$t('dashboard.customize')"
                :description="$t('dashboard.display_tabs')"
                :choices="filterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
                class="mr-n2"
            >
            </FilterMenu>
        </v-toolbar>

        <v-card-title class="pt-0 px-5">
            <!---- GROUP GENERAL INFO DISPLAY (NAME, USERS...) ---->
            <v-row class="d-flex align-center pt-2 mx-2">
                <!---- NAME ---->
                <v-col cols="6" sm="3" class="py-0 px-0 mb-1">
                    <span class="text-caption">{{ $t("headers.name") }}</span
                    ><br />
                    <span class="font-weight-medium">
                        {{
                            updatedGroup ? updatedGroup.name : actionGroup.name
                        }}
                    </span>
                </v-col>
                <!---- NOTIFICATIONS ---->
                <v-col cols="3" sm="3" class="py-0 px-0 mb-1">
                    <span class="text-caption">{{
                        $t("general.email_notification")
                    }}</span
                    ><br />
                    <span
                        class="font-weight-medium"
                        v-if="
                            updatedGroup
                                ? updatedGroup.shouldEmailNotification
                                : actionGroup.shouldEmailNotification
                        "
                    >
                        {{ $t("headers.enabled") }}
                    </span>
                    <span class="font-weight-medium" v-else>
                        {{ $t("headers.disabled") }}
                    </span>
                </v-col>
                <v-col cols="3" sm="3" class="py-0 px-0 mb-1">
                    <span class="text-caption">{{
                        $t("headers.inbox_notification")
                    }}</span
                    ><br />
                    <span
                        class="font-weight-medium"
                        v-if="
                            updatedGroup
                                ? updatedGroup.shouldInboxNotification
                                : actionGroup.shouldInboxNotification
                        "
                    >
                        {{ $t("headers.enabled") }}
                    </span>
                    <span class="font-weight-medium" v-else>
                        {{ $t("headers.disabled") }}
                    </span>
                </v-col>
                <v-spacer></v-spacer>

                <!---- SELECT TAB OR LIST VIEW ---->
                <v-col cols="4" sm="3" class="d-flex justify-end">
                    <v-select
                        v-model="view"
                        :items="viewChoices"
                        dense
                        item-text="name"
                        item-value="code"
                        return-value
                        outlined
                        hide-details
                        class="fit"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-title>

        <!---- tab view ---->
        <ActionGroupDetailTabView
            v-if="view === 'tab'"
            :actionGroup="actionGroup"
            :selectedFilters="selectedFilters"
            v-on:refreshDataTable="refreshDataTable()"
        />

        <!---- list view ---->
        <ActionGroupDetailListView
            v-if="view === 'list'"
            class="mx-5 pb-10"
            :actionGroup="actionGroup"
            :selectedFilters="selectedFilters"
            v-on:refreshDataTable="refreshDataTable()"
        />
    </v-card>
</template>
<script>
import helper from "@/utils/helper";
import FilterMenu from "@/components/base/FilterMenu.vue";
import ActionGroupDetailTabView from "@/components/actiongroups/ActionGroupDetailTabView.vue";
import ActionGroupDetailListView from "@/components/actiongroups/ActionGroupDetailListView.vue";
import DeleteActionGroupDialog from "@/components/actiongroups/delete/DeleteActionGroupDialog.vue";
import EditActionGroupDialog from "@/components/actiongroups/update/EditActionGroupDialog.vue";
import { TenantPermissions } from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "ActionGroupDetail",
    props: {
        actionGroup: {
            type: Object,
            required: true
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        ActionGroupDetailTabView,
        ActionGroupDetailListView,
        FilterMenu,
        DeleteActionGroupDialog,
        EditActionGroupDialog
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
    },
    data() {
        return {
            /**********************/
            /* --- QUERY DATA --- */
            /**********************/
            updatedGroup: null,
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /***********************************/
            openDeleteGroupDialog: false,
            openEditGroupDialog: false,
            /*******************/
            /* --- FILTERS --- */
            /*******************/
            view: "tab",
            viewChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "users",
                    name: "Users",
                    tag: "home.users",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "externalEmails",
                    name: "External Emails",
                    tag: "general.external_emails",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "alerts",
                    name: "Alert Rules",
                    tag: "home.alert_rules",
                    enabled: true
                }
            ],
            filterChoices: []
        };
    },
    created() {
        this.viewType = helper.getViewType("actionGroupDetailViewType");
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "actionGroupDetailFilterChoices"
        );
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];

            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        canEdit() {
            return this.hasPermission(
                TenantPermissions.EDIT_TENANT_ACTION_GROUP
            );
        },
        canDelete() {
            return this.hasPermission(
                TenantPermissions.DELETE_TENANT_ACTION_GROUP
            );
        }
    },
    watch: {
        viewType: {
            handler(value) {
                if (value) {
                    helper.updateMyPreferences({
                        actionGroupDetailViewType: value
                    });
                }
            },
            deep: true
        },
        filterChoices: {
            handler(value) {
                if (value) {
                    helper.updateMyPreferences({
                        actionGroupDetailFilterChoices: value
                    });
                }
            },
            deep: true
        }
    },
    mounted(){
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'action_group_details_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("action_group_details_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("action_group_details_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editGroupDialogHandler(item) {
            this.openEditGroupDialog = true;
            this.$refs.editGroupDialog.initializeEditing(item);
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updateGroupValues(val) {
            this.$emit("refreshDataTable");
            this.openEditGroupDialog = false;
            this.updatedGroup = val.tenantActionGroup;
        },
        refreshDataTable() {
            this.$emit("refreshDataTable");
        }
    }
};
</script>
<style scoped>
.v-select.fit {
  max-width: min-content;
  padding: 10px;
}
.v-select.fit  .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
</style>