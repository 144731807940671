<template>
    <v-container fluid class="pa-0">
        <v-row class="d-flex align-center justify-end mt-1 mb-3 mr-0">
            <v-col cols="auto">
                <!-- ADD -->
                <v-btn
                    v-if="!openSelector"
                    rounded
                    color="primary"
                    class="mr-1"
                    @click="openAddGroup = true"
                >
                    {{ $t("groups.create_group") }}
                </v-btn>
                <!-- SELECT -->
                <v-btn
                    v-if="!openSelector"
                    color="primary"
                    @click="openSelector = true"
                    rounded
                    class="mr-2"
                    >{{$t("general.select")}}</v-btn
                >

                <!-- REMOVE -->
                <v-btn
                    v-if="openSelector"
                    rounded
                    class="mr-1"
                    color="error"
                    :disabled="enableOrDisableRemove"
                    @click="openDeleteDialog = true"
                >
                {{$t("general.remove")}}</v-btn
                >

                <!-- CANCEL -->
                <v-btn
                    v-if="openSelector"
                    text
                    @click="openSelector = false"
                    rounded
                    class="mr-2"
                    >{{$t("general.cancel")}}
                </v-btn>
            </v-col>
        </v-row>
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <!-- <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col>
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                            align-top
                        "
                    >
                        Groups
                    </h2>
                </v-col>
                <v-col cols="auto" class="px-0"> </v-col>
            </v-row>
        </div> -->

        <!-- GROUPS FILTER/TOOLBAR -->
        <GroupsFilter
            class="mx-3"
            :selector="openSelector"
            :selectedGroups.sync="selectedGroups"
            v-on:updateGroupTable="refetchTableData"
            v-on:deletedGroup="selectedGroups = []"
            v-on:filter-changed="onFilterChanged"
            v-on:updateSelector="onUpdateSelector"
        ></GroupsFilter>

        <!-- TABLE -->
        <GroupsTable
            ref="groupTable"
            v-on:selectedCheckbox="sendSelectedBoxes"
            :groupsFilter="groupsFilter"
            :selector="openSelector"
            v-on:selectedGroups="updateSelectedGroups"
            v-on:click:row="onGroupSelected"
            class="mx-1"
        ></GroupsTable>

        <!---- ADD GROUP DIALOG ---->
        <AddGroupDialog
            ref="addGroupDialog"
            :openAddGroupDialog="openAddGroup"
            v-on:refreshDataTable="refetchTableData"
            v-on:closeAddGroupDialog="openAddGroup = false"
        >
        </AddGroupDialog>

        <!---- DELETE GROUP DIALOG ---->
        <DeleteGroupDialog
            :openDeleteGroupDialog="openDeleteDialog"
            :groupId="selectedGroups"
            v-on:refreshDataTable="refetchTableData"
            v-on:closeDeleteDialog="closeAndCleanDeletedGroups"
        >
        </DeleteGroupDialog>

        <!-- TUTORIAL -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>

        <!-- DETAIL DRAWER -->
        <v-navigation-drawer
            v-model="groupDetailDrawer"
            :width="groupDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <GroupDetail
                    ref="groupDetail"
                    v-if="selectedGroup"
                    :key="selectedGroup.node.id"
                    :group="selectedGroup"
                    :isFullScreen="isGroupDetailDrawerFullScreen"
                    v-on:changeFullScreen="onGroupDetailDrawerFullScreenChange"
                    v-on:refreshDataTable="refetchTableData"
                    v-on:close="groupDetailDrawer = false"
                ></GroupDetail>
            </div>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import GroupsFilter from "@/components/groups/GroupsFilter.vue";
import GroupsTable from "@/components/groups/GroupsTable.vue";
import GroupDetail from "@/components/groups/GroupDetail.vue";
import { eventBus } from "@/main.js";
import AddGroupDialog from "@/components/groups/create/AddGroupDialog.vue";
import DeleteGroupDialog from "@/components/groups/delete/DeleteGroupDialog.vue";

export default {
    title: "Groups",
    components: {
        TutorialDialog,
        GroupsFilter,
        GroupsTable,
        GroupDetail,
        AddGroupDialog,
        DeleteGroupDialog
    },

    apollo: {},

    data() {
        return {
            searchInput: null,
            shouldStickyFilter: false,
            openAddGroup: false,
            openDeleteDialog: false,
            openSelector: false,

            groupDetailDrawer: false,
            groupDetailFullScreen: false,
            groupsFilter: {
                first: 20
            },
            goTopShow: false,
            isLoading: false,
            menu: false,
            selectedGroup: null,
            selectedGroups: null,
            tutorialDialog: false
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        groupDetailDrawerWidth() {
            if (this.groupDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isGroupDetailDrawerFullScreen() {
            return this.groupDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        enableOrDisableRemove() {
            return this.selectedGroups?.length > 0 ? false : true;
        },
    },

    watch: {},

    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },

    mounted() {
        this.onResize();
    },

    methods: {
        closeAndCleanDeletedGroups() {
            // this.$emit("deletedGroup");
            this.selectedGroups = []
            this.openDeleteDialog = false;
        },
        onGroupSelected(group) {
            this.selectedGroup = group;
            this.groupDetailDrawer = true;
        },

        onGroupDetailDrawerFullScreenChange(val) {
            this.groupDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },

        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },

        onLoading(val) {
            this.isLoading = val;
        },

        onFilterChanged(filter) {
            this.groupsFilter = filter;
        },

        refetchTableData() {
            this.$refs.groupTable.$apollo.queries.groups.refetch();
        },

        sendSelectedBoxes(e) {
            this.selectedGroups = e;
        },

        onUpdateSelector(val) {
            this.openSelector = val;
        },

        updateSelectedGroups(val) {
            this.selectedGroups = val.map(group => {
                return group.node.id;
            });
        },

        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        }
    }
};
</script>
<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
</style>
