<template>
    <v-container class="py-0">
        <v-card
            :key="me.id"
            elevation="3"
            class="mx-auto"
            flat
            width="600"
            height="360"
            :loading="isFetchingMe"
        >
            <v-card-text class="text-center pt-16">
                <v-avatar color="primary" size="56">
                    <v-icon dark>
                        person
                    </v-icon></v-avatar
                >
                <h3 class="text-h5 mt-5 mb-2">
                    {{ me.firstName }} {{ me.lastName }}
                </h3>
                <div class="blue--text mb-2">
                    {{ me.email }}
                </div>

                <v-chip v-if="me.enabled2fa" color="success" small class="ml-2"
                    ><v-icon small color="white" left>verified_user</v-icon
                    >2FA</v-chip
                >
                <v-chip v-else color="grey" disabled small class="ml-2"
                    ><v-icon small color="white" left>cancel</v-icon>2FA</v-chip
                >

                <div class="mt-10">
                    <v-btn
                        small
                        outlined
                        rounded
                        color="primary"
                        class="mx-1 mb-1"
                        @click="
                            changeProfileFormDialog = !changeProfileFormDialog
                        "
                    >
                        {{ $t("general.change_profile") }}
                    </v-btn>
                    <v-btn
                        small
                        outlined
                        rounded
                        color="primary"
                        class="mx-1 mb-1"
                        @click="
                            changePasswordFormDialog = !changePasswordFormDialog
                        "
                    >
                        {{ $t("general.change_password") }}
                    </v-btn>
                    <v-btn
                        v-if="me.enabled2fa"
                        small
                        outlined
                        rounded
                        color="error"
                        class="mx-1 mb-1"
                        @click="disable2faFormDialog = !disable2faFormDialog"
                    >
                        {{ $t("general.disable_2fa") }}
                    </v-btn>
                    <v-btn
                        v-else
                        small
                        outlined
                        rounded
                        color="primary"
                        class="mx-1 mb-1"
                        @click="enable2faFormaDialog = !enable2faFormaDialog"
                    >
                        {{ $t("general.enable_2fa") }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>

        <!-- Change my profile dialog -->
        <ChangeMyProfileForm
            :dialog.sync="changeProfileFormDialog"
            v-on:callback="fetchMe"
        >
        </ChangeMyProfileForm>

        <!-- Change my password dialog -->
        <ChangePasswordForm :dialog.sync="changePasswordFormDialog">
        </ChangePasswordForm>

        <!-- Enable 2FA dialog -->
        <Enable2faForm
            :dialog.sync="enable2faFormaDialog"
            v-on:callback="fetchMe"
        ></Enable2faForm>

        <!-- Disable 2FA dialog -->
        <Disable2faForm
            :dialog.sync="disable2faFormDialog"
            v-on:callback="fetchMe"
        >
        </Disable2faForm>
    </v-container>
</template>

<script>
import ChangeMyProfileForm from "./forms/ChangeMyProfileForm.vue";
import ChangePasswordForm from "./forms/ChangePasswordForm.vue";
import Enable2faForm from "./forms/Enable2faForm.vue";
import Disable2faForm from "./forms/Disable2faForm.vue";
import helper from "@/utils/helper.js";
import Config from "@/utils/config.json";

export default {
    title: "Details",
    props: [],
    components: {
        ChangeMyProfileForm,
        ChangePasswordForm,
        Enable2faForm,
        Disable2faForm
    },

    apollo: {},

    data() {
        return {
            isFetchingMe: false,
            changeProfileFormDialog: false,
            changePasswordFormDialog: false,
            enable2faFormaDialog: false,
            disable2faFormDialog: false
        };
    },

    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {},
    mounted() {
        // fetch me
        this.fetchMe();
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'profile_details_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("profile_details_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("profile_details_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        fetchMe() {
            this.isFetchingMe = true;

            this.$store
                .dispatch("user/fetchMe")
                .then(() => {
                    try {
                        // google analytics
                        this.$gtag.set("user_properties", {
                            user_email: this.$store.state.user.me.email,
                            user_tenant: this.$store.state.user.me.tenant
                                ? this.$store.state.user.me.tenant.name
                                : null
                        });
                    } catch (err) {
                        console.log(err);
                    }
                })
                .catch(error => {
                    console.log("fetchMe catched error: ", error);
                })
                .finally(() => {
                    this.isFetchingMe = false;
                });
        }
    }
};
</script>
