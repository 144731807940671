<template>
    <v-dialog v-model="temperatureUnitsDialog" max-width="500" persistent>
        <template v-slot:activator="{ on, attrs }">
            <div v-if="displayFormat === 'icon'"  v-on="on">
                <v-tooltip
                    bottom                     
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="screenSize === 'lg' || screenSize === 'xl' || screenSize === 'md'"
                        >
                            <v-icon>manage_history</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ timezone }} {{ temperature.symbol }}</span>
                </v-tooltip>
            </div>
            <div v-else-if="displayFormat === 'text'">
                <div v-on="on" class="">
                    {{ $t('general.datetime_temparature') }}
                </div>   
            </div>
            <div v-else>
                <v-btn
                    label
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    v-if="
                        screenSize === 'lg' ||
                        screenSize === 'xl' ||
                        screenSize === 'md'
                    "
                >
                    {{ timezone }} {{ temperature.symbol }}
                </v-btn>
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    v-else
                >
                    {{ timezone }} {{ temperature.symbol }}
                </v-btn>
            </div>

        </template>
        <v-card>
            <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-2">settings</v-icon>
                {{ $t("home.settings") }}
            </v-card-title>
            <v-card-text>
                <template>
                    <v-container fluid>
                        <h2 style="margin-bottom: 1%; margin-top: 1%">
                            {{ $t("general.timezone") }}:
                        </h2>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="12">
                                <v-autocomplete
                                    auto-select-first
                                    :items="timezonesList"
                                    v-model="pendingTimezone"
                                    item-text="zone"
                                    item-value="zone"
                                >
                                    <template v-slot:item="data">
                                        <v-row class="ma-0 body-2">
                                            <v-col
                                                cols="auto"
                                                class="text-truncate pl-0 ml-0"
                                            >
                                                {{ data.item.zone }}
                                            </v-col>
                                            <v-col
                                                cols="auto"
                                                class="text-truncate pl-0 ml-0 grey--text"
                                            >
                                                {{ data.item.offset }}
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <h2 style="margin-bottom: 1%">
                            {{ $t("general.date_format") }}:
                        </h2>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="12">
                                <v-select
                                    auto-select-first
                                    :items="dateFormats"
                                    v-model="pendingDateFormat"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <h2 style="margin-bottom: 2%">
                            {{ $t("general.temperature") }}:
                        </h2>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="12">
                                <v-btn-toggle v-model="pendingTemperature">
                                    <v-btn value="celsius"> ºC </v-btn>
                                    <v-btn value="fahrenheit"> ºF </v-btn>
                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-card-text>

            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn
                    v-if="me.preferences"
                    text
                    @click="temperatureUnitsDialog = false"
                    rounded
                    >{{ $t("general.cancel") }}</v-btn
                >
                <v-btn color="primary" @click="applyChanges" rounded dense>
                    {{
                        me.preferences
                            ? this.$t("general.apply_changes")
                            : this.$t("general.save")
                    }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment";
import helper from "@/utils/helper.js";

export default {
    name: "TemperatureUnitsDialog",
    props: {
        openTemperatureUnitsDialog:{
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: "btn"
        }
    },
    data() {
        return {
            temperatureUnitsDialog: false,
            pendingTemperature: null,
            pendingTimezone: null,
            pendingDateFormat: null,
            dateFormats: [
                { text: "YYYY/MM/DD", value: "YYYY/MM/DD" },
                { text: "MM/DD/YYYY", value: "MM/DD/YYYY" },
                { text: "DD/MM/YYYY", value: "DD/MM/YYYY" }
            ]
        };
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        timezone() {
            let _preferences = this.me?.preferences;
            if (_preferences == null || _preferences == "") {
                return moment.tz.guess();
            } else {
                _preferences = JSON.parse(_preferences);
                if (_preferences?.timezone != null)
                    return _preferences?.timezone;
                else return moment.tz.guess();
            }
        },
        timezonesList() {
            //Country capitals timezone
            moment.tz.add(["China/Beijing|CST|-80|0101|1Lzm0"]);
            let _timezones = [];
            let timezones = moment.tz.names();
            // let myTimezone = moment.tz.guess();
            timezones.forEach(tz => {
                let zoneOffset = moment.tz(tz).utcOffset();
                let myZoneOffset = moment.tz(this.timezone).utcOffset();
                let offset = zoneOffset - myZoneOffset;
                offset = offset / 60;
                let _offset = "";
                if (offset > 0 && offset < 10) _offset = `UTC (+0${offset}:00)`;
                else if (offset >= 10) _offset = `UTC (+${offset}:00)`;
                else if (offset < 0 && offset > -10)
                    _offset = `UTC (-0${-1 * offset}:00)`;
                else if (offset <= -10) _offset = `UTC (-${-1 * offset}:00)`;
                else _offset = `UTC (00:00)`;

                _timezones.push({
                    zone: tz,
                    offset: _offset
                });
            });
            return _timezones;
        },
        temperature() {
            let _preferences = this.me?.preferences;
            if (_preferences == null || _preferences == "")
                return { symbol: "ºC", unit: "celsius" };
            else {
                _preferences = JSON.parse(_preferences);
                if (_preferences?.temperature == null)
                    return { symbol: "ºC", unit: "celsius" };
                else return _preferences?.temperature;
            }
        },
        dateFormat() {
            let _preferences = this.me?.preferences;
            if (_preferences == null || _preferences == "") return "YYYY/MM/DD";
            else {
                _preferences = JSON.parse(_preferences);
                if (_preferences?.dateFormat == null) return "YYYY/MM/DD";
                else return _preferences?.dateFormat;
            }
        },
        displayFormat() {
            switch(this.format) {
                case "icon":
                    return "icon";
                case "text":
                    return "text";
                case "list-item":
                    return "list-item";
                default:
                    return "btn";
            }
        },
    },
    watch: {
        temperatureUnitsDialog() {
            this.pendingTimezone = this.timezone;
            this.pendingTemperature = this.temperature.unit;
            this.pendingDateFormat = this.dateFormat;
        },
        openTemperatureUnitsDialog() {
            this.temperatureUnitsDialog = this.openTemperatureUnitsDialog;
        },
        timezone() {
            this.pendingTimezone = this.timezone;
        }
    },
    methods: {
        applyChanges() {
            const changes = {
                ...(this.changeTemperature() || {}),
                ...((this.pendingTimezone &&
                    this.changeTimezone(this.pendingTimezone)) ||
                    {}),
                ...((this.pendingDateFormat &&
                    this.changeDateFormat(this.pendingDateFormat)) ||
                    {})
            };
            helper.updateMyPreferences(changes);
            this.temperatureUnitsDialog = false;
        },
        changeTemperature: function() {
            let symbol = "ºC";
            if (this.pendingTemperature == "fahrenheit") symbol = "ºF";
            return {
                temperature: { symbol: symbol, unit: this.pendingTemperature }
            };
        },
        changeTimezone: function() {
            return { timezone: this.pendingTimezone };
        },
        changeDateFormat: function() {
            return { dateFormat: this.pendingDateFormat };
        }
    }
};
</script>
