<template>
    <!-- Change password dialog -->
    <v-dialog v-model="dialog" persistent max-width="500px" scrollable>
        <v-card>
            <v-card-title class="primary white--text">
                {{ $t("general.change_password") }}
            </v-card-title>

            <v-card-text class="pt-10 pb-0">
                <v-form v-model="valid" ref="changePasswordForm">
                    <v-text-field
                        id="oldPassword"
                        :label="$t('general.old_password')"
                        :type="showOldPassword ? 'text' : 'password'"
                        :rules="[rules.required]"
                        :error-messages="changePasswordError"
                        outlined
                        v-model="oldPassword"
                        :append-icon="
                            showOldPassword ? 'visibility' : 'visibility_off'
                        "
                        @click:append="showOldPassword = !showOldPassword"
                    />
                    <v-text-field
                        id="newPassword"
                        :label="$t('general.new_password')"
                        :type="showNewPassword ? 'text' : 'password'"
                        :rules="[rules.password]"
                        outlined
                        v-model="newPassword"
                        :append-icon="
                            showNewPassword ? 'visibility' : 'visibility_off'
                        "
                        @click:append="showNewPassword = !showNewPassword"
                        :hint="'*' + $t('general.hint')"
                        :persistent-hint="true"
                    />
                    <v-text-field
                        id="newPassword2"
                        :label="$t('general.confirm_password')"
                        :type="showNewPassword ? 'text' : 'password'"
                        :rules="[rules.required]"
                        outlined
                        v-model="newPasswordConfirm"
                        :append-icon="
                            showNewPassword ? 'visibility' : 'visibility_off'
                        "
                        @click:append="showNewPassword = !showNewPassword"
                        @keydown.enter="changePassword()"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-5">
                <v-spacer></v-spacer>
                <v-btn outlined rounded @click="closeChangePasswordDialog()">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    outlined
                    rounded
                    :disabled="
                        newPassword.length == 0 ||
                            newPassword !== newPasswordConfirm ||
                            !valid
                    "
                    :loading="isChangingPassword"
                    color="primary"
                    @click="changePassword()"
                    >{{ $t("general.confirm") }}</v-btn
                >
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "change-password-form",
    props: ["dialog"],
    components: {},

    apollo: {},

    data: function() {
        return {
            valid: false,
            isChangingPassword: false,
            oldPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            showOldPassword: "",
            showNewPassword: "",
            changePasswordError: "",

            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                password: value => {
                    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
                    return pattern.test(value) || this.$t("rules.min");
                }
            }
        };
    },
    computed: {},
    watch: {
        oldPassword() {
            this.changePasswordError = "";
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        // this.fetchMe();
    },
    methods: {
        // User Profile
        // fetchMe() {
        //     this.$store
        //         .dispatch("user/fetchMe")
        //         .then(() => {})
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },

        changePassword() {
            if (!this.$refs.changePasswordForm.validate()) {
                return;
            }

            this.isChangingPassword = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation changeMyPassword(
                            $input: ChangeMyPasswordInput!
                        ) {
                            changeMyPassword(input: $input) {
                                user {
                                    id
                                    firstName
                                    lastName
                                    email
                                    enabled2fa
                                    require2fa
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            oldPassword: this.oldPassword,
                            newPassword: this.newPassword
                        }
                    }
                })
                .then(() => {
                    this.closeChangePasswordDialog();

                    // show snackbar
                    const payload = {
                        color: "success",
                        message: this.$t("general.password_changed")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.changePasswordError = error.message;
                })
                .finally(() => {
                    this.isChangingPassword = false;
                });
        },

        closeChangePasswordDialog() {
            this.oldPassword = "";
            this.newPassword = "";
            this.newPasswordConfirm = "";
            this.changePasswordError = "";
            this.showOldPassword = false;
            this.showNewPassword = false;
            this.$refs.changePasswordForm.resetValidation();
            this.$emit("update:dialog", false);
        }
    }
};
</script>
