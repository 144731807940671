<template>
    <v-card elevation="5" outlined v-resize="onResize">
        <!-- V-CARD -->
        <v-card-text class="pb-3 pr-3">
            <!-- filter menu -->
            <div class="d-flex justify-end">
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display')"
                    :choices="headerChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                >
                </FilterMenu>
            </div>
            <v-data-table
                :headers="dynamicHeaders"
                :items="tenantPartners.edges"
                item-key="node.id"
                :loading-text="$t('loading.loading1')"
                :loading="$apollo.queries.tenantPartners.loading"
                hide-default-footer
                disable-pagination
                disable-sort
                :mobile-breakpoint="
                    screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                "
                loader-height="2"
                :no-data-text="$t('loading.no_data')"
                fixed-header
                :height="tableHeight"
                @click:row="item => this.$emit('click:row', item)"
                class="row-pointer"
            >
                <!-- Notifications Column -->
                <template v-slot:item.node.notificationEnabled="{ item }">
                    <v-icon
                        v-if="item.node.notificationEnabled == true"
                        color="success"
                        >check_circle</v-icon
                    >
                    <v-icon color="grey lighten-2" v-else>check_circle</v-icon>
                </template>

                <!-- infinit loading trigger -->
                <template slot="body.append">
                    <tr>
                        <td
                            :colspan="dynamicHeaders.length"
                            class="text-center"
                        >
                            <v-btn
                                v-if="hasMoreData"
                                v-intersect="onLoadMoreTriggerIntersect"
                                :disabled="!hasMoreData"
                                :loading="
                                    $apollo.queries.tenantPartners.loading
                                "
                                plain
                                class="mb-5"
                                @click="loadMore"
                            >
                                {{ $t("loading.load_more") }}
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>

        <!-- infinit loading trigger -->
        <!-- <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.tenantPartners.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                Load more
            </v-btn>
        </v-card-actions> -->
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";

export default {
    name: "CarrierTable",
    components: { FilterMenu },
    props: {
        carrierFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $referenceNumberIcontains: String
                    $partnerType: String
                    $carrierType: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        referenceNumber_Icontains: $referenceNumberIcontains
                        partnerType: $partnerType
                        carrierType: $carrierType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                description
                                partnerType
                                carrierType
                                carrier {
                                    id
                                    code
                                    name
                                    carrierType
                                }
                                referenceNumber
                                openTrips
                                totalTrips
                                notificationEnabled
                                tenantPartnerUserSet {
                                    totalCount
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.carrierFilter;
            },
            debounce: 800,
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantPartners,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    data() {
        return {
            loading: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            // --------- PARTNERS --------- \\
            tenantPartners: { edges: [] },
            tableHeight: 800,
            // --------- HEADERS --------- \\
            defaultHeaderChoices: [
                {
                    // for filter menu
                    code: "node.referenceNumber",
                    name: "Reference Number",
                    tag: "headers.reference_number",
                    enabled: true,
                    order: 0,
                    // for table header
                    text: "Reference Number",
                    align: "left",
                    value: "node.referenceNumber"
                },
                {
                    // for filter menu
                    code: "node.name",
                    name: "Partner Name",
                    tag: "headers.partner_name",
                    enabled: true,
                    order: 0,
                    // for table header
                    text: "Partner Name",
                    align: "left",
                    value: "node.name"
                },
                {
                    // for filter menu
                    code: "node.carrierType",
                    name: "Carrier Type",
                    tag: "headers.carrier_type",
                    enabled: true,
                    order: 0,
                    // for table header
                    text: "Carrier Type",
                    align: "left",
                    value: "node.carrierType"
                },
                {
                    // for filter menu
                    code: "node.openTrips",
                    name: "Open Trips",
                    tag: "headers.open_trips1",
                    enabled: true,
                    order: 0,
                    // for table header
                    text: "Open Trips",
                    align: "left",
                    value: "node.openTrips"
                },
                {
                    // for filter menu
                    code: "node.totalTrips",
                    name: "Total Trips",
                    tag: "headers.total_trips",
                    enabled: true,
                    order: 0,
                    // for table header
                    text: "Total Trips",
                    align: "left",
                    value: "node.totalTrips"
                },
                {
                    // for filter menu
                    code: "node.notificationEnabled",
                    name: "Notifications Enabled",
                    tag: "headers.notifications_enabled",
                    enabled: true,
                    order: 0,
                    //for table header
                    text: "Notifications Enabled",
                    align: "center",
                    value: "node.notificationEnabled"
                },
                {
                    // for filter menu
                    code: "",
                    name: "Users",
                    tag: "home.users",
                    enabled: true,
                    order: 0,
                    //for table header
                    text: "Users",
                    align: "right",
                    value: "node.tenantPartnerUserSet.totalCount"
                }
            ],
            headerChoices: null
        };
    },
    watch: {},
    mounted() {
        this.$watch(
            "headerChoices",
            function(value) {
                helper.updateMyPreferences({
                    carrierTableHeaders: value
                });
            },
            { deep: true }
        );
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        hasMoreData() {
            return this.tenantPartners?.pageInfo?.hasNextPage;
        },
        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            _headers.push({
                text: "",
                align: "right",
                value: "headerFilter",
                width: "1px",
                sortable: false
            });

            return _headers;
        }
    },
    created() {
        this.headerChoices = helper.getTableHeaders(
            "carrierTableHeaders",
            this.defaultHeaderChoices
        );
    },
    methods: {
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 145 + "px";
            });
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (
                this.shouldLoadMore &&
                this.tenantPartners?.pageInfo?.hasNextPage
            ) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.tenantPartners?.pageInfo?.hasNextPage) {
                this.$apollo.queries.tenantPartners.fetchMore({
                    variables: {
                        after: this.tenantPartners.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
