<template>
    <v-container fluid class="pa-4">
        <v-row dense >
            <v-col cols="12">
                <DashboardKpis></DashboardKpis>
            </v-col>
            <v-col cols="12">
                <TripOverview ></TripOverview>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DashboardKpis from "@/components/tenants/statistics/DashboardKpis.vue";
import TripOverview from "@/components/tenants/statistics/TripOverview.vue";
//import FilterKpis from "@/components/tenants/statistics/FilterKpis.vue";

export default {
    title: "Home",
    components: {
        DashboardKpis, TripOverview
    },
    data() {
        return {
            filterChoices:[]
        }
    },
    created() {},
    computed: {},
    watch: {},
    methods: {}

    
};
</script>
