<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <v-card elevation="1" class="mb-5 pb-0" rounded="xl">
                <v-card-title class="background2 py-3">
                    <v-row>
                        <v-col cols="auto" class="mr-2">
                            <v-icon>query_stats</v-icon>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{ $t("headers.timeline_overview") }}</span>
                        </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                    <ExportTripTrackerDialog
                        :selectedFreightId.sync="tripFreight"
                        :selectedTrackerId.sync="trackerId"
                        :startTime="chartStartTime"
                        :endTime="chartEndTime"
                        :trip="trip"
                        :selectedTrackerSerialNumber="
                            selectedTrackerSerialNumber
                        "
                    ></ExportTripTrackerDialog>
                </v-card-title>
                <v-card-text class="px-0 py-0">
                    <v-row dense class="">
                        <v-col cols="12">
                            <v-row dense class="px-8 mt-3 d-flex align-center">
                                <v-col cols="3">
                                    <v-autocomplete
                                        v-model="tripFreight"
                                        :items="trip.tripFreightSet.edges"
                                        :no-data-text="
                                            $t('loading.no_available')
                                        "
                                        auto-select-first
                                        item-text="node.referenceNumber"
                                        item-value="node.id"
                                        hide-details
                                        :label="$t('headers.freight') + '*'"
                                        dense
                                        color="primary"
                                        :disabled="freight ? true : false"
                                        outlined
                                        attach
                                    >
                                        <template v-slot:selection="data">
                                            <span
                                                v-if="
                                                    data.item.node
                                                        .containerNumber &&
                                                        trip.transportMode.toLowerCase() ===
                                                            'sea'
                                                "
                                                >{{
                                                    data.item.node
                                                        .containerNumber
                                                }}</span
                                            >
                                            <span
                                                v-else-if="
                                                    data.item.node
                                                        .airWaybillNumber &&
                                                        trip.transportMode.toLowerCase() ===
                                                            'air'
                                                "
                                                >{{
                                                    data.item.node
                                                        .airWaybillNumber
                                                }}</span
                                            >
                                            <span v-else
                                                >({{
                                                    data.item.node
                                                        .referenceNumber
                                                }})</span
                                            >
                                            <!-- {{
                                                data.item.node.containerNumber && trip.transportMode.toLowerCase() === 'sea' ? data.item.node.containerNumber :
                                                data.item.node.airWaybillNumber && trip.transportMode.toLowerCase() === 'air' ? data.item.node.airWaybillNumber : data.item.node.referenceNumber
                                            }} -->
                                        </template>
                                        <template v-slot:item="data">
                                            <v-row class="ma-0 body-2" dense>
                                                <v-col
                                                    cols="6"
                                                    class="text-truncate pl-0 ml-0"
                                                    v-if="
                                                        data.item.node
                                                            .containerNumber &&
                                                            trip.transportMode.toLowerCase() ===
                                                                'sea'
                                                    "
                                                >
                                                    {{
                                                        data.item.node
                                                            .containerNumber
                                                    }}
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    class="text-truncate pl-0 ml-0"
                                                    v-else-if="
                                                        data.item.node
                                                            .airWaybillNumber &&
                                                            trip.transportMode.toLowerCase() ===
                                                                'air'
                                                    "
                                                >
                                                    {{
                                                        data.item.node
                                                            .airWaybillNumber
                                                    }}
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    v-if="
                                                        data.item.node
                                                            .referenceNumber
                                                    "
                                                >
                                                    <span
                                                        class="grey--text mr-1"
                                                        ><i
                                                            >({{
                                                                data.item.node
                                                                    .referenceNumber
                                                            }})</i
                                                        ></span
                                                    >
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="3">
                                    <v-autocomplete
                                        auto-select-first
                                        v-model="trackerId"
                                        :items="trackers"
                                        item-text="serialNumber"
                                        item-value="id"
                                        :label="$t('headers.tracker')"
                                        hide-details
                                        dense
                                        color="primary"
                                        outlined
                                        :disabled="trackers.length < 1"
                                        :no-data-text="$t('loading.no_tracker')"
                                        :hide-no-data="false"
                                        attach
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="auto">
                                    <v-autocomplete
                                        v-model="selectedSensors"
                                        :items="sensorChoices"
                                        multiple
                                        small-chips
                                        hide-details
                                        :label="$t('trips.sensor')"
                                        dense
                                        color="primary"
                                        outlined
                                        item-text="text"
                                        item-value="value"
                                        :class="
                                            screenSize === 'xl'
                                                ? 'maxScreenWidth'
                                                : 'maxWidth'
                                        "
                                        :disabled="!showCharts && !trackerId"
                                        attach
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="px-6">
                            <LoadingBar v-if="isLoadingChart"></LoadingBar>
                            <SummaryTimelineChart
                                v-if="!isLoadingChart"
                                :minTemperature="minTemperature"
                                :maxTemperature="maxTemperature"
                                :minHumidity="minHumidity"
                                :maxHumidity="maxHumidity"
                                :minLight="minLight"
                                :maxLight="maxLight"
                                :minShock="minShock"
                                :maxShock="maxShock"
                                :startTime="chartStartTime"
                                :endTime="chartEndTime"
                                :eventsLocations="eventsLocation"
                                :alertTrigger="tripAlertTriggers"
                                :tripAlert="tripAlert"
                                :temperatureSeries="mergeSensorData.temperature"
                                :probeTemperaturesSeries="
                                    mergeSensorData.probeTemperature
                                "
                                :externalTemperaturesSeries="
                                    mergeSensorData.externalTemperature
                                "
                                :lightSeries="mergeSensorData.light"
                                :humiditySeries="mergeSensorData.humidity"
                                :shockSeries="mergeSensorData.shock"
                                :selected="selectedSensors"
                                :merge="mergeSensorData"
                            />
                        </v-col>
                    </v-row>
                    <v-expansion-panels
                        v-model="openPanels"
                        accordion
                        full-width
                        multiple
                        class="rounded-b-xl "
                        flat
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                @click.prevent="onExpansionPanelClick($event)"
                            >
                                <template v-slot:default="{ open }">
                                    <v-row dense :class="open">
                                        <v-col cols="auto">
                                            <span class="font-weight-bold">{{
                                                open
                                                    ? $t("general.see_less")
                                                    : $t("general.see_more")
                                            }}</span>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-tabs
                                    v-model="tab"
                                    background-color="transparent"
                                    grow
                                    ref="tabs"
                                >
                                    <v-tab key="timeline" href="#tab-timeline"
                                        ><span>
                                            {{ $t("headers.timeline_history") }}
                                        </span></v-tab
                                    >
                                    <v-tab key="geofence" href="#tab-geofence"
                                        ><span>{{
                                            $t("headers.geofence_history")
                                        }}</span></v-tab
                                    >
                                </v-tabs>
                                <v-divider></v-divider>
                                <v-tabs-items
                                    v-model="tab"
                                    :style="`height=200px`"
                                >
                                    <v-tab-item value="tab-timeline">
                                        <v-row justify="end">
                                            <v-col cols="auto" class="mr-3">
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row
                                                    dense
                                                    class="pl-8 mt-3 mb-2 d-flex align-center"
                                                >
                                                    <v-col cols="auto">
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{
                                                                    on
                                                                }"
                                                            >
                                                                <v-btn
                                                                    v-on="on"
                                                                    small
                                                                    @click="
                                                                        sortDescTimeline = !sortDescTimeline
                                                                    "
                                                                    icon
                                                                    class="mr-3 primary elevation-2"
                                                                    outlined
                                                                >
                                                                    <v-icon
                                                                        v-if="
                                                                            sortDescTimeline
                                                                        "
                                                                        color="white"
                                                                        >arrow_downward</v-icon
                                                                    >
                                                                    <v-icon
                                                                        color="white"
                                                                        v-else
                                                                        >arrow_upward</v-icon
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span
                                                                >Sort
                                                                {{
                                                                    sortDescTimeline
                                                                        ? "ascending"
                                                                        : "descending"
                                                                }}</span
                                                            >
                                                        </v-tooltip>
                                                    </v-col>
                                                    <v-col
                                                        cols="2"
                                                        lg="auto"
                                                        md="2"
                                                        sm="2"
                                                        xs="2"
                                                        class="d-flex justify-center align-center"
                                                    >
                                                        <v-switch
                                                            :disabled="
                                                                !trackerId
                                                            "
                                                            v-model="showCharts"
                                                            :label="
                                                                $t(
                                                                    'headers.charts'
                                                                )
                                                            "
                                                            color="primary"
                                                            class="mt-n1"
                                                            hide-details
                                                        ></v-switch>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="py-0 mb-1">
                                                    <v-col>
                                                        <LoadingBar
                                                            v-if="
                                                                $apollo.queries
                                                                    .tripFreightTimelines
                                                                    .loading
                                                            "
                                                        ></LoadingBar>
                                                        <v-card flat v-else>
                                                            <v-timeline
                                                                dense
                                                                align-top
                                                            >
                                                                <div
                                                                    v-for="(item,
                                                                    i) in timelineEvents"
                                                                    :key="i"
                                                                >
                                                                    <v-timeline-item
                                                                        :color="
                                                                            getEventColor(
                                                                                item
                                                                                    .node
                                                                                    .eventType
                                                                            )
                                                                        "
                                                                    >
                                                                        <template
                                                                            v-slot:icon
                                                                        >
                                                                            <v-icon
                                                                                small
                                                                                dark
                                                                            >
                                                                                {{
                                                                                    getEventIcon(
                                                                                        item
                                                                                            .node
                                                                                            .eventType
                                                                                    )
                                                                                }}
                                                                            </v-icon>
                                                                        </template>
                                                                        <v-card
                                                                            flat
                                                                        >
                                                                            <v-row
                                                                                class="px-1"
                                                                            >
                                                                                <v-col
                                                                                    cols="2"
                                                                                >
                                                                                    <strong
                                                                                        :class="
                                                                                            isFutureDate(
                                                                                                item
                                                                                                    .node
                                                                                                    .eventTime
                                                                                            )
                                                                                                ? 'grey--text'
                                                                                                : ''
                                                                                        "
                                                                                    >
                                                                                        {{
                                                                                            getDate(
                                                                                                item
                                                                                                    .node
                                                                                                    .eventTime
                                                                                            )
                                                                                        }}
                                                                                    </strong>
                                                                                    <div
                                                                                        :class="
                                                                                            isFutureDate(
                                                                                                item
                                                                                                    .node
                                                                                                    .eventTime
                                                                                            )
                                                                                                ? 'grey--text'
                                                                                                : ''
                                                                                        "
                                                                                    >
                                                                                        {{
                                                                                            getTime(
                                                                                                item
                                                                                                    .node
                                                                                                    .eventTime
                                                                                            )
                                                                                        }}
                                                                                    </div>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="8"
                                                                                >
                                                                                    <div
                                                                                        :class="
                                                                                            isFutureDate(
                                                                                                item
                                                                                                    .node
                                                                                                    .eventTime
                                                                                            )
                                                                                                ? 'grey--text'
                                                                                                : ''
                                                                                        "
                                                                                        v-html="
                                                                                            getEventTitle(
                                                                                                item.node
                                                                                            )
                                                                                        "
                                                                                    ></div>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-card>
                                                                    </v-timeline-item>

                                                                    <v-timeline-item
                                                                        hide-dot
                                                                        v-if="
                                                                            !isFutureDate(
                                                                                item
                                                                                    .node
                                                                                    .eventTime
                                                                            ) &&
                                                                                showCharts
                                                                        "
                                                                    >
                                                                        <LoadingBar
                                                                            v-if="
                                                                                isLoadingChart
                                                                            "
                                                                        ></LoadingBar>
                                                                        <div
                                                                            class="chartContainer"
                                                                            v-else
                                                                        >
                                                                            <TripFreightTimelineChart
                                                                                v-if="
                                                                                    trackerId !==
                                                                                        null &&
                                                                                        trackerId !==
                                                                                            undefined &&
                                                                                        showCharts
                                                                                "
                                                                                :minTemperature="
                                                                                    minTemperature
                                                                                "
                                                                                :maxTemperature="
                                                                                    maxTemperature
                                                                                "
                                                                                :minHumidity="
                                                                                    minHumidity
                                                                                "
                                                                                :maxHumidity="
                                                                                    maxHumidity
                                                                                "
                                                                                :minLight="
                                                                                    minLight
                                                                                "
                                                                                :maxLight="
                                                                                    maxLight
                                                                                "
                                                                                :minShock="
                                                                                    minShock
                                                                                "
                                                                                :maxShock="
                                                                                    maxShock
                                                                                "
                                                                                :chartStartTime="
                                                                                    chartStartTime
                                                                                "
                                                                                :chartEndTime="
                                                                                    chartEndTime
                                                                                "
                                                                                :currentStartTime="
                                                                                    getCurrentStartTime(
                                                                                        i
                                                                                    )
                                                                                "
                                                                                :currentEndTime="
                                                                                    getCurrentEndTime(
                                                                                        i
                                                                                    )
                                                                                "
                                                                                :alertTrigger="
                                                                                    tripAlertTriggers
                                                                                "
                                                                                :tripAlert="
                                                                                    showCurrentEventAlertTime(
                                                                                        i,
                                                                                        item
                                                                                            .node
                                                                                            .eventTime,
                                                                                        tripAlert
                                                                                    )
                                                                                "
                                                                                :temperatureSeries="
                                                                                    mergeSensorData.temperature
                                                                                "
                                                                                :probeTemperaturesSeries="
                                                                                    mergeSensorData.probeTemperature
                                                                                "
                                                                                :externalTemperaturesSeries="
                                                                                    mergeSensorData.externalTemperature
                                                                                "
                                                                                :lightSeries="
                                                                                    mergeSensorData.light
                                                                                "
                                                                                :humiditySeries="
                                                                                    mergeSensorData.humidity
                                                                                "
                                                                                :shockSeries="
                                                                                    mergeSensorData.shock
                                                                                "
                                                                                :selected="
                                                                                    selectedSensors
                                                                                "
                                                                            ></TripFreightTimelineChart>
                                                                        </div>
                                                                    </v-timeline-item>
                                                                </div>
                                                            </v-timeline>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-tab-item>
                                    <v-tab-item value="tab-geofence">
                                        <v-row justify="end">
                                            <v-col cols="auto" class="mr-3">
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row
                                                    dense
                                                    class="pl-8 mt-3 mb-2  d-flex align-center"
                                                >
                                                    <v-col cols="auto">
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{
                                                                    on
                                                                }"
                                                            >
                                                                <v-btn
                                                                    v-on="on"
                                                                    small
                                                                    @click="
                                                                        sortDescGeofence = !sortDescGeofence
                                                                    "
                                                                    icon
                                                                    class="mr-3 primary elevation-2"
                                                                    outlined
                                                                >
                                                                    <v-icon
                                                                        v-if="
                                                                            sortDescGeofence
                                                                        "
                                                                        color="white"
                                                                        >arrow_downward</v-icon
                                                                    >
                                                                    <v-icon
                                                                        color="white"
                                                                        v-else
                                                                        >arrow_upward</v-icon
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span
                                                                >Sort
                                                                {{
                                                                    sortDescGeofence
                                                                        ? "ascending"
                                                                        : "descending"
                                                                }}</span
                                                            >
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="py-0 mb-1">
                                                    <v-col>
                                                        <LoadingBar
                                                            v-if="
                                                                $apollo.queries
                                                                    .tripFreightGeofenceEvents
                                                                    .loading
                                                            "
                                                        ></LoadingBar>
                                                        <v-card flat v-else>
                                                            <v-timeline
                                                                dense
                                                                align-top
                                                            >
                                                                <div
                                                                    v-for="(item,
                                                                    i) in geofenceEvents"
                                                                    :key="i"
                                                                >
                                                                    <v-timeline-item
                                                                        :color="
                                                                            getEventColor(
                                                                                item.eventType
                                                                            )
                                                                        "
                                                                        ><template
                                                                            v-slot:icon
                                                                        >
                                                                            <v-icon
                                                                                small
                                                                                dark
                                                                                >{{
                                                                                    getEventIcon(
                                                                                        item.eventType
                                                                                    )
                                                                                }}</v-icon
                                                                            ></template
                                                                        >
                                                                        <v-card
                                                                            flat
                                                                        >
                                                                            <v-row
                                                                                class="px-1"
                                                                            >
                                                                                <v-col
                                                                                    cols="2"
                                                                                    ><strong
                                                                                        :class="
                                                                                            isFutureDate(
                                                                                                item.eventTime
                                                                                            )
                                                                                                ? 'grey--text'
                                                                                                : ''
                                                                                        "
                                                                                        >{{
                                                                                            getDate(
                                                                                                item.eventTime
                                                                                            )
                                                                                        }}</strong
                                                                                    >
                                                                                    <div
                                                                                        :class="
                                                                                            isFutureDate(
                                                                                                item.eventTime
                                                                                            )
                                                                                                ? 'grey--text'
                                                                                                : ''
                                                                                        "
                                                                                    >
                                                                                        {{
                                                                                            getTime(
                                                                                                item.eventTime
                                                                                            )
                                                                                        }}
                                                                                    </div></v-col
                                                                                ><v-col
                                                                                    cols="8"
                                                                                >
                                                                                    <div
                                                                                        class="text-no-wrap"
                                                                                    >
                                                                                        <div
                                                                                            class="d-inline"
                                                                                        >
                                                                                            <strong
                                                                                                >{{
                                                                                                    item.location
                                                                                                }}</strong
                                                                                            >
                                                                                        </div>
                                                                                        <div
                                                                                            class="d-inline"
                                                                                            v-if="
                                                                                                item.hasEnvironmentData
                                                                                            "
                                                                                        >
                                                                                            <b
                                                                                                >(</b
                                                                                            ><strong
                                                                                                v-if="
                                                                                                    item.temperature
                                                                                                "
                                                                                                >{{
                                                                                                    item.temperature
                                                                                                }}
                                                                                                ºC</strong
                                                                                            >
                                                                                            <strong
                                                                                                v-if="
                                                                                                    item.humidity
                                                                                                "
                                                                                            >
                                                                                                {{
                                                                                                    item.humidity
                                                                                                }}%</strong
                                                                                            ><b
                                                                                                >)</b
                                                                                            >
                                                                                        </div>
                                                                                        <div>
                                                                                            <v-chip
                                                                                                small
                                                                                                class="mr-1"
                                                                                                v-for="(email,
                                                                                                i) in item.emails"
                                                                                                :key="
                                                                                                    i
                                                                                                "
                                                                                                >{{
                                                                                                    email
                                                                                                }}</v-chip
                                                                                            >
                                                                                        </div>
                                                                                        <div>
                                                                                            <span
                                                                                                class="grey--text"
                                                                                            >
                                                                                                {{
                                                                                                    getEventSubtitle(
                                                                                                        item.eventType
                                                                                                    )
                                                                                                }}</span
                                                                                            >
                                                                                        </div>
                                                                                    </div></v-col
                                                                                ></v-row
                                                                            ></v-card
                                                                        ></v-timeline-item
                                                                    >
                                                                </div>
                                                            </v-timeline>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
        </div>
    </v-sheet>
</template>
<script>
import TripFreightTimelineChart from "@/components/trips/details/TripFreightTimelineChart.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import moment from "moment";
import LoadingBar from "@/components/base/LoadingBar.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import ExportTripTrackerDialog from "@/components/trips/other/ExportTripTrackerDialog.vue";
import SummaryTimelineChart from "@/components/trips/other/SummaryTimelineChart.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        trip: {
            type: Object
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        freight: {
            type: Object,
            required: false
        }
    },
    components: {
        TripFreightTimelineChart,
        LoadingBar,
        NoPermission,
        ExportTripTrackerDialog,
        SummaryTimelineChart
    },
    apollo: {
        trackerDeviceTimeRange: {
            query: gql`
                query trackerDeviceTimeRange($trackerId: ID, $freightId: ID) {
                    trackerDeviceTimeRange(
                        freightId: $freightId
                        trackerId: $trackerId
                    )
                }
            `,
            variables() {
                return {
                    trackerId: this.trackerId ? this.trackerId : null,
                    freightId: this.tripFreight ? this.tripFreight : null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            result({ data }) {
                if (data?.trackerDeviceTimeRange) {
                    this.trackerDeviceTimeRange = JSON.parse(
                        data.trackerDeviceTimeRange
                    );
                }
            },
            debounce: 0,
            skip() {
                return !this.trackerId;
            }
        },
        trackerMergedSensorDatas: {
            query: gql`
                query TrackerMergedSensorDatas(
                    $trackerId: ID
                    $tripId: ID
                    $freightId: ID
                    $orderBy: String
                ) {
                    trackerMergedSensorDatas(
                        tracker: $trackerId
                        tripId: $tripId
                        freightId: $freightId
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                id
                                deviceTime
                                receiveTime
                                light
                                shock
                                humidity
                                temperature
                                externalTemperature
                                probeTemperature
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    trackerId: this.trackerId ? this.trackerId : null,
                    freightId: this.tripFreight ? this.tripFreight : null,
                    orderBy: "deviceTime"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerMergedSensorDatas,
            skip() {
                return !this.trackerId;
            }
        },
        tripFreightTimelines: {
            query: gql`
                query tripFreightTimelines($tripFreight: ID!, $trackerId: ID, $eventSourceTypeNotequal: String) {
                    tripFreightTimelines(
                        tripFreight: $tripFreight
                        trackerId: $trackerId
                        eventSourceTypeNotequal: $eventSourceTypeNotequal
                    ) {
                        edges {
                            node {
                                id
                                eventSource
                                eventType
                                eventTime
                                eventName
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    tripFreight: this.tripFreight,
                    trackerId: this.trackerId,
                    eventSourceTypeNotequal:"city_location"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripFreightTimelines,
            skip() {
                return !(this.canAccess && this.tripFreight);
            }
        },
        tripFreightGeofenceEvents: {
            query: gql`
                query TripFreightTrackerGeofenceEvents(
                    $tripFreightTrackerTripFreight: ID
                    $tripFreightTrackerTracker: ID
                    $isNoisyEvent: Boolean
                ) {
                    tripFreightTrackerGeofenceEvents(
                        tripFreightTracker_TripFreight: $tripFreightTrackerTripFreight
                        tripFreightTracker_Tracker: $tripFreightTrackerTracker
                        isNoisyEvent: $isNoisyEvent
                    ) {
                        edges {
                            node {
                                eventTime
                                eventType
                                id
                                tenantLocation {
                                    id
                                    fullAddress
                                    name
                                }
                                trackerMergedSensorData {
                                    id
                                    temperature
                                    humidity
                                }
                                carrierAirport {
                                    id
                                    name
                                }
                                carrierSeaport {
                                    id
                                    name
                                }
                                tripAlerts {
                                    alertedEmails {
                                        emails
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tripFreightTrackerTripFreight: this.tripFreight,
                    tripFreightTrackerTracker: this.trackerId,
                    isNoisyEvent: false
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripFreightTrackerGeofenceEvents,
            skip() {
                let open = this.openPanel;
                return !(this.canAccess && this.tripFreight && open);
            }
        },
        tripAlertSet: {
            query: gql`
                query trip(
                    $tripId: ID!
                    $tripFreight: ID
                    $trackerMergedSensorDataTracker: ID
                ) {
                    trip(id: $tripId) {
                        id
                        tripAlertSet(
                            alertTime_Isnull: false
                            tripFreight: $tripFreight
                            trackerMergedSensorData_Tracker: $trackerMergedSensorDataTracker
                        ) {
                            edges {
                                node {
                                    id
                                    alertTime
                                    alertJson
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tripId: this.trip?.id,
                    trackerMergedSensorDataTracker: this.trackerId
                        ? this.trackerId
                        : null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.trip?.tripAlertSet,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.canAccess;
            }
        },
        tripFreightAlertRules: {
            query: gql`
                query tripFreightAlertRules($tripFreight: ID) {
                    tripFreightAlertRules(tripFreight: $tripFreight) {
                        edges {
                            node {
                                id
                                tenantAlertRule {
                                    id
                                    name
                                    tenantAlertRuleTriggerSet {
                                        edges {
                                            node {
                                                id
                                                triggerJson
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tripFreight: this.tripFreight
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripFreightAlertRules,
            debounce: 0,
            skip() {
                return !(this.canAccess && this.tripFreight);
            }
        }
    },
    data() {
        return {
            tab: null,
            panel: [],
            showCharts: false,
            tripFreight: null,
            trackerId: null,
            selectedTrackerSerialNumber: null,
            events: ["tracker", "vessel", "carrier", "manual"],
            defaultSelectedSensors: [
                { text: this.$t("general.temperature"), value: "temperature" },
                { text: this.$t("general.light"), value: "light" }
            ],
            selectedSensors: ["temperature", "light"],
            sortDescTimeline: false,
            sortDescGeofence: false,
            openPanel: true,
            openPanels: [0],
            loadingOverview: true,
            trackerDeviceTimeRange: []
        };
    },
    computed: {
        screenSize() {
            let width = this.$vuetify.breakpoint.width;
            let size = "";
            if (width < 600) {
                return "xs";
            } else if (width < 960) {
                return "sm";
            } else if (width < 1264) {
                return "md";
            } else if (width < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        tripAlertTriggers() {
            let _tenantAlerts = [];
            this.tripFreightAlertRules?.edges
                .filter(element => {
                    return element?.node;
                })
                .forEach(element => {
                    let alertName = element?.node?.tenantAlertRule?.name;
                    element?.node?.tenantAlertRule?.tenantAlertRuleTriggerSet?.edges.forEach(
                        item => {
                            _tenantAlerts.push({
                                id: item.node.id,
                                name: alertName,
                                triggerJson: item.node.triggerJson
                            });
                        }
                    );
                });
            return _tenantAlerts || [];
        },
        tripAlert() {
            let alert = [];
            this.tripAlertSet?.edges.forEach(element => {
                alert.push({
                    deviceTime: element.node.deviceTime
                        ? this.convertDateTimeTimezoneToMilliseconds(element.node.deviceTime)
                        : this.convertDateTimeTimezoneToMilliseconds(element.node.alertTime),
                    type: this.alertType(element.node.alertJson)
                });
            });
            return alert;
        },
        me() {
            return helper.me();
        },
        isLoadingChart() {
            return this.$apollo.queries.trackerMergedSensorDatas.loading;
        },
        mergeSensorData() {
            const sensorTypes = [
                "light",
                "shock",
                "humidity",
                "temperature",
                "externalTemperature",
                "probeTemperatures"
            ];
            let object = {};
            for (const sensor of sensorTypes) {
                if (!this.$apollo.queries.trackerMergedSensorDatas.loading) {
                    object[sensor] = this.sensorData(sensor);
                } else {
                    object[sensor] = [];
                }
            }
            return object;
        },
        chartStartTime() {
            let startTime = "";

            if (this.trackerDeviceTimeRange?.start_date) {
                startTime = this.trackerDeviceTimeRange?.start_date;
            } else {
                let now = moment()
                    .subtract(3, "hours")
                    .toISOString();

                startTime = this.getMinDate([
                    this.trip?.plannedDepartureDate,
                    now
                ]);
            }
            return this.updateDateTimeTimezone(startTime);
        },
        chartEndTime() {
            let endTime = "";
            // if (this.trip?.completedAt) {
            //     endTime = this.trip?.completedAt;
            // } else {
            if (this.trackerDeviceTimeRange?.end_date) {
                endTime = this.trackerDeviceTimeRange?.end_date;
            } else {
                let now = moment()
                    .add(3, "hours")
                    .toISOString();

                endTime = this.getMaxDate([this.trip?.plannedArrivalDate, now]);
            }
            return this.updateDateTimeTimezone(endTime);
        },
        sensorChoices() {
            let _choices = [
                { text: this.$t("general.temperature"), value: "temperature" },
                { text: this.$t("general.humidity"), value: "humidity" },
                { text: this.$t("general.light"), value: "light" },
                { text: this.$t("general.shock"), value: "shock" },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature"
                }
            ];

            // TODO: remove unsupported sensors based on the trackers_tracker last_xxxx_device_time

            return _choices;
        },
        timelineEvents() {
            let t = [];

            if (this.tripFreightTimelines?.edges) {
                t = JSON.parse(
                    JSON.stringify(this.tripFreightTimelines?.edges)
                );

                // // update time zone to UTC
                // t.forEach(element => {
                //     element.node.eventTime = this.updateDateTimeTimezone(
                //         element.node.eventTime
                //     );
                // });
            }
            // // if trip planned departure date exists, add it to the timeline
            // if (this.trip?.plannedDepartureDate) {
            //     t.push({
            //         node: {
            //             id: "plannedDepartureDate",
            //             eventTime: this.trip?.plannedDepartureDate,
            //             eventType: "planned_departure_date",
            //             eventSource: "trip",
            //             eventDescription: "Planned Departure Date"
            //         }
            //     });
            // }

            // if (this.trip?.plannedArrivalDate) {
            //     t.push({
            //         node: {
            //             id: "plannedArrivalDate",
            //             eventTime: this.trip?.plannedArrivalDate,
            //             eventType: "planned_arrival_date",
            //             eventSource: "trip",
            //             eventDescription: "Planned Arrival Date"
            //         }
            //     });
            // }

            // if (this.trip?.completedAt || this.trip?.plannedArrivalDate) {
            //     let arrivalDate = this.trip?.completedAt
            //         ? this.trip?.completedAt
            //         : this.trip?.plannedArrivalDate;
            //     let eventType = this.trip?.completedAt
            //         ? "completed_at"
            //         : "planned_arrival_date";

            //     t.push({
            //         node: {
            //             id: "arrivalDate",
            //             eventTime: arrivalDate,
            //             eventType: eventType,
            //             eventSource: "trip",
            //             eventDescription: "Arrival Date"
            //         }
            //     });
            // }

            // sort the timeline by event time
            t.sort((a, b) => {
                return this.sortDescTimeline
                    ? new Date(b.node.eventTime) - new Date(a.node.eventTime)
                    : new Date(a.node.eventTime) - new Date(b.node.eventTime);
            });
            return t;
        },
        geofenceEvents() {
            let g = [];

            if (this.tripFreightGeofenceEvents?.edges) {
                g = JSON.parse(
                    JSON.stringify(this.tripFreightGeofenceEvents?.edges)
                );
            }
            g = g.map(item => {
                if (item) {
                    let _emails = [];
                    item.node?.tripAlerts?.forEach(alert => {
                        alert.alertedEmails?.forEach(email => {
                            _emails = _emails.concat(JSON.parse(email.emails));
                        });
                    });
                    _emails = [...new Set(_emails)];
                    let _item = {
                        eventTime: item.node.eventTime,
                        eventType: item.node.eventType,
                        id: item.node.id,
                        location: item.node.tenantLocation
                            ? item.node.tenantLocation.fullAddress ||
                              item.node.tenantLocation.name
                            : item.node.carrierAirport
                            ? item.node.carrierAirport.name
                            : item.node.carrierSeaport
                            ? item.node.carrierSeaport.name
                            : "",
                        hasEnvironmentData: item.node.trackerMergedSensorData
                            ? true
                            : false,
                        temperature: item.node.trackerMergedSensorData
                            ? item.node.trackerMergedSensorData.temperature
                            : "",
                        humidity: item.node.trackerMergedSensorData
                            ? item.node.trackerMergedSensorData.humidity
                            : "",
                        emails: _emails
                    };
                    item = _item;
                }
                return item;
            });

            g.sort((a, b) => {
                return this.sortDescGeofence
                    ? new Date(b.eventTime) - new Date(a.eventTime)
                    : new Date(a.eventTime) - new Date(b.eventTime);
            });
            return g;
        },
        trackers() {
            let _trackers = [];
            let freight = this.trip.tripFreightSet.edges.find(
                freight => freight.node.id === this.tripFreight
            );
            if (freight) {
                _trackers = freight.node.tripFreightTrackerSet.edges.map(
                    e => e.node.tracker
                );
            }
            return _trackers;
        },
        minTemperature() {
            let _min = 0;

            let temperature =
                this.mergeSensorData.temperature.map(item => item[1]) || [];

            let probeTemperature =
                this.mergeSensorData.probeTemperatures.map(item => item[1]) ||
                [];

            let externalTemperature =
                this.mergeSensorData.externalTemperature.map(item => item[1]) ||
                [];

            let min = [
                ...temperature,
                ...probeTemperature,
                ...externalTemperature
            ].filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            let alerts = this.tripAlertTriggers.map(x => {
                return JSON.parse(x?.triggerJson);
            });

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "temperature") {
                            if (
                                sensor.Condition == "in" ||
                                sensor.Condition == "out"
                            ) {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            });

            return _min - 5;
        },
        maxTemperature() {
            let _max = 0;

            let temperature =
                this.mergeSensorData.temperature.map(item => item[1]) || [];

            let probeTemperature =
                this.mergeSensorData.probeTemperatures.map(item => item[1]) ||
                [];

            let externalTemperature =
                this.mergeSensorData.externalTemperature.map(item => item[1]) ||
                [];

            let max = [
                ...temperature,
                ...probeTemperature,
                ...externalTemperature
            ].filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            let alerts = this.tripAlertTriggers.map(x => {
                return JSON.parse(x?.triggerJson);
            });

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "temperature") {
                            if (
                                sensor.Condition == "in" ||
                                sensor.Condition == "out"
                            ) {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            });

            return _max + 5;
        },
        minHumidity() {
            let _min = 0;

            let humidity =
                this.mergeSensorData.humidity.map(item => item[1]) || [];

            let min = humidity.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            let alerts = this.tripAlertTriggers.map(x => {
                return JSON.parse(x?.triggerJson);
            });

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "humidity") {
                            if (
                                sensor.Condition == "in" ||
                                sensor.Condition == "out"
                            ) {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            });

            return _min - 0.01;
        },
        maxHumidity() {
            let _max = 0;

            let humidity =
                this.mergeSensorData.humidity.map(item => item[1]) || [];

            let max = humidity.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            let alerts = this.tripAlertTriggers.map(x => {
                return JSON.parse(x?.triggerJson);
            });

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "humidity") {
                            if (
                                sensor.Condition == "in" ||
                                sensor.Condition == "out"
                            ) {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            });

            return _max + 0.01;
        },
        minLight() {
            let _min = 0;

            let light = this.mergeSensorData.light.map(item => item[1]) || [];

            let min = light.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            let alerts = this.tripAlertTriggers.map(x => {
                return JSON.parse(x?.triggerJson);
            });

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "light") {
                            if (
                                sensor.Condition == "in" ||
                                sensor.Condition == "out"
                            ) {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            });

            return _min - 1;
        },
        maxLight() {
            let _max = 0;

            let light = this.mergeSensorData.light.map(item => item[1]) || [];

            let max = light.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            let alerts = this.tripAlertTriggers.map(x => {
                return JSON.parse(x?.triggerJson);
            });

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "light") {
                            if (
                                sensor.Condition == "in" ||
                                sensor.Condition == "out"
                            ) {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            });
            return _max + 100;
        },
        minShock() {
            let _min = 0;

            let shock = this.mergeSensorData.shock.map(item => item[1]) || [];

            let min = shock.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            let alerts = this.tripAlertTriggers.map(x => {
                return JSON.parse(x?.triggerJson);
            });

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "shock") {
                            if (
                                sensor.Condition == "in" ||
                                sensor.Condition == "out"
                            ) {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            });

            return _min - 1;
        },
        maxShock() {
            let _max = 0;

            let shock = this.mergeSensorData.shock.map(item => item[1]) || [];

            let max = shock.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            let alerts = this.tripAlertTriggers.map(x => {
                return JSON.parse(x?.triggerJson);
            });

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "shock") {
                            if (
                                sensor.Condition == "in" ||
                                sensor.Condition == "out"
                            ) {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            });

            return _max + 10;
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                // console.log(this.organisationPermissions)
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE,
                        this.organisationPermissions
                    )
                )
                    return false;
            
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT_TIMELINE,
                        this.trip?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (
                    !this.hasPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE
                    ) &&
                    this.me?.tenant
                )
                    return false;
            }
            return true;
        },
        eventsLocation() {
            let geofence = [];
            //this.geofenceLocations = [];

            let _elements = this.timelineEvents.filter(event => {
                if (
                    event.node.eventType == "tracker_activate" ||
                    event.node.eventType == "geofence_enter" ||
                    event.node.eventType == "geofence_exit" ||
                    event.node.eventType == "flight_depart" ||
                    event.node.eventType == "flight_arrive"
                ) {
                    return event;
                }
            });
            //sortby eventTime
            _elements.sort((a, b) => {
                return new Date(a.node.eventTime) - new Date(b.node.eventTime);
            });

            let _elementsByEventName = _elements.reduce((result, element) => {
                const eventName = element.node.eventName;
                const eventType = element.node.eventType;
                const eventTime = element.node.eventTime;

                if (!result[eventName]) {
                    result[eventName] = [];
                }
                result[eventName].push({
                    eventType: eventType,
                    eventTime: eventTime,
                    eventName: eventName
                });

                return result;
            }, {});

            //separete the event by event type and for flight event sort by event time
            let _flight = [];
            let _flights = [];
            let _geofences = [];
            let geofences = [];

            //geofence events
            Object.keys(_elementsByEventName).forEach(key => {
                let _event = _elementsByEventName[key];
                _event.forEach(item => {
                    if (
                        item.eventType == "geofence_enter" ||
                        item.eventType == "geofence_exit" ||
                        item.eventType == "tracker_activate"
                    ) {
                        _geofences.push(item);
                    }
                });
            });

            //flight events
            Object.keys(_elementsByEventName).forEach(key => {
                let _event = _elementsByEventName[key];
                _event.forEach(item => {
                    if (
                        item.eventType == "flight_depart" ||
                        item.eventType == "flight_arrive"
                    ) {
                        _flight.push(item);
                    }
                });
            });

            //organize the geofence events in _geofences array
            _geofences.sort((a, b) => {
                return new Date(a.eventTime) - new Date(b.eventTime);
            });

            _geofences.forEach((item, index) => {
                if (
                    item.eventType == "geofence_enter" &&
                    _geofences[index + 1]?.eventType == "geofence_exit"
                ) {
                    geofences.push({
                        0: item,
                        1: _geofences[index + 1]
                    });
                } else if (
                    item.eventType == "tracker_activate" &&
                    _geofences[index + 1]?.eventType == "geofence_exit"
                ) {
                    //check if item event time is less than next event time
                    geofences.push({
                        0: {
                            eventType: "tracker_activate",
                            eventTime: item.eventTime,
                            eventName: _geofences[index + 1].eventName
                        },
                        1: _geofences[index + 1]
                    });
                } /* else if (
                    item.eventType == "geofence_exit" &&
                    _geofences[index - 1]?.eventType !== "geofence_enter"
                ) {
                    //check if item event time is greater than previous event time
                    if (
                        geofences[index - 1]?.eventTime !== undefined &&
                        geofences[index - 1]?.eventTime !== null
                    ) {
                        if (
                            new Date(item.eventTime) >
                            new Date(_geofences[index - 1].eventTime)
                        ) {
                            geofences.push({
                                0: item
                            });
                        }
                    } else {
                        geofences.push({
                            0: item
                        });
                    }
                } */
            });
            //organize the flight events in flights array
            _flight.forEach((item, index) => {
                if (item.eventType == "flight_depart") {
                    _flights.push({
                        0: item,
                        1: _flight[index + 1]
                    });
                }
            });

            let _events = [...geofences, ..._flights];
            _events.sort((a, b) => {
                return new Date(a[0].eventTime) - new Date(b[0].eventTime);
            });
           
            return _events;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
    },
    created() {
        this.selectedSensors =
            helper.getSelectedSensors() || this.selectedSensors;
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.formatDateTime = helper.formatDateTime;
        this.formatDateTimeWithoutTimezone =
            helper.formatDateTimeWithoutTimezone;
        this.relativeTime = helper.relativeTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.formatDateTimeIso = helper.formatDateTimeIso;
        this.convertDateTimeTimezoneToMilliseconds = helper.convertDateTimeTimezoneToMilliseconds;
    },
    watch: {
        selectedSensors: function(newValue, oldValue) {
            if (newValue.toString() !== oldValue.toString()) {
                helper.updateMyPreferences({ selectedSensors: newValue });
                this.$apollo.queries.trackerMergedSensorDatas.refetch();
            }
        },
        tripFreight: function(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.trackerId = this.trackers[0]?.id;
            }
        },
        trackerId: function(newValue, oldValue) {
            this.selectedTrackerSerialNumber = this.trackers.find(
                t => t.id === newValue
            )?.serialNumber;
            if (newValue === undefined || newValue === null) {
                this.showCharts = false;
            } else if (
                oldValue !== undefined &&
                oldValue !== null &&
                newValue !== oldValue
            ) {
                this.showCharts = false;
            } else {
                this.showCharts = false;
            }
        },
        openPanel: {
            handler: function(newValue) {
                if (newValue) {
                    this.$nextTick(() => {
                        this.$refs.chart?.chart?.reflow();
                    });
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.initialize();
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_timeline_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_timeline_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_timeline_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        sensorData(sensor) {
            let result = [];

            if (!this.trackerMergedSensorDatas?.edges) {
                return [];
            }

            this.trackerMergedSensorDatas?.edges?.forEach(device => {
                const _time = this.convertDateTimeTimezoneToMilliseconds(device.node?.deviceTime);

                if (
                    sensor === "temperature" ||
                    sensor === "externalTemperature" ||
                    sensor === "probeTemperature"
                ) {
                    if (device.node?.[sensor] != null) {
                        let value = parseFloat(device?.node?.[sensor]).toFixed(
                            2
                        );
                        result.push([_time, this.formatTemperature(value)]);
                    }
                } else {
                    if (device.node?.[sensor] != null) {
                        let value = parseFloat(device?.node?.[sensor]).toFixed(
                            2
                        );
                        result.push([_time, value]);
                    }
                }
            });

            return result;
        },
        getEvntbyName(event) {
            if (
                event.includes("flight_depart") ||
                event.includes("flight_arrive")
            ) {
                return "flight";
            }
        },
        onExpansionPanelClick() {
            //catch the class when the panel is open
            this.openPanel = !this.openPanel;
        },
        getCurrentGeofenceLocation(currentEvent, nextEvent) {
            /* case "geofence_exit":
                    title = "Exit <b>" + event.eventName + "</b>";
                    break;
                case "geofence_enter":
                    title = "Enter <b>" + event.eventName + "</b>";
                    break; */
        },
        getMinActivateDate(trackers) {
            let minDate = null;
            trackers.forEach(d => {
                if (!minDate || new Date(d.activatedDate) < new Date(minDate)) {
                    minDate = d.activatedDate;
                }
            });
            return minDate;
        },
        alertType(item) {
            let alert = [];
            let newObj = JSON.parse(item);

            if (newObj?.Location?.Condition) alert.push("location");

            if (newObj?.Sensor?.length > 0) {
                newObj.Sensor.forEach(item => {
                    alert.push(item.Type);
                });
            }
            return alert;
        },
        showCurrentEventAlertTime(index, currentEvent, alert) {
            let _currentTime = new Date(currentEvent).getTime();
            let _nextTime = new Date(this.getNextEventTime(index)).getTime();
            let eventAlert = [];

            if (alert.length > 0) {
                alert.forEach(item => {
                    let _alertTime = item.alertTime;
                    if (_alertTime >= _currentTime && _alertTime < _nextTime) {
                        eventAlert.push(item);
                    }
                });
            }
            return eventAlert;
        },
        initialize() {
            this.$nextTick(() => {
                if (this.freight?.id) {
                    this.tripFreight = this.freight?.id;
                } else
                    this.tripFreight = this.trip?.tripFreightSet?.edges[0]?.node?.id;
                /*  this.trackerId = this.trackers[0]?.id; */
            });
        },
        getNextEventTime(index) {
            let dateNow = new Date();
            return index < this.timelineEvents.length - 1
                ? this.updateDateTimeTimezone(
                      this.timelineEvents[index + 1].node.eventTime
                  )
                : this.formatDateTime(dateNow);
        },
        getCurrentStartTime(index) {
            // return current event time if sortDescTimeline is false, else return previous event time
            return !this.sortDescTimeline
                ? this.updateDateTimeTimezone(
                      this.timelineEvents[index].node.eventTime
                  )
                : this.getNextEventTime(index);
        },
        getCurrentEndTime(index) {
            // return next event time if sortDescTimeline is false, else return current event time
            return !this.sortDescTimeline
                ? this.getNextEventTime(index)
                : this.updateDateTimeTimezone(
                      this.timelineEvents[index].node.eventTime
                  );
        },
        getDate(date) {
            return this.formatDateTime(date)?.slice(0, 10);
        },
        getTime(date) {
            return this.formatDateTime(date)?.slice(11, 16);
        },
        isFutureDate(date) {
            return new Date(date) > new Date();
        },
        getMinDate(dates) {
            let minDate = null;
            dates.forEach(d => {
                if (!minDate || new Date(d) < new Date(minDate)) {
                    minDate = d;
                }
            });
            return minDate;
        },
        getMaxDate(dates) {
            let maxDate = null;
            dates.forEach(d => {
                if (!maxDate || new Date(d) > new Date(maxDate)) {
                    maxDate = d;
                }
            });
            return maxDate;
        },
        getEventTitle(event) {
            let title = "";
            switch (event.eventType) {
                case "planned_departure_date":
                    title = this.$t("headers.planned_departure");
                    break;
                case "planned_arrival_date":
                    title = this.$t("headers.planned_arrival");
                    break;
                case "completed_at":
                    title = this.$t("general.exits");
                    break;
                case "tracker_activate":
                    title = this.$t("trips.tracker_activated");
                    break;
                case "geofence_exit":
                    title =
                        this.$t("general.exits") +
                        " <b>" +
                        event.eventName +
                        "</b>";
                    break;
                case "geofence_enter":
                    title =
                        this.$t("general.enter") +
                        " <b>" +
                        event.eventName +
                        "</b>";
                    break;
                case "vessel_depart":
                    title =
                        this.$t("trips.departed") +
                        " <b>" +
                        event.eventName +
                        "</b>";
                    break;
                case "vessel_arrive":
                    title =
                        this.$t("trips.arrived") +
                        " <b>" +
                        event.eventName +
                        "</b>";
                    break;
                case "flight_depart":
                    title =
                        this.$t("trips.flight") +
                        " <b>" +
                        event.eventName +
                        "</b>";
                    break;
                case "flight_arrive":
                    title =
                        this.$t("trips.flight_arrival") +
                        " <b>" +
                        event.eventName +
                        "</b>";
                    break;
                default:
                    return event.eventName;
            }
            return title;
        },
        getEventSubtitle(event_type) {
            switch (event_type) {
                case "planned_departure_date":
                    return this.$t("headers.planned_departure");
                case "planned_arrival_date":
                    return this.$t("headers.planned_arrival");
                case "tracker_activate":
                    return this.$t("trips.tracker_activated");
                case "Enter":
                    return this.$t("general.entered");
                case "Exit":
                    return this.$t("general.exited");
                case "vessel_depart":
                    return this.$t("trips.departure1");
                case "vessel_arrive":
                    return this.$t("trips.arrival1");
                case "flight_depart":
                    return this.$t("trips.flight_departure");
                case "flight_arrive":
                    return this.$t("trips.flight_arrival");
                case "custom":
                    return "";
                default:
                    return event_type;
            }
        },
        getEventColor(event_type) {
            switch (event_type) {
                case "tracker_activate":
                    return "#1C72A5C0";
                case "planned_departure_date":
                    return "#1C72A5C0";
                case "planned_arrival_date":
                    return "#1C72A5FF";
                case "completed_at":
                    return "#1C72A5FF";
                case "vessel_depart":
                    return "#1C72A5C0";
                case "vessel_arrive":
                    return "#1C72A5FF";
                case "flight_depart":
                    return "#CB5858AD";
                case "flight_arrive":
                    return "#CB5858FF";
                case "Enter":
                    return "#1C72A5C0";
                case "Exit":
                    return "#1C72A5FF";
                case "Stay":
                    return "warning";
                case "geofence_enter":
                    return "#1C72A5C0";
                case "geofence_exit":
                    return "#1C72A5FF";
                default:
                    return "secondary";
            }
        },
        getEventIcon(event_type) {
            switch (event_type) {
                case "planned_departure_date":
                    return "flag";
                case "planned_arrival_date":
                    return "sports_score";
                case "completed_at":
                    return "sports_score";
                case "tracker_activate":
                    return "play_arrow";
                case "geofence_enter":
                    return "login";
                case "geofence_exit":
                    return "logout";
                case "vessel_depart":
                    return "directions_boat";
                case "vessel_arrive":
                    return "directions_boat";
                case "flight_depart":
                    return "flight_takeoff";
                case "flight_arrive":
                    return "flight_land";
                case "Enter":
                    return "login";
                case "Exit":
                    return "logout";
                case "Stay":
                    return "crop_free";
                case "custom":
                    return "";
                default:
                    return "";
            }
        }
    }
};
</script>
<style scoped>
.maxWidth {
    max-width: 300px;
    min-width: 270px;
}
.maxScreenWidth {
    width: 100%;
    min-width: 300px;
}
.maxHeight {
    max-height: 350px;
    min-height: 290px;
}
.chartContainer {
    height: 280px;
}
</style>
