import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
import Intersect from 'vuetify/lib/directives/intersect';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":_vm.offsetIsY,"offset-x":!_vm.offsetIsY,"right":!_vm.offsetIsY,"left":_vm.offsetIsY,"bottom":"","close-on-content-click":false,"origin":"center center","nudge-bottom":10},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.displayFormat === 'text')?_c('div',[_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t('headers.notifications'))+" ")])]):(_vm.displayFormat === 'list-item')?_c('div',[_c(VListItem,_vm._g({directives:[{def: Ripple,name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],attrs:{"link":""}},on),[_c(VListItemAction,[_c(VBadge,{attrs:{"content":_vm.unreadNotificationCount > 9 ? '9+' : _vm.unreadNotificationCount,"value":_vm.unreadNotificationCount,"color":"warning","overlap":""}},[_c(VIcon,[_vm._v("notifications")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(_vm.$t('headers.notifications')))])])],1)],1)],1):_c('div',[_c(VBtn,_vm._g({staticClass:"mr-1",attrs:{"icon":""}},on),[_c(VBadge,{attrs:{"content":_vm.unreadNotificationCount > 9
                            ? '9+'
                            : _vm.unreadNotificationCount,"value":_vm.unreadNotificationCount,"color":"warning","overlap":""}},[_c(VIcon,[_vm._v("notifications")])],1)],1)],1)]}}]),model:{value:(_vm.isUserInboxNotificationCenterOpen),callback:function ($$v) {_vm.isUserInboxNotificationCenterOpen=$$v},expression:"isUserInboxNotificationCenterOpen"}},[_c(VCard,{staticClass:"elevation-0",staticStyle:{"width":"450px"}},[_c(VCardTitle,{staticClass:"pb-0"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c(VBtn,_vm._g({attrs:{"icon":"","color":_vm.isUnreadNotificationFilterEnabled
                                ? 'primary'
                                : 'grey',"disabled":_vm.$apollo.queries.userInboxNotifications.loading},on:{"click":function($event){return _vm.filterNotifications()}}},on),[_c(VIcon,[_vm._v("filter_list")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.isUnreadNotificationFilterEnabled ? this.$t("general.show_all") : this.$t("general.show_unread")))])]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c(VBtn,_vm._g({attrs:{"icon":"","disabled":_vm.unreadNotificationCount == 0 ||
                                _vm.isMarkingAllNotificationsAsRead},on:{"click":function($event){return _vm.markAllUserInboxNotificationAsRead()}}},on),[_c(VIcon,[_vm._v("drafts")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("general.mark_all")))])])],1),_c(VCardText,{staticClass:"pa-0"},[_c(VToolbar,{staticClass:"elevation-0"},[_c(VRow,[_c(VBtnToggle,{staticClass:"mt-2",attrs:{"color":"primary","group":"","max":""},model:{value:(_vm.toggle),callback:function ($$v) {_vm.toggle=$$v},expression:"toggle"}},[_c(VBtn,{staticClass:"text-capitalize white",attrs:{"value":"general","text":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.general")))])]),_c(VBtn,{staticClass:"text-capitalize white",attrs:{"value":"alert","text":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.notifications")))])])],1)],1)],1),(_vm.toggle == 'general')?_c(VVirtualScroll,{attrs:{"items":[],"item-height":80,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var index = ref.index;
                                var item = ref.item;
return [_c(VRow,{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])])],1)]}}],null,false,3622192287)}):_vm._e(),(_vm.toggle == 'alert')?_c(VVirtualScroll,{attrs:{"items":_vm.userInboxNotifications
                        ? _vm.userInboxNotifications.edges
                        : [],"item-height":80,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var index = ref.index;
                        var item = ref.item;
return [_c(VDivider),_c(VListItem,{attrs:{"three-line":"","dense":""}},[_c(VListItemContent,{staticClass:"row-pointer elevation-0",attrs:{"tile":""}},[_c(VListItemTitle,{staticClass:"subtitle-2",domProps:{"textContent":_vm._s(item.node.subject)}}),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.formatDateTime(item.node.createdAt)))])],1),_c(VListItemAction,[(item.node.isRead)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.updateUserInboxNotificationAsRead(
                                                item.node.id,
                                                false
                                            )}},model:{value:(_vm.isRead),callback:function ($$v) {_vm.isRead=$$v},expression:"isRead"}},on),[_c(VIcon,[_vm._v("drafts")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.mark_unread")))])]):_vm._e(),(!item.node.isRead)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.updateUserInboxNotificationAsRead(
                                                item.node.id,
                                                true
                                            )}},model:{value:(_vm.isRead),callback:function ($$v) {_vm.isRead=$$v},expression:"isRead"}},on),[_c(VIcon,[_vm._v("email")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.mark_read")))])]):_vm._e()],1)],1),(
                            index == _vm.userInboxNotifications.edges.length - 1
                        )?_c(VCard,{staticClass:"elevation-0",attrs:{"tile":""}},[_c(VCardActions,{staticClass:"justify-center"},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.userInboxNotifications
                                        .loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)],1):_vm._e()]}}],null,false,3748536476)}):_vm._e()],1),_c(VProgressLinear,{attrs:{"active":_vm.$apollo.queries.userInboxNotifications.loading,"indeterminate":_vm.$apollo.queries.userInboxNotifications.loading,"absolute":"","bottom":"","color":"primary","height":"3"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }