<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card outlined class="rounded-lg">
                    <v-card-title class="mb-2">
                        <div class="title">
                            {{ $t("general.general") }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn v-if="!editForm" icon color="primary" @click="editForm = !editForm">
                            <v-icon>edit</v-icon>
                        </v-btn>
                        <v-btn v-if="me.preferences && editForm" text class="mr-2" @click="cancelChanges">{{
                            $t("general.cancel")
                        }}</v-btn>
                        <v-btn color="primary" @click="applyChanges" v-if="editForm" elevation="0">
                            {{ me.preferences ? this.$t("general.apply_changes") : this.$t("general.save") }}
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-if="!editForm">
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="4" class="headers">
                                        {{ $t("language.language") }}
                                    </v-col>
                                    <v-col class="d-flex align-left" cols="auto">
                                        <v-img :src="selectedFlag(language)" height="24" width="24"></v-img>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="4" class="headers">
                                        {{ $t("general.timezone") }}
                                    </v-col>
                                    <v-col class="body">
                                        {{ timezone }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="4" class="headers">
                                        {{ $t("general.date_format") }}
                                    </v-col>
                                    <v-col class="body">
                                        {{ dateFormat }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="4" class="headers">
                                        {{ $t("general.temperature") }}
                                    </v-col>
                                    <v-col class="body">
                                        {{ temperature.symbol }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row align="center" v-else>
                            <v-col cols="6">
                                <v-row>
                                    <v-col cols="6" class="headers">
                                        {{ $t("language.language") }}
                                    </v-col>
                                </v-row>
                                <v-autocomplete
                                    v-model="language"
                                    :items="locales"
                                    outlined
                                    item-text="localeName"
                                    item-value="locale"
                                    hide-details
                                >
                                    <template v-slot:selection="data">
                                        <v-row>
                                            <v-col cols="auto">
                                                <v-img :src="data.item.flag" height="24" width="24"></v-img>
                                            </v-col>
                                            <v-col>
                                                {{ data.item.localeName }}
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-slot:item="data">
                                        <v-row>
                                            <v-col cols="auto">
                                                <v-img :src="data.item.flag" height="24" width="24"></v-img>
                                            </v-col>
                                            <v-col>
                                                {{ data.item.localeName }}
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-row>
                                    <v-col class="headers">
                                        {{ $t("general.timezone") }}
                                    </v-col>
                                </v-row>

                                <v-autocomplete
                                    auto-select-first
                                    :items="timezonesList"
                                    v-model="pendingTimezone"
                                    item-text="zone"
                                    item-value="zone"
                                    outlined
                                    hide-details
                                >
                                    <template v-slot:item="data">
                                        <v-row class="ma-0 body-2">
                                            <v-col cols="auto" class="text-truncate pl-0 ml-0">
                                                {{ data.item.zone }}
                                            </v-col>
                                            <v-col cols="auto" class="text-truncate pl-0 ml-0 grey--text">
                                                {{ data.item.offset }}
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-row>
                                    <v-col class="headers">
                                        {{ $t("general.date_format") }}
                                    </v-col>
                                </v-row>
                                <v-select
                                    auto-select-first
                                    :items="dateFormats"
                                    v-model="pendingDateFormat"
                                    hide-details
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-col class="headers">
                                        {{ $t("general.temperature") }}
                                    </v-col>
                                </v-row>
                                <v-btn-toggle v-model="pendingTemperature">
                                    <v-btn value="celsius"> ºC </v-btn>
                                    <v-btn value="fahrenheit"> ºF </v-btn>
                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from "moment";
import helper from "@/utils/helper.js";

export default {
    name: "TemperatureUnitsDialog",
    components: {},
    props: {
        openTemperatureUnitsDialog: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: "btn"
        }
    },
    data() {
        return {
            temperatureUnitsDialog: false,
            pendingTemperature: null,
            pendingTimezone: null,
            pendingDateFormat: null,
            language: null,
            dateFormats: [
                { text: "YYYY/MM/DD", value: "YYYY/MM/DD" },
                { text: "MM/DD/YYYY", value: "MM/DD/YYYY" },
                { text: "DD/MM/YYYY", value: "DD/MM/YYYY" }
            ],
            editForm: false
        };
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        timezone() {
            let _preferences = this.me?.preferences;
            if (_preferences == null || _preferences == "") {
                return moment.tz.guess();
            } else {
                _preferences = JSON.parse(_preferences);
                if (_preferences?.timezone != null) return _preferences?.timezone;
                else return moment.tz.guess();
            }
        },
        timezonesList() {
            //Country capitals timezone
            moment.tz.add(["China/Beijing|CST|-80|0101|1Lzm0"]);
            let _timezones = [];
            let timezones = moment.tz.names();
            // let myTimezone = moment.tz.guess();
            timezones.forEach(tz => {
                let zoneOffset = moment.tz(tz).utcOffset();
                let myZoneOffset = moment.tz(this.timezone).utcOffset();
                let offset = zoneOffset - myZoneOffset;
                offset = offset / 60;
                let _offset = "";
                if (offset > 0 && offset < 10) _offset = `UTC (+0${offset}:00)`;
                else if (offset >= 10) _offset = `UTC (+${offset}:00)`;
                else if (offset < 0 && offset > -10) _offset = `UTC (-0${-1 * offset}:00)`;
                else if (offset <= -10) _offset = `UTC (-${-1 * offset}:00)`;
                else _offset = `UTC (00:00)`;

                _timezones.push({
                    zone: tz,
                    offset: _offset
                });
            });
            return _timezones;
        },
        temperature() {
            let _preferences = this.me?.preferences;
            if (_preferences == null || _preferences == "") return { symbol: "ºC", unit: "celsius" };
            else {
                _preferences = JSON.parse(_preferences);
                if (_preferences?.temperature == null) return { symbol: "ºC", unit: "celsius" };
                else return _preferences?.temperature;
            }
        },
        dateFormat() {
            let _preferences = this.me?.preferences;
            if (_preferences == null || _preferences == "") return "YYYY/MM/DD";
            else {
                _preferences = JSON.parse(_preferences);
                if (_preferences?.dateFormat == null) return "YYYY/MM/DD";
                else return _preferences?.dateFormat;
            }
        },
        displayFormat() {
            switch (this.format) {
                case "icon":
                    return "icon";
                case "text":
                    return "text";
                case "list-item":
                    return "list-item";
                default:
                    return "btn";
            }
        },
        locales: function() {
            let _locales = [
                {
                    locale: "en",
                    localeName: "English",
                    flag: require("@/assets/great-britain-circular.png")
                },
                // {
                //     locale: "nl",
                //     localeName: "Nederlands",
                //     flag: require("@/assets/netherlands-circular.png")
                // },
                // {
                //     locale: "es",
                //     localeName: "Español",
                //     flag: require("@/assets/spain2-circular.png")
                // },
                {
                    locale: "pt",
                    localeName: "Português",
                    flag: require("@/assets/portugal-circular.png")
                }
            ];
            return _locales;
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.pendingTemperature = this.temperature.unit;
            this.pendingTimezone = this.timezone;
            this.pendingDateFormat = this.dateFormat;
        });
        this.$watch(
            "_locale",
            function(value) {
                helper.updateMyPreferences({
                    locale: value
                });
            },
            { deep: true }
        );
        let _lang = this.$i18n.locale;
        this.language = _lang;
    },
    watch: {
        /* temperatureUnitsDialog() {
            this.pendingTimezone = this.timezone;
            this.pendingTemperature = this.temperature.unit;
            this.pendingDateFormat = this.dateFormat;
        }, */
        openTemperatureUnitsDialog() {
            this.temperatureUnitsDialog = this.openTemperatureUnitsDialog;
        },
        timezone: {
            handler(val) {
                if (val) {
                    this.pendingTimezone = val;
                }
            },
            deep: true
        }
    },
    methods: {
        cancelChanges() {
            this.temperatureUnitsDialog = false;
            this.editForm = false;
        },
        selectedFlag(language) {
            switch (language) {
                case "en":
                    return require("@/assets/great-britain-circular.png");
                case "nl":
                    return require("@/assets/netherlands-circular.png");
                case "es":
                    return require("@/assets/spain2-circular.png");
                case "pt":
                    return require("@/assets/portugal-circular.png");
                default:
                    return require("@/assets/great-britain-circular.png");
            }
        },
        applyChanges() {
            if (this.language !== null) {
                this.$i18n.locale = this.language;
                localStorage.setItem("locale", this.language);
                this.$store.commit("user/setLocale", this.language);
                helper.updateMyPreferences({
                    locale: this.language
                });
            }

            const changes = {
                ...(this.changeTemperature() || {}),
                ...((this.pendingTimezone && this.changeTimezone(this.pendingTimezone)) || {}),
                ...((this.pendingDateFormat && this.changeDateFormat(this.pendingDateFormat)) || {})
            };
            helper.updateMyPreferences(changes);

            this.editForm = false;
            this.temperatureUnitsDialog = false;

            const payload = {
                color: "blue",
                message: this.$t("general.update_successfully")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        changeTemperature: function() {
            let symbol = "ºC";
            if (this.pendingTemperature == "fahrenheit") symbol = "ºF";
            return {
                temperature: { symbol: symbol, unit: this.pendingTemperature }
            };
        },
        changeTimezone: function() {
            return { timezone: this.pendingTimezone };
        },
        changeDateFormat: function() {
            return { dateFormat: this.pendingDateFormat };
        }
    }
};
</script>
<style scoped>
.title {
    color: #0e0d35;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
}
.headers {
    color: #868599;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
}
.body {
    color: #0e0d35;
    font-size: 13px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: left;
}
</style>
