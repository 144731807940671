<template>
    <div>
        <v-menu
            v-model="menu"
            bottom
            left
            :close-on-content-click="false"
            :open-on-hover="openOnHover"
            z-index="3"
            transition="slide-x-reverse-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-on="on"
                    :dark="dark"
                    v-bind="attrs"
                    @click="loadMenu"
                >
                    <v-icon>{{ icon }}</v-icon>
                </v-btn>
            </template>

            <v-card :width="width">
                <v-list v-if="title || description">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-if="title">
                                {{ title }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                                v-if="description"
                                class="text-wrap"
                            >
                                {{ description }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="icon">
                            <v-icon>filter_list_alt</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card flat :max-height="height" class="overflow-auto">
                    <v-list dense>
                        <draggable
                            class="list-group"
                            v-model="newChoices"
                            v-bind="dragOptions"
                        >
                            <transition-group type="transition">
                                <div
                                    v-for="(item, index) in newChoices"
                                    :key="index + 0"
                                >
                                    <v-hover>
                                        <template v-slot="{ hover }">
                                            <v-list-item
                                                class="list-group-item pl-0"
                                                :class="
                                                    hover
                                                        ? 'grey lighten-4'
                                                        : 'white'
                                                "
                                            >
                                                <v-list-item-avatar>
                                                    <v-icon
                                                        >drag_indicator</v-icon
                                                    >
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{
                                                            translatedChoices.find(
                                                                f =>
                                                                    f.code ===
                                                                    item.code
                                                            ).name
                                                        }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-switch
                                                        color="primary"
                                                        v-model="item.enabled"
                                                    ></v-switch>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </template>
                                    </v-hover>
                                </div>
                            </transition-group>
                        </draggable>
                    </v-list>
                </v-card>

                <v-divider></v-divider>

                <v-card-actions
                    v-if="showApplyButton"
                    class="d-flex justify-end py-2 pr-3"
                >
                    <v-btn
                        small
                        outlined
                        rounded
                        color="primary"
                        @click="onApplied"
                        >{{ $t("general.apply") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    props: {
        choices: {
            type: Array, // [{ code: '', name: '', enabled: false }, ...]
            required: true
        },

        title: String,
        description: String,
        showApplyButton: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: "more_vert"
        },
        openOnHover: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: "500px"
        },
        width: {
            type: String,
            default: "300px"
        },
        dark: {
            type: Boolean,
            default: false
        }
    },
    components: {
        draggable
    },
    data() {
        return {
            menu: false,
            newChoices: [],
            originalChoices: [],
            selectedItem: null
        };
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        translatedChoices() {
            return this.choices.map(f => {
                return {
                    ...f,
                    name: this.$t(f.tag)
                };
            });
        }
    },
    watch: {
        newChoices: {
            handler(newChoices) {
                this.$emit("changed", JSON.parse(JSON.stringify(newChoices)));
            },
            deep: true
        },
        menu(value) {
            // if didn't click apply, when close menu, reset choices to originalChoices
            if (!value & this.showApplyButton) {
                this.newChoices = JSON.parse(
                    JSON.stringify(this.originalChoices)
                );
            }
        }
    },
    methods: {
        onApplied() {
            this.originalChoices = JSON.parse(JSON.stringify(this.newChoices));
            this.$emit("applied", JSON.parse(JSON.stringify(this.newChoices)));
            this.menu = false;
        },
        loadMenu() {
            let _choices = JSON.parse(JSON.stringify(this.choices));
            this.newChoices = _choices.map(item => item);
            this.originalChoices = _choices.map(item => item);
        }
    }
};
</script>

<style scoped>
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.list-group {
    min-height: 10px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}
.bg-active {
    background-color: white;
    color: grey;
}
</style>
