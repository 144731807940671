<template>
    <v-dialog max-width="600" v-model="openAddGroupUserDialog" persistent>
        <v-card>
            <!--- ICON AND TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("groups.add_user") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pt-6">
                <v-form ref="groupUserForm">
                    <v-col cols="12" class="pa-0 my-1">
                        <v-autocomplete
                            dense
                            v-model="selectedUsers"
                            :items="tenantUsers.edges"
                            :no-data-text="$t('loading.no_available')"
                            :label="$t('headers.select_user') + '*'"
                            outlined
                            hide-selected
                            hide-details
                            deletable-chips
                            small-chips
                            item-text="node.user.fullName"
                            item-value="node.user.id"
                            multiple
                            class="mx-2"
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    x-small
                                    outlined
                                    fab
                                    color="primary"
                                    @click="createNewUser"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-form>
            </v-card-text>
            <!--- ACTION BUTTONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    :loading="isLoadingAddGroupUser"
                    @click="createTenantGroupUser"
                    color="primary"
                    rounded
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
        <AddUserDialog
            :openAddUserDialog="openCreateNewUserDialog"
            v-on:refreshDataTable="$apollo.queries.tenantUsers.refresh()"
            v-on:closeAddUserDialog="openCreateNewUserDialog = false"
        ></AddUserDialog>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import AddUserDialog from "@/components/users/create/AddUserDialog.vue";

export default {
    name: "AddGroupUserDialog",
    props: ["openAddGroupUserDialog", "actionGroup", "groupUsers"],
    components: { AddUserDialog },
    apollo: {
        tenantUsers: {
            query: gql`
                query TenantUsers($tenantId: ID) {
                    tenantUsers(tenantId: $tenantId) {
                        edges {
                            node {
                                id
                                user {
                                    id
                                    fullName
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.me.tenant.id
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUsers;
            },
            skip: false
        }
    },
    data() {
        return {
            /**************************************/
            /* --------- FORM VARIABLES --------- */
            /**************************************/
            selectedUsers: [],
            tenantUsers: { edges: [] },
            firstName: null,
            lastName: null,
            email: null,
            requires2fa: false,
            /**********************************************/
            /* --------- DIALOGS/LOADINGS/MENUS --------- */
            /**********************************************/
            isLoadingAddGroupUser: false,
            openCreateNewUserDialog: false,
            /*****************************/
            /* --------- RULES --------- */
            /*****************************/
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v =>
                    /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {
        groupUsers() {
            this.selectedUsers = this.groupUsers.edges.map(item => {
                return item.node.user.id;
            });
        }
    },
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.firstName = null;
            this.lastName = null;
            this.email = null;
            this.requires2fa = false;
            this.$refs.groupUserForm.resetValidation();
            this.$emit("closeAddGroupUserDialog");
        },
        createNewUser() {
            this.openCreateNewUserDialog = true;
        },
        /* ---> TENANT GROUP USER MUTATION <--- */
        createTenantGroupUser() {
            // validate form input :
            if (!this.$refs.groupUserForm.validate()) return;
            // Initialize Loading :
            this.isLoadingAddGroupUser = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTenantActionGroupUsers(
                            $input: UpdateTenantActionGroupUsersInput!
                        ) {
                            updateTenantActionGroupUsers(input: $input) {
                                tenantActionGroup {
                                    id
                                    tenantActionGroupUserSet {
                                        totalCount
                                        edges {
                                            node {
                                                user {
                                                    email
                                                    firstName
                                                    id
                                                    fullName
                                                }
                                            }
                                        }
                                    }
                                    name
                                    userEmails
                                    externalEmails
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.actionGroup.id,
                            users: this.selectedUsers
                        }
                    }
                })
                .then(() => {
                    // // Stop Loading //
                    // this.isLoadingAddGroupUser = false;
                    // Emit event to signal user is added and reload table showing users //
                    this.$emit("refreshGroupUsersTable");
                    // Close the dialog and reset form fields //
                    this.closeAndResetInput();
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.isLoadingAddGroupUser = false;
                });
        }
    }
};
</script>
