import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"loading":_vm.$apollo.queries.trackerLocations.loading,"id":"map"}},[(_vm.noData)?_c(VOverlay,{attrs:{"absolute":"","opacity":""}},[_c(VAlert,{attrs:{"dense":"","color":"grey","type":"warning"}},[_vm._v(_vm._s(_vm.$t("trackers.no_locations")))])],1):_vm._e(),_c('div',{ref:"hereMap",style:({
            height: _vm.mapHeight,
            width: '100%'
        }),attrs:{"id":"mapContainer"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }