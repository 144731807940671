<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="background"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                {{ t.name }}
            </v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <!-- GROUP USERS TAB -->
            <v-tab-item value="tab-users" :transition="false"
                ><GroupUsers
                    :group="group"
                    v-on:updatedGroup="updatedGroup"
                ></GroupUsers
            ></v-tab-item>
            <!-- GROUP PERMISSIONS TAB -->
            <v-tab-item value="tab-permissions" :transition="false">
                <GroupPermissions :group="group"></GroupPermissions>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import GroupUsers from "@/components/staff/groups/details/GroupUsers.vue";
import GroupPermissions from "@/components/staff/groups/details/GroupPermissions.vue";
export default {
    props: {
        group: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        GroupUsers,
        GroupPermissions
    },
    data() {
        return {
            tab: null
        };
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225 ;
            return  _break;
        }
    },
    mounted() {},
    methods: {
        updatedGroup(val) {
            this.$emit("updatedGroup", val);
        }
    }
};
</script>
