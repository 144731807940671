<template>
    <!--single date select-->
    <v-row no-gutters class="px-0 py-0">
        <v-col>
            <v-text-field
                v-model="selectedText"
                @blur="date = parseDate(date)"
                class="rounded-r-0"
                @click="openDateTimePicker"
                @click:clear="onClear"
                :clearable="clearField && !readOnly"
                :label="label"
                :disabled="disabled"
                :filled="filled"
                :dense="dense"
                :outlined="outlined"
                :rules="rules"
                :readonly="readOnly"
                :hide-details="hideDetails"
                :placeholder="placeholder"
            >
            </v-text-field>
        </v-col>
        <v-col cols="auto">
            <v-row class="py-n4">
                <v-col>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :return-value.sync="date"
                        offset-y
                        offset-x
                        nudge-left="400"
                        nudge-width="350"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="openDateTimePicker"
                                width="20"
                                :height="btnHeight"
                                tile
                                small
                                elevation="0"
                                v-on="on"
                                v-bind="attrs"
                                outlined
                                rounded
                                class="border btnBackGround "
                                :disabled="disabled"
                            >
                                <v-icon>{{ calendarIcon }}</v-icon>
                            </v-btn>
                        </template>
                        <v-form
                            class="px-0 py-0"
                            v-click-outside="{
                                handler: clickOutside,
                                include: include
                            }"
                            v-model="isValid"
                        >
                            <v-row no-gutters class="px-0 py-0 ">
                                <v-col cols="auto" class="pa-0">
                                    <v-card tile elevation="1">
                                        <v-date-picker
                                            ref="datePickerRef"
                                            v-if="!readOnly"
                                            v-model="date"
                                            no-title
                                            width="310"
                                            :pickerDate.sync="pickerDate"
                                            class="included"
                                            @change="submit"
                                        >
                                        </v-date-picker>
                                    </v-card>
                                </v-col>
                                <v-col cols="auto" class="pa-0">
                                    <v-card
                                        scrollable
                                        height="290"
                                        class="overflow-y-auto"
                                        tile
                                        elevation="1"
                                    >
                                        <v-card-title class="py-0">
                                            <!--  <v-btn
                                                @click="close"
                                                class="pa-0"
                                                icon
                                                small
                                            >
                                                <v-icon>close</v-icon>
                                            </v-btn> -->
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                icon
                                                small
                                                :disabled="!date"
                                                @click="menu = !menu"
                                            >
                                                <v-icon
                                                    color="primary"
                                                    class="pa-0"
                                                    >done</v-icon
                                                >
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text
                                            class="overflow-y-auto pa-0"
                                            style="max-height: 254px;"
                                        >
                                            <v-list dense ref="list">
                                                <v-list-item-group
                                                    v-model="indexTime"
                                                    color="primary"
                                                    @change="submit"
                                                >
                                                    <v-list-item
                                                        v-for="(item,
                                                        index) in system24"
                                                        :key="index"
                                                    >
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ item }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-menu>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import moment from "moment";
import helper from "@/utils/helper";

export default {
    name: "date-time-component",
    props: {
        value: {
            type: String,
            default: ""
        },
        label: String,
        placeholder: String,
        disabled: {
            type: Boolean,
            default: false
        },
        filled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        calendarIcon: {
            type: String,
            default: "calendar_month"
        },
        rules: {
            type: Array,
            default: () => []
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        clearField: {
            type: Boolean,
            default: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        defaultTime: {
            type: String,
            default: "00:00"
        }
    },
    data: function() {
        return {
            menu: false,
            timerMenu: false,
            selectedRange: [],
            tab: false,
            isValid: false,
            timeBackUp: null,
            dateBackUp: null,
            pickerDate: null,
            dateTime: {
                date: null,
                time: null
            },
            //selected: null,
            system12: [
                "12:00 AM",
                "12:30 AM",
                "01:00 AM",
                "01:30 AM",
                "02:00 AM",
                "02:30 AM",
                "03:00 AM",
                "03:30 AM",
                "04:00 AM",
                "04:30 AM",
                "05:00 AM",
                "05:30 AM",
                "06:00 AM",
                "06:30 AM",
                "07:00 AM",
                "07:30 AM",
                "08:00 AM",
                "08:30 AM",
                "09:00 AM",
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM",
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM",
                "10:00 PM",
                "10:30 PM",
                "11:00 PM",
                "11:30 PM"
            ],
            //system 24 hours next upgrade
            system24: [
                "00:00",
                "00:30",
                "01:00",
                "01:30",
                "02:00",
                "02:30",
                "03:00",
                "03:30",
                "04:00",
                "04:30",
                "05:00",
                "05:30",
                "06:00",
                "06:30",
                "07:00",
                "07:30",
                "08:00",
                "08:30",
                "09:00",
                "09:30",
                "10:00",
                "10:30",
                "11:00",
                "11:30",
                "12:00",
                "12:30",
                "13:00",
                "13:30",
                "14:00",
                "14:30",
                "15:00",
                "15:30",
                "16:00",
                "16:30",
                "17:00",
                "17:30",
                "18:00",
                "18:30",
                "19:00",
                "19:30",
                "20:00",
                "20:30",
                "21:00",
                "21:30",
                "22:00",
                "22:30",
                "23:00",
                "23:30"
            ],
            system: ""
        };
    },
    computed: {
        btnHeight() {
            return this.dense ? "40px" : "56px";
        },
        date: {
            get() {
                let _date = "";
                if (this.dateTime.date == null) {
                    _date = this.value ? this.value.slice(0, 10) : null;
                } else {
                    _date = this.dateTime.date;
                }

                if (!_date) return null;

                //moment format the current value
                let _newDate = moment(_date, helper.getDateFormat());
                let _Date = moment(_newDate, [
                    "DD/MM/YYYY",
                    "YYYY/MM/DD",
                    "MM/DD/YYYY"
                ]).toDate();

                let dateFormat = moment(_Date).format("YYYY-MM-DD");

                return dateFormat;
            },
            set(value) {
                let _date = moment(value, "YYYY-MM-DD").format(
                    helper.getDateFormat()
                );

                this.dateTime.date = _date;
            }
        },
        time: {
            get() {
                let _time = "";
                if (this.dateTime.time == null) {
                    _time = this.value
                        ? this.value.slice(11, 16)
                        : this.defaultTime;
                } else {
                    _time = this.dateTime.time || this.defaultTime || "00:00";
                }
                return _time;
            },
            set(value) {
                this.dateTime.time = value;
            }
        },
        indexTime: {
            get() {
                return this.system24.indexOf(this.time);
            },
            set(value) {
                this.time = this.system24[value];
            }
        },
        selectedText: {
            get() {
                let textFormat = "";
                if (this.value) {
                    let [date, time] = this.value.split(" ");
                    let _dateGetFormat = moment(date, helper.getDateFormat());
                    let _Date = moment(_dateGetFormat, [
                        "DD/MM/YYYY",
                        "YYYY/MM/DD",
                        "MM/DD/YYYY"
                    ]).toDate();

                    let dateSetFormat = moment(_Date).format("YYYY-MM-DD");

                    let _dateFormat = moment(
                        dateSetFormat,
                        "YYYY-MM-DD"
                    ).format(helper.getDateFormat());

                    textFormat = `${_dateFormat} ${time}`;
                } else {
                    textFormat = null;
                }

                return textFormat;
            },
            set() {
                //this.$emit("input", value);
            }
        }
    },
    watch: {
        selectedText(v) {
            if (v === null) {
                const today = new Date().toISOString().split("T")[0];
                this.pickerDate = today;
            }
        }
    },
    methods: {
        openDateTimePicker() {
            this.dateBackUp = this.value ? this.value.slice(0, 10) : null;
            this.timeBackUp = this.value
                ? this.value.slice(11, 16)
                : this.defaultTime;
            this.dateTime.date = this.value ? this.value.slice(0, 10) : null;
            this.dateTime.time = this.value
                ? this.value.slice(11, 16)
                : this.defaultTime;
            this.menu = !this.menu;
        },
        submit() {
            let _date =
                this.dateTime.date == null
                    ? this.dateBackUp
                    : this.dateTime.date;
            let backup = this.isEmpty(this.timeBackUp)
                ? this.defaultTime || "00:00"
                : this.timeBackUp;
            let _time = this.isEmpty(this.dateTime.time)
                ? backup
                : this.dateTime.time;

            let _concat = _date + " " + _time;
            this.$emit("input", _concat);
        },
        close() {
            /* let _concat = this.dateBackUp + " " + this.timeBackUp;
            this.$emit("input", _concat); */
            this.menu = false;
        },
        clickOutside() {
            if (this.menu) {
                /*  let _date =
                    this.dateTime.date == null
                        ? this.dateBackUp
                        : this.dateTime.date;
                let backup = this.isEmpty(this.timeBackUp)
                    ? this.defaultTime || "00:00"
                    : this.timeBackUp;
                let _time = this.isEmpty(this.dateTime.time)
                    ? backup
                    : this.dateTime.time;

                let _concat = _date + " " + _time;
                this.$emit("input", _concat);  */
            }
        },
        include() {
            // Call the value in the include class
            return [document.querySelector(".included")];
        },
        clearCache() {
            //clear the cache in child and father side
            //this.selectedRange = [];
            //this.$emit("input", "");
        },
        onClear() {
            // clear the cache on father side
            this.$emit("input", "");
        },
        parseDate(date) {
            if (!date) {
                if (this.rangeSelect) return [];
                return null;
            }
            const [year, month, day] = date.split("-");
            return `${year}-${month?.padStart(2, "0")}-${day?.padStart(
                2,
                "0"
            )}`;
        },
        isEmpty(v) {
            if (
                v === null ||
                v === undefined ||
                v === "" ||
                v === "null" ||
                v === "undefined" ||
                v === "NULL"
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
<style scoped>
.border {
    border: 1px solid rgb(171, 170, 170) !important;
}
.border:hover {
    border: 1px solid rgb(126, 126, 126) !important;
}
.border:focus {
    border: 1px solid rgb(126, 126, 126) !important;
}
.border:active {
    border: 1px solid rgb(126, 126, 126) !important;
}
.btnBackGround {
    background-color: #f5f5f5 !important;
}
</style>
