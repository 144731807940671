<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <!---- SEARCH BAR ---->
                    <v-col cols="5" sm="5" lg="5">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :hint="$t('alert_rules.search_action')"
                                    v-model="filter.search"
                                    append-icon="search"
                                    :label="$t('general.search')"
                                    clearable
                                    hide-details
                                    dense
                                    class="subtitle-2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <!---- ADD BUTTON ---->
                </v-row>
                <AddAlertRuleDialog
                    :openAddAlertRuleDialog="openAddAlertRule"
                    :createMode="true"
                    v-on:refreshDataTable="$emit('updateAlertRulesTable')"
                    v-on:close-dialog="openAddAlertRule = false"
                    v-on:item-created="onItemCreated"
                ></AddAlertRuleDialog>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import { TenantPermissions } from "@/core/enum";
import AddAlertRuleDialog from "@/components/alertrules/create/AddAlertRuleDialog.vue";

export default {
    components: { AddAlertRuleDialog },
    apollo: {},

    data() {
        return {
            fab: false,
            isLoading: false,
            sortAsc: true,
            shouldStickyFilter: false,
            openAddAlertRule: false,

            // graphql query filter
            filter: {
                first: 10,
                search: null,
                isStandardAlert: false
            }
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        canCreate() {
            return this.hasPermission(
                TenantPermissions.CREATE_TENANT_ALERT_RULE
            );
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                    helper.setFilterValues("alertRuleTableFilters", value);
                }
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.onResize();
        let _filter = helper.getFilterValues("alertRuleTableFilters");
        if (_filter) {
            this.filter.search = _filter.search;
        }
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        },
        onItemCreated(alertRule) {
            this.$emit("item-created", alertRule);
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
