import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.goTopShow),expression:"goTopShow"}],staticStyle:{"z-index":"1"},attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","small":"","color":"primary"},on:{"click":_vm.toTop}},[_c(VIcon,[_vm._v("keyboard_arrow_up")])],1),_c('div',[_c(VRow,{staticClass:"ma-0 px-3 d-flex align-center"},[_c(VCol,[_c('h2',{staticClass:"ml-3 display-3 font-weight-medium d-flex align-top"},[_vm._v(" "+_vm._s(_vm.$t("templates.trips_templates"))+" ")])]),_c(VSpacer),(!_vm.selectMode)?_c('AddTrip',{attrs:{"templateMode":true},on:{"template-created":_vm.onTemplateCreated}}):_vm._e(),(!_vm.selectMode)?_c(VBtn,{attrs:{"color":"primary","rounded":""},on:{"click":_vm.changeSelectMode}},[_vm._v(_vm._s(_vm.$t("general.select")))]):_c('div',[_c(VBtn,{ref:"deleteBtn",staticClass:"mr-2",attrs:{"color":"primary","loading":_vm.isLoading,"rounded":""},on:{"click":_vm.onDeleteSelectedTemplates}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" delete ")]),_vm._v(" "+_vm._s(_vm.$t("general.delete"))+" ")],1),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.changeSelectMode}},[_vm._v(_vm._s(_vm.$t("general.cancel")))])],1)],1)],1),_c('TripTemplatesFilter',{ref:"tripTemplateFilter",staticClass:"mx-3",on:{"filter-changed":_vm.onFilterChanged}}),_c('TripTemplatesTable',{ref:"tripTemplateTable",staticClass:"mx-3 mt-3 mb-3",attrs:{"tripTemplatesFilter":_vm.tripTemplateFilter,"selectMode":_vm.selectMode},on:{"click:row":_vm.onTripTemplateSelected,"deleted-templates":_vm.deleteFinished}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }