<template>
    <div class="px-5 mb-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <v-col cols="12" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-btn
                                small
                                outlined
                                color="primary"
                                @click="expand"
                            >
                                {{
                                    isAllExpanded
                                        ? "- " + $t("general.collapse")
                                        : "+ " + $t("general.expand")
                                }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="12" sm="5" lg="4" class="py-2 pr-4 text-right">
                    <v-row class="d-flex align-center justify-end">
                        <!-- ADD -->
                        <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            class="mr-1"
                            :loading="isLoadingUpdateGroupPermissions"
                            @click="updateUserGroupPermission"
                            :disabled="enableOrDisablePermissionBtn"
                        >
                            <v-icon left small>add_box</v-icon>
                            {{ $t("general.save_permission") }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <!-- <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined> -->
        <v-expansion-panels
            v-model="panel"
            multiple
            focusable
            class="mt-2 mb-5 pb-5"
        >
            <v-expansion-panel
                v-for="(item, index) in permissionsObject"
                :key="index"
            >
                <v-expansion-panel-header>
                    <h5>{{ item.name }}</h5>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                    <v-lazy>
                        <!-- TABLE -->
                        <GroupPermissionTable
                            :tableData.sync="item.options"
                            :isLoadingTable="
                                $apollo.queries.groupPermissions.loading
                            "
                            :permissions.sync="permissions"
                            v-on:updateTableData="updateSelectedPermissions"
                        ></GroupPermissionTable>
                    </v-lazy>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <!-- </v-card> -->
    </div>
</template>
<script>
import gql from "graphql-tag";
import GroupPermissionTable from "@/components/groups/tabs/GroupPermissionTabTable.vue";
import {
    TrackerPermissions,
    TenantPermissions,
    StaffPermissions
} from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "GroupPermissionsTab",
    components: { GroupPermissionTable },
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    apollo: {
        groupPermissions: {
            query: gql`
                query ColdchaStaffGroup($coldchaGroupId: ID!) {
                    coldchaGroup(id: $coldchaGroupId) {
                        id
                        name
                        permissions
                    }
                }
            `,
            variables() {
                return {
                    coldchaGroupId: this.group.node.id
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.coldchaGroup.permissions;
            },
            result({ loading, data }) {
                if (!loading) {
                    let coldchaStaffGroupPermissions =
                        data.coldchaGroup.permissions;
                    let temp = [...coldchaStaffGroupPermissions];
                    this.permissionStr = temp.sort().join("-");
                    this.permissions = [...coldchaStaffGroupPermissions];
                }
            },
            skip: false
        }
    },
    data() {
        return {
            panel: [0],
            permissions: [],
            isLoadingUpdateGroupPermissions: false,
            enableOrDisablePermissionBtn: true,
            permissionStr: "",
            selectedPermissions: [],
            permissionsObject: [
                {
                    name: this.$t("general.general"),
                    options: [
                        {
                            module: this.$t("general.general"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.tenant_users"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_USER,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_USER,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_USER,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_USER,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.tenant_services"),
                            view: {
                                value: StaffPermissions.VIEW_TENANT_SERVICES,
                                isChecked: false
                            },
                            create: {
                                value: StaffPermissions.CREATE_TENANT_SERVICES,
                                isChecked: false
                            },
                            edit: {
                                value: StaffPermissions.EDIT_TENANT_SERVICES,
                                isChecked: false
                            },
                            delete: {
                                value: StaffPermissions.DELETE_TENANT_SERVICES,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.login_tenant"),
                            view: {
                                value: StaffPermissions.LOGIN_AS_TENANT_USER,
                                isChecked: false
                            },
                            create: {
                                value: null,
                                isChecked: false
                            },
                            edit: {
                                value: null,
                                isChecked: false
                            },
                            delete: {
                                value: null,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.tenant_statistics"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_DASHBOARD,
                                isChecked: false
                            },
                            create: {
                                value: null,
                                isChecked: false
                            },
                            edit: {
                                value: null,
                                isChecked: false
                            },
                            delete: {
                                value: null,
                                isChecked: false
                            }
                        }
                    ]
                },
                {
                    name: this.$t("home.trackers"),
                    options: [
                        {
                            module: this.$t("home.trackers"),
                            view: {
                                value: TrackerPermissions.VIEW_TRACKER,
                                isChecked: false
                            },
                            create: {
                                value: TrackerPermissions.CREATE_TRACKER,
                                isChecked: false
                            },
                            edit: {
                                value: TrackerPermissions.EDIT_TRACKER,
                                isChecked: false
                            },
                            delete: {
                                value: TrackerPermissions.DELETE_TRACKER,
                                isChecked: false
                            }
                        }
                    ]
                },
                {
                    name: this.$t("permissions.staff_portal"),
                    options: [
                        {
                            module: this.$t("permissions.dashboard"),
                            view: {
                                value: StaffPermissions.VIEW_DASHBOARD,
                                isChecked: false
                            },
                            create: {
                                value: null,
                                isChecked: false
                            },
                            edit: {
                                value: null,
                                isChecked: false
                            },
                            delete: {
                                value: null,
                                isChecked: false
                            }
                        }
                    ]
                }
            ]
        };
    },
    beforeCreate() {
        this.TrackerPermissions = TrackerPermissions;
        this.TenantPermissions = TenantPermissions;
        this.StaffPermissions = StaffPermissions;
    },
    computed: {
        isAllExpanded() {
            return this.panel.length === this.permissionsObject.length;
        }
    },
    watch: {},
    mounted(){
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'staff_group_permissions_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("staff_group_permissions_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("staff_group_permissions_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        updateSelectedPermissions(value) {
            this.permissions = [];
            this.permissions = [...value];

            this.enableOrDisablePermissionBtn = true;
            let selectedPermissionStr = this.permissions.sort().join("-");
            if (selectedPermissionStr !== this.permissionStr)
                this.enableOrDisablePermissionBtn = false;
        },
        updateUserGroupPermission() {
            // Initialize Loading :
            this.isLoadingUpdateGroupPermissions = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateStaffGroupPermissions(
                            $input: UpdateStaffGroupPermissionsInput!
                        ) {
                            updateStaffGroupPermissions(input: $input) {
                                coldchaStaffGroup {
                                    id
                                    permissions
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.group.node.id,
                            permissions: this.permissions
                        }
                    }
                })
                .then(() => {
                    // Reload permissions data//
                    this.$apollo.queries.groupPermissions.refresh();

                    const payload = {
                        color: "blue",
                        message: this.$t("alert_rules.permission_saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.isLoadingUpdateGroupPermissions = false;
                });
        },
        expand() {
            if (this.isAllExpanded) {
                this.panel = [];
            } else {
                this.panel = [
                    ...this.permissionsObject.map((item, index) => index)
                ];
            }
        }
    }
};
</script>
