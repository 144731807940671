<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <LoadingBar
                v-if="$apollo.queries.tripDocuments.loading"
            ></LoadingBar>
            <div v-else>
                <v-card elevation="5" class="mb-0 pb-0" rounded="xl">
                    <!-- card title (air) -->
                    <v-card-title class="background2 py-3">
                        <div class="mr-2">
                            <v-icon>description</v-icon>
                        </div>
                        <span>{{ $t("headers.documents") }}</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="!isSharedTrip"
                            color="primary"
                            rounded
                            @click="uploadDialog = true"
                        >
                            {{ $t("headers.upload") }}
                            <v-icon class="ml-3">cloud_upload</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="py-0">
                        <v-card flat>
                            <LoadingBar
                                v-if="$apollo.queries.tripDocuments.loading"
                            ></LoadingBar>
                            <v-card-text v-else class="">
                                <v-data-table
                                    v-if="tripDocuments.edges.length > 0"
                                    :headers="headers"
                                    :items="
                                        tripDocuments ? tripDocuments.edges : []
                                    "
                                    :loading-text="$t('loading.loading1')"
                                    mobile-breakpoint="1000"
                                    disable-pagination
                                    hide-default-footer
                                    sort-by="node.name"
                                    item-key="node.id"
                                    class="mb-2"
                                >
                                    <template v-slot:item.node.name="{ item }">
                                        <v-list-item two-line class="pl-0">
                                            <v-list-item-content>
                                                <v-list-item-title>{{
                                                    item.node.name
                                                }}</v-list-item-title>
                                                <v-list-item-subtitle
                                                    style="max-width: 400px"
                                                    class="text-truncate"
                                                    >{{
                                                        item.node.description
                                                    }}</v-list-item-subtitle
                                                >
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template
                                        v-slot:item.node.format="{ item }"
                                    >
                                        <span>{{
                                            formatType(item.node.name)
                                        }}</span>
                                    </template>
                                    <template v-slot:item.node.size="{ item }">
                                        <span>{{
                                            bytesToSize(item.node.size)
                                        }}</span>
                                    </template>
                                    <template
                                        v-slot:item.action="{ item, slot }"
                                    >
                                        <v-btn
                                            small
                                            icon
                                            @click="
                                                downloadDocument(item.node.url)
                                            "
                                        >
                                            <v-icon>download</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-if="!isSharedTrip"
                                            icon
                                            small
                                            @click="editedDialog(item.node)"
                                        >
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-if="!isSharedTrip"
                                            icon
                                            small
                                            @click="deletedDialog(item.node)"
                                        >
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                                <div
                                    v-else
                                    class="text-caption text-center mt-3"
                                >
                                    {{ $t("trips.no_documents") }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
                <!-- Upload dialog -->
                <v-dialog
                    v-model="uploadDialog"
                    rounded="xl"
                    max-width="600px"
                    height="400px"
                    transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-card-title class="primary py-3 mb-2">
                            <div class="mr-2">
                                <v-icon color="white">description</v-icon>
                            </div>
                            <span
                                ><h5 class="white--text">
                                    {{ $t("trips.upload_document") }}
                                </h5></span
                            >
                        </v-card-title>
                        <v-card-text>
                            <DragAndDropFileUpload
                                v-on:upload="uploadDocuments"
                                v-on:disabledUpload="disabledUploadMethod"
                                ref="uploadFile"
                            >
                            </DragAndDropFileUpload>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                width="80px"
                                rounded
                                text
                                @click="closeUpload"
                            >
                                {{ $t("general.cancel") }}
                            </v-btn>
                            <v-btn
                                width="80px"
                                color="primary"
                                rounded
                                :disabled="!disabledUpload"
                                @click="$refs.uploadFile.uploadFile()"
                                :loading="loadingUploadBtn"
                            >
                                {{ $t("headers.upload") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- Edit Dialog -->
                <v-dialog
                    v-model="editDialog"
                    max-width="300px"
                    height="500px"
                    transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-card-title class="background2 py-3 mb-2">
                            <div class="mr-2">
                                <v-icon large>edit_note</v-icon>
                            </div>
                            <span
                                ><h5>{{ $t("trips.edit_document") }}</h5></span
                            >
                        </v-card-title>
                        <v-card-text>
                            <v-form v-model="validate">
                                <v-textarea
                                    v-model="editItem.description"
                                    :rules="[rules.maxLength(200)]"
                                    outlined
                                    :placeholder="$t('trips.edit_add')"
                                    counter="200"
                                ></v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="closeEditDialog"
                                width="80px"
                                rounded
                                outlined
                                >{{ $t("general.cancel") }}</v-btn
                            >
                            <v-btn
                                :disabled="!validate"
                                width="80px"
                                color="primary"
                                rounded
                                outlined
                                @click="editDocument(editItem)"
                                :loading="loadingEditBtn"
                                >{{ $t("general.save") }}</v-btn
                            >
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="deleteDialog"
                    max-width="300px"
                    height="500px"
                    transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-card-title class="background2 py-3 mb-2">
                            <div class="mr-2">
                                <v-icon>warning</v-icon>
                            </div>
                            <span
                                ><h5>
                                    {{ $t("trips.delete_document") }}
                                </h5></span
                            >
                        </v-card-title>
                        <v-card-text>
                            <div>
                                <span
                                    ><h4>
                                        {{ $t("trips.sure_delete") }}
                                    </h4></span
                                >
                            </div>
                            <div class="mt-1">{{ deleteItem.name }}</div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="closeDeleteDialog"
                                width="80px"
                                rounded
                                outlined
                                >{{ $t("general.cancel") }}</v-btn
                            >
                            <v-btn
                                width="80px"
                                color="error"
                                rounded
                                outlined
                                @click="deleteDocument(deleteItem.id)"
                                :loading="loadingDeleteBtn"
                                >{{ $t("general.delete") }}</v-btn
                            >
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </v-sheet>
</template>
<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import DragAndDropFileUpload from "@/components/base/DragAndDropFileUpload.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import Config from "@/utils/config.json";

export default {
    components: { DragAndDropFileUpload, LoadingBar, NoPermission },
    props: {
        trip: {
            type: Object,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    apollo: {
        tripDocuments: {
            query: gql`
                query tripDocuments(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $tripId: ID!
                    $isReportDoc: Boolean
                ) {
                    tripDocuments(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        tripId: $tripId
                        isReportDoc: $isReportDoc
                    ) {
                        edges {
                            node {
                                id
                                name
                                size
                                format
                                url
                                file
                                description
                                trip {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tripId: this.trip.id,
                    isReportDoc: false
                };
            },
            fetchPolicy: "cache-and-network",
            update(data) {
                return data.tripDocuments;
            },
            skip() {
                return !this.canAccess;
            }
        }
    },
    data: function() {
        return {
            //Upload System
            uploadDialog: false,
            disabledUpload: false,
            loadingUploadBtn: false,
            //Edit System
            editDialog: false,
            validate: false,
            editItem: {},
            loadingEditBtn: false,
            //Delete System
            deleteDialog: false,
            deleteItem: {},
            loadingDeleteBtn: false,
            headers: [
                {
                    text: this.$t("headers.name"),
                    align: "left",
                    value: "node.name"
                },
                {
                    text: this.$t("headers.format"),
                    align: "center",
                    value: "node.format"
                },
                {
                    text: this.$t("headers.size"),
                    align: "center",
                    value: "node.size"
                },
                {
                    text: this.$t("headers.actions"),
                    align: "center",
                    value: "action"
                }
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    watch: {},
    computed: {
        me() {
            return helper.me();
        },
        hasManagePermission() {
            return this.hasOrganisationPermission(
                TripPermissions.MANAGE_TRIP_DOCUMENT
            );
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_DOCUMENT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_DOCUMENT,
                        this.organisationPermissions
                    )
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_DOCUMENT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (
                    !this.hasPermission(TripPermissions.VIEW_TRIP_DOCUMENT) &&
                    this.me?.tenant
                )
                    return false;
            }
            return true;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
    },
    created() {
        this.bytesToSize = helper.bytesToSize;
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'trip_documents_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("trip_documents_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("trip_documents_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        /* General section */
        formatType(format) {
            let newFormat = format.split(".");
            return newFormat[newFormat.length - 1];
        },
        refetch() {
            return this.$apollo.queries.tripDocuments.refetch();
        },
        /* Upload section */
        disabledUploadMethod(val) {
            this.disabledUpload = val;
        },
        closeUpload() {
            this.$refs.uploadFile.clearCache();
            this.uploadDialog = false;
        },
        uploadDocuments(files) {
            this.loadingUploadBtn = true;
            let file = [];
            let uploadFields = [];

            files.forEach(e => {
                file.push(e.file);
                uploadFields.push({
                    fileName: e.file.name,
                    description:
                        e.file.description === undefined ||
                        e.file.description === null
                            ? ""
                            : e.file.description
                });
            });

            let payload = {
                tripId: this.trip.id,
                uploadFields: uploadFields,
                file: file
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation uploadTripDocument(
                            $input: UploadTripDocumentInput!
                        ) {
                            uploadTripDocument(input: $input) {
                                trip {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("headers.file_upload")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingUploadBtn = false;
                    this.refetch();
                    this.closeUpload();
                });
        },
        /* Edit section */
        editedDialog(item) {
            this.editDialog = true;
            this.editItem = { ...item };
        },
        closeEditDialog() {
            this.editDialog = false;
            this.editItem = {};
        },
        editDocument(item) {
            this.loadingEditBtn = true;
            let payload = {
                id: item.id,
                description: item.description
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation editTripDocument(
                            $input: EditTripDocumentInput!
                        ) {
                            editTripDocument(input: $input) {
                                trip {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(() => {
                    // show snackbar
                    const payload = {
                        color: "primary",
                        message: this.$t("headers.file_edited")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingEditBtn = false;
                    this.refetch();
                    this.closeEditDialog();
                });
        },
        /* Delete section */
        deletedDialog(item) {
            this.deleteDialog = true;
            this.deleteItem = { ...item };
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.deleteItem = {};
        },
        deleteDocument(id) {
            this.loadingDeleteBtn = true;
            let payload = {
                id: id
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTripDocument(
                            $input: DeleteTripDocumentInput!
                        ) {
                            deleteTripDocument(input: $input) {
                                trip {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(() => {
                    // show snackbar
                    const payload = {
                        color: "primary",
                        message: this.$t("headers.file_removed")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingDeleteBtn = false;
                    this.refetch();
                    this.closeDeleteDialog();
                });
        },
        downloadDocument(url) {
            window.open(`${url}`, "_blank");
        }
    }
};
</script>
<style scoped></style>
