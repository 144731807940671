<template>
    <v-hover>
        <template v-slot="{ hover }">
            <!---- TOOLBAR LEFT SIDE ---->
            <v-card
                class="py-5 mb-3"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="12" sm="9" lg="10">
                        <v-row>
                            <v-col
                                v-for="f in dynamicFilters"
                                :key="f"
                                :class="getFilterChoiceClassByCode(f)"
                            >
                                <!---- CITY ---->
                                <v-text-field
                                    v-if="f === 'location'"
                                    v-model="filter.cityIcontains"
                                    :label="$t('general.location')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                                <!---- COUNTRY ---->
                                <v-text-field
                                    v-if="f === 'country'"
                                    v-model="filter.countryIcontains"
                                    :label="$t('partners.country')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                            </v-col>

                            <!---- SORT BY ---->
                            <v-col cols="5" sm="4" lg="3">
                                <v-row class="d-flex align-center ma-0 pa-0">
                                    <v-select
                                        v-model="sortBy"
                                        :items="sortByChoices"
                                        :label="$t('general.sort_by')"
                                        :item-text="item => item.name"
                                        :item-value="item => item.code"
                                        clearable
                                        hide-details
                                        dense
                                        color="primary"
                                        :menu-props="{
                                            zIndex: 3
                                        }"
                                    >
                                        <!---- SORT BY ICON ---->
                                        <template v-slot:append-outer>
                                            <v-btn
                                                class="mt-n1"
                                                small
                                                icon
                                                :disabled="!sortBy"
                                                @click="sortAsc = !sortAsc"
                                            >
                                                <v-icon color="grey darken-2">{{
                                                    sortAsc ? "north" : "south"
                                                }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </v-row>
                            </v-col>
                            <v-col
                                class="col-auto col-md-4 col-lg-3 col-xl-3 d-flex "
                            >
                                <div class="d-flex align-center mr-4">
                                    <v-switch
                                        v-model="warningCities"
                                        :label="$t('general.warning')"
                                        class="text-no-wrap"
                                        color="primary"
                                        style="margin-top: 0%"
                                    ></v-switch>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- TOOLBAR RIGHT SIDE -->
                    <v-col cols="12" sm="3" lg="2" class="py-2 pr-4 text-right">
                        <v-row class="d-flex flex-nowrap align-center justify-end">
                            
                            <!-- FILTERS MENU -->
                            <FilterMenu
                                :title="$t('dashboard.customize')"
                                :description="$t('dashboard.display')"
                                :choices="filterChoices"
                                :open-on-hover="false"
                                height="500px"
                                width="250px"
                                v-on:changed="onFilterMenuChanged"
                            >
                            </FilterMenu>
                        </v-row>
                    </v-col>
                </v-row>
                <!---- ADD LOCATION DIALOG ---->
                <AddWeather
                    ref="addWeatherDialog"
                    :openAddLocationDialog="openAddLocationDialog"
                    :locationsDisabledWeatherForecast="locationsFilter"
                    v-on:closeMap="openAddLocationDialog = false"
                    v-on:refetchData="requestRefetch"
                ></AddWeather>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import FilterMenu from "@/components/base/FilterMenu.vue";
import AddWeather from "@/components/weather/create/AddWeather.vue";

export default {
    components: { FilterMenu, AddWeather },
    props: {
        selectedLocations: [],
        locationsWithoutForecast: {
            type: Object,
            default: () => []
        },
        disabledAddLocationButton: {
            type: Boolean,
            default: false
        }
    },
    apollo: {},
    data() {
        return {
            fab: false,
            isLoading: false,
            menu: false,
            sortAsc: true,
            shouldStickyFilter: false,
            openAddLocationDialog: false,
            locationsFilter: { edges: [] },
            // --------------------> FILTERS <----------------------- \\
            // defaultFilterChoices: [
            //     {
            //         code: "city",
            //         name: "City",
            //         enabled: true,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "country",
            //         name: "Country",
            //         enabled: true,
            //         class: "col-auto col-md-3 col-lg-2"
            //     }
            // ],

            filterChoices: null,

            // sortByChoices: [
            //     {
            //         code: "city",
            //         name: "City"
            //     }
            // ],

            sortBy: null,

            warningCities: false,

            // graphql query filter
            filter: {
                cityIcontains: null,
                countryIcontains: null,
                orderBy: null,
                warningCities: false
            }
        };
    },
    beforeCreate() {
        this.me = helper.me();
    },
    computed: {
        dynamicFilters() {
            return this.filterChoices.filter(f => f.enabled).map(f => f.code);
        },
        defaultFilterChoices: function() {
            let _defaultFilterChoices = [
                {
                    code: "location",
                    name: "Location",
                    tag: "general.location",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "country",
                    name: "Country",
                    tag: "partners.country",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                }
            ];
            return _defaultFilterChoices;
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "location",
                    name: this.$t("general.location")
                }
            ];
            return _sortByChoices;
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        warningCities(val) {
            this.filter.warningCities = val;
        },
        locationsWithoutForecast(val) {
            this.locationsFilter = val;
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                }
                helper.setFilterValues("weatherChartsFilter", value);
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "weatherChartsFilter"
        );
    },
    mounted() {
        let _filter = helper.getFilterValues("weatherChartsFilter");
        if (_filter) {
            this.filter = _filter;
            if (_filter.orderBy?.includes("-")) {
                this.sortBy = _filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else {
                this.sortBy = _filter.orderBy;
                this.sortAsc = true;
            }
            this.warningCities = _filter.warningCities;
        }

        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    locationsTableFilters: value
                });
            },
            { deep: true }
        );
    },
    updated() {},
    methods: {
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        onFilterMenuChanged(newChoises) {
            this.filterChoices = newChoises;
        },
        requestRefetch(callback) {
            this.$emit("refetchData", () => {
                callback();
            });
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
