<template>
    <v-dialog
        max-width="1000"
        v-model="openAddLocationDialog"
        persistent
        scrollable
    >
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("partners.add_location") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="px-1 py-1">
                <v-row no-gutters>
                    <v-col cols="6" sm="6" md="8" lg="8">
                        <!-- show loading -->
                        <v-progress-linear
                            v-if="$apollo.queries.trackerLocations.loading"
                            dense
                            indeterminate
                            style="z-index: 800;"
                            color="primary"
                            class="mb-0"
                            :height="3"
                        ></v-progress-linear>
                        <!--- MAP --->
                        <l-map
                            ref="map"
                            :zoom="zoom"
                            :min-zoom="min_zoom"
                            :max-zoom="max_zoom"
                            :center="map_center"
                            :options="{
                                attributionControl: false,
                                drawControl: false,
                                zoomControl: false,
                                worldCopyJump: true
                            }"
                        >
                            <!--- TILE LAYER --->
                            <l-tile-layer
                                class="mapEdit"
                                :url="here_map_url"
                            ></l-tile-layer>

                            <!--- ZOOM CONTROL --->
                            <l-control-zoom
                                position="bottomright"
                            ></l-control-zoom>

                            <!--- RECENTER BUTTON ---->
                            <l-control position="bottomleft">
                                <v-row dense>
                                    <v-col cols="2">
                                        <v-btn
                                            @click="recenterMap"
                                            fab
                                            tile
                                            x-small
                                        >
                                            <v-icon color="primary"
                                                >my_location</v-icon
                                            >
                                        </v-btn>
                                    </v-col>
                                    <v-col
                                        cols="10"
                                        class="pl-1 d-flex"
                                        v-if="triggerRadius"
                                    >
                                        <v-card
                                            width="250"
                                            tile
                                            class="pl-1"
                                            elevation="3"
                                            outlined
                                        >
                                            <v-slider
                                                v-model="radius"
                                                max="200000"
                                                min="1000"
                                                thumb-color="primary"
                                                track-color="primary"
                                                hide-details
                                                class="mt-0"
                                                @change="editCircle(radius)"
                                            >
                                                <template v-slot:label>
                                                    <span class="ml-1 pr-0"
                                                        >{{ radius }} m</span
                                                    >
                                                </template>
                                            </v-slider>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </l-control>
                            <!--- MAP SEARCH BAR --->
                            <l-control position="topleft">
                                <div class="d-flex">
                                    <!--- SEARCH BUTTON I/O --->
                                    <v-btn
                                        fab
                                        tile
                                        x-small
                                        v-if="!showMapSearchBar"
                                        @click.stop.prevent="
                                            showMapSearchBar = !showMapSearchBar
                                        "
                                        ><v-icon color="primary"
                                            >search</v-icon
                                        ></v-btn
                                    >
                                    <!--- SEARCH BAR/AUTOCOMPLETE --->
                                    <v-autocomplete
                                        v-model="selectLocation"
                                        :search-input.sync="search"
                                        :items="itemsLocation"
                                        :no-data-text="$t('loading.no_available')"
                                        item-text="address.label"
                                        item-value="id"
                                        solo
                                        prepend-inner-icon="search"
                                        append-icon="chevron_left"
                                        @click:append.stop.prevent="
                                            showMapSearchBar = !showMapSearchBar
                                        "
                                        @change="debounce"
                                        v-if="showMapSearchBar"
                                        attach
                                    >
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    {{
                                                        $t(
                                                            "partners.search_location"
                                                        )
                                                    }}
                                                </v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>

                                    <div class="mt-2 ml-3">
                                        <v-btn
                                            dense
                                            :loading="
                                                $apollo.queries.trackerLocations
                                                    .loading
                                            "
                                            v-if="enableHeatmap"
                                            @click="enableHeatmap = false"
                                            >{{
                                                $t("partners.hide_heatmap")
                                            }}</v-btn
                                        >

                                        <v-btn
                                            dense
                                            :loading="
                                                $apollo.queries.trackerLocations
                                                    .loading
                                            "
                                            v-if="!enableHeatmap"
                                            @click="enableHeatmap = true"
                                            >{{
                                                $t("partners.show_heatmap")
                                            }}</v-btn
                                        >
                                    </div>
                                </div>
                            </l-control>
                        </l-map>
                    </v-col>
                    <v-col cols="6" sm="6" md="4" lg="4" class="pr-2 pl-2">
                        <v-card flat>
                            <v-card-text class="px-0 mx-0  maxSize">
                                <!--- MAP FORM/INPUTS --->
                                <v-form ref="form" v-model="validate">
                                    <v-row>
                                        <!--- LOCATION NAME --->

                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="locationName"
                                                outlined
                                                hide-details
                                                :label="
                                                    $t('headers.name') + '*'
                                                "
                                                :rules="[rules.required]"
                                                :placeholder="
                                                    $t('general.example')
                                                "
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <!--- LOCATION CITY --->
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="locationCity"
                                                :rules="[rules.required]"
                                                outlined
                                                hide-details
                                                :label="
                                                    $t('partners.city') + '*'
                                                "
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <!--- LOCATION COUNTRY --->
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="locationCountry"
                                                :rules="[rules.required]"
                                                outlined
                                                hide-details
                                                :label="
                                                    $t('partners.country') + '*'
                                                "
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <!--- LOCATION ADRESS --->
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="locationFullAddress"
                                                :rules="[rules.required]"
                                                outlined
                                                hide-details
                                                :label="$t('partners.address')"
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <!--- TAGS --->
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="tags"
                                                :items="tagsChoices"
                                                :no-data-text="$t('loading.no_available')"
                                                small-chips
                                                multiple
                                                deletable-chips
                                                dense
                                                outlined
                                                hide-details
                                                :label="$t('headers.tags')"
                                                attach
                                            ></v-autocomplete>
                                        </v-col>
                                        <!-- <v-col cols="3" sm="3">
                                        <v-row no-gutters>
                                            <v-col cols="9">
                                                <v-switch
                                                    v-model="publicLocation"  
                                                    hide-details
                                                    label="Public Location"
                                                    class="mt-0"
                                                ></v-switch>
                                            </v-col>
                                            <v-col cols="2" class="mt-n1">
                                                <v-tooltip bottom class="">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                        color="primary"
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon
                                                        >
                                                            <v-icon>info</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        Public location examples:<br>
                                                        Airport, Sea Port, Warehouse
                                                    </span>
                                                </v-tooltip>
                                            </v-col >
                                        </v-row> 
                                    </v-col> -->
                                    </v-row></v-form
                                >
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="resetLocation" rounded>{{
                                    $t("general.cancel")
                                }}</v-btn>
                                <v-btn
                                    :disabled="
                                        !validate
                                    "
                                    color="primary"
                                    @click="createTenantLocation"
                                    rounded
                                    :loading="isLoadingLocationAdd"
                                    >{{ $t("general.save") }}</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<style scoped>
.cursorPen {
    cursor: url("https://img.icons8.com/material-rounded/24/000000/edit--v1.png"),
        default;
}
.map {
    cursor: not-allowed;
}
</style>
<script>
L;
LDraw;
import L from "leaflet";
import LDraw from "leaflet-draw";
import gql from "graphql-tag";
import { LMap, LTileLayer, LControl, LControlZoom } from "vue2-leaflet";
import helper from "@/utils/helper.js";
import "leaflet.heat";
import Config from "@/utils/config.json";

export default {
    name: "HereMap",
    components: {
        LMap,
        LTileLayer,
        LControl,
        LControlZoom
    },
    props: {
        openAddLocationDialog: {
            type: Boolean
        },
        viewType: {
            type: String,
            default: null
        },
        partner: {
            type: Object
        }
    },
    apollo: {
        trackerLocations: {
            query: gql`
                query trackerLocations($deviceTimeGte: DateTime) {
                    trackerLocations(deviceTime_Gte: $deviceTimeGte) {
                        edges {
                            node {
                                id
                                latitude
                                longitude
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    deviceTime_Gte: new Date(
                        new Date().setMonth(new Date().getMonth() - 3)
                    )
                };
            },
            skip() {
                return !this.enableHeatmap || this.heatmapLayer !== null;
            },
            result({ data }) {
                let points = data.trackerLocations.edges.map(edge => [
                    edge.node.latitude,
                    edge.node.longitude
                ]);

                const map = this.$refs.map?.mapObject;
                this.heatmapLayer = L.heatLayer(points, {
                    radius: 25,
                    blur: 15,
                    maxZoom: 17,
                    minOpacity: 0.5,
                    max: 1.0,
                    gradient: {
                        0.4: "blue",
                        0.6: "cyan",
                        0.7: "lime",
                        0.8: "yellow",
                        1.0: "red"
                    }
                });
                try {
                    this.heatmapLayer.addTo(map);
                } catch {
                    //do nothing
                }
            }
        }
    },
    data() {
        return {
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            },
            validate: false,
            /*******************************************************/
            /* ---------- HERE API & MAP CONFIGURATIONS ---------- */
            /*******************************************************/
            apikey: Config.VUE_APP_MAP_API_KEY, // You can get the API KEY from developer.here.com
            here_map_url:
                `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=${Config.VUE_APP_MAP_API_KEY}&ppi=320`,
            map_center: [38.73, -9.2],
            circle_center: null,
            zoom: 2,
            min_zoom: 2,
            max_zoom: 18,
            radius: 1000,
            publicLocation: false,
            trackerLocations: { edges: [] },
            heatmapLayer: null,
            enableHeatmap: false,
            triggerRadius: false,
            tags: [],
            tagsChoices: [
                {
                    text: this.$t('headers.warehouse'),
                    value: "warehouse"
                },
                {
                    text: this.$t('headers.border_post'),
                    value: "border_post"
                },
                {
                    text: this.$t('headers.cold_store'),
                    value: "cold_store"
                },
                {
                    text: this.$t('headers.empty_depot'),
                    value: "empty_depot"
                }
            ],
            /*******************************************/
            /* ---------- MAP UI VARIABLES ---------- */
            /*******************************************/
            selectedAutoComplete: null,
            searchBarValue: null,
            showMapSearchBar: true,
            searchSuggestions: null,
            selectedSuggestion: null,
            /*********************************************/
            /* ---------- DRAWING VARIABLES ------------*/
            /*********************************************/
            drawingEnabled: false,
            featureGroup: null,
            layer: null,
            automaticCircle: null,
            drawControl: null,
            drawOptions: {
                polygon: false,
                marker: false,
                polyline: false,
                circlemarker: false,
                rectangle: false,
                circle: {
                    allowIntersection: false,
                    showArea: true,
                    metric: true,
                    showRadius: true,
                    repeatMode: false
                }
            },
            locationName: null,
            locationCountry: null,
            locationCity: null,
            locationFullAddress: null,
            locationLatitude: null,
            locationLongitude: null,
            isLoadingLocationAdd: false,
            timeOutID: null,
            /* ---------- MAP SEARCH BAR ---------- */
            search: null,
            selectLocation: null,
            itemsLocation: [],

            headerChoices: [
                {
                    text: "",
                    align: "center",
                    value: "action",
                    width: "5px",
                    sortable: false
                },
                {
                    text: this.$t('headers.name'),
                    align: "center",
                    value: "name",
                    sortable: false
                },
                {
                    text: this.$t('headers.address'),
                    align: "center",
                    value: "node.user.lastName",
                    sortable: false
                },
                {
                    text: this.$t('partners.country'),
                    align: "center",
                    value: "node.user.email",
                    sortable: false
                },
                {
                    text: this.$t('partners.city'),
                    align: "center",
                    value: "node.user.require2fa",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "edit",
                    width: "5px",
                    sortable: false
                }
            ]
        };
    },
    computed: {},
    async mounted() {},
    updated() {
        //fix map render everytime the component updates
        this.resizeMap();
        this.allowDrawing(false);
    },
    watch: {
        async search(val) {
            if (val === null || val === undefined || val.length === 0) {
                this.itemsLocation = [];
                return;
            } else {
                try {
                    let formatedInput = val.replace(/\s/g, "+");
                    let response = await fetch(
                        "https://autocomplete.search.hereapi.com/v1/autocomplete?q=" +
                            formatedInput +
                            "&apiKey=" +
                            this.apikey
                    );

                    let data = await response.json();
                    this.itemsLocation = data.items.map(item => {
                        return item;
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        },
        map_center: function(val) {
            let map = this.$refs.map.mapObject;
            if (
                this.locationFullAddress ||
                this.locationCountry ||
                this.locationCity
            ) {
                map.setZoom(12);
                map.setView(val);
            }
        },
        enableHeatmap() {
            if (this.enableHeatmap == true) {
                try {
                    this.heatmapLayer.addTo(this.$refs.map.mapObject);
                } catch {
                    //do nothing
                }
            } else {
                try {
                    this.$refs.map.mapObject.removeLayer(this.heatmapLayer);
                } catch {
                    //do nothing
                }
            }
        }
    },
    methods: {
        resetLocation() {
            // Remove drawn layers //
            const map = this.$refs.map.mapObject;
            if (this.layer != null) map.removeLayer(this.layer);
            if (this.drawingEnabled == true) this.allowDrawing(false);
            if (this.automaticCircle != null) this.automaticCircle = null;
            this.featureGroup?.clearLayers();
            // scroll dialog back up if necessary //
            let dialog = window.document.getElementsByClassName(
                "v-dialog v-dialog--active v-dialog--persistent"
            );

            dialog[0]?.scrollTo(0, 0);
            // Reset zoom //
            map.setZoom(2);
            // Reset variables value //

            this.isTrackerStockLocation = false;
            this.locationName = null;
            this.locationCountry = null;
            this.locationCity = null;
            this.locationCode = null;
            this.locationFullAddress = null;
            this.locationLatitude = null;
            this.locationLongitude = null;
            this.drawingEnabled = false;
            this.selectLocation = null;
            this.search = null;
            this.map_center = [12, 30];
            this.itemsLocation = [];
            this.showMapSearchBar = true;
            this.radius = null;
            this.tags = [];
            try {
                if (this.enableHeatmap) {
                    this.$refs.map.mapObject.removeLayer(this.heatmapLayer);
                }
            } catch {
                //do nothing
            }
            this.heatmapLayer = null;
            this.enableHeatmap = false;

            this.$refs.form.resetValidation();
            // Close dialog //
            this.$emit("closeMap");
            this.triggerRadius = false;
        },
        recenterMap() {
            const map = this.$refs.map.mapObject;
            if (this.locationLatitude && this.locationLongitude)
                map.panTo([this.locationLatitude, this.locationLongitude]);
        },
        editCircle(radius) {
            const map = this.$refs.map.mapObject;
            if (this.automaticCircle != null) {
                map.removeLayer(this.automaticCircle);
                this.automaticCircle = null;
            }

            this.drawingEnabled = true;
            this.featureGroup.clearLayers();
            this.layer = L.circle(this.circle_center, {
                radius: radius
            });
            //then add new drawn layer.
            this.featureGroup.addLayer(this.layer);
            //Event listener for when editing:
            this.layer.on("edit", e => {
                this.circle_center = e.target.getLatLng();
                this.locationLatitude = e.target.getLatLng().lat;
                this.locationLongitude = e.target.getLatLng().lng;
                this.radius = e.target.getRadius();
            });
        },
        allowDrawing(createCircleAuto) {
            const map = this.$refs.map.mapObject;
            if (this.featureGroup == null) {
                // Add new FeatureGroup from leaflet for Draw objects on map. Group together elements of the maps including: markers, geoJSON, polylines and polygon, tooltip and popup.
                this.featureGroup = new window.L.FeatureGroup();
                this.featureGroup.addTo(map);
            }
            // Create leaflet draw control menu
            if (this.drawControl == null) {
                this.drawControl = new window.L.Control.Draw({
                    position: "topright",
                    draw: this.drawOptions,
                    edit: {
                        featureGroup: this.featureGroup,
                        remove: true,
                        edit: {
                            selectedPathOptions: {
                                color: "#000",
                                fillColor: "#000"
                            }
                        }
                    }
                });
                // Add draw control to the map
                map.addControl(this.drawControl);
            }
            if (createCircleAuto) {
                if (this.automaticCircle == null) {
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });
                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                } else {
                    this.drawingEnabled = true;
                    this.featureGroup.clearLayers();
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });
                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                }
            } else {
                if (this.circle_center) {
                    this.drawingEnabled = true;
                    this.featureGroup.clearLayers();
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });

                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                }
            }

            //capture when the drag and draw circle is clicked
            map.on("draw:created", e => {
                this.triggerRadius = true;
                this.handleEventData(e);
            });
            //capture when the drag circle is clicked
            map.on("draw:editstart", () => {
                this.triggerRadius = true;
            });
            //capture when edit stop.
            map.on("draw:editstop", () => {
                this.triggerRadius = false;
            });
            //capture the event when circle is deleted and reset the values.
            map.on("draw:deleted", () => {
                this.triggerRadius = false;
                this.locationLatitude = null;
                this.locationLongitude = null;
                this.radius = null;
                this.circle_center = null;
                this.automaticCircle = null;
                this.featureGroup.clearLayers();
                this.drawingEnabled = false;
                this.locationName = null;
                this.locationCountry = null;
                this.locationCity = null;
                this.locationFullAddress = null;
            });
        },
        handleEventData(event) {
            // HANDLE DATA COMING FROM DRAWING FINISHED AND EDITING CIRCLE
            this.radius = event.layer
                ? event.layer._mRadius
                : event.target._mRadius;
            this.locationLatitude = event.layer
                ? event.layer._latlng.lat
                : event.target._latlng.lat;
            this.locationLongitude = event.layer
                ? event.layer._latlng.lng
                : event.target._latlng.lng;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getlocationData(
                    this.locationLatitude,
                    this.locationLongitude
                );
            }, 500);
        },
        async mapSearchHandler(id) {
            if (
                this.selectLocation == null ||
                this.selectLocation == undefined
            ) {
                return;
            }

            try {
                let response = await fetch(
                    "https://lookup.search.hereapi.com/v1/lookup?id=" +
                        id +
                        "&lang=en-US&apiKey=" +
                        this.apikey
                );

                //const map = this.$refs.map.mapObject;
                let data = await response.json();
                this.locationLatitude = data.position.lat;
                this.locationLongitude = data.position.lng;
                this.locationCountry = data.address.countryName;
                this.locationCity = data.address.city;
                this.locationCode = data.address.postalCode;
                this.locationFullAddress = data.address.label;
                this.circle_center = data.position;
                this.map_center = data.position;
            } catch (err) {
                console.log(err);
            }
        },
        async getlocationData(lat, lng) {
            if (
                lat == null ||
                lat == undefined ||
                lng == null ||
                lng == undefined
            )
                return;

            /*** GET COORDINATES FROM CLICK EVENT AND ASSIGN THEM TO CIRCLE POSITION ***/
            this.circle_center = [lat, lng];

            /*** GET INFO FROM API WITH THOSE COORDINATES ***/
            try {
                let response = await fetch(
                    "https://revgeocode.search.hereapi.com/v1/revgeocode?at=" +
                        lat +
                        "%2C" +
                        lng +
                        "&lang=en-US&apiKey=" +
                        this.apikey
                );

                let data = await response.json();

                /*** ASSIGN RECEIVED DATA TO VARIABLES ***/
                this.selectLocation = null;
                this.search = null;
                this.locationCountry = data?.items[0]?.address?.countryName;
                this.locationCity = data?.items[0]?.address?.city;
                this.locationCode = data?.items[0]?.address?.postalCode;
                this.locationFullAddress = data?.items[0]?.address?.label;
                this.locationLatitude = lat;
                this.locationLongitude = lng;
                this.map_center = [lat, lng];
            } catch (err) {
                console.log(err);
            }
        },
        resizeMap() {
            /*** THIS FUNCTION IS RESPONSIBLE FOR FIXING MAP RENDERING ISSUE(only 1 or 2 tiles in the corner), RUN IT ON THE RIGHT LIFECYCLE HOOK ***/
            setTimeout(() => {
                //mapObject is an object which gives you acess to leaflet methods
                this.$refs.map.mapObject?.invalidateSize();
            }, 50);
        },
        debounce(id) {
            if (id == null || id == undefined || id.length == 0) {
                return;
            }
            clearTimeout(this.timeOutID);
            this.timeOutID = setTimeout(() => {
                this.mapSearchHandler(id);
            }, 500);
        },
        createTenantLocation() {
            this.isLoadingLocationAdd = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation saveLocation(
                            $input: CreateTenantLocationInput!
                        ) {
                            createTenantLocation(input: $input) {
                                tenantLocation {
                                    id
                                    name
                                    city
                                    state
                                    country
                                    fullAddress
                                    tags
                                    latitude
                                    longitude
                                    radius
                                    polygon
                                    locationType
                                    geometryType
                                    tenantPartner {
                                        id
                                        name
                                    }
                                    tenant {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantPartnerId: this.partner.id,
                            locationType: "partner_location",
                            name: this.locationName,
                            city: this.locationCity,
                            state: null,
                            country: this.locationCountry,
                            fullAddress: this.locationFullAddress,
                            isTrackerStockLocation: false,
                            tags: this.tags,
                            geometryType: null,
                            latitude: this.locationLatitude,
                            longitude: this.locationLongitude,
                            radius: this.radius
                        }
                    }
                })
                .then(() => {
                    this.$emit("closeMap");
                    this.$emit("updateLocationsTable");
                    this.resetLocation();
                })
                .catch(error => {
                    //may happen because location is to big (decimal values huge)
                    console.log(error);
                })
                .finally(() => {
                    this.isLoadingLocationAdd = false;
                });
        }
    }
};
</script>
<style scoped>
.maxSize {
    width: 100%;
    max-height: 1000px;
    min-height: 400px;
}
.maxSize {
    width: 100%;
    max-height: 1000px;
    min-height: 400px;
}
</style>
