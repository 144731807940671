<template>
    <v-container fluid class="pa-0 container" v-if="editor">
        <v-row dense class="">
            <v-col cols="12">
                <div outlined class="editor-container" @click="triggerEditor">
                    <EditorContent :editor="editor" />
                </div>
            </v-col>
            <v-col cols="12" class="pa-1">
                <v-card class="background2 pa-1" flat>
                    <TipTapMenuBar 
                        :editor="editor" 
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import TipTapMenuBar from "./TipTapMenuBar.vue";
import { Highlight } from "@tiptap/extension-highlight";
import { TaskList } from "@tiptap/extension-task-list";
import { TaskItem } from "@tiptap/extension-task-item";
import { CharacterCount } from "@tiptap/extension-character-count";
import Text from "@tiptap/extension-text";

/* import Collaboration from "@tiptap/extension-collaboration";
import * as Y from "yjs"; */

export default {
    components: {
        EditorContent,
        TipTapMenuBar
    },

    props: {
        value: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            editor: null
        };
    },
    computed: {
        /*  editor:{
            get() {
                return new Editor({
                    content: this.value,
                    extensions: [StarterKit],
                    onUpdate: ({ editor }) => {
                        this.$emit("input", editor.getHTML());
                    }
                });
            },
            set(editor) {
               this.$emit("input", editor.getHTML());
            }
        } */
    },
    watch: {
        value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value;

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return;
            }

            this.editor.commands.setContent(value, false);
        }
    },
    mounted() {
        //const ydoc = new Y.Doc();
        /* this.editor = new Editor({
            content: this.value,
            extensions: [StarterKit],

            onUpdate: () => {
                // HTML
                this.$emit("input", this.editor.getHTML());

                // JSON
                // this.$emit('input', this.editor.getJSON())
            }
        });  */

        this.editor = new Editor({
            extensions: [
                StarterKit.configure({
                    history: false
                }),
                /* Collaboration.configure({
                    document: ydoc
                }), */
                // Text,
                Highlight,
                TaskList,
                TaskItem,
                CharacterCount.configure({
                    limit: 10000
                })
            ],
            content: this.value,
            onUpdate: ({ editor }) => {
                this.$emit('input', this.editor.getHTML());
            }
        });
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    methods: {
        triggerEditor() {
            //activate the editor
            this.editor.chain().focus();
        }
    }
};
</script>
<style scoped>
.tiptap {
    :first-child {
        margin-top: 0;
    }
}
.container {
    height: 100%;
    width: 100%;
}
.editor-container {
    height: 300px; /* Set your desired height here */
    overflow-y: auto; /* Add scroll if content overflows */
    border: 1px solid #ccc; /* Optional: Add a border */
    padding: 5px; /* Optional: Add padding */
}


</style>
<style>
.ProseMirror:focus {
    outline: none;
}
</style>
