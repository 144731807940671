<template>
    <v-container fluid class="pa-0">
        <!-- USERS FILTER/TOOLBAR -->
        <v-row class="px-6 mt-2 mb-4">
            <!-- ADD -->
            <v-spacer></v-spacer>
            <!-- <v-btn
                rounded
                color="primary"
                @click="
                    isColdChaStaff
                        ? (openAddStaff = true)
                        : (openAddUser = true)
                "
            >
                {{$t("partners.add_user1")}}
            </v-btn> -->
        </v-row>
        <UsersFilter
            class="mx-3"
            v-on:updateUserTable="refetchTableData"
            v-on:filter-changed="onFilterChanged"
        ></UsersFilter>
        <!-- TABLE -->
        <UsersTable
            ref="usersTable"
            :usersFilter="usersFilter"
            v-on:click:row="onUserSelected"
            class="mx-1"
        ></UsersTable>
        <!-- DETAIL DRAWER -->
        <v-navigation-drawer
            v-model="userDetailDrawer"
            :width="userDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <UserDetail
                    ref="userDetail"
                    v-if="selectedUser"
                    :key="selectedUser.node.id"
                    :user="selectedUser"
                    :isFullScreen="isUserDetailDrawerFullScreen"
                    v-on:changeFullScreen="onUserDetailDrawerFullScreenChange"
                    v-on:refreshDataTable="refetchTableData"
                    v-on:close="userDetailDrawer = false"
                ></UserDetail>
            </div>
        </v-navigation-drawer>
         
       <!--  <AddUserDialog
            ref="addUserDialog"
            :openAddUserDialog="openAddUser"
            v-on:refreshDataTable="refetchTableData"
            v-on:closeAddUserDialog="openAddUser = false"
            :tenant="tenant"
        >
        </AddUserDialog>

       
        <AddStaffDialog
            ref="addStaffDialog"
            :openAddUserDialog="openAddStaff"
            v-on:refreshDataTable="refetchTableData"
            v-on:closeAddUserDialog="openAddStaff = false"
        >
        </AddStaffDialog> -->
    </v-container>
</template>
<script>
import helper from "@/utils/helper.js";
import UsersFilter from "@/components/users/UsersFilter.vue";
import UsersTable from "@/components/users/UsersTable.vue";
import UserDetail from "@/components/users/UserDetail.vue";
/* import AddUserDialog from "@/components/users/create/AddUserDialog.vue";
import AddStaffDialog from "@/components/staff/users/create/AddStaffDialog.vue"; */
import { eventBus } from "@/main.js";

export default {
    title: "Users",
    components: {
        UsersFilter,
        UsersTable,
        UserDetail,
        /* AddUserDialog, 
        AddStaffDialog */
    },
    props: {
        tenant: {
            type: Object,
            default: null
        },
        isColdChaStaff: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searchInput: null,
            shouldStickyFilter: false,
            userDetailDrawer: false,
            userDetailFullScreen: false,
            usersFilter: {
                first: 20
            },
            goTopShow: false,
            isLoading: false,
            menu: false,
            selectedUser: null,
            openAddUser: false,
            openAddStaff: false
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        userDetailDrawerWidth() {
            if (this.userDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isUserDetailDrawerFullScreen() {
            return this.userDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.onResize();
    },
    methods: {
        onUserSelected(user) {
            this.selectedUser = user;
            this.userDetailDrawer = true;
        },

        onUserDetailDrawerFullScreenChange(val) {
            this.userDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },

        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },

        onLoading(val) {
            this.isLoading = val;
        },

        onFilterChanged(filter) {
            this.usersFilter = filter;
        },

        refetchTableData() {
            this.$refs.usersTable.$apollo.queries.users.refetch();
        },

        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        }
    }
};
</script>
<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
