import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";
import DefaultHome from "../views/DefaultHome.vue";
import StaffDashboard from "../views/StaffDashboard.vue";
import Dashboard from "../views/Dashboard.vue";
import Trips from "../views/Trips.vue";
import TripsNew from "../views/TripsNewUi.vue";
import Trackers from "../views/Trackers.vue";
import Partners from "../views/Partners.vue";
import PreviewPartners from "../views/PreviewPartners.vue";
import Carriers from "../views/Carriers.vue";
import PreviewCarriers from "../views/PreviewCarriers.vue";
import Locations from "../views/Locations.vue";
import PreviewLocations from "../views/PreviewLocations.vue";
import Weather from "../views/Weather.vue";
import Groups from "../views/Groups.vue";
import ActionGroups from "../views/ActionGroups.vue";
import Profile from "../views/Profile.vue";
import Users from "../views/Users.vue";
import PreviewUsers from "../views/PreviewUsers.vue";
import ProfileDetails from "../components/userSettings/ProfileDetails.vue";
import CustomAlertRules from "../views/CustomAlertRules.vue";
import StandardAlertRules from "../views/StandardAlertRules.vue";
import AlertRules from "../views/AlertRules.vue";
import Tenants from "../views/Tenants.vue";
import CreateLocation from "@/components/locations/create/CreateLocation.vue";
import PreviewCreatePartner from "@/components/partners/create/PreviewCreatePartner.vue";
// import SharedTrips from "../views/SharedTrips.vue";
import UsersStaff from "@/components/users/UsersStaff.vue";
import Staff from "@/views/Staff.vue";
import AdminSettings from "@/views/AdminSettings.vue";
import PreviewAdminSettings from "@/views/PreviewAdminSettings.vue";
import SettingsDetails from "@/components/adminsettings/SettingsDetails.vue";
import Freights from "@/views/Freights.vue";
import StaffUsers from "@/components/staff/users/StaffUsers.vue";
import StaffGroups from "@/components/staff/groups/StaffGroups.vue";
import TripTemplates from "@/views/TripTemplates.vue";
import BookingOrderTemplates from "@/components/bookings/BookingOrderTemplates.vue";
import BookingOrders from "@/components/bookings/BookingOrders.vue";
import QxBookings from "../views/QxBookings.vue";
import PreviewCreateTrip from "@/components/trips/create/PreviewCreateTrip.vue";
import PreviewAddUser from "@/components/users/create/PreviewAddUser.vue";
import PreviewAddGroup from "@/components/groups/create/PreviewAddGroup.vue";


import helper from "@/utils/helper.js";
import {
    TripPermissions,
    TrackerPermissions,
    UserPermissions,
    TenantPermissions,
    BookingPermissions
} from "@/core/enum";

import { ACCESS_TOKEN_KEY } from "@/vue-apollo";

//preview uis
import PreviewTrackers from "../views/PreviewTrackers.vue";
import PreviewAddTracker from "@/components/trackers/create/PreviewAddTracker.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/resetpassword/:uidb64/:token",
        name: "resetpassword",
        component: ResetPassword
    },
    {
        path: "/",
        name: "home",
        component: DefaultHome,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/staffdashboard",
        name: "staffdashboard",
        component: StaffDashboard,
        meta: {
            requiresAuth: true,
            requiresStaff: true,
            permission: TenantPermissions.VIEW_TENANT_DASHBOARD
        }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true,
            permission: ""
        }
    },
    {
        path: "/trips",
        name: "trips",
        component: Trips,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TripPermissions.VIEW_TRIP
        }
    },
    {
        path: "/previewtrips",
        name: "previewtrips",
        component: TripsNew,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TripPermissions.VIEW_TRIP
        }
    },
    {
        path: "/newtrips",
        name: "previewcreatetrip",
        component: PreviewCreateTrip,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TripPermissions.VIEW_TRIP
        }
    },
    {
        path: "/freights",
        name: "freights",
        component: Freights,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TripPermissions.VIEW_TRIP_FREIGHT
        }
    },
    {
        path: "/sharedtrips",
        name: "sharedtrips",
        component: Trips,
        meta: {
            requiresAuth: true,
            requiresTenantPartner: true,
            permission: ""
        }
    },
    {
        path: "/previewsharedtrips",
        name: "previewsharedtrips",
        component: TripsNew,
        meta: {
            requiresAuth: true,
            requiresTenantPartner: true,
            permission: ""
        }
    },
    {
        path: "/qxbookings",
        name: "qxbookings",
        component: QxBookings,
        meta: {
            requiresAuth: true,
            // requiresTenantUser: true,
            permission: BookingPermissions.VIEW_BOOKING_ORDER_QX
        },
        children: [
            {
                path: "/qxorders",
                name: "Orders",
                component: BookingOrders,
                meta: {
                    requiresAuth: true,
                    // requiresTenantUser: true,
                    // requiresTenantPartner: true,
                    permission: BookingPermissions.VIEW_BOOKING_ORDER_QX
                }
            },
            {
                path: "/qxtemplates",
                name: "Templates",
                component: BookingOrderTemplates,
                meta: {
                    requiresAuth: true,
                    // requiresTenantUser: true,
                    // requiresTenantPartner: true,
                    permission: BookingPermissions.VIEW_BOOKING_ORDER_QX
                }
            }
        ]
    },
    {
        path: "/trackers/:id?",
        name: "trackers",
        component: Trackers,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TrackerPermissions.VIEW_TRACKER
        }
    },
    {
        path: "/previewtrackers/:id?",
        name: "previewtrackers",
        component: PreviewTrackers,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TrackerPermissions.VIEW_TRACKER
        }
    },
    {
        path: "/previewaddtrackers",
        name: "previewaddtracker",
        component: PreviewAddTracker,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TrackerPermissions.VIEW_TRACKER
        }
    },
    {
        path: "/partners",
        name: "partners",
        component: Partners,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_PARTNER
        },
        children: [
            {
                path: "/consignees",
                name: "Consignees",
                component: Partners,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_PARTNER
                }
            },
            //do it for consignors, forwarders, others
            {
                path: "/consignors",
                name: "Consignors",
                component: Partners,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_PARTNER
                }
            },
            {
                path: "/forwarders",
                name: "Forwarders",
                component: Partners,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_PARTNER
                }
            },
            {
                path: "/others",
                name: "Others",
                component: Partners,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_PARTNER
                }
            }
        ]
    },
    {
        path: "/previewpartners",
        name: "previewpartners",
        component: PreviewPartners,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_PARTNER
        }
    },
    {
        path: "/previewcreatepartner",
        name: "createpartners",
        component: PreviewCreatePartner,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_PARTNER
        }
    },
    {
        path: "/previewcarriers",
        name: "previewcarriers",
        component: PreviewCarriers,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_PARTNER
        }
    },
    {
        path: "/carriers",
        name: "carriers",
        component: Carriers,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_PARTNER
        }
    },
    {
        path: "/previewcreatecarriers",
        name: "createcarriers",
        component: PreviewCreatePartner,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_PARTNER
        }
    },
    {
        path: "/locations",
        name: "locations",
        component: Locations,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_LOCATION
        },
        children: [
            {
                path: "/mylocations",
                name: "mylocations",
                component: Locations,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_LOCATION
                }
            },
            {
                path: "/partnerslocations",
                name: "partnerslocations",
                component: Locations,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_LOCATION
                }
            },
            {
                path: "/publiclocations",
                name: "publiclocations",
                component: Locations,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_LOCATION
                }
            }
        ]
    },
    {
        path: "/createlocation",
        name: "createlocation",
        component: CreateLocation,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_PARTNER
        }
    },
    {
        path: "/previewlocations",
        name: "previewlocations",
        component: PreviewLocations,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_PARTNER
        }
        /*  children: [
            {
                path: "/previewmylocations",
                name: "previewmylocations",
                component: PreviewLocations,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_LOCATION
                }
            },
            {
                path: "/previewpartnerslocations",
                name: "previewpartnerslocations",
                component: PreviewLocations,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_LOCATION
                }
            },
            {
                path: "/previewpubliclocations",
                name: "previewpubliclocations",
                component: PreviewLocations,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_LOCATION
                }
            },
            {
                path: "/previewweather",
                name: "previewpubliclocations",
                component:Weather,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_LOCATION_WEATHER_FORECAST
                }
            }
        ] */
    },
    {
        path: "/weather",
        name: "weather",
        component: Weather,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_LOCATION_WEATHER_FORECAST
        }
    },
    {
        path: "/users",
        name: "users",
        component: Users,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: UserPermissions.VIEW_USER
        },
        children: [
            {
                path: "/usersStaff",
                name: "usersStaff",
                component: UsersStaff,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: UserPermissions.VIEW_USER
                }
            },
            {
                path: "/groups",
                name: "groups",
                component: Groups,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: UserPermissions.VIEW_USER
                }
            }
        ]
    },
    {
        path: "/previewusers",
        name: "previewusers",
        component: PreviewUsers,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: UserPermissions.VIEW_USER
        }
    },
    {
        path: "/previewcreateuser",
        name: "createuser",
        component: PreviewAddUser,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: UserPermissions.VIEW_USER
        }
    },
    {
        path: "/previewcreategroup",
        name: "creategroup",
        component: PreviewAddGroup,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: UserPermissions.VIEW_USER
        }
    },
    {
        path: "/alertrules",
        name: "alertrules",
        component: AlertRules,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_ALERT_RULE
        },
        children: [
            {
                path: "/customrules/:id?",
                name: "customrules",
                component: CustomAlertRules,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_ALERT_RULE
                }
            },
            {
                path: "/actiongroups",
                name: "actiongroups",
                component: ActionGroups,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_ACTION_GROUP
                }
            },
            {
                path: "/standardrules",
                name: "standardrules",
                component: StandardAlertRules,
                meta: {
                    requiresAuth: true,
                    requiresTenantUser: true,
                    permission: TenantPermissions.VIEW_TENANT_ALERT_RULE
                }
            }
        ]
    },
    {
        path: "/tenants/:id?",
        name: "tenants",
        component: Tenants,
        meta: {
            requiresAuth: true,
            requiresStaff: true,
            permission: TenantPermissions.VIEW_TENANT
        }
    },
    {
        path: "/trackersstock",
        name: "trackersstock",
        component: Trackers,
        meta: {
            requiresAuth: true,
            requiresStaff: true,
            permission: TrackerPermissions.VIEW_TRACKER
        }
    },
    {
        path: "/staff",
        name: "staff",
        component: Staff,
        meta: {
            requiresAuth: true,
            requiresSuperUser: true,
            permission: UserPermissions.VIEW_USER
        },
        children: [
            {
                path: "/staffusers",
                name: "staffusers",
                component: StaffUsers,
                meta: {
                    requiresAuth: true,
                    requiresSuperUser: true,
                    permission: UserPermissions.VIEW_USER
                }
            },
            {
                path: "/staffgroups",
                name: "staffgroups",
                component: StaffGroups,
                meta: {
                    requiresAuth: true,
                    requiresSuperUser: true,
                    permission: UserPermissions.VIEW_USER
                }
            }
        ]
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
        children: [
            {
                path: "details",
                name: "details",
                component: ProfileDetails
            }
        ],
        meta: {
            requiresAuth: true,
            permission: ""
        }
    },
    {
        path: "/adminsettings",
        name: "adminsettings",
        component: AdminSettings,
        children: [
            {
                path: "settingsdetails",
                name: "settingsdetails",
                component: SettingsDetails
            }
        ],
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_SETTINGS
        }
    },
    {
        path: "/previewadminsettings",
        name: "previewadminsettings",
        component: PreviewAdminSettings,
        children: [
            {
                path: "previewsettingsdetails",
                name: "previewsettingsdetails",
                component: SettingsDetails
            }
        ],
        meta: {
            requiresAuth: true,
            requiresTenantUser: true,
            permission: TenantPermissions.VIEW_TENANT_SETTINGS
        }
    },
    {
        path: "/triptemplates",
        name: "triptemplates",
        component: TripTemplates,
        meta: {
            requiresAuth: true,
            requiresTenantUser: true
        }
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    function checkIfMe() {
        if (helper.me().id == null && helper.isLoggedIn()) {
            setTimeout(checkIfMe, 50); // delay the next iteration by 50 milliseconds
        } else {
            if (to.matched.some(record => record.meta.requiresAuth)) {
                if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
                    if (to.matched.some(record => record.meta.requiresStaff)) {
                        if (
                            helper.isStaff() &&
                            to.matched.some(
                                record => helper.hasPermission(record.meta.permission) || record.meta.permission === ""
                            )
                        ) {
                            next();
                        } else {
                            next(false);
                        }
                    } else if (to.matched.some(record => record.meta.requiresSuperUser)) {
                        if (helper.isSuperUser()) {
                            next();
                        } else {
                            next(false);
                        }
                    } else if (to.matched.some(record => record.meta.requiresTenantUser)) {
                        if (to.path === "/trips" || to.path === "/newtrips") {
                            if (helper.isTenantPartner() || helper.isTenantUser()) {
                                next();
                            } else next(false);
                        } else {
                            if (
                                helper.isTenantUser() &&
                                to.matched.some(
                                    record =>
                                        helper.hasPermission(record.meta.permission) || record.meta.permission === ""
                                )
                            ) {
                                next();
                            } else {
                                next(false);
                            }
                        }
                    } else if (to.matched.some(record => record.meta.requiresTenantPartner)) {
                        if (helper.isTenantPartner()) {
                            next();
                        } else {
                            next(false);
                        }
                    } else {
                        next();
                    }
                } else {
                    next({ name: "login", query: { redirect: to.fullPath } });
                }
            } else {
                if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
                    if (helper.isTenantPartner() && !helper.isTenantUser()) {
                        next({ name: "trips", query: { type: "shared" } });
                    } else next({ name: "home" });
                } else {
                    next();
                }
            }
        }
    }

    checkIfMe();
});

export default router;
