<template>
    <v-card outlined class="rounded-lg pa-3" height="350">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <!-- <LoadingBar
                v-if="tripIsLoading"
            ></LoadingBar> -->
            <div>
                <v-card flat>
                    <!-- card title (air) -->
                    <v-card-title>
                        <v-img
                            :src="require('@/assets/description.png')"
                            max-width="25"
                            max-height="25"
                            class="mr-2"
                        ></v-img>
                        <span>{{ $t("headers.documents") }}</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="!isSharedTrip"
                            color="primary"
                            elevation="0"
                            @click="uploadDialog = true"
                        >
                            {{ $t("headers.upload") }}
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="cardContainer">
                        <LoadingBar
                            v-if="tripIsLoading"
                        ></LoadingBar>
                        <div v-if="!tripIsLoading && tripDocuments.edges.length === 0">
                            <NoData></NoData>
                        </div>
                        <v-card
                            v-for="item in tripDocuments.edges"
                            :key="item.node.id"
                            class="contentBg pb-2 rounded-lg mb-2"
                            outlined
                        >
                            <v-row dense class="px-3 mt-3" align="stretch">
                                <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6">
                                    <div class="textHeader">
                                        {{ $t("headers.name") }}
                                    </div>
                                    <div class="textBody text-capitalize">
                                        {{item.node.name }}
                                    </div>
                                </v-col>
                                <v-col cols="5" xs="5" sm="4" md="3" lg="3" xl="3" >
                                    <div class="textHeader">
                                        {{ $t("headers.format") }}
                                    </div>
                                    <div class="textBody text-capitalize">
                                        {{
                                            formatType(
                                                item.node.name
                                            ).toUpperCase()
                                        }}
                                    </div>
                                </v-col>
                                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2" >
                                    <div class="textHeader">
                                        {{ $t("headers.size") }}
                                    </div>
                                    <div class="textBody text-capitalize">
                                        {{ bytesToSize(item.node.size) }}
                                    </div>
                                </v-col>
                                <v-spacer v-if="isSmallScreen"></v-spacer>
                                <v-col cols="auto" xs="auto" sm="auto" md="auto" lg="1" xl="1" >
                                    <v-menu
                                        offset-y
                                        transition="slide-x-reverse-transition"
                                        width="250px"
                                        attach
                                        left
                                        bottom
                                        close-on-click
                                        nudge-top="-3"
                                    >
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="white">
                                            <v-list-item>
                                                <v-btn
                                                    plain
                                                    @click="
                                                        downloadDocument(
                                                            item.node.url
                                                        )
                                                    "
                                                >
                                                    <v-icon class="mr-2">download</v-icon>
                                                    <span>{{
                                                        $t("general.download")
                                                    }}</span>
                                                </v-btn>
                                            </v-list-item>
                                            <!-- <v-list-item v-if="!isSharedTrip">
                                                <v-btn
                                                    :disabled="isSharedTrip"
                                                    plain
                                                    @click="
                                                        editedDialog(item.node)
                                                    "
                                                >
                                                    <v-icon class="mr-2">edit</v-icon>
                                                    <span>
                                                        {{ $t("general.edit") }}
                                                    </span>
                                                </v-btn>
                                            </v-list-item> -->
                                            <v-list-item v-if="!isSharedTrip">
                                                <v-btn
                                                    plain
                                                    :disabled="isSharedTrip"
                                                    @click="
                                                        deletedDialog(item.node)
                                                    "
                                                >
                                                    <v-icon class="mr-2">delete</v-icon>
                                                    <span>
                                                        {{
                                                            $t("general.delete")
                                                        }}
                                                    </span>
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card-text>
                </v-card>
                <!-- Upload dialog -->
                <v-dialog
                    v-model="uploadDialog"
                    max-width="600px"
                    height="400px"
                    transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-card-title class="primary textTitle2">
                            <div class="mr-2">
                                <v-img
                                    :style="
                                        `
                                        background-color:transparent;
                                        filter: invert(100%);
                                    `
                                    "
                                    :src="require('@/assets/description.png')"
                                    max-width="30"
                                    max-height="30"
                                    class="mr-2"
                                ></v-img>
                            </div>
                            <span>
                                {{ $t("trips.upload_document") }}
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeUpload">
                                <v-icon color="white">close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="mt-2">
                            <DragAndDropFileUpload
                                v-on:upload="uploadDocuments"
                                v-on:disabledUpload="disabledUploadMethod"
                                ref="uploadFile"
                            >
                            </DragAndDropFileUpload>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="closeUpload">
                                {{ $t("general.cancel") }}
                            </v-btn>
                            <v-btn
                                color="primary"
                                elevation="0"
                                :disabled="!disabledUpload"
                                @click="$refs.uploadFile.uploadFile()"
                                :loading="loadingUploadBtn"
                            >
                                {{ $t("headers.upload") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- Edit Dialog -->
                <v-dialog
                    v-model="editDialog"
                    max-width="600px"
                    height="500px"
                    transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-card-title class="primary textTitle2">
                            <div>
                                <v-img
                                    :style="
                                        `
                                        background-color:transparent;
                                        filter: invert(100%);
                                    `
                                    "
                                    :src="require('@/assets/description.png')"
                                    max-width="30"
                                    max-height="30"
                                    class="mr-2"
                                ></v-img>
                            </div>
                            <span>
                                {{ $t("trips.edit_document") }}
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeEditDialog">
                                <v-icon color="white">close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="mt-2">
                            <v-form v-model="validate">
                                <v-textarea
                                    v-model="editItem.description"
                                    :rules="[rules.maxLength(200)]"
                                    outlined
                                    :placeholder="$t('trips.edit_add')"
                                    counter="200"
                                ></v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeEditDialog" text>{{
                                $t("general.cancel")
                            }}</v-btn>
                            <v-btn
                                :disabled="!validate"
                                color="primary"
                                elevation="0"
                                @click="editDocument(editItem)"
                                :loading="loadingEditBtn"
                                >{{ $t("general.save") }}</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="deleteDialog"
                    max-width="300px"
                    height="500px"
                    transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-card-title class="background2 textTitle">
                            <div class="mr-2">
                                <v-icon class="iconColor">warning</v-icon>
                            </div>
                            <span>
                                {{ $t("trips.delete_document") }}
                            </span>
                        </v-card-title>
                        <v-card-text class="mt-2">
                            <div>
                                <span
                                    ><h4>
                                        {{ $t("trips.sure_delete") }}
                                    </h4></span
                                >
                            </div>
                            <div class="mt-1">{{ deleteItem.name }}</div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="closeDeleteDialog"
                                
                                text
                                >{{ $t("general.cancel") }}</v-btn
                            >
                            <v-btn
                                elevation="0"
                                color="error"
                                @click="deleteDocument(deleteItem.id)"
                                :loading="loadingDeleteBtn"
                                >{{ $t("general.delete") }}</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </v-card>
</template>
<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import DragAndDropFileUpload from "@/components/base/DragAndDropFileUpload.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import Config from "@/utils/config.json";
import NoData from "@/components/base/NoData.vue";

export default {
    components: { 
        DragAndDropFileUpload, 
        LoadingBar, 
        NoPermission,
        NoData 
    },
    props: {
        trip: {
            type: Object,
            required: true
        },
        tripIsLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    apollo: {
        // tripDocuments: {
        //     query: gql`
        //         query tripDocuments(
        //             $first: Int
        //             $last: Int
        //             $before: String
        //             $after: String
        //             $tripId: ID!
        //             $isReportDoc: Boolean
        //         ) {
        //             tripDocuments(
        //                 first: $first
        //                 last: $last
        //                 before: $before
        //                 after: $after
        //                 tripId: $tripId
        //                 isReportDoc: $isReportDoc
        //             ) {
        //                 edges {
        //                     node {
        //                         id
        //                         name
        //                         size
        //                         format
        //                         url
        //                         file
        //                         description
        //                         trip {
        //                             id
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     `,
        //     variables() {
        //         return {
        //             tripId: this.trip.id,
        //             isReportDoc: false
        //         };
        //     },
        //     fetchPolicy: "cache-and-network",
        //     update(data) {
        //         return data.tripDocuments;
        //     },
        //     skip() {
        //         return !this.canAccess;
        //     }
        // }
    },
    data: function() {
        return {
            //Upload System
            uploadDialog: false,
            disabledUpload: false,
            loadingUploadBtn: false,
            //Edit System
            editDialog: false,
            validate: false,
            editItem: {},
            loadingEditBtn: false,
            //Delete System
            deleteDialog: false,
            deleteItem: {},
            loadingDeleteBtn: false,
            headers: [
                {
                    text: this.$t("headers.name"),
                    align: "left",
                    value: "node.name"
                },
                {
                    text: this.$t("headers.format"),
                    align: "center",
                    value: "node.format"
                },
                {
                    text: this.$t("headers.size"),
                    align: "center",
                    value: "node.size"
                },
                {
                    text: this.$t("headers.actions"),
                    align: "center",
                    value: "action"
                }
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    watch: {},
    computed: {
        me() {
            return helper.me();
        },
        tripDocuments(){
            let local = {edges:[]};
            if(!this.tripIsLoading)
                local = this.trip.tripDocumentSet;
            
            return local;
        },
        hasManagePermission() {
            return this.hasOrganisationPermission(
                TripPermissions.MANAGE_TRIP_DOCUMENT
            );
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1300;
        },
        primaryColor() {
            return this.$vuetify.theme.themes.light.primary;
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_DOCUMENT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_DOCUMENT,
                        this.organisationPermissions
                    )
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_DOCUMENT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (
                    !this.hasPermission(TripPermissions.VIEW_TRIP_DOCUMENT) &&
                    this.me?.tenant
                )
                    return false;
            }
            return true;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
        this.isEmpty = helper.isEmpty;
    },
    created() {
        this.bytesToSize = helper.bytesToSize;
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_documents_tab_svw"
        });
        this.setUserProperties();
        this.updateScrollbarColor(this.primaryColor);
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_documents_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_documents_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        textTruncate(str, length, ending) {
            if (length == null) {
                length = 100;
            }
            if (ending == null) {
                ending = "...";
            }
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
            } else {
                return str;
            }
        },
        /* General section */
        formatType(format) {
            if (!format) return "";
            let newFormat = format.split(".");
            return newFormat[newFormat.length - 1];
        },
        refetch() {
            this.$emit("refresh-trip");
            // return this.$apollo.queries.tripDocuments.refetch();
        },
        /* Upload section */
        disabledUploadMethod(val) {
            this.disabledUpload = val;
        },
        closeUpload() {
            this.$refs.uploadFile.clearCache();
            this.uploadDialog = false;
        },
        uploadDocuments(files) {
            this.loadingUploadBtn = true;
            let file = [];
            let uploadFields = [];

            files.forEach(e => {
                file.push(e.file);
                uploadFields.push({
                    fileName: e.file.name,
                    description:
                        e.file.description === undefined ||
                        e.file.description === null
                            ? ""
                            : e.file.description
                });
            });

            let payload = {
                tripId: this.trip.id,
                uploadFields: uploadFields,
                file: file
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation uploadTripDocument(
                            $input: UploadTripDocumentInput!
                        ) {
                            uploadTripDocument(input: $input) {
                                trip {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("headers.file_upload")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingUploadBtn = false;
                    this.refetch();
                    this.closeUpload();
                });
        },
        /* Edit section */
        editedDialog(item) {
            this.editDialog = true;
            this.editItem = { ...item };
        },
        closeEditDialog() {
            this.editDialog = false;
            this.editItem = {};
        },
        editDocument(item) {
            this.loadingEditBtn = true;
            let payload = {
                id: item.id,
                description: item.description
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation editTripDocument(
                            $input: EditTripDocumentInput!
                        ) {
                            editTripDocument(input: $input) {
                                trip {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(() => {
                    // show snackbar
                    const payload = {
                        color: "primary",
                        message: this.$t("headers.file_edited")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingEditBtn = false;
                    this.refetch();
                    this.closeEditDialog();
                });
        },
        /* Delete section */
        deletedDialog(item) {
            this.deleteDialog = true;
            this.deleteItem = { ...item };
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.deleteItem = {};
        },
        deleteDocument(id) {
            this.loadingDeleteBtn = true;
            let payload = {
                id: id
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTripDocument(
                            $input: DeleteTripDocumentInput!
                        ) {
                            deleteTripDocument(input: $input) {
                                trip {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(() => {
                    // show snackbar
                    const payload = {
                        color: "primary",
                        message: this.$t("headers.file_removed")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingDeleteBtn = false;
                    this.refetch();
                    this.closeDeleteDialog();
                });
        },
        downloadDocument(url) {
            window.open(`${url}`, "_blank");
        },
        updateScrollbarColor(color) {
            document.documentElement.style.setProperty(
                "--scrollbar-color",
                color
            );
        }
    }
};
</script>
<style scoped>
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
    color: #e6e9f5;
}
.contentBg {
    background-color: #f9fafe;
}
.cardContainer {
    height: 250px;
    overflow-y: auto;
    margin-bottom: 12px;
}
.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #555464;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
.iconColor {
    color: #0e0d35;
}
</style>
