<template>
    <v-dialog v-model="openDeleteDialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="primary">
                <v-icon color="white" class="mr-2">warning</v-icon>
                <div class="white--text">
                    {{ $t("partners.delete_partner") }}
                </div>
            </v-card-title>
            <v-card-text class="pt-2">
                <div>
                    <h4>
                        {{ $t("partners.sure_partner") }}
                    </h4>
                </div>
            </v-card-text>
            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    rounded
                    @click="$emit('closeDeletePartnerDialog')"
                    >{{ $t("general.cancel") }}</v-btn
                >
                <v-btn
                    color="primary"
                    rounded
                    :loading="isDeleting"
                    @click="deleteMaterialMutation"
                    >{{$t("general.delete")}}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "delete-dialog",
    props: ["openDeleteDialog", "partnerID"],
    data: function() {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteMaterialMutation() {
            this.isDeleting = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deletePartner(
                            $input: DeleteTenantPartnerInput!
                        ) {
                            deleteTenantPartner(input: $input) {
                                TenantPartner {
                                    id
                                    tenant {
                                        id
                                        name
                                    }
                                    createdBy {
                                        id
                                        firstName
                                        lastName
                                    }
                                    partnerType
                                    name
                                    carrierType
                                    referenceNumber
                                    notificationEnabled
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.partnerID
                        }
                    }
                })
                .then(response => {
                    response;
                })
                .finally(() => {
                    this.isDeleting = false;
                    this.$emit("refreshDataTable");
                    this.$emit("closeDeletePartnerDialog");
                });
        }
    }
};
</script>
