<template>
    <v-dialog
        max-width="500"
        v-model="openAddTrackerColumnsSelectorDialog"
        persistent
        scrollable
    >
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">edit</v-icon>
                {{ $t("trackers.excel") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-6 text-center">
                <v-row v-for="(item, i) in defaultHeaders" :key="i">
                    <v-col cols="5" style="text-align: left" class="mt-3"
                        ><span class="subtitle-1">{{ item.text }}</span></v-col
                    >
                    <v-col cols="7">
                        <v-form
                            v-if="item.value == 'serialNumber'"
                            ref="headerFormSerialNumber"
                        >
                            <v-autocomplete
                                v-model="finalHeaders[item.value]"
                                outlined
                                dense
                                :items="headers"
                                :no-data-text="$t('loading.no_available')"
                                :rules="[rules.required]"
                            >
                            </v-autocomplete></v-form
                        ><v-autocomplete
                            v-else
                            v-model="finalHeaders[item.value]"
                            outlined
                            dense
                            :items="headers"
                            :no-data-text="$t('loading.no_available')"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn color="primary" rounded @click="updateTrackers">{{
                    $t("general.save")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import helper from "@/utils/helper.js";

export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
        defaultHeaders: {
            type: Array,
            required: true
        },
        openAddTrackerColumnsSelectorDialog: {
            type: Boolean,
            required: true
        },
        trackersToAdd: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            finalHeaders: [],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t('rules.required')
            }
        };
    },
    methods: {
        closeAndResetInput() {
            this.finalHeaders = [];
            this.$emit("closeAddTrackerColumnsSelectorDialog");
            //reset validation
            this.$refs.headerFormSerialNumber[0].resetValidation();
        },
        updateTrackers() {
            if (!this.$refs.headerFormSerialNumber[0].validate()) {
                return;
            }

            let _finalTrackers = [];
            this.trackersToAdd.forEach(tracker => {
                let _tracker = {};
                for (let key in this.finalHeaders) {
                    if (
                        key == "expiryDate" &&
                        tracker[this.finalHeaders[key]]
                    ) {
                        //check if date is in excel format
                        if (
                            tracker[this.finalHeaders[key]] > 25569 &&
                            tracker[this.finalHeaders[key]] < 2958466
                        ) {
                            _tracker[key] =
                                (tracker[this.finalHeaders[key]] - 25569) *
                                86400 *
                                1000;
                        } else {
                            _tracker[key] = tracker[this.finalHeaders[key]];
                        }
                    } else {
                        _tracker[key] = tracker[this.finalHeaders[key]];
                    }
                }
                _finalTrackers.push(_tracker);
            });

            //update trackers
            this.$emit("updateTrackers", _finalTrackers);
            this.finalHeaders = [];
            this.$refs.headerFormSerialNumber[0].resetValidation();
        },
        similarHeaders(defaultHeader, header) {
            const normalizedDefaultHeader = defaultHeader
                .trim()
                .toLowerCase()
                .replace(/\s/g, "");
            const normalizedHeader = header
                .toLowerCase()
                .trim()
                .replace(/\s/g, "");
            return normalizedDefaultHeader === normalizedHeader;
        }
    },
    watch: {
        openAddTrackerColumnsSelectorDialog: {
            handler(newVal) {
                if (newVal) {
                    for (let header of this.headers) {
                        for (let defaultHeader of this.defaultHeaders) {
                            if (
                                this.similarHeaders(defaultHeader.text, header)
                            ) {
                                this.finalHeaders[defaultHeader.value] = header;
                            }
                        }
                    }
                }
            }
        }
    }
};
</script>
