<template>
    <v-dialog v-model="exportDataDialog" max-width="500" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="pa-2 mx-1"
                label
                :rounded="rounded"
                :plain="plain"
                :color="color"
                v-bind="attrs"
                v-on="on"
                :disabled="
                    selectedTrackerId == null || selectedFreightId == null
                "
            >
                <v-icon :color="iconColor">download</v-icon>
                {{ $t("general.download") }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-2">download</v-icon>
                {{ $t("trips.download_timeline") }}
            </v-card-title>
            <v-card-text>
                <template>
                    <v-row class="mt-5 align-center">
                        <v-col cols="4">
                            <span class="ml-5 subtitle-1"
                                >{{ $t("trips.file_format") }}:</span
                            >
                        </v-col>
                        <v-col cols="6"
                            ><v-select
                                auto-select-first
                                :items="fileFormatsAvailable"
                                v-model="fileFormat"
                            >
                            </v-select
                        ></v-col>
                    </v-row>
                </template>
            </v-card-text>

            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn
                    text
                    @click="closeAndResetInput"
                    :rounded ="rounded"
                    :disabled="isLoadingFile"
                    >{{ $t("general.close") }}</v-btn
                >
                <v-btn
                    color="primary"
                    :loading="isLoadingFile"
                    @click="submitExportData"
                    :rounded="rounded"
                    :outlined="outlined"
                    dense
                >
                    {{ $t("general.download") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { write, utils } from "xlsx";
import gql from "graphql-tag";

export default {
    props: {
        selectedFreightId: {
            type: String
        },
        selectedTrackerId: {
            type: String
        },
        chartStartTime: {
            type: String
        },
        chartEndTime: {
            type: String
        },
        trip: {
            type: Object
        },
        selectedTrackerSerialNumber: {
            type: String
        },
        plain: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
            default: "primary"
        },
        iconColor: {
            type: String,
            default: "white"
        },
        outlined: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            exportDataDialog: false,
            isLoadingFile: false,
            fileData: null,
            fileFormatsAvailable: [
                {
                    text: ".csv",
                    value: "csv"
                },
                {
                    text: ".xlsx",
                    value: "xlsx"
                }
            ],
            fileFormat: "csv"
        };
    },
    methods: {
        closeAndResetInput() {
            this.exportDataDialog = false;
            this.fileFormat = null;
        },
        submitExportData() {
            this.isLoadingFile = true;

            this.$apollo
                .query({
                    query: gql`
                        query downloadData(
                            $tripFreight: ID!
                            $tracker: ID!
                            $deviceTimeGte: DateTime
                            $deviceTimeLte: DateTime
                            $orderBy: String
                        ) {
                            tripFreightTimelines(tripFreight: $tripFreight) {
                                edges {
                                    node {
                                        id
                                        eventSource
                                        eventType
                                        eventTime
                                        eventName
                                    }
                                }
                            }
                            trackerMergedSensorDatas(
                                tracker: $tracker
                                deviceTime_Lte: $deviceTimeLte
                                deviceTime_Gte: $deviceTimeGte
                                orderBy: $orderBy
                            ) {
                                edges {
                                    node {
                                        battery
                                        co2
                                        deviceTime
                                        externalTemperature
                                        humidity
                                        id
                                        latitude
                                        light
                                        longitude
                                        pressure
                                        probeTemperature
                                        receiveTime
                                        temperature
                                        shock
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        tripFreight: this.selectedFreightId,
                        tracker: this.selectedTrackerId,
                        deviceTimeGte: this.chartStartTime,
                        deviceTimeLte: this.chartEndTime,
                        orderBy: "deviceTime"
                    }
                })
                .then(response => {
                    this.fileData = response.data.tripFreightTimelines.edges.map(
                        edge => edge.node
                    );
                    this.fileData = this.fileData.concat(
                        response.data.trackerMergedSensorDatas.edges.map(
                            edge => edge.node
                        )
                    );

                    // if (this.trip?.plannedDepartureDate) {
                    //     this.fileData.push({
                    //         id: "plannedDepartureDate",
                    //         eventTime: this.trip?.plannedDepartureDate,
                    //         eventType: "planned_departure_date",
                    //         eventSource: "trip",
                    //         eventDescription: "Planned Departure Date"
                    //     });
                    // }

                    // // if trip planned arrival date exists, add it to the timeline
                    // if (this.trip?.plannedArrivalDate) {
                    //     this.fileData.push({
                    //         id: "plannedArrivalDate",
                    //         eventTime: this.trip?.plannedArrivalDate,
                    //         eventType: "planned_arrival_date",
                    //         eventSource: "trip",
                    //         eventDescription: "Planned Arrival Date"
                    //     });
                    // }

                    this.fileData.sort(this.sortByDate);

                    this.fileData = this.mergeToOneObject(this.fileData);

                    try {
                        this.exportData(
                            this.fileData,
                            `${this.trip.referenceNumber}_${this.selectedTrackerSerialNumber}`
                        );
                    } catch (error) {
                        console.error(error);
                    } finally {
                        this.isLoadingFile = false;
                        this.closeAndResetInput();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        exportData(data, filename) {
            if (this.fileFormat === "csv") {
                const csv = this.convertToCSV(data);
                this.download(
                    csv,
                    `${filename}.csv`,
                    "text/csv;charset=utf-8;"
                );
            } else if (this.fileFormat === "xlsx") {
                const workbook = this.convertToXLSX(data);
                const excelBuffer = write(workbook, {
                    bookType: "xlsx",
                    type: "buffer"
                });
                this.download(
                    excelBuffer,
                    `${filename}.xlsx`,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
                );
            } else {
                console.error("Invalid format");
            }
        },

        convertToCSV(data) {
            const delimiter = ",";
            const keys = Object.keys(data[0]??"");
            const headers = keys.join(delimiter);
            const values = data.map(obj =>
                keys.map(key => obj[key]).join(delimiter)
            );
            return [headers, ...values].join("\n");
        },

        convertToXLSX(data) {
            const worksheet = utils.json_to_sheet(data);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"]
            };
            return workbook;
        },

        download(data, filename, type) {
            const blob = new Blob([data], { type });
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        sortByDate(a, b) {
            if (a.eventTime && b.deviceTime && a.eventTime === b.deviceTime)
                return -1;
            if (a.deviceTime && b.eventTime && a.deviceTime === b.eventTime)
                return 1;

            const dateA = a.deviceTime || a.eventTime;
            const dateB = b.deviceTime || b.eventTime;

            if (dateA < dateB) {
                return -1;
            } else if (dateA > dateB) {
                return 1;
            } else {
                return 0;
            }
        },
        mergeToOneObject(data) {
            data.forEach((item, index) => {
                const mergedData = {
                    Tracker: this.selectedTrackerSerialNumber,
                    Event: "",
                    "Device Time (UTC)": "",
                    "Receive Time (UTC)": "",
                    Latitude: "",
                    Longitude: "",
                    Temperature: "",
                    Humidity: "",
                    Light: "",
                    "Probe Temperature": "",
                    "External Temperature": "",
                    Pressure: "",
                    Battery: "",
                    CO2: "",
                    Shock: ""
                };
                if (item.eventTime) {
                    if (item.eventSource === "TRACKER")
                        mergedData.Tracker = item.eventName;
                    mergedData.Event = this.getEventTitle(item);
                    mergedData["Device Time (UTC)"] = item.eventTime;
                } else {
                    mergedData.Event = "Sensor";
                    mergedData["Device Time (UTC)"] = item.deviceTime;
                    mergedData["Receive Time (UTC)"] = item.receiveTime;
                    mergedData.Latitude = item.latitude;
                    mergedData.Longitude = item.longitude;
                    mergedData.Temperature = item.temperature;
                    mergedData.Humidity = item.humidity;
                    mergedData.Light = item.light;
                    mergedData["Probe Temperature"] = item.probeTemperature;
                    mergedData["External Temperature"] =
                        item.externalTemperature;
                    mergedData.Pressure = item.pressure;
                    mergedData.Battery = item.battery;
                    mergedData.CO2 = item.co2;
                    mergedData.Shock = item.shock;
                }
                data[index] = { ...mergedData };
            });
            return data;
        },
        getEventTitle(event) {
            let title = "";
            switch (event.eventType) {
                case "planned_departure_date":
                    title = this.$t('headers.planned_departure');
                    break;
                case "planned_arrival_date":
                    title = this.$t('headers.planned_arrival');
                    break;
                case "tracker_activate":
                    title = this.$t('headers.tracker_activated');
                    break;
                case "geofence_enter":
                    title = this.$t('general.entered') + " " + event.eventName;
                    break;
                case "geofence_exit":
                    title = this.$t('general.exit') + " " + event.eventName;
                    break;
                case "vessel_depart":
                    title = this.$t('trips.departed') + " " + event.eventName;
                    break;
                case "vessel_arrive":
                    title = this.$t('trips.arrived') + " " + event.eventName;
                    break;
                case "flight_depart":
                    title = this.$t('trips.departed') + " " + event.eventName;
                    break;
                case "flight_arrive":
                    title = this.$t('trips.arrived') + " " + event.eventName;
                    break;
                default:
                    return event.eventName;
            }
            return title;
        }
    }
};
</script>
