import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.goTopShow),expression:"goTopShow"}],staticStyle:{"z-index":"1"},attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","small":"","color":"primary"},on:{"click":_vm.toTop}},[_c(VIcon,[_vm._v("keyboard_arrow_up")])],1),_c('div',[_c(VRow,{staticClass:"ma-0 px-3 d-flex align-center"},[_c(VCol,[_c('h2',{staticClass:"ml-3 display-3 font-weight-medium d-flex align-top"},[_vm._v(" "+_vm._s(_vm.$t("bookings.booking_templates"))+" ")])]),_c(VCol,{staticClass:"px-0",attrs:{"cols":"auto"}})],1)],1),_c('BookingTemplatesFilter',{ref:"bookingTemplateFilter",staticClass:"mx-3",on:{"filter-changed":_vm.onFilterChanged,"template-created":_vm.onTemplateCreated,"select-mode":_vm.changeSelectMode,"delete-selected":_vm.onDeleteSelectedTemplates}}),_c('BookingTemplatesTable',{ref:"bookingTemplatesTable",staticClass:"mx-3 mt-3 mb-3",attrs:{"bookingTemplatesFilter":_vm.bookingTemplatesFilter,"selectMode":_vm.selectMode},on:{"click:row":_vm.onBookingTemplateSelected,"deleted-templates":_vm.deleteFinished}}),_c('CreateAndEditBooking',{ref:"createTemplate",attrs:{"editMode":true,"templateMode":true,"bookingOrder":_vm.selectedBookingTemplate},on:{"update:bookingOrder":function($event){_vm.selectedBookingTemplate=$event},"update:booking-order":function($event){_vm.selectedBookingTemplate=$event},"template-created":_vm.onTemplateCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }