<template>
    <v-card elevation="0" outlined v-resize="onResize" color="transparent">
        <v-card-text  class="px-0">
            <v-row dense>
                <v-col cols="12" class="py-0">
                    <div v-if="groupIsLoading && !hasData">
                        <LoadingBar></LoadingBar>
                    </div>
                    <div v-else>
                        <div v-if="hasData">
                            <v-card
                                v-for="group in groups.edges"
                                :key="group.node.id"
                                class="mb-4 rounded-lg px-3"
                                height=""
                                outlined
                                flat
                                @click.stop="partnerSelect(group)"
                            >
                                <v-card-title>
                                    <v-row class="py-1">
                                        <v-col
                                            v-for="item in mainCard(group)"
                                            :key="item.text"
                                            :cols="item.cols.xs"
                                            :xs="item.cols.xs"
                                            :sm="item.cols.sm"
                                            :md="item.cols.md"
                                            :lg="item.cols.lg"
                                            :xl="item.cols.xl"
                                        >
                                            <div class="">
                                                <div class="textHeader text-truncate">
                                                    {{ item.title }}
                                                </div>
                                                <div class="textBody text-truncate" :style="item.style">
                                                    <v-icon
                                                        v-if="item.icon"
                                                        class="mr-1"
                                                        :size="item.iconSize"
                                                        :color="item.colorIcon"
                                                        >{{ item.icon }}</v-icon
                                                    >
                                                    <span v-if="item.value || item.value > -1">
                                                        {{ item.value }}
                                                    </span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>
                        </div>
                        <div v-else-if="!groupIsLoading && !hasData">
                            <v-row class="d-flex align-center justify-center">
                                <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="text-center">
                                    <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')"></v-img>
                                    <v-card flat color="transparent" class="text-center">
                                        <v-card-title>
                                            <div class="textTitle">
                                                {{ !hasData ? $t("general.empty_data_title") : "No history" }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text class="text-center">
                                            <div class="textSubtitle">
                                                {{ !hasData ? $t("general.empty_data_subtitle_1") : "No history" }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="px-10">
                                            <v-btn color="primary" block elevation="0">
                                                {{ $t("partners.add_location1") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <!-- LOADING TRIGGER -->
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.groups.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                {{ $t("loading.load_more") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import helper from "@/utils/helper.js";

export default {
    name: "GroupsTable",
    components: {
        LoadingBar
    },
    props: {
        groupsFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        // selectedGroups: {
        //     type: Array,
        //     required: false,
        //     default: () => {
        //         return [];
        //     }
        // },
        selector: Boolean
    },
    apollo: {
        groups: {
            query: gql`
                query TenantUserGroups(
                    $tenantId: ID
                    $last: Int
                    $first: Int
                    $after: String
                    $before: String
                    $orderBy: String
                    $search: String
                ) {
                    tenantUserGroups(
                        tenantId: $tenantId
                        last: $last
                        first: $first
                        after: $after
                        before: $before
                        orderBy: $orderBy
                        search: $search
                    ) {
                        totalCount
                        pageInfo {
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                        edges {
                            node {
                                id
                                name
                                group {
                                    id
                                    userSet {
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.me?.tenant?.id,
                    ...this.groupsFilter
                    // last: null,
                    // first: 20,
                    // after: null,
                    // before: null,
                    // orderBy: null
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUserGroups;
            },
            watchLoading(isLoading) {
                this.groupIsLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            
            skip: false
        }
    },
    data() {
        return {
            groupIsLoading: false,
            groups: { edges: [] },
            openEditGroup: false,
            searchInput: "",
            selectedGroups: [],
            tableHeight: 800,
        };
    },
    watch: {
        // selectedGroups: function(val) {
        //     this.localSelectedGroups = [];
        //     this.localSelectedGroups = val;
        // },
        selector: function() {
            if (this.selector == false) this.selectedGroups = [];
        },
        selectedGroups: function() {
            this.$emit("selectedGroups", this.selectedGroups);
        }
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        hasData() {
            return this.groups?.edges?.length > 0;
        },
        hasMoreData() {
            return this.groups?.pageInfo?.hasNextPage;
        }
    },
    methods: {
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 145 + "px";
            });
        },
        partnerSelect(group) {
            this.$emit("click:row", group);
        },
        signalGroups() {
            this.$emit("selectedCheckbox", this.localSelectedGroups);
        },
        mainCard(item) {
            const { name, group } = item.node;
            let _main = [
                {
                    title: this.$t("groups.group_name"),
                    tag: "name",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    iconSize: null,
                    align: "left",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "6",
                        lg: "6",
                        xl: "6"
                    },
                    value: name
                },
                {
                    title: this.$t("general.nusers"),
                    tag: "groups",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    iconSize: null,
                    align: "left",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "6",
                        lg: "6",
                        xl: "6"
                    },
                    value: group.userSet.totalCount
                },
            ];

            return _main;
        },
        editGroup(item) {
            this.openEditGroup = true;
            this.$refs.editGroupDialog.initializeEditing(item);
        },

        loadMore() {
            if (this.groups?.pageInfo?.hasNextPage) {
                this.$apollo.queries.groups.fetchMore({
                    variables: {
                        after: this.groups.pageInfo.endCursor
                    }
                });
            }
        },

        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.groups?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
</style>
