<template>
    <div v-if="!canAccess">
        <NoPermission></NoPermission>
    </div>
    <div v-else class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col cols="12" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-text-field
                                hint="Search by name"
                                v-model="searchInput"
                                append-icon="search"
                                :label="$t('general.search')"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="12" sm="5" lg="4" class="py-2 pr-4 text-right">
                    <v-row class="d-flex align-center justify-end">
                        <!-- ADD -->
                        <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            class="mr-1"
                            @click="openAddGroupUserDialog = true"
                        >
                            <v-icon left small>add_box</v-icon>
                            {{ $t("partners.add_user1") }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :items="groupUsers.edges"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    loader-height="2"
                    hide-default-footer
                    :loading="$apollo.queries.groupUsers.loading"
                >
                    <!-- CHECKBOX COLUMN -->
                    <template v-slot:item.action="{ item }">
                        <v-checkbox
                            v-model="selectedUsers"
                            class="mx-0"
                            :value="item.node.user.id"
                        ></v-checkbox>
                    </template>

                    <!-- EDIT COLUMN -->
                    <template v-slot:item.edit="{ item }" v-if="canEdit">
                        <v-icon @click="editUser(item)">edit</v-icon>
                    </template>
                    <!-- REMOVE -->
                    <template v-slot:item.delete="{ item }" v-if="canEdit">
                        <v-icon @click="removeUser(item)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!---- ADD GROUP USER DIALOG ---->
        <AddActionGroupUserDialog
            :openAddGroupUserDialog="openAddGroupUserDialog"
            :actionGroup="actionGroup"
            :groupUsers="groupUsers"
            v-on:refreshGroupUsersTable="refreshTables()"
            v-on:closeAddGroupUserDialog="openAddGroupUserDialog = false"
        ></AddActionGroupUserDialog>

        <!---- EDIT GROUP USER DIALOG ---->
        <EditUserDialog
            ref="editUserDialog"
            :openEditUserDialog="openEditUserDialog"
            v-on:closeEditUserDialog="openEditUserDialog = false"
            v-on:refreshDataTable="refreshTables()"
        ></EditUserDialog>

        <!---- DELETE GROUP USER DIALOG ---->
        <DeleteActionGroupUserDialog
            :openDeleteDialog="openDeleteGroupUserDialog"
            :actionGroup="actionGroup"
            :usersID="selectedUsers"
            v-on:refreshDataTable="refreshTables()"
            v-on:closeDeleteGroupUserDialog="closeAndCleanDeletedUsers"
        >
            <template #title>{{ $t("alert_rules.delete_group") }}</template>
            <template #content>{{ $t("alert_rules.sure_group") }}</template>
        </DeleteActionGroupUserDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddActionGroupUserDialog from "@/components/actiongroups/create/AddActionGroupUserDialog.vue";
import DeleteActionGroupUserDialog from "@/components/actiongroups/delete/DeleteActionGroupUserDialog.vue";
import EditUserDialog from "@/components/users/update/EditUserDialog.vue";
import NoPermission from "@/components/base/NoPermission.vue";
import helper from "@/utils/helper.js";
import { TenantPermissions } from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "ActionGroupUsers",
    components: {
        AddActionGroupUserDialog,
        DeleteActionGroupUserDialog,
        EditUserDialog,
        NoPermission
    },
    props: {
        actionGroup: {
            type: Object,
            required: true
        }
    },
    apollo: {
        groupUsers: {
            query: gql`
                query TenantActionGroupUsers(
                    $tenantActionGroupId: ID!
                    $first: Int
                    $search: String
                ) {
                    tenantActionGroupUsers(
                        tenantActionGroupId: $tenantActionGroupId
                        first: $first
                        search: $search
                    ) {
                        edges {
                            node {
                                user {
                                    id
                                    firstName
                                    lastName
                                    fullName
                                    email
                                    isTenantAdmin
                                    tenantUser {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantActionGroupId: this.actionGroup.id,
                    first: 20,
                    search: this.searchInput
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantActionGroupUsers;
            },
            skip: false
        }
    },
    data() {
        return {
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /*********************************/
            openAddGroupUserDialog: false,
            openDeleteGroupUserDialog: false,
            openEditUserDialog: false,
            /******************************/
            /* ---- USER INPUT DATA ---- */
            /****************************/
            selectedUsers: [],
            searchInput: null,
            groupUsers: { edges: [] },
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
            headerChoices: [
                {
                    text: this.$t("headers.first_name"),
                    align: "center",
                    value: "node.user.firstName",
                    sortable: false
                },
                {
                    text: this.$t("headers.last_name"),
                    align: "center",
                    value: "node.user.lastName",
                    sortable: false
                },
                {
                    text: "E-mail",
                    align: "center",
                    value: "node.user.email",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "edit",
                    width: "5px",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "delete",
                    width: "5px",
                    sortable: false
                }
            ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
    },
    watch: {
        searchInput() {
            this.$apollo.tenantActionGroupUsers
                ? this.$apollo.tenantActionGroupUsers.refetch()
                : null;
        }
    },
    computed: {
        enableOrDisableRemove() {
            return this.removedUsers?.length > 0 ? false : true;
        },
        canAccess() {
            return this.hasPermission(
                TenantPermissions.VIEW_TENANT_ACTION_GROUP
            );
        },
        canEdit() {
            return this.hasPermission(
                TenantPermissions.EDIT_TENANT_ACTION_GROUP
            );
        },
        canDelete() {
            return this.hasPermission(
                TenantPermissions.DELETE_TENANT_ACTION_GROUP
            );
        }
    },
    mounted() {
                //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'action_group_external_emails_svw',
        });
        this.setUserProperties();
    },
    methods: {
      setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("action_group_external_emails_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("action_group_external_emails_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editUser(item) {
            this.openEditUserDialog = true;
            this.$refs.editUserDialog.initializeUser(item);
        },
        removeUser(item) {
            this.openDeleteGroupUserDialog = true;
            this.selectedUsers.push(item.node.user.id);
        },
        closeAndCleanDeletedUsers() {
            this.openDeleteGroupUserDialog = false;
            this.selectedUsers = [];
        },
        refreshTables() {
            this.$apollo.queries.groupUsers.refetch();
            this.$emit("refreshDataTable");
        }
    }
};
</script>
