<template>
    <v-container fluid>
        <v-form ref="form">
            <v-row>
                <v-col cols="6">
                    <div>
                        <v-row dense>
                            <v-col
                                cols="auto"
                                class="textHeader labelContent"
                            >
                                <span>
                                    {{ $t("home.forwarders") }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-autocomplete
                            v-model="partners"
                            :loading="
                                $apollo.queries.tenantPartners.loading
                            "
                            :items="forwarder"
                            :no-data-text="$t('loading.no_available')"
                            :placeholder="$t('home.forwarders')"
                            outlined
                            deletable-chips
                            small-chips
                            item-text="node.name"
                            item-value="node.id"
                            multiple
                            :dense="dense"
                            :menu-props="{
                                maxWidth: '350px'
                            }"
                            
                        >
                            <template v-slot:item="data">
                                <v-row class="ma-0 caption">
                                    <v-col
                                        cols="12"
                                        class="text-truncate pl-0 ml-0"
                                    >
                                        {{ data.item.node.name }}
                                    </v-col>  
                                </v-row>
                            </template>
                            <template #append-item>
                                <v-divider
                                    class="mt-4 append-divider"
                                >
                                </v-divider>
                                <v-list-item
                                    dense
                                    class="append py-0"
                                >
                                    <v-list-item-content>
                                        <v-btn
                                            color="primary"
                                            @click="
                                                createNewPartner(
                                                    'forwarder'
                                                )
                                            "
                                        >
                                            {{
                                                $t(
                                                    "general.add_forwarder"
                                                )
                                            }}
                                        </v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </div>
                    <div>
                        <v-row dense>
                            <v-col
                                cols="auto"
                                class="textHeader labelContent"
                            >
                                <span>
                                    {{ $t("home.consignors") }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-autocomplete
                            v-model="partners"
                            :loading="
                                $apollo.queries.tenantPartners.loading
                            "
                            :items="consignor"
                            :no-data-text="$t('loading.no_available')"
                            :placeholder="$t('home.consignors')"
                            outlined
                            deletable-chips
                            small-chips
                            item-text="node.name"
                            item-value="node.id"
                            multiple
                            :dense="dense"
                            :menu-props="{
                                maxWidth: '350px'
                            }"
                            
                        >
                            <template v-slot:item="data">
                                <v-row class="ma-0  caption">
                                    <v-col
                                        cols="12"
                                        class="text-truncate pl-0 ml-0"
                                    >
                                        {{ data.item.node.name }}
                                    </v-col>
                                </v-row>
                            </template>
                            <template #append-item>
                                <v-divider
                                    class="mt-4 append-divider"
                                >
                                </v-divider>
                                <v-list-item
                                    dense
                                    class="append py-0"
                                >
                                    <v-list-item-content>
                                        <v-btn
                                            color="primary"
                                            @click="
                                                createNewPartner(
                                                    'consignor'
                                                )
                                            "
                                        >
                                            {{
                                                $t(
                                                    "general.add_consignor"
                                                )
                                            }}
                                        </v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </div>
                </v-col>
                <v-col cols="6">
                    <div>
                        <v-row dense>
                            <v-col
                                cols="auto"
                                class="textHeader labelContent"
                            >
                                <span class="textHeader">
                                    {{ $t("home.consignees") }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-autocomplete
                            v-model="partners"
                            :loading="
                                $apollo.queries.tenantPartners.loading
                            "
                            :items="consignee"
                            :no-data-text="$t('loading.no_available')"
                            :placeholder="$t('home.consignees')"
                            outlined
                            deletable-chips
                            small-chips
                            item-text="node.name"
                            item-value="node.id"
                            multiple
                            :dense="dense"
                            :menu-props="{
                                maxWidth: '350px'
                            }"
                            
                        >
                            <template v-slot:item="data">
                                <v-row class="ma-0 caption">
                                    <v-col
                                        cols="12"
                                        class="text-truncate pl-0 ml-0"
                                    >
                                        {{ data.item.node.name }}
                                    </v-col>
                                </v-row>
                            </template>
                            <template #append-item>
                                <v-divider
                                    class="mt-4 append-divider"
                                >
                                </v-divider>
                                <v-list-item
                                    dense
                                    class="append py-0"
                                >
                                    <v-list-item-content>
                                        <v-btn
                                            color="primary"
                                            @click="
                                                createNewPartner(
                                                    'consignee'
                                                )
                                            "
                                        >
                                            {{
                                                $t(
                                                    "general.add_consignee"
                                                )
                                            }}
                                        </v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </div>
                    <div>
                        <v-row dense>
                            <v-col
                                cols="auto"
                                class="textHeader labelContent"
                            >
                                <span>
                                    {{ $t("home.others") }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-autocomplete
                            v-model="partners"
                            :loading="
                                $apollo.queries.tenantPartners.loading
                            "
                            :items="other"
                            :no-data-text="$t('loading.no_available')"
                            :placeholder="$t('home.others')"
                            outlined
                            deletable-chips
                            small-chips
                            item-text="node.name"
                            item-value="node.id"
                            multiple
                            :dense="dense"
                            :menu-props="{
                                maxWidth: '350px'
                            }"
                            
                        >
                            <template v-slot:item="data">
                                <v-row class="ma-0 caption">
                                    <v-col
                                        cols="12"
                                        class="text-truncate pl-0 ml-0"
                                    >
                                        {{ data.item.node.name }}
                                    </v-col>
                                    
                                </v-row>
                            </template>
                            <template #append-item>
                                <v-divider
                                    class="mt-4 append-divider"
                                >
                                </v-divider>
                                <v-list-item
                                    dense
                                    class="append py-0"
                                >
                                    <v-list-item-content>
                                        <v-btn
                                            color="primary"
                                            @click="
                                                createNewPartner(
                                                    'other'
                                                )
                                            "
                                        >
                                            {{
                                                $t(
                                                    "general.add_other"
                                                )
                                            }}
                                        </v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </div>
                </v-col>
            </v-row>
        </v-form>
        <AddPartnerDialog
            :openAddPartnerDialog="openAddPartnerDialog"
            v-on:refreshPartnersTable="newPartner = true"
            :partnerByType="partnerByType"
            v-on:closeAddPartnerDialog="openAddPartnerDialog = false"
            v-on:item-created="onPartnerCreated"
        >
        </AddPartnerDialog>
    </v-container>
</template>

<script>
import gql from "graphql-tag";

import AddPartnerDialog from "@/components/partners/create/AddPartnerDialog.vue";

export default {
    name: "PartnerTable",
    components: { AddPartnerDialog },
    props: {
        partnersOnBoard: {
            type: Array
        },
        getPartners: {
            type: Array
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $referenceNumberIcontains: String
                    $partnerType: String
                    $carrierType: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        referenceNumber_Icontains: $referenceNumberIcontains
                        partnerType: $partnerType
                        carrierType: $carrierType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantPartners,
            result({ loading, data }) {
                if (!loading) {
                    this.listOfPartners = data.tenantPartners.edges.filter(
                        data => {
                            let find = this.partnersOnBoard.find(
                                item =>
                                    item.node.tenantPartner.id === data.node.id
                            );
                            if (typeof find !== "object") return data;
                        }
                    );
                }
            },
            skip: false
        }
    },
    data() {
        return {
            partnerFilter: {
                first: 20
            },
            loading: false,
            listOfPartners: [],
            shouldLoadMore: false,
            isInitialLoad: true,
            tenantPartners: { edges: [] },
            openAddPartnerUser: false,
            partnerByType: "",
            transportMode: "",
            // transportType: [
            //     { text: this.$t("headers.consignor"), value: "consignor" },
            //     { text: this.$t("headers.consignee"), value: "consignee" },
            //     { text: this.$t("headers.forwarder"), value: "forwarder" },
            //     { text: this.$t("headers.carrier"), value: "carrier" },
            //     { text: this.$t("home.other"), value: "other" }
            // ],
            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            // ],
            openAddPartnerDialog: false,
            newPartner: false
        };
    },
    watch: {},
    computed: {
        hasMoreData() {
            return this.tenantPartners?.pageInfo?.hasNextPage;
        },
        partners: {
            get() {
                return this.getPartners;
            },
            set(value) {
                this.$emit("update:getPartners", value);
            }
        },
        consignee() {
            return this.listOfPartners.filter(
                e => e.node.partnerType === "consignee"
            );
        },
        consignor() {
            return this.listOfPartners.filter(
                e => e.node.partnerType === "consignor"
            );
        },
        carrier() {
            return this.listOfPartners.filter(
                e => e.node.partnerType === "carrier"
            );
        },
        forwarder() {
            return this.listOfPartners.filter(
                e => e.node.partnerType === "forwarder"
            );
        },
        other() {
            return this.listOfPartners.filter(
                e => e.node.partnerType === "other"
            );
        },
        transportType: function() {
            let _transportType = [
                { text: this.$t("headers.consignor"), value: "consignor" },
                { text: this.$t("headers.consignee"), value: "consignee" },
                { text: this.$t("headers.forwarder"), value: "forwarder" },
                { text: this.$t("headers.carrier"), value: "carrier" },
                { text: this.$t("home.other"), value: "other" }
            ];
            return _transportType;
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        }
    },
    methods: {
        createNewPartner(type) {
            this.openAddPartnerDialog = true;
            this.partnerByType = type;
        },
        partnersAdded() {
            this.$emit("addNewPartner", this.partners);
        },
        clearCache() {
            this.partners = [];
        },
        refetchTableData() {
            return this.$apollo.queries.tenantPartners.refetch();
        },
        onPartnerCreated(val) {
            this.refetchTableData();
            this.partners.push(val.tenantPartner.id);
        }
        /*  filterTransportByType(type){
             console.log("this",type)
             this.$apollo.queries.tenantPartners.setVariables({
                 partnerType: type,
                 carrierType:this.transportMode,
             })
             .then(() => {
                 //this.tenantPartners=response.data.tenantPartners.edges.map(e => e.node)  
             })
             .catch((error) => {
                 console.error(error);
                 this.error = error;
             })
             .finally(() => {});  
         },
         filterTransportByMode(mode){
             console.log(this.partnerType,"mode", mode)
             this.$apollo.queries.tenantPartners.setVariables({
                 partnerType:this.partnerType,
                 carrierType:mode,
             })
             .then(() => {
                 //this.tenantPartners=response.data.tenantPartners.edges.map(e => e.node)  
             })
             .catch((error) => {
                 console.error(error);
                 this.error = error;
             })
             .finally(() => {});  
 
         }, */
    }
};
</script>
<style scoped>

.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 15px;
}
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
/* .inputContent {
    height: 52px;
} */
.labelContent {
    height: 20px;
    margin-bottom: 4px;
}
.contentDisplay {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay2 {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay3 {
    padding-left: 20px;
    padding-right: 20px;
}
</style>