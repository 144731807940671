<template>
    <v-dialog max-width="600" v-model="openEditPartnerDialog" persistent>
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">edit</v-icon>
                {{ $t("partners.edit_partner") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pt-6">
                <v-form ref="materialForm">
                    <!--- PARTNER NAME --->
                    <v-row dense>
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="partnerName"
                                :label="$t('partners.partner_name')"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="description"
                                :label="$t('general.description')"
                                :rules="[rules.maxLength(200)]"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                            v-if="partnerType === 'carrier'"
                        >
                            <v-select
                                v-model="carrierType"
                                :items="carrierTypeChoices"
                                :label="$t('headers.carrier_type')"
                                :item-value="item => item.value"
                                :item-text="item => item.text"
                                outlined
                                dense
                                @change="getCarriersByType(carrierType)"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                            v-if="
                                (carrierType === 'sea' &&
                                    partnerType === 'carrier') ||
                                    (carrierType === 'air' &&
                                        partnerType === 'carrier')
                            "
                        >
                            <v-select
                                v-model="carrier"
                                :items="allCarriers ? allCarriers.edges : []"
                                :no-data-text="$t('loading.no_available')"
                                :label="$t('partners.standard_carriers')"
                                item-text="node.name"
                                item-value="node.id"
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="referenceNumber"
                                :label="$t('headers.reference_number')"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-checkbox
                                v-model="notification_checkbox"
                                :label="$t('headers.enable_notifications')"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTIONS --->
            <v-card-actions class="d-flex justify-end pb-3 pr-5">
                <v-btn rounded text @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isLoadingPartnerAdd"
                    @click="editTenantPartner"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "EditPartnerDialog",
    props: ["openEditPartnerDialog"],
    apollo: {
        allCarriers: {
            query: gql`
                query allCarriers(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $carrierType: String
                ) {
                    allCarriers(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: "name"
                        carrierType: $carrierType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                code
                                name
                                carrierType
                            }
                        }
                    }
                }
            `,
            variables() {},
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.allCarriers,
            skip: false
        }
    },
    data() {
        return {
            /* --- QUERY DATA ---  */
            updatedPartner: null,

            /* --- FORM VARIABLES --- */
            partnerId: null,
            partnerName: null,
            partnerType: null,
            description: null,
            referenceNumber: null,
            notification_checkbox: false,

            /* --- DATA VARIABLES --- */
            partnerTypeChoices: [
                this.$t("headers.consignor"),
                this.$t("headers.consignee"),
                this.$t("headers.carrier"),
                this.$t("headers.forwarder"),
                this.$t("home.other")
            ],
            carrierTypeChoices: [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ],
            carrierType: "",
            carrier: "",
            rules: {
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            },
            /* --- LOADING STATUS --- */
            isLoadingPartnerAdd: false
        };
    },
    watch: {},
    methods: {
        /***********************************/
        /* --- FORM RELATED FUNCTIONS --- */
        /***********************************/
        closeAndResetInput() {
            this.partnerName = null;
            this.partnerType = null;
            this.referenceNumber = null;
            this.notification_checkbox = false;
            this.$emit("closeEditPartnerDialog");
        },
        assignFormValues(partner) {
            // This function is called from PartnerDetail.vue when we click on the edit button
            this.partnerId = partner.id;
            this.partnerName = this.updatedPartner
                ? this.updatedPartner.name
                : partner.name;
            this.partnerType = this.updatedPartner
                ? this.updatedPartner.partnerType
                : partner.partnerType;
            this.description = this.updatedPartner
                ? this.updatedPartner.description
                : partner.description;
            this.carrierType = this.updatedPartner
                ? this.updatedPartner.carrierType
                : partner?.carrierType;
            this.carrier = this.updatedPartner
                ? this.updatedPartner.carrier?.id
                : partner.carrier?.id;
            this.referenceNumber = this.updatedPartner
                ? this.updatedPartner.referenceNumber
                : partner.referenceNumber;
            this.notification_checkbox = this.updatedPartner
                ? this.updatedPartner.notificationEnabled
                : partner.notificationEnabled;
        },
        /************************************/
        /* --- TENANT PARTNER MUTATION --- */
        /**********************************/
        editTenantPartner() {
            // Initialize Loading :
            this.isLoadingPartnerAdd = true;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updatePartner(
                            $input: UpdateTenantPartnerInput!
                        ) {
                            updateTenantPartner(input: $input) {
                                partner {
                                    id
                                    tenant {
                                        id
                                        name
                                    }
                                    createdBy {
                                        id
                                        firstName
                                        lastName
                                    }
                                    carrier {
                                        id
                                        code
                                        name
                                        carrierType
                                    }
                                    partnerType
                                    carrierType
                                    description
                                    name
                                    referenceNumber
                                    notificationEnabled
                                    organisationPermissions
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.partnerId,
                            partnerType: this.partnerType,
                            description: this.description,
                            name: this.partnerName,
                            referenceNumber: this.referenceNumber,
                            notificationEnabled: this.notification_checkbox,
                            organisationPermission: null,
                            carrierType: this.carrierType,
                            carrierId: this.carrier ? this.carrier : null
                        }
                    }
                })
                .then(response => {
                    // Stop Loading //
                    this.isLoadingPartnerAdd = false;
                    // Update current variables to the updated version //
                    this.$emit("updatedPartner", response);
                    this.updatedPartner =
                        response.data.updateTenantPartner.partner;
                    // Close the dialog and reset form fields //
                    this.closeAndResetInput();
                });
        },
        getCarriersByType(carrierType) {
            if (carrierType === "sea" || carrierType === "air") {
                this.$apollo.queries.allCarriers
                    .setVariables({
                        carrierType: carrierType
                    })
                    .then(() => {
                        //this.tenantPartners=response.data.tenantPartners.edges.map(e => e.node)
                    })
                    .catch(error => {
                        console.error(error);
                        this.error = error;
                    })
                    .finally(() => {});
            } else {
                this.carrier = "";
            }
        }
    }
};
</script>
