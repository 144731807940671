<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs v-model="tab" grow color="primary" show-arrows ref="tabs" background-color="background">
            <v-tab v-for="t in selectedFilters" :key="t.code" :href="`#tab-${t.code}`">
                {{ t.name }}
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab" :style="`height: ${heightScreen}px;`" class="overflow-y-auto">
            <!-- PARTNER USERS TAB -->
            <v-tab-item value="tab-users" :transition="false"><TenantUsers :tenant="tenant"></TenantUsers></v-tab-item>
            <!-- PARTNER PERMISSION TAB -->
            <v-tab-item value="tab-services" :transition="false">
                <TenantServices :tenant="tenant"></TenantServices>
            </v-tab-item>
            <!-- PARTNER STATISTICS TAB -->
            <v-tab-item value="tab-statistics" :transition="false">
                <TenantStatistics :tenant="tenant"></TenantStatistics>
            </v-tab-item>
            <!-- ADMIN SETTINGS TAB -->
            <v-tab-item value="tab-settings" :transition="false" class="px-3 mt-3">
                <v-card class="rounded-xl mb-4">
                    <v-card-title class="background2">
                        <v-icon>
                            manage_accounts
                        </v-icon>
                    </v-card-title>
                    <v-card-text class="pt-1">
                        <v-tabs vertical>
                            <v-tab class="d-flex justify-start">
                                <v-icon left >
                                    admin_panel_settings
                                </v-icon>
                                <span class="text-capitalize">
                                    {{ $t("home.profile") }}
                                </span>
                            </v-tab>
                            <v-tab class="d-flex justify-start">
                                <v-icon left>
                                    flight_takeoff
                                </v-icon>
                                <span class="text-capitalize">
                                    {{ $t("headers.trip") }}
                                </span>
                            </v-tab>
                            <v-tab>
                                <v-icon left>
                                    trending_up
                                </v-icon>
                                <span class="text-capitalize">
                                    {{ $t("headers.tracker") }}
                                </span>
                            </v-tab>
                            <v-tab-item>
                                <v-card flat>
                                    <TenantAdminSettings :tenant="tenant" />
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card flat class="pt-0">
                                    <SettingsDetails :tenant="tenant" />
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <StockLimitDialog
                                    :openStockLevelDialog="openStockLevelDialog"
                                    :tenant="tenant"
                                ></StockLimitDialog>
                            </v-tab-item>
                        </v-tabs>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import TenantUsers from "@/components/tenants/details/TenantUsers.vue";
import TenantServices from "@/components/tenants/details/TenantServices.vue";
import TenantStatistics from "@/components/tenants/details/TenantStatistics.vue";
import SettingsDetails from "../adminsettings/SettingsDetails.vue";
import TenantAdminSettings from "../adminsettings/TenantAdminSettings.vue";
import StockLimitDialog from "@/components/tenants/other/StockLimitDialog.vue";

export default {
    props: {
        tenant: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        TenantUsers,
        TenantServices,
        TenantStatistics,
        SettingsDetails,
        TenantAdminSettings,
        StockLimitDialog
    },
    data() {
        return {
            tab: null,
            testebton: false,
            mapDialog: false,
            iconImg: require("@/assets/monitoring.png"),
            openStockLevelDialog: false
        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    mounted() {}
};
</script>
<style scoped>
.tabPosition {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}
</style>
