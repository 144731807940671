<template>
    <v-combobox
        class="inputContent mb-n2 elevation-0"
        v-model="vesselInputValue"
        :items="vesselValues"
        :search-input.sync="vesselInputValue"
        :disabled="!vesselType"
        :filled="filled"
        outlined
        dense
        clearable
        :error-messages="vesselInputValue === null ? 'Vessel No. is missing' : []"
    >
    </v-combobox>
</template>
<script>
import gql from "graphql-tag";

export default {
    props: {
        value: {
            type: String,
            default: null
        },
        vesselType: {
            type: String,
            default: null
        },
        filled: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        vessels: {
            query() {
                return gql`
                    query Vessels(
                        $orderBy: String
                        $first: Int
                        $after: String
                        $last: Int
                        $before: String
                        $shipId_Icontains: String
                        $imo_Icontains: String
                        $callsign_Icontains: String
                        $name_Icontains: String
                        $mmsi_Icontains: String
                    ) {
                        vessels(
                            orderBy: $orderBy
                            first: $first
                            after: $after
                            last: $last
                            before: $before
                            name_Icontains: $name_Icontains
                            shipId_Icontains: $shipId_Icontains
                            callsign_Icontains: $callsign_Icontains
                            imo_Icontains: $imo_Icontains
                            mmsi_Icontains: $mmsi_Icontains
                        ) {
                            pageInfo {
                                startCursor
                                endCursor
                                hasPreviousPage
                                hasNextPage
                            }
                            edges {
                                node {
                                    id
                                    imo
                                    name
                                    mmsi
                                    callsign
                                }
                            }
                        }
                    }
                `;
            },
            variables() {
                return {
                    orderBy: "name",
                    first: 10,
                    imo_Icontains: this.vesselType === "imo" ? this.vesselInputValue : null,
                    callsign_Icontains: this.vesselType === "callsign" ? this.vesselInputValue : null,
                    name_Icontains: this.vesselType === "shipname" ? this.vesselInputValue : null,
                    mmsi_Icontains: this.vesselType === "mmsi" ? this.vesselInputValue : null
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.vessels;
            },
            result({ loading, data }) {
                if (!loading) {
                    let item = this.vesselType;
                    this.vesselValues=[];
                    if (item === "shipname") {
                        this.vesselValues = this.vessels.edges.map(vessel => vessel.node.name);
                    } else if (item === "callsign") {
                        this.vesselValues = this.vessels.edges.map(vessel => vessel.node.callsign);
                    } else if (item === "imo") {
                        this.vesselValues = this.vessels.edges.map(vessel => vessel.node.imo);
                    } else if (item === "mmsi") {
                        this.vesselValues = this.vessels.edges.map(vessel => vessel.node.mmsi);
                    }
                }
            },
            skip() {
                return !this.vesselInputValue;
            }
        }
    },
    data() {
        return {
            vesselInputValueChoices: [],
            seaPorts: {
                edges: []
            },
            filter: {
                shipId_Icontains: null,
                imo_Icontains: null,
                callsign_Icontains: null,
                name_Icontains: null
            },
            item: null,
            search: "",
            vesselValues:[],
            // vesselValues: {
            //     shipname: [],
            //     callsign: [],
            //     imo: [],
            //     mmsi: []
            // },
            shouldLoadMore: false,
            vessels: { edges: [] },
            type: [
                { text: "Ship Name", value: "shipname" },
                { text: "Callsign", value: "callsign" },
                { text: "IMO", value: "imo" },
                { text: "MMSI", value: "mmsi" }
            ]
        };
    },
    computed: {
        hasMoreData() {
            return this.vessels?.pageInfo?.hasNextPage;
        },

        vesselInputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    watch: {
        value: {
            handler(val) {
                this.item = val;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        getInputTypeText(value) {
            let result = null;
            if (value === null || value === undefined || value.length === 0) {
                result = "";
            } else {
                let type = this.type.find(type => type.value === value);
                result = type?.text;
            }

            return result;
        },
        getItems(array, value) {
            if (value === null || value === undefined || value.length === "") {
                return [];
            } else {
                return array;
            }
        }
    }
};
</script>
<style scoped>
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}

.textBodyContent {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}

/* .inputContent {
        height: 52px;
    } */
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 0px;
    padding-right: 0px;
}
.contentDisplay2 {
    padding-left: 0px;
    padding-right: 0px;
}
.contentDisplay3 {
    padding-left: 0px;
    padding-right: 0px;
}
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
</style>
