import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c(VCard,{staticClass:"py-3 px-3 mx-2 my-2",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c(VDataTable,{attrs:{"headers":_vm.headerChoices,"items":_vm.groups.edges,"item-key":"node.id","loading-text":_vm.$t('loading.loading1'),"loading":_vm.$apollo.queries.groups.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","loader-height":"2","mobile-breakpoint":_vm.screenSize === 'xs' || _vm.screenSize === 'sm' ? 800 : 0,"no-data-text":_vm.$t('loading.no_data')},on:{"click:row":function (item) { return this$1.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VCheckbox,{staticClass:"mx-0",attrs:{"multiple":"","value":item.node.id},on:{"click":[function($event){return _vm.signalGroups(item, $event)},function($event){$event.stopPropagation();}]},model:{value:(_vm.localSelectedGroups),callback:function ($$v) {_vm.localSelectedGroups=$$v},expression:"localSelectedGroups"}})]}},{key:"item.node.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.node.name)+" ")]}}])})],1),_c(VCardActions,{staticClass:"justify-center"},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.groups.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }