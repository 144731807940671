import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{staticClass:"d-flex justify-center"},[(!_vm.isEmbedded)?_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center mb-5"},[_c('a',{attrs:{"href":""}},[_c(VImg,{attrs:{"src":require('../assets/new_coldcha_logo.png'),"max-height":"83px","max-width":"352px","contain":""},on:{"click":_vm.goToColdCha}})],1)])]):_vm._e(),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"8","md":"6","lg":"5","xl":"4"}},[_c(VCard,{staticClass:"elevation-10 pt-3",attrs:{"width":"520px"}},[_c(VCardText,{staticClass:"mt-10 pb-0 px-10"},[_c(VForm,{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();}}},[_c(VTextField,{attrs:{"placeholder":"E-mail","name":"login","type":"email","rules":[_vm.rules.required],"error-messages":_vm.error,"outlined":"","prepend-inner-icon":"person"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"id":"password","placeholder":_vm.$t('login.password'),"name":"password","type":_vm.showpassword ? 'text' : 'password',"rules":[_vm.rules.required],"error-messages":_vm.error,"outlined":"","prepend-inner-icon":"lock"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VIcon,{attrs:{"color":_vm.isEmbedded && _vm.hasColor
                                            ? _vm.defaultPrimaryColor
                                            : 'primary',"tabindex":"-1"},on:{"click":function($event){_vm.showpassword = !_vm.showpassword}}},[_vm._v(_vm._s(_vm.showpassword ? "visibility" : "visibility_off"))])]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('p',{staticClass:"text-center pb-0 px-10"},[_c(VBtn,{staticClass:"white--text",attrs:{"color":_vm.isEmbedded && _vm.hasColor
                                ? _vm.defaultPrimaryColor
                                : 'primary',"block":"","rounded":"","disabled":_vm.email.length < 2 || _vm.password.length < 2,"loading":_vm.isLoggingIn},on:{"click":_vm.login}},[_vm._v(_vm._s(_vm.$t("home.login")))]),_c('br'),_c('a',{style:(_vm.isEmbedded && _vm.hasColor
                                ? 'color:' + _vm.defaultPrimaryColor + ';'
                                : 'color:primary;'),on:{"click":function($event){_vm.forgotPasswordDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("login.forgot"))+" ")])],1),_c(VDivider)],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.tfaDialog),callback:function ($$v) {_vm.tfaDialog=$$v},expression:"tfaDialog"}},[_c(VCard,[_c(VCardTitle,{class:_vm.isEmbedded && _vm.hasColor
                        ? _vm.defaultPrimaryColor + ' white--text'
                        : 'primary white--text'},[_vm._v(" "+_vm._s(_vm.$t("login.2fa"))+" "),_c(VSpacer),_c(VBtn,{staticClass:"mr-n3",attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.close2FADialog()}}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VCardText,{staticClass:"pt-5 pb-0"},[_c(VForm,{ref:"tfaForm",on:{"submit":function($event){$event.preventDefault();}}},[_c(VTextField,{staticClass:"headline",attrs:{"counter":"6","rules":[_vm.rules.required, _vm.rules.maxLength(6)],"label":_vm.$t('login.2facode'),"type":"number","outlined":"","autofocus":"","error-messages":_vm.tfaCodeError,"hint":"Google Authenticator Code"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loginWith2FA.apply(null, arguments)}},model:{value:(_vm.tfaCode),callback:function ($$v) {_vm.tfaCode=$$v},expression:"tfaCode"}})],1)],1),_c(VCardActions,{staticClass:"pb-3"},[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","rounded":"","disabled":_vm.tfaCode.length != 6,"loading":_vm.isLoggingInWith2FA,"color":_vm.isEmbedded && _vm.hasColor
                            ? _vm.defaultPrimaryColor
                            : 'primary'},on:{"click":function($event){return _vm.loginWith2FA()}}},[_vm._v(_vm._s(_vm.$t("login.verify")))]),_c(VSpacer)],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.forgotPasswordDialog),callback:function ($$v) {_vm.forgotPasswordDialog=$$v},expression:"forgotPasswordDialog"}},[_c(VCard,[(_vm.isEmbedded && _vm.hasColor)?_c(VCardTitle,{staticClass:"white--text",style:('background-color:' +
                        _vm.defaultPrimaryColor +
                        ';border-color:' +
                        _vm.defaultPrimaryColor +
                        ';')},[_vm._v(" "+_vm._s(_vm.$t("login.forgot"))+" "),_c(VSpacer),_c(VBtn,{staticClass:"mr-n3",attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.closeForgotPasswordDialog()}}},[_c(VIcon,[_vm._v("close")])],1)],1):_c(VCardTitle,{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.$t("login.forgot"))+" "),_c(VSpacer),_c(VBtn,{staticClass:"mr-n3",attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.closeForgotPasswordDialog()}}},[_c(VIcon,[_vm._v("close")])],1)],1),(!_vm.isResetPasswordEmailSent)?_c(VCardText,{staticClass:"pt-5 pb-0"},[_c('p',[_vm._v(_vm._s(_vm.$t("login.enter_email")))]),_c(VForm,{ref:"forgotPasswordForm",on:{"submit":function($event){$event.preventDefault();}}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"label":"E-mail","type":"email","outlined":"","autofocus":"","color":_vm.isEmbedded && _vm.hasColor
                                ? _vm.defaultPrimaryColor
                                : 'primary',"error-messages":_vm.forgotPasswordEmailError},model:{value:(_vm.forgotPasswordEmail),callback:function ($$v) {_vm.forgotPasswordEmail=$$v},expression:"forgotPasswordEmail"}})],1)],1):_c(VCardText,[_c('h1',{staticClass:"text-center my-5"},[_vm._v(_vm._s(_vm.$t("login.inbox")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("login.email_sent"))+" ")])]),_c(VCardActions,{staticClass:"pb-5"},[_c(VSpacer),(!_vm.isResetPasswordEmailSent)?_c(VBtn,{attrs:{"outlined":"","rounded":"","disabled":!_vm.forgotPasswordEmail.includes('@'),"loading":_vm.isRequestingPasswordReset,"color":_vm.isEmbedded && _vm.hasColor
                            ? _vm.defaultPrimaryColor
                            : 'primary'},on:{"click":function($event){return _vm.requestPasswordReset()}}},[_vm._v(_vm._s(_vm.$t("general.continue")))]):_c(VBtn,{attrs:{"outlined":"","rounded":"","color":_vm.isEmbedded && _vm.hasColor
                            ? _vm.defaultPrimaryColor
                            : 'primary'},on:{"click":function($event){return _vm.closeForgotPasswordDialog()}}},[_vm._v(_vm._s(_vm.$t("general.close")))]),_c(VSpacer)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }