const StaffPermissionsEnum = Object.freeze({
    VIEW_DASHBOARD: "users.view_dashboard",
    LOGIN_AS_TENANT_USER: "users.login_as_tenant_user",
    VIEW_TENANT_SERVICES: "users.view_tenantservices",
    CREATE_TENANT_SERVICES: "users.add_tenantservices",
    EDIT_TENANT_SERVICES: "users.change_tenantservices",
    DELETE_TENANT_SERVICES: "users.delete_tenantservices",
    MANAGE_TENANT_SERVICES: "users.manage_tenantservices",
});

export default StaffPermissionsEnum;