<template>
    <v-menu 
        :offset-y="offsetIsY" 
        :offset-x="!offsetIsY" 
        :right="!offsetIsY"
        origin="center center" 
        :position-x="30"
    >
        <template v-slot:activator="{ on }">
            <div v-if="displayFormat === 'text'">
                <div v-on="on" class="">
                    {{ $t('language.language') }}
                </div>            
            </div>
            <div v-else-if="displayFormat === 'list-item'">
                <v-list-item
                    v-on="on"                                 
                    link
                    v-ripple="{ center: true }"
                >
                    <v-list-item-action>
                        <v-badge
                            avatar
                            bordered
                            overlap
                        >
                            <template v-slot:badge>
                                <v-avatar size="25">
                                    <v-img :src="selectedFlag"></v-img>
                                </v-avatar>
                            </template>
                            <v-icon>language</v-icon>
                        </v-badge>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span>{{ $t('language.language') }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>       
            </div>
            <div v-else>
                <!-- <v-btn
                    icon
                    v-on="on"
                    v-if="screenSize === 'lg' || screenSize === 'xl' || screenSize === 'md'"
                    class="px-0"
                >
                    <img :src="selectedFlag" />
                </v-btn> -->
                <v-btn 
                    icon 
                    v-on="on" 
                    small
                    class="pa-0 ma-0"
                    height="24"
                    width="24"
                >
                    <v-img :src="selectedFlag" height="24" width="24"></v-img>
                </v-btn>
            </div>            
        </template>
        <v-list>
            <v-list-item
                v-for="item in locales"
                :key="item.locales"
                @click="changeLocale(item)"
            >
                <v-list-item class="px-0">
                    <img :src="item.flag" class="mr-2" />
                    {{ item.localeName }}
                </v-list-item>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import helper from "@/utils/helper.js";

export default {
    name: "langDropdown",
    props: {
        offset: {
            type: String,
            default: "y"
        },
        format: {
            type: String,
            default: "icon"
        }
    },
    data: () => ({
        selectedLocale: "en"
    }),
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        locale() {
            return this.$i18n.locale;
        },
        selectedFlag() {
            switch (this.locale) {
                case "en":
                    return require("@/assets/great-britain-circular.png");
                case "nl":
                    return require("@/assets/netherlands-circular.png");
                case "es":
                    return require("@/assets/spain2-circular.png");
                case "pt":
                    return require("@/assets/portugal-circular.png");
                default:
                    return require("@/assets/great-britain-circular.png");
            }
        },
        locales: function() {
            let _locales = [
                {
                    locale: "en",
                    localeName: "English",
                    flag: require("@/assets/great-britain-circular.png")
                },
                // {
                //     locale: "nl",
                //     localeName: "Nederlands",
                //     flag: require("@/assets/netherlands-circular.png")
                // },
                // {
                //     locale: "es",
                //     localeName: "Español",
                //     flag: require("@/assets/spain2-circular.png")
                // },
                {
                    locale: "pt",
                    localeName: "Português",
                    flag: require("@/assets/portugal-circular.png")
                }
            ];
            return _locales;
        },

        me() {
            return this.$store.state.user.me || {};
        },
        offsetIsY() {
            return this.offset === "y" ? true : false;
        },
        displayFormat() {
            switch(this.format) {
                case "icon":
                    return "icon";
                case "text":
                    return "text";
                case "list-item":
                    return "list-item";
                default:
                    return "icon";
            }
        },
        positionX() {
            return this.offset === "x" ? 23 : undefined;
        }
    },
    created() {
        let _preferences = this.me?.preferences;
        if (_preferences == null || _preferences == "") {
            let storedLocale = localStorage.getItem("locale");
            if (storedLocale) {
                this.$i18n.locale = storedLocale;
            } else {
                this.$i18n.locale = "en";
            }
        } else {
            _preferences = JSON.parse(_preferences);
            if (_preferences?.locale != null)
                this.$i18n.locale = _preferences?.locale;
            else this.$i18n.locale = "en";
        }
        // let storedLocale = localStorage.getItem("locale");
        // if (storedLocale) {
        //     this.$i18n.locale = storedLocale;
        // } else {
        //     this.$i18n.locale = "en";
        // }
    },
    mounted() {
        this.$watch(
            "_locale",
            function(value) {
                helper.updateMyPreferences({
                    locale: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        changeLocale(locale) {
            this.$i18n.locale = locale.locale;
            localStorage.setItem("locale", locale.locale);

            this.$store.commit("user/setLocale", locale.locale);

            helper.updateMyPreferences({
                locale: locale.locale
            });
            // this.$store.dispatch("updateMyPreferences", {
            //     locale: locale.locale
            // });
        }
    },
    watch: {}
};
</script>

<style scoped>
.select {
    width: 17vh;
    text-align: center;
}
</style>
