import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pl-0",attrs:{"fluid":""}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("alert_rules.type"))+" ")]),_c(VRow,{staticClass:"d-flex",attrs:{"dense":""}},_vm._l((_vm.sensorTypeChoices),function(sensorTypeChoice){return _c(VCol,{key:sensorTypeChoice.value,attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"py-5 d-flex align-center justify-center",attrs:{"elevation":"2","color":_vm.sensorTypes.includes(sensorTypeChoice.value)
                        ? 'primary'
                        : ''},on:{"click":function($event){return _vm.updateSensorType(sensorTypeChoice.value)}}},[_c('span',{class:_vm.sensorTypes.includes(sensorTypeChoice.value)
                            ? 'white--text text-no-wrap pr-1'
                            : 'black--text text-no-wrap pr-1'},[_c(VIcon,{staticClass:"pr-0",attrs:{"color":_vm.sensorTypes.includes(sensorTypeChoice.value)
                                ? 'white'
                                : 'black'}},[_vm._v(_vm._s(sensorTypeChoice.icon))]),_vm._v(" "+_vm._s(sensorTypeChoice.text)+" ")],1)])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }