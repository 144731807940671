import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c(VCard,{staticClass:"py-4 mt-4",attrs:{"elevation":"5","outlined":""}},[_c(VRow,{staticClass:"px-5 d-flex align-center",attrs:{"align":"start"}},[_c(VCol,{attrs:{"cols":"5","sm":"5","lg":"5"}},[_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"subtitle-2",attrs:{"hint":_vm.$t('general.search_hint'),"append-icon":"search","label":_vm.$t('general.search'),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1)],1)],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"small":"","outlined":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.openAddLocation = true}}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("add_box")]),_vm._v(" "+_vm._s(_vm.$t("partners.add_location1"))+" ")],1)],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"small":"","outlined":"","rounded":"","color":"error","disabled":_vm.enableOrDisableRemove},on:{"click":function($event){_vm.openDeleteDialog = true}}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("remove_circle")]),_vm._v(" "+_vm._s(_vm.$t("general.remove")))],1)],1)],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2 mb-5 pb-5",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,[_c(VDataTable,{attrs:{"loading":_vm.$apollo.queries.locations.loading,"items":_vm.locations,"headers":_vm.headerChoices,"disable-pagination":"","loading-text":_vm.$t('loading.loading1'),"hide-default-footer":"","loader-height":"2","no-data-text":_vm.$t('loading.no_available')},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VCheckbox,{staticClass:"mx-0",attrs:{"multiple":"","value":item.node.id},model:{value:(_vm.selectedLocations),callback:function ($$v) {_vm.selectedLocations=$$v},expression:"selectedLocations"}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{on:{"click":function($event){return _vm.editLocation(item)}}},[_vm._v("edit")])]}},{key:"item.node.tags",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((_vm.getTags(item.node.tags)),function(tag){return _c(VChip,{key:tag,staticClass:"mr-1 my-1",attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(tag))])}),1)]}}])})],1)],1),_c('AddPartnerLocation',{ref:"addPartnerLocation",attrs:{"partner":_vm.partner,"openAddLocationDialog":_vm.openAddLocation},on:{"updateLocationsTable":function($event){return _vm.$apollo.queries.locations.refetch()},"closeMap":function($event){_vm.openAddLocation = false}}}),_c('EditLocation',{ref:"editLocationDialog",attrs:{"openEditLocationDialog":_vm.openEditLocation,"showPartnerSelect":false},on:{"closeMap":function($event){_vm.openEditLocation = false}}}),_c('DeleteLocationDialog',{attrs:{"openDeleteDialog":_vm.openDeleteDialog,"locationID":_vm.selectedLocations},on:{"refreshDataTable":function($event){return _vm.$apollo.queries.locations.refetch()},"closeDeleteDialog":_vm.closeAndCleanDeletedLocations},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("partners.delete_location")))]},proxy:true},{key:"content",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.$t("partners.sure_location")))])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }