<template>
    <v-card elevation="0" outlined v-resize="onResize" color="transparent">
        <v-card-text class="px-0">
            <!-- TABLE -->
            <v-row dense>
                <v-col cols="12" class="py-0">
                    <div v-if="userIsLoading && !hasData">
                        <LoadingBar></LoadingBar>
                    </div>
                    <div v-else>
                        <div v-if="hasData">
                            <v-card
                                v-for="user in users.edges"
                                :key="user.node.id"
                                class="mb-4 rounded-lg px-3"
                                height=""
                                outlined
                                flat
                                @click.stop="partnerSelect(user)"
                            >
                                <v-card-title>
                                    <v-row class="d-flex justify-center py-1">
                                        <v-col
                                            v-for="item in mainCard(user)"
                                            :key="item.text"
                                            :cols="item.cols.xs"
                                            :xs="item.cols.xs"
                                            :sm="item.cols.sm"
                                            :md="item.cols.md"
                                            :lg="item.cols.lg"
                                            :xl="item.cols.xl"
                                        >
                                            <div class="">
                                                <div class="textHeader text-truncate">
                                                    {{ item.title }}
                                                </div>
                                                <div class="textBody text-truncate" :style="item.style">
                                                    <v-icon
                                                        v-if="item.icon"
                                                        class="mr-1"
                                                        :size="item.iconSize"
                                                        :color="item.colorIcon"
                                                        >{{ item.icon }}</v-icon
                                                    >
                                                    <span v-if="item.value || item.value > -1">
                                                        {{ item.value }}
                                                    </span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>
                        </div>
                        <div v-else-if="!userIsLoading && !hasData">
                            <v-row class="d-flex align-center justify-center">
                                <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="text-center">
                                    <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')"></v-img>
                                    <v-card
                                        flat
                                        color="transparent"
                                        class="text-center"
                                    >
                                        <v-card-title>
                                            <div class="textTitle">
                                                {{ !hasData ? $t("general.empty_data_title") : "No history" }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text class="text-center">
                                            <div class="textSubtitle">
                                                {{ !hasData ? $t("general.empty_data_subtitle_1") : "No history" }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="px-10">
                                            <v-btn color="primary" block elevation="0">
                                                {{ $t("partners.add_location1") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <!-- LOADING TRIGGER -->
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.users.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                {{ $t("loading.load_more") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import LoadingBar from "@/components/base/LoadingBar.vue";

export default {
    name: "UsersTable",
    components: {
        LoadingBar
    },
    props: {
        usersFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    apollo: {
        users: {
            query: gql`
                query TenantUsers(
                    $tenantId: ID
                    $last: Int
                    $first: Int
                    $after: String
                    $before: String
                    $orderBy: String
                    $search: String
                ) {
                    tenantUsers(
                        tenantId: $tenantId
                        last: $last
                        first: $first
                        after: $after
                        before: $before
                        orderBy: $orderBy
                        search: $search
                    ) {
                        totalCount
                        pageInfo {
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                        edges {
                            node {
                                id
                                isAdmin
                                user {
                                    id
                                    firstName
                                    lastName
                                    fullName
                                    email
                                    isTenantAdmin
                                    lastLogin
                                    require2fa
                                    allTenantUserGroups {
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.me?.tenant?.id,
                    ...this.usersFilter
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUsers;
            },
            watchLoading(isLoading) {
                this.userIsLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    data() {
        return {
            users: { edges: [] },
            tableHeight: 800,
            userIsLoading: false
        };
    },
    computed: {
        loading() {
            return this.$apollo.queries.users.loading;
        },
        hasData() {
            return this.users?.edges?.length > 0;
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        hasMoreData() {
            return this.users?.pageInfo?.hasNextPage;
        }
    },
    methods: {
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 145 + "px";
            });
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        partnerSelect(user) {
            this.$emit("click:row", user);
        },
        loadMore() {
            if (this.users?.pageInfo?.hasNextPage) {
                this.$apollo.queries.users.fetchMore({
                    variables: {
                        after: this.users.pageInfo.endCursor
                    }
                });
            }
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.users?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        mainCard(item) {
            const { firstName, lastName, fullName, email, isTenantAdmin, lastLogin, require2fa } = item.node.user;

            let containerStyle = `
                    background-color: ${this.statusColor(require2fa).color1};	
                    border-radius: 4px;
                    width: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            `;

            const containerStyle2 = tag => {
                return `
                    background-color: ${this.statusColor(tag).color1};
                    border-radius: 4px;
                    padding:2px;
                    width: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `;
            };

            let _mainCard = [
                {
                    title: this.$t("headers.first_name"),
                    tag: "firstName",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    iconSize: null,
                    align: "left",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "2",
                        xl: "2"
                    },
                    value: firstName
                },
                {
                    title: this.$t("headers.last_name"),
                    tag: "lastName",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    iconSize: null,
                    align: "left",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "2",
                        xl: "2"
                    },
                    value: lastName
                },
                {
                    title: "Email",
                    tag: "email",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    iconSize: null,
                    align: "left",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "2",
                        xl: "2"
                    },
                    value: email
                },
                {
                    title: this.$t("general.admin"),
                    tag: "admin",
                    icon: null,
                    iconSize: null,
                    colorIcon: this.statusColor(isTenantAdmin).color2,
                    style: containerStyle2(isTenantAdmin),
                    align: "center",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "2",
                        xl: "2"
                    },
                    value: isTenantAdmin ? "Yes" : "No"
                },
                {
                    title: this.$t("headers.2fa_enabled"),
                    tag: "require2fa",
                    icon: "circle",
                    colorIcon: this.statusColor(require2fa).color2,
                    iconSize: 8,
                    style: containerStyle,
                    align: "center",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "2",
                        xl: "2"
                    },
                    value: this.statusText(require2fa)
                },
                {
                    title: this.$t("headers.last_login"),
                    tag: "lastLogin",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    iconSize: null,
                    align: "left",
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "2",
                        xl: "2"
                    },
                    value: lastLogin ? this.formatDateTime(lastLogin) : ""
                }
            ];

            return _mainCard;
        },
        statusColor(status) {
            if (status) {
                return {
                    color1: "#C8E6C9",
                    color2: "#1B5E20"
                };
            } else {
                return {
                    color1: "#FFCDD2",
                    color2: "#F44336"
                };
            }
        },
        statusText(status) {
            if (status) {
                return this.$t("headers.enabled");
            } else {
                return this.$t("headers.disabled");
            }
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
</style>
