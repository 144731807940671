<template>
    <main>
        <v-card class="py-3 px-3 mx-2 my-2" elevation="5" outlined>
            <v-card-text class="pb-3 pr-3">
                <!-- TABLE -->
                <v-data-table
                    :headers="headerChoices"
                    :items="users.edges"
                    item-key="node.id"
                    :loading-text="$t('loading.loading1')"
                    :loading="$apollo.queries.users.loading"
                    hide-default-footer
                    disable-pagination
                    disable-sort
                    loader-height="2"
                    :mobile-breakpoint="0"
                    :no-data-text="$t('loading.no_data')"
                    @click:row="item => this.$emit('click:row', item)"
                >
                    <template v-slot:item.node.user.lastLogin="{ item }">
                        <span>{{
                            formatDateTime(item.node.user.lastLogin)
                        }}</span>
                    </template>
                    <template v-slot:item.node.user.isTenantAdmin="{ item }">
                        <div class="align-self-center d-flex">
                            <v-icon
                                v-if="item.node.user.isTenantAdmin"
                                color="success"
                                >check_circle</v-icon
                            >
                            <v-icon color="grey lighten-2" v-else
                                >check_circle</v-icon
                            >
                        </div>
                    </template>
                    <template v-slot:item.node.user.enabled2fa="{ item }">
                        <div class="align-self-center d-flex">
                            <v-icon
                                v-if="item.node.user.enabled2fa"
                                color="success"
                                >check_circle</v-icon
                            >
                            <v-icon color="grey lighten-2" v-else
                                >check_circle</v-icon
                            >
                        </div>
                    </template>

                    <!-- Actions Column -->
                    <template v-slot:item.actions="{ item }">
                        <div class="d-flex flex-nowrap">
                            <v-tooltip bottom v-if="canLoginAsOtherUser">
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        outlined
                                        small
                                        color="warning"
                                        class="mr-1"
                                        :disabled="isLoggingInAsOtherUser"
                                        @click="
                                            loginAsOtherUser(item.node.user.id)
                                        "
                                    >
                                        <v-icon small>mouse</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("permissions.login_user") }}</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="canEdit">
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        outlined
                                        small
                                        color="primary"
                                        class="mr-1"
                                        @click="editUser(item)"
                                    >
                                        <v-icon small>edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("partners.edit_user") }}</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="canDelete">
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        outlined
                                        small
                                        color="error"
                                        @click="deleteUser(item.node)"
                                    >
                                        <v-icon small>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("users.delete_user") }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>

                <!---- EDIT PARTNER DIALOG ---->
                <EditUserDialog
                    ref="editUserDialog"
                    :showUserSelect="true"
                    :isStaffEditing="true"
                    :openEditUserDialog="openEditUserDialog"
                    v-on:refreshUpdatedUser="refetch"
                    v-on:closeEditUserDialog="openEditUserDialog = false"
                ></EditUserDialog>

                <!---- DELETE PARTNER DIALOG ---->
                <DeleteUserDialog
                    :openDeleteUserDialog="openDeleteUserDialog"
                    :userId="userToDelete"
                    :isStaffDeleting="true"
                    v-on:refreshDataTable="refetch"
                    v-on:closeDeleteDialog="
                        openDeleteUserDialog = false;
                        userToDelete = null;
                    "
                >
                </DeleteUserDialog>
            </v-card-text>
            <!-- LOADING TRIGGER -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.users.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    {{ $t("loading.load_more") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </main>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import DeleteUserDialog from "@/components/users/delete/DeleteUserDialog.vue";
import EditUserDialog from "@/components/users/update/EditUserDialog.vue";
import { TenantPermissions, StaffPermissions } from "@/core/enum";

export default {
    components: {
        DeleteUserDialog,
        EditUserDialog
    },
    props: {
        usersFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        tenant: {
            type: Object,
            required: true
        }
    },
    apollo: {
        users: {
            query: gql`
                query TenantUsers(
                    $tenantId: ID
                    $last: Int
                    $first: Int
                    $after: String
                    $before: String
                    $orderBy: String
                    $search: String
                ) {
                    tenantUsers(
                        tenantId: $tenantId
                        last: $last
                        first: $first
                        after: $after
                        before: $before
                        orderBy: $orderBy
                        search: $search
                    ) {
                        totalCount
                        pageInfo {
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                        edges {
                            node {
                                id
                                isAdmin
                                user {
                                    id
                                    firstName
                                    lastName
                                    fullName
                                    email
                                    isTenantAdmin
                                    lastLogin
                                    enabled2fa
                                    allTenantUserGroups {
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.tenant.id,
                    ...this.usersFilter
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUsers;
            },
            skip: false
        }
    },
    data() {
        return {
            users: { edges: [] },
            openEditUserDialog: false,
            openDeleteUserDialog: false,
            userToDelete: null,
            isLoggingInAsOtherUser: false,

            headerChoices: [
                {
                    text: this.$t("headers.first_name"),
                    align: "left",
                    value: "node.user.firstName"
                },
                {
                    text: this.$t("headers.last_name"),
                    align: "left",
                    value: "node.user.lastName"
                },
                {
                    text: "Email",
                    align: "left",
                    value: "node.user.email"
                },
                {
                    text: this.$t("general.admin"),
                    align: "left",
                    value: "node.user.isTenantAdmin"
                },

                {
                    text: this.$t("headers.2fa_enabled"),
                    align: "left",
                    value: "node.user.enabled2fa"
                },
                {
                    text: this.$t("headers.last_login"),
                    align: "left",
                    value: "node.user.lastLogin"
                },
                { text: "", value: "actions" }
            ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TenantPermissions = TenantPermissions;
        this.StaffPermissions = StaffPermissions;
        this.me = helper.me();
    },
    watch: {},
    computed: {
        hasMoreData() {
            return this.users?.pageInfo?.hasNextPage;
        },
        canEdit() {
            if (this.me?.isSuperuser) return true;

            if (
                !this.hasPermission(TenantPermissions.EDIT_TENANT_USER) ||
                !this.me?.isStaff
            )
                return false;

            return true;
        },
        canDelete() {
            if (this.me?.isSuperuser) return true;

            if (
                !this.hasPermission(TenantPermissions.DELETE_TENANT_USER) ||
                !this.me?.isStaff
            )
                return false;

            return true;
        },
        canLoginAsOtherUser() {
            if (this.me?.isSuperuser) return true;

            if (
                !this.hasPermission(StaffPermissions.LOGIN_AS_TENANT_USER) ||
                !this.me?.isStaff
            )
                return false;

            return true;
        }
    },
    methods: {
        refetch() {
            this.$apollo.queries.users.refetch();
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        loadMore() {
            if (this.users?.pageInfo?.hasNextPage) {
                this.$apollo.queries.users.fetchMore({
                    variables: {
                        after: this.users.pageInfo.endCursor
                    }
                });
            }
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.users?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        loginAsOtherUser(userId) {
            this.isLoggingInAsOtherUser = true;

            const payload = { userId: userId };

            this.$store
                .dispatch("user/loginAsOtherUser", payload)
                .then(() => {
                    this.$router.push(this.$route.query.redirect || "/");
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            // show snackbar
                            const payload = {
                                color: "error",
                                message: err.message
                            };
                            this.$store.dispatch(
                                "snackbar/showMessage",
                                payload
                            );
                        }
                    }
                })
                .finally(() => {
                    this.isLoggingInAsOtherUser = false;
                });
        },
        editUser(user) {
            this.openEditUserDialog = true;
            this.$refs.editUserDialog.initializeUser(user);
        },
        deleteUser(user) {
            this.userToDelete = user.id;
            this.openDeleteUserDialog = true;
        }
    }
};
</script>
