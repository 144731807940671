<template>
    <v-card flat class="mt-1" max-width="900" max-height="500" rounded="xl">
        <v-card-text class="px-0 py-0">
            <v-row dense>
                <v-col cols="12" class="pa-0 ma-0">
                    <div
                        class="borderDash cardSize"
                        @dragover.prevent="dragOver = true"
                        @dragenter.prevent="dragOver = true"
                        @dragleave.prevent="dragOver = false"
                        @drop.prevent
                    >
                        <v-card flat height="250" :class="{ 'grey lighten-2': dragOver }">
                            <v-card-title class="mb-2">
                                <v-file-input
                                    multiple
                                    v-model="inputDoc"
                                    prepend-icon=""
                                    class="subtitle-2 included"
                                    ref="fileInputRef"
                                    style="display:none"
                                ></v-file-input>
                            </v-card-title>
                            <v-card-text @drop="dragFile">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="d-flex align-center justify-center"
                                    >
                                        <v-icon
                                            color="primary"
                                            size="60"
                                            class=""
                                            >cloud_upload</v-icon
                                        >
                                    </v-col>
                                    <v-col cols="12">
                                        <div
                                            class="d-flex align-center text-center justify-center primary--text subtitle-1"
                                        >
                                            {{ $t("general.drag") }} <br />
                                            {{ $t("general.or") }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions
                                class="d-flex justify-center align-center"
                            >
                                <v-btn
                                    color="primary"
                                    rounded
                                    outlined
                                    @click.prevent="handleFileImport"
                                    elevation="0"
                                    small
                                >
                                    <div class="text-capitalize mr-1">
                                        {{ $t("general.upload") }}
                                    </div>
                                    <v-icon class="">cloud_upload</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    class="px-0 py-1 mx-0 mb-0 mt-1"
                    v-if="showSelectedFile && documents.length > 0"
                >
                    <v-form v-model="validDescription">
                        <v-list dense>
                            <v-card flat>
                                <v-list-item-group>
                                    <v-list-item
                                        v-for="(doc, i) in documents"
                                        :key="i"
                                        class="pl-2 pr-2"
                                    >
                                        <v-list-item-title>
                                            <div
                                                class="subtitle-2 text-truncate mt-1"
                                                style="width:200px"
                                            >
                                                <v-icon
                                                    color="primary"
                                                    class="mt-n1"
                                                    >upload_file</v-icon
                                                >{{ doc.name }}
                                            </div>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-text-field
                                                :rules="[rules.maxLength(200)]"
                                                v-model="doc.description"
                                                :placeholder="$t('general.description')"
                                                dense
                                                flat
                                                filled
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-list-item-subtitle>
                                        <v-list-item-action>
                                            <v-btn
                                                small
                                                text
                                                icon
                                                class="mr-1"
                                                color="error"
                                                @click="deleteFile(i)"
                                            >
                                                <v-icon>close</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-card>
                        </v-list>
                    </v-form>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import helper from "@/utils/helper.js";

export default {
    components: {},
    props: {
        showSelectedFile: {
            type: Boolean,
            default: true
        }
    },
    mounted() {},
    data: function() {
        return {
            dragOver: false,
            inputDoc: [],
            documents: [],
            fields: [],
            loadFiles: false,
            validDescription: true,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len || this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        fileLength: {
            // getter
            get() {
                return this.documents.length > 0 ? true : false;
            }
        }
    },
    watch: {
        fileLength: function(newVal) {
            this.$emit("disabledUpload", newVal);
        },
        validDescription: function(newVal) {
            this.$emit("disabledUpload", newVal);
        },
        inputDoc: function(val) {
            if (val) {
                this.docFile(val);
            }
        }
    },
    methods: {
        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener(
                "focus",
                () => {
                    this.isSelecting = false;
                },
                { once: true }
            );
            this.$refs.fileInputRef.$refs.input.click();
        },
        include() {
            return [document.querySelector(".included")];
        },
        //Upload Functions
        uploadFile() {
            if (this.documents.length < 1) return;
            this.documents.forEach(item => {
                this.fields.push({ file: item });
            });
            this.$emit("upload", this.fields);
        },
        docFile(inputDocs) {
            if(!this.showSelectedFile){
                this.$emit("upload", inputDocs[0]);
            }else{
                inputDocs.forEach(file => {
                    this.documents.push(file);
                });
            }
        },
        dragFile(e) {
            this.dragOver = false;
            let files = e.dataTransfer.files;
            if(!this.showSelectedFile){
                this.$emit("upload", files[0]);
            }else{
                for (let i = 0; i < files.length; i++) {
                    this.documents.push(files[i]);
                }
            }
        },
        deleteFile(index) {
            this.documents.splice(index, 1);
        },
        closeChip(index) {
            this.documents.splice(index, 1);
        },
        clearCache() {
            this.fields = [];
            this.documents = [];
        }
    }
};
</script>
<style scoped>
.cardSize {
    width: 100%;
    height: 100%;
}

.borderDash {
    border-width: 2px;
    box-sizing: border-box;
    border-color: rgb(173, 173, 173);
    border-style: dashed;
}

.rotate-315 {
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg);
}

.v-list {
    height: 130px;
    overflow-y: auto;
}
</style>
