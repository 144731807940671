<template>
    <v-container fluid class="px-0 py-0" style="background-color: transparent;">
        <v-row dense>
            <v-col cols="12" v-for="(freight, i) in freights" :key="i">
                <v-form v-model="formValue" :ref="`freightForm-${i}`">
                    <div class="textTitle mt-8 d-flex flex-row px-2" style="height: 30px;">
                        <div class="px-0 py-0">{{ $t("headers.freight") }} {{ i + 1 }}</div>
                        <v-spacer class="py-0"></v-spacer>
                        <div v-if="freights.length > 1" class="px-0">
                            <v-btn icon small class="mt-n1" color="#0e1e46" @click="deleteItem(i)"
                                ><v-icon>delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-row class="align-self-stretch" justify="space-between">
                        <v-col cols="12" xs="12" sm="12" md="" lg="" xl="">
                            <v-card flat class="rounded-lg contentDisplay" height="100%">
                                <v-card-title class="px-0 textTitle">
                                    {{ $t("general.freight_management") }}
                                </v-card-title>
                                <!--   <v-card-subtitle class="px-0 textSubTitle1">
                                    {{ $t("general.optional_message_1") }} 
                                    <span class="optimization">{{ $t("general.optional_message_2") }}</span>.
                                </v-card-subtitle> -->
                                <v-card-text class="px-0">
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{
                                                    templateMode
                                                        ? $t("forms.freight_ref")
                                                        : $t("forms.freight_ref") + " *"
                                                }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="freight.referenceNumber"
                                        outlined
                                        dense
                                        :rules="[rules.required]"
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        class="inputContent"
                                    >
                                    </v-text-field>
                                    <v-row dense v-if="trip.transportMode === 'sea'">
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("headers.container_number") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="freight.containerNumber"
                                        outlined
                                        v-if="trip.transportMode === 'sea'"
                                        dense
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        class="inputContent"
                                    >
                                    </v-text-field>
                                    <v-row dense v-if="trip.transportMode === 'sea'">
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("forms.bill_lading") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-if="trip.transportMode === 'sea'"
                                        v-model="freight.billsOfLading"
                                        outlined
                                        dense
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        class="inputContent"
                                    ></v-text-field>
                                    <v-row dense v-if="trip.transportMode === 'air'">
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("forms.awb") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-if="trip.transportMode === 'air'"
                                        v-model="freight.awb"
                                        outlined
                                        dense
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        class="inputContent"
                                    >
                                    </v-text-field>
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("forms.goods") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="freight.goods"
                                        outlined
                                        dense
                                        class="inputContent"
                                    ></v-text-field>
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("forms.order_no") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="freight.orderNumber"
                                        outlined
                                        dense
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        class="inputContent"
                                    ></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-card
                            class="d-flex justify-center align-center"
                            width="1px"
                            flat
                            style="
                                background-color: transparent;
                            "
                        >
                        </v-card>
                        <v-col cols="12" xs="12" sm="12" md="" lg="" xl="">
                            <v-card flat class="rounded-lg contentDisplay" height="100%">
                                <v-card-title class="px-0 textTitle">
                                    {{ $t("general.tracker_alerts_menagement") }}
                                </v-card-title>
                                <!-- <v-card-subtitle class="px-0 textSubTitle1">
                                    {{ $t("general.optional_message_1") }} <span class="optimization">{{ $t("general.optional_message_2") }}</span>.
                                </v-card-subtitle> -->
                                <v-card-text class="px-0">
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("forms.select_alert_rule") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <AlertRuleAutoComplete
                                        v-model="freight.alerts"
                                        :dense="true"
                                        :hideDetails="false"
                                        :refresh.sync="refresh"
                                        class="inputContent"
                                        :activeSlot="true"
                                    >
                                        <template #custom-action>
                                            <v-btn color="primary" block class="mt-4" @click="addAlertRuleMethod(i)">
                                                {{ $t("general.create_alert") }}
                                            </v-btn>
                                        </template>
                                    </AlertRuleAutoComplete>
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("forms.select_tracker") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <TrackerSelected
                                        v-model="freight.trackers"
                                        class="inputContent"
                                        :dense="true"
                                        :hideDetails="false"
                                        :currentTrackers="currentTrackers"
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        :trackersInUse="trackersInUse"
                                        :rules="[rules.isAlreadySelected(freight, i)]"
                                    >
                                    </TrackerSelected>
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("trackers.tracker_start_date") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <DateTimeComponent
                                        v-model="freight.startDate"
                                        :icon="'calendar_month'"
                                        :outlined="true"
                                        :dense="true"
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        :defaultTime="'00:00'"
                                        :rules="[trackerDateChange(freight.startDate, freight.endDate)]"
                                        class="mb-n2 inputContent"
                                    >
                                    </DateTimeComponent>
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("trackers.tracker_end_date") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <DateTimeComponent
                                        v-model="freight.endDate"
                                        class="inputContent"
                                        :icon="'calendar_month'"
                                        :outlined="true"
                                        :dense="true"
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        :defaultTime="'00:00'"
                                        :rules="
                                            freight.endDate !== null && freight.endDate.length > 0
                                                ? [checkTrackerDate(freight.startDate, freight.endDate)]
                                                : []
                                        "
                                    >
                                    </DateTimeComponent>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-expansion-panels flat class="rounded-lg px-0" v-model="panel" multiple>
                                <v-expansion-panel class="px-0">
                                    <v-expansion-panel-header>
                                        <div class="pl-4">
                                            <div class="textTitle ">
                                                {{ $t("general.adtional_details") }}
                                            </div>
                                            <div class="px-0 textSubTitle1">
                                                {{ $t("general.optional_message_3") }}
                                                <span class="optimization">{{ $t("general.optional_message_4") }}</span
                                                >.
                                            </div>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="mb-4 px-3">
                                        <v-row>
                                            <v-col class="contentDisplay2" cols="12" xs="12" sm="12" md="" lg="" xl="">
                                                <v-row dense>
                                                    <v-col cols="auto" class="textBody labelContent">
                                                        <span>
                                                            {{ $t("forms.load_point") }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                                <v-text-field
                                                    v-model="freight.loadPoint"
                                                    outlined
                                                    dense
                                                    class="inputContent"
                                                ></v-text-field>
                                                <!-- <v-autocomplete
                                                    v-model="freight.loadPoint"
                                                    class="inputContent"
                                                    :loading="$apollo.queries.tenantLocations.loading"
                                                    :no-data-text="$t('loading.no_available')"
                                                    :items="tenantLocations ? tenantLocations.edges : []"
                                                    item-text="node.name"
                                                    item-value="node.id"
                                                    outlined
                                                    clearable
                                                    hide-selected
                                                    dense
                                                >
                                                    <template v-slot:item="data">
                                                        <v-row class="ma-0 body-2">
                                                            <v-col cols="9" class="text-truncate pl-0 ml-0">
                                                                {{ data.item.node.name }}
                                                            </v-col>
                                                            <v-col cols="3">
                                                                <span class="grey--text mr-0 float-right"
                                                                    ><i>{{
                                                                        selectLabelByType(
                                                                            data.item.node.locationType.toLowerCase()
                                                                        )
                                                                    }}</i></span
                                                                >
                                                            </v-col>
                                                        </v-row>
                                                    </template> 
                                                    <template #append-item>
                                                        <v-divider class="mt-4 append-divider"></v-divider>
                                                        <v-list-item dense class="append">
                                                            <v-list-item-content>
                                                                <v-btn
                                                                    color="primary"
                                                                    @click="openCreateLocationDialog('loadPoint', i)"
                                                                    block
                                                                    class="mt-4"
                                                                >
                                                                    {{ $t("general.create_location") }}
                                                                </v-btn>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-autocomplete>-->
                                                <v-row dense>
                                                    <v-col cols="auto" class="textBody labelContent">
                                                        <span>
                                                            {{ $t("forms.load_date") }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                                <DateTimeComponent
                                                    v-model="freight.loadDate"
                                                    class="inputContent mb-n2"
                                                    :icon="'calendar_month'"
                                                    :outlined="true"
                                                    :dense="true"
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                    :defaultTime="'00:00'"
                                                >
                                                </DateTimeComponent>
                                            </v-col>
                                            <!-- Content  -->
                                            <v-card
                                                v-if="!isSmallScreen"
                                                class="d-flex justify-center align-center"
                                                width="20px"
                                                flat
                                                style="
                                                    background-color: transparent;
                                                "
                                            >
                                            </v-card>
                                            <v-col class="contentDisplay2" cols="12" xs="12" sm="12" md="" lg="" xl="">
                                                <div>
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("forms.contact_person") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="freight.contact"
                                                        outlined
                                                        dense
                                                        class="inputContent"
                                                    ></v-text-field>
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("forms.term") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-select
                                                        class="inputContent"
                                                        v-model="freight.term"
                                                        :items="termChoices"
                                                        outlined
                                                        dense
                                                    ></v-select>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <v-col cols="12" class="d-flex justify-center align-center mt-4">
                <v-btn color="primary" small elevation="0" @click="addFreight">
                    Add extra freight
                </v-btn>
            </v-col>
        </v-row>
        <AddAlertRuleDialog
            :openAddAlertRuleDialog="openAddAlertRule"
            :createMode="true"
            v-on:refreshDataTable="refresh = true"
            v-on:close-dialog="openAddAlertRule = false"
            v-on:item-created="onItemCreated"
        ></AddAlertRuleDialog>
        <AddLocation
            :openAddLocationDialog="openAddLocation"
            v-on:closeMap="openAddLocation = false"
            v-on:updateLocationsTable="$apollo.queries.tenantLocations.refetch()"
            v-on:updateLocation="updateLocation"
        >
        </AddLocation>
    </v-container>
</template>
<script>
import helper from "@/utils/helper.js";
import TrackerSelected from "@/components/trackers/TrackerSelected.vue";
import AlertRuleAutoComplete from "@/components/alertrules/AlertRuleAutoComplete.vue";
import AddAlertRuleDialog from "@/components/alertrules/create/AddAlertRuleDialog.vue";
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import AddLocation from "@/components/locations/create/AddLocation.vue";
import moment from "moment-timezone";
import gql from "graphql-tag";

export default {
    props: {
        trip: {
            type: Object
        },
        value: {
            type: Array
        },
        validate: {
            type: Boolean,
            default: false
        },
        currentTrackers: {
            type: Array
        },
        templateMode: {
            type: Boolean,
            default: false
        },
        plannedDepartureDate: {
            type: String
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TrackerSelected,
        AlertRuleAutoComplete,
        AddLocation,
        // DateComponent,
        //TimeComponent,
        AddAlertRuleDialog,
        DateTimeComponent
        //FreightsForm,
    },
    data() {
        return {
            icons: {
                air: "flight",
                road: "local_shipping",
                rail: "train",
                ship: "directions_boat"
            },
            panel: [0, 1],
            trackersInUse: [],
            dialogForm: false,
            validateForm: false,
            toggleTitle: false,
            openAddAlertRule: false,
            freightIndex: 0,
            refresh: false,
            item: {},
            index: 0,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.invalid") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long"),
                alertIsInvalid: v => v === false || this.$t("rules.ref_no_exists"),
                isAlreadySelected: (item, index) => {
                    let currentItem = item;
                    let currentIndex = index;
                    let isAlreadySelected = false;

                    this.freights?.forEach((item, index) => {
                        if (index !== currentIndex) {
                            item.trackers?.forEach(tracker => {
                                let currentTrackers = currentItem.trackers;
                                currentTrackers.forEach(currentTracker => {
                                    if (tracker === currentTracker && tracker !== null) {
                                        isAlreadySelected = true;
                                    }
                                });
                            });
                        }
                    });

                    return !isAlreadySelected || this.$t("rules.tracker_already_selected");
                }
            },
            termChoices: [
                {
                    value: "DAP",
                    text: this.$t("term.dap") + " (DAP)"
                },
                {
                    value: "DAT",
                    text: this.$t("term.dat") + " (DAT)"
                },
                {
                    value: "DDP",
                    text: this.$t("term.ddp") + " (DDP)"
                },
                {
                    value: "DPU",
                    text: this.$t("term.dpu") + " (DPU)"
                },
                {
                    value: "CFR",
                    text: this.$t("term.cfr") + " (CFR)"
                },
                {
                    value: "CIF",
                    text: this.$t("term.cif") + " (CIF)"
                },
                {
                    value: "CIP",
                    text: this.$t("term.cip") + " (CIP)"
                },
                {
                    value: "CPT",
                    text: this.$t("term.cpt") + " (CPT)"
                },
                {
                    value: "EXW",
                    text: this.$t("term.exw") + " (EXW)"
                },
                {
                    value: "FCA",
                    text: this.$t("term.fca") + " (FCA)"
                },
                {
                    value: "FAS",
                    text: this.$t("term.fas") + " (FAS)"
                },
                {
                    value: "FOB",
                    text: this.$t("term.fob") + " (FOB)"
                }
            ],
            openAddLocation: false,
            locationType: "",
            newLoadPointIndex: null
        };
    },
    apollo: {
        tenantLocations: {
            query: gql`
                query tenantLocations(
                    $search: String
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $tenantPartnerNameIcontains: String
                    $nameIcontains: String
                    $cityIcontains: String
                    $countryIcontains: String
                ) {
                    tenantLocations(
                        search: $search
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: "name"
                        tenantPartner_Name_Icontains: $tenantPartnerNameIcontains
                        name_Icontains: $nameIcontains
                        city_Icontains: $cityIcontains
                        country_Icontains: $countryIcontains
                        locationType_In: ["tenant_location", "partner_location", "private_location"]
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                locationType
                                isTrackerStockLocation
                                fullAddress
                                city
                                state
                                country
                            }
                        }
                    }
                }
            `,
            variables() {},
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            debounce: 800,
            update: data => {
                return data.tenantLocations;
            },
            skip: false
        }
    },
    computed: {
        freights: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        // check v-model form value and emit to update to parent
        formValue: {
            get() {
                return this.validate;
            },
            set(value) {
                this.$emit("update:validate", value);
            }
        },
        inputContent() {
            return {
                height: "52px"
            };
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 960;
        }
    },
    mounted() {},
    watch: {
        freights: {
            handler: function(val) {
                if (val.length > 0) {
                    val.forEach(freight => {
                        if (freight.trackers.length > 0) {
                            freight.trackers.forEach(tracker => {
                                this.trackersInUse.push(tracker);
                            });
                        }
                    });
                }
            },
            deep: true
        }
    },
    methods: {
        selectLabelByType(val) {
            if (val === "tenant_location") return this.$t("alert_rules.public");
            if (val === "partner_location") return this.$t("home.partner");
            if (val === "private_location") return "";
        },
        openCreateLocationDialog(type, index) {
            this.$emit("createLocation", {
                path: "/createlocation",
                query: {
                    type: "createLocation",
                    field: `${type}-${index}`
                }
            });
        },
        updateLocation(id) {
            if (this.newLoadPointIndex) {
                this.freights[this.newLoadPointIndex].loadPoint = id;
            }
        },
        addAlertRuleMethod(index) {
            this.openAddAlertRule = true;
            this.freightIndex = index;
        },
        clickAdd() {
            this.toggleTitle = false;
            this.dialogForm = true;
        },
        addFreight() {
            this.freights.push({
                referenceNumber: null,
                containerNumber: null,
                awb: null,
                orderNumber: null,
                billsOfLading: null,
                contact: "",
                loadPoint: "",
                loadDate: null,
                startDate: null,
                endDate: null,
                /*  startTime: null,
                endTime: null, */
                goods: null,
                term: null,
                trackers: [],
                alerts: []
            });
        },
        deleteItem(index) {
            this.freights.splice(index, 1);
        },
        editItem(item, index) {
            this.item = { ...item };
            this.index = index;
            this.toggleTitle = true;
            this.dialogForm = true;
        },
        aditFreigth(value) {
            this.freights.splice(this.index, 1, { ...value });
            this.index = 0;
            this.item = {};
            this.closeFreightForm();
        },
        closeFreightForm() {
            this.dialogForm = false;
        },
        clearCache() {
            this.freights = [];
        },
        onItemCreated(item) {
            this.freights[this.freightIndex].alerts.push(item.id);
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        disablePastDates(val) {
            return val >= this.plannedDepartureDate;
        },
        checkTrackerDate(startDate, endDate) {
            if (startDate === null || startDate.length < 1) {
                return true;
            }
            if (endDate === null || endDate.length < 1) {
                return true;
            }

            let [dateA, timeA] = startDate.split(" ");
            let [dateB, timeB] = endDate.split(" ");

            if (helper.isEmpty(dateA) || helper.isEmpty(timeA)) {
                return true;
            }

            let _newDateA = moment(dateA, helper.getDateFormat());
            let _DateA = moment(_newDateA, ["DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY"]).toDate();
            let _dateFormatA = moment(_DateA).format("YYYY-MM-DD");

            //dateTime B
            let _newDateB = moment(dateB, helper.getDateFormat());
            let _DateB = moment(_newDateB, ["DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY"]).toDate();
            let _dateFormatB = moment(_DateB).format("YYYY-MM-DD");

            let dateTimeA = _dateFormatA + " " + timeA;
            let dateTimeB = _dateFormatB + " " + timeB;

            let validDate = moment(dateTimeA).isSameOrBefore(dateTimeB);

            return validDate || this.$t("rules.end_date");
        },
        trackerDateChange(startDate, endDate) {
            if (startDate === null || startDate.length < 1) {
                return true;
            }

            if (endDate === null || endDate.length < 1) {
                return true;
            }

            this.checkTrackerDate(startDate, endDate);

            return true;
        }
    }
};
</script>
<style scoped>
.columns {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 4px;
}
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textSubTitle1 {
    /* font-family: Lato; */
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
    /* font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left; */
}

.optimization {
    color: #377ef2;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: #868599 !important;
}
/* .inputContent {
    height: 52px;
} */
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 36px;
    padding-right: 36px;
}
.contentDisplay2 {
    padding-left: 36px;
    padding-right: 36px;
}
.contentDisplay3 {
    padding-left: 70px;
    padding-right: 70px;
}
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
</style>
