<template>
    <v-container fluid class="px-0">
        <v-card class="mt-4" color="transparent" flat outlined>
            <v-card-text class="px-0">
                <v-expansion-panels class="elevation-4" v-model="panels" multiple>
                    <v-expansion-panel
                        v-for="standardAlertPainel in standardAlertPainel"
                        :key="standardAlertPainel.name"
                    >
                        <v-expansion-panel-header disable-icon-rotate>
                            <template v-slot:default="{ open }" ref="header">
                                <v-row class="d-flex align-center">
                                    <v-col cols="11">
                                        <v-row class="d-flex align-center">
                                            <v-col cols="11">
                                                <span class="subtitle-1">{{ standardAlertPainel.name }} </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card
                                outlined
                                flat
                                class="pa-5"
                                color="grey lighten-4"
                                v-if="standardAlertPainel.standardAlertRules.length > 0"
                            >
                                <!-- <v-card-title>
                                    <v-spacer></v-spacer>

                                    
                                        
                                </v-card-title> -->
                                <v-card-text class="pa-0">
                                    <v-row>
                                        <v-col cols="10">
                                            <div
                                                v-for="standardRule in standardAlertPainel.standardAlertRules"
                                                :key="standardRule.code"
                                            >
                                                <v-row dense class="d-flex align-center">
                                                    <v-switch
                                                        class="mb-n3"
                                                        v-model="standardRule.enabled"
                                                        :disabled="!editMode"
                                                    ></v-switch>
                                                    <span class="mr-3 subtitle-1">{{ standardRule.text1 }} </span>
                                                    <v-text-field
                                                        class="centered-input mt-3"
                                                        v-model="standardRule.value"
                                                        placeholder="0"
                                                        type="number"
                                                        min="1"
                                                        max="336"
                                                        dense
                                                        :disabled="!editMode"
                                                        style="max-width: 50px;"
                                                    ></v-text-field>
                                                    <span class="ml-3 subtitle-1">
                                                        {{ standardRule.text2 }}
                                                    </span>
                                                </v-row>
                                                <v-row class="d-flex text-center align-center">
                                                    <v-col cols="3" class="mx-10">
                                                        <v-autocomplete
                                                            dense
                                                            v-model="standardRule.selectedGroups"
                                                            :items="actionGroups.edges"
                                                            :no-data-text="$t('loading.no_available')"
                                                            :label="$t('alert_rules.selected_groups') + '*'"
                                                            :loading="$apollo.queries.actionGroups.loading"
                                                            outlined
                                                            hide-selected
                                                            hide-details
                                                            deletable-chips
                                                            small-chips
                                                            :disabled="!editMode"
                                                            item-text="node.name"
                                                            item-value="node.id"
                                                            multiple
                                                            attach
                                                        >
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-row class="ml-8">
                                                            <v-col cols="12" class="d-flex justify-start pb-0 pt-4">
                                                                <span class="subtitle-1">
                                                                    {{ $t("alert_rules.should_notify") }}:
                                                                </span>
                                                            </v-col>
                                                            <v-col
                                                                cols="auto"
                                                                class="pt-0  d-flex align-center justify-center"
                                                            >
                                                                <v-checkbox
                                                                    v-model="
                                                                        standardRule.notifyPartners.shouldNotifyCarrier
                                                                    "
                                                                    :label="$t('headers.carrier')"
                                                                    :disabled="!editMode"
                                                                    hide-details
                                                                    dense
                                                                >
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="auto" class="pt-0">
                                                                <v-checkbox
                                                                    v-model="
                                                                        standardRule.notifyPartners
                                                                            .shouldNotifyConsignee
                                                                    "
                                                                    :label="$t('headers.consignee')"
                                                                    :disabled="!editMode"
                                                                    hide-details
                                                                    dense
                                                                >
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="auto" class="pt-0">
                                                                <v-checkbox
                                                                    v-model="
                                                                        standardRule.notifyPartners
                                                                            .shouldNotifyConsignor
                                                                    "
                                                                    :label="$t('headers.consignor')"
                                                                    :disabled="!editMode"
                                                                    hide-details
                                                                    dense
                                                                >
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="auto" class="pt-0">
                                                                <v-checkbox
                                                                    v-model="
                                                                        standardRule.notifyPartners
                                                                            .shouldNotifyForwarder
                                                                    "
                                                                    :label="$t('headers.forwarder')"
                                                                    :disabled="!editMode"
                                                                    hide-details
                                                                    dense
                                                                >
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="auto" class="pt-0">
                                                                <v-checkbox
                                                                    v-model="
                                                                        standardRule.notifyPartners.shouldNotifyOther
                                                                    "
                                                                    :label="$t('home.other')"
                                                                    :disabled="!editMode"
                                                                    hide-details
                                                                    dense
                                                                >
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-col>
                                        <v-col cols="2">
                                            <div class="float-sm-right">
                                                <v-btn rounded v-if="!editMode" @click="editAlert" color="primary">
                                                    <v-icon>edit</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    v-if="editMode"
                                                    rounded
                                                    class="mr-1"
                                                    @click="closeAndResetInput()"
                                                    >{{ $t("general.cancel") }}</v-btn
                                                >
                                                <v-btn
                                                    v-if="editMode"
                                                    :loading="isLoading"
                                                    @click="saveStandardRules(standardAlertPainel.standardAlertRules)"
                                                    color="primary"
                                                    rounded
                                                    dense
                                                    >{{ $t("general.save") }}</v-btn
                                                >
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="ml-2 grey--text">
                                            <span class="subtitle-1">
                                                <v-icon color="primary">info</v-icon>
                                                {{ $t("alert_rules.alert_description_text") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end "> </v-card-actions>
                            </v-card>
                            <v-card outlined class="pa-5 mt-5" color="grey lighten-4" v-else>
                                {{ $t("general.coming_soon") }}
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    title: "Standard Alert Rules",
    data() {
        return {
            isLoading: false,
            panels: [],
            actionGroups: { edges: [] },
            editMode: false,
            ind: 0
            // seaAlerts: [
            //     {
            //         id: null,
            //         code: "SEA_ETD_DELAY",
            //         name: "ETD Delay",
            //         text1: "When POL ETD Delays ",
            //         text2: " hour(s), then ",
            //         enabled: false,
            //         value: null,
            //         notifyPartners: {
            //             shouldNotifyCarrier: false,
            //             shouldNotifyConsignee: false,
            //             shouldNotifyConsignor: false,
            //             shouldNotifyForwarder: false,
            //             shouldNotifyOther: false
            //         },
            //         selectedGroups: []
            //     },
            //     {
            //         id: null,
            //         code: "SEA_ETA_DELAY",
            //         name: "ETA Delay",
            //         text1: "When POL ETA Delays ",
            //         text2: " hour(s), then ",
            //         enabled: false,
            //         value: null,
            //         notifyPartners: {
            //             shouldNotifyCarrier: false,
            //             shouldNotifyConsignee: false,
            //             shouldNotifyConsignor: false,
            //             shouldNotifyForwarder: false,
            //             shouldNotifyOther: false
            //         },
            //         selectedGroups: []
            //     },
            //     {
            //         id: null,
            //         code: "SEA_TRANS_WAIT",
            //         name: "T/S Waitings",
            //         text1: "When freight stays in T/S Waitings longer than ",
            //         text2: " hour(s), then ",
            //         enabled: false,
            //         value: null,
            //         notifyPartners: {
            //             shouldNotifyCarrier: false,
            //             shouldNotifyConsignee: false,
            //             shouldNotifyConsignor: false,
            //             shouldNotifyForwarder: false,
            //             shouldNotifyOther: false
            //         },
            //         selectedGroups: []
            //     },
            //     {
            //         id: null,
            //         code: "SEA_DEST_WAIT",
            //         name: "POD Waitings",
            //         text1: "When freight stays in POD Waitings longer than ",
            //         text2: " hour(s), then ",
            //         enabled: false,
            //         value: null,
            //         notifyPartners: {
            //             shouldNotifyCarrier: false,
            //             shouldNotifyConsignee: false,
            //             shouldNotifyConsignor: false,
            //             shouldNotifyForwarder: false,
            //             shouldNotifyOther: false
            //         },
            //         selectedGroups: []
            //     }
            // ]
        };
    },
    apollo: {
        actionGroups: {
            query: gql`
                query tenantActionGroups {
                    tenantActionGroups {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantActionGroups
        },
        alertRules: {
            query: gql`
                query tenantAlertRules($isStandardAlert: Boolean!) {
                    tenantAlertRules(isStandardAlert: $isStandardAlert) {
                        edges {
                            node {
                                id
                                isEnabled
                                name
                                shouldNotifyPartner
                                standardAlertCode
                                alertSeverity
                                tenantAlertRuleActionGroupSet {
                                    edges {
                                        node {
                                            id
                                            tenantActionGroup {
                                                id
                                                name
                                            }
                                        }
                                    }
                                }
                                tenantAlertRuleTriggerSet {
                                    edges {
                                        node {
                                            id
                                            triggerJson
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    isStandardAlert: true
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantAlertRules,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        tenantStandardAlertRules() {
            return this.alertRules?.edges || [];
        },
        standardAlertPainel() {
            let _painel = [];

            const jsonParse = str => {
                let json = JSON.parse(str);
                
                // Check if the key 'shouldNotifyNotifyOther' exists
                if (json.hasOwnProperty("shouldNotifyNotifyOther")) {
                   

                    // Copy the value to the new key 'shouldNotifyOther'
                    json["shouldNotifyOther"] = json["shouldNotifyNotifyOther"];
                    // Remove the old key 'shouldNotifyNotifyOther'
                    delete json["shouldNotifyNotifyOther"];
                }

                return json;
            };

            _painel = [
                {
                    name: this.$t("alert_rules.sea_transport"),
                    standardAlertRules: [...this.seaAlerts]
                },
                {
                    name: this.$t("alert_rules.air_transport"),
                    standardAlertRules: []
                },
                {
                    name: this.$t("alert_rules.rail_transport"),
                    standardAlertRules: []
                },
                {
                    name: this.$t("alert_rules.road_transport"),
                    standardAlertRules: []
                }
            ];

            if (this.tenantStandardAlertRules.length > 0) {
                this.tenantStandardAlertRules.forEach(e => {
                    let _rule = e.node;
                    let _id = _rule.id;
                    let _code = _rule.standardAlertCode;
                    //let _name = _rule.name;
                    let _text1 = _rule.tenantAlertRuleTriggerSet?.edges[0]?.node?.triggerJson.text1;
                    let _text2 = _rule.tenantAlertRuleTriggerSet?.edges[0]?.node?.triggerJson.text2;
                    let _value_parse = _rule.tenantAlertRuleTriggerSet?.edges[0]?.node?.triggerJson;
                    let _value = _value_parse ? parseInt(JSON.parse(_value_parse).RemainMinutes / 60) : null;
                    let _notifyPartners = _rule.shouldNotifyPartner
                        ? jsonParse(_rule.shouldNotifyPartner)
                        : {
                              shouldNotifyCarrier: false,
                              shouldNotifyConsignee: false,
                              shouldNotifyConsignor: false,
                              shouldNotifyForwarder: false,
                              shouldNotifyOther: false
                          };

                    let _enabled = _rule.isEnabled;
                    let _selectedGroups = _rule.tenantAlertRuleActionGroupSet.edges.map(
                        e => e.node.tenantActionGroup.id
                    );

                    _painel.forEach(i => {
                        let _ruleIndex = i.standardAlertRules.findIndex(r => r.code === _code);
                        if (_ruleIndex > -1) {
                            i.standardAlertRules[_ruleIndex].id = _id;
                            i.standardAlertRules[_ruleIndex].value = _value
                                ? _value
                                : i.standardAlertRules[_ruleIndex].value;
                            i.standardAlertRules[_ruleIndex].notifyPartners = _notifyPartners
                                ? _notifyPartners
                                : i.standardAlertRules[_ruleIndex].notifyPartners;
                            i.standardAlertRules[_ruleIndex].text1 = _text1
                                ? _text1
                                : i.standardAlertRules[_ruleIndex].text1;
                            i.standardAlertRules[_ruleIndex].text2 = _text2
                                ? _text2
                                : i.standardAlertRules[_ruleIndex].text2;
                            i.standardAlertRules[_ruleIndex].enabled = _enabled;
                            i.standardAlertRules[_ruleIndex].selectedGroups = _selectedGroups;
                        }
                    });
                });
            } else {
                return _painel;
            }

            return _painel || [];
        },
        seaAlerts: function() {
            let _seaAlerts = [
                {
                    id: null,
                    code: "SEA_ETD_DELAY",
                    name: this.$t("alert_rules.etd_delay"),
                    text1: this.$t("general.when") + " " + this.$t("alert_rules.pol_etd_delay"),
                    text2: this.$t("alert_rules.hours_then"),
                    enabled: false,
                    value: null,
                    notifyPartners: {
                        shouldNotifyCarrier: false,
                        shouldNotifyConsignee: false,
                        shouldNotifyConsignor: false,
                        shouldNotifyForwarder: false,
                        shouldNotifyOther: false
                    },
                    selectedGroups: []
                },
                {
                    id: null,
                    code: "SEA_ETA_DELAY",
                    name: this.$t("alert_rules.pod_eta_delay"),
                    text1: this.$t("general.when") + " " + this.$t("alert_rules.pod_eta_delay"),
                    text2: this.$t("alert_rules.hours_then"),
                    enabled: false,
                    value: null,
                    notifyPartners: {
                        shouldNotifyCarrier: false,
                        shouldNotifyConsignee: false,
                        shouldNotifyConsignor: false,
                        shouldNotifyForwarder: false,
                        shouldNotifyOther: false
                    },
                    selectedGroups: []
                },
                {
                    id: null,
                    code: "SEA_TRANS_WAIT",
                    name: this.$t("alert_rules.ts_waitings"),
                    text1: this.$t("alert_rules.ts_waitings_longer"),
                    text2: this.$t("alert_rules.hours_then"),
                    enabled: false,
                    value: null,
                    notifyPartners: {
                        shouldNotifyCarrier: false,
                        shouldNotifyConsignee: false,
                        shouldNotifyConsignor: false,
                        shouldNotifyForwarder: false,
                        shouldNotifyOther: false
                    },
                    selectedGroups: []
                },
                {
                    id: null,
                    code: "SEA_DEST_WAIT",
                    name: this.$t("alert_rules.pod_waitings"),
                    text1: this.$t("alert_rules.pod_waitings_longer"),
                    text2: this.$t("alert_rules.hours_then"),
                    enabled: false,
                    value: null,
                    notifyPartners: {
                        shouldNotifyCarrier: false,
                        shouldNotifyConsignee: false,
                        shouldNotifyConsignor: false,
                        shouldNotifyForwarder: false,
                        shouldNotifyOther: false
                    },
                    selectedGroups: []
                }
            ];
            return _seaAlerts;
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        if (this.$route.query.type) {
            switch (this.$route.query.type) {
                case "SEA":
                    this.panels.push(0);
                    break;
                case "AIR":
                    this.panels.push(1);
                    break;
                case "RAIL":
                    this.panels.push(2);
                    break;
                case "ROAD":
                    this.panels.push(3);
                    break;
                default:
                    this.panels.push(0);
            }
        }
    },
    methods: {
        editAlert() {
            //this.ind = i
            this.editMode = true;
        },
        closeAndResetInput() {
            this.editMode = false;
            this.ind = 0;
            let _painel = [];
            if (this.tenantStandardAlertRules.length > 0) {
                this.tenantStandardAlertRules.forEach(e => {
                    let _rule = e.node;
                    let _id = _rule.id;
                    let _code = _rule.standardAlertCode;
                    //let _name = _rule.name;
                    let _text1 = _rule.tenantAlertRuleTriggerSet.edges[0]?.node.triggerJson.text1;
                    let _text2 = _rule.tenantAlertRuleTriggerSet.edges[0]?.node.triggerJson.text2;
                    let _value_parse = _rule.tenantAlertRuleTriggerSet.edges[0]?.node?.triggerJson;
                    let _value = _value_parse ? parseInt(JSON.parse(_value_parse).RemainMinutes / 60) : null;
                    let _notifyPartners = _rule.shouldNotifyPartner;
                    let _enabled = _rule.isEnabled;
                    let _selectedGroups = _rule.tenantAlertRuleActionGroupSet.edges.map(
                        e => e.node.tenantActionGroup.id
                    );

                    this.standardAlertPainel.forEach(i => {
                        let _ruleIndex = i.standardAlertRules.findIndex(r => r.code === _code);
                        if (_ruleIndex > -1) {
                            i.standardAlertRules[_ruleIndex].id = _id;
                            i.standardAlertRules[_ruleIndex].value = _value
                                ? _value
                                : i.standardAlertRules[_ruleIndex].value;
                            i.standardAlertRules[_ruleIndex].notifyPartners = _notifyPartners
                                ? _notifyPartners
                                : i.standardAlertRules[_ruleIndex].notifyPartners;
                            i.standardAlertRules[_ruleIndex].text1 = _text1
                                ? _text1
                                : i.standardAlertRules[_ruleIndex].text1;
                            i.standardAlertRules[_ruleIndex].text2 = _text2
                                ? _text2
                                : i.standardAlertRules[_ruleIndex].text2;
                            i.standardAlertRules[_ruleIndex].enabled = _enabled;
                            i.standardAlertRules[_ruleIndex].selectedGroups = _selectedGroups;
                        }
                    });
                });
            } else {
                _painel = [
                    {
                        name: "Sea Transport",
                        standardAlertRules: [...this.seaAlerts]
                    },
                    {
                        name: "Air Transport",
                        standardAlertRules: []
                    },
                    {
                        name: "Rail Transport",
                        standardAlertRules: []
                    },
                    {
                        name: "Road Transport",
                        standardAlertRules: []
                    }
                ];
                this.standardAlertPainel = _painel;
            }
        },
        saveStandardRules(standardAlertRules) {
            let _standardAlertRules =
                standardAlertRules.map(rule => {
                    return {
                        actionGroups: rule.selectedGroups,
                        id: rule.id,
                        isEnabled: rule.enabled,
                        name: rule.name,
                        shouldNotifyPartner: JSON.stringify(rule.notifyPartners),
                        standardAlertCode: rule.code,
                        triggerJson: JSON.stringify({
                            RemainMinutes: parseInt(rule.value * 60)
                        })
                    };
                }) || [];

            this.isLoading = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTenantStandardAlertRules($input: UpdateTenantStandardAlertRulesInput!) {
                            updateTenantStandardAlertRules(input: $input) {
                                tenantAlertRules {
                                    id
                                    isEnabled
                                    name
                                    shouldNotifyPartner
                                    standardAlertCode
                                    alertSeverity
                                    tenantAlertRuleActionGroupSet {
                                        edges {
                                            node {
                                                id
                                                tenantActionGroup {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                    tenantAlertRuleTriggerSet {
                                        edges {
                                            node {
                                                id
                                                triggerJson
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantStandardAlertRules: _standardAlertRules,
                            tenantId: this.me.tenant.id
                        }
                    }
                })
                .then()
                .catch(err => {
                    this.isLoading = false;
                    console.log(err);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.editMode = false;
                    this.ind = 0;
                });
        }
    }
};
</script>
<style scoped>
.centered-input >>> input {
    text-align: center;
}
</style>
