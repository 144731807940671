<template>
    <v-dialog max-width="600" v-model="openEditGroupDialog" persistent>
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">edit</v-icon>
                {{ $t("groups.edit_group") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-6">
                <v-form ref="editGroupForm">
                    <v-row dense class="py-0">
                        <v-text-field
                            v-model="groupName"
                            :label="$t('general.group_name') + '*'"
                            :rules="[rules.required]"
                            outlined
                            dense
                            class="mx-4 mt-3"
                        ></v-text-field>
                    </v-row>
                    <v-row dense class="py-0">
                        <v-col
                            ><v-checkbox
                                v-model="shouldEmailNotification"
                                :label="$t('general.email_notification')"
                                class="mx-4 mt-3"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col>
                            <v-checkbox
                                v-model="shouldInboxNotification"
                                :label="$t('general.inbox_notification')"
                                class="mx-4 mt-3"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTIONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isLoadingEditGroup"
                    @click="editGroup"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "EditGroup",
    props: {
        openEditGroupDialog: {
            type: Boolean,
            required: true
        },
        actionGroup: {
            type: Object
        }
    },
    data() {
        return {
            error: false,
            isLoadingEditGroup: false,

            groupId: null,
            groupName: null,
            shouldEmailNotification: false,
            shouldInboxNotification: false,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    methods: {
        initializeEditing() {
            this.$nextTick(() => {
                this.groupId = this.actionGroup.id;
                this.groupName = this.actionGroup.name;
                this.shouldEmailNotification = this.actionGroup.shouldEmailNotification;
                this.shouldInboxNotification = this.actionGroup.shouldInboxNotification;
            });
        },
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.error = false;
            this.$refs.editGroupForm.resetValidation();
            this.$emit("closeEditGroupDialog");
        },
        editGroup() {
            // validate form input :
            if (!this.$refs.editGroupForm.validate()) return;
            // Initialize Loading :
            this.isLoadingEditGroup = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTenantActionGroup(
                            $input: UpdateTenantActionGroupInput!
                        ) {
                            updateTenantActionGroup(input: $input) {
                                tenantActionGroup {
                                    id
                                    name
                                    shouldEmailNotification
                                    shouldInboxNotification
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.groupId,
                            name: this.groupName,
                            shouldEmailNotification: this
                                .shouldEmailNotification,
                            shouldInboxNotification: this
                                .shouldInboxNotification
                        }
                    }
                })
                .then(response => {
                    // Emit event to tabel data and load new groups //
                    this.$emit("refreshGroupsTable");
                    this.$emit(
                        "updatedGroup",
                        response.data.updateTenantActionGroup
                    );
                    const payload = {
                        color: "blue",
                        message: this.$t("alert_rules.group_saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    if (error.message == "INVALID_REFERENCE_NUMBER")
                        this.error = true;
                    this.isLoadingEditGroup = false;
                })
                .finally(() => {
                    this.isLoadingEditGroup = false;
                    this.closeAndResetInput();
                });
        }
    }
};
</script>
