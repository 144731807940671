<template>
    <div v-if="!canAccess">
        <NoPermission></NoPermission>
    </div>
    <div v-else class="mb-15">
        <v-row dense>
            <v-col cols="12">
                <v-card flat class="pt-6 px-6">
                    <DashboardKpis :tenantId="tenant.id"></DashboardKpis>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card flat class="px-6">
                    <TripOverview :tenantId="tenant.id"></TripOverview>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import helper from "@/utils/helper";
import { TenantPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import DashboardKpis from "@/components/tenants/statistics/DashboardKpis.vue";
import TripOverview from "@/components/tenants/statistics/TripOverview.vue";
import Config from "@/utils/config.json";

export default {
    components: {
        NoPermission,
        DashboardKpis,
        TripOverview
    },
    props: {
        tenant: {
            type: Object,
            default: null
        }
    },
    data() {
        return {};
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TenantPermissions = TenantPermissions;
    },
    computed: {
        me() {
            return helper.me();
        },
        canAccess() {
            if (this.me?.isSuperuser) return true;

            if (
                !this.hasPermission(TenantPermissions.VIEW_TENANT_DASHBOARD) ||
                !this.me?.isStaff
            )
                return false;

            return true;
        }
    },
    mounted(){
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'tenant_statistics_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("tenant_statistics_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("tenant_statistics_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        }
    }
};
</script>
