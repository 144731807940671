<template>
    <v-card fill-height flat width="100%" v-if="partner" color="background">
        <v-toolbar flat color="background" v-if="!isDialog">
            <!----- TOP LEFT ICONS ---->
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn
                v-if="shouldShowFullScreenButton"
                icon
                @click="changeFullScreen"
            >
                <v-icon>{{
                    isFullScreen ? "fullscreen_exit" : "crop_free"
                }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <!---- TOP RIGHT ICONS ---->
            <v-btn icon>
                <v-icon @click="editPartnerDialogHandler"> edit </v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon @click="openDeletePartnerDialog = true">
                    delete
                </v-icon>
            </v-btn>

            <!---- EDIT PARTNER DIALOG ---->
            <EditPartnerDialog
                ref="editPartnerDialog"
                :openEditPartnerDialog="openEditPartnerDialog"
                v-on:updatedPartner="updatePartnerValues"
                v-on:closeEditPartnerDialog="openEditPartnerDialog = false"
            ></EditPartnerDialog>

            <!---- DELETE PARTNER DIALOG ---->
            <DeletePartnerDialog
                :openDeleteDialog="openDeletePartnerDialog"
                :partnerID="partner.id"
                v-on:refreshDataTable="closeDrawerAndRefresh"
                v-on:closeDeletePartnerDialog="openDeletePartnerDialog = false"
            >
                <template v-slot:title>
                    {{ $t("partners.delete_partner") }}
                </template>
                <template #content>
                    {{ $t("partners.sure_partner") }}
                </template>
            </DeletePartnerDialog>

            <!---- FILTER DIALOG ---->
            <FilterMenu
                :title="$t('dashboard.customize')"
                :description="$t('dashboard.display_tabs')"
                :choices="defaultFilterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
                class="mr-n2"
            >
            </FilterMenu>
        </v-toolbar>

        <v-card-title class="pt-0 px-5">
            <!---- PARTNER GENERAL INFO DISPLAY (NAME, TYPE, REFERENCE...) ---->
            <v-row
                align="stretch"
                :class="
                    isDialog
                        ? 'd-flex align-center pt-2 mx-2 mt-5'
                        : 'd-flex align-center pt-2 mx-2'
                "
            >
                <!---- NAME ---->
                <v-col cols="2" sm="3" class="py-0 px-0 mb-1">
                    <span class="text-caption">{{ $t("headers.name") }}</span
                    ><br />
                    <span class="font-weight-medium">
                        {{
                            updatedPartner ? updatedPartner.name : partner.name
                        }}
                    </span>
                </v-col>

                <!---- REFERENCE ---->
                <v-col
                    cols="2"
                    sm="3"
                    class="py-0 px-0 mb-1"
                    v-if="
                        updatedPartner
                            ? updatedPartner.referenceNumber != null
                                ? true
                                : false
                            : partner.referenceNumber != null
                            ? true
                            : false
                    "
                >
                    <span class="text-caption">{{
                        $t("partners.reference")
                    }}</span>
                    <div class="font-weight-medium">
                        {{
                            updatedPartner
                                ? updatedPartner.referenceNumber
                                : partner.referenceNumber
                        }}
                    </div>
                </v-col>

                <!---- Carrier Type ---->
                <v-col
                    cols="2"
                    sm="3"
                    class="py-0 px-0 mb-1"
                    v-if="
                        updatedPartner
                            ? updatedPartner.carrierType != null
                                ? true
                                : false
                            : partner.carrierType != null
                            ? true
                            : false
                    "
                >
                    <span class="text-caption">{{
                        $t("headers.carrier_type")
                    }}</span>
                    <div class="font-weight-medium">
                        {{
                            (updatedPartner
                                ? updatedPartner.carrierType
                                : partner.carrierType
                            )
                                .charAt(0)
                                .toUpperCase() +
                                (updatedPartner
                                    ? updatedPartner.carrierType
                                    : partner.carrierType
                                ).slice(1)
                        }}
                    </div>
                </v-col>

                <!---- Standard Carrier Name ---->
                <v-col
                    cols="2"
                    sm="3"
                    class="py-0 px-0 mb-1"
                    v-if="
                        updatedPartner
                            ? updatedPartner.carrier != null
                                ? true
                                : false
                            : partner.carrier != null
                            ? true
                            : false
                    "
                >
                    <span class="text-caption">{{
                        $t("partners.carrier_name")
                    }}</span>
                    <div class="font-weight-medium">
                        {{
                            updatedPartner
                                ? updatedPartner.carrier.name
                                : partner.carrier.name
                        }}
                    </div>
                </v-col>

                <v-spacer></v-spacer>

                <!---- SELECT TAB OR LIST VIEW ---->
                <v-col
                    cols="2"
                    sm="3"
                    class="py-0 px-0 mb-1 justify-end d-flex"
                >
                    <v-select
                        v-model="viewType"
                        :items="viewChoices"
                        dense
                        item-text="name"
                        item-value="code"
                        return-value
                        outlined
                        hide-details
                        class="fit"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-title>
        <v-row
            class="ma-0 px-4"
            v-if="
                updatedPartner
                    ? updatedPartner.description != null
                        ? true
                        : false
                    : partner.description != null
                    ? true
                    : false
            "
        >
            <v-col cols="12">
                <span class="subtitle-1">
                    {{
                        updatedPartner
                            ? updatedPartner.description
                            : partner.description
                    }}
                </span></v-col
            >
        </v-row>
        <!---- tab viewType ---->
        <PartnerDetailTabView
            v-if="viewType === 'tab'"
            :partner="partner"
            :selectedFilters="selectedFilters"
        />

        <!---- list viewType ---->
        <PartnerDetailListView
            v-if="viewType === 'list'"
            class="mx-5 pb-10"
            :partner="partner"
            :selectedFilters="selectedFilters"
        />
    </v-card>
</template>

<script>
import FilterMenu from "@/components/base/FilterMenu.vue";
import EditPartnerDialog from "@/components/partners/update/EditPartnerDialog.vue";
import DeletePartnerDialog from "@/components/partners/delete/DeletePartnerDialog.vue";
import PartnerDetailTabView from "@/components/partners/PartnerDetailTabView.vue";
import PartnerDetailListView from "@/components/partners/PartnerDetailListView.vue";
import helper from "../../utils/helper";
import Config from "@/utils/config.json";

export default {
    name: "PartnerDetail",
    props: {
        partner: {
            type: Object,
            required: true
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        isDialog: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        FilterMenu,
        PartnerDetailTabView,
        PartnerDetailListView,
        DeletePartnerDialog,
        EditPartnerDialog
    },
    data() {
        return {
            /**********************/
            /* --- QUERY DATA --- */
            /**********************/
            updatedPartner: null,
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /***********************************/
            openDeletePartnerDialog: false,
            openEditPartnerDialog: false,
            /*******************/
            /* --- FILTERS --- */
            /*******************/
            viewType: "tab",
            viewChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "users",
                    name: "Users",
                    tag: "home.users",
                    enabled: true,
                    order: 1
                },
                {
                    // for filter menu
                    code: "locations",
                    name: "Locations",
                    tag: "home.locations",
                    enabled: true,
                    order: 2
                },
                {
                    // for filter menu
                    code: "permissions",
                    name: "Permissions",
                    tag: "headers.permissions",
                    enabled: true,
                    order: 3
                },
                {
                    // for filter menu
                    code: "trips",
                    name: "Trips",
                    tag: "headers.trips",
                    enabled: true,
                    order: 3
                }
            ],
            filterChoices: []
        };
    },
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    partnerDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    partnerDetailFilterChoices: value
                });
            },
            { deep: true }
        );
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'partner_details_svw',
        });
        this.setUserProperties();
    },
    watch: {},
    created() {
        this.viewType = helper.getViewType("partnerDetailViewType");
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "partnerDetailFilterChoices"
        );
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];
            if (this.isDialog) {
                _filters = this.defaultFilterChoices.filter(
                    filter => filter.code !== "trips"
                );
            } else
                _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        }
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("partner_details_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("partner_details_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editPartnerDialogHandler() {
            this.openEditPartnerDialog = true;
            this.$refs.editPartnerDialog.assignFormValues(this.partner);
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updatePartnerValues(val) {
            // Close edit partner dialog if open
            this.$emit("refreshDataTable");
            this.openEditPartnerDialog = false;
            this.updatedPartner = val.data.updateTenantPartner.partner;
        }
    }
};
</script>
<style scoped>
.v-select.fit {
  max-width: min-content;
  padding: 10px;
}
.v-select.fit  .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
</style>
