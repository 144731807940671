<template>
    <v-container fluid>
        <v-row align="center" class="d-flex justify-center align-center">
            <v-col md="10" cols="12">
                <v-toolbar flat color="transparent">
                    <v-btn icon @click="cancel">
                        <v-icon>arrow_back_ios_new</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-col>
            <v-col cols="10">
                <v-card class="d-flex align-end" height="40" color="transparent" flat>
                    <div class="textTitle">
                        {{ viewTitle }}
                    </div>
                </v-card>
            </v-col>
            <v-col cols="10">
                <v-card outlined class="rounded-lg">
                    <v-card-text>
                        <v-form ref="addUserForm">
                            <v-row dense>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    xl="6"
                                    :class="!smallScreen ? 'paddingRight' : ''"
                                >
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("headers.first_name") + "*" }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="user.firstName"
                                        outlined
                                        :rules="[rules.required]"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    xl="6"
                                    :class="!smallScreen ? 'paddingLeft' : ''"
                                >
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("headers.last_name") + "*" }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        dense
                                        v-model="user.lastName"
                                        outlined
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    xl="6"
                                    :class="!smallScreen ? 'paddingRight' : ''"
                                >
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ "Email" + "*" }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        dense
                                        v-model="user.email"
                                        :rules="[rules.required, rules.invalidEmail]"
                                        outlined
                                        :class="!smallScreen ? 'max.width' : ''"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    xl="6"
                                    :class="!smallScreen ? 'paddingLeft' : ''"
                                >
                                    <v-row dense>
                                        <v-col cols="5">
                                            <v-checkbox
                                                v-model="user.isTenantAdmin"
                                                :label="$t('general.administrator')"
                                                hide-details
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-checkbox
                                                v-model="user.require2fa"
                                                :label="$t('headers.2fa')"
                                                hide-details
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="10">
                <v-card outlined class="rounded-lg">
                    <v-card-actions>
                        <v-row>
                            <v-col cols="auto" class="d-flex align-start">
                                <v-btn text @click="cancel">{{ $t("general.discard") }}</v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-btn :loading="isLoadingAddUser" @click="createUser" color="primary">{{
                                    $t("general.save")
                                }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "create-user-dialog",
    props: {
        openAddUserDialog: {
            type: Boolean,
            default: false
        },
        tenant: {
            type: Object,
            default: null
        }
    },
    components: {},
    data() {
        return {
            /* User data */
            user: {
                firstName: null,
                lastName: null,
                email: null,
                isTenantAdmin: false,
                require2fa: false
            },
            /* Loading */
            tenantId: null,
            editMode: false,
            isLoadingAddUser: false,
            /* Rules */
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v => /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v => (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    computed: {
        me() {
            return helper.me();
        },
        smallScreen() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        modeType() {
            let _mode = "create";
            if (this.$route.query?.type) {
                _mode = this.$route.query.type;
            }
            return _mode;
        },
        viewTitle() {
            let title = "";
            if (this.editMode) {
                title = this.$t("general.edit_user");
            } else {
                title = this.$t("general.create_user");
            }
            return title;
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.modeType === "edit") {
                this.editMode = true;
                this.editUser();
            }
        });
    },
    methods: {
        editUser() {
            let _userState = this.$store.getters["staff/getCreatedStaff"];
            let _user = _userState.node.user;

            if (_userState) {
                this.tenantId = _userState.node.id;
                this.user.firstName = _user.firstName;
                this.user.lastName = _user.lastName;
                this.user.email = _user.email;
                this.user.isTenantAdmin = _user.isTenantAdmin;
                this.user.require2fa = _user.require2fa;
            }
        },
        cancel() {
            let _user = this.$store.getters["staff/getCreatedStaff"];
            let tab = 'staff';
            this.$router.push({
                path: "/previewusers",
                query: { tab }
            });

            if (_user) {
                this.$store.dispatch("staff/updateCreatedStaff", {
                    type: "cancel",
                    staff: _user
                });
            } else {
                this.$store.dispatch("staff/updateCreatedStaff", {
                    type: "cancel",
                    staff: null
                });
            }
        },
        createUser() {
            // validate form input :
            if (!this.$refs.addUserForm.validate()) return;
            // Initialize Loading :
            this.isLoadingAddUser = true;
            // Initialize Mutation :

            if (this.editMode) {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation Mutation($input: UpdateTenantUserInput!) {
                                updateTenantUser(input: $input) {
                                    tenantUser {
                                        id
                                        isAdmin
                                        user {
                                            id
                                            firstName
                                            lastName
                                            fullName
                                            email
                                            isTenantAdmin
                                            lastLogin
                                            require2fa
                                            allTenantUserGroups {
                                                totalCount
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                id: this.tenantId,
                                ...this.user
                            }
                        }
                    })
                    .then(data => {
                        // Emit event to signal user is added and reload table showing users //
                        let tab = 'staff';
                        this.$router.push({
                            path: "/previewusers",
                            query: { tab }
                        });
                        this.$store.dispatch("staff/updateCreatedStaff", {
                            type: "update",
                            staff: data.data.updateTenantUser.tenantUser
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.errorMessage = true;
                    });
            } else {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation Mutation($input: CreateTenantUserInput!) {
                                createTenantUser(input: $input) {
                                    tenantUser {
                                        id
                                        isAdmin
                                        user {
                                            id
                                            firstName
                                            lastName
                                            fullName
                                            email
                                            isTenantAdmin
                                            lastLogin
                                            require2fa
                                            allTenantUserGroups {
                                                totalCount
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...this.user,
                                tenantId: this.tenant ? this.tenant.id : this.me.tenant.id
                            }
                        }
                    })
                    .then(data => {
                        // Emit event to signal user is added and reload table showing users //
                        let tab = 'staff';
                        this.$router.push({
                            path: "/previewusers",
                            query: { tab }
                        });
                        this.$store.dispatch("staff/updateCreatedStaff", {
                            type: "update",
                            staff: data.data.createTenantUser.tenantUser
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        const payload = {
                            color: "error",
                            message: error.message
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                        this.errorMessage = true;
                    });
            }
        }
    }
};
</script>
<style scoped>
.paddingRight {
    padding-right: 15px;
}
.paddingLeft {
    padding-left: 15px;
}
</style>
