<template>
    <v-form v-model="valid" ref="partnerForm">
        <v-row>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="contentDisplay">
                <v-row dense>
                    <v-col cols="auto" class="textBody labelContent">
                        <span>
                            {{ $t("headers.name") + "*" }}
                        </span>
                    </v-col>
                </v-row>
                <v-text-field
                    v-model="partnerObject.partnerName"
                    :rules="[rules.isRequired]"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="contentDisplay">
                <v-row dense>
                    <v-col cols="auto" class="textBody labelContent">
                        <span>
                            {{ $t("headers.reference_number") }}
                        </span>
                    </v-col>
                </v-row>
                <v-text-field
                    ref="partnerReferenceNumber"
                    v-model="partnerObject.referenceNumber"
                    outlined
                    dense
                    :rules="[() => uniqueReferenceNumber()]"
                ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="contentDisplay" v-if="partnerType === 'carrier'">
                <v-row dense>
                    <v-col cols="auto" class="textBody labelContent">
                        <span>
                            {{ $t("headers.carrier_type") }}
                        </span>
                    </v-col>
                </v-row>
                <v-select v-model="partnerObject.carrierType" :items="transportModeChoices" outlined dense></v-select>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="contentDisplay" v-if="partnerType === 'carrier'">
                <v-row dense>
                    <v-col cols="auto" class="textBody labelContent">
                        <span>
                            {{ $t("partners.standard_carriers") }}
                        </span>
                    </v-col>
                </v-row>
                <v-select
                    :disabled="!isDisabled"
                    v-model="partnerObject.carrier"
                    :items="allCarriers ? allCarriers.edges : []"
                    :loading="$apollo.queries.allCarriers.loading"
                    :no-data-text="$t('loading.no_available')"
                    item-text="node.name"
                    item-value="node.id"
                    hide-details
                    dense
                    outlined
                ></v-select>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="contentDisplay">
                <v-row dense>
                    <v-col cols="auto" class="textBody labelContent">
                        <span>
                            {{ $t("general.description") }}
                        </span>
                    </v-col>
                </v-row>
                <v-text-field
                    v-model="partnerObject.description"
                    :rules="
                        partnerObject.description !== null && partnerObject.description.length > 0
                            ? [rules.maxLength(200)]
                            : []
                    "
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="contentDisplay">
                <v-row dense>
                    <v-col cols="auto" class="textBody labelContent">
                        <span>
                            {{ $t("headers.enable_notifications") }}
                        </span>
                    </v-col>
                </v-row>
                <v-checkbox v-model="partnerObject.notification" hide-details></v-checkbox>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "CreatePartnerForm",
    props: {
        isValid: {
            type: Boolean,
            default: false
        },
        partner: {
            type: Object,
            default: () => {}
        },
        partnerType: {
            type: String,
            default: null
        }
    },
    apollo: {
        allCarriers: {
            query: gql`
                query allCarriers($first: Int, $last: Int, $before: String, $after: String, $carrierType: String) {
                    allCarriers(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: "name"
                        carrierType: $carrierType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                code
                                name
                                carrierType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    last: null,
                    before: null,
                    after: null,
                    carrierType: this.partnerObject.carrierType
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.allCarriers,
            skip: false
        }
    },
    data() {
        return {
            // Your data properties here
            transportMode: null,
            invalidReferenceNumber: false,
            rules: {
                isRequired: v => !!v || this.$t("general.required"),
                maxLength: maxLength => v =>
                    (v && v.length <= maxLength) || this.$t("rules.too_long") + " " + `${maxLength}`,
                unique: v => !!v || this.$t("rules.unique")
                // unique: v=>{
                //     // if(!v?.isUnique){
                //     //     return this.$t("rules.unique");
                //     // }
                //     // this.$refs[`serialNumberForm-${item.serialNumber}`].validate();
                //     return true
                // }
            },
            isDisabled: false
        };
    },
    computed: {
        // Your computed properties here
        valid: {
            get() {
                return this.isValid;
            },
            set(value) {
                this.$emit("update:isValid", value);
            }
        },
        partnerObject: {
            get() {
                return this.partner;
            },
            set(value) {
                this.$emit("update:partner", value);
            }
        },
        isCarrier() {
            return this.partnerType === "carrier";
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        }
    },
    watch: {
        // Your watch properties here
        "object.partnerType": function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.partnerObject.carrier = null;
            }
        },
        partnerObject: {
            handler(newVal, oldVal) {
                if (newVal) {
                    if(newVal.carrierType=== "sea" || newVal.carrierType === "air"){
                        this.isDisabled = true;
                    }else{
                        this.isDisabled = false;
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {},
    methods: {
        // Your component methods here
        uniqueReferenceNumber() {
            return this.invalidReferenceNumber ? this.$t("rules.unique_reference_number") : true;
        }
    }
};
</script>

<style scoped>
/* Your component styles here */
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay2 {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay3 {
    padding-left: 70px;
    padding-right: 20px;
}
</style>
