<template>
    <v-card elevation="5" outlined>
        <v-card-text class="pb-3 pr-3">
            <div class="d-flex justify-end px-5">
                <!-- filter menu -->
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display')"
                    :choices="headerChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                    class="mr-n4"
                >
                </FilterMenu>
            </div>
            <v-data-table
                :headers="dynamicHeaders"
                :items="alertRules.edges"
                item-key="node.id"
                :loading-text="$t('loading.loading1')"
                :loading="$apollo.queries.alertRules.loading"
                hide-default-footer
                disable-pagination
                loader-height="2"
                :mobile-breakpoint="screenSize === 'xs' || screenSize === 'sm' ? 800 : 0"
                :no-data-text="$t('loading.no_data')"
                @click:row="item => this.$emit('click:row', item)"
                class="row-pointer"
            >
                <!-- Enabled -->
                <template v-slot:item.node.isEnabled="{ item }">
                    <v-icon v-if="item.node.isEnabled" color="success">check_circle</v-icon>
                    <v-icon color="grey lighten-2" v-else>check_circle</v-icon>
                </template>

                <!-- Notify Partner -->
                <template v-slot:item.node.shouldNotifyPartner="{ item }">
                    <v-chip small class="mr-2" v-for="partner in getPartnersToBeNotified(item)" :key="partner">
                        {{ partner }}
                    </v-chip>
                </template>

                <!-- Notify every record -->
                <template v-slot:item.node.shouldNotifyForEveryRecord="{ item }">
                    <v-icon v-if="item.node.shouldNotifyForEveryRecord" color="success">check_circle</v-icon>
                    <v-icon color="grey lighten-2" v-else>check_circle</v-icon>
                </template>

                <!-- TotalCount of Triggers -->
                <template v-slot:item.node.triggers="{ item }">
                    <span>{{ item.node.tenantAlertRuleTriggerSet.totalCount }}</span>
                </template>

                <!-- TotalCount of Action Groups -->
                <template v-slot:item.node.actionGroups="{ item }">
                    <span>{{ item.node.tenantAlertRuleActionGroupSet.totalCount }}</span>
                </template>
                <template v-slot:item.node.alertSeverity="{ item }">
                    <span>{{ alertChoicesTitle(item.node.alertSeverity) }}</span>
                </template>
                <template v-slot:item.node.tag="{ item }">
                    <v-row no-gutters class="d-flex justify-center align-center">
                        <v-col cols="2" v-for="tag in alertTags(item.node.tag)" :key="tag.icon">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="" class="mr-2">{{ tag.icon }}</v-icon>
                                </template>
                                <span>{{ tag.text }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card-text>

        <!-- infinit loading trigger -->
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.alertRules.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                {{ $t("loading.load_more") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";

export default {
    props: {
        alertRulesFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    components: { FilterMenu },

    apollo: {
        alertRules: {
            query: gql`
                query tenantAlertRules(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $search: String
                    $orderBy: String
                    $isStandardAlert: Boolean
                ) {
                    tenantAlertRules(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        search: $search
                        orderBy: $orderBy
                        isStandardAlert: $isStandardAlert
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                description
                                isEnabled
                                name
                                shouldNotifyPartner
                                alertSeverity
                                shouldNotifyForEveryRecord
                                applyToAllNewTrips
                                applyToCarriers
                                applyToConsignors
                                applyToForwarders
                                applyToConsignees
                                applyToOthers
                                tag
                                tenant {
                                    id
                                }
                                tenantAlertRuleActionGroupSet {
                                    totalCount
                                    edges {
                                        node {
                                            id
                                            tenantActionGroup {
                                                id
                                                name
                                            }
                                        }
                                    }
                                }
                                tenantAlertRuleTriggerSet {
                                    totalCount
                                    edges {
                                        node {
                                            id
                                            triggerJson
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return this.alertRulesFilter;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantAlertRules,
            debounce: 800,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {              
                return !this.isCurrentRoute;
            }
        }
    },

    data() {
        return {
            shouldLoadMore: false,
            isInitialLoad: true,
            alertRules: { edges: [] },

            // defaultHeaderChoices: [
            //     {
            //         // for filter menu
            //         code: "node.name",
            //         name: "Name",
            //         enabled: true,
            //         // for table header
            //         text: "Name",
            //         align: "left",
            //         value: "node.name"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.isEnabled",
            //         name: "Enabled",
            //         enabled: true,
            //         // for table header
            //         text: "Enabled",
            //         align: "center",
            //         value: "node.isEnabled"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.triggers",
            //         name: "Triggers",
            //         enabled: true,
            //         // for table header
            //         text: "Triggers",
            //         align: "right",
            //         value: "node.triggers"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.actionGroups",
            //         name: "Action Groups",
            //         enabled: true,
            //         // for table header
            //         text: "Action Groups",
            //         align: "right",
            //         value: "node.actionGroups"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.shouldNotifyPartner",
            //         name: "Notify Partners",
            //         enabled: true,
            //         // for table header
            //         text: "Notify Partners",
            //         align: "center",
            //         value: "node.shouldNotifyPartner"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.shouldNotifyForEveryRecord",
            //         name: "Every Record Notification",
            //         enabled: true,
            //         // for table header
            //         text: "Every Record Notification",
            //         align: "center",
            //         value: "node.shouldNotifyForEveryRecord"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.alertSeverity",
            //         name: "Alert Severity",
            //         enabled: true,
            //         // for table header
            //         text: "Alert Severity",
            //         align: "center",
            //         value: "node.alertSeverity"
            //     }
            // ],

            headerChoices: null
        };
    },
    created() {
        this.headerChoices = helper.getTableHeaders("AlertRulesTableHeaders", this.defaultHeaderChoices);
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        // infinite
        hasMoreData() {
            return this.alertRules?.pageInfo?.hasNextPage;
        },
        isCurrentRoute() {
            let _routeName = this.$route.name;
            if (_routeName === "customrules") {
                return true;
            } else {
                return false;
            }
        },
        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            // put header filter in the last column
            _headers.push({
                text: "",
                align: "right",
                value: "headerFilter",
                sortable: false
            });

            return _headers;
        },
        defaultHeaderChoices: function() {
            let _defaultFilterChoices = [
                {
                    // for filter menu
                    code: "node.name",
                    name: "Name",
                    tag: "headers.name",
                    enabled: true,
                    // for table header
                    text: "Name",
                    align: "left",
                    value: "node.name"
                },
                {
                    // for filter menu
                    code: "node.isEnabled",
                    name: "Enabled",
                    tag: "headers.enabled",
                    enabled: true,
                    // for table header
                    text: "Enabled",
                    align: "center",
                    value: "node.isEnabled"
                },
                {
                    // for filter menu
                    code: "node.triggers",
                    name: "Triggers",
                    tag: "headers.triggers",
                    enabled: true,
                    // for table header
                    text: "Triggers",
                    align: "right",
                    value: "node.triggers"
                },
                {
                    // for filter menu
                    code: "node.actionGroups",
                    name: "Action Groups",
                    tag: "home.action_groups",
                    enabled: true,
                    // for table header
                    text: "Action Groups",
                    align: "right",
                    value: "node.actionGroups"
                },
                {
                    // for filter menu
                    code: "node.shouldNotifyPartner",
                    name: "Notify Partners",
                    tag: "headers.notify_partners",
                    enabled: true,
                    // for table header
                    text: "Notify Partners",
                    align: "center",
                    value: "node.shouldNotifyPartner"
                },
                {
                    // for filter menu
                    code: "node.shouldNotifyForEveryRecord",
                    name: "Every Record Notification",
                    tag: "headers.every_record",
                    enabled: true,
                    // for table header
                    text: "Every Record Notification",
                    align: "center",
                    value: "node.shouldNotifyForEveryRecord"
                },
                {
                    // for filter menu
                    code: "node.alertSeverity",
                    name: "Alert Severity",
                    tag: "alert_rules.alert_severity",
                    enabled: true,
                    // for table header
                    text: "Alert Severity",
                    align: "center",
                    value: "node.alertSeverity"
                },
                {
                    // for filter menu
                    code: "node.tag",
                    name: "Tag",
                    tag: "alert_rules.tag",
                    enabled: true,
                    // for table header
                    text: "Tag",
                    align: "center",
                    value: "node.tag"
                }
            ];
            return _defaultFilterChoices;
        }
    },
    watch: {
        headerChoices: {
            handler(newChoices) {
                helper.updateMyPreferences({
                    AlertRulesTableHeaders: newChoices
                });
            },
            deep: true
        }
    },
    mounted() {
        this.loadMore();
    },
    methods: {
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.alertRules?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.alertRules.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.alertRules?.pageInfo?.hasNextPage) {
                this.$apollo.queries.alertRules.fetchMore({
                    variables: {
                        after: this.alertRules.pageInfo.endCursor
                    }
                });
            }
        },
        alertChoicesTitle(alert) {
            let choices = [
                { text: this.$t("general.informational"), value: "info" },
                { text: this.$t("general.warning"), value: "warning" },
                { text: this.$t("general.critical"), value: "critical" }
            ];

            if (alert !== null) {
                let choice = choices.find(choice => choice.value === alert.toLowerCase());
                return choice.text;
            } else {
                return "";
            }
        },
        alertTags(tag) {
            let choices = [];
            if (tag === null) {
                return "";
            }

            switch (tag) {
                case "temperature":
                    choices.push({
                        icon: "thermostat",
                        color: "#64B5F6",
                        text: "Temperature"
                    });
                    break;
                case "light":
                    choices.push({
                        icon: "lightbulb",
                        color: "#FFF59D",
                        text: "Light"
                    });
                    break;
                case "humidity":
                    choices.push({
                        icon: "water_drop",
                        color: "#4DB6AC",
                        text: "Humidity"
                    });
                    break;
                case "pressure":
                    choices.push({
                        icon: "compress",
                        color: "#E57373",
                        text: "Pressure"
                    });
                    break;
                case "temperature_humidity":
                    choices.push(
                        {
                            icon: "thermostat",
                            color: "#64B5F6",
                            text: "Temperature"
                        },
                        {
                            icon: "water_drop",
                            color: "#4DB6AC",
                            text: "Humidity"
                        }
                    );
                    break;
                case "temperature_humidity_light":
                    choices.push(
                        {
                            icon: "thermostat",
                            color: "#64B5F6",
                            text: "Temperature"
                        },
                        {
                            icon: "water_drop",
                            color: "#4DB6AC",
                            text: "Humidity"
                        },
                        {
                            icon: "lightbulb",
                            color: "#FFF59D",
                            text: "Light"
                        }
                    );
                    break;
            }
            return choices;
        },
        getPartnersToBeNotified(alertRule) {
            let _partners = [];
            if (alertRule.node.shouldNotifyPartner) {
                let _shouldNotifyPartner = JSON.parse(alertRule.node.shouldNotifyPartner);
                if (_shouldNotifyPartner.shouldNotifyCarrier) {
                    _partners.push("Carrier");
                }
                if (_shouldNotifyPartner.shouldNotifyConsignee) {
                    _partners.push("Consignee");
                }
                if (_shouldNotifyPartner.shouldNotifyConsignor) {
                    _partners.push("Consignor");
                }
                if (_shouldNotifyPartner.shouldNotifyForwarder) {
                    _partners.push("Forwarder");
                }
                if (_shouldNotifyPartner.shouldNotifyOther) {
                    _partners.push("Other");
                }
            }
            return _partners;
        }
    }
};
</script>

<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
