import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.openDeleteTrackerDialog),callback:function ($$v) {_vm.openDeleteTrackerDialog=$$v},expression:"openDeleteTrackerDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("warning")]),_c('div',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('alert_rules.delete_tracker'))+" ")])],1),_c(VCardText,{staticClass:"pt-2"},[_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('alert_rules.sure_tracker'))+" ")])])]),_c(VCardActions,{staticClass:"pb-5 pr-5"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":function($event){return _vm.$emit('closeDeleteTrackerDialog')}}},[_vm._v(_vm._s(_vm.$t('general.cancel')))]),_c(VBtn,{attrs:{"color":"primary","rounded":"","loading":_vm.isDeleting},on:{"click":_vm.deleteTracker}},[_vm._v(_vm._s(_vm.$t('general.delete')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }