<template>
    <v-dialog v-model="openDeleteDialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="title">
                <v-icon color="error" class="mr-2">warning</v-icon>
                <slot name="title">{{ $t("general.delete") }}</slot>
            </v-card-title>
            <v-card-text>
                <slot name="content">Conteudo {{ userIds }} </slot>
            </v-card-text>
            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    rounded
                    @click="$emit('closeDeleteUserGroupDialog')"
                    >{{ $t("general.cancel") }}</v-btn
                >
                <v-btn
                    color="error"
                    outlined
                    rounded
                    :loading="isDeleting"
                    @click="deleteGroupUser"
                    >{{ $t("general.delete") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "delete-user-group-dialog",
    props: {
        openDeleteDialog: {
            type: Boolean,
            required: true
        },
        group: {
            type: Object,
            required: true
        },
        userIds: {
            type: Array,
            required: true
        }
    },
    data: function() {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteGroupUser() {
            this.isDeleting = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation RemoveUserFromTenantUserGroup(
                            $input: RemoveUserFromTenantUserGroupInput!
                        ) {
                            removeUserFromTenantUserGroup(input: $input) {
                                tenantStaffGroup {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.group.node.id,
                            users: this.userIds
                        }
                    }
                })
                .then(() => {
                    this.$emit("refreshDataTable");
                    this.$emit("closeDeleteDialog");
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isDeleting = false;
                });
        }
    }
};
</script>
