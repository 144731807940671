<template>
    <v-container fluid class="px-0 py-0 mx-0" style="max-width: 130px;">
        <v-dialog v-model="dialog" max-width="900" persistent scrollable>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-if="editMode"
                    rounded
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="edit(order)"
                    :disabled="
                        booking.orderStatus !== 'draft' && (!me.isTenantUser || booking.orderStatus === 'confirmed')
                    "
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">edit</v-icon>
                        </template>
                        <span>Edit Transport Order</span>
                    </v-tooltip>
                </v-btn>
                <v-btn
                    v-else
                    rounded
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="create"
                    :disabled="disableAddTransportOrderBtn"
                >
                    Add transport order
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="primary mb-4">
                    <span class="white--text">{{ editMode ? "Edit" : "Add" }} Transport Order</span>
                </v-card-title>
                <v-card-text class="py-0">
                    <v-form ref="form" v-model="stepOneIsValid">
                        <v-row dense v-if="item != null">
                            <v-col cols="12" class="">
                                <div class="title primary--text">
                                    Transport Specifications
                                </div>
                            </v-col>
                            <v-col cols="6" class="py-0" v-if="me.isTenantUser">
                                <v-row dense>
                                    <v-col cols="auto" class="text">
                                        <span>
                                            Booking Reference
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    v-model="item.bookingReference"
                                    outlined
                                    dense
                                    placeholder="Booking Reference"
                                    clearable
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <v-row dense>
                                    <v-col cols="auto" class="text">
                                        <span>
                                            Exporter Reference Number
                                        </span>
                                    </v-col>
                                    <!-- <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col> -->
                                    <v-col cols="auto" class="red--text mt-n1">
                                        <v-tooltip right class="">
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-icon
                                                    small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :style="styleBorder"
                                                    color="primary"
                                                >
                                                    priority_high
                                                </v-icon>
                                            </template>
                                            <span>
                                                Please enter the exporter reference number
                                                <br />
                                            </span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    v-model="item.exporterReferenceNumber"
                                    outlined
                                    placeholder="Exporter Reference Number"
                                    dense
                                    :rules="[]"
                                    clearable
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <v-row dense>
                                    <v-col cols="auto" class="text">
                                        <span>
                                            Load Date And Time
                                        </span>
                                    </v-col>
                                    <v-col cols="auto" class="red--text">
                                        *
                                    </v-col>
                                </v-row>
                                <DateTimeComponent
                                    v-model="item.loadDate"
                                    :icon="'calendar_month'"
                                    :outlined="true"
                                    :dense="true"
                                    :defaultTime="'00:00'"
                                    :placeholder="'1900/01/01 00:00'"
                                >
                                </DateTimeComponent>
                            </v-col>
                            <v-col cols="6" class="py-0" v-if="me.isTenantUser">
                                <v-row dense>
                                    <v-col cols="auto" class="text">
                                        <span>
                                            Empty Load Point
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-autocomplete
                                    v-model="item.emptyLoadPointId"
                                    :items="containerDepots"
                                    :loading="$apollo.queries.containerDepots.loading"
                                    item-text="Description"
                                    item-value="Code"
                                    flat
                                    placeholder="Select Empty Load Point"
                                    hide-no-data
                                    outlined
                                    dense
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <!-- Transport -->
                            <v-col cols="12" class="pb-5">
                                <v-row align="stretch" dense>
                                    <v-col cols="6" class="py-0">
                                        <v-row dense>
                                            <v-col cols="12" v-if="me.isTenantUser">
                                                <v-row dense>
                                                    <v-col cols="auto" class="text">
                                                        <span>Transporter</span>
                                                    </v-col>
                                                    <v-col cols="auto" class="red--text mt-n1">
                                                        <v-tooltip right class="">
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <v-icon
                                                                    small
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :style="styleBorder"
                                                                    color="primary"
                                                                >
                                                                    priority_high
                                                                </v-icon>
                                                            </template>
                                                            <span>
                                                                Transporter is the carrier
                                                                <br />
                                                            </span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-autocomplete
                                                    v-model="item.transporterId"
                                                    :items="transporters"
                                                    :loading="$apollo.queries.transporters.loading"
                                                    item-text="Description"
                                                    item-value="Code"
                                                    flat
                                                    placeholder="Select Transporter"
                                                    hide-no-data
                                                    hide-details
                                                    outlined
                                                    dense
                                                    :rules="[]"
                                                    clearable
                                                    disabled
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row dense>
                                                    <v-col cols="auto" class="text">
                                                        <span>
                                                            Temperature Recorder Type
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="auto" class="red--text">
                                                        *
                                                    </v-col>
                                                </v-row>
                                                <v-autocomplete
                                                    v-model="item.tempRecorderTypeId"
                                                    :items="tempDevices"
                                                    :loading="$apollo.queries.tempDevices.loading"
                                                    item-text="Description"
                                                    item-value="Code"
                                                    flat
                                                    hide-no-data
                                                    hide-details
                                                    outlined
                                                    dense
                                                    placeholder="Select Temperature Recorder Type"
                                                    clearable
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row dense>
                                                    <v-col cols="auto" class="text">
                                                        <span>
                                                            Solas Method
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="auto" class="red--text">
                                                        *
                                                    </v-col>
                                                </v-row>
                                                <v-autocomplete
                                                    v-model="item.solasMethodId"
                                                    :items="solasVerificationMethods"
                                                    :loading="$apollo.queries.solasVerificationMethods.loading"
                                                    item-text="Description"
                                                    item-value="VerificationMethod"
                                                    flat
                                                    hide-no-data
                                                    hide-details
                                                    outlined
                                                    dense
                                                    placeholder="Select Solas Method"
                                                    clearable
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <v-row dense>
                                            <v-col cols="auto" class="text">
                                                <span>
                                                    Transport Instructions
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-textarea
                                            v-model="item.transportInstruction"
                                            placeholder="Enter Transport Instruction"
                                            outlined
                                            height="180px"
                                            dense
                                            no-resize
                                            clearable
                                            hide-details
                                            auto-grow
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="">
                                <div class="title primary--text">
                                    Loading & Weight Details
                                </div>
                            </v-col>
                            <!-- Number of pallets per shipment -->
                            <v-col cols="6" class="py-0">
                                <v-row dense>
                                    <v-col cols="auto" class="text">
                                        <span>Number Of Pallets Per Container</span>
                                    </v-col>
                                    <v-col cols="auto" class="red--text">
                                        *
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    v-model.number="item.numberOfPalletsPerShipment"
                                    outlined
                                    :rules="[rules.isMoreThanZero]"
                                    placeholder="No. Pallets Per Shipment"
                                    dense
                                    clearable
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <v-row dense>
                                    <v-col cols="auto" class="text">
                                        <span>Load Point 1</span>
                                    </v-col>
                                    <v-col cols="auto" class="red--text">
                                        *
                                    </v-col>
                                    <v-col cols="auto" class="red--text mt-n1">
                                        <v-tooltip right class="">
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-icon
                                                    small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :style="styleBorder"
                                                    color="primary"
                                                >
                                                    priority_high
                                                </v-icon>
                                            </template>
                                            <span>
                                                Please enter the contract number
                                                <br />
                                            </span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <v-autocomplete
                                    v-model="item.loadPoint1Id"
                                    :items="depots"
                                    :loading="$apollo.queries.depots.loading"
                                    item-text="Description"
                                    item-value="Code"
                                    flat
                                    placeholder="Select Load Point"
                                    hide-no-data
                                    outlined
                                    dense
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-row dense>
                                    <v-col cols="auto" class="text">
                                        <span>
                                            Dual Load
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-switch v-model="item.dualLoad" label="Dual Load"></v-switch>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-row dense>
                                    <v-col cols="auto" class="text">
                                        <span>
                                            Genset Required
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-switch v-model="item.gensetIsRequired" class="text-no-wrap"></v-switch>
                            </v-col>
                            <v-col cols="6" class="py-0" v-if="item.dualLoad">
                                <v-row dense>
                                    <v-col cols="auto" class="text">
                                        <span>
                                            Load Point 2
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-autocomplete
                                    v-model="item.loadPoint2Id"
                                    :items="depots"
                                    :loading="$apollo.queries.depots.loading"
                                    item-text="Description"
                                    item-value="Code"
                                    placeholder="Select Load Point"
                                    flat
                                    hide-no-data
                                    outlined
                                    dense
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="py-4 px-3">
                    <v-spacer></v-spacer>
                    <v-btn text rounded @click="cancel()" class="ml-2">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        rounded
                        @click="saveOrders()"
                        :disabled="!stepOneIsValid"
                        :loading="loadingBtn"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 
                    <v-stepper v-model="step" elevation="0">
                        <v-stepper-header class="elevation-0 px-1">
                            <v-stepper-step :complete="step > 1" step="1" editable>
                                Transport Specifications
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 2" step="2" editable>
                                Special Requirements
                            </v-stepper-step>
                         
                                Step 3 is hide, for remove this condition discomented the v-stepper-step 3 and 
                                also the the logic in the step 2 continue button
                           
                            <v-divider
                                v-if="me.isTenantUser && editMode && booking.orderStatus !== 'draft'"
                            ></v-divider>
                            <v-stepper-step
                                :complete="step > 3"
                                step="3"
                                editable
                                v-if="me.isTenantUser && editMode && booking.orderStatus !== 'draft'"
                            >
                                Confirm Order
                            </v-stepper-step> 
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1" class="pt-1">
                                <v-card>
                                    <v-card-text>
                                       
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-spacer></v-spacer>
                                        <v-btn text rounded @click="cancel()" class="ml-2">
                                            Cancel
                                        </v-btn>
                                        <v-btn color="primary" rounded @click="step = 2" :disabled="!stepOneIsValid">
                                            Continue
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content step="2" class="pt-1">
                                <v-card>
                                    <v-card-text>
                                        <v-form v-model="stepTwoIsValid">
                                            <v-row v-if="item != null">
                                                <v-col cols="12" class="">
                                                    <div class="title primary--text">
                                                        Special Requirements
                                                    </div>
                                                </v-col>
                                                <v-col cols="6" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Solas Method
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="item.solasMethodId"
                                                        :items="solasVerificationMethods"
                                                        :loading="$apollo.queries.solasVerificationMethods.loading"
                                                        item-text="Description"
                                                        item-value="VerificationMethod"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        placeholder="Select Solas Method"
                                                        clearable
                                                    ></v-autocomplete>
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Temperature Recorder Type
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="item.tempRecorderTypeId"
                                                        :items="tempDevices"
                                                        :loading="$apollo.queries.tempDevices.loading"
                                                        item-text="Description"
                                                        item-value="Code"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        placeholder="Select Temperature Recorder Type"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="6" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Transport Instructions
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-textarea
                                                        v-model="item.transportInstruction"
                                                        placeholder="Enter Transport Instruction"
                                                        outlined
                                                        style="height: 242px;"
                                                        dense
                                                        no-resize
                                                        clearable
                                                    >
                                                    </v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-btn text rounded @click="step = 1" outlined>
                                            <v-icon small> arrow_back_ios </v-icon>{{ "Back" }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn text rounded @click="cancel()" class="ml-2">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            rounded
                                            @click="step = 3"
                                            v-if="me.isTenantUser && editMode && booking.orderStatus !== 'draft'"
                                        >
                                            Continue
                                        </v-btn>
                                        <v-btn
                                            v-else
                                            color="primary"
                                            rounded
                                            @click="saveOrders()"
                                            :disabled="!stepTwoIsValid"
                                            :loading="loadingBtn"
                                        >
                                            Save
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            rounded
                                            @click="saveOrders()"
                                            :disabled="!stepTwoIsValid"
                                            :loading="loadingBtn"
                                        >
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            hide item in the step 1 
                                <v-col cols="6" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Transport Type
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="item.transportType"
                                                        :items="transportTypeList"
                                                        item-text="text"
                                                        item-value="value"
                                                        flat
                                                        hide-no-data
                                                        placeholder="Select Transport Type"
                                                        outlined
                                                        dense
                                                        :disabled="true"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col> 
                            <v-col cols="6" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Estimated Gross Weight</span>
                                                        </v-col>
                                                        
                                                    </v-row>
                                                    <v-text-field
                                                        v-model.number="item.estimatedGrossWeight"
                                                        label=""
                                                        outlined
                                                        dense
                                                        placeholder="Estimated Gross Weight"
                                                        :disabled="true"
                                                        clearable
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                weighBridge
                            <v-col cols="6" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Weigh Bridge
                                                            </span>
                                                        </v-col>
                                                        
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="item.weighBridgeId"
                                                        :items="weighBridges"
                                                        :loading="$apollo.queries.weighBridges.loading"
                                                        item-text="Description"
                                                        item-value="Code"
                                                        placeholder="Select Weigh Bridge"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        :disabled="true"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                            
                                Step 3 is hide, for remove this condition discomented the v-stepper-step 3 and 
                                also the the logic in the step 2 continue button
                            
                            <v-stepper-content
                                step="3"
                                class="pt-1"
                                v-if="me.isTenantUser && editMode && booking.orderStatus !== 'draft'"
                            >
                                <v-card>
                                    <v-card-text>
                                        <v-form ref="form" v-model="stepOneIsValid">
                                            <v-row dense v-if="item != null">
                                                <v-col cols="12" class="d-flex flex-row">
                                                    <div class="title primary--text">
                                                        Container Details
                                                    </div>
                                                    <div cols="auto" class="red--text mt-1 ml-2">
                                                        <v-tooltip right class="">
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <v-icon
                                                                    small
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :style="styleBorder"
                                                                    color="primary"
                                                                >
                                                                    priority_high
                                                                </v-icon>
                                                            </template>
                                                            <span>
                                                                Here you can enter the specifications
                                                                <br />
                                                                of the cargo for better data result.
                                                            </span>
                                                        </v-tooltip>
                                                    </div>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                CTO Number
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model.number="item.ctoNumber"
                                                        outlined
                                                        dense
                                                        placeholder="CTO Number"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Container Number</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model.number="item.containerNumber"
                                                        placeholder="Container Number"
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Seal Number</span>
                                                        </v-col>
                                                       
                                                    </v-row>
                                                    <v-text-field
                                                        v-model.number="item.sealNumber"
                                                        placeholder="Seal Number"
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>

                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Tracker Number
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model.number="item.trackerNumber"
                                                        outlined
                                                        dense
                                                        placeholder="Tracker Number"
                                                        clearable
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Transport Allocation User
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="item.transportAllocationUser"
                                                        outlined
                                                        dense
                                                        placeholder="Transport Allocation User"
                                                        clearable
                                                    >
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12" class="d-flex flex-row">
                                                    <div class="title primary--text">
                                                        Transport Details
                                                    </div>
                                                    <div cols="auto" class="red--text mt-1 ml-2">
                                                        <v-tooltip right class="">
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <v-icon
                                                                    small
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :style="styleBorder"
                                                                    color="primary"
                                                                >
                                                                    priority_high
                                                                </v-icon>
                                                            </template>
                                                            <span>
                                                                Here you can enter the specifications
                                                                <br />
                                                                of the transporter.
                                                            </span>
                                                        </v-tooltip>
                                                    </div>
                                                </v-col>
                                               
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Transporter</span>
                                                        </v-col>
                                                       
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    Transporter is the carrier
                                                                    <br />
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="item.transporterId"
                                                        :items="transporters"
                                                        :loading="$apollo.queries.transporters.loading"
                                                        item-text="Description"
                                                        item-value="Code"
                                                        flat
                                                        placeholder="Select Transporter"
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        :rules="[]"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Transport Status</span>
                                                        </v-col>
                                                    </v-row>
                                                   
                                                    <v-autocomplete
                                                        v-model="item.transportStatusId"
                                                        :items="transporterStatuses"
                                                        :loading="$apollo.queries.transporterStatuses.loading"
                                                        item-text="Description"
                                                        item-value="StatusID"
                                                        flat
                                                        placeholder="Select Transport Status"
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        :rules="[]"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Transport Comment
                                                            </span>
                                                        </v-col>
                                                       
                                                    </v-row>
                                                    <v-textarea
                                                        v-model="item.transportComment"
                                                        outlined
                                                        dense
                                                        no-resize
                                                        height="80px"
                                                        hide-details
                                                        clearable
                                                    ></v-textarea>
                                                </v-col>
                                                <v-col cols="12" class="">
                                                    <div class="title primary--text">
                                                        Depot details
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" class="">
                                                    <v-row dense>
                                                        <v-col cols="4">
                                                            <span class="text">
                                                                Plug In Depot
                                                            </span>
                                                            <v-text-field
                                                                v-model="item.plugInDepot"
                                                                outlined
                                                                dense
                                                                placeholder="Plug In Depot"
                                                                :rules="[]"
                                                                clearable
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <span class="text">
                                                                Depot Date In
                                                            </span>
                                                            <DateTimeComponent
                                                                v-model="item.depotDateIn"
                                                                :icon="'calendar_month'"
                                                                :outlined="true"
                                                                :dense="true"
                                                                :defaultTime="'00:00'"
                                                                :placeholder="'1900/01/01 00:00'"
                                                            >
                                                            </DateTimeComponent>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <span class="text">
                                                                Depot Date Out
                                                            </span>
                                                            <DateTimeComponent
                                                                v-model="item.depotDateOut"
                                                                :icon="'calendar_month'"
                                                                :outlined="true"
                                                                :dense="true"
                                                                :defaultTime="'00:00'"
                                                                :placeholder="'1900/01/01 00:00'"
                                                            >
                                                            </DateTimeComponent>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-btn text rounded @click="step = 2" outlined>
                                            <v-icon small> arrow_back_ios </v-icon>{{ "Back" }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn text rounded @click="cancel()" class="ml-2">
                                            Cancel
                                        </v-btn>
                                        <v-btn color="primary" rounded @click="saveOrders()" :loading="loadingBtn">
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper> -->
    </v-container>
</template>
<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";

export default {
    components: {
        DateTimeComponent
    },
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        order: {
            type: Object,
            required: false
        },
        booking: {
            type: Object
        }
    },
    data() {
        return {
            dialog: false,
            item: null,
            transTypes: [
                { text: "Contour", value: "Contour" },
                { text: "Carrier", value: "Carrier" },
                { text: "Own", value: "Own" }
            ],
            formValidate: false,
            rules: {
                required: v => !helper.isEmpty(v) || "This filed is required",
                minLength: len => v => (v || "").length >= len || `Invalid character length, required ${len}`,
                maxLength: len => v => (v || "").length <= len || "Too long",
                isMoreThanZero: v => v > 0 || "Value must be more than 0"
            },
            step: 1,
            stepOneIsValid: false,
            stepTwoIsValid: false,
            transportTypeList: [
                { text: "Contour Logistics", value: "CON001" },
                { text: "Carrier", value: "CARRIER" },
                { text: "Own Transport", value: "OWN" }
            ],
            statusList: [
                { text: "PROV", value: "prov" },
                { text: "FIRM", value: "firm" }
            ],
            loadingBtn: false,
            defaultItem: {
                loadDate: "",
                transportType: "",
                estimatedGrossWeight: 0,
                loadPoint1Id: "",
                loadPoint1: "",
                weighBridgeId: "",
                solasMethodId: "",
                solasMethod: "",
                tempRecorderTypeId: "",
                tempRecorderType: "",
                loadPoint2Id: "",
                loadPoint2: "",
                dualLoad: false,
                plugInDepot: "",
                depotDateIn: "",
                depotDateOut: "",
                transportInstruction: "",
                gensetIsRequired: false,
                transportAllocationUser: "",
                trackerNumber: "",
                transportStatusId: null,
                transportStatus: "",
                transportComment: "",
                containerNumber: "",
                sealNumber: "",
                transporter: "",
                transporterId: "",
                numberOfPalletsPerShipment: 0,
                bookingReference: "",
                exporterReferenceNumber: "",
                emptyLoadPointId: "",
                emptyLoadPoint: ""
            }
        };
    },
    apollo: {
        containerDepots: {
            query: gql`
                query QxConatinerDepots($code: String) {
                    qxContainerDepots(code: $code) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // depotId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxContainerDepots,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        weighBridges: {
            query: gql`
                query QxWeighBridges($weighBridgeId: String) {
                    qxWeighBridges(weighBridgeId: $weighBridgeId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "weighBridgeId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxWeighBridges,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        depots: {
            query: gql`
                query QxDepots($depotId: String) {
                    qxDepots(depotId: $depotId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // depotId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxDepots,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        solasVerificationMethods: {
            query: gql`
                query QxSolasVerificationMethods($solasVerificationMethodId: String) {
                    qxSolasVerificationMethods(solasVerificationMethodId: $solasVerificationMethodId) {
                        Description
                        VerificationMethod
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "solasVerificationMethodId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxSolasVerificationMethods,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        tempDevices: {
            query: gql`
                query QxTempDevices($tempDeviceId: String) {
                    qxTempDevices(tempDeviceId: $tempDeviceId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // tempDeviceId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTempDevices,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        transporters: {
            query: gql`
                query QxTransporters($transporterId: String) {
                    qxTransporters(transporterId: $transporterId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "transporterId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTransporters,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        transporterStatuses: {
            query: gql`
                query QxTransporterStatuses($transporterStatusId: Int) {
                    qxTransporterStatuses(transporterStatusId: $transporterStatusId) {
                        StatusID
                        Description
                        DualLoad
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "transporterStatusId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTransporterStatuses,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        }
    },
    computed: {
        primaryTheme() {
            if (this.$vuetify.theme.themes.light.primary) {
                return this.$vuetify.theme.themes.light.primary;
            } else {
                return "#1976d2";
            }
        },
        styleBorder() {
            return `border: 1px solid ${this.primaryTheme}; border-radius: 10px;`;
        },
        me() {
            return helper.me();
        },
        nrOfTransportOrders() {
            return this.booking?.bookingTransportOrderQxSet?.edges?.length || 0;
        },
        disableAddTransportOrderBtn() {
            if (this.nrOfTransportOrders === this.booking.numberOfShipments) return true;

            return (
                this.booking.orderStatus !== "draft" &&
                (!this.me.isTenantUser || this.booking.orderStatus === "confirmed")
            );
        }
    },
    methods: {
        setBookingOrderFields() {
            this.item.weighBridgeId = this.booking.weighBridgeId;
            this.item.estimatedGrossWeight = this.booking.approxGrossWeight;
            this.item.transportType = this.booking.transportType;
            this.item.transporterId = this.booking.transportType;
        },
        async create() {
            this.resetForm();
            this.dialog = true;

            this.setBookingOrderFields();
        },
        async edit(value) {
            this.item = value;
            this.setBookingOrderFields();
            if (value.loadDate) {
                this.item.loadDate = helper.qxFormatDateTime(value.loadDate);
            }
            if (value.depotDateIn) {
                this.item.depotDateIn = helper.qxFormatDateTime(value.depotDateIn);
            }
            if (value.depotDateOut) {
                this.item.depotDateOut = helper.qxFormatDateTime(value.depotDateOut);
            }
            this.dialog = true;
        },
        add(item) {
            this.$emit("addTransportOrder", item);
            this.dialog = false;
        },
        resetForm() {
            this.item = { ...this.defaultItem };
            //this.step = 1;
        },
        cancel() {
            this.dialog = false;
            this.resetForm();
        },
        saveOrders() {
            //query CreateBookingTransportOrderQx
            this.loadingBtn = true;

            let payload = {
                // tenantId: this.me?.tenant?.id || "",
                // tenantPartnerId: partner_id || "",
                bookingOrderQxId: this.booking?.id || "",
                // loadDate: this.item.loadDate ? helper.convertDateTimeToUTC(this.item.loadDate) : null,
                loadDate: this.item.loadDate ? helper.qxConvertDateTimeToUTC(this.item.loadDate) : null,
                transportType: this.item.transportType || "",
                estimatedGrossWeight: this.item.estimatedGrossWeight || "",
                loadPoint1Id: this.item.loadPoint1Id || "",
                orderStatus: "draft",
                loadPoint1: this.depots.find(x => x.Code === this.item.loadPoint1Id)?.Description || "",
                containerNumber: this.item?.containerNumber || "",
                sealNumber: this.item?.sealNumber || "",
                trackerNumber: this.item?.trackerNumber || "",
                ctoNumber: this.item?.ctoNumber || "",
                transportStatusId: this.item?.transportStatusId || null,
                transportStatus:
                    this.transporterStatuses.find(x => x.StatusID === this.item.transportStatusId)?.Description || "",
                solasMethod:
                    this.solasVerificationMethods.find(x => x.VerificationMethod === this.item.solasMethodId)
                        ?.Description || "",
                solasMethodId: this.item.solasMethodId || "",
                tempRecorderTypeId: this.item.tempRecorderTypeId || "",
                tempRecorderType:
                    this.tempDevices.find(x => x.Code === this.item.tempRecorderTypeId)?.Description || "",
                loadPoint2Id: this.item.loadPoint2Id || "",
                loadPoint2: this.depots.find(x => x.Code === this.item.loadPoint2Id)?.Description || "",
                dualLoad: this.item.dualLoad || false,
                plugInDepot: this.item.plugInDepot,
                depotDateIn: this.item.depotDateIn ? helper.qxConvertDateTimeToUTC(this.item.depotDateIn) : null,
                depotDateOut: this.item.depotDateOut ? helper.qxConvertDateTimeToUTC(this.item.depotDateOut) : null,
                transportInstruction: this.item?.transportInstruction || "",
                transportAllocationUser: this.item?.transportAllocationUser || "",
                gensetIsRequired: this.item.gensetIsRequired,
                gensetIsRequiredId: this.item.gensetIsRequired === true ? "true" : "false",
                transportComment: this.item?.transportComment || "",
                transporterId: this.item?.transporterId || "",
                transporter: this.transporters.find(x => x.Code === this.item.transporterId)?.Description || "",
                numberOfPalletsPerShipment: this.item.numberOfPalletsPerShipment || 0,
                bookingReference: this.item.bookingReference || "",
                exporterReferenceNumber: this.item.exporterReferenceNumber || "",
                emptyLoadPointId: this.item.emptyLoadPointId || "",
                emptyLoadPoint:
                    this.containerDepots.find(item => item.Code === this.item.emptyLoadPointId)?.Description || ""
            };

            if (this.editMode) {
                payload.id = this.item.id;

                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation updateBookingTransportOrderQx($input: UpdateBookingTransportOrderQxInput!) {
                                updateBookingTransportOrderQx(input: $input) {
                                    bookingTransportOrderQx {
                                        id
                                        loadPoint1Id
                                        loadPoint1
                                        loadPoint2Id
                                        loadPoint2
                                        loadDate
                                        transportType
                                        estimatedGrossWeight
                                        solasMethodId
                                        solasMethod
                                        tempRecorderTypeId
                                        tempRecorderType
                                        dualLoad
                                        plugInDepot
                                        depotDateIn
                                        depotDateOut
                                        transportInstruction
                                        transportAllocationUser
                                        gensetIsRequired
                                        gensetIsRequiredId
                                        transportComment
                                        transporterId
                                        transporter
                                        transportStatusId
                                        transportStatus
                                        containerNumber
                                        sealNumber
                                        trackerNumber
                                        ctoNumber
                                        numberOfPalletsPerShipment
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(response => {
                        this.$emit(
                            "addTransportOrder",
                            response.data.updateBookingTransportOrderQx.bookingTransportOrderQx
                        );
                        this.dialog = false;
                    })
                    .catch(error => {
                        console.log("error", error);
                        // show snackbar
                        const payload = {
                            color: "error",
                            message: error.message
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .finally(() => {
                        this.loadingBtn = false;
                    });
            } else {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation createBookingTransportOrderQx($input: CreateBookingTransportOrderQxInput!) {
                                createBookingTransportOrderQx(input: $input) {
                                    bookingTransportOrderQx {
                                        id
                                        loadPoint1Id
                                        loadPoint1
                                        loadPoint2Id
                                        loadPoint2
                                        loadDate
                                        transportType
                                        estimatedGrossWeight
                                        solasMethodId
                                        solasMethod
                                        tempRecorderTypeId
                                        tempRecorderType
                                        dualLoad
                                        plugInDepot
                                        depotDateIn
                                        depotDateOut
                                        transportInstruction
                                        transportAllocationUser
                                        gensetIsRequired
                                        gensetIsRequiredId
                                        transportComment
                                        transporterId
                                        transporter
                                        transportStatusId
                                        transportStatus
                                        containerNumber
                                        sealNumber
                                        trackerNumber
                                        ctoNumber
                                        numberOfPalletsPerShipment
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(response => {
                        // this.loadingBtn = false;
                        this.$emit(
                            "addTransportOrder",
                            response.data.createBookingTransportOrderQx.bookingTransportOrderQx
                        );
                        this.dialog = false;
                    })
                    .catch(error => {
                        console.log("error", error);
                        // show snackbar
                        const payload = {
                            color: "error",
                            message: error.message
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .finally(() => {
                        this.loadingBtn = false;
                    });
            }
        }
    }
};
</script>
<style scoped>
.title {
    font-size: 16px;
    font-weight: 600;
}
.text {
    font-size: 13px;
    font-weight: 600;
}
</style>
