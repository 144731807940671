<template>
    <v-row class="d-flex justify-center">
        <v-col 
            v-for="(item, index) in items" 
            :cols="item.cols.xs" 
            :xs="item.cols.xs"
            :sm="item.cols.sm"
            :md="item.cols.md"
            :lg="item.cols.lg"
            :xl="item.cols.xl"
            :key="index"
>
            <v-card
                :height="height"
                :flat="flat"
                class="d-flex justify-center align-center flex-column rounded-lg outline"
                :color="item.value === selected ? selectedColor : unSelectedColor"
                @click="selectOption(item.value)"
            >
                <v-card-text class="d-flex justify-center flex-column align-center">
                    <div class="mb-1">
                        <v-icon
                            v-if="item.icon"
                            :size="iconSize"
                            x-large
                            :color="item.value === selected ? iconSelectedColor : iconUnSelectedColor"
                            :style="'transform: rotate(' + transport.iconRotate + ');'"
                            >{{ item.icon }}</v-icon
                        >
                        <v-img
                            v-if="item.img"
                            :src="item.img"
                            :width="imgSize"
                            :style="item.value === selected ? `filter: invert(100%);` : `filter: invert(0%);`"
                        ></v-img>
                    </div>
                    <div :class="txtClass">
                        <span
                            :style="
                                item.value === selected ? `color:${iconSelectedColor}` : `color:${iconUnSelectedColor}`
                            "
                            class="d-flex align-center text-center"
                        >
                            {{ item.text }}
                        </span>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "CustomCardOption",
    props: {
        cols: {
            type: Number,
            default: 3
        },
        items: {
            type: Array,
            default: () => []
        },
        value: {
            type: String,
            default: null
        },
        selectedColor: {
            type: String,
            default: "#0e1e46"
        },
        unSelectedColor: {
            type: String,
            default: "white"
        },
        iconSelectedColor: {
            type: String,
            default: "white"
        },
        iconUnSelectedColor: {
            type: String,
            default: "#0e1e46"
        },
        width: {
            type: String,
            default: "260px"
        },
        height: {
            type: String,
            default: "180px"
        },
        imgSize: {
            type: String,
            default: "40px"
        },
        iconSize: {
            type: String,
            default: "30px"
        },
        flat: {
            type: Boolean,
            default: true
        },
        txtClass: {
            type: String,
            default: "textSubTitle"
        }
    },
    data() {
        return {
            selected: null
        };
    },
    watch: {
        value: {
            handler(val) {
                this.selected = val?.toLowerCase();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        selectOption(option) {
            this.selected = option;
            this.$emit("input", option);
        }
    }
};
</script>

<style scoped>
.outline {
    border: 1px solid #cfd2da !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
}
</style>
