<template>
    <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
        <!-- V-CARD -->
        <v-card-text>
            <!-- filter menu -->
            <div style="position: absolute; right: 25px">
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display_filters')"
                    :choices="headerChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                    class="mr-4"
                >
                </FilterMenu>
            </div>
            <v-data-table
                :headers="dynamicHeaders"
                :items="trips.edges"
                item-key="node.id"
                :loading-text="$t('loading.loading1')"
                :loading="$apollo.queries.trips.loading"
                hide-default-footer
                disable-pagination
                disable-sort
                :mobile-breakpoint="0"
                loader-height="2"
                :no-data-text="$t('loading.no_available')"
            >
                <!-- Planned Departure -->
                <template v-slot:item.node.plannedDepartureDate="{ item }">
                    <span>{{
                        formatDateTime(item.node.plannedDepartureDate)
                    }}</span>
                </template>

                <!-- Planned Arrival -->
                <template v-slot:item.node.plannedArrivalDate="{ item }">
                    <span>{{
                        formatDateTime(item.node.plannedArrivalDate)
                    }}</span>
                </template>
            </v-data-table>
        </v-card-text>

        <!-- infinite loading trigger -->
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.trips.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                {{ $t("loading.load_more") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "@/utils/helper";

export default {
    name: "PartnerTripsTableTab",
    components: { FilterMenu },
    props: {
        partnerTripsFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    apollo: {
        trips: {
            query: gql`
                query trips(
                    $before: String
                    $after: String
                    $first: Int
                    $last: Int
                    $referenceNumberIexact: String
                    $referenceNumberIcontains: String
                    $transportModeIexact: String
                    $transportModeIcontains: String
                    $transportModeIn: [String]
                    $plannedDepartureDate: DateTime
                    $plannedDepartureDateGte: DateTime
                    $plannedDepartureDateLte: DateTime
                    $plannedArrivalDate: DateTime
                    $plannedArrivalDateGte: DateTime
                    $plannedArrivalDateLte: DateTime
                    $orderBy: String
                    $tenantPartnerId: ID
                    $tenantCarrier: ID
                    $status: String
                    $statusIn: [String]
                ) {
                    trips(
                        before: $before
                        after: $after
                        first: $first
                        last: $last
                        referenceNumber_Iexact: $referenceNumberIexact
                        referenceNumber_Icontains: $referenceNumberIcontains
                        tripPartnerSet_TenantPartner: $tenantPartnerId
                        tenantCarrier: $tenantCarrier
                        transportMode_Iexact: $transportModeIexact
                        transportMode_Icontains: $transportModeIcontains
                        transportMode_In: $transportModeIn
                        plannedDepartureDate: $plannedDepartureDate
                        plannedDepartureDate_Gte: $plannedDepartureDateGte
                        plannedDepartureDate_Lte: $plannedDepartureDateLte
                        plannedArrivalDate: $plannedArrivalDate
                        plannedArrivalDate_Gte: $plannedArrivalDateGte
                        plannedArrivalDate_Lte: $plannedArrivalDateLte
                        orderBy: $orderBy
                        status: $status
                        status_In: $statusIn
                    ) {
                        edges {
                            node {
                                id
                                plannedArrivalDate
                                plannedDepartureDate
                                referenceNumber
                                status
                                transportMode
                            }
                        }
                        pageInfo {
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return this.partnerTripsFilter;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trips,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 800
        }
    },
    data() {
        return {
            loading: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            // --------- PARTNERS --------- \\
            trips: { edges: [] },
            openAddPartnerUser: false,
            // --------- HEADERS --------- \\
            // defaultHeaderChoices: [
            //     {
            //         // for filter menu
            //         code: "node.referenceNumber",
            //         name: "Reference Number",
            //         tag: "headers.reference_number",
            //         enabled: true,
            //         order: 0,
            //         // for table header
            //         text: "Reference Number",
            //         align: "left",
            //         value: "node.referenceNumber"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.transportMode",
            //         name: "Transport Mode",
            //         tag: "headers.transport_mode",
            //         enabled: true,
            //         order: 1,
            //         // for table header
            //         text: "Transport Mode",
            //         align: "left",
            //         value: "node.transportMode"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.status",
            //         name: "Status",
            //         tag: "headers.status",
            //         enabled: true,
            //         order: 2,
            //         // for table header
            //         text: "Status",
            //         align: "left",
            //         value: "node.status"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.plannedDepartureDate",
            //         name: "Planned Departure Date",
            //         tag: "headers.planned_departure",
            //         enabled: true,
            //         order: 3,
            //         // for table header
            //         text: "Planned Departure Date",
            //         align: "left",
            //         value: "node.plannedDepartureDate"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.plannedArrivalDate",
            //         name: "Planned Arrival Date",
            //         tag: "headers.planned_arrival",
            //         enabled: true,
            //         order: 4,
            //         // for table header
            //         text: "Planned Arrival Date",
            //         align: "left",
            //         value: "node.plannedArrivalDate"
            //     }
            // ],
            headerChoices: null
        };
    },
    watch: {},
    mounted() {
        this.$watch(
            "headerChoices",
            function(value) {
                helper.updateMyPreferences({
                    partnerTripsTableHeaders: value
                });
            },
            { deep: true }
        );
    },
    computed: {
        hasMoreData() {
            return this.trips?.pageInfo?.hasNextPage;
        },
        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            _headers.push({
                text: "",
                align: "right",
                value: "headerFilter",
                width: "1px",
                sortable: false
            });

            return _headers;
        },
        defaultHeaderChoices: function() {
            let _defaultHeaderChoices = [
                {
                    // for filter menu
                    code: "node.referenceNumber",
                    name: "Reference Number",
                    tag: "headers.reference_number",
                    enabled: true,
                    order: 0,
                    // for table header
                    text: "Reference Number",
                    align: "left",
                    value: "node.referenceNumber"
                },
                {
                    // for filter menu
                    code: "node.transportMode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    enabled: true,
                    order: 1,
                    // for table header
                    text: "Transport Mode",
                    align: "left",
                    value: "node.transportMode"
                },
                {
                    // for filter menu
                    code: "node.status",
                    name: "Status",
                    tag: "headers.status",
                    enabled: true,
                    order: 2,
                    // for table header
                    text: "Status",
                    align: "left",
                    value: "node.status"
                },
                {
                    // for filter menu
                    code: "node.plannedDepartureDate",
                    name: "Planned Departure Date",
                    tag: "headers.planned_departure",
                    enabled: true,
                    order: 3,
                    // for table header
                    text: "Planned Departure Date",
                    align: "left",
                    value: "node.plannedDepartureDate"
                },
                {
                    // for filter menu
                    code: "node.plannedArrivalDate",
                    name: "Planned Arrival Date",
                    tag: "headers.planned_arrival",
                    enabled: true,
                    order: 4,
                    // for table header
                    text: "Planned Arrival Date",
                    align: "left",
                    value: "node.plannedArrivalDate"
                }
            ];
            return _defaultHeaderChoices;
        }
    },
    created() {
        this.headerChoices = helper.getTableHeaders(
            "partnerTripsTableHeaders",
            this.defaultHeaderChoices
        );
    },
    methods: {
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.trips?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.trips?.pageInfo?.hasNextPage) {
                this.$apollo.queries.trips.fetchMore({
                    variables: {
                        after: this.trips.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
