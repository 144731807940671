const state = {
    snackbar: null
};

const mutations = {
    setMessage(state, payload) {
        state.snackbar = payload;
    }
};

const actions = {
    showMessage(context, payload) {
        context.commit("setMessage", payload);
    }
};

const getters = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
