<template>
    <v-dialog max-width="600" v-model="openDeleteGroupDialog" persistent>
        <v-card class="pb-4">
            <v-card-title class="primary">
                <v-icon color="white" class="mr-2">warning</v-icon>
                <div class="white--text">
                    {{ $t("alert_rules.delete_action_group") }}
                </div>
            </v-card-title>
            <v-card-text class="pt-3">
                <div>
                    <h4>
                        {{ $t("alert_rules.sure_action_group") }}
                    </h4>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="$emit('closeDeleteDialog')">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isDeleting"
                    @click="deleteGroup"
                    >{{ $t("general.delete") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";

export default {
    name: "DeleteGroup",
    props: ["openDeleteGroupDialog", "groupId"],
    data: function() {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteGroup() {
            this.isDeleting = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation DeleteTenantActionGroup(
                            $input: DeleteTenantActionGroupInput!
                        ) {
                            deleteTenantActionGroup(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.groupId
                        }
                    }
                })
                .then(() => {
                    this.$emit("refreshDataTable");
                    this.$emit("closeDeleteDialog");
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isDeleting = false;
                });
        }
    }
};
</script>
