import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"py-5",class:_vm.shouldStickyFilter ? 'sticky-card' : '',attrs:{"elevation":hover ? 5 : 3,"outlined":""}},[_c(VRow,{staticClass:"px-5 d-flex align-center",attrs:{"align":"start"}},[_c(VCol,{attrs:{"cols":"12","sm":"9","lg":"10"}},[_c(VRow,[_vm._l((_vm.dynamicFilters),function(f){return _c(VCol,{key:f,class:_vm.getFilterChoiceClassByCode(f),attrs:{"cols":"auto"}},[(f === 'referenceNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode(
                                        'referenceNumber'
                                    ),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.referenceNumberIcontains),callback:function ($$v) {_vm.$set(_vm.filter, "referenceNumberIcontains", $$v)},expression:"filter.referenceNumberIcontains"}}):_vm._e(),(f === 'tripReferenceNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode(
                                        'tripReferenceNumber'
                                    ),"clearable":"","hide-details":"","dense":""},model:{value:(
                                    _vm.filter.tripReferenceNumberIcontains
                                ),callback:function ($$v) {_vm.$set(_vm.filter, "tripReferenceNumberIcontains", $$v)},expression:"\n                                    filter.tripReferenceNumberIcontains\n                                "}}):_vm._e(),(f === 'tracker')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('tracker'),"clearable":"","hide-details":"","dense":""},model:{value:(
                                    _vm.filter.tripFreightTrackerSetTrackerSerialNumberIcontains
                                ),callback:function ($$v) {_vm.$set(_vm.filter, "tripFreightTrackerSetTrackerSerialNumberIcontains", $$v)},expression:"\n                                    filter.tripFreightTrackerSetTrackerSerialNumberIcontains\n                                "}}):_vm._e(),(f === 'containerNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode(
                                        'containerNumber'
                                    ),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.containerNumberIcontains),callback:function ($$v) {_vm.$set(_vm.filter, "containerNumberIcontains", $$v)},expression:"filter.containerNumberIcontains"}}):_vm._e(),(f === 'airWaybillNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode(
                                        'airWaybillNumber'
                                    ),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.airWaybillNumberIcontains),callback:function ($$v) {_vm.$set(_vm.filter, "airWaybillNumberIcontains", $$v)},expression:"filter.airWaybillNumberIcontains"}}):_vm._e(),(f === 'polSearch')?_c('PortFilter',{attrs:{"label":_vm.getFilterChoiceNameByCode('polSearch'),"refreshTable":_vm.refreshTablePorts},model:{value:(_vm.filter.freightPol),callback:function ($$v) {_vm.$set(_vm.filter, "freightPol", $$v)},expression:"filter.freightPol"}}):_vm._e(),(f === 'transshipmentSearch')?_c('PortFilter',{attrs:{"label":_vm.getFilterChoiceNameByCode(
                                        'transshipmentSearch'
                                    ),"refreshTable":_vm.refreshTablePorts},model:{value:(_vm.filter.freightTransshipment),callback:function ($$v) {_vm.$set(_vm.filter, "freightTransshipment", $$v)},expression:"filter.freightTransshipment"}}):_vm._e(),(f === 'podSearch')?_c('PortFilter',{attrs:{"label":_vm.getFilterChoiceNameByCode('podSearch'),"refreshTable":_vm.refreshTablePorts},model:{value:(_vm.filter.freightPod),callback:function ($$v) {_vm.$set(_vm.filter, "freightPod", $$v)},expression:"filter.freightPod"}}):_vm._e(),(f === 'status')?_c(VSelect,{staticClass:"maxWidth",attrs:{"items":_vm.statusChoices,"label":_vm.getFilterChoiceNameByCode('status'),"item-text":"text","item-value":"value","hide-details":"","dense":"","menu-props":{
                                    zIndex: 3,
                                    offsetY: true
                                }},model:{value:(_vm.filter.tripStatusIn),callback:function ($$v) {_vm.$set(_vm.filter, "tripStatusIn", $$v)},expression:"filter.tripStatusIn"}}):_vm._e(),(
                                    f ===
                                        'relativePlannedDepartureDateRange'
                                )?_c(VSelect,{staticClass:"maxWidth",attrs:{"items":_vm.relativePlannedDepartureDateRangeChoices,"label":_vm.getFilterChoiceNameByCode(
                                        'relativePlannedDepartureDateRange'
                                    ),"clearable":"","hide-details":"","dense":"","color":"primary","menu-props":{
                                    zIndex: 3,
                                    offsetY: true
                                }},model:{value:(_vm.relativePlannedDepartureDateRange),callback:function ($$v) {_vm.relativePlannedDepartureDateRange=$$v},expression:"relativePlannedDepartureDateRange"}}):_vm._e(),(f === 'freightConsignor')?_c('PartnerTripsConsignor',{ref:"freightConsignor",refInFor:true,attrs:{"refreshTable":_vm.refreshTableConsignor,"label":_vm.getFilterChoiceNameByCode(
                                        'freightConsignor'
                                    )},model:{value:(_vm.filter.freightConsignor),callback:function ($$v) {_vm.$set(_vm.filter, "freightConsignor", $$v)},expression:"filter.freightConsignor"}}):_vm._e(),(f === 'freightConsignee')?_c('PartnerTripsConsignee',{ref:"freightConsignee",refInFor:true,attrs:{"refreshTable":_vm.refreshTableConsignee,"label":_vm.getFilterChoiceNameByCode(
                                        'freightConsignee'
                                    )},model:{value:(_vm.filter.freightConsignee),callback:function ($$v) {_vm.$set(_vm.filter, "freightConsignee", $$v)},expression:"filter.freightConsignee"}}):_vm._e(),(f === 'freightForwarder')?_c('PartnerTripsForwarder',{ref:"freightForwarder",refInFor:true,attrs:{"refreshTable":_vm.refreshTableForwarder,"label":_vm.getFilterChoiceNameByCode(
                                        'freightForwarder'
                                    )},model:{value:(_vm.filter.freightForwarder),callback:function ($$v) {_vm.$set(_vm.filter, "freightForwarder", $$v)},expression:"filter.freightForwarder"}}):_vm._e(),(f === 'carrier')?_c('PartnerTripsCarrier',{ref:"carrier",refInFor:true,attrs:{"refreshTable":_vm.refreshTableCarrier,"label":_vm.getFilterChoiceNameByCode('carrier')},model:{value:(_vm.filter.tripTenantCarrier),callback:function ($$v) {_vm.$set(_vm.filter, "tripTenantCarrier", $$v)},expression:"filter.tripTenantCarrier"}}):_vm._e(),(f === 'vessel')?_c('VesselFilter',{attrs:{"refreshTable":_vm.refreshTableVessel,"label":_vm.getFilterChoiceNameByCode('vessel')},model:{value:(
                                    _vm.filter.tripTripSeaTransportSetVesselId
                                ),callback:function ($$v) {_vm.$set(_vm.filter, "tripTripSeaTransportSetVesselId", $$v)},expression:"\n                                    filter.tripTripSeaTransportSetVesselId\n                                "}}):_vm._e(),(f === 'transportMode')?_c(VSelect,{attrs:{"items":_vm.transportModeChoices,"label":_vm.getFilterChoiceNameByCode(
                                        'transportMode'
                                    ),"chips":"","small-chips":"","clearable":"","multiple":"","hide-details":"","dense":"","color":"primary","menu-props":{
                                    zIndex: 3,
                                    offsetY: true
                                }},model:{value:(_vm.filter.tripTransportModeIn),callback:function ($$v) {_vm.$set(_vm.filter, "tripTransportModeIn", $$v)},expression:"filter.tripTransportModeIn"}}):_vm._e(),(f === 'trackerLastLocation')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode(
                                        'trackerLastLocation'
                                    ),"clearable":"","hide-details":"","dense":""},model:{value:(
                                    _vm.filter.trackerLastLocation_Icontains
                                ),callback:function ($$v) {_vm.$set(_vm.filter, "trackerLastLocation_Icontains", $$v)},expression:"\n                                    filter.trackerLastLocation_Icontains\n                                "}}):_vm._e(),(f === 'freightAlertCode')?_c(VSelect,{staticClass:"maxWidth",attrs:{"items":_vm.alertsType,"label":_vm.getFilterChoiceNameByCode(
                                        'freightAlertCode'
                                    ),"item-text":"name","item-value":"value","hide-details":"","dense":"","clearable":"","color":"primary","menu-props":{
                                    zIndex: 3,
                                    offsetY: true
                                }},model:{value:(_vm.filter.freightAlertCode),callback:function ($$v) {_vm.$set(_vm.filter, "freightAlertCode", $$v)},expression:"filter.freightAlertCode"}}):_vm._e(),(f === 'kpi')?_c(VSelect,{attrs:{"label":_vm.getFilterChoiceNameByCode('kpi'),"clearable":"","hide-details":"","dense":"","color":"primary","menu-props":{
                                    zIndex: 3,
                                    offsetY: true
                                }}}):_vm._e(),(f === 'goods')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('goods'),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.goodsIcontains),callback:function ($$v) {_vm.$set(_vm.filter, "goodsIcontains", $$v)},expression:"filter.goodsIcontains"}}):_vm._e(),(f === 'plannedDepartureDate')?_c(VMenu,{ref:"plannedDepartureDateMenu",refInFor:true,attrs:{"close-on-content-click":false,"return-value":_vm.savedPlannedDepartureDateRange,"transition":"scale-transition","offset-y":"","max-width":"auto","min-width":"auto","disabled":!!_vm.relativePlannedDepartureDateRange,"z-index":"3"},on:{"update:returnValue":function($event){_vm.savedPlannedDepartureDateRange=$event},"update:return-value":function($event){_vm.savedPlannedDepartureDateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.plannedDepartureDateRangeText,"label":_vm.getFilterChoiceNameByCode(
                                                'plannedDepartureDate'
                                            ),"readonly":"","clearable":"","hide-details":"","dense":"","disabled":!!_vm.relativePlannedDepartureDateRange},on:{"click:clear":function($event){return _vm.onClearPlannedDepartureDates()}}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.plannedDepartureDateMenu),callback:function ($$v) {_vm.plannedDepartureDateMenu=$$v},expression:"plannedDepartureDateMenu"}},[_c(VDatePicker,{attrs:{"first-day-of-week":1,"range":"","no-title":"","scrollable":"","color":"primary"},model:{value:(_vm.plannedDepartureDateRange),callback:function ($$v) {_vm.plannedDepartureDateRange=$$v},expression:"plannedDepartureDateRange"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":"","color":"primary"},on:{"click":_vm.cancelPlannedDepartureDatePicker}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VBtn,{attrs:{"outlined":"","rounded":"","color":"primary","disabled":_vm.plannedDepartureDateRange.length <
                                                2},on:{"click":function($event){_vm.$refs.plannedDepartureDateMenu[0].save(
                                                _vm.plannedDepartureDateRange.sort()
                                            )}}},[_vm._v(" OK ")])],1)],1):_vm._e(),(f === 'plannedArrivalDate')?_c(VMenu,{ref:"plannedArrivalDateMenu",refInFor:true,attrs:{"close-on-content-click":false,"return-value":_vm.savedPlannedArrivalDateRange,"transition":"scale-transition","offset-y":"","max-width":"auto","min-width":"auto","disabled":!!_vm.relativePlannedArrivalDateRange,"z-index":"3"},on:{"update:returnValue":function($event){_vm.savedPlannedArrivalDateRange=$event},"update:return-value":function($event){_vm.savedPlannedArrivalDateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.plannedArrivalDateRangeText,"label":_vm.getFilterChoiceNameByCode(
                                                'plannedArrivalDate'
                                            ),"readonly":"","clearable":"","hide-details":"","dense":"","disabled":!!_vm.relativePlannedArrivalDateRange},on:{"click:clear":function($event){return _vm.onClearPlannedArrivalDates()}}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.plannedArrivalDateMenu),callback:function ($$v) {_vm.plannedArrivalDateMenu=$$v},expression:"plannedArrivalDateMenu"}},[_c(VDatePicker,{attrs:{"first-day-of-week":1,"range":"","no-title":"","scrollable":"","color":"primary"},model:{value:(_vm.plannedArrivalDateRange),callback:function ($$v) {_vm.plannedArrivalDateRange=$$v},expression:"plannedArrivalDateRange"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":"","color":"primary"},on:{"click":_vm.cancelPlannedArrivalDatePicker}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VBtn,{attrs:{"outlined":"","rounded":"","color":"primary","disabled":_vm.plannedArrivalDateRange.length <
                                                2},on:{"click":function($event){_vm.$refs.plannedArrivalDateMenu[0].save(
                                                _vm.plannedArrivalDateRange.sort()
                                            )}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)}),_c(VCol,{staticClass:"col-auto col-md-4 col-lg-3 col-xl-3"},[_c(VRow,{staticClass:"d-flex align-center ma-0 pa-0"},[_c(VSelect,{attrs:{"items":_vm.sortByChoices,"label":_vm.$t('general.sort_by'),"item-value":function (item) { return item.code; },"item-text":function (item) { return item.name; },"clearable":"","hide-details":"","dense":"","color":"primary","menu-props":{
                                        zIndex: 3,
                                        offsetY: true
                                    }},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VBtn,{staticClass:"mt-n1",attrs:{"small":"","icon":"","disabled":!_vm.sortBy},on:{"click":function($event){_vm.sortAsc = !_vm.sortAsc}}},[_c(VIcon,{attrs:{"color":"grey darken-2"}},[_vm._v(_vm._s(_vm.sortAsc ? "north" : "south"))])],1)]},proxy:true}],null,true),model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1)],1),_c(VCol,{staticClass:"col-auto col-md-4 col-lg-3 col-xl-3 d-flex"},[_c('div',{staticClass:"d-flex align-center mr-4"},[_c(VSwitch,{staticClass:"text-no-wrap",staticStyle:{"margin-top":"0%"},attrs:{"label":_vm.$t('general.favorites'),"color":"primary"},model:{value:(_vm.userFavoriteTrip),callback:function ($$v) {_vm.userFavoriteTrip=$$v},expression:"userFavoriteTrip"}})],1),_c('div',{staticClass:"d-flex align-center mr-4"},[_c(VSwitch,{staticClass:"text-no-wrap",staticStyle:{"margin-top":"0%"},attrs:{"label":_vm.$t('general.alerts'),"color":"primary"},model:{value:(_vm.unresolvedAlerts),callback:function ($$v) {_vm.unresolvedAlerts=$$v},expression:"unresolvedAlerts"}})],1),_c('div',[_c(VBtn,{attrs:{"rounded":"","small":"","color":"","elevation-2":""},on:{"click":_vm.clearFilter}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("filter_alt_off")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.clear")))])],1)],1)])],2)],1),_c(VCol,{staticClass:"py-2 pr-4 text-right",attrs:{"cols":"12","sm":"3","lg":"2"}},[_c(VRow,{staticClass:"d-flex align-center justify-end"},[_c('FilterMenu',{attrs:{"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display'),"choices":_vm.filterChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }