import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"elevation":"5","outlined":""}},[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('left')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_back")])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('right')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_forward")])],1)],1),_c('FilterMenu',{attrs:{"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display'),"choices":_vm.headerChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1),_c(VDataTable,{staticClass:"row-pointer",attrs:{"headers":_vm.dynamicHeaders,"items":_vm.trips.edges,"item-key":"node.id","loading-text":_vm.$t('loading.loading1'),"loading":_vm.$apollo.queries.trips.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","loader-height":"2","mobile-breakpoint":_vm.screenSize === 'xs' || _vm.screenSize === 'sm' ? 800 : 0,"no-data-text":_vm.$t('loading.no_data'),"fixed-header":"","height":_vm.tableHeight},on:{"click:row":function (item) { return this$1.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('tr',[_c('td',{style:(_vm.centralizeLoadBtnClass),attrs:{"colspan":2}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('loading.loading1'))+" ")])])])]},proxy:true},{key:"item.isFavorite",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.node.isUserFavorite)?_c(VIcon,{attrs:{"small":"","color":"warning"}},[_vm._v(" star ")]):_vm._e()]}},{key:"item.node.numberOfUnresolvedAlerts",fn:function(ref){
var item = ref.item;
return [_c('span',[_c(VRow,{staticClass:"mx-0 px-0",attrs:{"dense":""}},[(item.node.numberOfUnresolvedAlerts > 0)?_c(VIcon,{staticClass:"mr-2",attrs:{"color":"warning","small":""}},[_vm._v(" warning ")]):_vm._e(),_vm._v(" "+_vm._s(item.node.numberOfUnresolvedAlerts)+" ")],1)],1)]}},{key:"item.containers",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.node.tripFreightSet.edges.filter(
                            function (item) { return item.node.containerNumber; }
                        )),function(freight){return _c(VChip,{key:freight.node.id,staticClass:"mr-1 my-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToFreight(freight.node.id, item.node.id)}}},[_vm._v(" "+_vm._s(freight.node.containerNumber)+" ")])}),1)]}},{key:"item.airwaybill",fn:function(ref){
                        var item = ref.item;
return [_c('div',_vm._l((item.node.tripFreightSet.edges.filter(
                            function (item) { return item.node.airWaybillNumber; }
                        )),function(freight){return _c(VChip,{key:freight.node.id,staticClass:"mr-1 my-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToFreight(freight.node.id, item.node.id)}}},[(freight.node.airWaybillNumber)?_c('div',[_vm._v(" "+_vm._s(_vm.formatAirwaybillNumber(freight.node.airWaybillNumber))+" | "+_vm._s(freight.node.airWaybillNumber)+" ")]):_c('div')])}),1)]}},{key:"item.trackers",fn:function(ref){
                        var item = ref.item;
return _vm._l((item.node.tripFreightSet.edges),function(element){return _c('div',{key:element.node.id},[_vm._l((element.node
                            .tripFreightTrackerSet.edges),function(freightTracker){return _c(VTooltip,{key:freightTracker.node.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mr-1 my-1",attrs:{"color":freightTracker.node.tracker
                                        .activatedDate
                                        ? 'primary'
                                        : '',"small":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToFreight(
                                        element.node.id,
                                        item.node.id
                                    )}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(freightTracker.node.tracker.serialNumber)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(freightTracker.node.tracker.activatedDate ? ((_vm.$t("general.activated")) + " " + (_vm.$t( "general.on" )) + " " + (_vm.formatDateTime( freightTracker.node.tracker .activatedDate ))) : _vm.$t("general.not_activated"))+" ")])])}),(
                            _vm.getExternalTrackerAdditionalInfo(
                                element.node.additionalInfo
                            )
                        )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mr-1 my-1",attrs:{"small":"","outlined":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getExternalTrackerAdditionalInfo( element.node.additionalInfo ).serialNumber)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getExternalTrackerAdditionalInfo( element.node.additionalInfo ).type)+" ")])]):_vm._e()],2)})}},{key:"item.node.plannedDepartureDate",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.plannedDepartureDate)))])]}},{key:"item.node.plannedArrivalDate",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.plannedArrivalDate)))])]}},{key:"item.node.vessel",fn:function(ref){
                        var item = ref.item;
return _vm._l((_vm.getAllVessels(
                        item.node.tripSeaTransportSet
                    )),function(vessel){return _c(VChip,{key:vessel.id,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(_vm._s(vessel.name))])})}},{key:"item.node.polEtd",fn:function(ref){
                    var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm.getPolEtd(item.node.tripSeaTransportSet) .date)+" ")]),_c(VCol,[_c('div',{class:_vm.getPolEtd(item.node.tripSeaTransportSet).color},[_c('span',[_vm._v(" "+_vm._s(_vm.getPolEtd(item.node.tripSeaTransportSet).text)+" ")])])])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getPolEtd(item.node.tripSeaTransportSet).original))])])]}},{key:"item.node.podEta",fn:function(ref){
                    var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm.getPodEta(item.node.tripSeaTransportSet) .date)+" ")]),_c(VCol,[_c('div',{class:_vm.getPodEta(item.node.tripSeaTransportSet).color},[_c('span',[_vm._v(" "+_vm._s(_vm.getPodEta(item.node.tripSeaTransportSet).text)+" ")])])])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getPodEta(item.node.tripSeaTransportSet).original))])])]}},{key:"item.consignor",fn:function(ref){
                    var item = ref.item;
return _vm._l((item.node.tripPartnerSet.edges),function(partner){return _c('div',{key:partner.node.id},[(
                            partner.node.tenantPartner.partnerType ===
                                'consignor'
                        )?_c('span',{staticClass:"text-no-wrap"},[_c(VChip,{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(partner.node.tenantPartner.name)+" ")])],1):_vm._e()])})}},{key:"item.node.trackerLastConnectionTime",fn:function(ref){
                        var item = ref.item;
return [(item.node.trackerLastConnectionTime)?_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.trackerLastConnectionTime)))]):_vm._e()]}},{key:"item.node.trackerLastLocation",fn:function(ref){
                        var item = ref.item;
return [(item.node.trackerLastLocation)?_c(VChip,{attrs:{"small":""}},[_vm._v(_vm._s(item.node.trackerLastLocation))]):_vm._e(),(item.node.trackerLastTemperatureValue)?_c(VChip,{staticClass:"ml-3",attrs:{"small":""}},[_c('b',[_vm._v(_vm._s(_vm.formatTemperature( item.node.trackerLastTemperatureValue ).toFixed(0))+" "+_vm._s(_vm.getTemperatureSymbol()))])]):_vm._e()]}},{key:"item.consignee",fn:function(ref){
                        var item = ref.item;
return _vm._l((item.node.tripPartnerSet.edges),function(partner){return _c('div',{key:partner.node.id},[(
                            partner.node.tenantPartner.partnerType ===
                                'consignee'
                        )?_c('span',{staticClass:"text-no-wrap"},[_c(VChip,{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(partner.node.tenantPartner.name)+" ")])],1):_vm._e()])})}},{key:"item.forwarder",fn:function(ref){
                        var item = ref.item;
return _vm._l((item.node.tripPartnerSet.edges),function(partner){return _c('div',{key:partner.node.id},[(
                            partner.node.tenantPartner.partnerType ===
                                'forwarder'
                        )?_c('span',{staticClass:"text-no-wrap"},[_c(VChip,{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(partner.node.tenantPartner.name)+" ")])],1):_vm._e()])})}},{key:"item.carrier",fn:function(ref){
                        var item = ref.item;
return [(item.node.tenantCarrier)?_c('div',[_c(VChip,{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.node.tenantCarrier.name)+" ")])],1):_vm._e()]}},{key:"item.other",fn:function(ref){
                        var item = ref.item;
return _vm._l((item.node.tripPartnerSet.edges),function(partner){return _c('div',{key:partner.node.id},[(
                            partner.node.tenantPartner.partnerType ===
                                'other'
                        )?_c('span',{staticClass:"text-no-wrap"},[_c(VChip,{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(partner.node.tenantPartner.name)+" ")])],1):_vm._e()])})}},{key:"item.POL",fn:function(ref){
                        var item = ref.item;
return _vm._l((item.node.tripSeaTransportSet.edges),function(e){return _c('div',{key:e.node.id},[(e.node.portTransportType === 'POL')?_c('span',{staticClass:"text-no-wrap"},[(
                                e.node.portCode
                                    ? e.node.portCode.length > 0
                                    : false
                            )?_c(VChip,{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(e.node.portCode)+" ")]):_vm._e()],1):_vm._e()])})}},{key:"item.transshipment",fn:function(ref){
                            var item = ref.item;
return _vm._l((item.node.tripSeaTransportSet.edges),function(e){return _c('div',{key:e.node.id},[(
                            e.node.portTransportType === 'Transshipment' ||
                                e.node.portTransportType === 'T/S'
                        )?_c('span',{staticClass:"text-no-wrap"},[(
                                e.node.portCode
                                    ? e.node.portCode.length > 0
                                    : false
                            )?_c(VChip,{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(e.node.portCode)+" ")]):_vm._e()],1):_vm._e()])})}},{key:"item.POD",fn:function(ref){
                            var item = ref.item;
return _vm._l((item.node.tripSeaTransportSet.edges),function(e){return _c('div',{key:e.node.id},[(e.node.portTransportType === 'POD')?_c('span',{staticClass:"text-no-wrap"},[(
                                e.node.portCode
                                    ? e.node.portCode.length > 0
                                    : false
                            )?_c(VChip,{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(e.node.portCode)+" ")]):_vm._e()],1):_vm._e()])})}},{key:"item.freights",fn:function(ref){
                            var item = ref.item;
return [_c('div',_vm._l((item.node.tripFreightSet.edges.filter(
                            function (item) { return item.node.referenceNumber; }
                        )),function(freight){return _c(VChip,{key:freight.node.id,staticClass:"mr-1 my-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToFreight(freight.node.id, item.node.id)}}},[_vm._v(" "+_vm._s(freight.node.referenceNumber)+" ")])}),1)]}},{key:"item.goods",fn:function(ref){
                        var item = ref.item;
return [_c('div',_vm._l((_vm.getAllGoods(
                            item.node.tripFreightSet
                        )),function(goods){return _c(VChip,{key:goods,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(goods)+" ")])}),1)]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{style:(_vm.centralizeLoadBtnClass),attrs:{"colspan":2}},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.trips.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }