import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"700","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"pa-2 mx-1",attrs:{"label":"","rounded":_vm.rounded,"elevation":_vm.elevation,"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("alert_rules.generate_report"))+" ")])]}}]),model:{value:(_vm.generateReportDialog),callback:function ($$v) {_vm.generateReportDialog=$$v},expression:"generateReportDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VImg,{staticClass:"mr-2",style:("\n                    background-color:transparent;\n                    filter: invert(100%);\n                    "),attrs:{"src":require('@/assets/file_save.png'),"width":"20","max-width":"30","max-height":"30"}}),_vm._v(" "+_vm._s(_vm.$t("alert_rules.generate_trip"))+" ")],1),_c(VCardText,[_c('div',{staticClass:"mt-5 mx-2"},[_c('div',[_c('span',{staticClass:"subtitle-1"},[_c('b',[_vm._v(_vm._s(_vm.$t("headers.report_type")))])]),_c(VRow,{staticClass:"d-flex mt-3",attrs:{"dense":""}},_vm._l((_vm.fileFormatsAvailable),function(reportType){return _c(VCol,{key:reportType.value,attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c(VCard,{staticClass:"py-5 d-flex align-center justify-center",attrs:{"elevation":"2","color":_vm.fileFormat == reportType.value
                                        ? 'primary'
                                        : ''},on:{"click":function($event){return _vm.changeReportType(reportType.value)}}},[_c('span',{class:_vm.fileFormat == reportType.value
                                            ? 'white--text text-no-wrap pr-1'
                                            : 'black--text text-no-wrap pr-1'},[_vm._v(" "+_vm._s(reportType.text)+" ")])])],1)}),1)],1),_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"subtitle-1"},[_c('b',[_vm._v(_vm._s(_vm.$t("headers.date_range"))+" ")]),_c('span',{staticClass:"subtitle-2"},[_vm._v("("+_vm._s(_vm.$t("general.required"))+")")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VMenu,{ref:"dateRangeMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.dateRangeText,"label":_vm.$t('headers.date_range'),"readonly":"","hide-details":"","loading":_vm.$apollo.queries
                                                .trackerDeviceTimeRange
                                                .loading,"filled":"","outline":"","solo":"","dense":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateRangeMenu),callback:function ($$v) {_vm.dateRangeMenu=$$v},expression:"dateRangeMenu"}},[_c(VDatePicker,{attrs:{"first-day-of-week":1,"range":"","allowed-dates":_vm.allowedDates,"no-title":"","scrollable":"","color":"primary"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[(
                                            this.trip
                                                .plannedDepartureDate &&
                                                this.trip.plannedArrivalDate
                                        )?_c(VBtn,{attrs:{"rounded":"","outlined":"","small":"","color":"grey"},on:{"click":_vm.setTripDuration}},[_vm._v(" "+_vm._s(_vm.$t("alert_rules.full_trip"))+" ")]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":"","small":"","color":"primary"},on:{"click":_vm.cancelDatePicker}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VBtn,{attrs:{"outlined":"","rounded":"","small":"","color":"primary","disabled":_vm.dateRange.length < 1},on:{"click":_vm.saveDatePicker}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"subtitle-1"},[_c('b',[_vm._v(_vm._s(_vm.$t("headers.tracker"))+" ")]),_c('span',{staticClass:"subtitle-2"},[_vm._v("("+_vm._s(_vm.$t("general.required"))+")")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"5","md":"5"}},[_c(VAutocomplete,{staticClass:"mt-3",attrs:{"items":_vm.trackers,"no-data-text":_vm.$t('loading.no_available'),"item-text":"serialNumber","item-value":"id","label":_vm.$t('headers.tracker'),"dense":"","outlined":"","clearable":"","rules":[_vm.rules.required]},model:{value:(_vm.selectedTracker),callback:function ($$v) {_vm.selectedTracker=$$v},expression:"selectedTracker"}})],1)],1)],1),_c('div',[_c('span',{staticClass:"subtitle-1"},[_c('b',[_vm._v(_vm._s(_vm.$t("headers.sensor_data"))+" ")]),_c('span',{staticClass:"subtitle-2"},[_vm._v("("+_vm._s(_vm.$t("general.required"))+")")])]),_c('br'),_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.$t("general.select_options")))]),_c(VRow,{staticClass:"d-flex mt-3",attrs:{"dense":""}},_vm._l((_vm.sensorTypeChoices),function(sensorTypeChoice){return _c(VCol,{key:sensorTypeChoice.value,attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c(VCard,{staticClass:"py-5 d-flex align-center justify-center",attrs:{"elevation":"2","color":_vm.sensorTypes.includes(
                                        sensorTypeChoice.value
                                    )
                                        ? 'primary'
                                        : ''},on:{"click":function($event){return _vm.updateSensorData(sensorTypeChoice.value)}}},[_c('span',{class:_vm.sensorTypes.includes(
                                            sensorTypeChoice.value
                                        )
                                            ? 'white--text text-no-wrap pr-1'
                                            : 'black--text text-no-wrap pr-1'},[_c(VIcon,{staticClass:"pr-0",attrs:{"color":_vm.sensorTypes.includes(
                                                sensorTypeChoice.value
                                            )
                                                ? 'white'
                                                : 'black'}},[_vm._v(_vm._s(sensorTypeChoice.icon))]),_vm._v(" "+_vm._s(sensorTypeChoice.text)+" ")],1)])],1)}),1)],1),_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"subtitle-1"},[_c('b',[_vm._v(_vm._s(_vm.$t("general.send_users")))])]),_c('div',{staticClass:"mt-3"},[(!_vm.me.isPartnerUser && _vm.me.tenant)?_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c('ActionGroupAddUserForm',{model:{value:(_vm.users),callback:function ($$v) {_vm.users=$$v},expression:"users"}})],1)],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c(VTextField,{attrs:{"label":_vm.$t('headers.external_emails'),"rules":[
                                        _vm.email.length > 0
                                            ? _vm.rules.email
                                            : function () { return true; }
                                    ],"outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1)])])]),_c(VCardActions,{staticClass:"d-flex justify-end pb-5 pr-5"},[_c(VBtn,{attrs:{"text":"","disabled":_vm.isLoadingGenerateReport},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.close")))]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.isLoadingGenerateReport,"disabled":!_vm.valid,"rounded":_vm.rounded,"elevation":_vm.elevation},on:{"click":_vm.submitGenerateReport}},[_vm._v(" "+_vm._s(_vm.$t("general.generate"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }