<template>
    <v-container fluid>
        <v-timeline dense class="ml-n7">
            <v-form ref="seaForm">
                <div v-for="(item, i) in tripSeaTransport" :key="i">
                    <v-timeline-item small v-if="item.sequence === 0">
                        <v-row no-gutters v-if="disabled">
                            <v-col cols="12">
                                <v-card class="mb-1" flat>
                                    <v-row dense class="px-4">
                                        <v-col>
                                            <div class="font-weight-bold">
                                                {{ $t("trips.port_type") }}
                                            </div>
                                            <div>
                                                {{ item.portTransportType }}
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="font-weight-bold">
                                                {{ $t("trips.port_code") }}
                                            </div>
                                            <div>
                                                {{
                                                    item.portCode
                                                        ? item.portCode
                                                        : "-"
                                                }}
                                            </div>
                                        </v-col>
                                        <v-col
                                            v-if="
                                                item.portTransportType ===
                                                    'POL' &&
                                                    item.actualDepartureDate
                                            "
                                        >
                                            <div class="font-weight-bold">
                                                ATD 
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            color="primary"
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{
                                                                iconSource(
                                                                    item.actualDepartureDateSource
                                                                )
                                                            }}
                                                        </v-icon>
                                                    </template>
                                                    <span class="text-capitalize">
                                                        {{
                                                            convertUnderscore(
                                                                item.actualDepartureDateSource
                                                            )
                                                        }}
                                                    </span>
                                                </v-tooltip>
                                            </div>
                                            <div
                                                v-if="item.actualDepartureDate"
                                            >
                                                <!-- {{
                                                    item.actualDepartureDate
                                                        | moment("YYYY-MM-DD")
                                                }} -->
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <span
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    {{
                                                                        item.actualDepartureDate
                                                                            | moment(
                                                                                "YYYY-MM-DD"
                                                                            )
                                                                    }}
                                                                </v-col>
                                                                <v-col>
                                                                    <div :class="getDelay(item,'etd').color">
                                                                        <span>{{ getDelay(item, 'etd').text }}</span>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </span>
                                                    </template>
                                                    <span>ETD:{{ item.estimatedDepartureDate | moment("YYYY-MM-DD")}}</span>
                                                    <!-- <span>ETD:{{ item.estimatedDepartureDate | moment("YYYY-MM-DD")}}</span> -->
                                                </v-tooltip>
                                            </div>
                                        </v-col>
                                        <v-col v-else>
                                            <div class="font-weight-bold">
                                                ETD
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            color="primary"
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{
                                                                iconSource(
                                                                    item.estimatedDepartureDateSource
                                                                )
                                                            }}
                                                        </v-icon>
                                                    </template>
                                                    <span class="text-capitalize">{{
                                                        convertUnderscore(
                                                            item.estimatedDepartureDateSource
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </div>
                                            <div
                                                v-if="
                                                    item.estimatedDepartureDate &&
                                                        item.originalEstimatedDepartureDate
                                                "
                                            >
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <span
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    {{
                                                                        item.estimatedDepartureDate
                                                                            | moment(
                                                                                "YYYY-MM-DD"
                                                                            )
                                                                    }}
                                                                </v-col>
                                                                <v-col>
                                                                    <div :class="getDelay(item,'etd').color">
                                                                        <span>{{ getDelay(item, 'etd').text }}</span>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </span>
                                                    </template>
                                                    <span
                                                        >Original ETD:
                                                        {{
                                                            item.originalEstimatedDepartureDate
                                                                | moment(
                                                                    "YYYY-MM-DD"
                                                                )
                                                        }}</span
                                                    >
                                                </v-tooltip>
                                            </div>
                                            <div
                                                v-else-if="
                                                    item.estimatedDepartureDate &&
                                                        (item.originalEstimatedDepartureDate ===
                                                            null ||
                                                            item.originalEstimatedDepartureDate ===
                                                                undefined)
                                                "
                                            >
                                                {{
                                                    item.estimatedDepartureDate
                                                        | moment("YYYY-MM-DD")
                                                }}
                                            </div>
                                            <div
                                                v-else-if="
                                                    item.originalEstimatedDepartureDate &&
                                                        (item.estimatedDepartureDate ===
                                                            null ||
                                                            item.estimatedDepartureDate ===
                                                                undefined)
                                                "
                                            >
                                                {{
                                                    item.originalEstimatedDepartureDate
                                                        | moment("YYYY-MM-DD")
                                                }}
                                            </div>
                                            <div v-else>
                                                -
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="font-weight-bold">
                                                {{
                                                    item.vesselInputType
                                                        ? getInputTypeText(
                                                              item.vesselInputType
                                                          )
                                                        : $t(
                                                              "trips.vessel_type"
                                                          )
                                                }}
                                            </div>
                                            <div>
                                                {{
                                                    item.vesselInputValue
                                                        ? item.vesselInputValue
                                                        : "-"
                                                }}
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="font-weight-bold">
                                                {{ $t("trips.voyage") }}
                                            </div>
                                            <div>
                                                {{
                                                    item.voyage
                                                        ? item.voyage
                                                        : "-"
                                                }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row no-gutters v-else>
                            <v-col cols="12" class="py-0">
                                <v-row dense>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-text-field
                                            dense
                                            :label="$t('trips.port_type')"
                                            v-model="item.portTransportType"
                                            outlined
                                            filled
                                            readonly
                                            hide-details
                                        />
                                    </v-col>

                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <DateComponent
                                            v-model="
                                                item.originalEstimatedDepartureDate
                                            "
                                            :label="$t('trips.original_etd')"
                                            :rules="[]"
                                            :outlined="true"
                                            :dense="true"
                                        >
                                        </DateComponent>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <DateComponent
                                            v-model="
                                                item.estimatedDepartureDate
                                            "
                                            label="ETD"
                                            :rules="[]"
                                            :outlined="true"
                                            :dense="true"
                                        >
                                            <!-- :iconToolTip="item.originalEstimatedDepartureDate? covertDate(item.originalEstimatedDepartureDate,'Original ETD:') : null" -->
                                        </DateComponent>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <DateComponent
                                            v-model="item.actualDepartureDate"
                                            label="ATD"
                                            :rules="[]"
                                            :outlined="true"
                                            :dense="true"
                                        >
                                            <!-- :iconToolTip="item.originalEstimatedDepartureDate? covertDate(item.originalEstimatedDepartureDate,'Original ETD:') : null" -->
                                        </DateComponent>
                                    </v-col>

                                    <!-- <v-text-field
                                        v-show="false"
                                        dense
                                        label="sequence"
                                        v-model="item.sequence"
                                        outlined
                                        filled
                                        readonly
                                        
                                    /> -->
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-combobox
                                            v-model="item.portCode"
                                            :error="
                                                item.portCode === '' ||
                                                    item.portCode === null
                                            "
                                            :items="seaPorts.edges"
                                            :no-data-text="
                                                $t('loading.no_available')
                                            "
                                            :item-value="item => item.node.code"
                                            item-text="node.code"
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            :search-input.sync="item.portCode"
                                            :return-object="false"
                                            :label="$t('trips.port_code')"
                                            clearable
                                            outlined
                                            dense
                                            :error-messages="
                                                item.portCode === '' ||
                                                item.portCode === null
                                                    ? ['Port Code is missing']
                                                    : []
                                            "
                                        >
                                            <template v-slot:item="{ item }">
                                                <v-row class="ma-0 body-2">
                                                    <v-col
                                                        cols="4"
                                                        class="text-truncate pl-0 ml-0"
                                                    >
                                                        {{ item.node.code }}
                                                    </v-col>
                                                    <v-col
                                                        cols="8"
                                                        class="d-flex justify-end"
                                                    >
                                                        <span
                                                            class="grey--text body-2 mr-1"
                                                            ><i>{{
                                                                item.node.name
                                                            }}</i></span
                                                        >
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-combobox>
                                    </v-col>

                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-select
                                            dense
                                            v-model="item.vesselInputType"
                                            :error-messages="
                                                item.vesselInputType === '' ||
                                                item.vesselInputType === null
                                                    ? $t(
                                                          'rules.vessel_type_missing'
                                                      )
                                                    : ''
                                            "
                                            :items="type"
                                            @change="
                                                updateVesselInputValueChoices(
                                                    item,
                                                    i
                                                )
                                            "
                                            :label="$t('trips.vessel_type')"
                                            outlined
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-combobox
                                            :label="
                                                item.vesselInputType
                                                    ? `${getInputTypeText(
                                                          item.vesselInputType
                                                      )}` + ' '
                                                    : $t('trips.vessel_no')
                                            "
                                            v-model="item.vesselInputValue"
                                            :items="
                                                vesselInputValueChoices.length >
                                                i
                                                    ? vesselInputValueChoices[i]
                                                    : []
                                            "
                                            :no-data-text="
                                                $t('loading.no_available')
                                            "
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            :disabled="
                                                !(
                                                    item.vesselInputType !==
                                                        null &&
                                                    item.vesselInputType
                                                        .length > 0
                                                )
                                            "
                                            :error-messages="
                                                item.vesselInputValue === '' ||
                                                item.vesselInputValue === null
                                                    ? 'Vessel input is missing'
                                                    : ''
                                            "
                                            clearable
                                            outlined
                                            dense
                                        ></v-combobox>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-text-field
                                            v-model="item.voyage"
                                            :error-messages="
                                                item.voyage
                                                    ? ''
                                                    : 'Voyage is missing'
                                            "
                                            dense
                                            :label="$t('trips.voyage')"
                                            outlined
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="
                            item.sequence > 0 &&
                                item.sequence < tripSeaTransport.length - 1
                        "
                        :color="disabled ? 'primary' : 'white'"
                        :class="!disabled ? 'pb-0' : ''"
                        :small="disabled"
                    >
                        <template v-slot:icon>
                            <v-btn
                                v-if="!disabled"
                                icon
                                small
                                rounded
                                outlined
                                color="error"
                                @click="remove(i)"
                            >
                                <v-icon>
                                    delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-row no-gutters v-if="disabled">
                                    <v-col cols="12">
                                        <v-card flat>
                                            <v-row dense class="px-4">
                                                <v-col>
                                                    <div
                                                        class="font-weight-bold"
                                                    >
                                                        {{
                                                            $t(
                                                                "trips.port_type"
                                                            )
                                                        }}
                                                    </div>
                                                    <div>
                                                        {{
                                                            item.portTransportType
                                                        }}
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    <div
                                                        class="font-weight-bold"
                                                    >
                                                        {{
                                                            $t(
                                                                "trips.port_code"
                                                            )
                                                        }}
                                                    </div>
                                                    <div>
                                                        {{
                                                            item.portCode
                                                                ? item.portCode
                                                                : "-"
                                                        }}
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        item.portTransportType ===
                                                            'T/S' &&
                                                            item.actualDepartureDate
                                                    "
                                                >
                                                    <div
                                                        class="font-weight-bold"
                                                    >
                                                        ATD
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <v-icon
                                                                    color="primary"
                                                                    dark
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                >
                                                                    {{
                                                                        iconSource(
                                                                            item.actualDepartureDateSource
                                                                        )
                                                                    }}
                                                                </v-icon>
                                                            </template>
                                                            <span class="text-capitalize">{{
                                                                convertUnderscore(
                                                                    item.actualDepartureDateSource
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            item.actualDepartureDate
                                                        "
                                                    >
                                                        <!-- {{
                                                            item.actualDepartureDate
                                                                | moment(
                                                                    "YYYY-MM-DD"
                                                                )
                                                        }} -->
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <span
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-row no-gutters>
                                                                        <v-col>
                                                                            {{
                                                                                item.actualDepartureDate
                                                                                    | moment(
                                                                                        "YYYY-MM-DD"
                                                                                    )
                                                                            }}
                                                                        </v-col>
                                                                        <v-col v-if="item.originalEstimatedDepartureDate || item.estimatedDepartureDate">
                                                                            <div :class="getDelay(item,'etd').color">
                                                                                <span>{{ getDelay(item, 'etd').text }}</span>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </span>
                                                            </template>
                                                            <span
                                                                >Original ETD:
                                                                {{
                                                                    item.originalEstimatedDepartureDate
                                                                        | moment(
                                                                            "YYYY-MM-DD"
                                                                        )
                                                                }}</span
                                                            >
                                                        </v-tooltip>
                                                    </div>
                                                </v-col>
                                                <v-col v-else>
                                                    <div
                                                        class="font-weight-bold"
                                                    >
                                                        ETD
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <v-icon
                                                                    color="primary"
                                                                    dark
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                >
                                                                    {{
                                                                        iconSource(
                                                                            item.estimatedDepartureDateSource
                                                                        )
                                                                    }}
                                                                </v-icon>
                                                            </template>
                                                            <span class="text-capitalize">{{
                                                                convertUnderscore(
                                                                    item.estimatedDepartureDateSource
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            item.estimatedDepartureDate &&
                                                                item.originalEstimatedDepartureDate
                                                        "
                                                    >
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <span
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                >
                                                                    <v-row
                                                                        no-gutters
                                                                    >
                                                                        <v-col>
                                                                            {{
                                                                                item.estimatedDepartureDate
                                                                                    | moment(
                                                                                        "YYYY-MM-DD"
                                                                                    )
                                                                            }}
                                                                        </v-col>
                                                                        <v-col>
                                                                            <div :class="getDelay(item,'etd').color">
                                                                                <span>{{ getDelay(item, 'etd').text }}</span>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </span>
                                                            </template>
                                                            <span
                                                                >Original ETD:
                                                                {{
                                                                    item.originalEstimatedDepartureDate
                                                                        | moment(
                                                                            "YYYY-MM-DD"
                                                                        )
                                                                }}</span
                                                            >
                                                        </v-tooltip>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            item.estimatedDepartureDate &&
                                                                (item.originalEstimatedDepartureDate ===
                                                                    null ||
                                                                    item.originalEstimatedDepartureDate ===
                                                                        undefined)
                                                        "
                                                    >
                                                        {{
                                                            item.estimatedDepartureDate
                                                                | moment(
                                                                    "YYYY-MM-DD"
                                                                )
                                                        }}
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            item.originalEstimatedDepartureDate &&
                                                                (item.estimatedDepartureDate ===
                                                                    null ||
                                                                    item.estimatedDepartureDate ===
                                                                        undefined)
                                                        "
                                                    >
                                                        {{
                                                            item.originalEstimatedDepartureDate
                                                                | moment(
                                                                    "YYYY-MM-DD"
                                                                )
                                                        }}
                                                    </div>
                                                    <div v-else>
                                                        -
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    <div
                                                        class="font-weight-bold"
                                                    >
                                                        {{
                                                            item.vesselInputType
                                                                ? getInputTypeText(
                                                                      item.vesselInputType
                                                                  )
                                                                : "Vessel Type"
                                                        }}
                                                    </div>
                                                    <div>
                                                        {{
                                                            item.vesselInputValue
                                                                ? item.vesselInputValue
                                                                : "-"
                                                        }}
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    <div
                                                        class="font-weight-bold"
                                                    >
                                                        {{ $t("trips.voyage") }}
                                                    </div>
                                                    <div>
                                                        {{
                                                            item.voyage
                                                                ? item.voyage
                                                                : "-"
                                                        }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row dense v-else>
                                    <v-col cols="12" class="pb-0">
                                        <v-row dense>
                                            <v-col
                                                cols="6"
                                                sm="6"
                                                md="3"
                                                lg="3"
                                            >
                                                <v-text-field
                                                    dense
                                                    :label="
                                                        $t('trips.port_type')
                                                    "
                                                    v-model="
                                                        item.portTransportType
                                                    "
                                                    outlined
                                                    filled
                                                    readonly
                                                    hide-details
                                                />
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                sm="6"
                                                md="3"
                                                lg="3"
                                            >
                                                <DateComponent
                                                    v-model="
                                                        item.originalEstimatedDepartureDate
                                                    "
                                                    :label="
                                                        $t('trips.original_etd')
                                                    "
                                                    :rules="[]"
                                                    :outlined="true"
                                                    :dense="true"
                                                >
                                                </DateComponent>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                sm="6"
                                                md="3"
                                                lg="3"
                                            >
                                                <DateComponent
                                                    v-model="
                                                        item.estimatedDepartureDate
                                                    "
                                                    label="ETD"
                                                    :rules="[]"
                                                    :outlined="true"
                                                    :dense="true"
                                                >
                                                    <!-- :iconToolTip="item.originalEstimatedDepartureDate? covertDate(item.originalEstimatedDepartureDate,'Original ETD:') : null" -->
                                                </DateComponent>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                sm="6"
                                                md="3"
                                                lg="3"
                                            >
                                                <DateComponent
                                                    v-model="
                                                        item.actualDepartureDate
                                                    "
                                                    label="ATD"
                                                    :rules="[]"
                                                    :outlined="true"
                                                    :dense="true"
                                                >
                                                    <!-- :iconToolTip="item.originalEstimatedDepartureDate? covertDate(item.originalEstimatedDepartureDate,'Original ETD:') : null" -->
                                                </DateComponent>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                sm="6"
                                                md="3"
                                                lg="3"
                                            >
                                                <v-combobox
                                                    v-model="item.portCode"
                                                    :items="seaPorts.edges"
                                                    :no-data-text="
                                                        $t(
                                                            'loading.no_available'
                                                        )
                                                    "
                                                    :error-messages="
                                                        item.portCode === null
                                                            ? 'Port Code is missing'
                                                            : ''
                                                    "
                                                    :item-value="
                                                        item => item.node.code
                                                    "
                                                    :item-text="
                                                        item => item.node.code
                                                    "
                                                    :loading="
                                                        $apollo.queries.seaPorts
                                                            .loading
                                                    "
                                                    :label="
                                                        $t('trips.port_code')
                                                    "
                                                    :search-input.sync="
                                                        item.portCode
                                                    "
                                                    :return-object="false"
                                                    clearable
                                                    outlined
                                                    dense
                                                    ><template
                                                        v-slot:item="{ item }"
                                                    >
                                                        <v-row
                                                            class="ma-0 body-2"
                                                        >
                                                            <v-col
                                                                cols="4"
                                                                class="text-truncate pl-0 ml-0"
                                                            >
                                                                {{
                                                                    item.node
                                                                        .code
                                                                }}
                                                            </v-col>
                                                            <v-col
                                                                cols="8"
                                                                class="d-flex justify-end"
                                                            >
                                                                <span
                                                                    class="grey--text body-2 mr-1"
                                                                    ><i>{{
                                                                        item
                                                                            .node
                                                                            .name
                                                                    }}</i></span
                                                                >
                                                            </v-col>
                                                        </v-row>
                                                    </template></v-combobox
                                                >
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                sm="6"
                                                md="3"
                                                lg="3"
                                            >
                                                <v-select
                                                    dense
                                                    :error-messages="
                                                        item.vesselInputType
                                                            ? ''
                                                            : $t(
                                                                  'rules.vessel_type_missing'
                                                              )
                                                    "
                                                    v-model="
                                                        item.vesselInputType
                                                    "
                                                    :items="type"
                                                    @change="
                                                        updateVesselInputValueChoices(
                                                            item,
                                                            i
                                                        )
                                                    "
                                                    :label="
                                                        $t('trips.vessel_type')
                                                    "
                                                    outlined
                                                    persistent-hint
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                sm="6"
                                                md="3"
                                                lg="3"
                                            >
                                                <v-combobox
                                                    :label="
                                                        item.vesselInputType
                                                            ? `${getInputTypeText(
                                                                  item.vesselInputType
                                                              )}` + ' '
                                                            : $t(
                                                                  'trips.vessel_no'
                                                              )
                                                    "
                                                    v-model="
                                                        item.vesselInputValue
                                                    "
                                                    :items="
                                                        vesselInputValueChoices.length >
                                                        i
                                                            ? vesselInputValueChoices[
                                                                  i
                                                              ]
                                                            : []
                                                    "
                                                    :no-data-text="
                                                        $t(
                                                            'loading.no_available'
                                                        )
                                                    "
                                                    :loading="
                                                        $apollo.queries.seaPorts
                                                            .loading
                                                    "
                                                    :disabled="
                                                        !(
                                                            item.vesselInputType !==
                                                                null &&
                                                            item.vesselInputType
                                                                .length > 0
                                                        )
                                                    "
                                                    :error-messages="
                                                        item.vesselInputValue
                                                            ? ''
                                                            : 'Vessel input is missing'
                                                    "
                                                    clearable
                                                    outlined
                                                    dense
                                                ></v-combobox>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                sm="6"
                                                md="3"
                                                lg="3"
                                            >
                                                <v-text-field
                                                    v-model="item.voyage"
                                                    :error-messages="
                                                        item.voyage
                                                            ? ''
                                                            : 'Voyage is missing'
                                                    "
                                                    dense
                                                    :label="$t('trips.voyage')"
                                                    outlined
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item
                        small
                        v-if="item.portTransportType === 'POD' && !disabled"
                        class="pb-6"
                    >
                        <template v-slot:icon>
                            <v-btn
                                icon
                                small
                                rounded
                                @click="add()"
                                class="primary"
                                :disabled="disabled"
                            >
                                <v-icon color="white">add</v-icon>
                            </v-btn>
                        </template>
                    </v-timeline-item>
                    <v-timeline-item
                        small
                        v-if="item.portTransportType === 'POD'"
                    >
                        <v-row no-gutters v-if="disabled">
                            <v-col cols="12">
                                <v-card flat>
                                    <v-row dense class="px-4">
                                        <v-col>
                                            <div class="font-weight-bold">
                                                {{ $t("trips.port_type") }}
                                            </div>
                                            <div>
                                                {{ item.portTransportType }}
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="font-weight-bold">
                                                {{ $t("trips.port_code") }}
                                            </div>
                                            <div>
                                                {{
                                                    item.portCode
                                                        ? item.portCode
                                                        : "-"
                                                }}
                                            </div>
                                        </v-col>
                                        <v-col
                                            v-if="
                                                item.portTransportType ===
                                                    'POD' &&
                                                    item.actualArrivalDate
                                            "
                                        >
                                            <div class="font-weight-bold">
                                                ATA
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            color="primary"
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{
                                                                iconSource(
                                                                    item.actualArrivalDateSource
                                                                )
                                                            }}
                                                        </v-icon>
                                                    </template>
                                                    <span class="text-capitalize">{{
                                                        convertUnderscore(
                                                            item.actualArrivalDateSource
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </div>
                                            <div v-if="item.actualArrivalDate">
                                                <!-- {{
                                                    item.actualArrivalDate
                                                        | moment("YYYY-MM-DD")
                                                }} -->
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <span
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    {{
                                                                        item.estimatedArrivalDate
                                                                            | moment(
                                                                                "YYYY-MM-DD"
                                                                            )
                                                                    }}
                                                                </v-col>
                                                                <v-col>
                                                                    <div :class="getDelay(item,'eta').color">
                                                                        <span>{{ getDelay(item, 'eta').text }}</span>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </span>
                                                    </template>
                                                    <span
                                                        >Original ETA:
                                                        {{
                                                            item.originalEstimatedArrivalDate
                                                                | moment(
                                                                    "YYYY-MM-DD"
                                                                )
                                                        }}</span
                                                    >
                                                </v-tooltip>
                                            </div>
                                        </v-col>
                                        <v-col v-else>
                                            <div class="font-weight-bold">
                                                ETA
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            color="primary"
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{
                                                                iconSource(
                                                                    item.estimatedArrivalDateSource
                                                                )
                                                            }}
                                                        </v-icon>
                                                    </template>
                                                    <span class="text-capitalize">{{
                                                        convertUnderscore(
                                                            item.estimatedArrivalDateSource
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </div>
                                            <div
                                                v-if="
                                                    item.originalEstimatedArrivalDate &&
                                                        item.estimatedArrivalDate
                                                "
                                            >
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <span
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    {{
                                                                        item.estimatedArrivalDate
                                                                            | moment(
                                                                                "YYYY-MM-DD"
                                                                            )
                                                                    }}
                                                                </v-col>
                                                                <v-col>
                                                                    <div :class="getDelay(item,'eta').color">
                                                                        <span>{{ getDelay(item, 'eta').text }}</span>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </span>
                                                    </template>
                                                    <span
                                                        >Original ETA:
                                                        {{
                                                            item.originalEstimatedArrivalDate
                                                                | moment(
                                                                    "YYYY-MM-DD"
                                                                )
                                                        }}</span
                                                    >
                                                </v-tooltip>
                                            </div>
                                            <div
                                                v-else-if="
                                                    item.estimatedArrivalDate &&
                                                        (item.originalEstimatedArrivalDate ===
                                                            null ||
                                                            item.originalEstimatedArrivalDate ===
                                                                undefined)
                                                "
                                            >
                                                {{
                                                    item.estimatedArrivalDate
                                                        | moment("YYYY-MM-DD")
                                                }}
                                            </div>
                                            <div
                                                v-else-if="
                                                    item.originalEstimatedArrivalDate &&
                                                        (item.estimatedArrivalDate ===
                                                            null ||
                                                            item.estimatedArrivalDate ===
                                                                undefined)
                                                "
                                            >
                                                {{
                                                    item.originalEstimatedArrivalDate
                                                        | moment("YYYY-MM-DD")
                                                }}
                                            </div>
                                            <div v-else>
                                                -
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="font-weight-bold">
                                                {{
                                                    item.vesselInputType
                                                        ? getInputTypeText(
                                                              item.vesselInputType
                                                          )
                                                        : $t(
                                                              "trips.vessel_type"
                                                          )
                                                }}
                                            </div>
                                            <div>
                                                {{
                                                    item.vesselInputValue
                                                        ? item.vesselInputValue
                                                        : "-"
                                                }}
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="font-weight-bold">
                                                {{ $t("trips.voyage") }}
                                            </div>
                                            <div>
                                                {{
                                                    item.voyage
                                                        ? item.voyage
                                                        : "-"
                                                }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row dense v-else>
                            <v-col cols="12" class="py-0">
                                <v-row dense>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-text-field
                                            dense
                                            :label="$t('trips.port_type')"
                                            v-model="item.portTransportType"
                                            outlined
                                            filled
                                            readonly
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <DateComponent
                                            v-model="
                                                item.originalEstimatedArrivalDate
                                            "
                                            label="Original ETA*"
                                            :rules="[]"
                                            :outlined="true"
                                            :dense="true"
                                        ></DateComponent>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <DateComponent
                                            v-model="item.estimatedArrivalDate"
                                            label="ETA"
                                            :rules="[]"
                                            :outlined="true"
                                            :dense="true"
                                        ></DateComponent>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <DateComponent
                                            v-model="item.actualArrivalDate"
                                            label="ATA"
                                            :rules="[]"
                                            :outlined="true"
                                            :dense="true"
                                        ></DateComponent>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-combobox
                                            v-model="item.portCode"
                                            :error-messages="
                                                item.portCode === null ||
                                                item.portCode === undefined
                                                    ? 'Port Code is missing'
                                                    : ''
                                            "
                                            :items="seaPorts.edges"
                                            :no-data-text="
                                                $t('loading.no_available')
                                            "
                                            :item-value="item => item.node.code"
                                            :item-text="item => item.node.code"
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            :label="$t('trips.port_code')"
                                            :search-input.sync="item.portCode"
                                            :return-object="false"
                                            clearable
                                            outlined
                                            dense
                                            ><template v-slot:item="{ item }">
                                                <v-row class="ma-0 body-2">
                                                    <v-col
                                                        cols="4"
                                                        class="text-truncate pl-0 ml-0"
                                                    >
                                                        {{ item.node.code }}
                                                    </v-col>
                                                    <v-col
                                                        cols="8"
                                                        class="d-flex justify-end"
                                                    >
                                                        <span
                                                            class="grey--text body-2 mr-1"
                                                            ><i>{{
                                                                item.node.name
                                                            }}</i></span
                                                        >
                                                    </v-col>
                                                </v-row>
                                            </template></v-combobox
                                        >
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-select
                                            dense
                                            v-model="item.vesselInputType"
                                            :items="type"
                                            @change="
                                                updateVesselInputValueChoices(
                                                    item,
                                                    i
                                                )
                                            "
                                            :label="$t('trips.vessel_input')"
                                            outlined
                                            disabled
                                            filled
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-combobox
                                            :label="
                                                item.vesselInputType
                                                    ? `${getInputTypeText(
                                                          item.vesselInputType
                                                      )}` + ' '
                                                    : $t('trips.vessel_no')
                                            "
                                            v-model="item.vesselInputValue"
                                            :items="
                                                vesselInputValueChoices.length >
                                                i
                                                    ? vesselInputValueChoices[i]
                                                    : []
                                            "
                                            :no-data-text="
                                                $t('loading.no_available')
                                            "
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            clearable
                                            outlined
                                            disabled
                                            filled
                                            dense
                                            hide-details
                                        ></v-combobox>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="3">
                                        <v-text-field
                                            v-model="item.voyage"
                                            dense
                                            disabled
                                            :label="$t('trips.voyage')"
                                            outlined
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </div>
            </v-form>
        </v-timeline>
    </v-container>
</template>
<script>
import DateComponent from "@/components/base/DateComponent.vue";
import moment from "moment";
import gql from "graphql-tag";
import helper from "@/utils/helper";

export default {
    components: {
        DateComponent
    },
    props: {
        tripSeaTransport: {
            type: Array
        },
        disabled: {
            type: Boolean
        }
    },
    apollo: {
        vessels: {
            query() {
                return gql`
                    query Vessels($orderBy: String) {
                        vessels(orderBy: $orderBy) {
                            edges {
                                node {
                                    callsign
                                    id
                                    imo
                                    name
                                    mmsi
                                }
                            }
                        }
                    }
                `;
            },
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update(data) {
                return data.vessels;
            }
        },
        seaPorts: {
            query() {
                return gql`
                    query SeaPorts {
                        seaPorts {
                            edges {
                                node {
                                    country
                                    code
                                    id
                                    name
                                }
                            }
                        }
                    }
                `;
            },
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update(data) {
                return data.seaPorts;
            }
        }
    },
    data() {
        return {
            validate: false,
            type: [
                { text: this.$t("trips.vessel_name"), value: "shipname" },
                { text: this.$t("trips.call_sign"), value: "callsign" },
                { text: "IMO", value: "imo" },
                { text: "MMSI", value: "mmsi" }
            ],
            ldBtn: false,
            saved: false,
            voyage: "",
            vesselInputType: "",
            vesselInputValue: "",
            days: 0,
            searchPortCode: "",
            seaPorts: { edges: [] },
            vessels: { edges: [] },
            vesselInputValueChoices: [],
            hasMissingFields: false
        };
    },
    computed: {
        lastItem() {
            let last = this.tripSeaTransport.length - 1;
            return last;
        }
    },
    created() {
        this.calculateDelays = helper.calculateDelays;
    },
    mounted() {
        this.areFieldsMissing(this.tripSeaTransport);
    },
    watch: {},
    methods: {
        getDelay(item, type){
            let actual = item?.actualArrivalDate;
            let offset = null;
            if(type === "eta"){
                offset = this.calculateDelays(item?.originalEstimatedArrivalDate, item?.estimatedArrivalDate,item?.actualArrivalDate)
            }else if(type == "etd"){
                actual = item?.actualDepartureDate;
                offset = this.calculateDelays(item?.originalEstimatedDepartureDate, item?.estimatedDepartureDate,item?.actualDepartureDate)
            }
                
            if(!offset){
               return {
                    offset: null,
                    event: "",
                    color: "",
                    text: ""
                }
            }
            let event = offset < 0 && !actual ? this.$t("date.to_go") :
            offset < 0 && actual ? this.$t("date.earlier") : this.$t("date.late");

            let color = offset > 0 ? "error--text" : "success--text";
            let text = offset == 0 ? '' : `${Math.abs(offset)} ${this.$t("date.days2")} ${event}`;
            return{
                offset: offset,
                event: event,
                color: color,
                text: text
            }
        },
        areFieldsMissing(tripSeaTransport) {
            let missingFields = false;
            missingFields = tripSeaTransport.some(
                x =>
                    x.portCode === null ||
                    x.portCode === undefined ||
                    x.portCode === ""
            );
            if (missingFields === true) {
                this.$emit("updateHasMissingFields", missingFields);
            } else {
                missingFields = tripSeaTransport.some(
                    x =>
                        x.portTransportType !== "POD" &&
                        (x.vesselInputType === null ||
                            x.vesselInputType === undefined ||
                            x.vesselInputType === "" ||
                            x.vesselInputValue === null ||
                            x.vesselInputValue === undefined ||
                            x.vesselInputValue === "")
                );
                this.$emit("updateHasMissingFields", missingFields);
            }
        },
        add() {
            //let _seaTransport = JSON.parse(JSON.stringify(this.tripSeaTransport))
            let _seaTransport = [...this.tripSeaTransport];

            _seaTransport.splice(-1, 0, {
                sequence: null,
                id: null,
                voyage: "",
                portCode: "",
                portTransportType: "T/S",
                vesselInputType: "",
                vesselInputValue: "",
                originalEstimatedDepartureDate: null,
                estimatedDepartureDate: "",
                actualDepartureDate: null
            });

            _seaTransport.forEach((element, i) => (element.sequence = i));

            this.$emit("update:tripSeaTransport", _seaTransport);
        },
        remove(index) {
            let _seaTransport = [...this.tripSeaTransport];

            _seaTransport.splice(index, 1);

            _seaTransport.forEach((element, i) => (element.sequence = i));

            this.$emit("update:tripSeaTransport", _seaTransport);
        },
        resetForm() {
            this.$refs.seaForm.reset();
        },
        getDays(start, end) {
            let Difference_In_Days = null;
            if (
                start === null ||
                end === null ||
                start === undefined ||
                end === undefined ||
                start.length === 0 ||
                end.length === 0
            ) {
                Difference_In_Days = null;
            } else {
                let startDate = new Date(start);
                let endDate = new Date(end);
                let Difference_In_Time =
                    endDate.getTime() - startDate.getTime();
                Difference_In_Days = Math.round(
                    Difference_In_Time / (1000 * 3600 * 24)
                );
            }

            return Difference_In_Days.toFixed(0);
        },
        covertDate(value, text) {
            let result = null;

            if (value === null || value === undefined || value.length === 0) {
                result = null;
            } else {
                let date = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
                result = text + " " + date;
            }

            return result;
        },
        getInputTypeText(value) {
            let result = null;
            if (value === null || value === undefined || value.length === 0) {
                result = "";
            } else {
                let type = this.type.find(type => type.value === value);
                result = type?.text;
            }

            return result;
        },
        updateVesselInputValueChoices(item, i) {
            let choices = [];
            if (
                item.vesselInputType === null ||
                item.vesselInputType === undefined ||
                item.vesselInputType === ""
            ) {
                choices = [];
            } else if (item.vesselInputType === "shipname") {
                choices = this.vessels.edges.map(vessel => vessel.node.name);
            } else if (item.vesselInputType === "callsign") {
                choices = this.vessels.edges.map(
                    vessel => vessel.node.callsign
                );
            } else if (item.vesselInputType === "imo") {
                choices = this.vessels.edges.map(vessel => vessel.node.imo);
            } else if (item.vesselInputType === "mmsi") {
                choices = this.vessels.edges.map(vessel => vessel.node.mmsi);
            }
            item.vesselInputValue = "";
            this.$set(this.vesselInputValueChoices, i, choices);
        },
        iconSource(sourceType) {
            let icon = null;
            switch (sourceType) {
                case "vessel_voyage":
                    icon = "directions_boat";
                    break;
                case "carrier":
                    icon = "commute";
                    break;
                case "imported":
                    icon = "inventory_2";
                    break;
                case "vessel_port_calls":
                    icon = "anchor";
                    break;
                case "user":
                    icon = "person";
                    break;
            }
            return icon;
        },
        convertUnderscore(name) {
            if (name === null || name === undefined || name.length === 0) {
                return null;
            } else {
                return name.replace(/_/g, " ");
            }
        }
    }
};
</script>
<style scoped>
.v-timeline::before {
    top: 55px;
    height: calc(100% - 110px);
}
</style>
