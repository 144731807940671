import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"elevation":"5","outlined":""}},[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('left')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_back")])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('right')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_forward")])],1)],1),_c('FilterMenu',{attrs:{"choices":_vm.headerChoices,"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display'),"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1),_c(VDataTable,{staticClass:"row-pointer",attrs:{"headers":_vm.dynamicHeaders,"items":_vm.tableItems,"item-key":"id","loading-text":_vm.$t('loading.loading'),"loading":_vm.$apollo.queries.tripTemplates.loading,"hide-default-footer":"","disable-pagination":"","show-select":_vm.selectMode,"disable-sort":"","loader-height":"2","mobile-breakpoint":_vm.screenSize === 'xs' || _vm.screenSize === 'sm' ? 800 : 0,"no-data-text":_vm.$t('loading.no_data'),"fixed-header":"","height":_vm.tableHeight},on:{"click:row":function (item) { return _vm.editTemplate(item); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.origin",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.origin.name))])])]}},{key:"item.destination",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.destination.name))])])]}},{key:"item.loadPoint",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.loadPoint),function(lp){return _c(VChip,{key:lp,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(_vm._s(lp))])}),1)]}},{key:"item.transportMode",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.transportMode ? item.transportMode.toUpperCase() : "")+" ")])]}},{key:"item.carrier",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.carrier.id)?_c(VChip,{staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.carrier.name)+" ")]):_vm._e()],1)]}},{key:"item.partners",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.partners),function(partner){return _c(VChip,{key:partner,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(partner)+" ")])}),1)]}},{key:"item.goods",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.goods),function(good){return _c(VChip,{key:good,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(good)+" ")])}),1)]}},{key:"item.terms",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.terms),function(term){return _c(VChip,{key:term,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(term)+" ")])}),1)]}},{key:"item.alertRules",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.alertRules),function(alertRule){return _c(VChip,{key:alertRule,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(alertRule)+" ")])}),1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(_vm._s(_vm.$t('loading.no_data')))])]),_vm._v("\"\" "),_c('template',{slot:"body.append"},[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.dynamicHeaders.length}},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.isLoading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t('loading.load_more'))+" ")]):_vm._e()],1)])])],2)],1)],1),_c('AddTrip',{ref:"addTrip",attrs:{"templateMode":true,"editMode":true,"openDialog":_vm.openEditDialog,"tripObj":_vm.templatetoEdit},on:{"template-edited":_vm.refreshTable,"close-dialog":function($event){_vm.openEditDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }