<template>
    <v-container fluid class="pa-0">
        <v-row no-gutters>
            <v-col cols="12">
                <v-autocomplete
                    v-model="selectedUsers"
                    :items="tenantUsers.edges"
                    :loading="$apollo.queries.tenantUsers.loading"
                    :no-data-text="$t('loading.no_available')"
                    :label="$t('headers.select_user') + '(s)'"
                    outlined
                    deletable-chips
                    small-chips
                    item-text="node.user.fullName"
                    item-value="node.user.id"
                    multiple
                    :menu-props="{
                        height: '200px',
                        offsetY: true
                    }"
                >
                    <template v-slot:item="data">
                        <v-row class="ma-0  caption">
                            <v-col cols="12" class="text-truncate pl-0 ml-0">
                                {{ data.item.node.user.fullName }}
                            </v-col>
                        </v-row>
                    </template>
                    <template #append-item>
                        <v-divider class="mt-4 append-divider"> </v-divider>
                        <v-list-item dense class="append py-0">
                            <v-list-item-content>
                                <v-btn color="primary" @click="createNewUser">
                                    {{ $t("partners.add_user1") }}
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <AddUserDialog
            :openAddUserDialog="openCreateNewUserDialog"
            v-on:refreshDataTable="$apollo.queries.tenantUsers.refresh()"
            v-on:user-Added="userCreated"
            v-on:closeAddUserDialog="openCreateNewUserDialog = false"
        ></AddUserDialog>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import AddUserDialog from "@/components/users/create/AddUserDialog.vue";

export default {
    name: "AddGroupUserForm",
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    components: { AddUserDialog },
    apollo: {
        tenantUsers: {
            query: gql`
                query TenantUsers($tenantId: ID) {
                    tenantUsers(tenantId: $tenantId) {
                        edges {
                            node {
                                id
                                user {
                                    id
                                    fullName
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.me.tenant.id
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUsers;
            },
            skip: false
        }
    },
    data() {
        return {
            /* selectedUsers: [], */
            tenantUsers: { edges: [] },
            firstName: null,
            lastName: null,
            email: null,
            requires2fa: false,
            isLoadingAddGroupUser: false,
            openCreateNewUserDialog: false,
            userAdded: {},
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v => /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v => (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        selectedUsers: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    methods: {
        createNewUser() {
            this.openCreateNewUserDialog = true;
        },
        userCreated(user) {
            this.selectedUsers.push(user.user.id);
            this.openCreateNewUserDialog = false;
        }
    }
};
</script>
<style scoped>
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
</style>
