import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"mt-5 mb-10 px-5"},[_c('div',[_c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"flat":"","outlined":"","loading":_vm.loadingMarkers ||
                    _vm.$apollo.queries.tenantPartnersLocations.loading ||
                    _vm.$apollo.queries.tenantLocations.loading ||
                    _vm.$apollo.queries.tripAlerts.loading ||
                    _vm.loadingRoutes}},[(
                    _vm.loadingMarkers ||
                        _vm.$apollo.queries.tenantPartnersLocations.loading ||
                        _vm.$apollo.queries.tenantLocations.loading ||
                        _vm.$apollo.queries.tripAlerts.loading ||
                        _vm.loadingRoutes
                )?_c(VProgressLinear,{staticClass:"mb-0",staticStyle:{"z-index":"800"},attrs:{"dense":"","indeterminate":"","color":"primary","height":3}}):_vm._e(),(!_vm.noDataTracker)?_c(VToolbar,{staticStyle:{"z-index":"800","background-color":"transparent"},attrs:{"elevation":"0","dense":"","absolute":""}},[_c('div',[_c(VMenu,{attrs:{"close-on-content-click":false,"open-on-hover":"","offset-x":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","loading":_vm.loadingMarkers ||
                                        _vm.$apollo.queries
                                            .tenantPartnersLocations
                                            .loading ||
                                        _vm.$apollo.queries.tenantLocations
                                            .loading ||
                                        _vm.loadingRoutes}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("home.trackers"))+" ")])]}}],null,false,4156877698),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"dense":"","rounded":""}},[_c(VList,_vm._l((this
                                        .tripFreightTrackers.edges),function(tracker){return _c(VListItem,{key:tracker.node.tracker.id},[_c(VListItemAction,[_c(VSwitch,{attrs:{"color":_vm.colors[
                                                    _vm.getIndex(
                                                        _vm.tripFreightTrackers.edges.indexOf(
                                                            tracker
                                                        )
                                                    )
                                                ],"value":tracker.node.tracker.id},model:{value:(_vm.selectedTrackers),callback:function ($$v) {_vm.selectedTrackers=$$v},expression:"selectedTrackers"}})],1),_c(VListItemTitle,[_vm._v(_vm._s(tracker.node.tracker.serialNumber))])],1)}),1)],1)],1)],1)]):_vm._e(),(!_vm.canAccess)?_c(VOverlay,{staticStyle:{"z-index":"800"},attrs:{"absolute":"","opacity":"1","color":"primary"}},[_c(VAlert,{attrs:{"dense":"","color":"grey","type":"warning"}},[_vm._v("No permission to look at this trip")])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center",style:({
                    height: _vm.mapHeight
                }),attrs:{"id":"map"}},[(
                        _vm.noDataTracker &&
                            _vm.noDataVessel &&
                            !_vm.$apollo.queries.tripFreightTrackers.loading &&
                            _vm.canAccess
                    )?_c(VAlert,{staticClass:"mt-3",staticStyle:{"z-index":"800"},attrs:{"max-width":"300px","max-height":"40px","dense":"","color":"grey","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("loading.no_trip")))]):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }