<template>
    <v-container fluid class="pa-0" style="background-color: transparent;">
        <v-form ref="form">
            <v-expansion-panels flat class="rounded-lg">
                <v-expansion-panel>
                    <v-expansion-panel-header class="contentDisplay">
                        <div class="d-flex flex-column px-4">
                            <div class="textTitle">
                                {{ $t("trips.advanced_info") }}
                            </div>
                            <span class="textBody">{{ $t("general.advance_info_warning") }}</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row dense class="mb-2">
                            <v-col class="contentDisplay" cols="12" xs="12" sm="12" md="" lg="" xl="">
                                <div class="px-4">
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("home.forwarders") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete
                                        v-model="partners"
                                        :loading="$apollo.queries.tenantPartners.loading"
                                        :items="forwarder"
                                        :no-data-text="$t('loading.no_available')"
                                        class="inputContent"
                                        outlined
                                        deletable-chips
                                        small-chips
                                        item-text="node.name"
                                        item-value="node.id"
                                        multiple
                                        dense
                                        :menu-props="{
                                            maxHeight: 150
                                        }"
                                    >
                                        <template v-slot:item="data">
                                            <v-row class="ma-0 body-2">
                                                <v-col cols="12" class="text-truncate pl-0 ml-0">
                                                    {{ data.item.node.name }}
                                                </v-col>
                                                <!-- <v-col cols="2">
                                                    <span class="grey--text mr-1"><i>{{data.item.node.partnerType}}</i></span> 
                                                </v-col> -->
                                            </v-row>
                                        </template>
                                        <template #append-item>
                                            <v-divider class="mt-4 append-divider"> </v-divider>
                                            <v-list-item dense class="append py-0">
                                                <v-list-item-content>
                                                    <v-btn
                                                        color="primary mt-4"
                                                        block
                                                        @click="createNewPartner('forwarder')"
                                                    >
                                                        {{ $t("general.add_forwarder") }}
                                                    </v-btn>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("home.others") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete
                                        v-model="partners"
                                        :loading="$apollo.queries.tenantPartners.loading"
                                        :items="other"
                                        :no-data-text="$t('loading.no_available')"
                                        class="inputContent"
                                        outlined
                                        deletable-chips
                                        small-chips
                                        item-text="node.name"
                                        item-value="node.id"
                                        multiple
                                        :dense="dense"
                                        :menu-props="{
                                            maxHeight: 150
                                        }"
                                    >
                                        <template v-slot:item="data">
                                            <v-row class="ma-0 body-2">
                                                <v-col cols="12" class="text-truncate pl-0 ml-0">
                                                    {{ data.item.node.name }}
                                                </v-col>
                                                <!--  <v-col cols="2">
                                                    <span class="grey--text mr-1"><i>{{data.item.node.partnerType}}</i></span> 
                                                </v-col> -->
                                            </v-row>
                                        </template>
                                        <template #append-item>
                                            <v-divider class="mt-4 append-divider"> </v-divider>
                                            <v-list-item dense class="append py-0">
                                                <v-list-item-content>
                                                    <v-btn
                                                        color="primary"
                                                        class="mt-4"
                                                        block
                                                        @click="createNewPartner('other')"
                                                    >
                                                        {{ $t("general.add_other") }}
                                                    </v-btn>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </div>
                            </v-col>
                            <v-card
                                v-if="!isSmallScreen"
                                class="d-flex justify-center align-center"
                                width="20px"
                                flat
                                style="
                                    background-color: transparent;
                                "
                            >
                            </v-card>
                            <v-col class="contentDisplay" cols="12" xs="12" sm="12" md="" lg="" xl="">
                                <div class="px-4">
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("home.consignors") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete
                                        v-model="partners"
                                        :loading="$apollo.queries.tenantPartners.loading"
                                        :items="consignor"
                                        :no-data-text="$t('loading.no_available')"
                                        class="inputContent"
                                        outlined
                                        deletable-chips
                                        small-chips
                                        item-text="node.name"
                                        item-value="node.id"
                                        multiple
                                        :dense="dense"
                                        :menu-props="{
                                            maxHeight: 150
                                        }"
                                    >
                                        <template v-slot:item="data">
                                            <v-row class="ma-0 body-2">
                                                <v-col cols="12" class="text-truncate pl-0 ml-0">
                                                    {{ data.item.node.name }}
                                                </v-col>
                                                <!-- <v-col cols="2">
                                                    <span class="grey--text mr-1"><i>{{data.item.node.partnerType}}</i></span> 
                                                </v-col> -->
                                            </v-row>
                                        </template>
                                        <template #append-item>
                                            <v-divider class="mt-4 append-divider"> </v-divider>
                                            <v-list-item dense class="append py-0">
                                                <v-list-item-content>
                                                    <v-btn
                                                        class="mt-4"
                                                        block
                                                        color="primary"
                                                        @click="createNewPartner('consignor')"
                                                    >
                                                        {{ $t("general.add_consignor") }}
                                                    </v-btn>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>

                                    <slot name="saveTemplateSlot"></slot>
                                </div>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-form>
        <AddPartnerDialog
            :openAddPartnerDialog="openAddPartnerDialog"
            v-on:refreshPartnersTable="newPartner = true"
            :partnerByType="partnerByType"
            v-on:closeAddPartnerDialog="openAddPartnerDialog = false"
            v-on:item-created="onPartnerCreated"
        >
        </AddPartnerDialog>
    </v-container>
</template>

<script>
import gql from "graphql-tag";

import AddPartnerDialog from "@/components/partners/create/AddPartnerDialog.vue";

export default {
    name: "PartnerTable",
    components: { AddPartnerDialog },
    props: {
        partnersOnBoard: {
            type: Array
        },
        getPartners: {
            type: Array
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $referenceNumberIcontains: String
                    $partnerType: String
                    $carrierType: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        referenceNumber_Icontains: $referenceNumberIcontains
                        partnerType: $partnerType
                        carrierType: $carrierType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantPartners,
            result({ loading, data }) {
                if (!loading) {
                    this.listOfPartners = data.tenantPartners.edges.filter(data => {
                        let find = this.partnersOnBoard.find(item => item.node.tenantPartner.id === data.node.id);
                        if (typeof find !== "object") return data;
                    });
                }
            },
            skip: false
        }
    },
    data() {
        return {
            partnerFilter: {
                first: 20
            },
            loading: false,
            listOfPartners: [],
            shouldLoadMore: false,
            isInitialLoad: true,
            tenantPartners: { edges: [] },
            openAddPartnerUser: false,
            partnerByType: "",
            transportMode: "",
            checkbox: false,
            // transportType: [
            //     { text: this.$t("headers.consignor"), value: "consignor" },
            //     { text: this.$t("headers.consignee"), value: "consignee" },
            //     { text: this.$t("headers.forwarder"), value: "forwarder" },
            //     { text: this.$t("headers.carrier"), value: "carrier" },
            //     { text: this.$t("home.other"), value: "other" }
            // ],
            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            // ],
            openAddPartnerDialog: false,
            newPartner: false,
            termChoices: [
                {
                    value: "DAP",
                    text: `DAP (${this.$t("term.dap")})`
                },
                {
                    value: "DAT",
                    text: `DAT (${this.$t("term.dat")})`
                },
                {
                    value: "DDP",
                    text: `DDP (${this.$t("term.ddp")})`
                },
                {
                    value: "DPU",
                    text: `DPU (${this.$t("term.dpu")})`
                },
                {
                    value: "CFR",
                    text: `CFR (${this.$t("term.cfr")})`
                },
                {
                    value: "CIF",
                    text: `CIF (${this.$t("term.cif")})`
                },
                {
                    value: "CIP",
                    text: `CIP (${this.$t("term.cip")})`
                },
                {
                    value: "CPT",
                    text: `CPT (${this.$t("term.cpt")})`
                },
                {
                    value: "EXW",
                    text: `EXW (${this.$t("term.exw")})`
                },
                {
                    value: "FCA",
                    text: `FCA (${this.$t("term.fca")})`
                },
                {
                    value: "FAS",
                    text: `FAS (${this.$t("term.fas")})`
                },
                {
                    value: "FOB",
                    text: `FOB (${this.$t("term.fob")})`
                }
            ]
        };
    },
    watch: {},
    computed: {
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 960;
        },
        hasMoreData() {
            return this.tenantPartners?.pageInfo?.hasNextPage;
        },
        partners: {
            get() {
                return this.getPartners;
            },
            set(value) {
                this.$emit("update:getPartners", value);
            }
        },
        consignee() {
            return this.listOfPartners.filter(
                e => e.node.partnerType === "consignee" || e.node.partnerType === "Consignee"
            );
        },
        consignor() {
            return this.listOfPartners.filter(
                e => e.node.partnerType === "consignor" || e.node.partnerType === "Consignor"
            );
        },
        carrier() {
            return this.listOfPartners.filter(
                e => e.node.partnerType === "carrier" || e.node.partnerType === "Carrier"
            );
        },
        forwarder() {
            return this.listOfPartners.filter(
                e => e.node.partnerType === "forwarder" || e.node.partnerType === "Forwarder"
            );
        },
        other() {
            return this.listOfPartners.filter(e => e.node.partnerType === "other" || e.node.partnerType === "Other");
        },
        transportType: function() {
            let _transportType = [
                { text: this.$t("headers.consignor"), value: "consignor" },
                { text: this.$t("headers.consignee"), value: "consignee" },
                { text: this.$t("headers.forwarder"), value: "forwarder" },
                { text: this.$t("headers.carrier"), value: "carrier" },
                { text: this.$t("home.other"), value: "other" }
            ];
            return _transportType;
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        }
    },
    methods: {
        createNewPartner(type) {
            /* this.openAddPartnerDialog = true;
            this.partnerByType = type; */
            this.$emit("createPartner", {
                path: "/previewcreatepartner",
                query: {
                    type: `create-${type}`,
                    field: this.transportMode
                }
            });
        },
        partnersAdded() {
            this.$emit("addNewPartner", this.partners);
        },
        clearCache() {
            this.partners = [];
        },
        refetchTableData() {
            return this.$apollo.queries.tenantPartners.refetch();
        },
        onPartnerCreated(val) {
            this.refetchTableData();
            this.partners.push(val.tenantPartner.id);
        }
        /*  filterTransportByType(type){
             console.log("this",type)
             this.$apollo.queries.tenantPartners.setVariables({
                 partnerType: type,
                 carrierType:this.transportMode,
             })
             .then(() => {
                 //this.tenantPartners=response.data.tenantPartners.edges.map(e => e.node)  
             })
             .catch((error) => {
                 console.error(error);
                 this.error = error;
             })
             .finally(() => {});  
         },
         filterTransportByMode(mode){
             console.log(this.partnerType,"mode", mode)
             this.$apollo.queries.tenantPartners.setVariables({
                 partnerType:this.partnerType,
                 carrierType:mode,
             })
             .then(() => {
                 //this.tenantPartners=response.data.tenantPartners.edges.map(e => e.node)  
             })
             .catch((error) => {
                 console.error(error);
                 this.error = error;
             })
             .finally(() => {});  
 
         }, */
    }
};
</script>
<style scoped>
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}
.append {
    position: sticky;
    bottom: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
}
.append-divider {
    position: sticky;
}
/* .inputContent {
    height: 52px;
} */
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay2 {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay3 {
    padding-left: 20px;
    padding-right: 20px;
}
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
</style>
