<template>
    <div
        :style="`height: ${heightScreen}px;`"
        class="overflow-y-auto"
    >
        <v-sheet class="pb-2" color="transparent">
            <v-spacer />
            <v-btn small outlined color="primary" @click="expand">
                {{
                    isAllExpanded
                        ? "- " + $t("general.collapse")
                        : "+ " + $t("general.expand")
                }}
            </v-btn>
        </v-sheet>
        <v-expansion-panels v-model="panel" multiple focusable class="mb-5">
            <v-expansion-panel
                v-for="(item, index) in selectedFilters"
                :key="index"
            >
                <v-expansion-panel-header>
                    <h5>{{ item.name }}</h5>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                    <v-lazy>
                        <LocationMap
                            v-if="item.code === 'map'"
                            :location="location"
                            viewType="list"
                            :editLocation.sync="editLocation"
                            :title="title"
                            @refreshDataTable="refreshDataTable"
                        ></LocationMap>
                    </v-lazy>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import LocationMap from "@/components/locations/details/LocationMap.vue";

export default {
    props: {
        location: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            default: "locations"
        }
    },
    components: {
        LocationMap,
    },
    data() {
        return {
            panel: [0],
            editLocation: false
        };
    },
    computed: {
        isAllExpanded() {
            return this.panel.length === this.selectedFilters.length;
        },
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 174  ;
            return  _break;
        }
    },
    watch: {},
    mounted() {},
    methods: {
        refreshDataTable() {
            this.$emit("refreshDataTable");
        },
        expand() {
            if (this.isAllExpanded) {
                this.panel = [];
            } else {
                this.panel = [
                    ...this.selectedFilters.map((item, index) => index)
                ];
            }
        },
        editMethod(){
            this.editLocation = true;
        }
    }
};
</script>

<style scoped lang="scss">
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>
