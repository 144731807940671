<template>
    <v-card class="" :flat="flat" :outlined="outlined">
        <v-lazy>
            <apexcharts
                type="line"
                class="maxHeight"
                height="250"
                width="100%"
                :options="options"
                :series="series"
            ></apexcharts>
        </v-lazy>
    </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import helper from "@/utils/helper.js";
import Config from "@/utils/config.json";

/* import warning from "@/assets/warning2.png"; */
export default {
    props: {
        backgroundColor: {
            type: String,
            default: "#ffffff"
        },
        chartStartTime: {
            type: String
        },
        chartEndTime: {
            type: String
        },
        currentStartTime: {
            type: String
        },
        currentEndTime: {
            type: String
        },
        temperatureSeries: {
            type: Array,
            default: () => []
        },
        lightSeries: {
            type: Array,
            default: () => []
        },
        humiditySeries: {
            type: Array,
            default: () => []
        },
        shockSeries: {
            type: Array,
            default: () => []
        },
        trackerTemperatures: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Array,
            default: () => []
        },
        probeTemperaturesSeries: {
            type: Array,
            default: () => []
        },
        externalTemperaturesSeries: {
            type: Array,
            default: () => []
        },
        alertTrigger: {
            type: Array,
            default: () => []
        },
        tripAlert: {
            type: Array,
            default: () => []
        },
        minTemperature: {
            type: Number,
            default: 0
        },
        maxTemperature: {
            type: Number,
            default: 100
        },
        minHumidity: {
            type: Number,
            default: 0
        },
        maxHumidity: {
            type: Number,
            default: 100
        },
        minLight: {
            type: Number,
            default: 0
        },
        maxLight: {
            type: Number,
            default: 100
        },
        minShock: {
            type: Number,
            default: 0
        },
        maxShock: {
            type: Number,
            default: 100
        },
        flat: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    components: {
        apexcharts: VueApexCharts
    },
    data() {
        return {
            warning: require("@/assets/warning2.png"),
            temperature: require("@/assets/temperature.png"),
            humidity: require("@/assets/humidity2.png"),
            light: require("@/assets/light.png"),
            shock: require("@/assets/shock.png")
        };
    },
    computed: {
        /* ------ Temperature Series ------ */
        completeTemparatureSeries() {
            return this.temperatureSeries;
        },
        currentTemperatureSeries() {
            let temperatureSeries = this.completeTemparatureSeries;
            let _temperatureSeries = [];

            let _startTime = new Date(this.currentStartTime).getTime();
            let _endTime = new Date(this.currentEndTime).getTime();

            _temperatureSeries = temperatureSeries.filter(item => {
                return item[0] >= _startTime && item[0] <= _endTime;
            });
            return _temperatureSeries;
        },
        /* ------ Probe Temp. Series ------ */
        completeProbeTemperaturesSeries() {
            return this.probeTemperaturesSeries;
        },
        currentProbeTemperaturesSeries() {
            let probeTemperaturesSeries = this.completeProbeTemperaturesSeries;
            let _probeTemperaturesSeries = [];

            let _startTime = new Date(this.currentStartTime).getTime();
            let _endTime = new Date(this.currentEndTime).getTime();

            _probeTemperaturesSeries = probeTemperaturesSeries.filter(item => {
                return item[0] >= _startTime && item[0] <= _endTime;
            });

            return _probeTemperaturesSeries;
        },

        /* ------ External Temp. Series ------ */
        completeExternalTemperaturesSeries() {
            return this.externalTemperaturesSeries;
        },
        currentExternalTemperaturesSeries() {
            let externalTemperaturesSeries = this.completeExternalTemperaturesSeries;
            let _externalTemperaturesSeries = [];

            let _startTime = new Date(this.currentStartTime).getTime();
            let _endTime = new Date(this.currentEndTime).getTime();

            _externalTemperaturesSeries = externalTemperaturesSeries.filter(item => {
                return item[0] >= _startTime && item[0] <= _endTime;
            });

            return _externalTemperaturesSeries;
        },

        /* ------ Light Series ------ */
        completeLightSeries() {
            return this.lightSeries;
        },
        currentLightSeries() {
            let lightSeries = this.completeLightSeries;
            let _lightSeries = [];

            let _startTime = new Date(this.currentStartTime).getTime();
            let _endTime = new Date(this.currentEndTime).getTime();

            _lightSeries = lightSeries.filter(item => {
                return item[0] >= _startTime && item[0] <= _endTime;
            });

            return _lightSeries;
        },

        /* ------ Humidity Series ------ */
        completeHumiditySeries() {
            return this.humiditySeries;
        },
        currentHumiditySeries() {
            let humiditySeries = this.completeHumiditySeries;
            let _humiditySeries = [];

            let _startTime = new Date(this.currentStartTime).getTime();
            let _endTime = new Date(this.currentEndTime).getTime();

            _humiditySeries = humiditySeries.filter(item => {
                return item[0] >= _startTime && item[0] <= _endTime;
            });

            return _humiditySeries;
        },

        /* ------ Shock Series ------ */
        completeShockSeries() {
            return this.shockSeries;
        },
        currentShockSeries() {
            let shockSeries = this.completeShockSeries;
            let _shockSeries = [];

            let _startTime = new Date(this.currentStartTime).getTime();
            let _endTime = new Date(this.currentEndTime).getTime();

            _shockSeries = shockSeries.filter(item => {
                return item[0] >= _startTime && item[0] <= _endTime;
            });

            return _shockSeries;
        },
        minX() {
            let twoHours = 2 * 60 * 60 * 1000; // 2 hour in milliseconds
            // let startDate = new Date(this.startTime);
            let minDate = new Date(this.chartStartTime);
            if (this.temperatureSeries.length > 0) {
                let tempMinDate = new Date(Math.min(...this.temperatureSeries.map(e => e[0])));

                if (tempMinDate) {
                    minDate = tempMinDate;
                }
            }
            if (this.lightSeries.length > 0) {
                let lightMinDate = new Date(Math.min(...this.lightSeries.map(e => e[0])));

                if (lightMinDate) {
                    minDate = lightMinDate;
                }
            }
            if (this.humiditySeries.length > 0) {
                let humidityMinDate = new Date(Math.min(...this.humiditySeries.map(e => e[0])));
                if (humidityMinDate) {
                    minDate = humidityMinDate;
                }
            }
            if (this.shockSeries.length > 0) {
                let shockMinDate = new Date(Math.min(...this.shockSeries.map(e => e[0])));
                if (shockMinDate) {
                    minDate = shockMinDate;
                }
            }
            if (this.probeTemperaturesSeries.length > 0) {
                let probeMinDate = new Date(Math.min(...this.probeTemperaturesSeries.map(e => e[0])));
                if (probeMinDate) {
                    minDate = probeMinDate;
                }
            }
            if (this.externalTemperaturesSeries.length > 0) {
                let externalMinDate = new Date(Math.min(...this.externalTemperaturesSeries.map(e => e[0])));
                if (externalMinDate) {
                    minDate = externalMinDate;
                }
            }

            return minDate.getTime() - twoHours;
        },
        maxX() {
            let twoHours = 2 * 60 * 60 * 1000;
            let endDate = this.chartEndTime ? new Date(this.chartEndTime) : new Date();

            if (this.temperatureSeries.length > 0) {
                let tempMaxDate = new Date(Math.max(...this.temperatureSeries.map(e => e[0])));
                if (tempMaxDate) {
                    endDate = tempMaxDate;
                }
            }
            if (this.lightSeries.length > 0) {
                let lightMaxDate = new Date(Math.max(...this.lightSeries.map(e => e[0])));
                if (lightMaxDate) {
                    endDate = lightMaxDate;
                }
            }
            if (this.humiditySeries.length > 0) {
                let humidityMaxDate = new Date(Math.max(...this.humiditySeries.map(e => e[0])));
                if (humidityMaxDate) {
                    endDate = humidityMaxDate;
                }
            }
            if (this.shockSeries.length > 0) {
                let shockMaxDate = new Date(Math.max(...this.shockSeries.map(e => e[0])));
                if (shockMaxDate) {
                    endDate = shockMaxDate;
                }
            }
            if (this.probeTemperaturesSeries.length > 0) {
                let probeMaxDate = new Date(Math.max(...this.probeTemperaturesSeries.map(e => e[0])));
                if (probeMaxDate) {
                    endDate = probeMaxDate;
                }
            }
            if (this.externalTemperaturesSeries.length > 0) {
                let externalMaxDate = new Date(Math.max(...this.externalTemperaturesSeries.map(e => e[0])));
                if (externalMaxDate) {
                    endDate = externalMaxDate;
                }
            }

            return endDate.getTime() + twoHours;
        },
        sensors() {
            let _sensors = [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    completeSerieColor: "#1B6A9910",
                    currentSerieColor: "#1B6A99FF",
                    strokeColorOne: "#1B6A9910",
                    strokeColorTwo: "#1B6A99FF",
                    series: [
                        {
                            name: this.$t("general.temperature"),
                            data: this.completeTemparatureSeries
                        },
                        {
                            name: "CurrentTemperatureSeries",
                            data: this.currentTemperatureSeries
                        }
                    ],
                    yaxis: [
                        {
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            seriesName: this.$t("general.temperature"),
                            title: {
                                text: this.$t("general.temperature") + " " + this.getTemperatureSymbol(),
                                style: {
                                    color: "#1B6A99FF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value.toFixed(0) + " " + this.getTemperatureSymbol() : "";
                                }.bind(this),
                                style: {
                                    colors: "#1B6A99FF"
                                }
                            },
                            axisBorder: {
                                show: false,
                                color: "#1B6A99FF"
                            }
                        },
                        {
                            seriesName: this.$t("general.temperature"),
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            show: false
                        }
                    ]
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    completeSerieColor: "#07A98B10",
                    currentSerieColor: "#07A98BFF",
                    strokeColorOne: "#07A98B10",
                    strokeColorTwo: "#07A98BFF",
                    series: [
                        {
                            name: this.$t("general.humidity"),
                            data: this.completeHumiditySeries
                        },
                        {
                            name: "currentHumiditySeries",
                            data: this.currentHumiditySeries
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.humidity"),
                            min: this.minHumidity,
                            max: this.maxHumidity,
                            title: {
                                text: this.$t("general.humidity") + "",
                                style: {
                                    color: "#07A98BFF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value.toFixed(0) + "" + "%" : "";
                                },
                                style: {
                                    colors: "#07A98BFF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#07A98BFF"
                            }
                        },
                        {
                            seriesName: this.$t("general.humidity"),
                            min: this.minHumidity,
                            max: this.maxHumidity,
                            show: false
                        }
                    ],
                    annotations: {
                        yaxis: []
                    }
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    completeSerieColor: "#DEBA0410",
                    currentSerieColor: "#DEBA04FF",
                    strokeColorOne: "#DEBA0410",
                    strokeColorTwo: "#DEBA04FF",
                    series: [
                        {
                            name: this.$t("general.light"),
                            data: this.completeLightSeries
                        },
                        {
                            name: "currentLightSeries",
                            data: this.currentLightSeries
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.light"),
                            min: this.minLight,
                            max: this.maxLight,
                            opposite: false,
                            title: {
                                text: this.$t("general.light") + " (Lux)",
                                style: {
                                    color: "#DEBA04FF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value.toFixed(0) + "" + "Lux" : "";
                                },
                                style: {
                                    colors: "#DEBA04FF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#DEBA04FF"
                            }
                        },
                        {
                            seriesName: this.$t("general.light"),
                            min: this.minLight,
                            max: this.maxLight,
                            show: false
                        }
                    ]
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    completeSerieColor: "#FF000010",
                    currentSerieColor: "#FF0000FF",
                    strokeColorOne: "#FF000010",
                    strokeColorTwo: "#FF0000FF",
                    series: [
                        {
                            name: this.$t("general.shock"),
                            data: this.completeShockSeries
                        },
                        {
                            name: "currentShockSeries",
                            data: this.currentShockSeries
                        }
                    ],
                    yaxis: [
                        {
                            min: this.minShock,
                            max: this.maxShock,
                            seriesName: this.$t("general.shock"),
                            opposite: false,
                            title: {
                                text: this.$t("general.shock") + " (G)",
                                style: {
                                    color: "#FF0000FF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value.toFixed(0) + "" + "G" : "";
                                },
                                style: {
                                    colors: "#FF0000FF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#FF0000FF"
                            }
                        },
                        {
                            seriesName: this.$t("general.shock"),
                            min: this.minShock,
                            max: this.maxShock,
                            show: false
                        }
                    ]
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    completeSerieColor: "#71C4EC10",
                    currentSerieColor: "#71C4ECFF",
                    strokeColorOne: "#71C4EC10",
                    strokeColorTwo: "#71C4ECFF",
                    series: [
                        {
                            name: this.$t("general.probe_temperature"),
                            data: this.completeProbeTemperaturesSeries
                        },
                        {
                            name: "currentProbeTemperatureSeries",
                            data: this.currentProbeTemperaturesSeries
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.probe_temperature"),
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            title: {
                                text: "Probe Temperature" + "" + this.getTemperatureSymbol(),
                                style: {
                                    color: "#71C4ECFF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value.toFixed(0) + "" + this.getTemperatureSymbol();
                                }.bind(this),
                                style: {
                                    colors: "#71C4ECFF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#71C4ECFF"
                            }
                        },
                        {
                            seriesName: this.$t("general.probe_temperature"),
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            show: false
                        }
                    ]
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    completeSerieColor: "#71C4EC10",
                    currentSerieColor: "#71C4ECFF",
                    strokeColorOne: "#71C4EC10",
                    strokeColorTwo: "#71C4ECFF",
                    series: [
                        {
                            name: this.$t("general.external_temperature"),
                            data: this.completeExternalTemperaturesSeries
                        },
                        {
                            name: "currentExternalTemperatureSeries",
                            data: this.currentExternalTemperaturesSeries
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.external_temperature"),
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            title: {
                                text: this.$t("general.external_temperature") + "" + this.getTemperatureSymbol(),
                                style: {
                                    color: "#71C4ECFF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value + "" + this.getTemperatureSymbol() : "";
                                }.bind(this),
                                style: {
                                    colors: "#71C4ECFF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#71C4ECFF"
                            }
                        },
                        {
                            seriesName: this.$t("general.external_temperature"),
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            show: false
                        }
                    ],
                    alertDisplay: []
                }
            ];

            return _sensors;
        },
        yaxis() {
            let _yaxis = [];

            this.sensors
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        i.yaxis[0].opposite = false;
                        _yaxis.unshift(i.yaxis[0], i.yaxis[1]);
                    } else {
                        i.yaxis[0].opposite = true;
                        _yaxis.push(i.yaxis[0], i.yaxis[1]);
                    }
                });

            return _yaxis;
        },
        colors() {
            let _colors = [];

            this.sensors
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        _colors.unshift(i.completeSerieColor, i.currentSerieColor);
                    } else {
                        _colors.push(i.completeSerieColor, i.currentSerieColor);
                    }
                });

            return _colors;
        },
        strokeColors() {
            let _strokeColors = [];

            this.sensors
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        _strokeColors.unshift(i.strokeColorOne, i.strokeColorTwo);
                    } else {
                        _strokeColors.push(i.strokeColorOne, i.strokeColorTwo);
                    }
                });

            return _strokeColors;
        },
        annotations() {
            let _annotations = {
                yaxis: [],
                xaxis: [],
                points: []
            };
            let _yaxisIndex = 0;

            let alerts = this.alertTrigger.map(alert => {
                let _trigger = null;
                if (typeof alert == "string") {
                    _trigger = JSON.parse(alert);
                } else _trigger = JSON.parse(alert?.triggerJson);
                return _trigger;
            });

            let alertDisplay = [
                {
                    type: "temperature",
                    symbol: this.getTemperatureSymbol(),
                    position: "left",
                    textAnchor: "start",
                    color: "#1B6A99FF",
                    highestValue: this.maxTemperature
                },
                {
                    type: "humidity",
                    symbol: "%",
                    position: "right",
                    textAnchor: "end",
                    color: "#07A98BFF",
                    highestValue: this.maxHumidity
                },
                {
                    type: "light",
                    symbol: "Lux",
                    position: "right",
                    textAnchor: "end",
                    color: "#DEBA04FF",
                    highestValue: this.maxLight
                },
                {
                    type: "shock",
                    symbol: "G",
                    position: "right",
                    textAnchor: "end",
                    color: "#FF0000FF",
                    highestValue: this.maxShock
                },
                {
                    type: "probeTemperature",
                    symbol: this.getTemperatureSymbol(),
                    position: "left",
                    textAnchor: "start",
                    color: "#71C4ECFF",
                    highestValue: this.maxTemperature
                },
                {
                    type: "externalTemperature",
                    symbol: this.getTemperatureSymbol(),
                    position: "left",
                    textAnchor: "start",
                    color: "#71C4ECFF",
                    highestValue: this.maxTemperature
                },
                //location
                {
                    type: "location",
                    symbol: "",
                    position: "left",
                    textAnchor: "start",
                    color: "#71C4ECFF",
                    highestValue: this.maxTemperature || this.maxHumidity || this.maxLight || this.maxShock || 100
                }
            ];

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        this.yaxis.findIndex((e, i) => {
                            if (e.seriesName.toLowerCase() == sensor.Type.toLowerCase()) {
                                _yaxisIndex = i;
                            }
                        });

                        if (this.selected.includes(sensor.Type)) {
                            alertDisplay.forEach(display => {
                                if (display.type == sensor.Type) {
                                    if (sensor.Condition == "in") {
                                        let minValue =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value[0])
                                                : sensor.Value[0];
                                        let maxValue =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value[1])
                                                : sensor.Value[1];

                                        //Annotation yaxis
                                        _annotations.yaxis.push(
                                            {
                                                y: minValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: _yaxisIndex,
                                                label: {
                                                    offsetY: 15,
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#64B5F6",
                                                        background: "transparent"
                                                    },
                                                    text: minValue + " " + display.symbol
                                                }
                                            },
                                            {
                                                position: "back",
                                                y: maxValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: _yaxisIndex,
                                                label: {
                                                    offsetY: 15,
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#FF4560",
                                                        background: "transparent"
                                                    },
                                                    text: maxValue + " " + display.symbol
                                                }
                                            }
                                        );
                                    } else if (sensor.Condition == "out") {
                                        let minValue = this.formatTemperature(sensor.Value[0]);
                                        let maxValue = this.formatTemperature(sensor.Value[1]);

                                        //Annotation yaxis
                                        _annotations.yaxis.push(
                                            {
                                                y: minValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: _yaxisIndex,
                                                label: {
                                                    offsetY: 15,
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#64B5F6",
                                                        background: "transparent"
                                                    },
                                                    text: minValue + " " + display.symbol
                                                }
                                            },
                                            {
                                                position: "back",
                                                y: maxValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: 0,
                                                label: {
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#FF4560",
                                                        background: "transparent"
                                                    },
                                                    text: maxValue + " " + display.symbol
                                                }
                                            }
                                        );
                                    } else if (sensor.Condition === "gt") {
                                        let value =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value)
                                                : sensor.Value;
                                        //Annotation yaxis
                                        _annotations.yaxis.push({
                                            position: "back",
                                            y: value,
                                            borderColor: display.color,
                                            strokeDashArray: 3,
                                            yAxisIndex: _yaxisIndex,
                                            label: {
                                                offsetY: 0,
                                                textAnchor: display.textAnchor,
                                                position: display.position,
                                                borderColor: undefined,
                                                style: {
                                                    color: "#FF4560",
                                                    background: "transparent"
                                                },
                                                text: value + " " + display.symbol
                                            }
                                        });
                                    } else if (sensor.Condition === "lt") {
                                        let value =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value)
                                                : sensor.Value;

                                        //Annotation yaxis
                                        _annotations.yaxis.push({
                                            position: "back",
                                            y: value,
                                            borderColor: display.color,
                                            strokeDashArray: 3,
                                            yAxisIndex: _yaxisIndex,
                                            label: {
                                                offsetY: 15,
                                                textAnchor: display.textAnchor,
                                                position: display.position,
                                                borderColor: undefined,
                                                style: {
                                                    color: "#64B5F6",
                                                    background: "transparent"
                                                },
                                                text: value + " " + display.symbol
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
            });

            ///Annotation xaxis and point
            if (Array.isArray(this.tripAlert)) {
                this.tripAlert.forEach(tripAlert => {
                    alertDisplay.forEach(display => {
                        if (tripAlert.type.includes(display.type)) {
                            //Annotation xaxis
                            _annotations.xaxis.push({
                                x: tripAlert.deviceTime,
                                borderColor: "#FF4560",
                                strokeDashArray: 0,
                                label: {
                                    borderColor: undefined,
                                    text: this.annotationText,
                                    offsetX: 0,
                                    offsetY: 0,
                                    orientation: "vertical",
                                    position: "bottom",
                                    textAnchor: "middle",
                                    width: 100,
                                    height: 50,
                                    style: {
                                        color: "#BDBDBD",
                                        background: "transparent",
                                        fontSize: "11px",
                                        cssClass: "apexcharts-xaxis-label"
                                    }
                                }
                            });
                        }
                    });
                });
            }
            if (Array.isArray(this.tripAlert)) {
                this.tripAlert.forEach(tripAlert => {
                    alertDisplay.forEach(display => {
                        if (tripAlert.type.includes(display.type)) {
                            //Annotation point
                            _annotations.points.push({
                                x: tripAlert.deviceTime,
                                y: tripAlert.alertValue,
                                marker: {
                                    size: 4,
                                    fillColor: "#FF4560",
                                    strokeColor: "#fff",
                                    radius: 1,
                                    cssClass: "apexcharts-custom-class"
                                },
                                label: {
                                    offsetY: 0,
                                    offsetX: 0,
                                    textAnchor: "middle",
                                    text: null,
                                    borderColor: "trasparent"
                                },
                                image: {
                                    path: this.warning,
                                    width: 21,
                                    height: 21,
                                    offsetX: 0,
                                    offsetY: 0,
                                    style: {
                                        cssClass: "apexcharts-custom-class"
                                    }
                                }
                            });
                        }
                    });
                });
            }

            return _annotations;
        },
        annotationText() {
            let text = "";

            let alertDisplay = [
                {
                    type: "temperature",
                    name: "Temperarure",
                    symbol: this.getTemperatureSymbol()
                },
                {
                    type: "humidity",
                    name: "Humidity",
                    symbol: "%"
                },
                {
                    type: "light",
                    name: "Light",
                    symbol: "Lux"
                },
                {
                    type: "shock",
                    name: "Shock",
                    symbol: "G"
                },
                {
                    type: "probeTemperature",
                    name: "Probe. temperature",
                    symbol: this.getTemperatureSymbol()
                },
                {
                    type: "externalTemperature",
                    name: "Ext. temperature",
                    symbol: this.getTemperatureSymbol()
                },
                {
                    type: "location",
                    name: "Location",
                    symbol: ""
                }
            ];

            if (Array.isArray(this.tripAlert)) {
                this.tripAlert.forEach(tripAlert => {
                    alertDisplay.forEach(display => {
                        if (tripAlert.type.includes(display.type)) {
                            for (let index = 0; index < tripAlert.type.length; index++) {
                                if (
                                    tripAlert.type[index] != tripAlert.type[index - 1] &&
                                    !text.toLocaleLowerCase().includes(tripAlert.type[index])
                                ) {
                                    //capilize first letter
                                    let firstLetter = tripAlert.type[index].charAt(0).toUpperCase();
                                    let newText = firstLetter + tripAlert.type[index].slice(1);
                                    text = text + newText + " ";

                                    //if text have than 3 word then break loop
                                    if (text.split(" ").length > 2) {
                                        break;
                                    }
                                }
                            }
                            //text = display.name +" "+"Alert " ;
                        }
                    });
                });
            }

            text = text + "Alert";
            return text;
        },
        series() {
            let _series = [];

            this.sensors
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        _series.unshift(
                            {
                                name: i.series[0].name,
                                data: i.series[0].data ? i.series[0].data : []
                            },
                            {
                                name: i.series[1].name,
                                data: i.series[1].data ? i.series[1].data : []
                            }
                        );
                    } else {
                        _series.push(
                            {
                                name: i.series[0].name,
                                data: i.series[0].data ? i.series[0].data : []
                            },
                            {
                                name: i.series[1].name,
                                data: i.series[1].data ? i.series[1].data : []
                            }
                        );
                    }
                });
            return _series;
        },
        options() {
            let _options = {
                chart: {
                    id: "temperature-chart",
                    type: "line",
                    height: "100%",
                    width: "100%",
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
                annotations: {
                    yaxis: []
                },
                dataLabels: {
                    enabled: false
                },
                noData: {
                    text: this.$t("loading.no_available"),
                    align: "center",
                    verticalAlign: "middle"
                },
                zoom: {
                    autoScaleYaxis: true
                },
                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"],
                        opacity: 0.5
                    }
                },
                xaxis: {
                    type: "datetime",
                    min: this.minX,
                    max: this.maxX,
                    labels: {
                        minHeight: 70,
                        style: {
                            fontSize: "12px",
                            fontWeight: 600,
                            lineHeight: "13px",
                            letterSpacing: "0em",
                            textAlign: "left"
                        },
                        offsetX: 0,
                        offsetY: 15,
                        datetimeUTC: false,
                        datetimeFormatter: {
                            year: "yyyy",
                            month: "MMM 'yy",
                            day: "dd MMM",
                            hour: "HH:mm"
                        }
                    },
                },
                yaxis: [],
                colors: [],
                markers: {
                    // size: [0, 0.5, 0, 0.5],
                    strokeColors: [],
                    showNullDataPoints: true,
                    hover: { sizeOffset: 3 }
                },
                tooltip: {
                    x: {
                        show: false,
                        format: "dd MMM yyyy HH:mm"
                    }
                },
                stroke: {
                    curve: "smooth",
                    width: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                    dashArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                fill: {
                    opacity: 1
                }
            };

            _options.yaxis = [...this.yaxis];
            _options.colors = [...this.colors];
            _options.markers.strokeColors = [...this.strokeColors];
            _options.annotations = { ...this.annotations };
            return _options;
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_freight_timeline_chart_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_freight_timeline_chart_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_freight_timeline_chart_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        }
        /* getIcon(name) {
            if(name=="temperature"){
                return 'thermostat' ;
            }else if(name=="water_drop"){ 
                return this.humidity;
            }else if(name=="light"){
                return 'lightbulb';
            }else if(name=="shock"){
                return 'bolt';
            }
            
        },  */
    }
};
</script>
<style scoped>
.maxHeight {
    max-height: 400px;
    max-width: 900px;
}
</style>
