import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.location)?_c(VCard,{attrs:{"fill-height":"","flat":"","width":"100%","color":"background"}},[_c(VToolbar,{attrs:{"flat":"","color":"transparent"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("arrow_back_ios_new")])],1),(_vm.shouldShowFullScreenButton)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.changeFullScreen}},[_c(VIcon,[_vm._v(_vm._s(_vm.isFullScreen ? "fullscreen_exit" : "crop_free"))])],1):_vm._e(),_c(VSpacer),(_vm.title !== _vm.$t('partners.public_locations'))?_c('div',[_c(VBtn,{attrs:{"icon":"","disabled":_vm.disabledButton},on:{"click":_vm.editLocation}},[_c(VIcon,[_vm._v("edit")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.openDeleteDialog = true}}},[_c(VIcon,[_vm._v("delete")])],1),_c('DeleteLocationDialog',{attrs:{"openDeleteDialog":_vm.openDeleteDialog,"locationID":_vm.location.id},on:{"refreshDataTable":function($event){return _vm.$emit('refreshDataTable')},"closeDeleteDialog":function($event){_vm.openDeleteDialog = false},"locationDeleted":function($event){return _vm.$emit('onLocationDeleted')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("partners.delete_location")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("partners.sure_location")))]},proxy:true}],null,false,2206765352)}),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v(" share ")])],1)],1):_vm._e()],1),_c(VSheet,{staticClass:"pt-0 mb-5 px-5 background"},[_c('div',{staticClass:"textBody"},[_vm._v(" "+_vm._s(_vm.$t("headers.name"))+" ")]),_c('h3',[_vm._v(_vm._s(_vm.location ? _vm.location.name : ""))])]),(_vm.viewType === 'tab')?_c('PreviewDetailTabView',{ref:"tabView",attrs:{"location":_vm.location,"selectedFilters":_vm.selectedFilters,"title":_vm.title},on:{"updateLocationsTable":_vm.updateLocationsTable}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }