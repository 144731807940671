<template>
    <v-dialog v-model="dialog" persistent width="860px" scrollable>
        <v-card>
            <v-card-title class="primary white--text">
                <v-icon dark left>help</v-icon> HELP
                <v-spacer></v-spacer>
                <v-btn dark icon @click="closeDialog"
                    ><v-icon>close</v-icon></v-btn
                >
            </v-card-title>

            <v-card-text class="mt-5" v-if="knowledgebases.edges.length > 0">
                <div
                    v-for="k in knowledgebases.edges"
                    :key="k.node.id"
                    class="mb-10"
                >
                    <h3>
                        {{ k.node.title }}
                    </h3>
                    <p>
                        {{ k.node.description }}
                    </p>
                    <iframe
                        width="800"
                        height="450"
                        :src="k.node.videoUrl"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                </div>
            </v-card-text>

            <v-card-text class="mt-5" v-else>
                Coming soon!
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    title: "Help",
    props: ["dialog"],

    apollo: {
        knowledgebases: {
            query: gql`
                query knowledgebases($orderBy: String) {
                    knowledgebases(orderBy: $orderBy) {
                        edges {
                            node {
                                id
                                title
                                description
                                videoUrl
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "sequence"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            update: data => data.knowledgebases
            // skip() {}
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            knowledgebases: { edges: [] }
        };
    },

    computed: {},
    created() {},
    mounted() {},
    methods: {
        closeDialog() {
            this.$emit("update:dialog", false);
        }
    }
};
</script>
