import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"mt-5 mb-15 px-5"},[(!_vm.canAccess)?_c('div',[_c('NoPermission')],1):_c('div',[(_vm.$apollo.queries.tripReports.loading)?_c('LoadingBar'):_c('div',[_c(VCard,{staticClass:"mb-0 pb-0",attrs:{"elevation":"5","rounded":"xl"}},[_c(VCardTitle,{staticClass:"background2 py-3"},[_c('div',{staticClass:"mr-2"},[_c(VIcon,[_vm._v("description")])],1),_c('span',[_vm._v(_vm._s(_vm.$t("headers.reports")))]),_c(VSpacer),(_vm.canCreate)?_c('GenerateReportDialog',{attrs:{"trip":_vm.trip},on:{"reportGenerated":_vm.refetch}}):_vm._e()],1),_c(VCardText,{staticClass:"py-0"},[_c(VCard,{attrs:{"flat":""}},[(_vm.$apollo.queries.tripReports.loading)?_c('LoadingBar'):_c(VCardText,{},[(_vm.tripReports.edges.length > 0)?_c(VDataTable,{staticClass:"mb-2",attrs:{"headers":_vm.headers,"items":_vm.tripReports ? _vm.tripReports.edges : [],"loading-text":_vm.$t('loading.loading1'),"mobile-breakpoint":"1000","disable-pagination":"","hide-default-footer":"","single-expand":true,"expanded":_vm.expanded,"sort-by":"node.createdAt","sort-desc":true,"item-key":"node.id"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expandRow},scopedSlots:_vm._u([{key:"item.node.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.createdAt)))])]}},{key:"item.dateRange",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime( item.node.startDate ).split(" ")[0]))]),_c('span',[_vm._v(" - ")]),_c('span',[_vm._v(_vm._s(_vm.formatDateTime( item.node.endDate ).split(" ")[0]))])]}},{key:"item.node.sensorData",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getSensorTypes(
                                            item.node.sensorData
                                        )),function(sensorType){return _c(VTooltip,{key:sensorType.text,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(sensorType.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(sensorType.text))])])})}},{key:"item.node.reportType",fn:function(ref){
                                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.node.reportType))])]}},{key:"item.action",fn:function(ref){
                                        var item = ref.item;
                                        var slot = ref.slot;
return [(item.node.isCompleted)?_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.downloadReport(
                                                item.node.document.url
                                            )}}},[_c(VIcon,[_vm._v("download")])],1):_c(VBtn,{attrs:{"small":"","icon":"","loading":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("loading.loading1"))+" ")])]}},{key:"expanded-item",fn:function(ref){
                                            var headers = ref.headers;
                                            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VRow,[(item.node.document)?_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.node .document .name))]),_c(VListItemSubtitle,{staticClass:"text-truncate",staticStyle:{"max-width":"400px"}},[_vm._v(_vm._s(_vm.$t( "headers.document" )))])],1)],1)],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.node .tracker .serialNumber))]),_c(VListItemSubtitle,{staticClass:"text-truncate",staticStyle:{"max-width":"400px"}},[_vm._v(_vm._s(_vm.$t( "headers.tracker" )))])],1)],1)],1),(item.node.document)?_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.bytesToSize( item .node .document .size )))]),_c(VListItemSubtitle,{staticClass:"text-truncate",staticStyle:{"max-width":"400px"}},[_vm._v(_vm._s(_vm.$t( "headers.size" )))])],1)],1)],1):_vm._e()],1)],1),_c(VRow)]}}],null,false,762880695)}):_c('div',{staticClass:"text-caption text-center mt-3"},[_vm._v(" "+_vm._s(_vm.$t("trips.no_reports"))+" ")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }