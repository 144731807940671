<template>
    <v-container fluid class="pl-0">
        <h4 class="mb-2">
            {{ $t("alert_rules.type") }}
        </h4>
        <v-row dense class="d-flex">
            <v-col
                v-for="sensorTypeChoice in sensorTypeChoices"
                :key="sensorTypeChoice.value"
                cols="12"
                sm="12"
                md="3"
                lg="3"
            >
                <v-card
                    elevation="2"
                    class="py-5 d-flex align-center justify-center"
                    @click="updateSensorType(sensorTypeChoice.value)"
                    :color="
                        sensorTypes.includes(sensorTypeChoice.value)
                            ? 'primary'
                            : ''
                    "
                >
                    <span
                        :class="
                            sensorTypes.includes(sensorTypeChoice.value)
                                ? 'white--text text-no-wrap pr-1'
                                : 'black--text text-no-wrap pr-1'
                        "
                    >
                        <v-icon
                            :color="
                                sensorTypes.includes(sensorTypeChoice.value)
                                    ? 'white'
                                    : 'black'
                            "
                            class="pr-0"
                            >{{ sensorTypeChoice.icon }}</v-icon
                        >
                        {{ sensorTypeChoice.text }}
                    </span>
                </v-card></v-col
            >
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: {
        Sensor: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            sensorTypeChoices: [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    icon: "lightbulb"
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    icon: "water_drop"
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    icon: "bolt"
                },
                {
                    text: this.$t("general.pressure"),
                    value: "pressure",
                    icon: "tire_repair"
                }

                // { text: "CO2", value: "co2", icon: "air" }
            ],
            sensorTypes: []
        };
    },
    computed: {},
    created() {
        this.sensorTypes = this.Sensor.map(sensor => sensor.Type);
    },
    mounted() {},
    watch: {
        Sensor: {
            handler(val) {
                this.sensorTypes = val.map(sensor => sensor.Type);
            },
            deep: true
        }
    },
    methods: {
        updateSensorType(val) {
            if (this.sensorTypes.includes(val)) {
                this.sensorTypes = this.sensorTypes.filter(
                    sensorType => sensorType !== val
                );
            } else {
                this.sensorTypes.push(val);
            }
            let _sensor = [];
            for (let i = 0; i < this.sensorTypes.length; i++) {
                let _sensorToCheck = this.Sensor.find(
                    sensor => sensor.Type === this.sensorTypes[i]
                );
                if (!_sensorToCheck) {
                    _sensor.push({
                        Type: this.sensorTypes[i],
                        Value: null,
                        Condition: null
                    });
                } else {
                    _sensor.push(_sensorToCheck);
                }
            }
            this.$emit("updateSensorType", _sensor);
        },
        reset() {
            this.sensorTypes = [];
        }
    }
};
</script>
