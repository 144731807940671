<template>
    <v-container fluid>
        <v-row class="d-flex justify-center">
            <v-col md="8" cols="12">
                <v-toolbar flat color="transparent">
                    <v-btn icon @click="discard">
                        <v-icon>arrow_back_ios_new</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-col>
            <v-col md="8" cols="12">
                <v-card class="d-flex align-end " height="40" color="transparent" flat>
                    <div class="textTitle">
                        {{ viewTitle }}
                    </div>
                </v-card>
            </v-col>
            <v-col md="8" cols="12">
                <v-card flat color="transparent">
                    <v-stepper v-model="stepNo" style="background-color: transparent;" flat>
                        <v-stepper-header class="elevation-0 px-1">
                            <v-stepper-step
                                :complete="stepNo > 1"
                                step="1"
                                editable
                                class="outLined stepperContent mr-6 mt-5"
                            >
                                <span class="primary--text">
                                    {{ viewTitle }}
                                </span>
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                                :complete="stepNo > 2"
                                step="2"
                                :editable="isFormsValid"
                                class="outLined stepperContent ml-6 mt-5"
                            >
                                {{ permissionView }}
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items class="px-0">
                            <v-stepper-content step="1" class="pt-1 px-0">
                                <div v-if="!editMode">
                                    <!-- Create Partner Content -->
                                    <v-card
                                        flat
                                        class="background px-0"
                                        v-for="(partner, index) in partners"
                                        :key="index"
                                    >
                                        <v-card-text class="px-0">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card flat class="rounded-lg" v-if="!isCarrier">
                                                        <v-card-title>
                                                            <div>{{ $t("headers.collaborator_type") }}</div>
                                                            <v-spacer />
                                                            <div>
                                                                <button
                                                                    icon
                                                                    @click="removeExtraContributor(index)"
                                                                    color="red"
                                                                    v-if="partners.length > 1"
                                                                >
                                                                    <v-icon>delete</v-icon>
                                                                </button>
                                                            </div>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <CustomCardOptions
                                                                v-model="partner.partnerType"
                                                                :items="partnerChoices"
                                                                :height="'180px'"
                                                                :selectedColor="'primary'"
                                                            ></CustomCardOptions>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-card flat class="rounded-lg">
                                                        <v-card-title>
                                                            <div>{{ $t("headers.collaborator_info") }}</div>
                                                            <v-spacer />
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <CreatePartnerForm
                                                                :ref="'partnerForm' + index"
                                                                :partner.sync="partner"
                                                                :isValid.sync="partner.isValid"
                                                                :partnerType="isCarrier ? 'carrier' : null"
                                                            ></CreatePartnerForm>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12" class="d-flex justify-center">
                                                    <v-btn
                                                        elevation="0"
                                                        color="primary"
                                                        v-if="isTheLastItem(index)"
                                                        @click="addExtraContributor"
                                                    >
                                                        {{ $t("general.add_extra_contributor") }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                                <!-- Edit Partners -->
                                <div v-else>
                                    <v-card flat class="background px-0">
                                        <v-card-text class="px-0">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card flat class="rounded-lg" v-if="!isCarrier">
                                                        <v-card-title>
                                                            <div>{{ $t("headers.collaborator_type") }}</div>
                                                            <v-spacer />
                                                            <div>
                                                                <button
                                                                    icon
                                                                    @click="removeExtraContributor(index)"
                                                                    color="red"
                                                                    v-if="partners.length > 1"
                                                                >
                                                                    <v-icon>delete</v-icon>
                                                                </button>
                                                            </div>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <CustomCardOptions
                                                                v-model="partner.partnerType"
                                                                :items="partnerChoices"
                                                                :height="'180px'"
                                                                :selectedColor="'primary'"
                                                            ></CustomCardOptions>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-card flat class="rounded-lg">
                                                        <v-card-title>
                                                            <div>{{ $t("headers.collaborator_info") }}</div>
                                                            <v-spacer />
                                                            <div>
                                                                <button
                                                                    icon
                                                                    @click="removeExtraContributor(index)"
                                                                    color="red"
                                                                    v-if="partners.length > 1"
                                                                >
                                                                    <v-icon>delete</v-icon>
                                                                </button>
                                                            </div>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <CreatePartnerForm
                                                                :partner.sync="partner"
                                                                :isValid.sync="isValidForm"
                                                                :partnerType="isCarrier ? 'carrier' : null"
                                                            ></CreatePartnerForm>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-stepper-content>
                            <v-stepper-content step="2" class="pt-1 px-0">
                                <v-row>
                                    <v-col cols="12">
                                        <PermissionForm
                                            :partnerPermissions="selectedPermissions"
                                            :permissions="permissions"
                                            v-on:update-permissions="updatePermissions"
                                        ></PermissionForm>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                    <v-card-actions ref="card" class="card-container rounded-lg px-8">
                        <v-btn text @click="discard()" outlined>
                            {{ $t("general.discard") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="stepNo === 1"
                            elevation="0"
                            color="primary"
                            :disabled="!isFormsValid"
                            @click="stepNo = 2"
                        >
                            {{ $t("general.next") }}
                        </v-btn>
                        <v-btn v-else elevation="0" color="primary" :disabled="!isFormsValid" @click="save()">
                            {{ $t("general.save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
//import CustomTabs from "@/components/base/CustomTabs.vue";
import CustomCardOptions from "@/components/base/CustomCardOptions.vue";
import CreatePartnerForm from "@/components/partners/forms/CreatePartnerForm.vue";
import PermissionForm from "@/components/partners/forms/PermissionForm.vue";
import gql from "graphql-tag";
import {
    TripPermissions,
    // TrackerPermissions,
    CarrierPermissions,
    TenantPermissions,
    BookingPermissions
} from "@/core/enum";
export default {
    name: "PreviewCreatePartner",
    components: {
        CustomCardOptions,
        CreatePartnerForm,
        PermissionForm
    },
    data() {
        return {
            tab: null,
            editMode: false,
            stepNo: 1,
            partners: [
                {
                    partnerName: null,
                    partnerType: null,
                    referenceNumber: null,
                    notification: false,
                    transportMode: "",
                    carrier: "",
                    description: null
                }
            ],
            partner: {
                partnerName: null,
                partnerType: null,
                referenceNumber: null,
                notification: false,
                transportMode: "",
                carrier: "",
                description: null
            },
            isValidForm: false,
            isCardHidden: false,
            selectedPermissions: [],
            createFromAnotherComp: null,
            permissions: [
                {
                    module: this.$t("partners.trip_carrier"),
                    permission: "View",
                    description: this.$t("partners.carrier_description"),
                    value: CarrierPermissions.VIEW_CARRIER,
                    managePermission: CarrierPermissions.MANAGE_CARRIER
                },
                {
                    module: this.$t("partners.trip_documents"),
                    permission: "View",
                    description: this.$t("partners.documents_description"),
                    value: TripPermissions.VIEW_TRIP_DOCUMENT,
                    managePermission: TripPermissions.MANAGE_TRIP_DOCUMENT
                },
                {
                    module: this.$t("partners.trip_trackers"),
                    permission: "View",
                    description: this.$t("partners.trackers_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT_TRACKER,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT_TRACKER
                },
                {
                    module: this.$t("partners.trip_freights"),
                    permission: "View",
                    description: this.$t("partners.trackers_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT
                },
                {
                    module: this.$t("partners.trip_partners"),
                    permission: "View",
                    description: this.$t("partners.partners_description"),
                    value: TripPermissions.VIEW_TRIP_PARTNER,
                    managePermission: TripPermissions.MANAGE_TRIP_PARTNER
                },
                {
                    module: this.$t("partners.trip_map"),
                    permission: "View",
                    description: this.$t("partners.map_description"),
                    value: TenantPermissions.VIEW_TENANT_LOCATION,
                    managePermission: TenantPermissions.MANAGE_TENANT_LOCATION
                },
                {
                    module: this.$t("partners.trip_sea"),
                    permission: "View",
                    description: this.$t("partners.sea_description"),
                    value: TripPermissions.VIEW_TRIP_SEA_TRANSPORT,
                    managePermission: TripPermissions.MANAGE_TRIP_SEA_TRANSPORT
                },
                {
                    module: this.$t("partners.trip_alert"),
                    permission: "View",
                    description: this.$t("partners.alert_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT_ALERT,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT_ALERT
                },
                {
                    module: this.$t("partners.trip_alert"),
                    permission: "Resolve",
                    description: this.$t("partners.alert_resolve"),
                    value: TripPermissions.EDIT_TRIP_FREIGHT_ALERT
                },
                {
                    module: this.$t("partners.trip_alert"),
                    permission: "Resolve",
                    description: this.$t("partners.alert_resolve"),
                    value: TripPermissions.EDIT_TRIP_FREIGHT_ALERT
                },
                {
                    module: this.$t("partners.trip_timeline"),
                    permission: "View",
                    description: this.$t("partners.timeline_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT_TIMELINE
                },
                {
                    module: this.$t("partners.trip_report"),
                    permission: "View",
                    description: this.$t("partners.trip_report_view_description"),
                    value: TripPermissions.VIEW_TRIP_REPORT,
                    managePermission: TripPermissions.MANAGE_TRIP_REPORT
                },
                {
                    module: this.$t("partners.trip_report"),
                    permission: "Create",
                    description: this.$t("partners.trip_report_create_description"),
                    value: TripPermissions.CREATE_TRIP_REPORT,
                    managePermission: TripPermissions.MANAGE_TRIP_REPORT
                },
                {
                    module: this.$t("partners.qx_booking"),
                    permission: "View",
                    description: this.$t("partners.qx_booking_description"),
                    value: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                },
                {
                    module: this.$t("partners.qx_booking"),
                    permission: "Create",
                    description: this.$t("partners.qx_booking_create_description"),
                    value: BookingPermissions.CREATE_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                },
                {
                    module: this.$t("partners.qx_booking"),
                    permission: "Edit",
                    description: this.$t("partners.qx_booking_edit_description"),
                    value: BookingPermissions.EDIT_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                }
            ],
            localTenantPartner: null
        };
    },
    apollo: {
        tenantPartner: {
            query: gql`
                query tenantPartner($id: ID!) {
                    tenantPartner(id: $id) {
                        carrier {
                            id
                            name
                            carrierType
                        }

                        description
                        id
                        name
                        notificationEnabled
                        organisationPermissions
                        partnerType
                        referenceNumber
                        tenant {
                            id
                        }
                    }
                }
            `,
            variables() {
                //get query id from route
                return { id: this.$route?.query?.id };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantPartner,
            result({ data, loading }) {
                if (!loading) {
                    this.localTenantPartner = data.tenantPartner;
                }
            },
            skip() {
                return true;
            }
        }
    },
    computed: {
        isFormsValid() {
            let _isValid = true;
            if (this.editMode) {
                _isValid = this.isValidForm;
            } else {
                this.partners.forEach(partner => {
                    if (!partner.isValid) {
                        _isValid = false;
                    }
                });
            }
            return _isValid;
        },
        modeType() {
            let _mode = "create-consignor";
            if (this.$route.query?.type) {
                if (this.$route.query?.from) {
                    const { id, type, from } = this.$route.query;
                    const [mode, partner, prevMode] = type.split("-");

                    _mode = mode + "-" + partner;
                } else {
                    _mode = this.$route.query.type;
                }
            }
            return _mode;
        },
        viewTitle() {
            let title = "";
            if (this.editMode) {
                title = this.$t("headers.edit_collaborator");
            } else {
                title = this.$t("headers.add_collaborator");
            }
            return title;
        },
        permissionView() {
            let title = "";
            if (this.editMode) {
                title = this.$t("headers.edit_permissions");
            } else {
                title = this.$t("headers.add_permissions");
            }
            return title;
        },
        tabs() {
            return [
                {
                    id: 0,
                    text: this.viewTitle,
                    value: "addPartner"
                },
                {
                    id: 1,
                    text: this.permissionView,
                    value: "addPermission"
                }
            ];
        },
        isCarrier() {
            let toLowerCase = str => {
                return str ? str.toLowerCase() : "";
            };
            return toLowerCase(this.modeType).includes("carrier");
        },
        partnerChoices() {
            return [
                {
                    icon: null,
                    img: require("@/assets/inventory.png"),
                    text: this.$t("headers.consignor"),
                    value: "consignor",
                    cols: {
                        xs: 6,
                        sm: 6,
                        md: 3,
                        lg: 3,
                        xl: 3
                    }
                },
                {
                    icon: null,
                    img: require("@/assets/trip_bag.png"),
                    text: this.$t("headers.consignee"),
                    value: "consignee",
                    cols: {
                        xs: 6,
                        sm: 6,
                        md: 3,
                        lg: 3,
                        xl: 3
                    }
                },
                {
                    icon: null,
                    img: require("@/assets/airportShuttle.png"),
                    text: this.$t("headers.forwarder"),
                    value: "forwarder",
                    cols: {
                        xs: 6,
                        sm: 6,
                        md: 3,
                        lg: 3,
                        xl: 3
                    }
                },
                {
                    icon: null,
                    img: require("@/assets/help.png"),
                    text: this.$t("home.other"),
                    value: "other",
                    cols: {
                        xs: 6,
                        sm: 6,
                        md: 3,
                        lg: 3,
                        xl: 3
                    }
                }
            ];
        }
    },
    watch: {
        modeType: {
            handler: function(val) {
                if (val.includes("edit")) {
                    this.editMode = true;
                } else {
                    this.editMode = false;
                }
            },
            immediate: true
        },
        isCardHidden: {
            handler: function(val) {
                if (this.partners.length < 1) {
                    this.isCardHidden = true;
                }
            },
            immediate: true
        },
        partners: {
            handler: function(val) {
                if (val.length < 1) {
                    this.isCardHidden = true;
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.modeType.includes("edit") && this.modeType !== "edit-carrier") {
                this.localTenantPartner = this.$store.getters["partner/getCreatedPartner"];
                if (!this.localTenantPartner) {
                    this.$apollo.queries.tenantPartner.skip = false;
                    setTimeout(() => {
                        this.edit();
                    }, "3000");
                } else {
                    this.edit();
                }
                this.editMode = true;
            } else if (this.modeType === "edit-carrier") {
                this.localTenantPartner = this.$store.getters["partner/getCreatedPartner"];
                if (!this.localTenantPartner) {
                    this.$apollo.queries.tenantPartner.skip = false;
                    setTimeout(() => {
                        this.editCarriers();
                    }, "3000");
                } else {
                    this.editCarriers();
                }
                this.editMode = true;
            } else if (this.modeType.includes("create")) {
                if (this.$route.query?.from) {
                    const { id, type, from } = this.$route.query;
                    const [mode, partner, prevMode] = type.split("-");
                    const [, routeDetail] = from.split("-");
                    const [, carrierType] = routeDetail.split("/");

                    //update the partner type
                    this.partners[0].partnerType = partner;

                    if (partner === "carrier") {
                        this.partners[0].carrierType = carrierType.toLowerCase();
                    }

                    this.retainRouteData();
                } else if (this.$route.query?.type) {
                    const { type } = this.$route.query;
                    const [mode, partner] = type.split("-");
                    this.partners[0].partnerType = partner;
                }
                this.editMode = false;
            }
        });

        if (this.partners.length < 1) {
            this.isCardHidden = true;
        }
    },
    methods: {
        retainRouteData() {
            const { id, type, from } = this.$route.query;
            const [mode, partner, prevMode] = type.split("-");
            const [previousRoute, routeDetail] = from.split("-");
            const [, parameter] = routeDetail.split("/");
            this.createFromAnotherComp = {
                previousRoute: previousRoute,
                routeDetails: routeDetail ? routeDetail.replace("/", "-") : null,
                typeDetails: prevMode,
                partnertype: partner
            };
        },
        editCarriers() {
            const toLowerCase = str => {
                return str ? str.toLowerCase() : null;
            };

            this.partner.id = this.localTenantPartner?.id;
            this.partner.partnerName = this.localTenantPartner?.name;
            this.partner.description = this.localTenantPartner?.description;
            this.partner.referenceNumber = this.localTenantPartner?.referenceNumber;
            this.partner.partnerType = this.localTenantPartner?.partnerType;
            this.partner.carrier = this.localTenantPartner?.carrier?.id;
            this.partner.carrierType = toLowerCase(this.localTenantPartner?.carrier?.carrierType);
            this.partner.notification = this.localTenantPartner?.notificationEnabled;
            this.selectedPermissions = JSON.parse(this.localTenantPartner?.organisationPermissions);
        },
        async edit() {
            this.partner.id = this.localTenantPartner?.id;
            this.partner.partnerName = this.localTenantPartner?.name;
            this.partner.description = this.localTenantPartner?.description;
            this.partner.referenceNumber = this.localTenantPartner?.referenceNumber;
            this.partner.partnerType = this.localTenantPartner?.partnerType;
            this.partner.notification = this.localTenantPartner?.notificationEnabled;
            this.selectedPermissions = JSON.parse(this.localTenantPartner?.organisationPermissions);
        },
        isTheLastItem(index) {
            return index === this.partners.length - 1;
        },
        back() {
            this.tab = "addPartner";
        },
        overLapScroll() {
            if (this.templateDialogActivated) {
                return "fixed-bottom-card-1 containerFooter rounded-lg px-8 elevation-0 disabledClass outlineD";
            } else {
                return "fixed-bottom-card containerFooter rounded-lg px-8 elevation-0 outlineD";
            }
        },
        handleScroll() {
            const overlapCard = this.$refs.overlapCard;
            const otherCard = this.$refs.card;
            const overlapRect = overlapCard.getBoundingClientRect();
            const cardRect = otherCard.getBoundingClientRect();
            if (this.partners.length < 1) {
                this.isCardHidden = true;
            } else {
                let _overlapRect = overlapRect.top > 0 ? overlapRect : this.storeCardCoordinates;

                if (overlapRect.top > 0) {
                    this.storeCardCoordinates = overlapRect;
                }

                if (this.isLessThan(_overlapRect, cardRect)) {
                    this.isCardHidden = false;
                } else if (this.isMoreThan(_overlapRect, cardRect)) {
                    this.isCardHidden = true;
                }
            }
        },
        isLessThan(rect1, rect2) {
            let rectTop = rect2.top - 20;
            return rect1.bottom <= rectTop;
        },
        isMoreThan(rect1, rect2) {
            return rect1.bottom > rect2.top;
        },
        updatePermissions(permissionsSelected) {
            this.selectedPermissions = [...new Set(permissionsSelected.map(item => item))];
        },
        addExtraContributor() {
            let _partner = {
                partnerName: null,
                partnerType: null,
                referenceNumber: null,
                notification: false,
                transportMode: "",
                carrier: "",
                description: null
            };

            this.partners.push(_partner);
        },
        removeExtraContributor(index) {
            this.partners.splice(index, 1);
        },
        discard() {
            let _tenantPartner = this.$store.getters["partner/getCreatedPartner"];
            /* Navigate back to the current tab */
            let tab = this.modeType.split("-")[1];

            if (this.createFromAnotherComp) {
                /* Trip navigate back */
                const { partnertype, previousRoute, routeDetails, typeDetails } = this.createFromAnotherComp;
                const [locationtype] = routeDetails.split("-");

                /* Previous route  */
                this.$router.push({
                    path: previousRoute,
                    query: { type: locationtype, typeDetails: typeDetails }
                });
            } else {
                if (this.isCarrier) {
                    this.$router.push({ path: "/previewcarriers", query: { tab } });
                    this.$store.dispatch("partner/updateCreatedPartner", {
                        type: "cancel",
                        partner: _tenantPartner
                    });
                } else {
                    this.$router.push({ path: "/previewpartners", query: { tab } });
                    this.$store.dispatch("partner/updateCreatedPartner", {
                        type: "cancel",
                        partner: _tenantPartner
                    });
                }
            }
        },
        save() {
            const partnerType = type => {
                switch (type) {
                    case "consignor":
                        return "consignor";
                    case "consignee":
                        return "consignee";
                    case "forwarder":
                        return "forwarder";
                    case "other":
                        return "other";
                    default:
                        return this.isCarrier ? "Carrier" : null;
                }
            };

            let _tenantPartners = [];
            let _permissonsJson = JSON.stringify(this.selectedPermissions);
            let payload = null;

            if (this.modeType.includes("edit")) {
                payload = {
                    id: this.partner.id,
                    name: this.partner.partnerName,
                    partnerType: this.partner.partnerType?.toLowerCase(),
                    referenceNumber: this.partner.referenceNumber,
                    notificationEnabled: this.partner.notification,
                    carrierType: this.partner.carrierType ? this.partner.carrierType : null,
                    carrierId: this.partner.carrier ? this.partner.carrier : null,
                    description: this.partner.description,
                    organisationPermission: _permissonsJson
                };
            } else {
                this.partners.forEach(partner => {
                    _tenantPartners.push({
                        name: partner.partnerName,
                        partnerType: this.isCarrier ? "carrier" : partner.partnerType?.toLowerCase(),
                        referenceNumber: partner.referenceNumber,
                        notificationEnabled: partner.notification,
                        carrierType: partner.carrierType ? partner.carrierType : null,
                        carrierId: partner.carrier ? partner.carrier : null,
                        description: partner.description,
                        organisationPermissions: _permissonsJson
                    });
                });

                payload = {
                    tenantPartners: _tenantPartners
                };
            }

            this.updateMutation(payload)
                .then(response => {
                    if (this.editMode) {
                        this.$store.dispatch("partner/updateCreatedPartner", response.data.updateTenantPartner.partner);
                        let _tenantPartner = this.$store.getters["partner/getCreatedPartner"];

                        /* Navigate back to the current tab */
                        let tab = this.modeType.split("-")[1];

                        if (this.isCarrier) {
                            this.$router.push({
                                path: "/previewcarriers",
                                query: { tab }
                            });
                            this.$store.dispatch("partner/updateCreatedPartner", {
                                type: "cancel",
                                partner: _tenantPartner
                            });
                        } else {
                            this.$router.push({
                                path: "/previewpartners",
                                query: { tab }
                            });
                            this.$store.dispatch("partner/updateCreatedPartner", {
                                type: "cancel",
                                partner: _tenantPartner
                            });
                        }
                    } else {
                        /*Trip - Navigate back to the current component*/
                        if (this.createFromAnotherComp) {
                            /* Previous route detais */
                            const { id } = response.data.createTenantPartners.partner;
                            const {
                                partnertype,
                                previousRoute,
                                routeDetails,
                                typeDetails
                            } = this.createFromAnotherComp;
                            const [locationtype] = routeDetails.split("-");

                            /* get  */
                            let _trip = this.$store.getters["trip/getDraftTrip"];

                            /* update the state */
                            if (partnertype === "carrier") {
                                _trip.tenantCarrier.id = id;
                            } else {
                                _trip.tripPartnerSet.edges.push({
                                    node: {
                                        tenantPartner: {
                                            id: id
                                        }
                                    }
                                });
                            }
                            /* set */
                            this.$store.dispatch("trip/updateCreatedTrip", {
                                type: "draft",
                                trip: _trip
                            });

                            /* Previous route  */
                            this.$router.push({
                                path: previousRoute,
                                query: { type: locationtype, typeDetails: typeDetails }
                            });
                        } else {
                            /* Partner - Navigate Back to the current tab */
                            let tab = this.modeType.split("-")[1];

                            if (this.isCarrier) {
                                this.$router.push({
                                    path: "/previewcarriers",
                                    query: { tab }
                                });
                            } else {
                                this.$router.push({
                                    path: "/previewpartners",
                                    query: { tab }
                                });
                            }

                            const payload = {
                                color: "blue",
                                message: this.$t("trips.partner_saved")
                            };
                            this.$store.dispatch("snackbar/showMessage", payload);
                        }
                    }
                })
                .catch(error => {
                    if (error.message.includes("INVALID_REFERENCE_NUMBER")) {
                        this.$refs["partnerForm0"][0].invalidReferenceNumber = true;
                        this.$refs["partnerForm0"][0].$children[0].validate();
                    }
                    console.log(error);
                });
        },
        updateMutation(payload) {
            const partnerFields = `
                partner {
                    id
                    tenant {
                        id
                        name
                    }
                    createdBy {
                        id
                        firstName
                        lastName
                    }
                    carrier {
                        id
                        code
                        name
                        carrierType
                    }
                    partnerType
                    carrierType
                    description
                    name
                    referenceNumber
                    notificationEnabled
                    organisationPermissions
                }
            `;
            let mutation = null;

            if (this.modeType.includes("edit") && this.modeType !== "edit-carrier") {
                mutation = gql`
                    mutation updatePartner(
                        $input: UpdateTenantPartnerInput!
                    ) {
                        updateTenantPartner(input: $input) {
                            ${partnerFields}
                        }
                    }
                `;
            } else if (this.modeType === "edit-carrier") {
                mutation = gql`
                    mutation updatePartner(
                        $input: UpdateTenantPartnerInput!
                    ) {
                        updateTenantPartner(input: $input) {
                            ${partnerFields}
                        }
                    }
                `;
            } else {
                mutation = gql`
                    mutation createTenantPartners(
                        $input: CreateTenantPartnersInput!
                    ) {
                        createTenantPartners(input: $input) {
                            ${partnerFields}
                        }
                    }
                `;
            }

            return this.$apollo.mutate({
                mutation,
                variables: { input: payload }
            });
        }
    }
};
</script>

<style scoped>
.card-container {
    position: relative;
    height: 80px;
    background-color: #ffffff;
    border-radius: 5px;
}
.containerFooter {
    width: 67%;
    height: 80px;
    background-color: #ffffff;
    border-radius: 5px;
}
.fixed-bottom-card {
    position: fixed;
    bottom: 1.2px;
    left: 16%;
    z-index: 1000;
}
.fixed-bottom-card-1 {
    position: fixed;
    bottom: 50px;
    left: 16%;
    z-index: 0; /* Adjust the z-index as needed */
}
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

/* Your component styles go here */
</style>
