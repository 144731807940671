import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"bottom":"","left":"","close-on-content-click":false,"open-on-hover":_vm.openOnHover,"z-index":"3","transition":"slide-x-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","dark":_vm.dark},on:{"click":_vm.loadMenu}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"width":_vm.width}},[(_vm.title || _vm.description)?_c(VList,[_c(VListItem,[_c(VListItemContent,[(_vm.title)?_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.description)?_c(VListItemSubtitle,{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()],1),(_vm.icon)?_c(VListItemAction,[_c(VIcon,[_vm._v("filter_list_alt")])],1):_vm._e()],1)],1):_vm._e(),_c(VDivider),_c(VCard,{staticClass:"overflow-auto",attrs:{"flat":"","max-height":_vm.height}},[_c(VList,{attrs:{"dense":""}},[_c('draggable',_vm._b({staticClass:"list-group",model:{value:(_vm.newChoices),callback:function ($$v) {_vm.newChoices=$$v},expression:"newChoices"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition"}},_vm._l((_vm.newChoices),function(item,index){return _c('div',{key:index + 0},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VListItem,{staticClass:"list-group-item pl-0",class:hover
                                                    ? 'grey lighten-4'
                                                    : 'white'},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("drag_indicator")])],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.translatedChoices.find( function (f) { return f.code === item.code; } ).name)+" ")])],1),_c(VListItemAction,[_c(VSwitch,{attrs:{"color":"primary"},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)],1)]}}],null,true)})],1)}),0)],1)],1)],1),_c(VDivider),(_vm.showApplyButton)?_c(VCardActions,{staticClass:"d-flex justify-end py-2 pr-3"},[_c(VBtn,{attrs:{"small":"","outlined":"","rounded":"","color":"primary"},on:{"click":_vm.onApplied}},[_vm._v(_vm._s(_vm.$t("general.apply")))])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }