<template>
    <v-dialog v-model="trackerChangeTenantDialog" max-width="500" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                rounded
                color="primary"    
                class="mr-2 mt-2"
                v-bind="attrs"
                v-on="on"
            >
                {{ $t("trackers.assign_tenant") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-2">autorenew</v-icon
                >{{ $t("general.change") }}
                {{ $t("trackers.tracker_tenant") }}
            </v-card-title>
            <v-alert
                style="margin-bottom: 0%; border-radius: 0% 0% 4% 4%;"
                v-if="noTrackersError"
                dense
                type="error"
                elevation="2"
            >
                {{ $t("trackers.no_tracker") }}
            </v-alert>

            <v-card-text style="padding-top: 20px">
                <v-container fluid>
                    <h3 style="margin-bottom: 1%; margin-top: 1%;">
                        {{ $t("trackers.tenant") }}:
                    </h3>
                    <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="12">
                            <v-autocomplete
                                clearable
                                hide-selected
                                hide-details
                                auto-select-first
                                dense
                                outlined
                                :items="tenants.edges"
                                :no-data-text="$t('loading.no_available')"
                                v-model="pendingTenant"
                                item-text="node.name"
                                item-value="node.id"
                                :menu-props="{
                                    maxHeight: '180px',
                                }"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn
                    text
                    @click="
                        trackerChangeTenantDialog = false;
                        noTrackersError = false;
                    "
                    rounded
                    >{{ $t("general.close") }}</v-btn
                >
                <v-btn
                    color="primary"
                    @click="changeTrackersTenant"
                    :loading="isLoadingChangeTracker"
                    rounded
                >
                    {{ $t("general.apply_changes") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "TrackerChangeTenant",
    props: ["opentrackerChangeTenantDialog", "selectedTrackers"],
    apollo: {
        tenants: {
            query: gql`
                query tenants {
                    tenants {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenants,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    data() {
        return {
            trackerChangeTenantDialog: false,
            pendingTenant: null,
            tenants: { edges: [] },
            noTrackersError: false,
            isLoadingChangeTracker: false
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {},
    methods: {
        changeTrackersTenant() {
            if (this.selectedTrackers.length === 0) {
                this.noTrackersError = true;
                return;
            }

            this.isLoadingChangeTracker = true;
            let trackerIdsToMutation = [];
            this.selectedTrackers.forEach(tracker => {
                trackerIdsToMutation.push(tracker.node.id);
            });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ChangeTrackersTenant(
                            $input: ChangeTrackersTenantInput!
                        ) {
                            changeTrackersTenant(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantId: this.pendingTenant,
                            trackersIds: trackerIdsToMutation
                        }
                    }
                })
                .then(() => {
                    this.trackerChangeTenantDialog = false;
                    this.pendingTenant = null;
                    this.isLoadingChangeTracker = false;
                    this.$emit("updateTrackersTable");
                    this.$emit("closeSelector", false);
                })
                .catch(error => {
                    this.isLoadingChangeTracker = false;
                    console.log(error);
                });
        }
    }
};
</script>
