<template>
    <v-container fluid class="pa-0">
        <v-card outlined class="rounded-lg pa-4">
            <v-card-title class="mb-2">
                <div class="title">
                    {{ $t("headers.organisation_information") }}
                </div>
                <v-spacer></v-spacer>

                <v-spacer></v-spacer>
                <v-btn rounded icon color="primary" @click="editTenant" v-if="!editMode">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn v-if="editMode" text :disabled="isSavingSystemProfile" @click="resetSystemProfile">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    v-if="editMode"
                    :loading="isSavingSystemProfile"
                    elevation="0"
                    color="primary"
                    @click="saveSystemProfile"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-title>
            <v-card-text>
                <v-form ref="editSystemProfileForm">
                    <v-row class="py-0">
                        <v-col cols="12">
                            <v-row v-if="!editMode">
                                <v-col cols="2">
                                    <div class="headers">
                                        <span>{{ $t("general.business_name") }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="10">
                                    <div class="body">
                                        {{ editingSystemProfile.name }}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.business_name") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.name"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="12">
                            <v-row v-if="!editMode">
                                <v-col cols="2">
                                    <div class="headers">
                                        <span>{{ $t("general.website") }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="10">
                                    <div class="body" v-if="editingSystemProfile.address.website">
                                        {{ editingSystemProfile.address.website }}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.website") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.website"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="12">
                            <v-row v-if="!editMode">
                                <v-col cols="2">
                                    <div class="headers">
                                        <span>{{ $t("general.phone") }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="10">
                                    <div class="body">
                                        {{ contactPhone(editingSystemProfile.address) }}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="6">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.phone") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.phone"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.mobile") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.mobile"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="12">
                            <v-row v-if="!editMode">
                                <v-col cols="2">
                                    <div class="headers">
                                        <span>{{ $t("general.address") }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="10">
                                    <v-row>
                                        <v-col cols="auto" class="body">
                                            {{ contactAddress(editingSystemProfile.address) }}
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="6">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.street") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.street"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.houseNumber") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.houseNumber"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.suffix") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.suffix"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.zip_code") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.zipCode"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.aptSuiteBldg") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.aptSuiteBldg"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.city") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.city"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.state") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.state"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("general.country") }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editingSystemProfile.address.country"
                                        outlined
                                        :disabled="!editMode"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <div v-if="isSuperUser">
                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-row v-if="!editMode">
                            <v-col cols="2">
                                <div class="headers">
                                    <span>Logo</span>
                                </div>
                            </v-col>
                            <v-col cols="10">
                                <div class="body" v-if="editingSystemProfile.logoUrl">
                                    {{ editingSystemProfile.logoUrl }}
                                </div>
                                <div class="body" v-else>
                                    {{ editingSystemProfile.logoText }}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row align="stretch" v-else>
                            <v-col cols="12">
                                <div :class="editMode ? '' : 'disabled'">
                                    <v-icon left class="mr-2">image</v-icon>
                                    <span>
                                        Logo
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12" class="headers">
                                <v-row>
                                    <v-col>
                                        <div class="headers">
                                            <span>Logo Text</span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    v-model="editingSystemProfile.logoText"
                                    outlined
                                    :disabled="!editMode"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-row>
                                    <v-col class="borderDash ml-4" cols="8" xs="8" sm="8" md="5" lg="5" xl="5">
                                        <div
                                            v-if="previewImg == null && editingSystemProfile.logoUrl == null"
                                            :style="`width:100% ; height:${imgHeight}px`"
                                        >
                                            <span>
                                                Add your logo here
                                            </span>
                                        </div>
                                        <div v-else>
                                            <v-img
                                                label="Logo preview"
                                                contain
                                                width="90%"
                                                :height="imgHeight"
                                                :src="validateInput ? previewImg : editingSystemProfile.logoUrl"
                                                class="logoContainer"
                                            ></v-img>
                                        </div>
                                    </v-col>
                                    <v-col cols="4" class="pa-0 d-flex flex-column justify-space-between">
                                        <v-btn-toggle class="d-flex flex-column " v-if="editMode">
                                            <v-btn icon small :call="isSelecting" @click="handleFileImport">
                                                <v-icon>
                                                    camera
                                                </v-icon>
                                            </v-btn>
                                            <v-btn icon small @click="removePicture">
                                                <v-icon>
                                                    delete
                                                </v-icon>
                                            </v-btn>
                                        </v-btn-toggle>
                                        <div>
                                            <v-file-input
                                                ref="fileInputRef"
                                                outlined
                                                style="display:none"
                                                :rules="[rules.maxSize]"
                                                @change="onFileChange"
                                                :disabled="!editMode"
                                                accept="image/png, image/jpeg, image/jpg, image/bmp, .svg"
                                            ></v-file-input>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <div v-if="editMode" class="caption grey--text text--darken-1 ml-1">
                                            Please upload a high resolution image for best results. <br />
                                            Image size: 130 x 40 pixels <br />
                                            File type: .png, .jpg, .jpeg, .bmp, .svg <br />
                                            Maximum file size: 2MB
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div>
                                    <v-icon left class="mr-2" v-if="editMode">color_lens</v-icon>
                                    <span class="headers">
                                        Theme Colors
                                    </span>
                                </div>
                            </v-col>
                            <v-card class="py-2 px-4" flat :disabled="!editMode">
                                <v-row dense>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <v-card
                                            :color="editingSystemProfile.themeJson.primary"
                                            tile
                                            dark
                                            @click="openColorPickerDialog('primary')"
                                        >
                                            <v-card-title class="subtitle-1">
                                                Primary
                                                <v-spacer></v-spacer>
                                                {{ editingSystemProfile.themeJson.primary }}
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <v-card
                                            :color="editingSystemProfile.themeJson.secondary"
                                            tile
                                            dark
                                            @click="openColorPickerDialog('secondary')"
                                        >
                                            <v-card-title class="subtitle-1">
                                                Secondary
                                                <v-spacer></v-spacer>
                                                {{ editingSystemProfile.themeJson.secondary }}
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <v-card
                                            :color="editingSystemProfile.themeJson.info"
                                            tile
                                            dark
                                            @click="openColorPickerDialog('info')"
                                        >
                                            <v-card-title class="subtitle-1">
                                                Info
                                                <v-spacer></v-spacer>
                                                {{ editingSystemProfile.themeJson.info }}
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <v-card
                                            :color="editingSystemProfile.themeJson.success"
                                            tile
                                            dark
                                            @click="openColorPickerDialog('success')"
                                        >
                                            <v-card-title class="subtitle-1">
                                                Success
                                                <v-spacer></v-spacer>
                                                {{ editingSystemProfile.themeJson.success }}
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <v-card
                                            :color="editingSystemProfile.themeJson.warning"
                                            tile
                                            dark
                                            @click="openColorPickerDialog('warning')"
                                        >
                                            <v-card-title class="subtitle-1">
                                                Warning
                                                <v-spacer></v-spacer>
                                                {{ editingSystemProfile.themeJson.warning }}
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <v-card
                                            :color="editingSystemProfile.themeJson.error"
                                            tile
                                            dark
                                            @click="openColorPickerDialog('error')"
                                        >
                                            <v-card-title class="subtitle-1">
                                                Error
                                                <v-spacer></v-spacer>
                                                {{ editingSystemProfile.themeJson.error }}
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-row>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-5" v-if="isSuperUser">
                <v-spacer></v-spacer>
                <v-btn v-if="editMode" outlined color="grey lighten-1" @click="onDefautThemeColors">
                    Default theme
                </v-btn>
            </v-card-actions>
        </v-card>
        <!-- color picker dialog -->
        <v-dialog v-model="colorPickerDialog" persistent max-width="400px">
            <v-card>
                <v-card-title class="primary white--text mb-2"> Select {{ selectedColorLabel }} color </v-card-title>
                <v-card-text justify="space-around">
                    <v-color-picker v-model="selectedColor" class="mx-auto" show-swatches></v-color-picker>
                </v-card-text>
                <v-card-actions class="pb-3">
                    <v-spacer></v-spacer>
                    <v-btn text rounded @click="colorPickerDialog = false">Cancel</v-btn>
                    <v-btn rounded color="primary" @click="onColorSelected">Select</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import Config from "@/utils/config.json";

export default {
    props: {
        tenant: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loadingBtn: false,
            colorPickerDialog: false,
            defaultTenantsettings: {
                name: null,
                logo: null,
                logoUrl: null,
                logoText: null,
                address: {
                    street: null,
                    houseNumber: null,
                    suffix: null,
                    zipCode: null,
                    city: null,
                    aptSuiteBldg: null,
                    state: null,
                    country: null,
                    phone: null,
                    mobile: null,
                    website: null,
                    tag: null,
                    alias: null
                },
                themeJson: {
                    primary: "#1B6A99",
                    secondary: "#154D79",
                    success: "#C5E17A",
                    info: "#3D9BC8",
                    accent: "#B05C4B",
                    warning: "#ED8E83",
                    error: "#CB5858"
                }
            },
            isSavingSystemProfile: false,
            selectedColor: null,
            selectedColorLabel: "primary",
            editMode: false,
            isSelecting: false,
            validateInput: false,
            previewImg: null,
            imgHeight: 0,
            defaultThemeJson: {
                primary: "#1B6A99",
                secondary: "#154D79",
                success: "#C5E17A",
                info: "#3D9BC8",
                accent: "#B05C4B",
                warning: "#ED8E83",
                error: "#CB5858"
            },
            rules: {
                maxSize: value => !value || value.size < 2000000 || "Logo size should be less than 2 MB!"
            }
        };
    },
    computed: {
        editingSystemProfile: {
            get() {
                return this.defaultTenantsettings;
            },
            set(value) {
                this.defaultTenantsettings = {
                    name: value.name,
                    logo: value.logo,
                    logoUrl: value.logoUrl,
                    logoText: value.logoText,
                    themeJson: value.themeJson,
                    address: {
                        street: value.address.street,
                        houseNumber: value.address.houseNumber,
                        suffix: value.address.suffix,
                        zipCode: value.address.zipCode,
                        city: value.address.city,
                        aptSuiteBldg: value.address.aptSuiteBldg,
                        state: value.address.state,
                        country: value.address.country,
                        phone: value.address.phone,
                        mobile: value.address.mobile,
                        website: value.address.website,
                        tag: value.address.tag,
                        alias: value.address.alias
                    }
                };
            }
        },
        logoImageStyleObject() {
            return {
                maxHeight: "150px"
            };
        },
        testHeight() {
            let width = document.querySelector(".borderDash").clientWidth;
            return width;
        },
        me() {
            return helper.me();
        },
        isSuperUser() {
            return this.me?.isSuperUser;
        }
    },
    mounted() {
        this.$nextTick(() => {
            // single address
            const address = this.tenant?.tenantAddressSet.edges[0]?.node || {};

            this.defaultTenantsettings = {
                name: this.tenant?.name,
                logo: this.tenant?.logo ? this.tenant?.logo : this.defaultTenantsettings.logo,
                logoUrl: this.tenant?.logoUrl ? this.tenant?.logoUrl : this.defaultTenantsettings.logoUrl,
                logoText: this.tenant?.logoText ? this.tenant?.logoText : this.defaultTenantsettings.logoText,
                address: {
                    id: address.id ? address.id : null,
                    street: address.street ? address.street : "",
                    houseNumber: address.houseNumber ? address.houseNumber : "",
                    suffix: address.suffix ? address.suffix : "",
                    zipCode: address.zipCode ? address.zipCode : "",
                    city: address.city ? address.city : "",
                    aptSuiteBldg: address.aptSuiteBldg ? address.aptSuiteBldg : "",
                    state: address.state ? address.state : "",
                    country: address.country ? address.country : "",
                    phone: address.phone ? address.phone : "",
                    mobile: address.mobile ? address.mobile : "",
                    website: address.website ? address.website : "",
                    tag: address.tag ? address.tag : "",
                    alias: address.alias ? address.alias : ""
                },
                themeJson: this.tenant?.themeJson
                    ? JSON.parse(this.tenant?.themeJson)
                    : this.defaultTenantsettings.themeJson
            };
        });
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "tenant_admin_settings_svw"
        });
        this.setUserProperties();
    },
    updated() {
        this.resize();
    },
    methods: {
        editTenant() {
            this.editMode = true;
            this.resize();
        },
        resize() {
            setTimeout(() => {
                let width = document.querySelector(".borderDash")?.clientWidth;
                if (width > 0) {
                    this.imgHeight = width * 0.75;
                } else {
                    this.imgHeight = 100;
                }
            }, 300);
        },
        contactAddress(address) {
            let contact = "";
            if (address.street) {
                contact += address.street;
            }
            if (address.houseNumber) {
                contact += " " + address.houseNumber;
            }
            if (address.suffix) {
                contact += " " + address.suffix;
            }
            if (address.aptSuiteBldg) {
                contact += ", " + address.aptSuiteBldg;
            }
            if (address.zipCode) {
                contact += ", " + address.zipCode;
            }
            if (address.city) {
                contact += " " + address.city;
            }
            if (address.state) {
                contact += ", " + address.state;
            }
            if (address.country) {
                contact += ", " + address.country;
            }
            return contact;
        },
        contactPhone(address) {
            let contact = "";
            if (address.phone) {
                contact += address.phone;
            }
            if (address.mobile && address.phone) {
                contact += " / " + address.mobile;
            } else if (address.mobile && !address.phone) {
                contact += address.mobile;
            }
            return contact;
        },
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("tenant_admin_details_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("tenant_admin_details_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        isValidObject(obj) {
            if (obj == null || obj == undefined) {
                return false;
            } else {
                let _obj = JSON.parse(obj);
                if (Object.keys(_obj).length == 0) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        onDefautThemeColors() {
            this.editingSystemProfile.themeJson = this.defaultThemeJson;
            Object.entries(this.defaultThemeJson).forEach(([key, value]) => {
                this.$vuetify.theme.themes.light[key] = value;
            });
        },
        resetSystemProfile() {
            const address = this.tenant?.tenantAddressSet.edges[0]?.node || {};
            this.defaultTenantsettings = {
                name: null,
                logo: null,
                logoText: null,
                address: null,
                themeJson: {
                    primary: "#1B6A99",
                    secondary: "#154D79",
                    success: "#C5E17A",
                    info: "#3D9BC8",
                    accent: "#B05C4B",
                    warning: "#ED8E83",
                    error: "#CB5858"
                }
            };

            this.editingSystemProfile = {
                name: this.tenant?.name ? this.tenant?.name : this.defaultTenantsettings.name,
                logo: this.tenant?.logo ? this.tenant?.logo : this.defaultTenantsettings.logo,
                logoText: this.tenant?.logoText ? this.tenant?.logoText : this.defaultTenantsettings.logoText,
                logoUrl: this.tenant?.logoUrl ? this.tenant?.logoUrl : this.defaultTenantsettings.logoUrl,
                address: {
                    street: address.street ? address.street : "",
                    houseNumber: address.houseNumber ? address.houseNumber : "",
                    suffix: address.suffix ? address.suffix : "",
                    zipCode: address.zipCode ? address.zipCode : "",
                    city: address.city ? address.city : "",
                    aptSuiteBldg: address.aptSuiteBldg ? address.aptSuiteBldg : "",
                    state: address.state ? address.state : "",
                    country: address.country ? address.country : "",
                    phone: address.phone ? address.phone : "",
                    mobile: address.mobile ? address.mobile : "",
                    website: address.website ? address.website : "",
                    tag: address.tag ? address.tag : "",
                    alias: address.alias ? address.alias : ""
                },
                themeJson: this.isValidObject(this.tenant?.themeJson)
                    ? JSON.parse(this.tenant?.themeJson)
                    : this.defaultTenantsettings.themeJson
            };
            this.editMode = false;
        },
        onFileChange(file) {
            if (!file) {
                this.editingSystemProfile.logo = null;
                this.editingSystemProfile.logoUrl = null;
            } else {
                this.validateInput = this.$refs.fileInputRef.validate();
                this.previewImg = URL.createObjectURL(file);
                this.createImage(file);
            }
        },
        createImage(file) {
            let reader = new FileReader();
            reader.onload = e => {
                this.editingSystemProfile.logoUrl = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        openColorPickerDialog(colorLabel) {
            this.colorPickerDialog = true;
            this.selectedColorLabel = colorLabel;
            this.selectedColor = this.editingSystemProfile.themeJson[colorLabel];
        },
        onColorSelected() {
            this.editingSystemProfile.themeJson[this.selectedColorLabel] = this.selectedColor;
            /* this.$vuetify.theme.themes.light[
                this.selectedColorLabel
            ] = this.selectedColor; */
            this.colorPickerDialog = false;
        },
        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener(
                "focus",
                () => {
                    this.isSelecting = false;
                },
                { once: true }
            );

            this.$refs.fileInputRef.$refs.input.click();
        },
        objectIsPopulated(obj) {
            if (obj == null || obj == undefined) {
                return false;
            } else {
                let array = Object.keys(obj);

                //check if the object have one value different from null or empty
                return array.some(key => obj[key] != null && obj[key] != "");
            }
        },
        removePicture() {
            let remove = {
                logo: null,
                logoUrl: null
            };

            let newObject = {
                ...this.editingSystemProfile,
                ...remove
            };

            this.editingSystemProfile = newObject;
            this.previewImg = null;
            this.$refs.fileInputRef.reset();
        },
        saveSystemProfile() {
            this.isSavingSystemProfile = true;
            let tenantAddressInput = null;

            if (this.objectIsPopulated(this.editingSystemProfile.address)) {
                tenantAddressInput = [];
                tenantAddressInput.push({
                    id: this.editingSystemProfile.address.id || null,
                    street: this.editingSystemProfile.address.street || null,
                    houseNumber: this.editingSystemProfile.address.houseNumber || null,
                    suffix: this.editingSystemProfile.address.suffix || null,
                    zipCode: this.editingSystemProfile.address.zipCode || null,
                    city: this.editingSystemProfile.address.city || null,
                    aptSuiteBldg: this.editingSystemProfile.address.aptSuiteBldg || null,
                    state: this.editingSystemProfile.address.state || null,
                    country: this.editingSystemProfile.address.country || null,
                    phone: this.editingSystemProfile.address.phone || null,
                    mobile: this.editingSystemProfile.address.mobile || null,
                    website: this.editingSystemProfile.address.website || null
                });
            }

            let payload = {
                tenantId: this.tenant.id,
                logo: this.editingSystemProfile.logoUrl,
                logoText: this.editingSystemProfile.logoText,
                themeJson: JSON.stringify(this.editingSystemProfile.themeJson),
                addresses: tenantAddressInput
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTenantSettings($input: UpdateTenantSettingsInput!) {
                            updateTenantSettings(input: $input) {
                                tenant {
                                    id
                                    logo
                                    logoUrl
                                    logoText
                                    themeJson
                                    tenantAddressSet {
                                        edges {
                                            node {
                                                id
                                                mobile
                                                phone
                                                state
                                                street
                                                suffix
                                                website
                                                zipCode
                                                houseNumber
                                                country
                                                city
                                                aptSuiteBldg
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: payload
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("general.update_successfully")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.log("error", error);
                })
                .finally(() => {
                    this.isSavingSystemProfile = false;
                    this.editMode = false;
                });
        }
    }
};
</script>
<style scoped>
.disabled {
    pointer-events: none;
    opacity: 0.4;
}
.buttonContent {
    height: 20px;
    width: 20px;
}

.borderDash {
    border-width: 1px;
    box-sizing: border-box;
    border-color: rgb(192, 192, 192);
    border-style: dashed;
}

.title {
    color: #0e0d35;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
}
.headers {
    color: #868599;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
}
.body {
    color: #0e0d35;
    font-size: 13px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: left;
}
.detailsText {
    color: #868599;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
}
</style>
