import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.tenantPartners.loading,"items":_vm.tenantPartners.edges,"label":_vm.label,"dense":"","hide-selected":"","hide-details":"","outlined":_vm.outLined,"clearable":"","small-chips":_vm.chips,"chips":_vm.chips,"deletable-chips":_vm.chips,"multiple":_vm.multiple,"item-text":"node.name","item-value":"node.id","no-data-text":_vm.$apollo.queries.tenantPartners.loading
                    ? this.$t('loading.loading1')
                    : this.$t('loading.no_available'),"menu-props":{
                maxHeight: '200px'
            }},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [(_vm.activeSlot)?_c(VDivider,{staticClass:"mt-4 append-divider"}):_vm._e(),(_vm.activeSlot)?_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_vm._t("custom-action")],2)],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }