<template>
    <v-card :loading="$apollo.queries.trackerLocations.loading" id="map" v-resize="onResize">
        <v-overlay v-if="noData" absolute opacity>
            <v-alert dense color="grey" type="warning">{{ $t("trackers.no_locations") }}</v-alert>
        </v-overlay>
        <!-- <v-row class="ma-0 px-0"> -->
        <!--side menu-->
        <!-- <v-col cols="12" sm="4" md="3" lg="2" xl="1" class="ma-0 pa-0">
                <div>
                    <v-select></v-select>
                </div>
            </v-col> -->
        <!--In the following div the HERE Map will render-->
        <!-- <v-col cols="12" sm="8" md="9" lg="10" xl="11" class="ma-0 pa-0"> -->
        <div
            id="mapContainer"
            :style="{
                height: mapHeight,
                width: '100%'
            }"
            ref="hereMap"
        >
            <!-- <div
                id="map-fixer"
                
                :style="{width: '100%', height:'100%', background: 'rgba(0,0,0,1)', opacity: '0.5', position: 'absolute'}"
            ><h1 color="white">Use ctrl+scroll to zoom in/out</h1></div> -->
        </div>
        <!-- </v-col>
        </v-row> -->
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";
import Config from "@/utils/config.json";

export default {
    name: "HereMap",
    props: {
        viewType: {
            type: String,
            default: null
        },
        center: {
            type: Object,
            required: false,
            default() {
                return { lat: 38.73422868094384, lng: -9.153389577259775 };
            }
        },
        tracker: {
            type: Object,
            required: true
        }
        // center object { lat: 40.730610, lng: -73.935242 }
    },
    data() {
        return {
            trackerLocations: [],
            trackerLocationsPoints: [],
            platform: null,
            apikey: Config.VUE_APP_MAP_API_KEY,
            // You can get the API KEY from developer.here.com
            mapHeight: "2000px",
            map: null,
            ui: null,
            noData: false
        };
    },
    computed: {},
    apollo: {
        trackerLocations: {
            query: gql`
                query trackerLocations($trackerId: ID!, $orderBy: String) {
                    trackerLocations(tracker: $trackerId, orderBy: $orderBy) {
                        edges {
                            node {
                                id
                                deviceTime
                                latitude
                                longitude
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    trackerId: this.tracker.id,
                    orderBy: "deviceTime"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            debounce: 0,
            result(data) {
                this.trackerLocationsPoints.push(
                    ...data.data.trackerLocations.edges.map(item => {
                        return {
                            lat: item.node.latitude,
                            lng: item.node.longitude,
                            time: item.node.deviceTime
                        };
                    })
                );
                if (this.trackerLocationsPoints.length > 0) this.createLine(this.trackerLocationsPoints);
                //TODO what to do if there are no locations
                else this.noData = true;
            }
        }
    },
    async mounted() {
        // Initialize the platform object:
        const platform = new window.H.service.Platform({
            apikey: this.apikey
        });
        this.platform = platform;
        this.initializeHereMap();
        this.onResize();

        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "tracker_map_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("tracker_map_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("tracker_map_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        onResize() {
            this.$nextTick(() => {
                if (this.viewType == "tab") {
                    this.mapHeight = window.innerHeight - 220 + "px";
                } else if (this.viewType == "list") {
                    this.mapHeight = window.innerHeight * 0.5 + "px";
                } else {
                    this.mapHeight = window.innerHeight * 0.5 + "px";
                }
            });
        },
        initializeHereMap() {
            // Rendering map
            const mapContainer = this.$refs.hereMap;
            const H = window.H;
            // Obtain the default map types from the platform object and set minimum zoom level
            var maptypes = this.platform.createDefaultLayers();
            maptypes.vector.normal.map.setMin(2);

            // Instantiate and display a map object
            this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
                center: this.center,
                zoom: 10 // Added default zoom level for initial view
            });

            // Resize map on window resize
            window.addEventListener("resize", () => this.map.getViewPort().resize());

            // Add map behavior control (e.g., zoom and pan)
            new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

            // Add default UI components
            this.ui = H.ui.UI.createDefault(this.map, maptypes);

            // Use a string to represent the map style in HERE tile URL
            const style = "normal.day"; // This can be customized
            const apikey = Config.VUE_APP_MAP_API_KEY;

            // Custom tile provider with dynamic URL replacement
            const customTileProvider = new H.map.provider.ImageTileProvider({
                min: 2,
                max: 20,
                getURL: (column, row, level) => {
                    // Ensure the URL is correctly formatted with actual tile coordinates and zoom level
                    return `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${style}/${level}/${column}/${row}/512/png8?apiKey=${apikey}&ppi=320`;
                }
            });

            // Create and add the custom tile layer to the map
            const customTileLayer = new H.map.layer.TileLayer(customTileProvider);
            this.map.addLayer(customTileLayer);

            // Add fullscreen map button
            this.addFullscreenToggleButton(this.ui);
        },
        addFullscreenToggleButton(ui) {
            const mapContainer = this.$refs.hereMap;
            const H = window.H;
            // Create a custom UI control for the fullscreen button
            const fullscreenControl = new H.ui.Control();
            fullscreenControl.addClass("fullscreenControl");
            ui.addControl("fullscreenControl", fullscreenControl);

            // Create a panel to hold the fullscreen button
            const fullscreenPanel = new H.ui.base.OverlayPanel();
            fullscreenPanel.addClass("fullscreenPanel");
            fullscreenControl.addChild(fullscreenPanel);

            // Store the original styles of the map container for restoring later
            const mapDiv = ui.getMap().getElement();
            const originalStyles = {
                position: mapDiv.style.position,
                width: mapDiv.style.width,
                height: mapDiv.style.height,
                top: mapDiv.style.top,
                left: mapDiv.style.left,
                zIndex: mapDiv.style.zIndex,
                overflow: document.body.style.overflow
            };

            // Create the fullscreen toggle button
            const fullscreenButton = new H.ui.base.PushButton({
                label: "",
                onStateChange: () => {
                    const isFullscreen = fullscreenButton.getState() === H.ui.base.Button.State.DOWN;
                    if (isFullscreen) {
                        //full screen
                        mapDiv.style.position = "fixed";
                        mapDiv.style.width = "100%";
                        mapDiv.style.height = "100%";
                        mapDiv.style.top = "0";
                        mapDiv.style.left = "0";
                        mapDiv.style.zIndex = "9999";
                        document.body.style.overflow = "hidden";
                        this.$emit('fullscreenChange', true);

                    } else {
                        // Exit full screen and restore original styles
                        mapDiv.style.position = originalStyles.position;
                        mapDiv.style.width = originalStyles.width;
                        mapDiv.style.height = originalStyles.height;
                        mapDiv.style.top = originalStyles.top;
                        mapDiv.style.left = originalStyles.left;
                        mapDiv.style.zIndex = originalStyles.zIndex;
                        document.body.style.overflow = originalStyles.overflow;
                        this.$emit('fullscreenChange', false);
                    }

                    // Ensure the map resizes to fit the new dimensions
                    ui.getMap()
                        .getViewPort()
                        .resize();
                }
            });

            // Add the button to the panel and open it
            fullscreenPanel.addChild(fullscreenButton);
            fullscreenPanel.setState(H.ui.base.OverlayPanel.State.OPEN);

            setTimeout(() => {
                //get H_overlay element and change the style size 
                const panel = document.querySelector(".H_overlay");
               
                //display color transparent and elevation 0
                panel.style.backgroundColor = "transparent";
                panel.style.boxShadow = "0 0 0 0";
                
                // Get the button element and add an icon
                const buttonElement = fullscreenButton.getElement();
                if (buttonElement) {
                    // Clear existing content and add an icon
                    buttonElement.innerHTML = `<div class="fullscreen-button">
                        <i class="material-icons">zoom_out_map</i>
                    </div>`;

                    // Apply additional styles if needed
                    buttonElement.querySelector(".fullscreen-button").style.cursor = "pointer";
                    // size of the button
                    buttonElement.style.width = "18px";
                    buttonElement.style.height = "18px";
                    // button rounded
                    buttonElement.style.borderRadius = "10%";

                    //fix the oveyrlay style before btn and after btn
                    this.hOverlayStyle();
                }
            }, 0);
        },
        hOverlayStyle() {
            const style = document.createElement('style');
            document.head.appendChild(style);
            style.sheet.insertRule('.H_overlay::before, .H_overlay::after { display: none !important; }', 0);
        },
        createLine(data) {
            var svgCurrentLocationActive =
                '<svg xmlns="http://www.w3.org/2000/svg" height="50" width="50">' +
                '<circle cx="25" cy="25" r="25" fill="#1876D2" opacity="0.3" />' +
                '<circle cx="25" cy="25" r="8" stroke="white" stroke-width="1" fill="#1876D2" /></svg> ';
            var svgCurrentLocationInactive =
                '<svg xmlns="http://www.w3.org/2000/svg" height="50" width="50">' +
                '<circle cx="25" cy="25" r="25" fill="black" opacity="0.3" />' +
                '<circle cx="25" cy="25" r="8" stroke="white" stroke-width="1" fill="grey" /></svg> ';

            var svgOrigin =
                '<svg xmlns="http://www.w3.org/2000/svg" height="40" width="50">' +
                '<circle cx="25" cy="25" r="6" stroke="white" stroke-width="1" fill="grey" /></svg> ';

            // var svgOrigin =
            //     '<svg width="36px" height="36px" viewBox="-4 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
            //     '<g id="Vivid.JS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)">' +
            //     '<g id="Icons" transform="translate(37.000000, 169.000000)"><g id="map-marker" transform="translate(78.000000, 468.000000)">' +
            //     '<g transform="translate(10.000000, 6.000000)">' +
            //     '<path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" id="Shape" fill="#1876D2"></path>' +
            //     '<circle id="Oval" fill="white" fill-rule="nonzero" cx="14" cy="14" r="7"></circle></g></g></g></g></g></svg>';

            var linestring = new window.H.geo.LineString();
            for (let i = 0; i < data.length; i++) {
                if (i == 0) {
                    var iconOrigin = new window.H.map.Icon(svgOrigin, {
                            anchor: {
                                x: 25,
                                y: 25
                            }
                        }),
                        coordsOrigin = { lat: data[i].lat, lng: data[i].lng },
                        markerOrigin = new window.H.map.Marker(coordsOrigin, {
                            icon: iconOrigin
                        });
                    linestring.pushPoint(data[i]);
                    this.map.addObject(markerOrigin);
                } else if (i == data.length - 1) {
                    let svg = null;
                    if (moment(data[i].time) > moment().subtract(1, "days")) {
                        svg = svgCurrentLocationActive;
                    } else {
                        svg = svgCurrentLocationInactive;
                    }
                    var icon = new window.H.map.Icon(svg, {
                            anchor: {
                                x: 25,
                                y: 25
                            }
                        }),
                        coords = { lat: data[i].lat, lng: data[i].lng },
                        marker = new window.H.map.Marker(coords, {
                            icon: icon
                        });
                    linestring.pushPoint(data[i]);
                    this.map.addObject(marker);
                } else linestring.pushPoint(data[i]);
            }

            // Initialize a polyline with the linestring:
            if (linestring.getPointCount() >= 2) {
                var polyline = new window.H.map.Polyline(linestring, {
                    style: { lineWidth: 3, strokeColor: "rgba(288, 0, 0, 1)" }
                });
                // Add the polyline to the map:
                this.map.addObject(polyline);

                // Zoom the map to fit the line:
                this.map.getViewModel().setLookAtData({
                    bounds: polyline.getBoundingBox()
                });
            } else {
                this.map.setCenter({ lat: data[0].lat, lng: data[0].lng });
                this.map.setZoom(15);
            }

            setTimeout(() => {
                this.map.setZoom(this.map.getZoom() - 2);
            }, 1);
            this.map.getViewPort().resize();
        }
    }
};
</script>

<style scoped>
#map {
    /* width: 60vw; */
    min-width: 360px;
    min-height: 300px;
    text-align: center;
    /* margin: 5% auto; */
    background-color: #ffffff;
}

.fullscreen-button {
    position: absolute;
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    width: 20px; /* Button size */
    height: 20px; /* Button size */
    border: none;
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Adjust icon size if needed */
.fullscreen-button .material-icons {
    font-size: 24px;
}
</style>
