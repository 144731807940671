<template>
    <v-container fluid class="pa-0">
        <v-autocomplete
            ref="autocomplete"
            v-model="selectedTrackers"
            :loading=" !disabled && trackers.length === 0"
            :items="trackers"
            :no-data-text="$t('loading.no_available')"
            :label="label"
            :placeholder="placeholder"
            outlined
            :serch-input="selectedTrackers"
            hide-selected
            :hide-details="hideDetails"
            deletable-chips
            small-chips
            item-text="serialNumber"
            item-value="id"
            multiple
            :dense="dense"
            :disabled="disabled || trackers.length === 0"
            :filled="filled"
            :rules="rules"
            :menu-props="{
                maxHeight: '200px'
            }"
        >
            <template v-slot:item="data">
                <v-row class="ma-0 body-2">
                    <v-col cols="8" class="text-truncate pl-0 ml-0">
                        {{ data.item.serialNumber }}
                    </v-col>
                    <v-col cols="4" class="d-flex justify-end">
                        <!-- <v-icon v-if="data.item.isUsed" left
                            >warning</v-icon
                        > -->
                        <span class="grey--text">{{
                            $t("general.reuse")
                        }}</span>
                    </v-col>
                </v-row>
            </template>
            <template #append-item>
                <v-divider class="mt-4 append-divider"
                    v-if="activeSlot"
                ></v-divider>
                <v-list-item dense class="append py-0"
                    v-if="activeSlot"
                >
                    <v-list-item-content>
                    <!-- Use the slot named 'custom-action' here -->
                    <slot name="custom-action"></slot>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
        
    </v-container>
</template>

<script>
import gql from "graphql-tag";

export default {
    props: {
        value: {
            type: Array
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        dense: {
            type: Boolean,
            default: false
        },
        currentTrackers: {
            type: Array,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        filled: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: true
        }, 
        activeSlot: {
            type: Boolean,
            default: false
        }, 
        trackersInUse: {
            type: Array,
            default: null
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    apollo: {
        availableTrackers: {
            query: gql`
                query TrackersWithStatus {
                    trackersWithStatus {
                        id
                        isUsed
                        serialNumber
                    }
                }
            `,
            variables() {},
            // Additional options here
            fetchPolicy: "no-cache",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackers,
            skip: false,
            result({ loading, data }) {
                if (!loading) {
                    let _trackers = [];
                    if (this.currentTrackers) {
                        this.trackers = [
                            ...data.trackersWithStatus,
                            ...this.currentTrackers
                        ];
                    } else {
                        this.trackers = [...data.trackersWithStatus];
                    }

                   /*  if (this.trackersInUse) {
                        console.log("=>",this.trackersInUse);
                        this.trackers = this.trackers.filter(
                            tracker =>
                                !this.trackersInUse.includes(tracker.id)
                        );
                    } */
                }
            }
        }
    },

    data() {
        return {
            trackers: [],
            trackerSelecteds: []
        };
    },
    computed: {
        selectedTrackers: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    watch: {
        trackers: {
            handler: function(val) {
                if (val.length > 0) {
                    this.$emit(
                        "focusTrackerSelected",
                        this.$refs.autocomplete.isFocused
                    );
                }
            },
            deep: true
        },
    },
    mounted() {
        this.$watch(
            () => {
                return this.$refs.autocomplete.isFocused;
            },
            val => {
                this.trackers.length > 0
                    ? this.$emit("focusTrackerSelected", val)
                    : null;
            }
        );
    },
    created() {},
};
</script>
<style scoped>
    .append {
        position: sticky;
        bottom: 0.1px;
        width: 100%;
        display: flex;
        justify-content: center;
        background: rgb(254, 254, 254);
    }
    .append-divider {
        position: sticky;
    }
</style>

