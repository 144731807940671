import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.goTopShow),expression:"goTopShow"}],staticStyle:{"z-index":"1"},attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","small":"","color":"primary"},on:{"click":_vm.toTop}},[_c(VIcon,[_vm._v("keyboard_arrow_up")])],1),_c('div',[_c(VRow,{staticClass:"ma-0 px-3 d-flex align-center"},[_c(VCol,{attrs:{"cols":"auto"}},[_c('h2',{staticClass:"ml-3 display-3 font-weight-medium d-flex align-top"},[_vm._v(" "+_vm._s(_vm.$t("home.users"))+" ")])]),_c(VCol,{staticClass:"px-0",attrs:{"cols":"auto"}}),_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTabs,{staticClass:"justify-center",attrs:{"background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v(_vm._s(_vm.$t("home.staffs")))]),_c(VTab,[_vm._v(_vm._s(_vm.$t("home.groups")))])],1)],1)],1)],1)],1),_c('TutorialDialog',{attrs:{"dialog":_vm.tutorialDialog},on:{"update:dialog":function($event){_vm.tutorialDialog=$event}}}),_c(VCard,{staticStyle:{"background-color":"#F6F8FA"},attrs:{"flat":"","min-height":"73vh","fill-width":""}},[_c('router-view',{on:{"loading":_vm.onLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }