<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <LoadingBar v-if="$apollo.queries.trip.loading"></LoadingBar>
            <v-card v-else elevation="5" class="mb-5 pb-0" rounded="xl">
                <v-card-title class="background2 py-3">
                    <v-icon class="mx-3">directions_boat</v-icon>

                    <span class="mr-3 subtitle-2">{{ carrierObj.name }}</span>
                    <!-- <div class="mr-2">
                        <v-icon>
                            date_range
                        </v-icon>
                    </div> -->
                    <v-spacer></v-spacer>
                    <div v-if="canEdit">
                        <v-btn v-if="!editedMode" class="primary--text" rounded small icon @click="editMode">
                            <v-icon>edit</v-icon></v-btn
                        >
                        <v-btn v-if="editedMode" class="mx-2" rounded outlined @click="cancelChanges">{{
                            $t("general.cancel")
                        }}</v-btn>
                        <v-btn rounded v-if="editedMode" color="primary" @click="saveChanges" :loading="loadingBtn">
                            {{ $t("general.save") }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-card class="my-3 pr-3" outlined>
                        <LoadPointForm
                            ref="load"
                            v-model="tripSeaLoadPoint"
                            :disabled="!editedMode"
                            :hasMissingFields.sync="hasMissingFields"
                        ></LoadPointForm>
                    </v-card>
                    <v-card class="pr-3" outlined>
                        <SeaTransportForm
                            ref="sea"
                            :tripSeaTransport.sync="seaTransport"
                            :disabled="!editedMode"
                            v-on:updateHasMissingFields="updateHasMissingFields"
                        ></SeaTransportForm>
                    </v-card>
                </v-card-text>
            </v-card>
            <v-row dense>
                <v-col cols="12" sm="12" lg="6" v-for="(overview, i) in vesselOverviews" :key="i">
                    <v-card elevation="5" rounded="xl">
                        <v-card-title
                            v-if="overview.voyageProgress.progress < 100"
                            class="primary justify-center"
                            color="white"
                        >
                            <v-icon class="mr-1" color="white">directions_boat</v-icon>
                            <span style="color: white;">{{ overview.vesselName ? overview.vesselName : "" }}</span>
                            <span class="ml-1 subtitle-2" style="color: white;" v-if="overview.voyage">
                                {{ overview.voyage }}
                            </span>
                            <v-spacer></v-spacer>
                            <v-tooltip top v-if="overview.voyageProgress.daysleft.includes('Day')">
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        style="color: white;"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="text-capitalize subtitle-2"
                                    >
                                        {{
                                            overview.voyageProgress.daysleft
                                                ? overview.voyageProgress.daysleft.toLowerCase()
                                                : ""
                                        }}
                                    </span>
                                </template>
                                <span>{{ $t("date.days_to") }} ETA</span>
                            </v-tooltip>
                            <span style="color: white;" v-else>{{ overview.voyageProgress.daysleft }}</span>
                            <v-spacer></v-spacer>
                            <v-btn rounded color="grey lighten-2" @click="openDialog(overview, i)">
                                {{ $t("trips.history") }}
                            </v-btn>
                        </v-card-title>
                        <v-card-title v-else class="background2 justify-center text-capitalize">
                            <v-icon>directions_boat</v-icon>
                            {{ overview.vesselName ? overview.vesselName : "" }}
                            <span class="ml-1 subtitle-2" v-if="overview.voyage">
                                {{ overview.voyage }}
                            </span>
                            <v-spacer></v-spacer>
                            <v-tooltip top v-if="overview.voyageProgress.daysleft.includes('Day')">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="text-capitalize">{{
                                        overview.voyageProgress.daysleft
                                            ? overview.voyageProgress.daysleft.toLowerCase()
                                            : ""
                                    }}</span>
                                </template>
                                <span>{{ $t("date.days_to") }} ETA</span>
                            </v-tooltip>
                            <span v-else class="text-capitalize">{{
                                overview.voyageProgress.daysleft ? overview.voyageProgress.daysleft.toLowerCase() : ""
                            }}</span>
                            <v-spacer></v-spacer>
                            <v-btn rounded color="primary" @click="openDialog(overview, i)">
                                {{ $t("trips.history") }}
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="mt-3">
                            <v-card min-height="80" flat>
                                <v-row class="mt-0 mx-0">
                                    <v-col cols="6" class="text-left">
                                        <span class="body-1"
                                            ><b>{{ overview.originName || overview.origin }}</b></span
                                        >
                                        ( {{ overview.origin }} )
                                        <br />
                                        <span v-if="overview.ATD"
                                            ><b>ATD: </b
                                            >{{ addTimezoneToDate(overview.ATD, overview.originTimezone) }}</span
                                        >
                                        <span v-else-if="overview.ETD"
                                            ><b>ETD: </b
                                            >{{ addTimezoneToDate(overview.ETD, overview.originTimezone) }}</span
                                        >
                                    </v-col>

                                    <v-col cols="6" class="text-right">
                                        <span class="body-1"
                                            ><b>{{ overview.destinationName || overview.destination }}</b></span
                                        >
                                        ( {{ overview.destination }} )
                                        <br />
                                        <span v-if="overview.ATA || overview.destinationATD">
                                            <b>{{ overview.ATA ? "ATA: " : "ATD: " }}</b>
                                            <span v-if="overview.ATA">{{
                                                addTimezoneToDate(overview.ATA, overview.destinationTimezone)
                                            }}</span>
                                            <span v-else>{{
                                                addTimezoneToDate(overview.destinationATD, overview.destinationTimezone)
                                            }}</span>
                                        </span>
                                        <span v-else-if="overview.ETA || overview.destinationETD">
                                            <b>{{ overview.ETA ? "ETA: " : "ETD: " }}</b>
                                            <span v-if="overview.ETA">{{
                                                addTimezoneToDate(overview.ETA, overview.destinationTimezone)
                                            }}</span>
                                            <span v-else>{{
                                                addTimezoneToDate(overview.destinationETD, overview.destinationTimezone)
                                            }}</span>
                                        </span>
                                        <span v-else-if="overview.ETD"
                                            ><b>ETD: </b
                                            >{{ addTimezoneToDate(overview.ETD, overview.originTimezone) }}</span
                                        >
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-slider
                                class="mt-15 px-0"
                                v-model="overview.voyageProgress.progress"
                                :thumb-size="30"
                                thumb-label="always"
                                thumb-color="primary"
                                track-color="grey lighten-2"
                                :color="overview.voyageProgress.progress < 100 ? 'primary' : 'grey'"
                                hide-details
                                readonly
                            >
                                <template v-slot:thumb-label>
                                    <v-icon color="white" small :readonly="false">directions_boat</v-icon>
                                </template>
                            </v-slider>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- Dialog o vessel timeline -->
                <v-dialog v-model="dialog" width="800">
                    <v-card flat>
                        <v-card-title class="primary white--text">
                            <v-icon color="white" class="mr-2">directions_boat</v-icon>
                            <span>
                                {{ $t("trips.vessel_timeline") }}
                                ( {{ $t("date.last") }} 2
                                {{ $t("date.months") }}
                                )
                            </span>
                        </v-card-title>

                        <v-card-text>
                            <LoadingBar v-if="$apollo.queries.vesselEventTimelines.loading"></LoadingBar>
                            <div v-else-if="timelineVessel.length > 0">
                                <div class="mt-5 ml-9">
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{
                                                on
                                            }"
                                        >
                                            <v-btn
                                                v-on="on"
                                                small
                                                @click="sortDesc = !sortDesc"
                                                icon
                                                class=" ml-8 primary elevation-2"
                                                outlined
                                                :disabled="!timelineVessel"
                                            >
                                                <v-icon v-if="sortDesc" color="white">arrow_downward</v-icon>
                                                <v-icon color="white" v-else>arrow_upward</v-icon>
                                            </v-btn>
                                        </template>
                                        <span
                                            >{{ $t("general.sort") }} {{ sortDesc ? "ascending" : "descending" }}</span
                                        >
                                    </v-tooltip>
                                    <v-timeline dense align-top>
                                        <div v-for="(item, i) in timelineVessel" :key="i">
                                            <v-timeline-item>
                                                <v-card flat>
                                                    <v-row class="pt-1">
                                                        <v-col cols="3">
                                                            <strong>
                                                                {{ formatDateTime(item.node.eventTime) }}
                                                            </strong>
                                                            <div class="text-caption">
                                                                {{ item.node.eventType }}
                                                            </div>
                                                        </v-col>

                                                        <v-col cols="5">
                                                            <strong> {{ item.node.portName }}, </strong>
                                                            <strong v-if="item.node.portCode">
                                                                ({{ item.node.portCode }}),
                                                            </strong>
                                                            <strong>
                                                                {{ item.node.portType }}
                                                            </strong>
                                                            <div class="text-caption">
                                                                {{ item.node.portCountry }}
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-timeline-item>
                                        </div>
                                    </v-timeline>
                                </div>
                            </div>
                            <div v-else-if="timelineVessel.length == 0">
                                <div class="mt-5 px-9">
                                    <v-card flat>
                                        <v-card-text>
                                            <div class="text-center">
                                                <span class="subtitle-2">
                                                    {{ $t("loading.no_available") }}
                                                </span>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div>
                        </v-card-text>
                        <v-divider></v-divider>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
    </v-sheet>
</template>
<script>
import gql from "graphql-tag";

import LoadPointForm from "@/components/trips/forms/LoadPointForm.vue";
import SeaTransportForm from "@/components/trips/forms/SeaTransportForm.vue";
import helper from "@/utils/helper.js";
import LoadingBar from "@/components/base/LoadingBar.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import Config from "@/utils/config.json";

export default {
    components: {
        LoadPointForm,
        SeaTransportForm,
        LoadingBar,
        NoPermission
    },
    props: {
        trip: {
            type: Object,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    apollo: {
        vesselEventTimelines: {
            query: gql`
                query vesselEventTimelines($vessel: ID, $eventTimeGte: DateTime, $eventTimeLte: DateTime) {
                    vesselEventTimelines(vessel: $vessel, eventTime_Gte: $eventTimeGte, eventTime_Lte: $eventTimeLte) {
                        edges {
                            node {
                                eventTime
                                eventType
                                id
                                portCode
                                portCountry
                                portName
                                portType
                                timezone
                                vessel {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    vessel: this.vessel,
                    eventTimeGte: this.eventTimeGte,
                    eventTimeLte: this.eventTimeLte
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.vesselEventTimelines,
            result(data) {
                return data;
            },
            skip() {
                return !this.vessel;
            }
        },
        trip: {
            query: gql`
                query trip($tripId: ID!) {
                    trip(id: $tripId) {
                        id
                        trade
                        logco
                        loadPoint
                        loadDate
                        tenantCarrier {
                            id
                            name
                            carrier {
                                id
                                name
                                website
                                code
                            }
                        }
                        tripFreightSet {
                            edges {
                                node {
                                    id
                                    carrierEvents
                                    lastCarrierEventsSyncTime
                                    airlineLink
                                    airlineName
                                }
                            }
                        }
                        tripSeaTransportSet {
                            edges {
                                node {
                                    id
                                    sequence
                                    portCode
                                    portTransportType
                                    voyage
                                    vessel {
                                        id
                                        name
                                    }
                                    portName
                                    portTimezone
                                    vesselInputType
                                    vesselInputValue
                                    actualDepartureDate
                                    actualArrivalDate
                                    estimatedDepartureDate
                                    estimatedArrivalDate
                                    originalEstimatedDepartureDate
                                    originalEstimatedArrivalDate
                                    actualDepartureDateSource
                                    actualArrivalDateSource
                                    estimatedDepartureDateSource
                                    estimatedArrivalDateSource
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return { tripId: this.trip?.id };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trip,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            // debounce: 800,
            skip() {
                return !this.trip?.id;
            },
            result({ data, loading }) {
                if (!loading) {
                    this.tripSeaLoadPoint = {
                        trade: data?.trip?.trade,
                        logco: data?.trip?.logco,
                        loadPoint: data?.trip?.loadPoint,
                        loadDate: data?.trip?.loadDate
                    };

                    if (data?.trip?.tripSeaTransportSet?.edges.length > 0) {
                        this.seaTransport = JSON.parse(
                            JSON.stringify(
                                data.trip.tripSeaTransportSet.edges
                                    .map(e => e.node)
                                    .sort((a, b) => a.sequence - b.sequence)
                            )
                        );
                        this.defaultSeaTransport = JSON.parse(
                            JSON.stringify(
                                data.trip.tripSeaTransportSet.edges
                                    .map(e => e.node)
                                    .sort((a, b) => a.sequence - b.sequence)
                            )
                        );
                    }
                }
                this.vesselOverviews = [];
                for (let i = 0; i < this.seaTransport.length; i++) {
                    if (this.seaTransport[i]?.vessel?.id != null) {
                        if (i == 0) {
                            if (this.seaTransport[i + 1].vessel?.id != this.seaTransport[i].vessel?.id) {
                                this.vesselOverviewParser(
                                    this.seaTransport[i],
                                    this.seaTransport[i + 1],
                                    this.seaTransport[i + 2]
                                        ? this.nextTripSeaTransportHasDepartedOrArrived(
                                              this.seaTransport[i + 1],
                                              this.seaTransport[i + 2]
                                          )
                                        : false
                                );
                            } else if (
                                this.seaTransport[i + 1].vessel?.id == this.seaTransport[i].vessel?.id &&
                                this.seaTransport[i + 1].actualDepartureDate == null
                            ) {
                                this.vesselOverviewParser(this.seaTransport[i], this.seaTransport[i + 1], false);
                            }
                        } else if (i + 1 < this.seaTransport.length) {
                            if (
                                this.seaTransport[i + 1].vessel?.id != this.seaTransport[i].vessel?.id &&
                                this.seaTransport[i - 1].vessel?.id != this.seaTransport[i].vessel?.id
                            ) {
                                this.vesselOverviewParser(
                                    this.seaTransport[i],
                                    this.seaTransport[i + 1],
                                    this.seaTransport[i + 2]
                                        ? this.nextTripSeaTransportHasDepartedOrArrived(
                                              this.seaTransport[i + 1],
                                              this.seaTransport[i + 2]
                                          )
                                        : false
                                );
                            } else if (
                                this.seaTransport[i + 1].vessel?.id == this.seaTransport[i].vessel?.id &&
                                this.seaTransport[i + 1].actualDepartureDate == null
                            ) {
                                this.vesselOverviewParser(this.seaTransport[i], this.seaTransport[i + 1], false);
                            } else if (
                                this.seaTransport[i - 1].vessel?.id == this.seaTransport[i].vessel?.id &&
                                this.seaTransport[i].actualArrivalDate != null
                            ) {
                                this.vesselOverviewParser(this.seaTransport[i], this.seaTransport[i - 1], false);
                            } else if (
                                this.seaTransport[i - 1].vessel?.id == this.seaTransport[i].vessel?.id &&
                                (this.seaTransport[i].actualArrivalDate != null ||
                                    this.seaTransport[i + 1].portTransportType == "POD")
                            ) {
                                this.vesselOverviewParser(this.seaTransport[i], this.seaTransport[i + 1], false);
                            }
                        }
                    }
                }

                this.tenantCarrier = data?.trip?.tenantCarrier;
                this.carrierEvents = data?.trip?.tripFreightSet.edges[0];
            }
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TripPermissions = TripPermissions;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
    },
    created() {
        this.formatDateTimeWithoutTimezone = helper.formatDateTimeWithoutTimezone;
        this.formatDateTime = helper.formatDateTime;
    },
    data() {
        return {
            dialog: false,
            vesselEventTimelines: { edges: [] },
            sortDesc: false,
            vessel: null,
            eventTimeGte: null,
            eventTimeLte: null,
            hasMissingFields: false,
            seaTransport: [
                {
                    sequence: 0,
                    voyage: "",
                    portCode: "",
                    portTransportType: "POL",
                    vesselInputType: "",
                    vesselInputValue: "",
                    originalEstimatedDepartureDate: "",
                    estimatedDepartureDate: "",
                    actualDepartureDate: ""
                },
                {
                    sequence: 1,
                    portCode: "",
                    portTransportType: "POD",
                    estimatedArrivalDate: "",
                    actualArrivalDate: ""
                }
            ],
            defaultSeaTransport: [
                {
                    sequence: 0,
                    voyage: "",
                    portCode: "",
                    portTransportType: "POL",
                    vesselInputType: "",
                    vesselInputValue: "",
                    estimatedDepartureDate: "",
                    actualDepartureDate: ""
                },
                {
                    sequence: 1,
                    portCode: "",
                    portTransportType: "POD",
                    estimatedArrivalDate: "",
                    actualArrivalDate: ""
                }
            ],
            tripSeaLoadPoint: {
                trade: "",
                logco: "",
                loadPoint: "",
                loadDate: null
            },
            editedMode: false,
            loadingBtn: false,
            vesselOverviews: [],
            type: [
                { text: this.$t("trips.vessel_name"), value: "shipname" },
                { text: this.$t("trips.call_sign"), value: "callsign" },
                { text: "IMO", value: "imo" },
                { text: "MMSI", value: "mmsi" }
            ],
            tenantCarrier: null,
            carrierEvents: null
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_SEA_TRANSPORT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_SEA_TRANSPORT,
                        this.organisationPermissions
                    )
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_SEA_TRANSPORT,
                        this.trip.tenant.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.VIEW_TRIP_SEA_TRANSPORT) && this.me?.tenant) return false;
            }
            return true;
        },
        carrierObj() {
            const { tenantCarrier, tripFreightSet } = this.trip;

            return {
                name: tenantCarrier?.carrier?.name || tenantCarrier?.name || null
            };
        },
        canEdit() {
            if (this.trip?.status === "COMPLETED") return false;

            if (this.isSharedTrip) return false;

            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_SEA_TRANSPORT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_SEA_TRANSPORT,
                        this.organisationPermissions
                    )
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_SEA_TRANSPORT,
                        this.trip.tenant.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.EDIT_TRIP_SEA_TRANSPORT) && this.me?.tenant) return false;
            }
            return true;
        },
        timelineVessel() {
            let event = [];

            if (this.vesselEventTimelines?.edges) {
                event = JSON.parse(JSON.stringify(this.vesselEventTimelines?.edges));

                // sort the timeline by event time
                event.sort((a, b) => {
                    return this.sortDesc
                        ? new Date(b.node.eventTime) - new Date(a.node.eventTime)
                        : new Date(a.node.eventTime) - new Date(b.node.eventTime);
                });
            }
            return event;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_sea_transport_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_sea_transport_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_sea_transport_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        openDialog(overview, i) {
            this.vessel = null;
            this.eventTimeGte = null;
            this.eventTimeLte = null;

            let _overview = null;
            let _nextOverview = null;
            if (overview.destinationPortTransportType === "POD") {
                _nextOverview = overview;
                _overview = this.vesselOverviews[i - 1];
            } else {
                _nextOverview = this.vesselOverviews[i + 1];
                _overview = overview;
            }

            this.vessel = overview.vesselId;
            if (_overview?.ATD !== undefined || _overview?.ATD !== null) {
                this.eventTimeGte = _overview?.ATD;
            } else if (_overview?.ETD !== undefined || _overview?.ETD !== null) {
                this.eventTimeGte = _overview?.ETD;
            } else {
                this.eventTimeGte =
                    this.trip.plannedDepartureDate || new Date(new Date().setMonth(new Date().getMonth() - 2));
            }

            if (_nextOverview.destinationPortTransportType === "POD") {
                this.eventTimeLte = _nextOverview.ETA || _nextOverview.ATA;
            } else if (_nextOverview?.ATD !== undefined || _nextOverview?.ATD !== null) {
                this.eventTimeLte = _nextOverview.ATD;
            } else if (_nextOverview?.ETD !== undefined || _nextOverview?.ETD !== null) {
                this.eventTimeLte = _nextOverview.ETD;
            } else {
                this.eventTimeLte =
                    this.trip?.plannedArrivalDate || new Date(new Date().setMonth(new Date().getMonth() + 2));
            }

            this.dialog = true;
        },
        setVesselTimelineFilters(vessel, overview) {
            this.vessel = null;
            this.eventTimeGte = null;
            this.eventTimeLte = null;

            this.vessel = vessel;
            if (overview.ATD) {
                this.eventTimeGte = overview?.ATD;
            } else if (overview.ETD) {
                this.eventTimeGte = overview?.ETD;
            } else {
                this.eventTimeGte = new Date(new Date().setMonth(new Date().getMonth() - 2));
            }

            if (overview.ATA) {
                this.eventTimeLte = overview?.ATA;
            } else if (overview.ETA) {
                this.eventTimeLte = overview?.ETA;
            } else {
                this.eventTimeLte = new Date(new Date().setMonth(new Date().getMonth() + 2));
            }
        },
        updateHasMissingFields(value) {
            this.hasMissingFields = value;
        },
        getDate(date) {
            return this.formatDateTime(date)?.slice(0, 10);
        },
        getFreightCarrierName() {
            if (this.carrierEvents) {
                let _carrierName = null;
                try {
                    let _carrierEvents = JSON.parse(this.carrierEvents.node.carrierEvents);
                    _carrierName = _carrierEvents.carrier?.name;
                } catch (e) {
                    //console.log(e);
                }

                return _carrierName || this.tenantCarrier?.carrier?.name;
            }
            return null;
        },
        editMode() {
            this.editedMode = true;
        },
        cancelChanges() {
            this.editedMode = false;
            this.seaTransport = [...this.defaultSeaTransport];
            //this.$apollo.queries.trip.refresh()
        },
        getEventIcon(event_type) {
            switch (event_type) {
                default:
                    return "";
            }
        },
        saveChanges() {
            this.loadingBtn = true;
            let seaTransportsInput = [];

            this.seaTransport.forEach(i => {
                seaTransportsInput.push({
                    id: i.id,
                    voyage: i.voyage,
                    sequence: i.sequence,
                    portCode: i.portCode,
                    portTransportType: i.portTransportType,
                    vesselInputType: i.vesselInputType,
                    vesselInputValue: i.vesselInputValue,
                    originalEstimatedDepartureDate: helper.convertDateToUTC(i.originalEstimatedDepartureDate),
                    originalEstimatedArrivalDate: helper.convertDateToUTC(i.originalEstimatedArrivalDate),
                    estimatedDepartureDate: helper.convertDateToUTC(i.estimatedDepartureDate),
                    estimatedArrivalDate: helper.convertDateToUTC(i.estimatedArrivalDate),
                    actualDepartureDate: helper.convertDateToUTC(i.actualDepartureDate),
                    actualArrivalDate: helper.convertDateToUTC(i.actualArrivalDate)
                });
            });

            let payload = {
                tripId: this.trip.id,
                trade: this.tripSeaLoadPoint.trade,
                logco: this.tripSeaLoadPoint.logco,
                loadPoint: this.tripSeaLoadPoint.loadPoint,
                loadDate: helper.convertDateToUTC(this.tripSeaLoadPoint.loadDate),
                seaTransports: seaTransportsInput
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTripSeaTransports($input: UpdateTripSeaTransportsInput!) {
                            updateTripSeaTransports(input: $input) {
                                trip {
                                    id
                                    trade
                                    logco
                                    loadPoint
                                    loadDate
                                    tripSeaTransportSet {
                                        edges {
                                            node {
                                                id
                                                sequence
                                                portCode
                                                portTransportType
                                                vesselInputType
                                                vesselInputValue
                                                voyage
                                                sequence
                                                portCode
                                                portTransportType
                                                vesselInputType
                                                vesselInputValue
                                                actualDepartureDate
                                                actualArrivalDate
                                                estimatedDepartureDate
                                                estimatedArrivalDate
                                                originalEstimatedDepartureDate
                                                originalEstimatedArrivalDate
                                                portName
                                                portTimezone
                                                vessel {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("trips.trip_sea_saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.$refs.sea.areFieldsMissing(this.seaTransport);
                    this.loadingBtn = false;
                    this.editedMode = false; //CQEQ8
                });
        },
        vesselOverviewParser(tr1, tr2, isNextTrActive) {
            //isNextTrActive is to verify if the next tr is active and even if we dont have info about current voyage, we will mark this one as not active
            if (!tr1 || !tr2) return;

            let _vesselOverview = {
                id: tr1.id,
                origin: tr1.portCode,
                originName: tr1.portName,
                originTimezone: tr1.portTimezone,
                originPortTransportType: tr1.portTransportType,
                destination: tr2.portCode,
                destinationName: tr2.portName,
                destinationTimezone: tr2.portTimezone,
                destinationPortTransportType: tr2.portTransportType,
                subtitle: null,
                voyage: null,
                vesselName: tr1.vessel.name,
                vesselId: tr1.vessel.id,
                ATD: tr1.actualDepartureDate,
                ATA: tr2.actualArrivalDate,
                ETD: tr1.estimatedDepartureDate || tr1.originalEstimatedDepartureDate,
                ETA: tr2.estimatedArrivalDate || tr2.originalEstimatedArrivalDate,
                destinationETD: tr2.estimatedDepartureDate || tr2.originalEstimatedDepartureDate,
                destinationATD: tr2.actualDepartureDate
            };
            if (tr1.vesselInputType != "shipname") {
                _vesselOverview.subtitle = " ( " + tr1.vesselInputValue + " )";
            }
            if (tr1.voyage) {
                _vesselOverview.voyage = " ( " + tr1.voyage + " )";
            }
            _vesselOverview.voyageProgress = this.calculateVoyageProgress(
                _vesselOverview.ETD,
                _vesselOverview.ATD,
                _vesselOverview.ETA,
                _vesselOverview.ATA,
                isNextTrActive
            );
            this.vesselOverviews.push(_vesselOverview);
        },
        addTimezoneToDate(date, timezone) {
            let _date = new Date(date);
            if (timezone > 0) {
                // _date.setHours(_date.getHours() + timezone);
                return this.formatDateTime(_date) + " (UTC +" + timezone + ")";
            } else if (timezone < 0) {
                // _date.setHours(_date.getHours() - timezone);
                return this.formatDateTime(_date) + " (UTC " + timezone + ")";
            } else return this.formatDateTime(_date) + " (UTC)";
        },
        calculateVoyageProgress(ETD, ATD, ETA, ATA, destinationETD, destinationATD, isNextTrActive) {
            let _ATD = new Date(ATD);
            let _ETD = new Date(ETD);
            let _ETA = ETA ? new Date(ETA) : new Date(destinationETD);
            let _ATA = ATA ? new Date(ATA) : new Date(destinationATD);
            let _now = new Date();
            let _progress = 0;
            let _daysleft = null;

            if (isNextTrActive) {
                _progress = 100;
                _daysleft = "";
            } else if (_ATA) {
                _progress = 100;
                _daysleft = this.$t("trips.arrived1");
            } else if (ATD && !ATA && !ETA) {
                _progress = 50;
                _daysleft = this.$t("trips.unknown");
            } else if (ATD && ETA) {
                _progress = Math.round(((_now - _ATD) / (_ETA - _ATD)) * 100);
                _daysleft = Math.round((_ETA - _ATD - (_now - _ATD)) / (1000 * 60 * 60 * 24));

                if (_daysleft == 0) _daysleft = "0 " + this.$t("date.days");
                else if (_daysleft == 1) _daysleft = "1 " + this.$t("date.day");
                else if (_daysleft < 0) _daysleft = this.$t("trips.delayed");
                else {
                    _daysleft += " " + this.$t("date.days");
                }
            } else if (!ATD && ETD && ETA) {
                _progress = Math.round(((_now - _ETD) / (_ETA - _ETD)) * 100);
                _daysleft = Math.round((_ETA - _ETD - (_now - _ETD)) / (1000 * 60 * 60 * 24));

                if (_daysleft == 0) _daysleft = "0 " + this.$t("date.days");
                else if (_daysleft == 1) _daysleft = "1 " + this.$t("date.day");
                else if (_daysleft < 0) _daysleft = this.$t("trips.delayed");
                else {
                    _daysleft += " " + this.$t("date.days");
                }
            } else {
                _progress = 0;
                _daysleft = "";
            }

            return { progress: _progress, daysleft: _daysleft };
        },
        nextTripSeaTransportHasDepartedOrArrived(tst1, tst2) {
            if (tst1 && tst2) {
                if (tst1.actualDepartureDate || tst2.actualArrivalDate) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }
};
</script>
