<template>
    <LoadingBar v-if="tenantId && $apollo.queries.tenant.loading"></LoadingBar>
    <div v-else>
        <v-card fill-height flat width="100%" v-if="tenant" color="background">
            <v-toolbar flat color="background">
                <!----- TOP LEFT ICONS ---->
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
                <v-btn v-if="shouldShowFullScreenButton" icon @click="changeFullScreen">
                    <v-icon>{{ isFullScreen ? "fullscreen_exit" : "crop_free" }}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>

                <!---- TOP RIGHT ICONS ---->
                <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="editTenantDialogHandler">
                            <v-icon> edit </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="canDelete">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="openDeleteTenantDialog = true">
                            <v-icon>
                                delete
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t("general.delete") }}</span>
                </v-tooltip>

                <!---- EDIT PARTNER DIALOG ---->
                <EditTenantDialog
                    ref="editTenantDialog"
                    :openEditTenantDialog="openEditTenantDialog"
                    v-on:updatedTenant="updateTenantValues"
                    v-on:closeEditTenantDialog="openEditTenantDialog = false"
                    :tenant="tenant"
                ></EditTenantDialog>

                <!---- DELETE PARTNER DIALOG ---->
                <DeleteTenantDialog
                    :openDeleteDialog="openDeleteTenantDialog"
                    :tenantId="tenant.id"
                    v-on:refreshDataTable="closeDrawerAndRefresh"
                    v-on:closeDeleteTenantDialog="openDeleteTenantDialog = false"
                >
                </DeleteTenantDialog>

                <!---- FILTER DIALOG ---->
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display_tabs')"
                    :choices="filterChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                    class="mr-n2"
                >
                </FilterMenu>
            </v-toolbar>

            <v-card-title class="pt-0 px-5">
                <!---- PARTNER GENERAL INFO DISPLAY (NAME, TYPE, REFERENCE...) ---->
                <v-row class="d-flex align-center pt-2 mx-2">
                    <!---- NAME ---->
                    <v-col cols="6" sm="3" class="py-0 px-0 mb-1">
                        <span class="text-caption">{{ $t("headers.name") }}</span
                        ><br />
                        <span class="font-weight-medium">
                            {{ updatedTenant ? updatedTenant.name : tenant.name }}
                        </span>
                    </v-col>
                    <v-spacer></v-spacer>

                    <!---- SELECT TAB OR LIST VIEW ---->
                    <v-col cols="4" sm="3" class="d-flex justify-end">
                        <v-select
                            v-model="viewType"
                            :items="viewChoices"
                            dense
                            item-text="name"
                            item-value="code"
                            return-value
                            outlined
                            hide-details
                            class="fit"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-title>

            <!---- tab viewType ---->
            <TenantDetailTabView v-if="viewType === 'tab'" :tenant="tenant" :selectedFilters="selectedFilters" />

            <!---- list viewType ---->
            <TenantDetailListView
                v-if="viewType === 'list'"
                class="mx-5 pb-10"
                :tenant="tenant"
                :selectedFilters="selectedFilters"
            />
        </v-card>
        <!-- else show a v-card displaying that didnt find what looking for -->
        <v-card fill-height flat width="100%" v-else color="background">
            <v-toolbar flat color="transparent">
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
            </v-toolbar>
            <NotFoundDetailDrawer type="tenant" style="padding-top: 100px;"> </NotFoundDetailDrawer>
        </v-card>
    </div>
</template>

<script>
import FilterMenu from "@/components/base/FilterMenu.vue";
import NotFoundDetailDrawer from "../base/NotFoundDetailDrawer.vue";
import EditTenantDialog from "@/components/tenants/update/EditTenantDialog.vue";
import DeleteTenantDialog from "@/components/tenants/delete/DeleteTenantDialog.vue";
import TenantDetailTabView from "@/components/tenants/TenantDetailTabView.vue";
import TenantDetailListView from "@/components/tenants/TenantDetailListView.vue";
import helper from "@/utils/helper";
import { TenantPermissions } from "@/core/enum";
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import Config from "@/utils/config.json";


export default {
    name: "TenantDetail",
    props: {
        tenantProp: {
            type: Object
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        tenantId: {
            type: String
        }
    },
    components: {
        FilterMenu,
        TenantDetailTabView,
        TenantDetailListView,
        DeleteTenantDialog,
        EditTenantDialog,
        LoadingBar,
        NotFoundDetailDrawer
    },
    apollo: {
        tenant: {
            query: gql`
                query Tenant($tenantId: ID!) {
                    tenant(id: $tenantId) {
                        id
                        name
                        logo
                        logoUrl
                        logoText
                        themeJson
                        trackerStockLimit
                        organisationPermissions
                        organisationPreferences
                        tenantPartnerSet {
                            totalCount
                        }
                        tenantUserSet {
                            totalCount
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return { tenantId: this.tenantId };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenant,
            result(data) {
                if (data.data.tenant) {
                    this.tenantDetail = Object.assign({}, this.tenantProp, data.data.tenant);
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.tenantId;
            }
        }
    },
    data() {
        return {
            updatedTenant: null,
            openDeleteTenantDialog: false,
            openEditTenantDialog: false,
            openStockLevelDialog: false,
            tenantDetail: null,
            viewType: "tab",
            viewChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TenantPermissions = TenantPermissions;
    },
    computed: {
        me() {
            return helper.me();
        },
        tenant() {
            return this.tenantDetail ? this.tenantDetail : this.tenantProp;
        },
        defaultFilterChoices() {
            let _default = [
                {
                    // for filter menu
                    code: "users",
                    name: this.$t("home.users"),
                    tag: "headers.users",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "services",
                    name: this.$t("home.services"),
                    tag: "headers.services",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "statistics",
                    name: this.$t("home.statistics"),
                    tag: "headers.statistics",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "settings",
                    name: this.$t("home.settings"),
                    tag: "headers.settings",
                    enabled: true
                }
            ];

            return _default;
        },
        filterChoices: {
            get() {
                let _filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "tenantDetailFilterChoices");
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    tenantDetailFilterChoices: value
                });
            }
        },
        selectedFilters() {
            let choices = [];
            this.filterChoices.filter(choice => {
                this.defaultFilterChoices.filter(defaultChoice => {
                    if (choice.code === defaultChoice.code && choice.enabled) {
                        choices.push(defaultChoice);
                    }
                });
            });
            return choices;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        canEdit() {
            if (this.me?.isSuperuser) return true;

            if (!this.hasPermission(TenantPermissions.EDIT_TENANT) && !this.me?.isStaff) return false;

            return true;
        },
        canDelete() {
            if (this.me?.isSuperuser) return true;

            if (!this.hasPermission(TenantPermissions.DELETE_TENANT) && !this.me?.isStaff) return false;

            return true;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "tenant_details_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("tenant_details_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("tenant_details_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        editTenantDialogHandler() {
            this.openEditTenantDialog = true;
        },
        closeDrawerAndRefresh() {
            this.$emit("refreshDataTable");
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice.map(item => item);
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updateTenantValues(val) {
            // Close edit tenant dialog if open
            this.$emit("refreshDataTable");
            this.openEditTenantDialog = false;
            this.updatedTenant = val.data.updateTenant.tenant;
        }
    }
};
</script>
<style scoped>
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
</style>
