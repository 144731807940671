const TripPermissionsEnum = Object.freeze({
    //Trip
    VIEW_TRIP: "trips.view_trip",
    CREATE_TRIP: "trips.add_trip",
    EDIT_TRIP: "trips.change_trip",
    DELETE_TRIP: "trips.delete_trip",
    MANAGE_TRIP: "trips.manage_trip",

    VIEW_TRIP_COMMENT: "trips.view_trip_comment",
    VIEW_TRIP_EXTERNAL_SHARE: "trips.view_trip_external_share",
    VIEW_TRIP_ALERT: "trips.view_trip_alert",

    //Trip Partner
    VIEW_TRIP_PARTNER: "trips.view_trippartner",
    CREATE_TRIP_PARTNER: "trips.add_trippartner",
    EDIT_TRIP_PARTNER: "trips.change_trippartner",
    DELETE_TRIP_PARTNER: "trips.delete_trippartner",
    MANAGE_TRIP_PARTNER: "trips.manage_trippartner",

    //Trip Freight
    VIEW_TRIP_FREIGHT: "trips.view_tripfreight",
    CREATE_TRIP_FREIGHT: "trips.add_tripfreight",
    EDIT_TRIP_FREIGHT: "trips.change_tripfreight",
    DELETE_TRIP_FREIGHT: "trips.delete_tripfreight",
    MANAGE_TRIP_FREIGHT: "trips.manage_tripfreight",

    //Trip Freight Alert
    VIEW_TRIP_FREIGHT_ALERT: "trips.view_tripalert",
    CREATE_TRIP_FREIGHT_ALERT: "trips.add_tripalert",
    EDIT_TRIP_FREIGHT_ALERT: "trips.change_tripalert",
    DELETE_TRIP_FREIGHT_ALERT: "trips.delete_tripalert",
    MANAGE_TRIP_FREIGHT_ALERT: "trips.manage_tripalert",

    //Trip Freight Tracker
    VIEW_TRIP_FREIGHT_TRACKER: "trips.view_tripfreighttracker",
    CREATE_TRIP_FREIGHT_TRACKER: "trips.add_tripfreighttracker",
    EDIT_TRIP_FREIGHT_TRACKER: "trips.change_tripfreighttracker",
    DELETE_TRIP_FREIGHT_TRACKER: "trips.delete_tripfreighttracker",
    MANAGE_TRIP_FREIGHT_TRACKER: "trips.manage_tripfreighttracker",

    //Trip Document
    VIEW_TRIP_DOCUMENT: "trips.view_tripdocument",
    CREATE_TRIP_DOCUMENT: "trips.add_tripdocument",
    EDIT_TRIP_DOCUMENT: "trips.change_tripdocument",
    DELETE_TRIP_DOCUMENT: "trips.delete_tripdocument",
    MANAGE_TRIP_DOCUMENT: "trips.manage_tripdocument",

    // Trip Sea Transport
    VIEW_TRIP_SEA_TRANSPORT: "trips.view_tripseatransport",
    CREATE_TRIP_SEA_TRANSPORT: "trips.add_tripseatransport",
    EDIT_TRIP_SEA_TRANSPORT: "trips.change_tripseatransport",
    DELETE_TRIP_SEA_TRANSPORT: "trips.delete_tripseatransport",
    MANAGE_TRIP_SEA_TRANSPORT: "trips.manage_tripseatransport",

    //Trip Freight Timeline
    VIEW_TRIP_FREIGHT_TIMELINE: "trips.view_tripfreighttimeline",
    CREATE_TRIP_FREIGHT_TIMELINE: "trips.add_tripfreighttimeline",
    EDIT_TRIP_FREIGHT_TIMELINE: "trips.change_tripfreighttimeline",
    DELETE_TRIP_FREIGHT_TIMELINE: "trips.delete_tripfreighttimeline",
    MANAGE_TRIP_FREIGHT_TIMELINE: "trips.manage_tripfreighttimeline",

    //Trip Report
    VIEW_TRIP_REPORT: "trips.view_tripreport",
    CREATE_TRIP_REPORT: "trips.add_tripreport",
    EDIT_TRIP_REPORT: "trips.change_tripreport",
    DELETE_TRIP_REPORT: "trips.delete_tripreport",
    MANAGE_TRIP_REPORT: "trips.manage_tripreport",

    //Trip Template
    VIEW_TRIP_TEMPLATE: "trips.view_triptemplate",
    CREATE_TRIP_TEMPLATE: "trips.add_triptemplate",
    EDIT_TRIP_TEMPLATE: "trips.change_triptemplate",
    DELETE_TRIP_TEMPLATE: "trips.delete_triptemplate",
    MANAGE_TRIP_TEMPLATE: "trips.manage_triptemplate",
    
});

export default TripPermissionsEnum;
