<template>
    <!--single date select-->
    <div>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-if="!rangeSelect && show"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="selectedText"
                    v-on:input="$emit('input', $event)"
                    @blur="date = parseDate(date)"
                    chips
                    small-chips
                    :clearable="clearField && !readOnly"
                    :label="label"
                    v-on="on"
                    :dense="dense"
                    :outlined="outlined"
                    :rules="rules"
                    :disabled="disabled"
                    :readonly="readOnly"
                    :prepend-inner-icon="icon"
                    :filled="filled"
                >
                </v-text-field>
            </template>
            <v-date-picker
                v-if="!readOnly"
                v-model="selected"
                no-title
                @input="menu = false"
                :pickerDate.sync="pickerDate"
            >
            </v-date-picker>
        </v-menu>

        <!--multiple dates select-->
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-if="rangeSelect && show"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="showTheDate"
                    class="
                    font-weight-regular body-2 
                    flex-wrap 
                    font-weight-medium 
                    included
                "
                    :clearable="clearField"
                    @click:clear="onClear"
                    v-on:input="$emit('input', $event)"
                    @click="clearCache"
                    :label="label"
                    v-on="on"
                    readonly
                    v-bind="attrs"
                    :dense="dense"
                    :disabled="disabled"
                    outlined
                    @blur="date = parseDate(value)"
                    style="width:235px;"
                    :hide-details="hideDetails"
                >
                </v-text-field>
            </template>
            <v-form
                ref="datePickerRef"
                v-click-outside="{
                    handler: clickOutside,
                    include: include
                }"
            >
                <v-date-picker
                    ref="datePickerRef"
                    v-model="selectedRange"
                    @change="onChangeDate"
                    aria-placeholder="Sales date"
                    range
                    no-title
                    :pickerDate.sync="pickerDate"
                ></v-date-picker>
            </v-form>
        </v-menu>
    </div>
</template>
<script>
import moment from "moment";
import helper from "@/utils/helper";

export default {
    name: "date-component",
    props: {
        value: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        filled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        rangeSelect: { type: Boolean, default: false },
        label: String,
        icon: String,
        rules: Array,
        show: {
            type: Boolean,
            default: true
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        clearField: {
            type: Boolean,
            default: true
        },
        alertMessage: {
            type: Number,
            default: null
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        iconToolTip: {
            type: String,
            default: null
        }
    },
    data: function() {
        return {
            menu: false,
            date: null,
            selectedRange: [],
            range: true,
            pickerDate: null
        };
    },
    computed: {
        selected: {
            get() {
                if (this.value) {
                    let date = moment(this.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DD"
                    );
                    return date;
                }
                return null;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        selectedText: {
            get() {
                return this.value
                    ? moment(this.value, "YYYY-MM-DD").format(
                          helper.getDateFormat()
                      )
                    : null;
            },
            set(value) {
                this.$emit("input", value);
            }
        },

        computedDateFormatted() {
            return this.formatDate(this.date);
        },
        showTheDate: {
            get() {
                if (this.selectedRange.length > 1) {
                    let date1 = moment(
                        this.selectedRange[0],
                        "YYYY-MM-DD"
                    ).format(helper.getDateFormat());
                    let date2 = moment(
                        this.selectedRange[1],
                        "YYYY-MM-DD"
                    ).format(helper.getDateFormat());
                    return date1 + " ~ " + date2;
                } else if (this.selectedRange.length === 1) {
                    return moment(this.selectedRange[0], "YYYY-MM-DD").format(
                        helper.getDateFormat()
                    );
                } else {
                    return this.selectedRange;
                }
            },
            set(value) {
                if (value === null) return "";
                return value;
            }
        }
    },
    watch: {
        dates() {
            this.value = this.formatDate(this.date);
        },
        selectedText(v) {
            if (v === null) {
                const today = new Date().toISOString().split("T")[0];
                this.pickerDate = today;
            }
        },
        showTheDate(v) {
            if (v === null) {
                const today = new Date().toISOString().split("T")[0];
                this.pickerDate = today;
            }
        }
    },
    methods: {
        clickOutside() {
            /*If the trader click one time in date and click outside send a array
                of size one
                */
            if (this.$refs.datePickerRef.validate()) {
                this.$emit("input", this.selectedRange.toString());
            }
        },
        clearCache() {
            //clear the cache in child and father side
            this.selectedRange = [];
            this.$emit("input", "");
        },
        onClear() {
            // clear the cache on father side
            this.$emit("input", "");
        },
        include() {
            // Call the value in the include class
            return [document.querySelector(".included")];
        },
        onChangeDate() {
            if (Array.isArray(this.selectedRange)) {
                /*Check if the values of date range is diferent and if this true
                organize the date range in order
                */
                if (
                    this.selectedRange.length > 1 &&
                    !(this.selectedRange[0] === this.selectedRange[1])
                ) {
                    this.selectedRange.sort((a, b) => {
                        let dateA = new Date(a),
                            dateB = new Date(b);
                        if (dateB > dateA) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                } else {
                    /* If the trader click two times in the same date value this
                        will send a array of size one.
                    */
                    this.selectedRange.pop(this.selectedRange[1]);
                }
            }
        },
        formatDate(date) {
            if (!date) {
                if (this.rangeSelect) return [];
                return null;
            }
            return date;
        },
        parseDate(date) {
            if (!date) {
                if (this.rangeSelect) return [];
                return null;
            }
            const [year, month, day] = date.split("-");
            return `${year}-${month?.padStart(2, "0")}-${day?.padStart(
                2,
                "0"
            )}`;
        }
    }
};
</script>
