import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",staticStyle:{"background-color":"transparent"},attrs:{"fluid":""}},[_c(VForm,{ref:"form"},[_c(VExpansionPanels,{staticClass:"rounded-lg",attrs:{"flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"contentDisplay"},[_c('div',{staticClass:"d-flex flex-column px-4"},[_c('div',{staticClass:"textTitle"},[_vm._v(" "+_vm._s(_vm.$t("trips.advanced_info"))+" ")]),_c('span',{staticClass:"textBody"},[_vm._v(_vm._s(_vm.$t("general.advance_info_warning")))])])]),_c(VExpansionPanelContent,[_c(VRow,{staticClass:"mb-2",attrs:{"dense":""}},[_c(VCol,{staticClass:"contentDisplay",attrs:{"cols":"12","xs":"12","sm":"12","md":"","lg":"","xl":""}},[_c('div',{staticClass:"px-4"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textBody labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("home.forwarders"))+" ")])])],1),_c(VAutocomplete,{staticClass:"inputContent",attrs:{"loading":_vm.$apollo.queries.tenantPartners.loading,"items":_vm.forwarder,"no-data-text":_vm.$t('loading.no_available'),"outlined":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":"","dense":"","menu-props":{
                                        maxHeight: 150
                                    }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{attrs:{"color":"primary mt-4","block":""},on:{"click":function($event){return _vm.createNewPartner('forwarder')}}},[_vm._v(" "+_vm._s(_vm.$t("general.add_forwarder"))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}}),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textBody labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("home.others"))+" ")])])],1),_c(VAutocomplete,{staticClass:"inputContent",attrs:{"loading":_vm.$apollo.queries.tenantPartners.loading,"items":_vm.other,"no-data-text":_vm.$t('loading.no_available'),"outlined":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":"","dense":_vm.dense,"menu-props":{
                                        maxHeight: 150
                                    }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{staticClass:"mt-4",attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.createNewPartner('other')}}},[_vm._v(" "+_vm._s(_vm.$t("general.add_other"))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}})],1)]),(!_vm.isSmallScreen)?_c(VCard,{staticClass:"d-flex justify-center align-center",staticStyle:{"background-color":"transparent"},attrs:{"width":"20px","flat":""}}):_vm._e(),_c(VCol,{staticClass:"contentDisplay",attrs:{"cols":"12","xs":"12","sm":"12","md":"","lg":"","xl":""}},[_c('div',{staticClass:"px-4"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textBody labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("home.consignors"))+" ")])])],1),_c(VAutocomplete,{staticClass:"inputContent",attrs:{"loading":_vm.$apollo.queries.tenantPartners.loading,"items":_vm.consignor,"no-data-text":_vm.$t('loading.no_available'),"outlined":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":"","dense":_vm.dense,"menu-props":{
                                        maxHeight: 150
                                    }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{staticClass:"mt-4",attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.createNewPartner('consignor')}}},[_vm._v(" "+_vm._s(_vm.$t("general.add_consignor"))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}}),_vm._t("saveTemplateSlot")],2)])],1)],1)],1)],1)],1),_c('AddPartnerDialog',{attrs:{"openAddPartnerDialog":_vm.openAddPartnerDialog,"partnerByType":_vm.partnerByType},on:{"refreshPartnersTable":function($event){_vm.newPartner = true},"closeAddPartnerDialog":function($event){_vm.openAddPartnerDialog = false},"item-created":_vm.onPartnerCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }