<template>
    <v-container fluid class="pa-0">
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <div>
            <v-row dense class="px-3 mt-2">
                <v-col cols=auto>
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex 
                        "
                    >
                       <span style="text-wrap: nowrap;">{{ title }}</span>
                    </h2>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-tabs
                        v-model="tab"
                        class="justify-center"
                        background-color="transparent"
                        ><v-tab>{{ $t("home.my_locations") }}</v-tab>
                        <v-tab>{{ $t("home.partners_locations") }}</v-tab>
                        <v-tab>{{ $t("partners.public_locations") }}</v-tab>
                    </v-tabs>
                    <v-row class="mt-4 mb-0">
                        <v-spacer></v-spacer>
                        <v-col
                            cols="auto"
                            v-if="title !== $t('partners.public_locations')"
                        >
                            <v-btn
                                rounded
                                color="primary"
                                class="mr-1"
                                @click="openAddLocation = true"
                            >
                                {{ $t("partners.add_location1") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="auto"
                            v-else
                        >
                            <v-btn
                                v-if="hasPermission('add_public_location')"
                                rounded
                                color="primary"
                                class="mr-1"
                                @click="openAddLocation = true"
                            >
                                {{ $t("partners.report_missing_location") }}
                            </v-btn>
                        </v-col>                  
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <!-- LOCATIONS FILTER/TOOLBAR -->
        <LocationsFilter
            class="mx-3"
            v-on:updateLocationTable="refreshTable"
            v-on:deletedLocation="selectedLocation = []"
            :selectedLocations="selectedLocation"
            v-on:filter-changed="onFilterChanged"
            @changePublicLocationType="publicLocationType = $event"
            :title="title"
        ></LocationsFilter>
        <!-- TABLE -->
        <LocationsTable
            ref="locationtable"
            :locationsFilter.sync="locationsFilter"
            class="mx-1 mb-3"
            v-on:click:row="onLocationSelected"
            :title="title"
            :publicLocationType.sync="publicLocationType"
        ></LocationsTable>
        <!-- TUTORIAL -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>

        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="locationDetailDrawer"
            :width="locationDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <LocationDetail
                    v-if="selectedLocation"
                    :key="selectedLocation.id"
                    :location="selectedLocation"
                    :isFullScreen="isLocationDetailDrawerFullScreen"
                    v-on:changeFullScreen="
                        onLocationDetailDrawerFullScreenChange
                    "
                    v-on:close="locationDetailDrawer = false"
                    v-on:refreshDataTable="refreshTable"
                    v-on:onLocationDeleted="onLocationDeleted"
                    :title="title"
                ></LocationDetail>
            </div>
        </v-navigation-drawer>
        <AddLocation
            v-if="title !== $t('partners.public_locations')"
            :openAddLocationDialog="openAddLocation"
            v-on:updateLocationsTable="refreshTable"
            v-on:closeMap="openAddLocation = false"
            :title="title"
        ></AddLocation>
        <AddPublicLocation
            v-else
            :openDialog="openAddLocation"
            @closeDialog="openAddLocation = false"
        ></AddPublicLocation>
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import LocationsFilter from "@/components/locations/LocationsFilter.vue";
import LocationsTable from "@/components/locations/LocationsTable.vue";
import LocationDetail from "@/components/locations/LocationDetail.vue";
import AddLocation from "@/components/locations/create/AddLocation.vue";
import AddPublicLocation from "@/components/locations/create/AddPublicLocation.vue";
import { eventBus } from "@/main.js";

export default {
    title: "Locations",
    components: {
        TutorialDialog,
        LocationsFilter,
        LocationsTable,
        LocationDetail,
        AddLocation,
        AddPublicLocation
    },

    apollo: {},

    data() {
        return {
            tab: 0,
            openAddLocation: false,
        
            publicLocationType: "airport",
            locationDetailDrawer: false,
            locationsFilter: {
                first: 15
            },
            goTopShow: false,
            // isLoading: false,
            menu: false,
            selectedLocation: null,
            tutorialDialog: false,
            locationDetailFullScreen: false
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        locationDetailDrawerWidth() {
            if (this.locationDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isLocationDetailDrawerFullScreen() {
            return this.locationDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        routeName() {
            return this.$route.name;
        },
        title() {
            if (this.routeName === "mylocations") {
                return this.$t("home.my_locations");
            } else if (this.routeName === "partnerslocations") {
                return this.$t("home.partners_locations");
            } else if (this.routeName === "publiclocations") {
                return this.$t("partners.public_locations");
            } else {
                return this.$t("home.locations");
            }
        }
    },

    watch: {
       tab(val) {
            switch(val){
                case 0:
                    this.pushTo("mylocations");
                    this.$refs.locationtable.refreshLocations()
                    break;
                case 1:
                    this.pushTo("partnerslocations");
                    this.$refs.locationtable.refreshLocations()
                    break;
                case 2:
                    this.pushTo("publiclocations");
                    break;
                default:
                    this.pushTo("mylocations");
            }
        }, 
        $route(to) {
            if (to.query.tab) {
                this.$router.push({ name: to.query.tab, params: to.params });
            }
        },
        // locationsFilter: {
        //     handler(value) {
        //         console.log(value)
        //     },
        //     deep: true
        // }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        if(!this.$route.query.id){
            this.pushTo("mylocations");
        }
    },
    methods: {
        // onLoading(val) {
        //     this.isLoading = val;
        // },
        onFilterChanged(filter) {
            this.locationsFilter = filter;
        },
        refreshTable() {
            this.$refs.locationtable.$apollo.queries.locations.refetch();
        },
        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },

        // loadMore() {
        //     this.isLoading = false;
        // },
        onLocationDetailDrawerFullScreenChange(val) {
            this.locationDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onLocationSelected(location) {
            this.selectedLocation = location.node;
            this.locationDetailDrawer = true;
        },
        onLocationDeleted() {
            this.selectedLocation = null;
            this.locationDetailDrawer = false;
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
</style>
