<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="12" sm="9" lg="10">
                        <v-row>
                            <v-col
                                cols="auto"
                                v-for="f in dynamicFilters"
                                :key="f"
                                :class="getFilterChoiceClassByCode(f)"
                            >
                                <!--!Trip Reference Number -->
                                <v-text-field
                                    v-if="f === 'referenceNumber'"
                                    v-model="filter.referenceNumberIcontains"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'referenceNumber'
                                        )
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--!Trip Reference Number -->
                                <v-text-field
                                    v-if="f === 'tripReferenceNumber'"
                                    v-model="
                                        filter.tripReferenceNumberIcontains
                                    "
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'tripReferenceNumber'
                                        )
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--!Trip Trackers -->
                                <v-text-field
                                    v-if="f === 'tracker'"
                                    v-model="
                                        filter.tripFreightTrackerSetTrackerSerialNumberIcontains
                                    "
                                    :label="
                                        getFilterChoiceNameByCode('tracker')
                                    "
                                    clearable
                                    class="maxWidth"
                                    hide-details
                                    dense
                                ></v-text-field>

                                <!--!Trip Container Number -->
                                <v-text-field
                                    v-if="f === 'containerNumber'"
                                    v-model="filter.containerNumberIcontains"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'containerNumber'
                                        )
                                    "
                                    clearable
                                    hide-details
                                    class="maxWidth"
                                    dense
                                ></v-text-field>

                                <!-- !Trip Air Waybill Number -->
                                <v-text-field
                                    v-if="f === 'airWaybillNumber'"
                                    v-model="filter.airWaybillNumberIcontains"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'airWaybillNumber'
                                        )
                                    "
                                    clearable
                                    class="maxWidth"
                                    hide-details
                                    dense
                                ></v-text-field>

                                <!-- ! POL Search -->
                                <PortFilter
                                    v-if="f === 'polSearch'"
                                    v-model="filter.freightPol"
                                    :label="
                                        getFilterChoiceNameByCode('polSearch')
                                    "
                                    :refreshTable="refreshTablePorts"
                                >
                                </PortFilter>

                                <!-- ! Transsshipment Search -->
                                <PortFilter
                                    v-if="f === 'transshipmentSearch'"
                                    v-model="filter.freightTransshipment"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'transshipmentSearch'
                                        )
                                    "
                                    :refreshTable="refreshTablePorts"
                                >
                                </PortFilter>

                                <!-- ! freightPod search  -->
                                <PortFilter
                                    v-if="f === 'podSearch'"
                                    v-model="filter.freightPod"
                                    :label="
                                        getFilterChoiceNameByCode('podSearch')
                                    "
                                    :refreshTable="refreshTablePorts"
                                >
                                </PortFilter>

                                <!-- !Trip Status -->
                                <v-select
                                    v-if="f === 'status'"
                                    v-model="filter.tripStatusIn"
                                    :items="statusChoices"
                                    :label="getFilterChoiceNameByCode('status')"
                                    item-text="text"
                                    item-value="value"
                                    hide-details
                                    dense
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 3,
                                        offsetY: true
                                    }"
                                ></v-select>

                                <!-- !Partner Name -->
                                <!-- <PartnerAutoComplete
                                    v-if="f === 'tenantPartnerId'"
                                    v-model="filter.tenantPartnerId"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'tenantPartnerId'
                                        )
                                    "
                                ></PartnerAutoComplete> -->

                                <!-- !Relative Planned Departure Date -->
                                <v-select
                                    v-if="
                                        f ===
                                            'relativePlannedDepartureDateRange'
                                    "
                                    v-model="relativePlannedDepartureDateRange"
                                    :items="
                                        relativePlannedDepartureDateRangeChoices
                                    "
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'relativePlannedDepartureDateRange'
                                        )
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 3,
                                        offsetY: true
                                    }"
                                ></v-select>

                                <!-- !Consignor -->
                                <PartnerTripsConsignor
                                    v-if="f === 'freightConsignor'"
                                    ref="freightConsignor"
                                    :refreshTable="refreshTableConsignor"
                                    v-model="filter.freightConsignor"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'freightConsignor'
                                        )
                                    "
                                ></PartnerTripsConsignor>

                                <!-- !Consignee -->
                                <PartnerTripsConsignee
                                    v-if="f === 'freightConsignee'"
                                    ref="freightConsignee"
                                    :refreshTable="refreshTableConsignee"
                                    v-model="filter.freightConsignee"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'freightConsignee'
                                        )
                                    "
                                ></PartnerTripsConsignee>

                                <!-- !Forwarder -->
                                <PartnerTripsForwarder
                                    v-if="f === 'freightForwarder'"
                                    ref="freightForwarder"
                                    :refreshTable="refreshTableForwarder"
                                    v-model="filter.freightForwarder"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'freightForwarder'
                                        )
                                    "
                                ></PartnerTripsForwarder>

                                <!-- !Carrier -->
                                <PartnerTripsCarrier
                                    v-if="f === 'carrier'"
                                    ref="carrier"
                                    :refreshTable="refreshTableCarrier"
                                    v-model="filter.tripTenantCarrier"
                                    :label="
                                        getFilterChoiceNameByCode('carrier')
                                    "
                                ></PartnerTripsCarrier>

                                <VesselFilter
                                    v-if="f === 'vessel'"
                                    v-model="
                                        filter.tripTripSeaTransportSetVesselId
                                    "
                                    :refreshTable="refreshTableVessel"
                                    :label="getFilterChoiceNameByCode('vessel')"
                                >
                                </VesselFilter>
                                <!-- <v-select
                                    v-if="f === 'carrier'"
                                    v-model="filter.tripTenantCarrier"
                                    :items="transportModeChoices"
                                    :label="
                                        getFilterChoiceNameByCode('carrier')
                                    "
                                    chips
                                    small-chips
                                    clearable
                                    multiple
                                    hide-details
                                    dense
                                    color="primary"
                                    class="maxWidth"
                                ></v-select> -->

                                <!-- Transport Mode -->
                                <v-select
                                    v-if="f === 'transportMode'"
                                    v-model="filter.tripTransportModeIn"
                                    :items="transportModeChoices"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'transportMode'
                                        )
                                    "
                                    chips
                                    small-chips
                                    clearable
                                    multiple
                                    hide-details
                                    dense
                                    color="primary"
                                    :menu-props="{
                                        zIndex: 3,
                                        offsetY: true
                                    }"
                                ></v-select>

                                <!-- Last Location-->
                                <v-text-field
                                    v-if="f === 'trackerLastLocation'"
                                    v-model="
                                        filter.trackerLastLocation_Icontains
                                    "
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'trackerLastLocation'
                                        )
                                    "
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!-- !Alert Code -->
                                <v-select
                                    v-if="f === 'freightAlertCode'"
                                    v-model="filter.freightAlertCode"
                                    :items="alertsType"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'freightAlertCode'
                                        )
                                    "
                                    item-text="name"
                                    item-value="value"
                                    hide-details
                                    dense
                                    clearable
                                    color="primary"
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 3,
                                        offsetY: true
                                    }"
                                >
                                </v-select>

                                <!-- !Trip KPI -->
                                <v-select
                                    v-if="f === 'kpi'"
                                    :label="getFilterChoiceNameByCode('kpi')"
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                    :menu-props="{
                                        zIndex: 3,
                                        offsetY: true
                                    }"
                                >
                                </v-select>

                                <!--!Trip Reference Number -->
                                <v-text-field
                                    v-if="f === 'goods'"
                                    v-model="filter.goodsIcontains"
                                    :label="getFilterChoiceNameByCode('goods')"
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!-- !Planned Departure Date  -->
                                <v-menu
                                    v-if="f === 'plannedDepartureDate'"
                                    ref="plannedDepartureDateMenu"
                                    v-model="plannedDepartureDateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="
                                        savedPlannedDepartureDateRange
                                    "
                                    transition="scale-transition"
                                    offset-y
                                    max-width="auto"
                                    min-width="auto"
                                    :disabled="
                                        !!relativePlannedDepartureDateRange
                                    "
                                    z-index="3"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="
                                                plannedDepartureDateRangeText
                                            "
                                            :label="
                                                getFilterChoiceNameByCode(
                                                    'plannedDepartureDate'
                                                )
                                            "
                                            readonly
                                            clearable
                                            hide-details
                                            dense
                                            @click:clear="
                                                onClearPlannedDepartureDates()
                                            "
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="
                                                !!relativePlannedDepartureDateRange
                                            "
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="plannedDepartureDateRange"
                                        :first-day-of-week="1"
                                        range
                                        no-title
                                        scrollable
                                        color="primary"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            rounded
                                            color="primary"
                                            @click="
                                                cancelPlannedDepartureDatePicker
                                            "
                                        >
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            outlined
                                            rounded
                                            color="primary"
                                            :disabled="
                                                plannedDepartureDateRange.length <
                                                    2
                                            "
                                            @click="
                                                $refs.plannedDepartureDateMenu[0].save(
                                                    plannedDepartureDateRange.sort()
                                                )
                                            "
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>

                                <!-- !Planned Arrival Date  -->
                                <v-menu
                                    v-if="f === 'plannedArrivalDate'"
                                    ref="plannedArrivalDateMenu"
                                    v-model="plannedArrivalDateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="
                                        savedPlannedArrivalDateRange
                                    "
                                    transition="scale-transition"
                                    offset-y
                                    max-width="auto"
                                    min-width="auto"
                                    :disabled="
                                        !!relativePlannedArrivalDateRange
                                    "
                                    z-index="3"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="plannedArrivalDateRangeText"
                                            :label="
                                                getFilterChoiceNameByCode(
                                                    'plannedArrivalDate'
                                                )
                                            "
                                            readonly
                                            clearable
                                            hide-details
                                            dense
                                            @click:clear="
                                                onClearPlannedArrivalDates()
                                            "
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="
                                                !!relativePlannedArrivalDateRange
                                            "
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="plannedArrivalDateRange"
                                        :first-day-of-week="1"
                                        range
                                        no-title
                                        scrollable
                                        color="primary"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            rounded
                                            color="primary"
                                            @click="
                                                cancelPlannedArrivalDatePicker
                                            "
                                        >
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            outlined
                                            rounded
                                            color="primary"
                                            :disabled="
                                                plannedArrivalDateRange.length <
                                                    2
                                            "
                                            @click="
                                                $refs.plannedArrivalDateMenu[0].save(
                                                    plannedArrivalDateRange.sort()
                                                )
                                            "
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- !Sort By -->
                            <v-col class="col-auto col-md-4 col-lg-3 col-xl-3">
                                <v-row class="d-flex align-center ma-0 pa-0">
                                    <v-select
                                        v-model="sortBy"
                                        :items="sortByChoices"
                                        :label="$t('general.sort_by')"
                                        :item-value="item => item.code"
                                        :item-text="item => item.name"
                                        clearable
                                        hide-details
                                        dense
                                        color="primary"
                                        :menu-props="{
                                            zIndex: 3,
                                            offsetY: true
                                        }"
                                    >
                                        <template v-slot:append-outer>
                                            <v-btn
                                                class="mt-n1"
                                                small
                                                icon
                                                :disabled="!sortBy"
                                                @click="sortAsc = !sortAsc"
                                            >
                                                <v-icon color="grey darken-2">{{
                                                    sortAsc ? "north" : "south"
                                                }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </v-row>
                            </v-col>

                            <!-- !Favorite Trip -->
                            <v-col
                                class="col-auto col-md-4 col-lg-3 col-xl-3 d-flex "
                            >
                                <div class="d-flex align-center mr-4">
                                    <v-switch
                                        v-model="userFavoriteTrip"
                                        :label="$t('general.favorites')"
                                        class="text-no-wrap"
                                        color="primary"
                                        style="margin-top: 0%"
                                    ></v-switch>
                                </div>
                                <div class="d-flex align-center mr-4">
                                    <v-switch
                                        v-model="unresolvedAlerts"
                                        :label="$t('general.alerts')"
                                        color="primary"
                                        class="text-no-wrap"
                                        style="margin-top: 0%"
                                    ></v-switch>
                                </div>
                                <div>
                                    <v-btn 
                                        rounded 
                                        small
                                        color=""
                                        elevation-2
                                        @click="clearFilter"
                                    >
                                        <v-icon small>filter_alt_off</v-icon>
                                        <span>{{ $t("general.clear")}}</span>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- !Filters Menu -->
                    <v-col cols="12" sm="3" lg="2" class="py-2 pr-4 text-right">
                        <v-row class="d-flex align-center justify-end">
                            <!-- !Filters Menu -->
                            <FilterMenu
                                :title="$t('dashboard.customize')"
                                :description="$t('dashboard.display')"
                                :choices="filterChoices"
                                :open-on-hover="false"
                                height="500px"
                                width="250px"
                                v-on:changed="onFilterMenuChanged"
                            >
                            </FilterMenu>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import FilterMenu from "@/components/base/FilterMenu.vue";
// import PartnerAutoComplete from "@/components/partners/PartnerAutoComplete.vue";
import PartnerTripsConsignor from "@/components/partners/PartnerTripsConsignor.vue";
import PartnerTripsConsignee from "@/components/partners/PartnerTripsConsignee.vue";
import PartnerTripsForwarder from "@/components/partners/PartnerTripsForwarder.vue";
import PartnerTripsCarrier from "@/components/partners/PartnerTripsCarrier.vue";
import VesselFilter from "@/components/trips/other/VesselFilter.vue";
import PortFilter from "@/components/trips/other/PortFilter.vue";

export default {
    components: {
        FilterMenu,
        // PartnerAutoComplete,
        PartnerTripsConsignor,
        PartnerTripsConsignee,
        PartnerTripsForwarder,
        PartnerTripsCarrier,
        VesselFilter,
        PortFilter
    },
    props: {
        filterCode: {
            type: String,
            default: ""
        }
    },
    apollo: {},

    data() {
        return {
            fab: false,
            isLoading: false,
            plannedDepartureDateMenu: false,
            plannedArrivalDateMenu: false,
            plannedDepartureDateRange: [],
            plannedArrivalDateRange: [],
            savedPlannedDepartureDateRange: [],
            savedPlannedArrivalDateRange: [],
            userFavoriteTrip: null,
            sortAsc: true,
            shouldStickyFilter: false,
            unresolvedAlerts: null,
            // alertsType: [
            //     {
            //         name: this.$t("dashboard.etd_delay"),
            //         value: "SEA_ETD_DELAY"
            //     },
            //     {
            //         name: this.$t("dashboard.eta_delay"),
            //         value: "SEA_ETA_DELAY"
            //     },
            //     {
            //         name: this.$t("dashboard.ts_waiting"),
            //         value: "SEA_TRANS_WAIT"
            //     },
            //     {
            //         name: this.$t("dashboard.pod_waiting"),
            //         value: "SEA_DEST_WAIT"
            //     },
            //     {
            //         name: this.$t("dashboard.custom_alert"),
            //         value: "CUSTOM_ALERT"
            //     }
            // ],

            // graphql query filter
            filter: {
                first: 10,
                after: null,
                referenceNumberIcontains: null,
                tripReferenceNumberIcontains: null,
                tripFreightTrackerSetTrackerSerialNumberIcontains: null,
                containerNumberIcontains: null,
                airWaybillNumberIcontains: null,
                tenantPartnerId: null,
                tripStatusIn: "active",
                tripPlannedDepartureDateGte: null,
                tripPlannedDepartureDateLte: null,
                tripPlannedArrivalDateGte: null,
                tripPlannedArrivalDateLte: null,
                orderBy: null,
                isFavorite: null,
                freightPol: null,
                freightTransshipment: null,
                tripTransportModeIn: null,
                freightPod: null,
                freightAlertCode: null,
                tripTripAlertSetIsResolved: null,
                tripTenantCarrier: null,
                freightConsignor: null,
                freightConsignee: null,
                freightForwarder: null,
                tripTripSeaTransportSetVesselId: null,
                goodsIcontains: null,
                trackerLastLocation_Icontains: null
            },
            refreshTableCarrier: false,
            refreshTableConsignor: false,
            refreshTableConsignee: false,
            refreshTableForwarder: false,
            refreshTableVessel: false,
            refreshTablePorts: false,
            filterChoices: null,
            // defaultFilterChoices: [
            //     {
            //         // for filter menu
            //         code: "referenceNumber",
            //         name: this.$t("headers.reference_number"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "tripReferenceNumber",
            //         name: this.$t("headers.trip_number"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "tracker",
            //         name: this.$t("headers.tracker_number"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "containerNumber",
            //         name: this.$t("headers.container_number"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "airWaybillNumber",
            //         name: this.$t("headers.awb_number"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "status",
            //         name: this.$t("headers.status"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-auto"
            //     },
            //     {
            //         // for filter menu
            //         code: "plannedDepartureDate",
            //         name: this.$t("headers.planned_departure"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "plannedArrivalDate",
            //         name: this.$t("headers.planned_arrival"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "polSearch",
            //         name: this.$t("headers.pol_code"),
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: false
            //     },
            //     {
            //         code: "transshipmentSearch",
            //         name: this.$t("headers.ts_code"),
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: false
            //     },
            //     {
            //         code: "podSearch",
            //         name: this.$t("headers.pod_code"),
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: false
            //     },
            //     {
            //         code: "transportMode",
            //         name: this.$t("headers.transport_mode"),
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: false
            //     },
            //     {
            //         code: "freightAlertCode",
            //         name: this.$t("headers.alert_name"),
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: false
            //     },
            //     {
            //         // for filter menu
            //         code: "freightConsignor",
            //         name: this.$t("headers.consignor"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "freightConsignee",
            //         name: this.$t("headers.consignee"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "freightForwarder",
            //         name: this.$t("headers.forwarder"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "carrier",
            //         name: this.$t("headers.carrier"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "vessel",
            //         name: this.$t("headers.vessel"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         // for filter menu
            //         code: "goods",
            //         name: this.$t("headers.goods"),
            //         enabled: false,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     }
            // ],

            // sortByChoices: [
            //     {
            //         code: "referenceNumber",
            //         name: this.$t("headers.reference_number")
            //     },
            //     {
            //         code: "tripstatus",
            //         name: this.$t("headers.status")
            //     },
            //     {
            //         code: "createdAt",
            //         name: this.$t("headers.created_at")
            //     },
            //     {
            //         code: "modifiedAt",
            //         name: this.$t("headers.modified_at")
            //     },
            //     {
            //         code: "tripplanneddeparturedate",
            //         name: this.$t("headers.planned_departure")
            //     },
            //     {
            //         code: "fconsignor",
            //         name: this.$t("headers.consignor")
            //     },
            //     {
            //         code: "fconsignee",
            //         name: this.$t("headers.consignee")
            //     },
            //     {
            //         code: "fforwarder",
            //         name: this.$t("headers.forwarder")
            //     },
            //     {
            //         code: "fcarrier",
            //         name: this.$t("headers.carrier")
            //     }
            // ],

            sortBy: null,
            // statusChoices: [
            //     { text: this.$t("general.all_trips"), value: null },
            //     { text: this.$t("general.active"), value: "active" },
            //     { text: this.$t("general.completed"), value: "completed" }
            // ],
            relativePlannedDepartureDateRange: null,
            relativePlannedDepartureDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            relativePlannedArrivalDateRange: null,
            relativePlannedArrivalDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ]
            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            // ]
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        plannedDepartureDateRangeText() {
            return this.plannedDepartureDateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },
        plannedArrivalDateRangeText() {
            return this.plannedArrivalDateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },
        dynamicFilters() {
            return this.filterChoices.filter(f => f.enabled).map(f => f.code);
        },
        tenantPartners() {
            return this.tripPartnerSet?.edges?.map(e => e.node) || [];
        },
        statusChoices: function() {
            let _statusChoices = [
                { text: this.$t("general.all_trips"), value: null },
                { text: this.$t("general.active"), value: "active" },
                { text: this.$t("general.completed"), value: "completed" }
            ];
            return _statusChoices;
        },

        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        },
        // shouldStickyFilter() {
        //     switch (this.$vuetify.breakpoint.name) {
        //         case "xs":
        //             return false;
        //         case "sm":
        //             return false;
        //         case "md":
        //             return true;
        //         case "lg":
        //             return true;
        //         case "xl":
        //             return true;
        //         default:
        //             return true;
        //     }
        // }
        defaultFilterChoices: function() {
            let _defaultFilterChoices = [
                {
                    // for filter menu
                    code: "referenceNumber",
                    name: "Reference Number",
                    tag: "headers.reference_number",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "tripReferenceNumber",
                    name: "Trip Reference Number",
                    tag: "headers.trip_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "tracker",
                    name: "Tracker Number",
                    tag: "headers.tracker_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "containerNumber",
                    name: "Container Number",
                    tag: "headers.container_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "airWaybillNumber",
                    name: "AWB",
                    tag: "headers.awb_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "status",
                    name: "Status",
                    tag: "headers.status",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                },
                {
                    // for filter menu
                    code: "plannedDepartureDate",
                    name: "Planned Departure",
                    tag: "headers.planned_departure",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "plannedArrivalDate",
                    name: "Planned Arrival",
                    tag: "headers.planned_arrival",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "polSearch",
                    name: "POL Code",
                    tag: "headers.pol_code",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "transshipmentSearch",
                    name: "T/S Code",
                    tag: "headers.ts_code",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "podSearch",
                    name: "POD Code",
                    tag: "headers.pod_code",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "transportMode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "freightAlertCode",
                    name: "Alert Name",
                    tag: "headers.alert_name",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    // for filter menu
                    code: "freightConsignor",
                    name: "Consignor",
                    tag: "headers.consignor",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "freightConsignee",
                    name: "Consignee",
                    tag: "headers.consignee",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "freightForwarder",
                    name: "Forwarder",
                    tag: "headers.forwarder",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "vessel",
                    name: "Vessel",
                    tag: "headers.vessel",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "goods",
                    name: "Goods",
                    tag: "headers.goods",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "trackerLastLocation",
                    name: "Last Location",
                    tag: "headers.last_location",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                }
            ];
            return _defaultFilterChoices;
        },

        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "referenceNumber",
                    name: this.$t("headers.reference_number")
                },
                {
                    code: "tripstatus",
                    name: this.$t("headers.status")
                },
                {
                    code: "createdAt",
                    name: this.$t("headers.created_at")
                },
                {
                    code: "modifiedAt",
                    name: this.$t("headers.modified_at")
                },
                {
                    code: "tripplanneddeparturedate",
                    name: this.$t("headers.planned_departure")
                },
                {
                    code: "fconsignor",
                    name: this.$t("headers.consignor")
                },
                {
                    code: "fconsignee",
                    name: this.$t("headers.consignee")
                },
                {
                    code: "fforwarder",
                    name: this.$t("headers.forwarder")
                },
                {
                    code: "fcarrier",
                    name: this.$t("headers.carrier")
                }
            ];
            return _sortByChoices;
        },
        alertsType: function() {
            let _alertsType = [
                {
                    name: this.$t("dashboard.etd_delay"),
                    value: "SEA_ETD_DELAY"
                },
                {
                    name: this.$t("dashboard.eta_delay"),
                    value: "SEA_ETA_DELAY"
                },
                {
                    name: this.$t("dashboard.ts_waiting"),
                    value: "SEA_TRANS_WAIT"
                },
                {
                    name: this.$t("dashboard.pod_waiting"),
                    value: "SEA_DEST_WAIT"
                },
                {
                    name: this.$t("dashboard.custom_alert"),
                    value: "CUSTOM_ALERT"
                }
            ];
            return _alertsType;
        }
    },
    watch: {
        userFavoriteTrip(val) {
            this.filter.isFavorite = val ? true : null;
        },
        unresolvedAlerts(val) {
            this.filter.tripTripAlertSetIsResolved = val ? false : null;
        },
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        relativePlannedDepartureDateRange(val) {
            const _index = this.relativePlannedDepartureDateRangeChoices.indexOf(
                val
            );
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [
                        _startDate,
                        _endDate
                    ];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [
                        _startDate,
                        _endDate
                    ];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [
                        _startDate,
                        _endDate
                    ];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [
                        _startDate,
                        _endDate
                    ];
                    break;
                default:
                    this.plannedDepartureDateRange = [];
                    this.savedPlannedDepartureDateRange = [];
            }
        },

        relativePlannedArrivalDateRange(val) {
            const _index = this.relativePlannedArrivalDateRangeChoices.indexOf(
                val
            );
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedArrivalDateRange = [];
                    this.savedPlannedArrivalDateRange = [];
            }
        },

        savedPlannedDepartureDateRange(val) {
            if (val.length == 1) {
                this.filter.tripPlannedDepartureDateGte = val[0] + "T00:00:00";
                this.filter.tripPlannedDepartureDateLte = val[0] + "23:59:59";
            } else if (val.length == 2) {
                this.filter.tripPlannedDepartureDateGte = val[0] + "T00:00:00";
                this.filter.tripPlannedDepartureDateLte = val[1] + "T23:59:59";
            } else {
                this.filter.tripPlannedDepartureDateGte = null;
                this.filter.tripPlannedDepartureDateLte = null;
            }
        },

        savedPlannedArrivalDateRange(val) {
            if (val.length == 1) {
                this.filter.tripPlannedArrivalDateGte = val[0] + "T00:00:00";
                this.filter.tripPlannedArrivalDateLte = val[0] + "23:59:59";
            } else if (val.length == 2) {
                this.filter.tripPlannedArrivalDateGte = val[0] + "T00:00:00";
                this.filter.tripPlannedArrivalDateLte = val[1] + "T23:59:59";
            } else {
                this.filter.tripPlannedArrivalDateGte = null;
                this.filter.tripPlannedArrivalDateLte = null;
            }
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                    helper.setFilterValues("freightTableFilters", value);
                }
            },
            deep: true
        },
        filterCode: function(code) {
            if (this.alertsType.find(e => e.value === code)) {
                this.filter.freightAlertCode = code;
            } else if (code === "TOTAL_OPEN_TRIPS") {
                this.filter.tripStatusIn = "active";
            } else if (code === "UNRESOLVED_ALERTS") {
                this.filter.tripTripAlertSetIsResolved = false;
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "freightTableFilters"
        );
    },
    mounted() {
        this.onResize();
        this.sortBy = "referenceNumber";
        let _filter = helper.getFilterValues("freightTableFilters");
        if (_filter) {
            this.filter = _filter;

            if (_filter.tripPlannedDepartureDateGte) {
                this.plannedDepartureDateRange = [
                    _filter.tripPlannedDepartureDateGte.split("T")[0],
                    _filter.tripPlannedDepartureDateLte.split("T")[0]
                ];
            }

            if (_filter.tripPlannedArrivalDateGte) {
                this.plannedArrivalDateRange = [
                    _filter.tripPlannedArrivalDateGte.split("T")[0],
                    _filter.tripPlannedArrivalDateLte.split("T")[0]
                ];
            }

            if (_filter.orderBy.includes("-")) {
                this.sortBy = _filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else {
                this.sortBy = _filter.orderBy;
                this.sortAsc = true;
            }

            this.userFavoriteTrip = _filter.isFavorite;

            if (_filter.tripTripAlertSetIsResolved == false)
                this.unresolvedAlerts = true;

            if (_filter.freightForwarder) {
                this.refreshTableForwarder = true;
            }

            if (_filter.tripTenantCarrier) {
                this.refreshTableCarrier = true;
            }

            if (_filter.freightConsignee) {
                this.refreshTableConsignee = true;
            }

            if (_filter.freightConsignor) {
                this.refreshTableConsignor = true;
            }

            if (_filter.tripTripSeaTransportSetVesselId) {
                this.refreshTableVessel = true;
            }

            if (
                _filter.freightPol ||
                _filter.freightPod ||
                _filter.freightTransshipment
            ) {
                this.refreshTablePorts = true;
            }
        }

        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    freightTableFilters: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        clearFilter() {
            const filters = helper.getFilterValues("freightTableFilters");
            Object.keys(filters).forEach(key => {this.filter[key] = null;});

            this.filter.tripStatusIn = "active";
            this.filter.orderBy = "-tripplanneddeparturedate";
            this.filter.after = null;
            this.filter.first = 10;

            this.plannedDepartureDateRange = [];
            this.plannedArrivalDateRange = [];
            this.relativePlannedDepartureDateRange = null;
            this.relativePlannedArrivalDateRange = null;
            this.sortBy = "referenceNumber";
            this.sortAsc = true;
            this.userFavoriteTrip = null;
            this.unresolvedAlerts = null;
        },
        // dynamic filter
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        getFilterChoiceNameByCode(code) {
            return this.$t(
                this.defaultFilterChoices.find(item => {
                    return item.code === code;
                })?.tag
            );
        },

        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelPlannedDepartureDatePicker() {
            this.plannedDepartureDateMenu = false;
            if (this.savedPlannedDepartureDateRange.length == 0) {
                this.plannedDepartureDateRange = [];
            }
        },
        cancelPlannedArrivalDatePicker() {
            this.plannedArrivalDateMenu = false;
            if (this.savedPlannedArrivalDateRange.length == 0) {
                this.plannedArrivalDateRange = [];
            }
        },
        onClearPlannedDepartureDates() {
            this.plannedDepartureDateRange = [];
            this.savedPlannedDepartureDateRange = [];
            this.relativePlannedDepartureDateRange = null;
        },
        onClearPlannedArrivalDates() {
            this.plannedArrivalDateRange = [];
            this.savedPlannedArrivalDateRange = [];
            this.relativePlannedArrivalDateRange = null;
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
