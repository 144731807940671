import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"5","outlined":""}},[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c('div',{staticStyle:{"position":"absolute","right":"25px"}},[_c('FilterMenu',{staticClass:"mr-n4",attrs:{"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display_filters'),"choices":_vm.headerChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1),_c(VDataTable,{attrs:{"headers":_vm.dynamicHeaders,"items":_vm.tenants.edges,"item-key":"node.id","loading-text":_vm.$t('loading.loading1'),"loading":_vm.$apollo.queries.tenants.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","mobile-breakpoint":_vm.screenSize === 'xs' || _vm.screenSize === 'sm' ? 800 : 0,"loader-height":"2","no-data-text":_vm.$t('loading.no_data')},on:{"click:row":function (item) { return this$1.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.node.organisationPermissions",fn:function(ref){
var item = ref.item;
return [(item.node.organisationPermissions ? item.node.organisationPermissions.length > 0 : false)?_c('span',[_vm._v(" "+_vm._s(JSON.parse(item.node.organisationPermissions).length || 0)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.usedTrackerLastTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.node.tenantInsightSet ? item.node.tenantInsightSet.usedTrackerLastTime ? _vm.formatDateTime(item.node.tenantInsightSet.usedTrackerLastTime) : '' : '')+" ")])]}},{key:"item.node.tenantInsightSet.trackerStock",fn:function(ref){
var item = ref.item;
return [(item.node.tenantInsightSet)?_c('div',[_c('span',{class:{
                            'red--text': item.node.tenantInsightSet.trackerStock < item.node.trackerStockLimit,
                            'green--text': item.node.tenantInsightSet.trackerStock > item.node.trackerStockLimit
                        }},[_vm._v(" "+_vm._s(item.node.tenantInsightSet.trackerStock || 0)+" "),(item.node.tenantInsightSet.trackerStock < item.node.trackerStockLimit)?_c(VIcon,{attrs:{"small":"","color":"red"}},[_vm._v("warning")]):_vm._e()],1)]):_vm._e()]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){_vm.openAddTenantUser = true}}},[_vm._v("person_add")])]}}])})],1),_c(VCardActions,{staticClass:"justify-center"},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.tenants.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }