import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"locationForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"pr-3 pt-3 pb-5",attrs:{"flat":"","outlined":""}},[_c(VTimeline,{attrs:{"align-top":"","dense":""}},[_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" location_on ")])]},proxy:true}])},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"11"}},[_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.tenantLocations.loading,"items":_vm.tenantLocations
                                        ? _vm.tenantLocations.edges
                                        : [],"no-data-text":_vm.$t('loading.no_available'),"label":_vm.$t('trips.origin'),"item-text":"node.name","item-value":"node.id","outlined":"","clearable":"","dense":"","hide-selected":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2",attrs:{"dense":""}},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")]),_c(VCol,{attrs:{"cols":"3"}},[_c('span',{staticClass:"grey--text mr-0 float-right"},[_c('i',[_vm._v(_vm._s(_vm.selectLabelByType( data.item.node.locationType.toLowerCase() )))])])])],1)]}}]),model:{value:(_vm.trip.origin),callback:function ($$v) {_vm.$set(_vm.trip, "origin", $$v)},expression:"trip.origin"}})],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"mt-2",attrs:{"small":"","rounded":"","outlined":"","color":"primary","icon":""},on:{"click":function($event){return _vm.openCreateLocationDialog('origin')}}},[_c(VIcon,[_vm._v("add")])],1)],1)],1)],1),_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("calendar_month")])]},proxy:true}])},[_c(VRow,{attrs:{"dense":""}},[(!_vm.templateMode)?_c(VCol,{attrs:{"cols":"11"}},[_c('DateTimeComponent',{attrs:{"label":_vm.templateMode
                                        ? _vm.$t('date.departure_date')
                                        : _vm.$t('date.departure_date') + '*',"icon":'calendar_month',"rules":[_vm.rules.requiredDateTime],"outlined":true,"dense":true,"disabled":_vm.templateMode,"filled":_vm.templateMode,"defaultTime":_vm.trip.plannedDepartureTime || '00:00'},model:{value:(_vm.trip.plannedDepartureDate),callback:function ($$v) {_vm.$set(_vm.trip, "plannedDepartureDate", $$v)},expression:"trip.plannedDepartureDate"}})],1):_vm._e(),(_vm.templateMode)?_c(VCol,{attrs:{"cols":"6"}},[_c('DateComponent',{attrs:{"label":_vm.templateMode
                                        ? _vm.$t('date.departure_date')
                                        : _vm.$t('date.departure_date') + '*',"icon":'calendar_month',"rules":[_vm.rules.required],"outlined":true,"dense":true,"disabled":_vm.templateMode,"filled":_vm.templateMode},model:{value:(_vm.trip.plannedDepartureDate),callback:function ($$v) {_vm.$set(_vm.trip, "plannedDepartureDate", $$v)},expression:"trip.plannedDepartureDate"}})],1):_vm._e(),(_vm.templateMode)?_c(VCol,{attrs:{"cols":"5"}},[_c(VAutocomplete,{attrs:{"items":_vm.system24,"auto-select-first":"","label":_vm.$t('date.time'),"outlined":"","dense":"","menu-props":{ maxHeight: '200' }},model:{value:(_vm.trip.plannedDepartureTime),callback:function ($$v) {_vm.$set(_vm.trip, "plannedDepartureTime", $$v)},expression:"trip.plannedDepartureTime"}})],1):_vm._e()],1)],1),_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" location_on ")])]},proxy:true}])},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"11"}},[_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.tenantLocations.loading,"no-data-text":_vm.$t('loading.no_available'),"items":_vm.tenantLocations
                                        ? _vm.tenantLocations.edges
                                        : [],"label":_vm.$t('trips.destination'),"item-text":"node.name","item-value":"node.id","outlined":"","clearable":"","hide-selected":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")]),_c(VCol,{attrs:{"cols":"3"}},[_c('span',{staticClass:"grey--text mr-0 float-right"},[_c('i',[_vm._v(_vm._s(_vm.selectLabelByType( data.item.node.locationType.toLowerCase() )))])])])],1)]}}]),model:{value:(_vm.trip.destination),callback:function ($$v) {_vm.$set(_vm.trip, "destination", $$v)},expression:"trip.destination"}})],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"mt-2",attrs:{"small":"","rounded":"","outlined":"","color":"primary","icon":""},on:{"click":function($event){return _vm.openCreateLocationDialog('destination')}}},[_c(VIcon,[_vm._v("add")])],1)],1)],1)],1),_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("calendar_month")])]},proxy:true}])},[_c(VRow,{attrs:{"dense":""}},[(!_vm.templateMode)?_c(VCol,{attrs:{"cols":"11"}},[_c('DateTimeComponent',{attrs:{"label":_vm.$t('date.arrival_date'),"icon":'calendar_month',"rules":_vm.trip.plannedArrivalDate !== null &&
                                    _vm.trip.plannedArrivalDate.length > 0
                                        ? [_vm.rules.checkDate]
                                        : [],"outlined":true,"dense":true,"disabled":_vm.templateMode,"filled":_vm.templateMode,"defaultTime":_vm.trip.plannedArrivalTime || '00:00'},model:{value:(_vm.trip.plannedArrivalDate),callback:function ($$v) {_vm.$set(_vm.trip, "plannedArrivalDate", $$v)},expression:"trip.plannedArrivalDate"}})],1):_vm._e(),(_vm.templateMode)?_c(VCol,{attrs:{"cols":"6"}},[_c('DateComponent',{attrs:{"label":_vm.$t('date.arrival_date') + '*',"icon":'calendar_month',"outlined":true,"dense":true,"disabled":_vm.trip.plannedDepartureDate === null ||
                                        _vm.trip.plannedDepartureDate.length <
                                            1 ||
                                        _vm.templateMode,"filled":_vm.templateMode},model:{value:(_vm.trip.plannedArrivalDate),callback:function ($$v) {_vm.$set(_vm.trip, "plannedArrivalDate", $$v)},expression:"trip.plannedArrivalDate"}})],1):_vm._e(),(_vm.templateMode)?_c(VCol,{attrs:{"cols":"5"}},[_c(VAutocomplete,{attrs:{"items":_vm.system24,"auto-select-first":"","label":_vm.$t('date.time'),"outlined":"","dense":"","menu-props":{ maxHeight: '200' }},model:{value:(_vm.trip.plannedArrivalTime),callback:function ($$v) {_vm.$set(_vm.trip, "plannedArrivalTime", $$v)},expression:"trip.plannedArrivalTime"}})],1):_vm._e()],1)],1)],1)],1),_c('AddLocation',{attrs:{"openAddLocationDialog":_vm.openAddLocation},on:{"closeMap":function($event){_vm.openAddLocation = false},"updateLocationsTable":function($event){return _vm.$apollo.queries.tenantLocations.refetch()},"updateLocation":_vm.updateLocation}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }