<template>
    <v-dialog max-width="600" v-model="openAddGroupDialog" persistent>
        <!--- MAIN CARD --->
        <v-card class="pb-4">
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("groups.add_group") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="">
                <v-form ref="addGroupForm">
                    <v-row class="mt-0">
                        <v-text-field
                            dense
                            v-model="groupName"
                            :label="$t('general.group_name') + '*'"
                            :rules="[rules.required]"
                            outlined
                            class="mx-4 mt-6"
                        ></v-text-field>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTIONS --->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isLoadingAddGroup"
                    @click="addGroup"
                    >{{ $t("general.add") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "AddGroup",
    props: ["openAddGroupDialog", "isStaffGroup"],
    data() {
        return {
            error: false,
            isLoadingAddGroup: false,
            /***********************************/
            /* --------- FORM DATA --------- */
            /*********************************/
            groupName: null,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.required") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {},
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.groupName = null;
            this.error = false;
            this.$refs.addGroupForm.resetValidation();
            this.$emit("closeAddGroupDialog");
        },
        /* ---> TENANT PARTNER MUTATION <--- */
        addGroup() {
            // validate form input :
            if (!this.$refs.addGroupForm.validate()) return;
            // Initialize Loading :
            this.isLoadingAddGroup = true;
            // Initialize Mutation :
            if (this.isStaffGroup) {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CreateStaffGroup(
                                $input: CreateStaffGroupInput!
                            ) {
                                createStaffGroup(input: $input) {
                                    coldchaStaffGroup {
                                        id
                                        name
                                        group {
                                            userSet {
                                                totalCount
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                name: this.groupName
                            }
                        }
                    })
                    .then(response => {
                        // Emit event to tabel data and load new groups //
                        this.$emit("refreshDataTable");
                        this.$emit(
                            "item-created",
                            response.data.createStaffGroup
                        );

                        const payload = {
                            color: "blue",
                            message: this.$t("alert_rules.group_saved")
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .catch(error => {
                        if (error.message == "INVALID_REFERENCE_NUMBER")
                            this.error = true;
                        this.isLoadingGroupAdd = false;
                    })
                    .finally(() => {
                        this.isLoadingAddGroup = false;
                        this.closeAndResetInput();
                    });
            } else {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CreateTenantUserGroup(
                                $input: CreateTenantUserGroupInput!
                            ) {
                                createTenantUserGroup(input: $input) {
                                    tenantStaffGroup {
                                        id
                                        name
                                        staffs {
                                            totalCount
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                tenantId: this.me.tenant.id,
                                name: this.groupName
                            }
                        }
                    })
                    .then(response => {
                        // Emit event to tabel data and load new groups //
                        this.$emit("refreshDataTable");
                        this.$emit(
                            "item-created",
                            response.data.createTenantUserGroup
                        );

                        const payload = {
                            color: "blue",
                            message: this.$t("alert_rules.group_saved")
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .catch(error => {
                        if (error.message == "INVALID_REFERENCE_NUMBER")
                            this.error = true;
                        this.isLoadingGroupAdd = false;
                    })
                    .finally(() => {
                        this.isLoadingAddGroup = false;
                        this.closeAndResetInput();
                    });
            }
        }
    }
};
</script>
