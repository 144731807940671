<template>
    <!-- TABLE -->
    <v-data-table
        :items="computedTableData"
        :headers="permissionHeaders"
        disable-pagination
        :loading-text="$t('loading.loading1')"
        loader-height="2"
        hide-default-footer
        :loading="isLoadingTable"
    >
        <template v-slot:item.view.value="{ item }">
            <v-checkbox
                v-if="item.view.value"
                v-model="selectedPermissions"
                :value="`${item.view.value}`"
            ></v-checkbox>
        </template>
        <template v-slot:item.create.value="{ item }">
            <v-checkbox
                v-if="item.create.value"
                v-model="selectedPermissions"
                :value="`${item.create.value}`"
            ></v-checkbox>
        </template>
        <template v-slot:item.edit.value="{ item }">
            <v-checkbox
                v-if="item.edit.value"
                v-model="selectedPermissions"
                :value="`${item.edit.value}`"
            ></v-checkbox>
        </template>
        <template v-slot:item.delete.value="{ item }">
            <v-checkbox
                v-if="item.delete.value"
                v-model="selectedPermissions"
                :value="`${item.delete.value}`"
            ></v-checkbox>
        </template>
    </v-data-table>
</template>
<script>
export default {
    name: "group-permissions-table",
    props: {
        tableData: {
            type: Array,
            required: true
        },
        permissions: {
            type: Array,
            required: true
        },
        isLoadingTable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            permissionHeaders: [
                {
                    text: this.$t("general.module"),
                    align: "start",
                    value: "module",
                    width: "50%",
                    sortable: false
                },
                {
                    text: this.$t("general.view"),
                    align: "left",
                    value: "view.value",
                    sortable: false
                },
                {
                    text: this.$t("general.create"),
                    align: "left",
                    value: "create.value",
                    sortable: false
                },
                {
                    text: this.$t("general.edit"),
                    align: "left",
                    value: "edit.value",
                    sortable: false
                },
                {
                    text: this.$t("general.delete"),
                    align: "left",
                    value: "delete.value",
                    sortable: false
                }
            ]
        };
    },
    computed: {
        computedTableData() {
            return this.tableData.map(permission => {
                if (this.permissions.includes(permission.view.value))
                    permission.view.isChecked = true;
                if (this.permissions.includes(permission.create.value))
                    permission.create.isChecked = true;
                if (this.permissions.includes(permission.edit.value))
                    permission.edit.isChecked = true;
                if (this.permissions.includes(permission.delete.value))
                    permission.delete.isChecked = true;

                return permission;
            });
        },
        selectedPermissions: {
            get() {
                return [...this.permissions];
            },
            set(value) {
                this.$emit("updateTableData", value);
            }
        }
    },
    mounted() {},
    methods: {}
};
</script>
