<template>
    <v-container fluid class="pa-0">
        <v-btn
            :color="defaultColor"
            depressed
            rounded
            @click="whatsNewDialog = !whatsNewDialog"
            :icon="displayFormat === 'icon'"
            :text="displayFormat === 'text'"
        >
            <v-badge 
                v-if="showWhatsNewBadge()" dot borded color="success"
            >
                <div v-if="displayFormat === 'icon'">
                    <v-icon>new_releases</v-icon>
                </div>
                <div v-else>
                    <span v-if="screenSize === 'lg' || screenSize === 'xl'">
                        {{ $t("general.new") }}
                    </span> 
                    <v-tooltip 
                            bottom
                            v-else
                    >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                new_releases
                                </v-icon>
                            </template>
                            <span>{{ $t("general.new") }}</span>
                        </v-tooltip>
                </div>
            </v-badge>
            <span v-else>
                <div v-if="displayFormat === 'icon'">
                    <v-icon>new_releases</v-icon>
                </div>
                <div v-else>
                    <div 
                        v-if="screenSize === 'lg' || screenSize === 'xl'"
                    >
                        {{ $t("general.new") }}
                    </div>
                    <v-tooltip bottom
                        v-else
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                            >
                            new_releases
                            </v-icon>
                        </template>
                        <span>{{ $t("general.new") }}</span>
                    </v-tooltip>
                </div>
            </span>
        </v-btn>
        <!-- What's New dialog -->
        <v-dialog
            v-model="whatsNewDialog"
            max-width="1200px"
            scrollable
            @keydown.esc="whatsNewDialog = !whatsNewDialog"
        >
            <v-card>
                <v-card-title class="primary white--text">
                    {{ $t("general.new1") }}
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-n3"
                        dark
                        icon
                        @click="whatsNewDialog = !whatsNewDialog"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <template v-for="release in releases">
                        <v-card :key="release.version" class="elevation-0" tile>
                            <v-card-title>
                                {{ release.title }}
                            </v-card-title>
                            <v-card-subtitle>
                                {{ release.version }}
                                -
                                {{ release.date }}
                                <v-chip
                                    x-small
                                    v-if="isNewRelease(release)"
                                    color="success"
                                    class="mr-1 mb-1"
                                >
                                    {{ $t("headers.new") }}
                                </v-chip>
                            </v-card-subtitle>
                            <v-card-text v-html="release.content"></v-card-text>
                            <v-divider></v-divider>
                        </v-card>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Config from "@/utils/config.json";

export default {
    props: {
        color: {
            type: String
        },
        format: {
            type: String,
            default: "text"
        }
    },
    data: function() {
        return {
            whatsNewDialog: false,

            releases: [
            {
                    version: Config.VUE_APP_APPLICATION_VERSION,
                    title: `What's new in ${Config.VUE_APP_APPLICATION_VERSION}`,
                    date: "2024-03-01",
                    content: `
                    <h3>New Features</h3>
                    <p>In this release, we made alot of changes on the UI to improve the user's experience. To see the new features click on the Preview switch at the top to enable the preview version.</p>
                    <ul>
                        <li><b>Trips(Preview)</b>: New look for the trips and freights makes it possible to see everything together in the list.</li>
                        <li><b>Trackers(Preview)</b>: New look makes more clear showing all the information about a tracker. It is now possible to easily know which trip a tracker belongs to.</li>
                        <li><b>Location(Preview)</b>: With the new design it is now very easy to find and update location information.</li>
                        <li><b>Collaborators(Preview)</b>: Partners is renamed to Collaborators to make more clear to the users the roles of every stakeholder involve in a trip.</li>
                        <li><b>Carriers(Preview)</b>: With this new design it is now very easy to find and update carrier information.</li>
                    </ul>
                `
                },
                {
                    version: "v0.6.0 beta",
                    title: "What's new in v0.6.0 beta",
                    date: "2023-09-18",
                    content: `
                    <h3>New Features</h3>
                    <ul>
                        <li><b>Summary</b>: Now shows the tracker date range.</li>
                        <li><b>Tracker</b>: It is now possible to see trip reference number on the tracker detail page and navigate directly to the trip from the tracker detail page. </li>
                        <li><b>Location</b>: It is now possible to report a missing public location directly to the ColdCha Support team.</li>
                        <li><b>Map</b>: It is now possible to see on the map all the already existing locations. That way it helps to prevent users from recreating an already existing location.</li>
                    </ul>

                    <h3 class="pt-5">Improvements</h3>
                    <ul>
                        <li><b>Sea Transport</b>: For Sea Transport Trips without origin and destination we show the POL and POD as the origin and destination.</li>
                        <li><b>Trip Filter</b>: Made some changes on the trips filter on alerts, to improve the performance. </li>
                        <li><b>Security</b>: Improve the application security in managing the application keys.</li>
                        <li><b>Map Search</b>: Changed the map search to now use google search API to search location.</li>
                        <li><b>Trip Timeline</b>: Change the text color on the trip timeline to make it more visible.</li>
                        <li><b>Page Responsiveness</b>: Made changes to improve the pages responsiveness to different screen sizes.</li>
                    </ul>

                    <h3 class="pt-5">Fixes</h3>
                    <ul>
                        <li>Fixed issue with tracker route data on the map.</li>
                        <li>Fixed an issue with standard alerts not showing on the trip alerts page.</li>
                        <li>Fixed the issue with the date time picker showing invalid date.</li>
                        <li>Fixed the issue with the trips filter bar showing two POL Code.</li>
                        <li>Fixed the issue with timeline showing alerts outside of the chart.</li>
                        <li>Fixed issue of tracker end date being earlier than start date.</li>
                        <li>Fixed issue with translation of dropdown when the is no data.</li>
                        <li>Fixed the issue of some trackers showing very high battery levels.</li>
                        <li>Fixed issue with vessel history giving error if it is too much.</li>
                        <li>Fixed issue with translation on admin backend.</li>
                        <li>Fixed issue with tracker date range not saving.</li>
                    </ul>
                `
                },{
                    version: "v0.5.0 beta",
                    title: "What's new in v0.5.0 beta",
                    date: "2023-08-24",
                    content: `
                    <h3>New Features</h3>
                    <ul>
                        <li><b>Alert Rule</b>: It is now possible to set the alert rule timer in hours and minutes.</li>
                        <li><b>Geofence History</b>: The application now shows the trip timeline geofence history information. </li>
                        <li><b>Trip / Freights</b>: Added the last location temperature to the Trips / Freights list view.</li>
                        <li><b>Location</b>: The location is now split into My Locations, Partner Locations and Public Locations. The good news is users no longer need the create public locations because the application tracks all public locations by default.</li>
                    </ul>

                    <h3 class="pt-5">Improvements</h3>
                    <ul>
                        <li><b>Weather Forecast</b>: Made it easier to set maximum and minimum temperature ranges when adding a location to weather forecast list. It is also now possible to edit the maximum and minimum temperature from the weather forecast list.</li>
                        <li><b>Trip Map</b>: Map now shows "No data" text on the top. Making the map section very visible. </li>
                        <li><b>Admin Insights</b>: Added insight information for the admin to provide more information on the tracker usage.</li>
                        <li><b>Timezone Settings</b>: Timezone setting now shows the UTC time difference.</li>
                        <li><b>Trip</b>: Change the date and time picker, by merging them into one component.</li>
                    </ul>

                    <h3 class="pt-5">Fixes</h3>
                    <ul>
                        <li>Fixed issue with departure and arrival dates not saving correctly based on timezone.</li>
                        <li>Fixed an issue with translation on the Sea Transport.</li>
                        <li>Fixed an issue for which the trip arrival time could be before the trip departure time.</li>
                        <li>Fixed bug with #Partner, #Documents and #Alerts on Freights Page not showing.</li>
                        <li>Fixed issue of trip map not showing properly if user navigates from and to the page.</li>
                        <li>Fixed issue for which the geofence name on the timeline chart is too long.</li>
                        <li>Fixed issue with last location filter on trips page.</li>
                        <li>Fixed issue with dragging trips table header.</li>
                    </ul>
                `
                },{
                    version: "v0.4.0 beta",
                    title: "What's new in v0.4.0 beta",
                    date: "2023-08-07",
                    content: `
                    <h3>New Features</h3>
                    <ul>
                        <li><b>Translation</b>: The application now translated into two languages English and Portuguese. </li>
                        <li><b>Geofencing location on timeline overview chart</b>: With this new feature, it is now possible to see on the timeline chart overview the geofence shading of a shipment.</li>
                        <li><b>Sea transport warning</b>: With this new feature the application now shows a message if the vessel information for sea transport is not provided.</li>
                        <li><b>Trips details</b>: The Copy Trip, New Template and Share Trip are now better organized in a dropdown.</li>
                        <li><b>Create trip</b>: Added a back button on the create trip form.</li>
                        <li><b>See what's new</b>: With this feature, you are able to see the release notes for all the releases.</li>
                        <li><b>Upload Trackers</b>: In the excel upload tracker flow, we now added a button to easily clear existing trackers.</li>
                        <li><b>Trip Timeline</b>:  In the trip timeline overview chart, it is now possible to zoom and reset the chart.</li>
                    </ul>

                    <h3 class="pt-5">Fixes</h3>
                    <ul>
                        <li>Fixed issue which sometimes causes error on tenant listing when super admin user login.</li>
                        <li>Fixed issue for which the arrival date could be earlier than the departure date.</li>
                        <li>Fixed issue with alert resolve button on the dashboard, it is now hidden for partners.</li>
                        <li>Fixed issue of the localization filter on places.</li>
                        <li>Fixed the issue of the tracker date range not showing on the trip fright form.</li>
                        <li>Fixed the issue of time, not showing after resetting the user form and open again.</li>
                        <li>Fixed the issue with dates not showing correctly based on user time zone settings.</li>
                    </ul>

                    <h3 class="pt-5">Performance</h3>
                    <ul>
                        <li>Added reference to the timeline backend objects to better processing and traceability.</li>
                        <li>Added a pulling event which checks for new weather location, in order quickly update the weather forecast.</li>
                    </ul>
                `
                },
                {
                    version: "v0.3.0 beta",
                    title: "What's new in v0.3.0 beta",
                    date: "2023-07-31",
                    content: `
                    <h3>New Features</h3>
                    <ul>
                        <li><b>Trip Template</b>: It is now possible to create a trip template and reuse it in creating a trip.</li>
                        <ul>
                            <li>Trip Template Menu Item.</li>
                            <li>Create New Template.</li>
                            <li>Delete Template.</li>
                        </ul>
                        
                        <li><b>Trip Template</b>: It is now possible to create a new template from an existing trip.</li>
                        <ul>
                            <li>Create template from trip.</li>
                        </ul>
                        
                        <li><b>Trip Copy</b>: It is now possible to create a new trip by making a copy of an existing one.</li>
                        <ul>
                            <li>Copy trip.</li>
                        </ul>

                        <li><b>Weather Forecast</b>: It is now possible to see weather forecast of your locations.</li>
                        <ul>
                            <li>Enable weather forecast for location.</li>
                            <li>List of weather forecast locations.</li>
                        </ul>

                        <li><b>Partner Dashboard</b>: We now have a dashboard for partners showing some KPIs.</li>
                        <ul>
                            <li>Dashboard.</li>
                        </ul>

                        <li><b>Alerts</b>: It is now possible to navigate to the trip alert page from Alert dialog and Alert dashboard.</li>
                        <ul>
                            <li>Re-direct to trip alert.</li>
                        </ul>

                        <li><b>Trackers</b>: Show the tracker sensor measuring units.</li>
                        <ul>
                            <li>Sensor data measuring unit.</li>
                        </ul>

                        <li><b>Trackers</b>: The application is now able to auto map excel file headers to file upload fields.</li>
                        <ul>
                            <li>Excel upload tracker.</li>
                        </ul>

                        <li><b>Tracker upload logs</b>: It is now possible to see the tracker upload log history.</li>
                        <ul>
                            <li>Upload history.</li>
                        </ul>

                        <li><b>Report</b>: Auto select first active tracker.</li>
                        <ul>
                            <li>Tracker selected.</li>
                        </ul>
                    </ul>

                    <h3 class="pt-5">Fixes</h3>
                    <ul>
                        <li><b>Trip filter</b>: Bug filtering active sea trips.</li>
                        <li><b>Vessel timeline</b>: Align the sorting button and added "No data available".</li>
                        <li><b>Trip Freight</b>:  Freight form shows space between the form and the dialog when scroll the trackers to the end.</li>
                        <li><b>Alert</b>: Fixed bug which occurred when viewing an alert from the dashboard, caused by not finding the tenant location.</li>
                        <li><b>Authentication</b>: Fix bug which occurred when a is token expired.</li>
                        <li><b>Chart dates</b>: Chart dates were not based on the user time zone preference.</li>
                        <li><b>Partner Details</b>: Name not properly align.</li>
                        <li><b>Tracker</b>: Edit tracker was sometimes giving errors if not assign to properly.</li>
                        <li><b>Alerts</b>: Alert Action Group was not enabled when you create a new action group. You will have to select it again before it is enabled.</li>
                        <li><b>Trip Details</b>: Navigation to freight from trip list was sometimes not working properly.</li>
                        <li><b>Trip</b>: Create trip, Fix issue where verification of unique reference number failed.</li>
                        <li><b>Tenants</b>: Action buttons not aligning well.</li>
                        <li><b>Carriers</b>: After adding a new carrier, carriers table not refreshed.</li>
                        <li><b>Trip Detail</b>: When edit trip in the edit dialog inside the detail drawer, does not refresh the data on the current open tab (noticed this updating freight on the edit trip dialog).</li>
                        <li><b>Trackers</b>: Tracker map gives error.</li>
                        <li><b>Create Alert Rules</b>: Advanced geofence settings, cannot see the bottom of dropdown.</li>
                        <li><b>Users</b>: Removing a user from group, was sometimes not working.</li>
                        <li><b>Alert Rule</b>: Unable to save alert after edit.</li>
                    </ul>

                    <h3 class="pt-5">Performance</h3>
                    <ul>
                        <li>Error when creating trip, now show snack bar message if an error occurs or when successful.</li>
                        <li>Verify if reference number is available for a new trip.</li>
                        <li>Geofence location tracking</b>: The application can now automatically track all airports and seaports around the world.</li>
                        <li><b>Sea transport</b>: Use the load date(if provided) to improve the performance of vessel voyage tracking for short routes for example ferries.</li>
                        <li><b>Trip import from excel file.</li>
                    </ul>
                `
                },
                {
                    version: "v0.2.0 beta",
                    title: "What's new in v0.2.0 beta",
                    date: "2023-07-21",
                    content: `
                    <h3>New Features</h3>
                    <ul>
                        <li><b>Auto Complete Trip</b>: With this feature it is now possible to set the configuration yourself to auto complete trips.</li>
                        <li><b>Report</b>: With this feature, it is now possible to generate a pdf report for a trip. Also generate the csv file of the tracker data.</li>
                        <li><b>Sea Transport Vessel Timeline</b>: With this feature you can see the timeline of the vessel trip.</li>
                        <li><b>Copy Tracker Serial Number</b>: Its now possible to easily copy a tracker from Trip or Freight Summary.</li>
                        <li><b>Recorders</b>: Are now visible on the platform.</li>
                        <li><b>Last Tracker Location</b>: It now possible to see the last tracker location on the trips list.</li>
                        <li><b>Emails</b>: We also made some modifications of the email templates. Added more fields.</li>
                        <li><b>Trackers</b>: It is now possible to reuse trackers on the platform. So, a tracker can be reused on a different trip and the application will show the data for just the different trips without overlap.</li>
                        <li><b>Login</b>: Added hint on the password characters supported by the application.</li>
                        <li><b>Carrier Events</b>: It is now possible to sort on the carrier events.</li>
                        <li><b>Trip Sorting</b>: It is now possible to sort on tracker Action Date, Last Connection Date.</li>
                    </ul>

                    <h3 class="pt-5">Fixes</h3>
                    <ul>
                        <li>Fixed issue with carrier timeline still updating, even trip is completed.</li>
                        <li>Fixed issue with partner login to access shared trips.</li>
                        <li>Fixed issue on geofence processing timeline showing wrong exit location when tracker signal is lost.</li>
                        <li>Fixed issue of carrier link not working.</li>
                        <li>Fixed issue with tracker upload from excel file that does not support the import format.</li>
                        <li>Fixed issue with tracker sensor charts not showing data with date ranges.</li>
                    </ul>

                    <h3 class="pt-5">Performance</h3>
                    <ul>
                        <li>Improved the background job, process in processing the sea transport vessel tracking, for the ATD and ATA.</li>
                        <li>Improved the backend process in geofence location tracking.</li>
                        <li>Improved the alert action process and emailing.</li>
                        <li>Tracking shipments from OOCL.</li>
                        <li>Improved the backend process in deciding the start and end dates of a trip, freight.</li>
                    </ul>
                `
                },
                // {
                //     version: "v0.1.0 beta",
                //     title: "What's new in v0.1.0 beta",
                //     date: "2023-07-21",
                //     content: `
                //     <h3>New Features</h3>
                //     <ol>
                //         <li>Initial Project</li>
                //     </ol>

                //     <h3 class="pt-5">Fixes</h3>
                //     <ul>
                //         <li>Fixed several issues.</li>
                //     </ul>

                //     <h3 class="pt-5">Performance</h3>
                //     <ul>
                //         <li>Improved the background jobs.</li>
                //     </ul>
                // `
                // }
            ]
        };
    },
    computed: {
        screenSize(){
            let _resposiveView = this.$vuetify.breakpoint.width;
            if(_resposiveView < 650){
                return "xs";
            }else if(_resposiveView < 1000){
                return "sm";
            }else if(_resposiveView < 1400){
                return "md";
            }else if(_resposiveView < 1900){
                return "lg";
            }else{
                return "xl";
            }
        },
        defaultColor() {
            if(!this.color)
                return undefined;
            return this.color;
        },
        displayFormat() {
            switch(this.format) {
                case "btn":
                    return "btn";
                case "icon":
                    return "icon";
                default:
                    return "text";
            }
        }
    },
    methods: {
        showWhatsNewBadge() {
            // show badge if last release date is 3 days ago or less
            var lastReleaseDate = new Date(this.releases[0].date);
            var now = new Date();
            var diff = now - lastReleaseDate;
            var diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

            return diffDays <= 7;
        },
        isNewRelease(release) {
            // show badge if last release date is 3 days ago or less
            var lastReleaseDate = new Date(release.date);
            var now = new Date();
            var diff = now - lastReleaseDate;
            var diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

            return diffDays <= 7;
        }
    }
};
</script>
