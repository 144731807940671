<template>
    <div :key="booking.id">
        <!-- tabs -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="transparent"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                {{ t.name }}  
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <!-- tab content -->
        <v-tabs-items v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <v-tab-item value="tab-summary" :transition="false">
                <!-- add tab 1 -->
                <BookingSummary
                    :booking="booking"
                    v-on:onBookingUpdated="onBookingUpdated"
                >
                </BookingSummary>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
// todo - add new components
import BookingSummary from './details/BookingSummary.vue'; 
//import CreateAndEditBooking from "@/components/bookings/create/CreateAndEditBooking.vue";
//import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";

export default {
    props: {
        booking: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        //todo
        BookingSummary,
        //CreateAndEditBooking, 
        //FilterMenu
    },
    data() {
        return {
            tab: null,
            editedMode: false,
            defaultFilterChoices: [
                //define filter tabs
                {
                    //for filter menu
                    code: "summary",
                    name: "Summary",
                    enabled: true
                }
            ],

        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 158 ;
            return  _break;
        }, 
        filterChoices: {
            get() {
                let _filterChoices = helper.getFilterChoices(
                    this.defaultFilterChoices,
                    "bookingDetailFilterChoices"
                );
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    bookingDetailFilterChoices: value
                });
            }
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    mounted() {},
    methods: {
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        updateBooking(booking) {
            this.$emit("updateBooking", booking);
        },
        onBookingUpdated() {
            // TODO edit booking
            this.$emit("onBookingUpdated")
        },
    }
};
</script>