<template>
    <v-container fluid>
        <v-row class="d-flex align-center justify-center px-10">
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <PartnerFilter
                    v-on:filter-changed="onFilterChanged"
                    :partnerType="partnerType"
                    :label="$t('general.search_carrier')"  
                    class="mx-3"
                ></PartnerFilter>
                <v-btn
                    v-scroll="onScroll"
                    v-show="goTopShow"
                    fab
                    dark
                    fixed
                    bottom
                    right
                    small
                    style="z-index: 1"
                    color="primary"
                    @click="toTop"
                >
                    <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <v-row class="ma-0 px-0">
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <h2
                            class="
                                modelTitle
                            "
                        >
                            {{ $t("home.carriers") }}
                        </h2>
                    </v-col>
                    <v-spacer></v-spacer>

                    <v-btn elevation="0" color="primary" class="mr-1 mt-4" @click="createPartners()">
                        {{ $t("carrier.add_carrier") }}
                    </v-btn>
                </v-row>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <!-- LIST -->
                <v-row>
                    <!-- <v-col cols="3" class="mt-2">
                        <sortBy v-model="orderBy" :sortByChoices="sortByChoices" class=""></sortBy>
                    </v-col> -->
                    <v-col cols="12">
                        <PreviewPartnerTable
                            ref="partnerTable"
                            v-on:click:row="onPartnerSelected"
                            :partnerFilter="carrierFilter"
                            :type="partnerType"
                            :orderBy="orderBy"
                            class="mt-3"
                        > 
                            <template v-slot:actionButton>
                                <v-btn elevation="0" color="primary" class="mr-1 mt-4" @click="createPartners()">
                                    {{ $t("carrier.add_carrier") }}
                                </v-btn>
                            </template>
                        </PreviewPartnerTable>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="partnerDetailDrawer"
            :width="partnerDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            disable-route-watcher
            temporary
        >
            <div>
                <PartnerDetail
                    ref="partnerDetails"
                    v-if="selectedPartner || idFromRouter"
                    :key="selectedPartner ? selectedPartner.id : idFromRouter"
                    :partnerId="idFromRouter"
                    :partner="selectedPartner"
                    :isFullScreen="isPartnerDetailDrawerFullScreen"
                    :isCarrier="true"
                    :type="partnerType"
                    v-on:changeFullScreen="
                        onPartnerDetailDrawerFullScreenChange
                    "
                    v-on:refreshDataTable="refetchTableData"
                    v-on:close="partnerDetailDrawer = false"
                ></PartnerDetail>
            </div>
        </v-navigation-drawer>
        <AddPartnerDialog
            :openAddPartnerDialog="openAddPartnerDialog"
            :partnerByType.sync="partnerType"
            v-on:refreshPartnersTable="$emit('refreshDataTable')"
            v-on:closeAddPartnerDialog="openAddPartnerDialog = false"
        ></AddPartnerDialog>
    </v-container>
</template>

<script>
//import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import PartnerFilter from "@/components/partners/PreviewPartnerFilter.vue";
import PreviewPartnerTable from "@/components/partners/PreviewPartnerTable.vue";
import PartnerDetail from "@/components/partners/PreviewPartnersDetails.vue";
import AddPartnerDialog from "@/components/partners/create/AddPartnerDialog.vue";
import { eventBus } from "@/main.js";

export default {
    title: "Carriers",
    components: {
        //CarrierFilter,
        PreviewPartnerTable,
        PartnerFilter,
        PartnerDetail,
        AddPartnerDialog,
        // sortBy
    },

    apollo: {},
    data() {
        return {
            // --------- PARTNERS DATA --------- \\
            partners: [],
            selectedPartner: null,
            carrierFilter: {
                first: 15,
                carrierType: null,
                partnerType: "carrier"
            },
            // --------- DIALOGS/LOADINGS/MENUS --------- \\
            partnerDetailDrawer: false,
            idFromRouter: null,
            partnerType: "carrier",
            openAddPartnerDialog: false,
            isLoading: false,
            menu: false,
            goTopShow: false,
            partnerDetailFullScreen: false,
            /* Sort by */
            orderBy: null,
            sortByChoices: [
                // {
                //     code: "partnerType",
                //     name: this.$t("headers.partner_type")
                // },
                {
                    code: "name",
                    name: this.$t("headers.collaborator_name")
                },
                {
                    code: "openTrips",
                    name: this.$t("headers.open_trips")
                },
                {
                    code: "totalTrips",
                    name: this.$t("headers.total_trips")
                }
            ]
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        partnerDetailDrawerWidth() {
            if (this.partnerDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "90%";
                case "sm":
                    return "90%";
                case "md":
                    return "90%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isPartnerDetailDrawerFullScreen() {
            return this.partnerDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },

    watch: {
        orderBy(val) {
            if (val) {
                let _filter = helper.getFilterValues(`partnerTableFilters`);
                if (_filter) {
                    _filter.orderBy = val;
                    helper.setFilterValues(`${this.tab}TableFilters`, this.filter);
                }
            }
        },
        
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.partnerDetailDrawer = false;
        let partner = this.$store.getters["partner/getCreatedPartner"];
        let cancel = this.$store.getters["partner/getCancelPartner"];
        
        if (partner) {
            this.onPartnerCreate(partner);
        } else if (cancel) {
            this.onPartnerCancel(cancel);
        }

        /* update tab and sort */
        this.$nextTick(() => {
            let _filter = null;
            let _tabs = null;

            /* Filter */
            _filter = helper.getFilterValues(`partnerTableFilters`);
            if (_filter) {
                if (_filter.orderBy?.includes("-")) {
                    this.orderBy = _filter.orderBy.replace("-", "");
                } else {
                    this.orderBy = _filter.orderBy;
                }
            }

            //handleNavigateRoute
            this.handleRouteChange();
        });
    },
    methods: {
        handleRouteChange() {
            if (this.$route.query.id) {
                this.idFromRouter = this.$route.query.id;
                this.partnerDetailDrawer = true;
            }
        },
        onLoading(val) {
            this.isLoading = val;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        createPartners() {
            this.$router.push({
                path: "/previewcreatepartner",
                query: {
                    type: `create-${this.partnerType}`,
                }
            });
        },
        loadMore() {
            this.isLoading = false;
        },
        // -----> DATA RELOAD OR RESET <----- \\
        refetchTableData() {
            if (this.$refs.partnerTable) {
                this.$refs.partnerTable.refreshQuery();
            }
        },
        onFilterChanged(filter) {
            this.carrierFilter = filter;
        },
        // -----> EVENT HANDLERS (clicks, onchange, scroll, etc) <------ \\
        onPartnerSelected(partner) {
            this.selectedPartner = partner.node;
            this.$router.push({ query: { id: partner.node.id } }).catch(() => {});
            this.partnerDetailDrawer = true;
        },
        onPartnerCreate(partner) {
            this.selectedPartner = partner;
            this.$router.push({ query: { id: partner.id } }).catch(() => {});
            this.partnerDetailDrawer = true;
            if (partner) {
                this.$store.dispatch("partner/updateCreatedPartner", {
                    type: "update",
                    partner: null
                });
            }
        },
        onPartnerCancel(partner) {
            this.selectedPartner = partner;
            this.$router.push({ query: { id: partner.id } }).catch(() => {});
            this.partnerDetailDrawer = true;
            if (partner) {
                this.$store.dispatch("partner/updateCreatedPartner", {
                    type: "update",
                    partner: null 
                });
            }
        },
        onScroll(e) {
            // scroll to top of the page
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        onPartnerDetailDrawerFullScreenChange(val) {
            this.partnerDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        cleanRoute() {  
            if(this.$route.query.tab){
                let newQuery = { ...this.$route.query };
                delete newQuery.tab; // Remove the 'tab' parameter
        
                // Navigate to the same route with the updated query object
                this.$router.replace({ query: newQuery });
            }
        },
       /*  onPartnerCreated(val) {
            this.selectedPartner = val.tenantPartner;
            this.partnerDetailDrawer = true;
            this.refetchTableData();
        } */
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.modelTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;
    color: #000000;
}
</style>
