import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2 mt-2",attrs:{"rounded":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("trackers.assign_tenant"))+" ")])]}}]),model:{value:(_vm.trackerChangeTenantDialog),callback:function ($$v) {_vm.trackerChangeTenantDialog=$$v},expression:"trackerChangeTenantDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("autorenew")]),_vm._v(_vm._s(_vm.$t("general.change"))+" "+_vm._s(_vm.$t("trackers.tracker_tenant"))+" ")],1),(_vm.noTrackersError)?_c(VAlert,{staticStyle:{"margin-bottom":"0%","border-radius":"0% 0% 4% 4%"},attrs:{"dense":"","type":"error","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.$t("trackers.no_tracker"))+" ")]):_vm._e(),_c(VCardText,{staticStyle:{"padding-top":"20px"}},[_c(VContainer,{attrs:{"fluid":""}},[_c('h3',{staticStyle:{"margin-bottom":"1%","margin-top":"1%"}},[_vm._v(" "+_vm._s(_vm.$t("trackers.tenant"))+": ")]),_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c(VAutocomplete,{attrs:{"clearable":"","hide-selected":"","hide-details":"","auto-select-first":"","dense":"","outlined":"","items":_vm.tenants.edges,"no-data-text":_vm.$t('loading.no_available'),"item-text":"node.name","item-value":"node.id","menu-props":{
                                maxHeight: '180px',
                            }},model:{value:(_vm.pendingTenant),callback:function ($$v) {_vm.pendingTenant=$$v},expression:"pendingTenant"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end pb-5 pr-5"},[_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":function($event){_vm.trackerChangeTenantDialog = false;
                    _vm.noTrackersError = false;}}},[_vm._v(_vm._s(_vm.$t("general.close")))]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.isLoadingChangeTracker,"rounded":""},on:{"click":_vm.changeTrackersTenant}},[_vm._v(" "+_vm._s(_vm.$t("general.apply_changes"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }