
import * as types from "../types";
import { apolloClient, onLogin, onLogout } from "@/vue-apollo";

const ACCESS_TOKEN_KEY = "your_access_token_key";
const REFRESH_TOKEN_KEY = "your_refresh_token_key";

// Helper functions for localStorage operations
const localStoragePlugin = {
    setItem(key, item) {
        localStorage.setItem(key, JSON.stringify(item));
    },
    getItem(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    },
    removeItem(key) {
        localStorage.removeItem(key);
    }
};

// Initialize state from localStorage if available
const state = {
    createdLocation: localStoragePlugin.getItem('createdLocation') || null,
    cancel: localStoragePlugin.getItem('cancel') || null,
};

const mutations = {
    setCreatedLocation(state, { type, location }) {
        if (type == "update") {
            state.createdLocation = location;
            state.cancel = null;
            localStoragePlugin.setItem('createdLocation', location);
            localStoragePlugin.removeItem('cancel'); // Clear 'cancel' in localStorage
        } else if (type == "cancel") {
            state.cancel = location;
            state.createdLocation = null;
            localStoragePlugin.setItem('cancel', location);
            localStoragePlugin.removeItem('createdLocation'); // Clear 'createdLocation' in localStorage
        }
    },
};

const actions = {
    updateCreatedLocation({ commit }, { type, location }) {
        commit("setCreatedLocation", { type, location });
    },
    
};

const getters = {
    getCreatedLocation: state => state.createdLocation,
    getCancelLocation: state => state.cancel,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
