import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"py-5 transparent",class:_vm.shouldStickyFilter ? 'sticky-card' : '',attrs:{"outlined":""}},[_c(VRow,{staticClass:"d-flex align-center justify-center transparent",attrs:{"align":"start"}},[_c(VCol,[_c('SearchBar',{attrs:{"label":_vm.label},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }