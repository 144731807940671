<template>
    <v-container fluid class="pa-0">
        <v-card flat>
            <v-card-text class="pb-0 pt-0">
                <v-form ref="editSystemProfileForm">
                    <v-row dense class="py-0">
                        <v-col cols="12">
                            <v-subheader
                                :class="
                                    editMode
                                        ? 'subtitle-1 pl-1 py-0 mb-0'
                                        : 'subtitle-1 pl-1 disabled'
                                "
                            >
                                <v-icon left class="mr-2">image</v-icon>
                                Logo
                            </v-subheader>
                        </v-col>
                        <v-col cols="12"
                            lg="5"
                            md="5"
                            sm="12"   
                        >
                            <div>
                                <v-text-field
                                    v-model="editingSystemProfile.logoText"
                                    outlined
                                    prepend-icon="mdi-text"
                                    label="Logo Text"
                                    :disabled="!editMode"
                                    hide-details
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="auto"
                            class="d-flex justify-start pt-0 borderDash ml-9"
                        >
                            <div
                                v-if="previewImg ==null && editingSystemProfile.logoUrl == null"
                                style="width:180px; height:180px"
                            >
                                <span>
                                    Add your logo here
                                </span>
                            </div>
                            <v-img
                                v-else
                                label="Logo preview"
                                width="180"
                                height="180"
                                contain
                                :src="validateInput
                                    ? previewImg 
                                    : editingSystemProfile.logoUrl
                                "
                                
                                class="mx-0 my-0 px-0 py-0"
                            ></v-img>
                        </v-col>
                        <v-col 
                            cols="auto" 
                            class="d-flex justify-space-between flex-column pa-0"
                        >
                            <v-btn-toggle
                                class="d-flex flex-column "
                                v-if="editMode"
                            >
                                <v-btn
                                    icon 
                                    small
                                    :call="isSelecting"
                                    @click="handleFileImport"
                                >
                                    <v-icon>
                                        camera
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    small
                                    @click="removePicture"
                                >
                                    <v-icon>
                                        delete
                                    </v-icon>
                                </v-btn>
                            </v-btn-toggle>
                            <div>
                                <v-file-input
                                    ref="fileInputRef"
                                    outlined
                                    style="display:none"
                                    :rules="[rules.maxSize]"
                                    @change="onFileChange"
                                    :disabled="!editMode"
                                    accept="image/png, image/jpeg, image/jpg, image/bmp, .svg"
                                ></v-file-input>
                            </div>
                            <div
                                v-if="editMode"
                                class="caption grey--text text--darken-1 ml-1"
                            >
                                Please upload a high resolution image for best results. <br>
                                Image size: 130 x 40 pixels <br>
                                File type: .png, .jpg, .jpeg, .bmp, .svg <br>
                                Maximum file size: 2MB
                            </div>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-subheader
                            :class="
                                editMode
                                    ? 'subtitle-1 pl-1'
                                    : 'subtitle-1 pl-1 disabled'
                            "
                        >
                            <v-icon left class="mr-2">color_lens</v-icon>
                            Theme Colors
                        </v-subheader>
                        <v-card
                            class="py-2 px-6 ml-3"
                            flat
                            :disabled="!editMode"
                        >
                            <v-row dense>
                                <v-col cols="12"
                                    lg="5"
                                    md="5"
                                    sm="12"
                                
                                >
                                    <v-card
                                        :color="
                                            editingSystemProfile.themeJson
                                                .primary
                                        "
                                        tile
                                        dark
                                        @click="
                                            openColorPickerDialog('primary')
                                        "
                                    >
                                        <v-card-title class="subtitle-1">
                                            Primary
                                            <v-spacer></v-spacer>
                                            {{
                                                editingSystemProfile.themeJson
                                                    .primary
                                            }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                                <v-col cols="12"
                                    lg="5"
                                    md="5"
                                    sm="12"
                                >
                                    <v-card
                                        :color="
                                            editingSystemProfile.themeJson
                                                .secondary
                                        "
                                        tile
                                        dark
                                        @click="
                                            openColorPickerDialog('secondary')
                                        "
                                    >
                                        <v-card-title class="subtitle-1">
                                            Secondary
                                            <v-spacer></v-spacer>
                                            {{
                                                editingSystemProfile.themeJson
                                                    .secondary
                                            }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                                <v-col cols="12"
                                    lg="5"
                                    md="5"
                                    sm="12"
                            
                                >
                                    <v-card
                                        :color="
                                            editingSystemProfile.themeJson.info
                                        "
                                        tile
                                        dark
                                        @click="openColorPickerDialog('info')"
                                    >
                                        <v-card-title class="subtitle-1">
                                            Info
                                            <v-spacer></v-spacer>
                                            {{
                                                editingSystemProfile.themeJson
                                                    .info
                                            }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                                <v-col cols="12"
                                    lg="5"
                                    md="5"
                                    sm="12"
                                
                                >
                                    <v-card
                                        :color="
                                            editingSystemProfile.themeJson
                                                .success
                                        "
                                        tile
                                        dark
                                        @click="
                                            openColorPickerDialog('success')
                                        "
                                    >
                                        <v-card-title class="subtitle-1">
                                            Success
                                            <v-spacer></v-spacer>
                                            {{
                                                editingSystemProfile.themeJson
                                                    .success
                                            }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                                <v-col cols="12"
                                    lg="5"
                                    md="5"
                                    sm="12"
                                >
                                    <v-card
                                        :color="
                                            editingSystemProfile.themeJson
                                                .warning
                                        "
                                        tile
                                        dark
                                        @click="
                                            openColorPickerDialog('warning')
                                        "
                                    >
                                        <v-card-title class="subtitle-1">
                                            Warning
                                            <v-spacer></v-spacer>
                                            {{
                                                editingSystemProfile.themeJson
                                                    .warning
                                            }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                                <v-col cols="12"
                                    lg="5"
                                    md="5"
                                    sm="12"
                                >
                                    <v-card
                                        :color="
                                            editingSystemProfile.themeJson.error
                                        "
                                        tile
                                        dark
                                        @click="openColorPickerDialog('error')"
                                    >
                                        <v-card-title class="subtitle-1">
                                            Error
                                            <v-spacer></v-spacer>
                                            {{
                                                editingSystemProfile.themeJson
                                                    .error
                                            }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-5">
                <v-btn
                    v-if="editMode"
                    outlined
                    rounded
                    color="grey lighten-1"
                    class="ml-10"
                    @click="onDefautThemeColors"
                >
                    Default theme
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    color="primary"
                    @click="editMode = !editMode"
                    v-if="!editMode"
                    class="mr-12"
                >
                    Edit
                </v-btn>
                <v-btn
                    v-if="editMode"
                    rounded
                    text
                    :disabled="isSavingSystemProfile"
                    @click="resetSystemProfile"
                    >Cancel</v-btn
                >
                <v-btn
                    v-if="editMode"
                    rounded
                    :loading="isSavingSystemProfile"
                    color="primary"
                    @click="saveSystemProfile"
                    >Save</v-btn
                >
            </v-card-actions>
        </v-card>
        <!-- color picker dialog -->
        <v-dialog   
            v-model="colorPickerDialog" 
            persistent 
            max-width="400px"
        >
            <v-card>
                <v-card-title class="primary white--text mb-2">
                    Select {{ selectedColorLabel }} color
                </v-card-title>
                <v-card-text justify="space-around">
                    <v-color-picker
                        v-model="selectedColor"
                        class="mx-auto"
                        show-swatches
                    ></v-color-picker>
                </v-card-text>
                <v-card-actions class="pb-3">
                    <v-spacer></v-spacer>
                    <v-btn 
                        text 
                        rounded 
                        @click="colorPickerDialog = false"
                    >Cancel</v-btn>
                    <v-btn 
                        rounded 
                        color="primary" 
                        @click="onColorSelected"
                    >Select</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import Config from "@/utils/config.json";

export default {
    props: {
        tenant: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loadingBtn: false,
            colorPickerDialog: false,
            defaultTenantsettings: {
                logo: null,
                logoUrl: null,
                logoText: null,
                themeJson: {
                    primary: "#1B6A99",
                    secondary: "#154D79",
                    success: "#C5E17A",
                    info: "#3D9BC8",
                    accent: "#B05C4B",
                    warning: "#ED8E83",
                    error: "#CB5858"
                }
            },
            isSavingSystemProfile: false,
            selectedColor: null,
            selectedColorLabel: "primary",
            editMode: false,
            isSelecting: false,
            validateInput: false,
            previewImg: null,
            defaultThemeJson: {
                primary: "#1B6A99",
                secondary: "#154D79",
                success: "#C5E17A",
                info: "#3D9BC8",
                accent: "#B05C4B",
                warning: "#ED8E83",
                error: "#CB5858"
            },
            rules: {
                maxSize: value =>
                    !value ||
                    value.size < 2000000 ||
                    "Logo size should be less than 2 MB!",
            }
        };
    },
    computed: {
        editingSystemProfile: {
            get() {
                return this.defaultTenantsettings
            },
            set(value) {
                this.defaultTenantsettings = {
                    logo: value.logo,
                    logoUrl: value.logoUrl,
                    logoText: value.logoText,
                    themeJson: value.themeJson
                };
            }
        },
        logoImageStyleObject() {
            return {
                maxHeight: "150px",
            };
        }
    },
    mounted() {
        this.defaultTenantsettings = {
            logo: this.tenant?.logo 
                ? this.tenant?.logo 
                : this.defaultTenantsettings.logo,
            logoUrl: this.tenant?.logoUrl 
                ? this.tenant?.logoUrl 
                : this.defaultTenantsettings.logoUrl,
            logoText: this.tenant?.logoText 
                ? this.tenant?.logoText 
                : this.defaultTenantsettings.logoText,
            themeJson: this.tenant?.themeJson 
                ? JSON.parse(this.tenant?.themeJson)
                : this.defaultTenantsettings.themeJson    
        };
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'tenant_admin_settings_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("tenant_admin_details_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("tenant_admin_details_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        isValidObject(obj) {
            if (obj == null || obj == undefined) {
                return false;
            } else {
                let _obj = JSON.parse(obj);
                if (Object.keys(_obj).length == 0) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        onDefautThemeColors() {
            this.editingSystemProfile.themeJson = this.defaultThemeJson;
            Object.entries(this.defaultThemeJson).forEach(([key, value]) => {
                this.$vuetify.theme.themes.light[key] = value;
            });
        },
        resetSystemProfile() {
            this.defaultTenantsettings = {
                logo: null,
                logoText: null,
                themeJson: {
                    primary: "#1B6A99",
                    secondary: "#154D79",
                    success: "#C5E17A",
                    info: "#3D9BC8",
                    accent: "#B05C4B",
                    warning: "#ED8E83",
                    error: "#CB5858"
                }
            };

            this.editingSystemProfile = {
                logo: this.tenant?.logo
                    ? this.tenant?.logo
                    : this.defaultTenantsettings.logo,
                logoText: this.tenant?.logoText
                    ? this.tenant?.logoText
                    : this.defaultTenantsettings.logoText,
                logoUrl: this.tenant?.logoUrl
                    ? this.tenant?.logoUrl
                    : this.defaultTenantsettings.logoUrl,
                themeJson: this.isValidObject(this.tenant?.themeJson)
                    ? JSON.parse(this.tenant?.themeJson)
                    : this.defaultTenantsettings.themeJson
            };
            this.editMode = false;
        },
        onFileChange(file) {
            if (!file) {
                this.editingSystemProfile.logo = null;
                this.editingSystemProfile.logoUrl = null;
            } else {
                this.validateInput = this.$refs.fileInputRef.validate();
                this.previewImg = URL.createObjectURL(file);
                this.createImage(file);
            }
        },
        createImage(file) {
            let reader = new FileReader();
            reader.onload = e => {
                this.editingSystemProfile.logoUrl = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        openColorPickerDialog(colorLabel) {
            this.colorPickerDialog = true;
            this.selectedColorLabel = colorLabel;
            this.selectedColor = this.editingSystemProfile.themeJson[
                colorLabel
            ];
        },
        onColorSelected() {
            this.editingSystemProfile.themeJson[
                this.selectedColorLabel
            ] = this.selectedColor;
            /* this.$vuetify.theme.themes.light[
                this.selectedColorLabel
            ] = this.selectedColor; */
            this.colorPickerDialog = false;
        },
        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener(
                "focus",
                () => {
                    this.isSelecting = false;
                },
                { once: true }
            );

            this.$refs.fileInputRef.$refs.input.click();
        },
        removePicture() {
            let remove = {   
                logo: null,
                logoUrl: null
            }

            let newObject = {
                ...this.editingSystemProfile,
                ...remove
            }

            this.editingSystemProfile = newObject;
            this.previewImg = null;
            this.$refs.fileInputRef.reset();
        },
        saveSystemProfile() {
            this.isSavingSystemProfile = true;
            let payload = {
                tenantId: this.tenant.id,
                logo: this.editingSystemProfile.logoUrl,
                logoText: this.editingSystemProfile.logoText,
                themeJson: JSON.stringify(this.editingSystemProfile.themeJson)
            };
           
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTenantSettings(
                            $input: UpdateTenantSettingsInput!
                        ) {
                            updateTenantSettings(input: $input) {
                                tenant {
                                    id
                                    logo
                                    logoUrl
                                    logoText
                                    themeJson
                                }
                            }
                        }
                    `,
                    variables: {
                        input: payload
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: "Save"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.log("error", error);
                })
                .finally(() => {
                    this.isSavingSystemProfile = false;
                    this.editMode = false;
                });
        },
    }
};
</script>
<style scoped>
.disabled {
    pointer-events: none;
    opacity: 0.4;
}
.buttonContent {
    height: 20px;
    width: 20px;
}

.borderDash {
    border-width: 1px;
    box-sizing: border-box;
    border-color: rgb(192, 192, 192);
    border-style: dashed;
}
</style>
