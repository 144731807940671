<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <!-- loading progress bar -->
            <LoadingBar v-if="$apollo.queries.tripLocal.loading"></LoadingBar>
            <NoData
                v-else-if="
                    !$apollo.queries.tripLocal.loading && !hasTripFreightSet
                "
            >
                <template v-slot:content>{{ $t("loading.no_found") }}</template>
            </NoData>
            <!-- air cargo events cards -->
            <v-card
                v-else
                v-for="freight in tripFreightCarrierEvents.tripFreight.edges"
                :key="freight.node.id"
                elevation="5"
                class="mb-5 pb-0"
                rounded="xl"
            >
                <v-card-title class="background2 py-3">
                    <!-- freight icon -->
                    <div class="mr-2">
                        <v-icon v-if="tripLocal.transportMode == 'AIR'"
                            >list_alt</v-icon
                        >

                        <v-img
                            v-else-if="tripLocal.transportMode == 'SEA'"
                            :src="require('@/assets/container.png')"
                            width="30"
                        ></v-img>

                        <v-icon v-else-if="tripLocal.transportMode == 'RAIL'"
                            >list_alt</v-icon
                        >

                        <v-icon v-else>list_alt</v-icon>
                    </div>

                    <!-- freight title -->
                    <span>{{ getFreightTitle(freight) }}</span>

                    <v-spacer></v-spacer>

                    <v-icon class="mx-3">{{ carrierIcon }}</v-icon>

                    <span class="mr-3">{{
                        getFreightCarrierName(freight)
                    }}</span>
                    <v-btn
                        icon
                        small
                        color="grey"
                        @click="goToCarrierWebsite(freight)"
                    >
                        <v-icon small>launch</v-icon>
                    </v-btn>
                </v-card-title>

                <!-- events -->
                <v-card-text class="py-0">
                    <!-- no data -->
                    <v-card-text v-if="!freight.node.carrierEvents">
                        <div class="text-caption text-center">
                            {{ $t("trips.no_carrier") }}
                        </div>
                    </v-card-text>
                    <v-card-text v-else>
                        <div class="px-8">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        small
                                        @click="sortDesc = !sortDesc"
                                        icon
                                        class="mr-3 primary elevation-2"
                                        outlined
                                        :disabled="!freight.node.carrierEvents"
                                    >
                                        <v-icon v-if="sortDesc" color="white"
                                            >arrow_downward</v-icon
                                        >
                                        <v-icon color="white" v-else
                                            >arrow_upward</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span
                                    >Sort
                                    {{
                                        sortDesc ? "ascending" : "descending"
                                    }}</span
                                >
                            </v-tooltip>
                        </div>

                        <v-timeline align-top dense>
                            <v-timeline-item
                                color="primary"
                                small
                                v-for="(event, index) in getEvents(
                                    freight.node.carrierEvents
                                )"
                                :key="index"
                            >
                                <v-row class="pt-1">
                                    <v-col cols="3">
                                        <strong>{{ event.event_date }}</strong>
                                        <div class="text-caption">
                                            {{ event.event_time }}
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <strong>{{
                                            formatEventTitle(event)
                                        }}</strong>
                                        <span v-if="event.event_pieces">
                                            ({{ event.event_pieces }}
                                            {{ $t("general.pieces") }} )
                                        </span>
                                        <div class="text-caption">
                                            {{ event.current_location }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>
                </v-card-text>
            </v-card>
        </div>
    </v-sheet>
</template>

<script>
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import NoPermission from "@/components/base/NoPermission.vue";
import helper from "@/utils/helper.js";
import { CarrierPermissions } from "@/core/enum";
import NoData from "@/components/base/NoData.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        freightProp: {
            type: Object,
            required: false
        }
    },
    components: { LoadingBar, NoPermission, NoData },
    apollo: {
        tripLocal: {
            query: gql`
                query tripLocal($tripId: ID!, $globalIdIn: [ID]) {
                    trip(id: $tripId) {
                        id
                        transportMode
                        tenantCarrier {
                            id
                            name
                            carrier {
                                id
                                name
                                website
                                link
                                code
                            }
                        }
                        tripFreightSet(globalIdIn: $globalIdIn) {
                            totalCount
                            edges {
                                node {
                                    id
                                    referenceNumber
                                    containerNumber
                                    airWaybillNumber
                                    billOfLadingNumber
                                    goods
                                    carrierEvents
                                    lastCarrierEventsSyncTime
                                    airlineLink
                                    airlineName
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    tripId: this.trip?.id ? this.trip?.id : this.getRouteId,
                    globalIdIn: this.freightProp?.id
                        ? [this.freightProp?.id]
                        : null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trip,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading) {
                    if (data.trip?.tripFreightSet) {
                        this.tripFreightCarrierEvents.tripFreight = {
                            ...data.trip?.tripFreightSet
                        };
                        this.hasTripFreightSet =
                            data.trip?.tripFreightSet?.edges.length > 0;
                    }
                }
            },
            // debounce: 800,
            skip() {
                return !this.canAccess;
            }
            // pollInterval: 60000 // ms
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
    },
    computed: {
        me() {
            return helper.me();
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        CarrierPermissions.VIEW_CARRIER,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        CarrierPermissions.VIEW_CARRIER,
                        this.organisationPermissions
                    )
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        CarrierPermissions.MANAGE_CARRIER,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (
                    !this.hasPermission(CarrierPermissions.VIEW_CARRIER) &&
                    this.me?.tenant
                )
                    return false;
            }
            return true;
        },
        carrierIcon() {
            if (this.tripLocal.transportMode == "AIR") return "flight";
            if (this.tripLocal.transportMode == "SEA") return "directions_boat";
            if (this.tripLocal.transportMode == "RAIL") return "train";
            if (this.tripLocal.transportMode == "ROAD") return "local_shipping";

            return "local_shipping";
        },
        getRouteId(){
            return this.$route.query.id;
        }
    },
    data() {
        return {
            freight: null,
            tripLocal: {},
            hasTripFreightSet: false,
            sortDesc: false,
            tripFreightCarrierEvents: {
                tripFreight: {
                    edges: []
                }
            },
            CarrierPermissions: CarrierPermissions
        };
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'trip_carrier_events_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("trip_carrier_events_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("trip_carrier_events_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        getFreightCarrierName(freight) {
            let _carrierName = null;

            // if air, use airline name
            if (this.tripLocal.transportMode == "AIR") {
                _carrierName = freight.node.airlineName;
            } else {
                try {
                    let _carrierEvents = JSON.parse(freight.node.carrierEvents);
                    _carrierName = _carrierEvents.carrier?.name;
                } catch (e) {
                    //console.log(e);
                }
            }
            return _carrierName || this.tripLocal.tenantCarrier?.carrier?.name;
        },
        getEvents(carrierEventsJson) {
            if (!carrierEventsJson) return [];

            let carrierEvents = {};
            try {
                carrierEvents = JSON.parse(carrierEventsJson);
            } catch (e) {
                console.log(e);
            }

            let _events = [];

            if (carrierEvents?.events) {
                _events = carrierEvents.events
                    .filter(event => event.event_date !== "N/A")
                    .sort((a, b) => {
                        return this.sortDesc
                            ? new Date(b.event_datetime) -
                                  new Date(a.event_datetime)
                            : new Date(a.event_datetime) -
                                  new Date(b.event_datetime);
                        // (
                        //     new Date(b.event_datetime) -
                        //     new Date(a.event_datetime)
                        // );
                    });
            }

            return _events;
        },
        getCarrier(carrierEventsJson) {
            if (!carrierEventsJson) return {};

            let carrierEvents = {};
            try {
                carrierEvents = JSON.parse(carrierEventsJson);
            } catch (e) {
                console.log(e);
            }

            return carrierEvents.carrier || {};
        },
        formatEventTitle(event) {
            let _title = event.description;

            if (
                event.vessel_name &&
                event.vessel_name != "LADEN" &&
                event.vessel_name != "EMPTY" &&
                event.vessel_name != "N/A"
            ) {
                _title = _title.concat(". Vessel: ", event.vessel_name);
            }

            if (event.vessel_number && event.vessel_number != "N/A") {
                _title = _title.concat(". Vessel #", event.vessel_number);
            }

            if (event.voyage_number && event.voyage_number != "N/A") {
                _title = _title.concat(". Voyage: ", event.voyage_number);
            }

            return _title;
        },
        goToCarrierWebsite(freight) {
            let _carrierWebsite = this.tripLocal.tenantCarrier?.carrier?.link;
            // if air, use airline name
            if (
                (_carrierWebsite === null || _carrierWebsite === undefined) &&
                this.tripLocal.transportMode.toLowerCase() == "air"
            ) {
                try {
                    _carrierWebsite = freight.node.airlineLink;
                } catch (e) {
                    //console.log(e);
                }
            }
            if (_carrierWebsite !== undefined) {
                window.open(_carrierWebsite, "_blank");
            } else {
                const payload = {
                    color: "blue",
                    message: this.$t("general.cant_open")
                };
                this.$store.dispatch("snackbar/showMessage", payload);
            }
        },
        getFreightTitle(freight) {
            let _title = ""; //freight.node.referenceNumber;
            let connect = ". ";
            if (freight.node.airWaybillNumber) {
                _title = _title.concat(
                    "Air Waybill #: ",
                    freight.node.airWaybillNumber,
                    connect
                );
            }
            if (freight.node.containerNumber) {
                _title = _title.concat(
                    "Container #: ",
                    freight.node.containerNumber,
                    connect
                );
            } else if (freight.node.billOfLadingNumber) {
                _title = _title.concat(
                    "Bill of Lading #: ",
                    freight.node.billOfLadingNumber,
                    connect
                );
            }
            _title = _title.concat("Ref: ", freight.node.referenceNumber);
            return _title;
        }
    }
};
</script>
