<template>
    <div v-if="!canAccess">
        <NoPermission></NoPermission>
    </div>
    <div v-else>
        <v-card flat>
            <v-card-text>
                <!-- ADD EXTERNAL EMAIL DIALOG -->
                <AddActionGroupExternalEmailDialog
                    :openAddExternalEmailDialog="openAddExternalEmailDialog"
                    :actionGroup="actionGroup"
                    :emails="emails"
                    v-on:refreshDataTable="$emit('refreshDataTable')"
                    v-on:closeAddExternalEmailDialog="
                        openAddExternalEmailDialog = false
                    "
                    v-on:updateExternalEmails="updateExternalEmails"
                ></AddActionGroupExternalEmailDialog>

                <DeleteActionGroupExternalEmailDialog
                    :openDeleteDialog="openDeleteDialog"
                    :actionGroup="actionGroup"
                    :emails="emails"
                    :emailToDelete="emailToDelete"
                    v-on:refreshDataTable="$emit('refreshDataTable')"
                    v-on:closeDeleteDialog="openDeleteDialog = false"
                    v-on:updateExternalEmails="updateExternalEmails"
                >
                    <template #title>{{ $t("general.delete_email") }}</template>
                    <template #content>{{ $t("general.sure_email") }}</template>
                </DeleteActionGroupExternalEmailDialog>
                <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
                    <v-card-text>
                        <v-row
                            class="d-flex align-center justify-end mt-1 mr-1"
                        >
                            <!-- ADD -->
                            <v-btn
                                small
                                outlined
                                rounded
                                color="primary"
                                class="mr-1"
                                @click="openAddExternalEmailDialog = true"
                            >
                                <v-icon left small>add_box</v-icon>
                                {{ $t("general.add_email") }}
                            </v-btn>
                        </v-row>
                        <!-- TABLE -->
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Email
                                        </th>
                                        <th class="text-right"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="emails.length == 0">
                                        <td class="text-center">
                                            {{ $t("general.no_email") }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-else
                                        v-for="email in emails"
                                        :key="email"
                                    >
                                        <td class="text-left">{{ email }}</td>
                                        <td class="text-right">
                                            <v-icon
                                                @click="removeEmail(email)"
                                                v-if="canEdit"
                                                >delete</v-icon
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import NoPermission from "@/components/base/NoPermission.vue";
import helper from "@/utils/helper.js";
import { TenantPermissions } from "@/core/enum";
import AddActionGroupExternalEmailDialog from "@/components/actiongroups/create/AddActionGroupExternalEmailDialog.vue";
import DeleteActionGroupExternalEmailDialog from "@/components/actiongroups/delete/DeleteActionGroupExternalEmailDialog.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        actionGroup: {
            type: Object,
            required: true
        }
    },
    components: {
        NoPermission,
        AddActionGroupExternalEmailDialog,
        DeleteActionGroupExternalEmailDialog
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
    },
    computed: {
        canAccess() {
            return this.hasPermission(
                TenantPermissions.VIEW_TENANT_ACTION_GROUP
            );
        },
        canEdit() {
            return this.hasPermission(
                TenantPermissions.EDIT_TENANT_ACTION_GROUP
            );
        }
    },
    data() {
        return {
            emails: [],
            rules: {
                invalidEmail: v => /.+@.+\..+/.test(v) || this.$t("rules.email")
            },
            openAddExternalEmailDialog: false,
            openDeleteDialog: false,
            emailToDelete: null
        };
    },
    mounted() {
        let _email = this.actionGroup.externalEmails
            ? this.actionGroup.externalEmails.replace(/'/g, '"')
            : [];
        if (_email.length == 0) this.emails = [];
        else {
            _email = JSON.parse(_email);
            this.emails = _email;
        }

                //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'action_group_external_emails_svw',
        });
        this.setUserProperties();
    },
    watch: {},
    methods: {
      setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("action_group_external_emails_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("action_group_external_emails_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        removeEmail(email) {
            this.emailToDelete = email;
            this.openDeleteDialog = true;
        },
        updateExternalEmails(emails) {
            this.emails = emails;
        }
    }
};
</script>
