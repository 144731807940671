<template>
    <v-container fluid class="pa-0">
        <apexchart
            ref="forecastChart"
            :options="options"
            :series="series"
            type="line"
            width="100%"
            height="100%"
        />
    </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import helper from "@/utils/helper.js";
import { eventBus } from "@/main.js";

export default {
    name: "WeatherTable",
    components: {
        apexchart: VueApexCharts
    },
    props: {
        edge: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            isLoadingTable: false,
            isLoadingEdit: false,
            openEditDialog: false,
            locationtoEdit: {},
            selectAction: null,
            locations: { edges: [] },
            locationsToShow: { edges: [] },
            isChartRendered: false,
            displayedEdgesIds: [],
            last_sync_time: null,
            actionChoices: [
                {
                    icon: "edit",
                    text: this.$t("general.edit")
                },
                {
                    icon: "delete",
                    text: this.$t("general.delete")
                }
            ]
        };
    },
    computed: {
        minX() {
            let min = null;
            let _edge =
                [
                    ...this.edge.node.tenantLocationWeatherForecastSet.edges
                ]?.sort((a, b) => {
                    return (
                        new Date(a.node.forecastDate) -
                        new Date(b.node.forecastDate)
                    );
                }) ?? [];

            if (_edge[0]?.node?.forecastDate) {
                //filter min date
                min = this.formatDateTime(_edge[0]?.node?.forecastDate);
                min = new Date(min).getTime();
            }
            return min;
        },
        maxX() {
            let max = null;
            let _edge =
                [
                    ...this.edge.node.tenantLocationWeatherForecastSet.edges
                ]?.sort((a, b) => {
                    return (
                        new Date(a.node.forecastDate) -
                        new Date(b.node.forecastDate)
                    );
                }) ?? [];

            if (_edge?.length > 0) {
                //filter max date
                max = this.formatDateTime(
                    _edge[_edge.length - 1]?.node?.forecastDate
                );
                max = new Date(max).getTime();
            }

            return max;
        },
        series() {
            let maxTemperatureLimit = this.edge.node.maxTemperature;
            let minTemperatureLimit = this.edge.node.minTemperature;

            let chartSeries = [
                {
                    name: this.$t("weather.current_max"),
                    data: this.edge.node.tenantLocationWeatherForecastSet.edges.map(({node}) =>{
                        const maxTemperature = node?.maxTemperature;
                        let date = this.formatDateTime(node.forecastDate);
                        date = new Date(date).getTime();
                        return [date, parseFloat(this.formatTemperature(maxTemperature) ?? null).toFixed(2)]
                    })
                },
                {
                    name: this.$t("weather.current_min"),
                    data: this.edge.node.tenantLocationWeatherForecastSet.edges.map(({node}) =>{
                        const minTemperature = node?.minTemperature;
                        let date = this.formatDateTime(node.forecastDate);
                        date = new Date(date).getTime();

                        return [date, parseFloat(this.formatTemperature(minTemperature) ?? null).toFixed(2)]
                    })
                },
                {
                    name: this.$t("weather.limit_max"),
                    data: this.edge.node.tenantLocationWeatherForecastSet.edges.map(({node}) =>{
                        const maxTemperature = maxTemperatureLimit;
                        let date = this.formatDateTime(node.forecastDate);
                        date = new Date(date).getTime();
                        return [date, parseFloat(this.formatTemperature(maxTemperature) ?? null).toFixed(2)]
                    })
                },
                {
                    name: this.$t("weather.limit_min"),
                    data: this.edge.node.tenantLocationWeatherForecastSet.edges.map(({node}) =>{
                        const minTemperature = minTemperatureLimit;
                        let date = this.formatDateTime(node.forecastDate);
                        date = new Date(date).getTime();
                        return [date, parseFloat(this.formatTemperature(minTemperature) ?? null).toFixed(2)]
                    })
                   
                },
            ];
            return chartSeries;
        },
        options() {
            let _options = {
                chart: {
                    id: "forecast-chart-",
                    type: "line",

                    toolbar: {
                        show: false
                    }
                },
                tooltip: {
                    x: {
                        format: "dd/MM/yyyy"
                    }
                },
                colors: ["#FC6152", "#5C7FFF", "#F94332", "#3963FF"],
                dataLabels: {
                    enabled: false,
                    position: "top",
                    formatter: function(val, opts) {
                        if (opts.seriesIndex === 0 || opts.seriesIndex === 1) {
                            return val;
                        }
                        return "";
                    }
                },
                xaxis: {
                    type: "datetime",
                    min: this.minX,
                    max: this.maxX
                },
                stroke: {
                    width: [3, 3, 2, 2],
                    curve: "smooth",
                    dashArray: [0, 0, 7, 7]
                },
                grid: {
                    show: true,
                    borderColor: "#F3F3F3",
                    strokeDashArray: 5,
                    position: "back",
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
                legend: {
                    show: false,
                    showForNullSeries: false,
                    showForZeroSeries: false,
                    position: "top",
                    horizontalAlign: "left",
                    fontSize: "14px",
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: 400,
                    labels: {
                        colors: "#BDBDBD"
                    }
                }
            };
            return _options;
        },
        formValid() {
            let _max = parseFloat(this.locationtoEdit.maxTemperature);
            let _min = parseFloat(this.locationtoEdit.minTemperature);
            return (
                _max == null ||
                _min == null ||
                _max == "" ||
                _min == "" ||
                _max >= _min
            );
        },
        hasData() {
            return this.locationsShowed.edges.length > 0;
        }
    },
    mounted() {},
    created() {
        this.formatTemperature = helper.formatTemperature;
        this.getDateFormat = helper.getDateFormat;
        this.formatDateTime = helper.formatDateTime;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        eventBus.$on("refetchData", this.refetchData);
    },
    methods: {
        handleClick(item, edge) {
            switch (item.text) {
                case this.$t("general.edit"):
                    this.locationtoEdit = JSON.parse(JSON.stringify(edge.node));
                    this.openEditDialog = true;
                    break;
                case this.$t("general.delete"):
                    this.deleteLocation(edge.node);
                    break;
            }
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
