<template>
    <div class="px-10 pt-4">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4 mb-4" flat>
            <v-row align="start" class="d-flex align-center px-3">
                <!---- SEARCH BAR ---->
                <v-col cols="12" lg="12" sm="12" class="py-2">
                    <SearchBar v-model="searchInput" :label="$t('general.search')"></SearchBar>
                </v-col>
            </v-row>
        </v-card>
        <v-card flat class="px-3 rounded-lg mb-2" outlined>
            <v-card-title>
                <div>
                    <v-icon class="mr-2">manage_history</v-icon>
                    {{ $t("headers.login_history") }}
                </div>
            </v-card-title>
            <v-card-text>
                <div class="px-10 pt-4" v-if="loading && !hasData">
                    <LoadingBar></LoadingBar>
                </div>
                <div v-else-if="hasData">
                    <v-card
                        v-for="item in authLogs.edges"
                        :key="item.node.id"
                        class="mb-4 rounded-lg px-3 backgroundContent"
                        outlined
                        flat
                    >
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" lg="4" sm="12" class="py-2">
                                    <div
                                        class="textHeader
                                        text-truncate"
                                    >
                                        {{ $t("headers.action") }}
                                    </div>
                                    <div class="textBody text-truncate">
                                        {{ item.node.action }}
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="4" sm="12" class="py-2">
                                    <div
                                        class="textHeader
                                        text-truncate"
                                    >
                                        {{ $t("headers.time") }}
                                    </div>
                                    <div class="textBody text-truncate">
                                        {{ formatDateTime(item.node.actionTime) }}
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="4" sm="12" class="py-2">
                                    <div
                                        class="textHeader
                                        text-truncate"
                                    >
                                        {{ $t("headers.ip") }}
                                    </div>
                                    <div class="textBody text-truncate">
                                        {{ item.node.actionIp }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
                <div v-else-if="!loading && !hasData">
                    No Data
                </div>
            </v-card-text>
            <!-- infinit loading trigger -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.authLogs.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    {{ $t("loading.load_more") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import Config from "@/utils/config.json";
import SearchBar from "@/components/base/SearchBar.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";

export default {
    name: "UserGroupsTab",
    components: { SearchBar, LoadingBar },
    props: {
        user: {
            type: Object,
            required: true
        },
        isStaff: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        authLogs: {
            query: gql`
                query AuthLogs(
                    $userId: ID
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $search: String
                ) {
                    authLogs(
                        userId: $userId
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        search: $search
                    ) {
                        totalCount
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                action
                                actionIp
                                actionTime
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    userId: this.isStaff ? this.user.id : this.user.node.user.id,
                    ...this.authLogsFilter,
                    search: this.searchInput
                };
            },
            watchLoading(isLoading) {
                this.loading = isLoading;
                this.$emit("loading", isLoading);
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.authLogs;
            },
            skip: false
        }
    },
    data() {
        return {
            searchInput: null,
            loading: false,
            authLogs: { edges: [] },
            authLogsFilter: {
                first: 10
            },
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
            headerChoices: [
                {
                    text: this.$t("headers.action"),
                    align: "left",
                    value: "node.action"
                },
                {
                    text: this.$t("headers.time"),
                    align: "left",
                    value: "node.actionTime"
                },
                {
                    text: this.$t("headers.ip"),
                    align: "left",
                    value: "node.actionIp"
                }
            ],
            shouldLoadMore: false
        };
    },
    watch: {
        searchInput() {
            this.$apollo.userGroups ? this.$apollo.userGroups.refetch() : null;
        }
    },
    computed: {
        // infinite
        hasMoreData() {
            return this.authLogs?.pageInfo?.hasNextPage;
        },
        hasData() {
            return this.authLogs?.edges?.length > 0;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "user_login_history_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("user_login_history_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("user_login_history_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.authLogs?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.authLogs.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.authLogs?.pageInfo?.hasNextPage) {
                this.$apollo.queries.authLogs.fetchMore({
                    variables: {
                        after: this.authLogs.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
<style scoped>
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
.outline {
    border: 1px solid #ebebf5;
    box-sizing: border-box;
    border-radius: 4px;
}
.backgroundContent {
    background: #f9fafe;
}
</style>
