<template>
    <v-container fluid class="pa-0">
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col>
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                            align-top
                        "
                    >
                        {{ $t("home.carriers") }}
                    </h2>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    color="primary"
                    class="mr-1 mt-2"
                    @click="openAddPartnerDialog = true"
                >
                    
                    {{ $t("carrier.add_carrier") }}
                </v-btn>
            </v-row>
        </div>

        <!-- FILTER -->
        <CarrierFilter
            v-on:filter-changed="onFilterChanged"
            v-on:carrier-created="onPartnerCreated"
            class="mx-3"
        ></CarrierFilter>
        <!-- LIST -->
        <CarrierTable
            ref="carrierTable"
            v-on:click:row="onPartnerSelected"
            :carrierFilter="carrierFilter"
            class="mx-3 mt-3 mb-3"
        ></CarrierTable>

        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="partnerDetailDrawer"
            :width="partnerDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <PartnerDetail
                    ref="partnerDetails"
                    v-if="selectedPartner"
                    :key="selectedPartner.id"
                    :partner="selectedPartner"
                    :isFullScreen="isPartnerDetailDrawerFullScreen"
                    v-on:changeFullScreen="
                        onPartnerDetailDrawerFullScreenChange
                    "
                    v-on:refreshDataTable="refetchTableData"
                    v-on:close="partnerDetailDrawer = false"
                ></PartnerDetail>
            </div>
        </v-navigation-drawer>
        <AddPartnerDialog
            :openAddPartnerDialog="openAddPartnerDialog"
            :partnerByType.sync="partnerType"
            v-on:refreshPartnersTable="$emit('refreshDataTable')"
            v-on:closeAddPartnerDialog="
                openAddPartnerDialog = false
            "
            v-on:item-created="onPartnerCreated"
        ></AddPartnerDialog>
    </v-container>
</template>

<script>
//import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import CarrierFilter from "@/components/carriers/CarrierFilter.vue";
import CarrierTable from "@/components/carriers/CarrierTable.vue";
import PartnerDetail from "@/components/partners/PartnerDetail.vue";
import AddPartnerDialog from "@/components/partners/create/AddPartnerDialog.vue";
import { eventBus } from "@/main.js";

export default {
    title: "Carriers",
    components: {
        CarrierFilter,
        CarrierTable,
        PartnerDetail, 
        AddPartnerDialog
    },

    apollo: {},
    data() {
        return {
            // --------- PARTNERS DATA --------- \\
            partners: [],
            selectedPartner: null,
            carrierFilter: {
                first: 15,
                carrierType: null,
                partnerType: "carrier"
            },
            // --------- DIALOGS/LOADINGS/MENUS --------- \\
            partnerDetailDrawer: false,
            partnerType: "carrier",
            openAddPartnerDialog: false,
            isLoading: false,
            menu: false,
            goTopShow: false,
            partnerDetailFullScreen: false
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        partnerDetailDrawerWidth() {
            if (this.partnerDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isPartnerDetailDrawerFullScreen() {
            return this.partnerDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },

    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {},
    methods: {
        onLoading(val) {
            this.isLoading = val;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        },
        // -----> DATA RELOAD OR RESET <----- \\
        refetchTableData() {
            this.$refs.carrierTable.$apollo.queries.tenantPartners.refetch();
        },
        onFilterChanged(filter) {
            this.carrierFilter = filter;
        },
        // -----> EVENT HANDLERS (clicks, onchange, scroll, etc) <------ \\
        onPartnerSelected(partner) {
            this.selectedPartner = partner.node;
            this.partnerDetailDrawer = true;
        },
        onScroll(e) {
            // scroll to top of the page
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        onPartnerDetailDrawerFullScreenChange(val) {
            this.partnerDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onPartnerCreated(val) {
            this.selectedPartner = val.tenantPartner;
            this.partnerDetailDrawer = true;
            this.refetchTableData();
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
</style>
