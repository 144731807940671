import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c(VCard,{staticClass:"py-4 mt-4 mb-4",attrs:{"flat":""}},[_c(VRow,{staticClass:"d-flex align-center background px-4",attrs:{"align":"start"}},[_c(VCol,{staticClass:"py-2",attrs:{"cols":"12","lg":"12","sm":"12"}},[_c('SearchBar',{attrs:{"label":this.$t('general.search') + ' ' + this.$t('headers.trips')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c(VCard,{staticClass:"px-4 rounded-lg mb-2",attrs:{"flat":"","outlined":""}},[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"cols":"auto"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c('div',[_c(VImg,{attrs:{"src":require('@/assets/local_shipping.png'),"width":"26"}})],1)]),_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("headers.trips"))+" ")])],1)],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_vm._t("sortBy")],2)],1)],1),_c(VCardText,[(_vm.computeTrips.lenght === 0)?_c(VCard,[_vm._v(" No trips found ")]):_vm._e(),_vm._l((_vm.computeTrips),function(trip,i){return _c(VCard,{key:i,staticClass:"mb-4 rounded-lg px-3 backgroundContent",attrs:{"outlined":"","flat":""},on:{"click":function($event){return _vm.redirectTotrip(trip.id)}}},[_c(VCardText,[_c(VRow,_vm._l((_vm.cardBody(trip)),function(item){return _c(VCol,{key:item.text,staticClass:"textLeft",attrs:{"cols":"12","xs":"12","sm":"6","md":"4","lg":"","xl":""}},[_c('div',[_c('div',{staticClass:"textHeader text-truncate"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"textBody text-truncate d-flex align-start",style:(item.style)},[(item.icon)?_c(VIcon,{staticClass:"mr-1",attrs:{"size":"8","color":item.colorIcon}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_c('span',{staticClass:"col-auto pa-0"},[_vm._v(" "+_vm._s(item.value)+" ")])],1)])])}),1)],1)],1)})],2),_c(VCardActions,{staticClass:"justify-center"},[_vm._t("loadMore")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }