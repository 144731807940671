<template>
    <v-dialog v-model="openDeleteDialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="title">
                <v-icon color="error" class="mr-2">warning</v-icon>
                <slot name="title"></slot>
            </v-card-title>
            <v-card-text>
                <slot name="content"> </slot>
            </v-card-text>
            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn text rounded @click="$emit('closeDeleteDialog')">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="error"
                    outlined
                    rounded
                    :loading="isDeleting"
                    @click="deleteExternalEmail"
                    >{{ $t("general.delete") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "delete-external-email-dialog",
    props: {
        openDeleteDialog: {
            type: Boolean,
            required: true
        },
        actionGroup: {
            type: Object,
            required: true
        },
        emails: {
            type: Array,
            required: true
        },
        emailToDelete: {
            type: String
        }
    },
    data: function() {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteExternalEmail() {
            this.isDeleting = true;
            let _emails = this.emails.filter(email => {
                return email != this.emailToDelete;
            });
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTenantActionGroupExternalEmails(
                            $input: UpdateTenantActionGroupExternalEmailsInput!
                        ) {
                            updateTenantActionGroupExternalEmails(
                                input: $input
                            ) {
                                tenantActionGroup {
                                    id
                                    externalEmails
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.actionGroup.id,
                            externalEmails: _emails
                        }
                    }
                })
                .then(data => {
                    let _email = data.data.updateTenantActionGroupExternalEmails
                        .tenantActionGroup.externalEmails
                        ? data.data.updateTenantActionGroupExternalEmails.tenantActionGroup.externalEmails.replace(
                              /'/g,
                              '"'
                          )
                        : [];
                    if (_email.length == 0) this.emails = [];
                    else {
                        _email = JSON.parse(_email);
                    }
                    this.$emit("updateExternalEmails", _email);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isDeleting = false;
                    this.$emit("refreshDataTable");
                    this.$emit("closeDeleteDialog");
                });
        }
    }
};
</script>
