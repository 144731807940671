<template>
    <v-dialog max-width="600" v-model="openDeleteAlertRuleDialog" persistent>
        <v-card>
            <v-card-title class="title">
                <v-icon color="error" class="mr-2">warning</v-icon>
                <slot name="title">Delete</slot>
            </v-card-title>
            <v-card-text>
                <slot name="content">Conteudo </slot>
            </v-card-text>
            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn text rounded @click="$emit('closeDeleteDialog')">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="error"
                    outlined
                    rounded
                    :loading="isDeleting"
                    @click="deleteAlertRule"
                    >{{ $t("general.delete") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";

export default {
    name: "DeleteGroup",
    props: ["openDeleteAlertRuleDialog", "alertRuleId"],
    data: function() {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteAlertRule() {
            this.isDeleting = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation DeleteTenantAlertRule(
                            $input: DeleteTenantAlertRuleInput!
                        ) {
                            deleteTenantAlertRule(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.alertRuleId
                        }
                    }
                })
                .then(() => {
                    this.$emit("refreshDataTable");
                    this.$emit("closeDeleteDialog");
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isDeleting = false;
                });
        }
    }
};
</script>
