<template>
    <v-row v-if="editor && items" dense>
        <v-col v-for="(item, index) in items" :key="index" cols="auto">
            <MenuItem :icon="item.icon" :title="item.title" :action="item.action" :isActive="item.isActive"> </MenuItem>
        </v-col>
    </v-row>
</template>
<script>
import MenuItem from "./TipTapMenuItem.vue";
import {
    mdiFormatBold,
    mdiFormatItalic,
    mdiFormatStrikethrough,
    mdiCodeTags,
    mdiFormatColorText,
    mdiFormatHeader1,
    mdiFormatHeader2,
    mdiFormatParagraph,
    mdiFormatListBulleted,
    mdiFormatListNumbered,
    mdiFormatListCheckbox,
    mdiCodeTagsCheck,
    mdiFormatQuoteOpen,
    mdiMinus,
    mdiWrap,
    mdiFormatClear,
    mdiArrowULeftTop,
    mdiArrowURightTop
} from "@mdi/js";

export default {
    components: {
        MenuItem
    },

    props: {
        editor: {
            type: Object,
            required: true
        },
        icons: {
            type: Object,
            default: () => ({
                bold: mdiFormatBold,
                italic: mdiFormatItalic,
                strike: mdiFormatStrikethrough,
                code: mdiCodeTags,
                highlight: mdiFormatColorText,
                heading: mdiFormatHeader1,
                heading2: mdiFormatHeader2,
                paragraph: mdiFormatParagraph,
                bulletList: mdiFormatListBulleted,
                orderedList: mdiFormatListNumbered,
                taskList: mdiFormatListCheckbox,
                codeBlock: mdiCodeTagsCheck,
                blockquote: mdiFormatQuoteOpen,
                horizontalRule: mdiMinus,
                hardBreak: mdiWrap,
                clearFormat: mdiFormatClear,
                undo: mdiArrowULeftTop,
                redo: mdiArrowURightTop
            })
        }
    },

    data() {
        return {
            items: null,
            toggleBtn: []
        };
    },
    computed: {
        isActive() {
            return this.editor.isActive;
        }
    },
    mounted() {
        this.items = [
            {
                icon: this.icons.bold,
                title: "Bold",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleBold()
                        .run(),
                isActive: () => this.editor.isActive("bold")
            },
            {
                icon: this.icons.italic,
                title: "Italic",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleItalic()
                        .run(),
                isActive: () => this.editor.isActive("italic")
            },
            {
                icon: this.icons.strike,
                title: "Strike",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleStrike()
                        .run(),
                isActive: () => this.editor.isActive("strike")
            },
            {
                icon: this.icons.code,
                title: "Code",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleCode()
                        .run(),
                isActive: () => this.editor.isActive("code")
            },
            {
                icon: this.icons.highlight,
                title: "Highlight",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleHighlight()
                        .run(),
                isActive: () => this.editor.isActive("highlight")
            },

            {
                icon: this.icons.heading,
                title: "Heading 1",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleHeading({ level: 1 })
                        .run(),
                isActive: () => this.editor.isActive("heading", { level: 1 })
            },
            {
                icon: this.icons.heading2,
                title: "Heading 2",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleHeading({ level: 2 })
                        .run(),
                isActive: () => this.editor.isActive("heading", { level: 2 })
            },
            {
                icon: this.icons.paragraph,
                title: "Paragraph",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .setParagraph()
                        .run(),
                isActive: () => this.editor.isActive("paragraph")
            },
            {
                icon: this.icons.bulletList,
                title: "Bullet List",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleBulletList()
                        .run(),
                isActive: () => this.editor.isActive("bulletList")
            },
            {
                icon: this.icons.orderedList,
                title: "Ordered List",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleOrderedList()
                        .run(),
                isActive: () => this.editor.isActive("orderedList")
            },
            {
                icon: this.icons.taskList,
                title: "Task List",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleTaskList()
                        .run(),
                isActive: () => this.editor.isActive("taskList")
            },
            {
                icon: this.icons.codeBlock,
                title: "Code Block",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleCodeBlock()
                        .run(),
                isActive: () => this.editor.isActive("codeBlock")
            },
            {
                icon: this.icons.blockquote,
                title: "Blockquote",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .toggleBlockquote()
                        .run(),
                isActive: () => this.editor.isActive("blockquote")
            },
            {
                icon: this.icons.horizontalRule,
                title: "Horizontal Rule",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .setHorizontalRule()
                        .run()
            },
            {
                icon: this.icons.hardBreak,
                title: "Hard Break",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .setHardBreak()
                        .run()
            },
            {
                icon: this.icons.clearFormat,
                title: "Clear Format",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .clearNodes()
                        .unsetAllMarks()
                        .run()
            },
            {
                icon: this.icons.undo,
                title: "Undo",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .undo()
                        .run()
            },
            {
                icon: this.icons.redo,
                title: "Redo",
                action: () =>
                    this.editor
                        .chain()
                        .focus()
                        .redo()
                        .run()
            }
        ];
    }
};
</script>
<style lang="scss">
.divider {
    background-color: rgba(#fff, 0.25);
    height: 1.25rem;
    margin-left: 0.5rem;
    margin-right: 0.75rem;
    width: 1px;
}
.contentBtn {
    height: 25px;
    width: 25px;
}
</style>
