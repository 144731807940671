<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="12" sm="9" lg="10">
                        <v-row>
                            <v-col
                                cols="auto"
                                v-for="f in dynamicFilters"
                                :key="f"
                                :class="getFilterChoiceClassByCode(f)"
                            >
                                <!--!Trip Reference Number -->
                                <v-text-field
                                    v-if="f === 'referenceNumber'"
                                    v-model="filter.referenceNumber_Icontains"
                                    :label="getFilterChoiceNameByCode('referenceNumber')"
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--!Freights Reference Number -->
                                <v-text-field
                                    v-if="f === 'freights'"
                                    v-model="filter.tripFreightSet_ReferenceNumber_Icontains"
                                    :label="getFilterChoiceNameByCode('freights')"
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!-- Last Location-->
                                <v-text-field
                                    v-if="f === 'trackerLastLocation'"
                                    v-model="filter.trackerLastLocation_Icontains"
                                    :label="getFilterChoiceNameByCode('trackerLastLocation')"
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--!Trip Trackers -->
                                <v-text-field
                                    v-if="f === 'tracker'"
                                    v-model="filter.tripFreightTrackerSet_Tracker_SerialNumber_Icontains"
                                    :label="getFilterChoiceNameByCode('tracker')"
                                    clearable
                                    class="maxWidth"
                                    hide-details
                                    dense
                                ></v-text-field>

                                <!--!Trip Container Number -->
                                <v-text-field
                                    v-if="f === 'containerNumber'"
                                    v-model="filter.tripFreightSet_ContainerNumber_Icontains"
                                    :label="getFilterChoiceNameByCode('containerNumber')"
                                    clearable
                                    hide-details
                                    class="maxWidth"
                                    dense
                                ></v-text-field>

                                <!-- !Trip Air Waybill Number -->
                                <v-text-field
                                    v-if="f === 'airWaybillNumber'"
                                    v-model="filter.tripFreightSet_AirWaybillNumber_Icontains"
                                    :label="getFilterChoiceNameByCode('airWaybillNumber')"
                                    clearable
                                    class="maxWidth"
                                    hide-details
                                    dense
                                ></v-text-field>

                                <!-- ! POL Search -->
                                <PortFilter
                                    v-if="f === 'polSearch'"
                                    v-model="filter.pol"
                                    :label="getFilterChoiceNameByCode('polSearch')"
                                    :refreshTable="refreshTablePorts"
                                >
                                </PortFilter>

                                <!-- ! Transsshipment Search -->
                                <PortFilter
                                    v-if="f === 'transshipmentSearch'"
                                    v-model="filter.transshipment"
                                    :label="getFilterChoiceNameByCode('transshipmentSearch')"
                                    :refreshTable="refreshTablePorts"
                                >
                                </PortFilter>

                                <!-- ! pod search  -->
                                <PortFilter
                                    v-if="f === 'podsearch'"
                                    v-model="filter.pod"
                                    :label="getFilterChoiceNameByCode('podsearch')"
                                    :refreshTable="refreshTablePorts"
                                >
                                </PortFilter>
                                <!-- !Trip Status -->
                                <v-select
                                    v-if="f === 'status'"
                                    v-model="filter.status_In"
                                    :items="statusChoices"
                                    :label="getFilterChoiceNameByCode('status')"
                                    item-text="text"
                                    item-value="value"
                                    hide-details
                                    dense
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 2,
                                        offsetY: true
                                    }"
                                ></v-select>

                                <!-- !Partner Name -->
                                <!-- <PartnerAutoComplete
                                    v-if="f === 'tenantPartnerId'"
                                    v-model="filter.tenantPartnerId"
                                    :label="
                                        getFilterChoiceNameByCode(
                                            'tenantPartnerId'
                                        )
                                    "
                                ></PartnerAutoComplete> -->

                                <!-- !Relative Planned Departure Date -->
                                <v-select
                                    v-if="f === 'relativePlannedDepartureDateRange'"
                                    v-model="relativePlannedDepartureDateRange"
                                    :items="relativePlannedDepartureDateRangeChoices"
                                    :label="getFilterChoiceNameByCode('relativePlannedDepartureDateRange')"
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 2,
                                        offsetY: true
                                    }"
                                ></v-select>

                                <!-- !Consignor -->
                                <PartnerTripsConsignor
                                    v-if="f === 'consignor'"
                                    ref="consignor"
                                    :refreshTable="refreshTableConsignor"
                                    v-model="filter.consignor"
                                    :label="getFilterChoiceNameByCode('consignor')"
                                ></PartnerTripsConsignor>

                                <!-- !Consignee -->
                                <PartnerTripsConsignee
                                    v-if="f === 'consignee'"
                                    ref="consignee"
                                    :refreshTable="refreshTableConsignee"
                                    v-model="filter.consignee"
                                    :label="getFilterChoiceNameByCode('consignee')"
                                ></PartnerTripsConsignee>

                                <!-- !Forwarder -->
                                <PartnerTripsForwarder
                                    v-if="f === 'forwarder'"
                                    ref="forwarder"
                                    :refreshTable="refreshTableForwarder"
                                    v-model="filter.forwarder"
                                    :label="getFilterChoiceNameByCode('forwarder')"
                                ></PartnerTripsForwarder>

                                <!-- !Carrier -->
                                <PartnerTripsCarrier
                                    v-if="f === 'carrier'"
                                    ref="carrier"
                                    :refreshTable="refreshTableCarrier"
                                    v-model="filter.tenantCarrier"
                                    :label="getFilterChoiceNameByCode('carrier')"
                                ></PartnerTripsCarrier>

                                <VesselFilter
                                    v-if="f === 'polvessel'"
                                    v-model="filter.polvessel"
                                    :refreshTable="refreshTableVessel"
                                    :label="getFilterChoiceNameByCode('polvessel')"
                                >
                                </VesselFilter>

                                <VesselFilter
                                    v-if="f === 'tsvessel'"
                                    v-model="filter.tsvessel"
                                    :refreshTable="refreshTableVessel"
                                    :label="getFilterChoiceNameByCode('tsvessel')"
                                >
                                </VesselFilter>

                                <!-- <v-select
                                    v-if="f === 'carrier'"
                                    v-model="filter.tenantCarrier"
                                    :items="transportModeChoices"
                                    :label="
                                        getFilterChoiceNameByCode('carrier')
                                    "
                                    chips
                                    small-chips
                                    clearable
                                    multiple
                                    hide-details
                                    dense
                                    color="primary"
                                    class="maxWidth"
                                ></v-select> -->

                                <!-- Transport Mode -->
                                <v-select
                                    v-if="f === 'transportMode'"
                                    v-model="filter.transportMode_In"
                                    :items="transportModeChoices"
                                    :label="getFilterChoiceNameByCode('transportMode')"
                                    chips
                                    small-chips
                                    clearable
                                    multiple
                                    hide-details
                                    dense
                                    color="primary"
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 2,
                                        offsetY: true
                                    }"
                                ></v-select>

                                <!-- !Alert Code -->
                                <v-select
                                    v-if="f === 'alertCode'"
                                    v-model="filter.alertCode"
                                    :items="alertsType"
                                    :label="getFilterChoiceNameByCode('alertCode')"
                                    item-text="name"
                                    item-value="value"
                                    hide-details
                                    dense
                                    clearable
                                    color="primary"
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 2,
                                        offsetY: true
                                    }"
                                >
                                </v-select>

                                <!-- !Alert Tag -->
                                <v-autocomplete
                                    v-if="f === 'alertTag'"
                                    v-model="filter.alertTag"
                                    :items="alertTags"
                                    :label="getFilterChoiceNameByCode('alertTag')"
                                    item-text="text"
                                    item-value="value"
                                    hide-details
                                    dense
                                    clearable
                                    color="primary"
                                    class="maxWidth"
                                    :menu-props="{
                                        zIndex: 2,
                                        offsetY: true
                                    }"
                                ></v-autocomplete>

                                <!-- !Trip KPI -->
                                <v-select
                                    v-if="f === 'kpi'"
                                    :label="getFilterChoiceNameByCode('kpi')"
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                >
                                </v-select>

                                <!-- !Tenant -->
                                <v-text-field
                                    v-if="f === 'sharedBy' && isSharedTrip"
                                    v-model="filter.tenant"
                                    :label="getFilterChoiceNameByCode('sharedBy')"
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--!Trip Reference Number -->
                                <v-text-field
                                    v-if="f === 'goods'"
                                    v-model="filter.goods"
                                    :label="getFilterChoiceNameByCode('goods')"
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!--!Trade -->
                                <v-text-field
                                    v-if="f === 'trade'"
                                    v-model="filter.trade_Icontains"
                                    :label="getFilterChoiceNameByCode('trade')"
                                    clearable
                                    hide-details
                                    dense
                                    class="maxWidth"
                                ></v-text-field>

                                <!-- !Planned Departure Date  -->
                                <v-menu
                                    v-if="f === 'plannedDepartureDate'"
                                    ref="plannedDepartureDateMenu"
                                    v-model="plannedDepartureDateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="savedPlannedDepartureDateRange"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="auto"
                                    min-width="auto"
                                    :disabled="!!relativePlannedDepartureDateRange"
                                    z-index="2"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="plannedDepartureDateRangeText"
                                            :label="getFilterChoiceNameByCode('plannedDepartureDate')"
                                            readonly
                                            clearable
                                            hide-details
                                            dense
                                            @click:clear="onClearPlannedDepartureDates()"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!!relativePlannedDepartureDateRange"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="plannedDepartureDateRange"
                                        :first-day-of-week="1"
                                        range
                                        no-title
                                        scrollable
                                        color="primary"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text rounded color="primary" @click="cancelPlannedDepartureDatePicker">
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            outlined
                                            rounded
                                            color="primary"
                                            :disabled="plannedDepartureDateRange.length < 2"
                                            @click="
                                                $refs.plannedDepartureDateMenu[0].save(plannedDepartureDateRange.sort())
                                            "
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>

                                <!-- !Planned Arrival Date  -->
                                <v-menu
                                    v-if="f === 'plannedArrivalDate'"
                                    ref="plannedArrivalDateMenu"
                                    v-model="plannedArrivalDateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="savedPlannedArrivalDateRange"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="auto"
                                    min-width="auto"
                                    :disabled="!!relativePlannedArrivalDateRange"
                                    z-index="2"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="plannedArrivalDateRangeText"
                                            :label="getFilterChoiceNameByCode('plannedArrivalDate')"
                                            readonly
                                            clearable
                                            hide-details
                                            dense
                                            @click:clear="onClearPlannedArrivalDates()"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!!relativePlannedArrivalDateRange"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="plannedArrivalDateRange"
                                        :first-day-of-week="1"
                                        range
                                        no-title
                                        scrollable
                                        color="primary"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text rounded color="primary" @click="cancelPlannedArrivalDatePicker">
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            outlined
                                            rounded
                                            color="primary"
                                            :disabled="plannedArrivalDateRange.length < 2"
                                            @click="
                                                $refs.plannedArrivalDateMenu[0].save(plannedArrivalDateRange.sort())
                                            "
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- !Sort By -->
                            <v-col class="col-auto col-md-4 col-lg-3 col-xl-3">
                                <v-row class="d-flex align-center ma-0 pa-0">
                                    <v-select
                                        v-model="sortBy"
                                        :items="sortByChoices"
                                        :label="$t('general.sort_by')"
                                        :item-value="item => item.code"
                                        :item-text="item => item.name"
                                        clearable
                                        hide-details
                                        dense
                                        color="primary"
                                        :menu-props="{
                                            zIndex: 2,
                                            offsetY: true
                                        }"
                                    >
                                        <template v-slot:append-outer>
                                            <v-btn
                                                class="mt-n1"
                                                small
                                                icon
                                                :disabled="!sortBy"
                                                @click="sortAsc = !sortAsc"
                                            >
                                                <v-icon color="grey darken-2">{{ sortAsc ? "north" : "south" }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </v-row>
                            </v-col>

                            <!-- !Favorite Trip -->
                            <v-col class="col-auto col-md-4 col-lg-3 col-xl-3 d-flex ">
                                <div class="d-flex align-center mr-4">
                                    <v-switch
                                        v-model="userFavoriteTrip"
                                        :label="$t('general.favorites')"
                                        class="text-no-wrap"
                                        color="primary"
                                        style="margin-top: 0%"
                                    ></v-switch>
                                </div>
                                <div class="d-flex align-center mr-4">
                                    <v-switch
                                        v-model="unresolvedAlerts"
                                        :label="$t('general.alerts')"
                                        color="primary"
                                        class="text-no-wrap"
                                        style="margin-top: 0%"
                                    ></v-switch>
                                </div>
                                <div>
                                    <v-btn rounded small color="" elevation-2 @click="clearFilter">
                                        <v-img :src="require('@/assets/filter_clear.png')" width="20"></v-img>

                                        <span>{{ $t("general.clear") }}</span>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- !Filters Menu -->
                    <v-col cols="12" sm="3" lg="2" class="py-2 pr-4 text-right">
                        <v-row class="d-flex align-center justify-end">
                            <!-- !Filters Menu -->
                            <FilterMenu
                                :title="$t('dashboard.customize')"
                                :description="$t('dashboard.display_filters')"
                                :choices="filterChoices"
                                :open-on-hover="false"
                                height="500px"
                                width="250px"
                                v-on:changed="onFilterMenuChanged"
                            >
                            </FilterMenu>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import FilterMenu from "@/components/base/FilterMenu.vue";
import AddTrip from "@/components/trips/create/AddTrip.vue";
// import PartnerAutoComplete from "@/components/partners/PartnerAutoComplete.vue";
import PartnerTripsConsignor from "@/components/partners/PartnerTripsConsignor.vue";
import PartnerTripsConsignee from "@/components/partners/PartnerTripsConsignee.vue";
import PartnerTripsForwarder from "@/components/partners/PartnerTripsForwarder.vue";
import PartnerTripsCarrier from "@/components/partners/PartnerTripsCarrier.vue";
import VesselFilter from "@/components/trips/other/VesselFilter.vue";
import PortFilter from "@/components/trips/other/PortFilter.vue";

export default {
    components: {
        FilterMenu,

        // PartnerAutoComplete,
        PartnerTripsConsignor,
        PartnerTripsConsignee,
        PartnerTripsForwarder,
        PartnerTripsCarrier,
        VesselFilter,
        PortFilter
    },
    props: {
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        filterCode: {
            type: String,
            default: ""
        }
    },
    apollo: {},

    data() {
        return {
            fab: false,
            isLoading: false,
            plannedDepartureDateMenu: false,
            plannedArrivalDateMenu: false,
            plannedDepartureDateRange: [],
            plannedArrivalDateRange: [],
            savedPlannedDepartureDateRange: [],
            savedPlannedArrivalDateRange: [],
            userFavoriteTrip: null,
            sortAsc: true,
            shouldStickyFilter: false,
            unresolvedAlerts: null,
            alertTags: [
                { value: "temperature", text: this.$t("general.temperature") },
                { value: "humidity", text: this.$t("general.humidity") },
                { value: "light", text: this.$t("general.light") },
                { value: "temperature_humidity", text: this.$t("general.temperature_humidity") },
                { value: "temperature_humidity_light", text: this.$t("general.temperature_humidity_light") },
                { value: "pressure", text: this.$t("general.pressure") }
                /*  
                        {value:"shock", text:"Shock"},
                        {value:"external_temperature", text:"External Temperature"},
                        {value:"battery", text:"Battery"},
                        {value:"probe_temperature", text:"Probe Temperature"}, 
                    */
            ],
            // alertsType: [
            //     {
            //         name: this.$t("dashboard.etd_delay"),
            //         value: "SEA_ETD_DELAY"
            //     },
            //     {
            //         name: this.$t("dashboard.eta_delay"),
            //         value: "SEA_ETA_DELAY"
            //     },
            //     {
            //         name: this.$t("dashboard.ts_waiting"),
            //         value: "SEA_TRANS_WAIT"
            //     },
            //     {
            //         name: this.$t("dashboard.pod_waiting"),
            //         value: "SEA_DEST_WAIT"
            //     },
            //     {
            //         name: this.$t("dashboard.custom_alert"),
            //         value: "CUSTOM_ALERT"
            //     }
            // ],

            // graphql query filter
            filter: {
                first: 10,
                after: null,
                referenceNumber_Icontains: null,
                tripFreightTrackerSet_Tracker_SerialNumber_Icontains: null,
                tripFreightSet_ContainerNumber_Icontains: null,
                tripFreightSet_AirWaybillNumber_Icontains: null,
                tripFreightSet_ReferenceNumber_Icontains: null,
                tenantPartnerId: null,
                status_In: "active",
                plannedDepartureDate_Gte: null,
                plannedDepartureDate_Lte: null,
                plannedArrivalDate_Gte: null,
                plannedArrivalDate_Lte: null,
                orderBy: null,
                isFavorite: null,
                pol: null,
                transshipment: null,
                transportMode_In: null,
                pod: null,
                alertCode: null,
                tripAlertSetIsResolved: null,
                tenantCarrier: null,
                consignor: null,
                consignee: null,
                forwarder: null,
                polvessel: null,
                tsvessel: null,
                goods: null,
                tenant: null,
                trackerLastLocation_Icontains: null,
                alertTag: null,
                trade_Icontains: null
            },
            refreshTableCarrier: false,
            refreshTableConsignor: false,
            refreshTableConsignee: false,
            refreshTableForwarder: false,
            refreshTableVessel: false,
            refreshTablePorts: false,
            filterChoices: null,
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "referenceNumber",
                    name: "Reference Number",
                    tag: "headers.reference_number",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "freights",
                    name: "Freight Number",
                    tag: "headers.freight_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "tracker",
                    name: "Tracker Number",
                    tag: "headers.tracker_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "containerNumber",
                    name: "Container Number",
                    tag: "headers.container_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "airWaybillNumber",
                    name: "AWB",
                    tag: "headers.awb_number",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "status",
                    name: "Status",
                    tag: "headers.status",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                },
                {
                    // for filter menu
                    code: "plannedDepartureDate",
                    name: "Planned Departure Date",
                    tag: "headers.planned_departure",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "plannedArrivalDate",
                    name: "Planned Arrival Date",
                    tag: "headers.planned_arrival",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "polSearch",
                    name: "POL Code",
                    tag: "headers.pol_code",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "transshipmentSearch",
                    name: "T/S Code",
                    tag: "headers.ts_code",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                /*   */
                {
                    code: "transportMode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "podsearch",
                    name: "POD Code",
                    tag: "headers.pod_code",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "alertCode",
                    name: "Alert Name",
                    tag: "headers.alert_name",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    // for filter menu
                    code: "consignor",
                    name: "Consignor",
                    tag: "headers.consignor",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "consignee",
                    name: "Consignee",
                    tag: "headers.consignee",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "forwarder",
                    name: "Forwarder",
                    tag: "headers.forwarder",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "polvessel",
                    name: "POL Vessel",
                    tag: "headers.pol_vessel",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "tsvessel",
                    name: "T/S Vessel",
                    tag: "headers.ts_vessel",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "goods",
                    name: "Goods",
                    tag: "headers.goods",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "trackerLastLocation",
                    name: "Last Location",
                    tag: "headers.last_location",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "alertTag",
                    name: "Alert Tag",
                    tag: "headers.alert_tag",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "trade",
                    name: "Trade",
                    tag: "headers.trade",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                }

                // {
                //     // for filter menu
                //     code: "relativePlannedDepartureDateRange",
                //     name: "Relative Date",
                //     enabled: true,
                //     // for filter layout
                //     class: "col-auto col-md-auto"
                // },
                // {
                //     // for filter menu
                //     code: "userFavoriteTripSetIdIsnull",
                //     name: "User Favorite",
                //     enabled: true,
                //     // for filter layout
                //     class: "col-auto col-md-3 col-lg-2"
                // }
                // {
                //     // for filter menu
                //     code: "kpi",
                //     name: "KPI",
                //     enabled: true,
                //     // for filter layout
                //     class: "col-auto col-md-3 col-lg-2"
                // }
            ],

            // sortByChoices: [
            //     {
            //         code: "referenceNumber",
            //         name: "Reference number"
            //     },
            //     {
            //         code: "status",
            //         name: "Status"
            //     },
            //     {
            //         code: "createdAt",
            //         name: "Created date"
            //     },
            //     {
            //         code: "modifiedAt",
            //         name: "Last modified date"
            //     },
            //     {
            //         code: "plannedDepartureDate",
            //         name: "Planned departure date"
            //     },
            //     {
            //         code: "consignor",
            //         name: "Consignor"
            //     },
            //     {
            //         code: "consignee",
            //         name: "Consignee"
            //     },
            //     {
            //         code: "forwarder",
            //         name: "Forwarder"
            //     },
            //     {
            //         code: "tenant_carrier",
            //         name: "Carrier"
            //     }
            // ],

            sortBy: null,
            // statusChoices: [
            //     { text: this.$t("general.all_trips"), value: null },
            //     { text: this.$t("general.active"), value: "active" },
            //     { text: this.$t("general.completed"), value: "completed" }
            // ],
            relativePlannedDepartureDateRange: null,
            relativePlannedDepartureDateRangeChoices: [
                // "Vorige maand",
                // "Laatste kwartaal",
                // "Afgelopen half jaar",
                // "Vorig jaar"
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            relativePlannedArrivalDateRange: null,
            relativePlannedArrivalDateRangeChoices: [
                // "Vorige maand",
                // "Laatste kwartaal",
                // "Afgelopen half jaar",
                // "Vorig jaar"
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ]
            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            // ]
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        plannedDepartureDateRangeText() {
            if (this.plannedDepartureDateRange.length > 1) {
                let date1 = moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedDepartureDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.plannedDepartureDateRange.length === 1) {
                return moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedDepartureDateRange;
            }
        },
        plannedArrivalDateRangeText() {
            if (this.plannedArrivalDateRange.length > 1) {
                let date1 = moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedArrivalDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.plannedArrivalDateRange.length === 1) {
                return moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedArrivalDateRange;
            }
        },
        _filterChoices() {
            return this.filterChoices;
        },
        dynamicFilters() {
            return this.filterChoices.filter(f => f.enabled).map(f => f.code);
        },
        tenantPartners() {
            return this.tripPartnerSet?.edges?.map(e => e.node) || [];
        },
        // shouldStickyFilter() {
        //     switch (this.$vuetify.breakpoint.name) {
        //         case "xs":
        //             return false;
        //         case "sm":
        //             return false;
        //         case "md":
        //             return true;
        //         case "lg":
        //             return true;
        //         case "xl":
        //             return true;
        //         default:
        //             return true;
        //     }
        // },
        // defaultFilterChoices: function() {
        //     let _defaultFilterChoices = [
        //         {
        //             // for filter menu
        //             code: "referenceNumber",
        //             name: "Reference Number",
        //             tag: "headers.reference_number",
        //             enabled: true,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "freights",
        //             name: "Freight Number",
        //             tag: "headers.freight_number",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "tracker",
        //             name: "Tracker Number",
        //             tag: "headers.tracker_number",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "containerNumber",
        //             name: "Container Number",
        //             tag: "headers.container_number",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "airWaybillNumber",
        //             name: "AWB Number",
        //             tag: "headers.awb_number",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "status",
        //             name: "Status",
        //             tag: "headers.status",
        //             enabled: true,
        //             // for filter layout
        //             class: "col-auto col-md-auto"
        //         },
        //         {
        //             // for filter menu
        //             code: "plannedDepartureDate",
        //             name: "Planned Departure Date",
        //             tag: "headers.planned_departure",
        //             enabled: true,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "plannedArrivalDate",
        //             name: "Planned Arrival Date",
        //             tag: "headers.planned_arrival",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             code: "polSearch",
        //             name: "POL Code",
        //             tag: "headers.pol_code",
        //             class: "col-auto col-md-3 col-lg-2",
        //             enabled: false
        //         },
        //         {
        //             code: "transshipmentSearch",
        //             name: "T/S Code",
        //             tag: "headers.ts_code",
        //             class: "col-auto col-md-3 col-lg-2",
        //             enabled: false
        //         },
        //         {
        //             code: "podSearch",
        //             name: "POL Code",
        //             tag: "headers.pol_code",
        //             class: "col-auto col-md-3 col-lg-2",
        //             enabled: false
        //         },
        //         {
        //             code: "transportMode",
        //             name: "Transport Mode",
        //             tag: "headers.transport_mode",
        //             class: "col-auto col-md-3 col-lg-2",
        //             enabled: false
        //         },
        //         {
        //             code: "alertCode",
        //             name: "Alert Name",
        //             tag: "headers.alert_name",
        //             class: "col-auto col-md-3 col-lg-2",
        //             enabled: false
        //         },
        //         {
        //             // for filter menu
        //             code: "consignor",
        //             name: "Consignor",
        //             tag: "headers.consignor",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "consignee",
        //             name: "Consignee",
        //             tag: "headers.consignee",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "forwarder",
        //             name: "Forwarder",
        //             tag: "headers.forwarder",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "carrier",
        //             name: "Carrier",
        //             tag: "headers.carrier",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "polvessel",
        //             name: "POL Vessel",
        //             tag: "headers.pol_vessel",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "tsvessel",
        //             name: "T/S Vessel",
        //             tag: "headers.ts_vessel",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             // for filter menu
        //             code: "goods",
        //             name: "Goods",
        //             tag: "headers.goods",
        //             enabled: false,
        //             // for filter layout
        //             class: "col-auto col-md-3 col-lg-2"
        //         },
        //         {
        //             code: "trackerLastLocation",
        //             name: "Last Location",
        //             tag: "headers.last_location",
        //             class: "col-auto col-md-3 col-lg-2",
        //             enabled: false
        //         }
        //     ];
        //     return _defaultFilterChoices;
        // },

        statusChoices: function() {
            let _statusChoices = [
                { text: this.$t("general.all_trips"), value: null },
                { text: this.$t("general.active"), value: "active" },
                { text: this.$t("general.completed"), value: "completed" }
            ];
            return _statusChoices;
        },

        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        },

        alertsType: function() {
            let _alertsType = [
                {
                    name: this.$t("dashboard.etd_delay"),
                    value: "SEA_ETD_DELAY"
                },
                {
                    name: this.$t("dashboard.eta_delay"),
                    value: "SEA_ETA_DELAY"
                },
                {
                    name: this.$t("dashboard.ts_waiting"),
                    value: "SEA_TRANS_WAIT"
                },
                {
                    name: this.$t("dashboard.pod_waiting"),
                    value: "SEA_DEST_WAIT"
                },
                {
                    name: this.$t("dashboard.custom_alert"),
                    value: "CUSTOM_ALERT"
                }
            ];
            return _alertsType;
        },

        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "referenceNumber",
                    name: this.$t("headers.reference_number")
                },
                {
                    code: "status",
                    name: this.$t("headers.status")
                },
                {
                    code: "createdAt",
                    name: this.$t("headers.created_at")
                },
                {
                    code: "modifiedAt",
                    name: this.$t("headers.modified_at")
                },
                {
                    code: "plannedDepartureDate",
                    name: this.$t("headers.planned_departure")
                },
                {
                    code: "consignor",
                    name: this.$t("headers.consignor")
                },
                {
                    code: "consignee",
                    name: this.$t("headers.consignee")
                },
                {
                    code: "forwarder",
                    name: this.$t("headers.forwarder")
                },
                {
                    code: "tenant_carrier",
                    name: this.$t("headers.carrier")
                }
            ];
            return _sortByChoices;
        }
    },
    watch: {
        userFavoriteTrip(val) {
            this.filter.isFavorite = val ? true : null;
        },
        unresolvedAlerts(val) {
            this.filter.tripAlertSetIsResolved = val ? false : null;
        },
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        relativePlannedDepartureDateRange(val) {
            const _index = this.relativePlannedDepartureDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedDepartureDateRange = [];
                    this.savedPlannedDepartureDateRange = [];
            }
        },

        relativePlannedArrivalDateRange(val) {
            const _index = this.relativePlannedArrivalDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedArrivalDateRange = [];
                    this.savedPlannedArrivalDateRange = [];
            }
        },

        savedPlannedDepartureDateRange(val) {
            if (val.length == 1) {
                this.filter.plannedDepartureDate_Gte = val[0] + "T00:00:00";
                this.filter.plannedDepartureDate_Lte = val[0] + "23:59:59";
            } else if (val.length == 2) {
                this.filter.plannedDepartureDate_Gte = val[0] + "T00:00:00";
                this.filter.plannedDepartureDate_Lte = val[1] + "T23:59:59";
            } else {
                this.filter.plannedDepartureDate_Gte = null;
                this.filter.plannedDepartureDate_Lte = null;
            }
        },

        savedPlannedArrivalDateRange(val) {
            if (val.length == 1) {
                this.filter.plannedArrivalDate_Gte = val[0] + "T00:00:00";
                this.filter.plannedArrivalDate_Lte = val[0] + "23:59:59";
            } else if (val.length == 2) {
                this.filter.plannedArrivalDate_Gte = val[0] + "T00:00:00";
                this.filter.plannedArrivalDate_Lte = val[1] + "T23:59:59";
            } else {
                this.filter.plannedArrivalDate_Gte = null;
                this.filter.plannedArrivalDate_Lte = null;
            }
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit("filter-changed", JSON.parse(JSON.stringify(value)));
                    helper.setFilterValues("tripTableFilters", value);
                }
            },
            deep: true
        },
        filterCode: function(code) {
            if (this.alertsType.find(e => e.value === code)) {
                this.filter.alertCode = code;
                if (!this.filterChoices?.find(e => e.code === "alertCode")?.enabled) {
                    this.filterChoices.find(e => e.code === "alertCode").enabled = true;
                }
            } else if (code === "TOTAL_OPEN_TRIPS") {
                this.filter.status_In = "active";
                if (!this.filterChoices?.find(e => e.code === "status")?.enabled) {
                    this.filterChoices.find(e => e.code === "status").enabled = true;
                }
            } else if (code === "UNRESOLVED_ALERTS") {
                this.filter.tripAlertSetIsResolved = false;
                this.unresolvedAlerts = true;
            } else if (code === "UNRESOLVED_TAG_TEMPERATURE") {
                this.filter.alertTag = "temperature";
            } else if (code === "UNRESOLVED_TAG_HUMIDITY") {
                this.filter.alertTag = "humidity";
            } else if (code === "UNRESOLVED_TAG_LIGHT") {
                this.filter.alertTag = "light";
            } else if (code === "UNRESOLVED_TAG_SHOCK") {
                this.filter.alertTag = "shock";
            } else if (code === "UNRESOLVED_TAG_PRESSURE") {
                this.filter.alertTag = "pressure";
            } else if (code === "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY") {
                this.filter.alertTag = "temperature_humidity";
            } else if (code === "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY_LIGHT") {
                this.filter.alertTag = "temperature_humidity_light";
            }
        },
        isSharedTrip: {
            handler(value) {
                if (value) {
                    this.defaultFilterChoices.push({
                        // for filter menu
                        code: "sharedBy",
                        name: "Shared By",
                        tag: "headers.shared_by",
                        enabled: false,
                        // for filter layout
                        class: "col-auto col-md-3 col-lg-2"
                    });
                    this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "tripTableFilters");
                } else {
                    this.defaultFilterChoices = this.defaultFilterChoices.filter(e => e.code !== "sharedBy");
                    this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "tripTableFilters");
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "tripTableFilters");
    },
    mounted() {
        this.onResize();
        this.sortBy = "referenceNumber";
        let _filter = helper.getFilterValues("tripTableFilters");
        if (_filter) {
            this.filter = _filter;

            if (_filter.plannedDepartureDate_Gte) {
                this.plannedDepartureDateRange = [
                    _filter.plannedDepartureDate_Gte.split("T")[0],
                    _filter.plannedDepartureDate_Lte.split("T")[0]
                ];
            }

            if (_filter.plannedArrivalDate_Gte) {
                this.plannedArrivalDateRange = [
                    _filter.plannedArrivalDate_Gte.split("T")[0],
                    _filter.plannedArrivalDate_Lte.split("T")[0]
                ];
            }

            if (_filter?.orderBy?.includes("-")) {
                this.sortBy = _filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else {
                this.sortBy = _filter.orderBy;
                this.sortAsc = true;
            }

            this.userFavoriteTrip = _filter.isFavorite;

            if (_filter.tripAlertSetIsResolved == false) this.unresolvedAlerts = true;

            if (_filter.forwarder) {
                this.refreshTableForwarder = true;
            }

            if (_filter.tenantCarrier) {
                this.refreshTableCarrier = true;
            }

            if (_filter.consignee) {
                this.refreshTableConsignee = true;
            }

            if (_filter.consignor) {
                this.refreshTableConsignor = true;
            }

            if (_filter.polvessel || _filter.tsvessel) {
                this.refreshTableVessel = true;
            }

            if (_filter.pol || _filter.pod || _filter.transshipment) {
                this.refreshTablePorts = true;
            }
        }

        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    tripTableFilters: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        clearFilter() {
            const filters = helper.getFilterValues("tripTableFilters");
            Object.keys(filters).forEach(key => {
                this.filter[key] = null;
            });

            this.filter.status_In = "active";
            this.filter.orderBy = "-plannedDepartureDate";
            this.filter.after = null;
            this.filter.first = 10;

            this.plannedDepartureDateRange = [];
            this.plannedArrivalDateRange = [];
            this.relativePlannedDepartureDateRange = null;
            this.relativePlannedArrivalDateRange = null;
            this.sortBy = "referenceNumber";
            this.sortAsc = false;
            this.userFavoriteTrip = null;
            this.unresolvedAlerts = null;
        },
        // dynamic filter
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        getFilterChoiceNameByCode(code) {
            return this.$t(
                this.defaultFilterChoices.find(item => {
                    return item.code === code;
                })?.tag
            );
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelPlannedDepartureDatePicker() {
            this.plannedDepartureDateMenu = false;
            if (this.savedPlannedDepartureDateRange.length == 0) {
                this.plannedDepartureDateRange = [];
            }
        },
        cancelPlannedArrivalDatePicker() {
            this.plannedArrivalDateMenu = false;
            if (this.savedPlannedArrivalDateRange.length == 0) {
                this.plannedArrivalDateRange = [];
            }
        },
        onClearPlannedDepartureDates() {
            this.plannedDepartureDateRange = [];
            this.savedPlannedDepartureDateRange = [];
            this.relativePlannedDepartureDateRange = null;
        },
        onClearPlannedArrivalDates() {
            this.plannedArrivalDateRange = [];
            this.savedPlannedArrivalDateRange = [];
            this.relativePlannedArrivalDateRange = null;
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        },
        onTripCreated(trip) {
            this.$emit("trip-created", trip);
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
