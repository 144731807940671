<template>
    <!-- Disable 2FA dialog -->
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
            <v-card-title class="primary white--text">
                {{ $t("general.disable_2fa") }}
            </v-card-title>

            <v-card-text class="pt-10 pb-0">
                <v-form v-model="valid" ref="disable2FAForm">
                    <v-text-field
                        id="password"
                        :label="$t('login.password')"
                        name="password"
                        :type="showpassword ? 'text' : 'password'"
                        :rules="[rules.required]"
                        :error-messages="disable2FAError"
                        outlined
                        v-model="password"
                        :append-icon="
                            showpassword ? 'visibility' : 'visibility_off'
                        "
                        @click:append="showpassword = !showpassword"
                        @keydown.enter="disabled2FA()"
                    />
                </v-form>
            </v-card-text>

            <v-card-actions class="pb-5">
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeDisable2FADialog()">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    outlined
                    rounded
                    :loading="isDisabling2FA"
                    color="error"
                    @click="disable2FA()"
                    >{{ $t("general.turn_off") }}</v-btn
                >
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "disable-2fa-dialog",
    props: ["dialog"],
    components: {},

    apollo: {},

    data: function() {
        return {
            valid: false,
            isDisabling2FA: false,
            password: "",
            showpassword: false,
            disable2FAError: "",

            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
                // nameRules: [
                //     v => !!v || "Name is required",
                //     v =>
                //         v.length <= 50 || "Name must be less than 50 characters"
                // ]
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {},
    methods: {
        disable2FA() {
            if (!this.$refs.disable2FAForm.validate()) {
                return;
            }

            this.isDisabling2FA = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation disable2fa($input: Disable2FAInput!) {
                            disable2fa(input: $input) {
                                success
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            password: this.password
                        }
                    }
                })
                .then(() => {
                    this.closeDisable2FADialog();

                    this.$emit("callback");

                    // show snackbar
                    const payload = {
                        color: "success",
                        message: this.$t("general.2fa_disabled")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.disable2FAError = error.message;
                })
                .finally(() => {
                    this.isDisabling2FA = false;
                });
        },

        closeDisable2FADialog() {
            this.password = "";
            this.showpassword = false;
            this.$refs.disable2FAForm.resetValidation();
            this.$emit("update:dialog", false);
        }
    }
};
</script>
