<template>
    <v-card fill-height flat width="100%" v-if="group.node" color="background">
        <v-toolbar flat color="background">
            <!----- TOP LEFT ICONS ---->
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn
                v-if="shouldShowFullScreenButton"
                icon
                @click="changeFullScreen"
            >
                <v-icon>{{
                    isFullScreen ? "fullscreen_exit" : "crop_free"
                }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <!---- TOP RIGHT ICONS ---->
            <v-btn icon>
                <v-icon @click="editGroup"> edit </v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon @click="openDeleteGroupDialog = true"> delete </v-icon>
            </v-btn>

            <!---- EDIT PARTNER DIALOG ---->
            <EditGroupDialog
                ref="editGroupDialog"
                :showGroupSelect="true"
                :openEditGroupDialog="openEditGroupDialog"
                v-on:updatedGroup="updateGroupValues"
                v-on:closeEditGroupDialog="openEditGroupDialog = false"
            ></EditGroupDialog>

            <!---- DELETE PARTNER DIALOG ---->
            <DeleteGroupDialog
                :openDeleteGroupDialog="openDeleteGroupDialog"
                :groupId="[group.node.id]"
                v-on:refreshDataTable="closeDrawerAndRefresh"
                v-on:closeDeleteDialog="openDeleteGroupDialog = false"
            >
                <template v-slot:title>
                    {{ $t("groups.delete_group") }}
                </template>
                <template #content>
                    {{ $t("groups.sure_group") }}
                </template>
            </DeleteGroupDialog>

            <!---- FILTER DIALOG ---->
            <FilterMenu
                :title="$t('dashboard.customize')"
                :description="$t('dashboard.display_tabs')"
                :choices="filterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
                class="mr-n2"
            >
            </FilterMenu>
        </v-toolbar>

        <v-card-title class="pt-0 px-5">
            <!---- GROUP GENERAL INFO DISPLAY (NAME, USERS...) ---->
            <v-row>
                <!---- NAME ---->
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" class="mb-3">
                    <div class="textHeader text-truncate">{{ $t("headers.name") }}</div>
                    <div class="textBody text-truncate">
                        {{ updatedGroup ? updatedGroup.name : group.node.name }}
                    </div>
                </v-col>
                <!---- USERS ---->
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" class="mb-3">
                    <div class="text-caption">{{ $t("general.nusers") }}</div> 
                    <div class="textBody text-truncate">
                        {{
                            updatedGroup
                                ? updatedGroup.group.userSet.totalCount
                                : group.node.group.userSet.totalCount
                        }}
                    </div>
                </v-col>
            </v-row>
        </v-card-title>

        <!---- tab view ---->
        <PreviewGroupDetailTabView
            v-if="view === 'tab'"
            :group="group"
            :selectedFilters="selectedFilters"
        />

        <!---- list view ---->
        <GroupDetailListView
            v-if="view === 'list'"
            class="mx-5 pb-10"
            :group="group"
            :selectedFilters="selectedFilters"
        />
    </v-card>
</template>
<script>
import helper from "@/utils/helper";
import FilterMenu from "@/components/base/FilterMenu.vue";
import PreviewGroupDetailTabView from "@/components/groups/PreviewGroupDetailTabView.vue";
import GroupDetailListView from "@/components/groups/GroupDetailListView.vue";
import DeleteGroupDialog from "@/components/groups/delete/DeleteGroupDialog.vue";
import EditGroupDialog from "@/components/groups/update/EditGroupDialog.vue";
import Config from "@/utils/config.json";

export default {
    name: "GroupDetail",
    props: {
        group: {
            type: Object,
            required: true
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        PreviewGroupDetailTabView,
        GroupDetailListView,
        FilterMenu,
        DeleteGroupDialog,
        EditGroupDialog
    },
    data() {
        return {
            /**********************/
            /* --- QUERY DATA --- */
            /**********************/
            updatedGroup: null,
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /***********************************/
            openDeleteGroupDialog: false,
            openEditGroupDialog: false,
            /*******************/
            /* --- FILTERS --- */
            /*******************/
            view: "tab",
            viewChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "permissions",
                    name: "Permissions",
                    tag: "headers.permissions",
                    enabled: true,
                    order: 2
                },
                {
                    // for filter menu
                    code: "users",
                    name: "Users",
                    tag: "home.users",
                    enabled: true,
                    order: 1
                },
                
                {
                    // for filter menu
                    code: "partners",
                    name: "Partners",
                    tag: "headers.partners",
                    enabled: true,
                    order: 3
                }
            ],
            filterChoices: []
        };
    },
    created() {
        this.viewType = helper.getViewType("groupDetailViewType");
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "groupDetailFilterChoices"
        );
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];

            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        }
    },
    watch: {},
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    groupDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    groupDetailFilterChoices: value
                });
            },
            { deep: true }
        );

        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'group_detail_svw',
        });
        this.setUserProperties();
    },
    methods: {
          setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("group_detail_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("group_detail_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editGroup() {
           
            this.$store.dispatch("group/updateCreatedGroup", {
                type: "update",
                group: this.group
            });

            this.$router.push({
                path:  "/previewcreategroup",
                query: {
                    type: 'edit'
                }
            });
        },
        editGroupDialogHandler(item) {
            this.openEditGroupDialog = true;
            this.$refs.editGroupDialog.initializeEditing(item);
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updateGroupValues(val) {
            // Close edit partner dialog if open
            this.$emit("refreshDataTable");
            this.openEditGroupDialog = false;
            this.updatedGroup = val.data.updateTenantUserGroup.tenantStaffGroup;
        }
    }
};
</script>
<style scoped>
.custom-height {
    height: 35px;
}

.v-input--selection-controls {
    margin-top: 0;
}
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}

.labelText {
    font-size: 32px;
    font-weight: 700;
    color: #0e0d35;
    line-height: 38.4px;
}

.titleText {
    font-size: 12px;
    font-weight: 700;
    color: #555464;
    line-height: 14.4px;
}
</style>

