<template>
    <v-dialog
        max-width="1000"
        v-model="openEditLocationDialog"
        persistent
        scrollable
    >
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">edit</v-icon>
                {{ $t("partners.edit_location") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-1">
                <v-row no-gutters>
                    <v-col cols="6" sm="6" md="8" lg="8">
                        <l-map
                            ref="map"
                            :zoom="zoom"
                            :center="map_center"
                            :options="{
                                attributionControl: false,
                                drawControl: false,
                                zoomControl: false,
                                worldCopyJump: true
                            }"
                        >
                            <!--- TILE LAYER --->
                            <l-tile-layer
                                class="mapEdit"
                                :url="here_map_url"
                            ></l-tile-layer>
                            <!--- ZOOM CONTROL --->
                            <l-control-zoom
                                position="bottomright"
                            ></l-control-zoom>
                            <!--- RECENTER BUTTON ---->
                            <l-control position="bottomleft">
                                <v-row dense>
                                    <v-col cols="2">
                                        <v-btn
                                            @click="recenterMap"
                                            fab
                                            tile
                                            x-small
                                        >
                                            <v-icon color="primary"
                                                >my_location</v-icon
                                            >
                                        </v-btn>
                                    </v-col>
                                    <v-col
                                        cols="10"
                                        class="pl-1 d-flex"
                                        v-if="triggerRadius"
                                    >
                                        <v-card
                                            width="250"
                                            tile
                                            class="pl-1"
                                            elevation="3"
                                            outlined
                                        >
                                            <v-slider
                                                v-model="radius"
                                                max="200000"
                                                min="1000"
                                                thumb-color="primary"
                                                track-color="primary"
                                                hide-details
                                                class="mt-0"
                                                @change="editCircle(radius)"
                                            >
                                                <template v-slot:label>
                                                    <span class="ml-1 pr-0"
                                                        >{{ radius }} m</span
                                                    >
                                                </template>
                                            </v-slider>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </l-control>
                            <!--- MAP SEARCH BAR --->
                            <l-control position="topleft">
                                <!--- SEARCH BUTTON I/O --->
                                <v-btn
                                    fab
                                    tile
                                    x-small
                                    v-if="!showMapSearchBar"
                                    @click="
                                        showMapSearchBar = !showMapSearchBar
                                    "
                                    ><v-icon color="primary"
                                        >search</v-icon
                                    ></v-btn
                                >
                                <!--- SEARCH BAR/AUTOCOMPLETE --->
                                <v-autocomplete
                                    v-model="selectedSuggestion"
                                    :search-input.sync="searchBarValue"
                                    :items="searchSuggestions"
                                    :no-data-text="$t('loading.no_available')"
                                    item-text="address.label"
                                    item-value="id"
                                    solo
                                    prepend-inner-icon="search"
                                    append-icon="close"
                                    @click:append="
                                        showMapSearchBar = !showMapSearchBar
                                    "
                                    @click:prepend-inner="mapSearchHandler"
                                    @keydown.enter="mapSearchHandler"
                                    v-if="showMapSearchBar"
                                    :menu-props="{
                                        maxHeight: '150px',
                                    }"
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-title>
                                                {{
                                                    $t(
                                                        "partners.search_location"
                                                    )
                                                }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete></l-control
                            >
                        </l-map>
                    </v-col>
                    <v-col cols="6" sm="6" md="4" lg="4" class="pr-2 pl-2">
                        <v-card flat>
                            <v-card-text class="px-0 mx-0  maxSize">
                                <v-form v-model="validate">
                                    <v-row class="mx-0 pa-0">
                                        <!--- LOCATION NAME --->
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="locationName"
                                                outlined
                                                dense
                                                hide-details
                                                :label="
                                                    $t('headers.name') + '*'
                                                "
                                                :placeholder="
                                                    $t('general.example')
                                                "
                                            ></v-text-field>
                                        </v-col>
                                        <!--- LOCATION CITY --->
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="locationCity"
                                                outlined
                                                dense
                                                hide-details
                                                :label="
                                                    $t('partners.city') + '*'
                                                "
                                            ></v-text-field>
                                        </v-col>
                                        <!--- LOCATION COUNTRY --->
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="locationCountry"
                                                outlined
                                                hide-details
                                                dense
                                                :label="
                                                    $t('partners.country') + '*'
                                                "
                                            ></v-text-field>
                                        </v-col>
                                        <!--- LOCATION CODE --->
                                        <!-- <v-col cols="12" sm="6" md="4">
                                            <v-text-field
                                                v-model="locationCode"
                                                outlined
                                                label="Zip-Code"
                                            ></v-text-field>
                                        </v-col> -->
                                        <!--- LOCATION ADRESS --->
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="locationFullAddress"
                                                outlined
                                                hide-details
                                                dense
                                                :label="$t('partners.address')"
                                            ></v-text-field>
                                        </v-col>

                                        <!--- TAGS --->
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="tags"
                                                :items="tagsChoices"
                                                small-chips
                                                multiple
                                                deletable-chips
                                                dense
                                                outlined
                                                hide-details
                                                :label="$t('headers.tags')"
                                                :menu-props="{
                                                    maxHeight: '150px',
                                                }"
                                            ></v-autocomplete>
                                        </v-col>

                                        <!-- IS TRACKER STOCK LOCATION -->
                                        <v-col cols="4" sm="4" v-if="!partner">
                                            <v-switch
                                                v-model="isTrackerStockLocation"
                                                hide-details
                                                :label="
                                                    $t(
                                                        'partners.partner_location'
                                                    )
                                                "
                                                class="mt-0 text-no-wrap"
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="resetLocation" rounded>{{
                                    $t("general.cancel")
                                }}</v-btn>
                                <v-btn
                                    :disabled="
                                        !validate || locationLatitude == null
                                    "
                                    color="primary"
                                    @click="updateLocation"
                                    rounded
                                    dense
                                    :loading="isLoadingLocationEdit"
                                    >{{ $t("general.save") }}</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

                <!--- MAP --->

                <!--- MAP FORM/INPUTS --->
                <!--- PARTNER AUTOCOMPLETE --->
                <!-- <v-row>
                            <v-autocomplete
                                :class="
                                    $vuetify.breakpoint.smAndDown
                                        ? 'mx-2'
                                        : 'mx-2'
                                "
                                v-if="showPartnerSelect"
                                v-model="selectedPartner"
                                :items="tenantPartners"
                                item-text="node.name"
                                item-value="node.id"
                                label="Partner"
                                outlined
                                clearable
                                hint="Don't select a partner if you want this to be a public location."
                            ></v-autocomplete
                        ></v-row> -->
            </v-card-text>
            <!--- SAVE OR CANCEL --->
        </v-card>
    </v-dialog>
</template>
<style scoped>
.cursorPen {
    cursor: url("https://img.icons8.com/material-rounded/24/000000/edit--v1.png"),
        default;
}
.map {
    cursor: not-allowed;
}
</style>
<script>
L;
LDraw;
import L from "leaflet";
import LDraw from "leaflet-draw";
import gql from "graphql-tag";
import { LMap, LTileLayer, LControl, LControlZoom } from "vue2-leaflet";
import Config from "@/utils/config.json";

export default {
    name: "HereMap",
    components: {
        LMap,
        LTileLayer,
        LControl,
        LControlZoom
    },
    props: {
        openEditLocationDialog: {
            type: Boolean
        },
        viewType: {
            type: String,
            default: null
        },
        partner: {
            type: Object
        },
        showPartnerSelect: {
            type: Boolean
        }
    },
    // apollo: {
    //     tenantPartners: {
    //         query: gql`
    //             query AllTenantPartners {
    //                 tenantPartners {
    //                     edges {
    //                         node {
    //                             id
    //                             name
    //                         }
    //                     }
    //                 }
    //             }
    //         `,
    //         debounce: 800,
    //         fetchPolicy: "cache-and-network",
    //         nextFetchPolicy: "cache-first",
    //         update: (data) => data.tenantPartners.edges,
    //         skip: false
    //     }
    // },
    data() {
        return {
            /*******************************************************/
            /* ---------- HERE API & MAP CONFIGURATIONS ---------- */
            /*******************************************************/
            apikey: Config.VUE_APP_MAP_API_KEY, // You can get the API KEY from developer.here.com
            here_map_url:
                `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=${Config.VUE_APP_MAP_API_KEY}&ppi=320`,
            map_center: [38.73, -9.2],
            circle_center: [38.73, -9.2],
            zoom: 2,
            radius: 1000,
            validate: false,
            selectedAutoComplete: null,
            searchBarValue: null,
            showMapSearchBar: false,
            searchSuggestions: null,
            selectedSuggestion: null,

            drawingEnabled: false,
            featureGroup: null,
            layer: null,
            automaticCircle: null,
            drawControl: null,
            drawOptions: {
                polygon: false,
                marker: false,
                polyline: false,
                circlemarker: false,
                rectangle: false,
                circle: {
                    allowIntersection: false,
                    showArea: true,
                    metric: true,
                    showRadius: true,
                    repeatMode: false
                }
            },

            // selectedPartner: null,
            isTrackerStockLocation: false,
            publicLocation: false,
            locationName: null,
            locationCountry: null,
            locationType: null,
            locationCity: null,
            locationCode: null,
            locationFullAddress: null,
            locationLatitude: null,
            locationLongitude: null,
            isLoadingLocationEdit: false,
            tags: [],
            triggerRadius: false,
            tagsChoices: [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ],
            headerChoices: [
                {
                    text: "",
                    align: "center",
                    value: "action",
                    width: "5px",
                    sortable: false
                },
                {
                    text: this.$t("headers.name"),
                    align: "center",
                    value: "name",
                    sortable: false
                },
                {
                    text: this.$t("headers.address"),
                    align: "center",
                    value: "node.user.lastName",
                    sortable: false
                },
                {
                    text: this.$t("partners.country"),
                    align: "center",
                    value: "node.user.email",
                    sortable: false
                },
                {
                    text: this.$t("partners.city"),
                    align: "center",
                    value: "node.user.require2fa",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "edit",
                    width: "5px",
                    sortable: false
                }
            ]
        };
    },
    computed: {},
    async mounted() {},
    updated() {
        //fix map render everytime the component updates
        this.resizeMap();
        this.allowDrawing(false);
    },
    watch: {
        async selectedSuggestion(id) {
            if (
                this.selectedSuggestion == null ||
                this.selectedSuggestion == undefined
            )
                return;
            /*** IF USER SELECTS ONE OF THE SUGGESTIONS, ASSIGN IT TO MAP LOCATION/CENTER ***/
            let response = await fetch(
                "https://lookup.search.hereapi.com/v1/lookup?id=" +
                    id +
                    "&lang=en-US&apiKey=" +
                    this.apikey
            );
            const map = this.$refs.map.mapObject;
            let data = await response.json();
            this.map_center = data.position;
            this.circle_center = data.position;
            this.locationLatitude = data.position.lat;
            this.locationLongitude = data.position.lng;
            this.locationCountry = data.address.countryName;
            this.locationCity = data.address.city;
            this.locationCode = data.address.postalCode;
            this.locationFullAddress = data.address.label;
            map.setZoom(13);
            map.panTo(this.map_center);
            this.allowDrawing(true);
        },
        searchBarValue() {
            this.debounce();
        }
    },
    methods: {
        initializeEditing(item) {
            this.$nextTick(() => {
                this.locationID = item.node.id;
                // this.selectedPartner = item.node.tenantPartner
                //     ? item.node.tenantPartner.id
                //     : null;
                this.locationType = item.node.locationType;
                this.isTrackerStockLocation = item.node.isTrackerStockLocation;
                this.locationName = item.node.name;
                this.locationCountry = item.node.country;
                this.locationCity = item.node.city;
                this.locationCode = item.node.postalCode;
                this.locationFullAddress = item.node.fullAddress;
                this.locationLatitude = item.node.latitude;
                this.locationLongitude = item.node.longitude;
                this.radius = item.node.radius;
                if (item.node.tags) {
                    this.tags = JSON.parse(item.node.tags.replace(/'/g, '"'));
                }
                this.publicLocation =
                    this.locationType.toLowerCase() === "tenant_location";
                this.map_center = [item.node.latitude, item.node.longitude];
                this.circle_center = [item.node.latitude, item.node.longitude];
                const map = this.$refs.map?.mapObject;
                map?.setZoom(13);
                this.allowDrawing(true);
            });
        },
        resetLocation() {
            // Remove drawn layers //
            const map = this.$refs.map.mapObject;
            if (this.layer != null) map.removeLayer(this.layer);
            if (this.drawingEnabled == true) this.allowDrawing(false);
            if (this.automaticCircle != null) this.automaticCircle = null;
            this.featureGroup?.clearLayers();
            // scroll dialog back up if necessary //
            let dialog = window.document.getElementsByClassName(
                "v-dialog v-dialog--active v-dialog--persistent"
            );
            dialog[0]?.scrollTo(0, 0);
            // Reset zoom //
            map.setZoom(2);
            // Reset variables value //
            this.map_center = [12, 30];
            this.isTrackerStockLocation = false;
            this.locationName = null;
            this.locationCountry = null;
            this.locationCity = null;
            this.locationCode = null;
            this.locationFullAddress = null;
            this.locationLatitude = null;
            this.locationLongitude = null;
            this.drawingEnabled = false;
            this.searchBarValue = null;
            this.tags = [];
            this.showMapSearchBar = false;
            this.selectedSuggestion = null;
            // Close dialog //
            this.$emit("closeMap");
            this.triggerRadius = false;
        },
        recenterMap() {
            const map = this.$refs.map.mapObject;
            if (this.locationLatitude && this.locationLongitude)
                map.panTo([this.locationLatitude, this.locationLongitude]);
        },
        editCircle(radius) {
            const map = this.$refs.map.mapObject;
            if (this.automaticCircle != null) {
                map.removeLayer(this.automaticCircle);
                this.automaticCircle = null;
            }

            this.drawingEnabled = true;
            this.featureGroup.clearLayers();
            this.layer = L.circle(this.circle_center, {
                radius: radius
            });
            //then add new drawn layer.
            this.featureGroup.addLayer(this.layer);
            //Event listener for when editing:
            this.layer.on("edit", e => {
                this.circle_center = e.target.getLatLng();
                this.locationLatitude = e.target.getLatLng().lat;
                this.locationLongitude = e.target.getLatLng().lng;
                this.radius = e.target.getRadius();
            });
        },
        allowDrawing(createCircleAuto) {
            if (!this.$refs.map) return;
            const map = this.$refs.map.mapObject;
            if (this.featureGroup == null) {
                // Add new FeatureGroup from leaflet for Draw objects on map. Group together elements of the maps including: markers, geoJSON, polylines and polygon, tooltip and popup.
                this.featureGroup = new window.L.FeatureGroup();
                this.featureGroup.addTo(map);
            }
            // Create leaflet draw control menu
            if (this.drawControl == null) {
                this.drawControl = new window.L.Control.Draw({
                    position: "topright",
                    draw: this.drawOptions,
                    edit: {
                        featureGroup: this.featureGroup,
                        remove: true,
                        edit: {
                            selectedPathOptions: {
                                color: "#000",
                                fillColor: "#000"
                            }
                        }
                    }
                });
                // Add draw control to the map
                map.addControl(this.drawControl);
            }
            if (createCircleAuto) {
                if (this.automaticCircle == null) {
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });
                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                } else {
                    this.drawingEnabled = true;
                    this.featureGroup.clearLayers();
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });
                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                }
            } else {
                this.drawingEnabled = true;
                this.featureGroup.clearLayers();
                this.automaticCircle = L.circle(this.circle_center, {
                    radius: this.radius
                });

                //then add new drawn layer.
                this.featureGroup.addLayer(this.automaticCircle);
                //Event listener for when editing:
                this.automaticCircle.on("edit", e => {
                    this.handleEventData(e);
                });
            }

            //capture when the drag and draw circle is clicked
            map.on("draw:created", e => {
                this.triggerRadius = true;
                this.handleEventData(e);
            });
            //capture when the drag circle is clicked
            map.on("draw:editstart", () => {
                this.triggerRadius = true;
            });
            //capture when edit stop.
            map.on("draw:editstop", () => {
                this.triggerRadius = false;
            });
            //capture the event when circle is deleted and reset the values.
            map.on("draw:deleted", () => {
                this.triggerRadius = false;
                this.locationLatitude = null;
                this.locationLongitude = null;
                this.radius = null;
                this.circle_center = [12, 30];
                this.automaticCircle = null;
                this.featureGroup.clearLayers();
                this.drawingEnabled = false;
                this.isTrackerStockLocation = false;
                this.locationName = null;
                this.locationCountry = null;
                this.locationCity = null;
                this.locationCode = null;
                this.locationFullAddress = null;
            });

            /*  //reference this to acess it inside the scope of the next function
            const that = this;
            const vueDataObject = this.$data;
            //After finished drawing figure, add the drawn layer on top of the map.
            map.on("draw:created", function (e) {
                vueDataObject.layer = e.layer;
                //minimum radius allowed is 1000 meters.
                if (vueDataObject.layer._mRadius < 1000)
                    vueDataObject.layer._mRadius = 1000;
                //check if we have previously drawn anything. If so , clean it.
                vueDataObject.featureGroup.clearLayers();
                if (vueDataObject.automaticCircle) {
                    map.removeLayer(vueDataObject.automaticCircle);
                    vueDataObject.automaticCircle = null;
                }
                //then add new drawn layer.
                vueDataObject.featureGroup.addLayer(vueDataObject.layer);
                //Event listener for when editing:
                vueDataObject.layer.on("edit", (e) => {
                    that.handleEventData(e);
                });
                //Assign new received data to form and variables.
                that.handleEventData(e);
            }); */
        },
        handleEventData(event) {
            // HANDLE DATA COMING FROM DRAWING FINISHED AND EDITING CIRCLE
            this.radius = event.layer
                ? event.layer._mRadius
                : event.target._mRadius;
            this.locationLatitude = event.layer
                ? event.layer._latlng.lat
                : event.target._latlng.lat;
            this.locationLongitude = event.layer
                ? event.layer._latlng.lng
                : event.target._latlng.lng;
            this.getlocationData(this.locationLatitude, this.locationLongitude);
        },
        async autoCompleteHandler() {
            /*** GENERATE SUGGESTIONS WHILE USER IS TYPING ***/
            if (this.searchBarValue == null || this.searchBarValue == undefined)
                return;
            let formatedInput = this.searchBarValue.replace(/\s/g, "+");
            let response = await fetch(
                "https://autocomplete.search.hereapi.com/v1/autocomplete?q=" +
                    formatedInput +
                    "&lang=en-US&apiKey=" +
                    this.apikey
            );
            let data = await response.json();
            this.searchSuggestions = data.items.map(item => {
                return item;
            });
        },
        async mapSearchHandler() {
            /*** RUN A QUERY FOR USER MANUAL/RAW INPUT ***/
            let response = await fetch(
                "https://geocode.search.hereapi.com/v1/geocode?q=" +
                    this.searchBarValue +
                    "&lang=en-US&apiKey=" +
                    this.apikey
            );
            let data = await response.json();
            this.map_center = data.items[0].position;
        },
        async getlocationData(lat, lng) {
            /*** GET COORDINATES FROM CLICK EVENT AND ASSIGN THEM TO CIRCLE POSITION ***/
            this.circle_center = [lat, lng];
            /*** GET INFO FROM API WITH THOSE COORDINATES ***/
            let response = await fetch(
                "https://revgeocode.search.hereapi.com/v1/revgeocode?at=" +
                    lat +
                    "%2C" +
                    lng +
                    "&lang=en-US&apiKey=" +
                    this.apikey
            );
            let data = await response.json();
            /*** ASSIGN RECEIVED DATA TO VARIABLES ***/
            this.locationCountry = data?.items[0]?.address?.countryName;
            this.locationCity = data?.items[0]?.address?.city;
            this.locationCode = data?.items[0]?.address?.postalCode;
            this.locationFullAddress = data?.items[0]?.address?.label;
            this.locationLatitude = lat;
            this.locationLongitude = lng;
        },
        resizeMap() {
            /*** THIS FUNCTION IS RESPONSIBLE FOR FIXING MAP RENDERING ISSUE(only 1 or 2 tiles in the corner), RUN IT ON THE RIGHT LIFECYCLE HOOK ***/
            setTimeout(() => {
                //mapObject is an object which gives you acess to leaflet methods
                this.$refs.map?.mapObject?.invalidateSize();
            }, 50);
        },
        debounce(timeout = 500) {
            clearTimeout(this.timeOutID);
            this.timeOutID = setTimeout(() => {
                this.autoCompleteHandler();
            }, timeout);
        },
        updateLocation() {
            this.isLoadingLocationEdit = true;
            let payload = {
                id: this.locationID,
                // tenantPartnerId: this.selectedPartner,
                locationType: "partner_location",
                isTrackerStockLocation: this.isTrackerStockLocation,
                name: this.locationName,
                city: this.locationCity,
                state: null,
                tags: this.tags,
                country: this.locationCountry,
                fullAddress: this.locationFullAddress,
                geometryType: null,
                latitude: this.locationLatitude,
                longitude: this.locationLongitude,
                radius: this.radius ? this.radius : 1000
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateLocation(
                            $input: UpdateTenantLocationInput!
                        ) {
                            updateTenantLocation(input: $input) {
                                location {
                                    id
                                    name
                                    locationType
                                    isTrackerStockLocation
                                    fullAddress
                                    tags
                                    city
                                    state
                                    country
                                    geometryType
                                    latitude
                                    longitude
                                    radius
                                    polygon
                                    locationType
                                    tenantPartner {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(() => {
                    this.isLoadingLocationEdit = false;
                    this.$emit("closeMap");
                    this.$emit("updateLocationsTable");
                });
        }
    }
};
</script>
<style scoped>
.maxSize {
    width: 100%;
    max-height: 1000px;
    min-height: 400px;
}
</style>
