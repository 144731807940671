<template>
    <LoadingBar v-if="tripDetails.id == null && $apollo.queries.trip.loading"></LoadingBar>
    <div v-else>
        <v-card flat v-if="tripDetails.id !== null" color="background">
            <!-- top bar -->
            <v-toolbar flat color="transparent">
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
                <v-btn v-if="shouldShowFullScreenButton" icon @click="changeFullScreen">
                    <v-icon>{{ isFullScreen ? "fullscreen_exit" : "crop_free" }}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <!-- button to complete or reopen trip -->
                <v-tooltip bottom v-if="!isSharedTrip">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-if="tripDetails.isPrivate" @click="privateDialog = true" v-bind="attrs" v-on="on">
                            <v-icon color="error">visibility_off</v-icon>
                        </v-btn>
                        <v-btn icon v-else @click="privateDialog = true" v-bind="attrs" v-on="on">
                            <v-icon>visibility</v-icon>
                        </v-btn>
                    </template>
                    <span v-if="!tripDetails.isPrivate">{{ $t("trips.hide") }}</span>
                    <span v-else>{{ $t("trips.show") }}</span>
                </v-tooltip>
                <!-- Edit trip -->
                <v-btn color="" @click="editTrip()" icon v-if="canEdit">
                    <v-icon>
                        edit
                    </v-icon>
                </v-btn>

                <v-menu offset-y transition="slide-x-reverse-transition" width="250px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="!isSharedTrip">
                            <!-- Add Template from trip -->
                            <v-btn plain class="pa-0 ml-2" @click="createTemplate()">
                                <v-icon class="mr-2">description</v-icon>
                                <span>{{ $t("templates.create_new_template") }}</span>
                            </v-btn>
                        </v-list-item>
                        <v-list-item v-if="!isSharedTrip">
                            <!-- Copy trip -->
                            <v-btn plain class="pa-0 ml-2" @click="copyTrip()">
                                <v-icon class="mr-2">file_copy</v-icon>
                                <span>{{ $t("trips.copy_trip") }}</span>
                            </v-btn>
                        </v-list-item>
                        <v-list-item>
                            <v-btn plain @click="favorite = !favorite" class="pa-0 ml-2">
                                <v-icon class="mr-2">
                                    {{ favorite ? "playlist_remove" : "star" }}
                                </v-icon>
                                <span>
                                    {{ favorite ? $t("general.unfavorite") : $t("general.favorites") }}
                                </span>
                            </v-btn>
                        </v-list-item>
                        <v-list-item v-if="!isSharedTrip">
                            <!-- Trip status -->
                            <v-btn @click="openDialog = true" class="pa-0 ml-2" color="" plain>
                                <span v-if="isTripCompleted">
                                    <v-img
                                        :src="require('@/assets/reopen_window.png')"
                                        width="20"
                                        class="float-left"
                                    ></v-img>
                                    <div class="pl-4 float-right">
                                        {{ $t("trips.reopen1") }}
                                    </div>
                                </span>
                                <span v-else>
                                    <v-icon>done</v-icon>
                                    {{ $t("trips.complete") }}
                                </span>
                            </v-btn>
                        </v-list-item>
                        <v-list-item>
                            <v-btn plain @click="shareTripts">
                                <v-icon left> share </v-icon>
                                <span>{{ $t("headers.share_trip") }}</span>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
            <v-spacer></v-spacer>
            <!-- trip header -->
            <v-sheet class="pt-0 mb-5 px-5 background d-flex align-center justify-space-between">
                <v-row dense>
                    <v-col cols="6" class="d-flex align-center justify-start">
                        <div class="mt-1 textLeft">
                            <div class="titleText">
                                {{ $t("headers.reference_number") }}
                            </div>
                            <div class="labelText">
                                {{ tripDetails ? tripDetails.referenceNumber : "" }}
                            </div>
                        </div>
                    </v-col>
                    <!-- <v-col cols="4" class="d-flex align-center justify-start">
                        <div class="mt-1 textRight">
                            <div class="titleText">
                                {{ $t("headers.status") }}
                            </div>
                            <div
                                class="font-weight-bold primaryText px-1 d-flex align-center justify-start"
                                :style="
                                    `
                                    background-color: ${
                                        statusColor(tripDetails.status).color1
                                    };	
                                    border-radius: 4px;
                                    height: 22px;
                                `
                                "
                            >
                                <v-icon
                                    left
                                    size="8"
                                    :color="
                                        statusColor(tripDetails.status).color2
                                    "
                                >
                                    circle
                                </v-icon>
                                <span class="text-capitalize">
                                    {{
                                        labelTag(
                                            tripDetails
                                                ? tripDetails.status
                                                : ""
                                        )
                                    }}
                                </span>
                            </div>
                        </div>
                    </v-col> -->
                </v-row>
            </v-sheet>
            <TripDetailTabView
                :trip="tripDetails"
                :tripIsLoading.sync="$apollo.queries.trip.loading"
                :selectedFilters="selectedFilters"
                :isSharedTrip="isSharedTrip"
                :organisationPermissions.sync="organisationPermissions"
                :defaultFilterChoices="defaultFilterChoices"
                @fullScreen="changeFullScreen"
                v-on:refresh-trip="$apollo.queries.trip.refetch()"
                :tripTimeRange.sync="tripTimeRange"
            />

            <v-dialog max-width="400" v-model="privateDialog">
                <v-card>
                    <v-card-title class="primary py-3 mb-2">
                        <div class="mr-2 mt-n1">
                            <v-icon color="white" v-if="tripDetails.isPrivate">visibility</v-icon>
                            <v-icon color="white" v-else>visibility_off</v-icon>
                        </div>
                        <span
                            ><h5 class="white--text">
                                {{ tripDetails.isPrivate ? this.$t("trips.show_trip") : this.$t("trips.hide_trip") }}
                            </h5></span
                        >
                    </v-card-title>
                    <v-card-text class="mb-1">
                        <div>
                            <span v-if="tripDetails.isPrivate">
                                <h4>
                                    {{ $t("trips.sure_show") }}
                                </h4>
                            </span>
                            <span v-else>
                                <h4>
                                    {{ $t("trips.sure_hide") }}
                                </h4>
                            </span>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="privateDialog = false" width="80px" text rounded small>{{
                            $t("general.no")
                        }}</v-btn>
                        <v-btn
                            width="80px"
                            color="primary"
                            rounded
                            small
                            @click="updatePrivate"
                            :loading="privateBtn"
                            >{{ $t("general.yes") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
        <!-- else show a v-card displaying that didnt find what looking for -->
        <v-card fill-height flat width="100%" v-else color="background">
            <v-toolbar flat color="transparent">
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
            </v-toolbar>
            <NotFoundDetailDrawer type="trip" style="padding-top: 100px;"> </NotFoundDetailDrawer>
        </v-card>
        <TripChangeStatusDialog
            :openDialog="openDialog"
            :trip="tripDetails"
            v-on:closeDialog="openDialog = false"
            v-on:updateTripStatus="onTripStatusChanged"
        >
        </TripChangeStatusDialog>
    </div>
</template>

<script>
import LoadingBar from "@/components/base/LoadingBar.vue";
import NotFoundDetailDrawer from "../base/NotFoundDetailDrawer.vue";
import TripDetailTabView from "@/components/trips/TripDetailTabViewNew.vue";
/* import TripDetailListView from "@/components/trips/TripDetailListView.vue"; */
import helper from "../../utils/helper";
import gql from "graphql-tag";
import TripChangeStatusDialog from "@/components/trips/update/TripChangeStatusDialog.vue";
import { TripPermissions } from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    props: {
        tripProp: {
            type: Object
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        tripId: {
            type: String
        }
    },
    components: {
        // FilterMenu,
        TripDetailTabView,
        /* TripDetailListView, */
        TripChangeStatusDialog,
        /*  TripForm,
        TripLocationForm, */
        LoadingBar,
        NotFoundDetailDrawer
    },
    apollo: {
        trip: {
            query: gql`
                query trip($tripId: ID!) {
                    trip(id: $tripId) {
                        id
                        createdAt
                        completedAt
                        trade
                        logco
                        loadPoint
                        loadDate
                        tenant {
                            id
                            organisationPermissions
                        }
                        tenantCarrier {
                            id
                            name
                            carrier {
                                id
                                name
                                website
                                code
                            }
                        }
                        originLocation {
                            id
                            name
                            city
                            country
                            latitude
                            longitude
                            radius
                        }
                        destinationLocation {
                            id
                            name
                            city
                            country
                            latitude
                            longitude
                            radius
                        }
                        referenceNumber
                        transportMode
                        plannedDepartureDate
                        plannedArrivalDate
                        status
                        isArchived
                        isPrivate
                        numberOfFreights
                        numberOfPartners
                        numberOfDocuments
                        numberOfComments
                        numberOfUnresolvedAlerts
                        tripPartnerSet {
                            edges {
                                node {
                                    id
                                    tenantPartner {
                                        id
                                        name
                                        partnerType
                                        referenceNumber
                                        notificationEnabled
                                        carrierType
                                        organisationPermissions
                                    }
                                }
                            }
                        }
                        tripFreightSet{
                            edges {
                                node {
                                    id
                                    carrierEvents
                                    referenceNumber
                                    orderNumber
                                    containerNumber
                                    airWaybillNumber
                                    billOfLadingNumber
                                    startDate
                                    endDate
                                    goods
                                    loadDate
                                    loadPoint
                                    term
                                    contact
                                    notes
                                    trackerLastConnectionTime
                                    trackerLastLocation
                                    additionalInfo
                                    tripFreightTrackerSet {
                                        edges {
                                            node {
                                                id
                                                name
                                                lowTemperature
                                                highTemperature
                                                lowHumidity
                                                highHumidity
                                                tracker {
                                                    id
                                                    serialNumber
                                                    activatedDate
                                                    model
                                                    brand
                                                    lastConnectionTime
                                                    lastBatteryDeviceTime
                                                    lastBatteryValue
                                                    lastTemperatureDeviceTime
                                                    lastTemperatureValue
                                                    lastExternalTemperatureDeviceTime
                                                    lastExternalTemperatureValue
                                                    lastProbeTemperatureDeviceTime
                                                    lastProbeTemperatureValue
                                                    lastHumidityDeviceTime
                                                    lastHumidityValue
                                                    lastLightDeviceTime
                                                    lastLightValue
                                                    lastShockDeviceTime
                                                    lastShockValue
                                                    lastLocationDeviceTime
                                                    lastLatitude
                                                    lastLongitude
                                                    lastLocationDescription
                                                }
                                            }
                                        }
                                    }
                                    tripFreightAlertRuleSet {
                                        edges {
                                            node {
                                                id
                                                tenantAlertRule {
                                                    id
                                                    name
                                                    alertSeverity
                                                    isEnabled
                                                    isStandardAlert
                                                    tenantAlertRuleTriggerSet {
                                                        edges {
                                                            node {
                                                                id
                                                                triggerJson
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        shipmentFileNumber
                        tripSeaTransportSet(orderBy: "sequence") {
                            edges {
                                node {
                                    id
                                    portCode
                                    portName
                                    sequence
                                    portTransportType
                                    vesselInputType
                                    vesselInputValue
                                    voyage
                                    vessel {
                                        id
                                        name
                                        lastRouteJson
                                    }
                                    portTimezone
                                    portTransportType
                                    vesselInputType
                                    vesselInputValue
                                    actualDepartureDate
                                    actualArrivalDate
                                    estimatedDepartureDate
                                    estimatedArrivalDate
                                    originalEstimatedDepartureDate
                                    originalEstimatedArrivalDate
                                    actualDepartureDateSource
                                    actualArrivalDateSource
                                    estimatedDepartureDateSource
                                    estimatedArrivalDateSource
                                    bookingConfirmationNumber
                                    cutOffDateTime
                                    vgmCutOffDate
                                }
                            }
                        }
                        isUserFavorite
                        tripDocumentSet(isReportDoc: false) {
                            edges {
                                node {
                                    id
                                    name
                                    size
                                    format
                                    url
                                    file
                                    description
                                }
                            }
                        }
                        tripReportSet(orderBy: "-createdAt") {
                            edges {
                                node {
                                    id
                                    createdAt
                                    isCompleted
                                    reportType
                                    sendToUsers
                                    sensorData
                                    startDate
                                    endDate
                                    generateImmediately
                                    document {
                                        id
                                        name
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    tripId: this.tripProp?.id || this.tripId,
                    isReportDoc: false
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trip,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading) {
                    this.tripLoading = loading;
                    this.getHeaderDates(data.trip);
                }
            }
        },
        tenantPartners: {
            query: gql`
                query TenantPartners($tenantId: ID, $tenantPartnerUserSetUserId: ID) {
                    tenantPartners(tenantId: $tenantId, tenantPartnerUserSet_UserId: $tenantPartnerUserSetUserId) {
                        edges {
                            node {
                                id
                                organisationPermissions
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.trip?.tenant?.id,
                    tenantPartnerUserSetUserId: this.me?.id
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantPartners,
            result({ loading, data }) {
                if (!loading) {
                    this.organisationPermissions = JSON.parse(
                        data?.tenantPartners?.edges[0]?.node.organisationPermissions
                    );
                }
            },
            skip() {
                return this.skipTenantPartners;
            }
        },
        trackerDeviceTimeRange: {
            query: gql`
                query trackerDeviceTimeRange($tripId: ID, $trackerId: ID) {
                    trackerDeviceTimeRange(tripId: $tripId, trackerId: $trackerId)
                }
            `,
            variables() {
                return {
                    tripId: this.trip ? this.trip.id : null,
                    trackerId: null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            result({ data }) {
                if (data?.trackerDeviceTimeRange) {
                    this.tripTimeRange = JSON.parse(data.trackerDeviceTimeRange);
                }
            },
            debounce: 0,
            skip() {
                return true;
            }
        }
    },
    data() {
        return {
            actionChoices: [
                { code: "hide", name: "Hide" },
                { code: "share", name: "Share" },
                { code: "copy", name: "Copy" },
                { code: "edit", name: "Edit" },
                { code: "template", name: "Save as Template" }
            ],
            tripTimeRange: [],
            validateTripLocation: false,
            editedMode: false,
            editTripDialog: false,
            validateTripDialog: false,
            privateDialog: false,
            ldBtn: false,
            privateBtn: false,
            private: false,
            getTrip: {},
            tripLocation: {},
            viewType: "tab",
            viewTypeChoices: [
                { code: "tab", name: this.$t("general.tabs") },
                { code: "list", name: this.$t("general.list") }
            ],
            openDialog: false,
            headerDates: {
                firstDateTitle: this.$t("trips.planned_departure"),
                firstDate: "-",
                secondDateTitle: this.$t("trips.planned_arrival"),
                secondDate: "-"
            },
            organisationPermissions: [],
            tripLoading: true
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        // this.me = helper.me;
    },
    computed: {
        me() {
            return helper.me();
        },
        loadingQueries() {
            return this.$apollo.queries.trip.loading;
        },
        skipTenantPartners() {
            if (this.isSharedTrip && !this.tripLoading) return false;
            return true;
        },
        hasTripDetails() {
            return this.tripProp?.id !== null || this.tripId !== null;
        },
        routeTab() {
            return this.$route.query.tab;
        },
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];

            //_filters = this.filterChoices.filter(filter => filter.enabled);
            _filters = this.defaultFilterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });

            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        canEdit() {
            return this.hasPermission(TripPermissions.EDIT_TRIP) && !this.isTripCompleted;
        },
        tripDetails() {
            let details = { ...this.tripProp, ...this.trip };
            let _trip = JSON.parse(JSON.stringify(details));
            return _trip || {};
        },
        favorite: {
            get() {
                return this.tripDetails.isUserFavorite || false;
            },
            set(value) {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation UpdateFavoriteTrip($input: UpdateFavoriteTripInput!) {
                                updateFavoriteTrip(input: $input) {
                                    trip {
                                        id
                                        isUserFavorite
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                favorite: value,
                                tripId: this.tripDetails.id
                            }
                        }
                    })
                    .then(({ data }) => {
                        // this.$emit("update:tripProp", {
                        //     ...this.tripProp,
                        //     ...data.updateFavoriteTrip.trip
                        // });
                        this.$emit("trip-updated", {
                            ...this.tripProp,
                            ...data.updateFavoriteTrip.trip
                        });
                    });
            }
        },
        pinned() {
            return this.tripDetails.isUserPinned || false;
        },
        isTripCompleted() {
            return this.tripDetails.status === "COMPLETED" || false;
        },
        defaultFilterChoices() {
            let _choices = [];
            let _default = [
                {
                    // for filter menu
                    code: "freights",
                    name: "Summary",
                    tag: "headers.summary",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "timeline",
                    name: "Timeline",
                    tag: "headers.timeline",
                    enabled: true
                },
                {
                    code: "alerts",
                    name: "Alerts",
                    tag: "headers.alerts",
                    enabled: true
                }
            ];

            _default.forEach(item => {
                _choices.push(item);
            });

            return _choices;
        },
        filterChoices: {
            get() {
                let _filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "tripDetailFilterChoices");
                _filterChoices.filter(c => {
                    if (c.code === "freights" && c.name.toLowerCase() === "freights") c.name = "Summary";
                });
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    tripDetailFilterChoices: value
                });
            }
        }
    },
    watch: {},
    created() {
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.formatDateTime = helper.formatDateTime;
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_details_svw"
        });
        this.setUserProperties();
    },
    methods: {
        editTrip() {
            //update vuex
            this.$store.dispatch("trip/updateCreatedTrip", {
                type: "update",
                trip: this.tripDetails
            });
            this.$router.push({
                path: "/newtrips",
                query: {
                    type: "edit"
                }
            });
        },
        copyTrip() {
            //update vuex
            this.$store.dispatch("trip/updateCreatedTrip", {
                type: "update",
                trip: this.tripDetails
            });
            this.$router.push({
                path: "/newtrips",
                query: {
                    type: "copy"
                }
            });
        },
        createTemplate() {
            //update vuex
            this.$store.dispatch("trip/updateCreatedTrip", {
                type: "update",
                trip: this.tripDetails
            });
            this.$router.push({
                path: "/newtrips",
                query: {
                    type: "template"
                }
            });
        },
        labelTag(label) {
            let tag = "";
            if (label !== null) {
                switch (label) {
                    case "ACTIVE":
                        tag = this.$t("general.active");
                        break;
                    case "COMPLETED":
                        tag = this.$t("general.completed");
                        break;
                    case "status":
                        tag = this.$t("headers.status");
                        break;
                    case "trip_number":
                        tag = this.$t("headers.trip_number");
                        break;
                    case "planned_departure":
                        tag = this.$t("headers.planned_departure");
                        break;
                    case "destination":
                        tag = this.$t("headers.destination");
                        break;
                    case "origin":
                        tag = this.$t("headers.origin");
                        break;
                    case "carrier":
                        tag = this.$t("headers.carrier");
                        break;
                    case "trips_alert":
                        tag = this.$t("headers.alerts");
                        break;
                    case "documents":
                        tag = this.$t("headers.documents");
                        break;
                    case "freights":
                        tag = this.$t("headers.freight_ref_number");
                        break;
                    case "order_number":
                        tag = this.$t("headers.order_number");
                        break;
                    case "container_number":
                        tag = this.$t("headers.container_number");
                        break;
                    case "airwaybill_number":
                        tag = this.$t("headers.airwaybill_number");
                        break;
                    case "bill_of_lading_number":
                        tag = this.$t("headers.bill");
                        break;
                    case "tracker":
                        tag = this.$t("headers.tracker");
                        break;
                    case "goods":
                        tag = this.$t("headers.goods");
                        break;
                    case "trips":
                        tag = this.$t("home.trips_preview");
                        break;
                    case "favorites":
                        tag = this.$t("home.trips_favorite");
                        break;
                }
            }
            return tag;
        },
        statusColor(item) {
            let color = "";
            if (item !== null) {
                switch (item) {
                    case "ACTIVE":
                        color = {
                            color1: "#C8E6C9",
                            color2: "#1B5E20"
                        };
                        break;
                    case "COMPLETED":
                        color = {
                            color1: "#E0E0E0",
                            color2: "#616161"
                        };
                        break;
                    default:
                        color = {
                            color1: "#FFE0B2",
                            color2: "#FF6D00"
                        };
                        break;
                }
            }
            return color;
        },
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_details_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_details_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        shareTripts() {
            let url = window.location.href;
            let dummy = document.createElement("input"),
                text = url;
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            const payload = {
                color: "blue",
                message: this.$t("general.link")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        getHeaderDates(trip) {
            if (trip?.plannedDepartureDate) {
                this.headerDates.firstDate = this.formatDateTime(trip?.plannedDepartureDate);
            }

            if (trip?.plannedArrivalDate) {
                this.headerDates.secondDate = this.formatDateTime(trip?.plannedArrivalDate);
            }

            if (trip?.transportMode == "SEA") {
                let tripSeaTransportSet = [...trip?.tripSeaTransportSet?.edges];

                tripSeaTransportSet.sort((a, b) => a.node.sequence - b.node.sequence);
                tripSeaTransportSet?.forEach(edge => {
                    if (edge.node.portTransportType === "POL") {
                        if (
                            edge.node.estimatedDepartureDate &&
                            (trip?.plannedDepartureDate === null || trip?.plannedDepartureDate === "")
                        ) {
                            this.headerDates.firstDateTitle = this.$t("trips.estimated_departure");
                            this.headerDates.firstDate = this.formatDateTime(edge.node.estimatedDepartureDate);
                        }
                        if (edge.node.actualDepartureDate) {
                            this.headerDates.firstDateTitle = this.$t("trips.actual_departure");
                            this.headerDates.firstDate = this.formatDateTime(edge.node.actualDepartureDate);
                        }
                    }
                    if (edge.node.portTransportType === "POD") {
                        if (
                            edge.node.estimatedArrivalDate &&
                            (trip?.plannedArrivalDate === null || trip?.plannedArrivalDate === "")
                        ) {
                            this.headerDates.secondDateTitle = this.$t("trips.estimated_arrival");
                            this.headerDates.secondDate = this.formatDateTime(edge.node.estimatedArrivalDate);
                        }
                        if (edge.node.actualArrivalDate) {
                            this.headerDates.secondDateTitle = this.$t("trips.actual_arrival");
                            this.headerDates.secondDate = this.formatDateTime(edge.node.actualArrivalDate);
                        }
                    }
                });
            } else if (trip?.transportMode == "AIR") {
                // TODO
            } else if (trip?.transportMode == "RAIL") {
                // TODO
            } else if (trip?.transportMode == "ROAD") {
                // TODO
            } else if (trip?.transportMode == "OTHER") {
                // TODO
            }
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updatePinned() {
            this.pinned = !this.pinned;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdatePinnedTrip($input: UpdatePinnedTripInput!) {
                            updatePinnedTrip(input: $input) {
                                trip {
                                    id
                                    isUserPinned
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            pinned: this.pinned,
                            tripId: this.trip.id
                        }
                    }
                })
                .then()
                .catch(error => {
                    console.error(error);
                });
        },
        onTripStatusChanged(trip) {
            let _trip = { ...this.tripProp, ...trip };
            // this.$emit("update:tripProp", _trip);
            this.$emit("trip-updated", _trip);
        },
        editTripDetails() {
            let _trip = this.tripDetails;
            this.editTripDialog = true;
            this.editedMode = true;

            this.getTrip = {
                id: _trip.id,
                referenceNumber: _trip.referenceNumber,
                transportMode: _trip.transportMode.toLowerCase(),
                shipmentFileNumber: _trip.shipmentFileNumber,
                carrier: _trip.tenantCarrier ? _trip.tenantCarrier.id : null
            };

            this.tripLocation = {
                plannedArrivalDate: _trip.plannedArrivalDate ? this.getDate(_trip.plannedArrivalDate) : null,
                plannedArrivalTime: _trip.plannedArrivalDate ? this.getTime(_trip.plannedArrivalDate) : null,
                plannedDepartureDate: _trip.plannedDepartureDate ? this.getDate(_trip.plannedDepartureDate) : null,
                plannedDepartureTime: _trip.plannedDepartureDate ? this.getTime(_trip.plannedDepartureDate) : null,
                origin: _trip.originLocation ? _trip.originLocation.id : null,
                destination: _trip.destinationLocation ? _trip.destinationLocation.id : null
            };
        },
        updateTrip(trip) {
            // this.$emit("update:tripProp", trip);
            this.$emit("trip-updated", trip);
            this.tripLoading = true;
            this.$apollo.queries.trip.refetch();
        },
        updatePrivate() {
            let payload = {
                tripId: this.tripDetails.id,
                isPrivate: this.tripDetails.isPrivate ? false : true
            };
            this.privateBtn = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdatePrivateTrip($input: UpdatePrivateTripInput!) {
                            updatePrivateTrip(input: $input) {
                                trip {
                                    id
                                    isPrivate
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(response => {
                    let _trip = {
                        ...this.trip,
                        ...response.data.updatePrivateTrip.trip
                    };
                    // this.$emit("update:tripProp", _trip);
                    this.$emit("trip-updated", _trip);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.privateDialog = false;
                    this.privateBtn = false;
                });
        },
        getTime(date) {
            let _time = this.formatDateTime(date)?.slice(11, 16);
            return _time;
        },
        getDate(date) {
            let _date = this.formatDateTime(date)?.slice(0, 10);
            return _date;
        }
    }
};
</script>

<style scoped>
.custom-height {
    height: 35px;
}

.v-input--selection-controls {
    margin-top: 0;
}
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}

.labelText {
    font-size: 32px;
    font-weight: 700;
    color: #0e0d35;
    line-height: 38.4px;
}

.titleText {
    font-size: 12px;
    font-weight: 700;
    color: #555464;
    line-height: 14.4px;
}
</style>
