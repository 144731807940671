<template>
    <div :key="trip.id">
        <!-- tabs -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="transparent"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                <span
                    v-if="
                        trip.transportMode === 'SEA' &&
                            t.code === 'seaTransport'
                    "
                    >{{ t.name }}</span
                >
                <span v-if="t.code !== 'seaTransport'">{{ t.name }}</span>
            </v-tab>
            <!-- TAB FILTERS -->
            <FilterMenu
                title="Customize"
                description="Select modules to display"
                :choices.sync="filterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
                class="mt-1"
            ></FilterMenu>
        </v-tabs>

        <v-divider></v-divider>

        <!-- tab content -->
        <v-tabs-items
            v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <v-tab-item value="tab-freights" :transition="false">
                <TripFreights
                    :trip="trip"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripFreights>
            </v-tab-item>
            <!-- <v-tab-item value="tab-trackerscharts" :transition="false">
                <TripTrackers 
                    :trip="trip" 
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripTrackers>
            </v-tab-item> -->
            <v-tab-item value="tab-carrierEvents" :transition="false">
                <TripCarrierEvents
                    :trip="trip"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripCarrierEvents>
            </v-tab-item>
            <v-tab-item value="tab-partners" :transition="false">
                <TripPartners
                    :trip="trip"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripPartners>
            </v-tab-item>
            <v-tab-item value="tab-documents" :transition="false">
                <TripDocuments
                    :trip="trip"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripDocuments>
            </v-tab-item>
            <v-tab-item value="tab-alerts" :transition="false">
                <TripAlerts
                    :trip="trip"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripAlerts>
            </v-tab-item>
            <v-tab-item value="tab-comments" :transition="false">
                <TripComments
                    :trip="trip"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripComments>
            </v-tab-item>
            <v-tab-item value="tab-map" :transition="false">
                <TripMap
                    :trip="trip"
                    viewType="tab"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripMap>
            </v-tab-item>
            <v-tab-item value="tab-timeline" :transition="false">
                <TripTimeline
                    :trip="trip"
                    viewType="tab"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripTimeline>
            </v-tab-item>
            <v-tab-item
                v-if="trip.transportMode === 'SEA'"
                value="tab-seaTransport"
                :transition="false"
            >
                <TripSeaTransport
                    :trip="trip"
                    viewType="tab"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripSeaTransport>
            </v-tab-item>
            <v-tab-item value="tab-reports" :transition="false">
                <TripReports
                    :trip="trip"
                    viewType="tab"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripReports>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import TripFreights from "@/components/trips/details/TripFreights.vue";
import TripCarrierEvents from "@/components/trips/details/TripCarrierEvents.vue";
import TripAlerts from "@/components/trips/details/TripAlerts.vue";
import TripComments from "@/components/trips/details/TripComments.vue";
import TripDocuments from "@/components/trips/details/TripDocuments.vue";
import TripPartners from "@/components/trips/details/TripPartners.vue";
import TripMap from "@/components/trips/details/TripMap.vue";
// import TripTrackers from "@/components/trips/details/TripTrackers.vue";
import TripSeaTransport from "@/components/trips/details/TripSeaTransport.vue";
import TripTimeline from "@/components/trips/details/TripTimeline.vue";
import TripReports from "@/components/trips/details/TripReports.vue";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        // selectedFilters: {
        //     type: Array,
        //     required: true
        // },
        defaultFilterChoices: {
            type: Array,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    components: {
        TripFreights,
        TripCarrierEvents,
        TripAlerts,
        TripComments,
        TripDocuments,
        TripPartners,
        TripMap,
        // TripTrackers,
        TripSeaTransport,
        TripTimeline,
        TripReports,
        FilterMenu
    },
    data() {
        return {
            tab: null
        };
    },
    methods : {
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
    },
    computed: {
        routeTab() {
            return this.$route.query.tab;
        },
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        },
        
        filterChoices: {
            get() {
                let _filterChoices = helper.getFilterChoices(
                    this.defaultFilterChoices,
                    "tripDetailFilterChoices"
                );
                _filterChoices.filter(c => {
                    if (
                        c.code === "freights" &&
                        c.name.toLowerCase() === "freights"
                    )
                        c.name = "Summary";
                });
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    tripDetailFilterChoices: value
                });
            }
        },
        selectedFilters() {
            let _filters = [];

            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        },
        tab(val) {
            if (val) {
                if (this.routeTab === val.replace("tab-", "")) return;
                this.$router.push({
                    name: this.$route.name,
                    query: {
                        id: this.trip.id,
                        tab: val.replace("tab-", ""),
                        freight: this.$route.query.freight
                    }
                });
            }
        },
        routeTab: {
            immediate: true,
            handler() {
                if (this.routeTab) {
                    //check if tab exists
                    if (
                        this.selectedFilters.find(
                            f => f.code === this.routeTab
                        ) === undefined
                    ) {
                        try {
                            let firstTab = this.selectedFilters[0].code;
                            this.$router.push({
                                name: this.$route.name,
                                query: {
                                    id: this.trip.id,
                                    tab: firstTab,
                                    freight: this.$route.query.freight
                                }
                            });
                        } catch {
                            //do nothing
                        }
                    } else this.tab = "tab-" + this.routeTab;
                }
            }
        }
    },
    mounted() {}
};
</script>
