y<template>
    <v-dialog max-width="600" v-model="openEditUserDialog" persistent>
        <v-card class="pb-4">
            <!--- ICON AND TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("partners.edit_user") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-4">
                <v-form ref="editUserForm">
                    <!--- NAME --->
                    <v-col cols="12" class="pa-0 mt-6">
                        <v-text-field
                            dense
                            v-model="user.firstName"
                            :label="$t('headers.first_name') + '*'"
                            outlined
                            class="mx-6"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>

                    <!--- LAST NAME --->
                    <v-col cols="12" class="pa-0">
                        <v-text-field
                            dense
                            v-model="user.lastName"
                            :label="$t('headers.last_name') + '*'"
                            outlined
                            class="mx-6"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>

                    <!--- EMAIL --->
                    <v-col cols="12" class="pa-0">
                        <v-text-field
                            label="Email*"
                            dense
                            v-model="user.email"
                            :rules="[rules.required, rules.invalidEmail]"
                            outlined
                            class="mx-6"
                        ></v-text-field>
                    </v-col>
                    <v-row class="mx-6">
                        <!--- NOTIFICATION CHECKBOX --->
                        <v-checkbox
                            v-model="user.isTenantAdmin"
                            :label="$t('general.administrator')"
                            hide-details
                        ></v-checkbox>
                    </v-row>
                    <v-row class="mx-6">
                        <!--- 2FA CHECKBOX --->
                        <v-checkbox
                            v-model="user.require2fa"
                            :label="$t('headers.2fa')"
                            hide-details
                        ></v-checkbox>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTION BUTTONS --->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    :loading="isLoadingEditUser"
                    @click="editUser"
                    color="primary"
                    rounded
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "create-user-dialog",
    props: {
        openEditUserDialog: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    data() {
        return {
            isLoadingEditUser: false,
            user: {
                id: null,
                firstName: null,
                lastName: null,
                email: null,
                isTenantAdmin: false,
                require2fa: false
            },
            /* Rules */
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v =>
                    /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.me = helper.me();
    },
    computed: {},
    methods: {
        initializeUser(value) {
            this.user = {
                id: value.node.id ?? value.node.user?.tenantUser?.id,
                firstName: value.node.user.firstName,
                lastName: value.node.user.lastName,
                email: value.node.user.email,
                isTenantAdmin: value.node.user.isTenantAdmin,
                require2fa: value.node.user.require2fa
            };
        },
        closeAndResetInput() {
            this.user = {
                id: null,
                firstName: null,
                lastName: null,
                email: null,
                isTenantAdmin: false,
                require2fa: false
            };
            this.$refs.editUserForm.resetValidation();
            this.$emit("closeEditUserDialog");
        },
        editUser() {
            // validate form input :
            if (!this.$refs.editUserForm.validate()) return;
            // Initialize Loading :
            this.isLoadingEditUser = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation Mutation($input: UpdateTenantUserInput!) {
                            updateTenantUser(input: $input) {
                                tenantUser {
                                    id
                                    isAdmin
                                    user {
                                        id
                                        firstName
                                        lastName
                                        fullName
                                        email
                                        isTenantAdmin
                                        lastLogin
                                        require2fa
                                        allTenantUserGroups {
                                            totalCount
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...this.user
                        }
                    }
                })
                .then(response => {
                    // Emit event to signal user is added and reload table showing users //
                    this.$emit(
                        "refreshUpdatedUser",
                        response.data.updateTenantUser.tenantUser
                    );
                    // Close the dialog and reset form fields //
                    this.closeAndResetInput();
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.isLoadingEditUser = false;
                });
        }
    }
};
</script>
