<template>
    <v-hover>
        <template v-slot="{ hover }">
            <!---- TOOLBAR LEFT SIDE ---->
            <v-card class="py-5 transparent" outlined>
                <v-row dense align="start" class="d-flex align-center py-0">
                    <v-col class="py-0">
                        <SearchBar v-model="search" :label="label">
                            <template v-slot:append-outer>
                                <v-menu
                                    v-if="!isSmallScreen"
                                    ref="menu"
                                    v-model="menu"
                                    :nudge-width="300"
                                    :nudge-left="dynamicNudge"
                                    :offset-x="true"
                                    :offset-y="true"
                                    bottom
                                    content-class="classContent"
                                    :close-on-click="true"
                                    :close-on-content-click="false"
                                    attach
                                    nudge-top="-10"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            x-large
                                            @click="openFilterMenu"
                                            style="
                                                height: 53px;
                                                margin-top: -1em;
                                            "
                                        >
                                            <v-icon>
                                                filter_alt
                                            </v-icon>
                                            <v-spacer></v-spacer>
                                            {{ $t("trips.filter") }}
                                            <v-spacer></v-spacer>
                                        </v-btn>
                                    </template>
                                    <v-card class="px-5 py-5" height="100%" width="100%">
                                        <v-card-text>
                                            <v-row align="start" class="px-5 align-center">
                                                <v-col
                                                    v-for="f in dynamicFilters"
                                                    :key="f.code"
                                                    v-col="fCol.xs"
                                                    :xs="fCol.xs"
                                                    :sm="fCol.sm"
                                                    :md="fCol.md"
                                                    :lg="fCol.lg"
                                                    :xl="fCol.xl"
                                                >
                                                    <!---- LOCATION NAME ---->
                                                    <v-text-field
                                                        v-if="f.code === 'locationName'"
                                                        v-model="customFilter.nameIcontains"
                                                        :label="$t('headers.location_name')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>
                                                    <!---- CITY ---->
                                                    <v-text-field
                                                        v-if="f.code === 'city'"
                                                        v-model="customFilter.cityIcontains"
                                                        :label="$t('partners.city')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>
                                                    <!---- COUNTRY ---->
                                                    <v-text-field
                                                        v-if="f.code === 'country'"
                                                        v-model="customFilter.countryIcontains"
                                                        :label="$t('partners.country')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>
                                                    <!---- PUBLIC ---->
                                                    <v-autocomplete
                                                        v-if="f.code === 'tags'"
                                                        v-model="customFilter.tagsIcontains"
                                                        :items="defaulttagsChoices"
                                                        :label="$t('headers.tags')"
                                                        item-text="text"
                                                        item-value="value"
                                                        :return-object="false"
                                                        small-chips
                                                        deletable-chips
                                                        hide-details
                                                        dense
                                                    ></v-autocomplete>

                                                    <v-text-field
                                                        v-if="f.code === 'coloboratorName'"
                                                        v-model="customFilter.tenantPartnerNameIcontains"
                                                        :label="$t('headers.collaborator_name')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>

                                                    <v-select
                                                        v-if="f.code === 'coloboratorType'"
                                                        v-model="customFilter.tenantPartnerPartnerTypeIexact"
                                                        :items="partnersTypes"
                                                        item-text="text"
                                                        item-value="value"
                                                        :label="$t('headers.collaborator_type1')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-select>
                                                    <v-switch
                                                        v-if="f.code === 'warning'"
                                                        v-model="customFilter.warningCities"
                                                        :label="$t('general.warning')"
                                                        class="text-no-wrap"
                                                        color="primary"
                                                        dense
                                                    ></v-switch>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter">
                                                Cancel
                                            </v-btn>
                                            <v-btn color="primary" @click="searchFields(customFilter)">
                                                Search
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </template>
                            <template v-slot:content-below>
                                <v-row dense class="mt-1" v-if="filterIsSet">
                                    <v-col cols="auto" v-for="i in filterValues" :key="i.id">
                                        <v-chip
                                            label
                                            close
                                            small
                                            class="elevation-0 text-capitalize"
                                            @click:close="clearFilterOption(i.id)"
                                        >
                                            <span v-if="i.id == ''"></span>
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </template>
                        </SearchBar>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import SearchBar from "@/components/base/SearchBar.vue";

export default {
    components: { SearchBar },
    props: {
        selectedLocations: {
            type: Object
        },
        label: {
            type: String,
            default: "Search"
        },
        tab: {
            type: String,
            default: "locations"
        },
        searchInput: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            fab: false,
            isLoading: false,
            menu: false,
            resizeListener: null,
            dateRange: [],
            savedDateRange: [],
            sortAsc: true,
            shouldStickyFilter: false,
            openAddLocation: false,
            tags: [],
            customFilter: {},
            filterValues: [],
            partnersTypes: [
                {
                    text: this.$t("headers.consignor"),
                    value: "consignor"
                },
                {
                    text: this.$t("headers.consignee"),
                    value: "consignee"
                },
                {
                    text: this.$t("headers.forwarder"),
                    value: "forwarder"
                },
                {
                    text: this.$t("home.other"),
                    value: "other"
                }
            ],
            publicLocationsTypes: [
                {
                    text: this.$t("headers.airport"),
                    value: "air_port"
                },
                {
                    text: this.$t("headers.sea_port"),
                    value: "sea_port"
                }
            ],
            defaulttagsChoices: [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ],
            filterIsSet: false,
            filterChoices: null,
            sortBy: null,
            filter: {
                first: 15,
                after: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                cityIcontains: null,
                countryIcontains: null
            },
            weatherFilter: {
                first: 15,
                after: null,
                cityIcontains: null,
                countryIcontains: null
            },
            myFilter: {
                first: 15,
                after: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                cityIcontains: null,
                countryIcontains: null
            },
            partnerFilter: {
                first: 15,
                after: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                cityIcontains: null,
                countryIcontains: null
            },
            publicFilter: {
                first: 15,
                after: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                cityIcontains: null,
                countryIcontains: null
            }
        };
    },
    beforeCreate() {
        this.me = helper.me();
    },
    computed: {
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },
        nudgeLeft() {
            let size = this.$vuetify.breakpoint.width ? this.$vuetify.breakpoint.width : window.innerWidth;
            if (size < 600) {
                return 0;
            } else if (size < 960) {
                return 410;
            } else {
                return 610;
            }
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 600;
        },
        defaultFilterChoices() {
            let _default = [];
            // default filter choices
            let choices = [
                {
                    code: "locationName",
                    name: "Location Name",
                    tag: "headers.location_name",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "city",
                    name: "City",
                    tag: "headers.city",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "country",
                    name: "Country",
                    tag: "headers.country",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "scope",
                    name: "Scope",
                    tag: "headers.scope",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "tags",
                    name: "Tags",
                    tag: "headers.tags",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                /* partner name */
                {
                    code: "coloboratorName",
                    name: "Coloborator Name",
                    tag: "headers.coloborator_name",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                /* partner type */
                {
                    code: "coloboratorType",
                    name: "Coloborator Type",
                    tag: "headers.coloborator_type",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "warning",
                    name: "Warning Cities",
                    tag: "general.warning",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                }
            ];

            //filter choices based on the tab
            switch (this.tab) {
                case "myLocations":
                    _default = choices.filter(item => {
                        return (
                            item.code !== "coloboratorName" &&
                            item.code !== "coloboratorType" &&
                            item.code !== "stockLocation" &&
                            item.code !== "warning" &&
                            item.code !== "scope"
                        );
                    });
                    break;
                case "partnerLocations":
                    _default = choices.filter(item => {
                        return item.code !== "tags" && item.code !== "warning" && item.code !== "scope";
                    });
                    break;
                case "publicLocations":
                    _default = choices.filter(item => {
                        return (
                            item.code !== "coloboratorName" &&
                            item.code !== "coloboratorType" &&
                            item.code !== "stockLocation" &&
                            item.code !== "tags" &&
                            item.code !== "scope" &&
                            item.code !== "warning"
                        );
                    });
                    break;
                case "weather":
                    _default = choices.filter(item => {
                        return (
                            item.code !== "coloboratorName" &&
                            item.code !== "coloboratorType" &&
                            item.code !== "stockLocation" &&
                            item.code !== "tags" &&
                            item.code !== "warning"
                        );
                    });
                    break;
            }
            return _default;
        },
        dynamicFilters() {
            let _filter = this.defaultFilterChoices.map(item => {
                return item;
            });

            return _filter;
        },
        search: {
            get() {
                let _search = this.searchInput || "";
                return _search;
            },
            set(val) {
                this.$emit("update:searchInput", val);
            }
        },
        fCol() {
            let col = {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
                xl: 0
            };

            switch (this.tab) {
                case "myLocations":
                    col.xs = 12;
                    col.sm = 12;
                    col.md = 4;
                    col.lg = 3;
                    col.xl = 3;
                    break;
                case "partnerLocations":
                    col.xs = 12;
                    col.sm = 12;
                    col.md = 4;
                    col.lg = 3;
                    col.xl = 3;
                    break;
                case "publicLocations":
                    col.xs = 12;
                    col.sm = 12;
                    col.md = 4;
                    col.lg = 4;
                    col.xl = 4;
                    break;
                case "weather":
                    col.xs = 12;
                    col.sm = 12;
                    col.md = 4;
                    col.lg = 4;
                    col.xl = 4;
                    break;
            }
            return col;
        },
        dynamicNudge() {
            let nudge = 0;

            let mobileBreakpoint = this.$vuetify.breakpoint.width ? this.$vuetify.breakpoint.width : window.innerWidth;

            switch (this.tab) {
                case "myLocations":
                    if (mobileBreakpoint < 960) {
                        nudge = 400;
                    } else {
                        nudge = 800;
                    }

                    break;
                case "partnerLocations":
                    if (mobileBreakpoint < 960) {
                        nudge = 400;
                    } else {
                        nudge = 800;
                    }

                    break;
                case "publicLocations":
                    if (mobileBreakpoint < 960) {
                        nudge = 300;
                    } else {
                        nudge = 600;
                    }
                    break;
                case "weather":
                    if (mobileBreakpoint < 960) {
                        nudge = 300;
                    } else {
                        nudge = 600;
                    }

                    break;
            }
            return nudge;
        }
    },
    watch: {
        filter: {
            handler(val) {
                //update filter state values
                this.filterValues = this.showFilterValues(val);
                this.filterIsSet = this.filterValues.length > 0;
                this.$emit("filter-changed", JSON.parse(JSON.stringify(val)));
                if (this.tab) {
                    helper.setFilterValues(`${this.tab}TableFilters`, val);
                }
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.$nextTick(() => {
            this.populateFilterValues();
        });
    },
    methods: {
        populateFilterValues() {
            let _filter = {};

            _filter = helper.getFilterValues(`${this.tab}TableFilters`);

            if (_filter && Object.keys(_filter).length > 0) {
                switch (this.tab) {
                    case "myLocations":
                        Object.keys(this.myFilter).forEach(key => {
                            if (_filter[key]) {
                                this.myFilter[key] = _filter[key];
                            }
                        });

                        this.filter = { ...this.myFilter };

                        break;
                    case "partnerLocations":
                        Object.keys(this.partnerFilter).forEach(key => {
                            if (_filter[key]) {
                                this.partnerFilter[key] = _filter[key];
                            }
                        });

                        this.filter = { ...this.partnerFilter };

                        break;
                    case "publicLocations":
                        Object.keys(this.publicFilter).forEach(key => {
                            if (_filter[key]) {
                                this.publicFilter[key] = _filter[key];
                            }
                        });

                        this.filter = { ...this.publicFilter };

                        break;
                    case "weather":
                        Object.keys(this.weatherFilter).forEach(key => {
                            if (_filter[key]) {
                                this.weatherFilter[key] = _filter[key];
                            }
                        });

                        this.filter = { ...this.weatherFilter };
                        break;
                }
            } else {
                switch (this.tab) {
                    case "myLocations":
                        _filter = this.myFilter;
                        break;
                    case "partnerLocations":
                        _filter = this.partnerFilter;
                        break;
                    case "publicLocations":
                        _filter = this.publicFilter;
                        break;
                    case "weather":
                        _filter = this.weatherFilter;
                        break;
                }

                this.filter = _filter;
            }
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        },
        onChangePublicLocationType(value) {
            this.$emit("changePublicLocationType", value);
        },
        showFilterValues(filterValues) {
            if (!filterValues) return [];

            // replace filter values to show in the filter chip component in the search bar
            const replaceName = params => {
                let _params = params;
                if (params === "nameIcontains") {
                    _params = this.$t("headers.location_name");
                }
                if (params === "tenantPartnerNameIcontains") {
                    _params = this.$t("headers.partner_name");
                }
                if (params === "tenantPartnerPartnerTypeIexact") {
                    _params = this.$t("headers.partner_type");
                }
                if (params === "locationType_In") {
                    _params = this.$t("headers.scope");
                }
                if (params === "tagsIcontains") {
                    _params = this.$t("headers.tags");
                }
                if (params === "cityIcontains") {
                    _params = this.$t("partners.city");
                }
                if (params === "countryIcontains") {
                    _params = this.$t("partners.country");
                }

                return _params;
            };

            // filter values to show in the filter chip component in the search bar
            let _filter = Object.keys(filterValues).reduce((acc, key) => {
                if (filterValues[key] && key !== "first" && key !== "after" && key !== "locationType_In") {
                    acc.push({
                        id: key,
                        name: replaceName(key),
                        value:
                            key != "tagsIcontains"
                                ? filterValues[key]
                                : this.defaulttagsChoices.find(({ value }) => value === filterValues[key]).text
                    });
                }
                return acc;
            }, []);
            return _filter;
        },
        clearFilterOption(key) {
            // clear filter option from the filter chip component in the search bar
            let _filter = this.filter;
            _filter[key] = null;

            //update filter values
            this.filter = _filter;
        },
        searchFields(filter) {
            // search fields from the filter chip component in the search bar
            this.menu = false;

            let _filter = Object.keys(this.filter).reduce((acc, key) => {
                acc[key] = filter[key];
                return acc;
            }, {});

            //update filter values
            this.filter = _filter;
            //force filter
        },
        openFilterMenu() {
            // open filter menu from the filter chip component in the search bar
            this.menu = true;
            this.customFilter = { ...this.filter };
        },
        cancelFilter() {
            // cancel filter from the filter chip component in the search bar
            this.customFilter = {};
            this.menu = false;
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
.classContent {
    max-width: 1095px;
}
/* .v-menu__content {
    left: 610px !important;
    top: 60px !important;
} */
</style>
