import { render, staticRenderFns } from "./StaffUserDetail.vue?vue&type=template&id=6880b49c&scoped=true&"
import script from "./StaffUserDetail.vue?vue&type=script&lang=js&"
export * from "./StaffUserDetail.vue?vue&type=script&lang=js&"
import style0 from "./StaffUserDetail.vue?vue&type=style&index=0&id=6880b49c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6880b49c",
  null
  
)

export default component.exports