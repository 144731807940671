<template>
    <v-card fill-height flat width="100%" v-if="user" color="background">
        <v-toolbar flat color="background">
            <!----- TOP LEFT ICONS ---->
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn
                v-if="shouldShowFullScreenButton"
                icon
                @click="changeFullScreen"
            >
                <v-icon>{{
                    isFullScreen ? "fullscreen_exit" : "crop_free"
                }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <!---- TOP RIGHT ICONS ---->
            <v-btn icon>
                <v-icon @click="editUserDialogHandler(user)"> edit </v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon @click="openDeleteUserDialog = true"> delete </v-icon>
            </v-btn>

            <!---- EDIT PARTNER DIALOG ---->
            <EditUserDialog
                ref="editUserDialog"
                :showUserSelect="true"
                :openEditUserDialog="openEditUserDialog"
                v-on:refreshUpdatedUser="updateUserValues"
                v-on:closeEditUserDialog="openEditUserDialog = false"
            ></EditUserDialog>

            <!---- DELETE PARTNER DIALOG ---->
            <DeleteUserDialog
                :openDeleteUserDialog="openDeleteUserDialog"
                :userId="[user.id]"
                v-on:refreshDataTable="closeDrawerAndRefresh"
                v-on:closeDeleteDialog="openDeleteUserDialog = false"
            >
            </DeleteUserDialog>

            <!---- FILTER DIALOG ---->
            <FilterMenu
                :title="$t('dashboard.customize')"
                :description="$t('dashboard.display_tabs')"
                :choices="filterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
                class="mr-n2"
            >
            </FilterMenu>
        </v-toolbar>

        <v-card-title class="pt-0 px-5">
            <!---- USER GENERAL INFO DISPLAY (NAME, USERS...) ---->
            <v-row class="d-flex align-center pt-2 mx-2">
                <!---- NAME ---->
                <v-col cols="6" sm="3" class="py-0 px-0 mb-1">
                    <span class="text-caption">{{ $t("headers.name") }}</span
                    ><br />
                    <span class="font-weight-medium">
                        {{ updatedUser ? updatedUser.fullName : user.fullName }}
                    </span>
                </v-col>
                <!---- USERS ---->
                <v-col cols="6" sm="3" class="py-0 px-0 mb-1">
                    <span class="text-caption">{{ $t("general.ngroups") }}</span
                    ><br />
                    <span class="font-weight-medium">
                        {{ user.groups.totalCount }}
                    </span>
                </v-col>
                <v-spacer></v-spacer>

                <!---- SELECT TAB OR LIST VIEW ---->
                <v-col cols="4" sm="3" class="d-flex justify-end">
                    <v-select
                        v-model="view"
                        :items="viewChoices"
                        dense
                        item-text="name"
                        item-value="code"
                        return-value
                        outlined
                        hide-details
                        class="fit"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-title>

        <!---- tab view ---->
        <StaffUserDetailTabView
            v-if="view === 'tab'"
            :user="user"
            :selectedFilters="selectedFilters"
        />

        <!---- list view ---->
        <StaffUserDetailListView
            v-if="view === 'list'"
            class="mx-5 pb-10"
            :user="user"
            :selectedFilters="selectedFilters"
        />
    </v-card>
</template>
<script>
import helper from "@/utils/helper";
import FilterMenu from "@/components/base/FilterMenu.vue";
import StaffUserDetailTabView from "@/components/staff/users/StaffUserDetailTabView.vue";
import StaffUserDetailListView from "@/components/staff/users/StaffUserDetailListView.vue";
import DeleteUserDialog from "@/components/staff/users/delete/DeleteUserDialog.vue";
import EditUserDialog from "@/components/staff/users/update/EditUserDialog.vue";

export default {
    name: "StaffUserDetail",
    props: {
        user: {
            type: Object,
            required: true
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        StaffUserDetailTabView,
        StaffUserDetailListView,
        FilterMenu,
        DeleteUserDialog,
        EditUserDialog
    },
    data() {
        return {
            updatedUser: null,

            openDeleteUserDialog: false,
            openEditUserDialog: false,

            view: "tab",
            viewChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "groups",
                    name: "Groups",
                    tag: "home.groups",
                    enabled: true,
                    order: 1
                },
                {
                    // for filter menu
                    code: "loginHistory",
                    name: "Login History",
                    tag: "headers.login_history",
                    enabled: true,
                    order: 2
                }
            ],
            filterChoices: []
        };
    },
    created() {
        this.viewType = helper.getViewType("userDetailViewType");
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "userDetailFilterChoices"
        );
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];

            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        }
    },
    watch: {},
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    userDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    userDetailFilterChoices: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        editUserDialogHandler(item) {
            this.openEditUserDialog = true;
            this.$refs.editUserDialog.initializeUser(item);
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updateUserValues(user) {
            // Close edit partner dialog if open
            this.updatedUser = user;
            this.$emit("refreshDataTable");
            this.openEditUserDialog = false;
        }
    }
};
</script>
<style scoped>
.v-select.fit {
  max-width: min-content;
  padding: 10px;
}
.v-select.fit  .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
</style>
