<template>
    <div :key="tracker.id">
        <!-- tabs -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="transparent"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                {{ t.name }}
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <!-- tab content -->
        <v-tabs-items v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <v-tab-item value="tab-details" :transition="false">
                <TrackerDetails 
                    :tracker="tracker"
                    :trip="trip"
                    v-on:updateTracker="updateTracker"
                    :isAvailable="isAvailable"
                    :disabledEdit="disabledEdit"
                > </TrackerDetails>
            </v-tab-item>
            <v-tab-item value="tab-charts" :transition="false">
                <TrackerCharts :tracker="tracker"> </TrackerCharts>
            </v-tab-item>
            <v-tab-item value="tab-map" :transition="false">
                <TrackerMap :tracker="tracker" viewType="tab"> </TrackerMap>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import TrackerMap from "@/components/trackers/details/TrackerMap.vue";
import TrackerCharts from "@/components/trackers/details/TrackerCharts.vue";
import TrackerDetails from "@/components/trackers/details/TrackerDetails.vue";

export default {
    props: {
        tracker: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        },
        isAvailable: {
            type: Boolean,
            default:false
        }, 
        disabledEdit: {
            type: Boolean,
            default:true
        },
        trip: {
            type: Object,
        }
    },
    components: {
        TrackerMap,
        TrackerCharts,
        TrackerDetails
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225 ;
            return  _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    mounted() {},
    methods: {
        updateTracker(tracker) {
            this.$emit("updateTracker", tracker);
        }
    }
};
</script>
