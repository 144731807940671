<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="8" sm="9" lg="10">
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    v-model="filter.nameIcontains"
                                    :label="$t('general.search')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                   <!--  <AddTenantDialog
                        :openAddTenantDialog="openAddTenantDialog"
                        v-on:refreshTenantsTable="$emit('refreshDataTable')"
                        v-on:closeAddTenantDialog="openAddTenantDialog = false"
                        v-on:item-created="onTenantCreated"
                    ></AddTenantDialog> -->
                    <!-- filters menu -->
                    <v-col cols="4" sm="3" lg="2" class="py-2 pr-4 text-right">
                        <v-row class="d-flex align-center justify-end">
                            <!-- <v-btn
                                rounded
                                color="primary"
                                class="mr-1"
                                @click="openAddTenantDialog = true"
                            >
                                {{ $t("users.add_tenant") }}
                            </v-btn> -->
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
/* import AddTenantDialog from "@/components/tenants/create/AddTenantDialog.vue"; */
import { TenantPermissions } from "@/core/enum";

export default {
    components: {
        /* AddTenantDialog */
    },
    apollo: {},

    data() {
        return {
            openAddTenantDialog: false,

            isLoading: false,
            fab: false,
            menu: false,
            shouldStickyFilter: false,

            // graphql query filter
            filter: {
                first: 15,
                after: null,
                nameIcontains: null
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        canCreate() {
            if (this.me?.isSuperuser) return true;

            if (
                this.hasPermission(TenantPermissions.CREATE_TENANT) &&
                this.me?.isStaff
            )
                return true;

            return false;
        }
    },
    watch: {
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                    helper.setFilterValues("tenantTableFilters", value);
                }
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.TenantPermissions = TenantPermissions;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.onResize();
        let _filter = helper.getFilterValues("tenantTableFilters");
        if (_filter) {
            this.filter.nameIcontains = _filter.nameIcontains;
        }
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        onFilterMenuChanged(newChoises) {
            this.filterChoices = newChoises;
        },
        onTenantCreated(val) {
            this.$emit("item-created", val);
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
