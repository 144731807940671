<template>
    <v-dialog
        max-width="1200"
        v-model="openAddTrackerDialog"
        persistent
        scrollable
    >
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("trackers.add_trackers") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-6">
                <v-row class="mb-3">
                    <v-col cols="6">
                        <v-row class="mx-0 px-0 pt-3">
                            <v-btn
                                color="primary"
                                :disabled="
                                    fileName != null && trackers.length != 0
                                "
                                rounded
                                @click="addTracker"
                                >{{ $t("trackers.add_tracker") }}</v-btn
                            >
                            <v-btn
                                right
                                rounded
                                color="primary"
                                class="ml-3"
                                :loading="isLoadingFile"
                                @click="$refs.fileInput.click()"
                                :disabled="
                                    fileName != null && trackers.length != 0
                                "
                                >{{ $t("general.import") }}
                                <label style="display: none;">
                                    <input
                                        type="file"
                                        ref="fileInput"
                                        accept=".xlsx"
                                        @change="importData"/></label
                            ></v-btn>
                        </v-row>
                    </v-col>
                    <v-col cols="3">
                        <v-form ref="brandForm">
                            <v-autocomplete
                                clearable
                                hide-selected
                                auto-select-first
                                dense
                                outlined
                                :label="$t('headers.brand') + '*'"
                                :items="brandsChoices"
                                v-model="brand"
                                :rules="[rules.required]"
                                attach
                            ></v-autocomplete>
                        </v-form>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-if="isStaff"
                            clearable
                            hide-selected
                            auto-select-first
                            dense
                            outlined
                            :label="$t('trackers.tenant')"
                            :items="tenants.edges"
                            :no-data-text="$t('loading.no_available')"
                            v-model="tenantId"
                            item-text="node.name"
                            item-value="node.id"
                            attach
                        ></v-autocomplete>
                        <v-autocomplete
                            v-else
                            clearable
                            hide-selected
                            auto-select-first
                            dense
                            outlined
                            :label="$t('trackers.stock_location')"
                            :items="tenantLocations.edges"
                            :no-data-text="$t('loading.no_available')"
                            v-model="stockLocation"
                            item-text="node.name"
                            item-value="node.id"
                            attach
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="mb-3" align="center" justify="end">
                    <v-badge
                        :value="existingTrackers.length"
                        :content="existingTrackers.length"
                        bordered
                        color="primary"
                        overlap
                        class="mr-3"
                        ><v-btn
                            color="primary"
                            rounded
                            outlined
                            :disabled="existingTrackers.length == 0"
                            @click="cleanExistingTrackers"
                            ><v-icon left>delete_sweep</v-icon
                            >{{ $t("general.clear_existing") }}</v-btn
                        ></v-badge
                    >
                </v-row>
                <v-data-table
                    :headers="defaultHeaderChoices"
                    :items.sync="sortedTrackers"
                    :editable="true"
                    :no-data-text="$t('trackers.no_add')"
                >
                    <template v-slot:item.serialNumber="{ item }">
                        <v-form :ref="'serialNumberForm-' + item.serialNumber">
                            <v-text-field
                                v-model="item.serialNumber"
                                dense
                                :rules="[
                                    rules.required,
                                    rules.unique({
                                        checked: item.checked,
                                        valid: item.valid,
                                        serialNumber: item.serialNumber
                                    })
                                ]"
                                @change="updateCheckedStatus(item.serialNumber)"
                            ></v-text-field>
                        </v-form>
                    </template>
                    <template v-slot:item.mainTracker="{ item }">
                        <v-text-field v-model="item.mainTracker" dense></v-text-field>
                    </template>
                    <template v-slot:item.model="{ item }">
                        <v-text-field v-model="item.model" dense></v-text-field>
                    </template>
                    <template v-slot:item.packageNumber="{ item }">
                        <v-text-field
                            v-model="item.packageNumber"
                            dense
                        ></v-text-field>
                    </template>
                    <template v-slot:item.expiryDate="{ item }">
                        <DateComponent
                            v-model="item.expiryDate"
                            :rules="[]"
                            :outlined="false"
                            :dense="true"
                            class="ma-0 pa-0"
                        >
                        </DateComponent>
                    </template>
                    <template v-slot:item.comment="{ item }">
                        <v-text-field
                            v-model="item.comment"
                            dense
                        ></v-text-field>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" @click="deleteRow(item)"
                            >delete</v-icon
                        >
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    text
                    rounded
                    @click="saveTrackers"
                    :loading="
                        isLoadingAddTrackerDialog || isLoadingCheckSerialNumber
                    "
                    :disabled="invalidTrackers || isBrandEmpty"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
        <AddTrackerColumnsSelectorDialog
            :openAddTrackerColumnsSelectorDialog="
                openAddTrackerColumnsSelectorDialog
            "
            :headers="headersValuesFromExcel"
            :defaultHeaders="defaultHeaderChoicesWithoutActions"
            :trackersToAdd="trackersFromExcel"
            v-on:closeAddTrackerColumnsSelectorDialog="cancelAddTrackers"
            v-on:updateTrackers="addTrackersFromExcel"
        ></AddTrackerColumnsSelectorDialog>
    </v-dialog>
</template>

<script>
// import XLSX from "xlsx";
import { read, utils } from "xlsx";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import DateComponent from "@/components/base/DateComponent.vue";
import AddTrackerColumnsSelectorDialog from "@/components/trackers/other/AddTrackerColumnsSelectorDialog.vue";
import moment from "moment";

export default {
    props: {
        openAddTrackerDialog: {
            type: Boolean
        }
    },
    components: {
        DateComponent,
        AddTrackerColumnsSelectorDialog
    },
    data() {
        return {
            file: null,
            isLoadingAddTrackerDialog: false,
            brandsChoices: [
                { text: "SensiTech", value: "SensiTech" },
                { text: "Tive", value: "Tive" },
                { text: "DeltaTrak", value: "DeltaTrak" },
                { text: "Emerson", value: "Emerson" },
                { text: "Frigga", value: "Frigga" }
            ],
            brand: null,
            stockLocation: null,
            fileName: null,
            tenantId: null,
            tenants: { edges: [] },
            defaultHeaderChoices: [
                {
                    text: this.$t("headers.serial_number"),
                    align: "left",
                    value: "serialNumber"
                },
                {
                    text: this.$t("headers.main_tracker"),
                    align: "left",
                    value: "mainTracker"
                },
                {
                    text: this.$t("headers.model"),
                    align: "left",
                    value: "model"
                },
                {
                    text: this.$t("headers.package_number"),
                    align: "left",
                    value: "packageNumber"
                },
                {
                    text: this.$t("headers.expiration"),
                    align: "left",
                    value: "expiryDate"
                },
                {
                    text: this.$t("headers.comment"),
                    align: "left",
                    value: "comment"
                },
                {
                    text: "",
                    align: "right",
                    value: "actions",
                    sortable: false
                }
            ],
            trackers: [],
            tenantLocations: { edges: [] },
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                unique: v => {
                    if (v.checked && !v.valid) {
                        return this.$t("rules.serial");
                    }

                    const serialNumbers = this.trackers.map(
                        t => t.serialNumber
                    );
                    const matchingSerialNumbers = serialNumbers.filter(
                        sn => sn === v.serialNumber
                    );
                    if (matchingSerialNumbers.length > 1) {
                        return this.$t("rules.duplicated");
                    }
                    return true;
                },
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.required") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            },
            openAddTrackerColumnsSelectorDialog: false,
            trackersFromExcel: [],
            headersValuesFromExcel: [],
            isLoadingCheckSerialNumber: false,
            isLoadingFile: false,
            dateFormats: [
                "YYYY-MM-DD", // e.g., 2023-06-30
                "MM/DD/YYYY", // e.g., 06/30/2023
                "DD/MM/YYYY", // e.g., 30/06/2023
                "YYYY/MM/DD", // e.g., 2023/06/30
                "DD-MM-YYYY", // e.g., 30-06-2023
                "MM-DD-YYYY", // e.g., 06-30-2023
                "YYYY-MM-DD HH:mm", // e.g., 2023-06-30 12:30
                "MM/DD/YYYY HH:mm", // e.g., 06/30/2023 12:30
                "DD/MM/YYYY HH:mm", // e.g., 30/06/2023 12:30
                "YYYY/MM/DD HH:mm" // e.g., 2023/06/30 12:30
            ]
        };
    },
    beforeCreate() {
        this.me = helper.me();
    },
    computed: {
        isStaff() {
            return this.me?.isStaff || false;
        },
        defaultHeadersValues() {
            let _allValues = this.defaultHeaderChoices.map(
                header => header.value
            );
            _allValues.pop();
            return _allValues;
        },
        defaultHeaderChoicesWithoutActions() {
            let _allValues = [...this.defaultHeaderChoices];
            _allValues.pop();
            return _allValues;
        },
        invalidTrackers() {
            //check if there are any invalid or not checked trackers
            for (let i = 0; i < this.trackers.length; i++) {
                const item = this.trackers[i];
                if (item.checked && !item.valid) {
                    return true;
                } else if (!item.checked) {
                    return true;
                }
            }

            //check for duplicates in trackers
            const serialNumbers = this.trackers.map(t => t.serialNumber);
            const uniqueSerialNumbers = [...new Set(serialNumbers)];
            if (serialNumbers.length !== uniqueSerialNumbers.length) {
                return true;
            }

            return false;
        },
        existingTrackers() {
            return this.trackers.filter(t => t.checked && !t.valid);
        },
        isBrandEmpty() {
            return helper.isEmpty(this.brand);
        },
        sortedTrackers() {
            let sorted = [...this.trackers].sort((a, b) =>
                a.valid === b.valid ? 0 : a.valid ? 1 : -1
            );
            return sorted;
        }
    },
    apollo: {
        tenantLocations: {
            query: gql`
                query tenantLocations($isTrackerStockLocation: Boolean) {
                    tenantLocations(
                        isTrackerStockLocation: $isTrackerStockLocation
                    ) {
                        edges {
                            node {
                                name
                                id
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    isTrackerStockLocation: true
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantLocations,
            debounce: 0
            // skip() {
            //     return !this.me.isStaff;
            // }
            // pollInterval: 60000 // ms
        },
        tenants: {
            query: gql`
                query tenants {
                    tenants {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenants,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    watch: {
        trackers: {
            handler: function(val) {
                if (val.length > 0) {
                    let _serialNumbersToCheck = [];

                    val.forEach(item => {
                        this.$nextTick(() => {
                            if (
                                this.$refs[
                                    `serialNumberForm-${item.serialNumber}`
                                ] !== undefined
                            ) {
                                item.valid = this.$refs[
                                    `serialNumberForm-${item.serialNumber}`
                                ].validate();
                            }
                        });

                        if (item.checked === false) {
                            _serialNumbersToCheck.push(item.serialNumber);
                        }
                    });

                    this.checkSerialNumbers(_serialNumbersToCheck);
                } else {
                    this.fileName = null;
                }
            },
            deep: true
        }
    },
    methods: {
        cancelAddTrackers() {
            this.openAddTrackerColumnsSelectorDialog = false;
            this.trackersFromExcel = [];
            this.headersValuesFromExcel = [];
            this.isLoadingFile = false;
            this.fileName = null;
        },
        async importData(event) {
            const file = event.target.files[0];
            this.fileName = file.name;

            if (!file) {
                return;
            }

            try {
                const data = await this.readFileAsArrayBuffer(file);
                const workbook = read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = utils.sheet_to_json(sheet);

                if (!this.validArray(jsonData)) {
                    const errorMessage = this.$t("rules.file_valid");
                    this.showMessage({ color: "error", message: errorMessage });
                    return;
                }

                const headers = this.extractHeaders(sheet);

                this.showTrackerColumnsSelectorDialog(jsonData, headers);
            } catch (error) {
                console.error("Error importing data:", error);
            } finally {
                this.resetFileInput();
            }
        },
        showTrackerColumnsSelectorDialog(jsonData, headers) {
            this.trackersFromExcel = jsonData;
            this.headersValuesFromExcel = headers;
            this.openAddTrackerColumnsSelectorDialog = true;
        },
        readFileAsArrayBuffer(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = event =>
                    resolve(new Uint8Array(event.target.result));
                reader.onerror = event => reject(event.target.error);
                reader.readAsArrayBuffer(file);
            });
        },
        extractHeaders(sheet) {
            const headers = [];
            const range = utils.decode_range(sheet["!ref"]);

            for (let column = range.s.c; column <= range.e.c; column++) {
                const cell = sheet[utils.encode_cell({ r: 0, c: column })];
                const header = cell?.w || utils.format_cell(cell);
                if (header != undefined && header != null && header != "") {
                    headers.push(header);
                }
            }

            return headers;
        },
        validArray(list) {
            let validParms =
                list != undefined && list != null && list.length > 0
                    ? true
                    : false;

            return validParms;
        },
        areDefaultHeadersPresent(headers) {
            const defaultHeaders = [
                "serial",
                "serial no.",
                "serial number",
                "main tracker",
                "id",
                "model",
                "package",
                "expiration",
                "expiration date",
                "expiry",
                "expiry date",
                "comment"
            ]; // Replace with your default headers
            let trigger = [];
            defaultHeaders.forEach(header => {
                headers.forEach(item => {
                    if (item.toLowerCase() == header.toLowerCase()) {
                        trigger.push(true);
                    }
                });
            });
            return trigger.length > 0 ? true : false;
        },

        showMessage(payload) {
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        resetFileInput() {
            this.$refs.fileInput.value = "";
        },
        addTracker() {
            const emptyItem = {};
            for (let i = 0; i < this.defaultHeaderChoices.length; i++) {
                const header = this.defaultHeaderChoices[i].value;
                emptyItem[header] = "";
            }
            emptyItem["checked"] = false;
            emptyItem["valid"] = false;
            this.trackers.push(emptyItem);
        },
        deleteRow(item) {
            const index = this.trackers.indexOf(item);
            if (index >= 0) {
                this.trackers.splice(index, 1);
            }
        },
        cleanExistingTrackers() {
            this.trackers = this.trackers.filter(item => {
                return item.checked && item.valid;
            });
        },
        saveTrackers() {
            for (let i = 0; i < this.trackers.length; i++) {
                const item = this.trackers[i];
                if (item.valid == false) {
                    this.$refs[
                        `serialNumberForm-${item.serialNumber}`
                    ].validate();
                    return;
                }
            }

            if (this.$refs.brandForm.validate() == false) {
                return;
            }

            if (this.trackers.length == 0) {
                return;
            }

            let _trackerInput = this.trackers.map(item => {
                return {
                    serialNumber: item.serialNumber,
                    mainTracker: item.mainTracker,
                    model: item.model,
                    packageNumber: item.packageNumber,
                    expiryDate: new Date(item.expiryDate),
                    comment: item.comment
                };
            });

            this.isLoadingAddTrackerDialog = true;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation CreateTrackers($input: CreateTrackersInput!) {
                            createTrackers(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            trackers: _trackerInput,
                            brand: this.brand,
                            stockLocationId: this.stockLocation,
                            tenantId: this.isStaff
                                ? this.tenantId
                                : this.me.tenant.id,
                            fileName: this.fileName
                        }
                    }
                })
                .then(() => {
                    this.isLoadingAddTrackerDialog = false;
                    this.closeAndResetInput();
                    this.$emit("updateTrackersTable");
                })
                .catch(err => {
                    this.isLoadingAddTrackerDialog = false;
                    console.log(err);
                });
        },
        updateCheckedStatus(serialNumber) {
            for (let i = 0; i < this.trackers.length; i++) {
                const item = this.trackers[i];
                if (item.serialNumber == serialNumber) {
                    item.checked = false;
                    item.valid = false;
                }
            }
        },
        checkSerialNumbers(serialNumbers) {
            if (serialNumbers.length > 0) {
                this.isLoadingCheckSerialNumber = true;
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CheckIfTrackersExist(
                                $input: CheckIfTrackersExistInput!
                            ) {
                                checkIfTrackersExist(input: $input) {
                                    response {
                                        serialNumber
                                        exists
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                serialNumbers: serialNumbers
                            }
                        }
                    })
                    .then(res => {
                        let _trackersChecked =
                            res.data.checkIfTrackersExist.response;
                        for (let i = 0; i < this.trackers.length; i++) {
                            let tracker = this.trackers[i];
                            for (let j = 0; j < _trackersChecked.length; j++) {
                                const _item = _trackersChecked[j];
                                if (
                                    tracker.serialNumber == _item.serialNumber
                                ) {
                                    tracker.checked = true;
                                    tracker.valid = !_item.exists;

                                    this.$nextTick(() => {
                                        if (
                                            this.$refs[
                                                `serialNumberForm-${_item.serialNumber}`
                                            ] !== undefined
                                        ) {
                                            _item.valid = this.$refs[
                                                `serialNumberForm-${_item.serialNumber}`
                                            ].validate();
                                        }
                                    });
                                }
                            }
                        }
                        this.isLoadingCheckSerialNumber = false;
                    })
                    .catch(err => {
                        console.log(err);
                        this.isLoadingCheckSerialNumber = false;
                    });
            }
        },
        closeAndResetInput() {
            this.trackers = [];
            this.brand = null;
            this.stockLocation = null;
            this.tenantId = null;
            this.fileName = null;
            this.$refs.brandForm.resetValidation();
            this.$emit("closeAddTrackerDialog");
        },
        addTrackersFromExcel(val) {
            if (val == null || val.length == 0) {
                return;
            }

            for (let i = 0; i < val.length; i++) {
                const item = val[i];
                item["checked"] = false;
                item["valid"] = false;
                if (item.expiryDate) {
                    try {
                        let convertedDate = null;
                        for (let i = 0; i < this.dateFormats.length; i++) {
                            const dateFormat = this.dateFormats[i];
                            const parsedDate = moment(
                                item.expiryDate,
                                dateFormat,
                                true
                            );
                            if (parsedDate.isValid()) {
                                convertedDate = parsedDate.format("YYYY-MM-DD");
                                break;
                            }
                        }
                        if (convertedDate == null) {
                            if (
                                this.isDateValid(item.expiryDate) == true &&
                                item.expiryDate != "Invalid Date" &&
                                !isNaN(item.expiryDate)
                            ) {
                                convertedDate = new Date(
                                    item.expiryDate
                                ).toISOString();
                            }
                        }
                        item.expiryDate = convertedDate;
                    } catch (error) {
                        item.expiryDate = null;
                    }
                }
            }

            this.trackers = this.trackers.concat(val);
            this.trackersFromExcel = [];
            this.openAddTrackerColumnsSelectorDialog = false;
            this.isLoadingFile = false;
        },
        isDateValid(dateString) {
            var dateObj = new Date(dateString);
            return !isNaN(dateObj.getTime());
        }
    }
};
</script>
