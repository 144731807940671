<template>
    <v-card flat class="background px-0 my-0">
        <div>
            <v-card flat class="my-0">
                <v-card-text class="background px-0 py-0">
                    <v-row class="background">
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="6" :lg="isSmallScreen ? 6 : 3" xl="3">
                                    <v-autocomplete
                                        v-model="tripFreight"
                                        :items="trip.tripFreightSet.edges"
                                        :no-data-text="$t('loading.no_available')"
                                        auto-select-first
                                        item-text="node.referenceNumber"
                                        item-value="node.id"
                                        hide-details
                                        :label="$t('headers.freight') + '*'"
                                        dense
                                        color="primary"
                                        :disabled="freight ? true : false"
                                        outlined
                                        attach
                                    >
                                        <template v-slot:selection="data">
                                            <span
                                                v-if="
                                                    data.item.node.containerNumber &&
                                                        trip.transportMode.toLowerCase() === 'sea'
                                                "
                                                >{{ data.item.node.containerNumber }}</span
                                            >
                                            <span
                                                v-else-if="
                                                    data.item.node.airWaybillNumber &&
                                                        trip.transportMode.toLowerCase() === 'air'
                                                "
                                                >{{ data.item.node.airWaybillNumber }}</span
                                            >
                                            <span v-else>({{ data.item.node.referenceNumber }})</span>
                                        </template>
                                        <template v-slot:item="data">
                                            <v-row class="ma-0 body-2" dense>
                                                <v-col
                                                    cols="6"
                                                    class="text-truncate pl-0 ml-0"
                                                    v-if="
                                                        data.item.node.containerNumber &&
                                                            trip.transportMode.toLowerCase() === 'sea'
                                                    "
                                                >
                                                    {{ data.item.node.containerNumber }}
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    class="text-truncate pl-0 ml-0"
                                                    v-else-if="
                                                        data.item.node.airWaybillNumber &&
                                                            trip.transportMode.toLowerCase() === 'air'
                                                    "
                                                >
                                                    {{ data.item.node.airWaybillNumber }}
                                                </v-col>
                                                <v-col cols="6" v-if="data.item.node.referenceNumber">
                                                    <span class="grey--text mr-1"
                                                        ><i>({{ data.item.node.referenceNumber }})</i></span
                                                    >
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="6" :lg="isSmallScreen ? 6 : 3" xl="3">
                                    <v-autocomplete
                                        auto-select-first
                                        v-model="trackerId"
                                        :items="trackers"
                                        item-text="serialNumber"
                                        item-value="id"
                                        :label="$t('headers.tracker')"
                                        hide-details
                                        dense
                                        color="primary"
                                        outlined
                                        :disabled="trackers.length < 1"
                                        :no-data-text="$t('loading.no_tracker')"
                                        :hide-no-data="false"
                                        attach
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="6" :lg="isSmallScreen ? 6 : 'auto'" xl="auto">
                                    <v-autocomplete
                                        v-model="selectedSensors"
                                        :items="sensorChoices"
                                        multiple
                                        small-chips
                                        hide-details
                                        :label="$t('trips.sensor')"
                                        dense
                                        color="primary"
                                        outlined
                                        item-text="text"
                                        item-value="value"
                                        :class="screenSize === 'xl' ? 'maxScreenWidth' : 'maxWidth'"
                                        :disabled="!showCharts && !trackerId"
                                        attach
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                    <v-card flat max-width="20px" class="d-flex justify-center" color="transparent">
                                        <v-menu
                                            offset-y
                                            transition="slide-x-reverse-transition"
                                            width="250px"
                                            attach
                                            left
                                            bottom
                                            close-on-click
                                            nudge-top="-3"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon>more_vert</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <ExportTripTrackerDialog
                                                        :selectedFreightId.sync="tripFreight"
                                                        :selectedTrackerId.sync="trackerId"
                                                        :startTime="chartStartTime"
                                                        :endTime="chartEndTime"
                                                        :trip="trip"
                                                        :selectedTrackerSerialNumber="selectedTrackerSerialNumber"
                                                        :plain="true"
                                                        :rounded="false"
                                                        :outlined="false"
                                                        :color="''"
                                                        :iconColor="''"
                                                    ></ExportTripTrackerDialog>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <LoadingBar v-if="isLoadingChart"></LoadingBar>
                            <SummaryTimelineChart
                                v-if="!isLoadingChart"
                                :minTemperature="minTemperature"
                                :maxTemperature="maxTemperature"
                                :minHumidity="minHumidity"
                                :maxHumidity="maxHumidity"
                                :minLight="minLight"
                                :maxLight="maxLight"
                                :minShock="minShock"
                                :maxShock="maxShock"
                                :startTime="chartStartTime"
                                :endTime="chartEndTime"
                                :eventsLocations="eventsLocationNew"
                                :alertTrigger="tripAlertTriggers"
                                :tripAlert="tripAlert"
                                :temperatureSeries="mergeSensorData.temperature"
                                :probeTemperaturesSeries="mergeSensorData.probeTemperature"
                                :externalTemperaturesSeries="mergeSensorData.externalTemperature"
                                :lightSeries="mergeSensorData.light"
                                :humiditySeries="mergeSensorData.humidity"
                                :shockSeries="mergeSensorData.shock"
                                :selected="selectedSensors"
                                :merge="mergeSensorData"
                                :transportMode="trip.transportMode"
                            />
                        </v-col>
                    </v-row>
                    <LoadingBar v-if="isLoadingHistoryEvents"></LoadingBar>
                    <v-card flat v-else class="px-3 py-2 rounded-lg outlineD mb-5" max-height="1000px">
                        <v-card-title>
                            <v-row :class="carrierObj ? 'py-0' : ''">
                                <v-col class="d-flex flex-row pt-6" cols="12" xs="12" sm="6" md="5" lg="5" xl="5">
                                    <v-img
                                        :src="require('@/assets/history.png')"
                                        max-width="25"
                                        max-height="25"
                                        class="mr-2"
                                    ></v-img>
                                    <div class="textTitle text-no-wrap">
                                        {{ $t("general.timeline") }}
                                    </div>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="12" xs="12" sm="4" md="4" lg="auto" xl="auto" class="pt-1">
                                    <v-switch
                                        :disabled="!trackerId"
                                        v-model="showCharts"
                                        :label="$t('headers.charts')"
                                        color="primary"
                                        inset
                                    ></v-switch>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="4"
                                    md="4"
                                    lg="auto"
                                    xl="auto"
                                    class="pt-1"
                                    v-if="selectEvent === 'timeline'"
                                >
                                    <v-switch
                                        :disabled="!trackerId"
                                        v-model="summarized"
                                        :label="$t('general.show_all')"
                                        color="primary"
                                        inset
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" xs="12" sm="8" md="3" lg="3" xl="3" class="pt-5">
                                    <v-autocomplete
                                        v-model="selectEvent"
                                        :items="timelineHistoryEvents"
                                        item-text="text"
                                        item-value="value"
                                        :label="$t('trips.filter')"
                                        outlined
                                        hide-details
                                        dense
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <div
                            v-if="carrierObj.name !== null && selectEvent !== 'timeline'"
                            class="textBody px-4 d-flex flex-row align-center mb-2"
                        >
                            <div class="mr-2">{{ $t("headers.carrier") }}:</div>
                            <div class="mr-2">
                                {{ carrierObj.name }}
                            </div>
                            <v-btn
                                icon
                                :disabled="!carrierObj.link"
                                color="primary"
                                @click="goToCarrierWebsite(carrierObj.link)"
                            >
                                <v-icon>
                                    public
                                </v-icon>
                            </v-btn>
                        </div>
                        <div v-if="historyEventsNew.length == 0" class="d-flex flex-row justify-center align-center">
                            <div class="textBody">
                                {{ $t("loading.no_data") }}
                            </div>
                        </div>
                        <v-card-text class="cardContainer" v-else>
                            <v-timeline dense align-top>
                                <v-timeline-item
                                    v-for="(item, i) in Array.isArray(historyEventsNew) ? historyEventsNew : []"
                                    :key="i"
                                    :color="getEventColor(item.tag, item).background"
                                    fill-dot
                                >
                                    <template v-slot:icon>
                                        <v-icon :color="getEventColor(item.tag).icon" size="18">
                                            {{ getEventIcon(item.tag, item) }}
                                        </v-icon>
                                    </template>
                                    <!-- Expand Container  -->
                                    <v-expansion-panels v-model="panel[i]" flat>
                                        <v-expansion-panel
                                            class="mb-2 contentBg"
                                            @click="() => handlePanelChange(i, !(panel[i] === 0))"
                                        >
                                            <v-expansion-panel-header class="pa-2 d-flex align-center ">
                                                <template v-slot:actions>
                                                    <v-icon v-if="hasContent(item[0])">
                                                        keyboard_arrow_down
                                                    </v-icon>
                                                    <v-icon color="transparent" v-else>
                                                        filter_list_off
                                                    </v-icon>
                                                </template>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        xs="12"
                                                        sm="12"
                                                        md="8"
                                                        lg="8"
                                                        xl="8"
                                                        v-if="
                                                            item[0].eventDescription === 'Tracker' ||
                                                                item[0].eventDescription === 'Vessel'
                                                        "
                                                    >
                                                        <v-row dense class="py-0">
                                                            <v-col cols="auto" v-html="getEventTitle(item[0])"></v-col>
                                                            <v-col cols="auto" class="timeText">
                                                                ( {{ dateDiff(item[0].eventTime, item[1].eventTime) }} )
                                                            </v-col>
                                                        </v-row>
                                                        <v-row dense class="py-0">
                                                            <v-col
                                                                cols="12"
                                                                xs="12"
                                                                sm="12"
                                                                md="3"
                                                                lg="2"
                                                                xl="2"
                                                                v-if="item[0].eventTime"
                                                            >
                                                                <div class="timeText">
                                                                    {{ getDate(item[0].eventTime) }}
                                                                </div>
                                                                <div class="timeText">
                                                                    {{ getTime(item[0].eventTime) }}
                                                                </div>
                                                            </v-col>
                                                            <v-col v-else>
                                                                <div class="timeText">
                                                                    N/A
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" v-else>
                                                        <v-row dense>
                                                            <v-col v-html="getCarrierEventTitle(item[0])"></v-col>
                                                        </v-row>
                                                        <v-row dense>
                                                            <v-col
                                                                cols="12"
                                                                xs="12"
                                                                sm="12"
                                                                md="3"
                                                                lg="2"
                                                                xl="2"
                                                                v-if="item[0].eventTime"
                                                            >
                                                                <div class="timeText">
                                                                    {{ getDate(item[0].eventTime) }}
                                                                </div>
                                                                <div class="timeText">
                                                                    {{ getTime(item[0].eventTime) }}
                                                                </div>
                                                            </v-col>
                                                            <v-col v-else>
                                                                <div class="timeText">
                                                                    N/A
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-spacer></v-spacer>
                                                    <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">
                                                        <v-chip
                                                            class="px-4 py-2"
                                                            :color="
                                                                historyEventColor(item[0].eventDescription).background
                                                            "
                                                            label
                                                        >
                                                            <v-icon
                                                                left
                                                                size="8"
                                                                :style="
                                                                    `color:${
                                                                        historyEventColor(item[0].eventDescription)
                                                                            .color
                                                                    };`
                                                                "
                                                            >
                                                                circle
                                                            </v-icon>
                                                            <span class="statusText">
                                                                {{ item[0].eventDescription }}
                                                            </span>
                                                        </v-chip>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content
                                                class="mt-n1 px-6 pt-6 pb-2 contentBg"
                                                v-if="
                                                    item[0].eventDescription === 'Tracker' ||
                                                        item[0].eventDescription === 'Vessel'
                                                "
                                            >
                                                <div>
                                                    <div v-if="!isFutureDate(item[0].eventTime)">
                                                        <div
                                                            v-if="
                                                                item[0].eventDescription == 'Tracker' ||
                                                                    item[0].eventDescription === 'Vessel'
                                                            "
                                                        >
                                                            <TripFreightTimelineChart
                                                                v-if="
                                                                    trackerId !== null &&
                                                                        trackerId !== undefined &&
                                                                        monitorExpansionPanels[i]
                                                                "
                                                                :minTemperature="minTemperature"
                                                                :backgroundColor="'#f9fafe'"
                                                                :maxTemperature="maxTemperature"
                                                                :minHumidity="minHumidity"
                                                                :maxHumidity="maxHumidity"
                                                                :minLight="minLight"
                                                                :maxLight="maxLight"
                                                                :minShock="minShock"
                                                                :maxShock="maxShock"
                                                                :chartStartTime="chartStartTime"
                                                                :chartEndTime="chartEndTime"
                                                                :currentStartTime="item[0].eventTime"
                                                                :currentEndTime="item[1].eventTime"
                                                                :alertTrigger="tripAlertTriggers"
                                                                :tripAlert="
                                                                    showCurrentEventAlertTime(
                                                                        i,
                                                                        item[0].eventTime,
                                                                        tripAlert
                                                                    )
                                                                "
                                                                :temperatureSeries="mergeSensorData.temperature"
                                                                :probeTemperaturesSeries="
                                                                    mergeSensorData.probeTemperature
                                                                "
                                                                :externalTemperaturesSeries="
                                                                    mergeSensorData.externalTemperature
                                                                "
                                                                :lightSeries="mergeSensorData.light"
                                                                :humiditySeries="mergeSensorData.humidity"
                                                                :shockSeries="mergeSensorData.shock"
                                                                :selected="selectedSensors"
                                                            ></TripFreightTimelineChart>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-timeline-item>
                            </v-timeline>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </div>
    </v-card>
</template>
<script>
import TripFreightTimelineChart from "@/components/trips/details/TripFreightTimelineChart.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import moment from "moment";
import LoadingBar from "@/components/base/LoadingBar.vue";
import { TripPermissions } from "@/core/enum";
import ExportTripTrackerDialog from "@/components/trips/other/ExportTripTrackerDialog.vue";
import SummaryTimelineChart from "@/components/trips/other/SummaryTimelineChartNew.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        freight: {
            type: Object,
            required: false
        }
    },
    components: {
        TripFreightTimelineChart,
        LoadingBar,
        ExportTripTrackerDialog,
        SummaryTimelineChart
    },
    apollo: {
        trackerDeviceTimeRange: {
            query: gql`
                query trackerDeviceTimeRange($trackerId: ID, $freightId: ID) {
                    trackerDeviceTimeRange(freightId: $freightId, trackerId: $trackerId)
                }
            `,
            variables() {
                return {
                    freightId: this.tripFreight ? this.tripFreight : null,
                    trackerId: this.trackerId ? this.trackerId : null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            result({ data }) {
                if (data?.trackerDeviceTimeRange) {
                    this.trackerDeviceTimeRange = JSON.parse(data.trackerDeviceTimeRange);
                }
            },
            debounce: 0,
            skip() {
                let _trackerId = this.trackerId ? this.trackerId : null;
                return _trackerId !== null ? false : true;
            }
        },
        trackerMergedSensorDatas: {
            query: gql`
                query TrackerMergedSensorDatas($trackerId: ID, $tripId: ID, $freightId: ID, $orderBy: String) {
                    trackerMergedSensorDatas(
                        tracker: $trackerId
                        tripId: $tripId
                        freightId: $freightId
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                id
                                deviceTime
                                receiveTime
                                light
                                shock
                                humidity
                                temperature
                                externalTemperature
                                probeTemperature
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    freightId: this.tripFreight ? this.tripFreight : null,
                    trackerId: this.trackerId ? this.trackerId : null,
                    orderBy: "deviceTime"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerMergedSensorDatas,
            skip() {
                let _trackerId = this.trackerId ? this.trackerId : null;
                return _trackerId !== null ? false : true;
            }
        },
        tripFreightTimelines: {
            query: gql`
                query tripFreightTimelines($tripFreight: ID!, $trackerId: ID) {
                    tripFreightTimelines(tripFreight: $tripFreight, trackerId: $trackerId) {
                        edges {
                            node {
                                id
                                eventSource
                                eventType
                                eventTime
                                eventName
                                eventSourceType
                                mergeReference
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    tripFreight: this.tripFreight ? this.tripFreight : null,
                    trackerId: this.trackerId ? this.trackerId : null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripFreightTimelines,
            skip() {
                let _trackerId = this.trackerId ? this.trackerId : null;
                let _tripFreight = this.tripFreight ? this.tripFreight : null;
                let _skip = _trackerId !== null && _tripFreight !== null;
                return !_skip;
            }
        },
        tripLocal: {
            query: gql`
                query tripLocal($tripId: ID!, $globalIdIn: [ID]) {
                    trip(id: $tripId) {
                        id
                        transportMode
                        tenantCarrier {
                            id
                            name
                            carrier {
                                id
                                name
                                website
                                link
                                code
                            }
                        }
                        tripFreightSet(globalIdIn: $globalIdIn) {
                            totalCount
                            edges {
                                node {
                                    id
                                    referenceNumber
                                    containerNumber
                                    airWaybillNumber
                                    billOfLadingNumber
                                    goods
                                    carrierEvents
                                    lastCarrierEventsSyncTime
                                    airlineLink
                                    airlineName
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tripId: this.trip?.id || null
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.trip,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.trip?.id;
            }
        },
        // tripFreightGeofenceEvents: {
        //     query: gql`
        //         query TripFreightTrackerGeofenceEvents(
        //             $tripFreightTrackerTripFreight: ID
        //             $tripFreightTrackerTracker: ID
        //             $isNoisyEvent: Boolean
        //         ) {
        //             tripFreightTrackerGeofenceEvents(
        //                 tripFreightTracker_TripFreight: $tripFreightTrackerTripFreight
        //                 tripFreightTracker_Tracker: $tripFreightTrackerTracker
        //                 isNoisyEvent: $isNoisyEvent
        //             ) {
        //                 edges {
        //                     node {
        //                         id
        //                         eventTime
        //                         eventType

        //                         tenantLocation {
        //                             id
        //                             fullAddress
        //                             name
        //                         }
        //                         trackerMergedSensorData {
        //                             id
        //                             temperature
        //                             humidity
        //                         }
        //                         carrierAirport {
        //                             id
        //                             name
        //                         }
        //                         carrierSeaport {
        //                             id
        //                             name
        //                         }
        //                         tripAlerts {
        //                             alertedEmails {
        //                                 emails
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     `,
        //     variables() {
        //         return {
        //             tripFreightTrackerTripFreight: this.tripFreight,
        //             tripFreightTrackerTracker: this.trackerId,
        //             isNoisyEvent: false
        //         };
        //     },
        //     // Additional options here
        //     fetchPolicy: "cache-and-network",
        //     nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
        //     update: data => data.tripFreightTrackerGeofenceEvents
        //     /* skip() {
        //         let open = this.openPanel;
        //         return !(this.tripFreight && open);
        //     },  */
        // },
        tripAlerts: {
            query: gql`
                query TripAlerts($trip: ID, $tripFreight: ID, $trackerMergedSensorDataTracker: ID) {
                    tripAlerts(
                        trip: $trip
                        tripFreight: $tripFreight
                        trackerMergedSensorData_Tracker: $trackerMergedSensorDataTracker
                    ) {
                        edges {
                            node {
                                id
                                alertTime
                                deviceTime
                                alertJson
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    trip: this.trip?.id,
                    trackerMergedSensorDataTracker: this.trackerId ? this.trackerId : null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tripAlert,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.trip?.id;
            }
        },
        tripFreightAlertRules: {
            query: gql`
                query tripFreightAlertRules($tripFreight: ID) {
                    tripFreightAlertRules(tripFreight: $tripFreight) {
                        edges {
                            node {
                                id
                                tenantAlertRule {
                                    id
                                    name
                                    tenantAlertRuleTriggerSet {
                                        edges {
                                            node {
                                                id
                                                triggerJson
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tripFreight: this.tripFreight
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripFreightAlertRules,
            debounce: 0,
            skip() {
                return !this.tripFreight;
            }
        }
    },
    data() {
        return {
            tab: null,
            panel: {},
            showCharts: false,
            tripFreight: null,
            trackerId: null,
            selectedTrackerSerialNumber: null,
            selectEvent: "all",
            defaultSelectedSensors: [
                { text: this.$t("general.temperature"), value: "temperature" },
                { text: this.$t("general.light"), value: "light" }
            ],
            selectedSensors: ["temperature", "light"],
            sortDescTimeline: false,
            sortDescGeofence: false,
            loadingOverview: true,
            // trackerDeviceTimeRange: [],
            monitorExpansionPanels: {},
            summarized: false
        };
    },
    computed: {
        //Display settings - screen size and primary color
        screenSize() {
            let width = this.$vuetify.breakpoint.width;
            let size = "";
            if (width < 600) {
                return "xs";
            } else if (width < 960) {
                return "sm";
            } else if (width < 1264) {
                return "md";
            } else if (width < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        primaryColor() {
            return this.$vuetify.theme.currentTheme.primary;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1460;
        },
        //Trip permissions and user
        me() {
            return helper.me();
        },
        isLoadingChart() {
            return this.$apollo.queries.trackerMergedSensorDatas.loading;
        },
        isLoadingHistoryEvents() {
            return this.$apollo.queries.tripFreightTimelines.loading || this.$apollo.queries.tripLocal.loading;
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE,
                        this.organisationPermissions
                    )
                )
                    return false;
            }
            if (
                !this.hasOrganisationPermission(
                    TripPermissions.MANAGE_TRIP_FREIGHT_TIMELINE,
                    this.trip.tenant.organisationPermissions
                )
            )
                return false;
            if (this.me?.tenant && this.me?.isTenantAdmin) return true;
            if (!this.hasPermission(TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE) && this.me?.tenant) return false;

            return true;
        },
        //Alerts and Triggers
        tripAlertTriggers() {
            let _tenantAlerts = [];
            this.tripFreightAlertRules?.edges
                .filter(element => {
                    return element?.node;
                })
                .forEach(element => {
                    let alertName = element?.node?.tenantAlertRule?.name;
                    element?.node?.tenantAlertRule?.tenantAlertRuleTriggerSet?.edges.forEach(item => {
                        _tenantAlerts.push({
                            id: item.node.id,
                            name: alertName,
                            triggerJson: item.node.triggerJson
                        });
                    });
                });
            return _tenantAlerts || [];
        },
        tripAlert() {
            let alert = [];
            this.tripAlerts?.edges.forEach(element => {
                alert.push({
                    deviceTime: element.node.deviceTime
                        ? this.convertDateTimeTimezoneToMilliseconds(element.node.deviceTime)
                        : this.convertDateTimeTimezoneToMilliseconds(element.node.alertTime),
                    type: this.alertType(element.node.alertJson)
                });
            });
            return alert;
        },
        //TRACKER sensors data
        mergeSensorData() {
            const sensorTypes = [
                "light",
                "shock",
                "humidity",
                "temperature",
                "externalTemperature",
                "probeTemperatures"
            ];
            let object = {};
            for (const sensor of sensorTypes) {
                if (!this.$apollo.queries.trackerMergedSensorDatas.loading) {
                    object[sensor] = this.sensorData(sensor);
                } else {
                    object[sensor] = [];
                }
            }

            return object;
        },
        sensorChoices() {
            let _choices = [
                { text: this.$t("general.temperature"), value: "temperature" },
                { text: this.$t("general.humidity"), value: "humidity" },
                { text: this.$t("general.light"), value: "light" },
                { text: this.$t("general.shock"), value: "shock" },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature"
                }
            ];

            // TODO: remove unsupported sensors based on the trackers_tracker last_xxxx_device_time

            return _choices;
        },
        //Start time for the chart
        chartStartTime() {
            let startTime = "";

            if (this.trackerDeviceTimeRange?.start_date) {
                startTime = this.trackerDeviceTimeRange?.start_date;
            } else {
                let now = moment()
                    .subtract(3, "hours")
                    .toISOString();

                startTime = this.getMinDate([this.trip?.plannedDepartureDate, now]);
            }
            return this.updateDateTimeTimezone(startTime);
        },
        //End time for the chart
        chartEndTime() {
            let endTime = "";
            // if (this.trip?.completedAt) {
            //     endTime = this.trip?.completedAt;
            // } else {
            if (this.trackerDeviceTimeRange?.end_date) {
                endTime = this.trackerDeviceTimeRange?.end_date;
            } else {
                let now = moment()
                    .add(3, "hours")
                    .toISOString();

                endTime = this.getMaxDate([this.trip?.plannedArrivalDate, now]);
            }
            return this.updateDateTimeTimezone(endTime);
        },
        //EVENTS - General list
        timelineEvents() {
            let t = [];

            if (this.tripFreightTimelines?.edges) {
                t = JSON.parse(JSON.stringify(this.tripFreightTimelines?.edges));

                t.forEach(element => {
                    element.node.eventDescription = "Tracker";
                });
            }

            // sort the timeline by event time
            t.sort((a, b) => {
                return new Date(a.node.eventTime) - new Date(b.node.eventTime);
            });
            return t;
        },
        carrierObj() {
            const { tenantCarrier, tripFreightSet } = this.tripLocal;

            let carrierEvents = tripFreightSet.edges
                .filter(({ node }) => node.id === this.tripFreight)
                .flatMap(({ node }) => {
                    const { carrier } = JSON.parse(node.carrierEvents) || {};
                    let _freight = node;

                    let carrierName = "";
                    if (this.trip?.transportMode === "AIR") {
                        carrierName = _freight?.airlineName;
                    } else {
                        carrierName = carrier?.name;
                    }

                    return {
                        name: carrierName || tenantCarrier?.carrier?.name || tenantCarrier?.name || null,
                        link: tenantCarrier?.carrier?.link || _freight?.airlineLink || null
                    };
                });

            return carrierEvents[0] || null;
        },
        //HISTORY EVENTS choices
        timelineHistoryEvents() {
            let values = [];
            let _default = [
                {
                    text: this.$t("general.all"),
                    value: "all"
                },
                {
                    text: this.$t("general.timeline2"),
                    value: "timeline"
                },
                {
                    text: this.$t("general.carrier"),
                    value: "carrier"
                }
            ];

            if (this.trip.transportMode.toLowerCase() === "sea") {
                values = [
                    ..._default,
                    {
                        text: this.$t("general.vessel_event"),
                        value: "vessel"
                    }
                ];
            } else {
                values = [..._default];
            }

            return values;
        },
        //History events
        historyEventsNew() {
            const { tenantCarrier, tripFreightSet } = this.tripLocal;
            let events = [];
            let _timelineIsTrigger = this.tripFreightTimelines?.edges.length > 0;
            let _carrierIsTrigger =
                tripFreightSet.edges
                    .filter(({ node }) => node.id === this.tripFreight)
                    .flatMap(({ node }) => node && node.carrierEvents && JSON.parse(node.carrierEvents).events)
                    ?.length > 0;

            switch (this.selectEvent) {
                case "timeline":
                    events = this.trackerEventsNew || [];

                    break;
                case "carrier":
                    events = this.carrierEventsNew || [];

                    break;
                case "vessel":
                    events = this.trackerEventsNew.filter(event => event.tag === "vessel_depart");

                    break;
                case "all":
                    events = [...this.trackerEventsNew, ...this.carrierEventsNew];

                    //Event sorting
                    events.sort((a, b) => {
                        let dateA = new Date(a[0].eventTime).getTime();
                        let dateB = new Date(b[0].eventTime).getTime();
                        return dateA - dateB;
                    });

                    break;
            }

            return events;
        },

        //carrier events
        carrierEventsNew() {
            const { tenantCarrier, tripFreightSet } = this.tripLocal;

            let carrierEvents = tripFreightSet.edges
                .filter(({ node }) => node.id === this.tripFreight)
                .flatMap(({ node }) => {
                    const { carrier, events } = JSON.parse(node.carrierEvents) || {};

                    if (!events) return [];
                    return events.map((event, i) => {
                        const {
                            current_location,
                            description,
                            event_datetime,
                            vessel_name,
                            vessel_number,
                            voyage_number,
                            event_classifier
                        } = event;

                        return {
                            node: {
                                id: i,
                                eventDescription: "Carrier",
                                eventSource: "Carrier",
                                eventType: "Carrier",
                                eventTime: event_datetime ? this.updateDateTimeTimezone(event_datetime) : null,
                                eventName: description,
                                carrier: carrier,
                                carrierName: this.getCarrierName(tenantCarrier, node, carrier),
                                location: current_location,
                                vessel_name,
                                vessel_number,
                                voyage_number,
                                eventClassifier: event_classifier
                            }
                        };
                    });
                });

            //Sort Events
            carrierEvents.sort((a, b) => {
                let timeA = new Date(a.node.eventTime).getTime();
                let timeB = new Date(b.node.eventTime).getTime();
                return timeA - timeB;
            });

            const organizedCarrierEvents = carrierEvents.map(event => ({
                "0": { ...event.node, eventDescription: "Carrier" },
                "1": null, // No next event for carrier as mentioned
                tag: event.node.eventType
            }));

            return organizedCarrierEvents || [];
        },
        //Tracker events
        trackerEventsNew() {
            /**
             * 1. Flight depart or vessel depart
             *   1a. previous event should be public airport or public seaport
             *       1a.1 if previous event is public airport or public seaport then that becomes the point of departure
             *   1b. if previous event is is not public airport or public seaport then previous shoulb be city location
             *      1b.1 if previous event is city location then that becomes the point of departure
             *
             * 2. Flight arrive or vessel arrive
             *   2a. next event should be public airport or public seaport
             *       2a.1 if next event is public airport or public seaport then that becomes the point of arrival
             *   2b. if next event is is not public airport or public seaport then next shoulb be city location
             *      2b.1 if next event is city location then that becomes the point of arrival
             *
             *
             * Exception:
             * 1.
             *
             **/
            const enterEvents = [
                "tracker_activate",
                "geofence_enter",
                "flight_depart",
                "flight_arrive",
                "vessel_depart",
                "vessel_arrive"
            ];
            const exitEvents = ["geofence_exit"];
            let timeline = this.tripFreightTimelines?.edges || [];

            if (timeline.length === 0) return [];
            const inTrasitPoints = ["flight_depart", "vessel_depart", "flight_arrive", "vessel_arrive"];
            const inTransitEvents = this.filterInTransitEvents(timeline, inTrasitPoints);

            // Filter and sort the timeline events, ensuring no duplicates
            let timelineEventsNew = this.filterTimelineEvents(timeline, inTransitEvents, this.summarized);

            let timelineAgrouped = this.groupEventsByMergeReference(timelineEventsNew);

            // Create tracker events with tags and pairings
            const trackerEvents = this.createTrackerEvents(timelineAgrouped, enterEvents, exitEvents, "tracker");

            // Filter out short tracker events that are not significant
            const trackerEventsFiltered = this.filterShortEvents(trackerEvents);

            return trackerEventsFiltered;
        },
        //GEOFENCE EVENTS - New Refactor
        eventsLocationNew() {
            // Event source types of interest
            // 1. tenant_location
            // 2. public_airport (event before in-flight)
            // 3. public_seaport (event before in-transit)

            const enterEvents = [
                "tracker_activate",
                "geofence_enter",
                "flight_depart",
                "flight_arrive",
                "vessel_depart",
                "vessel_arrive"
            ];
            const exitEvents = ["geofence_exit"];
            let timeline = this.tripFreightTimelines?.edges || [];

            // If the timeline is empty, return an empty array
            if (timeline.length === 0) return [];

            // Filter and process in-transit events
            const inTrasitPoints = ["flight_depart", "vessel_depart", "flight_arrive", "vessel_arrive"];
            const inTransitEvents = this.filterInTransitEvents(timeline, inTrasitPoints);

            // Merge public and city location events into a single timeline
            const mergedTimeline = this.mergePublicAndCityEvents(timeline, inTransitEvents);

            // Create geofence events with tags and pairings
            let geofencesEvents = this.createTrackerEvents(mergedTimeline, enterEvents, exitEvents, "geofence");

            // Filter out short geofence events that are not significant
            let geofencesEventsFiltered = this.filterShortGeofenceEvents(geofencesEvents);

            return geofencesEventsFiltered;
        },
        //Trip Freight ID and Tracker ID
        trackers() {
            let _trackers = [];
            if (!this.trip.tripFreightSet.edges) return [];

            let freight = this.trip.tripFreightSet.edges.find(freight => freight.node.id === this.tripFreight);
            if (freight) {
                _trackers = freight.node.tripFreightTrackerSet.edges.map(e => e.node.tracker);
            }
            return _trackers;
        },
        //Chart MAX and MIN temp values
        minTemperature() {
            let _min = 0;

            let temperature = this.mergeSensorData.temperature.map(item => item[1]) || [];

            let probeTemperature = this.mergeSensorData.probeTemperatures.map(item => item[1]) || [];

            let externalTemperature = this.mergeSensorData.externalTemperature.map(item => item[1]) || [];

            let min = [...temperature, ...probeTemperature, ...externalTemperature].filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            // If exist alert for temperature sensor then update the max value to fix the coordinate values
            let alerts =
                this.tripAlertTriggers.map(x => {
                    return JSON.parse(x?.triggerJson);
                }) || [];

            let isTemperatureAlert = false;

            if (alerts.length > 0) {
                isTemperatureAlert = alerts.some(alert => {
                    return alert?.Sensor?.some(sensor => sensor.Type === "temperature") || false;
                });
            }

            if (isTemperatureAlert && this.selectedSensors.includes("temperature")) {
                alerts.forEach(alert => {
                    if (alert?.Sensor && alert?.Sensor?.length > 0) {
                        alert.Sensor.forEach(sensor => {
                            if (sensor.Type == "temperature") {
                                if (sensor.Condition == "in" || sensor.Condition == "out") {
                                    if (sensor.Value[0] < _min) {
                                        _min = Math.floor(sensor.Value[0]);
                                    }
                                } else {
                                    if (sensor.Value < _min) {
                                        _min = Math.floor(sensor.Value);
                                    }
                                }
                            }
                        });
                    }
                });
            }

            return _min - 5;
        },
        maxTemperature() {
            let _max = 0;

            let temperature = this.mergeSensorData.temperature.map(item => item[1]) || [];

            let probeTemperature = this.mergeSensorData.probeTemperatures.map(item => item[1]) || [];

            let externalTemperature = this.mergeSensorData.externalTemperature.map(item => item[1]) || [];

            let max = [...temperature, ...probeTemperature, ...externalTemperature].filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            // If exist alert for temperature sensor then update the max value to fix the coordinate values
            let alerts =
                this.tripAlertTriggers.map(x => {
                    return JSON.parse(x?.triggerJson);
                }) || [];

            let isTemperatureAlert = false;

            if (alerts.length > 0) {
                isTemperatureAlert = alerts.some(alert => {
                    return alert?.Sensor?.some(sensor => sensor.Type === "temperature") || false;
                });
            }

            if (isTemperatureAlert && this.selectedSensors.includes("temperature")) {
                alerts.forEach(alert => {
                    if (alert?.Sensor && alert?.Sensor?.length > 0) {
                        alert.Sensor.forEach(sensor => {
                            if (sensor.Type == "temperature") {
                                if (sensor.Condition == "in" || sensor.Condition == "out") {
                                    if (sensor.Value[1] > _max) {
                                        _max = Math.round(sensor.Value[1]);
                                    }
                                } else {
                                    if (sensor.Value > _max) {
                                        _max = Math.round(sensor.Value);
                                    }
                                }
                            }
                        });
                    }
                });
            }

            return _max + 5;
        },
        minHumidity() {
            let _min = 0;

            let humidity = this.mergeSensorData.humidity.map(item => item[1]) || [];

            let min = humidity.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            // If exist alert for humidity sensor then update the min value to fix the coordinate values
            let alerts =
                this.tripAlertTriggers.map(x => {
                    return JSON.parse(x?.triggerJson);
                }) || [];

            let isHumidityAlert = false;

            if (alerts.length > 0) {
                isHumidityAlert = alerts.some(alert => {
                    return alert?.Sensor?.some(sensor => sensor.Type === "humidity") || false;
                });
            }

            if (isHumidityAlert && this.selectedSensors.includes("humidity")) {
                alerts.forEach(alert => {
                    if (alert?.Sensor && alert?.Sensor?.length > 0) {
                        alert.Sensor.forEach(sensor => {
                            if (sensor.Type == "humidity") {
                                if (sensor.Condition == "in" || sensor.Condition == "out") {
                                    if (sensor.Value[0] < _min) {
                                        _min = Math.floor(sensor.Value[0]);
                                    }
                                } else {
                                    if (sensor.Value < _min) {
                                        _min = Math.floor(sensor.Value);
                                    }
                                }
                            }
                        });
                    }
                });
            }

            return _min;
        },
        maxHumidity() {
            let _max = 0;

            let humidity = this.mergeSensorData.humidity.map(item => item[1]) || [];

            let max = humidity.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            // If exist alert for humidity sensor then update the min value to fix the coordinate values
            let alerts =
                this.tripAlertTriggers.map(x => {
                    return JSON.parse(x?.triggerJson);
                }) || [];

            let isHumidityAlert = false;

            if (alerts.length > 0) {
                isHumidityAlert = alerts.some(alert => {
                    return alert?.Sensor?.some(sensor => sensor.Type === "humidity") || false;
                });
            }

            if (isHumidityAlert && this.selectedSensors.includes("humidity")) {
                alerts.forEach(alert => {
                    if (alert?.Sensor && alert?.Sensor?.length > 0) {
                        alert.Sensor.forEach(sensor => {
                            if (sensor.Type == "humidity") {
                                if (sensor.Condition == "in" || sensor.Condition == "out") {
                                    if (sensor.Value[1] > _max) {
                                        _max = Math.round(sensor.Value[1]);
                                    }
                                } else {
                                    if (sensor.Value > _max) {
                                        _max = Math.round(sensor.Value);
                                    }
                                }
                            }
                        });
                    }
                });
            }

            return _max + 2;
        },
        minLight() {
            let _min = 0;

            let light = this.mergeSensorData.light.map(item => item[1]) || [];

            let min = light.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            // If exist alert for light sensor then update the min value to fix the coordinate values
            let alerts =
                this.tripAlertTriggers.map(x => {
                    return JSON.parse(x?.triggerJson);
                }) || [];

            let isLightAlert = false;

            if (alerts.length > 0) {
                isLightAlert = alerts.some(alert => {
                    return alert?.Sensor?.some(sensor => sensor.Type === "light") || false;
                });
            }

            if (isLightAlert && this.selectedSensors.includes("light")) {
                alerts.forEach(alert => {
                    if (alert?.Sensor && alert?.Sensor?.length > 0) {
                        alert.Sensor.forEach(sensor => {
                            if (sensor.Type == "light") {
                                if (sensor.Condition == "in" || sensor.Condition == "out") {
                                    if (sensor.Value[0] < _min) {
                                        _min = Math.floor(sensor.Value[0]);
                                    }
                                } else {
                                    if (sensor.Value < _min) {
                                        _min = Math.floor(sensor.Value);
                                    }
                                }
                            }
                        });
                    }
                });
            }

            return _min;
        },
        maxLight() {
            let _max = 0;

            let light = this.mergeSensorData.light.map(item => item[1]) || [];

            let max = light.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            // If exist alert for light sensor then update the max value to fix the coordinate values
            let alerts =
                this.tripAlertTriggers.map(x => {
                    return JSON.parse(x?.triggerJson);
                }) || [];

            let isLightAlert = false;

            if (alerts.length > 0) {
                isLightAlert = alerts.some(alert => {
                    return alert?.Sensor?.some(sensor => sensor.Type === "light") || false;
                });
            }

            if (isLightAlert && this.selectedSensors.includes("light")) {
                alerts.forEach(alert => {
                    if (alert?.Sensor && alert?.Sensor?.length > 0) {
                        alert.Sensor.forEach(sensor => {
                            if (sensor.Type == "light") {
                                if (sensor.Condition == "in" || sensor.Condition == "out") {
                                    if (sensor.Value[1] > _max) {
                                        _max = Math.round(sensor.Value[1]);
                                    }
                                } else {
                                    if (sensor.Value > _max) {
                                        _max = Math.round(sensor.Value);
                                    }
                                }
                            }
                        });
                    }
                });
            }

            return _max + 1;
        },
        minShock() {
            let _min = 0;

            let shock = this.mergeSensorData.shock.map(item => item[1]) || [];

            let min = shock.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            // If exist alert for shock sensor then update the min value to fix the coordinate values
            let alerts =
                this.tripAlertTriggers.map(x => {
                    return JSON.parse(x?.triggerJson);
                }) || [];

            let isShockAlert = false;

            if (alerts.length > 0) {
                isShockAlert = alerts.some(alert => {
                    return alert?.Sensor?.some(sensor => sensor.Type === "shock") || false;
                });
            }

            if (isShockAlert && this.selectedSensors.includes("shock")) {
                alerts.forEach(alert => {
                    if (alert?.Sensor && alert?.Sensor?.length > 0) {
                        alert.Sensor.forEach(sensor => {
                            if (sensor.Type == "shock") {
                                if (sensor.Condition == "in" || sensor.Condition == "out") {
                                    if (sensor.Value[0] < _min) {
                                        _min = Math.floor(sensor.Value[0]);
                                    }
                                } else {
                                    if (sensor.Value < _min) {
                                        _min = Math.floor(sensor.Value);
                                    }
                                }
                            }
                        });
                    }
                });
            }

            return _min;
        },
        maxShock() {
            let _max = 0;

            let shock = this.mergeSensorData.shock.map(item => item[1]) || [];

            let max = shock.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 0;
            _max = Math.round(_max);

            // If exist alert for shock sensor then update the max value to fix the coordinate values
            let alerts =
                this.tripAlertTriggers.map(x => {
                    return JSON.parse(x?.triggerJson);
                }) || [];

            let isShockAlert = false;

            if (alerts.length > 0) {
                isShockAlert = alerts.some(alert => {
                    return alert?.Sensor?.some(sensor => sensor.Type === "shock") || false;
                });
            }

            if (isShockAlert && this.selectedSensors.includes("shock")) {
                alerts.forEach(alert => {
                    if (alert?.Sensor && alert?.Sensor?.length > 0) {
                        alert.Sensor.forEach(sensor => {
                            if (sensor.Type == "shock") {
                                if (sensor.Condition == "in" || sensor.Condition == "out") {
                                    if (sensor.Value[1] > _max) {
                                        _max = Math.round(sensor.Value[1]);
                                    }
                                } else {
                                    if (sensor.Value > _max) {
                                        _max = Math.round(sensor.Value);
                                    }
                                }
                            }
                        });
                    }
                });
            }

            return _max + 5;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
    },
    created() {
        this.selectedSensors = helper.getSelectedSensors() || this.selectedSensors;
        //helper.updateDateTimeTimezone
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.formatDateTime = helper.formatDateTime;
        this.formatDateTimeWithoutTimezone = helper.formatDateTimeWithoutTimezone;
        this.relativeTime = helper.relativeTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.formatDateTimeIso = helper.formatDateTimeIso;
        this.convertDateTimeTimezoneToMilliseconds = helper.convertDateTimeTimezoneToMilliseconds;
    },
    watch: {
        selectedSensors: function(newValue, oldValue) {
            if (newValue.toString() !== oldValue.toString()) {
                helper.updateMyPreferences({ selectedSensors: newValue });
                this.$apollo.queries.trackerMergedSensorDatas.refetch();
            }
        },
        tripFreight: function(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.trackerId = this.trackers[0]?.id;
            }
        },
        trip: function(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.tripFreight = this.trip?.tripFreightSet?.edges[0]?.node?.id;
            }
        },
        trackerId: function(newValue, oldValue) {
            this.selectedTrackerSerialNumber = this.trackers.find(t => t.id === newValue)?.serialNumber;
            if (newValue === undefined || newValue === null) {
                this.showCharts = false;
            } else if (oldValue !== undefined && oldValue !== null && newValue !== oldValue) {
                this.showCharts = false;
            } else {
                this.showCharts = false;
            }
        },
        showCharts: {
            handler: function(newValue, oldValue) {
                this.historyEvents?.forEach((item, index) => {
                    if (item.node.eventDescription == "Tracker") {
                        //open all panel
                        if (newValue) {
                            this.panel[index] = 0;
                            this.monitorExpansionPanels[index] = true;
                        } else {
                            this.panel[index] = undefined;
                            this.monitorExpansionPanels[index] = false;
                        }
                    }
                });
            },
            deep: true
        }
    },
    mounted() {
        this.initialize();
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_timeline_tab_svw"
        });
        this.setUserProperties();
        this.updateScrollbarColor(this.primaryColor);
    },
    methods: {
        handlePanelChange(index, isOpen) {
            this.monitorExpansionPanels[index] = isOpen;
        },
        hasContent(item) {
            if (item.eventDescription == "Tracker") {
                return true;
            } else if (item.eventDescription == "Vessel") {
                return true;
            } else if (item.eventDescription == "Carrier") {
                return false;
            }
            return false;
        },
        dateDiff(date1, date2) {
            let dateA = new Date(date1);
            let dateB = new Date(date2);

            let diff = Math.abs(dateA - dateB);
            let diffInHours = diff / (1000 * 60 * 60);
            let diffInMinutes = diff / (1000 * 60);
            let diffInDays = diff / (1000 * 60 * 60 * 24);

            if (diffInMinutes < 60) {
                return Math.round(diffInMinutes) + " minutes";
            } else if (diffInHours < 24) {
                return Math.round(diffInHours) + " hours";
            } else {
                return Math.round(diffInDays) + " days";
            }
        },
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_timeline_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_timeline_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        getCarrierName(tenantCarrier, freight, carrier) {
            let carrierName = "";
            if (this.trip?.transportMode === "AIR") {
                carrierName = freight?.airlineName;
            } else {
                carrierName = carrier?.name;
            }

            return carrierName || tenantCarrier?.carrier?.name;
        },
        goToCarrierWebsite(link) {
            let _carrierWebsite = null;
            if (link !== undefined && link !== null && link !== "" && link !== "null") {
                try {
                    _carrierWebsite = link;
                } catch (e) {
                    //console.log(e);
                }
            }
            if (_carrierWebsite !== undefined) {
                window.open(_carrierWebsite, "_blank");
            } else {
                const payload = {
                    color: "blue",
                    message: this.$t("general.cant_open")
                };
                this.$store.dispatch("snackbar/showMessage", payload);
            }
        },
        updateScrollbarColor(color) {
            document.documentElement.style.setProperty("--scrollbar-color", color);
        },
        sensorData(sensor) {
            let result = [];

            if (!this.trackerMergedSensorDatas?.edges) {
                return [];
            }

            this.trackerMergedSensorDatas?.edges?.forEach(device => {
                const _time = this.convertDateTimeTimezoneToMilliseconds(device.node?.deviceTime);

                if (sensor === "temperature" || sensor === "externalTemperature" || sensor === "probeTemperature") {
                    if (device.node?.[sensor] != null && device.node?.[sensor] != undefined) {
                        let value = parseFloat(device?.node?.[sensor]).toFixed(2);
                        result.push([_time, this.formatTemperature(value)]);
                    }
                } else {
                    if (device.node?.[sensor] != null && device.node?.[sensor] != undefined) {
                        let value = parseFloat(device?.node?.[sensor]).toFixed(2);
                        result.push([_time, value]);
                    }
                }
            });

            return result;
        },
        getEvntbyName(event) {
            if (event.includes("flight_depart") || event.includes("flight_arrive")) {
                return "flight";
            }
        },
        getMinActivateDate(trackers) {
            let minDate = null;
            trackers.forEach(d => {
                if (!minDate || new Date(d.activatedDate) < new Date(minDate)) {
                    minDate = d.activatedDate;
                }
            });
            return minDate;
        },
        alertType(item) {
            let alert = [];
            let newObj = JSON.parse(item);

            if (newObj?.Location?.Condition) alert.push("location");

            if (newObj?.Sensor?.length > 0) {
                newObj.Sensor.forEach(item => {
                    alert.push(item.Type);
                });
            }
            return alert;
        },
        showCurrentEventAlertTime(index, currentEvent, alert) {
            let _currentTime = new Date(currentEvent).getTime();
            let _nextTime = new Date(this.getNextEventTime(index)).getTime();
            let eventAlert = [];

            if (alert.length > 0) {
                alert.forEach(item => {
                    let _alertTime = item.deviceTime;
                    if (_alertTime >= _currentTime && _alertTime < _nextTime) {
                        eventAlert.push(item);
                    }
                });
            }
            return eventAlert;
        },
        initialize() {
            this.$nextTick(() => {
                if (this.freight?.id) {
                    this.tripFreight = this.freight?.id;
                } else this.tripFreight = this.trip?.tripFreightSet?.edges[0]?.node?.id;
                /*  this.trackerId = this.trackers[0]?.id; */
            });
        },
        getNextEventTime(index) {
            let dateNow = new Date();

            return index < this.timelineEvents.length - 1
                ? this.updateDateTimeTimezone(this.timelineEvents[index + 1].node.eventTime)
                : this.formatDateTime(dateNow);
        },
        getCurrentStartTime(id) {
            let index = this.timelineEvents.findIndex(event => event.node.id === id);

            return !this.sortDescTimeline
                ? this.updateDateTimeTimezone(this.timelineEvents[index].node.eventTime)
                : this.getNextEventTime(index);
        },
        getCurrentEndTime(id) {
            let index = this.timelineEvents.findIndex(event => event.node.id === id);

            return !this.sortDescTimeline
                ? this.getNextEventTime(index)
                : this.updateDateTimeTimezone(this.timelineEvents[index].node.eventTime);
        },
        getUpdatedTimezone(date) {
            return this.updateDateTimeTimezone(date);
        },
        getDate(date) {
            return date?.slice(0, 10);
        },
        getTime(date) {
            return date?.slice(11, 16);
        },
        isFutureDate(date) {
            return new Date(date) > new Date();
        },
        getMinDate(dates) {
            let minDate = null;
            dates.forEach(d => {
                if (!minDate || new Date(d) < new Date(minDate)) {
                    minDate = d;
                }
            });
            return minDate;
        },
        getMaxDate(dates) {
            let maxDate = null;
            dates.forEach(d => {
                if (!maxDate || new Date(d) > new Date(maxDate)) {
                    maxDate = d;
                }
            });
            return maxDate;
        },
        getEventTitle(event) {
            let title = "";
            switch (event.eventType) {
                case "planned_departure_date":
                    title = `<div class='textBody'>${this.$t("headers.planned_departure")}</div>`;
                    break;
                case "planned_arrival_date":
                    title = `<div class='textBody'>${this.$t("headers.planned_arrival")}</div>`;
                    break;
                case "completed_at":
                    title = `<div class='textBody'>${this.$t("general.exits")}</div>`;
                    break;
                case "tracker_activate":
                    title = `<div class='textBody'>${this.$t("trips.tracker_activated")}</div>`;
                    break;
                case "geofence_exit":
                    title = " <div class='textBody'>" + " " + event.eventName + "</div>";
                    break;
                case "geofence_enter":
                    title = " <div class='textBody'>" + " " + event.eventName + "</div>";
                    break;
                case "vessel_depart":
                    title = " <div class='textBody'>" + this.$t("trips.in_transit") + "</div>";
                    break;
                case "vessel_arrive":
                    title = " <div class='textBody'>" + event.eventName + "</div>";
                    break;
                case "flight_depart":
                    title = " <div class='textBody'>" + this.$t("trips.in_flight") + "</div>";
                    break;
                case "flight_arrive":
                    title = " <div class='textBody'>" + event.eventName + "</div>";
                    break;
                default:
                    return `<div class='textBody'>${event.eventName}</div>`;
            }
            return title;
        },
        getCarrierEventTitle(event) {
            let title = "";
            let vessel = null;
            let voyage = null;
            let eventClassifier = "";

            if (
                event.vessel_name &&
                event.vessel_name !== "LADEN" &&
                event.vessel_name !== "EMPTY" &&
                event.vessel_name !== "N/A"
            ) {
                vessel = ". Vessel: " + event.vessel_name;
            }

            if (event.vessel_number && event.vessel_number !== "N/A") {
                vessel += ". Vessel #" + event.vessel_number;
            }
            if (event.voyage_number && event.voyage_number !== "N/A") {
                voyage = ". " + event.voyage_number;
            }

            if (event?.eventClassifier) {
                if (event.eventClassifier === "EST") {
                    eventClassifier = "Estimated";
                } else if (event.eventClassifier === "ACT") {
                    eventClassifier = "Actual";
                }
            }

            if (event?.location) {
                title = `
                    <div class="textBody" style="user-select: text;" >
                        ${eventClassifier} ${event.eventName} ${vessel ? vessel : ""} ${voyage ? voyage : ""} ${
                    event.location
                }
                    </div>
                `;
            } else {
                title = `
                    <div class='textBody' style="user-select: text;" >
                        ${eventClassifier} ${event.eventName} ${vessel ? vessel : ""} ${voyage ? voyage : ""}
                    </div>
                `;
            }

            return title || "";
        },
        getCarrierEventBody(event) {
            let title = event.description;
            if (event.hasEnvironmentData) {
                const textTitle = val => {
                    if (val) return "";
                };

                title += `
                    <div
                        class="d-flex flex-row"
                    >
                        <div
                            class="d-flex flex-column mr-2"
                        >

                            ${textTitle(event)}

                        </div>
                        <div
                            class="d-flex flex-column"
                        >
                            <div class="textHeader">
                                ${this.$t("general.humidity")}
                            </div>
                            <div class="textBody">
                                ${event.humidity ? event.humidity + "%" : ""}
                            </div>
                        </div>
                        div
                            class="d-flex flex-column"
                        >

                        ${
                            event.emails.length > 0
                                ? '<div class="textHeader">' +
                                  this.$t("general.emails") +
                                  "</div>" +
                                  '<div class="textBody">' +
                                  event.emails
                                      .map(email => {
                                          return `<v-chip label >${email}</v-chip>`;
                                      })
                                      .join("") +
                                  "</div>"
                                : ""
                        }

                    </div>
                `;
            }
            return title;
        },
        getEventSubtitle(event_type) {
            switch (event_type) {
                case "planned_departure_date":
                    return this.$t("headers.planned_departure");
                case "planned_arrival_date":
                    return this.$t("headers.planned_arrival");
                case "tracker_activate":
                    return this.$t("trips.tracker_activated");
                case "Enter":
                    return this.$t("general.enter");
                case "Exit":
                    return this.$t("general.exits");
                case "vessel_depart":
                    return this.$t("trips.departure");
                case "vessel_arrive":
                    return this.$t("trips.arrival");
                case "flight_depart":
                    return this.$t("trips.flight_departure");
                case "flight_arrive":
                    return this.$t("trips.flight_arrival");
                case "custom":
                    return "";
                default:
                    return event_type;
            }
        },
        getEventColor(event_type, item) {
            let isLessThanTwoHours = true;

            if (item) {
                let date1 = item[0]?.eventTime;
                let date2 = item[1]?.eventTime;
                let dateA = new Date(date1);
                let dateB = new Date(date2);
                let diff = Math.abs(dateA - dateB);
                isLessThanTwoHours = diff / (1000 * 60 * 60) < 2;
            }

            switch (event_type) {
                case "tracker_activate":
                    return {
                        icon: "white",
                        background: "primary"
                    };
                case "planned_departure_date":
                    return {
                        icon: "white",
                        background: "primary"
                    };
                case "planned_arrival_date":
                    return {
                        icon: "white",
                        background: "primary"
                    };
                case "completed_at":
                    return {
                        icon: "white",
                        background: "primary"
                    };
                case "event_depart":
                    return {
                        icon: "white",
                        background: "#DEB900"
                    };

                case "Carrier":
                    return {
                        icon: "white",
                        background: "grey"
                    };
                case "vessel_depart":
                case "flight_depart":
                    return {
                        icon: "white",
                        background: "#6DB4DF"
                    };
                case "transhipment":
                case "event_arrive":
                case "vessel_arrive":
                case "flight_arrive":
                    return {
                        icon: "white",
                        background: "#DEB900"
                    };
                case "Enter":
                    return {
                        icon: "white",
                        background: "#DEB900"
                    };
                case "Exit":
                    return {
                        icon: "white",
                        background: "primary"
                    };
                case "Stay":
                    return {
                        icon: "white",
                        background: "primary"
                    };
                case "geofence_enter":
                    return {
                        icon: "white",
                        background: "grey"
                    };
                /* if (isLessThanTwoHours) {
                    } else {
                        return {
                            icon: "white",
                            background: "primary"
                        };
                    } */

                case "geofence_exit":
                    return {
                        icon: "white",
                        background: "primary"
                    };
                default:
                    return {
                        icon: "white",
                        background: "primary"
                    };
            }
        },
        //Get the sensor data for the selected sensor
        getMaxSensorDataTime(sensorData) {
            const sensorTypes = [
                "temperature",
                "probeTemperature",
                "externalTemperature",
                "light",
                "humidity",
                "shock"
            ];
            let maxTime = new Date(0);

            sensorTypes.forEach(type => {
                if (sensorData?.[type]?.length > 0) {
                    const maxDate = new Date(Math.max(...sensorData[type].map(e => new Date(e[0]).getTime())));
                    if (maxDate > maxTime) {
                        maxTime = maxDate;
                    }
                }
            });

            return maxTime;
        },
        // Check if the event is a departure event and the next event is a departure event
        isEventDeparture(actual, next, future) {
            return (
                (actual.eventType === "geofence_enter" &&
                    (next?.eventType === "flight_depart" || next?.eventType === "vessel_depart")) ||
                (actual.eventType === "geofence_enter" &&
                    next?.eventType === "geofence_exit" &&
                    (future?.eventType === "flight_depart" || future?.eventType === "vessel_depart"))
            );
        },
        //Intransit Events - Hide geofence events between intransit events
        filterInTransitEvents(timeline, inTransitPoints) {
            const uniqueEvents = new Set();

            return timeline
                .filter(event => inTransitPoints.includes(event.node.eventType))
                .map((event, index, filteredEvents) => {
                    return {
                        startPoint: event,
                        endPoint: filteredEvents[index + 1] || null
                    };
                })
                .filter(({ startPoint, endPoint }) => {
                    const startPointKey = `${startPoint.node.eventType}_${startPoint.node.eventSourceType}_${startPoint.node.eventTime}`;
                    const endPointKey = endPoint
                        ? `${endPoint.node.eventType}_${endPoint.node.eventSourceType}_${endPoint.node.eventTime}`
                        : null;

                    if (uniqueEvents.has(startPointKey) || (endPointKey && uniqueEvents.has(endPointKey))) {
                        return false;
                    }

                    uniqueEvents.add(startPointKey);
                    if (endPointKey) uniqueEvents.add(endPointKey);

                    return true;
                });
        },

        //Group events by merge reference key (Merge city location)
        groupEventsByMergeReference(timeline) {
            // Filter events that have a merge reference
            const mergeReferenceEvents = timeline.filter(event => event && event.mergeReference !== null);

            // Group events by their merge reference
            const mergeReferenceGroups = mergeReferenceEvents.reduce((acc, event) => {
                const { mergeReference } = event;
                if (!acc[mergeReference]) {
                    acc[mergeReference] = [];
                }
                acc[mergeReference].push(event);
                return acc;
            }, {});

            // Create the new grouped events
            const groupedEvents = Object.values(mergeReferenceGroups)
                .map(group => {
                    // Create a grouped event
                    if (group[0]?.eventTime && group[group.length - 1]?.eventTime) {
                        if (group.length <= 2) {
                            return {
                                startPoint: {
                                    ...group[0],
                                    eventTime: this.updateDateTimeTimezone(group[0].eventTime)
                                },
                                endPoint: {
                                    ...group[group.length - 1],
                                    eventTime: this.updateDateTimeTimezone(group[group.length - 1].eventTime)
                                }
                            };
                        } else {
                            return {
                                startPoint: {
                                    ...group[0],
                                    eventName: group[0].mergeReference + "*",
                                    eventTime: this.updateDateTimeTimezone(group[0].eventTime)
                                },
                                endPoint: {
                                    ...group[group.length - 1],
                                    eventTime: this.updateDateTimeTimezone(group[group.length - 1].eventTime)
                                }
                            };
                        }
                    }
                })
                .filter(group => group !== null); // Remove any null groups

            // Create a set of merge references to remove from the original timeline
            const mergeReferencesToRemove = new Set(mergeReferenceEvents.map(event => event.mergeReference));

            // Filter out the original grouped events from the timeline
            const filteredTimeline = timeline.filter(event => !mergeReferencesToRemove.has(event.mergeReference));

            // Add the new grouped events to the timeline
            groupedEvents.forEach(group => {
                if (group.startPoint) filteredTimeline.push(group.startPoint);
                if (group.endPoint) filteredTimeline.push(group.endPoint);
            });

            // Return the updated timeline
            return filteredTimeline.sort((a, b) => new Date(a.eventTime) - new Date(b.eventTime));
        },

        //Tracker events - Organize events by merge reference key and filter events between intransit events and geofence events
        filterTimelineEvents(timeline, inTransitEvents) {
            const publicEvents = timeline.filter(event => !["city_location"].includes(event.node.eventSourceType));
            const cityEvents = timeline.filter(event => ["city_location"].includes(event.node.eventSourceType));

            let uniqueEventIds = new Set();

            return publicEvents
                .reduce((acc, currentPublicEvent, index) => {
                    const nextEvent = publicEvents[index + 1];
                    const prevEvent = publicEvents[index - 1];

                    let cityEventsInRange = cityEvents.filter(cityEvent => {
                        let nextTime = nextEvent ? new Date(nextEvent.node.eventTime) : null;
                        let currentTime = new Date(currentPublicEvent.node.eventTime);
                        let cityEventTime = new Date(cityEvent.node.eventTime);

                        // Check if the city event time falls within the current and next public event time
                        let inRange = nextEvent
                            ? cityEventTime >= currentTime && cityEventTime <= nextTime
                            : cityEventTime >= currentTime;

                        // Debugging: Log city event and whether it's in range
                        return inRange;
                    });

                    //before in-flight or in-transit events
                    if (nextEvent && ["flight_depart", "vessel_depart"].includes(nextEvent.node.eventType)) {
                        if (
                            ["public_airport", "public_seaport", "tenant_location", "flight_detect"].includes(
                                currentPublicEvent.node.eventSourceType
                            ) ||
                            ["vessel_depart", "vessel_arrive"].includes(currentPublicEvent.node.eventType)
                        ) {
                            acc.push(currentPublicEvent);
                        } else if (cityEventsInRange.length > 0) {
                            //This is active only currentPublicEvent.node.eventSourceType is null
                            acc.push(cityEventsInRange[0]);
                        }
                    }

                    //after in-flight or in-transit events
                    if (prevEvent && ["flight_arrive", "vessel_arrive"].includes(prevEvent.node.eventType)) {
                        if (
                            ["public_airport", "public_seaport", "tenant_location", "flight_detect"].includes(
                                currentPublicEvent.node.eventSourceType
                            ) ||
                            ["vessel_depart", "vessel_arrive"].includes(currentPublicEvent.node.eventType)
                        ) {
                            acc.push(currentPublicEvent);
                        } else if (cityEventsInRange.length > 0) {
                            //This is active only currentPublicEvent.node.eventSourceType is null
                            acc.push(cityEventsInRange[0]);
                        }
                    }

                    //between public events ['tracker_activate', "public_airport", "public_seaport", "tenant_location"]
                    let publicResources = ["public_airport", "public_seaport", "tenant_location", "tracker_activate"];
                    let mainKeys = ["flight_depart", "vessel_depart", "flight_arrive", "vessel_arrive"];

                    if (
                        currentPublicEvent &&
                        currentPublicEvent.node &&
                        (publicResources.includes(currentPublicEvent.node.eventSourceType) ||
                            publicResources.includes(currentPublicEvent.node.eventType)) &&
                        nextEvent &&
                        nextEvent.node &&
                        (publicResources.includes(nextEvent.node.eventType) ||
                            publicResources.includes(nextEvent.node.eventSourceType))
                    ) {
                        if (cityEventsInRange.length > 0) {
                            // Ensure all city events in range have a valid structure before pushing
                            let validCityEvents = cityEventsInRange.filter(
                                event => event && event.node && event.node.id
                            );

                            acc.push(currentPublicEvent);
                            acc.push(...validCityEvents); // Use the spread operator to push all valid events
                        } else {
                            acc.push(currentPublicEvent);
                        }
                    } else {
                        acc.push(currentPublicEvent);
                    }

                    // always handle in_filght events
                    if (
                        [
                            "flight_depart",
                            "vessel_depart",
                            "flight_arrive",
                            "vessel_arrive",
                            "tracker_activate"
                        ].includes(currentPublicEvent.node.eventType)
                    ) {
                        // Exception - Hide events in transit
                        const keysToIgnore = ["geofence_enter", "geofence_exit"];
                        if (keysToIgnore.includes(currentPublicEvent.node.eventType)) {
                            for (let transit of inTransitEvents) {
                                if (transit.endPoint) {
                                    let startTime = new Date(transit.startPoint.node.eventTime);
                                    let endTime = new Date(transit.endPoint.node.eventTime);
                                    endTime.setSeconds(endTime.getSeconds() + 1);
                                    let currentTime = new Date(currentPublicEvent.node.eventType);
                                    if (currentTime >= startTime && currentTime <= endTime) {
                                        return false;
                                    }
                                }
                            }
                        }

                        acc.push(currentPublicEvent);
                    }

                    // Add the last city event if it is the last public event and the event is an arrival event (flight_arrive, vessel_arrive)
                    if (
                        cityEventsInRange.length > 0 &&
                        publicEvents.length - 1 === index &&
                        ["flight_arrive", "vessel_arrive"].includes(currentPublicEvent.node.eventType)
                    ) {
                        cityEvents.forEach(cityEvent => {
                            let currentTime = new Date(currentPublicEvent.node.eventTime);
                            let cityEventTime = new Date(cityEvent.node.eventTime);

                            if (cityEventTime > currentTime) {
                                acc.push(cityEvent);
                            }
                        });
                    }

                    return acc;
                }, [])
                .map(({ node }) => {
                    if (uniqueEventIds.has(node.id)) return null;
                    uniqueEventIds.add(node.id);
                    return {
                        ...node,
                        eventTime: node.eventTime ? this.updateDateTimeTimezone(node.eventTime) : null
                    };
                })
                .filter(event => event !== null)
                .sort((a, b) => new Date(a.eventTime) - new Date(b.eventTime));
        },
        //Merge public and city location events into a single timeline
        mergePublicAndCityEvents(timeline, inTransitEvents) {
            // Separate events into public and city location events
            const _publicEvents = timeline.filter(event => !["city_location"].includes(event.node.eventSourceType));
            const _cityEvents = timeline.filter(event => ["city_location"].includes(event.node.eventSourceType));
            let uniqueEventIds = new Set();

            // Merge events into a single timeline
            return _publicEvents
                .reduce((acc, publicEvent, index) => {
                    const nextEvent = _publicEvents[index + 1];
                    const prevEvent = _publicEvents[index - 1];

                    let cityEventsInRange = _cityEvents.filter(cityEvent => {
                        let nextTime = nextEvent ? new Date(nextEvent.node.eventTime) : null;
                        let currentTime = new Date(publicEvent.node.eventTime);
                        let cityEventTime = new Date(cityEvent.node.eventTime);

                        return nextEvent
                            ? cityEventTime >= currentTime && cityEventTime <= nextTime
                            : cityEventTime >= currentTime;
                    });

                    // Original condition before in-flight or in-transit events
                    if (nextEvent && ["flight_depart", "vessel_depart"].includes(nextEvent.node.eventType)) {
                        if (
                            ["public_airport", "public_seaport", "tenant_location", "flight_detect"].includes(
                                publicEvent.node.eventSourceType
                            ) ||
                            ["vessel_depart", "vessel_arrive"].includes(publicEvent.node.eventType)
                        ) {
                            acc.push(publicEvent);
                        } else if (cityEventsInRange.length > 0) {
                            acc.push(cityEventsInRange[0]);
                        }
                    }

                    // Original condition after in-flight or in-transit events
                    if (prevEvent && ["flight_arrive", "vessel_arrive"].includes(prevEvent.node.eventType)) {
                        if (
                            ["public_airport", "public_seaport", "tenant_location", "flight_detect"].includes(
                                publicEvent.node.eventSourceType
                            ) ||
                            ["vessel_depart", "vessel_arrive"].includes(publicEvent.node.eventType)
                        ) {
                            acc.push(publicEvent);
                        } else if (cityEventsInRange.length > 0) {
                            acc.push(cityEventsInRange[0]);
                        }
                    }

                    //between public events ['tracker_activate', "public_airport", "public_seaport", "tenant_location"]
                    let publicResources = ["public_airport", "public_seaport", "tenant_location", "tracker_activate"];
                    if (
                        publicResources.includes(publicEvent.node.eventSourceType) ||
                        publicResources.includes(publicEvent.node.eventType)
                    ) {
                        acc.push(publicEvent);
                    } else if (
                        publicEvent.node.eventSourceType === null &&
                        ["geofence_enter", "geofence_exit"].includes(publicEvent.node.eventType)
                    ) {
                        acc.push(publicEvent);
                    } else {
                        acc.push(publicEvent);
                    }

                    // Always add certain types of public events
                    if (
                        [
                            "tracker_activate",
                            "flight_depart",
                            "vessel_depart",
                            "flight_arrive",
                            "vessel_arrive"
                        ].includes(publicEvent.node.eventType)
                    ) {
                        acc.push(publicEvent);
                    }

                    // Add the last city event if it is the last public event and the event is an arrival event (flight_arrive, vessel_arrive)
                    if (
                        cityEventsInRange.length > 0 &&
                        _publicEvents.length - 1 === index &&
                        ["flight_arrive", "vessel_arrive"].includes(publicEvent.node.eventType)
                    ) {
                        acc.push(cityEventsInRange[0]);
                    }

                    return acc;
                }, [])
                .map(({ node }) => {
                    if (uniqueEventIds.has(node.id)) return null;
                    uniqueEventIds.add(node.id);
                    return {
                        ...node,
                        eventTime: node.eventTime ? this.convertDateTimeTimezoneToMilliseconds(node.eventTime) : null
                    };
                })
                .filter(event => event !== null)
                .sort((a, b) => new Date(a.eventTime) - new Date(b.eventTime));
        },

        //Create the panel content for the timeline events and geofence events
        createTrackerEvents(timelineEventsNew, enterEvents, exitEvents, flagEvent) {
            return timelineEventsNew.reduce((result, event, index, array) => {
                const nextEvent = array[index + 1];
                const futureEvent = array[index + 2];
                const prevEvent = array[index - 1];
                const isEnterEvent = enterEvents.includes(event.eventType);
                const isLastEvent = index === array.length - 1;
                let eventName = event.eventName;

                if (nextEvent && !isLastEvent) {
                    let tag = event.eventType;
                    let isTrulyEvent = true;

                    if (isEnterEvent) {
                        if (this.isEventDeparture(event, nextEvent, futureEvent)) {
                            tag = "event_depart";
                            if (["flight_depart", "vessel_depart"].includes(nextEvent?.eventType)) {
                                eventName = nextEvent?.eventName;
                            }
                            if (["flight_depart", "vessel_depart"].includes(futureEvent?.eventType)) {
                                eventName = futureEvent?.eventName;
                            }
                        } else if (
                            ["flight_arrive", "vessel_arrive"].includes(event?.eventType) &&
                            ["geofence_enter", "geofence_exit"].includes(nextEvent.eventType)
                        ) {
                            tag = "event_arrive";
                        } else if (
                            ["flight_arrive", "vessel_arrive"].includes(prevEvent?.eventType) &&
                            ["geofence_enter", "geofence_exit"].includes(event.eventType)
                        ) {
                            if (result.length > 0) {
                                const lastEvent = result[result.length - 1];
                                if (!(lastEvent.tag === "event_arrive")) {
                                    tag = "event_arrive";
                                }
                            }
                        } else if (
                            ["flight_arrive", "vessel_arrive"].includes(event.eventType) &&
                            ["flight_depart", "vessel_depart"].includes(nextEvent.eventType)
                        ) {
                            tag = "transhipment";
                        } else {
                            if (
                                event.eventType === "geofence_enter" &&
                                ["vessel_arrive", "flight_arrive"].includes(nextEvent.eventType)
                            ) {
                                isTrulyEvent = false;
                            }
                        }

                        //Manipulate huge time gap between geofence events
                        if (isTrulyEvent) {
                            //Manipulate huge time gap between geofence events
                            if (
                                event?.eventType === "geofence_enter" &&
                                nextEvent?.eventType === "geofence_exit" &&
                                ["geofence_enter", "geofence_exit"].includes(futureEvent?.eventType)
                            ) {
                                let dateA = new Date(event.eventTime);
                                let dateB = new Date(nextEvent.eventTime);
                                let dateC = new Date(futureEvent.eventTime);

                                const diffAB = moment(dateB).diff(moment(dateA), "minutes");
                                const diffBC = moment(dateC).diff(moment(dateB), "minutes");

                                if (diffBC >= diffAB) {
                                    result.push({
                                        "0": { ...event, eventDescription: "Tracker" },
                                        "1": { ...futureEvent, eventDescription: "Tracker" },
                                        tag
                                    });
                                } else {
                                    result.push({
                                        "0": { ...event, eventDescription: "Tracker" },
                                        "1": { ...nextEvent, eventDescription: "Tracker" },
                                        tag
                                    });
                                }
                            } else {
                                result.push({
                                    "0": {
                                        ...event,
                                        eventName: eventName,
                                        eventDescription: event.eventSource === "vessel" ? "Vessel" : "Tracker"
                                    },
                                    "1": {
                                        ...nextEvent,
                                        eventDescription: nextEvent.eventSource === "vessel" ? "Vessel" : "Tracker"
                                    },
                                    tag
                                });
                            }
                        }
                    } else if (
                        exitEvents.includes(event.eventType) &&
                        ["flight_depart", "vessel_depart"].includes(nextEvent.eventType)
                    ) {
                        result.push({
                            "0": {
                                ...event,
                                eventName: nextEvent.eventName,
                                eventDescription: event.eventSource === "vessel" ? "Vessel" : "Tracker"
                            },
                            "1": {
                                ...nextEvent,
                                eventDescription: nextEvent.eventSource === "vessel" ? "Vessel" : "Tracker"
                            },
                            tag: "event_depart"
                        });
                    }
                } else if (
                    isLastEvent &&
                    !exitEvents.includes(event.eventType) &&
                    prevEvent?.eventType !== "geofence_enter" &&
                    flagEvent === "tracker"
                ) {
                    let now = new Date();
                    const endTime =
                        this.mergeSensorData.length > 0 ? this.getMaxSensorDataTime(this.mergeSensorData) : now;

                    result.push({
                        "0": { ...event, eventDescription: "Tracker" },
                        "1": {
                            eventName: null,
                            eventTime: this.updateDateTimeTimezone(endTime),
                            eventType: "in_progress",
                            eventSourceType: event.eventSourceType,
                            eventDescription: "Tracker",
                            mergeReference: event.mergeReference
                        },
                        tag: event.eventType
                    });
                }

                return result;
            }, []);
        },
        //Filter the timeline events by unique event ids
        filterAndSortTimelineEvents(mergedTimeline, uniqueEventIds) {
            return mergedTimeline
                .map(({ node }) => {
                    if (uniqueEventIds.has(node.id)) return null;
                    uniqueEventIds.add(node.id);
                    return {
                        ...node,
                        eventTime: node.eventTime ? this.convertDateTimeTimezoneToMilliseconds(node.eventTime) : null
                    };
                })
                .filter(event => event !== null);
        },
        //Filter the timeline events by unique event ids
        filterAndSortTrackerEvents(mergedTimeline, uniqueEventIds) {
            return mergedTimeline
                .map(({ node }) => {
                    if (uniqueEventIds.has(node.id)) return null;
                    uniqueEventIds.add(node.id);
                    return {
                        ...node,
                        eventTime: node.eventTime ? this.convertDateTimeTimezoneToMilliseconds(node.eventTime) : null
                    };
                })
                .filter(event => event !== null);
        },
        //Sort the timeline events by event time
        filterShortEvents(trackerEvents) {
            return trackerEvents.filter(event => {
                const diff = moment(event["1"]?.eventTime).diff(moment(event["0"]?.eventTime), "minutes");
                const keysToHide = ["geofence_enter", "geofence_exit"];
                if (event["1"] && keysToHide.includes(event.tag)) {
                    return diff !== 0;
                }

                return true;
            });
        },
        //Filter the geofence events by unique event ids
        filterShortGeofenceEvents(geofencesEvents) {
            return geofencesEvents.filter(event => {
                if (event["1"] && !this.summarized) {
                    let timeA = new Date(event["0"].eventTime);
                    let timeB = new Date(event["1"].eventTime);

                    const diff = moment(timeB).diff(moment(timeA), "minutes");
                    const keysToHide = ["geofence_enter", "geofence_exit"];
                    if (keysToHide.includes(event["0"].eventType) && keysToHide.includes(event["1"].eventType)) {
                        return diff !== 0;
                    }
                }
                return true;
            });
        },
        historyEventColor(description) {
            switch (description) {
                case "Carrier":
                    return {
                        background: "#FBDCDA",
                        color: "#EB4F46"
                    };
                case "Timeline":
                    return {
                        background: "#D9E8FF",
                        color: "#1C72A5"
                    };
                case "Vessel":
                    return {
                        background: "#E8F5E9FF",
                        color: "#43A047FF"
                    };
                default:
                    return {
                        background: "#D9E8FF",
                        color: "#1C72A5"
                    };
            }
        },
        getEventIcon(event_type, item) {
            let isLessThanTwoHours = true;

            if (item) {
                let date1 = item[0]?.eventTime;
                let date2 = item[1]?.eventTime;
                let dateA = new Date(date1);
                let dateB = new Date(date2);
                let diff = Math.abs(dateA - dateB);
                isLessThanTwoHours = diff / (1000 * 60 * 60) < 2;
            }

            let icon = "";
            switch (event_type) {
                case "planned_departure_date":
                    return "flag";
                case "planned_arrival_date":
                    return "sports_score";
                case "completed_at":
                    return "sports_score";
                case "tracker_activate":
                    return "house";
                case "geofence_enter":
                    if (isLessThanTwoHours) {
                        return "local_shipping";
                    } else {
                        return "location_on";
                    }
                case "geofence_exit":
                    return "logout";
                case "vessel_depart":
                    return "sailing";
                case "flight_depart":
                    return "flight";
                case "flight_arrive":
                    return "flight_land";
                case "vessel_arrive":
                case "event_arrive":
                    if (this.trip.transportMode == "AIR") {
                        icon = "flight_land";
                    }
                    if (this.trip.transportMode == "SEA") {
                        icon = "directions_boat";
                    }
                    return icon;
                case "event_depart":
                    if (this.trip.transportMode == "AIR") {
                        icon = "flight_takeoff";
                    }
                    if (this.trip.transportMode == "SEA") {
                        icon = "directions_boat";
                    }
                    return icon;
                case "Exit":
                    return "logout";
                case "Stay":
                    return "crop_free";
                case "transhipment":
                    if (this.trip.transportMode == "AIR") {
                        icon = "multiple_stop";
                    }
                    if (this.trip.transportMode == "SEA") {
                        icon = "directions_boat";
                    }

                    return icon;
                case "custom":
                    return "";
                case "Carrier":
                    if (this.trip.transportMode == "AIR") return "commute" /* "flight" */;
                    if (this.trip.transportMode == "SEA") return "commute" /* "sailing" */;
                    if (this.trip.transportMode == "RAIL") return "commute" /* "train" */;
                    if (this.trip.transportMode == "ROAD") return "commute" /* "local_shipping" */;
                    return "";
                default:
                    return "";
            }
        }
    }
};
</script>
<style scoped>
.maxWidth {
    max-width: 100%;
    min-width: 270px;
}
.maxScreenWidth {
    width: 100%;
    min-width: 300px;
}
.maxHeight {
    max-height: 350px;
    min-height: 290px;
}
.chartContainer {
    height: 280px;
}
.maxHeight {
    max-height: 18px;
}
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
    color: #e6e9f5;
}
.contentBg {
    background-color: #f9fafe;
}
.cardContainer {
    height: 660px;
    overflow-y: auto;
    margin-bottom: 12px;
    margin-top: 12px;
}
.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 15px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 15px;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}

.timeText {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
    color: #6c6c6c;
}
.timeText2 {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #6c6c6c;
}
.statusText {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: center;
}
</style>
