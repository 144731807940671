<template>
    <v-card fill-height flat width="100%" v-if="location" color="background">
        <!-- top bar -->
        <v-toolbar flat color="transparent">
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn
                v-if="shouldShowFullScreenButton"
                icon
                @click="changeFullScreen"
            >
                <v-icon>{{
                    isFullScreen ? "fullscreen_exit" : "crop_free"
                }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div v-if="title !== $t('partners.public_locations')">
                <!--Button to edit location-->
                <v-btn icon @click="editMap">
                    <v-icon>edit</v-icon>
                </v-btn>
                <!--Button to delete location-->
                <v-btn icon @click="openDeleteDialog = true">
                    <v-icon>delete</v-icon>
                </v-btn>
                <DeleteLocationDialog
                    :openDeleteDialog="openDeleteDialog"
                    :locationID="location.id"
                    v-on:refreshDataTable="$emit('refreshDataTable')"
                    v-on:closeDeleteDialog="openDeleteDialog = false"
                    v-on:locationDeleted="$emit('onLocationDeleted')"
                >
                    <template #title>{{
                        $t("partners.delete_location")
                    }}</template>
                    <template #content>{{
                        $t("partners.sure_location")
                    }}</template>
                </DeleteLocationDialog>
                <v-btn icon>
                    <v-icon> share </v-icon>
                </v-btn>
            </div>
            <!-- TAB FILTERS -->
            <!-- <FilterMenu
                :title="$t('dashboard.customize')"
                :description="$t('dashboard.display_modules')"
                :choices.sync="filterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
                class="mr-0"
            >
            </FilterMenu> -->
        </v-toolbar>

        <!-- trip header -->
        <v-sheet
            class="
                pt-0
                mb-5
                px-5
                background
                d-flex
                align-center
                justify-space-between
            "
        >
            <h3>{{ location ? location.name : "" }}</h3>

            <!-- trip status
            <div class="ml-3">
                <div class="font-weight-medium d-flex align-center">
                    <v-icon small left color="primary">circle</v-icon>
                    {{ tripStatusText }}
                </div>
            </div> -->

            <v-spacer></v-spacer>

            <!-- origin & destination -->
            <!-- <v-card
                flat
                outlined
                rounded="xl"
                class="d-flex align-center px-3 font-weight-normal pa-2"
            >
                <span>Shanghai, China</span>
                <v-icon class="mx-3">directions_boat</v-icon>
                
                <span>Rotterdam, Netherland</span>
            </v-card> -->

            <v-spacer></v-spacer>

            <v-select
                v-model="viewType"
                :items="viewTypeChoices"
                dense
                item-text="name"
                item-value="code"
                return-value
                outlined
                hide-details
                class="fit"
            ></v-select>

            <!-- trip origin & destination -->
            <!-- <v-card
                outlined
                flat
                rounded="xl"
                class="d-flex align-center px-3 my-2"
            >
                <span>Shanghai, China</span>
                <v-icon class="mx-3">flight_takeoff</v-icon>
                <span>Rotterdam, Netherland</span>
            </v-card> -->
        </v-sheet>

        <!-- tab view -->
        <LocationDetailTabView
            ref="tabView"
            v-if="viewType === 'tab'"
            :location="location"
            :selectedFilters="selectedFilters"
            :title="title"
            @updateLocationsTable="updateLocationsTable"
        />

        <!-- list view -->
        <LocationDetailListView
            ref="listView"
            v-if="viewType === 'list'"
            class="mx-5 pb-10"
            :location="location"
            :selectedFilters="selectedFilters"
            :title="title"
            @updateLocationsTable="updateLocationsTable"
        />
    </v-card>
</template>

<script>
// import FilterMenu from "@/components/base/FilterMenu.vue";
import LocationDetailTabView from "@/components/locations/LocationDetailTabView.vue";
import LocationDetailListView from "@/components/locations/LocationDetailListView.vue";
import helper from "../../utils/helper";
import DeleteLocationDialog from "@/components/locations/delete/DeleteLocationDialog.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        location: {
            type: Object,
            required: true
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            default: "locations"
        }
    },
    components: {
        // FilterMenu,
        LocationDetailTabView,
        LocationDetailListView,
        DeleteLocationDialog
    },
    data() {
        return {
            viewType: "tab",
            viewTypeChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            openDeleteDialog: false,
            enableEditing: false,
            center: {
                lat: 40.73061,
                lng: -73.935242
            },

            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "map",
                    name: "Map",
                    tag: "headers.map",
                    enabled: true
                }
            ],

            filterChoices: []
        };
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];
            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        }
    },
    watch: {},
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    locationDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    locationDetailFilterChoices: value
                });
            },
            { deep: true }
        );
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'location_details_svw',
        });
        this.setUserProperties();
    },
    created() {
        this.viewType = helper.getViewType("locationDetailViewType");
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "locationDetailFilterChoices"
        );
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("location_details_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("location_details_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        updateLocationsTable() {
            this.$emit("refreshDataTable");
        },
        editMap() {
            if (this.viewType === "tab") {
                this.$refs.tabView.editMethod();
            } else {
                this.$refs.listView.editMethod();
            }
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        }
    }
};
</script>
<style scoped>
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
</style>
