<template>
    <v-dialog max-width="600" v-model="openAddPartnerDialog" persistent>
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("partners.add_partner") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pt-6">
                <v-form ref="partnerForm">
                    <v-row dense>
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="partner_name"
                                :label="$t('partners.partner_name') + '*'"
                                :rules="nameRules"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="description"
                                :label="$t('general.description')"
                                :rules="[rules.maxLength(200)]"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                            v-if="partnerByType === 'carrier'"
                        >
                            <v-select
                                v-model="transportMode"
                                :items="transportModeChoices"
                                :label="$t('headers.carrier_type')"
                                outlined
                                @change="filterByType(transportMode)"
                                dense
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                            v-if="
                                (transportMode === 'sea' &&
                                    partnerByType === 'carrier') ||
                                    (transportMode === 'air' &&
                                        partnerByType === 'carrier')
                            "
                        >
                            <v-select
                                v-model="carrier"
                                :items="allCarriers ? allCarriers.edges : []"
                                :no-data-text="$t('loading.no_available')"
                                :label="$t('partners.standard_carriers')"
                                item-text="node.name"
                                item-value="node.id"
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="reference_number"
                                :label="$t('headers.reference_number')"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-checkbox
                                v-model="notification_checkbox"
                                :label="$t('headers.enable_notifications')"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTIONS --->
            <v-card-actions class="d-flex justify-end pb-3 pr-5">
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isLoadingPartnerAdd"
                    @click="createTenantPartner"
                    >{{ $t("general.add") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "AddPartnerDialog",
    props: {
        openAddPartnerDialog: {
            type: Boolean,
            default: false
        },
        partnerByType: {
            type: String
        }
    },
    apollo: {
        allCarriers: {
            query: gql`
                query allCarriers(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $carrierType: String
                ) {
                    allCarriers(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: "name"
                        carrierType: $carrierType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                code
                                name
                                carrierType
                            }
                        }
                    }
                }
            `,
            variables() {},
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.allCarriers,
            skip: false
        }
    },
    data() {
        return {
            partner_name: null,
            description: null,
            reference_number: null,
            notification_checkbox: false,
            transportMode: "",
            carrier: "",
            partnerTypeChoices: [
                this.$t("headers.consignor"),
                this.$t("headers.consignee"),
                this.$t("headers.carrier"),
                this.$t("headers.forwarder"),
                this.$t("home.other")
            ],
            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            // ],
            error: false,
            isLoadingPartnerAdd: false,
            nameRules: [v => !!v || this.$t("rules.name")],
            rules: {
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    watch: {},
    computed: {
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        }
    },
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.partner_name = null;
            this.description = null;
            this.reference_number = null;
            this.notification_checkbox = false;
            this.error = false;
            this.$refs.partnerForm.resetValidation();
            this.$emit("closeAddPartnerDialog");
        },
        /* ---> TENANT PARTNER MUTATION <--- */
        createTenantPartner() {
            // validate form input :
            if (!this.$refs.partnerForm.validate()) return;
            // Initialize Loading :
            this.isLoadingPartnerAdd = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation createTenantPartner(
                            $input: CreateTenantPartnerInput!
                        ) {
                            createTenantPartner(input: $input) {
                                tenantPartner {
                                    id
                                    description
                                    partnerType
                                    carrierType
                                    name
                                    referenceNumber

                                    notificationEnabled
                                    createdBy {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            partnerType: this.partnerByType,
                            name: this.partner_name,
                            description: this.description,
                            referenceNumber: this.reference_number,
                            notificationEnabled: this.notification_checkbox,
                            organisationPermission: null,
                            carrierType: this.transportMode,
                            carrierId: this.carrier
                        }
                    }
                })
                .then(response => {
                    // Emit event to tabel data and load new partners //
                    this.$emit("refreshPartnersTable");
                    this.$emit(
                        "item-created",
                        response.data.createTenantPartner
                    );

                    const payload = {
                        color: "blue",
                        message: this.$t("trips.partner_saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    if (error.message == "INVALID_REFERENCE_NUMBER")
                        this.error = true;
                    this.isLoadingPartnerAdd = false;
                })
                .finally(() => {
                    this.isLoadingPartnerAdd = false;
                    this.closeAndResetInput();
                });
        },
        filterByType(mode) {
            if (mode === "sea" || mode === "air") {
                this.$apollo.queries.allCarriers
                    .setVariables({
                        carrierType: mode
                    })
                    .then(() => {
                        //this.tenantPartners=response.data.tenantPartners.edges.map(e => e.node)
                    })
                    .catch(error => {
                        console.error(error);
                        this.error = error;
                    })
                    .finally(() => {});
            } else {
                this.carrier = "";
            }
        }
    }
};
</script>
