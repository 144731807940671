<template>
    <v-dialog v-model="openDialog" persistent max-width="400px">
        <v-card>
            <v-card-title class="primary py-3 mb-2 white--text">
                <v-icon color="white" class="mr-1">done</v-icon>
                {{ $t("trips.confirm_action") }}
            </v-card-title>
            <v-card-text>
                <div>
                    <span>
                        <h4>
                            {{
                                trip.status == "COMPLETED"
                                    ? this.$t("trips.confirm_reopen")
                                    : this.$t("trips.confirm_mark")
                            }}
                        </h4>
                    </span>
                </div>
                <p></p>
            </v-card-text>
            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn text rounded @click="$emit('closeDialog')">Cancel</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isUpdating"
                    @click="updateTripStatus"
                    >{{
                        trip.status == "COMPLETED"
                            ? this.$t("trips.reopen")
                            : this.$t("trips.complete")
                    }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    props: {
        openDialog: {
            type: Boolean,
            default: false
        },
        trip: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            show: false,
            isUpdating: false
        };
    },
    watch: {},
    methods: {
        updateTripStatus() {
            this.isUpdating = true;
            let _status = "";
            if (this.trip.status != "COMPLETED") {
                _status = "completed";
            } else {
                _status = "active";
            }
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTripStatus(
                            $input: UpdateTripStatusInput!
                        ) {
                            updateTripStatus(input: $input) {
                                trip {
                                    id
                                    status
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tripId: this.trip.id,
                            status: _status
                        }
                    }
                })
                .then(data => {
                    this.$emit("closeDialog");
                    this.$emit(
                        "updateTripStatus",
                        data.data.updateTripStatus.trip
                    );
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isUpdating = false;
                });
        }
    }
};
</script>
