<template>
    <v-dialog max-width="600" v-model="openAddUserGroupDialog" persistent>
        <v-card>
            <!--- ICON AND TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("users.add_user") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pt-3">
                <v-form ref="userGroupForm">
                    <v-col cols="12" class="mx-0 px-0 mt-6">
                        <v-autocomplete
                            dense
                            v-model="selectedGroup"
                            :items="groups.edges"
                            :no-data-text="$t('loading.no_available')"
                            :label="$t('users.select_group') + '*'"
                            outlined
                            hide-selected
                            hide-details
                            deletable-chips
                            small-chips
                            item-text="node.name"
                            item-value="node.id"
                            multiple
                            class="mx-3"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-form>
            </v-card-text>
            <!--- ACTION BUTTONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    :loading="isLoadingAddUserGroup"
                    @click="addUserGroup"
                    color="primary"
                    rounded
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";

export default {
    name: "AddUserGroupDialog",
    props: ["openAddUserGroupDialog", "user", "userGroups"],
    components: {
        // AddUserDialog
    },
    apollo: {
        groups: {
            query: gql`
                query groups {
                    coldchaGroups {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables() {
                return {};
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.coldchaGroups;
            },
            skip: false
        }
    },
    data() {
        return {
            selectedGroup: [],
            groups: { edges: [] },

            isLoadingAddUserGroup: false,

            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v =>
                    /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {
        userGroups() {
            this.selectedGroup = this.userGroups.edges.map(item => {
                return item.node.id;
            });
        }
    },
    methods: {
        closeAndResetInput() {
            this.$refs.userGroupForm.resetValidation();
            this.$emit("closeAddDialog");
        },
        addUserGroup() {
            // validate form input :
            if (!this.$refs.userGroupForm.validate()) return;
            // Initialize Loading :
            this.isLoadingAddUserGroup = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateUserStaffGroup(
                            $input: UpdateUserStaffGroupInput!
                        ) {
                            updateUserStaffGroup(input: $input) {
                                groups {
                                    id
                                    name
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.user.id,
                            groups: this.selectedGroup
                        }
                    }
                })
                .then(() => {
                    this.$emit("refreshDataTable");
                    this.closeAndResetInput();
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.isLoadingAddUserGroup = false;
                });
        }
    }
};
</script>
