import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":_vm.offsetIsY,"offset-x":!_vm.offsetIsY,"right":!_vm.offsetIsY,"origin":"center center","position-x":30},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.displayFormat === 'text')?_c('div',[_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t('language.language'))+" ")])]):(_vm.displayFormat === 'list-item')?_c('div',[_c(VListItem,_vm._g({directives:[{def: Ripple,name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],attrs:{"link":""}},on),[_c(VListItemAction,[_c(VBadge,{attrs:{"avatar":"","bordered":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c(VAvatar,{attrs:{"size":"25"}},[_c(VImg,{attrs:{"src":_vm.selectedFlag}})],1)]},proxy:true}],null,true)},[_c(VIcon,[_vm._v("language")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(_vm.$t('language.language')))])])],1)],1)],1):_c('div',[_c(VBtn,_vm._g({staticClass:"pa-0 ma-0",attrs:{"icon":"","small":"","height":"24","width":"24"}},on),[_c(VImg,{attrs:{"src":_vm.selectedFlag,"height":"24","width":"24"}})],1)],1)]}}])},[_c(VList,_vm._l((_vm.locales),function(item){return _c(VListItem,{key:item.locales,on:{"click":function($event){return _vm.changeLocale(item)}}},[_c(VListItem,{staticClass:"px-0"},[_c('img',{staticClass:"mr-2",attrs:{"src":item.flag}}),_vm._v(" "+_vm._s(item.localeName)+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }