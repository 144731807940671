<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <LoadingBar v-if="$apollo.queries.tripReports.loading"></LoadingBar>
            <div v-else>
                <v-card elevation="5" class="mb-0 pb-0" rounded="xl">
                    <!-- card title (air) -->
                    <v-card-title class="background2 py-3">
                        <div class="mr-2">
                            <v-icon>description</v-icon>
                        </div>
                        <span>{{ $t("headers.reports") }}</span>
                        <v-spacer></v-spacer>
                        <GenerateReportDialog
                            v-if="canCreate"
                            :trip="trip"
                            v-on:reportGenerated="refetch"
                        ></GenerateReportDialog>
                    </v-card-title>
                    <v-card-text class="py-0">
                        <v-card flat>
                            <LoadingBar
                                v-if="$apollo.queries.tripReports.loading"
                            ></LoadingBar>
                            <v-card-text v-else class="">
                                <v-data-table
                                    v-if="tripReports.edges.length > 0"
                                    :headers="headers"
                                    :items="
                                        tripReports ? tripReports.edges : []
                                    "
                                    :loading-text="$t('loading.loading1')"
                                    mobile-breakpoint="1000"
                                    disable-pagination
                                    hide-default-footer
                                    :single-expand="true"
                                    :expanded.sync="expanded"
                                    @click:row="expandRow"
                                    sort-by="node.createdAt"
                                    :sort-desc="true"
                                    item-key="node.id"
                                    class="mb-2"
                                >
                                    <template
                                        v-slot:item.node.createdAt="{ item }"
                                    >
                                        <span>{{
                                            formatDateTime(item.node.createdAt)
                                        }}</span>
                                    </template>

                                    <template v-slot:item.dateRange="{ item }">
                                        <span>{{
                                            formatDateTime(
                                                item.node.startDate
                                            ).split(" ")[0]
                                        }}</span>
                                        <span> - </span>
                                        <span>{{
                                            formatDateTime(
                                                item.node.endDate
                                            ).split(" ")[0]
                                        }}</span>
                                    </template>

                                    <template
                                        v-slot:item.node.sensorData="{ item }"
                                    >
                                        <v-tooltip
                                            bottom
                                            v-for="sensorType in getSensorTypes(
                                                item.node.sensorData
                                            )"
                                            :key="sensorType.text"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    {{ sensorType.icon }}
                                                </v-icon>
                                            </template>
                                            <span>{{ sensorType.text }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template
                                        v-slot:item.node.reportType="{ item }"
                                    >
                                        <span>{{ item.node.reportType }}</span>
                                    </template>

                                    <template
                                        v-slot:item.action="{ item, slot }"
                                    >
                                        <v-btn
                                            small
                                            icon
                                            v-if="item.node.isCompleted"
                                            @click="
                                                downloadReport(
                                                    item.node.document.url
                                                )
                                            "
                                        >
                                            <v-icon>download</v-icon>
                                        </v-btn>

                                        <v-btn
                                            small
                                            icon
                                            v-else
                                            loading
                                            disabled
                                        >
                                            {{ $t("loading.loading1") }}
                                        </v-btn>
                                    </template>

                                    <template
                                        v-slot:expanded-item="{ headers, item }"
                                    >
                                        <td :colspan="headers.length">
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="4"
                                                    md="4"
                                                    v-if="item.node.document"
                                                    ><v-list-item two-line>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                >{{
                                                                    item.node
                                                                        .document
                                                                        .name
                                                                }}</v-list-item-title
                                                            >
                                                            <v-list-item-subtitle
                                                                style="max-width: 400px"
                                                                class="text-truncate"
                                                                >{{
                                                                    $t(
                                                                        "headers.document"
                                                                    )
                                                                }}</v-list-item-subtitle
                                                            >
                                                        </v-list-item-content>
                                                    </v-list-item></v-col
                                                >
                                                <v-col cols="12" sm="4" md="4"
                                                    ><v-list-item two-line>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                >{{
                                                                    item.node
                                                                        .tracker
                                                                        .serialNumber
                                                                }}</v-list-item-title
                                                            >
                                                            <v-list-item-subtitle
                                                                style="max-width: 400px"
                                                                class="text-truncate"
                                                                >{{
                                                                    $t(
                                                                        "headers.tracker"
                                                                    )
                                                                }}</v-list-item-subtitle
                                                            >
                                                        </v-list-item-content>
                                                    </v-list-item></v-col
                                                >
                                                <v-col
                                                    cols="12"
                                                    sm="4"
                                                    md="4"
                                                    v-if="item.node.document"
                                                >
                                                    <v-list-item two-line>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                >{{
                                                                    bytesToSize(
                                                                        item
                                                                            .node
                                                                            .document
                                                                            .size
                                                                    )
                                                                }}</v-list-item-title
                                                            >
                                                            <v-list-item-subtitle
                                                                style="max-width: 400px"
                                                                class="text-truncate"
                                                                >{{
                                                                    $t(
                                                                        "headers.size"
                                                                    )
                                                                }}</v-list-item-subtitle
                                                            >
                                                        </v-list-item-content>
                                                    </v-list-item></v-col
                                                >
                                            </v-row>
                                        </td>
                                        <v-row></v-row>
                                    </template>
                                </v-data-table>
                                <div
                                    v-else
                                    class="text-caption text-center mt-3"
                                >
                                    {{ $t("trips.no_reports") }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-sheet>
</template>
<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import GenerateReportDialog from "../other/GenerateReportDialog.vue";
import Config from "@/utils/config.json";

export default {
    components: { LoadingBar, NoPermission, GenerateReportDialog },
    props: {
        trip: {
            type: Object,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    apollo: {
        tripReports: {
            query: gql`
                query TripReports($tripId: ID, $orderBy: String) {
                    tripReports(tripId: $tripId, orderBy: $orderBy) {
                        edges {
                            node {
                                id
                                createdAt
                                isCompleted
                                reportType
                                sendToUsers
                                sensorData
                                startDate
                                endDate
                                generateImmediately
                                document {
                                    description
                                    file
                                    format
                                    id
                                    name
                                    size
                                    url
                                }
                                tracker {
                                    id
                                    serialNumber
                                }
                                trip {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tripId: this.trip.id,
                    orderBy: "-createdAt"
                };
            },
            fetchPolicy: "no-cache",
            // pollInterval: 3000, // ms
            pollInterval() {
                return this.pollInterval;
            },
            update(data) {
                return data.tripReports;
            },
            // result({ data }) {
            //     let isLoadingReport = false;
            //     if (data.tripReports.edges.length > 0) {
            //         data.tripReports.edges.map(item => {
            //             if (item.node.isCompleted == false) {
            //                 isLoadingReport = true;
            //             }
            //         });
            //     }
            // },
            skip() {
                return !this.canAccess;
                // ||
                // this.isLoadingReport == null ||
                // this.isLoadingReport == undefined
            }
        }
    },
    data: function() {
        return {
            createReportDialog: false,
            tripReports: { edges: [] },
            expanded: [],
            headers: [
                {
                    text: this.$t("headers.request_date"),
                    align: "left",
                    value: "node.createdAt"
                },
                {
                    text: this.$t("headers.date_range"),
                    align: "center",
                    value: "dateRange"
                },
                {
                    text: this.$t("headers.sensor_data"),
                    align: "center",
                    value: "node.sensorData"
                },
                {
                    text: this.$t("headers.report_type"),
                    align: "center",
                    value: "node.reportType"
                },
                {
                    text: this.$t("general.download"),
                    align: "center",
                    value: "action"
                }
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            },
            sensorTypeChoices: [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    icon: "lightbulb"
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    icon: "water_drop"
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    icon: "bolt"
                },
                {
                    text: this.$t("general.pressure"),
                    value: "pressure",
                    icon: "tire_repair"
                }

                // { text: "CO2", value: "co2", icon: "air" }
            ],
            pollInterval: 0
        };
    },
    watch: {
        "$route.query.tab": {
            handler: function(value) {
                this.pollInterval = value === "reports" ? 3000 : 0;
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        hasManagePermission() {
            return this.hasOrganisationPermission(
                TripPermissions.MANAGE_TRIP_REPORT
            );
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_REPORT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                // console.log(this.me)
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_REPORT,
                        this.organisationPermissions
                    )
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_REPORT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;

                if (this.me?.tenant && this.me?.isTenantAdmin) return true;

                if (
                    !this.hasPermission(TripPermissions.VIEW_TRIP_REPORT) &&
                    this.me?.tenant
                )
                    return false;
            }
            return true;
        },
        canCreate() {
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.CREATE_TRIP_REPORT,
                        this.organisationPermissions
                    )
                )
                    return false;
            }else{
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.CREATE_TRIP_REPORT,
                        this.organisationPermissions
                    )
                )
                    return false;

                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_REPORT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (
                    !this.hasPermission(TripPermissions.CREATE_TRIP_REPORT) &&
                    this.me?.tenant
                )
                    return false;
            }
  
            return true;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
        this.formatDateTime = helper.formatDateTime;
    },
    created() {
        this.bytesToSize = helper.bytesToSize;
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'trip_report_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("trip_report_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("trip_report_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        /* General section */
        formatType(format) {
            let newFormat = format.split(".");
            return newFormat[newFormat.length - 1];
        },
        expandRow(item) {
            if (
                item.node &&
                this.expanded.filter(val => val["node"]["id"] === item.node.id)
                    .length > 0
            ) {
                this.expanded = [];
            } else {
                this.expanded = [];
                this.expanded.push(item);
            }
        },
        refetch() {
            return this.$apollo.queries.tripReports.refetch();
        },
        reportGenerated() {
            this.$apollo.queries.tripReports.refresh();
        },
        getSensorTypes(sensorData) {
            let sensorTypes = [];
            if (sensorData) {
                let sensorArray = JSON.parse(sensorData.replace(/'/g, '"'));
                sensorArray.forEach(sensor => {
                    let sensorType = this.sensorTypeChoices.find(
                        type => type.value === sensor
                    );
                    if (sensorType) {
                        sensorTypes.push(sensorType);
                    }
                });
            }
            return sensorTypes;
        },
        downloadReport(url) {
            window.open(`${url}`, "_blank");
        }
    }
};
</script>
<style scoped></style>
