<template>
    <v-dialog max-width="600" v-model="openAddPartnerUserDialog" persistent>
        <v-card class="pb-4">
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-1">add_circle</v-icon>
                {{ $t("partners.add_user1") }}
            </v-card-title>
            <v-card-text class="pb-0">
                <v-form ref="partnerUserForm" class="pa-2 mt-4">
                    <v-row dense class="mx-2">
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="first_name"
                                :label="$t('headers.first_name') + '*'"
                                outlined
                                dense
                                :rules="nameRules"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="last_name"
                                :label="$t('headers.last_name') + '*'"
                                outlined
                                dense
                                :rules="nameRules"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                label="Email*"
                                v-model="email"
                                :rules="emailRules"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="mx-2">
                        <v-col cols="12" class="py-0">
                            <v-checkbox
                                v-model="notification_checkbox"
                                :label="$t('headers.enable_notifications')"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-checkbox
                                v-model="requires_2fa"
                                :label="$t('headers.2fa')"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTION BUTTONS --->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeAndResetInput">Close</v-btn>
                <v-btn
                    :loading="isLoadingPartnerAdd"
                    @click="createTenantPartnerUser"
                    color="primary"
                    rounded
                    dense
                    >{{ $t("general.add") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "AddPartnerUserDialog",
    props: ["openAddPartnerUserDialog", "partner"],
    data() {
        return {
            /**************************************/
            /* --------- FORM VARIABLES --------- */
            /**************************************/
            first_name: null,
            last_name: null,
            email: null,
            notification_checkbox: false,
            requires_2fa: false,
            /**********************************************/
            /* --------- DIALOGS/LOADINGS/MENUS --------- */
            /**********************************************/
            isLoadingPartnerAdd: false,
            /*****************************/
            /* --------- RULES --------- */
            /*****************************/
            nameRules: [v => !!v || this.$t('rules.name_rules')],
            emailRules: [
                v => !!v || this.$t('rules.email_required'),
                v => /.+@.+\..+/.test(v) || this.$t('rules.email')
            ]
        };
    },
    // mounted(){
    //     console.log(this.partner)
    // },
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.first_name = null;
            this.last_name = null;
            this.email = null;
            this.notification_checkbox = false;
            this.requires_2fa = false;
            this.$refs.partnerUserForm.resetValidation();
            this.$emit("closeAddPartnerUserDialog");
        },
        /* ---> TENANT PARTNER USER MUTATION <--- */
        createTenantPartnerUser() {
            // validate form input :
            if (!this.$refs.partnerUserForm.validate()) return;
            // Initialize Loading :
            this.isLoadingPartnerAdd = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation tenantPartnerUser(
                            $input: CreateTenantPartnerUserInput!
                        ) {
                            createTenantPartnerUser(input: $input) {
                                tenantPartnerUser {
                                    id
                                    user {
                                        id
                                        firstName
                                        lastName
                                        email
                                        require2fa
                                        isStaff
                                        isSuperuser
                                    }
                                    tenantPartner {
                                        id
                                        name
                                        referenceNumber
                                        partnerType
                                        carrierType
                                        tenant {
                                            id
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            firstName: this.first_name,
                            lastName: this.last_name,
                            email: this.email,
                            isAdmin: false,
                            require2fa: this.requires_2fa,
                            notificationEnabled: this.notification_checkbox,
                            tenantPartnerId: this.partner.id
                        }
                    }
                })
                .then(() => {
                    // Stop Loading //
                    this.isLoadingPartnerAdd = false;
                    // Emit event to signal user is added and reload table showing users //
                    this.$emit("refreshPartnerUsersTable");
                    // Close the dialog and reset form fields //
                    this.closeAndResetInput();
                })
                .catch((error) => {
                    const payload = {
                        color: "error",
                        message: error.message
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                    this.errorMessage = true;
                    this.isLoadingPartnerAdd = false;
                });
        }
    }
};
</script>
