<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col cols="5" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :hint="$t('general.search_hint')"
                                v-model="searchInput"
                                append-icon="search"
                                :label="$t('general.search')"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>

                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="auto">
                    <v-row dense>
                        <!-- ADD -->
                        <v-col cols="auto">
                            <v-btn
                                small
                                outlined
                                rounded
                                color="primary"
                                @click="openAddLocation = true"
                            >
                                <v-icon left small>add_box</v-icon>
                                {{ $t("partners.add_location1") }}
                            </v-btn>
                        </v-col>
                        <!-- REMOVE -->
                        <v-col cols="auto">
                            <v-btn
                                small
                                outlined
                                rounded
                                color="error"
                                :disabled="enableOrDisableRemove"
                                @click="openDeleteDialog = true"
                            >
                                <v-icon left small>remove_circle</v-icon>
                                {{ $t("general.remove") }}</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :loading="$apollo.queries.locations.loading"
                    :items="locations"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    hide-default-footer
                    loader-height="2"
                    :no-data-text="$t('loading.no_available')"
                >
                    <!-- CHECKBOX COLUMN -->
                    <template v-slot:item.action="{ item }">
                        <v-checkbox
                            multiple
                            v-model="selectedLocations"
                            class="mx-0"
                            :value="item.node.id"
                        ></v-checkbox>
                    </template>
                    <!-- EDIT COLUMN -->
                    <template v-slot:item.edit="{ item }">
                        <v-icon @click="editLocation(item)">edit</v-icon>
                    </template>

                    <!-- TAGS -->
                    <template v-slot:item.node.tags="{ item }">
                        <div>
                            <v-chip
                                v-for="tag in getTags(item.node.tags)"
                                :key="tag"
                                color="primary"
                                small
                                class="mr-1 my-1"
                                >{{ tag }}</v-chip
                            >
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!---- ADD LOCATION DIALOG ---->
        <AddPartnerLocation
            ref="addPartnerLocation"
            :partner="partner"
            :openAddLocationDialog="openAddLocation"
            v-on:updateLocationsTable="$apollo.queries.locations.refetch()"
            v-on:closeMap="openAddLocation = false"
        >
        </AddPartnerLocation>

        <!---- EDIT LOCATION DIALOG ---->
        <EditLocation
            ref="editLocationDialog"
            :openEditLocationDialog="openEditLocation"
            :showPartnerSelect="false"
            v-on:closeMap="openEditLocation = false"
        ></EditLocation>

        <!---- DELETE LOCATION DIALOG ---->
        <DeleteLocationDialog
            :openDeleteDialog="openDeleteDialog"
            :locationID="selectedLocations"
            v-on:refreshDataTable="$apollo.queries.locations.refetch()"
            v-on:closeDeleteDialog="closeAndCleanDeletedLocations"
        >
            <template #title>{{ $t("partners.delete_location") }}</template>
            <template #content
                ><b>{{ $t("partners.sure_location") }}</b></template
            >
        </DeleteLocationDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddPartnerLocation from "@/components/partners/create/AddPartnerLocation.vue";
import DeleteLocationDialog from "@/components/locations/delete/DeleteLocationDialog.vue";
import EditLocation from "@/components/locations/update/EditLocation.vue";
import Config from "@/utils/config.json";

export default {
    name: "PartnerLocationsTab",
    components: {
        AddPartnerLocation,
        DeleteLocationDialog,
        EditLocation
    },
    props: {
        partner: {
            type: Object,
            required: true
        }
    },
    apollo: {
        locations: {
            query: gql`
                query tenantLocations(
                    $search: String
                    $first: Int
                    $after: String
                    $tenantPartnerId: [ID]
                ) {
                    tenantLocations(
                        search: $search
                        first: $first
                        after: $after
                        tenantPartnerId: $tenantPartnerId
                    ) {
                        edges {
                            node {
                                id
                                name
                                fullAddress
                                locationType
                                isTrackerStockLocation
                                city
                                state
                                country
                                latitude
                                longitude
                                tags
                                radius
                                tenantPartner {
                                    id
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantPartnerId: [this.partner.id],
                    search: this.searchInput,
                    first: null,
                    after: null
                };
            },
            fetchPolicy: "network-only",
            update: response => {
                return response.tenantLocations.edges;
            },
            skip: false
        }
    },
    data() {
        return {
            /* --------- DIALOGS/LOADINGS/MENUS --------- */
            openAddLocation: false,
            openDeleteDialog: false,
            openEditLocation: false,
            /* --------> USER INPUT DATA <-------- */
            selectedLocations: [],
            searchInput: "",

            /* --------> TAGS <-------- */
            tagsChoices: [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ],
            /* ---- TABLE HEADERS ---- */
            headerChoices: [
                {
                    text: "",
                    align: "left",
                    value: "action",
                    width: "5px",
                    sortable: false
                },
                {
                    text: this.$t("headers.location_name"),
                    align: "left",
                    value: "node.name",
                    sortable: false
                },
                {
                    text: this.$t("partners.city"),
                    align: "left",
                    value: "node.city",
                    sortable: false
                },
                {
                    text: this.$t("partners.country"),
                    align: "left",
                    value: "node.country",
                    sortable: false
                },
                {
                    text: this.$t("partners.address"),
                    align: "left",
                    value: "node.fullAddress",
                    sortable: false
                },
                {
                    text: this.$t("headers.tags"),
                    align: "left",
                    value: "node.tags",
                    sortable: false
                },
                {
                    text: "",
                    align: "left",
                    value: "edit",
                    width: "5px",
                    sortable: false
                }
            ]
        };
    },
    watch: {},
    computed: {
        enableOrDisableRemove() {
            return this.selectedLocations?.length > 0 ? false : true;
        }
    },
    mounted(){
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'partner_locations_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("partner_locations_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("partner_locations_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editLocation(item) {
            this.openEditLocation = true;
            this.$refs.editLocationDialog.initializeEditing(item);
        },
        closeAndCleanDeletedLocations() {
            this.openDeleteDialog = false;
            this.selectedLocations = [];
        },
        getTags(tags) {
            if (tags) {
                try {
                    let tagsArray = JSON.parse(tags.replace(/'/g, '"'));
                    return tagsArray.map(tag => {
                        let tagChoice = this.tagsChoices.find(
                            choice => choice.value == tag
                        );
                        return tagChoice.text;
                    });
                } catch (error) {
                    console.log(error);
                    return null;
                }
            }
            return null;
        }
    }
};
</script>
