<template>
    <v-dialog max-width="1100" v-model="openAddAlertRuleDialog" persistent>
        <v-card flat>
            <v-card-title class="primary white--text ">
                <v-icon color="white" class="mr-2" v-if="editMode">edit</v-icon>
                <v-icon color="white" class="mr-2" v-else>add_circle</v-icon>
                {{
                    editMode
                        ? $t("alert_rules.edit_alert")
                        : $t("alert_rules.create_alert")
                }}
            </v-card-title>
            <v-card-text class="px-1 py-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-stepper v-model="e6" vertical class="elevation-0">
                            <v-stepper-step
                                :complete="e6 > 1"
                                step="1"
                                :editable="validate"
                            >
                                {{ $t("alert_rules.alert_details") }}
                                <small>{{ $t("alert_rules.details") }}</small>
                            </v-stepper-step>
                            <v-stepper-content step="1" class="pl-4">
                                <v-card flat class="mt-2 mb-1 grey lighten-4">
                                    <v-form
                                        ref="addAlertRuleForm"
                                        v-model="validate"
                                        class="pa-3"
                                    >
                                        <v-row dense>
                                            <v-col
                                                cols="6"
                                                sm="6"
                                                md="6"
                                                lg="6"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        alertDetails.alertRuleName
                                                    "
                                                    :label="
                                                        $t(
                                                            'alert_rules.alert_name'
                                                        ) + '*'
                                                    "
                                                    :rules="[
                                                        rules.required,
                                                        rules.maxLength(50)
                                                    ]"
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="4" sm="4" md="3" lg="4"
                                                ><v-autocomplete
                                                    v-model="
                                                        alertDetails.alertSeverity
                                                    "
                                                    :items="
                                                        alertSeverityChoices
                                                    "
                                                    :label="
                                                        $t(
                                                            'alert_rules.alert_severity'
                                                        ) + '*'
                                                    "
                                                    item-text="text"
                                                    item-value="value"
                                                    outlined
                                                    hide-details
                                                    :rules="[rules.required]"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="
                                                        alertDetails.alertRuleDescription
                                                    "
                                                    :label="
                                                        $t(
                                                            'general.description'
                                                        )
                                                    "
                                                    :rules="[
                                                        rules.maxLength(150)
                                                    ]"
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4" lg="4"
                                                ><v-autocomplete
                                                    v-model="alertDetails.tag"
                                                    :items="alertTags"
                                                    :label="
                                                        $t('alert_rules.tag')
                                                    "
                                                    item-text="text"
                                                    item-value="value"
                                                    outlined
                                                    hide-details
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                    <v-card-actions class="">
                                        <v-spacer></v-spacer>
                                        <!-- <v-btn text rounded v-if="!editMode">
                                                Cancel
                                            </v-btn> -->
                                        <v-btn
                                            color="primary"
                                            @click="e6 = 2"
                                            rounded
                                            :disabled="!validate"
                                        >
                                            {{ $t("general.continue") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-step
                                :complete="e6 > 2"
                                step="2"
                                :editable="requireStepValidation.step2"
                            >
                                {{ $t("headers.alert_trigger") }}
                                <small>{{
                                    $t("alert_rules.define_alert")
                                }}</small>
                            </v-stepper-step>
                            <v-stepper-content
                                step="2"
                                class="mt-0 pt-0 pl-0 scrollable-content"
                            >
                                <TriggerForm
                                    :trigger="trigger"
                                    :editModeTrigger.sync="editTrigger"
                                    :notifyForEveryRecord.sync="
                                        notify.notifyForEveryRecord
                                    "
                                    v-on:saveTrigger="saveTrigger"
                                    v-on:updateTrigger="updateTrigger"
                                    v-on:removeTrigger="trigger = null"
                                    v-on:updateApplyAllNewTrips="
                                        updateApplyAllNewTrips
                                    "
                                    v-on:updatePartnersToApplyAlert="
                                        updatePartnersToApplyAlert
                                    "
                                    v-on:updateNotifyForEveryRecord="
                                        updateNotifyForEveryRecord
                                    "
                                    v-on:resetApplyAllNewTrips="() => null"
                                    v-on:resetPartnersToApplyAlert="() => null"
                                    v-on:resetNotifyForEveryRecord="
                                        resetNotifyForEveryRecord
                                    "
                                    ref="triggerForm"
                                ></TriggerForm>
                            </v-stepper-content>
                            <v-stepper-step
                                :complete="e6 > 3"
                                step="3"
                                :editable="requireStepValidation.step3"
                            >
                                {{ $t("alert_rules.alert_action") }}
                                <small>{{
                                    $t("alert_rules.define_receive")
                                }}</small>
                            </v-stepper-step>
                            <v-stepper-content step="3" class="pl-4">
                                <v-card class="grey lighten-4">
                                    <ActionGroupsForm
                                        ref="actionGroupsForm"
                                        :actionGroups.sync="actionGroups"
                                        :notifyTripPartners.sync="
                                            notify.notifyTripPartners
                                        "
                                        v-on:updateActionGroups="
                                            updateActionGroups
                                        "
                                        v-on:updateNotifyTripPartners="
                                            updateNotifyTripPartners
                                        "
                                        :createMode="true"
                                    ></ActionGroupsForm>
                                    <v-card-actions class="pt-0">
                                        <v-spacer></v-spacer>
                                        <!-- <v-btn text rounded v-if="!editMode">
                                            Cancel
                                        </v-btn> -->
                                        <v-btn
                                            color="primary"
                                            @click="e6 = 4"
                                            rounded
                                            :disabled="!validForm"
                                        >
                                            {{ $t("general.continue") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-step
                                :complete="e6 > 4"
                                step="4"
                                :editable="validForm"
                            >
                                {{ $t("alert_rules.alert_automation") }}
                                <small>
                                    {{ $t("alert_rules.define_automation") }}
                                </small>
                            </v-stepper-step>
                            <v-stepper-content step="4" class="pl-4">
                                <AutomateForm
                                    ref="automateForm"
                                    :applyAllNewTrips.sync="applyToAllNewTrips"
                                    :partnersToApplyAlert="automate"
                                    :partnersList="partnerListIds"
                                    v-on:updateApplyAllNewTrips="
                                        updateApplyAllNewTrips
                                    "
                                    v-on:updatePartnersToApplyAlert="
                                        updatePartnersToApplyAlert
                                    "
                                    :activeMode="activeMode"
                                    :validate.sync="validatePartners"
                                ></AutomateForm>
                            </v-stepper-content>
                        </v-stepper>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex pb-4 pr-8">
                <v-switch
                    v-model="alertDetails.enabled"
                    :label="
                        alertDetails.enabled
                            ? $t('headers.enabled')
                            : $t('headers.disabled')
                    "
                    color="primary"
                    hide-details
                    class="ml-6"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.close")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :disabled="!validForm"
                    :loading="isLoadingAddAlertRule"
                    @click="submitAlertRule"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card></v-dialog
    >
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
// import { TenantPermissions } from "@/core/enum";
import TriggerForm from "@/components/alertrules/forms/base/TriggerForm.vue";
import ActionGroupsForm from "@/components/alertrules/forms/base/ActionGroupsForm.vue";
import AutomateForm from "@/components/alertrules/forms/secondary/AutomateForm";

export default {
    name: "AddAlertRuleDialog",
    props: {
        openAddAlertRuleDialog: {
            type: Boolean,
            required: false,
            default: false
        },
        tenantAlertRule: {
            type: Object,
            required: false
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
        createMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    apollo: {
        getTenantPartners: {
            query: gql`
                query getTenantPartners(
                    $orderBy: String
                    $idIn: [String]
                    $first: Int
                ) {
                    getTenantPartners(
                        orderBy: $orderBy
                        id_In: $idIn
                        first: $first
                    ) {
                        edges {
                            node {
                                id
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name",
                    idIn: this.partnerListIds,
                    first: 80
                };
            },
            fetchPolicy: "no-cache",
            update: data => data.getTenantPartners,
            result({ data }) {
                let _partners = {
                    consignee: [],
                    consignor: [],
                    carrier: [],
                    forwarder: [],
                    other: []
                };

                data.getTenantPartners.edges.forEach(edge => {
                    _partners[edge.node.partnerType].push(edge.node.id);
                });

                for (let key in _partners) {
                    if (_partners[key].length == 0) {
                        delete _partners[key];
                    }
                }

                this.automate = _partners;
            },
            skip() {
                return !this.partnerListIds.length > 0;
            }
        }
    },
    components: { TriggerForm, ActionGroupsForm, AutomateForm },
    data() {
        return {
            panels: [0, 1], // open both panels by default
            open: false,
            notifyForEveryRecord: false,
            isLoadingAddAlertRule: false,
            validatePartners: false,
            /* actionGroups: [], */
            copyObj: {},
            notifyTripPartners: false,
            mainObject: {
                name: null,
                timerFormat: "hours",
                description: null,
                isEnabled: true,
                shouldNotifyPartner: null,
                shouldNotifyForEveryRecord: false,
                alertSeverity: "info",
                applyToAllNewTrips: false,
                applyToCarriers: null,
                applyToConsignors: null,
                applyToForwarders: null,
                applyToConsignees: null,
                applyToOthers: null,
                tag: null,
                tenantAlertRuleTriggerSet: {
                    edges: [
                        {
                            node: {
                                triggerJson: null
                            }
                        }
                    ]
                },
                tenantAlertRuleActionGroupSet: {
                    edges: []
                }
            },
            e6: 1,
            itemList: [
                {
                    title: this.$t("alert_rules.alert_details"),
                    subtitle: this.$t("general.required")
                },
                {
                    title: this.$t("alert_rules.trigger_alert"),
                    subtitle: this.$t("general.required")
                },
                {
                    title: this.$t("alert_rules.notified"),
                    subtitle: this.$t("general.optional")
                }
            ],
            alertSeverityChoices: [
                { text: this.$t("general.informational"), value: "info" },
                { text: this.$t("general.warning"), value: "warning" },
                { text: this.$t("general.critical"), value: "critical" }
            ],
            alertTags: [
                { value: "temperature", text: this.$t("general.temperature") },
                { value: "humidity", text: this.$t("general.humidity") },
                { value: "light", text: this.$t("general.light") },
                {
                    value: "temperature_humidity",
                    text: this.$t("general.temperature_humidity")
                },
                {
                    value: "temperature_humidity_light",
                    text: this.$t("general.temperature_humidity_light")
                },
                { value: "pressure", text: this.$t("general.pressure") }
                /*  
                        {value:"shock", text:"Shock"},
                        {value:"external_temperature", text:"External Temperature"},
                        {value:"battery", text:"Battery"},
                        {value:"probe_temperature", text:"Probe Temperature"}, 
                    */
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            },
            validate: false
        };
    },
    created() {},
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
    },
    computed: {
        me() {
            return helper.me();
        },
        requireStepValidation() {
            let _stepValidation = {
                step2: false,
                step3: false
            };

            if (this.validate) {
                _stepValidation.step2 = true;
            } else {
                _stepValidation.step2 = false;
            }

            if (this.validate && this.trigger !== null) {
                _stepValidation.step3 = true;
            } else {
                _stepValidation.step3 = false;
            }

            return _stepValidation;
        },
        validForm() {
            return (
                this.validate &&
                this.trigger !== null &&
                this.actionGroups.length > 0 &&
                this.validAutomate
            );
        },
        validAutomate() {
            if (
                this.validatePartners &&
                Object.keys(this.automate).length > 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        alertDetails: {
            get() {
                let _alert = {
                    alertRuleName: this.mainObject?.name,
                    alertRuleDescription: this.mainObject?.description,
                    enabled: this.mainObject.isEnabled,
                    alertSeverity: this.mainObject?.alertSeverity?.toLowerCase(),
                    tag: this.mainObject?.tag
                };

                return _alert;
            },
            set(value) {
                this.mainObject = {
                    name: value.alertRuleName,
                    description: value.alertRuleDescription,
                    isEnabled: value.enabled,
                    alertSeverity: value.alertSeverity || "info",
                    tag: value.tag || null
                };
            }
        },
        trigger: {
            get() {
                let _triggerJson = this.mainObject?.tenantAlertRuleTriggerSet
                    ?.edges[0]?.node?.triggerJson
                    ? JSON.parse(
                          this.mainObject?.tenantAlertRuleTriggerSet?.edges[0]
                              ?.node?.triggerJson
                      )
                    : null;

                if (_triggerJson !== null) {
                    let _trigger = {
                        TriggerType: _triggerJson?.TriggerType
                            ? _triggerJson?.TriggerType
                            : _triggerJson?.Sensor.length > 0
                            ? "sensor"
                            : "location",
                        Sensor: _triggerJson?.Sensor,
                        Location: _triggerJson?.Location,
                        RemainMinutes: _triggerJson?.RemainMinutes,
                        timerFormat: _triggerJson?.timerFormat || "hours"
                    };
                    return _trigger;
                } else {
                    return null;
                }
            },
            set(value) {
                if (value !== null) {
                    let _trigger = {
                        TriggerType: value.TriggerType || null,
                        Sensor: value.Sensor || [],
                        Location: value.Location || {},
                        RemainMinutes: value.RemainMinutes,
                        timerFormat: value.timerFormat
                    };

                    this.mainObject.tenantAlertRuleTriggerSet.edges[0].node.triggerJson = JSON.stringify(
                        _trigger
                    );
                } else {
                    this.mainObject.tenantAlertRuleTriggerSet.edges[0].node
                        .triggerJson
                        ? (this.mainObject.tenantAlertRuleTriggerSet.edges[0].node.triggerJson = null)
                        : null;
                }
            }
        },
        editMode: {
            get() {
                return this.edit;
            },
            set(value) {
                if (value === false) this.$emit("editClose");
            }
        },
        editTrigger() {
            if (this.editMode && this.trigger !== null) {
                return true;
            } else {
                return false;
            }
        },
        actionGroups: {
            get() {
                this.automate;
                let _actionGroups = [];

                this.mainObject?.tenantAlertRuleActionGroupSet?.edges.forEach(
                    element => {
                        _actionGroups.push(element.node.tenantActionGroup.id);
                    }
                ) || [];

                return _actionGroups;
            },
            set(value) {
                let _actionGroups = [];
                if (value !== null && value.length > 0) {
                    value.forEach(element => {
                        _actionGroups.push({
                            node: {
                                id: element,
                                tenantActionGroup: {
                                    id: element
                                }
                            }
                        });
                    });
                    this.mainObject.tenantAlertRuleActionGroupSet.edges = _actionGroups;
                } else {
                    this.mainObject.tenantAlertRuleActionGroupSet.edges = [];
                }
            }
        },
        automate: {
            get() {
                let _automate = {
                    carrier: this.checkNull(this.mainObject?.applyToCarriers)
                        ? this.convertIds(this.mainObject?.applyToCarriers)
                        : [],
                    consignor: this.checkNull(
                        this.mainObject?.applyToConsignors
                    )
                        ? this.convertIds(this.mainObject?.applyToConsignors)
                        : [],
                    forwarder: this.checkNull(
                        this.mainObject?.applyToForwarders
                    )
                        ? this.convertIds(this.mainObject?.applyToForwarders)
                        : [],
                    consignee: this.checkNull(
                        this.mainObject?.applyToConsignees
                    )
                        ? this.convertIds(this.mainObject?.applyToConsignees)
                        : [],
                    other: this.checkNull(this.mainObject?.applyToOthers)
                        ? this.convertIds(this.mainObject?.applyToOthers)
                        : []
                };

                return _automate;
            },
            set(value) {
                if (value !== null && value !== undefined) {
                    this.mainObject.applyToCarriers = value.carrier;
                    this.mainObject.applyToConsignors = value.consignor;
                    this.mainObject.applyToForwarders = value.forwarder;
                    this.mainObject.applyToConsignees = value.consignee;
                    this.mainObject.applyToOthers = value.other;
                } else {
                    this.mainObject.applyToCarriers = [];
                    this.mainObject.applyToConsignors = [];
                    this.mainObject.applyToForwarders = [];
                    this.mainObject.applyToConsignees = [];
                    this.mainObject.applyToOthers = [];
                }
            }
        },
        partnerListIds() {
            let _partnerListIds = [];
            if (
                this.automate.carrier.length > 0 &&
                this.isArrayOfNumbers(this.automate.carrier)
            ) {
                _partnerListIds = _partnerListIds.concat(this.automate.carrier);
            }
            if (
                this.automate.consignor.length > 0 &&
                this.isArrayOfNumbers(this.automate.consignor)
            ) {
                _partnerListIds = _partnerListIds.concat(
                    this.automate.consignor
                );
            }
            if (
                this.automate.forwarder.length > 0 &&
                this.isArrayOfNumbers(this.automate.forwarder)
            ) {
                _partnerListIds = _partnerListIds.concat(
                    this.automate.forwarder
                );
            }
            if (
                this.automate.consignee.length > 0 &&
                this.isArrayOfNumbers(this.automate.consignee)
            ) {
                _partnerListIds = _partnerListIds.concat(
                    this.automate.consignee
                );
            }
            if (
                this.automate.other.length > 0 &&
                this.isArrayOfNumbers(this.automate.other)
            ) {
                _partnerListIds = _partnerListIds.concat(this.automate.other);
            }

            return _partnerListIds;
        },
        isReady() {
            let _isReady = false;
            let carrier = this.isArrayOfNumbers(this.automate.carrier);
            let consignor = this.isArrayOfNumbers(this.automate.consignor);
            let forwarder = this.isArrayOfNumbers(this.automate.forwarder);
            let consignee = this.isArrayOfNumbers(this.automate.consignee);
            let other = this.isArrayOfNumbers(this.automate.other);

            //Ids come to backend and we transform this in PK before continue
            if (!carrier && !consignor && !forwarder && !consignee && !other) {
                _isReady = true;
            }

            return _isReady;
        },
        activeMode() {
            let _EditMode = false;
            if ((this.editMode || this.createMode) && this.isReady) {
                _EditMode = true;
            }
            return _EditMode;
        },
        applyToAllNewTrips: {
            get() {
                return this.mainObject?.applyToAllNewTrips;
            },
            set(value) {
                this.mainObject.applyToAllNewTrips = value;
            }
        },
        notify: {
            get() {
                let _notifyTripPartners = {
                    notifyTripPartners: {
                        shouldNotifyCarrier: false,
                        shouldNotifyConsignor: false,
                        shouldNotifyConsignee: false,
                        shouldNotifyForwarder: false,
                        shouldNotifyOther: false
                    },
                    notifyForEveryRecord:
                        this.mainObject?.shouldNotifyForEveryRecord || false
                };
                if (
                    this.mainObject?.shouldNotifyPartner === undefined ||
                    this.mainObject?.shouldNotifyPartner === null ||
                    this.mainObject?.shouldNotifyPartner === ""
                ) {
                    return _notifyTripPartners;
                } else {
                    let _notifyTripPartnerJson = JSON.parse(
                        this.mainObject?.shouldNotifyPartner
                    );
                    if (_notifyTripPartnerJson.shouldNotifyCarrier)
                        _notifyTripPartners.notifyTripPartners.shouldNotifyCarrier =
                            _notifyTripPartnerJson.shouldNotifyCarrier;
                    if (_notifyTripPartnerJson.shouldNotifyConsignor)
                        _notifyTripPartners.notifyTripPartners.shouldNotifyConsignor =
                            _notifyTripPartnerJson.shouldNotifyConsignor;
                    if (_notifyTripPartnerJson.shouldNotifyConsignee)
                        _notifyTripPartners.notifyTripPartners.shouldNotifyConsignee =
                            _notifyTripPartnerJson.shouldNotifyConsignee;
                    if (_notifyTripPartnerJson.shouldNotifyForwarder)
                        _notifyTripPartners.notifyTripPartners.shouldNotifyForwarder =
                            _notifyTripPartnerJson.shouldNotifyForwarder;
                    if (_notifyTripPartnerJson.shouldNotifyOther)
                        _notifyTripPartners.notifyTripPartners.shouldNotifyOther =
                            _notifyTripPartnerJson.shouldNotifyOther;
                }
                return _notifyTripPartners;
            },
            set(value) {
                this.mainObject.shouldNotifyPartner = value.notifyTripPartners;
                this.mainObject.shouldNotifyForEveryRecord =
                    value.notifyForEveryRecord;
            }
        }
    },
    watch: {
        "alertDetails.alertSeverity": {
            handler: function(val) {
                if (val === null || val === undefined) {
                    this.alertDetails.alertSeverity = "info";
                }
            },
            deep: true
        }
    },
    methods: {
        editModeTrigger(tenant) {
            this.mainObject = {
                id: tenant.id,
                name: tenant.name,
                description: tenant.description,
                isEnabled: tenant.isEnabled,
                partnerIds: tenant.partnerIds,
                alertSeverity: tenant.alertSeverity,
                tag: tenant.tag,
                shouldNotifyPartner: tenant.shouldNotifyPartner,
                shouldNotifyForEveryRecord: tenant.shouldNotifyForEveryRecord,
                applyToAllNewTrips: tenant.applyToAllNewTrips,
                applyToCarriers: tenant.applyToCarriers,
                applyToConsignors: tenant.applyToConsignors,
                applyToForwarders: tenant.applyToForwarders,
                applyToConsignees: tenant.applyToConsignees,
                applyToOthers: tenant.applyToOthers,
                tenantAlertRuleTriggerSet: {
                    edges: [
                        {
                            node: {
                                id:
                                    tenant.tenantAlertRuleTriggerSet?.edges[0]
                                        ?.node?.id,
                                triggerJson:
                                    tenant.tenantAlertRuleTriggerSet?.edges[0]
                                        ?.node?.triggerJson
                            }
                        }
                    ]
                },
                tenantAlertRuleActionGroupSet:
                    tenant.tenantAlertRuleActionGroupSet
            };
        },
        closeAndResetInput() {
            this.mainObject = {
                name: null,
                description: null,
                isEnabled: true,
                partnerIds: [],
                alertSeverity: "info",
                tenantAlertRuleTriggerSet: {
                    edges: [
                        {
                            node: {
                                triggerJson: null
                            }
                        }
                    ]
                },
                tenantAlertRuleActionGroupSet: {
                    edges: []
                },
                shouldNotifyPartner: false,
                shouldNotifyForEveryRecord: false,
                applyToAllNewTrips: false,
                applyToCarriers: [],
                applyToConsignors: [],
                applyToForwarders: [],
                applyToConsignees: [],
                applyToOthers: []
            };
            this.$refs.triggerForm.closeAndResetInput();
            /* this.$refs.addAlertRuleForm.reset(); */
            this.$refs.automateForm.resetValues();
            this.$refs.actionGroupsForm.resetActionGroups();
            this.e6 = 1;
            this.editMode = false;
            this.validate = false;
            this.$emit("close-dialog");
        },
        updateNotifyForEveryRecord(value) {
            this.notify.notifyForEveryRecord = value;
        },
        updateApplyAllNewTrips(value) {
            this.applyToAllNewTrips = value;
        },
        updatePartnersToApplyAlert(value) {
            this.automate = value;
        },
        updateActionGroups(value) {
            this.actionGroups = value;
        },
        updateNotifyTripPartners(value) {
            this.notify.notifyTripPartners = value;
        },
        resetNotifyForEveryRecord() {
            //TODO add if to edit
            this.notifyForEveryRecord = false;
        },
        convertIds(array) {
            this.promisseDone = false;
            let _array = [];

            if (array?.length > 0 && Array.isArray(array) === false) {
                _array = JSON.parse(array.replace(/'/g, '"')).map(str => str);
            } else {
                _array = array;
            }

            return _array;
        },
        checkNull(value) {
            let val = false;
            if (value !== null && value !== undefined) {
                val = true;
            }
            return val;
        },
        isArrayOfNumbers(array) {
            let val = false;
            array.forEach(element => {
                if (typeof element === "number") {
                    val = true;
                } else {
                    val = false;
                }
            });

            return val;
        },
        refreshDataTable() {
            this.$emit("refreshDataTable");
        },
        submitAlertRule() {
            this.isLoadingAddAlertRule = true;
            if (this.editMode) {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation UpdateTenantAlertRule(
                                $input: UpdateTenantAlertRuleInput!
                            ) {
                                updateTenantAlertRule(input: $input) {
                                    tenantAlertRule {
                                        id
                                        name
                                        description
                                        isEnabled
                                        alertSeverity
                                        tag
                                        shouldNotifyPartner
                                        shouldNotifyForEveryRecord
                                        applyToAllNewTrips
                                        applyToCarriers
                                        applyToConsignors
                                        applyToForwarders
                                        applyToConsignees
                                        applyToOthers
                                        tenant {
                                            id
                                        }
                                        tenantAlertRuleActionGroupSet {
                                            edges {
                                                node {
                                                    id
                                                    tenantActionGroup {
                                                        id
                                                        name
                                                    }
                                                }
                                            }
                                        }
                                        tenantAlertRuleTriggerSet {
                                            edges {
                                                node {
                                                    id
                                                    triggerJson
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                triggerInput: {
                                    id: this.mainObject
                                        ?.tenantAlertRuleTriggerSet?.edges[0]
                                        ?.node.id,
                                    triggerJson: JSON.stringify(this.trigger)
                                },
                                alertRuleInput: {
                                    id: this.mainObject?.id,
                                    applyToAllNewTrips: this.applyToAllNewTrips,
                                    applyToCarriers: this.automate.carrier,
                                    applyToConsignees: this.automate.consignee,
                                    applyToConsignors: this.automate.consignor,
                                    applyToForwarders: this.automate.forwarder,
                                    applyToOthers: this.automate.other,

                                    shouldNotifyForEveryRecord: this.notify
                                        .notifyForEveryRecord,
                                    shouldNotifyPartner: JSON.stringify(
                                        this.notify.notifyTripPartners
                                    ),
                                    name: this.alertDetails.alertRuleName,
                                    description: this.alertDetails
                                        .alertRuleDescription,
                                    isEnabled: this.alertDetails.enabled,
                                    alertSeverity: this.alertDetails
                                        .alertSeverity,
                                    tag: this.alertDetails.tag
                                },
                                actionGroupsInput: {
                                    groups: this.actionGroups
                                }
                            }
                        }
                    })
                    .then(response => {
                        // Emit event to tabel data and load new groups //
                        this.$emit("refreshDataTable");
                        this.$emit(
                            "item-updated",
                            response.data.updateTenantAlertRule.tenantAlertRule
                        );
                        this.closeAndResetInput();
                        this.isLoadingAddAlertRule = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isLoadingAddAlertRule = false;
                    });
            } else {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CreateTenantAlertRule(
                                $input: CreateTenantAlertRuleInput!
                            ) {
                                createTenantAlertRule(input: $input) {
                                    tenantAlertRule {
                                        id
                                        name
                                        description
                                        isEnabled
                                        alertSeverity
                                        tag
                                        shouldNotifyPartner
                                        shouldNotifyForEveryRecord
                                        applyToAllNewTrips
                                        applyToCarriers
                                        applyToConsignors
                                        applyToForwarders
                                        applyToConsignees
                                        applyToOthers
                                        tenant {
                                            id
                                        }
                                        tenantAlertRuleActionGroupSet {
                                            edges {
                                                node {
                                                    id
                                                    tenantActionGroup {
                                                        id
                                                        name
                                                    }
                                                }
                                            }
                                        }
                                        tenantAlertRuleTriggerSet {
                                            edges {
                                                node {
                                                    id
                                                    triggerJson
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                triggerInput: {
                                    triggerJson: JSON.stringify(this.trigger)
                                },
                                tenantId: this.me.tenant.id,
                                alertRuleInput: {
                                    applyToAllNewTrips: this.applyToAllNewTrips,
                                    applyToCarriers: this.automate.carrier,
                                    applyToConsignees: this.automate.consignee,
                                    applyToConsignors: this.automate.consignor,
                                    applyToForwarders: this.automate.forwarder,
                                    applyToOthers: this.automate.other,
                                    shouldNotifyForEveryRecord: this.notify
                                        .notifyForEveryRecord,
                                    shouldNotifyPartner: JSON.stringify(
                                        this.notify.notifyTripPartners
                                    ),
                                    name: this.alertDetails.alertRuleName,
                                    description: this.alertDetails
                                        .alertRuleDescription,
                                    isEnabled: this.alertDetails.enabled,
                                    alertSeverity: this.alertDetails
                                        .alertSeverity,
                                    tag: this.alertDetails.tag
                                },
                                actionGroupsInput: {
                                    groups: this.actionGroups
                                }
                            }
                        }
                    })
                    .then(response => {
                        // Emit event to tabel data and load new groups //
                        this.$emit("refreshDataTable");
                        this.$emit(
                            "item-created",
                            response.data.createTenantAlertRule.tenantAlertRule
                        );

                        const payload = {
                            color: "blue",
                            message: this.$t("alert_rules.alert_rule_saved")
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .catch(error => {
                        console.log(error);
                        this.isLoadingAddAlertRule = false;
                    })
                    .finally(() => {
                        this.isLoadingAddAlertRule = false;
                        this.closeAndResetInput();
                    });
            }
        },
        saveTrigger(trigger) {
            this.e6 = 3;
            this.trigger = trigger;
        },
        updateTrigger(trigger) {
            this.trigger = trigger;
        }
    }
};
</script>

<style scoped>
.custom-dialog.v-dialog {
    margin-top: 5%;
    margin-bottom: 5%;
    max-height: 80%;
}
.scroll {
    overflow-y: scroll;
}
.heightCard {
    height: 700px;
}
</style>
