<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4 mb-4" flat>
            <v-row align="start" class="d-flex align-center background px-4">
                <!---- SEARCH BAR ---->
                <v-col cols="12" lg="12" sm="12" class="py-2">
                    <SearchBar
                        v-model="searchInput"
                        :label="this.$t('general.search') + ' ' + this.$t('general.permission').toLowerCase()"
                    ></SearchBar>
                </v-col>
            </v-row>
        </v-card>
        <v-card flat class="px-4 rounded-lg mb-2" outlined>
            <v-card-title>
                <v-row dense>
                    <v-col cols="auto" class="mr-2">
                        <v-row dense>
                            <v-col>
                                <div>
                                    <v-img :src="require('@/assets/warning.png')" width="26"></v-img>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                {{ $t("headers.permissions") }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="5" class="d-flex justify-center align-center ">
                        <v-row dense align="stretch">
                            <v-col cols="auto">
                                <div v-if="!isSmallScreen">
                                    <v-img :src="require('@/assets/info.png')" width="26"> </v-img>
                                </div>
                                <v-tooltip bottom v-else open-on-click>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-img v-bind="attrs" v-on="on" :src="require('@/assets/info.png')" width="26">
                                        </v-img>
                                    </template>
                                    <span>{{ $t("general.permissions_warning_message") }}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col
                                v-if="!isSmallScreen"
                                class="textHeader mt-1 d-flex justify-start text-no-wrap"
                                cols="10"
                            >
                                {{ $t("general.permissions_warning_message") }}
                            </v-col>
                        </v-row>
                    </v-col> -->
                    <v-spacer></v-spacer>
                    <v-col class="d-flex flex-row justify-end">
                        <v-btn
                            v-if="selectedPermissions.length > 0"
                            elevation="0"
                            color=""
                            @click="deselectAll"
                            class="mr-2"
                        >
                            {{ $t("general.deselect_all") }}
                        </v-btn>
                        <v-btn elevation="0" color="primary" @click="selectAll">
                            {{ $t("general.select_all") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-card
                    v-for="permission in computedPermissions"
                    :key="permission.value"
                    class="mb-4 rounded-lg px-3 backgroundContent"
                    outlined
                    flat
                >
                    <v-card-text>
                        <!-- TABLE -->
                        <v-row dense>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="1" xl="1">
                                <v-checkbox
                                    :value="isChecked(permission.value)"
                                    :input-value="isChecked(permission.value)"
                                    @change="onSelelect(permission.value)"
                                ></v-checkbox>
                            </v-col>
                            <v-col
                                v-for="item in cardBody(permission)"
                                :key="item.text"
                                cols="12"
                                xs="12"
                                sm="6"
                                md="4"
                                lg="3"
                                xl="3"
                            >
                                <div class="">
                                    <div class="textHeader ">
                                        {{ item.title }}
                                    </div>
                                    <div class="textBody" :style="item.style">
                                        <v-icon v-if="item.icon" size="8" class="mr-1" :color="item.colorIcon">{{
                                            item.icon
                                        }}</v-icon>
                                        <span>
                                            {{ item.value }}
                                        </span>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import helper from "@/utils/helper.js";
import SearchBar from "@/components/base/SearchBar.vue";
import {
    TripPermissions,
    // TrackerPermissions,
    CarrierPermissions,
    TenantPermissions,
    BookingPermissions
} from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "PartnerPermissionsTab",
    components: { SearchBar },
    props: {
        permissions: {
            type: Array,
            default: () => []
        },
        partnerPermissions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isLoadingSavePermission: false,
            enableOrDisablePermissionBtn: true,
            selectedPermissions: [],
            selectedPermissionsCopy: [],
            searchInput: "",
            headerChoices: [
                {
                    text: "",
                    align: "left",
                    value: "action",
                    width: "5px",
                    sortable: false
                },
                {
                    text: this.$t("general.module"),
                    align: "left",
                    value: "module",
                    sortable: false
                },
                {
                    text: this.$t("general.permission"),
                    align: "left",
                    value: "permission",
                    sortable: false
                },
                {
                    text: this.$t("general.description"),
                    align: "left",
                    value: "description",
                    sortable: false
                }
            ]
        };
    },
    beforeCreate() {
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
        this.CarrierPermissions = CarrierPermissions;
        this.TenantPermissions = TenantPermissions;
    },
    watch: {
        partnerPermissions: {
            handler(val) {
                if (val?.length > 0) {
                    val.forEach(item => {
                        if (!this.selectedPermissions.includes(item)) {
                            this.selectedPermissions.push(item);
                        }
                    });
                }
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        computedPermissions() {
            let _permission = this.permissions?.filter(c => this.hasOrganisationPermission(c?.managePermission));

            if (this.searchInput !== "" && this.searchInput !== null) {
                _permission = _permission.filter(
                    item => item.module.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1
                    /*  || item.permission
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) > -1 ||
                        item.description
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) > -1 */
                );
            }

            return _permission;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1300;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "partner_locations_tab_svw"
        });
    },
    methods: {
        isChecked(permission) {
            this.selectedPermissions.includes(permission);
            return this.selectedPermissions.includes(permission);
        },
        cardBody(item) {
            const { module, permission, description } = item;
            let _body = [
                {
                    title: this.$t("general.module"),
                    tag: "module",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: module
                },
                {
                    title: this.$t("general.permission"),
                    tag: "permission",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: permission
                },
                {
                    title: this.$t("general.description"),
                    tag: "description",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: description
                }
            ];

            return _body;
        },
        onSelelect(val) {
            if (this.selectedPermissions.includes(val)) {
                this.selectedPermissions = this.selectedPermissions.filter(item => item !== val);
            } else {
                this.selectedPermissions.push(val);
            }
            this.$emit("update-permissions", this.selectedPermissions);
        },
        selectAll() {
            this.computedPermissions.forEach(permission => {
                const { value } = permission;
                this.selectedPermissions.push(value);
            });
            this.$emit("update-permissions", this.selectedPermissions);
        },
        deselectAll() {
            this.selectedPermissions = [];
            this.$emit("update-permissions", this.selectedPermissions);
        }
    }
};
</script>
<style scoped>
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
.outline {
    border: 1px solid #ebebf5;
    box-sizing: border-box;
    border-radius: 4px;
}
.backgroundContent {
    background: #f9fafe;
}
</style>
