<template>
    <v-dialog max-width="630" v-model="openAddLocationDialog" persistent scrollable>
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("partners.add_location") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="px-4 pb-1 pt-3">
                <v-autocomplete
                    :loading="$apollo.queries.locations.loading"
                    :disabled="$apollo.queries.locations.loading"
                    v-model="locationsToAdd"
                    :items="locations.edges"
                    :no-data-text="$t('loading.no_available')"
                    item-text="node.city"
                    item-value="node.id"
                    return-object
                    :label="$t('partners.add_location')"
                    multiple
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    hide-details
                    dense
                >
                    <template v-slot:item="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.node.name }}</v-list-item-title>
                            <v-list-item-subtitle class="text-truncate">{{ item.node.fullAddress }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
                <div v-if="locationsToAdd.length > 0">
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col cols="12" md="6" v-for="(item, i) in locationsToAdd" :key="i">
                            <v-container fluid>
                                <v-card flat outlined class="pr-3 pt-2 pb-n7">
                                    <v-card-title>{{ item.node.city }}</v-card-title
                                    ><v-card-text><MinMaxTemperature v-model="item.node"/></v-card-text>
                                </v-card>
                            </v-container>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <!--- BUTTONS --->
            <v-card-actions class=" justify-end">
                <v-btn text @click="resetLocation" rounded>{{ $t("general.cancel") }}</v-btn>
                <v-btn
                    color="primary"
                    @click="addNewWeatherLocation"
                    rounded
                    :loading="isLoadingLocationAdd"
                    :disabled="!formValid"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import MinMaxTemperature from "@/components/weather/forms/MinMaxTemperature.vue";
import { max } from "moment-timezone";

export default {
    name: "AddWeatherDialog",
    components: {
        MinMaxTemperature
    },
    props: {
        openAddLocationDialog: {
            type: Boolean
        }
    },
    apollo: {
        locations: {
            query: gql`
                query tenantLocations {
                    tenantLocationsWeather(
                        weatherForecastSubscriptionSet_Enabled: false
                        locationType_In: ["tenant_location", "private_location", "partner_location"]
                    ) {
                        edges {
                            node {
                                id
                                name
                                locationType
                                isTrackerStockLocation
                                fullAddress
                                city
                                state
                                country
                                latitude
                                longitude
                                radius
                                tags
                                weatherForecastSubscriptionSet {
                                    edges {
                                        node {
                                            minTemperature
                                            maxTemperature
                                            enabled
                                            lastSyncTime
                                            tenantLocationWeatherForecastSet {
                                                edges {
                                                    node {
                                                        id
                                                        maxTemperature
                                                        minTemperature
                                                        forecastDate
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "no-cache",
            debounce: 800,
            update: response => response.tenantLocationsWeather.weatherForecastSubscriptionSet,
            result({ data }) {
                if (data) {
                    this.locations = JSON.parse(JSON.stringify(data.tenantLocationsWeather));
                    this.locations.edges = this.locations.edges.map(({ __typename, node }) => {
                        return {
                            __typename: __typename,
                            node: {
                                ...node,
                                maxTemperature: node.weatherForecastSubscriptionSet?.edges[0].node.maxTemperature,
                                minTemperature: node.weatherForecastSubscriptionSet?.edges[0].node.minTemperature,
                                enabled: node.weatherForecastSubscriptionSet?.edges[0].node.enabled,
                                lastSyncTime: node.weatherForecastSubscriptionSet?.edges[0].node.lastSyncTime,
                                tenantLocationWeatherForecastSet:
                                    node.weatherForecastSubscriptionSet?.edges[0].node.tenantLocationWeatherForecastSet
                            }
                        };
                    });
                }
            },
            skip: false
        }
    },
    data() {
        return {
            isLoadingLocationAdd: false,
            locationsToAdd: [],
            locations: { edges: [] }
        };
    },
    created() {},
    computed: {
        formValid() {
            let validInputs = this.locationsToAdd.every(location => {
                let _max = parseFloat(location.node.maxTemperature) || null;
                let _min = parseFloat(location.node.minTemperature) || null;
                return _max == null || _min == null || _max > _min;
            });
            return this.locationsToAdd.length > 0 && validInputs;
        }
    },
    watch: {},
    methods: {
        resetLocation() {
            this.locationsToAdd = [];
            this.$emit("closeMap");
        },
        addNewWeatherLocation() {
            this.isLoadingLocationAdd = true;
            let payload = {
                locations: this.locationsToAdd.map(location => {
                    return {
                        id: location.node.id,
                        maxTemperature: parseFloat(location.node.maxTemperature) || null,
                        minTemperature: parseFloat(location.node.minTemperature) || null
                    };
                }),
                isAdding: true
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation addNewWeatherLocation($input: UpdateTenantLocationWeatherForecastInput!) {
                            updateTenantLocationWeatherForecast(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: payload
                    }
                })
                .finally(() => {
                    this.resetLocation();
                    this.$emit("refetchData", () => {
                        this.isLoadingLocationAdd = false;
                    });
                });
        }
    }
};
</script>
