import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000","persistent":"","scrollable":""},model:{value:(_vm.openAddLocationDialog),callback:function ($$v) {_vm.openAddLocationDialog=$$v},expression:"openAddLocationDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t("partners.add_location"))+" ")],1),_c(VCardText,{staticClass:"px-1 py-1"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6","sm":"6","md":"8","lg":"8"}},[(_vm.$apollo.queries.trackerLocations.loading)?_c(VProgressLinear,{staticClass:"mb-0",staticStyle:{"z-index":"800"},attrs:{"dense":"","indeterminate":"","color":"primary","height":3}}):_vm._e(),_c('l-map',{ref:"map",attrs:{"zoom":_vm.zoom,"center":_vm.map_center,"min-zoom":_vm.min_zoom,"max-zoom":_vm.max_zoom,"options":{
                            attributionControl: false,
                            drawControl: false,
                            zoomControl: false,
                            worldCopyJump: true
                        }}},[_c('l-tile-layer',{staticClass:"mapEdit",attrs:{"url":_vm.here_map_url}}),_c('l-control-zoom',{attrs:{"position":"bottomright"}}),_c('l-control',{attrs:{"position":"bottomleft"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"fab":"","tile":"","x-small":""},on:{"click":_vm.recenterMap}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("my_location")])],1)],1),(_vm.triggerRadius)?_c(VCol,{staticClass:"pl-1 d-flex",attrs:{"cols":"10"}},[_c(VCard,{staticClass:"pl-1",attrs:{"width":"250","tile":"","elevation":"3","outlined":""}},[_c(VSlider,{staticClass:"mt-0",attrs:{"max":"200000","min":"1000","thumb-color":"primary","track-color":"primary","hide-details":""},on:{"change":function($event){return _vm.editCircle(_vm.radius)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"ml-1 pr-0"},[_vm._v(" "+_vm._s(_vm.radius)+" m ")])]},proxy:true}],null,false,570744188),model:{value:(_vm.radius),callback:function ($$v) {_vm.radius=$$v},expression:"radius"}})],1)],1):_vm._e()],1)],1),_c('l-control',{attrs:{"position":"topleft"}},[_c('div',{staticClass:"d-flex"},[(!_vm.showMapSearchBar)?_c(VBtn,{attrs:{"fab":"","tile":"","x-small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showMapSearchBar = !_vm.showMapSearchBar}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("search")])],1):_vm._e(),_c('MapSearchBar',{on:{"onPlaceChanged":_vm.onPlaceChanged},model:{value:(_vm.googleResult),callback:function ($$v) {_vm.googleResult=$$v},expression:"googleResult"}}),_c('div',{staticClass:"mt-2 ml-3"},[(_vm.enableHeatmap)?_c(VBtn,{attrs:{"dense":"","loading":_vm.$apollo.queries.trackerLocations
                                                .loading},on:{"click":function($event){_vm.enableHeatmap = false}}},[_vm._v(_vm._s(_vm.$t("partners.hide_heatmap")))]):_vm._e(),(!_vm.enableHeatmap)?_c(VBtn,{attrs:{"dense":"","loading":_vm.$apollo.queries.trackerLocations
                                                .loading},on:{"click":function($event){_vm.enableHeatmap = true}}},[_vm._v(_vm._s(_vm.$t("partners.show_heatmap")))]):_vm._e()],1)],1)])],1)],1),_c(VCol,{staticClass:"pr-2 pl-2",attrs:{"cols":"6","sm":"6","md":"4","lg":"4"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"px-0 mx-0"},[_c(VForm,{ref:"form",model:{value:(_vm.validate),callback:function ($$v) {_vm.validate=$$v},expression:"validate"}},[(
                                        _vm.title ===
                                            _vm.$t('home.partners_locations')
                                    )?_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.partnersTypes,"item-text":"text","item-value":"value","label":_vm.$t('headers.partner_type') +
                                                    '*',"outlined":"","dense":"","clearable":"","rules":[_vm.rules.required]},model:{value:(_vm.partnerType),callback:function ($$v) {_vm.partnerType=$$v},expression:"partnerType"}})],1),(_vm.partnerType !== null)?_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.partners.edges,"no-data-text":_vm.$t('loading.no_available'),"item-value":"node.id","item-text":"node.name","label":_vm.partnerTypeText,"outlined":"","dense":"","cleareable":"","loading":_vm.$apollo.queries
                                                    .tenantPartners.loading,"disabled":_vm.$apollo.queries
                                                    .tenantPartners.loading,"rules":[_vm.rules.required]},model:{value:(_vm.tenantPartnerId),callback:function ($$v) {_vm.tenantPartnerId=$$v},expression:"tenantPartnerId"}})],1):_vm._e(),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c(VDivider)],1)],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('headers.name') + '*',"rules":[_vm.rules.required],"placeholder":_vm.$t('general.example')},model:{value:(_vm.locationName),callback:function ($$v) {_vm.locationName=$$v},expression:"locationName"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"dense":"","outlined":"","hide-details":"","label":_vm.$t('partners.city') + '*'},model:{value:(_vm.locationCity),callback:function ($$v) {_vm.locationCity=$$v},expression:"locationCity"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"dense":"","outlined":"","hide-details":"","label":_vm.$t('partners.country') + '*'},model:{value:(_vm.locationCountry),callback:function ($$v) {_vm.locationCountry=$$v},expression:"locationCountry"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"dense":"","outlined":"","hide-details":"","label":_vm.$t('partners.address')},model:{value:(_vm.locationFullAddress),callback:function ($$v) {_vm.locationFullAddress=$$v},expression:"locationFullAddress"}})],1),(
                                            _vm.title ===
                                                _vm.$t('home.my_locations')
                                        )?_c(VCol,{attrs:{"cols":"12"}},[_c(VCombobox,{attrs:{"items":_vm.tagsChoices,"return-object":false,"item-text":"text","item-value":"value","small-chips":"","multiple":"","deletable-chips":"","dense":"","outlined":"","hide-details":"","label":_vm.$t('headers.tags'),"menu-props":{
                                                maxHeight: '200px'
                                            }},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1):_vm._e(),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VSwitch,{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","label":_vm.$t(
                                                    'partners.partner_location'
                                                )},model:{value:(_vm.isTrackerStockLocation),callback:function ($$v) {_vm.isTrackerStockLocation=$$v},expression:"isTrackerStockLocation"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VRow,{staticClass:"pr-6",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-0",attrs:{"cols":"8"}},[_c(VSwitch,{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","label":_vm.$t(
                                                            'partners.public_location'
                                                        )},model:{value:(_vm.publicLocation),callback:function ($$v) {_vm.publicLocation=$$v},expression:"publicLocation"}})],1),_c(VCol,{staticClass:"mt-n1 px-0",attrs:{"cols":"2"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                        var on = ref.on;
                                                        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("info")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "partners.public_examples" ))+" ")])])],1)],1)],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VRow,{staticClass:"pr-6",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-0",attrs:{"cols":"8"}},[_c(VSwitch,{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","label":_vm.$t(
                                                            'weather.weather_forecast'
                                                        )},model:{value:(
                                                        _vm.showWeatherForecast
                                                    ),callback:function ($$v) {
                                                        _vm.showWeatherForecast
                                                    =$$v},expression:"\n                                                        showWeatherForecast\n                                                    "}})],1),_c(VCol,{staticClass:"mt-n1 px-0",attrs:{"cols":"2"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                        var on = ref.on;
                                                        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("info")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "weather.enable_weather_forcast" ))+":"),_c('br'),_vm._v(" "+_vm._s(_vm.$t( "weather.enable_weather_forcast_description" ))+" ")])])],1)],1)],1),(_vm.showWeatherForecast)?_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{style:({ display: 'block' }),attrs:{"dense":"","outlined":"","hide-details":"","label":_vm.$t(
                                                    'general.maximum_temperature'
                                                ),"type":"number","step":"0.25","max":"50","min":"-25"},model:{value:(_vm.maxTemperature),callback:function ($$v) {_vm.maxTemperature=$$v},expression:"maxTemperature"}})],1):_vm._e(),(_vm.showWeatherForecast)?_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{style:({ display: 'block' }),attrs:{"dense":"","outlined":"","hide-details":"","label":"Minimum Temperature","type":"number","step":"0.25","max":"50","min":"-25"},model:{value:(_vm.minTemperature),callback:function ($$v) {_vm.minTemperature=$$v},expression:"minTemperature"}})],1):_vm._e(),(_vm.locationLatitude == null)?_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{staticClass:"caption",attrs:{"dense":"","type":"info","text":""}},[_vm._v(" "+_vm._s(_vm.$t( "partners.select_location" ))+" ")])],1):_vm._e()],1)],1)],1),_c(VCardActions,{staticClass:"mt-10"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.resetLocation}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"disabled":!_vm.validate,"color":"primary","rounded":"","loading":_vm.isLoadingLocationAdd},on:{"click":_vm.createTenantLocation}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }