import Vue from "vue";
import Vuex from "vuex";
import snackbar from "./modules/snackbar";
import user from "./modules/user";
import trip from "./modules/trip.js";
import location from "./modules/location.js";
import partner from "./modules/partner.js";
import staff from "./modules/staff.js";
import group from "./modules/group.js";
// import mapGetters from "vuex"

Vue.use(Vuex);

// const getters = {
//   ...mapGetters("user", "isLoggedIn")
// }

export default new Vuex.Store({
    // getters: getters,
    modules: {
        snackbar,
        user,
        trip,
        location,
        partner,
        staff,
        group

    }
});
