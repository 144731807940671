import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"items":_vm.computedTableData,"headers":_vm.permissionHeaders,"disable-pagination":"","loading-text":_vm.$t('loading.loading1'),"loader-height":"2","hide-default-footer":"","loading":_vm.isLoadingTable},scopedSlots:_vm._u([{key:"item.view.value",fn:function(ref){
var item = ref.item;
return [(item.view.value)?_c(VCheckbox,{attrs:{"value":("" + (item.view.value))},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}}):_vm._e()]}},{key:"item.create.value",fn:function(ref){
var item = ref.item;
return [(item.create.value)?_c(VCheckbox,{attrs:{"value":("" + (item.create.value))},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}}):_vm._e()]}},{key:"item.edit.value",fn:function(ref){
var item = ref.item;
return [(item.edit.value)?_c(VCheckbox,{attrs:{"value":("" + (item.edit.value))},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}}):_vm._e()]}},{key:"item.delete.value",fn:function(ref){
var item = ref.item;
return [(item.delete.value)?_c(VCheckbox,{attrs:{"value":("" + (item.delete.value))},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }