<template>
    <div class="search">
        <v-icon color="primary">location_on</v-icon>
        <input
            class="searchbar"
            type="text"
            ref="searchbar"
            :placeholder="$t('partners.search_location')"
        />
    </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import Config from "@/utils/config.json";

export default {
    props:{
        value: {
            type: Object,
        }
    },
    data() {
        return {
            apiKey: Config.VUE_APP_GOOGLE_MAP_API_KEY,
            loader: null,
            autocomplete: null,
        };
    },
    computed: {
        searchLocation: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                }
            }
    },
    created(){
        this.loader = new Loader({
            apiKey: this.apiKey,
            version: "weekly",
            libraries: ["places"]
        });
    },
    mounted(){
        this.googleSearch();
    },
    methods: {
        googleSearch() {
            let input = this.$refs.searchbar;
            this.loader.load().then(() => {
                this.autocomplete = new window.google.maps.places.Autocomplete(
                    input,
                    {
                        fields: [
                            "place_id",
                            "formatted_address",
                            "address_components",
                            "geometry"
                        ]
                    }
                );
                this.autocomplete.addListener(
                    "place_changed",
                    this.onPlaceChanged
                );
            });
        },
        onPlaceChanged() {
            this.searchLocation = this.autocomplete.getPlace();
            this.$emit("onPlaceChanged");
        },
    }
};
</script>

<style scoped>
.search {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: white;
    border-radius: 5px;
    height: 2.5rem;
    width: 300px;
    padding: 0.5rem;
    padding-right: 0;
    margin-top: 0.4rem;
}
.search input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: auto 0;
}
</style>
