<template>
    <v-row no-gutters>
        <v-col cols="12" class="py-0">
            <v-autocomplete
                v-model="partners"
                :loading="$apollo.queries.tenantPartners.loading"
                :items="tenantPartners.edges"
                :label="label"
                dense
                hide-selected
                hide-details
                :outlined="outLined"
                clearable
                :small-chips="chips"
                :chips="chips"
                :deletable-chips="chips"
                :multiple="multiple"
                item-text="node.name"
                item-value="node.id"
                :no-data-text="
                    $apollo.queries.tenantPartners.loading
                        ? this.$t('loading.loading1')
                        : this.$t('loading.no_available')
                "
                :menu-props="{
                    maxHeight: '200px'
                }"
            >
                <template #append-item>
                    <v-divider v-if="activeSlot" class="mt-4 append-divider"></v-divider>
                    <v-list-item v-if="activeSlot" dense class="append py-0">
                        <v-list-item-content>
                            <!-- Use the slot named 'custom-action' here -->
                            <slot name="custom-action"></slot>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-autocomplete>
        </v-col>
    </v-row>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "PartnerList",
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: Array,
            default: () => []
        },
        refreshTable: {
            type: Boolean,
            default: false
        },
        outLined: {
            type: Boolean,
            default: false
        },
        activeSlot: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        chips: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $partnerType: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        partnerType: $partnerType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name",
                    partnerType: "consignee"
                };
            },
            fetchPolicy: "no-cache",
            update: data => data.tenantPartners,
            skip() {
                return false;
            }
        }
    },
    data() {
        return {
            partnerFilter: {
                first: 20
            },
            loading: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            tenantPartners: { edges: [] },
            openPartnersList: false
        };
    },
    watch: {
        openPartnersList() {}
    },
    computed: {
        hasMoreData() {
            return this.tenantPartners?.pageInfo?.hasNextPage;
        },
        partners: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    methods: {}
};
</script>
<style scoped>
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
</style>
