import * as types from "../types";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, apolloClient, onLogin, onLogout } from "@/vue-apollo";

import {
    LOGIN_MUTATION,
    LOGOUT_MUTATION,
    ME_QUERY,
    UPDATE_MY_PREFERENCES_MUTATION,
    LOGIN_AS_OTHER_USER_MUTATION,
    LOGOUT_AS_OTHER_USER_MUTATION
} from "@/graphql/user.js";

const token = localStorage.getItem(ACCESS_TOKEN_KEY);
const me = null;
const original_token = localStorage.getItem("original_" + ACCESS_TOKEN_KEY);

const state = token
    ? {
          loggedIn: true,
          me: me,
          loggedInAsOtherUser: original_token ? true : false,
          showPreview: localStorage.getItem("showPreview") || true
      }
    : {
          loggedIn: false,
          me: null,
          loggedInAsOtherUser: false,
          showPreview: localStorage.getItem("showPreview") || true
      };

const getters = {
    showPreview: state => state.showPreview
};

const mutations = {
    [types.LOGIN](state) {
        state.loggedIn = true;
    },
    [types.LOGOUT](state) {
        state.loggedIn = false;
        state.me = null;
    },
    [types.SET_ME](state, me) {
        state.me = me;
    },
    [types.LOGIN_AS_OTHER_USER](state) {
        state.loggedInAsOtherUser = true;
    },
    [types.LOGOUT_AS_OTHER_USER](state) {
        state.loggedInAsOtherUser = false;
    },
    setLocale(state, locale) {
        state.locale = locale;
    },
    setShowPreview(state, showPreview) {
        state.showPreview = showPreview;
    }
};

const actions = {
    async login(context, payload) {
        let routeColor = undefined;
        if (localStorage.getItem("routeColor", routeColor) != null) {
            routeColor = localStorage.getItem("routeColor", routeColor);
        }

        let locale = localStorage.getItem("locale");

        localStorage.clear();

        localStorage.setItem("locale", locale);
        // console.log("locale", locale);

        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: LOGIN_MUTATION,
                    variables: {
                        email: payload.email,
                        password: payload.password,
                        tfaCode: payload.tfaCode
                    }
                })
                .then(response => {
                    const { token, refreshToken, user } = response.data.tokenAuth;

                    context.commit("LOGOUT_AS_OTHER_USER");

                    localStorage.setItem(ACCESS_TOKEN_KEY, token);
                    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
                    if (routeColor != undefined) localStorage.setItem("routeColor", routeColor);

                    // reset apollo client state
                    onLogin(apolloClient);

                    // commit vuex store state
                    context.commit(types.LOGIN);

                    context.commit(types.SET_ME, { ...user });

                    resolve(response);
                })
                .catch(error => {
                    onLogout(apolloClient);

                    console.log("login failed!", error);
                    reject(error);
                });
        });
    },

    async fetchMe(context) {
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: ME_QUERY,
                    fetchPolicy: "network-only"
                })
                .then(response => {
                    if (response.data.me) {
                        // localStorage.setItem(
                        //     "me",
                        //     JSON.stringify(response.data.me)
                        // );

                        let userPreferences = JSON.parse(response.data.me.preferences);

                        let _locale = userPreferences?.locale || "en";

                        localStorage.setItem("locale", _locale);

                        context.commit("setLocale", _locale);

                        // localStorage.setItem("locale", response.data.me.locale);
                        // let _locale = localStorage.getItem("locale") || "en";
                        // context.commit('setLocale', _locale);

                        context.commit(types.SET_ME, { ...response.data.me });
                    }

                    resolve(response);
                })
                .catch(error => {
                    onLogout(apolloClient);

                    console.log("fetchme failed!", error);
                    reject(error);
                });
        });
    },

    destroyStorage({ commit }) {
        localStorage.removeItem("locale");
        localStorage.removeItem("showPreview");
        localStorage.removeItem("filters");
        localStorage.removeItem("userPreferences");
    },

    async updateMyPreferences(context, payload) {
        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: UPDATE_MY_PREFERENCES_MUTATION,
                    variables: {
                        input: payload
                    }
                })
                .then(response => {
                    // localStorage.setItem(
                    //     "me",
                    //     JSON.stringify(response.data.updateMyPreferences.user)
                    // );
                    context.commit(types.SET_ME, {
                        ...response.data.updateMyPreferences.user
                    });
                    resolve(response);
                })
                .catch(error => {
                    onLogout(apolloClient);

                    console.log("login failed!", error);
                    reject(error);
                });
        });
    },

    async loginAsOtherUser(context, payload) {
        return new Promise((resolve, reject) => {
            const input = {
                userId: payload.userId
            };

            apolloClient
                .mutate({
                    mutation: LOGIN_AS_OTHER_USER_MUTATION,
                    variables: {
                        input: input
                    }
                })
                .then(response => {
                    // Backup original tokens
                    localStorage.setItem("original_" + ACCESS_TOKEN_KEY, localStorage.getItem(ACCESS_TOKEN_KEY));
                    localStorage.setItem("original_" + REFRESH_TOKEN_KEY, localStorage.getItem(REFRESH_TOKEN_KEY));

                    // Replace tokens
                    const { token, refreshToken } = response.data.loginAsOtherUser;

                    localStorage.setItem(ACCESS_TOKEN_KEY, token);
                    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

                    // Clear Apollo client cache
                    return apolloClient.clearStore();
                })
                .then(() => {
                    //distroy storage
                    context.dispatch("destroyStorage");
                    // Fetch the new user's data
                    return context.dispatch("fetchMe");
                })
                .then(() => {
                    context.commit("LOGIN_AS_OTHER_USER");
                    resolve();
                })
                .catch(error => {
                    onLogout(apolloClient);
                    console.log("login as other user failed!", error);
                    reject(error);
                });
        });
    },

    async logoutAsOtherUser(context) {
        return new Promise((resolve, reject) => {
            const input = {
                userId: context.state.me.id,
                refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY)
            };

            apolloClient
                .mutate({
                    mutation: LOGOUT_AS_OTHER_USER_MUTATION,
                    variables: {
                        input: input
                    }
                })
                .then(() => {
                    // restore original tokens
                    let _original_access_token = localStorage.getItem("original_" + ACCESS_TOKEN_KEY);
                    let _original_refresh_token = localStorage.getItem("original_" + REFRESH_TOKEN_KEY);

                    localStorage.removeItem("filters");
                    localStorage.removeItem("logo");
                    localStorage.removeItem("themeColor");

                    if (_original_access_token && _original_refresh_token) {
                        localStorage.setItem(ACCESS_TOKEN_KEY, localStorage.getItem("original_" + ACCESS_TOKEN_KEY));
                        localStorage.setItem(REFRESH_TOKEN_KEY, localStorage.getItem("original_" + REFRESH_TOKEN_KEY));
                    }

                    // remove backup tokens
                    localStorage.removeItem("original_" + ACCESS_TOKEN_KEY);
                    localStorage.removeItem("original_" + REFRESH_TOKEN_KEY);

                    // reset apollo client state
                    // await onLogin(apolloClient);
                    apolloClient.clearStore();
                    return;
                    // context.commit(types.LOGIN);
                })
                .then(() => {
                    context.dispatch("fetchMe");
                    context.commit("LOGOUT_AS_OTHER_USER");

                    resolve();
                })
                .catch(error => {
                    // localStorage.removeItem("token");
                    onLogout(apolloClient);

                    console.log("logout as other user failed!", error);
                    reject(error);
                })
                .finally(() => {});
        });
    },

    async logoutLocalOnly(context) {
        localStorage.removeItem("me");
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);

        context.commit(types.LOGOUT);
    },

    async logout(context) {
        return new Promise((resolve, reject) => {
            const input = {
                refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY)
            };

            apolloClient
                .mutate({
                    mutation: LOGOUT_MUTATION,
                    variables: {
                        input: input
                    }
                })
                .then(() => {
                    context.commit(types.LOGOUT);
                    context.commit(types.LOGOUT_AS_OTHER_USER);
                })
                .then(() => {
                    // // reset apollo client state
                    onLogout(apolloClient);
                    //   console.log("login succeed! ", token);
                    resolve();
                })
                .catch(error => {
                    // localStorage.removeItem("token");
                    onLogout(apolloClient);

                    console.log("logout failed!", error);
                    reject(error);
                })
                .finally(() => {
                    // // remove token from local storage
                    // localStorage.removeItem("me");
                    // localStorage.removeItem(ACCESS_TOKEN_KEY);
                    // localStorage.removeItem(REFRESH_TOKEN_KEY);
                    // // removed backup tokens
                    // localStorage.removeItem("original_" + ACCESS_TOKEN_KEY);
                    // localStorage.removeItem("original_" + REFRESH_TOKEN_KEY);
                    let locale = localStorage.getItem("locale");
                    localStorage.clear();
                    localStorage.setItem("locale", locale);
                    context.commit(types.LOGOUT);
                    context.commit(types.LOGOUT_AS_OTHER_USER);
                });
        });
    },

    updateShowPreview({ commit }, showPreview) {
        let preview = false;
        //for the first time showPreview send a string, convert it to boolean
        if (showPreview.showPreview == "true") preview = true;
        else if (showPreview.showPreview == "false") preview = false;
        else preview = showPreview.showPreview;

        commit("setShowPreview", preview);
        localStorage.setItem("showPreview", preview);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
