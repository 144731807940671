<template>
    <v-container class="px-5">
        <v-row>
            <v-col cols="12">
                <LoadingBar v-if="$apollo.queries.partnerPermissions.loading"></LoadingBar>
                <PermissionForm
                    v-else
                    :partnerPermissions="selectedPermissions"
                    :permissions="permissions"
                    v-on:update-permissions="updatePermissions"
                ></PermissionForm>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import PermissionForm from "@/components/partners/forms/PermissionForm.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";

import {
    TripPermissions,
    // TrackerPermissions,
    CarrierPermissions,
    TenantPermissions,
    BookingPermissions
} from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "PartnerPermissionsTab",
    components: { PermissionForm, LoadingBar },
    props: {
        partner: {
            type: Object,
            required: true
        }
    },
    apollo: {
        partnerPermissions: {
            query: gql`
                query TenantPartner($id: ID!) {
                    tenantPartner(id: $id) {
                        id
                        name
                        organisationPermissions
                    }
                }
            `,
            variables() {
                return {
                    id: this.partner ? this.partner.id : null
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantPartner;
            },
            result({ loading, data }) {
                if (!loading) {
                    let _permissions = JSON.parse(data.tenantPartner.organisationPermissions);

                    if (Array.isArray(_permissions)) {
                        _permissions.forEach(item => {
                            if (this.permissions.find(ele => ele.value === item)) {
                                if (!this.selectedPermissions.includes(item)) {
                                    this.selectedPermissions.push(item);
                                }
                            }
                        });
                    }
                }
            },
            skip: false
        }
    },
    data() {
        return {
            isLoadingSavePermission: false,
            enableOrDisablePermissionBtn: true,
            selectedPermissions: [],
            selectedPermissionsCopy: [],
            searchInput: "",
            permissions: [
                {
                    module: this.$t("partners.trip_carrier"),
                    permission: "View",
                    description: this.$t("partners.carrier_description"),
                    value: CarrierPermissions.VIEW_CARRIER,
                    managePermission: CarrierPermissions.MANAGE_CARRIER
                },
                {
                    module: this.$t("partners.trip_documents"),
                    permission: "View",
                    description: this.$t("partners.documents_description"),
                    value: TripPermissions.VIEW_TRIP_DOCUMENT,
                    managePermission: TripPermissions.MANAGE_TRIP_DOCUMENT
                },
                {
                    module: this.$t("partners.trip_trackers"),
                    permission: "View",
                    description: this.$t("partners.trackers_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT_TRACKER,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT_TRACKER
                },
                {
                    module: this.$t("partners.trip_freights"),
                    permission: "View",
                    description: this.$t("partners.trackers_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT
                },
                {
                    module: this.$t("partners.trip_partners"),
                    permission: "View",
                    description: this.$t("partners.partners_description"),
                    value: TripPermissions.VIEW_TRIP_PARTNER,
                    managePermission: TripPermissions.MANAGE_TRIP_PARTNER
                },
                {
                    module: this.$t("partners.trip_map"),
                    permission: "View",
                    description: this.$t("partners.map_description"),
                    value: TenantPermissions.VIEW_TENANT_LOCATION,
                    managePermission: TenantPermissions.MANAGE_TENANT_LOCATION
                },
                {
                    module: this.$t("partners.trip_sea"),
                    permission: "View",
                    description: this.$t("partners.sea_description"),
                    value: TripPermissions.VIEW_TRIP_SEA_TRANSPORT,
                    managePermission: TripPermissions.MANAGE_TRIP_SEA_TRANSPORT
                },
                {
                    module: this.$t("partners.trip_alert"),
                    permission: "View",
                    description: this.$t("partners.alert_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT_ALERT,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT_ALERT
                },
                {
                    module: this.$t("partners.trip_alert"),
                    permission: "Resolve",
                    description: this.$t("partners.alert_resolve"),
                    value: TripPermissions.EDIT_TRIP_FREIGHT_ALERT
                },
                {
                    module: this.$t("partners.trip_alert"),
                    permission: "Resolve",
                    description: this.$t("partners.alert_resolve"),
                    value: TripPermissions.EDIT_TRIP_FREIGHT_ALERT
                },
                {
                    module: this.$t("partners.trip_timeline"),
                    permission: "View",
                    description: this.$t("partners.timeline_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT_TIMELINE
                },
                {
                    module: this.$t("partners.trip_report"),
                    permission: "View",
                    description: this.$t("partners.trip_report_view_description"),
                    value: TripPermissions.VIEW_TRIP_REPORT,
                    managePermission: TripPermissions.MANAGE_TRIP_REPORT
                },
                {
                    module: this.$t("partners.trip_report"),
                    permission: "Create",
                    description: this.$t("partners.trip_report_create_description"),
                    value: TripPermissions.CREATE_TRIP_REPORT,
                    managePermission: TripPermissions.MANAGE_TRIP_REPORT
                },
                {
                    module: this.$t("partners.qx_booking"),
                    permission: "View",
                    description: this.$t("partners.qx_booking_description"),
                    value: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                },
                {
                    module: this.$t("partners.qx_booking"),
                    permission: "Create",
                    description: this.$t("partners.qx_booking_create_description"),
                    value: BookingPermissions.CREATE_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                },
                {
                    module: this.$t("partners.qx_booking"),
                    permission: "Edit",
                    description: this.$t("partners.qx_booking_edit_description"),
                    value: BookingPermissions.EDIT_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                }
            ]
        };
    },
    beforeCreate() {
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        // this.TrackerPermissions = TrackerPermissions;
        this.TripPermissions = TripPermissions;
        this.CarrierPermissions = CarrierPermissions;
        this.TenantPermissions = TenantPermissions;
    },
    watch: {},
    computed: {},
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "partner_locations_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("partner_permissions_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("partner_permissions_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        cardBody(item) {
            const { module, permission, description } = item;

            let _body = [
                {
                    title: this.$t("general.module"),
                    tag: "module",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: module
                },
                {
                    title: this.$t("general.permission"),
                    tag: "permission",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: permission
                },
                {
                    title: this.$t("general.description"),
                    tag: "description",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: description
                }
            ];

            return _body;
        },
        updatePermissions(permissionsSelected) {
            // Initialize Loading :
            this.isLoadingSavePermission = true;
            // Initialize Mutation :
            this.selectedPermissions = [...new Set(permissionsSelected.map(item => item))];

            let permissionList = [...new Set(permissionsSelected.map(item => item))];

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation CreateTenantPartnerPermission($input: CreateTenantPartnerPermissionInput!) {
                            createTenantPartnerPermission(input: $input) {
                                tenantPartner {
                                    id
                                    name
                                    organisationPermissions
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            partnerId: this.partner.id,
                            permissions: JSON.stringify(permissionList)
                        }
                    }
                })
                .then(res => {
                    if (res) {
                        const payload = {
                            color: "blue",
                            message: this.$t("general.update_successfully")
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    }

                    /* */
                })
                .catch(error => {
                    if (error.message == "INVALID_REFERENCE_NUMBER") this.error = true;
                    // this.isLoadingSavePermission = false;
                })
                .finally(() => {
                    this.enableOrDisablePermissionBtn = true;
                    this.isLoadingSavePermission = false;
                    // this.$apollo.queries.partnerPermissions.refetch();
                });
        }
    }
};
</script>
<style scoped>
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
.outline {
    border: 1px solid #ebebf5;
    box-sizing: border-box;
    border-radius: 4px;
}
.backgroundContent {
    background: #f9fafe;
}
</style>
