<template>
    <v-card elevation="0" outlined v-resize="onResize" color="transparent">
        <v-card-title class="px-0">
            <v-row>
                <v-col cols="12">
                    <v-tabs v-model="tab" background-color="transparent">
                        <v-tab v-for="item in tabs" :key="item.value">
                            <template v-slot:default>
                                <div class="text-capitalize">
                                    {{ $t(`trackers.${item.text}`) }}
                                </div>
                            </template>
                        </v-tab>
                    </v-tabs>
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                    <v-row dense class="d-flex align-center ">
                        <v-col cols="6" xs="6" sm="6" md="3" lg="2" xl="2" class="pt-0">
                            <!-- <v-switch
                                v-if="tab === 0"
                                v-model="showAll"
                                :label="showAll ? $t('general.active') : $t('general.all_trackers')"
                                hide-details
                                inset
                                color="primary"
                                class="ml-2 text-no-wrap mt-n1"
                                @change="updateTableFilter"
                            ></v-switch> -->
                            <!-- Toggle button -->
                            <v-btn-toggle
                                v-model="showAll"
                                dense
                                class="rounded-md"
                                @change="updateTableFilter"
                                background-color="transparent"
                            >
                                <v-btn
                                    :color="showAll === 0 ? 'primary' : primaryTextLighten"
                                    :class="showAll === 0 ? 'elevation-2' : 'elevation-0'"
                                >
                                    <span :class="showAll === 0 ? 'white--text tabText1' : 'grey--text'">
                                        {{ $t("general.all") }}
                                    </span>
                                </v-btn>
                                <v-btn
                                    :color="showAll === 1 ? 'primary' : primaryTextLighten"
                                    :class="showAll === 1 ? 'elevation-2' : 'elevation-0'"
                                >
                                    <span :class="showAll === 1 ? 'white--text tabText1' : 'grey--text'">
                                        {{ $t("general.active") }}
                                    </span>
                                </v-btn>
                                <v-btn
                                    :color="showAll === 2 ? 'primary' : primaryTextLighten"
                                    :class="showAll === 2 ? 'elevation-2' : 'elevation-0'"
                                >
                                    <span :class="showAll === 2 ? 'white--text tabText1' : 'grey--text'">
                                        {{ $t("general.not_activated") }}
                                    </span>
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                        <!-- <v-col cols="4" lg="auto" md="4" sm="4" xs="4">
                            <v-select
                                prepend-inner-icon="sort"
                                v-model="sortBy"
                                :items="sortByChoices"
                                :label="$t('general.sort_by')"
                                :item-value="item => item.code"
                                :item-text="item => item.name"
                                outlined
                                clearable
                                hide-details
                                dense
                                color="primary"
                            >
                                <template v-slot:item="{ item }">
                                    <div class="caption">
                                        {{ item.name }}
                                    </div>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <div class="caption text-truncate">
                                        {{ item.name }}
                                    </div>
                                </template>
                                <template v-slot:append-outer>
                                    <v-btn
                                        class="mt-n1"
                                        small
                                        icon
                                        :disabled="!sortBy"
                                        @click="sortAsc = !sortAsc"
                                    >
                                        <v-icon color="grey darken-2">{{
                                            sortAsc ? "north" : "south"
                                        }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-select>
                        </v-col> -->
                        <v-spacer></v-spacer>

                        <div v-if="canEdit && tab === 0 && !isSmallScreen">
                            <v-btn v-if="openSelector" @click="openSelector = false" text class="mr-2">{{
                                $t("general.cancel")
                            }}</v-btn>
                            <v-btn v-else color="primary" @click="openSelector = true">
                                {{ stockMoveText }}
                            </v-btn>
                            <!-- Tracker change location -->
                            <TrackerChangeLocation
                                v-if="!isStaff && canEdit && openSelector"
                                :rounded="false"
                                :openTrackerChangeLocationDialog="openTrackerChangeLocationDialog"
                                :selectedTrackers="selectedTrackersToMove"
                                v-on:closeSelector="closeSelector"
                                v-on:updateTrackersTable="$apollo.queries.trackers.refetch()"
                            >
                            </TrackerChangeLocation>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="px-0">
            <v-row dense align="center">
                <v-col cols="12" class="d-flex align-center justify-center" v-if="trackersIsLoading && !hasTrackers">
                    <LoadingBar></LoadingBar>
                </v-col>
                <v-col cols="12" v-else>
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="item in tabs" :key="item.value" :transition="false" class="tabItemCl">
                            <div v-if="hasTrackers">
                                <div v-if="item.value === 'trackers' && trackers.edges.length > 0">
                                    <v-card
                                        v-for="item in trackers.edges"
                                        :key="item.node.id"
                                        class="mb-4 rounded-lg "
                                        outlined
                                        flat
                                        @click.stop="detailsPage(item, 'tracker')"
                                    >
                                        <v-card-text>
                                            <v-row dense align="center">
                                                <v-col
                                                    cols="12"
                                                    xs="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="12"
                                                    v-if="layoutTransitionLgToSm"
                                                >
                                                    <v-row class="d-flex align-center justify-end" align="center" dense>
                                                        <v-col class="d-flex align-center justify-center" cols="auto">
                                                            <div class="mt-1 textLeft">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-on="on"
                                                                            v-bind="attrs"
                                                                            :color="
                                                                                trackerStatus(item.node.activatedDate)
                                                                                    .color2
                                                                            "
                                                                            size="20"
                                                                        >
                                                                            settings_remote
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>
                                                                        {{
                                                                            trackerStatus(item.node.activatedDate).text
                                                                        }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </v-col>
                                                        <v-col class="d-flex align-center justify-center" cols="auto"
                                                            v-if="item.node.isBeacon"
                                                        >
                                                            <div class="mt-1 textLeft">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-on="on"
                                                                            v-bind="attrs"
                                                                            color="primary"
                                                                            small
                                                                        >
                                                                            wifi_tethering
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>
                                                                        {{ $t("headers.beaconTracker") }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </v-col>

                                                        <v-col class="d-flex align-center justify-center" cols="auto">
                                                            <div class="mt-1 textLeft">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-badge
                                                                            :color="getBatteryLevel(item.node).color"
                                                                            bordered
                                                                            offset-x="5"
                                                                            offset-y="10"
                                                                            right
                                                                        >
                                                                            <template v-slot:badge>
                                                                                <div>
                                                                                    {{ getTrackerValue(item.node) }}
                                                                                </div>
                                                                            </template>
                                                                            <v-icon
                                                                                v-on="on"
                                                                                v-bind="attrs"
                                                                                :color="
                                                                                    getBatteryLevel(item.node).color
                                                                                "
                                                                                small
                                                                            >
                                                                                {{ getBatteryLevel(item.node).level }}
                                                                            </v-icon>
                                                                        </v-badge>
                                                                    </template>
                                                                    <span>
                                                                        {{ vTooltip(item.node) }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </v-col>
                                                        <v-col class="d-flex align-center justify-center pa-0 ml-2" cols="auto">
                                                            <div class="mt-1 textLeft">
                                                                <v-menu
                                                                    offset-y
                                                                    bottom
                                                                    transition="slide-x-reverse-transition"
                                                                    width="250px"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon v-bind="attrs" v-on="on" small>
                                                                            <v-icon>
                                                                                more_vert
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-list>
                                                                        <v-list-item v-if="!isStaff && canEdit">
                                                                            <!-- <v-btn plain @click="assignToStockLocation(item)">
                                                                                    <v-icon left>
                                                                                        add_location
                                                                                    </v-icon>
                                                                                    {{ $t("trackers.assign_to") }} {{ $t("trackers.stock_location") }}
                                                                                </v-btn> -->
                                                                            <TrackerChangeLocation
                                                                                :isMenuItem="true"
                                                                                :selectedTrackers="
                                                                                    selectedTrackersToMove
                                                                                "
                                                                                v-on:closeSelector="closeSelector"
                                                                                v-on:updateTrackersTable="
                                                                                    $apollo.queries.trackers.refetch()
                                                                                "
                                                                                v-on:menuItemClicked="
                                                                                    assignToStockLocation(item)
                                                                                "
                                                                            >
                                                                            </TrackerChangeLocation>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-menu>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    xs="12"
                                                    sm="12"
                                                    md="12"
                                                    :lg="layoutTransitionLgToSm ? '12' : '11'"
                                                    xl="11"
                                                    class="pa-0"
                                                >
                                                    <v-row class="pa-0" dense >
                                                        <v-col
                                                            cols="6"
                                                            xl="2"
                                                            lg="2"
                                                            md="3"
                                                            sm="4"
                                                            xs="6"
                                                            class="align-center justify-start"
                                                        >
                                                            <div>
                                                                <v-checkbox
                                                                    v-if="!isStaff && canEdit && openSelector"
                                                                    v-model="selectedTrackersToMove"
                                                                    :value="item"
                                                                    hide-details
                                                                    color="primary"
                                                                    class="mt-0"
                                                                    @click.stop=""
                                                                ></v-checkbox>
                                                            </div>
                                                            <div class="mt-1 textLeft">
                                                                <div
                                                                    class="font-weight-thin secondaryText  text-truncate"
                                                                >
                                                                    {{ $t("headers.serial_number") }}
                                                                </div>
                                                                <div class="font-weight-bold primaryText text-truncate">
                                                                    {{ item.node.serialNumber }}
                                                                </div>
                                                            </div>
                                                        </v-col>

                                                        <v-col
                                                            cols="6"
                                                            xl="2"
                                                            lg="2"
                                                            md="3"
                                                            sm="4"
                                                            xs="6"
                                                            class="align-center justify-start"
                                                        >
                                                            <div class="mt-1 textLeft">
                                                                <div
                                                                    class="font-weight-thin secondaryText  text-truncate"
                                                                >
                                                                    {{ $t("headers.trip_number") }}
                                                                </div>
                                                                <div class="font-weight-bold primaryText text-truncate">
                                                                    {{ item.node.tripReferenceNumber }}
                                                                </div>
                                                            </div>
                                                        </v-col>
                                                        <!-- <v-col cols="1" xl="1" lg="1" md="1" sm="2" xs="2" class="d-flex align-center justify-start d-md-none d-lg-none d-xl-none">
                                                            <v-row
                                                                no-gutters
                                                                class="d-flex align-center justify-end "
                                                                align="center"
                                                            >
                                                                <v-col class="d-flex align-center justify-center">
                                                                    <div class="mt-1 textLeft">
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-badge
                                                                                    :color="
                                                                                        getBatteryLevel(item.node.lastBatteryValue)
                                                                                            .color
                                                                                    "
                                                                                    bordered
                                                                                    offset-x="5"
                                                                                    offset-y="10"
                                                                                    left
                                                                                >
                                                                                    <template v-slot:badge>
                                                                                        <div>
                                                                                            {{ item.node.lastBatteryValue || 0 }}
                                                                                        </div>
                                                                                    </template>
                                                                                    <v-icon
                                                                                        v-on="on"
                                                                                        v-bind="attrs"
                                                                                        :color="
                                                                                            getBatteryLevel(
                                                                                                item.node.lastBatteryValue
                                                                                            ).color
                                                                                        "
                                                                                        small
                                                                                    >
                                                                                        {{
                                                                                            getBatteryLevel(
                                                                                                item.node.lastBatteryValue
                                                                                            ).level
                                                                                        }}
                                                                                    </v-icon>
                                                                                </v-badge>
                                                                            </template>
                                                                            <span>
                                                                                {{ $t("headers.battery") }}
                                                                            </span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </v-col>
                                                                <v-col class="d-flex align-center justify-center">
                                                                    <div class="mt-1 textLeft">
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-icon
                                                                                    v-on="on"
                                                                                    v-bind="attrs"
                                                                                    color="primary"
                                                                                    small
                                                                                >
                                                                                    wifi_tethering
                                                                                </v-icon>
                                                                            </template>
                                                                            <span>
                                                                                {{ $t("headers.beaconTracker") }}
                                                                            </span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </v-col>
                                                                <v-col class="d-flex align-center justify-center">
                                                                    <div class="mt-1 textLeft">
                                                                        <v-menu
                                                                            offset-y
                                                                            bottom
                                                                            transition="slide-x-reverse-transition"
                                                                            width="250px"
                                                                        >
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon v-bind="attrs" v-on="on" small>
                                                                                    <v-icon>
                                                                                        more_vert
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <v-list>
                                                                                <v-list-item v-if="!isStaff && canEdit">
                                                                                    <TrackerChangeLocation
                                                                                        :isMenuItem="true"
                                                                                        :selectedTrackers="selectedTrackersToMove"
                                                                                        v-on:closeSelector="closeSelector"
                                                                                        v-on:updateTrackersTable="
                                                                                            $apollo.queries.trackers.refetch()
                                                                                        "
                                                                                        v-on:menuItemClicked="
                                                                                            assignToStockLocation(item)
                                                                                        "
                                                                                    >
                                                                                    </TrackerChangeLocation>
                                                                                </v-list-item>
                                                                            </v-list>
                                                                        </v-menu>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col> -->
                                                        <v-col
                                                            cols="6"
                                                            xl="2"
                                                            lg="2"
                                                            md="3"
                                                            sm="4"
                                                            xs="6"
                                                            class="align-center justify-start"
                                                        >
                                                            <div class="mt-1 textLeft">
                                                                <div
                                                                    class="font-weight-thin secondaryText  text-truncate"
                                                                >
                                                                    {{ $t("headers.brand") }}
                                                                </div>
                                                                <div class="font-weight-bold primaryText text-truncate">
                                                                    {{ item.node.brand }}
                                                                </div>
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            cols="6"
                                                            xl="2"
                                                            lg="2"
                                                            md="3"
                                                            sm="4"
                                                            xs="6"
                                                            class="align-center justify-start"
                                                        >
                                                            <div class="mt-1 textLeft">
                                                                <div
                                                                    class="font-weight-thin secondaryText  text-truncate"
                                                                >
                                                                    {{ $t("headers.activation") }}
                                                                </div>
                                                                <div class="font-weight-bold primaryText text-truncate">
                                                                    {{ formatDateTime(item.node.activatedDate) }}
                                                                </div>
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            cols="6"
                                                            xl="2"
                                                            lg="2"
                                                            md="3"
                                                            sm="4"
                                                            xs="6"
                                                            class="align-center justify-start"
                                                        >
                                                            <div class="mt-1 textLeft">
                                                                <div
                                                                    class="font-weight-thin secondaryText  text-truncate"
                                                                >
                                                                    {{ $t("headers.expiration") }}
                                                                </div>
                                                                <div class="font-weight-bold primaryText text-truncate">
                                                                    {{ formatDateTime(item.node.expiryDate) }}
                                                                </div>
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            cols="6"
                                                            xl="2"
                                                            lg="2"
                                                            md="3"
                                                            sm="4"
                                                            xs="6"
                                                            class="align-center justify-start"
                                                        >
                                                            <div class="mt-1 textLeft">
                                                                <div
                                                                    class="font-weight-thin secondaryText  text-truncate"
                                                                >
                                                                    {{ $t("headers.last_date") }}
                                                                </div>
                                                                <div class="font-weight-bold primaryText text-truncate">
                                                                    {{ formatDateTime(item.node.lastConnectionTime) }}
                                                                </div>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="1" v-if="!layoutTransitionLgToSm" >
                                                    <!-- <v-col cols="1" xl="1" lg="1" md="1" sm="1" xs="2" class="d-flex align-center justify-start"> -->
                                                    <v-row
                                                        dense
                                                        class="d-flex align-center justify-end "
                                                        align="center"
                                                        
                                                    >
                                                        <v-col class="d-flex align-center justify-center"
                                                            cols="auto"
                                                        >
                                                            <div class="mt-1 textLeft">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-on="on"
                                                                            v-bind="attrs"
                                                                            :color="
                                                                                trackerStatus(item.node.activatedDate)
                                                                                    .color2
                                                                            "
                                                                            size="20"
                                                                        >
                                                                            settings_remote
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>
                                                                        {{
                                                                            trackerStatus(item.node.activatedDate).text
                                                                        }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            class="d-flex align-center justify-center"
                                                            cols="auto"
                                                            v-if="item.node.isBeacon"
                                                        >
                                                            <div class="mt-1 textLeft">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-on="on"
                                                                            v-bind="attrs"
                                                                            color="primary"
                                                                            small
                                                                        >
                                                                            wifi_tethering
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>
                                                                        {{ $t("headers.beaconTracker") }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </v-col>
                                                        <v-col class="d-flex align-center justify-center mr-1"
                                                            cols="auto"
                                                        >
                                                            <div class="mt-1 textLeft">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-badge
                                                                            :color="getBatteryLevel(item.node).color"
                                                                            bordered
                                                                            offset-x="5"
                                                                            offset-y="10"
                                                                            right
                                                                        >
                                                                            <template v-slot:badge>
                                                                                <div>
                                                                                    {{ getTrackerValue(item.node) }}
                                                                                </div>
                                                                            </template>
                                                                            <v-icon
                                                                                v-on="on"
                                                                                v-bind="attrs"
                                                                                :color="
                                                                                    getBatteryLevel(item.node).color
                                                                                "
                                                                                small
                                                                            >
                                                                                {{ getBatteryLevel(item.node).level }}
                                                                            </v-icon>
                                                                        </v-badge>
                                                                    </template>
                                                                    <span>
                                                                        {{ vTooltip(item.node) }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </v-col>
                                                        <v-col class="d-flex align-center justify-center pa-0 ml-1" cols="auto">
                                                            <div class="mt-1 textLeft">
                                                                <v-menu
                                                                    offset-y
                                                                    bottom
                                                                    transition="slide-x-reverse-transition"
                                                                    width="250px"
                                                                    attach
                                                                    left
                                                                    close-on-click
                                                                    nudge-top="-3"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon v-bind="attrs" v-on="on" small>
                                                                            <v-icon>
                                                                                more_vert
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-list>
                                                                        <v-list-item v-if="!isStaff && canEdit">
                                                                            <!-- <v-btn plain @click="assignToStockLocation(item)">
                                                                                    <v-icon left>
                                                                                        add_location
                                                                                    </v-icon>
                                                                                    {{ $t("trackers.assign_to") }} {{ $t("trackers.stock_location") }}
                                                                                </v-btn> -->
                                                                            <TrackerChangeLocation
                                                                                :isMenuItem="true"
                                                                                :selectedTrackers="
                                                                                    selectedTrackersToMove
                                                                                "
                                                                                v-on:closeSelector="closeSelector"
                                                                                v-on:updateTrackersTable="
                                                                                    $apollo.queries.trackers.refetch()
                                                                                "
                                                                                v-on:menuItemClicked="
                                                                                    assignToStockLocation(item)
                                                                                "
                                                                            >
                                                                            </TrackerChangeLocation>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-menu>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    <!-- </v-col> -->
                                                </v-col>

                                                <!-- <v-col cols="1" class="d-flex align-center justify-start px-0">
                                                    <div class="mt-1 textLeft">
                                                        <div class="font-weight-thin secondaryText  text-truncate">
                                                            {{ $t("headers.expiration") }}
                                                        </div>
                                                        <div class="font-weight-bold primaryText text-truncate">
                                                            {{ formatDateTime(item.node.expiryDate) }}
                                                        </div>
                                                    </div>             
                                                </v-col> -->
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                                <div v-else-if="item.value === 'history' && trackerUploadLogs.edges.length > 0">
                                    <v-card
                                        v-for="item in trackerUploadLogs.edges"
                                        :key="item.node.id"
                                        class="mb-4 rounded-lg "
                                        outlined
                                        flat
                                        @click.stop="detailsPage(item, 'history')"
                                    >
                                        <v-card-text>
                                            <v-row dense align="center">
                                                <v-col
                                                    cols="6"
                                                    xl="2"
                                                    lg="2"
                                                    md="2"
                                                    sm="4"
                                                    xs="6"
                                                    class="align-center justify-start"
                                                >
                                                    <div class="mt-1 textLeft">
                                                        <div class="font-weight-thin secondaryText  text-truncate">
                                                            {{ $t("general.uploaded_at") }}
                                                        </div>
                                                        <div class="font-weight-bold primaryText text-truncate">
                                                            {{ formatDateTime(item.node.createdAt) }}
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    xl="2"
                                                    lg="2"
                                                    md="2"
                                                    sm="4"
                                                    xs="6"
                                                    class="align-center justify-start"
                                                >
                                                    <div class="mt-1 textLeft">
                                                        <div class="font-weight-thin secondaryText  text-truncate">
                                                            {{ $t("general.uploaded_by") }}
                                                        </div>
                                                        <div class="font-weight-bold primaryText text-truncate">
                                                            {{ item.node.createdBy.fullName }}
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    xl="2"
                                                    lg="2"
                                                    md="2"
                                                    sm="4"
                                                    xs="6"
                                                    class="align-center justify-start"
                                                >
                                                    <div class="mt-1 textLeft">
                                                        <div class="font-weight-thin secondaryText  text-truncate">
                                                            {{ $t("headers.brand") }}
                                                        </div>
                                                        <div class="font-weight-bold primaryText text-truncate">
                                                            {{ item.node.brand }}
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    xl="4"
                                                    lg="4"
                                                    md="4"
                                                    sm="8"
                                                    xs="6"
                                                    class="align-center justify-start"
                                                >
                                                    <div class="mt-1 textLeft">
                                                        <div class="font-weight-thin secondaryText  text-truncate">
                                                            {{ $t("trackers.file_name") }}
                                                        </div>
                                                        <div class="font-weight-bold primaryText text-truncate">
                                                            {{ item.node.fileName }}
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    xl="2"
                                                    lg="2"
                                                    md="2"
                                                    sm="4"
                                                    xs="6"
                                                    class="align-center justify-start"
                                                >
                                                    <div class="mt-1 textLeft">
                                                        <div class="font-weight-thin secondaryText  text-truncate">
                                                            {{ $t("trackers.no_of_trackers") }}
                                                        </div>
                                                        <div class="font-weight-bold primaryText text-truncate">
                                                            {{ item.node.numberOfTrackers }}
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <!-- <v-col cols="2" class="d-flex align-center justify-start px-0">
                                                    <div class="mt-1 textLeft">
                                                        <div class="font-weight-thin secondaryText  text-truncate">
                                                            {{ $t("headers.brand") }}
                                                        </div>
                                                        <div class="font-weight-bold primaryText text-truncate">
                                                            {{ item.node.trackersForUploadLog ? item.node.trackerUploadLog.brand : '' }}
                                                        </div>
                                                    </div>             
                                                </v-col> -->
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div>
                            <div v-else-if="!trackersIsLoading && !hasTrackers">
                                <v-row class="d-flex align-center justify-center">
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="text-center">
                                        <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')"></v-img>
                                        <v-card flat color="transparent">
                                            <v-card-text class="px-0">
                                                <div class="textTitle">
                                                    {{
                                                        tab === 0
                                                            ? $t("general.empty_data_title")
                                                            : $t("trackers.no_history")
                                                    }}
                                                </div>
                                                <div class="textSubtitle">
                                                    {{ tab === 0 ? $t("general.empty_data_subtitle") : "" }}
                                                </div>
                                            </v-card-text>
                                            <v-card-actions class="px-10">
                                                <v-btn color="primary" @click="addTracker()" block>
                                                    {{ $t("trackers.add_tracker") }}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-center mb-5">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.trackers.loading"
                plain
                @click="loadMore"
            >
                Load more
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../utils/helper";
import LoadingBar from "@/components/base/LoadingBar.vue";
import TrackerChangeLocation from "@/components/trackers/TrackerChangeLocation.vue";
import { TrackerPermissions } from "@/core/enum";

export default {
    name: "TrackerTablePreview",
    components: {
        LoadingBar,
        TrackerChangeLocation
    },
    props: {
        trackerFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        trackerLogFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        isHistoryTab: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            shouldLoadMore: false,
            tab: 0,
            tabs: [
                {
                    text: "trackers",
                    value: "trackers"
                },
                {
                    text: "history",
                    value: "history"
                }
            ],
            sortBy: null,
            sortAsc: true,
            sortByChoices: [
                {
                    code: "serialNumber",
                    name: this.$t("headers.serial_number")
                },
                {
                    code: "brand",
                    name: this.$t("headers.brand")
                },
                {
                    code: "isArchived",
                    name: this.$t("headers.archived")
                },
                {
                    code: "lastConnectionTime",
                    name: this.$t("headers.last_connection")
                },
                {
                    code: "activatedDate",
                    name: "Activation Date"
                },
                {
                    code: "expiryDate",
                    name: this.$t("headers.expiry_date")
                }
            ],
            loading: false,
            trackersIsLoading: false,
            trackers: {
                edges: []
            },
            trackerUploadLogs: {
                edges: []
            },
            tableFilter: {
                status: null
            },
            openSelector: false,
            openTrackerChangeLocationDialog: false,
            selectedTrackersToMove: []
        };
    },
    apollo: {
        trackers: {
            query: gql`
                query trackers(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $serialNumber_Icontains: String
                    $brand_Icontains: String
                    $search: String
                    $orderBy: String
                    $isArchived: Boolean
                    $lastConnectionTime_Gte: DateTime
                    $lastConnectionTime_Lte: DateTime
                    $activatedDate: DateTime
                    $activatedDateGte: DateTime
                    $activatedDateLte: DateTime
                    $tenant: ID
                    $tenant_Isnull: Boolean
                    $expiryDateLte: DateTime
                    $expiryDateGte: DateTime
                    $packageNumberIcontains: String
                    $commentIcontains: String
                    $status: String
                    $isBeaconTracker: Boolean
                    $tripReference: String
                ) {
                    trackers(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        serialNumber_Icontains: $serialNumber_Icontains
                        brand_Icontains: $brand_Icontains
                        search: $search
                        orderBy: $orderBy
                        isArchived: $isArchived
                        lastConnectionTime_Gte: $lastConnectionTime_Gte
                        lastConnectionTime_Lte: $lastConnectionTime_Lte
                        activatedDate: $activatedDate
                        activatedDate_Gte: $activatedDateGte
                        activatedDate_Lte: $activatedDateLte
                        tenant: $tenant
                        tenant_Isnull: $tenant_Isnull
                        expiryDate_Lte: $expiryDateLte
                        expiryDate_Gte: $expiryDateGte
                        packageNumber_Icontains: $packageNumberIcontains
                        comment_Icontains: $commentIcontains
                        status: $status
                        isBeaconTracker: $isBeaconTracker
                        tripReference: $tripReference
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                createdAt
                                createdBy {
                                    id
                                    firstName
                                    lastName
                                    fullName
                                }
                                stockLocation {
                                    id
                                    name
                                }
                                serialNumber
                                mainTracker
                                brand
                                model
                                isBeacon
                                activatedDate
                                expiryDate
                                packageNumber
                                comment
                                isArchived
                                lastConnectionTime
                                lastBatteryDeviceTime
                                lastBatteryValue
                                lastTemperatureValue
                                lastTemperatureDeviceTime
                                lastExternalTemperatureValue
                                lastExternalTemperatureDeviceTime
                                lastProbeTemperatureValue
                                lastProbeTemperatureDeviceTime
                                lastHumidityDeviceTime
                                lastHumidityValue
                                lastLightDeviceTime
                                lastLightValue
                                lastShockDeviceTime
                                lastShockValue
                                lastLocationDeviceTime
                                tripReferenceNumber
                                tenant {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                this.trackerFilter.orderBy = this.sortBy ? (this.sortAsc ? "" : "-") + this.sortBy : null;
                let request = {
                    ...this.trackerFilter
                };
                return request;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackers,
            watchLoading(isLoading) {
                this.trackersIsLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            debounce: 800,
            skip() {
                return !this.tab === 0;
            }
            // pollInterval: 60000 // ms
        },
        trackerUploadLogs: {
            query: gql`
                query TrackerUploadLogs(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $search: String
                    $orderBy: String
                    $fileNameIcontains: String
                    $fileNameIexact: String
                    $brandIcontains: String
                    $brand: String
                    $createdAtGte: DateTime
                    $createdAtLte: DateTime
                    $createdByFirstNameIcontains: String
                    $createdByLastNameIcontains: String
                ) {
                    trackerUploadLogs(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        search: $search
                        orderBy: $orderBy
                        fileName_Icontains: $fileNameIcontains
                        fileName_Iexact: $fileNameIexact
                        brand_Icontains: $brandIcontains
                        brand: $brand
                        createdAt_Gte: $createdAtGte
                        createdAt_Lte: $createdAtLte
                        createdBy_FirstName_Icontains: $createdByFirstNameIcontains
                        createdBy_LastName_Icontains: $createdByLastNameIcontains
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                fileName
                                createdAt
                                brand
                                numberOfTrackers
                                trackerSerialNumbers
                                createdBy {
                                    id
                                    firstName
                                    lastName
                                    fullName
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.trackerLogFilter;
                // return {
                //     "first": null,
                //     "last": null,
                //     "before": null,
                //     "after": null,
                //     "search": null,
                //     "orderBy": null,
                //     "fileNameIcontains": null,
                //     "brandIcontains": null,
                //     "brand": null
                // }
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerUploadLogs,
            watchLoading(isLoading) {
                this.trackersIsLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            debounce: 800,
            skip() {
                return !this.tab === 1;
            }
        }
    },
    computed: {
        // Your computed properties go here
        primaryTextLighten() {
            let primary = this.$vuetify.theme.themes.light.primary;
            let convertHexToRgb = primary.replace("#", "").match(/.{1,2}/g);
            let opacity = 0.5;
            return `rgba(${convertHexToRgb}, ${opacity})`;
        },
        hasMoreData() {
            if (this.tab === 1) return this.trackerUploadLogs?.pageInfo?.hasNextPage;
            return this.trackers?.pageInfo?.hasNextPage;
        },
        layoutTransitionLgToSm() {
            return this.$vuetify.breakpoint.width < 1370;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 660;
        },
        hasTrackers() {
            let hasData = true;
            if (this.tab === 0) hasData = this.trackers.edges.length > 0;
            if (this.tab === 1) hasData = this.trackerUploadLogs.edges.length > 0;
            return hasData;
        },
        me() {
            return helper.me();
        },
        isStaff() {
            return this.me.isStaff || false;
        },
        canEdit() {
            if (this.me?.isSuperuser) {
                return true;
            }
            return this.hasPermission(TrackerPermissions.EDIT_TRACKER);
        },
        canCreate() {
            if (this.me?.isSuperuser) {
                return true;
            }
            return this.hasPermission(TrackerPermissions.CREATE_TRACKER);
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        stockMoveText() {
            if (this.openSelector) return this.$t("trackers.move");
            return `${this.$t("trackers.assign_to")} ${this.$t("trackers.stock_location")}`;
        },
        showAll: {
            get() {
                if (this.trackerFilter.status === null || this.trackerFilter.status === undefined) {
                    return 0;
                } else if (this.trackerFilter.status === "active") {
                    return 1;
                } else {
                    return 2;
                }
            },
            set(val) {
                if (val === 0) {
                    this.trackerFilter.status = null;
                } else if (val === 1) {
                    this.trackerFilter.status = "active";
                } else {
                    this.trackerFilter.status = "inactive";
                }
            }
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        // Code to run when the component is mounted
        /*  this.$nextTick(() => {
            //populate tracker filter
            let _filter = helper.getFilterValues("trackerTableFilters");
            if (_filter) {
                let code = this.$route.query.code || null;
                if (code) {
                    if (code === "AVAILABLE_TRACKERS") {
                        this.tableFilter.status = "inactive";
                    }
                    this.$router.replace({ query: {} });
                } else {
                    this.tableFilter.status = _filter?.status || null;
                }
            }
        });  */
    },
    watch: {
        tableFilter: {
            handler(newValue, oldValue) {
                let newValueString = JSON.parse(JSON.stringify(newValue));
                let oldValueString = JSON.parse(JSON.stringify(oldValue));
                if (newValueString !== oldValueString) {
                    this.trackersIsLoading = true;
                    this.trackers = { edges: [] };
                }
            },
            deep: true
        },
        trackerFilter: {
            handler(newValue, oldValue) {
                let newValueString = JSON.parse(JSON.stringify(newValue));
                let oldValueString = JSON.parse(JSON.stringify(oldValue));
                if (newValueString !== oldValueString) {
                    this.trackersIsLoading = true;
                    this.trackers = { edges: [] };
                }
            },
            deep: true
        },
        trackerLogFilter: {
            handler(newValue, oldValue) {
                let newValueString = JSON.parse(JSON.stringify(newValue));
                let oldValueString = JSON.parse(JSON.stringify(oldValue));
                if (newValueString !== oldValueString) {
                    this.trackersIsLoading = true;
                    this.trackerUploadLogs = { edges: [] };
                }
            },
            deep: true
        },
        tab(val) {
            if (val === 0) {
                this.$emit("update:isHistoryTab", false);
            } else {
                this.$emit("update:isHistoryTab", true);
            }
        }
    },
    methods: {
        updateTableFilter(event) {
            let _filter = { ...this.trackerFilter };
            helper.setFilterValues("trackerTableFilters", _filter);
        },
        onCloseSelector() {
            this.openSelector = false;
            this.selectedTrackersToMove = [];
        },
        onSelectTrackersToMove(trackers) {
            this.selectedTrackersToMove = trackers;
        },
        closeSelector(val) {
            this.openSelector = val;
            this.selectedTrackersToMove = [];
        },
        loadMore() {
            if (this.trackers?.pageInfo?.hasNextPage) {
                this.$apollo.queries.trackers.fetchMore({
                    variables: {
                        after: this.trackers.pageInfo.endCursor
                    }
                });
            }
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.trackers?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 180 + "px";
            });
        },
        assignToStockLocation(item) {
            this.selectedTrackersToMove = [item];
        },
        detailsPage(item, type = "tracker") {
            this.$emit("click:row", item, type);
        },
        getBatteryLevel(tracker) {
            if (tracker?.brand?.toLowerCase() === "sensitech") {
                return { level: "battery_unknown", color: "grey" };
            }

            let lastBatteryValue = tracker?.lastBatteryValue;

            if (tracker?.activatedDate !== null && tracker?.activatedDate !== undefined) {
                if (lastBatteryValue > 0 && lastBatteryValue < 20) {
                    return { level: "battery_2_bar", color: "red" };
                } else if (lastBatteryValue >= 20 && lastBatteryValue <= 60) {
                    return { level: "battery_4_bar", color: "orange" };
                } else if (lastBatteryValue > 60) {
                    return { level: "battery_6_bar", color: "green" };
                } else {
                    return { level: "battery_2_bar", color: "red" };
                }
            } else if (!lastBatteryValue) {
                return { level: "battery_unknown", color: "grey" };
            }
        },
        getTrackerValue(tracker) {
            return tracker?.brand?.toLowerCase() === "sensitech" ? "?" : tracker.lastBatteryValue || 0;
            // const checkSensitechValues = tracker => {
            //     let isSensitech = tracker?.brand?.toLowerCase() === "sensitech";
            //     let isDecimal = tracker?.lastBatteryValue % 1 !== 0;
            //     let isBtwZeroAndOne = tracker?.lastBatteryValue > 0 && tracker?.lastBatteryValue < 1;
            //     return isSensitech && (isDecimal && isBtwZeroAndOne);
            // };

            // let isInValid = checkSensitechValues(tracker);
            // if (tracker.activatedDate !== null && tracker.activatedDate !== undefined) {
            //     if (tracker.lastBatteryValue === null || tracker.lastBatteryValue === undefined || isInValid) {
            //         return "?";
            //     } else {
            //         return tracker.lastBatteryValue || 0;
            //     }
            // } else {
            //     return 0;
            // }
        },
        vTooltip(tracker) {
            const checkSensitechValues = tracker => {
                let isSensitech = tracker?.brand?.toLowerCase() === "sensitech";
                let isDecimal = tracker?.lastBatteryValue % 1 !== 0;
                let isBtwZeroAndOne = tracker?.lastBatteryValue > 0 && tracker?.lastBatteryValue < 1;
                return isSensitech && isDecimal && isBtwZeroAndOne;
            };

            let isInValid = checkSensitechValues(tracker);

            if (tracker?.activatedDate !== null && tracker?.activatedDate !== undefined) {
                if (tracker.lastBatteryValue === null || tracker.lastBatteryValue === undefined || isInValid) {
                    return this.$t("headers.unknown_baterry");
                } else {
                    return this.$t("headers.battery");
                }
            } else {
                return this.$t("headers.battery");
            }
        },
        trackerStatus(activatedDate) {
            let result = {
                text: this.$t("trackers.not_activated"),
                color1: "#E0E0E0",
                color2: "grey"
            };
            // activatedDate ? $t("trackers.activated") : $t("trackers.not_activated")
            if (activatedDate !== null) {
                result = {
                    text: this.$t("trackers.activated"),
                    color1: "#C8E6C9",
                    color2: "#4CAF50"
                };
            }
            return result;
        }
    }
};
</script>

<style scoped>
.primaryText {
    font-size: 12px !important;
    color: "#868599" !important;
    height: 23px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.secondaryText {
    font-size: 11px !important;
    color: "#0e1e46" !important;
    height: 21px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
    word-break: normal !important;
    line-height: inherit !important;
    text-align: center !important;
}
.textSubtitle {
    font-size: 13px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tabText1 {
    font-size: 14px !important;
    height: 17px !important;
    font-weight: 500;
}

.textLeft {
    text-align: left !important;
}

.tabItemCl {
    background-color: var(--v-background-base);
}
</style>
