<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs
            v-model="tab"
            color="primary"
            show-arrows
            ref="tabs"
            background-color="background"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
                class="text-capitalize"
                @click="cleanRoute"
            >
                {{ t.name }}
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <!-- TAB CONTENT -->
        <v-tabs-items
            v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto background"
        >
            <!-- PARTNER USERS TAB -->
            <v-tab-item value="tab-users" :transition="false"
                ><PartnerUsersTab
                    :partner="partner"
                    :isSelectorEnabled="selector"
                    @updateSelector="selector = $event"
                ></PartnerUsersTab
            ></v-tab-item>
            <!-- PARTNER LOCATIONS TAB -->
            <v-tab-item value="tab-locations" :transition="false">
                <PreviewPartnerLocationsTab
                    :partner="partner"
                ></PreviewPartnerLocationsTab>
            </v-tab-item>
            <!-- PARTNER PERMISSION TAB -->
            <v-tab-item value="tab-permissions" :transition="false">
                <PartnerPermissionsTab
                    :partner="partner"
                ></PartnerPermissionsTab>
            </v-tab-item>
            <!-- PARTNER TRIPS TAB -->
            <v-tab-item value="tab-trips" :transition="false">
                <PreviewPartnerTripsTab :partner="partner"></PreviewPartnerTripsTab>
            </v-tab-item>
            <!-- PARTNER MAP TAB -->
            <v-tab-item value="tab-map" :transition="false">
                SEP...
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import PartnerUsersTab from "@/components/partners/tabs/PreviewPartnerUsersTab.vue";
import PreviewPartnerLocationsTab from "@/components/partners/tabs/PreviewPartnerLocationsTab.vue";
import PartnerPermissionsTab from "@/components/partners/tabs/PreviewPartnerPermissionsTab.vue";
import PreviewPartnerTripsTab from "@/components/partners/tabs/PreviewPartnerTripsTab.vue";

export default {
    props: {
        partner: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        PartnerUsersTab,
        PreviewPartnerLocationsTab,
        PartnerPermissionsTab,
        PreviewPartnerTripsTab
    },
    data() {
        return {
            tab: null,
            testebton: false,
            mapDialog: false,
            selector: false
        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        },
          
    },
    mounted() {
        if(this.$route.query.tabDetail){
            this.tab = this.$route.query.tabDetail;
            //after populate the whole details clean the navigate query 
        }

    },
    methods: {
        cleanRoute() {
            if (this.$route.query.tabDetail) {
                let newQuery = { ...this.$route.query };
                delete newQuery.tabDetail; // Remove the 'tab' parameter

                // Navigate to the same route with the updated query object
                this.$router.replace({ query: newQuery });
            }
        }
    }
};
</script>
