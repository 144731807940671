<template>
    <v-container fluid>
        <v-row class="d-flex align-center justify-center px-10">
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <!-- FILTER -->
                <PreviewLocationsFilter
                    class="mx-3"
                    v-on:updateLocationTable="refreshTable"
                    v-on:deletedLocation="selectedLocation = []"
                    :selectedLocations="selectedLocation"
                    v-on:filter-changed="onFilterChanged"
                    @changePublicLocationType="publicLocationType = $event"
                    :tab="tab"
                    :searchInput.sync="searchInput"
                    :label="searchLabel"
                ></PreviewLocationsFilter>
                <v-btn
                    v-scroll="onScroll"
                    v-show="goTopShow"
                    fab
                    dark
                    fixed
                    bottom
                    right
                    small
                    style="z-index: 1"
                    color="primary"
                    @click="toTop"
                >
                    <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12" class="mt-2">
                <v-tabs-items v-model="tab" class="background">
                    <v-tab-item v-if="tab === 'myLocations'" value="myLocations" :transition="false">
                        <v-row align="stretch">
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <div class="modelTitle text-no-wrap">
                                    {{ $t("home.my_locations") }}
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-btn elevation="0" color="primary" @click="createLocation">
                                    {{ $t("partners.add_location1") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item
                        v-if="tab === 'partnerLocations'"
                        value="partnerLocations"
                        :transition="false"
                        class="pa-0 ma-0"
                    >
                        <v-row align="stretch" class="mb-2">
                            <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6">
                                <div class="modelTitle">
                                    {{ $t("headers.collaborator_locations") }}
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-btn elevation="0" color="primary" @click="createLocation">
                                    {{ $t("partners.add_location1") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item
                        v-if="tab === 'publicLocations'"
                        value="publicLocations"
                        :transition="false"
                        class="pa-0 ma-0"
                    >
                        <v-row align="stretch">
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <div class="modelTitle">
                                    {{ $t("partners.public_locations") }}
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-btn elevation="0" color="primary" @click="openAddLocation = true">
                                    {{ $t("partners.report_missing_location") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item v-else value="weather" :transition="false" class="pa-0 ma-0">
                        <v-row align="stretch">
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <div class="modelTitle">
                                    {{ $t("weather.weather_forecast") }}
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-btn elevation="0" color="primary" @click="openAddLocationDialog = true">
                                    {{ $t("partners.add_location") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <!-- TABLES -->
                <CustomTabs v-model="tab" :tabs="tabs" :draggable="false" v-on:onClick="clearRoute"></CustomTabs>
                <v-tabs-items v-model="tab" class="mt-3 background">
                    <v-tab-item
                        v-if="dinamicTab(tab).isLocation"
                        :value="dinamicTab(tab).tabValue"
                        :transition="false"
                        class="pa-0 ma-0"
                    >
                        <v-row dense>
                            <!-- <v-col cols="3" class="mt-2">
                                <sortBy v-model="orderBy" :sortByChoices="sortByChoices" class=""></sortBy>
                            </v-col> -->
                            <v-col
                                cols="12"
                                xs="12"
                                sm="6"
                                md="3"
                                lg="3"
                                xl="3"
                                class="mt-5"
                                v-if="tab === 'publicLocations'"
                            >
                                <v-select
                                    v-model="publicLocationType"
                                    :items="publicLocationsTypes"
                                    item-text="text"
                                    item-value="value"
                                    :label="$t('headers.location_type')"
                                    outlined
                                    hide-details
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <PreviewLocationsTable
                                    ref="locationtable"
                                    :locationsFilter.sync="locationsFilter"
                                    :search="searchInput"
                                    class="mx-0 mb-3"
                                    v-on:click:row="onLocationSelected"
                                    :tabFilter="tab"
                                    :orderBy="orderBy"
                                    :publicLocationType.sync="publicLocationType"
                                ></PreviewLocationsTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item v-else value="weather" :transition="false" class="pa-0 ma-0">
                        <v-row dense>
                            <!-- <v-col cols="3" class="mt-2">
                                <sortBy v-model="orderBy" :sortByChoices="sortByChoices" class=""></sortBy>
                            </v-col> -->
                            <v-col cols="3" class="mt-5 ml-2">
                                <v-switch
                                    inset
                                    v-model="warningCities"
                                    :label="$t('weather.filter_warning_cities')"
                                    class="text-no-wrap"
                                    color="primary"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12">
                                <PreviewWeatherTable
                                    ref="weathertable"
                                    class="mx-0 mb-3"
                                    v-on:click:row="onLocationSelected"
                                    :search="searchInput"
                                    :locationsFilter.sync="locationsFilter"
                                    :warningCities.sync="warningCities"
                                    :orderBy="orderBy"
                                    :tabFilter="tab"
                                ></PreviewWeatherTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>

        <!-- TUTORIAL -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>

        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="locationDetailDrawer"
            :width="locationDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <PreviewLocationDetail
                    v-if="selectedLocation"
                    :key="selectedLocation.id"
                    :location="selectedLocation"
                    :isFullScreen="isLocationDetailDrawerFullScreen"
                    v-on:changeFullScreen="onLocationDetailDrawerFullScreenChange"
                    v-on:close="locationDetailDrawer = false"
                    v-on:refreshDataTable="refreshTable"
                    v-on:onLocationDeleted="onLocationDeleted"
                    :title="tab"
                ></PreviewLocationDetail>
            </div>
        </v-navigation-drawer>
        <AddPublicLocation
            :openDialog="openAddLocation"
            @closeDialog="openAddLocation = false"
            :rounded="false"
        ></AddPublicLocation>
        <AddWeather
            v-if="openAddLocationDialog"
            ref="addWeatherDialog"
            :openAddLocationDialog="openAddLocationDialog"
            :locationsDisabledWeatherForecast="locationsFilter"
            :warningCities="warningCities"
            v-on:closeMap="openAddLocationDialog = false"
            v-on:refetchData="requestRefetch"
            :rounded="false"
        ></AddWeather>
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import PreviewLocationsFilter from "@/components/locations/PreviewLocationsFilter.vue";
import PreviewLocationsTable from "@/components/locations/PreviewLocationsTable.vue";
import PreviewLocationDetail from "@/components/locations/PreviewLocationDetail.vue";
import PreviewWeatherTable from "@/components/weather/PreviewWeatherTable.vue";
import AddPublicLocation from "@/components/locations/create/AddPublicLocation.vue";
import CustomTabs from "@/components/base/CustomTabs.vue";
import AddWeather from "@/components/weather/create/AddWeather.vue";
// import SortBy from "@/components/base/SortBy.vue";
import { eventBus } from "@/main.js";

export default {
    title: "Locations",
    components: {
        TutorialDialog,
        PreviewLocationsFilter,
        PreviewLocationsTable,
        PreviewLocationDetail,
        PreviewWeatherTable,
        CustomTabs,
        AddPublicLocation,
        AddWeather
        // SortBy,
    },

    apollo: {},
    data() {
        return {
            openAddLocationDialog: false,
            openAddLocation: false,
            locationDetailDrawer: false,
            locationsFilter: {
                first: 15,
                search: null
            },
            searchInput: "",
            goTopShow: false,
            // isLoading: false,
            menu: false,
            selectedLocation: null,
            tutorialDialog: false,
            locationDetailFullScreen: false,
            warningCities: false,
            tab: null,
            tabs: [
                {
                    id: 0,
                    text: this.$t("home.my_locations"),
                    value: "myLocations"
                },
                {
                    id: 1,
                    text: this.$t("headers.collaborator_locations"),
                    value: "partnerLocations"
                },
                {
                    id: 2,
                    text: this.$t("partners.public_locations"),
                    value: "publicLocations"
                },
                {
                    id: 3,
                    text: this.$t("weather.weather_forecast"),
                    value: "weather"
                }
            ],
            orderBy: null,
            sortByChoices: [
                {
                    code: "name",
                    name: this.$t("headers.name")
                },
                {
                    code: "city",
                    name: this.$t("partners.city")
                },
                {
                    code: "country",
                    name: this.$t("partners.country")
                }
            ],
            publicLocationType: "air_port",
            publicLocationsTypes: [
                {
                    text: this.$t("headers.airport"),
                    value: "air_port"
                },
                {
                    text: this.$t("headers.sea_port"),
                    value: "sea_port"
                }
            ]
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        locationDetailDrawerWidth() {
            if (this.locationDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "90%";
                case "sm":
                    return "80%";
                case "md":
                    return "70%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isLocationDetailDrawerFullScreen() {
            return this.locationDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        routeName() {
            return this.$route.name;
        },
        searchLabel() {
            let _label = "";
            if (this.tab === "myLocations") {
                _label = this.$t("general.search_my_locations");
            } else if (this.tab === "partnerLocations") {
                _label = this.$t("general.search_partner_locations");
            } else if (this.tab === "publicLocations") {
                _label = this.$t("general.search_public_locations");
            } else if (this.tab === "weather") {
                _label = this.$t("general.search_my_locations");
            }

            return _label;
        }
    },
    watch: {
        orderBy(val) {
            if (val) {
                let _filter = helper.getFilterValues(`${this.tab}TableFilters`);
                if (_filter) {
                    _filter.orderBy = val;
                    helper.setFilterValues(`${this.tab}TableFilters`, _filter);
                }
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.tab) {
                this.tab = this?.$route?.query?.tab;
            } else {
                this.tab = "myLocations";
            }

            /* orderby */
            let _filter = helper.getFilterValues(`${this.tab}TableFilters`);
            if (_filter) {
                if (_filter.orderBy?.includes("-")) {
                    this.orderBy = _filter.orderBy.replace("-", "");
                } else {
                    this.orderBy = _filter.orderBy;
                }
            }
        });
    },
    methods: {
        onFilterChanged(filter) {
            this.locationsFilter = filter;
        },
        refreshTable() {
            this.$refs.locationtable.$apollo.queries.locations.refetch();
        },
        onScroll(e) {
            // scroll to top of the page
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        createLocation() {
            if (this.tab === "myLocations") {
                this.$router.push({
                    path: "/createlocation",
                    query: {
                        type: "createLocation"
                    }
                });
            } else if (this.tab === "partnerLocations") {
                this.$router.push({
                    path: "/createlocation",
                    query: {
                        type: "createPartnerLocation"
                    }
                });
            }
        },
        onLocationDetailDrawerFullScreenChange(val) {
            this.locationDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onLocationSelected(location) {
            this.selectedLocation = location.node;
            this.locationDetailDrawer = true;
        },
        onLocationDeleted() {
            this.selectedLocation = null;
            this.locationDetailDrawer = false;
        },
        dinamicTab(tab) {
            let _tab = null;
            switch (tab) {
                case "myLocations":
                    _tab = {
                        tabValue: "myLocations",
                        isLocation: true
                    };
                    break;
                case "partnerLocations":
                    _tab = {
                        tabValue: "partnerLocations",
                        isLocation: true
                    };
                    break;
                case "publicLocations":
                    _tab = {
                        tabValue: "publicLocations",
                        isLocation: true
                    };
                    break;
                case "weather":
                    _tab = {
                        tabValue: "weather",
                        isLocation: false
                    };
                    break;
                default:
                    _tab = {
                        tabValue: "myLocations",
                        isLocation: true
                    };
            }
            return _tab;
        },
        requestRefetch(callback) {
            eventBus.$emit("refetchData", () => {
                callback();
            });
        },
        clearRoute() {
            if (this.$route.query.tab) {
                let newQuery = { ...this.$route.query };
                delete newQuery.tab; // Remove the 'tab' parameter

                // Navigate to the same route with the updated query object
                this.$router.replace({ query: newQuery });
            }
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.textHeader {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    width: 291px;
    height: 58px;
    top: 141px;
    left: 376px;
}
.modelTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;
    color: #000000;
}
</style>
