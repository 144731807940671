<template>
    <v-dialog
        max-width="1200"
        max-height="800"
        v-model="openTrackerUploadHistoryDialog"
        persistent
        scrollable
    >
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-2">history</v-icon>
                <span>{{ $t("general.upload_history") }}</span>
                <v-spacer></v-spacer>
                <v-btn
                    class="grey lighten-4"
                    text
                    rounded
                    @click="closeDialog"
                    >{{ $t("general.close") }}</v-btn
                >
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="px-6 pt-6">
                <v-row>
                    <v-col cols="12" md="4" lg="4" xl="4">
                        <!--Upload At Filter-->
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="dateRangeText"
                                    :label="$t('general.filter_upload')"
                                    readonly
                                    clearable
                                    outlined
                                    hide-details
                                    dense
                                    @click:clear="onClearDates()"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dateRange"
                                :first-day-of-week="1"
                                range
                                no-title
                                scrollable
                                color="primary"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    rounded
                                    color="primary"
                                    @click="cancelDatePicker"
                                >
                                    {{ $t("general.cancel") }}
                                </v-btn>
                                <v-btn
                                    outlined
                                    rounded
                                    color="primary"
                                    :disabled="dateRange.length < 1"
                                    @click="saveDatePicker"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                        <LoadingBar
                            v-if="$apollo.queries.trackerUploadLogs.loading"
                        ></LoadingBar>
                        <v-card
                            v-else
                            v-for="edge in trackerUploadLogs.edges"
                            :key="edge.node.id"
                            class="mb-5 mt-3"
                            elevation="4"
                            :color="
                                selectedLog == edge.node.id
                                    ? 'primary lighten-4'
                                    : 'white'
                            "
                            @click="selectedLog = edge.node.id"
                        >
                            <v-row dense class="pt-5 ml-3">
                                <v-icon color="primary">settings_remote</v-icon>
                                <span class="ml-2"
                                    ><b>{{
                                        edge.node.fileName
                                            ? edge.node.fileName
                                            : $t("general.manually_created")
                                    }}</b></span
                                >
                            </v-row>
                            <div class="px-5 py-5">
                                <v-row dense
                                    ><span
                                        >{{ $t("general.uploaded_at") }}:
                                        <b>{{
                                            formatDateTime(edge.node.createdAt)
                                        }}</b></span
                                    ></v-row
                                >
                                <v-row dense
                                    ><span
                                        >{{ $t("general.uploaded_by") }}:
                                        <b>{{
                                            edge.node.createdBy
                                                ? edge.node.createdBy.fullName
                                                    ? edge.node.createdBy
                                                          .fullName
                                                    : edge.node.createdBy.email
                                                : ""
                                        }}</b></span
                                    ></v-row
                                >
                                <v-row dense
                                    ><span
                                        >{{ $t("home.trackers") }}:<b>
                                            {{ edge.node.numberOfTrackers }}</b
                                        ></span
                                    ></v-row
                                >
                                <v-row dense
                                    ><span
                                        >{{ $t("headers.brand") }}:
                                        <b>{{ edge.node.brand }}</b></span
                                    ></v-row
                                >
                            </div>
                        </v-card>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="12" md="8" lg="8" xl="8">
                        <v-card
                            ><v-data-table
                                :headers="defaultHeaderChoices"
                                :items="trackersForUploadLogs.edges"
                                item-key="node.id"
                                loading-text="Loading..."
                                :loading="
                                    $apollo.queries.trackersForUploadLogs
                                        .loading
                                "
                                :mobile-breakpoint="0"
                                loader-height="2"
                                :no-data-text="$t('loading.no_data')"
                                fixed-header
                                class="row-pointer "
                            >
                                <!-- no data availabel -->
                                <template slot="no-data">
                                    <div>No data</div>
                                </template>

                                <!--Serial Number-->
                                <template v-slot:item.serialNumber="{ item }">
                                    <span class="text-uppercase">{{
                                        item.node.serialNumber
                                    }}</span>
                                </template>

                                <!-- Model -->
                                <template v-slot:item.model="{ item }">
                                    <span>{{
                                        item.node.model ? item.node.model : ""
                                    }}</span>
                                </template>

                                <!-- Last Connection Time -->
                                <template
                                    v-slot:item.lastConnectionTime="{ item }"
                                >
                                    <span>{{
                                        formatDateTime(
                                            item.node.lastConnectionTime
                                        )
                                    }}</span>
                                </template>

                                <!-- Expiration Date -->
                                <template v-slot:item.expiryDate="{ item }">
                                    <span>{{
                                        formatDateTime(item.node.expiryDate)
                                    }}</span>
                                </template>

                                <!-- Comment -->
                                <template v-slot:item.comment="{ item }">
                                    <span>{{
                                        item.node.comment
                                            ? item.node.comment
                                            : ""
                                    }}</span>
                                </template>

                                <!-- Tenant -->
                                <template
                                    v-if="me.isStaff"
                                    v-slot:item.tenant.name="{ item }"
                                >
                                    <span>{{
                                        item.node.tenant.name
                                            ? item.node.tenant.name
                                            : ""
                                    }}</span>
                                </template>
                            </v-data-table></v-card
                        >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import LoadingBar from "@/components/base/LoadingBar.vue";
import moment from "moment";

export default {
    props: {
        openTrackerUploadHistoryDialog: {
            type: Boolean
        }
    },
    components: {
        LoadingBar
    },
    data() {
        return {
            defaultHeaderChoices: [
                {
                    text: "Serial Number",
                    align: "left",
                    value: "serialNumber",
                    sortable: false
                },
                {
                    text: "Model",
                    align: "left",
                    value: "model",
                    sortable: false
                },
                {
                    text: "Last Connection Time",
                    align: "left",
                    value: "lastConnectionTime",
                    sortable: false
                },
                {
                    text: "Expiration Date",
                    align: "left",
                    value: "expiryDate",
                    sortable: false
                },
                {
                    text: "Comment",
                    align: "left",
                    value: "comment",
                    sortable: false
                }
            ],
            trackerUploadLogs: { edges: [] },
            trackersForUploadLogs: { edges: [] },
            selectedLog: null,
            menu: false,
            dateRange: [],
            savedDateRange: []
        };
    },
    beforeCreate() {},
    computed: {
        isStaff() {
            return this.me?.isStaff || false;
        },
        me() {
            return helper.me();
        },
        dateRangeText() {
            let dates = this.dateRange.map(d => {
                return this.formatDate(d);
            });
            if (dates.length == 0 || dates[0] == null) return "";
            return dates.join(" to ");
        }
    },
    apollo: {
        trackersForUploadLogs: {
            query: gql`
                query TrackersForUploadLogs($trackerUploadLog: ID) {
                    trackersForUploadLogs(trackerUploadLog: $trackerUploadLog) {
                        edges {
                            node {
                                id
                                serialNumber
                                brand
                                model
                                expiryDate
                                packageNumber
                                comment
                                lastConnectionTime
                                tenant {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    trackerUploadLog: this.selectedLog
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackersForUploadLogs,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.selectedLog;
            }
            // pollInterval: 60000 // ms
        },
        trackerUploadLogs: {
            query: gql`
                query TrackerUploadLogs(
                    $orderBy: String
                    $createdAt_Gte: DateTime
                    $createdAt_Lte: DateTime
                ) {
                    trackerUploadLogs(
                        orderBy: $orderBy
                        createdAt_Gte: $createdAt_Gte
                        createdAt_Lte: $createdAt_Lte
                    ) {
                        edges {
                            node {
                                brand
                                fileName
                                id
                                numberOfTrackers
                                createdAt
                                createdBy {
                                    id
                                    fullName
                                    email
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "-createdAt",
                    createdAt_Gte: this.savedDateRange[0]
                        ? moment(this.savedDateRange[0]).format()
                        : null,
                    createdAt_Lte: this.savedDateRange[1]
                        ? moment(this.savedDateRange[1])
                              .endOf("day")
                              .format()
                        : null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerUploadLogs,
            result({ loading, data }) {
                if (!loading) {
                    this.selectedLog =
                        data?.trackerUploadLogs?.edges[0]?.node?.id;
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.openTrackerUploadHistoryDialog;
            }
            // pollInterval: 60000 // ms
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
        if (this.isStaff) {
            this.defaultHeaderChoices.push({
                // for filter menu
                code: "node.tenant.name",
                name: "Tenant",
                enabled: true,
                // for table header
                text: "Tenant",
                align: "center",
                value: "node.tenant.name",
                sortable: false
            });
        }
    },
    watch: {
        selectedLog(val) {
            if (!val) this.trackersForUploadLogs = { edges: [] };
        },
        dateRange(val) {
            if (val.length === 2) {
                if (this.dateRange[0] > this.dateRange[1]) {
                    this.dateRange = [this.dateRange[1], this.dateRange[0]];
                }
            }
        },
        savedDateRange(val) {
            if (val.length === 1) {
                this.dateRange = [val[0], val[0]];
                this.savedDateRange = [val[0], val[0]];
            }
        }
    },
    methods: {
        closeDialog() {
            this.$emit("close");
        },
        showMessage(payload) {
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        cancelDatePicker() {
            this.menu = false;
            this.dateRange = this.savedDateRange;
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        saveDatePicker() {
            this.menu = false;
            this.savedDateRange = this.dateRange;
        },
        onClearDates() {
            this.dateRange = [];
            this.savedDateRange = [];
        }
    }
};
</script>
