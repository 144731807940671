<template>
    <main>
        <v-card
            class="py-3 px-3 mx-2 my-2"
            elevation="5"
            outlined
            v-resize="onResize"
        >
            <v-card-text class="pb-3 pr-3">
                <!-- TABLE -->
                <v-data-table
                    :headers="headerChoices"
                    :items="tableItems"
                    item-key="node.id"
                    :loading-text="$t('loading.loading1')"
                    :loading="tableLoading"
                    hide-default-footer
                    disable-pagination
                    disable-sort
                    loader-height="2"
                    :mobile-breakpoint="
                        screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                    "
                    :no-data-text="$t('loading.no_data')"
                    fixed-header
                    :height="tableHeight"
                    @click:row="item => this.$emit('click:row', item)"
                    class="row-pointer"
                >
                    <!-- LOCATION TYPE -->
                    <template v-slot:item.node.locationType="{ item }">
                        <span
                            v-if="item.node.locationType === 'TENANT_LOCATION'"
                        >
                            {{ $t("alert_rules.public") }}
                        </span>
                        <span
                            color="grey lighten-2"
                            v-else-if="
                                item.node.locationType === 'PRIVATE_LOCATION'
                            "
                        >
                            {{ $t("alert_rules.nonpublic") }}</span
                        >
                        <span
                            v-else-if="
                                item.node.locationType.trim() === 'AIR_PORT'
                            "
                            >{{ $t("headers.airport") }}</span
                        >
                        <span
                            v-else-if="
                                item.node.locationType.trim() === 'SEA_PORT'
                            "
                            >{{ $t("headers.sea_port") }}</span
                        >
                    </template>
                    <!-- PARTNER TYPE -->
                    <template
                        v-slot:item.node.tenantPartner.partnerType="{ item }"
                    >
                        <div v-if="item.node.tenantPartner">
                            <span v-if="item.node.tenantPartner.partnerType === 'consignor'">{{ $t("headers.consignor") }}</span>
                            <span v-else-if="item.node.tenantPartner.partnerType === 'consignee'">{{ $t("headers.consignee") }}</span>
                            <span v-else-if="item.node.tenantPartner.partnerType === 'carrier'">{{ $t("headers.carrier") }}</span>
                            <span v-else-if="item.node.tenantPartner.partnerType === 'forwarder'">{{ $t("headers.forwarder") }}</span>
                            <span v-else-if="item.node.tenantPartner.partnerType === 'other'">{{ $t("home.other") }}</span>
                        </div>
                    </template>

                    <!-- TAGS -->
                    <template v-slot:item.node.tags="{ item }">
                        <div>
                            <v-chip
                                v-for="tag in getTags(item.node.tags)"
                                :key="tag"
                                color="primary"
                                small
                                class="mr-1 my-1"
                                >{{ tag }}</v-chip
                            >
                        </div>
                    </template>

                    <template
                        v-slot:item.node.isTrackerStockLocation="{ item }"
                    >
                        <v-icon
                            v-if="item.node.isTrackerStockLocation === true"
                            color="success"
                            >check_circle</v-icon
                        >
                        <v-icon color="grey lighten-2" v-else
                            >check_circle</v-icon
                        >
                    </template>

                    <!-- infinit loading trigger -->
                    <template slot="body.append">
                        <tr>
                            <td
                                :colspan="headerChoices.length"
                                class="text-center"
                            >
                                <v-btn
                                    v-if="hasMoreData"
                                    v-intersect="onLoadMoreTriggerIntersect"
                                    :disabled="!hasMoreData"
                                    :loading="tableLoading"
                                    plain
                                    class="mb-5"
                                    @click="loadMore"
                                >
                                    {{ $t("loading.load_more") }}
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
            <!-- LOADING TRIGGER -->
            <!-- <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.locations.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    Load more
                </v-btn>
            </v-card-actions> -->
        </v-card>
    </main>
</template>
<script>
import gql from "graphql-tag";
export default {
    name: "PartnerUsersTab",
    components: {},
    props: {
        locationsFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        title: {
            type: String,
            default: "locations"
        },
        publicLocationType: {
            type: String,
            default: "airport"
        }
    },
    apollo: {
        locations: {
            query: gql`
                query tenantLocations(
                    $search: String
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $tenantPartnerNameIcontains: String
                    $tenantPartnerPartnerTypeIexact: String
                    $nameIcontains: String
                    $cityIcontains: String
                    $countryIcontains: String
                    $locationType_In: [String]
                    $tagsIcontains: String
                ) {
                    tenantLocations(
                        search: $search
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        tenantPartner_Name_Icontains: $tenantPartnerNameIcontains
                        name_Icontains: $nameIcontains
                        city_Icontains: $cityIcontains
                        country_Icontains: $countryIcontains
                        locationType_In: $locationType_In
                        tags_Icontains: $tagsIcontains
                        tenantPartner_PartnerType_Iexact: $tenantPartnerPartnerTypeIexact
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                locationType
                                isTrackerStockLocation
                                fullAddress
                                city
                                state
                                country
                                latitude
                                longitude
                                radius
                                tags
                                weatherForecastSubscriptionSet {
                                    edges {
                                        node {
                                            minTemperature
                                            maxTemperature
                                            enabled
                                        }
                                    }
                                }
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.locationsFilter;
            },
            skip() {
                return this.title === this.$t("partners.public_locations");
            },
            fetchPolicy: "network-only",
            debounce: 800,
            update: response => {
                return response.tenantLocations;
            }
        },
        airports: {
            query: gql`
                query Airports(
                    $search: String
                    $first: Int
                    $last: Int
                    $after: String
                    $before: String
                    $orderBy: String
                    $cityIcontains: String
                    $countryIcontains: String
                    $nameIcontains: String
                ) {
                    airports(
                        search: $search
                        first: $first
                        last: $last
                        after: $after
                        before: $before
                        orderBy: $orderBy
                        city_Icontains: $cityIcontains
                        country_Icontains: $countryIcontains
                        name_Icontains: $nameIcontains
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                code
                                city
                                country
                                longitude
                                latitude
                                name
                                radius
                                locationType
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.locationsFilter;
            },
            skip() {
                // return this.title !=== this.$t("partners.public_locations") && this.publicLocationType === "seaport";
                return !this.isAirportLocations;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: 'cache-first',
            debounce: 800,
            update: response => {
                return response.airports;
            }
        },
        seaports: {
            query: gql`
                query SeaPorts(
                    $search: String
                    $first: Int
                    $last: Int
                    $after: String
                    $before: String
                    $orderBy: String
                    $countryIcontains: String
                    $nameIcontains: String
                ) {
                    seaPorts(
                        search: $search
                        first: $first
                        last: $last
                        after: $after
                        before: $before
                        orderBy: $orderBy
                        country_Icontains: $countryIcontains
                        name_Icontains: $nameIcontains
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                code
                                city
                                country
                                latitude
                                longitude
                                name
                                radius
                                locationType
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.locationsFilter;
            },
            skip() {
                // return this.title !=== this.$t("partners.public_locations") && this.publicLocationType === "airport";
                return !this.isSeaportLocations;
            },
            fetchPolicy: "network-only",
            debounce: 800,
            update: response => {
                return response.seaPorts;
            }
        }
    },
    data() {
        return {
            locations: { edges: [] },
            airports: { edges: [] },
            seaports: { edges: [] },
            tableHeight: 800,
            searchInput: "",
        };
    },
    watch:{},
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        isAirportLocations(){
            return this.title === this.$t("partners.public_locations") && this.publicLocationType === "airport";
        },
        isSeaportLocations(){
            return this.title === this.$t("partners.public_locations") && this.publicLocationType === "seaport";
        },
        tableLoading() {
            if (this.title === this.$t("partners.public_locations")) {
                if (this.publicLocationType === "sea_port") {
                    return this.$apollo.queries.seaports.loading;
                } else {
                    return this.$apollo.queries.airports.loading;
                }
            } else return this.$apollo.queries.locations.loading;
        },
        tableItems() {
            if (this.title === this.$t("partners.public_locations")) {
                if (this.publicLocationType === "sea_port") {
                    return this.seaports?.edges;
                } else {
                    return this.airports?.edges;
                }
            } else {
                return this.locations?.edges;
            }
   
        },
        hasMoreData() {
            if (this.title === this.$t("partners.public_locations")) {
                if (this.publicLocationType === "sea_port") {
                    return this.seaports?.pageInfo?.hasNextPage;
                } else {
                    return this.airports?.pageInfo?.hasNextPage;
                }
            } else return this.locations?.pageInfo?.hasNextPage;
        },
        tagsChoices: function() {
            let _tagsChoices = [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ];
            return _tagsChoices;
        },
        headerChoices: function() {
            let _headerChoices = [];

            if (this.title === this.$t("home.my_locations")) {
                _headerChoices = [
                    {
                        text: "",
                        align: "left",
                        value: "action",
                        width: "5px"
                    },
                    {
                        text: this.$t("headers.location_name"),
                        align: "left",
                        value: "node.name"
                    },
                    {
                        text: this.$t("partners.city"),
                        align: "left",
                        value: "node.city"
                    },
                    {
                        text: this.$t("partners.country"),
                        align: "left",
                        value: "node.country"
                    },
                    {
                        text: this.$t("headers.address"),
                        align: "left",
                        value: "node.fullAddress"
                    },
                    {
                        text: this.$t("headers.tags"),
                        align: "left",
                        value: "node.tags"
                    },
                    {
                        text: this.$t("headers.scope"),
                        align: "center",
                        value: "node.locationType"
                    },
                    {
                        text: "",
                        align: "left",
                        value: "edit",
                        width: "5px"
                    }
                ];
            } else if (this.title === this.$t("home.partners_locations")) {
                _headerChoices = [
                    {
                        text: "",
                        align: "left",
                        value: "action",
                        width: "5px"
                    },
                    {
                        text: this.$t("headers.location_name"),
                        align: "left",
                        value: "node.name"
                    },
                    {
                        text: this.$t("partners.city"),
                        align: "left",
                        value: "node.city"
                    },
                    {
                        text: this.$t("partners.country"),
                        align: "left",
                        value: "node.country"
                    },
                    {
                        text: this.$t("headers.address"),
                        align: "left",
                        value: "node.fullAddress"
                    },
                    {
                        text: this.$t("headers.partner_name"),
                        align: "left",
                        value: "node.tenantPartner.name"
                    },
                    {
                        text: this.$t("headers.partner_type"),
                        align: "left",
                        value: "node.tenantPartner.partnerType"
                    },
                    {
                        text: this.$t("trackers.stock_location"),
                        align: "center",
                        value: "node.isTrackerStockLocation"
                    },
                    {
                        text: "",
                        align: "left",
                        value: "edit",
                        width: "5px"
                    }
                ];
            } else if (this.title === this.$t("partners.public_locations")) {
                _headerChoices = [
                    {
                        text: "",
                        align: "left",
                        value: "action",
                        width: "5px"
                    },
                    {
                        text: this.$t("headers.location_name"),
                        align: "left",
                        value: "node.name"
                    },
                    {
                        text: this.$t("partners.city"),
                        align: "left",
                        value: "node.city"
                    },
                    {
                        text: this.$t("partners.country"),
                        align: "left",
                        value: "node.country"
                    },
                    {
                        text: this.$t("headers.code"),
                        align: "left",
                        value: "node.code"
                    },
                    {
                        text: this.$t("headers.location_type"),
                        align: "left",
                        value: "node.locationType"
                    },
                    {
                        text: "",
                        align: "left",
                        value: "edit",
                        width: "5px"
                    }
                ];
            }

            return _headerChoices;
        }
    },
    methods: {
        refreshLocations() {
            this.locations = { edges: [] };
            this.$apollo.queries.locations.refetch();
        },
        refreshPorts() {
            this.airports = { edges: [] };
            this.seaports = { edges: [] };
            this.$apollo.queries.airports.refetch();
            this.$apollo.queries.seaports.refetch();
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 170 + "px";
            });
        },
        loadMore() {
            if (this.title === this.$t("partners.public_locations")) {
                if (this.publicLocationType === "sea_port" && this.seaports?.pageInfo?.hasNextPage) {
                    this.$apollo.queries.seaports.fetchMore({
                        variables: {
                            after: this.seaports.pageInfo.endCursor
                        }
                    });
                } else if(this.publicLocationType === "airport" && this.airports?.pageInfo?.hasNextPage) {
                    this.$apollo.queries.airports.fetchMore({
                        variables: {
                            after: this.airports.pageInfo.endCursor
                        }
                    });
                }
            } else if (this.locations?.pageInfo?.hasNextPage) {
                this.$apollo.queries.locations.fetchMore({
                    variables: {
                        after: this.locations.pageInfo.endCursor
                    }
                });
            }
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;
            if (
                this.shouldLoadMore &&
                (this.locations?.pageInfo?.hasNextPage ||
                    (this.publicLocationType === "airport" &&
                        this.airports?.pageInfo?.hasNextPage) ||
                    (this.publicLocationType === "seaport" &&
                        this.seaports?.pageInfo?.hasNextPage))
            ) {
                this.loadMore();
            }
        },
        getTags(tags) {
            if (tags) {
                try {
                    let tagsArray = JSON.parse(tags.replace(/'/g, '"'));
                    return tagsArray.map(tag => {
                        return this.$t(`headers.${tag}`);
                    });
                } catch (error) {
                    console.log(error);
                    return null;
                }
            }
            return null;
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
