<template>
    <v-container fluid class="pa-0">
        <v-row dense>
            <v-col cols="12" lg="12" md="12" sm="12">
                <v-card :loading="loading">
                    <v-card-title class="subtitle-2 grey--text">
                        {{ $t("headers.trips") }}
                    </v-card-title>
                    <v-card-text>
                        <apexcharts
                            type="bar"
                            height="350"
                            :options="chartOptions"
                            :series="series"
                        ></apexcharts>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import helper from "@/utils/helper.js";
import gql from "graphql-tag";

export default {
    title: "Home",
    components: {
        apexcharts: VueApexCharts
    },
    props: {
        tenantId: {
            type: String,
            default: null
        }
    },
    apollo: {
        tripSumMonthlys: {
            query: gql`
                query tripSumMonthlys($tenant: ID) {
                    tripSumMonthlys(tenant: $tenant) {
                        edges {
                            node {
                                id
                                firstDateOfMonth
                                numOfTrips
                                lastRefreshedAt
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenant: this.tenantId
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripSumMonthlys,
            skip() {
                return false;
            }
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        loading() {
            return this.$apollo.queries.tripSumMonthlys.loading;
        },
        CurrentYearTripSeries() {
            let currentYear = new Date().getFullYear();
            let currentYearOverview = [];
            let tripSeries = [];

            this.tripSumMonthlys?.edges?.forEach(trip => {
                let tripYear = new Date(
                    trip.node.firstDateOfMonth
                ).getFullYear();
                if (tripYear === currentYear) {
                    currentYearOverview.push(trip.node);
                }
            });

            for (let i = 0; i < 12; i++) {
                let _month = helper.months(i);
                let _total = 0;
                currentYearOverview.forEach(trip => {
                    let _tripMonth = new Date(trip.firstDateOfMonth).getMonth();
                    if (_tripMonth === i) {
                        _total += trip.numOfTrips;
                    }
                });

                tripSeries.push({
                    month: _month,
                    total: _total
                });
            }

            return tripSeries || [];
        },
        lastYearTripSeries() {
            let tripSeries = [];
            let lastYear = new Date().getFullYear() - 1;
            let lastYearTrips = [];

            this.tripSumMonthlys?.edges?.forEach(trip => {
                let tripYear = new Date(
                    trip.node.firstDateOfMonth
                ).getFullYear();

                if (tripYear === lastYear) {
                    lastYearTrips.push(trip.node);
                }
            });

            for (let i = 0; i < 12; i++) {
                let _month = helper.months(i);
                let _total = 0;
                lastYearTrips.forEach(trip => {
                    let _tripMonth = new Date(trip.firstDateOfMonth).getMonth();
                    if (_tripMonth === i) {
                        _total += trip.numOfTrips;
                    }
                });

                tripSeries.push({
                    month: _month,
                    total: _total
                });
            }

            return tripSeries || [];
        },
        chartOptions() {
            let _options = {
                chart: {
                    type: "bar",
                    height: "100%",
                    width: "100%",
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    position: "top"
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "70%",
                        endingShape: "rounded"
                    }
                },
                dataLabels: {
                    formatter: function(val) {
                        return val;
                    }
                    /* style: {
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        colors:  ['#E0E0E0']
                    }, */
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"]
                },
                xaxis: {
                    categories: [
                        this.$t("months.jan"),
                        this.$t("months.feb"),
                        this.$t("months.mar"),
                        this.$t("months.apr"),
                        this.$t("months.may"),
                        this.$t("months.jun"),
                        this.$t("months.jul"),
                        this.$t("months.aug"),
                        this.$t("months.sep"),
                        this.$t("months.oct"),
                        this.$t("months.nov"),
                        this.$t("months.dec")
                    ]
                },
                yaxis: {
                    title: {
                        text: ""
                    }
                },
                fill: {
                    opacity: 1
                },
                colors: ["#3D9BC8", "#154D79", "#1B6A99"],
                tooltip: {
                    y: {
                        formatter: function(val) {
                            // return this.$t("headers.trips") + ": " + val;
                            return `Trips: ${val}`;
                        }
                    }
                }
            };
            return _options || {};
        },
        series() {
            let lastYear = new Date().getFullYear() - 1;
            let currentYear = new Date().getFullYear();
            let _series = [
                {
                    name: lastYear,
                    data: this.lastYearTripSeries.map(trip => trip.total)
                },
                {
                    name: currentYear,
                    data: this.CurrentYearTripSeries.map(trip => trip.total)
                }
            ];
            return _series || [];
        }
    },

    watch: {},
    created() {
        this.months = helper.months;
    },
    mounted() {},
    methods: {}
};
</script>
