<template>
    <v-card elevation="0" color="transparent" class="pa-0">
        <v-card-text class="px-0 py-0">
            <v-row no-gutters>
                <v-col v-for="(edge, index) in locationsShowed.edges" :key="index" cols="12">
                    <v-card outlined class="rounded-lg">
                        <v-card-title>
                            <v-row>
                                <v-col cols="auto">
                                    <v-icon>
                                        update
                                    </v-icon>
                                </v-col>
                                <v-col cols="auto">
                                    <div class="text-no-wrap">
                                        {{ edge.node.city }} -
                                        {{ edge.node.country }}
                                    </div>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                    <v-menu offset-y transition="slide-y-transition" width="250px">
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-for="(item, i) in actionChoices"
                                                :key="i"
                                                @click="handleClick(item, edge)"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon>{{ item.icon }}</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <WeatherChart :edge="edge"></WeatherChart>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <!-- Edit Location -->
        <v-dialog v-model="openEditDialog" persistent max-width="300">
            <v-card>
                <v-card-title class="primary white--text">
                    {{ locationtoEdit.city }} -
                    {{ locationtoEdit.country }}
                </v-card-title>
                <v-card-text>
                    <div class="mt-10">
                        <MinMaxTemperature v-model="locationtoEdit"></MinMaxTemperature>
                    </div>
                </v-card-text>
                <v-card-actions class=" justify-end">
                    <v-btn text @click="resetLocation" rounded>{{ $t("general.cancel") }}</v-btn>
                    <v-btn
                        color="primary"
                        @click="editLocation(locationtoEdit)"
                        rounded
                        :loading="isLoadingEdit"
                        :disabled="!formValid"
                        >{{ $t("general.save") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import MinMaxTemperature from "@/components/weather/forms/MinMaxTemperature.vue";
import { eventBus } from "@/main.js";
import WeatherChart from "@/components/weather/chart/WeatherChart.vue";

export default {
    name: "WeatherTable",
    components: {
        MinMaxTemperature,
        WeatherChart
    },
    props: {
        location: {
            type: Object,
            required: false,
            default: () => {}
        },
        displayedEdgesIds: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            isLoadingTable: false,
            isLoadingEdit: false,
            openEditDialog: false,
            locationtoEdit: {},
            selectAction: null,
            isChartRendered: false,
            last_sync_time: null,
            actionChoices: [
                {
                    icon: "edit",
                    text: this.$t("general.edit")
                },
                {
                    icon: "delete",
                    text: this.$t("general.delete")
                }
            ]
        };
    },
    watch: {},
    computed: {
        formValid() {
            let _max = parseFloat(this.locationtoEdit.maxTemperature);
            let _min = parseFloat(this.locationtoEdit.minTemperature);
            return _max == null || _min == null || _max == "" || _min == "" || _max >= _min;
        },
        hasData() {
            return this.locationsShowed.edges.length > 0;
        },
        locationsShowed() {
            let _locations = { edges: [] };
            if (this.location) {
                _locations = this.convertDataWeatherFormat(this.location);
            }
            return _locations;
        },
        isWarningCitiesFilterEnabled() {
            return this.locationsFilter?.warningCities === true;
        },
        allHaveDataToShow() {
            return this.locationsToShow.edges.every(edge => {
                return edge.node.tenantLocationWeatherForecastSet.edges.length > 0;
            });
        }
    },
    mounted() {},
    created() {
        this.formatTemperature = helper.formatTemperature;
        this.getDateFormat = helper.getDateFormat;
        this.formatDateTime = helper.formatDateTime;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        eventBus.$on("refetchData", this.refetchData);
    },
    methods: {
        convertDataWeatherFormat(data) {
            let object = {
                edges: []
            };

            if (data) {
                let _location = JSON.parse(JSON.stringify(data));
                const { weatherForecastSubscriptionSet } = _location;
                if (weatherForecastSubscriptionSet) {
                    const { edges } = weatherForecastSubscriptionSet;
                    if (edges.length > 0) {
                        const { node } = edges[0];
                        _location.maxTemperature = node.maxTemperature;
                        _location.minTemperature = node.minTemperature;
                        _location.enabled = node.enabled;
                        _location.lastSyncTime = node.lastSyncTime;
                        _location.tenantLocationWeatherForecastSet = node.tenantLocationWeatherForecastSet;
                    } else {
                        _location.maxTemperature = null;
                        _location.minTemperature = null;
                        _location.enabled = false;
                        _location.lastSyncTime = null;
                        _location.tenantLocationWeatherForecastSet = {
                            edges: []
                        };
                    }
                }
                object.edges.push({ node: _location });
            }
            return object;
        },
        resetLocation() {
            this.locationtoEdit = {};
            this.openEditDialog = false;
        },
        editLocation(location) {
            this.isLoadingEdit = true;

            let payload = {
                id: location.id,
                locationType: location.locationType.toLowerCase(),
                isTrackerStockLocation: location.isTrackerStockLocation,
                showWeatherForecast: location.enabled,
                name: location.name,
                city: location.city,
                country: location.country,
                fullAddress: location.fullAddress,
                maxTemperature: location.maxTemperature == "" ? null : location.maxTemperature,
                minTemperature: location.minTemperature == "" ? null : location.minTemperature,
                geometryType: location.geometryType,
                latitude: location.latitude,
                longitude: location.longitude,
                radius: location.radius
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateLocation($input: UpdateTenantLocationInput!) {
                            updateTenantLocation(input: $input) {
                                location {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(() => {
                    this.isLoadingEdit = false;
                })
                .finally(() => {
                    this.resetLocation();
                    this.refetchData();
                });
        },
        deleteLocation(node) {
            let payload = {
                locations: [
                    {
                        id: node.id
                    }
                ],
                isAdding: false
            };
            this.isLoadingTable = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation removeWeatherLocation($input: UpdateTenantLocationWeatherForecastInput!) {
                            updateTenantLocationWeatherForecast(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .finally(() => {
                    this.refetchData().finally(() => {
                        this.isLoadingTable = false;
                        this.$emit("locationDeleted");
                    });
                });
        },
        stopLocationsPolling() {
            this.$apollo.queries.locations.stopPolling();
        },
        startLocationsPolling() {
            this.$apollo.queries.locations.startPolling(30000);
        },
        getChartOptions(edge) {
            return {
                chart: {
                    id: "forecast-chart-" + edge.node.id,
                    type: "line",

                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    type: "datetime",
                    categories: edge.node.tenantLocationWeatherForecastSet.edges.map(forecast =>
                        new Date(forecast.node.forecastDate).getTime()
                    ),
                    tickAmount: 2
                },
                tooltip: {
                    x: {
                        format: "dd/MM/yyyy"
                    }
                },
                colors: ["#FC6152", "#5C7FFF", "#F94332", "#3963FF"],
                dataLabels: {
                    enabled: false,
                    position: "top",
                    formatter: function(val, opts) {
                        if (opts.seriesIndex === 0 || opts.seriesIndex === 1) {
                            return val;
                        }
                        return "";
                    }
                },
                stroke: {
                    width: [3, 3, 2, 2],
                    curve: "smooth",
                    dashArray: [0, 0, 7, 7]
                },
                legend: {
                    show: false,
                    showForNullSeries: false,
                    showForZeroSeries: false,
                    position: "top",
                    horizontalAlign: "left",
                    fontSize: "14px",
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: 400,
                    labels: {
                        colors: "#BDBDBD"
                    }
                    // i want add text to legend show just the text "temperature"
                }
            };
        },
        getChartSeries(edge) {
            let chartSeries = [
                {
                    name: this.$t("weather.limit_max"),
                    data: edge.node.tenantLocationWeatherForecastSet.edges.map(forecast =>
                        parseFloat(this.formatTemperature(forecast.node?.maxTemperature) ?? null).toFixed(2)
                    )
                },
                {
                    name: this.$t("weather.limit_min"),
                    data: edge.node.tenantLocationWeatherForecastSet.edges.map(forecast =>
                        parseFloat(this.formatTemperature(forecast.node?.minTemperature) ?? null).toFixed(2)
                    )
                },
                {
                    name: this.$t("weather.limit_max"),
                    data: edge.node.tenantLocationWeatherForecastSet.edges.map(forecast => {
                        const maxTemperature = edge.node?.maxTemperature;
                        const formattedMinTemperature =
                            maxTemperature !== null
                                ? parseFloat(this.formatTemperature(maxTemperature)).toFixed(2)
                                : null;
                        return formattedMinTemperature;
                    })
                },
                {
                    name: this.$t("weather.limit_min"),
                    data: edge.node.tenantLocationWeatherForecastSet.edges.map(forecast => {
                        const minTemperature = edge.node?.minTemperature;
                        const formattedMinTemperature =
                            minTemperature !== null
                                ? parseFloat(this.formatTemperature(minTemperature)).toFixed(2)
                                : null;
                        return formattedMinTemperature;
                    })
                }
            ];
            return chartSeries;
        },
        onCheckDetailsButtonClick(edge) {
            this.$emit("click:checkDetails", edge);
        },
        onRemoveLocationButtonClick(edge) {
            this.$emit("click:disabledAddLocationButton");

            let payload = {
                locationIds: edge.node.id
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation addNewWeatherLocation($input: UpdateTenantLocationWeatherForecastInput!) {
                            updateTenantLocationWeatherForecast(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .finally(() => {
                    this.refetchData(() => {
                        this.$emit("click:enableAddLocationButton");
                    });
                });
        },
        async refetchData(callback) {
            await this.$apollo.queries.locations.refetch();
            if (callback) {
                callback();
            }
        },
        orderLocationsToShow() {
            let length = this.locationsShowed.edges.length;
            if (length !== 0) {
                const edges = this.locationsShowed.edges;
                if (this.displayedEdgesIds !== undefined && this.displayedEdgesIds.length > 0) {
                    edges.sort((a, b) => {
                        const indexOfA = this.displayedEdgesIds.indexOf(a.node.id);
                        const indexOfB = this.displayedEdgesIds.indexOf(b.node.id);
                        return indexOfA - indexOfB;
                    });
                }
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: "weather-cards",
                disabled: this.isFilterEnabled,
                ghostClass: "ghost"
            };
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
