<template>
    <v-dialog max-width="600" v-model="openDeleteUserDialog" persistent>
        <v-card class="pb-4">
            <v-card-title class="primary">
                <v-icon color="white" class="mr-2">warning</v-icon>
                <div>
                    <span class="white--text">
                        {{ $t("users.delete_user") }}
                    </span>
                </div>
            </v-card-title>
            <v-card-text class="pb-2">
                <div class="mt-6">
                    <h4>
                        {{$t('users.sure_user')}}
                    </h4>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="$emit('closeDeleteDialog')"
                    >{{$t('general.cancel')}}</v-btn
                >
                <v-btn
                    color="primary"
                    rounded
                    :loading="isDeleting"
                    @click="deleteUser"
                    >{{$t('general.delete')}}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";

export default {
    name: "DeleteUser",
    props: ["openDeleteUserDialog", "userId"],
    data: function() {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteUser() {
            this.isDeleting = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation DeleteUser($input: DeleteUserInput!) {
                            deleteUser(input: $input) {
                                user {
                                    id
                                    email
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.userId
                        }
                    }
                })
                .then(() => {
                    this.$emit("refreshDataTable");
                    this.$emit("closeDeleteDialog");
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isDeleting = false;
                });
        }
    }
};
</script>
