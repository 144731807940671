<template>
    <v-card flat class="py-3 px-3">
        <v-sheet class="mb-5 pb-0" rounded="xl" elevation="5">
            <!-- freight info -->
            <v-card flat rounded="xl">
                <v-card-title class="background2 py-1" v-if="me.isSuperuser">
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        color="primary"
                        @click="editTracker(tracker)"
                        :disabled="readonly"
                    >
                        <v-icon>
                            edit
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-row class="my-0 pa-0 mx-3 d-flex">
                    <!-- <v-col cols="6" sm="4" md="3">
                        <div class="font-weight-medium">
                            {{ tracker.serialNumber || "-" }}
                        </div>
                        <div class="text-caption">Serial Number</div>
                    </v-col> -->

                    <v-col cols="6" sm="4" md="3">
                        <div class="font-weight-medium">
                            {{ tracker.brand }}
                        </div>
                        <div class="text-caption">
                            {{ $t("headers.brand") }}
                        </div>
                    </v-col>

                    <!--  <v-col cols="6" sm="4" md="3">
                        <div class="font-weight-medium">
                            {{ tracker.model || "-" }}
                        </div>
                        <div class="text-caption">Model</div>
                    </v-col> -->

                    <v-col cols="6" sm="4" md="3">
                        <div class="font-weight-medium">
                            {{ tracker.packageNumber || "-" }}
                        </div>
                        <div class="text-caption">
                            {{ $t("headers.package_number") }}
                        </div>
                    </v-col>

                    <v-col cols="6" sm="4" md="3">
                        <div class="font-weight-medium">
                            {{
                                tracker.stockLocation
                                    ? tracker.stockLocation.name
                                    : "-"
                            }}
                        </div>
                        <div class="text-caption">
                            {{ $t("trackers.stock_location") }}
                        </div>
                    </v-col>

                    <v-col cols="6" sm="4" md="3">
                        <div class="font-weight-medium">
                            {{
                                tracker.activatedDate
                                    ? this.formatDateTime(
                                          tracker.activatedDate
                                      ).slice(0, 10)
                                    : "-"
                            }}
                        </div>
                        <div class="text-caption">
                            {{ $t("headers.activation") }}
                        </div>
                    </v-col>

                    <v-col cols="6" sm="4" md="3">
                        <div class="font-weight-medium">
                            {{
                                tracker.expiryDate
                                    ? this.formatDateTime(
                                          tracker.expiryDate
                                      ).slice(0, 10)
                                    : "-"
                            }}
                        </div>
                        <div class="text-caption">
                            {{ $t("headers.expiration") }}
                        </div>
                    </v-col>
                    <v-col
                        v-if="!me.isSuperuser" cols="6" sm="4" md="3"
                    >
                         <div class="font-weight-medium">
                            <v-chip v-if="!isAvailable" dense small pill  @click="redirectTotrip">
                                <v-avatar left>
                                    <v-icon color="primary">
                                        check_circle
                                    </v-icon>
                                </v-avatar>
                                {{trip.referenceNumber}}
                            </v-chip>
                            <div v-else class="d-flex align-center">
                                <v-chip dense small pill>
                                    <v-avatar left>
                                        <v-icon color=""
                                            >check_circle</v-icon
                                        >
                                    </v-avatar>
                                    {{ $t("trips.available") }}
                                </v-chip>
                            </div>
                        </div>
                        <div class="text-caption">
                            {{ $t("headers.reference_number") }}
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" md="3" v-if="me.isSuperuser">
                        <div  class="font-weight-medium">
                            {{tracker.tenant ? tracker.tenant.name : "-"}}
                        </div>
                        <div class="text-caption">
                            {{ $t("trackers.tenant") }}
                        </div>
                    </v-col>
                    <v-col v-if="tracker.isBeacon" cols="6" sm="4" md="3">
                        <div class="font-weight-medium">
                            {{tracker.mainTracker}}
                        </div>
                        <div class="text-caption">
                            {{ $t("headers.main_tracker") }}
                        </div>
                    </v-col>
                    <!-- <v-col
                        cols="6"
                        lg="3"
                        sm="6"
                        md="3"
                        class="d-flex align-center"
                    >
                        <div class="font-weight-medium">
                            <v-chip v-if="isAvailable" dense small pill>
                                <v-avatar left>
                                    <v-icon color="">
                                        check_circle
                                    </v-icon>
                                </v-avatar>
                                {{ $t("trips.available") }}
                            </v-chip>
                            <div v-else class="d-flex align-center">
                                <v-chip dense small pill>
                                    <v-avatar left>
                                        <v-icon color="primary"
                                            >check_circle</v-icon
                                        >
                                    </v-avatar>
                                    {{ $t("trips.already") }}
                                </v-chip>
                            </div>
                        </div>
                    </v-col> -->
                </v-row>
            </v-card>
            <v-expansion-panels
                v-model="panel"
                accordion
                multiple
                class="my-0 py-0"
            >
                <v-expansion-panel class="rounded-b-xl mx-3 my-0 py-1">
                    <v-expansion-panel-header>
                        <template v-slot:default="{ open }">
                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <v-chip
                                        :color="
                                            alertTracker(
                                                tracker.lastConnectionTime
                                            )
                                                ? 'primary'
                                                : ''
                                        "
                                        class="mr-3 pr-3"
                                    >
                                        <v-icon>settings_remote</v-icon>
                                        <div>
                                            {{ tracker.serialNumber }}
                                        </div>
                                        <v-btn
                                            icon
                                            color="white"
                                            small
                                            @click="
                                                copyToClipboard(
                                                    tracker.serialNumber
                                                )
                                            "
                                            @click.stop=""
                                        >
                                            <v-icon small>
                                                content_copy
                                            </v-icon>
                                        </v-btn>
                                    </v-chip>
                                </v-col>

                                <v-col cols="auto">
                                    <v-chip v-if="tracker.model" outlined>
                                        <div>
                                            {{ tracker.model }}
                                        </div>
                                    </v-chip>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-spacer></v-spacer>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <v-fade-transition leave-absolute>
                                        <div
                                            class="
                                                grey--text
                                                d-flex
                                                justify-start
                                                align-center
                                                mt-2
                                                caption
                                            "
                                            v-if="tracker.lastConnectionTime"
                                        >
                                            <div>
                                                <i
                                                    >{{
                                                        $t(
                                                            "trips.last_connection"
                                                        )
                                                    }}
                                                    {{
                                                        relativeTime(
                                                            tracker.lastConnectionTime
                                                        )
                                                    }}</i
                                                >
                                            </div>
                                        </div>
                                        <div
                                            class="
                                                grey--text
                                                d-flex
                                                justify-start
                                                align-center
                                                mt-2
                                                caption
                                            "
                                            v-else
                                        >
                                            <div>
                                                <i>{{
                                                    $t("loading.no_available")
                                                }}</i>
                                            </div>
                                        </div>
                                    </v-fade-transition>
                                </v-col>
                            </v-row>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="px-4 py-2">
                        <v-row dense>
                            <!-- Temperature -->
                            <v-col cols="3" v-if="tracker.lastTemperatureValue">
                                <v-card>
                                    <v-card-title style="height: 90px">
                                        <v-row dense>
                                            <v-col>
                                                <div
                                                    class="
                                                    font-weight-medium
                                                "
                                                >
                                                    {{
                                                        Math.floor(
                                                            formatTemperature(
                                                                tracker.lastTemperatureValue
                                                            )
                                                        )
                                                    }}
                                                    {{ getTemperatureSymbol() }}
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-btn
                                                            class="indigo"
                                                            elevation="0"
                                                            fab
                                                            small
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon
                                                                class="
                                                                d-flex
                                                                align-center
                                                                justify-center
                                                            "
                                                                color="white"
                                                            >
                                                                thermostat
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            "general.temperature"
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-caption">
                                            {{
                                                formatDateTime(
                                                    tracker.lastTemperatureDeviceTime
                                                )
                                            }}
                                        </div>
                                        <!-- <div class="text-caption">{{formatDateTime(tracker.lastConnectionTime)}}</div> -->
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <!-- External Temperature -->
                            <v-col
                                cols="3"
                                v-if="tracker.lastExternalTemperatureValue"
                            >
                                <v-card>
                                    <v-card-title style="height: 90px">
                                        <v-row dense>
                                            <v-col>
                                                <div
                                                    class="
                                                    font-weight-medium
                                                "
                                                >
                                                    {{
                                                        Math.floor(
                                                            formatTemperature(
                                                                tracker.lastExternalTemperatureValue
                                                            )
                                                        )
                                                    }}
                                                    {{ getTemperatureSymbol() }}
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-btn
                                                            class="indigo"
                                                            elevation="0"
                                                            fab
                                                            small
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon
                                                                class="
                                                                d-flex
                                                                align-center
                                                                justify-center
                                                            "
                                                                color="white"
                                                            >
                                                                thermostat
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            "general.external_temperature"
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-caption">
                                            {{
                                                formatDateTime(
                                                    tracker.lastExternalTemperatureDeviceTime
                                                )
                                            }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <!-- Probe Temperature -->
                            <v-col
                                cols="3"
                                v-if="tracker.lastProbeTemperatureValue"
                            >
                                <v-card>
                                    <v-card-title style="height: 90px">
                                        <v-row dense>
                                            <v-col>
                                                <div
                                                    class="
                                                    font-weight-medium
                                                "
                                                >
                                                    {{
                                                        Math.floor(
                                                            formatTemperature(
                                                                tracker.lastProbeTemperatureValue
                                                            )
                                                        )
                                                    }}
                                                    {{ getTemperatureSymbol() }}
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-btn
                                                            class="indigo"
                                                            elevation="0"
                                                            fab
                                                            small
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon
                                                                class="
                                                                d-flex
                                                                align-center
                                                                justify-center
                                                            "
                                                                color="white"
                                                            >
                                                                thermostat
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            "general.probe_temperature"
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-caption">
                                            {{
                                                formatDateTime(
                                                    tracker.lastProbeTemperatureDeviceTime
                                                )
                                            }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <!-- Battery -->
                            <v-col
                                cols="3"
                                v-if="
                                    tracker.lastBatteryValue &&
                                        tracker.brand.toLowerCase() !==
                                            'sensitech'
                                "
                            >
                                <v-card>
                                    <v-card-title style="height: 90px">
                                        <v-row dense>
                                            <v-col>
                                                <div
                                                    class="
                                                    font-weight-medium
                                                "
                                                >
                                                    {{
                                                        Math.floor(
                                                            tracker.lastBatteryValue
                                                        )
                                                    }}
                                                    {{
                                                        this.unitPerBrand(
                                                            tracker.brand
                                                        )
                                                    }}
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-btn
                                                            class="success"
                                                            elevation="0"
                                                            fab
                                                            small
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon
                                                                class="
                                                                d-flex
                                                                align-center
                                                                justify-center
                                                            "
                                                                color="white"
                                                            >
                                                                battery_charging_full
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{
                                                        $t("general.battery")
                                                    }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-caption">
                                            {{
                                                formatDateTime(
                                                    tracker.lastBatteryDeviceTime
                                                )
                                            }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Humidity -->
                            <v-col cols="3" v-if="tracker.lastHumidityValue">
                                <v-card>
                                    <v-card-title style="height: 90px">
                                        <v-row dense>
                                            <v-col>
                                                <div
                                                    class="
                                                    font-weight-medium
                                                "
                                                >
                                                    {{
                                                        Math.floor(
                                                            tracker.lastHumidityValue
                                                        )
                                                    }}
                                                    %
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-btn
                                                            class="cyan"
                                                            elevation="0"
                                                            fab
                                                            small
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon
                                                                class="
                                                                d-flex
                                                                align-center
                                                                justify-center
                                                            "
                                                                color="white"
                                                            >
                                                                water_drop
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{
                                                        $t("general.humidity")
                                                    }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-caption">
                                            {{
                                                formatDateTime(
                                                    tracker.lastHumidityDeviceTime
                                                )
                                            }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="3" v-if="tracker.lastLightValue">
                                <v-card>
                                    <v-card-title style="height: 90px">
                                        <v-row dense>
                                            <v-col>
                                                <div
                                                    class="
                                                    font-weight-medium
                                                "
                                                >
                                                    {{tracker.lastLightValue.toFixed(2)}} Lux
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-btn
                                                            class="amber"
                                                            elevation="0"
                                                            fab
                                                            small
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon
                                                                class="
                                                                d-flex
                                                                align-center
                                                                justify-center
                                                            "
                                                                color="white"
                                                            >
                                                                lightbulb
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{
                                                        $t("general.light")
                                                    }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-caption">
                                            {{
                                                formatDateTime(
                                                    tracker.lastLightDeviceTime
                                                )
                                            }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="3" v-if="tracker.lastShockValue">
                                <v-card>
                                    <v-card-title style="height: 90px">
                                        <v-row dense>
                                            <v-col>
                                                <div
                                                    class="
                                                    font-weight-medium
                                                "
                                                >
                                                    {{
                                                        Math.floor(
                                                            tracker.lastShockValue
                                                        )
                                                    }}
                                                    G
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-btn
                                                            class="error"
                                                            elevation="0"
                                                            fab
                                                            small
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon
                                                                class="
                                                                d-flex
                                                                align-center
                                                                justify-center
                                                            "
                                                                color="white"
                                                            >
                                                                bolt
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{
                                                        $t("general.shock")
                                                    }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-caption">
                                            {{
                                                formatDateTime(
                                                    tracker.lastShockDeviceTime
                                                )
                                            }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- <v-col cols="3" v-if="
                        tracker.lastLatitude &&
                        tracker.lastLongitude
                    ">
                    <v-card>
                            <v-card-title style="height:90px">
                                <v-row dense>
                                    <v-col>
                                        <div class="font-weight-medium">
                                            {{
                                                tracker
                                                        .lastLatitude
                                            }}
                                        </div>
                                        <div class="font-weight-medium">
                                            {{
                                                    tracker
                                                        .lastLongitude
                                                    }}
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn class="deep-purple  mt-3" elevation="0" fab small>
                                                    <v-icon class="d-flex align-center justify-center" color="white">
                                                        public
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <div class="text-caption">
                                    {{
                                        formatDateTime(
                                            tracker
                                            .lastLocationDeviceTime
                                            )
                                        }}
                                    </div>
                                </v-card-text>
                            </v-card>
                    </v-col>
                    <v-col cols="3" v-if="tracker.lastLocationDescription">
                        <v-card>
                            <v-card-title style="height:90px">
                                <v-row dense>
                                    <v-col>
                                        <div class="font-weight-medium">
                                            {{
                                                tracker
                                                .lastLocationDescription
                                            }}
                                        </div>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn class="primary" elevation="0" fab small>
                                            <v-icon class="d-flex align-center justify-center" color="white">
                                                location_on
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <div class="text-caption">
                                    {{
                                        formatDateTime(
                                                tracker
                                                .lastLocationDeviceTime
                                                )
                                            }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col> -->
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-sheet>
        <EditTrackerDialog
            :item="editItem"
            :dialog.sync="editDialog"
            :isAvailable="isAvailable"
            v-on:onEdited="onEdited"
        ></EditTrackerDialog>
    </v-card>
</template>

<script>
import helper from "../../../utils/helper";
import moment from "moment";
import EditTrackerDialog from "@/components/trackers/update/EditTrackerDialog.vue";
import Config from "@/utils/config.json";

export default {
    components: {
        EditTrackerDialog
    },
    props: {
        tracker: {
            type: Object,
            required: true
        },
        isAvailable: {
            type: Boolean,
            required: false
        },
        disabledEdit: {
            type: Boolean,
            required: true
        },
        trip: {
            type: Object
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
        this.relativeTime = helper.relativeTime;
        this.unitPerBrand = helper.unitPerBrand;
    },
    data() {
        return {
            panel: [0],
            editDialog: false,
            readonly: true,
            deleteDialog: false,
            editItem: null
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {
        disabledEdit: {
            handler: function(val, oldVal) {
                this.readonly = val;
            },
            deep: true,
            immediate: true
        }
    },
    mounted(){
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'tracker_details_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("tracker_details_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("tracker_details_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        redirectTotrip() {
            this.$router.push({
                name: "trips",
                query: { id: this.trip.id, tab: "summary" }
            });
        },
        copyToClipboard(text) {
            let dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            const payload = {
                color: "primary",
                message: "Copied!"
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        editTracker(tracker) {
            this.editItem = { ...tracker };
            this.editDialog = true;
        },
        onEdited(tracker) {
            this.$emit("updateTracker", tracker);
        },
        alertTracker(value) {
            let relativeTime = moment(value)
                .locale("en")
                .startOf("hour")
                .fromNow();
            let x = relativeTime.split(" ");

            if (
                x[2] === this.$t("date.minutes") ||
                x[1] === this.$t("date.minutes") ||
                x[1] === this.$t("date.minute") ||
                x[1] === this.$t("date.hour") ||
                x[1] === this.$t("date.hours")
            ) {
                return true;
            } else {
                return false;
            }
        },
        getTemperatureSymbol() {
            let _preferences = this.$store.state.user.me?.preferences;
            if (_preferences != null) {
                let preferences = JSON.parse(_preferences);
                try {
                    return preferences.temperature.symbol;
                } catch {
                    return "°C";
                }
            }
        }
    }
};
</script>
