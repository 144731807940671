<template>
    <v-container fluid>
        <v-card outlined>
            <v-form>
                <v-row class="ma-0 pa-0 d-flex">
                    <v-col cols="3">
                        <v-text-field
                            v-model="tripLoadPoint.trade"
                            :label="$t('forms.trade')"
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <div
                        v-if="tripContainers.length > 0"
                        class="d-flex justify-end"
                    >
                        <v-autocomplete
                            class="mt-3 mr-3"
                            v-model="containerAutofill"
                            :items="tripContainers"
                            :no-data-text="$t('loading.no_available')"
                            :label="$t('general.container')"
                            :disabled="isLoadingAutoFill || templateMode"
                            :filled="templateMode"
                            outlined
                            dense
                            hide-details
                            attach
                        ></v-autocomplete>
                        <v-btn
                            rounded
                            color="primary"
                            @click="autoFill"
                            :loading="isLoadingAutoFill"
                            :disabled="!containerAutofill"
                            class="mt-3 mr-3"
                        >
                            <div class="mr-1">{{ $t("forms.auto_fill") }}</div>
                            <v-icon>drive_file_rename_outline</v-icon>
                        </v-btn>
                    </div>

                    <!--  <v-col>
                        <v-text-field
                            v-model="tripLoadPoint.logco"
                            label="Contact person"
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="tripLoadPoint.loadPoint"
                            label="Load Point"
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <DateComponent
                            v-model="tripLoadPoint.loadDate"
                            label="Load Date"
                            :icon="'calendar_month'"
                            :outlined="true"
                            :dense = true
                            :hideDetails="true"
                        ></DateComponent>
                    </v-col> -->
                </v-row>
            </v-form>
        </v-card>
        <v-card outlined class="mt-5 pr-3">
            <v-timeline align-top dense>
                <div v-for="(item, i) in tripSeaTransport" :key="i">
                    <v-timeline-item
                        small
                        v-if="item.portTransportType === 'POL'"
                        class="py-0"
                    >
                        <v-row no-gutters>
                            <v-col cols="12" class="py-0">
                                <v-row dense>
                                    <v-text-field
                                        v-show="false"
                                        dense
                                        :label="$t('forms.sequence')"
                                        v-model="item.sequence"
                                        outlined
                                        filled
                                        readonly
                                    />

                                    <v-col>
                                        <v-text-field
                                            dense
                                            :label="$t('forms.port_trans')"
                                            v-model="item.portTransportType"
                                            outlined
                                            :filled="!templateMode"
                                            readonly
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-combobox
                                            v-model="item.portCode"
                                            :items="seaPorts.edges"
                                            :no-data-text="$t('loading.no_available')"
                                            :item-value="item => item.node.code"
                                            :item-text="item => item.node.code"
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            :label="$t('trips.port_code')"
                                            :search-input.sync="item.portCode"
                                            :return-object="false"
                                            clearable
                                            outlined
                                            dense
                                            :error-messages="
                                                item.portCode === null &&
                                                editMode
                                                    ? 'Port Code is missing'
                                                    : []
                                            "
                                        >
                                            <template v-slot:item="{ item }">
                                                <v-row class="ma-0 body-2">
                                                    <v-col
                                                        cols="4"
                                                        class="text-truncate pl-0 ml-0"
                                                    >
                                                        {{ item.node.code }}
                                                    </v-col>
                                                    <v-col
                                                        cols="8"
                                                        class="d-flex justify-end"
                                                    >
                                                        <span
                                                            class="grey--text body-2 mr-1"
                                                            ><i>{{
                                                                item.node.name
                                                            }}</i></span
                                                        >
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="3">
                                        <DateComponent
                                            v-model="
                                                item.estimatedDepartureDate
                                            "
                                            label="ETD"
                                            :icon="'calendar_month'"
                                            :rules="[]"
                                            :outlined="true"
                                            :dense="true"
                                            :disabled="templateMode"
                                            :filled="templateMode"
                                        ></DateComponent>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            dense
                                            v-model="item.vesselInputType"
                                            :items="type"
                                            @change="
                                                updateVesselInputValueChoices(
                                                    item,
                                                    i
                                                )
                                            "
                                            :label="$t('trips.vessel_input')"
                                            outlined
                                            :disabled="templateMode"
                                            :filled="templateMode"
                                            :error-messages="
                                                item.vesselInputType === null &&
                                                editMode
                                                    ? $t(
                                                          'rules.vessel_input_missing'
                                                      )
                                                    : []
                                            "
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col>
                                        <v-combobox
                                            :label="
                                                item.vesselInputType
                                                    ? `${getInputTypeText(
                                                          item.vesselInputType
                                                      )}` + ' '
                                                    : $t('trips.vessel_no')
                                            "
                                            v-model="item.vesselInputValue"
                                            :items="
                                                vesselInputValueChoices.length >
                                                i
                                                    ? vesselInputValueChoices[i]
                                                    : []
                                            "
                                            :no-data-text="$t('loading.no_available')"
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            :disabled="
                                                item.vesselInputType == null ||
                                                    item.vesselInputType
                                                        .length == 0
                                            "
                                            :filled="templateMode"
                                            clearable
                                            outlined
                                            dense
                                            :error-messages="
                                                item.vesselInputValue ===
                                                    null && editMode
                                                    ? 'Vessel No. is missing'
                                                    : []
                                            "
                                        ></v-combobox>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            dense
                                            :label="$t('trips.voyage')"
                                            v-model="item.voyage"
                                            outlined
                                            :disabled="templateMode"
                                            :filled="templateMode"
                                            :error-messages="
                                                item.voyage === null && editMode
                                                    ? 'Voyage is missing'
                                                    : []
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="item.portTransportType === 'T/S'"
                        color="white"
                        class="py-0"
                    >
                        <template v-slot:icon>
                            <v-btn
                                dark
                                icon
                                small
                                rounded
                                outlined
                                color="error"
                                class="float-right"
                                @click="remove(i)"
                            >
                                <v-icon>
                                    delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-row no-gutters>
                            <v-col cols="12" class="py-0">
                                <v-row dense>
                                    <v-text-field
                                        v-show="false"
                                        dense
                                        :label="$t('forms.sequence')"
                                        v-model="item.sequence"
                                        outlined
                                        filled
                                        readonly
                                    />
                                    <v-col class="">
                                        <v-text-field
                                            dense
                                            :label="$t('forms.port_trans')"
                                            v-model="item.portTransportType"
                                            outlined
                                            :filled="!templateMode"
                                            readonly
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-combobox
                                            v-model="item.portCode"
                                            :items="seaPorts.edges"
                                            :no-data-text="$t('loading.no_available')"
                                            :item-value="item => item.node.code"
                                            :item-text="item => item.node.code"
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            :label="$t('trips.port_code')"
                                            :search-input.sync="item.portCode"
                                            :return-object="false"
                                            clearable
                                            outlined
                                            dense
                                            :error-messages="
                                                (item.portCode === null ||
                                                    item.portCode === '') &&
                                                editMode
                                                    ? 'Port Code is missing'
                                                    : []
                                            "
                                            ><template v-slot:item="{ item }">
                                                <v-row class="ma-0 body-2">
                                                    <v-col
                                                        cols="4"
                                                        class="text-truncate pl-0 ml-0"
                                                    >
                                                        {{ item.node.code }}
                                                    </v-col>
                                                    <v-col
                                                        cols="8"
                                                        class="d-flex justify-end"
                                                    >
                                                        <span
                                                            class="grey--text body-2 mr-1"
                                                            ><i>{{
                                                                item.node.name
                                                            }}</i></span
                                                        >
                                                    </v-col>
                                                </v-row>
                                            </template></v-combobox
                                        >
                                    </v-col>
                                    <v-col cols="3">
                                        <DateComponent
                                            v-model="
                                                item.estimatedDepartureDate
                                            "
                                            label="ETD"
                                            :icon="'calendar_month'"
                                            :rules="[]"
                                            :outlined="true"
                                            :dense="true"
                                            :disabled="templateMode"
                                            :filled="templateMode"
                                        ></DateComponent>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            dense
                                            v-model="item.vesselInputType"
                                            :items="type"
                                            @change="
                                                updateVesselInputValueChoices(
                                                    item,
                                                    i
                                                )
                                            "
                                            :label="$t('trips.port_code')"
                                            :error-messages="
                                                (item.vesselInputType ===
                                                    null ||
                                                    item.vesselInputType ===
                                                        '') &&
                                                editMode
                                                    ? $t(
                                                          'rules.vessel_input_missing'
                                                      )
                                                    : []
                                            "
                                            outlined
                                            :disabled="templateMode"
                                            :filled="templateMode"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col>
                                        <v-combobox
                                            :label="
                                                item.vesselInputType
                                                    ? `${getInputTypeText(
                                                          item.vesselInputType
                                                      )}` + ' '
                                                    : $t('trips.vessel_no')
                                            "
                                            v-model="item.vesselInputValue"
                                            :items="
                                                vesselInputValueChoices.length >
                                                i
                                                    ? vesselInputValueChoices[i]
                                                    : []
                                            "
                                            :no-data-text="$t('loading.no_available')"
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            :disabled="
                                                item.vesselInputType == null ||
                                                    item.vesselInputType
                                                        .length == 0
                                            "
                                            :filled="templateMode"
                                            clearable
                                            outlined
                                            dense
                                            :error-messages="
                                                (item.vesselInputValue ===
                                                    null ||
                                                    item.vesselInputValue ===
                                                        '') &&
                                                editMode
                                                    ? 'Vessel No. is missing'
                                                    : []
                                            "
                                        ></v-combobox>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            dense
                                            :label="$t('trips.voyage')"
                                            v-model="item.voyage"
                                            outlined
                                            :disabled="templateMode"
                                            :filled="templateMode"
                                            :error-messages="
                                                (item.voyage === null ||
                                                    item.voyage === '') &&
                                                editMode
                                                    ? 'Voyage is missing'
                                                    : []
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item
                        small
                        v-if="item.portTransportType === 'POD'"
                        class="pt-n1"
                    >
                        <template v-slot:icon>
                            <v-btn
                                icon
                                small
                                rounded
                                @click="add()"
                                class="primary"
                            >
                                <v-icon color="white">add</v-icon>
                            </v-btn>
                        </template>
                    </v-timeline-item>
                    <v-timeline-item
                        small
                        v-if="item.portTransportType === 'POD'"
                        class="py-0"
                    >
                        <v-row no-gutters>
                            <v-col cols="12" class="py-0">
                                <v-row dense>
                                    <v-text-field
                                        v-show="false"
                                        dense
                                        :label="$t('forms.sequence')"
                                        v-model="item.sequence"
                                        outlined
                                        filled
                                        readonly
                                    />
                                    <v-col>
                                        <v-text-field
                                            dense
                                            :label="$t('forms.port_trans')"
                                            v-model="item.portTransportType"
                                            outlined
                                            :filled="!templateMode"
                                            readonly
                                        />
                                    </v-col>
                                    <v-col>
                                        <!-- <v-text-field
                                            dense
                                            label="Port Code"
                                            v-model="item.portCode"
                                            outlined
                                        /> -->
                                        <v-combobox
                                            v-model="item.portCode"
                                            :items="seaPorts.edges"
                                            :no-data-text="$t('loading.no_available')"
                                            :item-value="item => item.node.code"
                                            :item-text="item => item.node.code"
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            :label="$t('trips.port_code')"
                                            :search-input.sync="item.portCode"
                                            :return-object="false"
                                            clearable
                                            outlined
                                            dense
                                            :error-messages="
                                                (item.portCode === null ||
                                                    item.portCode === '') &&
                                                editMode
                                                    ? 'Port Code is missing'
                                                    : []
                                            "
                                            ><template v-slot:item="{ item }">
                                                <v-row class="ma-0 body-2">
                                                    <v-col
                                                        cols="4"
                                                        class="text-truncate pl-0 ml-0"
                                                    >
                                                        {{ item.node.code }}
                                                    </v-col>
                                                    <v-col
                                                        cols="8"
                                                        class="d-flex justify-end"
                                                    >
                                                        <span
                                                            class="grey--text body-2 mr-1"
                                                            ><i>{{
                                                                item.node.name
                                                            }}</i></span
                                                        >
                                                    </v-col>
                                                </v-row>
                                            </template></v-combobox
                                        >
                                    </v-col>
                                    <v-col cols="3">
                                        <DateComponent
                                            v-model="item.estimatedArrivalDate"
                                            label="ETA"
                                            :icon="'calendar_month'"
                                            :rules="[]"
                                            :outlined="true"
                                            :dense="true"
                                            :disabled="templateMode"
                                            :filled="templateMode"
                                        ></DateComponent>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            dense
                                            v-model="item.vesselInputType"
                                            :items="type"
                                            @change="
                                                updateVesselInputValueChoices(
                                                    item,
                                                    i
                                                )
                                            "
                                            :label="$t('trips.vessel_input')"
                                            outlined
                                            disabled
                                            filled
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col>
                                        <v-combobox
                                            :label="
                                                item.vesselInputType
                                                    ? `${getInputTypeText(
                                                          item.vesselInputType
                                                      )}` + ' '
                                                    : $t('trips.vessel_input')
                                            "
                                            v-model="item.vesselInputValue"
                                            :items="
                                                vesselInputValueChoices.length >
                                                i
                                                    ? vesselInputValueChoices[i]
                                                    : []
                                            "
                                            :no-data-text="$t('loading.no_available')"
                                            :loading="
                                                $apollo.queries.seaPorts.loading
                                            "
                                            clearable
                                            outlined
                                            disabled
                                            filled
                                            dense
                                        ></v-combobox>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            dense
                                            :label="$t('trips.voyage')"
                                            v-model="item.voyage"
                                            outlined
                                            disabled
                                            filled
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </div>
            </v-timeline>
        </v-card>
        <v-row dense class="d-flex justify-center">
            <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="mt-1"
                style="z-index: 9999;"
            >
                <v-alert
                    v-if="hasMissingFields"
                    outlined
                    type="warning"
                    border="left"
                    dense
                >
                    {{ $t("forms.sea_transport_warning") }}
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import DateComponent from "@/components/base/DateComponent.vue";
import gql from "graphql-tag";

export default {
    components: {
        DateComponent
    },
    props: {
        tripSeaTransport: {
            type: Array
        },
        value: {
            type: Object
        },
        tripContainers: {
            type: Array
        },
        tripPartnerCarrierId: {
            type: String
        },
        templateMode: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        vessels: {
            query() {
                return gql`
                    query Vessels($orderBy: String) {
                        vessels(orderBy: $orderBy) {
                            edges {
                                node {
                                    callsign
                                    id
                                    imo
                                    name
                                    mmsi
                                }
                            }
                        }
                    }
                `;
            },
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update(data) {
                return data.vessels;
            }
        },
        seaPorts: {
            query() {
                return gql`
                    query SeaPorts {
                        seaPorts {
                            edges {
                                node {
                                    country
                                    code
                                    id
                                    name
                                }
                            }
                        }
                    }
                `;
            },
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update(data) {
                return data.seaPorts;
            }
        }
    },
    data() {
        return {
            validate: false,
            type: [
                { text: this.$t("trips.vessel_name"), value: "shipname" },
                { text: this.$t("trips.call_sign"), value: "callsign" },
                { text: "IMO", value: "imo" },
                { text: "MMSI", value: "mmsi" }
            ],
            isLoadingAutoFill: false,
            transhipments: [],
            ldBtn: false,
            saved: false,
            voyage: "",
            vesselInputType: "",
            vesselInputValue: "",
            sequenceNo: 0,
            containerAutofill: null,
            searchPortCode: "",
            seaPorts: { edges: [] },
            vessels: { edges: [] },
            vesselInputValueChoices: []
            //hasMissingFields: false,
        };
    },
    computed: {
        tripLoadPoint: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        hasMissingFields() {
            let missingFields = false;
            missingFields = this.tripSeaTransport.some(
                x =>
                    x.portCode === null ||
                    x.portCode === undefined ||
                    x.portCode === ""
            );
            if (missingFields === true) {
                return missingFields;
            } else {
                missingFields = this.tripSeaTransport.some(
                    x =>
                        x.portTransportType !== "POD" &&
                        (x.vesselInputType === null ||
                            x.vesselInputType === undefined ||
                            x.vesselInputType === "" ||
                            x.vesselInputValue === null ||
                            x.vesselInputValue === undefined ||
                            x.vesselInputValue === "")
                );
                return missingFields;
            }
        }
    },
    mounted() {
        //this.areFieldsMissing(this.tripSeaTransport);
    },
    watch: {
        isSeaTransport: {
            immediate: true,
            handler: function(newVal) {
                if (newVal === false) {
                    this.transhipments = [];
                    this.pol = {
                        sequence: 0,
                        voyage: "",
                        portCode: "",
                        portTransportType: "POL",
                        vesselInputType: "",
                        vesselInputValue: "",
                        estimatedDepartureDate: ""
                    };
                    this.pod = {
                        sequence: 0,
                        portCode: "",
                        portTransportType: "POD",
                        estimatedArrivalDate: ""
                    };
                    this.tripLoadPoint = {
                        trade: "",
                        logco: "",
                        loadPoint: "",
                        loadDate: ""
                    };
                }
            },
            deep: true
        }
    },
    methods: {
        add() {
            let _seaTransport = [...this.tripSeaTransport];

            _seaTransport.splice(-1, 0, {
                sequence: null,
                id: null,
                voyage: "",
                portCode: "",
                portTransportType: "T/S",
                vesselInputType: "",
                vesselInputValue: "",
                estimatedDepartureDate: ""
            });

            _seaTransport.forEach((element, i) => (element.sequence = i));

            this.$emit("update:tripSeaTransport", _seaTransport);
        },
        remove(index) {
            let _seaTransport = [...this.tripSeaTransport];

            _seaTransport.splice(index, 1);

            _seaTransport.forEach((element, i) => (element.sequence = i));

            this.$emit("update:tripSeaTransport", _seaTransport);
        },
        getInputTypeText(value) {
            let result = null;
            if (value === null || value === undefined || value.length === 0) {
                result = "";
            } else {
                let type = this.type.find(type => type.value === value);
                result = type?.text;
            }

            return result;
        },
        autoFill() {
            this.isLoadingAutoFill = true;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation AutoFillTripSeaTransport(
                            $input: AutoFillTripSeaTransportInput!
                        ) {
                            autoFillTripSeaTransport(input: $input) {
                                response {
                                    containerNumber
                                    ports {
                                        ata
                                        atd
                                        eta
                                        etd
                                        code
                                        location
                                        sequence
                                        type
                                        vessel
                                        voyage
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            containerNumber: this.containerAutofill,
                            tripPartnerCarrier: this.tripPartnerCarrierId
                        }
                    }
                })
                .then(res => {
                    let _tripseatransport = [];
                    res.data.autoFillTripSeaTransport.response.ports.map(
                        (port, i) => {
                            if (port.type === "POL") {
                                _tripseatransport.push({
                                    sequence: i,
                                    voyage: port.voyage,
                                    portCode: port.code,
                                    portTransportType: "POL",
                                    vesselInputType: "shipname",
                                    vesselInputValue: port.vessel,
                                    estimatedDepartureDate: port.etd
                                });
                            } else if (port.type === "T/S") {
                                _tripseatransport.push({
                                    sequence: i,
                                    voyage: port.voyage,
                                    portCode: port.code,
                                    portTransportType: "T/S",
                                    vesselInputType: "shipname",
                                    vesselInputValue: port.vessel,
                                    estimatedDepartureDate: port.etd,
                                    estimatedArrivalDate: port.eta
                                });
                            } else if (port.type === "POD") {
                                _tripseatransport.push({
                                    sequence: i,
                                    portCode: port.code,
                                    portTransportType: "POD",
                                    estimatedArrivalDate: port.eta
                                });
                            }
                        }
                    );
                    this.$emit("update:tripSeaTransport", _tripseatransport);
                })
                .catch(() => {
                    const payload = {
                        color: "error",
                        message: "Couldn't get the container data"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .finally(() => {
                    this.isLoadingAutoFill = false;
                });
        },
        updateVesselInputValueChoices(item, i) {
            let choices = [];
            if (
                item.vesselInputType === null ||
                item.vesselInputType === undefined ||
                item.vesselInputType === ""
            ) {
                choices = [];
            } else if (item.vesselInputType === "shipname") {
                choices = this.vessels.edges.map(vessel => vessel.node.name);
            } else if (item.vesselInputType === "callsign") {
                choices = this.vessels.edges.map(
                    vessel => vessel.node.callsign
                );
            } else if (item.vesselInputType === "imo") {
                choices = this.vessels.edges.map(vessel => vessel.node.imo);
            } else if (item.vesselInputType === "mmsi") {
                choices = this.vessels.edges.map(vessel => vessel.node.mmsi);
            }
            item.vesselInputValue = "";
            this.$set(this.vesselInputValueChoices, i, choices);
        }
    }
};
</script>
