<template>
    <v-dialog max-width="600" v-model="openEditTenantDialog" persistent>
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">edit</v-icon>
                {{ $t("users.edit_tenant") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-2">
                <v-form ref="materialForm">
                    <!--- PARTNER NAME --->
                    <v-row dense class="pa-2 mt-4 mx-6">
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="tenant_name"
                                :label="$t('users.tenant_name')"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTIONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn rounded text @click="closeAndResetInput">Cancel</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isLoadingTenantAdd"
                    @click="editTenant"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "EditTenantDialog",
    props: ["openEditTenantDialog", "tenant"],
    data() {
        return {
            tenant_name: null,
            isLoadingTenantAdd: false
        };
    },
    created() {
        this.tenant_name = this.tenant.name;
    },
    watch: {},
    methods: {
        closeAndResetInput() {
            this.tenant_name = null;
            this.$emit("closeEditTenantDialog");
        },
        editTenant() {
            // Initialize Loading :
            this.isLoadingTenantAdd = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTenant($input: UpdateTenantInput!) {
                            updateTenant(input: $input) {
                                tenant {
                                    id
                                    name
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantId: this.tenant.id,
                            name: this.tenant_name
                        }
                    }
                })
                .then(response => {
                    this.isLoadingTenantAdd = false;
                    this.$emit("updatedTenant", response);
                    this.closeAndResetInput();
                });
        }
    }
};
</script>
