import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"py-5",class:_vm.shouldStickyFilter ? 'sticky-card' : '',attrs:{"elevation":hover ? 5 : 3,"outlined":""}},[_c(VRow,{staticClass:"px-5 d-flex align-center",attrs:{"align":"start"}},[_c(VCol,{attrs:{"cols":"12","sm":"10","lg":"10"}},[_c(VRow,[_vm._l((_vm.dynamicFilters),function(f){return _c(VCol,{key:f,class:_vm.getFilterChoiceClassByCode(f),attrs:{"cols":"auto"}},[(f === 'referenceNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode(
                                        'referenceNumber'
                                    ),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.bookingReferenceIcontains),callback:function ($$v) {_vm.$set(_vm.filter, "bookingReferenceIcontains", $$v)},expression:"filter.bookingReferenceIcontains"}}):_vm._e(),(f === 'exporterReferenceNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('exporterReferenceNumber'),"clearable":"","hide-details":"","dense":""},model:{value:(
                                    _vm.filter.exporterReferenceNumberIcontains
                                ),callback:function ($$v) {_vm.$set(_vm.filter, "exporterReferenceNumberIcontains", $$v)},expression:"\n                                    filter.exporterReferenceNumberIcontains\n                                "}}):_vm._e(),(f === 'containerNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('containerNumber'),"clearable":"","hide-details":"","dense":""},model:{value:(
                                    _vm.filter.containerNumberIcontains
                                ),callback:function ($$v) {_vm.$set(_vm.filter, "containerNumberIcontains", $$v)},expression:"\n                                    filter.containerNumberIcontains\n                                "}}):_vm._e(),(f === 'ctoNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('ctoNumber'),"clearable":"","hide-details":"","dense":""},model:{value:(
                                    _vm.filter.ctoNumberIcontains
                                ),callback:function ($$v) {_vm.$set(_vm.filter, "ctoNumberIcontains", $$v)},expression:"\n                                    filter.ctoNumberIcontains\n                                "}}):_vm._e(),(f === 'trackerNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('trackerNumber'),"clearable":"","hide-details":"","dense":""},model:{value:(
                                    _vm.filter.trackerNumberIcontains
                                ),callback:function ($$v) {_vm.$set(_vm.filter, "trackerNumberIcontains", $$v)},expression:"\n                                    filter.trackerNumberIcontains\n                                "}}):_vm._e(),(f === 'customer')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.customers,"label":_vm.getFilterChoiceNameByCode('customer'),"loading":_vm.$apollo.queries.customers.loading,"item-text":"Name","item-value":"ConsigneeID","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.customers.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.customers.skip = false}},model:{value:(_vm.filter.customerId),callback:function ($$v) {_vm.$set(_vm.filter, "customerId", $$v)},expression:"filter.customerId"}}):_vm._e(),(f === 'exporter')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.exporters,"label":_vm.getFilterChoiceNameByCode('exporter'),"loading":_vm.$apollo.queries.exporters.loading,"item-text":"Description","item-value":"Code","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.exporters.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.exporters.skip = false}},model:{value:(_vm.filter.exporterId),callback:function ($$v) {_vm.$set(_vm.filter, "exporterId", $$v)},expression:"filter.exporterId"}}):_vm._e(),(f === 'receiver')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.receivers,"label":_vm.getFilterChoiceNameByCode('receiver'),"loading":_vm.$apollo.queries.receivers.loading,"item-text":"Name","item-value":"ReceiverID","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.receivers.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.receivers.skip = false}},model:{value:(_vm.filter.receiverId),callback:function ($$v) {_vm.$set(_vm.filter, "receiverId", $$v)},expression:"filter.receiverId"}}):_vm._e(),(f === 'agentCode')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.agentCodes,"label":_vm.getFilterChoiceNameByCode('agentCode'),"loading":_vm.$apollo.queries.agentCodes.loading,"item-text":"AgentDescription","item-value":"AgentCode","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.agentCodes.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.agentCodes.skip = false}},model:{value:(_vm.filter.agentCodeId),callback:function ($$v) {_vm.$set(_vm.filter, "agentCodeId", $$v)},expression:"filter.agentCodeId"}}):_vm._e(),(f === 'voyageNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode(
                                        'voyageNumber'
                                    ),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.voyageNoIcontains),callback:function ($$v) {_vm.$set(_vm.filter, "voyageNoIcontains", $$v)},expression:"filter.voyageNoIcontains"}}):_vm._e(),(f === 'contractNumber')?_c(VTextField,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode(
                                        'contractNumber'
                                    ),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.contractNumberIcontains),callback:function ($$v) {_vm.$set(_vm.filter, "contractNumberIcontains", $$v)},expression:"filter.contractNumberIcontains"}}):_vm._e(),(f === 'finalDestination')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.ports,"label":_vm.getFilterChoiceNameByCode('finalDestination'),"loading":_vm.$apollo.queries.ports.loading,"item-text":"Description","item-value":"Code","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.ports.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.ports.skip = false}},model:{value:(_vm.filter.finalDestinationId),callback:function ($$v) {_vm.$set(_vm.filter, "finalDestinationId", $$v)},expression:"filter.finalDestinationId"}}):_vm._e(),(f === 'pol')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.ports,"label":_vm.getFilterChoiceNameByCode('pol'),"loading":_vm.$apollo.queries.ports.loading,"item-text":"Description","item-value":"Code","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.ports.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.ports.skip = false}},model:{value:(_vm.filter.polId),callback:function ($$v) {_vm.$set(_vm.filter, "polId", $$v)},expression:"filter.polId"}}):_vm._e(),(f === 'pod')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.ports,"label":_vm.getFilterChoiceNameByCode('pod'),"loading":_vm.$apollo.queries.ports.loading,"item-text":"Description","item-value":"Code","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.ports.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.ports.skip = false}},model:{value:(_vm.filter.podId),callback:function ($$v) {_vm.$set(_vm.filter, "podId", $$v)},expression:"filter.podId"}}):_vm._e(),(f === 'status')?_c(VSelect,{staticClass:"maxWidth",attrs:{"items":_vm.statusChoices,"label":_vm.getFilterChoiceNameByCode('status'),"item-text":"text","item-value":"value","hide-details":"","dense":"","clearable":"","menu-props":{
                                    zIndex: 2,
                                    offsetY: true
                                }},model:{value:(_vm.filter.orderStatus),callback:function ($$v) {_vm.$set(_vm.filter, "orderStatus", $$v)},expression:"filter.orderStatus"}}):_vm._e(),(f === 'vessel')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.vessels,"label":_vm.getFilterChoiceNameByCode('vessel'),"loading":_vm.$apollo.queries.vessels.loading,"item-text":"Description","item-value":"Code","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.vessels.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.vessels.skip = false}},model:{value:(_vm.filter.vesselNameId),callback:function ($$v) {_vm.$set(_vm.filter, "vesselNameId", $$v)},expression:"filter.vesselNameId"}}):_vm._e(),(f === 'shippingLine')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.shippingLines,"label":_vm.getFilterChoiceNameByCode('shippingLine'),"loading":_vm.$apollo.queries.shippingLines.loading,"item-text":"Description","item-value":"Code","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.shippingLines.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.shippingLines.skip = false}},model:{value:(_vm.filter.shippingLineId),callback:function ($$v) {_vm.$set(_vm.filter, "shippingLineId", $$v)},expression:"filter.shippingLineId"}}):_vm._e(),(f === 'transportType')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('transportType'),"items":_vm.transportTypeList,"item-text":"text","item-value":"value","flat":"","hide-no-data":"","dense":"","clearable":""},model:{value:(_vm.filter.transportType),callback:function ($$v) {_vm.$set(_vm.filter, "transportType", $$v)},expression:"filter.transportType"}}):_vm._e(),(f === 'orderDate')?_c('DateComponent',{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('orderDate'),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.orderDate),callback:function ($$v) {_vm.orderDate=$$v},expression:"orderDate"}}):_vm._e(),(f === 'loadDate')?_c('DateComponent',{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('loadDate'),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.loadDate),callback:function ($$v) {_vm.loadDate=$$v},expression:"loadDate"}}):_vm._e(),(f === 'commodity')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"label":_vm.getFilterChoiceNameByCode('commodity'),"items":_vm.commodities,"loading":_vm.$apollo.queries.commodities.loading,"item-text":"CommodityDescription","item-value":"CommodityCode","flat":"","hide-no-data":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.commodities.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.commodities.skip = false}},model:{value:(_vm.filter.commodityId),callback:function ($$v) {_vm.$set(_vm.filter, "commodityId", $$v)},expression:"filter.commodityId"}}):_vm._e(),(f === 'stackStatus')?_c(VSelect,{staticClass:"maxWidth",attrs:{"items":_vm.stackStatusList,"label":_vm.getFilterChoiceNameByCode('stackStatus'),"item-text":"text","item-value":"value","hide-details":"","dense":"","clearable":"","menu-props":{
                                    zIndex: 2,
                                    offsetY: true
                                }},model:{value:(_vm.filter.stackStatus),callback:function ($$v) {_vm.$set(_vm.filter, "stackStatus", $$v)},expression:"filter.stackStatus"}}):_vm._e(),(f === 'steri')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"placeholder":"Select Steri","label":_vm.getFilterChoiceNameByCode('steri'),"items":_vm.steriList,"cache-items":"","item-text":"text","item-value":"value","flat":"","hide-no-data":"","dense":"","clearable":""},model:{value:(_vm.filter.steri),callback:function ($$v) {_vm.$set(_vm.filter, "steri", $$v)},expression:"filter.steri"}}):_vm._e(),(f === 'containerType')?_c(VAutocomplete,{staticClass:"maxWidth",attrs:{"items":_vm.containerTypes,"label":_vm.getFilterChoiceNameByCode('containerType'),"loading":_vm.$apollo.queries.containerTypes.loading,"item-text":"Description","item-value":"Code","cache-items":"","flat":"","dense":"","clearable":"","no-data-text":_vm.$apollo.queries.containerTypes.loading
                                    ? 'Loading...'
                                        : 'No Data Available'},on:{"focus":function($event){_vm.$apollo.queries.containerTypes.skip = false}},model:{value:(_vm.filter.containerTypeId),callback:function ($$v) {_vm.$set(_vm.filter, "containerTypeId", $$v)},expression:"filter.containerTypeId"}}):_vm._e(),(f === 'incoTerm')?_c(VSelect,{staticClass:"maxWidth",attrs:{"items":_vm.terms,"label":_vm.getFilterChoiceNameByCode('incoTerm'),"item-text":"text","item-value":"value","hide-details":"","dense":"","clearable":"","menu-props":{
                                    zIndex: 2,
                                    offsetY: true
                                }},model:{value:(_vm.filter.incoTerm),callback:function ($$v) {_vm.$set(_vm.filter, "incoTerm", $$v)},expression:"filter.incoTerm"}}):_vm._e()],1)}),_c(VCol,{staticClass:"col-auto col-md-4 col-lg-3 col-xl-3"},[_c(VRow,{staticClass:"d-flex align-center ma-0 pa-0"},[_c(VSelect,{attrs:{"items":_vm.sortByChoices,"label":_vm.$t('general.sort_by'),"item-value":function (item) { return item.code; },"item-text":function (item) { return item.name; },"clearable":"","hide-details":"","dense":"","color":"primary","menu-props":{
                                        zIndex: 2,
                                        offsetY: true
                                    }},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VBtn,{staticClass:"mt-n1",attrs:{"small":"","icon":"","disabled":!_vm.sortBy},on:{"click":function($event){_vm.sortAsc = !_vm.sortAsc}}},[_c(VIcon,{attrs:{"color":"grey darken-2"}},[_vm._v(_vm._s(_vm.sortAsc ? "north" : "south"))])],1)]},proxy:true}],null,true),model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1)],1)],2)],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VRow,{staticClass:"d-flex align-center justify-end"},[_c(VCol,{staticClass:"d-flex justify-end align-center",attrs:{"cols":"auto"}},[_c('CreateAndEditBooking',{attrs:{"editMode":false,"templateMode":false},on:{"add-booking":_vm.onBookingCreated}})],1),_c(VCol,{staticClass:"px-1",attrs:{"cols":"auto"}},[_c('FilterMenu',{attrs:{"title":"Customize","description":"Select filters to display","choices":_vm._filterChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }