<template>
    <v-dialog max-width="600" v-model="openAddTenantDialog" persistent>
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("users.new_tenant") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pt-6">
                <v-form ref="tenantForm">
                    <v-row>
                        <v-text-field
                            v-model="tenant_name"
                            :label="$t('users.tenant_name') + '*'"
                            :rules="nameRules"
                            outlined
                            class="mx-4 mt-3"
                        ></v-text-field>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTIONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn text rounded @click="closeAndResetInput">Cancel</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isLoadingTenantAdd"
                    @click="createTenant"
                    >{{ $t("general.add") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "AddTenantDialog",
    props: {
        openAddTenantDialog: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tenant_name: null,
            error: false,
            isLoadingTenantAdd: false,
            nameRules: [v => !!v || this.$t("rules.name")]
        };
    },
    watch: {},
    methods: {
        closeAndResetInput() {
            this.tenant_name = null;
            this.error = false;
            this.$refs.tenantForm.resetValidation();
            this.$emit("closeAddTenantDialog");
        },
        createTenant() {
            if (!this.$refs.tenantForm.validate()) return;

            this.isLoadingTenantAdd = true;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation createTenant($input: CreateTenantInput!) {
                            createTenant(input: $input) {
                                tenant {
                                    id
                                    name
                                    organisationPermissions
                                    tenantPartnerSet {
                                        totalCount
                                    }
                                    tenantUserSet {
                                        totalCount
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            name: this.tenant_name
                        }
                    }
                })
                .then(response => {
                    // Emit event to tabel data and load new tenants //
                    this.$emit("refreshTenantsTable");
                    this.$emit("item-created", response.data.createTenant);

                    const payload = {
                        color: "blue",
                        message: this.$t("alert_rules.tenant_saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingTenantAdd = false;
                    /* const payload = {
                        color: "blue",
                        message: "Tenant is save!"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload); */
                })
                .finally(() => {
                    this.isLoadingTenantAdd = false;
                    this.closeAndResetInput();
                });
        }
    }
};
</script>
