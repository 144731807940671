<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs v-model="tab" color="primary" show-arrows ref="tabs" background-color="background">
            <v-tab v-for="t in selectedFilters" :key="t.code" :href="`#tab-${t.code}`" class="text-capitalize">
                {{ t.name }}
            </v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab" :style="`height: ${heightScreen}px;`" class="overflow-y-auto background">
            <!-- GROUP PERMISSIONS TAB -->
            <v-tab-item value="tab-permissions" :transition="false" class="pa-4 background">
                <PreviewGroupPermissionsTab :group="group"></PreviewGroupPermissionsTab>
            </v-tab-item>
            <!-- GROUP USERS TAB -->
            <v-tab-item value="tab-users" :transition="false" class="px-8 py-4 background"
                ><PreviewGroupUsersTab :group="group"></PreviewGroupUsersTab
            ></v-tab-item>
            <!-- GROUP PARTNERS TAB -->
            <v-tab-item value="tab-partners" :transition="false" class="px-8 py-4 background">
                <PreviewGroupPartnersTab :group="group"></PreviewGroupPartnersTab>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import PreviewGroupUsersTab from "@/components/groups/tabs/PreviewGroupUsersTab.vue";
import PreviewGroupPartnersTab from "@/components/groups/tabs/PreviewGroupPartnersTab.vue";
import PreviewGroupPermissionsTab from "@/components/groups/tabs/PreviewGroupPermissionsTab.vue";
export default {
    props: {
        group: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        PreviewGroupUsersTab,
        PreviewGroupPartnersTab,
        PreviewGroupPermissionsTab
    },
    data() {
        return {
            tab: null
        };
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        }
    },
    mounted() {}
};
</script>
