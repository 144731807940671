<template>
    <main>
        <v-card class="py-3 px-3 mx-2 my-2" elevation="5" outlined>
            <v-card-text class="pb-3 pr-3">
                <!-- TABLE -->
                <v-data-table
                    :headers="headerChoices"
                    :items="groups.edges"
                    item-key="node.id"
                    :loading-text="$t('loading.loading1')"
                    :loading="$apollo.queries.groups.loading"
                    hide-default-footer
                    disable-pagination
                    disable-sort
                    loader-height="2"
                    :mobile-breakpoint="
                        screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                    "
                    :no-data-text="$t('loading.no_data')"
                    @click:row="item => this.$emit('click:row', item)"
                >
                    <!-- CHECKBOX COLUMN -->
                    <template v-slot:item.action="{ item }">
                        <v-checkbox
                            multiple
                            v-model="localSelectedGroups"
                            class="mx-0"
                            @click="signalGroups(item, $event)"
                            :value="item.node.id"
                            @click.stop=""
                        ></v-checkbox>
                    </template>

                    <!-- NAME COLUMN -->
                    <template v-slot:item.node.name="{ item }">
                        {{ item.node.name }}
                    </template>
                </v-data-table>
            </v-card-text>
            <!-- LOADING TRIGGER -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.groups.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    {{ $t("loading.load_more") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </main>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "ColdChaGroupsTable",
    props: {
        groupsFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        selectedGroups: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        }
    },
    apollo: {
        groups: {
            query: gql`
                query groups(
                    $last: Int
                    $first: Int
                    $after: String
                    $before: String
                ) {
                    coldchaGroups(
                        last: $last
                        first: $first
                        after: $after
                        before: $before
                    ) {
                        edges {
                            node {
                                id
                                name
                                group {
                                    userSet {
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    ...this.groupsFilter
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.coldchaGroups;
            },
            skip: false
        }
    },
    data() {
        return {
            groups: { edges: [] },
            localSelectedGroups: this.selectedGroups,
            searchInput: ""

            // headerChoices: [
            //     {
            //         text: "",
            //         align: "left",
            //         value: "action",
            //         width: "5px"
            //     },
            //     {
            //         text: this.$t("general.group_name"),
            //         align: "left",
            //         value: "node.name"
            //     },
            //     {
            //         text: this.$t("general.nusers"),
            //         align: "left",
            //         value: "node.group.userSet.totalCount"
            //     }
            // ]
        };
    },
    watch: {
        selectedGroups: function(val) {
            this.localSelectedGroups = [];
            this.localSelectedGroups = val;
        }
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        hasMoreData() {
            return this.groups?.pageInfo?.hasNextPage;
        },
        headerChoices: function() {
            let _headerChoices = [
                {
                    text: "",
                    align: "left",
                    value: "action",
                    width: "5px"
                },
                {
                    text: this.$t("general.group_name"),
                    align: "left",
                    value: "node.name"
                },
                {
                    text: this.$t("general.nusers"),
                    align: "left",
                    value: "node.group.userSet.totalCount"
                }
            ];
            return _headerChoices;
        }
    },
    methods: {
        signalGroups() {
            this.$emit("selectedCheckbox", this.localSelectedGroups);
        },
        loadMore() {
            if (this.groups?.pageInfo?.hasNextPage) {
                this.$apollo.queries.groups.fetchMore({
                    variables: {
                        after: this.groups.pageInfo.endCursor
                    }
                });
            }
        },

        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.groups?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        }
    }
};
</script>
