<template>
    <LoadingBar
        v-if="tripDetails.id == null && $apollo.queries.freight.loading"
    ></LoadingBar>
    <div v-else>
        <v-card
            fill-height
            flat
            width="100%"
            v-if="tripDetails.id != null"
            color="background"
        >
            <!-- top bar -->
            <v-toolbar flat color="transparent">
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
                <v-btn
                    v-if="shouldShowFullScreenButton"
                    icon
                    @click="changeFullScreen"
                >
                    <v-icon>{{
                        isFullScreen ? "fullscreen_exit" : "crop_free"
                    }}</v-icon>
                </v-btn>

                <div class="ml-3">
                    <div class="font-weight-medium d-flex align-center">
                        <v-icon small left color="primary">circle</v-icon>
                        {{ tripDetails.status }}
                    </div>
                </div>
                <v-spacer></v-spacer>

                <TripChangeStatusDialog
                    :openDialog="openDialog"
                    :trip="tripDetails"
                    v-on:closeDialog="openDialog = false"
                    v-on:updateTripStatus="onTripStatusChanged"
                >
                </TripChangeStatusDialog>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            @click="favorite = !favorite"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon
                                :color="favorite ? 'warning' : 'grey lighten-1'"
                            >
                                {{ favorite ? "star" : "star_border" }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t("trips.favorite") }}</span>
                </v-tooltip>

                <!-- TAB FILTERS -->
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display_modules')"
                    :choices.sync="defaultFilterChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                    class="mr-0"
                >
                </FilterMenu>
            </v-toolbar>
            <v-spacer></v-spacer>
            <!-- trip header -->
            <v-sheet
                class="pt-0 mb-5 px-5 background d-flex align-center justify-space-between"
            >
                <h3 class="ml-1 mr-n3">
                    {{ freightDetails ? freightDetails.referenceNumber : "" }}
                </h3>
                <v-spacer></v-spacer>
                <!-- trip status -->
                <v-card
                    flat
                    outlined
                    rounded="xl"
                    class="d-flex align-center px-3 py-0"
                    style="width: 700"
                >
                    <v-row no-gutters class="d-flex align-center">
                        <v-col style="width: 190px">
                            <v-row no-gutters>
                                <v-col
                                    cols="12"
                                    class="d-flex justify-center align-center"
                                >
                                    <!-- <span
                                        class="body-2 text-truncate"
                                        v-if="tripDetails.originLocation"
                                    >
                                        {{
                                            tripDetails.originLocation.city +
                                                ", " +
                                                tripDetails.originLocation
                                                    .country
                                        }}
                                    </span> -->
                                    <span>{{
                                        headerDates.firstDateTitle
                                    }}</span>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="d-flex align-center justify-center"
                                >
                                    <span class="text-no-wrap body-2">{{
                                        headerDates.firstDate
                                    }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="d-flex justify-center align-center mx-3">
                            <div>
                                <v-icon
                                    v-if="tripDetails.transportMode === 'SEA'"
                                    >directions_boat</v-icon
                                >
                                <v-icon
                                    v-if="tripDetails.transportMode === 'AIR'"
                                    >flight</v-icon
                                >
                                <v-icon
                                    v-if="tripDetails.transportMode === 'ROAD'"
                                    >directions_bus</v-icon
                                >
                                <v-icon
                                    v-if="tripDetails.transportMode === 'OTHER'"
                                    >location_on</v-icon
                                >
                                <v-icon
                                    v-if="tripDetails.transportMode === 'RAIL'"
                                    >directions_railway</v-icon
                                >
                            </div>
                        </v-col>
                        <v-col style="width: 190px">
                            <v-row no-gutters>
                                <v-col
                                    cols="12"
                                    class="d-flex align-center justify-center"
                                >
                                    <!-- <span
                                        class="body-2 text-truncate"
                                        v-if="tripDetails.destinationLocation"
                                        >{{
                                            tripDetails.destinationLocation
                                                .city +
                                                ", " +
                                                tripDetails.destinationLocation
                                                    .country
                                        }}</span
                                    > -->
                                    <span>{{
                                        headerDates.secondDateTitle
                                    }}</span>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="d-flex align-center justify-center"
                                >
                                    <span class="text-no-wrap body-2">{{
                                        headerDates.secondDate
                                    }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
                <v-spacer></v-spacer>
                <!-- origin & destination -->
                <v-select
                    v-model="viewType"
                    :items="viewTypeChoices"
                    dense
                    item-text="name"
                    item-value="code"
                    return-value
                    outlined
                    hide-details
                    class="fit"
                ></v-select>
            </v-sheet>

            <div v-if="tripDetails.id != null" class="mb-15">
                <!-- tab view -->
                <FreightDetailTabView
                    v-if="viewType === 'tab'"
                    :freight="freightDetails"
                    :selectedFilters="selectedFilters"
                    :organisationPermissions.sync="organisationPermissions"
                />
                <!-- list view -->
                <FreightDetailListView
                    v-if="viewType === 'list'"
                    class="mx-5 pb-10"
                    :freight="freightDetails"
                    :selectedFilters="selectedFilters"
                    :organisationPermissions.sync="organisationPermissions"
                />
            </div>

            <!-- show loading -->
            <v-progress-linear
                v-else
                dense
                indeterminate
                color="primary"
                class="mb-0"
                :height="3"
            ></v-progress-linear>

            <v-dialog v-model="editTripDialog" max-width="1000px">
                <v-card class="pb-1">
                    <v-card-title class="primary white--text">
                        {{ $t("trips.edit_trip") }}
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text class="pt-2 pb-0">
                        <v-row dense>
                            <v-col cols="6">
                                <TripForm
                                    ref="tripForm"
                                    v-model="validateTripDialog"
                                    :getTrip.sync="getTrip"
                                    :editMode="editedMode"
                                    :isOpen="editTripDialog"
                                >
                                </TripForm>
                            </v-col>
                            <v-col cols="6">
                                <TripLocationForm
                                    v-model="tripLocation"
                                    :edit="true"
                                    :validate.sync="validateTripLocation"
                                >
                                </TripLocationForm>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text rounded @click="editTripDialog = false"
                            >{{ $t("general.cancel") }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            rounded
                            :loading="ldBtn"
                            @click="updateTrip"
                            :disabled="
                                !(validateTripDialog && validateTripLocation)
                            "
                        >
                            {{ $t("general.save") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog max-width="400" v-model="privateDialog">
                <v-card>
                    <v-card-title class="primary py-3 mb-2">
                        <div class="mr-2 mt-n1">
                            <v-icon color="white" v-if="tripDetails.isPrivate"
                                >visibility</v-icon
                            >
                            <v-icon color="white" v-else>visibility_off</v-icon>
                        </div>
                        <span
                            ><h5 class="white--text">
                                {{
                                    tripDetails.isPrivate
                                        ? "Show Trip Information"
                                        : "Hide Trip Information"
                                }}
                            </h5></span
                        >
                    </v-card-title>
                    <v-card-text class="mb-1">
                        <div>
                            <span v-if="tripDetails.isPrivate">
                                <h4>
                                    {{ $t("trips.sure_show") }}
                                </h4>
                            </span>
                            <span v-else>
                                <h4>
                                    {{ $t("trips.sure_hide") }}
                                </h4>
                            </span>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="privateDialog = false"
                            width="80px"
                            text
                            rounded
                            small
                            >{{ $t("general.no") }}</v-btn
                        >
                        <v-btn
                            width="80px"
                            color="primary"
                            rounded
                            small
                            @click="updatePrivate"
                            :loading="privateBtn"
                            >{{ $t("general.yes") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
        <!-- else show a v-card displaying that didnt find what looking for -->
        <v-card fill-height flat width="100%" v-else color="background">
            <v-toolbar flat color="transparent">
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
            </v-toolbar>
            <NotFoundDetailDrawer type="trip" style="padding-top: 100px;">
            </NotFoundDetailDrawer>
        </v-card>
    </div>
</template>

<script>
import LoadingBar from "@/components/base/LoadingBar.vue";
import NotFoundDetailDrawer from "../base/NotFoundDetailDrawer.vue";
import FilterMenu from "@/components/base/FilterMenu.vue";
import FreightDetailTabView from "@/components/freights/FreightDetailTabView.vue";
import FreightDetailListView from "@/components/freights/FreightDetailListView.vue";
import TripForm from "@/components/trips/forms/TripForm.vue";
import TripLocationForm from "@/components/trips/forms/TripLocationForm.vue";
import helper from "../../utils/helper";
import gql from "graphql-tag";
//import moment from "moment";
import TripChangeStatusDialog from "@/components/trips/update/TripChangeStatusDialog.vue";
import { TripPermissions } from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    props: {
        freightProp: {
            type: Object
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        freightId: {
            type: String
        }
    },
    components: {
        FilterMenu,
        FreightDetailTabView,
        FreightDetailListView,
        TripChangeStatusDialog,
        TripForm,
        TripLocationForm,
        LoadingBar,
        NotFoundDetailDrawer
    },
    apollo: {
        freight: {
            query: gql`
                query freight($freightId: ID!) {
                    tripFreight(id: $freightId) {
                        id
                        referenceNumber
                        containerNumber
                        airWaybillNumber
                        billOfLadingNumber
                        goods
                        isUserFavorite
                        tripFreightTrackerSet {
                            edges {
                                node {
                                    id
                                    tracker {
                                        id
                                        serialNumber
                                    }
                                }
                            }
                        }
                        trip {
                            id
                            createdAt
                            tenantCarrier {
                                id
                                name
                            }
                            originLocation {
                                id
                                name
                                city
                                country
                            }
                            destinationLocation {
                                id
                                name
                                city
                                country
                            }
                            tenant {
                                id
                                organisationPermissions
                            }
                            tripFreightSet {
                                edges {
                                    node {
                                        id
                                        referenceNumber
                                        containerNumber
                                        airWaybillNumber
                                        billOfLadingNumber
                                        goods
                                        tripFreightTrackerSet {
                                            edges {
                                                node {
                                                    id
                                                    tracker {
                                                        id
                                                        serialNumber
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            referenceNumber
                            transportMode
                            plannedDepartureDate
                            plannedArrivalDate
                            status
                            isArchived
                            isPrivate
                            numberOfFreights
                            numberOfPartners
                            numberOfDocuments
                            numberOfComments
                            numberOfUnresolvedAlerts
                            tripPartnerSet {
                                edges {
                                    node {
                                        id
                                        tenantPartner {
                                            id
                                            name
                                            partnerType
                                        }
                                    }
                                }
                            }
                            tripSeaTransportSet {
                                edges {
                                    node {
                                        id
                                        sequence
                                        portCode
                                        portTransportType
                                        portName
                                        vessel {
                                            id
                                            name
                                        }
                                        actualDepartureDate
                                        actualArrivalDate
                                        estimatedDepartureDate
                                        estimatedArrivalDate
                                        originalEstimatedDepartureDate
                                        originalEstimatedArrivalDate
                                    }
                                }
                            }
                            isUserFavorite
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return { freightId: this.freightProp?.id || this.freightId };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripFreight,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading) {
                    this.getHeaderDates(data.tripFreight?.trip);
                }
            }
        }
    },
    data() {
        return {
            validateTripLocation: false,
            editedMode: false,
            editTripDialog: false,
            validateTripDialog: false,
            privateDialog: false,
            ldBtn: false,
            privateBtn: false,
            private: false,
            getTrip: {},
            tripLocation: {},
            viewType: "tab",
            viewTypeChoices: [
                { code: "tab", name: this.$t("general.tabs") },
                { code: "list", name: this.$t("general.list") }
            ],
            openDialog: false,
            headerDates: {
                firstDateTitle: this.$t("trips.planned_departure"),
                firstDate: "-",
                secondDateTitle: this.$t("trips.planned_arrival"),
                secondDate: "-"
            }
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
    },
    computed: {
        routeTab() {
            return this.$route.query.tab;
        },
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];
            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        canEdit() {
            return this.hasPermission(TripPermissions.EDIT_TRIP);
        },
        freightDetails() {
            let details = { ...this.freightProp, ...this.freight };
            let _freight = JSON.parse(JSON.stringify(details));
            return _freight || {};
        },
        tripDetails() {
            return this.freightDetails?.trip || {};
        },
        organisationPermissions() {
            let tripPartnerSet = this.freightDetails?.trip?.tripPartnerSet
                ?.edges;
            if (!tripPartnerSet || tripPartnerSet.length === 0) return [];

            let permissions = tripPartnerSet.filter(
                c => c.node.tenantPartner?.tenantPartnerUserSet?.totalCount > 0
            );

            if (permissions && permissions.length === 1) {
                return JSON.parse(
                    permissions[0]?.node?.tenantPartner?.organisationPermissions
                );
            }

            return [];
        },
        isTripCompleted() {
            return this.tripDetails.status === "COMPLETED" || false;
        },
        favorite: {
            get() {
                return this.freightDetails.isUserFavorite || false;
            },
            set(value) {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation UpdateFavoriteFreight(
                                $input: UpdateFavoriteFreightInput!
                            ) {
                                updateFavoriteFreight(input: $input) {
                                    freight {
                                        id
                                        isUserFavorite
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                favorite: value,
                                tripFreightId: this.freightDetails.id
                            }
                        }
                    })
                    .then(({ data }) => {
                        this.$emit("update:freightProp", {
                            ...this.freightProp,
                            ...data.updateFavoriteFreight.freight
                        });
                    });
            }
        },
        defaultFilterChoices() {
            let _choices = [];
            let _default = [
                {
                    // for filter menu
                    code: "summary",
                    name: "Summary",
                    tag: "headers.summary",
                    enabled: true
                },
                // {
                // for filter menu
                //     code: "trackerscharts",
                //     name: "Trackers",
                //     enabled: true
                // },
                {
                    // for filter menu
                    code: "map",
                    name: "Map",
                    tag: "headers.map",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "partners",
                    name: "Partners",
                    tag: "headers.partners",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "seaTransport",
                    name: "Sea Transport",
                    tag: "alert_rules.sea_transport",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "carrierEvents",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "documents",
                    name: "Documents",
                    tag: "headers.documents",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "alerts",
                    name: "Alerts",
                    tag: "headers.alerts",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "timeline",
                    name: "Timeline",
                    tag: "headers.timeline",
                    enabled: true
                }
                // {
                //     // for filter menu
                //     code: "comments",
                //     name: "Comments",
                //     enabled: true
                // },
                // {
                //     // for filter menu
                //     code: "external_shares",
                //     name: "External Shares",
                //     enabled: false
                // }
            ];

            _default.forEach(item => {
                if (this.tripDetails.transportMode === "SEA") {
                    _choices.push(item);
                } else {
                    if (item.code !== "seaTransport") {
                        _choices.push(item);
                    }
                }
            });

            return _choices;
        },
        filterChoices: {
            get() {
                let _filterChoices = helper.getFilterChoices(
                    this.defaultFilterChoices,
                    "tripDetailFilterChoices"
                );
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    tripDetailFilterChoices: value
                });
            }
        }
    },
    watch: {},
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.viewType = helper.getViewType("tripDetailViewType");
    },
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    tripDetailViewType: value
                });
            },
            { deep: true }
        );
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'freight_detail_svw',
        });
        this.setUserProperties();
    },
    methods: {
          setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("freight_detail_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("freight_detail_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        getHeaderDates(trip) {
            if (trip.plannedDepartureDate)
                this.headerDates.firstDate = this.formatDateTime(
                    trip.plannedDepartureDate
                );

            if (trip.plannedArrivalDate)
                this.headerDates.secondDate = this.formatDateTime(
                    trip.plannedArrivalDate
                );

            if (trip?.transportMode == "SEA") {
                let tripSeaTransportSet = [...trip?.tripSeaTransportSet?.edges];

                tripSeaTransportSet.sort(
                    (a, b) => a.node.sequence - b.node.sequence
                );
                tripSeaTransportSet?.forEach(edge => {
                    if (edge.node.portTransportType === "POL") {
                        if (edge.node.estimatedDepartureDate) {
                            this.headerDates.firstDateTitle = this.$t(
                                "trips.estimated_departure"
                            );
                            this.headerDates.firstDate = this.formatDateTime(
                                edge.node.estimatedDepartureDate
                            );
                        }
                        if (edge.node.actualDepartureDate) {
                            this.headerDates.firstDateTitle = this.$t(
                                "trips.actual_departure"
                            );
                            this.headerDates.firstDate = this.formatDateTime(
                                edge.node.actualDepartureDate
                            );
                        }
                    }
                    if (edge.node.portTransportType === "POD") {
                        if (edge.node.estimatedArrivalDate) {
                            this.headerDates.secondDateTitle = this.$t(
                                "trips.estimated_arrival"
                            );
                            this.headerDates.secondDate = this.formatDateTime(
                                edge.node.estimatedArrivalDate
                            );
                        }
                        if (edge.node.actualArrivalDate) {
                            this.headerDates.secondDateTitle = this.$t(
                                "trips.actual_arrival"
                            );
                            this.headerDates.secondDate = this.formatDateTime(
                                edge.node.actualArrivalDate
                            );
                        }
                    }
                });
            } else if (trip?.transportMode == "AIR") {
                // TODO
            } else if (trip?.transportMode == "RAIL") {
                // TODO
            } else if (trip?.transportMode == "ROAD") {
                // TODO
            } else if (trip?.transportMode == "OTHER") {
                // TODO
            }
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        onTripStatusChanged(trip) {
            let _trip = { ...this.freightProp, ...trip };
            this.$emit("update:freightProp", _trip);
        },
        editTripDetails() {
            let _trip = this.freightDetails;
            this.editTripDialog = true;
            this.editedMode = true;

            this.getTrip = {
                id: _trip.id,
                referenceNumber: _trip.referenceNumber,
                transportMode: _trip.transportMode.toLowerCase(),
                shipmentFileNumber: _trip.shipmentFileNumber,
                carrier: _trip.tenantCarrier ? _trip.tenantCarrier.id : null
            };

            this.tripLocation = {
                plannedArrivalDate: _trip.plannedArrivalDate
                    ? this.getDate(_trip.plannedArrivalDate)
                    : null,
                plannedArrivalTime: _trip.plannedArrivalDate
                    ? this.getTime(_trip.plannedArrivalDate)
                    : null,
                plannedDepartureDate: _trip.plannedDepartureDate
                    ? this.getDate(_trip.plannedDepartureDate)
                    : null,
                plannedDepartureTime: _trip.plannedDepartureDate
                    ? this.getTime(_trip.plannedDepartureDate)
                    : null,
                origin: _trip.originLocation ? _trip.originLocation.id : null,
                destination: _trip.destinationLocation
                    ? _trip.destinationLocation.id
                    : null
            };
        },
        updateTrip() {
            this.ldBtn = true;

            let payload = {
                freightId: this.getTrip.id,
                referenceNumber: this.getTrip.referenceNumber
                    ? this.getTrip.referenceNumber
                    : null,
                transportMode: this.getTrip.transportMode
                    ? this.getTrip.transportMode
                    : null,
                shipmentFileNumber: this.getTrip.shipmentFileNumber
                    ? this.getTrip.shipmentFileNumber
                    : null,
                carrier: this.getTrip.carrier ? this.getTrip.carrier : null,
                originLocationId: this.tripLocation.origin
                    ? this.tripLocation.origin
                    : null,
                destinationLocationId: this.tripLocation.destination
                    ? this.tripLocation.destination
                    : null,
                plannedDepartureDate: this.tripLocation.plannedDepartureDate
                    ? helper.concatDateAndTimeAndConvertToUTC(
                          this.tripLocation.plannedDepartureDate,
                          this.tripLocation.plannedDepartureTime
                      )
                    : null,
                plannedArrivalDate: this.tripLocation.plannedArrivalDate
                    ? helper.concatDateAndTimeAndConvertToUTC(
                          this.tripLocation.plannedArrivalDate,
                          this.tripLocation.plannedArrivalTime
                      )
                    : null
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTrip($input: UpdateTripInput!) {
                            updateTrip(input: $input) {
                                trip {
                                    id
                                    referenceNumber
                                    transportMode
                                    shipmentFileNumber
                                    plannedDepartureDate
                                    plannedArrivalDate
                                    tenantCarrier {
                                        id
                                        name
                                    }
                                    originLocation {
                                        id
                                        name
                                        city
                                        state
                                        country
                                        fullAddress
                                    }
                                    destinationLocation {
                                        id
                                        name
                                        city
                                        state
                                        country
                                        fullAddress
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(response => {
                    let _trip = {
                        ...this.trip,
                        ...response.data.updateTrip.trip
                    };
                    this.$emit("update:freightProp", _trip);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.editTripDialog = false;
                    this.ldBtn = false;
                });
        },
        updatePrivate() {
            let payload = {
                freightId: this.tripDetails.id,
                isPrivate: this.tripDetails.isPrivate ? false : true
            };
            this.privateBtn = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdatePrivateTrip(
                            $input: UpdatePrivateTripInput!
                        ) {
                            updatePrivateTrip(input: $input) {
                                trip {
                                    id
                                    isPrivate
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(response => {
                    let _trip = {
                        ...this.trip,
                        ...response.data.updatePrivateTrip.trip
                    };
                    this.$emit("update:freightProp", _trip);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.privateDialog = false;
                    this.privateBtn = false;
                });
        },
        getTime(date) {
            return this.formatDateTime(date)?.slice(11, 16);
        },
        getDate(date) {
            return date?.slice(0, 10);
        }
    }
};
</script>
<style scoped>
.v-select.fit {
  max-width: min-content;
  padding: 10px;
}
.v-select.fit  .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}</style>
