<template>
    <v-dialog max-width="600" v-model="openAddExternalEmailDialog" persistent>
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("users.add_email") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-6">
                <v-form ref="addExternalEmailForm">
                    <v-row>
                        <v-text-field
                            v-model="email"
                            :label="$t('headers.email_address') + '*'"
                            :rules="[rules.required, rules.invalidEmail]"
                            outlined
                            class="mx-4 mt-3"
                        ></v-text-field>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTIONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.close")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    outlined
                    :loading="isLoadingAddExternalEmail"
                    @click="addExternalEmail"
                    >{{ $t("general.add") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "AddExternalEmail",
    props: ["openAddExternalEmailDialog", "emails", "actionGroup"],
    data() {
        return {
            error: false,
            isLoadingAddExternalEmail: false,
            /***********************************/
            /* --------- FORM DATA --------- */
            /*********************************/
            email: null,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                invalidEmail: v => /.+@.+\..+/.test(v) || this.$t("rules.email")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {},
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.email = null;
            this.error = false;
            this.$refs.addExternalEmailForm.resetValidation();
            this.$emit("closeAddExternalEmailDialog");
        },
        /* ---> TENANT PARTNER MUTATION <--- */
        addExternalEmail() {
            let _emails = [];
            // validate form input :
            if (!this.$refs.addExternalEmailForm.validate()) return;
            // Add new email to emails list :
            if (!this.emails.includes(this.email))
                _emails = [...this.emails, this.email];
            // Initialize Loading :
            this.isLoadingAddExternalEmail = true;
            //Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTenantActionGroupExternalEmails(
                            $input: UpdateTenantActionGroupExternalEmailsInput!
                        ) {
                            updateTenantActionGroupExternalEmails(
                                input: $input
                            ) {
                                tenantActionGroup {
                                    id
                                    externalEmails
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.actionGroup.id,
                            externalEmails: _emails
                        }
                    }
                })
                .then(data => {
                    let _email = data.data.updateTenantActionGroupExternalEmails
                        .tenantActionGroup.externalEmails
                        ? data.data.updateTenantActionGroupExternalEmails.tenantActionGroup.externalEmails.replace(
                              /'/g,
                              '"'
                          )
                        : [];
                    if (_email.length == 0) this.emails = [];
                    else {
                        _email = JSON.parse(_email);
                    }
                    this.$emit("updateExternalEmails", _email);
                })
                .catch(error => {
                    console.log(error);
                    this.isLoadingAddExternalEmail = false;
                })
                .finally(() => {
                    this.isLoadingAddExternalEmail = false;
                    this.closeAndResetInput();
                    this.$emit("refreshDataTable");
                });
        }
    }
};
</script>
