<template>
    <v-dialog
        max-width="750"
        v-model="openAddTrackerColumnsSelectorDialog"
        persistent
        scrollable
    >
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">edit</v-icon>
                {{ $t("trackers.excel") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-6 text-center">
                <v-form ref="form" v-model="formIsValid">
                    <v-row>
                        <v-col cols="6" md="6" sm="12" class="py-0">
                            <div class="pb-6 textBody labelContent">
                                <span>
                                    {{ $t("headers.brand")  }}        
                                </span>
                            </div>
                            <v-autocomplete 
                                clearable
                                hide-selected
                                auto-select-first
                                dense
                                outlined
                                :items="brandsChoices"
                                v-model="brandId"
                                :rules="[rules.required]"
                                attach
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" md="6" sm="12" class="py-0" v-if="isStaff">
                            <div class="pb-6 textBody labelContent">
                                <span>
                                    {{ $t("trackers.tenant")  }}        
                                </span>
                            </div>
                            <v-autocomplete
                                v-model="tenantId"
                                :items="tenants ? tenants.edges : []"
                                :no-data-text="$t('loading.no_available')"
                                item-text="node.name"
                                item-value="node.id"
                                outlined
                                dense
                                :rules="[rules.required]"
                            ></v-autocomplete>                           
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" class="align-left">
                            <h3 class="text-h4">
                                {{ $t("trackers.select_columns") }}
                            </h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="6" sm="12" class="py-0" v-for="(item, i) in defaultHeaders" :key="i">
                            <div class="pb-6 textBody labelContent">
                                <span>
                                    {{ item.text }}        
                                </span>
                            </div>
                            <v-autocomplete
                                v-model="finalHeaders[item.value]"
                                outlined
                                dense
                                clearable
                                :items="headers"
                                :no-data-text="$t('loading.no_available')"
                                :rules="item.value == 'serialNumber' ? [rules.required] : []"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn color="primary" 
                    @click="updateTrackers" 
                    :loading="isLoadingFile"
                    :disabled="!formIsValid"
                >
                    {{ $t("general.save")}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import helper from "@/utils/helper.js";

export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
        defaultHeaders: {
            type: Array,
            required: true
        },
        brandsChoices:{
            type: Array,
            required: true
        },
        tenants:{
            type: Array,
            required: false
        },
        openAddTrackerColumnsSelectorDialog: {
            type: Boolean,
            required: true
        },
        trackersToAdd: {
            type: Array,
            required: true
        },
        isLoadingFile: {
            type: Boolean,
            required: true
        },
        brand: {
            type: String,
            required: true
        },
        tenant: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // brand: null,
            // tenant: null,
            finalHeaders: [],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t('rules.required')
            },
            formIsValid: false
        };
    },
    computed:{
        me() {
            return helper.me();
        },
        isStaff() {
            return this.me?.isStaff || false;
        },
        tenantId: {
            get() {
                return this.tenant;
            },
            set(val) {
                this.$emit("update:tenant", val);
            }
        },
        brandId: {
            get() {
                return this.brand;
            },
            set(val) {
                this.$emit("update:brand", val);
            }
        }
    },

    methods: {
        closeAndResetInput() {
            this.finalHeaders = [];
            this.$emit("closeAddTrackerColumnsSelectorDialog");
            //reset validation
            this.$refs.form.resetValidation();
        },
        updateTrackers() {
            if (!this.$refs.form.validate()) {
                return;
            }
            
            let _finalTrackers = [];

            this.trackersToAdd.forEach(tracker => {
                let _tracker = {};
             
                for (let key in this.finalHeaders) {
                    _tracker[key] = tracker[this.finalHeaders[key]];

                    if ( key == "expiryDate" && tracker[this.finalHeaders[key]]) {
                        //check if date is in excel format
                        if (tracker[this.finalHeaders[key]] > 25569 && tracker[this.finalHeaders[key]] < 2958466) {
                            _tracker["expiryDate"] = (tracker[this.finalHeaders[key]] - 25569) * 86400 * 1000;
                        }
                    }
                }
                _finalTrackers.push(_tracker);
            });
            
            //update trackers
            this.$emit("updateTrackers", _finalTrackers);
            this.finalHeaders = [];
            this.$refs.form.resetValidation();
        },
        similarHeaders(defaultHeader, header) {
            const normalizedDefaultHeader = defaultHeader
                .trim()
                .toLowerCase()
                .replace(/\s/g, "");
            const normalizedHeader = header
                .toLowerCase()
                .trim()
                .replace(/\s/g, "");
            return normalizedDefaultHeader === normalizedHeader;
        }
    },
    watch: {
        openAddTrackerColumnsSelectorDialog: {
            handler(newVal) {
                if (newVal) {
                    for (let header of this.headers) {
                        for (let defaultHeader of this.defaultHeaders) {
                            if (
                                this.similarHeaders(defaultHeader.text, header)
                            ) {
                                this.finalHeaders[defaultHeader.value] = header;
                            }
                        }
                    }
                }
            }
        },
        // brand: {
        //     handler(newVal) {
        //         this.$emit("update:brand", newVal);
        //     }
        // },
        // tenant: {
        //     handler(newVal) {
        //         this.$emit("update:tenant", newVal);
        //     }
        // }
    }
};
</script>
<style scoped>
    .textBody {
        font-size: 12px;
        font-weight: 400;
        color: "#868599" !important;
    }
</style>

