import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"pa-0",attrs:{"height":"350px"}},[_c('div',[_c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"flat":"","outlined":"","loading":_vm.$apollo.queries.trackerLocations.loading}},[(_vm.$apollo.queries.trackerLocations.loading)?_c(VProgressLinear,{staticClass:"mb-0",staticStyle:{"z-index":"800"},attrs:{"dense":"","indeterminate":"","color":"primary","height":3}}):_vm._e(),(_vm.noDataTracker && !_vm.$apollo.queries.trackerLocations.loading)?_c(VOverlay,{staticStyle:{"z-index":"800"},attrs:{"absolute":"","opacity":""}},[_c(VAlert,{attrs:{"dense":"","color":"grey","type":"warning"}},[_vm._v(_vm._s(_vm.$t("loading.no_trip")))])],1):_vm._e(),_c('div',{style:({
                    height: 350 + 'px'
                }),attrs:{"id":"alertMap"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }