<template>
    <v-card fill-height flat width="100%" v-if="location" color="background">
        <!-- top bar -->
        <v-toolbar flat color="transparent">
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn v-if="shouldShowFullScreenButton" icon @click="changeFullScreen">
                <v-icon>{{ isFullScreen ? "fullscreen_exit" : "crop_free" }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div v-if="title !== $t('partners.public_locations')">
                <!--Button to edit location-->
                <v-btn icon @click="editLocation" :disabled="disabledButton">
                    <v-icon>edit</v-icon>
                </v-btn>
                <!--Button to delete location-->
                <v-btn icon @click="openDeleteDialog = true">
                    <v-icon>delete</v-icon>
                </v-btn>
                <DeleteLocationDialog
                    :openDeleteDialog="openDeleteDialog"
                    :locationID="location.id"
                    v-on:refreshDataTable="$emit('refreshDataTable')"
                    v-on:closeDeleteDialog="openDeleteDialog = false"
                    v-on:locationDeleted="$emit('onLocationDeleted')"
                >
                    <template #title>{{ $t("partners.delete_location") }}</template>
                    <template #content>{{ $t("partners.sure_location") }}</template>
                </DeleteLocationDialog>
                <v-btn icon>
                    <v-icon> share </v-icon>
                </v-btn>
            </div>
        </v-toolbar>

        <!-- trip header -->
        <v-sheet
            class="
                pt-0 mb-5 px-5
                background
            "
        >
            <div class="textBody">
                {{ $t("headers.name") }}
            </div>
            <h3>{{ location ? location.name : "" }}</h3>
        </v-sheet>

        <!-- tab view -->
        <PreviewDetailTabView
            ref="tabView"
            v-if="viewType === 'tab'"
            :location="location"
            :selectedFilters="selectedFilters"
            :title="title"
            @updateLocationsTable="updateLocationsTable"
        />
    </v-card>
</template>

<script>
import PreviewDetailTabView from "@/components/locations/PreviewDetailTabView.vue";
import helper from "../../utils/helper";
import DeleteLocationDialog from "@/components/locations/delete/DeleteLocationDialog.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        location: {
            type: Object,
            required: true
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            default: "locations"
        }
    },
    components: {
        // FilterMenu,
        PreviewDetailTabView,
        DeleteLocationDialog
    },
    data() {
        return {
            viewType: "tab",
            viewTypeChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            openDeleteDialog: false,
            enableEditing: false,
            center: {
                lat: 40.73061,
                lng: -73.935242
            },

            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "map",
                    name: "Details",
                    tag: "headers.details",
                    enabled: true
                }
            ],

            filterChoices: []
        };
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];
            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        disabledButton() {
            if (this.title === "myLocations") {
                return false;
            } else if (this.title === "partnerLocations") {
                return false;
            } else {
                return true;
            }
        }
    },
    watch: {},
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    locationDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    locationDetailFilterChoices: value
                });
            },
            { deep: true }
        );
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "location_details_svw"
        });
        this.setUserProperties();
    },
    created() {
        this.viewType = helper.getViewType("locationDetailViewType");
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "locationDetailFilterChoices");
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("location_details_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("location_details_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        updateLocationsTable() {
            this.$emit("refreshDataTable");
            
        },
        editLocation() {
            this.$store.dispatch("location/updateCreatedLocation", {
                type: "update",
                location: this.location
            });
            if (this.title === "myLocations") {
                this.$router.push({
                    path: "/createlocation",
                    query: {
                        type: "editLocation"
                    }
                });
            } else if (this.title === "partnerLocations") {
                this.$router.push({
                    path: "/createlocation",
                    query: {
                        type: "editPartnerLocation"
                    }
                });
            }
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        }
    }
};
</script>
<style scoped>
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textBody {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: "#868599" !important;
}
</style>
