<template>
    <v-sheet class="mx-auto background" max-width="100%" min-height="100%">
        <v-row dense class="pl-5 pr-3" align="stretch">
            <v-col cols="12" class="pt-4">
                <TripRoute
                    :tripObj="trip"
                    :tripIsLoading.sync="tripIsLoading"
                    :isSharedTrip="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    v-on:refresh-trip="$emit('refresh-trip')"
                    :tripTimeRange.sync="tripTimeRange"
                ></TripRoute>
                <!-- trip route -->
            </v-col>
            <v-col cols="12 ">
                <!-- freight info  -->
                <TripFreights
                    :trip="trip"
                    :tripIsLoading.sync="tripIsLoading"
                    :isSharedTrip="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    v-on:refresh-trip="$emit('refresh-trip')"
                ></TripFreights>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <!-- partners -->
                <TripPartnersNew
                    :trip="trip"
                    :tripIsLoading.sync="tripIsLoading"
                    :isSharedTrip="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    v-on:refresh-trip="$emit('refresh-trip')"
                ></TripPartnersNew>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <!-- Map  -->
                <TripMap
                    :trip="trip"
                    :tripIsLoading.sync="tripIsLoading"
                    :isSharedTrip="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    @fullScreen="changeFullScreen"
                    v-on:refresh-trip="$emit('refresh-trip')"
                ></TripMap>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <!-- documents -->
                <TripDocuments
                    :trip="trip"
                    :tripIsLoading.sync="tripIsLoading"
                    :isSharedTrip="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    v-on:refresh-trip="$emit('refresh-trip')"
                ></TripDocuments>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <!-- reports -->
                <TripReports
                    :trip="trip"
                    :tripIsLoading.sync="tripIsLoading"
                    :isSharedTrip="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    v-on:refresh-trip="$emit('refresh-trip')"
                ></TripReports>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import helper from "@/utils/helper.js";

import { TripPermissions } from "@/core/enum";
import Config from "@/utils/config.json";
import TripRoute from "@/components/trips/arrangement/TripRoute.vue";
import TripFreights from "@/components/trips/arrangement/TripFreightsNew.vue";
import TripPartnersNew from "@/components/trips/arrangement/TripPartnersNew.vue";
import TripReports from "@/components/trips/arrangement/TripReports.vue";
import TripDocuments from "@/components/trips/arrangement/TripDocuments.vue";
import TripMap from "@/components/trips/arrangement/TripMap.vue";
//import LoadingBar from "@/components/base/LoadingBar.vue";
//import FreightsForm from "@/components/trips/forms/FreightsForm.vue";
//import NoPermission from "@/components/base/NoPermission.vue";
//import NoData from "@/components/base/NoData.vue";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        tripIsLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        freightProp: {
            type: Object,
            required: false,
            default: function() {
                return null;
            }
        },
        tripTimeRange: {
            type: Array,
            required: false,
            default: function() {
                return [];
            }
        }
    },
    components: {
        TripRoute,
        TripFreights,
        TripPartnersNew,
        TripMap,
        TripReports,
        TripDocuments
        //LoadingBar,
        //FreightsForm,
        //NoPermission,
        //NoData
    },
    data() {
        return {
            panel: [0, 1],
            panelFreight: [],
            tripFreights: {},
            createDialog: false,
            deleteDialog: false,
            item: {},
            deletedItem: {},
            validateForm: false,
            toggleTitle: false,
            loadingDeleteBtn: false,
            loadingCreateEditBtn: false,
            carousel: 0,
            tripFreight: { edges: [] },
            currentTrackers: [],
            FreightsFormCardHeight: "auto"
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.relativeTime = helper.relativeTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.unitPerBrand = helper.unitPerBrand;
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (to.query.freight) {
                    if (this.tripFreight.edges.length === 0) return;
                    let index = this.tripFreight.edges.findIndex(x => x.node.id === to.query.freight);

                    let temp = this.tripFreight.edges[index];
                    let newEdges = [...this.tripFreight.edges];
                    newEdges.splice(index, 1);
                    newEdges.unshift(temp);
                    this.tripFreight = { edges: newEdges };

                    this.panelFreight = [0];
                }
            }
        },
        // organisationPermissions: {
        //     deep: true,
        //     handler(val) {
        //         console.log("organisationPermissions", val);
        //     }
        // }
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_summary_tab_svw"
        });
        //this.setUserProperties();
    },
    methods: {
        changeFullScreen(val) {
            this.$emit("fullScreen", val);
        }
    }
};
</script>

<style scope>
.v-expansion-panel-content__wrap {
    padding: 0% !important;
}
.v-expansion-panel:not(:first-child)::after {
    border-top: none !important;
}
.txtIndex {
    max-height: 19px;
    font-size: 12.4px;
    font-weight: 720;
    color: #a4a4a8;
}
.txtBody {
    max-height: 19px;
    font-size: 12.3px;
    font-weight: 650;
}
.maxHeight {
    max-height: 18px;
}

.transparentBg {
    background-color: transparent !important;
}
</style>
