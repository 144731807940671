<template>
    <v-card elevation="5" outlined>
        <!-- DIALOGS -->
        <!-- <AddTenantUserDialog
            :openAddTenantUserDialog="openAddTenantUser"
            v-on:closeAddTenantUserDialog="openAddTenantUser = false"
        ></AddTenantUserDialog> -->

        <!-- V-CARD -->
        <v-card-text class="pb-3 pr-3">
            <!-- filter menu -->
            <div style="position: absolute; right: 25px">
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display_filters')"
                    :choices="headerChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                    class="mr-n4"
                >
                </FilterMenu>
            </div>
            <v-data-table
                :headers="dynamicHeaders"
                :items="tenants.edges"
                item-key="node.id"
                :loading-text="$t('loading.loading1')"
                :loading="$apollo.queries.tenants.loading"
                hide-default-footer
                disable-pagination
                disable-sort
                :mobile-breakpoint="
                    screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                "
                loader-height="2"
                :no-data-text="$t('loading.no_data')"
                @click:row="item => this.$emit('click:row', item)"
            >
                <!-- Number of Organisation Permissions Column -->
                <template v-slot:item.node.organisationPermissions="{ item }">
                    <span v-if="item.node.organisationPermissions ? item.node.organisationPermissions.length > 0 : false">
                        {{ JSON.parse(item.node.organisationPermissions).length || 0}}
                    </span>
                    <span v-else>-</span>
                </template>
                <template v-slot:item.usedTrackerLastTime="{ item }">
                    <span>
                        {{item.node.tenantInsightSet ? 
                            item.node.tenantInsightSet.usedTrackerLastTime ?
                                formatDateTime(item.node.tenantInsightSet.usedTrackerLastTime) : ''
                                : ''
                        }}
                    </span>
                   
                </template>
                <template v-slot:item.node.tenantInsightSet.trackerStock="{ item }">
                    <div v-if="item.node.tenantInsightSet">
                        <span
                            :class="{
                                'red--text': item.node.tenantInsightSet.trackerStock < item.node.trackerStockLimit,
                                'green--text': item.node.tenantInsightSet.trackerStock > item.node.trackerStockLimit
                            }"
                        >
                            {{ item.node.tenantInsightSet.trackerStock || 0 }}
                            <v-icon
                                v-if="item.node.tenantInsightSet.trackerStock < item.node.trackerStockLimit"
                                small
                                color="red"
                            >warning</v-icon>
                        </span>
                    </div>
                </template>
                <!-- Actions Column -->
                <template v-slot:item.action="{ item }">
                    <v-icon color="primary" @click="openAddTenantUser = true"
                        >person_add</v-icon
                    >
                </template>
            </v-data-table>
        </v-card-text>

        <!-- infinit loading trigger -->
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.tenants.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                {{ $t("loading.load_more") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";
import _default from "vuex";

export default {
    name: "TenantTable",
    components: { FilterMenu },
    props: {
        tenantFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    apollo: {
        tenants: {
            query: gql`
                query tenants(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $nameIcontains: String
                ) {
                    tenants(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        name_Icontains: $nameIcontains
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                organisationPermissions
                                trackerStockLimit
                                tenantPartnerSet {
                                    totalCount
                                }
                                tenantUserSet {
                                    totalCount
                                }
                                tenantInsightSet {
                                    id
                                    trackerStock,
                                    usedTrackersLast7Days
                                    usedTrackersLast30Days
                                    usedTrackersTotal
                                    usedTrackersPerWeek
                                    usedTrackerLastTime
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.tenantFilter;
            },
            debounce: 800,
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenants,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    data() {
        return {
            loading: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            tenants: { edges: [] },
            openAddTenantUser: false,

            // defaultHeaderChoices: [
            //     {
            //         // for filter menu
            //         code: "node.name",
            //         name: this.$t("headers.name"),
            //         enabled: true,
            //         order: 0,
            //         // for table header
            //         text: this.$t("headers.name"),
            //         align: "left",
            //         value: "node.name"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.tenantPartnerSet.totalCount",
            //         name: "#" + this.$t("headers.partners"),
            //         enabled: true,
            //         order: 0,
            //         // for table header
            //         text: "#" + this.$t("headers.partners"),
            //         align: "right",
            //         value: "node.tenantPartnerSet.totalCount"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.tenantUserSet.totalCount",
            //         name: "#" + this.$t("home.users"),
            //         enabled: true,
            //         order: 0,
            //         //for table header
            //         text: "#" + this.$t("home.users"),
            //         align: "right",
            //         value: "node.tenantUserSet.totalCount"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.organisationPermissions",
            //         name: "#" + this.$t("home.services"),
            //         enabled: true,
            //         order: 0,
            //         // for table header
            //         text: "#" + this.$t("home.services"),
            //         align: "right",
            //         value: "node.organisationPermissions"
            //     }
            // ],
            headerChoices: null
        };
    },
    watch: {},
    mounted() {
        this.$watch(
            "headerChoices",
            function(value) {
                helper.updateMyPreferences({
                    tenantTableHeaders: value
                });
            },
            { deep: true }
        );
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        hasMoreData() {
            return this.tenants?.pageInfo?.hasNextPage;
        },
        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            _headers.push({
                text: "",
                align: "right",
                value: "headerFilter",
                width: "1px",
                sortable: false
            });

            return _headers;
        },
        defaultHeaderChoices: function() {
            let _defaultHeaderChoices = [
                {
                    // for filter menu
                    code: "node.name",
                    name: "Name",
                    tag: "headers.name",
                    enabled: true,
                    order: 0,
                    // for table header
                    text: "Name",
                    align: "left",
                    value: "node.name"
                },
                {
                    // for filter menu
                    code: "node.tenantPartnerSet.totalCount",
                    name: "#" + "Partners",
                    tag: "headers.partners",
                    enabled: true,
                    order: 0,
                    // for table header
                    text: "#" + "Partners",
                    align: "right",
                    value: "node.tenantPartnerSet.totalCount"
                },
                {
                    // for filter menu
                    code: "node.tenantUserSet.totalCount",
                    name: "#" + "Users",
                    tag: "home.users",
                    enabled: true,
                    order: 0,
                    //for table header
                    text: "#" + "Users",
                    align: "right",
                    value: "node.tenantUserSet.totalCount"
                },
                {
                    // for filter menu
                    code: "node.organisationPermissions",
                    name: "#" + "Services",
                    tag: "home.services",
                    enabled: true,
                    order: 0,
                    // for table header
                    text: "#" + "Services",
                    align: "right",
                    value: "node.organisationPermissions"
                },
                {
                    // for filter menu
                    code: "node.tenantInsightSet.trackerStock",
                    name: "Tracker Stock",
                    tag: "headers.tracker_stock",
                    enabled: true,
                    order: 0,
                    //for table header
                    text: "Tracker Stock",
                    align: "right",
                    value: "node.tenantInsightSet.trackerStock"
                },
                {
                    // for filter menu
                    code: "node.usedTrackersLastSevenDays",
                    name: "Used Trackers Last 7 Days",
                    tag: "headers.used_trackers_last_seven_days",
                    enabled: true,
                    order: 0,
                    //for table header
                    text: "Used Trackers Last 7 Days",
                    align: "right",
                    value: "node.tenantInsightSet.usedTrackersLast7Days"
                },
                {
                    // for filter menu
                    code: "node.usedTrackersLastThirtyDays",
                    name: "Used Trackers Last 30 Days",
                    tag: "headers.used_trackers_last_thirty_days",
                    enabled: true,
                    order: 0,
                    //for table header
                    text: "Used Trackers Last 30 Days",
                    align: "right",
                    value: "node.tenantInsightSet.usedTrackersLast30Days"
                },
                {
                    // for filter menu
                    code: "node.usedTrackersPerWeek",
                    name: "Used Trackers Per Week",
                    tag: "headers.used_trackers_per_week",
                    enabled: true,
                    order: 0,
                    //for table header
                    text: "Used Trackers Per Week",
                    align: "right",
                    value: "node.tenantInsightSet.usedTrackersPerWeek"
                },
                {
                    // for filter menu
                    code: "node.usedTrackersTotal",
                    name: "Used Trackers Total",
                    tag: "headers.used_trackers_total",
                    enabled: true,
                    order: 0,
                    //for table header
                    text: "Used Trackers Total",
                    align: "right",
                    value: "node.tenantInsightSet.usedTrackersTotal"
                },
                {
                    // for filter menu
                    code: "node.usedTrackerLastTime",
                    name: "Used Tracker Last Time",
                    tag: "headers.used_Tracker_last_time",
                    enabled: true,
                    order: 0,
                    //for table header
                    text: "Used Tracker Last Time",
                    align: "right",
                    value: "usedTrackerLastTime"
                }
               
            ];
            return _defaultHeaderChoices;
        }
    },
    created() {
        this.headerChoices = helper.getTableHeaders(
            "tenantTableHeaders",
            this.defaultHeaderChoices
        );
    },
    methods: {
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        warningColor(value) {
            return value > 0 ? "red" : "green";
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.tenants?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.tenants?.pageInfo?.hasNextPage) {
                this.$apollo.queries.tenants.fetchMore({
                    variables: {
                        after: this.tenants.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
