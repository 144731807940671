import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c(VCard,{staticClass:"py-4 mt-4",attrs:{"elevation":"5","outlined":""}},[_c(VRow,{staticClass:"px-5 d-flex align-center",attrs:{"align":"start"}},[_c(VCol,{attrs:{"cols":"5","sm":"5","lg":"5"}},[_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"subtitle-2",attrs:{"hint":this.$t('general.search_module'),"append-icon":"search","label":this.$t('general.search'),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1)],1)],1),_c(VSpacer),_c(VCol,{staticClass:"py-2 pr-4 text-right",attrs:{"cols":"4","sm":"5","lg":"4"}},[_c(VRow,{staticClass:"d-flex align-center justify-end"},[_c(VBtn,{staticClass:"mr-1",attrs:{"small":"","outlined":"","rounded":"","color":"primary","disabled":_vm.enableOrDisablePermissionBtn,"loading":_vm.isLoadingSavePermission},on:{"click":_vm.savePermissions}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("add_box")]),_vm._v(" "+_vm._s(_vm.$t("general.save_permissions"))+" ")],1)],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2 mb-5 pb-5",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,[_c(VDataTable,{attrs:{"loading":_vm.$apollo.queries.partnerPermisssions.loading,"items":_vm.computedPermissions,"headers":_vm.headerChoices,"disable-pagination":"","loading-text":_vm.$t('loading.loading1'),"hide-default-footer":"","loader-height":"2"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VCheckbox,{staticClass:"mx-0",attrs:{"multiple":"","value":item},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}})]}}])})],1)],1),_c(VCol)],1)}
var staticRenderFns = []

export { render, staticRenderFns }