<template>
    <v-dialog v-model="openDeleteTrackerDialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="primary">
                <v-icon color="white" class="mr-2">warning</v-icon>
                <div class="white--text">
                    {{$t('alert_rules.delete_tracker')}}
                </div>
            </v-card-title>
            <v-card-text class="pt-2">
                <div>
                    <h4>
                        {{$t('alert_rules.sure_tracker')}}
                    </h4>
                </div>
            </v-card-text>
            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn text rounded @click="$emit('closeDeleteTrackerDialog')"
                    >{{$t('general.cancel')}}</v-btn
                >
                <v-btn
                    color="primary"
                    rounded
                    :loading="isDeleting"
                    @click="deleteTracker"
                    >{{$t('general.delete')}}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "delete-dialog",
    props: ["openDeleteTrackerDialog", "trackerId"],
    data: function () {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteTracker() {
            this.isDeleting = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTracker(
                            $input: DeleteTrackerInput!
                        ) {
                            deleteTracker(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            trackerId: this.trackerId
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isDeleting = false;
                    this.$emit("refreshDataTable");
                    this.$emit("closeDeleteTrackerDialog");
                });
        }
    }
};
</script>