<template>
    <v-card outlined class="rounded-lg pa-3" height="350">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <v-card-title class="textTitle">
                <div class="mr-2">
                    <v-icon class="iconColor">group</v-icon>
                </div>
                <span>{{ $t("home.collaborators") }}</span>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!isSharedTrip"
                    elevation="0"
                    :disabled="!canEdit"
                    color="primary"
                    @click="addPartnerDialog = true"
                >
                    <div class="mr-1">{{ $t("general.add_collaborator") }}</div>
                </v-btn>
            </v-card-title>

            <v-card-text class="cardContainer">
                <LoadingBar
                    v-if="tripIsLoading"
                ></LoadingBar>
                <div v-else-if="!tripIsLoading && tripPartnersResult.edges.length === 0">
                    <NoData></NoData>
                </div>
                <v-card
                    v-for="item in tripPartnersResult.edges"
                    :key="item.node.id"
                    class="contentBg pb-2 rounded-lg mb-2"
                    outlined
                >
                    <v-row class="px-3 mt-1 py-1" align="stretch">
                        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
                            <div class="textHeader">
                                {{ $t("headers.type") }}
                            </div>
                            <div class="textBody text-capitalize">
                                {{ item.node.tenantPartner.partnerType }}
                            </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="5" :lg="isSmallScreen ? 5 : ''" xl="" class="text-truncate">
                            <div class="textHeader">
                                {{ $t("headers.name") }}
                            </div>
                            <div class="text-capitalize textBody">
                                {{ textTruncate(item.node.tenantPartner.name, 30) }}
                            </div>
                        </v-col>

                        <v-col cols="auto">
                            <div class="textHeader">
                                {{ $t("headers.enable_notification") }}
                            </div>
                            <div class="textBody col-auto py-0 pl-0">
                                <div
                                    class=" textBody primaryText px-1 d-flex align-center justify-start "
                                    :style="
                                        `
                                            background-color:${
                                                statusColor(item.node.tenantPartner.notificationEnabled).color1
                                            };
                                            border-radius: 4px;
                                            height: 22px;
                                        `
                                    "
                                >
                                    <v-icon
                                        left
                                        size="8"
                                        :color="statusColor(item.node.tenantPartner.notificationEnabled).color2"
                                    >
                                        circle
                                    </v-icon>
                                    <span>
                                        {{ statusColor(item.node.tenantPartner.notificationEnabled).text }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                        <v-spacer v-if="isSmallScreen"></v-spacer>
                        <v-col cols="auto">
                            <v-menu
                                v-if="!isSharedTrip"
                                offset-y
                                transition="slide-x-reverse-transition"
                                width="250px"
                                attach
                                left
                                bottom
                                close-on-click
                                nudge-top="-3"
                            >
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <v-list class="white">
                                    <v-list-item>
                                        <v-btn plain @click.stop="onPartnerSelected(item.node.tenantPartner)">
                                            <v-icon class="mr-2">visibility</v-icon>
                                            <span>
                                                {{ $t("general.see_more") }}
                                            </span>
                                        </v-btn>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-btn
                                            plain
                                            @click.stop="
                                                deletedDialog({
                                                    id: item.node.id,
                                                    partnerId: item.node.tenantPartner.id,
                                                    partnerName: item.node.tenantPartner.name
                                                })
                                            "
                                        >
                                            <v-icon class="mr-2">close</v-icon>
                                            <span>
                                                {{ $t("general.remove") }}
                                            </span>
                                        </v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card>
                <v-dialog v-model="addPartnerDialog" width="800" persistent>
                    <v-card>
                        <v-card-title class="primary textTitle2">
                            <div class="mr-2">
                                <v-icon color="white">group</v-icon>
                            </div>
                            <span>
                                <h5>{{ $t("general.add_collaborator") }}</h5>
                            </span>
                        </v-card-title>
                        <v-card-text class="py-2">
                            <PartnersForm
                                ref="partnerList"
                                v-on:addNewPartner="save"
                                :getPartners.sync="partners"
                                :partnersOnBoard="partnersOnBoard"
                            >
                            </PartnersForm>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeAddPartner" text>{{ $t("general.cancel") }}</v-btn>
                            <v-btn
                                elevation="0"
                                color="primary"
                                @click="$refs.partnerList.partnersAdded()"
                                :loading="saveLoadingBtn"
                                >{{ $t("general.save") }}</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="deletePartnerDialog"
                    max-width="300px"
                    height="500px"
                    transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-card-title class="background2 py-3 mb-2 textTitle">
                            <div class="mr-2">
                                <v-icon class="iconColor">warning</v-icon>
                            </div>
                            <span>
                                <h5>{{ $t("trips.remove_partner") }}</h5>
                            </span>
                        </v-card-title>
                        <v-card-text>
                            <div>
                                <span>
                                    <h4>
                                        {{ $t("trips.confirm_partner") }}
                                    </h4>
                                </span>
                            </div>
                            <div class="mt-2">
                                <span>{{ deleteItem.partnerName }}</span>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeDeleteDialog" text>{{ $t("general.cancel") }}</v-btn>
                            <v-btn color="error" elevation="0" @click="deletePartner" :loading="loadingDeleteBtn">{{
                                $t("general.remove")
                            }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="partnerDetailsDialog"
                    max-width="805px"
                    height="605px"
                    class="overflow-hidden"
                    content-class="overflow-hidden"
                    persistent
                >
                    <v-card flat>
                        <v-card-title class=" py-3 mb-2 textTitle2 primary">
                            <div class="mr-2">
                                <v-icon color="white">visibility</v-icon>
                            </div>
                            <span>
                                <h5>{{ $t("general.partner_details") }}</h5>
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="partnerDetailsDialog = false">
                                <v-icon color="white">close</v-icon>
                            </v-btn>
                            <!-- <v-spacer></v-spacer> -->
                        </v-card-title>
                        <v-card-text class="pa-0 ma-0 overflow-y-auto" style="max-width: 800px; max-height: 500px;">
                            <PartnerDetail
                                ref="partnerDetails"
                                v-if="selectedPartner"
                                :isDialog="true"
                                :key="selectedPartner.id"
                                :partner="selectedPartner"
                                v-on:refreshDataTable="refetchTableData"
                                v-on:close="partnerDetailDrawer = false"
                            >
                            </PartnerDetail>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </div>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import PartnersForm from "@/components/trips/forms/PartnersForm.vue";
import PartnerDetail from "@/components/partners/PartnerDetail.vue";
import helper from "@/utils/helper.js";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import Config from "@/utils/config.json";
import NoData from "@/components/base/NoData.vue";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        tripIsLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    components: {
        PartnersForm,
        LoadingBar,
        PartnerDetail,
        NoPermission,
        NoData
    },
    apollo: {
        // tripPartners: {
        //     query: gql`
        //         query trip($tripId: ID!) {
        //             trip(id: $tripId) {
        //                 id
        //                 tripPartnerSet {
        //                     edges {
        //                         node {
        //                             id
        //                             tenantPartner {
        //                                 id
        //                                 name
        //                                 partnerType
        //                                 referenceNumber
        //                                 notificationEnabled
        //                                 carrierType
        //                                 tenantPartnerUserSet {
        //                                     totalCount
        //                                     edges {
        //                                         node {
        //                                             id
        //                                             user {
        //                                                 id
        //                                                 firstName
        //                                                 lastName
        //                                                 email
        //                                                 lastLogin
        //                                             }
        //                                         }
        //                                     }
        //                                 }
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     `,
        //     variables() {
        //         return { tripId: this.trip?.id };
        //     },
        //     // Additional options here
        //     fetchPolicy: "cache-and-network",
        //     nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
        //     update: data => data.trip?.tripPartnerSet,
        //     watchLoading(isLoading) {
        //         this.$emit("loading", isLoading);
        //     },
        //     result({ loading, data }) {
        //         if (!loading) {
        //             this.tripPartnersResult = { ...data.trip?.tripPartnerSet };
        //             this.partnersOnBoard = data.trip?.tripPartnerSet.edges.map(item => item);
        //         }
        //     },
        //     // debounce: 800,
        //     skip() {
        //         return !this.canAccess;
        //     }
        //     // pollInterval: 60000 // ms
        // }
    },
    data() {
        return {
            // tripPartnersResult: { edges: [] },
            addPartnerDialog: false,
            deletePartnerDialog: false,
            saveLoadingBtn: false,
            deleteItem: {},
            openEditPartnerDialog: false,
            editPartner: {},
            updatedPartner: null,
            loadingDeleteBtn: false,
            selectedPartner: null,
            partnerDetailDrawer: false,
            partnerDetailsDialog: false,
            // partnersOnBoard: [],
            partners: [],
            headers: [
                {
                    text: this.$t("headers.reference_number"),
                    align: "left",
                    value: "node.tenantPartner.referenceNumber"
                },
                {
                    text: this.$t("headers.partner_name"),
                    align: "left",
                    value: "node.tenantPartner.name"
                },
                {
                    text: this.$t("headers.partner_type"),
                    align: "left",
                    value: "node.tenantPartner.partnerType"
                },
                {
                    text: this.$t("headers.enable_notification"),
                    align: "left",
                    value: "node.tenantPartner.notificationEnabled"
                },
                {
                    text: this.$t("headers.actions"),
                    align: "center",
                    value: "action"
                }
            ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
        this.isEmpty = helper.isEmpty;
    },
    computed: {
        me() {
            return helper.me();
        },
        tripPartnersResult() {
            let local = {edges:[]};
            if(!this.tripIsLoading)
                local = this.trip?.tripPartnerSet;

            // if(this.isEmpty(local)) return {edges:[]};
            return local;
        },
        partnersOnBoard() {
            let local = this.trip?.tripPartnerSet.edges;
            if(this.isEmpty(local)) return [];

            return local.map(item => item);
        },
        primaryColor() {
            return this.$vuetify.theme.themes.light.primary;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1300;
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (!this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_PARTNER, this.organisationPermissions))
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_PARTNER, this.organisationPermissions)
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_PARTNER,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.VIEW_TRIP_PARTNER) && this.me?.tenant) return false;
            }
            return true;
        },
        canEdit() {
            if (this.trip?.status === "COMPLETED") return false;

            if (this.isSharedTrip) return false;

            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (!this.hasOrganisationPermission(TripPermissions.EDIT_TRIP_PARTNER, this.organisationPermissions))
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(TripPermissions.EDIT_TRIP_PARTNER, this.organisationPermissions)
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_PARTNER,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.EDIT_TRIP_PARTNER) && this.me?.tenant) return false;
            }
            return true;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_partners_tab_svw"
        });
        this.setUserProperties();
        this.updateScrollbarColor(this.primaryColor);
    },
    methods: {
        textTruncate(text, length) {
            if (text === null || text === undefined) {
                return;
            } else if (text.length > length) {
                return text.substring(0, length) + "...";
            } else {
                return text;
            }
        },
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_partners_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_partners_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        updateScrollbarColor(color) {
            document.documentElement.style.setProperty("--scrollbar-color", color);
        },
        statusColor(el) {
            let color = {};
            if (el !== null) {
                if (el) {
                    color = {
                        color1: "#C8E6C9",
                        color2: "#1B5E20",
                        text: this.$t("general.enable")
                    };
                } else {
                    color = {
                        color1: "#FFCDD2",
                        color2: "#F44336",
                        text: this.$t("general.not_enable")
                    };
                }
            } else {
                color = {
                    color1: "#FFCDD2",
                    color2: "#F44336",
                    text: this.$t("general.not_enable")
                };
            }
            return color;
        },
        onPartnerSelected(partner) {
            this.selectedPartner = partner;
            this.partnerDetailsDialog = true;
        },
        closeAddPartner() {
            this.$refs.partnerList.clearCache();
            this.addPartnerDialog = false;
        },
        save(partnersId) {
            this.saveLoadingBtn = true;
            // prepare api call payload
            let payload = {
                tripId: this.trip.id,
                partnersId: [...partnersId]
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation addTripPartner($input: AddTripPartnerInput!) {
                            addTripPartner(input: $input) {
                                trip {
                                    id
                                    tripPartnerSet {
                                        edges {
                                            node {
                                                id
                                                tenantPartner {
                                                    id
                                                    name
                                                    partnerType
                                                    referenceNumber
                                                    notificationEnabled
                                                    carrierType
                                                    tenantPartnerUserSet {
                                                        totalCount
                                                        edges {
                                                            node {
                                                                id
                                                                user {
                                                                    id
                                                                    firstName
                                                                    lastName
                                                                    email
                                                                    lastLogin
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("trips.partner_saved"),
                        icon: "done"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.saveLoadingBtn = false;
                    this.closeAddPartner();
                    this.refetchTableData();
                });
        },
        deletedDialog(item) {
            this.deletePartnerDialog = true;
            this.deleteItem = { ...item };
        },
        refetchTableData() {
            this.$emit("refresh-trip");
            // return this.$apollo.queries.tripPartners.refetch();
        },
        closeDeleteDialog() {
            this.deletePartnerDialog = false;
            this.deleteItem = {};
        },
        deletePartner() {
            this.loadingDeleteBtn = true;
            let payload = {
                id: this.deleteItem.id
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTripPartner($input: DeleteTripPartnerInput!) {
                            deleteTripPartner(input: $input) {
                                trip {
                                    id
                                    tripPartnerSet {
                                        edges {
                                            node {
                                                id
                                                tenantPartner {
                                                    id
                                                    name
                                                    partnerType
                                                    referenceNumber
                                                    notificationEnabled
                                                    carrierType
                                                    tenantPartnerUserSet {
                                                        totalCount
                                                        edges {
                                                            node {
                                                                id
                                                                user {
                                                                    id
                                                                    firstName
                                                                    lastName
                                                                    email
                                                                    lastLogin
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    // show snackbar
                    const payload = {
                        color: "error",
                        message: this.$t("trips.partner_removed"),
                        icon: "warning"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.refetchTableData();
                    this.loadingDeleteBtn = false;
                    this.deletePartnerDialog = false;
                });
        }
    }
};
</script>
<style scoped>
/* hide the scrollbar of the v-dialog */
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
    color: #e6e9f5;
}
.contentBg {
    background-color: #f9fafe;
}
.cardContainer {
    height: 250px;
    overflow-y: auto !important;
    margin-bottom: 12px;
}
.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.iconColor {
    color: #0e0d35;
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #555464;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
</style>
