<template>
    <v-card elevation="0" outlined v-resize="onResize" color="transparent">
        <!-- V-CARD -->
        <v-card-text class="px-0">
            <!-- Body -->
            <v-row dense>
                <v-col cols="12" class="py-0">
                    <div v-if="partnerIsLoading && !hasData">
                        <LoadingBar></LoadingBar>
                    </div>
                    <div v-else>
                        <div v-if="hasData">
                            <v-card
                                v-for="partner in tenantPartners.edges"
                                :key="partner.node.id"
                                class="mb-4 rounded-lg px-3"
                                height=""
                                outlined
                                flat
                                @click.stop="partnerSelect(partner)"
                            >
                                <v-card-text>
                                    <v-row class="d-flex justify-center py-1" flat>
                                        <v-col
                                            v-if="type.toLowerCase() === 'carrier'"
                                            class="d-flex justify-left align-left"
                                            :style="actionCard(partner).style"
                                            :cols="actionCard(partner).cols.xs"
                                            :xs="actionCard(partner).cols.xs"
                                            :sm="actionCard(partner).cols.sm"
                                            :md="actionCard(partner).cols.md"
                                            :lg="actionCard(partner).cols.lg"
                                            :xl="actionCard(partner).cols.xl"
                                        >
                                            <v-icon
                                                v-if="actionCard(partner).icon"
                                                class="mr-1"
                                                :size="actionCard(partner).iconSize"
                                                :color="actionCard(partner).colorIcon"
                                                >{{ actionCard(partner).icon }}</v-icon
                                            >
                                        </v-col>
                                        <v-col>
                                            <v-row dense>
                                                <v-col
                                                    v-for="item in mainCard(partner)"
                                                    :key="item.text"
                                                    :cols="item.cols.xs"
                                                    :xs="item.cols.xs"
                                                    :sm="item.cols.sm"
                                                    :md="item.cols.md"
                                                    :lg="item.cols.lg"
                                                    :xl="item.cols.xl"
                                                >
                                                    <div class="">
                                                        <div class="textHeader text-truncate">
                                                            {{ item.title }}
                                                        </div>
                                                        <div class="textBody text-truncate" :style="item.style">
                                                            <v-icon
                                                                v-if="item.icon"
                                                                class="mr-1"
                                                                :size="item.iconSize"
                                                                :color="item.colorIcon"
                                                                >{{ item.icon }}</v-icon
                                                            >
                                                            <span v-if="item.value || item.value > -1">
                                                                {{ item.value }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    xs="12"
                                                    sm="6"
                                                    :md="!isCarrier ? 3 : 9"
                                                    :lg="!isCarrier ? 4 : 3"
                                                    :xl="!isCarrier ? 4 : 3"
                                                   
                                                >
                                                    <v-row>
                                                        <v-col
                                                            v-for="item in secondaryCard(partner)"
                                                            :key="item.text"
                                                            :cols="item.cols.xs"
                                                            :xs="item.cols.xs"
                                                            :sm="item.cols.sm"
                                                            :md="item.cols.md"
                                                            :lg="item.cols.lg"
                                                            :xl="item.cols.xl"
                                                        >
                                                            <div>
                                                                <div class="textHeader text-no-wrap">
                                                                    {{ item.title }}
                                                                </div>
                                                                <div class="textBody text-truncate" :style="item.style">
                                                                    <v-icon
                                                                        v-if="item.icon"
                                                                        class="mr-1"
                                                                        :size="item.iconSize"
                                                                        :color="item.colorIcon"
                                                                        >{{ item.icon }}</v-icon
                                                                    >
                                                                    <span v-if="item.value || item.value > -1">
                                                                        {{ item.value }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div v-else>
                            <v-row class="d-flex align-center justify-center">
                                <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="text-center">
                                    <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')"></v-img>
                                    <v-card flat color="transparent">
                                        <v-card-title class="justify-center">
                                            <div class="textTitle">
                                                {{ $t("general.empty_data_title") }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text class="px-0">
                                            <div class="textSubtitle">
                                                {{ $t("partners.empty_data_subtitle") }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="px-10 d-flex justify-center">
                                            <slot name="actionButton"></slot>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.tenantPartners.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                {{ $t("loading.load_more") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../utils/helper";
import LoadingBar from "@/components/base/LoadingBar.vue";

export default {
    name: "PartnerTable",
    props: {
        partnerFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        orderBy: {
            type: String,
            required: false,
            default: null
        }
    },
    components: {
        LoadingBar
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $referenceNumberIcontains: String
                    $partnerType: String
                    $search: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        referenceNumber_Icontains: $referenceNumberIcontains
                        partnerType: $partnerType
                        search: $search
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                description
                                referenceNumber
                                openTrips
                                totalTrips
                                partnerType
                                carrier {
                                    id
                                    name
                                    carrierType
                                }
                                notificationEnabled
                                organisationPermissions
                                tenantPartnerUserSet {
                                    totalCount
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                let request = {
                    ...this.partnerFilter,
                    ...this.tableFilter
                };

                return request;
            },
            fetchPolicy: "cache-and-network",

            update: data => data.tenantPartners,
            watchLoading(isLoading) {
                this.partnerIsLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            debounce: 800,
            skip() {
                return false;
            }
        }
    },
    data() {
        return {
            shouldLoadMore: false,
            isInitialLoad: true,
            tenantPartners: { edges: [] },
            openAddPartnerUser: false,
            tableHeight: 800,
            headerChoices: null,
            tableFilter: {
                first: 15,
                after: null,
                nameIcontains: null
            },
            filter: null,
            tab: null,
            partnerIsLoading: true
        };
    },
    computed: {
        loading() {
            return this.$apollo.queries.tenantPartners.loading;
        },
        hasData() {
            return this.tenantPartners?.edges?.length > 0;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1320;
        },
        isCarrier() {
            return this.type?.toLowerCase() === "carrier";
        },
        hasMoreData() {
            return this.tenantPartners?.pageInfo?.hasNextPage;
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        }
    },
    watch: {
        type: {
            handler(val, oldVal) {
                let _filter = { ...this.tableFilter };
                if (val !== oldVal) {
                    _filter.partnerType = val;
                    this.tableFilter = _filter;
                    this.tenantPartners = { edges: [] };
                }
            },
            immediate: true,
            deep: true
        },
        orderBy: {
            handler(val) {
                this.tableFilter.orderBy = val;
            },
            immediate: true,
            deep: true
        }
        /* partnerFilter: {
            handler(newValue, oldValue) {
                let newValueString = JSON.parse(JSON.stringify(newValue));
                let oldValueString = JSON.parse(JSON.stringify(oldValue));
                if (newValueString !== oldValueString) {
                    this.partnerIsLoading = true;
                    this.tenantPartners = { edges: [] };
                }
            },
            deep: true
        } */
    },
    methods: {
        createPartner() {
            this.$emit("click:add");
        },
        partnerSelect(partner) {
            this.$emit("click:row", partner);
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 145 + "px";
            });
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.tenantPartners?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refreshQuery() {
            if (this.$apollo.queries?.tenantPartners?.refetch()) {
                this.$apollo.queries?.tenantPartners?.refetch();
            }
        },
        loadMore() {
            if (this.tenantPartners?.pageInfo?.hasNextPage) {
                this.$apollo.queries.tenantPartners.fetchMore({
                    variables: {
                        after: this.tenantPartners.pageInfo.endCursor
                    }
                });
            }
        },
        mainCard(item) {
            const { referenceNumber, name, carrier, notificationEnabled } = item.node;
            let containerStyle = `
                    background-color: ${this.statusColor(notificationEnabled).color1};
                    border-radius: 4px;
                    width: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            `;

            let _mainCard = [
                {
                    title: this.$t("headers.reference_number"),
                    tag: "referenceNumber",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: referenceNumber,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: !this.isCarrier ? "3" : "3",
                        lg: !this.isCarrier ? "2" : "2",
                        xl: !this.isCarrier ? "2" : "2"
                    }
                },
                {
                    title: this.$t("headers.name"),
                    tag: "name",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: name,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: !this.isCarrier ? "4" : "3",
                        lg: !this.isCarrier ? "4" : "3",
                        xl: !this.isCarrier ? "4" : "3"
                    }
                },
                {
                    title: this.$t("headers.notifications"),
                    tag: "notifications",
                    color: "primary",
                    icon: "circle",
                    iconSize: "8",
                    colorIcon: this.statusColor(notificationEnabled).color2,
                    style: containerStyle,
                    align: "center",
                    value: this.statusText(notificationEnabled),
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: !this.isCarrier ? "2" : this.isSmallScreen ? "3" : "3",
                        lg: !this.isCarrier ? "2" : "1",
                        xl: !this.isCarrier ? "2" : "1"
                    }
                }
            ];

            if (this.type?.toLowerCase() === "carrier") {
                _mainCard.splice(2, 0, {
                    //add standard carrier
                    title: this.$t("partners.carrier_name"),
                    tag: "carrierName",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: carrier?.name,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        xl: "3"
                    }
                });
                let icon = null;
            }

            return _mainCard;
        },
        secondaryCard(item) {
            const { openTrips, totalTrips, notificationEnabled, tenantPartnerUserSet } = item.node;

            let containerStyle = `
                    background-color: ${this.statusColor(notificationEnabled).color1};
                    border-radius: 4px;
                    width: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            `;

            let _secondaryCard = [
                {
                    title: this.$t("headers.open_trips1"),
                    tag: "open_trips",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: openTrips,
                    cols: {
                        xs: "4",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4"
                    }
                },
                {
                    title: this.$t("headers.total_trips"),
                    tag: "total_trips",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: totalTrips,
                    cols: {
                        xs: "4",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4"
                    }
                },
                {
                    title: this.$t("headers.users"),
                    tag: "users",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "right",
                    value: tenantPartnerUserSet?.totalCount ? tenantPartnerUserSet.totalCount : 0,
                    cols: {
                        xs: "4",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4"
                    }
                }
            ];

            return _secondaryCard;
        },
        actionCard(item) {
            let icon = null;
            switch (item.node?.carrier?.carrierType.toLowerCase()) {
                case "sea":
                    icon = "directions_boat";
                    break;
                case "air":
                    icon = "flight";
                    break;
                case "road":
                    icon = "local_shipping";
                    break;
                default:
                    icon = "local_shipping";
                    break;
            }

            return {
                title: "",
                tag: null,
                icon: icon,
                iconZise: "18",
                colorIcon: null,
                style: "display: flex; align-items: center;",
                align: "left",
                value: null,
                cols: {
                    xs: "12",
                    sm: "12",
                    md: "12",
                    lg: "auto",
                    xl: "auto"
                }
            };
        },
        statusColor(status) {
            if (status) {
                return {
                    color1: "#C8E6C9",
                    color2: "#1B5E20"
                };
            } else {
                return {
                    color1: "#FFCDD2",
                    color2: "#F44336"
                };
            }
        },
        statusText(status) {
            if (status) {
                return this.$t("headers.enabled");
            } else {
                return this.$t("headers.disabled");
            }
        }
    }
};
</script>

<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
</style>
