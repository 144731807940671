<template>
    <v-container fluid>
        <v-form v-model="tripValidate" ref="form">
            <v-card flat outlined class="pr-3 pt-3 pb-5">
                <v-timeline dense align-top>
                    <v-timeline-item color="white" class="py-0">
                        <template v-slot:icon>
                            <v-icon color="primary">commute</v-icon>
                        </template>
                        <v-row dense>
                            <v-col cols="11">
                                <v-autocomplete
                                    v-model="trip.transportMode"
                                    :items="transportModeChoices"
                                    :label="$t('trips.transport_mode') + '*'"
                                    :search-input="trip.transportMode"
                                    outlined
                                    :rules="[rules.required]"
                                    @change="
                                        filterTransportMode(trip.transportMode)
                                    "
                                    dense
                                    hide-selected
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item color="white" class="py-0">
                        <template v-slot:icon>
                            <v-icon v-if="!trip.transportMode" color="primary"
                                >remove</v-icon
                            >
                            <v-icon
                                v-if="trip.transportMode === 'other'"
                                color="primary"
                                >airport_shuttle</v-icon
                            >
                            <v-icon
                                v-if="trip.transportMode === 'sea'"
                                color="primary"
                                >directions_boat</v-icon
                            >
                            <v-icon
                                v-if="trip.transportMode === 'air'"
                                color="primary"
                                >flight</v-icon
                            >
                            <v-icon
                                v-if="trip.transportMode === 'road'"
                                color="primary"
                                >directions_bus</v-icon
                            >
                            <v-icon
                                v-if="trip.transportMode === 'rail'"
                                color="primary"
                                >directions_railway</v-icon
                            >
                        </template>
                        <v-row dense>
                            <v-col cols="11">
                                <v-autocomplete
                                    v-model="trip.carrier"
                                    :loading="
                                        $apollo.queries.getTenantPartners
                                            .loading
                                    "
                                    :items="tenantPartners"
                                    :no-data-text="$t('loading.no_available')"
                                    :label="$t('headers.carrier')"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    clearable
                                    :disabled="!trip.transportMode"
                                    dense
                                    hide-selected
                                >
                                    <template v-slot:item="data">
                                        <v-row dense class="ma-0 body-2">
                                            <v-col
                                                cols="10"
                                                class="text-truncate pl-0 ml-0"
                                            >
                                                {{ data.item.name }}
                                            </v-col>
                                            <v-col cols="2">
                                                <span
                                                    class="grey--text"
                                                    v-if="
                                                        data.item.carrier &&
                                                            data.item.carrier
                                                                .isSupportTracking
                                                    "
                                                    ><i>Trackable</i></span
                                                >
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1">
                                <v-btn
                                    small
                                    outlined
                                    rounded
                                    icon
                                    color="primary"
                                    @click="createNewPartner('carrier')"
                                    class="mt-2"
                                    :disabled="
                                        trip.transportMode === null ||
                                            trip.transportMode.length === 0
                                    "
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item color="white" class="py-0">
                        <template v-slot:icon>
                            <v-icon color="primary">tag</v-icon>
                        </template>
                        <v-row dense>
                            <v-col cols="11">
                                <v-text-field
                                    v-model="trip.referenceNumber"
                                    :label="
                                        !templateMode
                                            ? this.$t('trips.trip_ref') + '*'
                                            : this.$t('trips.trip_ref')
                                            ? this.$t('trips.trip_ref') + '*'
                                            : this.$t('trips.trip_ref')
                                    "
                                    :placeholder="$t('trips.order_ship_no')"
                                    outlined
                                    :rules="[
                                        rules.required,
                                        rules.alertIsInvalid(alertHint)
                                    ]"
                                    @change="
                                        validateRefNumber(trip.referenceNumber)
                                    "
                                    :success-messages="!setActivate ? [''] : []"
                                    :disabled="templateMode"
                                    :filled="templateMode"
                                    dense
                                >
                                    <template v-slot:append>
                                        <div class="d-flex align-center">
                                            <v-btn
                                                icon
                                                small
                                                :loading="tripLoading"
                                                v-if="
                                                    trip.referenceNumber !==
                                                        '' &&
                                                        trip.referenceNumber !==
                                                            null
                                                "
                                                color="primary"
                                            >
                                                <v-icon v-if="!setActivate"
                                                    >done</v-icon
                                                >
                                                <v-icon
                                                    v-else-if="alertHint"
                                                    color="error"
                                                    >warning</v-icon
                                                >
                                            </v-btn>
                                        </div>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item color="white" class="py-0">
                        <template v-slot:icon>
                            <v-icon color="primary">description</v-icon>
                        </template>
                        <v-row dense>
                            <v-col cols="11">
                                <v-text-field
                                    v-model="trip.shipmentFileNumber"
                                    :label="$t('trips.shipment_no')"
                                    outlined
                                    dense
                                    :disabled="templateMode"
                                    :filled="templateMode"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </v-card>
        </v-form>
        <AddPartnerDialog
            :openAddPartnerDialog="openAddPartnerDialog"
            v-on:closeAddPartnerDialog="openAddPartnerDialog = false"
            v-on:item-created="onPartnerCreated"
            :partnerByType="partnerByType"
        ></AddPartnerDialog>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
//import DateComponent from "@/components/base/DateComponent.vue";
//import TimeComponent from "@/components/base/TimeComponent.vue";
import AddPartnerDialog from "@/components/partners/create/AddPartnerDialog.vue";

export default {
    components: {
        /* DateComponent */ /* TimeComponent */ AddPartnerDialog
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean
        },
        isSeaTransport: {
            type: Boolean,
            default: false
        },
        getTrip: {
            type: Object
        },
        editMode: {
            type: Boolean,
            default: false
        },
        templateMode: {
            type: Boolean,
            default: false
        },
        addFromTrip: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        getTenantPartners: {
            query: gql`
                query getTenantPartners(
                    $partnerType: String
                    $orderBy: String
                    $carrierType: String
                ) {
                    getTenantPartners(
                        partnerType: $partnerType
                        orderBy: $orderBy
                        carrierType: $carrierType
                    ) {
                        edges {
                            node {
                                id
                                name
                                carrierType
                                carrier {
                                    id
                                    isSupportTracking
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    partnerType: "carrier",
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.getTenantPartners,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return true;
            }
        }
    },
    data() {
        return {
            trigger: false,
            partnerByType: "",
            tripLoading: false,
            setActivate: true,
            alertHint: false,
            newPartner: false,
            openAddPartnerDialog: false,
            // tenantPartners:[],
            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" },
            //     { text: this.$t("transport_mode_choice.other"), value: "other" }
            // ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.required") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                alertIsInvalid: v =>
                    v === false || this.$t("rules.ref_number_exists")
            }
        };
    },
    computed: {
        tripValidate: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        trip: {
            get() {
                return this.getTrip;
            },
            set(value) {
                this.$emit("update:getTrip", value);
            }
        },
        tenantPartners() {
            return this.getTenantPartners?.edges?.map(e => e.node) || [];
        },
        me() {
            return helper.me();
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" },
                { text: this.$t("transport_mode_choice.other"), value: "other" }
            ];
            return _transportModeChoices;
        },
    },
    created() {},
    mounted() {
        this.$apollo.queries.getTenantPartners.skip = false;
        if (!this.editMode && !this.addFromTrip) {
            let organisationPreferences = this.me.tenant
                ?.organisationPreferences;
            if (
                organisationPreferences != null &&
                organisationPreferences != "" &&
                organisationPreferences != undefined
            ) {
                let _preferences = JSON.parse(organisationPreferences);
                if (
                    _preferences != null &&
                    _preferences?.transportModeChoice != null
                ) {
                    this.trip.transportMode = Array.isArray(
                        _preferences.transportModeChoice
                    )
                        ? ""
                        : _preferences.transportModeChoice;
                }
            }
        }

        if (
            this.trip.transportMode !== null ||
            this.trip.transportMode !== undefined ||
            this.trip.transportMode?.length === 0
        ) {
            this.$apollo.queries.getTenantPartners
                .setVariables({
                    partnerType: "carrier",
                    carrierType: this.trip.transportMode,
                    orderBy: "name"
                });

                this.$apollo.queries.getTenantPartners.refetch();
                // .then(() => {
                //     // this.tenantPartners=response.data.getTenantPartners.edges.map(e => e.node)
                // })
                // .catch(error => {
                //     console.error(error);
                //     this.error = error;
                // })
                // .finally(() => {});
        }
    },
    watch: {
        "trip.transportMode": function(newVal) {
            if (newVal === null) {
                this.trip.transportMode = null;
                this.trip.carrier = null;
            }
            if (newVal === "sea") {
                this.$emit("update:isSeaTransport", true);
            } else {
                this.$emit("update:isSeaTransport", false);
            }
            if (newVal != null) {
                this.$apollo.queries.getTenantPartners
                    .setVariables({
                        partnerType: "carrier",
                        carrierType: newVal,
                        orderBy: "name"
                    });

                this.$apollo.queries.getTenantPartners.refetch();
                // .then(() => {
                //     // this.tenantPartners=response.data.getTenantPartners.edges.map(e => e.node)
                // })
                // .catch(error => {
                //     console.error(error);
                //     this.error = error;
                // })
                // .finally(() => {});
            }
        },
        "trip.referenceNumber": function(newValue, oldValue) {
            if (newValue === null) return (this.trip.referenceNumber = "");
            if (newValue !== oldValue) {
                this.setActivate = true;
                this.alertHint = false;
            }
        },
        isOpen: function(newVal) {
            if (newVal && !this.editMode && !this.addFromTrip) {
                let organisationPreferences = this.me.tenant
                    ?.organisationPreferences;
                if (
                    organisationPreferences != null &&
                    organisationPreferences != "" &&
                    organisationPreferences != undefined
                ) {
                    let _preferences = JSON.parse(organisationPreferences);
                    if (
                        _preferences != null &&
                        _preferences?.transportModeChoice != null
                    ) {
                        this.trip.transportMode =
                            _preferences.transportModeChoice;
                    }
                }
            }

            if (!newVal) {
                this.setActivate = true;
                this.alertHint = false;
            }
        }
    },
    methods: {
        validateRefNumber(reference) {
            if (
                reference !== null &&
                reference !== undefined &&
                reference !== ""
            ) {
                this.tripLoading = true;
                this.alertHint = false;

                //mutation to check if reference number exists
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CheckIfReferenceNumberIsAvailable(
                                $input: CheckIfReferenceNumberIsAvailableInput!
                            ) {
                                checkIfReferenceNumberIsAvailable(
                                    input: $input
                                ) {
                                    available
                                }
                            }
                        `,
                        variables: {
                            input: {
                                referenceNumber: reference
                            }
                        }
                    })
                    .then(response => {
                        if (
                            response.data.checkIfReferenceNumberIsAvailable
                                .available === false
                        ) {
                            this.setActivate = true;
                            this.alertHint = true;
                        } else {
                            this.setActivate = false;
                            this.alertHint = false;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.error = error;
                    })
                    .finally(() => {
                        this.tripLoading = false;
                    });
            }
        },
        filterTransportMode(mode) {
            this.trip.carrier = null;
            if (mode !== null) {
                if (mode.length > 0) {
                    this.$apollo.queries.getTenantPartners
                        .setVariables({
                            partnerType: "carrier",
                            carrierType: mode,
                            orderBy: "name"
                        });

                    this.$apollo.queries.getTenantPartners.refetch();
                    // .then(() => {
                    //     // this.tenantPartners=response.data.getTenantPartners.edges.map(e => e.node)
                    // })
                    // .catch(error => {
                    //     console.error(error);
                    //     this.error = error;
                    // })
                    // .finally(() => {});
                } else {
                    // this.tenantPartners=[]
                    this.trip.carrier = null;
                }
            } else {
                // this.tenantPartners=[]
                this.trip.carrier = null;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        createNewPartner(type) {
            this.openAddPartnerDialog = true;
            this.partnerByType = type;
        },
        refetchData() {
            return this.$apollo.queries.getTenantPartners.refresh();
        },
        onPartnerCreated(item) {
            this.refetchData();
            this.trip.carrier = item?.tenantPartner?.id;
        }
    }
};
</script>
