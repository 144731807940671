<template>
    <div>
        <LoadingBar v-if="$apollo.queries.trackers.loading"></LoadingBar>
        <div v-else>
            <v-card flat v-if="historyDetail" color="background">
                <!-- top bar -->
                <v-toolbar flat color="transparent">
                    <v-btn icon @click="$emit('close')">
                        <v-icon>arrow_back_ios_new</v-icon>
                    </v-btn>
                    <!-- <v-btn
                        v-if="shouldShowFullScreenButton"
                        icon
                        @click="changeFullScreen"
                    >
                        <v-icon>{{
                            isFullScreen ? "fullscreen_exit" : "crop_free"
                        }}</v-icon>
                    </v-btn> -->
                    <v-spacer></v-spacer>
                    <!-- Edit trip -->
                    <!-- <v-btn
                        color=""
                        @click="editTracker()"
                        icon
                    >
                        <v-icon>
                            edit
                        </v-icon>
                    </v-btn> -->

                    <!-- <v-menu
                        offset-y
                        transition="slide-x-reverse-transition"
                        width="250px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-btn
                                    plain
                                    class="pa-0 ml-2"
                                    @click="assignToStockLocation()"
                                
                                >
                                    <v-icon class="mr-2">description</v-icon>
                                    <span>{{ $t('trackers.assign_to') }} {{ $t('trackers.stock_location') }}</span>
                                </v-btn>
                        
                            </v-list-item>
                            <v-list-item v-if="!(canDelete && !isTrackerActivated)">
                                <v-btn
                                    plain
                                    class="pa-0 ml-2"
                                    @click="openDeleteDialog = true"
                                
                                >
                                    <v-icon class="mr-2">delete</v-icon>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-btn>
                        
                            </v-list-item>
                        </v-list>
                    </v-menu> -->
                </v-toolbar>
                <v-spacer></v-spacer>

                <!-- title Bar header -->
                <v-sheet class="pt-0 mb-5 px-5 background d-flex align-center justify-space-between">
                    <v-row dense>
                        <v-col cols="6" class="d-flex align-center justify-start">
                            <div class="mt-1 textLeft">
                                <div class="titleText">
                                    {{ $t("trackers.file_name") }}
                                </div>
                                <div class="labelText">
                                    {{ historyDetail ? historyDetail.fileName : "" }}
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="4" class="d-flex align-center justify-start">
                            <div class="mt-1 textRight">
                                <div class="titleText">
                                    {{ $t("general.uploaded_at") }}
                                </div>
                                <div
                                    class="font-weight-bold primaryText px-1 d-flex align-center justify-start"
                                    :style="` border-radius: 4px;height: 22px;`"
                                >
                                    <span class="text-capitalize">
                                        {{ historyDetail ? formatDateTime(historyDetail.createdAt) : "" }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>

                <!-- Tabs -->
                <v-tabs v-model="tab" color="primary" ref="tabs" background-color="transparent">
                    <v-tab v-for="t in tabs" :key="t.code" :href="`#tab-${t.code}`">
                        <template v-slot:default>
                            <div class="text-capitalize">
                                {{ t.name }}
                            </div>
                        </template>
                    </v-tab>
                    <v-spacer></v-spacer>

                    <!-- tab content -->
                    <v-tabs-items v-model="tab" :style="`height: ${heightScreen}px;`" class="overflow-y-auto contentBg">
                        <v-tab-item value="tab-details" :transition="false">
                            <v-card
                                v-for="item in trackers.edges"
                                :key="item.node.id"
                                class="ma-4 rounded-lg "
                                outlined
                                flat
                            >
                                <v-card-text>
                                    <v-row dense align="center">
                                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                                            <div class="mt-1 textLeft">
                                                <div class="font-weight-thin secondaryText  text-truncate">
                                                    {{ $t("headers.serial_number") }}
                                                </div>
                                                <div class="font-weight-bold primaryText text-truncate">
                                                    {{ item.node.serialNumber }}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                                            <div class="mt-1 textLeft">
                                                <div class="font-weight-thin secondaryText  text-truncate">
                                                    {{ $t("headers.model") }}
                                                </div>
                                                <div class="font-weight-bold primaryText text-truncate">
                                                    {{ item.node.model }}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                                            <div class="mt-1 textLeft">
                                                <div class="font-weight-thin secondaryText  text-truncate">
                                                    {{ $t("headers.comment") }}
                                                </div>
                                                <div class="font-weight-bold primaryText text-truncate">
                                                    {{ item.node.comment }}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                                            <div class="mt-1 textLeft">
                                                <div class="font-weight-thin secondaryText  text-truncate">
                                                    {{ $t("headers.last_date") }}
                                                </div>
                                                <div class="font-weight-bold primaryText text-truncate">
                                                    {{ formatDateTime(item.node.lastConnectionTime) }}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                                            <div class="mt-1 textLeft">
                                                <div class="font-weight-thin secondaryText  text-truncate">
                                                    {{ $t("headers.expiration") }}
                                                </div>
                                                <div class="font-weight-bold primaryText text-truncate">
                                                    {{ formatDateTime(item.node.expiryDate) }}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                                            <div class="mt-1 textLeft">
                                                <div class="font-weight-thin secondaryText  text-truncate">
                                                    {{ $t("trackers.tenant") }}
                                                </div>
                                                <div class="font-weight-bold primaryText text-truncate">
                                                    {{ item.node.tenant.name }}
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-card>
        </div>
        <!---- DELETE PARTNER DIALOG ---->
        <!-- <DeleteTrackerDialog
            :openDeleteTrackerDialog="openDeleteDialog"
            :trackerId="tracker.id"
            v-on:refreshDataTable="closeDrawerAndRefresh"
            v-on:closeDeleteTrackerDialog="openDeleteDialog = false"
        >
        </DeleteTrackerDialog> -->
    </div>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../utils/helper";
import { TrackerPermissions } from "@/core/enum";
import LoadingBar from "@/components/base/LoadingBar.vue";
// import DeleteTrackerDialog from "@/components/trackers/delete/DeleteTrackerDialog.vue";
// import TrackerDetailsPreviewVue from "@/components/trackers/details/TrackerDetailsPreview.vue";
import Config from "@/utils/config.json";

export default {
    name: "TrackerDetailPreview",
    // Your component logic goes here
    components: {
        LoadingBar
        // DeleteTrackerDialog,
        // TrackerDetailsPreviewVue
    },
    props: {
        historyDetail: {
            type: Object
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            trackerDetail: null,
            trackerDetailError: null,
            // openDeleteDialog: false,
            tab: 0,
            tabs: [
                {
                    code: "details",
                    name: this.$t("headers.details")
                }
            ],
            trackers:{edges:[]}
        };
    },
    apollo: {
        trackers: {
            query: gql`
                query trackers($trackerUploadLog: ID) {
                    trackers(trackerUploadLog: $trackerUploadLog) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                createdAt
                                createdBy {
                                    id
                                    firstName
                                    lastName
                                    fullName
                                }
                                stockLocation {
                                    id
                                    name
                                }
                                serialNumber
                                mainTracker
                                brand
                                model
                                isBeacon
                                activatedDate
                                expiryDate
                                packageNumber
                                comment
                                isArchived
                                lastConnectionTime
                                lastBatteryDeviceTime
                                lastBatteryValue
                                lastTemperatureValue
                                lastTemperatureDeviceTime
                                lastExternalTemperatureValue
                                lastExternalTemperatureDeviceTime
                                lastProbeTemperatureValue
                                lastProbeTemperatureDeviceTime
                                lastHumidityDeviceTime
                                lastHumidityValue
                                lastLightDeviceTime
                                lastLightValue
                                lastShockDeviceTime
                                lastShockValue
                                lastLocationDeviceTime
                                tripReferenceNumber
                                tenant {
                                    id
                                    name
                                }
                                trackerUploadLog {
                                    id
                                    fileName
                                    brand
                                    createdBy {
                                        id
                                        firstName
                                        lastName
                                        fullName
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return { trackerUploadLog: this.historyDetail?.id };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackers,
            watchLoading(isLoading) {
                this.loading = isLoading;
                this.$emit("loading", isLoading);
            },
            skip() {
                return this.historyDetail?.id ? false : true;
            }
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TrackerPermissions = TrackerPermissions;
        this.formatDateTime = helper.formatDateTime;
    },
    computed: {
        me() {
            return helper.me();
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        // canDelete() {
        //     if (this.me?.isSuperuser) return true;

        //     if (
        //         this.me?.isStaff &&
        //         this.hasPermission(TrackerPermissions.DELETE_TRACKER)
        //     )
        //         return true;

        //     return false;
        // },

        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        }
    },
    watch: {},
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "tracker_details_svw"
        });
    },
    methods: {
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        }
    }
};
</script>

<style scoped>
/* Your component styles go here */
.contentBg {
    background-color: #f9fafe;
}
.primaryText {
    font-size: 12px !important;
    color: "#868599" !important;
    height: 23px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.secondaryText {
    font-size: 11px !important;
    color: "#0e1e46" !important;
    height: 21px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textLeft {
    text-align: left !important;
}

.tabItemCl {
    background-color: var(--v-background-base);
}
</style>
