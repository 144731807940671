<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col cols="12" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :hint="$t('general.search_name')"
                                v-model="searchInput"
                                append-icon="search"
                                :label="$t('general.search')"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="12" sm="5" lg="4" class="py-2 pr-4 text-right">
                    <v-row class="d-flex align-center justify-end">
                        <!-- ADD -->
                        <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            class="mr-1"
                            @click="openAddUserGroupDialog = true"
                        >
                            <v-icon left small>add_box</v-icon>
                            {{ $t("users.add_group") }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :items="userGroups.edges"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    :no-data-text="$t('loading.no_data')"
                    loader-height="2"
                    hide-default-footer
                    :loading="$apollo.queries.userGroups.loading"
                >
                    <!-- REMOVE -->
                    <template v-slot:item.delete="{ item }">
                        <v-icon @click="removeUser(item)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <!-- infinit loading trigger -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.userGroups.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    {{ $t("loading.load_more") }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <!---- ADD USER GROUP DIALOG ---->
        <AddUserGroupDialog
            :openAddUserGroupDialog="openAddUserGroupDialog"
            :user="user"
            :userGroups="userGroups"
            v-on:refreshDataTable="$apollo.queries.userGroups.refetch()"
            v-on:closeAddDialog="openAddUserGroupDialog = false"
        ></AddUserGroupDialog>

        <!---- DELETE USER GROUP DIALOG ---->
        <DeleteUserGroupDialog
            :openDeleteDialog="openDeleteUserGroupDialog"
            :group="selectedGroup"
            :userIds="selecteIds"
            v-on:refreshDataTable="$apollo.queries.userGroups.refetch()"
            v-on:closeDeleteDialog="closeAndCleanDeletedGroups"
        >
            <template #title>{{ $t("users.remove_user") }}</template>
            <template #content>{{ $t("users.sure_group") }}</template>
        </DeleteUserGroupDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddUserGroupDialog from "@/components/users/create/AddUserGroupDialog.vue";
import DeleteUserGroupDialog from "@/components/users/delete/DeleteUserGroupDialog.vue";
import Config from "@/utils/config.json";

export default {
    name: "UserGroupsTab",
    components: {
        AddUserGroupDialog,
        DeleteUserGroupDialog
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    apollo: {
        userGroups: {
            query: gql`
                query TenantUser(
                    $tenantUserId: ID!
                    $first: Int
                    $before: String
                    $after: String
                    $last: Int
                    $orderBy: String
                ) {
                    tenantUser(id: $tenantUserId) {
                        id
                        user {
                            id
                            allTenantUserGroups(
                                first: $first
                                before: $before
                                after: $after
                                last: $last
                                orderBy: $orderBy
                            ) {
                                totalCount
                                pageInfo {
                                    startCursor
                                    endCursor
                                    hasPreviousPage
                                    hasNextPage
                                }
                                edges {
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantUserId: this.user.node.id,
                    ...this.filter
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUser.user.allTenantUserGroups;
            },
            skip: false
        }
    },
    data() {
        return {
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /*********************************/
            openAddUserGroupDialog: false,
            openDeleteUserGroupDialog: false,
            openEditUserDialog: false,
            /******************************/
            /* ---- USER INPUT DATA ---- */
            /****************************/
            selectedGroup: {},
            selecteIds: [],
            searchInput: null,
            userGroups: { edges: [] },
            filter: {
                first: 20
            },
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
            headerChoices: [
                {
                    text: this.$t("general.group_name"),
                    align: "left",
                    value: "node.name"
                },
                {
                    text: "",
                    align: "left",
                    value: "delete",
                    width: "5px"
                }
            ],
            shouldLoadMore: false
        };
    },
    watch: {
        searchInput() {
            this.$apollo.userGroups ? this.$apollo.userGroups.refetch() : null;
        }
    },
    computed: {
        enableOrDisableRemove() {
            return this.removedUsers?.length > 0 ? false : true;
        },
        // infinite
        hasMoreData() {
            return this.userGroups?.pageInfo?.hasNextPage;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'user_groups_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("user_groups_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("user_groups_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editUser(item) {
            this.openEditUserDialog = true;
            this.$refs.editUserDialog.initializeUser(item);
        },
        removeUser(item) {
            this.openDeleteUserGroupDialog = true;
            this.selectedGroup = item;
            this.selecteIds.push(this.user.node.user.id);
        },
        closeAndCleanDeletedGroups() {
            this.openDeleteUserGroupDialog = false;
            this.selectedGroup = {};
            this.selecteIds = [];
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.userGroups?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.userGroups.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.userGroups?.pageInfo?.hasNextPage) {
                this.$apollo.queries.userGroups.fetchMore({
                    variables: {
                        after: this.userGroups.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
