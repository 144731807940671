import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"mt-5 mb-15 px-5"},[(!_vm.canAccess)?_c('div',[_c('NoPermission')],1):_c('div',[_c(VCard,{staticClass:"mb-5 pb-0",attrs:{"elevation":"5","rounded":"xl"}},[_c(VCardTitle,{staticClass:"background2 py-3"},[_c('div',{staticClass:"mr-2"},[_c(VIcon,[_vm._v(" settings ")])],1),_c(VSpacer),(_vm.canEdit)?_c('div',[(!_vm.isEditing)?_c(VBtn,{staticClass:"primary--text",attrs:{"rounded":"","small":"","icon":""},on:{"click":function($event){_vm.isEditing = true}}},[_c(VIcon,[_vm._v("edit")])],1):_vm._e(),(_vm.isEditing)?_c(VBtn,{staticClass:"mx-2",attrs:{"rounded":"","outlined":"","small":""},on:{"click":_vm.cancelChanges}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]):_vm._e(),(_vm.isEditing)?_c(VBtn,{attrs:{"small":"","rounded":"","outlined":"","color":"primary","loading":_vm.isLoadingEditServices},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")]):_vm._e()],1):_vm._e()],1),_c(VCardText,[_c(VRow,{staticClass:"ml-15 mb-10"},_vm._l((_vm.servicesChoices),function(service){return _c(VCol,{key:service.value,staticClass:"mt-5",attrs:{"cols":"6","sm":"12","md":"6","lg":"6"}},[_c(VSwitch,{attrs:{"label":service.text,"disabled":!_vm.isEditing,"hide-details":""},model:{value:(service.enabled),callback:function ($$v) {_vm.$set(service, "enabled", $$v)},expression:"service.enabled"}})],1)}),1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }