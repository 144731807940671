<template>
    <v-container fluid class="py-0">
        <v-form ref="loadForm" >   
            <v-row dense  class="pa-3 d-flex " v-if="disabled">
                <v-col cols="6" sm="6" md="3" lg="3">
                    <div class="font-weight-bold">
                        {{ $t('forms.trade')}}
                    </div>
                    <div v-if="tripLoadPoint.trade">
                        {{tripLoadPoint.trade}}
                    </div>   
                    <div v-else>
                        -
                    </div>   
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6">
                    <v-alert
                        v-if="hasMissingFields && disabled"
                        outlined
                        type="warning"
                        border="left"
                        dense
                    >
                       This information is needed in order to track the vessel.   
                    </v-alert>
                </v-col>
                <!-- <v-col cols="6" sm="6" md="3" lg="3">
                    <div class="font-weight-bold">
                        Contact Person
                    </div>
                    <div v-if="tripLoadPoint.logco">
                        {{tripLoadPoint.logco}}
                    </div>
                    <div v-else>
                        -
                    </div>       
                </v-col>
                <v-col  cols="6" sm="6" md="3" lg="3">
                    <div class="font-weight-bold">
                        Load Point
                    </div>
                    <div v-if="tripLoadPoint.loadPoint">
                        {{tripLoadPoint.loadPoint}}
                    </div>
                    <div v-else>
                        -
                    </div>       
                </v-col>
                <v-col cols="6" sm="6" md="3" lg="3">
                    <div class="font-weight-bold">
                        Load Date
                    </div>
                    <div v-if="tripLoadPoint.loadDate ">
                        {{tripLoadPoint.loadDate | moment("YYYY-MM-DD")}}
                    </div>
                    <div v-else>
                        -
                    </div>     
                    
                </v-col> -->
            </v-row>
            <v-row dense class="mt-3 pl-1 " v-else>
                <v-col cols="6" sm="6" md="3" lg="3">
                    <v-text-field
                        v-model="tripLoadPoint.trade"
                        :label="$t('forms.trade')"
                        outlined
                        dense
                        
                    ></v-text-field>
                </v-col>
                <!-- <v-col cols="6" sm="6" md="3" lg="3">
                    <v-text-field
                        v-model="tripLoadPoint.logco"
                        label="Contact Person"
                        outlined
                        dense
                       
                    ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="3" lg="3">
                    <v-text-field
                        v-model="tripLoadPoint.loadPoint"
                        label="Load Point"
                        outlined
                        dense
                        
                    ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="3" lg="3">
                    <DateComponent
                        v-model="tripLoadPoint.loadDate"
                        label="Load Date"
                        :icon="'calendar_month'"
                        :outlined="true"
                        :dense = true
                        
                    ></DateComponent>
                </v-col> -->
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
//import DateComponent from "@/components/base/DateComponent.vue"; 


export default {
    components:{
        //DateComponent
    },
    props:{
        value: {
            type: Object,       
        },
        disabled:{
            type:Boolean,
            default:false
        },
        hasMissingFields:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            
           
        };
    },
    computed:{
        tripLoadPoint: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },   
    },
    methods: {
        resetForm(){
            this.$refs.loadForm.reset();
        }
    },
};
</script>
