<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col cols="12" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :hint="$t('general.search_name')"
                                v-model="searchInput"
                                append-icon="search"
                                :label="$t('general.search')"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="12" sm="5" lg="4" class="py-2 pr-4 text-right">
                    <v-row class="d-flex align-center justify-end">
                        <!-- ADD -->
                        <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            class="mr-1"
                            @click="openAddGroupUserDialog = true"
                        >
                            <v-icon left small>add_box</v-icon>
                            {{ $t("partners.add_user1") }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :items="groupUsers.edges"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    loader-height="2"
                    hide-default-footer
                    :loading="$apollo.queries.groupUsers.loading"
                >
                    <!-- CHECKBOX COLUMN -->
                    <template v-slot:item.action="{ item }">
                        <v-checkbox v-model="selectedUsers" class="mx-0" :value="item.node.id"></v-checkbox>
                    </template>

                    <!-- EDIT COLUMN -->
                    <template v-slot:item.edit="{ item }">
                        <v-icon @click="editUser(item)">edit</v-icon>
                    </template>
                    <!-- REMOVE -->
                    <template v-slot:item.delete="{ item }">
                        <v-icon @click="removeUser(item)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!---- ADD GROUP USER DIALOG ---->
        <AddGroupUserDialog
            :openAddGroupUserDialog="openAddGroupUserDialog"
            :group="group"
            :groupUsers="groupUsers"
            v-on:refreshGroupUsersTable="$apollo.queries.groupUsers.refetch()"
            v-on:closeAddGroupUserDialog="openAddGroupUserDialog = false"
        ></AddGroupUserDialog>

        <!---- EDIT GROUP USER DIALOG ---->
        <EditUserDialog
            ref="editUserDialog"
            :openEditUserDialog="openEditUserDialog"
            v-on:closeEditUserDialog="openEditUserDialog = false"
            v-on:refreshDataTable="$apollo.queries.groupUsers.refresh()"
        ></EditUserDialog>

        <!---- DELETE GROUP USER DIALOG ---->
        <DeleteGroupUserDialog
            :openDeleteDialog="openDeleteGroupUserDialog"
            :group="group"
            :usersID="selectedUsers"
            v-on:refreshDataTable="$apollo.queries.groupUsers.refetch()"
            v-on:closeDeleteGroupUserDialog="closeAndCleanDeletedUsers"
        >
            <template #title>{{ $t("groups.delete_partner") }}</template>
            <template #content>{{ $t("groups.sure_user") }}</template>
        </DeleteGroupUserDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddGroupUserDialog from "@/components/groups/create/AddGroupUserDialog.vue";
import DeleteGroupUserDialog from "@/components/groups/delete/DeleteGroupUserDialog.vue";
import EditUserDialog from "@/components/users/update/EditUserDialog.vue";
import Config from "@/utils/config.json";

export default {
    name: "GroupUsersTab",
    components: {
        AddGroupUserDialog,
        DeleteGroupUserDialog,
        EditUserDialog
    },
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    apollo: {
        groupUsers: {
            query: gql`
                query TenantUserGroup($tenantUserGroupId: ID!, $first: Int, $search: String) {
                    tenantUserGroup(id: $tenantUserGroupId) {
                        id
                        name
                        staffs(first: $first, search: $search) {
                            pageInfo {
                                endCursor
                                hasNextPage
                                hasPreviousPage
                                startCursor
                            }
                            edges {
                                node {
                                    id
                                    user {
                                        id
                                        fullName
                                        firstName
                                        lastName
                                        email
                                        isTenantAdmin
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantUserGroupId: this.group.node.id,
                    first: 20,
                    search: this.searchInput
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUserGroup.staffs;
            },
            skip: false
        }
    },
    data() {
        return {
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /*********************************/
            openAddGroupUserDialog: false,
            openDeleteGroupUserDialog: false,
            openEditUserDialog: false,
            /******************************/
            /* ---- USER INPUT DATA ---- */
            /****************************/
            selectedUsers: [],
            searchInput: null,
            groupUsers: { edges: [] },
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
            headerChoices: [
                // {
                //     text: "",
                //     align: "center",
                //     value: "action",
                //     width: "5px",
                //     sortable: false
                // },
                {
                    text: this.$t("headers.first_name"),
                    align: "center",
                    value: "node.user.firstName",
                    sortable: false
                },
                {
                    text: this.$t("headers.last_name"),
                    align: "center",
                    value: "node.user.lastName",
                    sortable: false
                },
                {
                    text: "E-mail",
                    align: "center",
                    value: "node.user.email",
                    sortable: false
                },
                // {
                //     text: "Require2FA",
                //     align: "center",
                //     value: "node.user.require2fa",
                //     sortable: false
                // },
                {
                    text: "",
                    align: "center",
                    value: "edit",
                    width: "5px",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "delete",
                    width: "5px",
                    sortable: false
                }
            ]
        };
    },
    watch: {
        searchInput() {
            this.$apollo.groupUsers ? this.$apollo.groupUsers.refetch() : null;
        }
    },
    computed: {
        enableOrDisableRemove() {
            return this.removedUsers?.length > 0 ? false : true;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "group_users_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("group_users_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("group_users_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        editUser(item) {
            this.openEditUserDialog = true;
            this.$refs.editUserDialog.initializeUser(item);
        },
        removeUser(item) {
            this.openDeleteGroupUserDialog = true;
            this.selectedUsers.push(item.node.id);
        },
        closeAndCleanDeletedUsers() {
            this.openDeleteGroupUserDialog = false;
            this.selectedUsers = [];
        }
    }
};
</script>
