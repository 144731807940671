import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.canAccess)?_c('div',[_c('NoPermission')],1):_c('div',{staticClass:"px-5 mb-15"},[_c(VCard,{staticClass:"py-4 mt-4",attrs:{"elevation":"5","outlined":""}},[_c(VRow,{staticClass:"px-5 d-flex align-center",attrs:{"align":"start"}},[_c(VCol,{attrs:{"cols":"12","sm":"5","lg":"5"}},[_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"subtitle-2",attrs:{"hint":_vm.$t('general.search_name'),"append-icon":"search","label":_vm.$t('general.search'),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1)],1)],1),_c(VSpacer),_c(VCol,{staticClass:"py-2 pr-4 text-right",attrs:{"cols":"12","sm":"5","lg":"4"}},[_c(VRow,{staticClass:"d-flex align-center justify-end"},[(_vm.canEdit)?_c(VBtn,{staticClass:"mr-1",attrs:{"small":"","outlined":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.openAddActionGroupDialog = true}}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("add_box")]),_vm._v(" "+_vm._s(_vm.$t("alert_rules.add_action_group"))+" ")],1):_vm._e()],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2 mb-5 pb-5",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,[_c(VDataTable,{attrs:{"items":_vm.actionGroups.edges,"headers":_vm.headerChoices,"disable-pagination":"","loading-text":_vm.$t('loading.loading1'),"loader-height":"2","hide-default-footer":"","loading":_vm.$apollo.queries.actionGroups.loading},scopedSlots:_vm._u([{key:"item.node.tenantActionGroup.shouldEmailNotification",fn:function(ref){
                    var item = ref.item;
return [(
                            item.node.tenantActionGroup
                                .shouldEmailNotification
                        )?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])]}},{key:"item.node.tenantActionGroup.shouldInboxNotification",fn:function(ref){
                    var item = ref.item;
return [(
                            item.node.tenantActionGroup
                                .shouldInboxNotification
                        )?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])]}},{key:"item.node.tenantActionGroup.users",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.node.tenantActionGroup.tenantActionGroupUserSet .totalCount))])]}},{key:"item.node.tenantActionGroup.externalEmails",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.calculateNumberOfEmails(item.node.tenantActionGroup)))])]}},{key:"item.delete",fn:function(ref){
                    var item = ref.item;
return [(_vm.canEdit)?_c(VIcon,{on:{"click":function($event){return _vm.removeActionGroup(item)}}},[_vm._v("delete")]):_vm._e()]}}])})],1)],1),_c('AddAlertRuleActionGroupDialog',{attrs:{"openAddActionGroupDialog":_vm.openAddActionGroupDialog,"actionGroups":_vm.actionGroups,"alertRule":_vm.alertRule},on:{"refreshActionGroupsTable":function($event){return _vm.refreshTables()},"closeAddActionGroupDialog":function($event){_vm.openAddActionGroupDialog = false}}}),_c('DeleteAlertRuleActionGroupDialog',{attrs:{"openDeleteDialog":_vm.openDeleteActionGroupDialog,"alertRule":_vm.alertRule,"actionGroup":_vm.actionGroup},on:{"refreshDataTable":function($event){return _vm.refreshTables()},"closeDeleteActionGroupDialog":_vm.closeAndCleanDeletedActionGroups},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("alert_rules.delete_action_group")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("alert_rules.sure_action_group")))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }