import store from "../store/index.js";
var moment = require("moment-timezone");
import gql from "graphql-tag";
import { apolloClient } from "../vue-apollo.js";

const rules = {
    required: v => !this.isEmpty(v) || "This field is required",
    listRequired: v => (v || "").length >= 1 || `This field is required`,
    minLength: len => v => (v || "").length >= len || `Require at least ${len}`,
    maxLength: len => v => (v || "").length <= len || "Too long"
};
const typeOf = obj => {
    return {}.toString
        .call(obj)
        .split(" ")[1]
        .slice(0, -1)
        .toLowerCase();
};
const formatTemperature = val => {
    let _preferences = store.state.user.me?.preferences;
    if (_preferences == null || _preferences == "") {
        return val;
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.temperature != null)
            if (preferences.temperature.unit == "fahrenheit") return (val * 9) / 5 + 32;
            else return val;
        else return val;
    }
};
const fahrenheitToCelsius = val => {
    return Math.round(((val - 32) * 5) / 9);
};
const celsiusToFahrenheit = val => {
    return Math.round((val * 9) / 5 + 32);
};
const getSelectedSensors = () => {
    let _preferences = store.state.user.me?.preferences;
    if (_preferences == null || _preferences == "") {
        return null;
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.selectedSensors != null) return preferences.selectedSensors;
        else return null;
    }
};
const getTemperatureSymbol = () => {
    let _preferences = store.state.user.me?.preferences;
    if (_preferences == null) {
        return "ºC";
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.temperature != null) return preferences.temperature.symbol;
        else return "ºC";
    }
};
const getViewType = key => {
    let _preferences = store.state.user.me?.preferences;
    let viewType = "tab";
    if (_preferences != null && _preferences != "") {
        let preferences = JSON.parse(_preferences);
        if (preferences[key] != null) {
            viewType = preferences[key];
        }
    }
    return viewType;
};
const checkIfTripIsShared = tripId => {
    return apolloClient
        .mutate({
            mutation: gql`
                mutation checkIfTripIsShared($input: CheckIfTripIsSharedInput!) {
                    checkIfTripIsShared(input: $input) {
                        isShared
                    }
                }
            `,
            variables: {
                input: {
                    tripId: tripId
                }
            }
        })
        .then(res => {
            return res?.data?.checkIfTripIsShared?.isShared;
        })
        .catch(err => {
            console.error(err);
            return null;
        });
};
const getFilterChoices = (defaultChoices, key) => {
    try {
        let _preferences = store.state.user.me?.preferences;
        let _choices = [];
        if (_preferences != null && _preferences != "") {
            let preferences = JSON.parse(_preferences);
            if (preferences[key] != null) {
                // preferences[key].forEach(item => {
                //     let matchingChoice = defaultChoices.find(a => a.code == item.code);
                //     if (matchingChoice != null) {
                //         let tag = matchingChoice.tag;
                //         item.tag = tag;
                //     } else {
                //         console.error('No matching choice found for item:', item);
                //     }
                // })
                let defaultChoicesValues = defaultChoices.map(a => a.code);
                preferences[key].forEach(item => {
                    if (defaultChoicesValues.find(a => a == item.code)) {
                        _choices.push(item);
                    }
                    if (item.tag == null) {
                        let tag = defaultChoices.find(a => a.code == item.code)?.tag;
                        item.tag = tag;
                    }
                });
                defaultChoices.forEach(choice => {
                    let checked = false;
                    _choices.forEach(pref => {
                        if (pref.code == choice.code) {
                            checked = true;
                        }
                    });
                    if (!checked) {
                        _choices.push(choice);
                    }
                });
            }
        }
        if (_choices.length == 0) {
            _choices = defaultChoices;
        } else {
            // deduplicate
            let _choices2 = [];
            _choices.forEach(choice => {
                if (!_choices2.find(a => a.code == choice.code)) {
                    _choices2.push(choice);
                }
            });

            _choices = _choices2;
        }
        return _choices;
    } catch (e) {
        console.log("error", e);
    }
};
const getTableHeaders = (key, defaultHeaders) => {
    let _preferences = store.state.user.me?.preferences;
    let _headers = [];
    if (_preferences != null && _preferences != "") {
        let preferences = JSON.parse(_preferences);
        if (preferences[key] != null) {
            preferences[key].forEach(item => {
                // preferences[key].forEach(item => {
                //     let matchingChoice = defaultHeaders.find(a => a.code == item.code);
                //     if (matchingChoice != null) {
                //         let tag = matchingChoice.tag;
                //         item.tag = tag;
                //     } else {
                //         console.error('No matching choice found for item:', item);
                //     }
                // })
                if (defaultHeaders.find(a => a.code == item.code)) {
                    _headers.push(item);
                }
                if (item.tag == null) {
                    let tag = defaultHeaders.find(a => a.code == item.code)?.tag;
                    item.tag = tag;
                }
            });
            defaultHeaders.forEach(header => {
                let checked = false;
                _headers.forEach(pref => {
                    if (pref.code == header.value) {
                        checked = true;
                    }
                });
                if (!checked) {
                    _headers.push(header);
                }
            });
        }
    }
    if (_headers.length == 0) {
        _headers = defaultHeaders;
    } else {
        // deduplicate
        let _headers2 = [];
        _headers.forEach(header => {
            if (!_headers2.find(a => a.code == header.code)) {
                _headers2.push(header);
            }
        });

        _headers = _headers2;
    }

    return _headers;
};
const getTab = (choices, key) => {
    const _preferences = store.state.user.me?.preferences;
    // If preferences are available and not empty, parse and process them
    if (_preferences) {
        const preferences = JSON.parse(_preferences);
        // If there are specific preferences for the provided key
        if (preferences[key]) {
            // Filter and map the preferences to align with the default choices
            return preferences[key]
                .filter(({ value }) => choices.some(choice => choice.value === value))
                .map(({ text, value }, id) => ({
                    id,
                    text: text || choices.find(choice => choice.value === value)?.text,
                    value
                }));
        }
    }

    return choices;
};
const getWeatherLocationOrder = () => {
    let _preferences = store.state.user.me?.preferences;
    if (_preferences != null) {
        let preferences = JSON.parse(_preferences);
        return preferences.weatherLocations;
    }
};
const bytesToSize = (bytes, seperator = "") => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)}${seperator} ${sizes[i]}`;
};
const formatDateTime = datetime => {
    if (!moment.utc(datetime).isValid()) {
        return null;
    }
    let _preferences = store.state.user.me?.preferences;
    let timezoneArea = null;
    let format = null;
    if (_preferences == null || _preferences == "") {
        timezoneArea = moment.tz.guess();
        format = "YYYY/MM/DD";
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.timezone != null) timezoneArea = preferences?.timezone;
        else timezoneArea = moment.tz.guess();
        if (preferences?.dateFormat != null) format = preferences?.dateFormat;
        else format = "YYYY/MM/DD";
    }
    let datetimeToMoment = moment
        .utc(datetime)
        .tz(timezoneArea)
        .format(format + " HH:mm");

    return datetimeToMoment;
};

const formatDateTimeWithoutTimezone = datetime => {
    if (!moment.utc(datetime).isValid()) {
        return null;
    }
    let _preferences = store.state.user.me?.preferences;
    let format = null;
    if (_preferences == null || _preferences == "") {
        format = "YYYY/MM/DD";
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.dateFormat != null) format = preferences?.dateFormat;
        else format = "YYYY/MM/DD";
    }
    let datetimeToMoment = moment.utc(datetime).format(format + " HH:mm");

    return datetimeToMoment;
};
const formatDateTimeIso = datetime => {
    if (!moment.utc(datetime).isValid()) {
        return null;
    }
    let _preferences = store.state.user.me?.preferences;
    let timezoneArea = null;
    let format = null;
    if (_preferences == null || _preferences == "") {
        timezoneArea = moment.tz.guess();
        format = "YYYY/MM/DD";
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.timezone != null) timezoneArea = preferences?.timezone;
        else timezoneArea = moment.tz.guess();
    }

    // format = "2023-07-10T14:23:08+00:00";
    let datetimeIso = moment
        .utc(datetime)
        .tz(timezoneArea)
        .format();

    return datetimeIso;
};
const getTimezone = () => {
    let _preferences = store.state.user.me?.preferences;
    let timezoneArea = null;
    if (_preferences == null || _preferences == "") {
        timezoneArea = moment.tz.guess();
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.timezone != null) timezoneArea = preferences?.timezone;
        else timezoneArea = moment.tz.guess();
    }
    return timezoneArea;
};
const getDateFormat = () => {
    let _preferences = store.state.user.me?.preferences;
    let format = null;
    if (_preferences == null || _preferences == "") {
        format = "YYYY/MM/DD";
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.dateFormat != null) format = preferences?.dateFormat;
        else format = "YYYY/MM/DD";
    }
    return format;
};
const updateDateTimeTimezoneOld = (datetime) => {
    if (!moment.utc(datetime).isValid()) {
        return null;
    }
    let _preferences = store.state.user.me?.preferences;
    let timezoneArea = null;
    if (_preferences == null || _preferences == "") {
        timezoneArea = moment.tz.guess();
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.timezone != null)
            timezoneArea = preferences?.timezone;
        else timezoneArea = moment.tz.guess();
    }
    let timeDiff = moment.tz(timezoneArea).utcOffset();

    let datetimeToTimezone = moment(datetime)
        .add(timeDiff, "minutes")
        .format();

    return datetimeToTimezone;
};

const updateDateTimeTimezone = datetime => {
    if (!moment.utc(datetime).isValid()) {
        return null;
    }
    let _preferences = store.state.user.me?.preferences;
    let timezoneArea = moment.tz.guess();
    if (_preferences) {
        let preferences = JSON.parse(_preferences);
        if (preferences?.timezone) {
            timezoneArea = preferences.timezone;
        }
    }
    // Convert datetime to the timezone area and format it
    let datetimeToTimezone = moment
        .utc(datetime)
        .tz(timezoneArea)
        .format();

    return datetimeToTimezone;
};
const convertDateTimeTimezoneToMilliseconds = datetime => {
    // Step 1: Check if the date is in UTC
    if (!moment.utc(datetime, moment.ISO_8601, true).isValid()) {
        return null;
    }

    // Step 2: Get timezone preferences
    let _preferences = store.state.user.me?.preferences;
    let timezoneArea = null;
    if (_preferences == null || _preferences === "") {
        timezoneArea = moment.tz.guess();
    } else {
        let preferences = JSON.parse(_preferences);
        if (preferences?.timezone != null) {
            timezoneArea = preferences.timezone;
        } else {
            timezoneArea = moment.tz.guess();
        }
    }

    // Step 3: Convert datetime to the timezone area
    const updatedDatetime = moment.utc(datetime).tz(timezoneArea);

    // Step 4:  Manually calculate milliseconds
    const year = updatedDatetime.year();
    const month = updatedDatetime.month();
    const day = updatedDatetime.date();
    const hour = updatedDatetime.hour();
    const minute = updatedDatetime.minute();
    const second = updatedDatetime.second();
    const millisecond = updatedDatetime.millisecond();

    const dateObject = new Date(year, month, day, hour, minute, second, millisecond);
    const milliseconds = dateObject.getTime();

    return milliseconds;
};

const relativeTime = dateTime => {
    let relative = moment(dateTime)
        .locale("en")
        .startOf("hour")
        .fromNow();
    return relative;
};
const convertDateToUTC = date => {
    //date + By default time is 00:00 => 2022-08-15T00:00:00.000Z
    let utcDate = "";
    if (date === null || date === undefined || date.length === 0) {
        utcDate = null;
    } else {
        utcDate = new Date(date).toISOString();
    }
    return utcDate;
};
const convertDateAndTimeNowToUTC = date => {
    //date + By default is using 'Now' to set current time  => 2022-08-15T07:23:00.000Z
    let utcDateTime = "";
    let now = new Date();
    let time = `${now.getHours()}` + ":" + `${now.getMinutes()}`;

    if (date === null || date === undefined || date.length === 0) {
        utcDateTime = null;
    } else {
        let concat = date + " " + time;
        utcDateTime = new Date(concat).toISOString();
    }
    return utcDateTime;
};
const concatDateAndTimeAndConvertToUTC = (date, time) => {
    //date + time => 2022-08-15T07:23:00.000Z
    let utcDateTime = "";

    if (date === null || date === undefined || date.length === 0) {
        utcDateTime = null;
    } else {
        if (time === null || time === undefined || time.length === 0) {
            time = "00:00:00";
        } else {
            time = time + ":00";
        }
        let dateTimeString = `${date}T${time}Z`;
        let timeDiff = moment.tz(this.getTimezone()).utcOffset();

        utcDateTime = moment(dateTimeString)
            .subtract(timeDiff, "minutes")
            .format();
    }
    return utcDateTime;
};
const setFilterValues = (filter, value) => {
    let _filters = localStorage.getItem("filters");
    if (_filters == "") {
        _filters == null;
    } else _filters = JSON.parse(_filters);

    if (_filters === null) {
        _filters = Object.assign(_filters || {});
        _filters[filter] = value;
    } else if (filter != null) {
        _filters[filter] = value;
    }
    localStorage.setItem("filters", JSON.stringify(_filters));
};
const getFilterValues = filter => {
    let _filters = localStorage.getItem("filters");
    if (_filters == "") {
        _filters == null;
    } else _filters = JSON.parse(_filters);

    if (_filters === null) {
        return null;
    } else if (filter != null) {
        return _filters[filter];
    }
};
const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const isEmpty = input => {
    // array
    if (typeOf(input) === "array") {
        return input.length === 0;
    }

    // int
    if (typeOf(input) === "number") {
        if (input === 0) {
            return false;
        }

        return !input;
    }

    // undefined, null, string, object
    return !input || Object.keys(input).length === 0;
};
const hasPermission = (codename, manageCodeName = "") => {
    if (!store.state.user.me) {
        return false;
    }
    if (store.state.user.me.isSuperuser) {
        return true;
    }

    if (store.state.user.me.isTenantAdmin && manageCodeName !== "") {
        return hasOrganisationPermission(manageCodeName);
    } else if (store.state.user.me.isTenantAdmin && manageCodeName === "") {
        return true;
    } else if (store.state.user.me.isPartnerUser && manageCodeName !== "") {
        return hasOrganisationPermission(manageCodeName);
    }

    const _permissions = store.state.user.me.allPermissions || [];

    let _check_permission = _permissions.includes(codename);

    return _check_permission ? true : false;
};
const hasOrganisationPermission = (codename, permissions = []) => {
    if (!store.state.user.me) {
        return false;
    }

    const _permissions = store.state.user.me?.tenant?.organisationPermissions
        ? store.state.user.me?.tenant?.organisationPermissions
        : store.state.user.me?.partnerOrganisationPermissions
        ? store.state.user.me?.partnerOrganisationPermissions
        : permissions;

    let _check_permission = _permissions.includes(codename);

    return _check_permission ? true : false;
};
const convertToCSV = objArray => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
            if (line != "") line += ";";

            line += array[i][index];
        }

        str += line + "\r\n";
    }

    return str;
};
const exportCSVFile = (headers, items, fileTitle) => {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = this.convertToCSV(jsonObject);

    csv = "sep=;\r\n" + csv;

    //TODO: add sep=, in first line

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
const isSuperUser = () => {
    return (store.state.user?.me?.isSuperuser && store.state.user?.me?.isStaff) || false;
};
const isStaff = () => {
    return store.state.user?.me?.isStaff || false;
};
const isTenantUser = () => {
    return store.state.user?.me?.isTenantUser || false;
};
const isTenantAdminUser = () => {
    return store.state.user?.me?.isTenantAdmin || false;
};
const isTenantPartner = () => {
    return store.state.user?.me?.isPartnerUser || false;
};
const me = () => {
    if ("me" in store.state.user) {
        return store.state.user.me || {};
    }
    // if (localStorage.getItem("me")) {
    //     return JSON.parse(localStorage.getItem("me")) || {};
    // }
};
const isLoggedIn = () => {
    return store.state.user.loggedIn;
};
const unitPerBrand = brand => {
    switch (brand) {
        case "Emerson":
            return "%";
        case "Tive":
            return "%";
        case "SensiTech":
            return "%";
        case "DeltaTrak":
            return "";
        default:
            return "%";
    }
};
const months = value => {
    let _month = [
        { value: 0, text: "Jan" },
        { value: 1, text: "Feb" },
        { value: 2, text: "Mar" },
        { value: 3, text: "Apr" },
        { value: 4, text: "May" },
        { value: 5, text: "Jun" },
        { value: 6, text: "Jul" },
        { value: 7, text: "Aug" },
        { value: 8, text: "Sep" },
        { value: 9, text: "Oct" },
        { value: 10, text: "Nov" },
        { value: 11, text: "Dec" }
    ];
    return _month[value].text;
};
const getPreferenceByKey = key => {
    let _preferences = store.state.user?.me;
    if (_preferences == "") {
        _preferences == null;
    } else {
        _preferences = JSON.parse(_preferences);
    }

    return _preferences ? _preferences[key] : null;
};
const calculateDelaysInDay = (originalDate, estimatedDate, actualDate) => {
    let Difference_In_Days = null;
    if (
        originalDate === null ||
        estimatedDate === null ||
        originalDate === undefined ||
        estimatedDate === undefined ||
        originalDate.length === 0 ||
        estimatedDate.length === 0
    ) {
        Difference_In_Days = null;
    } else if (actualDate === null || actualDate === undefined || actualDate.length === 0) {
        let _originalDate = new Date(originalDate);
        let _estimatedDate = new Date(estimatedDate);

        // Clear the time part
        _originalDate.setHours(0, 0, 0, 0);
        _estimatedDate.setHours(0, 0, 0, 0);

        let Difference_In_Time = _estimatedDate - _originalDate;
        Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    } else {
        let _estimatedDate = estimatedDate ? new Date(estimatedDate) : new Date(originalDate);
        let _actualDate = new Date(actualDate);

        // Clear the time part
        _actualDate.setHours(0, 0, 0, 0);
        _estimatedDate.setHours(0, 0, 0, 0);

        let Difference_In_Time = _actualDate - _estimatedDate;
        Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    }

    return Difference_In_Days?.toFixed(0);
};
const calculateDelays = (originalDate, estimatedDate, actualDate) => {
    let Difference_In_Days = null;
    if (
        originalDate === null ||
        estimatedDate === null ||
        originalDate === undefined ||
        estimatedDate === undefined ||
        originalDate.length === 0 ||
        estimatedDate.length === 0
    ) {
        Difference_In_Days = null;
    } else if (actualDate === null || actualDate === undefined || actualDate.length === 0) {
        let _originalDate = new Date(originalDate);
        let _estimatedDate = new Date(estimatedDate);
        let Difference_In_Time = _estimatedDate.getTime() - _originalDate.getTime();
        Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    } else {
        let _estimatedDate = estimatedDate ? new Date(estimatedDate) : new Date(originalDate);
        let _actualDate = new Date(actualDate);
        let Difference_In_Time = _actualDate.getTime() - _estimatedDate.getTime();
        Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    }

    return Difference_In_Days?.toFixed(0);
};
const formatAirwaybillNumber = awb => {
    if (awb === null || awb === "" || awb === undefined) {
        return "";
    }

    return awb.replace(/(.{3})(.{0})/, "$1-$2");
};

const removeHifenFromAirwaybill = awb => {
    if (awb === null || awb === "" || awb === undefined) {
        return null;
    }

    return awb
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9]/g, "");
};

const isValidJson = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
const isDateValid = dateString => {
    var dateObj = new Date(dateString);
    return !isNaN(dateObj.getTime());
};

export default {
    rules,
    typeOf,
    formatTemperature,
    fahrenheitToCelsius,
    celsiusToFahrenheit,
    getSelectedSensors,
    getTemperatureSymbol,
    getViewType,
    checkIfTripIsShared,
    getFilterChoices,
    getTableHeaders,
    getTab,
    getWeatherLocationOrder,
    bytesToSize,
    formatDateTime,
    formatDateTimeWithoutTimezone,
    formatDateTimeIso,
    getTimezone,
    getDateFormat,
    updateDateTimeTimezone,
    convertDateTimeTimezoneToMilliseconds,
    updateDateTimeTimezoneOld,
    relativeTime,
    convertDateToUTC,
    convertDateAndTimeNowToUTC,
    calculateDelays,
    calculateDelaysInDay,
    concatDateAndTimeAndConvertToUTC,
    getPreferenceByKey,
    objectsEqual(o1, o2) {
        if (o2 == null) return false;
        return (
            Object.keys(o1).length === Object.keys(o2).length &&
            Object.keys(o1).every(p => JSON.stringify(o1[p]) === JSON.stringify(o2[p]))
        );
    },
    updateMyPreferences(value) {
        let _preferences = store.state.user.me?.preferences;

        if (_preferences === undefined) {
            console.log("store.state.user.me?.preferences is undefined");
            return;
        }

        if (_preferences == "") {
            _preferences = null;
        } else {
            try {
                _preferences = JSON.parse(_preferences);
            } catch (e) {
                console.log("Error parsing _preferences:", e);
                return;
            }
        }

        if (_preferences === null) {
            _preferences = Object.assign(_preferences || {}, value);
            store
                .dispatch("user/updateMyPreferences", {
                    preferences: JSON.stringify(_preferences)
                })
                .then(() => {})
                .catch(error => {
                    console.log("updateMyPreferences catched error: ", error);
                });
        } else if (value != null) {
            let keysToCheck = Object.keys(value);
            for (let key of keysToCheck) {
                if (
                    _preferences == null ||
                    _preferences[key] == null ||
                    !this.objectsEqual(value[key], _preferences[key])
                ) {
                    _preferences = Object.assign(_preferences || {}, value);
                    store
                        .dispatch("user/updateMyPreferences", {
                            preferences: JSON.stringify(_preferences)
                        })
                        .then(() => {})
                        .catch(error => {
                            console.log("updateMyPreferences catched error: ", error);
                        });
                }
            }
        }
    },
    convertDateTimeToUTC(dateTime) {
        //Thu Aug 15 2022 07:23:00 GMT+0000 => 2022-08-15T07:23:00.000Z
        let utcDateTime = "";
        if (dateTime === null || dateTime === undefined || dateTime.length === 0) {
            utcDateTime = null;
        } else {
            let timeDiff = moment.tz(this.getTimezone()).utcOffset();
            let [date, time] = dateTime.split(" ");
            let _newDate = moment(date, this.getDateFormat());
            let _DateFormat = moment(_newDate, ["YYYY/MM/DD", "MM/DD/YYYY", "DD/MM/YYYY"]).toDate();
            let _date = moment(_DateFormat).format("YYYY-MM-DD");
            let _time = time + ":00";
            let _dateTime = `${_date}T${_time}Z`;

            return (utcDateTime = moment(_dateTime)
                .subtract(timeDiff, "minutes")
                .format());
        }
        return utcDateTime;
    },
    setFilterValues,
    getFilterValues,
    numberWithCommas,
    isEmpty,
    hasPermission,
    hasOrganisationPermission,
    pushTo(routeName, params = "") {
        if (this.$router.currentRoute.name !== routeName) {
            if (params !== "") {
                this.$router.push({
                    name: routeName,
                    query: params
                });
            } else {
                this.$router.push({
                    name: routeName
                });
            }
        }
    },
    convertToCSV,
    exportCSVFile,
    isSuperUser,
    isStaff,
    isTenantUser,
    isTenantAdminUser,
    isTenantPartner,
    me,
    isLoggedIn,
    unitPerBrand,
    months,
    formatAirwaybillNumber,
    removeHifenFromAirwaybill,
    isValidJson,
    isDateValid,
    qxConvertDateTimeToUTC(dateTime){
        //Thu Aug 15 2022 07:23:00 GMT+0000 => 2022-08-15T07:23:00.000Z    
        let utcDateTime = "";
        if (dateTime === null || dateTime === undefined || dateTime.length === 0) {
            utcDateTime = null;
        } else {
            // let timeDiff = moment.tz(this.getTimezone()).utcOffset();
            let [date, time] = dateTime.split(" ");
            let _newDate = moment(date, this.getDateFormat())
            let _DateFormat = moment(_newDate, [
                "YYYY/MM/DD",
                "MM/DD/YYYY",
                "DD/MM/YYYY"
            ]).toDate();
            let _date = moment(_DateFormat).format("YYYY-MM-DD");
            let _time = time + ":00";
            let _dateTime = `${_date}T${_time}Z`;
            
           return utcDateTime = _dateTime;
        }
        return utcDateTime;
    },
    qxFormatDateTime(datetime) {
        if (!moment.utc(datetime).isValid()) {
            return null;
        }
        let _preferences = store.state.user.me?.preferences;
        let timezoneArea = null;
        let format = null;
        if (_preferences == null || _preferences == "") {
            timezoneArea = moment.tz.guess();
            format = "YYYY/MM/DD";
        } else {
            let preferences = JSON.parse(_preferences);
            if (preferences?.timezone != null) timezoneArea = preferences?.timezone;
            else timezoneArea = moment.tz.guess();
            if (preferences?.dateFormat != null) format = preferences?.dateFormat;
            else format = "YYYY/MM/DD";
        }
        
        let [date, time] = datetime.split("T");
        let secondColonIndex = time.indexOf(':', time.indexOf(':') + 1);
        let newDateTime = date+" "+time.substring(0, secondColonIndex);
        
        let datetimeToMoment = moment(newDateTime)
            // .tz(timezoneArea)
            .format(format + " HH:mm");
        
        return datetimeToMoment;
    }
};
