import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"form",model:{value:(_vm.tripValidate),callback:function ($$v) {_vm.tripValidate=$$v},expression:"tripValidate"}},[_c(VCard,{staticClass:"pr-3 pt-3 pb-5",attrs:{"flat":"","outlined":""}},[_c(VTimeline,{attrs:{"dense":"","align-top":""}},[_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("commute")])]},proxy:true}])},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"11"}},[_c(VAutocomplete,{attrs:{"items":_vm.transportModeChoices,"label":_vm.$t('trips.transport_mode') + '*',"search-input":_vm.trip.transportMode,"outlined":"","rules":[_vm.rules.required],"dense":"","hide-selected":""},on:{"change":function($event){return _vm.filterTransportMode(_vm.trip.transportMode)}},model:{value:(_vm.trip.transportMode),callback:function ($$v) {_vm.$set(_vm.trip, "transportMode", $$v)},expression:"trip.transportMode"}})],1)],1)],1),_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(!_vm.trip.transportMode)?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("remove")]):_vm._e(),(_vm.trip.transportMode === 'other')?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("airport_shuttle")]):_vm._e(),(_vm.trip.transportMode === 'sea')?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("directions_boat")]):_vm._e(),(_vm.trip.transportMode === 'air')?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("flight")]):_vm._e(),(_vm.trip.transportMode === 'road')?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("directions_bus")]):_vm._e(),(_vm.trip.transportMode === 'rail')?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("directions_railway")]):_vm._e()]},proxy:true}])},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"11"}},[_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.getTenantPartners
                                        .loading,"items":_vm.tenantPartners,"no-data-text":_vm.$t('loading.no_available'),"label":_vm.$t('headers.carrier'),"item-text":"name","item-value":"id","outlined":"","clearable":"","disabled":!_vm.trip.transportMode,"dense":"","hide-selected":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2",attrs:{"dense":""}},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c(VCol,{attrs:{"cols":"2"}},[(
                                                    data.item.carrier &&
                                                        data.item.carrier
                                                            .isSupportTracking
                                                )?_c('span',{staticClass:"grey--text"},[_c('i',[_vm._v("Trackable")])]):_vm._e()])],1)]}}]),model:{value:(_vm.trip.carrier),callback:function ($$v) {_vm.$set(_vm.trip, "carrier", $$v)},expression:"trip.carrier"}})],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"mt-2",attrs:{"small":"","outlined":"","rounded":"","icon":"","color":"primary","disabled":_vm.trip.transportMode === null ||
                                        _vm.trip.transportMode.length === 0},on:{"click":function($event){return _vm.createNewPartner('carrier')}}},[_c(VIcon,[_vm._v("add")])],1)],1)],1)],1),_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("tag")])]},proxy:true}])},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"11"}},[_c(VTextField,{attrs:{"label":!_vm.templateMode
                                        ? this.$t('trips.trip_ref') + '*'
                                        : this.$t('trips.trip_ref')
                                        ? this.$t('trips.trip_ref') + '*'
                                        : this.$t('trips.trip_ref'),"placeholder":_vm.$t('trips.order_ship_no'),"outlined":"","rules":[
                                    _vm.rules.required,
                                    _vm.rules.alertIsInvalid(_vm.alertHint)
                                ],"success-messages":!_vm.setActivate ? [''] : [],"disabled":_vm.templateMode,"filled":_vm.templateMode,"dense":""},on:{"change":function($event){return _vm.validateRefNumber(_vm.trip.referenceNumber)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[(
                                                _vm.trip.referenceNumber !==
                                                    '' &&
                                                    _vm.trip.referenceNumber !==
                                                        null
                                            )?_c(VBtn,{attrs:{"icon":"","small":"","loading":_vm.tripLoading,"color":"primary"}},[(!_vm.setActivate)?_c(VIcon,[_vm._v("done")]):(_vm.alertHint)?_c(VIcon,{attrs:{"color":"error"}},[_vm._v("warning")]):_vm._e()],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.trip.referenceNumber),callback:function ($$v) {_vm.$set(_vm.trip, "referenceNumber", $$v)},expression:"trip.referenceNumber"}})],1)],1)],1),_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("description")])]},proxy:true}])},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"11"}},[_c(VTextField,{attrs:{"label":_vm.$t('trips.shipment_no'),"outlined":"","dense":"","disabled":_vm.templateMode,"filled":_vm.templateMode},model:{value:(_vm.trip.shipmentFileNumber),callback:function ($$v) {_vm.$set(_vm.trip, "shipmentFileNumber", $$v)},expression:"trip.shipmentFileNumber"}})],1)],1)],1)],1)],1)],1),_c('AddPartnerDialog',{attrs:{"openAddPartnerDialog":_vm.openAddPartnerDialog,"partnerByType":_vm.partnerByType},on:{"closeAddPartnerDialog":function($event){_vm.openAddPartnerDialog = false},"item-created":_vm.onPartnerCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }