import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c(VCard,{staticClass:"py-4 mt-4",attrs:{"elevation":"5","outlined":""}},[_c(VRow,{staticClass:"px-5 d-flex align-center",attrs:{"align":"start"}},[_c(VCol,{attrs:{"cols":"12","sm":"5","lg":"5"}},[_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"subtitle-2",attrs:{"hint":_vm.$t('general.search_name'),"append-icon":"search","label":_vm.$t('general.search'),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1)],1)],1),_c(VSpacer),_c(VCol,{staticClass:"py-2 pr-4 text-right",attrs:{"cols":"12","sm":"5","lg":"4"}},[_c(VRow,{staticClass:"d-flex align-center justify-end"},[_c(VBtn,{staticClass:"mr-1",attrs:{"small":"","outlined":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.openAddGroupUserDialog = true}}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("add_box")]),_vm._v(" "+_vm._s(_vm.$t("partners.add_user1"))+" ")],1)],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2 mb-5 pb-5",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,[_c(VDataTable,{attrs:{"items":_vm.groupUsers.edges,"headers":_vm.headerChoices,"disable-pagination":"","loading-text":_vm.$t('loading.loading1'),"loader-height":"2","hide-default-footer":"","loading":_vm.$apollo.queries.groupUsers.loading},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VCheckbox,{staticClass:"mx-0",attrs:{"value":item.node.id},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v("edit")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{on:{"click":function($event){return _vm.removeUser(item)}}},[_vm._v("delete")])]}}])})],1)],1),_c('AddGroupUserDialog',{attrs:{"openAddGroupUserDialog":_vm.openAddGroupUserDialog,"group":_vm.group,"groupUsers":_vm.groupUsers},on:{"refreshGroupUsersTable":function($event){return _vm.$apollo.queries.groupUsers.refetch()},"closeAddGroupUserDialog":function($event){_vm.openAddGroupUserDialog = false}}}),_c('EditUserDialog',{ref:"editUserDialog",attrs:{"openEditUserDialog":_vm.openEditUserDialog},on:{"closeEditUserDialog":function($event){_vm.openEditUserDialog = false},"refreshDataTable":function($event){return _vm.$apollo.queries.groupUsers.refresh()}}}),_c('DeleteGroupUserDialog',{attrs:{"openDeleteDialog":_vm.openDeleteGroupUserDialog,"group":_vm.group,"usersID":_vm.selectedUsers},on:{"refreshDataTable":function($event){return _vm.$apollo.queries.groupUsers.refetch()},"closeDeleteGroupUserDialog":_vm.closeAndCleanDeletedUsers},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("groups.delete_partner")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("groups.sure_user")))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }