import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"630","persistent":"","scrollable":""},model:{value:(_vm.openAddLocationDialog),callback:function ($$v) {_vm.openAddLocationDialog=$$v},expression:"openAddLocationDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t("partners.add_location"))+" ")],1),_c(VCardText,{staticClass:"px-4 pb-1 pt-3"},[_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.locations.loading,"disabled":_vm.$apollo.queries.locations.loading,"items":_vm.locations.edges,"no-data-text":_vm.$t('loading.no_available'),"item-text":"node.city","item-value":"node.id","return-object":"","label":_vm.$t('partners.add_location'),"multiple":"","outlined":"","chips":"","small-chips":"","deletable-chips":"","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.node.name))]),_c(VListItemSubtitle,{staticClass:"text-truncate"},[_vm._v(_vm._s(item.node.fullAddress))])],1)]}}]),model:{value:(_vm.locationsToAdd),callback:function ($$v) {_vm.locationsToAdd=$$v},expression:"locationsToAdd"}}),(_vm.locationsToAdd.length > 0)?_c('div',[_c(VDivider),_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.locationsToAdd),function(item,i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"6"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"pr-3 pt-2 pb-n7",attrs:{"flat":"","outlined":""}},[_c(VCardTitle,[_vm._v(_vm._s(item.node.city))]),_c(VCardText,[_c('MinMaxTemperature',{model:{value:(item.node),callback:function ($$v) {_vm.$set(item, "node", $$v)},expression:"item.node"}})],1)],1)],1)],1)}),1)],1):_vm._e()],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.resetLocation}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"color":"primary","rounded":"","loading":_vm.isLoadingLocationAdd,"disabled":!_vm.formValid},on:{"click":_vm.addNewWeatherLocation}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }