<template>
    <v-container fluid class="pa-0">
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col>
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                            align-top
                        "
                    >
                        {{ $t("home.tenants") }}
                    </h2>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    color="primary"
                    class="mr-1"
                    @click="openAddTenantDialog = true"
                >
                    {{ $t("users.add_tenant") }}
                </v-btn>
            </v-row>
        </div>

        <!-- FILTER -->
        <TenantFilter
            v-on:filter-changed="onFilterChanged"
            class="mx-3"
            v-on:item-created="onTenantCreated"
        ></TenantFilter>
        <!-- LIST -->
        <TenantTable
            ref="tenantTable"
            v-on:click:row="onTenantSelected"
            :tenantFilter="tenantFilter"
            class="mx-3 mt-3"
        ></TenantTable>

        <!-- DETAIL DRAWER -->
        <v-navigation-drawer
            v-model="tenantDetailDrawer"
            :width="tenantDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <TenantDetail
                    ref="tenantDetails"
                    v-if="selectedTenant || idFromRouter"
                    :key="selectedTenant ? selectedTenant.id : idFromRouter"
                    :tenantProp="selectedTenant"
                    :tenantId="idFromRouter"
                    :isFullScreen="isTenantDetailDrawerFullScreen"
                    v-on:changeFullScreen="onTenantDetailDrawerFullScreenChange"
                    v-on:refreshDataTable="refetchTableData"
                    v-on:close="tenantDetailDrawer = false"
                ></TenantDetail>
            </div>
        </v-navigation-drawer>
        <AddTenantDialog
            :openAddTenantDialog="openAddTenantDialog"
            v-on:refreshTenantsTable="refetchTableData"
            v-on:closeAddTenantDialog="openAddTenantDialog = false"
            v-on:item-created="onTenantCreated"
        ></AddTenantDialog>
    </v-container>
</template>

<script>
//import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TenantFilter from "@/components/tenants/TenantFilter.vue";
import TenantTable from "@/components/tenants/TenantTable.vue";
import TenantDetail from "@/components/tenants/TenantDetail.vue";
import { eventBus } from "@/main.js";
import AddTenantDialog from "@/components/tenants/create/AddTenantDialog.vue";





export default {
    title: "Tenants",
    components: {
        TenantFilter,
        TenantTable,
        TenantDetail,
        AddTenantDialog
    },

    apollo: {},
    data() {
        return {
            tenants: [],
            selectedTenant: null,
            tenantFilter: {
                first: 20
            },
            openAddTenantDialog: false,
            tenantDetailDrawer: false,
            isLoading: false,
            menu: false,
            trackerDetailDrawer: false,
            goTopShow: false,
            tenantDetailFullScreen: false,
            idFromRouter: null
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        tenantDetailDrawerWidth() {
            if (this.tenantDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isTenantDetailDrawerFullScreen() {
            return this.tenantDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },

    watch: {
        tenantDetailDrawer(val) {
            if (!val) {
                this.selectedTenant = null;
                if (this.$route.fullPath != "/tenants") {
                    this.$router.push({
                        path: "/tenants",
                        query: {},
                        params: {}
                    });
                }
                this.trackerDetailFullScreen = false;
            }
        },
        $route(to) {
            if (to.fullPath == "/tenants") return;
            if (to.query.id) {
                this.idFromRouter = to.query.id;
                this.tenantDetailDrawer = true;
            }
            if (to.params.id) {
                this.idFromRouter = to.params.id;
                this.tenantDetailDrawer = true;
                this.tenantDetailFullScreen = true;
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        if (this.$route.query.id) {
            this.idFromRouter = this.$route.query.id;
            this.tenantDetailDrawer = true;
        }
        if (this.$route.params.id) {
            this.idFromRouter = this.$route.params.id;
            this.tenantDetailDrawer = true;
            this.tenantDetailFullScreen = true;
        }
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        },
        // -----> DATA RELOAD OR RESET <----- \\
        refetchTableData() {
            this.$refs.tenantTable.$apollo.queries.tenants.refetch();
        },
        onFilterChanged(filter) {
            this.tenantFilter = filter;
        },
        // -----> EVENT HANDLERS (clicks, onchange, scroll, etc) <------ \\
        onTenantSelected(tenant) {
            this.selectedTenant = tenant.node;
            this.$router.push({ query: { id: tenant.node.id } });
            this.tenantDetailDrawer = true;
        },
        onScroll(e) {
            // scroll to top of the page
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        onTenantDetailDrawerFullScreenChange(val) {
            this.tenantDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onTenantCreated(val) {
            this.selectedTenant = val.tenant;
            this.$router.push({ query: { id: val.tenant.id } });
            this.tenantDetailDrawer = true;
            this.$refs.tenantTable.$apollo.queries.tenants.refetch();
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
</style>
