import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"rounded-lg",attrs:{"outlined":"","height":"350"}},[_c('div',[_c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"flat":"","outlined":"","loading":_vm.loading}},[(_vm.loading)?_c(VProgressLinear,{staticClass:"mb-0",staticStyle:{"z-index":"800"},attrs:{"dense":"","indeterminate":"","color":"primary","height":3}}):_vm._e(),(!_vm.noDataTracker)?_c('div',[(!_vm.isSharedTrip)?_c(VMenu,{attrs:{"close-on-content-click":false,"open-on-click":"","left":"","offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"button-over-map",attrs:{"color":"primary","dark":"","loading":_vm.loading}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("home.trackers"))+" ")])]}}],null,false,869740070),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"rounded":""}},[_c(VList,_vm._l((_vm.tripFreightTrackers.edges),function(tracker){return _c(VListItem,{key:tracker.node.tracker.id},[_c(VCheckbox,{attrs:{"value":_vm.isChecked(tracker.node.tracker.id),"input-value":_vm.isChecked(tracker.node.tracker.id)},on:{"change":function($event){return _vm.toggleSelection(tracker.node.tracker.id)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{style:('color: ' +
                                                    _vm.colors[_vm.getIndex(_vm.tripFreightTrackers.edges.indexOf(tracker))] +
                                                    ';')},[_vm._v(" "+_vm._s(tracker.node.tracker.serialNumber)+" ")])]},proxy:true}],null,true)})],1)}),1)],1)],1):_vm._e()],1):_vm._e(),(!_vm.canAccess)?_c(VOverlay,{staticStyle:{"z-index":"800"},attrs:{"absolute":"","opacity":"1","color":"primary"}},[_c(VAlert,{attrs:{"dense":"","color":"grey","type":"warning"}},[_vm._v("No permission to look at this trip")])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center",style:({
                    height: 348 + 'px'
                }),attrs:{"id":"map"}},[(_vm.noData)?_c(VAlert,{staticClass:"mt-3",staticStyle:{"z-index":"800"},attrs:{"max-width":"300px","max-height":"40px","dense":"","color":"grey","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("loading.no_trip")))]):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }