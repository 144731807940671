<template>
    <v-container class="px-5">
        <v-row>
            <v-col cols="12">
                <!-- TABLE -->
                <PreviewPartnerTripsTable
                    ref="partnerTripsTable"
                    :partnerTripsFilter="computedPartnerTripsFilter"
                ></PreviewPartnerTripsTable>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PreviewPartnerTripsTable from "@/components/partners/PreviewPartnerTripsTable.vue";
import Config from "@/utils/config.json";

export default {
    name: "PreviewPartnerTripsTab",
    components: {
        PreviewPartnerTripsTable
    },
    props: {
        partner: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            partnerTripsFilter: {
                first: 10,
                // tenantPartnerId: [this.partner.id]
            }
        };
    },
    computed:{
        computedPartnerTripsFilter(){
            if(this.partner.partnerType.toLowerCase() === "carrier")
                return {
                    tenantCarrier: this.partner.id,
                    tenantPartnerId: null
                }
            
            return {
                tenantCarrier: null,
                tenantPartnerId: [this.partner.id]
            };
        }
    },
    mounted(){
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'partner_trips_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("partner_trips_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("partner_trips_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        onFilterChanged(filter) {
            this.partnerTripsFilter = filter;
        }
    }
};
</script>
