<template>
    <v-container class="grey lighten-4">
        <v-row dense class="d-flex justify-start ">
            <v-col cols="12" class="pt-0">
                <h4>
                    {{ $t("alert_rules.apply") }}
                </h4>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3">
                <v-card
                    elevation="2"
                    class="py-5 d-flex align-center justify-center"
                    @click="automateOption('applyAllNewTrips')"
                    :color="valueApplyAllNewTrips ? 'primary' : ''"
                    height="70"
                >
                    <span
                        :class="
                            valueApplyAllNewTrips
                                ? 'white--text'
                                : 'black--text'
                        "
                    >
                        {{ $t("general.all") }}
                    </span>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3">
                <v-card
                    elevation="2"
                    class="py-5 d-flex align-center justify-center"
                    height="70"
                    @click="automateOption('partnersTrips')"
                    :color="applyPartnersAlert ? 'primary' : ''"
                >
                    <span
                        :class="
                            applyPartnersAlert ? 'white--text' : 'black--text'
                        "
                    >
                        {{ $t("alert_rules.with_partners") }}
                    </span>
                </v-card>
            </v-col>
        </v-row>
        <v-form v-model="validatePartner">
            <v-row dense class="d-flex justify-start" v-if="applyPartnersAlert">
                <v-col cols="12" class="d-flex flex-row ">
                    <v-col
                        v-for="choice in defaultChoices"
                        :key="choice.value"
                        class="pl-0"
                    >
                        <v-card
                            elevation="2"
                            class="py-5 d-flex align-center justify-center"
                            @click="updatePartnerChoice(choice)"
                            :color="
                                partnerTypeChoices.find(
                                    e => e.value === choice.value
                                )
                                    ? 'primary'
                                    : ''
                            "
                            height="70"
                        >
                            <span
                                :class="
                                    partnerTypeChoices.find(
                                        e => e.value === choice.value
                                    )
                                        ? 'white--text'
                                        : 'black--text'
                                "
                            >
                                {{ choice.text }}
                            </span>
                        </v-card>
                    </v-col>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    v-for="partnerTypeChoice in partnerTypeChoices"
                    :key="partnerTypeChoice.value"
                >
                    <v-autocomplete
                        v-if="partnerTypeChoice.value"
                        hide-details
                        outlined
                        multiple
                        :rules="[rules.required]"
                        small-chips
                        clearable
                        v-model="
                            valuePartnersToApplyAlert[partnerTypeChoice.value]
                        "
                        :items="
                            tenantPartners.edges.filter(
                                partner =>
                                    partner.node.partnerType ===
                                    partnerTypeChoice.value
                            )
                        "
                        :no-data-text="$t('loading.no_available')"
                        :item-value="item => (item.node ? item.node.id : null)"
                        :item-text="item => (item.node ? item.node.name : null)"
                        :label="partnerTypeChoice.text"
                        @change="
                            $emit(
                                'updatePartnersToApplyAlert',
                                valuePartnersToApplyAlert
                            )
                        "
                    >
                    </v-autocomplete
                ></v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";

export default {
    props: {
        applyAllNewTrips: {
            type: Boolean,
            required: false,
            default: false
        },
        partnersToApplyAlert: {
            type: Object,
            required: false
        },
        /* editMode: {
            type: Boolean,
            required: false,
            default: false
        }, 
        isReady: {
            type: Boolean,
            required: false,
            default: false
        }, */
        activeMode: {
            type: Boolean,
            required: false,
            default: false
        },
        partnersList: {
            type: Array,
            required: false,
            default: () => []
        },
        validate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            tenantPartners: { edges: [] },
            partnerTypeChoices: [],
            partnersTrips: false,
            defaultChoices: [
                { text: this.$t("headers.carrier"), value: "carrier" },
                { text: this.$t("headers.consignor"), value: "consignor" },
                { text: this.$t("headers.forwarder"), value: "forwarder" },
                { text: this.$t("headers.consignee"), value: "consignee" },
                { text: this.$t("home.other"), value: "other" }
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                alertIsInvalid: v =>
                    v === false || this.$t("rules.ref_no_exists")
            }
        };
    },
    computed: {
        validatePartner: {
            get() {
                return this.validate;
            },
            set(val) {
                this.$emit("update:validate", val);
            }
        },
        valueApplyAllNewTrips: {
            get() {
                return this.applyAllNewTrips;
            },
            set(val) {
                this.$emit("updateApplyAllNewTrips", val);
            }
        },
        valuePartnersToApplyAlert() {
            let _partnersToApplyAlert = this.partnersToApplyAlert;
            return _partnersToApplyAlert;
        },
        applyPartnersAlert: {
            get() {
                let _boolean = this.partnersTrips;
                return _boolean;
            },
            set(val) {
                this.partnersTrips = val;
            }
        }
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query tenantPartners($orderBy: String, $idIn: [String]) {
                    tenantPartners(orderBy: $orderBy, id_In: $idIn) {
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "no-cache",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantPartners,
            skip() {
                return !this.activeMode;
            }
        }
    },
    watch: {
        activeMode(val) {
            if (val) {
                this.defaultChoices.forEach(choice => {
                    if (
                        this.valuePartnersToApplyAlert[choice.value].length > 0
                    ) {
                        this.partnersTrips = true;
                        this.partnerTypeChoices.push(choice);
                    }
                });
            }
        }
    },
    methods: {
        resetValues() {
            this.valueApplyAllNewTrips = false;
            this.applyPartnersAlert = false;
            this.valuePartnersToApplyAlert.carrier = [];
            this.valuePartnersToApplyAlert.consignee = [];
            this.valuePartnersToApplyAlert.consignor = [];
            this.valuePartnersToApplyAlert.forwarder = [];
            this.valuePartnersToApplyAlert.other = [];
            this.partnerTypeChoices = [];
        },
        automateOption(option) {
            this.validatePartner = true;
            if (option === "applyAllNewTrips") {
                this.valueApplyAllNewTrips = !this.valueApplyAllNewTrips;
                this.applyPartnersAlert = false;
                this.valuePartnersToApplyAlert.carrier = [];
                this.valuePartnersToApplyAlert.consignee = [];
                this.valuePartnersToApplyAlert.consignor = [];
                this.valuePartnersToApplyAlert.forwarder = [];
                this.valuePartnersToApplyAlert.other = [];
            } else if (option === "partnersTrips") {
                this.applyPartnersAlert = !this.applyPartnersAlert;
                this.valueApplyAllNewTrips = false;
                this.validatePartner = false;
            }
        },
        updatePartnerChoice(choice) {
            if (this.partnerTypeChoices.includes(choice)) {
                this.partnerTypeChoices = this.partnerTypeChoices.filter(
                    item => item !== choice
                );
                this.valuePartnersToApplyAlert[choice.value] = [];
                this.$emit(
                    "updatePartnersToApplyAlert",
                    this.valuePartnersToApplyAlert
                );
            } else {
                this.partnerTypeChoices.push(choice);
            }
        }
    }
};
</script>
