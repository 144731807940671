import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-4 scrollable",attrs:{"fluid":""}},[_c(VRow,{attrs:{"dense":"","align":"stretch"}},[_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('FilterMenu',{staticClass:"mr-n2",attrs:{"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display'),"choices":_vm.filterChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1),_vm._l((_vm.dynamicChoices),function(kpi){return _c(VCol,{key:kpi.tag,attrs:{"cols":"12","lg":"3","md":"6","sm":"12"}},[_c(VCard,{attrs:{"loading":_vm.$apollo.queries.tenantDashboards.loading,"loader-height":3,"height":"100%"},on:{"click":function($event){return _vm.travelTo(kpi)}}},[_c(VCardTitle,{staticClass:"pa-3"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c('div',[_c('h6',{staticClass:"subtitle-2 grey--text text-left no-wrap"},[_vm._v(" "+_vm._s(_vm.$t(kpi.tag))+" "),(
                                            kpi.code === 'UNRESOLVED_TAG_TEMPERATURE' ||
                                                kpi.code === 'UNRESOLVED_TAG_TEMPERATURE_HUMIDITY' ||
                                                kpi.code === 'UNRESOLVED_TAG_TEMPERATURE_HUMIDITY_LIGHT' ||
                                                kpi.code === 'UNRESOLVED_TAG_PRESSURE' ||
                                                kpi.code === 'UNRESOLVED_TAG_HUMIDITY' ||
                                                kpi.code === 'UNRESOLVED_TAG_SHOCK' ||
                                                kpi.code === 'UNRESOLVED_TAG_LIGHT'
                                        )?_c(VIcon,{attrs:{"small":"","color":"warning"}},[_vm._v(" warning ")]):_vm._e()],1)]),_c('div',{staticClass:"text-left grey--text text--darken-3 mt-3"},[_c('h1',{staticClass:"font-weight-regular no-wrap"},[_vm._v(" "+_vm._s(kpi.value)+" ")])])]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_c('div',[_c(VBtn,{class:kpi.color,attrs:{"elevation":"0","fab":"","small":""}},[_c(VIcon,{staticClass:"d-flex align-center justify-center",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(kpi.icon)+" ")])],1)],1),_c('div',[_c('span',{staticClass:"text-caption text--disabled no-wrap"},[_vm._v(" "+_vm._s(_vm.formatDateTime(kpi.lastRefreshedAt))+" ")])])])],1)],1)],1)],1)}),(_vm.showMap)?_c(VCol,{attrs:{"cols":"12"}},[_c('ClusterMap',{attrs:{"loadingQuery":_vm.$apollo.queries.tripFreightTrackers.loading,"data":_vm.trackersLocations}})],1):_vm._e(),(_vm.showTable)?_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c(VCard,{staticClass:"mt-1"},[_c(VCardTitle,{staticClass:"subtitle-2 grey--text"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.unresolved_alerts"))+" ")]),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.tripAlerts ? _vm.tripAlerts.edges : [],"loading":_vm.$apollo.queries.tripAlerts.loading,"item-key":"node.id","height":"500","fixed-header":"","hide-default-footer":"","loading-text":_vm.$t('loading.loading'),"disable-pagination":"","disable-sort":"","loader-height":"2","mobile-breakpoint":_vm.screenSize === 'xs' ? 650 : 0},scopedSlots:_vm._u([{key:"item.tracker",fn:function(ref){
                                        var item = ref.item;
return [(item.node.tripFreightTracker)?_c(VRow,{staticClass:"mx-0 px-0"},[(item.node.tripFreightTracker.tracker)?_c(VChip,{staticClass:"px-2 caption",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.node.tripFreightTracker.tracker.serialNumber)+" ")]):_vm._e()],1):_c('div',[_vm._v(" - ")])]}},{key:"item.status",fn:function(ref){
                                        var item = ref.item;
return [(!item.node.isResolved)?_c('div',{staticClass:"caption pr-0 mr-0"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.unresolved"))+" ")]):_c('div',{staticClass:"caption pr-0 mr-0"},[_vm._v(" - ")])]}},{key:"item.trip",fn:function(ref){
                                        var item = ref.item;
return [_c('div',{staticClass:"caption pr-0 mr-0"},[_vm._v(" "+_vm._s(item.node.trip.referenceNumber)+" ")])]}},{key:"item.alertTime",fn:function(ref){
                                        var item = ref.item;
return [(item.node.alertTime)?_c('div',{staticClass:"caption pr-0 mr-0"},[_vm._v(" "+_vm._s(_vm.formatDateTime(item.node.alertTime))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.deviceTime",fn:function(ref){
                                        var item = ref.item;
return [(item.node.deviceTime)?_c('div',{staticClass:"caption pr-0 mr-0"},[_vm._v(" "+_vm._s(_vm.formatDateTime(item.node.deviceTime))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.alertDescription",fn:function(ref){
                                        var item = ref.item;
return [_c(VRow,_vm._l((_vm.checkAlert(item.node.alertJson)),function(alert,i){return _c(VCol,{key:i,attrs:{"cols":"1"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [(alert === 'temperature')?_c(VIcon,_vm._g(_vm._b({staticClass:"mr-6",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" thermostat ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.temperature")))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [(alert === 'humidity')?_c(VIcon,_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" water_drop ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.humidity")))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [(alert === 'light')?_c(VIcon,_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" lightbulb ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.light")))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [(alert === 'shock')?_c(VIcon,_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" bolt ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.shock")))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [(alert === 'location')?_c(VIcon,_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" location_on ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.location")))])])],1)}),1)]}},{key:"item.freight",fn:function(ref){
                                        var item = ref.item;
return [_c(VRow,[(item.node.tripFreightTracker)?_c(VCol,{staticClass:"caption"},[_vm._v(" "+_vm._s(item.node.tripFreightTracker.tripFreight.referenceNumber)+" ")]):_c('div',[_vm._v(" - ")])],1)]}},{key:"item.alerts",fn:function(ref){
                                        var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":"warning"}},[_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.node.tenantAlertRule.name)+" ")])])]}},{key:"item.actions",fn:function(ref){
                                        var item = ref.item;
                                        var index = ref.index;
return [_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.redirect(item.node.trip.id)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" open_in_new ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.redirect")))])]),_c('AlertRuleDetailDialog',{attrs:{"tripAlertId":item.node.id,"alertTime":item.node.alertTime,"deviceTime":item.node.deviceTime,"dashboardTripId":item.node.trip.id,"tripId":item.node.trip.id}}),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [(!item.node.isResolved)?_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading && _vm.ind === index},on:{"click":function($event){return _vm.save(item.node.id, index)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("done")])],1):_c(VBtn,_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":"","color":"success","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("done")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.resolve")))])])],1)]}},{key:"body.append",fn:function(){return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"empty-wrapper text-center mt-5"},[(_vm.hasMoreData)?_c(VBtn,{attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.tripAlerts.loading,"plain":"","text":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)])]},proxy:true}],null,false,379436972)})],1)],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }