<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4 mb-4" flat>
            <v-row align="start" class="d-flex align-center background px-4">
                <!---- SEARCH BAR ---->
                <v-col cols="12" class="py-2">
                    <SearchBar
                        v-model="search"
                        :label="this.$t('general.search') + ' ' + this.$t('home.locations')"
                    ></SearchBar>
                </v-col>
            </v-row>
        </v-card>
        <v-card flat class="px-4 rounded-lg mb-2" outlined>
            <v-card-title>
                <v-row>
                    <v-col cols="auto">
                        <v-row dense>
                            <v-col>
                                <div>
                                    <v-img :src="require('@/assets/location_on.png')" width="26"></v-img>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                {{ $t("home.locations") }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <slot name="addPartnerLocation"></slot>
            </v-card-title>
            <v-card-text>
                <v-card v-if="computedLocations.lenght === 0">
                    No Locations found
                </v-card>
                <v-card
                    v-for="(location, i) in computedLocations"
                    :key="i"
                    class="mb-4 rounded-lg px-3 backgroundContent"
                    outlined
                    flat
                >
                    <v-card-text>
                        <!-- TABLE -->
                        <v-row>
                            <v-col
                                v-for="item in cardBody(location)"
                                :key="item.text"
                                :cols="item.tag === 'fullAddress' ? 12 : 12"
                                :xs="item.tag === 'fullAddress' ? 12 : 12"
                                :sm="item.tag === 'fullAddress' ? 12 : item.tag === 'name' ? 12 : 6"
                                :md="item.tag === 'fullAddress' ? 3 : 2"
                                :lg="item.tag === 'fullAddress' ? 3 : 2"
                                :xl="item.tag === 'fullAddress' ? 3 : 2"
                            >
                                <div v-if="item.tag === 'tags'">
                                    <div class="textHeader">
                                        {{ item.title }}
                                    </div>
                                    <div class="d-flex flex-row justify-start align-center">
                                        <div
                                            v-for="(tag, i) in item.value"
                                            :key="i"
                                            class="textBody mr-1"
                                            :style="item.style"
                                        >
                                            <v-icon v-if="item.icon" size="8" class="mr-1" :color="item.colorIcon">{{
                                                item.icon
                                            }}</v-icon>
                                            <span>
                                                {{ tag }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="textHeader">
                                        {{ item.title }}
                                    </div>
                                    <div class="textBody text-truncate" :style="item.style">
                                        <v-icon v-if="item.icon" size="8" class="mr-1" :color="item.colorIcon">{{
                                            item.icon
                                        }}</v-icon>
                                        <span>
                                            {{ item.value }}
                                        </span>
                                    </div>
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-card flat max-width="20px" class="d-flex justify-center" color="transparent">
                                    <v-menu
                                        offset-y
                                        transition="slide-x-reverse-transition"
                                        width="250px"
                                        attach
                                        left
                                        bottom
                                        close-on-click
                                        nudge-top="-3"
                                    >
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="white">
                                            <v-list-item>
                                                <v-btn plain elevation="0" @click.stop.prevent="editLocation(location)">
                                                    <v-icon class="mr-2">edit</v-icon>
                                                    {{ $t("general.edit") }}
                                                </v-btn>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-btn
                                                    plain
                                                    elevation="0"
                                                    @click.stop.prevent="deleteLocation(location)"
                                                >
                                                    <v-icon class="mr-2">delete</v-icon>
                                                    {{ $t("general.delete") }}
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import helper from "@/utils/helper.js";
import SearchBar from "@/components/base/SearchBar.vue";
import Config from "@/utils/config.json";

export default {
    name: "PartnerLocationForm",
    components: { SearchBar },
    props: {
        locations: {
            type: Array,
            default: () => []
        },
        searchInput: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            isLoadingSavePermission: false,
            enableOrDisablePermissionBtn: true,
            selectedPermissions: [],
            selectedPermissionsCopy: [],
            search: ""
        };
    },
    watch: {
        search(val) {
            if (val.length > 0) {
                this.$emit("update:searchInput", val);
            } else {
                this.$emit("update:searchInput", null);
            }
        }
    },
    computed: {
        computedLocations() {
            let _locations = [];
            if (this.locations?.length > 0) {
                _locations = this.locations.map(item => {
                    return item.node;
                });
            }
            return _locations;
        }
    },
    mounted() {},
    methods: {
        cardBody(item) {
            const { name, city, coutry, fullAddress, tags } = item;
            let _tags = [];
            if (tags) {
                _tags = this.convertJsonToArray(tags);
            }

            let containerStyle = `
                    background-color: ${this.statusColor(_tags)?.color1};	
                    border-radius: 4px;
                    width: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            `;

            let _body = [
                {
                    title: this.$t("headers.location_name"),
                    tag: "name",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: name
                },
                {
                    title: this.$t("partners.city"),
                    tag: "city",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: city
                },
                {
                    title: this.$t("partners.country"),
                    tag: "country",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: coutry
                },
                {
                    title: this.$t("partners.address"),
                    tag: "fullAddress",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: fullAddress
                },
                {
                    title: this.$t("headers.tags"),
                    tag: "tags",
                    icon: null,
                    colorIcon: this.statusColor(_tags)?.color2,
                    style: containerStyle,
                    align: "center",
                    value: _tags
                }
            ];

            return _body;
        },
        statusColor(status) {
            if (!Array.isArray(status)) return;
            if (status.length > 0) {
                return {
                    color1: "#C8E6C9",
                    color2: "#1B5E20"
                };
            } else {
                return;
            }
        },
        convertJsonToArray(json) {
            if (!json) return [];
            if (typeof json === "object") return Array.isArray(json) ? json : [json];

            try {
                // Ensure the input is a string and replace single quotes globally
                let correctedJsonString = json.replace(/\\'/g, "\\'").replace(/'/g, '"');
                let parsedArray = JSON.parse(correctedJsonString);
                return parsedArray;
            } catch (error) {
                console.error("Error parsing JSON to array:", error);
                return []; // Return an empty array in case of error
            }
        },
        convertArrayToString(array) {
            if (Array.isArray(array) && array.length === 0) return "";
            let _array = array.map(item => {
                return item;
            });
            return _array.join(", ");
        },
        statusText(status) {
            if (status) {
                return this.$t("headers.enabled");
            } else {
                return this.$t("headers.disabled");
            }
        },
        editLocation(editLocation) {
            let _location = this.locations.filter(item => item.node.id === editLocation.id);
            this.$emit("update-location", _location[0]);
        },
        deleteLocation(editLocation) {
            let _location = this.locations.filter(item => item.node.id === editLocation.id);
            this.$emit("delete-location", _location[0]);
        }
    }
};
</script>
<style scoped>
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
.outline {
    border: 1px solid #ebebf5;
    box-sizing: border-box;
    border-radius: 4px;
}
.backgroundContent {
    background: #f9fafe;
}
</style>
