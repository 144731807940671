<template>
    <v-dialog max-width="600" v-model="openDialog" persistent>
        <!--- MAIN CARD --->
        <v-card>
            <!--- TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("templates.name_description") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pt-6">
                <v-form ref="partnerForm" v-model="formValid">
                    <v-row dense>
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="template_name"
                                :label="$t('templates.template_name') + '*'"
                                :rules="nameRules"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-textarea
                                v-model="description"
                                :label="$t('general.description')"
                                :rules="[rules.maxLength(200)]"
                                outlined
                                dense
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTIONS --->
            <v-card-actions class="d-flex justify-end pb-3 pr-5">
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :disabled="!formValid"
                    @click="saveNameDescription"
                    :loading="isLoading"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "NameDescriptionDialog",
    props: {
        openDialog: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        },
        templateObj: {
            type: Object,
            default: null
        }
    },
    apollo: {},
    data() {
        return {
            formValid: false,
            template_name: null,
            description: null,
            isLoading: false,
            nameRules: [v => !!v || this.$t("rules.required")],
            rules: {
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    watch: {
        openDialog(val) {
            if (val) {
                this.template_name = this.templateObj.name;
                this.description = this.templateObj.description;
            }
        }
    },
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.template_name = this.templateObj.name;
            this.description = this.templateObj.description;
            // this.$refs.partnerForm.resetValidation();
            this.$emit("closeDialog");
        },
        /* ---> TENANT PARTNER MUTATION <--- */
        saveNameDescription() {
            this.isLoading = true;
            this.templateObj.name = this.template_name;
            this.templateObj.description = this.description;
            this.$emit("saveNameDescription", true);
        }
    }
};
</script>
