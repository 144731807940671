import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle),_c(VCardText,[_c(VRow,[_c('span',{staticClass:"mt-3 subtitle-1"},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("headers.tracker_stock"))+" ")])])]),_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.stock_limit"))+" ")])]),_c(VCol,{attrs:{"cols":"3"}},[(!_vm.editMode)?_c('div',[_vm._v(" "+_vm._s(_vm.stockLevel)+" ")]):_c(VTextField,{attrs:{"type":"number","label":"Stock Level","placeholder":"Placeholder","outlined":""},model:{value:(_vm.stockLevel),callback:function ($$v) {_vm.stockLevel=_vm._n($$v)},expression:"stockLevel"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),(!_vm.editMode)?_c(VBtn,{attrs:{"rounded":"","color":"primary"},on:{"click":function($event){_vm.editMode = !_vm.editMode}}},[_vm._v(" "+_vm._s(_vm.$t("general.edit"))+" ")]):_vm._e(),(_vm.editMode)?_c(VBtn,{attrs:{"rounded":"","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]):_vm._e(),(_vm.editMode)?_c(VBtn,{attrs:{"rounded":"","color":"primary","loading":_vm.isLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }