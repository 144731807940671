<template>
    <v-card flat outlined class="rounded-lg notOverlap" height="450">
        <v-card flat outlined v-resize="onResize">
            <LoadingBar v-if="loading" class="loading-over-map"></LoadingBar>
            <div
                :style="{
                    height: 450 + 'px'
                }"
                id="map"
                class="d-flex justify-center"
            >
                <v-alert
                    v-if="noData"
                    max-width="300px"
                    max-height="40px"
                    class="mt-3"
                    dense
                    color="grey"
                    type="warning"
                >
                    {{ $t("loading.no_trip") }}</v-alert
                >
            </div>
        </v-card>
    </v-card>
</template>

<script>
L;
LDraw;
import L from "leaflet";
import "leaflet.markercluster";
import LDraw from "leaflet-draw";
import helper from "@/utils/helper.js";
import LoadingBar from "@/components/base/LoadingBar.vue";
import Config from "@/utils/config.json";

export default {
    name: "ClusterMap",
    components: {
        LoadingBar
    },
    props: {
        viewType: {
            type: String,
            default: null
        },
        center: {
            type: Object,
            required: false,
            default: () => ({
                lat: 40.73061,
                lng: -73.935242
            })
        },
        data: {
            type: Array,
            required: false,
            default: () => []
        },
        loadingQuery: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    apollo: {},
    data() {
        return {
            apikey: Config.VUE_APP_MAP_API_KEY,
            here_map_url: `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=${Config.VUE_APP_MAP_API_KEY}&ppi=320`,
            zoom: 2,
            map: null,
            here_map_attribution: "",
            resetZoomBtn: null
        };
    },
    updated() {
        this.resizeMap();
    },
    computed: {
        me() {
            return helper.me();
        },
        isShowPreview() {
            let localShowPreview = this.$store.getters["user/showPreview"];
            if (localShowPreview === null) {
                return false;
            }

            if (localShowPreview === "true") return true;
            if (localShowPreview === "false") return false;
            else return localShowPreview;
        },
        loading() {
            return this.loadingQuery;
        },
        noData() {
            return this.data.length == 0;
        },
        locationsPoints() {
            return this.data;
        }
    },
    watch: {
        locationsPoints(val) {
            if (val.length > 0) {
                this.createLocationsPoints();
            }
        }
    },
    async mounted() {
        this.$nextTick(async () => {
            const style = "normal.day.grey";
            const apikey = Config.VUE_APP_MAP_API_KEY;
            const hereTileUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?apiKey=${apikey}&ppi=320`;

            this.map = L.map("map", {
                attributionControl: false,
                center: this.center,
                zoom: this.zoom,
                maxZoom: 18,
                minZoom: 2,
                layers: [
                    L.tileLayer(hereTileUrl, {
                        attribution: this.here_map_attribution,
                        maxZoom: 18,
                        minZoom: 2
                    })
                ]
            });
            this.map.zoomControl.setPosition("topright");
        });
    },
    methods: {
        resetZoom(bounds) {
            L.Control.ResetView = L.Control.extend({
                onAdd: function(map) {
                    let container = L.DomUtil.create("div", "leaflet-bar leaflet-control leaflet-control-custom");

                    container.style.backgroundColor = "white";
                    container.innerHTML = `<i class='material-icons primary--text mt-1'>
                                                my_location
                                            </i>`;

                    container.style.backgroundRepeat = "no-repeat";
                    container.style.backgroundPosition = "center";
                    container.style.backgroundSize = "20px 20px";
                    container.style.width = "50px";
                    container.style.height = "35px";
                    container.style.cursor = "pointer";
                    container.onclick = function() {
                        if (bounds) {
                            map.fitBounds(bounds);
                            if (map.getZoom() > 12) {
                                map.setZoom(12);
                            }
                        } else {
                            map.setView(this.center, this.zoom);
                        }
                    };
                    return container;
                }
            });

            L.control.resetView = function(opts) {
                return new L.Control.ResetView(opts);
            };

            L.control.resetView({ position: "bottomleft" }).addTo(this.map);
        },
        createLocationsPoints() {
            const locationsPoints = this.locationsPoints;

            const markers = L.markerClusterGroup({
                iconCreateFunction: function(cluster) {
                    return L.divIcon({
                        html: `<div
                            class="primary white--text"
                            style="border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;"
                        >${cluster.getChildCount()}</div>`,
                        iconSize: [30, 30],
                        className: ""
                    });
                }
            });

            locationsPoints.forEach(({ trip, points }) => {
                const icon = L.divIcon({
                    html: `<div
                        class="primary white--text"
                        style="border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;"
                    >${1}</div>`,
                    iconSize: [30, 30],
                    iconAnchor: [15, 15],
                    className: ""
                });

                points.forEach(([lat, lng]) => {
                    let marker = L.marker([lat, lng], { icon: icon });

                    let popupContent = `
                        <div class="">
                            <div class="d-flex flex-column">
                                <div class="d-flex justify-start">
                                    <h5 class="">
                                       Trip Ref.
                                    </h5>
                                </div>
                                <button
                                    id="trip-detail-button"
                                    class="primary--text d-flex justify-start"
                                    style="outline: none; border: none; padding: 0px opx; cursor: pointer; height: 25px;"
                                >
                                   <u>${trip.referenceNumber}</u> 
                                </button>
                                <div class="d-flex justify-start" >
                                    <h5 class="">
                                       Tracker No.
                                    </h5>
                                </div>
                                <button
                                    id="tracker-detail-button"
                                    class="primary--text d-flex justify-start"
                                    style="outline: none; border: none; padding: 0px opx; cursor: pointer; height: 25px;"
                                >
                                   <u>${trip.tracker.serialNumber}</u> 
                                </button>
                               
                                
                            </div>
                        </div>`;

                    marker.bindPopup(popupContent);
                    let tripLink = this.isShowPreview ? "/previewtrips" : "/trips";
                    let trackerLink = this.isShowPreview ? "/previewtrackers" : "/trackers";

                    // Attach an event listener after the popup opens
                    marker.on("popupopen", () => {
                        // Get the element with the id of trip-detail-button
                        let tripDetailButton = document.getElementById("trip-detail-button");

                        // Add a click event listener to that element
                        tripDetailButton.addEventListener("click", () => {
                            // Navigate to the trip detail page
                            this.$router.push({
                                path: tripLink,
                                query: { id: trip.id, tab: "freights" }
                            });
                        });
                    });

                    // Attach an event listener after the popup opens
                    marker.on("popupopen", () => {
                        // Get the element with the id of trip-detail-button
                        let tripDetailButton = document.getElementById("tracker-detail-button");

                        // Add a click event listener to that element
                        tripDetailButton.addEventListener("click", () => {
                            // Navigate to the trip detail page
                            this.$router.push({
                                path: trackerLink,
                                query: { id: trip.tracker.id, tab: "details" }
                            });
                        });
                    });

                    markers.addLayer(marker);
                });
            });

            this.resetZoom(markers.getBounds());
            this.map.addLayer(markers);
            this.map.fitBounds(markers.getBounds());
        },
        onResize() {
            this.$nextTick(() => {
                if (this.viewType == "tab") {
                    this.mapHeight = window.innerHeight - 400 + "px";
                    this.cardHeight = window.innerHeight - 400 - 120 + "px";
                } else if (this.viewType == "list") {
                    this.mapHeight = window.innerHeight * 0.5 + "px";
                    this.cardHeight = window.innerHeight * 0.4 + "px";
                } else {
                    this.mapHeight = window.innerHeight * 0.5 + "px";
                }
            });
        },
        resizeMap() {
            /*** THIS FUNCTION IS RESPONSIBLE FOR FIXING MAP RENDERING ISSUE(only 1 or 2 tiles in the corner), RUN IT ON THE RIGHT LIFECYCLE HOOK ***/
            setTimeout(() => {
                //mapObject is an object which gives you acess to leaflet methods
                this.map?.invalidateSize();
            }, 1);
        }
    }
};
</script>

<style scoped>
#map {
    min-width: 360px;
    min-height: 300px;
    text-align: center;
    background-color: #ffffff;
}
.leaflet-popup-close-button {
    display: none;
}
.notOverlap {
    z-index: 0;
}
.loading-over-map {
    position: absolute;
    bottom: 35%; /* Center vertically */
    right: 50%; /* Center horizontally */
    transform: translate(50%, 50%); /* Adjust the positioning */
    z-index: 1000;
}

.roundCircle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
