import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.goTopShow),expression:"goTopShow"}],staticStyle:{"z-index":"1"},attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","small":"","color":"primary"},on:{"click":_vm.toTop}},[_c(VIcon,[_vm._v("keyboard_arrow_up")])],1),_c('div',[_c(VRow,{staticClass:"ma-0 px-3 d-flex align-center"},[_c(VCol,[_c('h2',{staticClass:"ml-3 display-3 font-weight-medium d-flex align-top"},[_vm._v(" Contour Bookings ")])]),_c(VCol,{staticClass:"px-0",attrs:{"cols":"auto"}})],1)],1),_c('BookingFilter',{staticClass:"mx-3",attrs:{"filterCode":_vm.filterCode},on:{"filter-changed":_vm.onFilterChanged,"booking-created":_vm.onBookingCreated}}),_c('BookingTable',{ref:"bookingTable",staticClass:"mx-3 mt-3 mb-3",attrs:{"bookingFilter":_vm.bookingFilter},on:{"click:row":_vm.onBookingSelected}}),_c(VNavigationDrawer,{style:({ top: '46px', zIndex: 3 }),attrs:{"width":_vm.bookingDetailDrawerWidth,"right":"","app":"","disable-route-watcher":"","temporary":""},model:{value:(_vm.bookingDetailDrawer),callback:function ($$v) {_vm.bookingDetailDrawer=$$v},expression:"bookingDetailDrawer"}},[_c('div',[(_vm.selectedBooking)?_c('BookingDetailDrawer',{key:_vm.selectedBooking ? _vm.selectedBooking.id : _vm.idFromRouter,attrs:{"booking":_vm.selectedBooking,"bookingId":_vm.idFromRouter,"isFullScreen":_vm.isBookingDetailDrawerFullScreen,"openOder":_vm.openOrder},on:{"update:booking":function($event){_vm.selectedBooking=$event},"changeFullScreen":_vm.onTripDetailDrawerFullScreenChange,"close":function($event){_vm.bookingDetailDrawer = false},"update:openOder":function($event){_vm.openOrder=$event},"update:open-oder":function($event){_vm.openOrder=$event}}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }