import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"mt-5 mb-15 px-5"},[(!_vm.canAccess)?_c('div',[_c('NoPermission')],1):_c('div',[(_vm.$apollo.queries.tripLocal.loading)?_c('LoadingBar'):(
                !_vm.$apollo.queries.tripLocal.loading && !_vm.hasTripFreightSet
            )?_c('NoData',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("loading.no_found")))]},proxy:true}])}):_vm._l((_vm.tripFreightCarrierEvents.tripFreight.edges),function(freight){return _c(VCard,{key:freight.node.id,staticClass:"mb-5 pb-0",attrs:{"elevation":"5","rounded":"xl"}},[_c(VCardTitle,{staticClass:"background2 py-3"},[_c('div',{staticClass:"mr-2"},[(_vm.tripLocal.transportMode == 'AIR')?_c(VIcon,[_vm._v("list_alt")]):(_vm.tripLocal.transportMode == 'SEA')?_c(VImg,{attrs:{"src":require('@/assets/container.png'),"width":"30"}}):(_vm.tripLocal.transportMode == 'RAIL')?_c(VIcon,[_vm._v("list_alt")]):_c(VIcon,[_vm._v("list_alt")])],1),_c('span',[_vm._v(_vm._s(_vm.getFreightTitle(freight)))]),_c(VSpacer),_c(VIcon,{staticClass:"mx-3"},[_vm._v(_vm._s(_vm.carrierIcon))]),_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.getFreightCarrierName(freight)))]),_c(VBtn,{attrs:{"icon":"","small":"","color":"grey"},on:{"click":function($event){return _vm.goToCarrierWebsite(freight)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("launch")])],1)],1),_c(VCardText,{staticClass:"py-0"},[(!freight.node.carrierEvents)?_c(VCardText,[_c('div',{staticClass:"text-caption text-center"},[_vm._v(" "+_vm._s(_vm.$t("trips.no_carrier"))+" ")])]):_c(VCardText,[_c('div',{staticClass:"px-8"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-3 primary elevation-2",attrs:{"small":"","icon":"","outlined":"","disabled":!freight.node.carrierEvents},on:{"click":function($event){_vm.sortDesc = !_vm.sortDesc}}},on),[(_vm.sortDesc)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v("arrow_downward")]):_c(VIcon,{attrs:{"color":"white"}},[_vm._v("arrow_upward")])],1)]}}],null,true)},[_c('span',[_vm._v("Sort "+_vm._s(_vm.sortDesc ? "ascending" : "descending"))])])],1),_c(VTimeline,{attrs:{"align-top":"","dense":""}},_vm._l((_vm.getEvents(
                                freight.node.carrierEvents
                            )),function(event,index){return _c(VTimelineItem,{key:index,attrs:{"color":"primary","small":""}},[_c(VRow,{staticClass:"pt-1"},[_c(VCol,{attrs:{"cols":"3"}},[_c('strong',[_vm._v(_vm._s(event.event_date))]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(event.event_time)+" ")])]),_c(VCol,[_c('strong',[_vm._v(_vm._s(_vm.formatEventTitle(event)))]),(event.event_pieces)?_c('span',[_vm._v(" ("+_vm._s(event.event_pieces)+" "+_vm._s(_vm.$t("general.pieces"))+" ) ")]):_vm._e(),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(event.current_location)+" ")])])],1)],1)}),1)],1)],1)],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }