<template>
    <v-container
        fluid
        class="px-0"
    >
        <v-row class="mt-2 mb-3 d-flex justify-end ">
            <v-btn
                rounded
                color="primary"
                class="mr-3"
                @click="openAddAlertRule = true"
                v-if="canCreate"
            >
                {{ $t("alert_rules.create_alert") }}
            </v-btn>
        </v-row>
        <!-- filter -->
        <AlertRulesFilter
            class=""
            v-on:filter-changed="onFilterChanged"
            v-on:updateAlertRulesTable="refreshTable"
            v-on:openAddAlertRuleDrawer="addAlertRuleDrawer = true"
        ></AlertRulesFilter>

        <!-- list -->
        <AlertRulesTable
            ref="alertRulesTable"
            class="mt-3"
            :alertRulesFilter="alertRulesFilter"
            v-on:click:row="onGroupSelected"
        ></AlertRulesTable>

        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="alertRuleDetailDrawer"
            :width="alertRuleDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
            disable-route-watcher
        >
            <div>
                <AlertRuleDetail
                    v-if="selectedAlertRule || idFromRouter"
                    :key="
                        selectedAlertRule ? selectedAlertRule.id : idFromRouter
                    "
                    :alertRuleProp="selectedAlertRule"
                    :alertId="idFromRouter"
                    :isFullScreen="isAlertRuleDetailDrawerFullScreen"
                    v-on:changeFullScreen="
                        onAlertRuleDetailDrawerFullScreenChange
                    "
                    v-on:close="alertRuleDetailDrawer = false"
                    v-on:refreshDataTable="refreshTable"
                ></AlertRuleDetail>
            </div>
        </v-navigation-drawer>
        <AddAlertRuleDialog
            :openAddAlertRuleDialog="openAddAlertRule"
            :createMode="true"
            v-on:refreshDataTable="$emit('updateAlertRulesTable')"
            v-on:close-dialog="openAddAlertRule = false"
            v-on:item-created="onItemCreated"
        ></AddAlertRuleDialog>
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import AlertRulesFilter from "@/components/alertrules/AlertRulesFilter.vue";
import AlertRulesTable from "@/components/alertrules/AlertRulesTable.vue";
import AlertRuleDetail from "@/components/alertrules/AlertRuleDetail.vue";
import AddAlertRuleDialog from "@/components/alertrules/create/AddAlertRuleDialog.vue";
import { eventBus } from "@/main.js";
import { TenantPermissions } from "@/core/enum";

export default {
    title: "Custom Alert Rules",
    components: {
        AlertRulesFilter,
        AlertRulesTable,
        AlertRuleDetail, 
        AddAlertRuleDialog
      
    },
    apollo: {},
    data() {
        return {
            alertRulesFilter: {
                first: 10,
                isStandardAlert: false
            },
            openAddAlertRule: false,
            selectedAlertRule: null,
            idFromRouter: null,

            alertRuleDetailDrawer: false,
            alertRuleDetailFullScreen: false
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        alertRuleDetailDrawerWidth() {
            if (this.alertRuleDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isAlertRuleDetailDrawerFullScreen() {
            return this.alertRuleDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        canCreate() {
            return this.hasPermission(
                TenantPermissions.CREATE_TENANT_ALERT_RULE
            );
        }
    },
    watch: {
        alertRuleDetailDrawer(val) {
            if (!val) {
                this.selectedAlertRule = null;

                if (this.$route.fullPath != "/customrules") {
                    this.$router.push({
                        path: "/customrules",
                        query: {},
                        params: {}
                    });
                }
                this.alertRuleDetailFullScreen = false;
            }
        },
        $route(to) {
            if (to.fullPath == "/customrules")
                return;
            if (to.query.id) {
                this.idFromRouter = to.query.id;
                this.alertRuleDetailDrawer = true;
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        if (this.$route.query.id) {
            this.idFromRouter = this.$route.query.id;
            this.alertRuleDetailDrawer = true;
        }
    },
    methods: {
        refreshTable() {
            this.$refs.alertRulesTable.$apollo.queries.alertRules.refetch();
        },
        onFilterChanged(filter) {
            this.alertRulesFilter = filter;
        },
        onGroupSelected(alertRule) {
            this.selectedAlertRule = alertRule.node;
            this.$router.push({
                query: { id: this.selectedAlertRule.id },
            })
            this.alertRuleDetailDrawer = true;
        },
        onAlertRuleDetailDrawerFullScreenChange(val) {
            this.alertRuleDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onItemCreated(alertRule) {
            this.selectedAlertRule = alertRule;
            this.idFromRouter = alertRule.id;
            this.$router.push({
                path: "/customrules",
                query: { id: this.selectedAlertRule.id },
            })
            this.alertRuleDetailDrawer = true;
            this.refreshTable();
        }
    }
};
</script>
<style scoped>
    ::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
</style>
