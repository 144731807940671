<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5 transparent"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                outlined
            >
                <v-row dense align="start" class="d-flex align-center py-0">
                    <v-col class="py-0">
                        <v-row class="background">
                            <v-text-field
                                v-model="search"
                                outlined
                                hide-details
                                :label="!isHistoryTab ? $t('trackers.search') : $t('trackers.search_history')"
                                prepend-inner-icon="search"
                            >
                                <template v-slot:append-outer>
                                    <v-menu
                                        v-model="menu"
                                        offset-y
                                        offset-x
                                        nudge-left="1095"
                                        nudge-top="-10"
                                        nudge-width="600"
                                        min-width="auto"
                                        content-class="classContent"
                                        :close-on-click="true"
                                        :close-on-content-click="false"
                                        v-if="!isSmallScreen"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on"
                                                block
                                                x-large
                                                @click="openFilterMenu"
                                                style="height: 53px;margin-top: -1em;"
                                            >
                                                <v-icon>
                                                    filter_alt
                                                </v-icon>
                                                <v-spacer></v-spacer>
                                                <div>
                                                    {{ $t("trips.filter") }}
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-btn>
                                        </template>
                                        <v-card class="px-5 py-5" height="100%" width="100%" v-if="!isHistoryTab">
                                            <v-card-text class="pa-0">
                                                <v-row align="start" class="px-5 d-flex align-center">
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="3"
                                                        lg="3"
                                                        v-for="f in dynamicFilters"
                                                        :key="f"
                                                        :class="getFilterChoiceClassByCode(f)"
                                                    >
                                                        <!-- Serial Number -->
                                                        <v-text-field
                                                            v-if="f === 'serialNumber'"
                                                            v-model="menuObject['serialNumber_Icontains']"
                                                            :label="getFilterChoiceNameByCode('serialNumber')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            class="maxWidth"
                                                        ></v-text-field>

                                                        <!-- Brand -->
                                                        <v-text-field
                                                            v-if="f === 'brand'"
                                                            v-model="menuObject['brand_Icontains']"
                                                            :label="getFilterChoiceNameByCode('brand')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            class="maxWidth"
                                                        ></v-text-field>

                                                        <!-- Package Number -->
                                                        <v-text-field
                                                            v-if="f === 'packageNumber'"
                                                            v-model="menuObject['packageNumberIcontains']"
                                                            :label="getFilterChoiceNameByCode('packageNumber')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            class="maxWidth"
                                                        ></v-text-field>

                                                        <!-- Comment -->
                                                        <v-text-field
                                                            v-if="f === 'comment'"
                                                            v-model="menuObject['commentIcontains']"
                                                            :label="getFilterChoiceNameByCode('comment')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            class="maxWidth"
                                                        ></v-text-field>

                                                        <!-- Trip Reference Number -->
                                                        <v-text-field
                                                            v-if="f === 'tripReference'"
                                                            v-model="menuObject['tripReference']"
                                                            :label="getFilterChoiceNameByCode('tripReference')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            class="maxWidth"
                                                        >
                                                            <template v-slot:append>
                                                                <v-tooltip bottom content-class="tooltipContent">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon v-on="on" color="primary">
                                                                            info
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>{{ $t("general.info_tracker_empty") }}</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </v-text-field>

                                                        <!-- Last connection date  -->
                                                        <v-menu
                                                            v-if="f === 'lastConnectionTime'"
                                                            ref="lastConnectionTimeMenu"
                                                            v-model="openLastConnectionTimeMenu"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                            max-width="auto"
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    :value="dateRangeText"
                                                                    :label="
                                                                        getFilterChoiceNameByCode('lastConnectionTime')
                                                                    "
                                                                    readonly
                                                                    clearable
                                                                    hide-details
                                                                    dense
                                                                    @click:clear="onClearDates()"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="dateRange"
                                                                :first-day-of-week="1"
                                                                range
                                                                no-title
                                                                scrollable
                                                                color="primary"
                                                            >
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                    text
                                                                    rounded
                                                                    color="primary"
                                                                    @click="cancelDatePicker"
                                                                >
                                                                    {{ $t("general.cancel") }}
                                                                </v-btn>
                                                                <v-btn
                                                                    outlined
                                                                    rounded
                                                                    color="primary"
                                                                    :disabled="dateRange.length < 1"
                                                                    @click="saveDatePicker"
                                                                >
                                                                    OK
                                                                </v-btn>
                                                            </v-date-picker>
                                                        </v-menu>

                                                        <!-- Expiration Date List -->
                                                        <v-select
                                                            v-if="f === 'relativeExpiryDateRange'"
                                                            v-model="menuObject['relativeExpiryDateRange']"
                                                            :items="relativeExpiryDateRangeChoices"
                                                            :label="
                                                                getFilterChoiceNameByCode('relativeExpiryDateRange')
                                                            "
                                                            item-text="text"
                                                            item-value="value"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            color="primary"
                                                            class="maxWidth"
                                                        ></v-select>

                                                        <!-- Activate Date List -->
                                                        <v-select
                                                            v-if="f === 'relativeActivateDateRange'"
                                                            v-model="menuObject['relativeActivateDateRange']"
                                                            :items="relativeActivateDateRangeChoices"
                                                            :label="
                                                                getFilterChoiceNameByCode('relativeActivateDateRange')
                                                            "
                                                            item-text="text"
                                                            item-value="value"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            color="primary"
                                                            class="maxWidth"
                                                        ></v-select>

                                                        <!-- Status -->
                                                        <!-- <v-select
                                                                v-if="f === 'status'"
                                                                v-model="menuObject['status']"
                                                                :items="statusSelection"
                                                                :label="getFilterChoiceNameByCode('status')"
                                                                item-text="text"
                                                                item-value="value"
                                                                clearable
                                                                hide-details
                                                                dense
                                                                color="primary"
                                                                class="maxWidth"
                                                            ></v-select> -->

                                                        <!--Archived-->
                                                        <v-autocomplete
                                                            v-if="f === 'isArchived'"
                                                            v-model="menuObject['isArchived']"
                                                            :items="archivedChoices"
                                                            :label="getFilterChoiceNameByCode('isArchived')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            color="primary"
                                                            class="maxWidth"
                                                        ></v-autocomplete>

                                                        <!--Tenant-->
                                                        <v-autocomplete
                                                            v-if="f === 'tenant' && isStaff"
                                                            v-model="menuObject['tenant']"
                                                            :items="tenants.edges"
                                                            :no-data-text="$t('loading.no_available')"
                                                            :item-text="item => item.node.name"
                                                            :item-value="item => item.node.id"
                                                            :label="getFilterChoiceNameByCode('tenant')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            color="primary"
                                                            attach
                                                            class="maxWidth"
                                                        ></v-autocomplete>

                                                        <!--Tenant is null-->
                                                        <v-autocomplete
                                                            v-if="f === 'tenantIsnull' && isStaff"
                                                            v-model="menuObject['tenant_Isnull']"
                                                            :items="archivedChoices"
                                                            :label="getFilterChoiceNameByCode('tenantIsnull')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            color="primary"
                                                            attach
                                                        ></v-autocomplete>

                                                        <!-- Is Beacon Tracker -->
                                                        <v-select
                                                            v-if="f === 'isBeaconTracker'"
                                                            v-model="menuObject['isBeaconTracker']"
                                                            :items="archivedChoices"
                                                            :label="getFilterChoiceNameByCode('isBeaconTracker')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            color="primary"
                                                            class="maxWidth"
                                                        ></v-select>
                                                    </v-col>
                                                    <!-- <v-col
                                                            class="col-auto col-md-4 col-lg-3 col-xl-3 d-flex "
                                                        >
                                                            <div
                                                                class="d-flex align-center"
                                                            >
                                                                <v-switch
                                                                    :label="getFilterChoiceNameByCode('isBeaconTracker')"
                                                                    v-model="menuObject['isBeaconTracker']"
                                                                ></v-switch>
                                                            </div>
                                                        </v-col> -->
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions class="px-0 pt-6">
                                                <v-spacer></v-spacer>
                                                <v-btn text @click="cancelFilter">
                                                    Cancel
                                                </v-btn>
                                                <v-btn color="primary" @click="searchFields(menuObject)">
                                                    Search
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        <v-card v-else class="px-5 py-5" height="100%" width="100%">
                                            <v-card-text class="pa-0">
                                                <v-row align="start" class="px-5 d-flex align-center">
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="3"
                                                        lg="3"
                                                        v-for="f in dynamicHistoryFilters"
                                                        :key="f"
                                                    >
                                                        <!-- File Name -->
                                                        <v-text-field
                                                            v-if="f === 'fileName'"
                                                            v-model="historyFilter.fileNameIexact"
                                                            :label="getHistoryFilterChoiceNameByCode('fileName')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            class="maxWidth"
                                                        ></v-text-field>

                                                        <!-- Brand -->
                                                        <v-text-field
                                                            v-if="f === 'brand'"
                                                            v-model="historyFilter.brand"
                                                            :label="getHistoryFilterChoiceNameByCode('brand')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            class="maxWidth"
                                                        ></v-text-field>

                                                        <!-- Upload by -->
                                                        <v-text-field
                                                            v-if="f === 'uploadedBy'"
                                                            v-model="historyFilter.createdByFirstNameIcontains"
                                                            :label="getFilterChoiceNameByCode('uploadedBy')"
                                                            clearable
                                                            hide-details
                                                            dense
                                                            class="maxWidth"
                                                        ></v-text-field>

                                                        <!-- Uploaded Date -->
                                                        <v-menu
                                                            v-if="f === 'uploadedDate'"
                                                            ref="uploadedDateMenu"
                                                            v-model="openUploadedDateMenu"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                            max-width="auto"
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    :value="uploadedDateRangeText"
                                                                    :label="getFilterChoiceNameByCode('uploadedDate')"
                                                                    readonly
                                                                    clearable
                                                                    hide-details
                                                                    dense
                                                                    @click:clear="onClearDates()"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="uploadedDateRange"
                                                                :first-day-of-week="1"
                                                                range
                                                                no-title
                                                                scrollable
                                                                color="primary"
                                                            >
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                    text
                                                                    rounded
                                                                    color="primary"
                                                                    @click="cancelDatePicker"
                                                                >
                                                                    {{ $t("general.cancel") }}
                                                                </v-btn>
                                                                <v-btn
                                                                    outlined
                                                                    rounded
                                                                    color="primary"
                                                                    :disabled="uploadedDateRange.length < 1"
                                                                    @click="saveDatePicker"
                                                                >
                                                                    OK
                                                                </v-btn>
                                                            </v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions class="px-0 pt-6">
                                                <v-spacer></v-spacer>
                                                <v-btn text @click="cancelFilter">
                                                    Cancel
                                                </v-btn>
                                                <v-btn color="primary" @click="searchFields(menuObject)">
                                                    Search
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-row>
                        <v-row class="background">
                            <v-col cols="auto" v-for="i in filterObjs" :key="i.id" class="pl-0 py-2">
                                <!-- <v-chip
                                        label
                                        close
                                        small
                                        class="elevation-0 text-capitalize"
                                        @click:close="deleteFilterValue(i.id)"
                                        v-if="
                                            i.id !=
                                                'plannedDepartureDate_Gte' &&
                                                i.id !=
                                                    'plannedDepartureDate_Lte' &&
                                                i.id !=
                                                    'plannedArrivalDate_Gte' &&
                                                i.id != 'plannedArrivalDate_Lte'
                                        "
                                    >
                                        {{ i.name + ":" + " " + i.value }}
                                    </v-chip> -->
                                <v-chip
                                    label
                                    close
                                    small
                                    class="elevation-0 text-capitalize"
                                    @click:close="deleteFilterValue(i.id)"
                                    v-if="i.value != null && i.value != ''"
                                >
                                    {{ i.name + ":" + " " + i.value }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import { TrackerPermissions } from "@/core/enum";

export default {
    props: ["selector", "selectedTrackers", "filterCode", "isHistoryTab"],
    components: {
        //FilterMenu,
        //AddTrip,
        // PartnerAutoComplete,
    },

    apollo: {},

    data() {
        return {
            fab: false,
            isLoading: false,
            sortAsc: true,
            shouldStickyFilter: false,
            menu: false,
            openLastConnectionTimeMenu: false,
            openUploadedDateMenu: false,
            dateRange: [],
            uploadedDateRange: [],
            savedExpiryDateRange: [],
            batteryRange: [],
            savedDateRange: [],
            savedDateExpiry: [],
            savedBatteryRange: [],
            openTrackerChangeLocationDialog: false,
            openTrackerChangeTenantDialog: false,
            tenants: { edges: [] },
            relativeExpiryDateRange: null,
            savedActiveDateRange: [],
            menuObject: {},
            filter: {
                first: 25,
                after: null,
                search: null,
                serialNumber_Icontains: null,
                isArchived: null,
                lastConnectionTime_Gte: null,
                lastConnectionTime_Lte: null,
                orderBy: "-lastConnectionTime",
                tenant: null,
                tenant_Isnull: null,
                expiryDateGte: null,
                expiryDateLte: null,
                activatedDateGte: null,
                activatedDateLte: null,
                packageNumberIcontains: null,
                commentIcontains: null,
                isBeaconTracker: null,
                brandIcontains: null,
                status: null,
                tripReference: null
            },
            historyFilter: {
                first: 25,
                after: null,
                search: null,
                orderBy: "-createdAt",
                tenant: null,
                fileNameIcontains: null,
                fileNameIexact: null,
                brandIcontains: null,
                brand: null,
                createdAtGte: null,
                createdAtLte: null,
                createdByFirstNameIcontains: null,
                createdByLastNameIcontains: null, 
                status:null
            },
            filterChoices: null,
            relativeActiveDateRange: null,
            historyFilterChoices: null,
            sortBy: null,
            filterObjs: []
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 560;
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        dateRangeText() {
            if (this.dateRange.length > 1) {
                let date1 = moment(this.dateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.dateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.dateRange.length === 1) {
                return moment(this.dateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.dateRange;
            }
        },
        uploadedDateRangeText() {
            if (this.uploadedDateRange.length > 1) {
                let date1 = moment(this.uploadedDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.uploadedDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.uploadedDateRange.length === 1) {
                return moment(this.uploadedDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.uploadedDateRange;
            }
        },
        uploadedByName: {
            get() {
                return this.historyFilter.createdByFirstNameIcontains;
            },
            set(value) {
                this.historyFilter.createdByFirstNameIcontains = value;
                this.historyFilter.createdByLastNameIcontains = value;
            }
        },
        getFilters: {
            get() {
                if (this.isHistoryTab) {
                    return this.historyFilter;
                }
                return this.filter;
            },
            set(value) {
                if (this.isHistoryTab) {
                    this.historyFilter = value;
                }
                this.filter = value;
            }
        },
        search: {
            get() {
                if (this.isHistoryTab) {
                    return this.historyFilter.search;
                }
                return this.filter.search;
            },
            set(value) {
                if (this.isHistoryTab) {
                    this.historyFilter.search = value;
                    this.updateFilter("search", this.historyFilter);
                } else {
                    this.filter.search = value;
                    this.updateFilter("search", this.filter);
                }
            }
        },
        _filterChoices() {
            return this.filterChoices;
        },
        dynamicFilters() {
            let _dynamicFilters = [];
            if (this.filterChoices) {
                this.filterChoices.forEach(f => {
                    _dynamicFilters.push(f.code);
                });
            }
            return _dynamicFilters;
        },
        dynamicHistoryFilters() {
            let _dynamicFilters = [];
            if (this.historyFilterChoices) {
                this.historyFilterChoices.forEach(f => {
                    _dynamicFilters.push(f.code);
                });
            }
            return _dynamicFilters;
        },
        tenantPartners() {
            return this.tripPartnerSet?.edges?.map(e => e.node) || [];
        },
        isStaff() {
            return this.me.isStaff || false;
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "serialNumber",
                    name: this.$t("headers.serial_number")
                },
                {
                    code: "brand",
                    name: this.$t("headers.brand")
                },
                // {
                //     code: "stockLocation",
                //     name: "Stock Location"
                // },
                {
                    code: "isArchived",
                    name: this.$t("headers.archived")
                },
                {
                    code: "lastConnectionTime",
                    name: this.$t("headers.last_connection")
                },
                {
                    code: "activatedDate",
                    name: "Activation Date"
                },
                {
                    code: "expiryDate",
                    name: this.$t("headers.expiry_date")
                }
                // {
                //     code: "lastBatteryValue",
                //     name: "Battery Level"
                // }
            ];
            return _sortByChoices;
        },
        statusSelection: function() {
            let _statusSelection = [
                { text: this.$t("general.all"), value: "all" },
                { text: this.$t("general.activated"), value: "active" },
                { text: this.$t("general.not_activated"), value: "inactive" }
            ];
            return _statusSelection;
        },
        archivedChoices: function() {
            let _archivedChoices = [
                { text: this.$t("general.yes"), value: true },
                { text: this.$t("general.no"), value: false }
            ];
            return _archivedChoices;
        },
        relativeExpiryDateRangeChoices: function() {
            let _relativeExpiryDateRangeChoices = [
                {
                    text: this.$t("general.expires7"),
                    value: "7"
                },
                {
                    text: this.$t("general.expires14"),
                    value: "14"
                },
                {
                    text: this.$t("general.expires30"),
                    value: "30"
                }
                /*  this.$t("general.expires7"),
                this.$t("general.expires14"),
                this.$t("general.expires30") */
            ];
            return _relativeExpiryDateRangeChoices;
        },
        relativeActivateDateRangeChoices: function() {
            // To add more choices, add more objects to the array below
            let _relativeDateRangeChoices = [
                {
                    text: this.$t("general.activated7"),
                    value: "7"
                },
                {
                    text: this.$t("general.activated14"),
                    value: "14"
                },
                {
                    text: this.$t("general.activated30"),
                    value: "30"
                }
            ];
            return _relativeDateRangeChoices;
        },
        defaultFilterChoices: function() {
            let _defaultFilterChoices;
            if (this.isHistoryTab) {
                return [
                    {
                        // for filter menu
                        code: "fileName",
                        name: "File Name",
                        tag: "trackers.file_name",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "brand",
                        name: "Brand",
                        tag: "headers.brand",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "uploadedBy",
                        name: "Uploaded By",
                        tag: "general.uploaded_by",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "uploadedDate",
                        name: "Uploaded Date",
                        tag: "general.uploaded_at",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    }
                ];
            }

            if (this.me.isStaff) {
                _defaultFilterChoices = [
                    {
                        // for filter menu
                        code: "serialNumber",
                        name: "Serial Number",
                        tag: "headers.serial_number",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "brand",
                        name: "Brand",
                        tag: "headers.brand",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "packageNumber",
                        name: "Package Number",
                        tag: "headers.package_number",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "comment",
                        name: "Comment",
                        tag: "headers.comment",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    // {
                    //     // for filter menu
                    //     code: "status",
                    //     name: "Status",
                    //     tag: "headers.status",
                    //     enabled: true,
                    //     // for filter layout
                    //     class: "col-auto col-md-auto"
                    // },
                    {
                        // for filter menu
                        code: "lastConnectionTime",
                        name: "Last Connection",
                        tag: "headers.last_connection",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "relativeExpiryDateRange",
                        name: "Expiry Date",
                        tag: "headers.expiry_date",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "relativeActivateDateRange",
                        name: "Active Date",
                        tag: "headers.activation_date",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },

                    /*  {
                    // for filter menu
                    code: "lastBatteryValue",
                    name: "Battery Level",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                }, */
                    {
                        // for filter menu
                        code: "isArchived",
                        name: "Archived",
                        tag: "headers.archived",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-3 col-lg-2"
                    },
                    {
                        // for filter menu
                        code: "isBeaconTracker",
                        name: "Beacon Tracker",
                        tag: "headers.beaconTracker",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-3 col-lg-2"
                    },
                    {
                        code: "tenant",
                        name: "Tenant",
                        tag: "trackers.tenant",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    }
                    // {
                    //     code: "tenantIsnull",
                    //     name: "Not Assigned",
                    //     tag: "trackers.not_assigned",
                    //     enabled: true,
                    //     // for filter layout
                    //     class: "col-auto col-md-auto"
                    // }
                    // {
                    //     // for filter menu
                    //     code: "kpi",
                    //     name: "KPI",
                    //     enabled: true,
                    //     // for filter layout
                    //     class: "col-auto col-md-3 col-lg-2"
                    // }
                ];
            } else {
                _defaultFilterChoices = [
                    {
                        // for filter menu
                        code: "serialNumber",
                        name: "Serial Number",
                        tag: "headers.serial_number",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "brand",
                        name: "Brand",
                        tag: "headers.brand",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "packageNumber",
                        name: "Package Number",
                        tag: "headers.package_number",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "comment",
                        name: "Comment",
                        tag: "headers.comment",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    // {
                    //     // for filter menu
                    //     code: "status",
                    //     name: "Status",
                    //     tag: "headers.status",
                    //     enabled: true,
                    //     // for filter layout
                    //     class: "col-auto col-md-auto"
                    // },
                    {
                        // for filter menu
                        code: "lastConnectionTime",
                        name: "Last Connection",
                        tag: "headers.last_connection",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "relativeExpiryDateRange",
                        name: "Expiry Date",
                        tag: "headers.expiry_date",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "relativeActivateDateRange",
                        name: "Active Date",
                        tag: "headers.activation_date",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "tripReference",
                        name: "Trip Reference",
                        tag: "headers.trip_number",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    /*  {
                        // for filter menu
                        code: "lastBatteryValue",
                        name: "Battery Level",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    }, */
                    {
                        // for filter menu
                        code: "isArchived",
                        name: "Archived",
                        tag: "headers.archived",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-3 col-lg-2"
                    },
                    {
                        // for filter menu
                        code: "isBeaconTracker",
                        name: "Beacon Tracker",
                        tag: "headers.beaconTracker",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-3 col-lg-2"
                    }
                    // {
                    //     // for filter menu
                    //     code: "kpi",
                    //     name: "KPI",
                    //     enabled: true,
                    //     // for filter layout
                    //     class: "col-auto col-md-3 col-lg-2"
                    // }
                ];
            }
            return _defaultFilterChoices;
        }
    },
    watch: {
        // sortBy(val) {
        //     if (val) {
        //         this.filter.orderBy = this.sortAsc ? val : `-${val}`;
        //     } else {
        //         this.filter.orderBy = null;
        //     }
        // },
        // sortAsc(val) {
        //     if (this.sortBy) {
        //         this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
        //     } else {
        //         this.filter.orderBy = null;
        //     }
        // },
        /* "historyFilter.search"(value) {
            if (value) {
                this.updateFilter("search", this.historyFilter);
            }
        }, */
        // historyFilter: {
        //     handler(value) {
        //         if (value) {
        //             this.$emit("history-filter-changed", JSON.parse(JSON.stringify(value)));
        //             helper.setFilterValues("trackerHistoryLogTableFilters", value);
        //         }
        //     },
        //     deep: true
        // },
        /* filter: {
            handler(value) {
                if (value) {
                    
                }
            },
            deep: true
        }, */
        /* relativeExpiryDateRange(val) {
            const _index = this.relativeExpiryDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last 7 days
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(-8, "day")
                        .format("YYYY-MM-DD");
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last 14 days
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(-15, "day")
                        .format("YYYY-MM-DD");
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last 30 days
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(-31, "day")
                        .format("YYYY-MM-DD");
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.savedExpiryDateRange = [];
            }
        }, */
        /* savedExpiryDateRange(val) {
            if (val.length == 1) {
                this.filter.expiryDateLte = val[0] + "T00:00:00";
                this.filter.expiryDateGte = val[0] + "23:59:59";
            } else if (val.length == 2) {
                this.filter.expiryDateLte = val[0] + "T00:00:00";
                this.filter.expiryDateGte = val[1] + "T23:59:59";
            } else {
                this.filter.expiryDateGte = null;
                this.filter.expiryDateLte = null;
            }
        } */
    },
    created() {
        this.TrackerPermissions = TrackerPermissions;
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        if (this.isStaff) {
            this.defaultFilterChoices.push(
                {
                    // for filter menu
                    code: "tenant",
                    name: this.$t("trackers.tenant"),
                    tag: "trackers.tenant",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                },
                {
                    // for filter menu
                    code: "tenantIsnull",
                    name: this.$t("trackers.not_assigned"),
                    tag: "trackers.not_assigned1",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                }
            );
        }
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "trackersTableFilters");
        this.historyFilterChoices = helper.getFilterChoices(
            [
                {
                    // for filter menu
                    code: "fileName",
                    name: "File Name",
                    tag: "trackers.file_name",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                },
                {
                    // for filter menu
                    code: "brand",
                    name: "Brand",
                    tag: "headers.brand",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                },
                {
                    // for filter menu
                    code: "uploadedBy",
                    name: "Uploaded By",
                    tag: "general.uploaded_by",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                },
                {
                    // for filter menu
                    code: "uploadedDate",
                    name: "Uploaded Date",
                    tag: "general.uploaded_at",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                }
            ],
            "trackerHistoryLogTableFilters"
        );
    },
    mounted() {
        this.onResize();
        this.$nextTick(() => {
            this.initializeFilter();
        });
    },
    methods: {
        initializeFilter() {
            let _filter = null;

            if (!this.isHistoryTab) {
                _filter = helper.getFilterValues("trackerTableFilters") || {};
            } else {
                _filter = helper.getFilterValues("trackerHistoryLogTableFilters") || {};
            }

            //Filter by code
            if (this.filterCode && !this.isHistoryTab) {
                let code = this.filterCode;
                if (code === "AVAILABLE_TRACKERS") {
                    _filter.status = "inactive";
                }
                //Remove filter route
                this.removeFilterRoute();
            }

            if (Object.keys(_filter).length > 0) {
                if (_filter && _filter.orderBy && _filter.orderBy.includes("-")) {
                    this.sortBy = _filter.orderBy.replace("-", "");
                    this.sortAsc = false;
                } else if (_filter && _filter.orderBy) {
                    this.sortBy = _filter.orderBy;
                    this.sortAsc = true;
                }

                if (_filter.lastConnectionTime_Gte) {
                    this.dateRange = [
                        _filter.lastConnectionTime_Gte.split("T")[0],
                        _filter.lastConnectionTime_Lte.split("T")[0]
                    ];
                }

                //Expiry Date
                if (!_filter.expiryDateGte) {
                    _filter.expiryDateLte = null;
                }

                if (_filter.expiryDateGte) {
                    const _startDate = moment(_filter.expiryDateGte);
                    const _endDate = moment(_filter.expiryDateLte);
                    const _diff = _endDate.diff(_startDate, "days");
                    this.relativeExpiryDateRange = `${_diff - 1}`;
                }

                //Activated Date
                if (!_filter.activatedDateGte) {
                    _filter.activatedDateLte = null;
                }

                if (_filter.activatedDateGte && _filter.activatedDateLte) {
                    const _startDate = moment(_filter.activatedDateGte);
                    const _endDate = moment(_filter.activatedDateLte);
                    const _diff = _endDate.diff(_startDate, "days");
                    this.relativeActiveDateRange = `${_diff - 1}`;
                }

                Object.keys(_filter).forEach(key => {
                    if (key !== "status" && key !== "activatedDateGte" && key !== "activatedDateLte") {
                        this.menuObject[key] = _filter[key];
                    } else if (key === "activatedDateGte" || key === "activatedDateLte") {
                        this.menuObject["relativeActivateDateRange"] = this.relativeActiveDateRange;
                    } else if (key === "expiryDateGte" || key === "expiryDateLte") {
                        this.menuObject["relativeExpiryDateRange"] = this.relativeExpiryDateRange;
                    }
                });

                Object.keys(_filter).forEach(key => {
                    if (key) {
                        if (!this.isHistoryTab) {
                            this.filter[key] = _filter[key];
                        } else {
                            this.historyFilter[key] = _filter[key];
                        }
                    }
                });
            }

            if (!this.isHistoryTab) {
                this.updateFilter("populateFilter", this.filter);
            } else if (!this.isHistoryTab && this.filterCode) {
                this.updateFilter("updateFilter", this.filter);
            } else {
                this.updateFilter("historyFilter", this.historyFilter);
            }
        },
        updateFilter(updateFrom, filter) {
            //update query values
            if (!this.isHistoryTab) {
                this.$emit("filter-changed", JSON.parse(JSON.stringify(filter)));
            } else {
                this.$emit("history-filter-changed", JSON.parse(JSON.stringify(filter)));
            }

            //update store
            if (updateFrom !== "populateFilter" || updateFrom !== "search" || updateFrom !== "historyFilter") {
                helper.setFilterValues("trackerTableFilters", filter);
            } else if (updateFrom === "historyFilter") {
                helper.setFilterValues("trackerHistoryLogTableFilters", filter);
            }
            //update filter object
            this.filterValues(filter);
        },
        removeFilterRoute() {
            this.$router.replace({ query: {} });
        },
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        getHistoryFilterChoiceClassByCode(code) {
            return this.historyFilterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        getFilterChoiceNameByCode(code) {
            return this.$t(
                this.defaultFilterChoices.find(item => {
                    return item.code === code;
                })?.tag
            );
        },
        getHistoryFilterChoiceNameByCode(code) {
            return this.$t(
                this.defaultFilterChoices.find(item => {
                    return item.code === code;
                })?.tag
            );
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        saveDatePicker() {
            if (!this.isHistoryTab) {
                if (this.dateRange.length == 1) {
                    this.filter.lastConnectionTime_Gte = this.dateRange[0] + "T00:00:00";
                    this.filter.lastConnectionTime_Lte = this.dateRange[0] + "T23:59:59";
                } else if (this.dateRange.length == 2) {
                    this.filter.lastConnectionTime_Gte = this.dateRange[0] + "T00:00:00";
                    this.filter.lastConnectionTime_Lte = this.dateRange[1] + "T23:59:59";
                } else {
                    this.filter.lastConnectionTime_Gte = null;
                    this.filter.lastConnectionTime_Lte = null;
                }
                this.openLastConnectionTimeMenu = false;
            } else {
                if (this.uploadedDateRange.length == 1) {
                    this.historyFilter.createdAtGte = this.uploadedDateRange[0] + "T00:00:00";
                    this.historyFilter.createdAtLte = this.uploadedDateRange[0] + "T23:59:59";
                } else if (this.uploadedDateRange.length == 2) {
                    this.historyFilter.createdAtGte = this.uploadedDateRange[0] + "T00:00:00";
                    this.historyFilter.createdAtLte = this.uploadedDateRange[1] + "T23:59:59";
                } else {
                    this.historyFilter.createdAtGte = null;
                    this.historyFilter.createdAtLte = null;
                }
                this.openUploadedDateMenu = false;
            }
        },
        onClearDates() {
            if (this.isHistoryTab) {
                this.uploadedDateRange = [];
                this.historyFilter.createdAtLte = null;
                this.historyFilter.createdAtGte = null;
            } else {
                this.dateRange = [];
                this.savedDateRange = [];
                this.savedDateExpiry = [];
                this.relativeDateRange = null;
                this.filter.lastConnectionTime_Gte = null;
                this.filter.lastConnectionTime_Lte = null;
                this.filter.expiryDateGte = null;
                this.filter.expiryDateLte = null;
                this.filter.activatedDateGte = null;
                this.filter.activatedDateLte = null;
            }
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        },
        convertActivateDateRange(val) {
            //Activate date range
            //convert the relative date range - last 7, 14, 30 days for add more date range its easy to add
            let _startDate = null;
            let _endDate = null;
            let _range = [];
            switch (val) {
                case "7":
                    // last 7 days
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(8, "day")
                        .format("YYYY-MM-DD");
                    _range = [_startDate, _endDate];
                    break;
                case "14":
                    // last 14 days
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(15, "day")
                        .format("YYYY-MM-DD");
                    _range = [_startDate, _endDate];
                    break;
                case "30":
                    // last 30 days
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(31, "day")
                        .format("YYYY-MM-DD");
                    _range = [_startDate, _endDate];
                    break;
                default:
                    _range = [];
            }

            return _range;
        },
        searchFields(menuItems) {
            //populate the filter object
            let menuKeys = Object.keys(menuItems);
            let _filter = helper.getFilterValues("trackerTableFilters");

            //populate the filter object if the last updates are made in filter store
            if (_filter) {
                Object.keys(_filter).forEach(key => {
                    if (key) {
                        this.filter[key] = _filter[key];
                    }
                });
            }

            if (!this.isHistoryTab) {
                menuKeys.forEach(key => {
                    if (
                        menuItems[key] !== null &&
                        menuItems[key] !== "" &&
                        key !== "relativeActivateDateRange" &&
                        key !== "relativeExpiryDateRange"
                    ) {
                        this.filter[key] = menuItems[key];
                    } else if (key === "relativeActivateDateRange") {
                        this.relativeActiveDateRange = menuItems[key];
                        let _range = this.convertActivateDateRange(menuItems[key]);
                        if (_range.length == 1) {
                            this.filter.activatedDateGte = _range[0] + "T00:00:00";
                            this.filter.activatedDateLte = _range[0] + "23:59:59";
                        } else if (_range.length == 2) {
                            this.filter.activatedDateGte = _range[0] + "T00:00:00";
                            this.filter.activatedDateLte = _range[1] + "T23:59:59";
                        } else {
                            this.filter.activatedDateGte = null;
                            this.filter.activatedDateLte = null;
                        }
                    } else if (key === "relativeExpiryDateRange") {
                        this.relativeExpiryDateRange = menuItems[key];
                        let _range = this.convertActivateDateRange(menuItems[key]);
                        if (_range.length == 1) {
                            this.filter.expiryDateGte = _range[0] + "T00:00:00";
                            this.filter.expiryDateLte = _range[0] + "23:59:59";
                        } else if (_range.length == 2) {
                            this.filter.expiryDateGte = _range[0] + "T00:00:00";
                            this.filter.expiryDateLte = _range[1] + "T23:59:59";
                        } else {
                            this.filter.expiryDateGte = null;
                            this.filter.expiryDateLte = null;
                        }
                    } else {
                        this.filter[key] = null;
                    }
                });

                this.updateFilter("populateFilter", this.filter);
            } else {
                this.updateFilter("customUpdate", this.historyFilter);
            }

            this.menu = false;
        },
        cancelFilter() {
            //clear menuObject
            this.menuObject = {};
            this.menu = false;
        },
        filterValues(object) {
            const filterKeysToIgnore = ["first", "after", "orderBy", "search", "status"];
            const keys = JSON.parse(JSON.stringify(Object.keys(object)));
            let values = [];
            //this.filterObjs = [];

            let filteredKeys = keys.filter(key => {
                return object[key] != null && !filterKeysToIgnore.includes(key);
            });

            filteredKeys.forEach(key => {
                if (!values.find(e => e.id === key)) {
                    if (this.replaceName(key))
                        values.push({
                            id: key,
                            name: this.replaceName(key),
                            value: this.valuePopulate(object[key], key)
                        });
                }
            });

            let lastConnectionTime_Gte = values.find(e => e.id === "lastConnectionTime_Gte");
            let lastConnectionTime_Lte = values.find(e => e.id === "lastConnectionTime_Lte");
            if (lastConnectionTime_Gte && lastConnectionTime_Lte) {
                if (lastConnectionTime_Gte.value !== lastConnectionTime_Lte.value)
                    lastConnectionTime_Gte.value = lastConnectionTime_Gte.value + " ~ " + lastConnectionTime_Lte.value;

                values = values.filter(e => e.id !== "lastConnectionTime_Lte");
            }
            let createdAtGte = values.find(e => e.id === "createdAtGte");
            let createdAtLte = values.find(e => e.id === "createdAtLte");
            if (createdAtGte && createdAtLte) {
                if (createdAtGte.value !== createdAtLte.value)
                    createdAtGte.value = createdAtGte.value + " ~ " + createdAtLte.value;

                values = values.filter(e => e.id !== "createdAtLte");
            }

            this.filterObjs = values;

            // return values;
        },
        deleteFilterValue(key) {
            if (!this.isHistoryTab) {
                if (key === "activatedDateGte" || key === "activatedDateLte") {
                    this.menuObject = {};
                    this.filter["activatedDateGte"] = null;
                    this.filter["activatedDateLte"] = null;
                } else if (key == "lastConnectionTime_Gte" || key == "lastConnectionTime_Lte") {
                    this.menuObject = {};
                    this.filter["lastConnectionTime_Gte"] = null;
                    this.filter["lastConnectionTime_Lte"] = null;
                } else if (key === "expiryDateGte" || key === "expiryDateLte") {
                    this.menuObject = {};
                    this.filter["expiryDateGte"] = null;
                    this.filter["expiryDateLte"] = null;
                } else {
                    this.menuObject[key] = null;
                    this.filter[key] = null;
                }

                this.updateFilter("delete", this.filter);
            } else {
                if (key == "createdAtGte" || key == "createdAtLte") {
                    this.historyFilter["createdAtGte"] = null;
                    this.historyFilter["createdAtLte"] = null;
                } else {
                    this.historyFilter[key] = null;
                }

                this.updateFilter("delete", this.historyFilter);
            }
        },
        replaceName(key) {
            let replace = "";
            switch (key) {
                case "serialNumber_Icontains":
                    replace = "Serial Number";
                    break;
                // case "brand_Icontains":
                //     replace = "Brand";
                //     break;
                case "packageNumberIcontains":
                    replace = "Package Number";
                    break;
                case "tripReference":
                    replace = "Trip Reference";
                    break;
                case "commentIcontains":
                    replace = "Comment";
                    break;
                case "relativeExpiryDateRange":
                    replace = "Relative Expiry Date";
                    break;
                case "expiryDateGte":
                    replace = "Expiry Date";
                    break;
                case "activatedDateGte":
                    replace = "Activate Date";
                    break;
                case "lastConnectionTime_Gte":
                    replace = "Last Connection Date";
                    break;
                case "lastConnectionTime_Lte":
                    replace = "Last Connection Date";
                    break;
                case "isBeaconTracker":
                    replace = "Beacon Tracker";
                    break;
                case "orderBy":
                    replace = "Sort By";
                    break;
                case "isArchived":
                    replace = "Archived";
                    break;
                case "tenant":
                    replace = "Tenant";
                    break;
                case "tenantIsnull":
                    replace = "Tenant";
                    break;
                case "brand":
                case "brand_Icontains":
                    replace = "Brand";
                    break;
                case "fileName":
                case "fileNameIexact":
                case "fileNameIcontains":
                    replace = "File Name";
                    break;
                case "createdAtGte":
                case "createdAtLte":
                    replace = "Uploaded Date";
                    break;
                case "createdByFirstNameIcontains":
                case "createdByLastNameIcontains":
                    replace = "Uploaded By";
                    break;
            }

            return replace;
        },
        valuePopulate(item, key) {
            let _value = null;
            switch (key) {
                case "serialNumber_Icontains":
                    _value = item;
                    break;
                // case "isBeaconTracker":
                //     _value = item;
                //     break;
                case "tripReference":
                    _value = item;
                    break;
                case "lastConnectionTime_Gte":
                    _value = item.split("T")[0];
                    break;
                case "lastConnectionTime_Lte":
                    _value = item.split("T")[0];
                    break;
                case "activatedDateGte":
                    _value = this.relativeActivateDateRangeChoices.find(el => el.value === this.relativeActiveDateRange)
                        ?.text;

                    break;
                case "orderBy":
                    _value = item;
                    break;
                case "isArchived":
                case "isBeaconTracker":
                    _value = this.archivedChoices.find(el => el.value === item)?.text;
                    break;
                case "tenant":
                    _value = item;
                    break;
                case "tenantIsnull":
                    _value = item;
                    break;
                case "commentIcontains":
                    _value = item;
                    break;
                // case "alertCode":
                //     _value = this.alertsType.find(el => el.value === item).name;
                //     break;
                case "relativeExpiryDateRange":
                    _value = this.relativeExpiryDateRangeChoices.find(el => el.value === this.relativeExpiryDateRange)
                        ?.text;
                    break;
                case "expiryDateGte":
                    _value = this.relativeExpiryDateRangeChoices.find(el => el.value === this.relativeExpiryDateRange)
                        ?.text;
                    break;
                case "relativeActiveDateRange":
                    _value = item;
                    break;
                case "brand":
                case "brand_Icontains":
                    _value = item;
                    break;
                case "search":
                    _value = item;
                    break;
                case "packageNumberIcontains":
                    _value = item;
                    break;
                case "createdAtGte":
                    _value = item.split("T")[0];
                    break;
                case "createdAtLte":
                    _value = item.split("T")[0];
                    break;
                case "fileName":
                case "fileNameIexact":
                case "fileNameIcontains":
                    _value = item;
                    break;
                case "createdByFirstNameIcontains":
                case "createdByLastNameIcontains":
                    _value = item;
                    break;
            }

            return _value;
        },
        openFilterMenu() {
            this.menu = true;
        },
        cancelDatePicker() {
            if (this.isHistoryTab) {
                this.openUploadedDateMenu = false;
                this.uploadedDateRange = [];
            } else {
                this.openLastConnectionTimeMenu = false;
                this.dateRange = [];
            }
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
.classContent {
    max-height: 400px;
    max-width: 1095px;

    overflow: auto;
}
</style>
