<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs v-model="tab" color="primary" show-arrows ref="tabs" background-color="background">
            <v-tab v-for="t in selectedFilters" :key="t.code" :href="`#tab-${t.code}`" class="text-capitalize">
                {{ t.name }}
            </v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab" :style="`height: ${heightScreen}px;`" class="overflow-y-auto background">
            <!-- USER GROUP TAB -->
            <v-tab-item value="tab-groups" :transition="false" class="background">
                <PreviewUserGroupsTab :user="user"></PreviewUserGroupsTab
            ></v-tab-item>
            <!-- USER LOGIN HISTORY TAB -->
            <v-tab-item value="tab-loginHistory" :transition="false" class="background">
                <PreviewUserLoginHistoryTab :user="user"></PreviewUserLoginHistoryTab>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import PreviewUserGroupsTab from "@/components/users/tabs/PreviewUserGroupsTab.vue";
import PreviewUserLoginHistoryTab from "@/components/users/tabs/PreviewUserLoginHistoryTab.vue";
export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        PreviewUserGroupsTab,
        PreviewUserLoginHistoryTab
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    mounted() {}
};
</script>
