<template>
    <v-card flat>
        <!-- Your component's template goes here -->
        <v-card-title> </v-card-title>
        <v-card-text>
            <v-row>
                <span class="mt-3 subtitle-1"
                    ><b>
                        {{ $t("headers.tracker_stock") }}
                    </b>
                </span>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <span>
                        {{ $t("general.stock_limit") }}
                    </span>
                </v-col>
                <v-col cols="3">
                    <div v-if="!editMode">
                        {{ stockLevel }}
                    </div>
                    <v-text-field
                        v-else
                        v-model.number="stockLevel"
                        type="number"
                        label="Stock Level"
                        placeholder="Placeholder"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="editMode = !editMode" rounded v-if="!editMode" color="primary">
                {{ $t("general.edit") }}
            </v-btn>
            <v-btn @click="cancel" rounded text v-if="editMode">
                {{ $t("general.cancel") }}
            </v-btn>
            <v-btn @click="save" rounded color="primary" :loading="isLoading" v-if="editMode">
                {{ $t("general.save") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "StockLimitDialog",
    props: {
        tenant: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            openStockLevelDialog: false,
            stockLevel: 0,
            isLoading: false,
            iconImg: require("@/assets/monitoring.png"),
            editMode: false
        };
    },
    methods: {
        // Your component's methods go here
        cancel() {
            this.editMode = false;
            this.stockLevel = this.tenant.trackerStockLimit ? this.tenant.trackerStockLimit : 0;
        },
        save() {
            // Code to save the stock level goes here
            this.isLoading = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTenantStockLevel($input: UpdateTenantStockLevelInput!) {
                            updateTenantStockLevel(input: $input) {
                                tenant {
                                    id
                                    trackerStockLimit
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantId: this.tenant.id,
                            trackerStockLimit: this.stockLevel
                        }
                    }
                })
                .then(response => {
                    this.isLoading = false;
                    const payload = {
                        color: "blue",
                        message: this.$t("general.saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                    this.$emit("updatedTenant", response);
                    this.editMode = false;
                });
        }
    },
    mounted() {
        // Code to run when the component is mounted goes here
        this.tenant.trackerStockLimit ? (this.stockLevel = this.tenant.trackerStockLimit) : 0;
    }
};
</script>

<style scoped>
/* Your component's styles go here */
.dark-grey-img {
    filter: grayscale(100%) brightness(50%);
}
</style>
