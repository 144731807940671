<template>
    <v-container fluid class="px-0 pt-0" style="background-color: transparent;">
        <v-form v-model="tripValidate" ref="form">
            <v-card flat class="rounded-lg">
                <v-card-text>
                    <v-row align="stretch">
                        <v-col cols="12" xs="12" sm="12" md="" lg="" xl="" class="contentDisplay">
                            <div class="px-4">
                                <v-row dense>
                                    <v-col cols="auto" class="textBody labelContent">
                                        <span>
                                            {{
                                                !templateMode
                                                    ? this.$t("trips.trip_ref") + "*"
                                                    : this.$t("trips.trip_ref")
                                                    ? this.$t("trips.trip_ref") + "*"
                                                    : this.$t("trips.trip_ref")
                                            }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    v-model="trip.referenceNumber"
                                    :placeholder="$t('trips.order_ship_no')"
                                    outlined
                                    :rules="[rules.required, rules.alertIsInvalid(alertHint)]"
                                    @change="validateRefNumber(trip.referenceNumber)"
                                    :disabled="templateMode"
                                    :filled="templateMode"
                                    dense
                                    class="inputContent"
                                >
                                    <template v-slot:append>
                                        <div class="d-flex align-center">
                                            <v-btn
                                                icon
                                                small
                                                :loading="tripLoading"
                                                v-if="trip.referenceNumber !== '' && trip.referenceNumber !== null"
                                                color="primary"
                                            >
                                                <v-icon v-if="!setActivate">done</v-icon>
                                                <v-icon v-else-if="alertHint" color="error">warning</v-icon>
                                            </v-btn>
                                        </div>
                                    </template>
                                </v-text-field>
                                <v-row dense>
                                    <v-col cols="auto" class="textBody labelContent">
                                        <span>
                                            {{ $t("trips.shipment_no") }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    v-model="trip.shipmentFileNumber"
                                    outlined
                                    dense
                                    :disabled="templateMode"
                                    :filled="templateMode"
                                    class="inputContent"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-card
                            v-if="!isSmallScreen"
                            class="d-flex justify-center align-center"
                            width="20px"
                            flat
                            style="
                                background-color: transparent;
                            "
                        >
                        </v-card>
                        <v-col cols="12" xs="12" sm="12" md="" lg="" xl="" class="contentDisplay">
                            <div class="px-4">
                                <v-row dense>
                                    <v-col cols="auto" class="textBody labelContent">
                                        <span>
                                            {{ $t("headers.carrier") }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-autocomplete
                                    v-model="trip.carrier"
                                    :loading="$apollo.queries.getTenantPartners.loading"
                                    :items="tenantPartners"
                                    :no-data-text="$t('loading.no_available')"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    clearable
                                    :disabled="!transportMode"
                                    dense
                                    class="inputContent"
                                    :menu-props="{
                                        maxHeight: '200px'
                                    }"
                                >
                                    <template v-slot:item="data">
                                        <v-row dense class="ma-0 body-2">
                                            <v-col cols="10" class="text-truncate pl-0 ml-0">
                                                {{ data.item.name }}
                                            </v-col>
                                            <v-col cols="2">
                                                <span
                                                    class="grey--text"
                                                    v-if="data.item.carrier && data.item.carrier.isSupportTracking"
                                                    ><i>Trackable</i></span
                                                >
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template #append-item>
                                        <v-divider class="mt-4 append-divider"></v-divider>
                                        <v-list-item dense class="append">
                                            <v-list-item-content>
                                                <v-btn
                                                    color="primary"
                                                    @click="createNewPartner('carrier')"
                                                    class="mt-4"
                                                    block
                                                >
                                                    {{ $t("general.add_carrier") }}
                                                </v-btn>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                                <v-row dense>
                                    <v-col cols="auto" lass="labelContent">
                                        <span class="textBody">
                                            {{ $t("home.consignees") }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <PartnerSelectConsignee
                                    ref="refPartnerSelectConsignee"
                                    v-model="trip.consignee"
                                    :outLined="true"
                                    :hideDetails="true"
                                    :activeSlot="true"
                                    :chips="true"
                                    :clearable="true"
                                    :multiple="true"
                                    class="inputContent"
                                >
                                    <template #custom-action>
                                        <v-btn
                                            color="primary"
                                            @click="createNewPartner('consignee')"
                                            class="mt-4"
                                            block
                                        >
                                            {{ $t("general.add_consignee") }}
                                        </v-btn>
                                    </template>
                                </PartnerSelectConsignee>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
        <AddPartnerDialog
            :openAddPartnerDialog="openAddPartnerDialog"
            v-on:closeAddPartnerDialog="openAddPartnerDialog = false"
            v-on:item-created="onPartnerCreated"
            :partnerByType="partnerByType"
        ></AddPartnerDialog>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
//import DateComponent from "@/components/base/DateComponent.vue";
//import TimeComponent from "@/components/base/TimeComponent.vue";
import AddPartnerDialog from "@/components/partners/create/AddPartnerDialog.vue";
import PartnerSelectConsignee from "@/components/partners/PartnerSelectConsignee.vue";

export default {
    components: {
        /* DateComponent */ /* TimeComponent */

        AddPartnerDialog,
        PartnerSelectConsignee
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean
        },
        isSeaTransport: {
            type: Boolean,
            default: false
        },
        getTrip: {
            type: Object
        },
        editMode: {
            type: Boolean,
            default: false
        },
        templateMode: {
            type: Boolean,
            default: false
        },
        transportMode: {
            type: String,
            default: null
        }
    },
    apollo: {
        getTenantPartners: {
            query: gql`
                query getTenantPartners($partnerType: String, $orderBy: String, $carrierType: String) {
                    getTenantPartners(partnerType: $partnerType, orderBy: $orderBy, carrierType: $carrierType) {
                        edges {
                            node {
                                id
                                name
                                carrierType
                                carrier {
                                    id
                                    isSupportTracking
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    partnerType: "carrier",
                    orderBy: "name"
                };
            },
            fetchPolicy: "no-cache",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.getTenantPartners,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return true;
            }
        }
    },
    data() {
        return {
            trigger: false,
            partnerByType: "",
            tripLoading: false,
            setActivate: true,
            alertHint: false,
            newPartner: false,
            openAddPartnerDialog: false,
            // tenantPartners:[],
            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" },
            //     { text: this.$t("transport_mode_choice.other"), value: "other" }
            // ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.required") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long"),
                alertIsInvalid: v => v === false || this.$t("rules.ref_no_exists")
            }
        };
    },
    computed: {
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 960;
        },
        tripValidate: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        trip: {
            get() {
                return this.getTrip;
            },
            set(value) {
                this.$emit("update:getTrip", value);
            }
        },
        tenantPartners() {
            return this.getTenantPartners?.edges?.map(e => e.node) || [];
        },
        me() {
            return helper.me();
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" },
                { text: this.$t("transport_mode_choice.other"), value: "other" }
            ];
            return _transportModeChoices;
        }
    },
    created() {},
    mounted() {
        this.$apollo.queries.getTenantPartners.skip = false;
        if (!this.editMode) {
            let organisationPreferences = this.me.tenant?.organisationPreferences;
            if (
                organisationPreferences != null &&
                organisationPreferences != "" &&
                organisationPreferences != undefined
            ) {
                let _preferences = JSON.parse(organisationPreferences);
                if (_preferences != null && _preferences?.transportModeChoice != null) {
                    this.trip.transportMode = Array.isArray(_preferences.transportModeChoice)
                        ? ""
                        : _preferences.transportModeChoice;
                }
            }
        }

        if (this.transportMode !== null || this.transportMode !== undefined || this.transportMode?.length === 0) {
            this.$apollo.queries.getTenantPartners
                .setVariables({
                    partnerType: "carrier",
                    carrierType: this.transportMode,
                    orderBy: "name"
                });
                this.$apollo.queries.getTenantPartners.refetch();
                // .then(() => {
                //     // this.tenantPartners=response.data.getTenantPartners.edges.map(e => e.node)
                // })
                // .catch(error => {
                //     console.error(error);
                //     this.error = error;
                // })
                // .finally(() => {});
        }
    },
    watch: {
        transportMode: function(newVal) {
            if (newVal === null) {
                this.transportMode = null;
                this.trip.carrier = null;
            }
            if (newVal === "sea") {
                this.$emit("update:isSeaTransport", true);
            } else {
                this.$emit("update:isSeaTransport", false);
            }
            if (newVal != null) {
                this.$apollo.queries.getTenantPartners
                    .setVariables({
                        partnerType: "carrier",
                        carrierType: newVal,
                        orderBy: "name"
                    });
                    this.$apollo.queries.getTenantPartners.refetch();
                    // .then(() => {
                    //     // this.tenantPartners=response.data.getTenantPartners.edges.map(e => e.node)
                    // })
                    // .catch(error => {
                    //     console.error(error);
                    //     this.error = error;
                    // })
                    // .finally(() => {});
            }
        },
        "trip.referenceNumber": function(newValue, oldValue) {
            if (newValue === null) return (this.trip.referenceNumber = "");
            if (newValue !== oldValue) {
                this.setActivate = true;
                this.alertHint = false;
            }
        }
    },
    methods: {
        validateRefNumber(reference) {
            if (reference !== null && reference !== undefined && reference !== "") {
                this.tripLoading = true;
                this.alertHint = false;

                //mutation to check if reference number exists
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CheckIfReferenceNumberIsAvailable(
                                $input: CheckIfReferenceNumberIsAvailableInput!
                            ) {
                                checkIfReferenceNumberIsAvailable(input: $input) {
                                    available
                                }
                            }
                        `,
                        variables: {
                            input: {
                                referenceNumber: reference
                            }
                        }
                    })
                    .then(response => {
                        if (response.data.checkIfReferenceNumberIsAvailable.available === false) {
                            this.setActivate = true;
                            this.alertHint = true;
                        } else {
                            this.setActivate = false;
                            this.alertHint = false;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.error = error;
                    })
                    .finally(() => {
                        this.tripLoading = false;
                    });
            }
        },
        filterTransportMode(mode) {
            this.trip.carrier = null;
            if (mode !== null) {
                if (mode.length > 0) {
                    this.$apollo.queries.getTenantPartners
                        .setVariables({
                            partnerType: "carrier",
                            carrierType: mode,
                            orderBy: "name"
                        });
                        this.$apollo.queries.getTenantPartners.refetch();
                        // .then(() => {
                        //     // this.tenantPartners=response.data.getTenantPartners.edges.map(e => e.node)
                        // })
                        // .catch(error => {
                        //     console.error(error);
                        //     this.error = error;
                        // })
                        // .finally(() => {});
                } else {
                    // this.tenantPartners=[]
                    this.trip.carrier = null;
                }
            } else {
                // this.tenantPartners=[]
                this.trip.carrier = null;
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        createNewPartner(type) {
            /* this.openAddPartnerDialog = true;
            this.partnerByType = type; */
            this.$emit("createPartner", {
                path: "/previewcreatepartner",
                query: {
                    type: `create-${type}`,
                    field: this.transportMode
                }
            });
        },
        refetchData() {
            return this.$apollo.queries.getTenantPartners.refresh();
        },
        onPartnerCreated(item) {
            if (item?.tenantPartner?.partnerType === "carrier") {
                this.refetchData();
                this.trip.carrier = item?.tenantPartner?.id;
            }

            if (item?.tenantPartner?.partnerType === "consignee") {
                this.$refs.refPartnerSelectConsignee.$apollo.queries.tenantPartners.refresh();
                this.trip.consignee.push(item?.tenantPartner?.id);
            }
        }
    }
};
</script>
<style scoped>
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}
/* .inputContent {
    height: 52px;
} */
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay2 {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay3 {
    padding-left: 70px;
    padding-right: 70px;
}
</style>
