<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="8" sm="9" lg="10">
                        <v-row>
                            <v-col
                                v-for="f in dynamicFilters"
                                :key="f"
                                :class="getFilterChoiceClassByCode(f)"
                            >
                                <v-text-field
                                    v-if="f === 'partnerName'"
                                    v-model="filter.nameIcontains"
                                    :label="$t('headers.name')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>

                                <v-text-field
                                    v-if="f === 'referenceNumber'"
                                    v-model="filter.referenceNumberIcontains"
                                    :label="$t('headers.reference_number')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>

                                <v-autocomplete
                                    v-if="f === 'partnerType' && !isFromSubMenu"
                                    v-model="filter.partnerType"
                                    :items="partnerTypeChoices"
                                    :label="$t('headers.partner_type')"
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                    attach
                                    :menu-props="{
                                        zIndex: 3
                                    }"
                                ></v-autocomplete>
                            </v-col>

                            <!-- sort by -->
                            <v-col class="col-auto col-md-3 col-lg-2 col-xl-2">
                                <v-row class="d-flex align-center ma-0 pa-0">
                                    <v-select
                                        v-model="sortBy"
                                        :items="sortByChoices"
                                        :label="$t('headers.name')"
                                        :item-text="item => item.name"
                                        :item-value="item => item.code"
                                        clearable
                                        hide-details
                                        dense
                                        color="primary"
                                        :menu-props="{
                                            zIndex: 3
                                        }"
                                    >
                                        <template v-slot:append-outer>
                                            <v-btn
                                                class="mt-n1"
                                                small
                                                icon
                                                :disabled="!sortBy"
                                                @click="sortAsc = !sortAsc"
                                            >
                                                <v-icon color="grey darken-2">{{
                                                    sortAsc ? "north" : "south"
                                                }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <AddPartnerDialog
                        :openAddPartnerDialog="openAddPartnerDialog"
                        :partnerByType.sync="partnerType"
                        v-on:refreshPartnersTable="$emit('refreshDataTable')"
                        v-on:closeAddPartnerDialog="
                            openAddPartnerDialog = false
                        "
                        v-on:item-created="onPartnerCreated"
                    ></AddPartnerDialog>
                    <!-- filters menu -->
                    <v-col cols="4" sm="3" lg="2" class="py-2 pr-4 text-right">
                        <v-row class="d-flex align-center justify-end">
                            <!-- filters menu -->
                            <FilterMenu
                                :title="$t('dashboard.customize')"
                                :description="$t('dashboard.display_filters')"
                                :choices="filterChoices"
                                :open-on-hover="false"
                                height="500px"
                                width="250px"
                                v-on:changed="onFilterMenuChanged"
                            >
                            </FilterMenu>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import FilterMenu from "@/components/base/FilterMenu.vue";
import AddPartnerDialog from "@/components/partners/create/AddPartnerDialog.vue";

export default {
    components: { FilterMenu, AddPartnerDialog },
    props: {
        title: {
            type: String,
            default: "Partner"
        }
    },
    apollo: {},

    data() {
        return {
            openAddPartnerDialog: false,

            isLoading: false,
            fab: false,
            menu: false,
            dateRange: [],
            savedDateRange: [],
            sortAsc: true,
            shouldStickyFilter: false,
            partnerType: null,

            // --------------------> FILTERS <----------------------- \\
            // defaultFilterChoices: [
            //     {
            //         code: "partnerName",
            //         name: "Partner Name",
            //         tag: 'headers.partner_name',
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: true,
            //         order: 0
            //     },
            //     {
            //         code: "referenceNumber",
            //         name: "Reference Number",
            //         tag: 'headers.reference_number',
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: true,
            //         order: 1
            //     },
            //     {
            //         code: "partnerType",
            //         name: "Partner Type",
            //         tag: 'headers.partner_type',
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: true,
            //         order: 2
            //     }
            // ],

            filterChoices: null,

            // sortByChoices: [
            //     {
            //         code: "partnerType",
            //         name: this.$t("headers.partner_type")
            //     },
            //     {
            //         code: "name",
            //         name: this.$t("headers.partner_name")
            //     },
            //     {
            //         code: "openTrips",
            //         name: this.$t("headers.open_trips")
            //     },
            //     {
            //         code: "totalTrips",
            //         name: this.$t("headers.total_trips")
            //     }
            // ],

            sortBy: null,

            // partnerTypeChoices: [
            //     { text: this.$t("headers.consignor"), value: "consignor" },
            //     { text: this.$t("headers.consignee"), value: "consignee" },
            //     { text: this.$t("headers.forwarder"), value: "forwarder" },
            //     { text: this.$t("headers.carrier"), value: "carrier" },
            //     { text: this.$t("home.other"), value: "other" }
            // ],
            relativeDateRange: null,
            relativeDateRangeChoices: [
                "Last 30 days",
                "Last 90 days",
                "Last 180 days",
                "Last 360 days"
            ],

            // graphql query filter
            filter: {
                first: 15,
                after: null,
                nameIcontains: null,
                referenceNumberIcontains: null,
                partnerType: null,
                orderBy: null
            },
            defaultFilter: {
                first: 15,
                after: null,
                nameIcontains: null,
                referenceNumberIcontains: null,
                partnerType: null,
                orderBy: null
            }
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },
        dynamicFilters() {
            return this.filterChoices.filter(f => f.enabled).map(f => f.code);
        },
        isFromSubMenu() {
            return this.$route.name !== "partners";
        },
        partnerByType() {
            return this.$route.name;
        },
        /*  filterBytype(){
            let _filterSwitch = `${this.$route.name}Filter`;
            return _filterSwitch ;
        } */
        defaultFilterChoices: function() {
            let _defaultFilterChoices = [
                {
                    code: "partnerName",
                    name: "Partner Name",
                    tag: "headers.partner_name",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: true,
                    order: 0
                },
                {
                    code: "referenceNumber",
                    name: "Reference Number",
                    tag: "headers.reference_number",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: true,
                    order: 1
                },
                {
                    code: "partnerType",
                    name: "Partner Type",
                    tag: "headers.partner_type",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: true,
                    order: 2
                }
            ];
            return _defaultFilterChoices;
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "partnerType",
                    name: this.$t("headers.partner_type")
                },
                {
                    code: "name",
                    name: this.$t("headers.partner_name")
                },
                {
                    code: "openTrips",
                    name: this.$t("headers.open_trips")
                },
                {
                    code: "totalTrips",
                    name: this.$t("headers.total_trips")
                }
            ];
            return _sortByChoices;
        },
        partnerTypeChoices: function() {
            let _partnerTypeChoices = [
                { text: this.$t("headers.consignor"), value: "consignor" },
                { text: this.$t("headers.consignee"), value: "consignee" },
                { text: this.$t("headers.forwarder"), value: "forwarder" },
                { text: this.$t("headers.carrier"), value: "carrier" },
                { text: this.$t("home.other"), value: "other" }
            ];
            return _partnerTypeChoices;
        }
    },
    watch: {
        filter: {
            handler(value) {
                if (value) {
                    // value.partnerType = this.partnerType.toLowerCase();
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                    helper.setFilterValues(
                        `${this.partnerByType}TableFilters`,
                        value
                    );
                }
            },
            deep: true
        },
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        isFromSubMenu: {
            immediate: true,
            handler(val) {
                if (val) {
                    let route = this.$route.name;

                    this.filter = this.defaultFilter;

                    if (route !== null) {
                        let _filter = helper.getFilterValues(
                            `${route}TableFilters`
                        );

                        if (_filter !== undefined) {
                            if (_filter !== null) this.filter = _filter;
                        }

                        this.partnerType = this.formatType(route);
                        this.filter.partnerType = this.formatType(route);
                    }

                    this.filterChoices = helper.getFilterChoices(
                        this.defaultFilterChoices,
                        "partnerTableFilters"
                    );
                    this.filterChoices = this.filterChoices?.filter(
                        f => f.code !== "partnerType"
                    );
                } else {
                    this.filterChoices = helper.getFilterChoices(
                        this.defaultFilterChoices,
                        "partnerTableFilters"
                    );

                    this.filter.partnerType = null;
                }
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "partnerTableFilters"
        );
        if (this.isFromSubMenu) {
            this.filterChoices = this.filterChoices?.filter(
                f => f.code !== "partnerType"
            );
        }
    },
    mounted() {
        this.onResize();
        let _filter = null;

        if (this.partnerByType !== null) {
            _filter = helper.getFilterValues(
                `${this.partnerByType}TableFilters`
            );
        }

        if (_filter) {
            this.filter = _filter;
            if (_filter.orderBy?.includes("-")) {
                this.sortBy = _filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else {
                this.sortBy = _filter.orderBy;
                this.sortAsc = true;
            }
        }

        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    partnerTableFilters: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        // dynamic filter
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        formatType(type) {
            type = type.toLowerCase();
            if (type === "consignors") {
                return "consignor";
            } else if (type === "consignees") {
                return "consignee";
            } else if (type === "forwarders") {
                return "forwarder";
            } else if (type === "others") {
                return "other";
            }
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        },
        onClearDates() {
            this.dateRange = [];
            this.savedDateRange = [];
            this.relativeDateRange = null;
        },
        onFilterMenuChanged(newChoises) {
            this.filterChoices = newChoises;
        },
        onPartnerCreated(val) {
            this.$emit("partner-created", val);
        },
        titleChoices(title) {
            let choices = [
                { text: this.$t("headers.consignor"), value: "consignors" },
                { text: this.$t("headers.consignee"), value: "consignees" },
                { text: this.$t("headers.forwarder"), value: "forwarders" },
                { text: this.$t("headers.carrier"), value: "carriers" },
                { text: this.$t("home.partner"), value: "others" },
                { text: this.$t("home.partner"), value: null }
            ];

            return choices.find(item => {
                return item.value === title.toLowerCase();
            })?.text;
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
