<template>
    <div :key="freight.id">
        <!-- tabs -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="transparent"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                <span
                    v-if="
                        freight.trip.transportMode === 'SEA' &&
                            t.code === 'seaTransport'
                    "
                    >{{ t.name }}</span
                >
                <span v-if="t.code !== 'seaTransport'">{{ t.name }}</span>
            </v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <!-- tab content -->
        <v-tabs-items
            v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <v-tab-item value="tab-summary" :transition="false">
                <TripFreights
                    :trip="freight.trip"
                    :freightProp="freight"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripFreights>
            </v-tab-item>
            <!-- <v-tab-item value="tab-trackerscharts" :transition="false">
                <TripTrackers 
                    :trip="trip" 
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripTrackers>
            </v-tab-item> -->
            <v-tab-item value="tab-carrierEvents" :transition="false">
                <TripCarrierEvents
                    :trip="freight.trip"
                    :freightProp="freight"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripCarrierEvents>
            </v-tab-item>
            <v-tab-item value="tab-partners" :transition="false">
                <TripPartners
                    :trip="freight.trip"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripPartners>
            </v-tab-item>
            <v-tab-item value="tab-documents" :transition="false">
                <TripDocuments
                    :trip="freight.trip"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripDocuments>
            </v-tab-item>
            <v-tab-item value="tab-alerts" :transition="false">
                <TripAlerts
                    :trip="freight.trip"
                    :freight="freight"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripAlerts>
            </v-tab-item>
            <v-tab-item value="tab-comments" :transition="false">
                <TripComments
                    :trip="freight.trip"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripComments>
            </v-tab-item>
            <v-tab-item value="tab-map" :transition="false">
                <TripMap
                    :trip="freight.trip"
                    viewType="tab"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripMap>
            </v-tab-item>
            <v-tab-item value="tab-timeline" :transition="false">
                <TripTimeline
                    :trip="freight.trip"
                    :freight="freight"
                    viewType="tab"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripTimeline>
            </v-tab-item>
            <v-tab-item
                v-if="freight.trip.transportMode === 'SEA'"
                value="tab-seaTransport"
                :transition="false"
            >
                <TripSeaTransport
                    :trip="freight.trip"
                    viewType="tab"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                ></TripSeaTransport>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import TripFreights from "@/components/trips/details/TripFreights.vue";
import TripCarrierEvents from "@/components/trips/details/TripCarrierEvents.vue";
import TripAlerts from "@/components/trips/details/TripAlerts.vue";
import TripComments from "@/components/trips/details/TripComments.vue";
import TripDocuments from "@/components/trips/details/TripDocuments.vue";
import TripPartners from "@/components/trips/details/TripPartners.vue";
import TripMap from "@/components/trips/details/TripMap.vue";
// import TripTrackers from "@/components/trips/details/TripTrackers.vue";
import TripSeaTransport from "@/components/trips/details/TripSeaTransport.vue";
import TripTimeline from "@/components/trips/details/TripTimeline.vue";

export default {
    props: {
        freight: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    components: {
        TripFreights,
        TripCarrierEvents,
        TripAlerts,
        TripComments,
        TripDocuments,
        TripPartners,
        TripMap,
        // TripTrackers,
        TripSeaTransport,
        TripTimeline
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        routeTab() {
            return this.$route.query.tab;
        },
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        },
        tab(val) {
            if (val) {
                if (this.routeTab === val.replace("tab-", "")) return;
                this.$router.push({
                    name: this.$route.name,
                    query: {
                        id: this.freight.id,
                        tab: val.replace("tab-", "")
                    }
                });
            }
        },
        routeTab: {
            immediate: true,
            handler() {
                if (this.routeTab) {
                    //check if tab exists
                    if (
                        this.selectedFilters.find(
                            f => f.code === this.routeTab
                        ) === undefined
                    ) {
                        try {
                            let firstTab = this.selectedFilters[0].code;
                            this.$router.push({
                                name: this.$route.name,
                                query: {
                                    id: this.freight.id,
                                    tab: firstTab
                                }
                            });
                        } catch {
                            //do nothing
                        }
                    } else this.tab = "tab-" + this.routeTab;
                }
            }
        }
    },
    mounted() {}
};
</script>
