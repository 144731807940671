<template>
    <v-container fluid class="pa-0">
        <v-autocomplete
            ref="autocomplete"
            v-model="SelectedAlerts"
            :loading="$apollo.queries.alertRules.loading"
            :items="alertRules.edges"
            :no-data-text="$t('loading.no_available')"
            :hide-details="hideDetails"
            :label="label"
            outlined
            deletable-chips
            small-chips
            item-text="node.name"
            item-value="node.id"
            multiple
            dense
            :menu-props="{
                maxHeight: '200px'
            }"
        >
            <template #append-item>
                <v-divider v-if="activeSlot" class="mt-4 append-divider"></v-divider>
                <v-list-item v-if="activeSlot" dense class="append py-0">
                    <v-list-item-content>
                        <!-- Use the slot named 'custom-action' here -->
                        <slot name="custom-action"></slot>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
    </v-container>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "AlertRulesAutoComplete",
    props: {
        value: {
            type: Array
        },
        refresh: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: ""
        },
        activeSlot: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        alertRules: {
            query: gql`
                query tenantAlertRules(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $search: String
                    $orderBy: String
                ) {
                    tenantAlertRules(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        search: $search
                        orderBy: $orderBy
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                description
                                isEnabled
                                name
                            }
                        }
                    }
                }
            `,
            variables() {},
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantAlertRules,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 800,
            result({ loading }) {
                if (!loading) {
                    this.$emit("update:refresh", loading);
                }
            }
        }
    },
    data() {
        return {
            shouldLoadMore: false,
            isInitialLoad: true,
            alertRules: { edges: [] }
        };
    },
    created() {},
    mounted() {
        this.$watch(
            () => {
                return this.$refs.autocomplete.isFocused;
            },
            val => {
                this.alertRules.edges.length > 0 ? this.$emit("focusAlertRuleSelected", val) : null;
            }
        );
    },
    computed: {
        SelectedAlerts: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    watch: {
        refresh(val) {
            if (val) {
                this.$apollo.queries.alertRules.refetch();
            }
        },
        alertRules: {
            handler(val) {
                if (val.edges.length > 0) {
                    this.$emit("focusAlertRuleSelected", this.$refs.autocomplete.isFocused);
                }
            },
            deep: true
        }
    }
};
</script>
<style scoped>
.append {
    position: sticky;
    bottom: 0.1px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
}
.append-divider {
    position: sticky;
}
</style>
