import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.openAddPartnerDialog),callback:function ($$v) {_vm.openAddPartnerDialog=$$v},expression:"openAddPartnerDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t("partners.add_partner"))+" ")],1),_c(VCardText,{staticClass:"pt-6"},[_c(VForm,{ref:"partnerForm"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('partners.partner_name') + '*',"rules":_vm.nameRules,"outlined":"","dense":""},model:{value:(_vm.partner_name),callback:function ($$v) {_vm.partner_name=$$v},expression:"partner_name"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('general.description'),"rules":[_vm.rules.maxLength(200)],"outlined":"","dense":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),(_vm.partnerByType === 'carrier')?_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.transportModeChoices,"label":_vm.$t('headers.carrier_type'),"outlined":"","dense":""},on:{"change":function($event){return _vm.filterByType(_vm.transportMode)}},model:{value:(_vm.transportMode),callback:function ($$v) {_vm.transportMode=$$v},expression:"transportMode"}})],1):_vm._e(),(
                            (_vm.transportMode === 'sea' &&
                                _vm.partnerByType === 'carrier') ||
                                (_vm.transportMode === 'air' &&
                                    _vm.partnerByType === 'carrier')
                        )?_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.allCarriers ? _vm.allCarriers.edges : [],"no-data-text":_vm.$t('loading.no_available'),"label":_vm.$t('partners.standard_carriers'),"item-text":"node.name","item-value":"node.id","outlined":"","dense":""},model:{value:(_vm.carrier),callback:function ($$v) {_vm.carrier=$$v},expression:"carrier"}})],1):_vm._e(),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('headers.reference_number'),"outlined":"","dense":""},model:{value:(_vm.reference_number),callback:function ($$v) {_vm.reference_number=$$v},expression:"reference_number"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VCheckbox,{attrs:{"label":_vm.$t('headers.enable_notifications'),"hide-details":""},model:{value:(_vm.notification_checkbox),callback:function ($$v) {_vm.notification_checkbox=$$v},expression:"notification_checkbox"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end pb-3 pr-5"},[_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"color":"primary","rounded":"","loading":_vm.isLoadingPartnerAdd},on:{"click":_vm.createTenantPartner}},[_vm._v(_vm._s(_vm.$t("general.add")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }