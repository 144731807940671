<template>
    <v-sheet
        color="transparent"
        class="mx-auto d-flex justify-center align-center"
        fill-height
        :width="width"
        :min-height="height"
    >
        <v-row dense>
            <v-col cols="12" class="shrink d-flex justify-center">
                <div class="grey--text text--darken-2">
                    <slot name="content">{{ $t("loading.no_data") }}</slot>
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: "no-data",

    props: {
        color: {
            type: String,
            default: "primary"
        },
        height: {
            type: Number,
            default: 100
        },
        width: {
            type: Number,
            default: 200
        }
    }
};
</script>
