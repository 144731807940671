<template>
    <div>
        <v-text-field
            v-model="location.maxTemperature"
            :label="$t('general.maximum_temperature')"
            :rules="[maxRule]"
            type="number"
            dense
            outlined
            step="0.25"
            required
        ></v-text-field>
        <v-text-field
            v-model="location.minTemperature"
            :label="$t('general.minimum_temperature')"
            type="number"
            :rules="[minRule]"
            dense
            outlined
            step="0.25"
            required
        ></v-text-field>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object
        }
    },
    data() {
        return {
            minRule: v =>
                v < parseFloat(this.location.maxTemperature) ||
                v == null ||
                v == "" ||
                isNaN(parseFloat(this.location.maxTemperature)) ||
                this.$t("weather.min_temp_rule"),
            maxRule: v =>
                v > parseFloat(this.location.minTemperature) ||
                v == null ||
                v == "" ||
                isNaN(parseFloat(this.location.minTemperature)) ||
                this.$t("weather.max_temp_rule")
        };
    },
    computed: {
        location: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
    }
};
</script>

<style scoped></style>
