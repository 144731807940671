<template>
    <div>
        <v-tabs
            v-model="tab"
            background-color="transparent"
            class="tabs-container"
            next-icon="chevron_right"
            prev-icon="chevron_left"
            show-arrows
        >
            <draggable v-model="localTabs" class="draggable-container">
                <v-tab
                    v-for="item in localTabs"
                    :value="item.id"
                    :key="item.id"
                    class="tab-item"
                    @click.stop="$emit('onClick', true)"
                >
                    <div class="text-capitalize">
                        {{ item.text }}
                    </div>
                </v-tab>
            </draggable>
        </v-tabs>
        <v-divider></v-divider>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "CustomTabs",
    components: {
        draggable
    },
    props: {
        selectedTab: {
            type: Object,
            default: () => {}
        },
        tabs: {
            type: Array,
            default: () => []
        },
        value: {
            type: String,
            default: null
        },
        droggable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        localTabs: {
            get() {
                return this.tabs;
            },
            set(val) {
                // Update the local copy of the tabs
                let _localTabs = val.map(({ text, value }, index) => {
                    return {
                        id: index,
                        text,
                        value
                    };
                });
                if (this.droggable) {
                    this.$emit("updateTabs", _localTabs);
                }
            }
        }
    },
    mounted() {
        /* if(this.tabs){
            this.tab = this.tabs.findIndex(item => item.value === this.value) || 0;
            console.log(this.value); 
            console.log(this.tabs);  
        } */
    },
    watch: {
        tab(val) {
            let type = this.localTabs[val];
            this.$emit("input", type.value);
            this.$emit("update:selectedTab", type);
        },
        value(val) {
            this.tab = this.localTabs.findIndex(tab => tab.value === val);
        }
    },
    methods: {}
};
</script>

<style scoped>
.tabs-container {
    display: flex;
    flex-direction: row; /* Aligns tabs vertically */
}

.draggable-container {
    display: flex;
    flex-direction: row; /* Aligns tabs horizontally */
}

.tab-item {
    margin-bottom: 5px; /* Adds space between tabs */
}
</style>
