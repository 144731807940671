<template>
    <div
        :style="`height: ${heightScreen}px;`"
        class="overflow-y-auto"
    >
        <v-sheet class="pb-2" color="transparent">
            <v-spacer />
            <v-btn small outlined color="primary" @click="expand">
                {{
                    isAllExpanded
                        ? "- " + $t("general.collapse")
                        : "+ " + $t("general.expand")
                }}
            </v-btn>
        </v-sheet>
        <v-expansion-panels v-model="panel" multiple focusable 
            class="mb-5"
        >
            <v-expansion-panel
                v-for="(item, index) in selectedFilters"
                :key="index"
            >
                <v-expansion-panel-header>
                    <h5>{{ item.name }}</h5>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                    <v-lazy>
                        <PartnerUsersTab
                            v-if="item.code === 'users'"
                            :partner="partner"
                        ></PartnerUsersTab>
                        <PartnerLocationsTab
                            v-if="item.code === 'locations'"
                            :partner="partner"
                        ></PartnerLocationsTab>
                        <PartnerPermissionsTab
                            v-if="item.code === 'permissions'"
                            :partner="partner"
                        ></PartnerPermissionsTab>
                        <PartnerTripsTab
                            v-if="item.code === 'trips'"
                            :partner="partner"
                        ></PartnerTripsTab>
                    </v-lazy>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import PartnerUsersTab from "@/components/partners/tabs/PartnerUsersTab.vue";
import PartnerLocationsTab from "@/components/partners/tabs/PartnerLocationsTab.vue";
import PartnerPermissionsTab from "@/components/partners/tabs/PartnerPermissionsTab.vue";
import PartnerTripsTab from "@/components/partners/tabs/PartnerTripsTab.vue";

export default {
    props: {
        partner: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        PartnerUsersTab,
        PartnerLocationsTab,
        PartnerPermissionsTab,
        PartnerTripsTab
    },
    data() {
        return {
            panel: [0]
        };
    },
    computed: {
        isAllExpanded() {
            return this.panel.length === this.selectedFilters.length;
        },
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 174  ;
            return  _break;
        }
    },
    watch: {},
    mounted() {},
    methods: {
        expand() {
            if (this.isAllExpanded) {
                this.panel = [];
            } else {
                this.panel = [
                    ...this.selectedFilters.map((item, index) => index)
                ];
            }
        }
    }
};
</script>

<style scoped lang="scss">
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>
