<template>
    <v-container fluid>
        <v-row class="d-flex justify-center">
            <v-col cols="8">
                <v-btn icon @click="discartChanges">
                    <v-icon>
                        chevron_left
                    </v-icon>
                </v-btn>
                <v-card class="d-flex align-end " height="110" color="transparent" flat>
                    <div class="textTitle">
                        {{ $t("partners.add_location") }}
                    </div>
                </v-card>
            </v-col>
            <v-col cols="8">
                <v-card class="background" flat>
                    <!--- BODY --->
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="px-0">
                                <!-- show loading -->
                                <v-progress-linear
                                    v-if="$apollo.queries.trackerLocations.loading"
                                    dense
                                    indeterminate
                                    color="primary"
                                    class="mb-0"
                                    :height="3"
                                ></v-progress-linear>
                                <!--- MAP --->
                                <l-map
                                    ref="map"
                                    id="map"
                                    :center="map_center"
                                    :zoom="zoom"
                                    :min-zoom="min_zoom"
                                    :max-zoom="max_zoom"
                                    :options="{
                                        attributionControl: false,
                                        drawControl: false,
                                        zoomControl: false,
                                        worldCopyJump: true
                                    }"
                                    style="z-index: 0;"
                                >
                                    <!--- TILE LAYER --->
                                    <l-tile-layer class="mapEdit" :url="here_map_url"></l-tile-layer>

                                    <!--- ZOOM CONTROL --->
                                    <l-control-zoom position="bottomright"></l-control-zoom>
                                    <!-- Recenter map / range radius control -->
                                    <l-control position="bottomleft">
                                        <v-row dense>
                                            <v-col cols="2">
                                                <v-btn @click="recenterMap" fab tile x-small>
                                                    <v-icon color="primary">my_location</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="10" class="pl-1 d-flex" v-if="triggerRadius">
                                                <v-card width="250" tile class="pl-1" elevation="3" outlined>
                                                    <v-slider
                                                        v-model="radius"
                                                        max="200000"
                                                        min="1000"
                                                        thumb-color="primary"
                                                        track-color="primary"
                                                        hide-details
                                                        class="mt-0"
                                                        @change="editCircle(radius)"
                                                    >
                                                        <template v-slot:label>
                                                            <span class="ml-1 pr-0"> {{ radius }} m </span>
                                                        </template>
                                                    </v-slider>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </l-control>
                                    <!-- Search -->
                                    <l-control position="topleft">
                                        <div class="d-flex">
                                            <v-btn
                                                fab
                                                tile
                                                x-small
                                                v-if="!showMapSearchBar"
                                                @click.stop.prevent="showMapSearchBar = !showMapSearchBar"
                                                ><v-icon color="primary">search</v-icon></v-btn
                                            >

                                            <MapSearchBar
                                                v-model="googleResult"
                                                @onPlaceChanged="onPlaceChanged"
                                            ></MapSearchBar>
                                            <div class="mt-2 ml-3">
                                                <v-btn
                                                    dense
                                                    v-if="enableHeatmap"
                                                    :loading="$apollo.queries.trackerLocations.loading"
                                                    @click="enableHeatmap = false"
                                                    >{{ $t("partners.hide_heatmap") }}</v-btn
                                                >

                                                <v-btn
                                                    dense
                                                    :loading="$apollo.queries.trackerLocations.loading"
                                                    v-if="!enableHeatmap"
                                                    @click="enableHeatmap = true"
                                                    >{{ $t("partners.show_heatmap") }}</v-btn
                                                >
                                            </div>
                                        </div>
                                    </l-control>
                                </l-map>
                            </v-col>
                            <!-- FILL FORM -->
                            <!-- <v-col cols="12" class="px-0" v-show="showForm"> -->
                            <v-col cols="12" class="px-0">
                                <v-card flat class="rounded-lg px-0">
                                    <v-card-text>
                                        <v-form ref="form" v-model="validate">
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    xs="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="6"
                                                    xl="6"
                                                    v-if="tab === 'partnerLocations'"
                                                >
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("headers.collaborator_type1") + "*" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="partnerType"
                                                        :items="partnersTypes"
                                                        item-text="text"
                                                        item-value="value"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        :placeholder="$t('general.collaborator_types')"
                                                        clearable
                                                        :rules="[rules.required]"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    xs="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="6"
                                                    xl="6"
                                                    v-if="tab === 'partnerLocations'"
                                                >
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ partnerTypeText }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="tenantPartnerId"
                                                        :items="partners.edges"
                                                        :no-data-text="$t('loading.no_available')"
                                                        item-value="node.id"
                                                        item-text="node.name"
                                                        :placeholder="$t('general.collaborator_example')"
                                                        outlined
                                                        dense
                                                        cleareable
                                                        hide-details
                                                        :loading="$apollo.queries.tenantPartners.loading"
                                                        :disabled="!(partnerType !== null && partnerType !== undefined)"
                                                        :rules="[rules.required]"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("headers.name") + "*" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="locationName"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        :rules="[rules.required]"
                                                        :placeholder="$t('general.example')"
                                                        :disabled="disabledFillIfPartner()"
                                                    ></v-text-field>
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("partners.city") + "*" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="locationCity"
                                                        :rules="[rules.required]"
                                                        dense
                                                        outlined
                                                        placeholder="Amsterdam"
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("partners.country") + "*" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="locationCountry"
                                                        :rules="[rules.required]"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        placeholder="Netherlands"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("partners.address") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="locationFullAddress"
                                                        :rules="[rules.required]"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        placeholder="Street 1, 1234"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    xs="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="6"
                                                    xl="6"
                                                    v-if="tab === 'myLocations'"
                                                >
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("headers.tags") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-combobox
                                                        v-model="tags"
                                                        :items="tagsChoices"
                                                        :return-object="false"
                                                        item-text="text"
                                                        item-value="value"
                                                        small-chips
                                                        multiple
                                                        deletable-chips
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :menu-props="{
                                                            maxHeight: '200px'
                                                        }"
                                                    ></v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    cols="6"
                                                    xs="6"
                                                    sm="6"
                                                    md="6"
                                                    lg="3"
                                                    xl="3"
                                                    class="d-flex justify-start align-center"
                                                >
                                                    <v-switch v-model="isTrackerStockLocation" inset hide-details>
                                                        <template v-slot:label>
                                                            <div class="textBody">
                                                                {{ $t("partners.partner_location") }}
                                                            </div>
                                                        </template>
                                                    </v-switch>
                                                </v-col>
                                                <!--  <v-col cols="3" class="d-flex justify-start align-center">
                                                    <v-switch v-model="publicLocation" inset hide-details>
                                                        <template v-slot:label>
                                                            <v-row dense class="d-flex justify-start align-center">
                                                                <v-col cols="auto">
                                                                    <span class="textBody">
                                                                        {{ $t("partners.public_location") }}
                                                                    </span>
                                                                </v-col>
                                                                <v-col cols="auto">
                                                                    <v-tooltip bottom class="">
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-btn
                                                                                color="primary"
                                                                                dark
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                icon
                                                                            >
                                                                                <v-icon>info</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>
                                                                            {{ $t("partners.public_examples") }}
                                                                        </span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-switch>
                                                </v-col> -->
                                                <v-col
                                                    cols="6"
                                                    xs="6"
                                                    sm="6"
                                                    md="6"
                                                    lg="3"
                                                    xl="3"
                                                    class="d-flex justify-start align-center"
                                                >
                                                    <v-switch v-model="showWeatherForecast" inset hide-details>
                                                        <template v-slot:label>
                                                            <v-row dense class="d-flex justify-start align-center">
                                                                <v-col cols="auto">
                                                                    <span class="textBody">
                                                                        {{ $t("weather.weather_forecast") }}
                                                                    </span>
                                                                </v-col>
                                                                <v-col cols="auto">
                                                                    <v-tooltip bottom>
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-btn
                                                                                color="primary"
                                                                                dark
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                icon
                                                                            >
                                                                                <v-icon>
                                                                                    info
                                                                                </v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>
                                                                            {{
                                                                                $t("weather.enable_weather_forcast")
                                                                            }}:<br />
                                                                            {{
                                                                                $t(
                                                                                    "weather.enable_weather_forcast_description"
                                                                                )
                                                                            }}
                                                                        </span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-switch>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    xs="6"
                                                    sm="6"
                                                    md="6"
                                                    lg="3"
                                                    xl="3"
                                                    v-if="showWeatherForecast"
                                                >
                                                    <v-row>
                                                        <v-col cols="auto">
                                                            <span class="textBody">
                                                                {{ $t("general.maximum_temperature") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="maxTemperature"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :style="{
                                                            display: 'block'
                                                        }"
                                                        type="number"
                                                        step="0.25"
                                                        max="50"
                                                        min="-25"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    xs="6"
                                                    sm="6"
                                                    md="6"
                                                    lg="3"
                                                    xl="3"
                                                    v-if="showWeatherForecast"
                                                >
                                                    <v-row>
                                                        <v-col cols="auto">
                                                            <span class="textBody">
                                                                {{ $t('general.minimum_temperature') }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="minTemperature"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :style="{
                                                            display: 'block'
                                                        }"
                                                        type="number"
                                                        step="0.25"
                                                        max="50"
                                                        min="-25"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <!-- <v-col cols="12" v-show="!showForm" class="px-0"> -->
                            <v-col cols="12" class="px-0">
                                <v-alert type="info" text>
                                    {{ $t("partners.select_location") }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <!-- FOOTER ACTION BUTTON -->
                    <v-card-actions class="card-container rounded-lg px-8">
                        <v-btn text outlined @click="discartChanges">
                            {{ $t("general.discard") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!validate" color="primary" @click="save" :loading="isLoadingLocationAdd">{{
                            $t("general.save")
                        }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
L;
LDraw;
import L from "leaflet";
import LDraw from "leaflet-draw";
import gql from "graphql-tag";
import { LMap, LTileLayer, LControl, LControlZoom } from "vue2-leaflet";
import helper from "@/utils/helper.js";
import "leaflet.heat";
import { Loader } from "@googlemaps/js-api-loader";
import MapSearchBar from "@/components/base/MapSearchBar.vue";
import Config from "@/utils/config.json";

export default {
    components: {
        LMap,
        LTileLayer,
        LControl,
        LControlZoom,
        MapSearchBar
    },
    props: {
        viewType: {
            type: String,
            default: null
        },
        partner: {
            type: Object
        },
        title: {
            type: String,
            default: "Locations"
        }
    },
    apollo: {
        trackerLocations: {
            query: gql`
                query trackerLocations($deviceTimeGte: DateTime) {
                    trackerLocations(deviceTime_Gte: $deviceTimeGte) {
                        edges {
                            node {
                                id
                                latitude
                                longitude
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    deviceTime_Gte: new Date(new Date().setMonth(new Date().getMonth() - 3))
                };
            },
            skip() {
                return !this.enableHeatmap || this.heatmapLayer !== null;
            },
            result({ data }) {
                let points = data.trackerLocations.edges.map(edge => [edge.node.latitude, edge.node.longitude]);

                const map = this.$refs.map?.mapObject;
                this.heatmapLayer = L.heatLayer(points, {
                    radius: 25,
                    blur: 15,
                    maxZoom: 17,
                    minOpacity: 0.5,
                    max: 1.0,
                    gradient: {
                        0.4: "blue",
                        0.6: "cyan",
                        0.7: "lime",
                        0.8: "yellow",
                        1.0: "red"
                    }
                });
                try {
                    this.heatmapLayer.addTo(map);
                } catch {
                    //do nothing
                }
            }
        },
        tenantPartners: {
            query: gql`
                query TenantPartners($partnerType: String) {
                    tenantPartners(partnerType: $partnerType) {
                        edges {
                            node {
                                name
                                id
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    partnerType: this.partnerType
                };
            },
            skip() {
                return true;
            },
            result({ data }) {
                this.partners = data.tenantPartners;
            }
        }
    },
    data() {
        return {
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v => (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            },
            validate: false,
            /* ---------- HERE API & MAP CONFIGURATIONS ---------- */
            apikey: Config.VUE_APP_MAP_API_KEY, // You can get the API KEY from developer.here.com

            map_center: [0, 0],
            circle_center: null,
            zoom: 2,
            min_zoom: 2,
            editMode: false,
            max_zoom: 18,
            radius: 0,
            publicLocation: false,
            here_map_attribution: 'Map data &copy; 2021 <a href="https://developer.here.com" target="_blank">HERE</a>',
            here_map_url: `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=${Config.VUE_APP_MAP_API_KEY}&ppi=320`,
            /* ---------- MAP SEARCH BAR ---------- */
            googleResult: null,
            search: null,
            selectLocation: null,
            itemsLocation: [],
            showMapSearchBar: true,

            drawingEnabled: false,
            featureGroup: null,
            layer: null,
            trackerLocations: { edges: [] },
            automaticCircle: null,
            drawControl: null,
            drawOptions: {
                polygon: false,
                marker: false,
                polyline: false,
                circlemarker: false,
                rectangle: false,
                circle: {
                    allowIntersection: false,
                    showArea: true,
                    metric: true,
                    showRadius: true,
                    repeatMode: false
                }
            },
            heatmapLayer: null,
            enableHeatmap: false,
            // selectedPartner: null,
            isTrackerStockLocation: false,
            locationId: null,
            locationName: null,
            locationCountry: null,
            locationCity: null,
            locationCode: null,
            locationFullAddress: null,
            locationLatitude: null,
            locationLongitude: null,
            isLoadingLocationAdd: false,
            showWeatherForecast: false,
            maxTemperature: null,
            minTemperature: null,

            tagsChoices: [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ],

            headerChoices: [
                {
                    text: "",
                    align: "center",
                    value: "action",
                    width: "5px",
                    sortable: false
                },
                {
                    text: this.$t("headers.name"),
                    align: "center",
                    value: "name",
                    sortable: false
                },
                {
                    text: this.$t("headers.address"),
                    align: "center",
                    value: "node.user.lastName",
                    sortable: false
                },
                {
                    text: this.$t("partners.country"),
                    align: "center",
                    value: "node.user.email",
                    sortable: false
                },
                {
                    text: this.$t("partners.city"),
                    align: "center",
                    value: "node.user.require2fa",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "edit",
                    width: "5px",
                    sortable: false
                }
            ],
            partnerType: null,
            partnersTypes: [
                {
                    text: this.$t("headers.consignor"),
                    value: "consignor"
                },
                {
                    text: this.$t("headers.consignee"),
                    value: "consignee"
                },
                {
                    text: this.$t("headers.forwarder"),
                    value: "forwarder"
                },
                {
                    text: this.$t("home.other"),
                    value: "other"
                },
                {
                    text: this.$t("headers.carrier"),
                    value: "carrier"
                }
            ],
            partners: { edges: [] },
            tenantPartnerId: null,
            triggerRadius: false,
            tags: [],
            listOfLocations: [],
            createFromAnotherComp: null
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        showForm() {
            return this.googleResult || this.locationLatitude !== null;
        },
        partnerTypeText() {
            let _text = this.$t("headers.collaborator") + "*";
            return _text;
        },
        modeType() {
            let _mode = "createLocation";
            if (this.$route.query?.type) {
                if (this.$route.query?.from) {
                    const { type } = this.$route.query;
                    const [typeLocation, parameter] = type.split("-");
                    _mode = typeLocation;
                } else {
                    _mode = this.$route.query.type;
                }
            }
            return _mode;
        },
        tab() {
            let _tab = "myLocations";
            if (this.modeType === "editLocation") {
                _tab = "myLocations";
            } else if (this.modeType === "editPartnerLocation" || this.modeType === "createPartnerLocation") {
                _tab = "partnerLocations";
            } else {
                if (this.createFromOtherComp?.type) {
                    _tab = this.createFromOtherComp.routeDetais;
                }
            }
            return _tab;
        }
    },
    created() {
        this.fahrenheitToCelsius = helper.fahrenheitToCelsius;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
    },
    mounted() {
        this.$nextTick(() => {
            switch (this.modeType) {
                case "editLocation":
                    this.editMode = true;
                    this.editLocation();
                    break;
                case "editPartnerLocation":
                    if (this.$route.query?.from) {
                        /* Edit Location From partner component */
                        this.retainRouteData();
                    }
                    this.editMode = true;
                    this.editLocation();
                    break;
                case "createPartnerLocation":
                    if (this.$route.query?.from) {
                        /* Create Location From partner component */
                        const { id, type, from } = this.$route.query;
                        const [typeLocation, parameter] = type.split("-");
                        this.partnerType = parameter;
                        this.tenantPartnerId = id;
                        this.retainRouteData();
                    }
                    break;
                default:
                    if (this.$route.query?.from) {
                        this.retainRouteData();
                    }

                    break;
            }
        });
    },
    updated() {
        //fix map render everytime the component updates
        this.resizeMap();
        this.allowDrawing(false);
    },
    watch: {
        partnerType(val) {
            this.partners = { edges: [] };
            if (val) {
                this.$apollo.queries.tenantPartners.skip = false;
                this.$apollo.queries.tenantPartners.setVariables({
                    partnerType: val
                });
                this.$apollo.queries.tenantPartners.refetch();
            } else {
                this.$apollo.queries.tenantPartners.skip = true;
            }
        },
        tenantPartnerId(id) {
            if(id){
                this.locationName = this.partners.edges.find(partner => partner.node.id === id).node.name;
            }
        },
        maxTemperature(newVal) {
            if (newVal > 100) {
                this.maxTemperature = 100;
            }
            if (newVal < -100) {
                this.maxTemperature = -100;
            }
        },
        minTemperature(newVal) {
            if (newVal > 100) {
                this.minTemperature = 100;
            }
            if (newVal < -100) {
                this.minTemperature = -100;
            }
        },
        map_center: function(val) {
            let map = this.$refs.map.mapObject;
            if (this.locationFullAddress || this.locationCountry || this.locationCity) {
                map.setZoom(12);
                map.setView(val);
            }
        },
        enableHeatmap() {
            if (this.enableHeatmap == true) {
                try {
                    this.heatmapLayer.addTo(this.$refs.map.mapObject);
                } catch {
                    //do nothing
                }
            } else {
                try {
                    this.$refs.map.mapObject.removeLayer(this.heatmapLayer);
                } catch {
                    //do nothing
                }
            }
        },
        showWeatherForecast(newValue) {
            if (!newValue) {
                this.maxTemperature = null;
                this.minTemperature = null;
            }
        }
    },
    methods: {
        retainRouteData() {
            const { id, type, from } = this.$route.query;
            const [typeLocation, parameter] = type.split("-");
            const [previousRoute, routeDetail, index] = from.split("-");
            let indexType = Number(index) || 0;
            this.createFromAnotherComp = {
                previousRoute: previousRoute,
                routeDetails: routeDetail ? routeDetail.replace("/", "-") : null,
                typeDetails: parameter ? parameter : null,
                locationtype: typeLocation,
                index: indexType
            };
        },
        disabledFillIfPartner() {
            if(this.modeType.includes("Partner")){
                if(this.tenantPartnerId){
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        editLocation() {
            const {
                id,
                name,
                country,
                city,
                code,
                fullAddress,
                latitude,
                longitude,
                tags,
                isTrackerStockLocation,
                publicLocation,
                maxTemperature,
                minTemperature,
                tenantPartner
            } = this.$store.getters["location/getCreatedLocation"];

            /* partner */
            this.tenantPartnerId = tenantPartner?.id;
            this.partnerType = tenantPartner?.partnerType;

            /* Location */
            this.locationId = id;
            this.locationName = name;
            this.locationCountry = country;
            this.locationCity = city;
            this.locationCode = code ? code : null;
            this.locationFullAddress = fullAddress;
            this.locationLatitude = latitude;
            this.locationLongitude = longitude;
            this.tags = tags ? this.getTags(tags) : null;
            this.isTrackerStockLocation = isTrackerStockLocation;
            this.publicLocation = publicLocation ? publicLocation : null;
            this.maxTemperature = maxTemperature || null;
            this.minTemperature = minTemperature || null;
            this.circle_center = [latitude, longitude];
            this.map_center = [latitude, longitude];
            this.triggerRadius = true;
            this.showWeatherForecast = maxTemperature || minTemperature ? true : false;
            this.allowDrawing(false);
        },
        getTags(tags) {
            if (tags) {
                try {
                    let tagsArray = JSON.parse(tags.replace(/'/g, '"'));
                    return tagsArray.map(tag => {
                        return tag
                    });
                } catch (error) {
                    console.log(error);
                    return null;
                }
            }
            return null;
        },
        onPlaceChanged() {
            let _lat = this.googleResult.geometry.location.lat();
            let _lng = this.googleResult.geometry.location.lng();

            this.circle_center = [_lat, _lng];
            this.map_center = [_lat, _lng];
            this.triggerRadius = true;
            this.locationLatitude = this.googleResult.geometry.location.lat();
            this.locationLongitude = this.googleResult.geometry.location.lng();
            this.locationCountry = this.googleResult.address_components.find(
                component => component.types[0] === "country"
            )?.long_name;
            this.locationCity = this.googleResult.address_components.find(
                component => component.types[0] === "locality"
            )?.long_name;
            this.locationCode = this.googleResult.address_components.find(
                component => component.types[1] === "postal_code"
            )?.long_name;
            this.locationFullAddress = this.googleResult.formatted_address;
        },
        resetLocation() {
            // Remove drawn layers //
            const map = this.$refs.map.mapObject;
            if (this.layer != null) map.removeLayer(this.layer);
            if (this.drawingEnabled == true) this.allowDrawing(false);
            if (this.automaticCircle != null) this.automaticCircle = null;
            this.featureGroup?.clearLayers();
            // scroll dialog back up if necessary //
            let dialog = window.document.getElementsByClassName("v-dialog v-dialog--active v-dialog--persistent");

            dialog[0]?.scrollTo(0, 0);
            // Reset zoom //
            map.setZoom(2);
            // Reset variables value //

            this.isTrackerStockLocation = false;
            this.locationName = null;
            this.locationCountry = null;
            this.locationCity = null;
            this.locationCode = null;
            this.locationFullAddress = null;
            this.locationLatitude = null;
            this.locationLongitude = null;
            this.drawingEnabled = false;
            this.selectLocation = null;
            this.search = null;
            this.map_center = [12, 30];
            this.itemsLocation = [];
            this.showMapSearchBar = true;
            this.radius = null;
            this.showWeatherForecast = false;
            this.maxTemperature = null;
            this.minTemperature = null;
            this.partnerType = null;
            this.tenantPartnerId = null;
            this.partners = { edges: [] };

            try {
                if (this.enableHeatmap) {
                    this.$refs.map.mapObject.removeLayer(this.heatmapLayer);
                }
            } catch {
                //do nothing
            }

            this.heatmapLayer = null;
            this.enableHeatmap = false;

            this.tags = [];
            this.$refs.form.resetValidation();
            this.googleResult = null;
            // Close dialog //
            this.$emit("closeMap");
            this.triggerRadius = false;
        },
        editStop(e) {
            if (e) {
                this.triggerRadius = false;
            }
        },
        recenterMap() {
            const map = this.$refs.map.mapObject;
            if (this.locationLatitude && this.locationLongitude)
                map.setView([this.locationLatitude, this.locationLongitude]);
        },
        allowDrawing(createCircleAuto) {
            if (!this.$refs.map) return;
            const map = this.$refs.map.mapObject;
            if (this.featureGroup == null) {
                // Add new FeatureGroup from leaflet for Draw objects on map. Group together elements of the maps including: markers, geoJSON, polylines and polygon, tooltip and popup.
                this.featureGroup = new window.L.FeatureGroup();
                this.featureGroup.addTo(map);
            }
            // Create leaflet draw control menu
            if (this.drawControl == null) {
                this.drawControl = new window.L.Control.Draw({
                    position: "topright",
                    draw: this.drawOptions,
                    edit: {
                        featureGroup: this.featureGroup,
                        remove: true,
                        edit: {
                            selectedPathOptions: {
                                color: "#000",
                                fillColor: "#000"
                            }
                        }
                    }
                });
                // Add draw control to the map
                map.addControl(this.drawControl);
            }

            if (createCircleAuto) {
                if (this.automaticCircle == null) {
                    this.triggerRadius = false;
                    this.triggerRadius = false;
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });
                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                } else {
                    this.drawingEnabled = true;
                    this.featureGroup.clearLayers();
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });
                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                }
            } else {
                if (this.circle_center) {
                    this.drawingEnabled = true;
                    this.featureGroup.clearLayers();
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });

                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                }
            }

            //capture when the drag and draw circle is clicked
            map.on("draw:created", e => {
                this.triggerRadius = true;
                this.handleEventData(e);
            });
            //capture when the drag circle is clicked
            map.on("draw:editstart", () => {
                this.triggerRadius = true;
            });
            //capture when edit stop.
            map.on("draw:editstop", () => {
                this.triggerRadius = false;
            });
            //capture the event when circle is deleted and reset the values.
            map.on("draw:deleted", () => {
                this.triggerRadius = false;
                this.locationLatitude = null;
                this.locationLongitude = null;
                this.radius = null;
                this.circle_center = null;
                this.automaticCircle = null;
                this.featureGroup.clearLayers();
                this.drawingEnabled = false;
                this.isTrackerStockLocation = false;
                this.showWeatherForecast = false;
                this.locationName = null;
                this.locationCountry = null;
                this.locationCity = null;
                this.locationCode = null;
                this.locationFullAddress = null;
            });
        },
        editCircle(radius) {
            const map = this.$refs.map.mapObject;
            if (this.automaticCircle != null) {
                map.removeLayer(this.automaticCircle);
                this.automaticCircle = null;
            }

            this.drawingEnabled = true;
            this.featureGroup.clearLayers();
            this.layer = L.circle(this.circle_center, {
                radius: radius
            });
            //then add new drawn layer.
            this.featureGroup.addLayer(this.layer);
            //Event listener for when editing:
            this.layer.on("edit", e => {
                this.circle_center = e.target.getLatLng();
                this.locationLatitude = e.target.getLatLng().lat;
                this.locationLongitude = e.target.getLatLng().lng;
                this.radius = e.target.getRadius();
            });
        },
        handleEventData(event) {
            // HANDLE DATA COMING FROM DRAWING FINISHED AND EDITING CIRCLE
            this.radius = event.layer ? event.layer._mRadius : event.target._mRadius;
            this.locationLatitude = event.layer ? event.layer._latlng.lat : event.target._latlng.lat;
            this.locationLongitude = event.layer ? event.layer._latlng.lng : event.target._latlng.lng;

            setTimeout(() => {
                this.getlocationData(this.locationLatitude, this.locationLongitude);
            }, 500);
        },
        debounce(id) {
            if (id == null || id == undefined || id.length == 0) {
                return;
            }
            clearTimeout(this.timeOutID);
            this.timeOutID = setTimeout(() => {
                this.mapSearchHandler(id);
            }, 500);
        },
        async mapSearchHandler(id) {
            if (this.selectLocation == null || this.selectLocation == undefined) {
                return;
            }
            try {
                let response = await fetch(
                    "https://lookup.search.hereapi.com/v1/lookup?id=" + id + "&lang=en-US&apiKey=" + this.apikey
                );

                //const map = this.$refs.map.mapObject;
                let data = await response.json();
                this.locationLatitude = data.position.lat;
                this.locationLongitude = data.position.lng;
                this.locationCountry = data.address.countryName;
                this.locationCity = data.address.city;
                this.locationCode = data.address.postalCode;
                this.locationFullAddress = data.address.label;
                this.circle_center = data.position;
                this.map_center = data.position;
            } catch (err) {
                console.log(err);
            }
        },
        async getlocationData(lat, lng) {
            if (lat == null || lat == undefined || lng == null || lng == undefined) return;

            /*** GET COORDINATES FROM CLICK EVENT AND ASSIGN THEM TO CIRCLE POSITION ***/
            this.circle_center = [lat, lng];

            /*** GET INFO FROM API WITH THOSE COORDINATES ***/
            try {
                let response = await fetch(
                    "https://revgeocode.search.hereapi.com/v1/revgeocode?at=" +
                        lat +
                        "%2C" +
                        lng +
                        "&lang=en-US&apiKey=" +
                        this.apikey
                );

                let data = await response.json();

                /*** ASSIGN RECEIVED DATA TO VARIABLES ***/
                this.selectLocation = null;
                this.search = null;
                this.locationCountry = data?.items[0]?.address?.countryName;
                this.locationCity = data?.items[0]?.address?.city;
                this.locationCode = data?.items[0]?.address?.postalCode;
                this.locationFullAddress = data?.items[0]?.address?.label;
                this.locationLatitude = lat;
                this.locationLongitude = lng;
                this.map_center = [lat, lng];
            } catch (err) {
                console.log(err);
            }
        },
        resizeMap() {
            setTimeout(() => {
                //mapObject is an object which gives you acess to leaflet methods
                this.$refs.map?.mapObject?.invalidateSize();
            }, 50);
        },
        discartChanges() {
            if (this.createFromAnotherComp) {
                const { previousRoute, routeDetails, typeDetails } = this.createFromAnotherComp;
                const [prevModeType, fieldToUpdate] = routeDetails.split("-");
                switch (this.tab) {
                    case "partnerLocations":
                        this.$router.push({
                            path: previousRoute,
                            query: { tab: typeDetails, tabDetail: routeDetails }
                        });
                        break;
                    case "myLocations":
                        this.$router.push({
                            path: previousRoute,
                            query: { type: prevModeType, typeDetails: typeDetails }
                        });
                        break;
                }
            } else {
                //get the location from the store
                let location = this.$store.getters["location/getCreatedLocation"];

                //update vuex store with the new location
                this.$store.dispatch("location/updateCreatedLocation", {
                    type: "cancel",
                    location: location
                });

                //navigate with the tab parameter
                this.$router.push({
                    path: "/previewlocations",
                    query: { tab: this.tab }
                });
            }
        },
        save() {
            this.isLoading = true; // Use a generic loading state
            const payload = this.getPayload();
            const isCreateMode = !this.editMode;
            const mutation = isCreateMode
                ? gql`
                      mutation saveLocation($input: CreateTenantLocationInput!) {
                          createTenantLocation(input: $input) {
                              tenantLocation {
                                  id
                                  name
                                  city
                                  state
                                  country
                                  locationType
                                  isTrackerStockLocation
                                  fullAddress
                                  latitude
                                  longitude
                                  radius
                                  tags
                                  tenantPartner {
                                      id
                                      name
                                  }
                                  tenant {
                                      id
                                      name
                                  }
                              }
                          }
                      }
                  `
                : gql`
                      mutation updateLocation($input: UpdateTenantLocationInput!) {
                          updateTenantLocation(input: $input) {
                              location {
                                  id
                                  name
                                  locationType
                                  isTrackerStockLocation
                                  fullAddress
                                  tags
                                  city
                                  state
                                  country
                                  geometryType
                                  latitude
                                  longitude
                                  radius
                                  polygon
                                  tenantPartner {
                                      id
                                      name
                                  }
                              }
                          }
                      }
                  `;

            this.$apollo
                .mutate({
                    mutation,
                    variables: { input: { ...payload } }
                })
                .then(response => {
                    const location = isCreateMode
                        ? response.data.createTenantLocation.tenantLocation //created location
                        : response.data.updateTenantLocation.location; // updated location

                    //Navigate to the preview locations page
                    if (this.createFromAnotherComp) {
                        const { previousRoute, routeDetails, typeDetails, index } = this.createFromAnotherComp;
                        const [prevModeType, fieldToUpdate] = routeDetails.split("-");
                        switch (this.tab) {
                            case "partnerLocations":
                                this.$router.push({
                                    path: previousRoute,
                                    query: { tab: typeDetails, tabDetail: routeDetails }
                                });
                                break;
                            case "myLocations":
                                if (fieldToUpdate) {
                                    /* get */
                                    let _trip = this.$store.getters["trip/getDraftTrip"];
                                    if (fieldToUpdate === "origin") {
                                        _trip.originLocation.id = location.id;
                                    }
                                    if (fieldToUpdate === "destination") {
                                        _trip.destinationLocation.id = location.id;
                                    }
                                    if (fieldToUpdate === "loadPoint") {
                                        _trip.tripFreightSet.edges[index].node.loadPoint = location.id;
                                    }
                                    /* set */
                                    this.$store.dispatch("trip/updateCreatedTrip", {
                                        type: "draft",
                                        trip: _trip
                                    });
                                }

                                this.$router.push({
                                    path: previousRoute,
                                    query: { type: prevModeType, typeDetails: typeDetails }
                                });
                                break;
                        }
                    } else {
                        // Update Vuex store with the new location
                        this.$store.dispatch("location/updateCreatedLocation", {
                            type: "update",
                            location: location
                        });

                        // Navigate with the tab parameter
                        this.$router.push({
                            path: "/previewlocations",
                            query: { tab: this.tab }
                        });
                    }
                    const payload = {
                        color: "blue",
                        message: this.$t("general.update_successfully")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        getPayload() {
            // Calculate temperature values
            const maxTemperatureCalculated = this.calculateTemp(this.maxTemperature);
            const minTemperatureCalculated = this.calculateTemp(this.minTemperature);

            // Start with a base payload that's common to all scenarios
            let payload = {
                locationType: this.publicLocation ? "tenant_location" : "private_location",
                isTrackerStockLocation: this.isTrackerStockLocation,
                showWeatherForecast: this.showWeatherForecast,
                name: this.locationName,
                city: this.locationCity,
                state: null, // Assuming state is always null in this context
                country: this.locationCountry,
                fullAddress: this.locationFullAddress,
                geometryType: null, // Assuming geometryType is always null in this context
                latitude: this.locationLatitude,
                longitude: this.locationLongitude,
                radius: this.radius || 1000, // Default to 1000 if radius is falsy
                maxTemperature: maxTemperatureCalculated,
                minTemperature: minTemperatureCalculated,
                tags: this.tags // Assume tags are applicable in all scenarios unless overridden
            };

            // Adjust payload based on modeType
            if (this.modeType === "editLocation" || this.modeType === "createLocation") {
                payload.locationType = this.publicLocation ? "tenant_location" : "private_location";
            } else if (this.modeType === "editPartnerLocation" || this.modeType === "createPartnerLocation") {
                payload.locationType = "partner_location";
                payload.tenantPartnerId = this.tenantPartnerId; // Only add tenantPartnerId for partner locations
            }

            // If in edit mode, include the ID
            if (this.modeType.includes("edit")) {
                payload.id = this.locationId;
            }

            return payload;
        },
        calculateTemp(temp) {
            if (this.getTemperatureSymbol() === "ºF") {
                temp = this.fahrenheitToCelsius(parseFloat(temp));
            }
            if (this.getTemperatureSymbol() === "ºC") {
                temp = parseFloat(temp);
            }
            return temp;
        }
    }
};
</script>
<style scoped>
.cursorPen {
    cursor: url("https://img.icons8.com/material-rounded/24/000000/edit--v1.png"), default;
}

.map {
    cursor: not-allowed;
}
#map {
    height: 400px; /* or any desired height */
}
.card-container {
    position: relative;
    height: 80px;
    background-color: #ffffff;
    border-radius: 5px;
}
.containerFooter {
    width: 67%;
    height: 80px;
    background-color: #ffffff;
    border-radius: 5px;
}
.fixed-bottom-card {
    position: fixed;
    bottom: 1.2px;
    left: 16%;
    z-index: 1000;
}
.fixed-bottom-card-1 {
    position: fixed;
    bottom: 50px;
    left: 16%;
    z-index: 0; /* Adjust the z-index as needed */
}
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textBody {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: "#868599" !important;
}
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay2 {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay3 {
    padding-left: 70px;
    padding-right: 20px;
}
</style>
