import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.$t("general.disable_2fa"))+" ")]),_c(VCardText,{staticClass:"pt-10 pb-0"},[_c(VForm,{ref:"disable2FAForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"id":"password","label":_vm.$t('login.password'),"name":"password","type":_vm.showpassword ? 'text' : 'password',"rules":[_vm.rules.required],"error-messages":_vm.disable2FAError,"outlined":"","append-icon":_vm.showpassword ? 'visibility' : 'visibility_off'},on:{"click:append":function($event){_vm.showpassword = !_vm.showpassword},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.disabled2FA()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VCardActions,{staticClass:"pb-5"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":function($event){return _vm.closeDisable2FADialog()}}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"outlined":"","rounded":"","loading":_vm.isDisabling2FA,"color":"error"},on:{"click":function($event){return _vm.disable2FA()}}},[_vm._v(_vm._s(_vm.$t("general.turn_off")))]),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }