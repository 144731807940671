<template>
    <div :key="trip.id">
        <!-- Tabs -->
        <v-tabs
            v-model="tab"
            color="primary"
            ref="tabs"
            background-color="transparent"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
                class="textTitle "
            >
                <template v-slot:default>
                    <div class="text-capitalize">
                        {{ t.name }}
                    </div>
                </template>
            </v-tab>
            <v-spacer></v-spacer>
            <!-- TAB FILTERS -->
            <!--  <FilterMenu
                title="Customize"
                description="Select modules to display"
                :choices.sync="filterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
                class="mt-1"
            ></FilterMenu>  -->
        </v-tabs>
        <v-divider></v-divider>
        <!-- tab content -->
        <v-tabs-items
            v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <v-tab-item value="tab-freights" :transition="false">
                <TripTabSummary
                    :trip="trip"
                    :tripIsLoading.sync="tripIsLoading"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    @fullScreen="changeFullScreen"
                    v-on:refresh-trip="$emit('refresh-trip')"
                    :tripTimeRange.sync="tripTimeRange"
                ></TripTabSummary>
            </v-tab-item>
            <v-tab-item value="tab-timeline" :transition="false">
                <TripTabTimeline
                    :trip="trip"
                    viewType="tab"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    v-on:refresh-trip="$emit('refresh-trip')"
                    :tripTimeRange.sync="tripTimeRange"
                ></TripTabTimeline>
            </v-tab-item>
            <v-tab-item value="tab-alerts" :transition="false">
                <TripTabAlert
                    :trip="trip"
                    :tripIsLoading.sync="tripIsLoading"
                    viewType="tab"
                    :isSharedTrip.sync="isSharedTrip"
                    :organisationPermissions.sync="organisationPermissions"
                    v-on:refresh-trip="$emit('refresh-trip')"
                    :tripTimeRange.sync="tripTimeRange"
                ></TripTabAlert>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import TripTabSummary from "@/components/trips/details/TripTabSummary.vue";
import TripTabTimeline from "@/components/trips/details/TripTabTimeline.vue";
import TripTabAlert from "@/components/trips/details/TripTabAlert.vue";
//import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        tripIsLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        defaultFilterChoices: {
            type: Array,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        tripTimeRange: {
            type: Array,
            required: false,
            default: function() {
                return [];
            }
        }
    },
    components: {
        TripTabSummary,
        TripTabTimeline,
        TripTabAlert
        //FilterMenu
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        routeTab() {
            return this.$route.query.tab;
        },
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        },
        filterChoices: {
            get() {
                let _filterChoices = helper.getFilterChoices(
                    this.defaultFilterChoices,
                    "tripDetailsFilterChoices"
                );
                _filterChoices.filter(c => {
                    if (
                        c.code === "freights" &&
                        c.name.toLowerCase() === "freights"
                    )
                        c.name = "Summary";
                });
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    tripDetailsFilterChoices: value
                });
            }
        },
        selectedFilters() {
            let _filters = [];

            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        },
        tab(val) {
            if (val) {
                if (this.routeTab === val.replace("tab-", "")) return;
                this.$router.push({
                    name: this.$route.name,
                    query: {
                        id: this.trip.id,
                        tab: val.replace("tab-", ""),
                        freight: this.$route.query.freight
                    }
                });
            }
        },

        routeTab: {
            immediate: true,
            handler() {
                if (this.routeTab) {
                    //check if tab exists
                    if (
                        this.selectedFilters.find(
                            f => f.code === this.routeTab
                        ) === undefined
                    ) {
                        try {
                            let firstTab = this.selectedFilters[0].code;
                            this.$router.push({
                                name: this.$route.name,
                                query: {
                                    id: this.trip.id,
                                    tab: firstTab,
                                    freight: this.$route.query.freight
                                }
                            });
                        } catch {
                            //do nothing
                        }
                    } else this.tab = "tab-" + this.routeTab;
                }
            }
        }
    },
    methods: {
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen(val) {
            this.$emit("fullScreen", val);
        }
    },
};
</script>
<style scoped>
.textTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
</style>
