<template>
    <v-dialog max-width="600" v-model="openAddUserDialog" persistent>
        <v-card>
            <!--- ICON AND TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("partners.add_user") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-4">
                <v-form ref="addUserForm">
                    <v-col cols="12" class="pa-0 ma-0 mt-6">
                        <v-text-field
                            v-model="user.firstName"
                            :label="$t('headers.first_name') + '*'"
                            outlined
                            class="mx-6"
                            :rules="[rules.required]"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-0 ma-0">
                        <v-text-field
                            dense
                            v-model="user.lastName"
                            :label="$t('headers.last_name') + '*'"
                            outlined
                            class="mx-6"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-0 ma-0">
                        <v-text-field
                            dense
                            label="Email*"
                            v-model="user.email"
                            :rules="[rules.required, rules.invalidEmail]"
                            outlined
                            class="mx-6"
                        ></v-text-field>
                    </v-col>
                    <v-row class="mx-6">
                        <v-checkbox
                            v-model="user.isSuperuser"
                            :label="$t('headers.superuser')"
                            hide-details
                        ></v-checkbox>
                    </v-row>
                    <v-row class="mx-6">
                        <v-checkbox
                            v-model="user.require2fa"
                            :label="$t('headers.2fa')"
                            hide-details
                        ></v-checkbox>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTION BUTTONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    :loading="isLoadingAddUser"
                    @click="createUser"
                    color="primary"
                    rounded
                    >{{ $t("general.add") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "create-user-dialog",
    props: {
        openAddUserDialog: {
            type: Boolean,
            default: false
        },
        tenant: {
            type: Object,
            default: null
        }
    },
    components: {},
    data() {
        return {
            /* User data */
            user: {
                firstName: null,
                lastName: null,
                email: null,
                isSuperuser: false,
                require2fa: false
            },
            isLoadingAddUser: false,
            /* Rules */
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v =>
                    /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    methods: {
        closeAndResetInput() {
            this.user = {
                firstName: null,
                lastName: null,
                email: null,
                isSuperuser: false,
                require2fa: false
            };
            this.$refs.addUserForm.resetValidation();
            this.$emit("closeAddUserDialog");
        },
        createUser() {
            // validate form input :
            if (!this.$refs.addUserForm.validate()) return;
            // Initialize Loading :
            this.isLoadingAddUser = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation Mutation($input: CreateUserInput!) {
                            createUser(input: $input) {
                                user {
                                    id
                                    firstName
                                    lastName
                                    fullName
                                    email
                                    isStaff
                                    isSuperuser
                                    lastLogin
                                    enabled2fa
                                    groups {
                                        totalCount
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...this.user
                        }
                    }
                })
                .then(() => {
                    // Emit event to signal user is added and reload table showing users //
                    this.$emit("refreshDataTable");
                    // Close the dialog and reset form fields //
                    this.closeAndResetInput();
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.isLoadingAddUser = false;
                });
        }
    }
};
</script>
