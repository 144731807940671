<template>
    <v-container fluid class="pa-0">
        <!-- tutorial -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>
        <v-row class="d-flex align-center justify-end mt-4 mb-3">
            <v-col cols="auto">
                <v-btn
                    rounded
                    color="primary"
                    class="mr-1"
                    @click="openAddActionGroup = true"
                >
                    {{ $t("alert_rules.create_action_group") }}
                </v-btn>
            </v-col>
        </v-row>
        <v-btn 
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            style="z-index: 1"
            class="mt-3"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- filter -->
        <ActionGroupsFilter
            v-on:filter-changed="onFilterChanged"
            v-on:updateActionGroupsTable="refreshTable"
            v-on:item-created="onItemCreated"
        ></ActionGroupsFilter>

        <!-- list -->
        <ActionGroupsTable
            ref="actionGroupsTable"
            class="mt-3"
            :actionGroupsFilter="actionGroupsFilter"
            v-on:click:row="onGroupSelected"
        ></ActionGroupsTable>

        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="groupDetailDrawer"
            :width="groupDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <ActionGroupDetail
                    v-if="selectedActionGroup"
                    :key="selectedActionGroup.id"
                    :actionGroup="selectedActionGroup"
                    :isFullScreen="isGroupDetailDrawerFullScreen"
                    v-on:changeFullScreen="onGroupDetailDrawerFullScreenChange"
                    v-on:close="groupDetailDrawer = false"
                    v-on:refreshDataTable="refreshTable"
                ></ActionGroupDetail>
            </div>
        </v-navigation-drawer>
        <AddActionGroupDialog
            :openAddActionGroupDialog="openAddActionGroup"
            v-on:refreshDataTable="refreshTable"
            v-on:closeAddGroupDialog="openAddActionGroup = false"
            v-on:item-created="onItemCreated"
        ></AddActionGroupDialog>
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import ActionGroupsFilter from "@/components/actiongroups/ActionGroupsFilter.vue";
import ActionGroupsTable from "@/components/actiongroups/ActionGroupsTable.vue";
import ActionGroupDetail from "@/components/actiongroups/ActionGroupDetail.vue";
import AddActionGroupDialog from "@/components/actiongroups/create/AddActionGroupDialog.vue";
import { eventBus } from "@/main.js";

export default {
    title: "Action Groups",
    components: {
        TutorialDialog,
        ActionGroupsFilter,
        ActionGroupsTable,
        ActionGroupDetail, 
        AddActionGroupDialog
    },

    apollo: {},

    data() {
        return {
            goTopShow: false,
            isLoading: false,
            menu: false,
            openAddActionGroup: false,
            actionGroupsFilter: {
                first: 10
            },

            selectedActionGroup: null,

            tutorialDialog: false,

            groupDetailDrawer: false,
            groupDetailFullScreen: false
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        groupDetailDrawerWidth() {
            if (this.groupDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isGroupDetailDrawerFullScreen() {
            return this.groupDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },
    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        // console.log(this.$route);
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        },

        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        refreshTable() {
            this.$refs.actionGroupsTable.$apollo.queries.actionGroups.refetch();
        },
        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        },
        onFilterChanged(filter) {
            this.actionGroupsFilter = filter;
        },
        onGroupSelected(actionGroup) {
            this.selectedActionGroup = actionGroup.node;
            this.groupDetailDrawer = true;
        },
        onGroupDetailDrawerFullScreenChange(val) {
            this.groupDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onItemCreated(actionGroup) {
            this.selectedActionGroup = actionGroup;
            this.groupDetailDrawer = true;
            this.refreshTable();
        }
    }
};
</script>
<style scoped>
    ::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
</style>
