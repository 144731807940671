<template>
    <div v-if="!canAccess">
        <NoPermission></NoPermission>
    </div>
    <div v-else class="px-5 mb-15">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col cols="12" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :hint="$t('general.search_name')"
                                v-model="searchInput"
                                append-icon="search"
                                :label="$t('general.search')"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="12" sm="5" lg="4" class="py-2 pr-4 text-right">
                    <v-row class="d-flex align-center justify-end">
                        <!-- ADD -->
                        <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            class="mr-1"
                            @click="openAddActionGroupDialog = true"
                            v-if="canEdit"
                        >
                            <v-icon left small>add_box</v-icon>
                            {{ $t("alert_rules.add_action_group") }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :items="actionGroups.edges"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    loader-height="2"
                    hide-default-footer
                    :loading="$apollo.queries.actionGroups.loading"
                >
                    <!-- Email Notification -->
                    <template
                        v-slot:item.node.tenantActionGroup.shouldEmailNotification="{
                            item
                        }"
                    >
                        <v-icon
                            v-if="
                                item.node.tenantActionGroup
                                    .shouldEmailNotification
                            "
                            color="success"
                            >check_circle</v-icon
                        >
                        <v-icon color="grey lighten-2" v-else
                            >check_circle</v-icon
                        >
                    </template>

                    <!-- Inbox Notification -->
                    <template
                        v-slot:item.node.tenantActionGroup.shouldInboxNotification="{
                            item
                        }"
                    >
                        <v-icon
                            v-if="
                                item.node.tenantActionGroup
                                    .shouldInboxNotification
                            "
                            color="success"
                            >check_circle</v-icon
                        >
                        <v-icon color="grey lighten-2" v-else
                            >check_circle</v-icon
                        >
                    </template>

                    <!-- TotalCount of Users -->
                    <template
                        v-slot:item.node.tenantActionGroup.users="{ item }"
                    >
                        <span>{{
                            item.node.tenantActionGroup.tenantActionGroupUserSet
                                .totalCount
                        }}</span>
                    </template>

                    <!-- TotalCount of External Emails -->
                    <template
                        v-slot:item.node.tenantActionGroup.externalEmails="{
                            item
                        }"
                    >
                        <span>{{
                            calculateNumberOfEmails(item.node.tenantActionGroup)
                        }}</span>
                    </template>

                    <!-- REMOVE -->
                    <template v-slot:item.delete="{ item }">
                        <v-icon @click="removeActionGroup(item)" v-if="canEdit"
                            >delete</v-icon
                        >
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!---- ADD GROUP USER DIALOG ---->
        <AddAlertRuleActionGroupDialog
            :openAddActionGroupDialog="openAddActionGroupDialog"
            :actionGroups="actionGroups"
            :alertRule="alertRule"
            v-on:refreshActionGroupsTable="refreshTables()"
            v-on:closeAddActionGroupDialog="openAddActionGroupDialog = false"
        ></AddAlertRuleActionGroupDialog>

        <!---- DELETE GROUP USER DIALOG ---->
        <DeleteAlertRuleActionGroupDialog
            :openDeleteDialog="openDeleteActionGroupDialog"
            :alertRule="alertRule"
            :actionGroup="actionGroup"
            v-on:refreshDataTable="refreshTables()"
            v-on:closeDeleteActionGroupDialog="closeAndCleanDeletedActionGroups"
        >
            <template #title>{{
                $t("alert_rules.delete_action_group")
            }}</template>
            <template #content>{{
                $t("alert_rules.sure_action_group")
            }}</template>
        </DeleteAlertRuleActionGroupDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddAlertRuleActionGroupDialog from "@/components/alertrules/create/AddAlertRuleActionGroupDialog.vue";
import DeleteAlertRuleActionGroupDialog from "@/components/alertrules/delete/DeleteAlertRuleActionGroupDialog.vue";
import helper from "@/utils/helper.js";
import { TenantPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import Config from "@/utils/config.json";

export default {
    name: "ActionGroupUsers",
    components: {
        AddAlertRuleActionGroupDialog,
        DeleteAlertRuleActionGroupDialog,
        NoPermission
    },
    props: {
        alertRule: {
            type: Object,
            required: true
        }
    },
    apollo: {
        actionGroups: {
            query: gql`
                query TenantAlertRuleActionGroups(
                    $tenantAlertRuleId: ID!
                    $first: Int
                    $search: String
                ) {
                    tenantAlertRuleActionGroups(
                        tenantAlertRuleId: $tenantAlertRuleId
                        first: $first
                        search: $search
                    ) {
                        edges {
                            node {
                                id
                                tenantActionGroup {
                                    id
                                    name
                                    tenantActionGroupUserSet {
                                        totalCount
                                    }
                                    externalEmails
                                    shouldEmailNotification
                                    shouldInboxNotification
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantAlertRuleId: this.alertRule.id,
                    first: 20,
                    search: this.searchInput
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantAlertRuleActionGroups;
            },
            skip: false
        }
    },
    data() {
        return {
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /*********************************/
            openAddActionGroupDialog: false,
            openDeleteActionGroupDialog: false,
            /******************************/
            /* ---- USER INPUT DATA ---- */
            /****************************/
            actionGroup: null,
            searchInput: null,
            actionGroups: { edges: [] },
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
            headerChoices: [
                {
                    text: this.$t('headers.name'),
                    align: "left",
                    value: "node.tenantActionGroup.name",
                    sortable: false
                },
                {
                    text: this.$t('home.users'),
                    align: "right",
                    value: "node.tenantActionGroup.users"
                },
                {
                    text: this.$t('general.external_emails'),
                    align: "right",
                    value: "node.tenantActionGroup.externalEmails"
                },
                {
                    text: this.$t('general.email_notification'),
                    align: "center",
                    value: "node.tenantActionGroup.shouldEmailNotification"
                },
                {
                    text: this.$t('general.inbox_notification'),
                    align: "center",
                    value: "node.tenantActionGroup.shouldInboxNotification"
                },
                {
                    text: "",
                    align: "center",
                    value: "delete",
                    width: "5px",
                    sortable: false
                }
            ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
    },
    watch: {
        searchInput() {
            this.$apollo.tenantAlertRuleActionGroups
                ? this.$apollo.tenantAlertRuleActionGroups.refetch()
                : null;
        }
    },
    computed: {
        canAccess() {
            return this.hasPermission(TenantPermissions.VIEW_TENANT_ALERT_RULE);
        },
        canEdit() {
            return this.hasPermission(TenantPermissions.EDIT_TENANT_ALERT_RULE);
        }
    },
    mounted(){
                //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'alert_rule_action_group_svw',
        });
        this.setUserProperties();
    },
    methods: {
          setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("alert_rule_action_group_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("alert_rule_action_group_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        removeActionGroup(item) {
            this.openDeleteActionGroupDialog = true;
            this.actionGroup = item.node.tenantActionGroup.id;
        },
        closeAndCleanDeletedActionGroups() {
            this.openDeleteActionGroupDialog = false;
            this.actionGroup = null;
        },
        refreshTables() {
            this.$apollo.queries.actionGroups.refetch();
            this.$emit("refreshDataTable");
        },
        calculateNumberOfEmails(actionGroup) {
            if (!actionGroup.externalEmails) return 0;
            else if (actionGroup.externalEmails === "[]") return 0;
            else return actionGroup.externalEmails.split(",").length;
        }
    }
};
</script>
