<template>
    <div class="px-5">
        <v-card flat class="px-4 rounded-lg mb-2" outlined>
            <v-card-title>
                <v-row dense>
                    <v-col cols="auto" class="mr-2">
                        <slot name="permissionTitle"></slot>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex flex-row justify-end">
                        <v-btn elevation="0" color="" @click="collapseAll" v-if="togglePanel" class="mr-2">
                            {{ $t("general.collapse_all") }}
                        </v-btn>
                        <v-btn elevation="0" color="" @click="expandAll" v-else class="mr-2">
                            {{ $t("general.expand_all") }}
                        </v-btn>
                        <v-btn
                            v-if="selectedPermissions.length > 0"
                            elevation="0"
                            color=""
                            @click="deselectAll"
                            class="mr-2"
                        >
                            {{ $t("general.deselect_all") }}
                        </v-btn>
                        <v-btn elevation="0" color="primary" @click="selectAll">
                            {{ $t("general.select_all") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-expansion-panels v-model="panel" multiple focusable class="mt-2 mb-5 pb-5 elevation-0" flat>
                    <v-expansion-panel v-for="(item, index) in computedPermissions" :key="index">
                        <v-expansion-panel-header>
                            <h5>{{ item.name }}</h5>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="elevation-0 py-4 px-1">
                            <v-row dense v-for="permission in item.options" :key="permission.module" class="my-2">
                                <v-col cols="12">
                                    <v-card outlined class="rounded-lg px-6 pt-4 backgroundContent">
                                        <v-row dense>
                                            <v-col
                                                v-for="item in cardBody(permission)"
                                                :key="item.title"
                                                cols="12"
                                                xs="12"
                                                sm="6"
                                                :md="item.tag === 'module' ? 5 : ''"
                                                :lg="item.tag === 'module' ? 5 : ''"
                                                :xl="item.tag === 'module' ? 5 : ''"
                                            >
                                                <div>
                                                    <div class="textHeader text-truncate">
                                                        {{ item.title }}
                                                    </div>
                                                    <v-checkbox
                                                        class="pa-0 ma-0"
                                                        dense
                                                        v-if="item.tag !== 'module'"
                                                        :value="isChecked(item.value)"
                                                        :input-value="isChecked(item.value)"
                                                        @change="onSelelect(item.value)"
                                                    ></v-checkbox>
                                                    <div class="textBody text-truncate" v-else>
                                                        <span v-if="item.value">
                                                            {{ item.value }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import helper from "@/utils/helper.js";
import {
    TripPermissions,
    // TrackerPermissions,
    CarrierPermissions,
    TenantPermissions,
    BookingPermissions
} from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "PartnerPermissionsTab",
    components: {},
    props: {
        permissions: {
            type: Array,
            default: () => []
        },
        groupPermissions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isLoadingSavePermission: false,
            enableOrDisablePermissionBtn: true,
            selectedPermissions: [],
            selectedPermissionsCopy: [],
            panel: [0],
            searchInput: "",
            togglePanel: false
        };
    },
    beforeCreate() {
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
        this.CarrierPermissions = CarrierPermissions;
        this.TenantPermissions = TenantPermissions;
    },
    watch: {
        groupPermissions: {
            handler(val) {
                if (val?.length > 0) {
                    val.forEach(item => {
                        if (!this.selectedPermissions.includes(item)) {
                            this.selectedPermissions.push(item);
                        }
                    });
                }
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        computedPermissions() {
            let _permission = this.permissions;
            return _permission;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1300;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "partner_locations_tab_svw"
        });
    },
    methods: {
        isChecked(permission) {
            this.selectedPermissions.includes(permission);
            return this.selectedPermissions.includes(permission);
        },
        cardBody(item) {
            let _body = [
                {
                    title: this.$t("general.module"),
                    tag: "module",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: item.module
                },
                {
                    title: this.$t("general.view"),
                    tag: "view",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: item.view.value
                },
                {
                    title: this.$t("general.create"),
                    tag: "create",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: item.create.value
                },
                {
                    title: this.$t("general.edit"),
                    tag: "edit",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: item.edit.value
                },
                {
                    title: this.$t("general.delete"),
                    tag: "delete",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: item.delete.value
                }
            ];

            return _body;
        },
        onSelelect(val) {
            if (this.selectedPermissions.includes(val)) {
                this.selectedPermissions = this.selectedPermissions.filter(item => item !== val);
            } else {
                this.selectedPermissions.push(val);
            }
            this.$emit("update-permissions", this.selectedPermissions);
        },
        expandAll() {
            this.togglePanel = true;
            this.panel = this.computedPermissions.map((item, index) => index);
        },
        collapseAll() {
            this.togglePanel = false;
            this.panel = [0];
        },
        selectAll() {
            this.computedPermissions.forEach(element => {
                element.options.forEach(permission => {
                    this.selectedPermissions.push(
                        permission.view.value,
                        permission.create.value,
                        permission.edit.value,
                        permission.delete.value
                    );
                });
            });
            this.$emit("update-permissions", this.selectedPermissions);
        },
        deselectAll() {
            this.selectedPermissions = [];
            this.$emit("update-permissions", this.selectedPermissions);
        }
    }
};
</script>
<style scoped>
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
.outline {
    border: 1px solid #ebebf5;
    box-sizing: border-box;
    border-radius: 4px;
}
.backgroundContent {
    background: #f9fafe;
}
</style>
