<template>
    <v-container fluid>
        <v-row class="d-flex align-center justify-center px-10">
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <v-btn
                    v-show="goTopShow"
                    fab
                    dark
                    fixed
                    bottom
                    right
                    small
                    style="z-index: 1"
                    color="primary"
                    @click="toTop"
                >
                    <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>
                <UsersFilterPreview
                    v-if="tab === 'staff'"
                    v-on:filter-changed="onUserFilterChanged"
                    :tabByType="tab"
                    :label="$t('general.search_name')"
                    class="mx-3"
                >
                </UsersFilterPreview>
                <PreviewGroupsFilter
                    v-if="tab === 'group'"
                    v-on:filter-changed="onGroupFilterChanged"
                    :tabByType="tab"
                    :label="$t('general.search_group')"
                    class="mx-3"
                >
                </PreviewGroupsFilter>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <v-row class="ma-0 px-0">
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <h2 class="modelTitle">
                            {{ titleChoices(tab) }}
                        </h2>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="d-flex justify-end">
                        <v-btn v-if="tab === 'staff'" color="primary" @click="createUser()">
                            {{ $t("general.create_user") }}
                        </v-btn>
                        <v-btn v-if="tab === 'group'" color="primary" @click="createGroup()">
                            {{ $t("general.create_group") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <!-- Custom tabs -->
                <CustomTabs v-model="tab" :tabs="tabs" :droggable="false" v-on:onClick="cleanRoute"></CustomTabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        v-for="(item, i) in tabs"
                        :key="i"
                        :value="item.value"
                        class="background"
                        :transition="false"
                    >
                        <!-- Users -->
                        <PreviewUsersTable
                            v-if="tab === 'staff'"
                            ref="usersTable"
                            :usersFilter="usersFilter"
                            v-on:click:row="onUserSelected"
                            class="mx-1"
                        >
                        </PreviewUsersTable>

                        <!-- Group -->
                        <PreviewGroupsTable
                            v-if="tab === 'group'"
                            ref="groupTable"
                            :groupsFilter="groupsFilter"
                            v-on:click:row="onGroupSelected"
                            class="mx-1"
                        >
                        </PreviewGroupsTable>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <!-- TUTORIAL -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>
        <!-- DETAIL DRAWER -->
        <v-navigation-drawer
            v-if="tab === 'staff'"
            v-model="userDetailDrawer"
            :width="userDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <PreviewUserDetail
                    ref="userDetail"
                    v-if="selectedUser"
                    :key="selectedUser.node.id"
                    :user="selectedUser"
                    :isFullScreen="isUserDetailDrawerFullScreen"
                    v-on:changeFullScreen="onUserDetailDrawerFullScreenChange"
                    v-on:refreshDataTable="() => {}"
                    v-on:close="userDetailDrawer = false"
                ></PreviewUserDetail>
            </div>
        </v-navigation-drawer>
        <v-navigation-drawer
            v-if="tab === 'group'"
            v-model="groupDetailDrawer"
            :width="userDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <PreviewGroupDetail
                    ref="groupDetail"
                    v-if="selectedGroup"
                    :key="selectedGroup.node.id"
                    :group="selectedGroup"
                    :isFullScreen="isUserDetailDrawerFullScreen"
                    v-on:changeFullScreen="onUserDetailDrawerFullScreenChange"
                    v-on:refreshDataTable="() => {}"
                    v-on:close="groupDetailDrawer = false"
                ></PreviewGroupDetail>
            </div>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import UsersFilterPreview from "@/components/users/UsersFilterPreview.vue";
import CustomTabs from "@/components/base/CustomTabs.vue";
import PreviewUsersTable from "@/components/users/PreviewUsersTable.vue";
import PreviewUserDetail from "@/components/users/PreviewUserDetail.vue";
import PreviewGroupsFilter from "@/components/groups/PreviewGroupsFilter.vue";
import PreviewGroupsTable from "@/components/groups/PreviewGroupsTable.vue";
import PreviewGroupDetail from "@/components/groups/PreviewGroupDetail.vue";
import { eventBus } from "@/main.js";

export default {
    title: "previewusers",
    components: {
        TutorialDialog,
        UsersFilterPreview,
        CustomTabs,
        PreviewUsersTable,
        PreviewUserDetail,
        PreviewGroupsFilter,
        PreviewGroupsTable,
        PreviewGroupDetail
    },
    data() {
        return {
            /* User */
            selectedUser: null,
            searchInput: null,
            userDetailDrawer: false,
            userDetailFullScreen: false,
            tutorialDialog: false,
            goTopShow: false,
            isLoading: false,
            tab: null,
            usersFilter: {
                first: 20
            },
            /* Groups */
            searchInputGroups: null,
            openAddGroup: false,
            openDeleteDialog: false,
            openSelector: false,
            groupDetailDrawer: false,
            groupDetailFullScreen: false,
            groupsFilter: {
                first: 20
            },
            menu: false,
            selectedGroup: null,
            selectedGroups: null,
            /* General */
            tabs: [
                {
                    id: 0,
                    text: this.$t("general.staff"),
                    value: "staff"
                },
                {
                    id: 1,
                    text: this.$t("general.group"),
                    value: "group"
                }
            ],
            shouldStickyFilter: false
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        userDetailDrawerWidth() {
            if (this.userDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isUserDetailDrawerFullScreen() {
            return this.userDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },
    watch: {
        userDetailDrawer: function(val) {
            if (!val) {
                this.$router.push({ query: {} }).catch(() => {});
            }
        },
        groupDetailDrawer: function(val) {
            if (!val) {
                this.$router.push({ query: {} }).catch(() => {});
            }
        }
    },
    async mounted() {
        this.$nextTick(() => {
            if (this.$route?.query?.tab) {
                this.tab = this?.$route.query.tab;
            }

            let userStaff = this.$store.getters["staff/getCreatedStaff"];
            let cancel = this.$store.getters["staff/getCancelStaff"];

            if (userStaff) {
                this.onUserCreate(userStaff);
            } else if (cancel) {
                this.onUserCancel(cancel);
            }

        });
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    methods: {
        createUser() {
            this.$router.push({
                path: "/previewcreateuser",
                query: {
                    type: "create"
                }
            });
        },
        createGroup() {
            this.$router.push({
                path: "/previewcreategroup",
                query: {
                    type: "create"
                }
            });
        },
        onUserDetailDrawerFullScreenChange(val) {
            this.userDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onGroupSelected(group) {
            this.selectedGroup = group;
            this.groupDetailDrawer = true;
        },
        onLoading(val) {
            this.isLoading = val;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        onUserFilterChanged(filter) {
            this.usersFilter = {
                ...filter
            };
        },
        onGroupFilterChanged(filter) {
            this.groupsFilter = {
                ...filter
            };
        },
        onUserSelected(user) {
            this.selectedUser = user;
            this.userDetailDrawer = true;
        },
        titleChoices(title) {
            switch (title) {
                case "staff":
                    return this.$t("general.staff");
                case "group":
                    return this.$t("general.group");
                default:
                    return "";
            }
        },
        onUserCreate(user) {
            if (!this.selectedUser) {
                this.selectedUser = {};
            }

            this.selectedUser.node = {
                ...user
            };

            this.userDetailDrawer = true;

            if (user) {
               /*  this.$store.dispatch("staff/updateCreatedStaff", {
                    type: "update",
                    staff: null
                }); */
            }
        },
        onUserCancel(user) {
            this.selectedUser = user;
            this.userDetailDrawer = true;

            if (user) {
                this.$store.dispatch("staff/updateCreatedStaff", {
                    type: "update",
                    staff: null
                });
            }
        },
        cleanRoute() {
            if (this.$route.query.tab) {
                let newQuery = { ...this.$route.query };
                delete newQuery.tab; // Remove the 'tab' parameter

                // Navigate to the same route with the updated query object
                this.$router.replace({ query: newQuery });
                if (this.$route.query.tabDetail) {
                    this.tab = this.$route.query.tabDetail;
                    //after populate the whole details clean the navigate query
                    this.$router.replace({ query: {} });
                }
            }
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.modelTitle {
    font-size: 34px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;
    color: #000000;
}
</style>
