<template>
    <v-container fluid>
        <v-row align="center" class="d-flex justify-center align-center">
            <v-col md="10" cols="12">
                <v-toolbar flat color="transparent">
                    <v-btn icon @click="cancel">
                        <v-icon>arrow_back_ios_new</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-col>
            <v-col md="10" cols="12">
                <v-card outlined class="mx-4 pa-3 rounded-lg">
                    <v-card-title class="mx-2">
                        <v-icon class="backGroundIcon mr-2">people</v-icon>
                        <h4>
                            {{ $t("general.group") + " " + $t("headers.details") }}
                        </h4>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="addGroupForm">
                            <v-row class="mt-0">
                                <v-col cols="5">
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent mx-4">
                                            <span>
                                                {{ $t("general.group_name") + "*" }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        dense
                                        v-model="groupName"
                                        :rules="[rules.required]"
                                        outlined
                                        class="mx-2"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="10" cols="12" class="px-8" v-if="modeType === 'create'">
                <v-expansion-panels flat class="outlined rounded-lg">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row dense>
                                <v-col cols="auto">
                                    <div>
                                        <v-img :src="require('@/assets/warning.png')" width="26"></v-img>
                                    </div>
                                </v-col>
                                <v-col cols="auto">
                                    <h3 class="mt-1">
                                        {{ $t("headers.permissions") }}
                                    </h3>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <GroupPermissionForm
                                :groupPermissions="selectedPermissions"
                                :permissions="permissionsObject"
                                v-on:update-permissions="updateUserGroupPermission"
                            >
                                <template v-slot:permissionTitle> </template>
                            </GroupPermissionForm>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col md="10" cols="12">
                <v-card outlined class="mx-5 pa-3 rounded-lg">
                    <v-card-text>
                        <v-row>
                            <v-btn text @click="cancel">{{ $t("general.cancel") }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" elevation="0" :loading="isLoadingAddGroup" @click="addGroup">{{
                                $t("general.add")
                            }}</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import GroupPermissionForm from "@/components/groups/forms/GroupPermissionForm.vue";
import {
    TripPermissions,
    TrackerPermissions,
    CarrierPermissions,
    UserPermissions,
    TenantPermissions,
    BookingPermissions
} from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "AddGroup",
    components: {
        GroupPermissionForm
    },
    props: ["openAddGroupDialog", "isStaffGroup"],
    data() {
        return {
            error: false,
            isLoadingAddGroup: false,
            groupName: null,
            editMode: false,
            groupId: null,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v => (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.required") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            },
            selectedPermissions: [],
            permissionsObject: [
                {
                    name: this.$t("permissions.trackers_management"),
                    options: [
                        {
                            module: this.$t("home.trackers"),
                            view: {
                                value: TrackerPermissions.VIEW_TRACKER,
                                isChecked: false
                            },
                            create: {
                                value: TrackerPermissions.CREATE_TRACKER,
                                isChecked: false
                            },
                            edit: {
                                value: TrackerPermissions.EDIT_TRACKER,
                                isChecked: false
                            },
                            delete: {
                                value: TrackerPermissions.DELETE_TRACKER,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.trips_management"),
                    options: [
                        {
                            module: this.$t("headers.trips"),
                            view: {
                                value: TripPermissions.VIEW_TRIP,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_partner"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_PARTNER,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_PARTNER,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_PARTNER,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_PARTNER,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_freight"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_FREIGHT,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_FREIGHT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_FREIGHT,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_FREIGHT,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_carrier"),
                            view: {
                                value: CarrierPermissions.VIEW_CARRIER,
                                isChecked: false
                            },
                            create: {
                                value: CarrierPermissions.CREATE_CARRIER,
                                isChecked: false
                            },
                            edit: {
                                value: CarrierPermissions.EDIT_CARRIER,
                                isChecked: false
                            },
                            delete: {
                                value: CarrierPermissions.DELETE_CARRIER,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_freight_alert"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_FREIGHT_ALERT,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_FREIGHT_ALERT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_FREIGHT_ALERT,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_FREIGHT_ALERT,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_freight_tracker"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_FREIGHT_TRACKER,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_FREIGHT_TRACKER,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_FREIGHT_TRACKER,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_FREIGHT_TRACKER,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_document"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_DOCUMENT,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_DOCUMENT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_DOCUMENT,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_DOCUMENT,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_sea_transport"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_SEA_TRANSPORT,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_SEA_TRANSPORT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_SEA_TRANSPORT,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_SEA_TRANSPORT,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_freight_timeline"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_FREIGHT_TIMELINE,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_FREIGHT_TIMELINE,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_FREIGHT_TIMELINE,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_report"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_REPORT,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_REPORT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_REPORT,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_REPORT,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.user_group_management"),
                    options: [
                        {
                            module: this.$t("home.groups"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_USER_GROUP,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_USER_GROUP,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_USER_GROUP,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_USER_GROUP,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("home.users"),
                            view: {
                                value: UserPermissions.VIEW_USER,
                                isChecked: false
                            },
                            create: {
                                value: UserPermissions.CREATE_USER,
                                isChecked: false
                            },
                            edit: {
                                value: UserPermissions.EDIT_USER,
                                isChecked: false
                            },
                            delete: {
                                value: UserPermissions.DELETE_USER,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("home.partners"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_USER_GROUP_PARTNER,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_USER_GROUP_PARTNER,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_USER_GROUP_PARTNER,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_USER_GROUP_PARTNER,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.alerts_management"),
                    options: [
                        {
                            module: this.$t("home.action_groups"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_ACTION_GROUP,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_ACTION_GROUP,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_ACTION_GROUP,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_ACTION_GROUP,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("home.alert_rules"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_ALERT_RULE,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_ALERT_RULE,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_ALERT_RULE,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_ALERT_RULE,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.location_management"),
                    options: [
                        {
                            module: this.$t("home.locations"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_LOCATION,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_LOCATION,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_LOCATION,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_LOCATION,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.settings_management"),
                    options: [
                        {
                            module: this.$t("home.settings"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_SETTINGS,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_SETTINGS,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_SETTINGS,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_SETTINGS,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.partner_management"),
                    options: [
                        {
                            module: this.$t("home.partners"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_PARTNER,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_PARTNER,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_PARTNER,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_PARTNER,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.booking_management"),
                    options: [
                        {
                            module: this.$t("permissions.qx_booking"),
                            view: {
                                value: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                                isChecked: false
                            },
                            create: {
                                value: BookingPermissions.CREATE_BOOKING_ORDER_QX,
                                isChecked: false
                            },
                            edit: {
                                value: BookingPermissions.EDIT_BOOKING_ORDER_QX,
                                isChecked: false
                            },
                            delete: {
                                value: BookingPermissions.DELETE_BOOKING_ORDER_QX,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                }
            ]
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        modeType() {
            let _mode = "create";
            if (this.$route.query?.type) {
                _mode = this.$route.query.type;
            }
            return _mode;
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.modeType === "edit") {
                this.editMode = true;
                this.editUser();
            }
        });
    },
    watch: {},
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.groupName = null;
            this.error = false;
            this.$refs.addGroupForm.resetValidation();
            this.$emit("closeAddGroupDialog");
        },
        editUser() {
            let _groupState = this.$store.getters["group/getCreatedGroup"];
            if (_groupState) {
                this.groupName = _groupState.node.name;
                this.groupId = _groupState.node.id;
            }
        },
        cancel() {
            let _group = this.$store.getters["group/getCreatedGroup"];
            let tab = "group";

            if (this.$route.query?.tab) {
                let [route, tabDetails] = this.$route.query.tab.split("-");
                this.$router.push({
                    path: `/preview${route}s`,
                    query: {
                        tab: "staff",
                        tabDetails: `${tabDetails}}`
                    }
                });
            } else {
                this.$router.push({
                    path: "/previewusers",
                    query: { tab }
                });

                if (_group) {
                    this.$store.dispatch("group/updateCreatedGroup", {
                        type: "cancel",
                        group: _group
                    });
                } else {
                    this.$store.dispatch("group/updateCreatedGroup", {
                        type: "cancel",
                        group: null
                    });
                }
            }
        },
        updateUserGroupPermission(permission) {
            this.selectedPermissions = [...permission];
        },
        addGroup() {
            // validate form input :
            if (!this.$refs.addGroupForm.validate()) return;

            // Initialize Loading :
            this.isLoadingAddGroup = true;

            // Initialize Mutation :
            if (this.isStaffGroup) {
                if (this.editMode) {
                    this.$apollo
                        .mutate({
                            mutation: gql`
                                mutation UpdateStaffGroup($input: UpdateStaffGroupInput!) {
                                    updateStaffGroup(input: $input) {
                                        coldchaStaffGroup {
                                            id
                                            name
                                            group {
                                                userSet {
                                                    totalCount
                                                }
                                            }
                                        }
                                    }
                                }
                            `,
                            variables: {
                                input: {
                                    id: this.groupId,
                                    name: this.groupName
                                }
                            }
                        })
                        .then(response => {
                            this.$emit("refreshGroupsTable");
                            this.$emit("updatedGroup", response.data.updateStaffGroup);

                            const payload = {
                                color: "blue",
                                message: this.$t("alert_rules.group_saved")
                            };
                            this.$store.dispatch("snackbar/showMessage", payload);
                        })
                        .catch(error => {
                            console.log(error);
                            this.isLoadingEditGroup = false;
                        });
                } else {
                    this.$apollo
                        .mutate({
                            mutation: gql`
                                mutation CreateStaffGroup($input: CreateStaffGroupInput!) {
                                    createStaffGroup(input: $input) {
                                        coldchaStaffGroup {
                                            id
                                            name
                                            group {
                                                userSet {
                                                    totalCount
                                                }
                                            }
                                        }
                                    }
                                }
                            `,
                            variables: {
                                input: {
                                    name: this.groupName
                                }
                            }
                        })
                        .then(response => {
                            // Emit event to tabel data and load new groups //
                            this.$emit("refreshDataTable");
                            this.$emit("item-created", response.data.createStaffGroup);

                            const payload = {
                                color: "blue",
                                message: this.$t("alert_rules.group_saved")
                            };
                            this.$store.dispatch("snackbar/showMessage", payload);
                        })
                        .catch(error => {
                            if (error.message == "INVALID_REFERENCE_NUMBER") this.error = true;
                            this.isLoadingGroupAdd = false;
                        });
                }
            } else {
                if (this.editMode) {
                    this.$apollo
                        .mutate({
                            mutation: gql`
                                mutation UpdateTenantUserGroup($input: UpdateTenantUserGroupInput!) {
                                    updateTenantUserGroup(input: $input) {
                                        tenantStaffGroup {
                                            id
                                            name
                                            permissions
                                            group {
                                                id
                                                userSet {
                                                    totalCount
                                                }
                                            }
                                        }
                                    }
                                }
                            `,
                            variables: {
                                input: {
                                    id: this.groupId,
                                    name: this.groupName
                                }
                            }
                        })
                        .then(response => {
                            // Emit event to tabel data and load new groups //
                            let tab = "group";
                            this.$router.push({
                                path: "/previewusers",
                                query: { tab }
                            });

                            this.$store.dispatch("group/updateCreatedGroup", {
                                type: "update",
                                group: response.data.updateTenantUserGroup.tenantStaffGroup
                            });

                            const payload = {
                                color: "blue",
                                message: this.$t("alert_rules.group_saved")
                            };
                            this.$store.dispatch("snackbar/showMessage", payload);
                        })
                        .catch(error => {
                            if (error.message == "INVALID_REFERENCE_NUMBER") this.error = true;
                            this.isLoadingEditGroup = false;
                        });
                } else {
                    this.$apollo
                        .mutate({
                            mutation: gql`
                                mutation CreateTenantUserGroup($input: CreateTenantUserGroupInput!) {
                                    createTenantUserGroup(input: $input) {
                                        tenantStaffGroup {
                                            id
                                            name
                                            permissions
                                            group {
                                                id
                                                userSet {
                                                    totalCount
                                                }
                                            }
                                        }
                                    }
                                }
                            `,
                            variables: {
                                input: {
                                    tenantId: this.me.tenant.id,
                                    name: this.groupName,
                                    permissions: this.selectedPermissions
                                }
                            }
                        })
                        .then(response => {
                            // Emit event to tabel data and load new groups //

                            let id = response.data.createTenantUserGroup.tenantStaffGroup.id;
                            let tab = "group";

                            if (this.$route.query?.tab) {
                                let [route, tabDetails] = this.$route.query.tab.split("-");
                                this.$router.push({
                                    path: `/preview${route}s`,
                                    query: {
                                        tab: "staff",
                                        tabDetails: `${tabDetails}-${id}`
                                    }
                                });
                            } else {
                                this.$router.push({
                                    path: "/previewusers",
                                    query: { tab }
                                });
                            }

                            this.$store.dispatch("group/updateCreatedGroup", {
                                type: "create",
                                group: response.data.createTenantUserGroup.tenantStaffGroup
                            });

                            const payload = {
                                color: "blue",
                                message: this.$t("alert_rules.group_saved")
                            };
                            this.$store.dispatch("snackbar/showMessage", payload);
                        })
                        .catch(error => {
                            if (error.message == "INVALID_REFERENCE_NUMBER") this.error = true;
                            this.isLoadingGroupAdd = false;
                        });
                }
            }
        }
    }
};
</script>
<style scoped>
.custom-height {
    height: 35px;
}

.v-input--selection-controls {
    margin-top: 0;
}
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}

.labelText {
    font-size: 32px;
    font-weight: 700;
    color: #0e0d35;
    line-height: 38.4px;
}

.titleText {
    font-size: 12px;
    font-weight: 700;
    color: #555464;
    line-height: 14.4px;
}
.backGroundIcon {
    color: #0e0d35;
}
.outlined {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}
</style>
