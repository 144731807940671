import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-2 mb-5 pb-5",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,[_c('div',{staticStyle:{"position":"absolute","right":"25px"}},[_c('FilterMenu',{staticClass:"mr-4",attrs:{"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display_filters'),"choices":_vm.headerChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1),_c(VDataTable,{attrs:{"headers":_vm.dynamicHeaders,"items":_vm.trips.edges,"item-key":"node.id","loading-text":_vm.$t('loading.loading1'),"loading":_vm.$apollo.queries.trips.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","mobile-breakpoint":0,"loader-height":"2","no-data-text":_vm.$t('loading.no_available')},scopedSlots:_vm._u([{key:"item.node.plannedDepartureDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.plannedDepartureDate)))])]}},{key:"item.node.plannedArrivalDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.plannedArrivalDate)))])]}}])})],1),_c(VCardActions,{staticClass:"justify-center"},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.trips.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }