import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VAutocomplete,{ref:"autocomplete",attrs:{"loading":_vm.$apollo.queries.alertRules.loading,"items":_vm.alertRules.edges,"no-data-text":_vm.$t('loading.no_available'),"hide-details":_vm.hideDetails,"label":_vm.label,"outlined":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":"","dense":"","menu-props":{
            maxHeight: '200px'
        }},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [(_vm.activeSlot)?_c(VDivider,{staticClass:"mt-4 append-divider"}):_vm._e(),(_vm.activeSlot)?_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_vm._t("custom-action")],2)],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.SelectedAlerts),callback:function ($$v) {_vm.SelectedAlerts=$$v},expression:"SelectedAlerts"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }