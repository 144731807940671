import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.tenantUsers.edges,"loading":_vm.$apollo.queries.tenantUsers.loading,"no-data-text":_vm.$t('loading.no_available'),"label":_vm.$t('headers.select_user') + '(s)',"outlined":"","deletable-chips":"","small-chips":"","item-text":"node.user.fullName","item-value":"node.user.id","multiple":"","menu-props":{
                    height: '200px',
                    offsetY: true
                }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 caption"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(data.item.node.user.fullName)+" ")])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.createNewUser}},[_vm._v(" "+_vm._s(_vm.$t("partners.add_user1"))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)],1),_c('AddUserDialog',{attrs:{"openAddUserDialog":_vm.openCreateNewUserDialog},on:{"refreshDataTable":function($event){return _vm.$apollo.queries.tenantUsers.refresh()},"user-Added":_vm.userCreated,"closeAddUserDialog":function($event){_vm.openCreateNewUserDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }