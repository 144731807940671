<template>
    <v-container fluid class="pa-0">
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col>
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                            align-top
                        "
                    >
                        {{ $t("bookings.booking_templates") }}
                    </h2>
                </v-col>
                <v-col cols="auto" class="px-0"> </v-col>
            </v-row>
        </div>
        <!-- TEMPLATES FILTER/TOOLBAR -->
        <BookingTemplatesFilter
            class="mx-3"
            ref="bookingTemplateFilter"
            v-on:filter-changed="onFilterChanged"
            @template-created="onTemplateCreated"
            @select-mode="changeSelectMode"
            @delete-selected="onDeleteSelectedTemplates"
        >
        </BookingTemplatesFilter>
        <!-- TABLE -->
        <BookingTemplatesTable
            ref="bookingTemplatesTable"
            :bookingTemplatesFilter="bookingTemplatesFilter"
            class="mx-3 mt-3 mb-3"
            v-on:click:row="onBookingTemplateSelected"
            :selectMode="selectMode"
            @deleted-templates="deleteFinished"
        ></BookingTemplatesTable>
        <!-- DETAIL DRAWER -->
        <CreateAndEditBooking
            ref="createTemplate"
            :editMode="true"
            :templateMode="true"
            :bookingOrder.sync="selectedBookingTemplate"
            v-on:template-created="onTemplateCreated"
        ></CreateAndEditBooking>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import BookingTemplatesFilter from "@/components/bookings/bookingTemplates/BookingTemplatesFilter.vue";
import BookingTemplatesTable from "@/components/bookings/bookingTemplates/BookingTemplatesTable.vue";
import CreateAndEditBooking from "@/components/bookings/create/CreateAndEditBooking.vue";

export default {
    title: "Contour Booking Order Templates",
    components: {
        BookingTemplatesFilter,
        BookingTemplatesTable,
        CreateAndEditBooking
    },
    data() {
        return {
            selectMode: false,
            bookingTemplateDetailDrawer: false,
            bookingTemplatesFilter: {
                first: 10
            },
            goTopShow: false,
            isLoading: false,
            selectedBookingTemplate: null
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },
    watch: {},
    created() {
        //TODO: Permission check
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {},
    methods: {
        deleteFinished() {
            this.$refs.bookingTemplateFilter.deletedItem();
        },
        onDeleteSelectedTemplates() {
            this.$refs.bookingTemplatesTable.deleteSelected();
            this.changeSelectMode(false);
        },
        changeSelectMode(val) {
            this.selectMode = val;
        },
        onLoading(val) {
            this.isLoading = val;
        },
        onFilterChanged(filter) {
            this.bookingTemplatesFilter = filter;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        loadMore() {
            this.isLoading = false;
        },
        onBookingTemplateSelected(bookingTemplate) {
            this.selectedBookingTemplate = bookingTemplate;
            this.$refs.createTemplate.editAndPopulateBookingTemplate(
                this.selectedBookingTemplate
            );
            this.bookingTemplateDetailDrawer = true;
        },
        onTemplateCreated() {
            this.$refs.bookingTemplatesTable.$apollo.queries.bookingOrderTemplatesQx.refetch();
        }
    }
};
</script>

<style scoped></style>
