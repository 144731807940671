<template>
    <v-container fluid class="pa-0 ma-0">
        <v-form
            v-model="formIsValid"
            ref="locationForm"
        >
            <v-row>
                <v-col 
                    cols="12"
                    class="pr-0" 
                    v-if="trip.type === 'Origin'"
                > 
                    <v-card flat>
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-row dense>
                                        <v-col
                                            cols="auto"
                                            class="textBodyContent labelContent"
                                        >
                                            <span>
                                                {{ $t("trips.origin") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete
                                        class="textBodyContent inputContent mb-n2"
                                        v-model="trip.origin"
                                        :loading="
                                            $apollo.queries.tenantLocations
                                                .loading
                                        "
                                        :items="
                                            tenantLocations
                                                ? tenantLocations.edges
                                                : []
                                        "
                                        :no-data-text="
                                            $t('loading.no_available')
                                        "
                                        item-text="node.name"
                                        item-value="node.id"
                                        outlined
                                        clearable
                                        dense
                                    >
                                        <template v-slot:item="data">
                                            <v-row class="ma-0 body-2" dense>
                                                <v-col
                                                    cols="9"
                                                    class="text-truncate pl-0 ml-0"
                                                >
                                                    {{ data.item.node.name }}
                                                </v-col>
                                                <v-col cols="3">
                                                    <span
                                                        class="grey--text mr-0 float-right"
                                                        ><i>{{
                                                            selectLabelByType(
                                                                data.item.node.locationType.toLowerCase()
                                                            )
                                                        }}</i></span
                                                    >
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template #append-item>
                                            <v-divider
                                                class="mt-4 append-divider"
                                            ></v-divider>
                                            <v-list-item dense class="append pa-0">
                                                <v-list-item-content>
                                                    <v-btn
                                                        small
                                                        color="primary"
                                                        @click="
                                                            openCreateLocationDialog(
                                                                'origin'
                                                            )
                                                        "
                                                        class="text-capitalize textBodyContent"
                                                    >
                                                        {{ $t(
                                                            "general.create_location"
                                                        )  }}
                                                    </v-btn>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-row dense>
                                        <v-col
                                            cols="auto"
                                            class="textBodyContent labelContent"
                                        >
                                            <span>
                                                {{
                                                    $t(
                                                        "date.departure_date"
                                                    ) + "*"
                                                }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <DateTimeComponent
                                        v-model="trip.plannedDepartureDate"
                                        :icon="'calendar_month'"
                                        :rules="[rules.requiredDateTime]"
                                        :outlined="true"
                                        :dense="true"
                                        :defaultTime="
                                            trip.plannedDepartureTime || '00:00'
                                        "
                                        class="inputContent"
                                    >
                                    </DateTimeComponent>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col 
                    class="pr-0"
                    cols="12"
                    v-if="trip.type === 'Destination'"
                > 
                    <v-card flat>
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-row dense>
                                        <v-col
                                            cols="auto"
                                            class="textBodyContent labelContent"
                                        >
                                            <span>
                                                {{ $t("trips.destination") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete
                                        v-model="trip.destination"
                                        class="textBodyContent inputContent mb-n2"
                                        :loading="
                                            $apollo.queries.tenantLocations
                                                .loading
                                        "
                                        :no-data-text="
                                            $t('loading.no_available')
                                        "
                                        :items="
                                            tenantLocations
                                                ? tenantLocations.edges
                                                : []
                                        "
                                        item-text="node.name"
                                        item-value="node.id"
                                        outlined
                                        clearable
                                        hide-selected
                                        dense
                                    >
                                        <template v-slot:item="data">
                                            <v-row class="ma-0 body-2">
                                                <v-col
                                                    cols="9"
                                                    class="text-truncate pl-0 ml-0"
                                                >
                                                    {{ data.item.node.name }}
                                                </v-col>
                                                <v-col cols="3">
                                                    <span
                                                        class="grey--text mr-0 float-right"
                                                        ><i>{{
                                                            selectLabelByType(
                                                                data.item.node.locationType.toLowerCase()
                                                            )
                                                        }}</i></span
                                                    >
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template #append-item>
                                            <v-divider
                                                class="mt-4 append-divider"
                                            ></v-divider>
                                            <v-list-item dense class="append">
                                                <v-list-item-content>
                                                    <v-btn
                                                        small
                                                        color="primary"
                                                        @click="
                                                            openCreateLocationDialog(
                                                                'destination'
                                                            )
                                                        "
                                                        class="text-capitalize textBodyContent"
                                                    >
                                                        {{ $t(
                                                            "general.create_location"
                                                        )  }}
                                                    </v-btn>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-row dense>
                                        <v-col
                                            cols="auto"
                                            class="textBodyContent labelContent"
                                        >
                                            <span>
                                                {{ $t("date.arrival_date") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <DateTimeComponent
                                        v-model="trip.plannedArrivalDate"
                                        :icon="'calendar_month'"
                                        :rules="
                                            trip.plannedArrivalDate !== null &&
                                            trip.plannedArrivalDate.length > 0
                                                ? [rules.checkDate]
                                                : []
                                        "
                                        :outlined="true"
                                        :dense="true"
                                        :defaultTime="
                                            trip.plannedArrivalTime || '00:00'
                                        "
                                        class="inputContent"
                                    >
                                    </DateTimeComponent>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
            
                </v-col>
            </v-row>
            <AddLocation
                :openAddLocationDialog="openAddLocation"
                v-on:closeMap="openAddLocation = false"
                v-on:updateLocationsTable="
                    $apollo.queries.tenantLocations.refetch()
                "
                v-on:updateLocation="updateLocation"
            >
            </AddLocation>
        </v-form>
    </v-container>
</template>
<script>
    import AddLocation from "@/components/locations/create/AddLocation.vue";
    import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
    import helper from "@/utils/helper.js";
    import gql from "graphql-tag";
    import moment from "moment";


    export default {
        components:{
            AddLocation,
            DateTimeComponent
        },
        props:{
            value: {
                type: Object,       
            },
            isValid: {
                type: Boolean,
                default: false
            },
        },
        apollo: {
            tenantLocations: {
                query: gql`
                    query tenantLocations(
                        $search: String
                        $first: Int
                        $last: Int
                        $before: String
                        $after: String
                        $tenantPartnerNameIcontains: String
                        $nameIcontains: String
                        $cityIcontains: String
                        $countryIcontains: String
                    ) {
                        tenantLocations(
                            search: $search
                            first: $first
                            last: $last
                            before: $before
                            after: $after
                            orderBy: "name"
                            tenantPartner_Name_Icontains: $tenantPartnerNameIcontains
                            name_Icontains: $nameIcontains
                            city_Icontains: $cityIcontains
                            country_Icontains: $countryIcontains
                            locationType_In: [
                                "tenant_location"
                                "partner_location"
                                "private_location"
                            ]
                        ) {
                            pageInfo {
                                hasNextPage
                                hasPreviousPage
                                startCursor
                                endCursor
                            }
                            edges {
                                node {
                                    id
                                    name
                                    locationType
                                    isTrackerStockLocation
                                    fullAddress
                                    city
                                    state
                                    country
                                }
                            }
                        }
                    }
                `,
                variables() {},
                fetchPolicy: "cache-and-network",
                nextFetchPolicy: "cache-first",
                debounce: 800,
                update: data => {
                    return data.tenantLocations;
                },
                skip: false
            }
        },
        data() {
            return {
                openAddLocation: false,
                hide: false,
                locationType: "",
                rules: {
                    required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                    requiredDateTime: v => {
                        if (v === null || v === undefined || v === "") {
                            return this.$t("rules.required");
                        }
                        let [date, time] = v.split(" ");
                        return (
                            (!this.isEmpty(date) && !this.isEmpty(time)) ||
                            this.$t("rules.required")
                        );
                    },
                    minLength: len => v =>
                        (v || "").length >= len ||
                        this.$t("rules.invalid") + ` ${len}`,
                    maxLength: len => v =>
                        (v || "").length <= len || this.$t("rules.too_long"),
                    alertIsInvalid: v =>
                        v === false || this.$t("rules.ref_no_exists"),
                    //check if planned arrival date is not less than planned departure date
                    checkDate: () => {
                        //let _selectedDate = new Date(v)
                        if (
                            this.trip.plannedDepartureDate === null ||
                            this.trip.plannedDepartureDate.length < 1
                        ) {
                            return true;
                        }
                        if (
                            this.trip.plannedArrivalDate === null ||
                            this.trip.plannedArrivalDate.length < 1
                        ) {
                            return true;
                        }

                        let [dateA, timeA] = this.trip.plannedDepartureDate.split(
                            " "
                        );
                        let [dateB, timeB] = this.trip.plannedArrivalDate.split(
                            " "
                        );

                        if (this.isEmpty(dateA) || this.isEmpty(timeA)) {
                            return true;
                        }

                        //dateTime A
                        let _newDateA = moment(dateA, helper.getDateFormat());
                        let _DateA = moment(_newDateA, [
                            "DD/MM/YYYY",
                            "YYYY/MM/DD",
                            "MM/DD/YYYY"
                        ]).toDate();
                        let _dateFormatA = moment(_DateA).format("YYYY-MM-DD");

                        //dateTime B
                        let _newDateB = moment(dateB, helper.getDateFormat());
                        let _DateB = moment(_newDateB, [
                            "DD/MM/YYYY",
                            "YYYY/MM/DD",
                            "MM/DD/YYYY"
                        ]).toDate();
                        let _dateFormatB = moment(_DateB).format("YYYY-MM-DD");

                        let dateTimeA = _dateFormatA + " " + timeA;
                        let dateTimeB = _dateFormatB + " " + timeB;

                        let validDate = moment(dateTimeA).isSameOrBefore(dateTimeB);

                        return validDate || this.$t("rules.diff");
                    }
                }
            };
        },
        computed:{
            trip: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                }
            },
            formIsValid:{
                get(){
                    return this.isValid;
                },
                set(val){
                    this.$emit("update:isValid", val);
                }
            }
        },
        methods: {
            resetForm(){
                this.$refs.loadForm.reset();
            },
            selectLabelByType(val) {
                if (val === "tenant_location") return this.$t("alert_rules.public");
                if (val === "partner_location") return this.$t("home.partner");
                if (val === "private_location") return "";
            },
            openCreateLocationDialog(type) {
                this.openAddLocation = true;
                this.locationType = type;
            },
            switchLocation() {
                let _origin = this.trip.origin;
                let _destination = this.trip.destination;
                this.trip.origin = _destination;
                this.trip.destination = _origin;
                let _plannedDepartureDate = this.trip.plannedDepartureDate;
                let _plannedArrivalDate = this.trip.plannedArrivalDate;
                this.trip.plannedDepartureDate = _plannedArrivalDate;
                this.trip.plannedArrivalDate = _plannedDepartureDate;
            },
            updateLocation(id) {
                if (this.locationType === "origin") {
                    this.trip.origin = id;
                } else if (this.locationType === "destination") {
                    this.trip.destination = id;
                }
            },
            isEmpty(val) {
            if (
                val === null ||
                val === undefined ||
                val === "" ||
                val === "null" ||
                val === "undefined" ||
                val === "NaN" ||
                val === "Invalid date-undefined-undefined"
            ) {
                return true;
            } else {
                return false;
            }
        }
        },
    };
</script>
<style scoped>
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}   

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}

.textBodyContent {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}

.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
.inputContent {
    height: 52px;
}
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay2 {
    padding-left: 36px;
    padding-right: 36px;
}
.contentDisplay3 {
    padding-left: 70px;
    padding-right: 70px;
}
</style>
