<template>
    <v-dialog max-width="600" v-model="openAddUserGroupDialog" persistent>
        <v-card>
            <!--- ICON AND TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("users.add_user") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pt-3">
                <v-form ref="userGroupForm">
                    <v-col cols="12" class="mx-0 px-0 mt-6">
                        <v-autocomplete
                            dense
                            v-model="selectedGroup"
                            :items="tenantUserGroups.edges"
                            :no-data-text="$t('loading.no_available')"
                            :label="$t('users.select_group') + '*'"
                            outlined
                            hide-selected
                            hide-details
                            deletable-chips
                            small-chips
                            item-text="node.name"
                            item-value="node.id"
                            multiple
                            class="mx-3"
                        >
                            <template #append-item>
                                <v-divider class="mt-4 append-divider"></v-divider>
                                <v-list-item dense class="append">
                                    <v-list-item-content>
                                        <v-btn color="primary" @click="createGroup()" block class="mt-4">
                                            {{ $t("general.create_group") }}
                                        </v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-form>
            </v-card-text>
            <!--- ACTION BUTTONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn text rounded @click="closeAndResetInput">{{ $t("general.cancel") }}</v-btn>
                <v-btn
                    :loading="isLoadingAddUserGroup"
                    @click="addTenantUserGroup"
                    color="primary"
                    :rounded="borderRounded"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import { h } from "vue";
// import AddUserDialog from "@/components/users/create/AddUserDialog.vue";

export default {
    name: "AddUserGroupDialog",
    props: ["openAddUserGroupDialog", "user", "userGroups", "rounded"],
    components: {
        // AddUserDialog
    },
    apollo: {
        tenantUserGroups: {
            query: gql`
                query TenantUserGroups($tenantId: ID) {
                    tenantUserGroups(tenantId: $tenantId) {
                        totalCount
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.me.tenant.id
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUserGroups;
            },
            skip: false
        }
    },
    data() {
        return {
            /**************************************/
            /* --------- FORM VARIABLES --------- */
            /**************************************/
            selectedGroup: [],
            tenantUserGroups: { edges: [] },
            /**********************************************/
            /* --------- DIALOGS/LOADINGS/MENUS --------- */
            /**********************************************/
            isLoadingAddUserGroup: false,
            /*****************************/
            /* --------- RULES --------- */
            /*****************************/
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v => /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v => (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        borderRounded() {
            let value = true;

            if (this.rounded == false) {
                value = false;
            }

            return value;
        }
    },
    watch: {
        userGroups: {
            handler() {
                this.userGroups.edges.forEach(item => {
                    this.selectedGroup.push(item.node.id);
                });
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        if (this.$route?.query?.tabDetails) {
            const [details, id] = this.$route.query.tabDetails.split("-");
            if (details === "group" && id) {
                this.selectedGroup.push(id);
            }
        }
    },
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        createGroup() {
            this.$store.dispatch("staff/updateCreatedStaff", {
                type: "cancel",
                staff: this.user
            });

            this.$router.push({
                path: "/previewcreategroup",
                query: {
                    type: "create",
                    tab: "user-group"
                }
            });
        },
        closeAndResetInput() {
            this.$refs.userGroupForm.resetValidation();
            this.$emit("closeAddDialog");
        },
        /* ---> TENANT GROUP USER MUTATION <--- */
        addTenantUserGroup() {
            // validate form input :
            if (!this.$refs.userGroupForm.validate()) return;
            // Initialize Loading :
            this.isLoadingAddUserGroup = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTenantUserGroupGroups($input: UpdateTenantUserGroupGroupsInput!) {
                            updateTenantUserGroupGroups(input: $input) {
                                tenantUserGroups {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.user.node.user.id,
                            tenantUserGroupIds: this.selectedGroup
                        }
                    }
                })
                .then(() => {
                    // // Stop Loading //
                    // this.isLoadingAddUserGroup = false;
                    // Emit event to signal user is added and reload table showing users //
                    this.$emit("refreshDataTable");
                    // Close the dialog and reset form fields //
                    this.closeAndResetInput();
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.isLoadingAddUserGroup = false;
                });
        },
        /* clearRouteQuery(router) {
            //tabDetails
            if (this.$route?.query?.tabDetails) {
                router.replace({
                    query: {
                        tabDetails: null
                    }
                });
            }
        } */
    }
};
</script>
<style scoped>
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
.inputContent {
    height: 52px;
}
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay2 {
    padding-left: 36px;
    padding-right: 36px;
}
.contentDisplay3 {
    padding-left: 70px;
    padding-right: 70px;
}
</style>
