import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text justify-center"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("verified_user")]),_vm._v(" "+_vm._s(_vm.$t("general.enable_2fa"))+" ")],1),(_vm.isGeneratingSecretKey)?_c(VCardText,[_c(VCard,{staticClass:"mx-auto text-center",attrs:{"color":"transparent","flat":"","height":"20vh","width":"200"}},[_c(VResponsive,{attrs:{"min-height":"10vh"}}),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t("general.one_moment"))+" ")]),_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VProgressLinear,{attrs:{"color":"primary accent-4","indeterminate":"","rounded":"","height":"5"}})],1)],1)],1)],1):_c(VCardText,{staticClass:"pt-5 pb-3"},[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("general.steps"))+" ")]),_c('p',{staticClass:"subtitle-2 text-center mt-5"},[_vm._v(" "+_vm._s(_vm.$t("general.steps1"))+" ")]),_c(VRow,{staticClass:"d-flex justify-space-around"},[(_vm.secretKey2FA)?_c(VCard,{staticClass:"pa-3 elevation-0"},[_c('qrcode-vue',{attrs:{"value":_vm.tfaQrCodeValue,"size":"300","level":"H"}})],1):_vm._e()],1),_c('p',{staticClass:"subtitle-2 text-center mt-5"},[_vm._v(" "+_vm._s(_vm.$t("general.steps2"))+" ")]),_c(VForm,{ref:"enable2FAForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{staticClass:"justify-space-around"},[_c(VCol,{staticClass:"col-6"},[_c(VTextField,{staticClass:"headline",attrs:{"counter":"6","rules":[
                                _vm.rules.required,
                                _vm.rules.minLength(6),
                                _vm.rules.maxLength(6)
                            ],"label":_vm.$t('general.code'),"type":"number","outlined":"","error-messages":_vm.tfaCodeError,"hint":_vm.$t('general.authenticator'),"autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.enable2FA()}},model:{value:(_vm.tfaCode),callback:function ($$v) {_vm.tfaCode=$$v},expression:"tfaCode"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"pb-5"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.closeEnable2FADialog}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"outlined":"","rounded":"","color":"primary","disabled":!_vm.valid,"loading":_vm.isVerifying2FA},on:{"click":function($event){return _vm.enable2FA()}}},[_vm._v(_vm._s(_vm.$t("general.enable")))]),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }