import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.openAddActionGroupDialog),callback:function ($$v) {_vm.openAddActionGroupDialog=$$v},expression:"openAddActionGroupDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t("alert_rules.add_action_group"))+" ")],1),_c(VCardText,{staticClass:"pa-4"},[_c(VForm,{ref:"actionGroupForm"},[_c(VCol,{staticClass:"pa-0 ma-0 mt-6",attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"dense":"","items":_vm.tenantActionGroups.edges,"no-data-text":_vm.$t('loading.no_available'),"label":_vm.$t('alert_rules.select_action_group') + ' *',"outlined":"","hide-details":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":""},model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}})],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end pb-5 pr-5"},[_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"loading":_vm.isLoadingAddActionGroup,"color":"primary","rounded":"","outlined":"","dense":""},on:{"click":_vm.createTenantActionGroup}},[_vm._v(_vm._s(_vm.$t("general.add")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }