<template>
    <v-container fluid class="pa-0">
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <!-- <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col>
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                            align-top
                        "
                    >
                        Groups
                    </h2>
                </v-col>
                <v-col cols="auto" class="px-0"> </v-col>
            </v-row>
        </div> -->

        <!-- GROUPS FILTER/TOOLBAR -->
        <GroupsFilter
            class="mx-3"
            v-on:updateGroupTable="refetchTableData"
            v-on:deletedGroup="selectedGroups = []"
            :selectedGroups.sync="selectedGroups"
            v-on:filter-changed="onFilterChanged"
            :isStaffGroup="true"
        ></GroupsFilter>

        <!-- TABLE -->
        <StaffGroupsTable
            ref="groupTable"
            v-on:selectedCheckbox="sendSelectedBoxes"
            :groupsFilter="groupsFilter"
            :selectedGroups="selectedGroups"
            v-on:click:row="onGroupSelected"
            class="mx-1"
        ></StaffGroupsTable>

        <!-- DETAIL DRAWER -->
        <v-navigation-drawer
            v-model="groupDetailDrawer"
            :width="groupDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <StaffGroupDetail
                    ref="groupDetail"
                    v-if="selectedGroup"
                    :key="selectedGroup.node.id"
                    :group="selectedGroup"
                    :isFullScreen="isGroupDetailDrawerFullScreen"
                    v-on:changeFullScreen="onGroupDetailDrawerFullScreenChange"
                    v-on:refreshDataTable="refetchTableData"
                    v-on:close="groupDetailDrawer = false"
                ></StaffGroupDetail>
            </div>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import GroupsFilter from "@/components/groups/GroupsFilter.vue";
import StaffGroupsTable from "@/components/staff/groups/StaffGroupsTable.vue";
import StaffGroupDetail from "@/components/staff/groups/StaffGroupDetail.vue";

export default {
    title: "Groups",
    components: {
        GroupsFilter,
        StaffGroupsTable,
        StaffGroupDetail
    },

    apollo: {},

    data() {
        return {
            searchInput: null,
            shouldStickyFilter: false,

            groupDetailDrawer: false,
            groupDetailFullScreen: false,
            groupsFilter: {
                first: 20
            },
            goTopShow: false,
            isLoading: false,
            menu: false,
            selectedGroup: null,
            selectedGroups: null,
            tutorialDialog: false
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        groupDetailDrawerWidth() {
            if (this.groupDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isGroupDetailDrawerFullScreen() {
            return this.groupDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },

    watch: {},

    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },

    mounted() {
        this.onResize();
    },

    methods: {
        onGroupSelected(group) {
            this.selectedGroup = group;
            this.groupDetailDrawer = true;
        },

        onGroupDetailDrawerFullScreenChange(val) {
            this.groupDetailFullScreen = val;
        },

        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },

        onLoading(val) {
            this.isLoading = val;
        },

        onFilterChanged(filter) {
            this.groupsFilter = filter;
        },

        refetchTableData() {
            this.$refs.groupTable.$apollo.queries.groups.refetch();
        },

        sendSelectedBoxes(e) {
            this.selectedGroups = e;
        },

        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        }
    }
};
</script>
<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
