import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5 mb-5"},[_c(VCard,{staticClass:"py-4 mt-4",attrs:{"elevation":"5","outlined":""}},[_c(VRow,{staticClass:"px-5 d-flex align-center",attrs:{"align":"start"}},[_c(VCol,{attrs:{"cols":"12","sm":"5","lg":"5"}},[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"small":"","outlined":"","color":"primary"},on:{"click":_vm.expand}},[_vm._v(" "+_vm._s(_vm.isAllExpanded ? "- " + _vm.$t("general.collapse") : "+ " + _vm.$t("general.expand"))+" ")])],1)],1)],1),_c(VSpacer),_c(VCol,{staticClass:"py-2 pr-4 text-right",attrs:{"cols":"12","sm":"5","lg":"4"}},[_c(VRow,{staticClass:"d-flex align-center justify-end"},[_c(VBtn,{staticClass:"mr-1",attrs:{"small":"","outlined":"","rounded":"","color":"primary","loading":_vm.isLoadingUpdateGroupPermissions,"disabled":_vm.enableOrDisablePermissionBtn},on:{"click":_vm.updateUserGroupPermission}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("add_box")]),_vm._v(" "+_vm._s(_vm.$t("general.save_permissions"))+" ")],1)],1)],1)],1)],1),_c(VExpansionPanels,{staticClass:"mt-2 mb-5 pb-5",attrs:{"multiple":"","focusable":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.computedPermissionsObject),function(item,index){return _c(VExpansionPanel,{key:index},[_c(VExpansionPanelHeader,[_c('h5',[_vm._v(_vm._s(item.name))])]),_c(VExpansionPanelContent,{staticClass:"px-0"},[_c(VLazy,[_c('GroupPermissionTable',{attrs:{"tableData":item.options,"isLoadingTable":_vm.$apollo.queries.groupPermissions.loading,"permissions":_vm.permissions},on:{"update:tableData":function($event){return _vm.$set(item, "options", $event)},"update:table-data":function($event){return _vm.$set(item, "options", $event)},"update:permissions":function($event){_vm.permissions=$event},"updateTableData":_vm.updateSelectedPermissions}})],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }