<template>
    <v-dialog v-model="exportDataDialog" max-width="500" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="pa-2 mx-1"
                label
                outlined
                rounded
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon color="primary" class="mr-2">download</v-icon>
                {{ $t("general.download") }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-2">download</v-icon>
                {{ $t("trackers.tracker_data") }}
            </v-card-title>
            <v-card-text>
                <template>
                    <v-row class="mt-5 align-center d-flex justify-center">
                        <v-radio-group v-model="allRecords" row>
                            <v-radio
                                :label="$t('general.records')"
                                :value="true"
                            ></v-radio>
                            <v-radio
                                :label="$t('general.filter')"
                                :value="false"
                            ></v-radio>
                        </v-radio-group>
                    </v-row>
                    <div v-if="!allRecords">
                        <v-row class="mt-5 align-center">
                            <v-col cols="4">
                                <span class="ml-5 subtitle-1"
                                    >{{ $t("general.start_date") }}:</span
                                >
                            </v-col>
                            <v-col cols="6"
                                ><DateComponent
                                    v-model="formStartTime"
                                    :label="$t('general.start_date')"
                                    :icon="'calendar_month'"
                                    :outlined="true"
                                    :dense="true"
                                    :hideDetails="true"
                                ></DateComponent
                            ></v-col>
                        </v-row>
                        <v-row class="mt-5 align-center">
                            <v-col cols="4">
                                <span class="ml-5 subtitle-1"
                                    >{{ $t("general.end_date") }}:</span
                                >
                            </v-col>
                            <v-col cols="6"
                                ><DateComponent
                                    v-model="formEndTime"
                                    :label="$t('general.end_date')"
                                    :icon="'calendar_month'"
                                    :outlined="true"
                                    :dense="true"
                                    :hideDetails="true"
                                ></DateComponent
                            ></v-col>
                        </v-row>
                    </div>

                    <v-row class="mt-5 align-center">
                        <v-col cols="6">
                            <span class="ml-5 subtitle-1"
                                >{{ $t("general.file") }}:</span
                            >
                        </v-col>
                        <v-col cols="6"
                            ><v-select
                                auto-select-first
                                :items="fileFormatsAvailable"
                                v-model="fileFormat"
                            >
                            </v-select
                        ></v-col>
                    </v-row>
                </template>
            </v-card-text>

            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn
                    text
                    @click="closeAndResetInput"
                    rounded
                    :disabled="isLoadingFile"
                    >{{ $t("general.close") }}</v-btn
                >
                <v-btn
                    color="primary"
                    text
                    :loading="isLoadingFile"
                    @click="submitExportData"
                    rounded
                    outlined
                    dense
                >
                    {{ $t("general.download") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { write, utils } from "xlsx";
import gql from "graphql-tag";
import DateComponent from "../../base/DateComponent.vue";

export default {
    props: {
        tracker: {
            type: Object,
            required: true
        },
        filterStartTime: {},
        filterEndTime: {}
    },
    components: {
        DateComponent
    },
    data() {
        return {
            exportDataDialog: false,
            isLoadingFile: false,
            formStartTime: null,
            formEndTime: null,
            fileData: null,
            allRecords: true,
            fileFormatsAvailable: [
                {
                    text: ".csv",
                    value: "csv"
                },
                {
                    text: ".xlsx",
                    value: "xlsx"
                }
            ],
            fileFormat: "csv",
            headers: {
                "Device Time (UTC)": "",
                "Receive Time (UTC)": "",
                Latitude: "",
                Longitude: "",
                Temperature: "",
                Humidity: "",
                Light: "",
                "Probe Temperature": "",
                "External Temperature": "",
                Pressure: "",
                Battery: "",
                CO2: ""
            }
        };
    },
    computed: {
        startTime() {
            if (this.allRecords) return null;
            else {
                let startTime = new Date(this.formStartTime);
                return startTime;
            }
        },
        endTime() {
            if (this.allRecords) return null;
            else {
                let endTime = new Date(this.formEndTime);
                return endTime;
            }
        }
    },
    watch: {
        filterStartTime: {
            immediate: true,
            handler(value) {
                if (value) {
                    if (typeof value === "string")
                        this.formStartTime = value.split("T")[0];
                    else this.formStartTime = value.format("YYYY-MM-DD");
                }
            },
            deep: true
        },
        filterEndTime: {
            immediate: true,
            handler(value) {
                if (value) {
                    if (typeof value === "string") this.formEndTime = value;
                    else this.formEndTime = value.format("YYYY-MM-DD");
                }
            },
            deep: true
        }
    },
    methods: {
        closeAndResetInput() {
            this.exportDataDialog = false;
            this.isLoadingFile = false;

            if (this.filterStartTime)
                if (typeof this.filterStartTime == "string")
                    this.formStartTime = this.filterStartTime.split("T")[0];
                else
                    this.formStartTime = this.filterStartTime.format(
                        "YYYY-MM-DD"
                    );
            else this.formStartTime = null;
            if (this.filterEndTime)
                if (typeof this.filterEndTime == "string")
                    this.formEndTime = this.filterEndTime;
                else this.formEndTime = this.filterEndTime.format("YYYY-MM-DD");
            else this.formEndTime = null;

            this.fileData = null;
            this.allRecords = true;
            this.fileFormat = "csv";
        },
        submitExportData() {
            this.isLoadingFile = true;

            this.$apollo
                .query({
                    query: gql`
                        query downloadData(
                            $tracker: ID!
                            $deviceTimeGte: DateTime
                            $deviceTimeLte: DateTime
                            $orderBy: String
                        ) {
                            trackerMergedSensorDatas(
                                tracker: $tracker
                                deviceTime_Lte: $deviceTimeLte
                                deviceTime_Gte: $deviceTimeGte
                                orderBy: $orderBy
                            ) {
                                edges {
                                    node {
                                        battery
                                        co2
                                        deviceTime
                                        externalTemperature
                                        humidity
                                        id
                                        latitude
                                        light
                                        longitude
                                        pressure
                                        probeTemperature
                                        receiveTime
                                        temperature
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        tracker: this.tracker.id,
                        deviceTimeGte: this.startTime,
                        deviceTimeLte: this.endTime,
                        orderBy: "deviceTime"
                    }
                })
                .then(response => {
                    this.fileData = response.data.trackerMergedSensorDatas.edges.map(
                        edge => edge.node
                    );

                    this.fileData.sort(this.sortByDate);

                    this.fileData = this.reformatDataToOutput(this.fileData);

                    try {
                        this.exportData(
                            this.fileData,
                            `${this.tracker.serialNumber}`
                        );
                    } catch (error) {
                        console.error(error);
                    } finally {
                        this.isLoadingFile = false;
                        this.closeAndResetInput();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        exportData(data, filename) {
            if (this.fileFormat === "csv") {
                const csv = this.convertToCSV(data);
                this.download(
                    csv,
                    `${filename}.csv`,
                    "text/csv;charset=utf-8;"
                );
            } else if (this.fileFormat === "xlsx") {
                const workbook = this.convertToXLSX(data);
                const excelBuffer = write(workbook, {
                    bookType: "xlsx",
                    type: "buffer"
                });
                this.download(
                    excelBuffer,
                    `${filename}.xlsx`,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
                );
            } else {
                console.error("Invalid format");
            }
        },

        convertToCSV(data) {
            const delimiter = ",";
            const keys = Object.keys(this.headers);
            const headers = keys.join(delimiter);
            const values = data.map(obj =>
                keys.map(key => obj[key]).join(delimiter)
            );
            return [headers, ...values].join("\n");
        },

        convertToXLSX(data) {
            if (!data || data.length === 0) {
                const worksheet = utils.json_to_sheet([this.headers]);
                const workbook = {
                    Sheets: { data: worksheet },
                    SheetNames: ["data"]
                };
                return workbook;
            }
            const worksheet = utils.json_to_sheet(data);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"]
            };
            return workbook;
        },

        download(data, filename, type) {
            const blob = new Blob([data], { type });
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        sortByDate(a, b) {
            const dateA = a.deviceTime;
            const dateB = b.deviceTime;

            if (dateA < dateB) {
                return -1;
            } else if (dateA > dateB) {
                return 1;
            } else {
                return 0;
            }
        },
        reformatDataToOutput(data) {
            data.forEach((item, index) => {
                const trackerData = {
                    "Device Time (UTC)": "",
                    "Receive Time (UTC)": "",
                    Latitude: "",
                    Longitude: "",
                    Temperature: "",
                    Humidity: "",
                    Light: "",
                    "Probe Temperature": "",
                    "External Temperature": "",
                    Pressure: "",
                    Battery: "",
                    CO2: ""
                };

                trackerData["Device Time (UTC)"] = item.deviceTime;
                trackerData["Receive Time (UTC)"] = item.receiveTime;
                trackerData.Latitude = item.latitude;
                trackerData.Longitude = item.longitude;
                trackerData.Temperature = item.temperature;
                trackerData.Humidity = item.humidity;
                trackerData.Light = item.light;
                trackerData["Probe Temperature"] = item.probeTemperature;
                trackerData["External Temperature"] = item.externalTemperature;
                trackerData.Pressure = item.pressure;
                trackerData.Battery = item.battery;
                trackerData.CO2 = item.co2;

                data[index] = { ...trackerData };
            });
            return data;
        }
    }
};
</script>
