<template>
    <v-hover>
        <template v-slot="{ hover }">
            <!---- TOOLBAR LEFT SIDE ---->
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="12" sm="9" lg="10">
                        <v-row>
                            <v-col
                                v-for="f in dynamicFilters"
                                :key="f"
                                :class="getFilterChoiceClassByCode(f)"
                            >
                                <!---- PARTNER NAME ---->
                                <!-- <v-text-field
                                    v-if="f === 'partnerName'"
                                    v-model="filter.tenantPartnerNameIcontains"
                                    label="Partner Name"
                                    clearable
                                    hide-details
                                    dense
                                    
                                ></v-text-field> -->

                                <!---- LOCATION NAME ---->
                                <v-text-field
                                    v-if="f === 'locationName'"
                                    v-model="filter.nameIcontains"
                                    :label="$t('headers.location_name')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                                <!---- CITY ---->
                                <v-text-field
                                    v-if="f === 'city'"
                                    v-model="filter.cityIcontains"
                                    :label="$t('partners.city')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                                <!---- COUNTRY ---->
                                <v-text-field
                                    v-if="f === 'country'"
                                    v-model="filter.countryIcontains"
                                    :label="$t('partners.country')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                                <!---- PUBLIC ---->
                                <v-select
                                    v-if="f === 'scope'"
                                    v-model="filter.locationType_In"
                                    dense
                                    :label="$t('headers.scope')"
                                    :items="locationByType"
                                    item-text="name"
                                    item-value="value"
                                    clearable
                                    hide-details
                                    :menu-props="{
                                        zIndex: 3
                                    }"
                                >
                                </v-select>
                                <v-combobox
                                    v-if="f === 'tags'"
                                    v-model="filter.tagsIcontains"
                                    :items="tagsChoices"
                                    :label="$t('headers.tags')"
                                    small-chips
                                    deletable-chips
                                    hide-details
                                    dense
                                ></v-combobox>

                                <v-text-field
                                    v-if="f === 'partnerName'"
                                    v-model="filter.tenantPartnerNameIcontains"
                                    :label="$t('headers.partner_name')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>

                                <v-select
                                    v-if="f === 'partnerType'"
                                    :items="partnersTypes"
                                    item-text="text"
                                    item-value="value"
                                    v-model="
                                        filter.tenantPartnerPartnerTypeIexact
                                    "
                                    :label="$t('headers.partner_type')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-select>
                                <v-select
                                    v-if="f === 'locationType'"
                                    :items="publicLocationsTypes"
                                    v-model="publicLocationType"
                                    item-text="text"
                                    item-value="value"
                                    :label="$t('headers.location_type')"
                                    hide-details
                                    @change="onChangePublicLocationType"
                                    dense
                                ></v-select>
                                <!-- <v-text-field
                                    v-model="filter.countryIcontains"
                                    label=""
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field> -->
                            </v-col>

                            <!---- SORT BY ---->
                            <v-col cols="5" sm="4" lg="3">
                                <v-row class="d-flex align-center ma-0 pa-0">
                                    <v-select
                                        v-model="sortBy"
                                        :items="sortByChoices"
                                        :label="$t('general.sort_by')"
                                        :item-text="item => item.name"
                                        :item-value="item => item.code"
                                        clearable
                                        hide-details
                                        dense
                                        color="primary"
                                        :menu-props="{
                                            zIndex: 3
                                        }"
                                    >
                                        <!---- SORT BY ICON ---->
                                        <template v-slot:append-outer>
                                            <v-btn
                                                class="mt-n1"
                                                small
                                                icon
                                                :disabled="!sortBy"
                                                @click="sortAsc = !sortAsc"
                                            >
                                                <v-icon color="grey darken-2">{{
                                                    sortAsc ? "north" : "south"
                                                }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- TOOLBAR RIGHT SIDE -->

                    <v-col cols="12" sm="3" lg="2" class="py-2 pr-4 text-right">
                        <v-row class="d-flex align-center justify-end flex-nowrap">
                           
                            <!-- FILTERS MENU -->
                            <FilterMenu
                                :title="$t('dashboard.customize')"
                                :description="$t('dashboard.display')"
                                :choices="filterChoices"
                                :open-on-hover="false"
                                height="500px"
                                width="250px"
                                v-on:changed="onFilterMenuChanged"
                            >
                            </FilterMenu>
                        </v-row>
                    </v-col>
                </v-row>

                <!---- ADD LOCATION DIALOG ---->
                <AddLocation
                    v-if="title !== $t('partners.public_locations')"
                    :openAddLocationDialog="openAddLocation"
                    v-on:updateLocationsTable="$emit('updateLocationTable')"
                    v-on:closeMap="openAddLocation = false"
                    :title="title"
                ></AddLocation>
                <AddPublicLocation
                    v-else
                    :openDialog="openAddLocation"
                    @closeDialog="openAddLocation = false"
                ></AddPublicLocation>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import FilterMenu from "@/components/base/FilterMenu.vue";
import AddLocation from "@/components/locations/create/AddLocation.vue";
import AddPublicLocation from "@/components/locations/create/AddPublicLocation.vue";
export default {
    components: { FilterMenu, AddLocation, AddPublicLocation },
    props: {
        selectedLocations: {
            type: Object
        },
        title: {
            type: String,
            default: "Locations"
        }
    },
    apollo: {
        // tenantLocations: {
        //     query: gql`
        //         query tenantLocations {
        //             tenantLocations {
        //                 edges {
        //                     node {
        //                         id
        //                         tags
        //                     }
        //                 }
        //             }
        //         }
        //     `,
        //     skip() {
        //         return this.title !== this.$t("home.my_locations");
        //     },
        //     result({ data }) {
        //         if (data) {
        //             data.tenantLocations.edges.forEach(edge => {
        //                 let _edgeTags =
        //                     edge.node.tags !== null
        //                         ? JSON.parse(edge.node.tags.replace(/'/g, '"'))
        //                         : [];
        //                 this.tags = [...this.tags, ..._edgeTags];
        //             });
        //         }
        //     }
        // }
    },
    data() {
        return {
            fab: false,
            isLoading: false,
            menu: false,
            dateRange: [],
            savedDateRange: [],
            sortAsc: true,
            shouldStickyFilter: false,
            openAddLocation: false,
            tags: [],
            publicLocationType: "airport",
            locationByType: [
                {
                    name: this.$t("alert_rules.public"),
                    value: ["tenant_location"]
                },
                {
                    name: this.$t("alert_rules.nonpublic"),
                    value: ["private_location"]
                }
            ],
            partnersTypes: [
                {
                    text: this.$t("headers.consignor"),
                    value: "consignor"
                },
                {
                    text: this.$t("headers.consignee"),
                    value: "consignee"
                },
                {
                    text: this.$t("headers.forwarder"),
                    value: "forwarder"
                },
                {
                    text: this.$t("home.other"),
                    value: "other"
                }
            ],
            publicLocationsTypes: [
                {
                    text: this.$t("headers.airport"),
                    value: "airport"
                },
                {
                    text: this.$t("headers.sea_port"),
                    value: "sea_port"
                }
            ],
            defaulttagsChoices: [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ],
            // --------------------> FILTERS <----------------------- \\
            MyDefaultFilterChoices: [
                {
                    code: "locationName",
                    name: "Location Name",
                    tag: "headers.location_name",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "city",
                    name: "City",
                    tag: "partners.city",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "country",
                    name: "Country",
                    tag: "partners.country",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "scope",
                    name: "Scope",
                    tag: "headers.scope",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "tags",
                    name: "Tags",
                    tag: "headers.tags",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                }
            ],

            PartnerDefaultFilterChoices: [
                {
                    code: "locationName",
                    name: "Location Name",
                    tag: "headers.location_name",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "city",
                    name: "City",
                    tag: "partners.city",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "country",
                    name: "Country",
                    tag: "partners.country",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "partnerName",
                    name: "Partner Name",
                    tag: "headers.partner_name",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "partnerType",
                    name: "Partner Type",
                    tag: "headers.partner_type",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                }
            ],

            PublicDefaultFilterChoices: [
                {
                    code: "locationName",
                    name: "Location Name",
                    tag: "headers.location_name",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "city",
                    name: "City",
                    tag: "partners.city",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "country",
                    name: "Country",
                    tag: "partners.country",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "locationType",
                    name: "Location Type",
                    tag: "headers.location_type",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                }
            ],

            filterChoices: null,

            // sortByChoices: [
            //     {
            //         code: "name",
            //         name: this.$t("headers.location_name")
            //     },
            //     {
            //         code: "city",
            //         name: this.$t("partners.city")
            //     },
            //     {
            //         code: "country",
            //         name: this.$t("partners.country")
            //     },
            //     {
            //         code: "isTrackerStockLocation",
            //         name: this.$t("headers.location_stock")
            //     }
            // ],

            sortBy: null,

            // graphql query filter
            filter: {
                first: 15,
                after: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                locationType_In: null,
                orderBy: null
            },
            myFilter: {
                first: 15,
                after: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                locationType_In: ["tenant_location", "private_location"],
                orderBy: null
            },
            partnerFilter: {
                first: 15,
                after: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                locationType_In: ["partner_location"],
                orderBy: null
            },
            publicFilter: {
                first: 15,
                after: null,
                nameIcontains: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                tagsIcontains: null,
                locationType_In: null,
                orderBy: null
            }
        };
    },
    beforeCreate() {
        this.me = helper.me();
    },
    computed: {
        tagsChoices() {
            let _tagsChoices = this.defaulttagsChoices; //.concat(this.tags);
            return _tagsChoices;
        },
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },
        dynamicFilters() {
            return this.filterChoices.filter(f => f.enabled).map(f => f.code);
        },
        sortByChoices: function() {
            let _sortByChoices;
            if (this.title === this.$t("partners.public_locations")) {
                _sortByChoices = [
                    {
                        code: "name",
                        name: this.$t("headers.location_name")
                    },
                    {
                        code: "city",
                        name: this.$t("partners.city")
                    },
                    {
                        code: "country",
                        name: this.$t("partners.country")
                    }
                ];
            } else {
                _sortByChoices = [
                    {
                        code: "name",
                        name: this.$t("headers.location_name")
                    },
                    {
                        code: "city",
                        name: this.$t("partners.city")
                    },
                    {
                        code: "country",
                        name: this.$t("partners.country")
                    },
                    {
                        code: "isTrackerStockLocation",
                        name: this.$t("headers.location_stock")
                    }
                ];
            }
            return _sortByChoices;
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                }
                if (this.title === this.$t("home.my_locations")) {
    
                    // if(value.locationType_In === null){
                    //     value.locationType_In = this.myFilter.locationType_In;
                    // }
                    value.locationType_In = this.myFilter.locationType_In;
                    helper.setFilterValues("MyLocationsTableFilters", value);
                } else if (this.title === this.$t("home.partners_locations")) {
                    helper.setFilterValues(
                        "PartnersLocationsTableFilters",
                        value
                    );
                } else if (
                    this.title === this.$t("partners.public_locations")
                ) {
                    helper.setFilterValues(
                        "PublicLocationsTableFilters",
                        value
                    );
                }
            },
            deep: true
        },
        title(val) {
            let _filter;
            if (val === this.$t("home.my_locations")) {
                this.filterChoices = helper.getFilterChoices(
                    this.MyDefaultFilterChoices,
                    "MyLocationsTableFilters"
                );
                _filter = helper.getFilterValues("MyLocationsTableFilters");
                
                if (!_filter) _filter = this.myFilter;
            } else if (val === this.$t("home.partners_locations")) {
                this.filterChoices = helper.getFilterChoices(
                    this.PartnerDefaultFilterChoices,
                    "PartnersLocationsTableFilters"
                );
                _filter = helper.getFilterValues(
                    "PartnersLocationsTableFilters"
                );
                if (!_filter) _filter = this.partnerFilter;
            } else if (val === this.$t("partners.public_locations")) {
                this.filterChoices = helper.getFilterChoices(
                    this.PublicDefaultFilterChoices,
                    "PublicLocationsTableFilters"
                );
                _filter = helper.getFilterValues("PublicLocationsTableFilters");
                if (!_filter) _filter = this.publicFilter;
            }
            if (_filter) {
                this.filter = _filter;
                if (_filter.orderBy?.includes("-")) {
                    this.sortBy = _filter.orderBy.replace("-", "");
                    this.sortAsc = false;
                } else {
                    this.sortBy = _filter.orderBy;
                    this.sortAsc = true;
                }
            }
        },
        deep: true
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        if (this.title === this.$t("home.my_locations")) {
            this.filterChoices = helper.getFilterChoices(
                this.MyDefaultFilterChoices,
                "MyLocationsTableFilters"
            );
        } else if (this.title === this.$t("home.partners_locations")) {
            this.filterChoices = helper.getFilterChoices(
                this.PartnerDefaultFilterChoices,
                "PartnersLocationsTableFilters"
            );
        } else if (this.title === this.$t("partners.public_locations")) {
            this.filterChoices = helper.getFilterChoices(
                this.PublicDefaultFilterChoices,
                "PublicLocationsTableFilters"
            );
        }
    },
    mounted() {
        this.onResize();

        let _filter;
        if (this.title === this.$t("home.my_locations")) {
            _filter = helper.getFilterValues("MyLocationsTableFilters");
            if (!_filter) _filter = this.myFilter;
        } else if (this.title === this.$t("home.partners_locations")) {
            _filter = helper.getFilterValues("PartnersLocationsTableFilters");
            if (!_filter) _filter = this.partnerFilter;
        } else if (this.title === this.$t("partners.public_locations")) {
            _filter = helper.getFilterValues("PublicLocationsTableFilters");
            if (!_filter) _filter = this.publicFilter;
        }
        
        if (_filter) {
            this.filter = _filter;
            if (_filter.orderBy?.includes("-")) {
                this.sortBy = _filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else {
                this.sortBy = _filter.orderBy;
                this.sortAsc = true;
            }
        }

        this.$watch(
            "filterChoices",
            function(value) {
                if (this.title === this.$t("home.my_locations")) {
                    helper.updateMyPreferences({
                        MyLocationsTableFilters: value
                    });
                } else if (this.title === this.$t("home.partners_locations")) {
                    helper.updateMyPreferences({
                        PartnersLocationsTableFilters: value
                    });
                } else if (
                    this.title === this.$t("partners.public_locations")
                ) {
                    helper.updateMyPreferences({
                        PublicLocationsTableFilters: value
                    });
                }
            },
            { deep: true }
        );
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },

        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        },
        onFilterMenuChanged(newChoises) {
            this.filterChoices = newChoises;
        },
        onChangePublicLocationType(value) {
            this.$emit("changePublicLocationType", value);
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
