<template>
    <v-container fluid class="pa-0">
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col>
                    <div class="d-flex">
                        <h2
                            class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                            align-top
                        "
                        >
                            Weather
                        </h2>
                        <span class="subtitle-2 mt-8">{{
                            last_refresh_time
                        }}</span>
                    </div>
                </v-col>
               <v-spacer></v-spacer>
               <v-btn
                    rounded
                    color="primary"
                    class="mr-1 mt-2"
                    @click="openAddLocationDialog = true"
                    :disabled="disabledAddLocationButton"
                >
                    {{ $t("partners.add_location") }}
                </v-btn>
            </v-row>
        </div>

        <!-- LOCATIONS FILTER/TOOLBAR -->
        <LocationsFilter
            ref="locationfilter"
            class="mx-3"
            v-on:updateLocationTable="refreshTable"
            v-on:deletedLocation="selectedLocation = []"
            :selectedLocations="selectedLocation"
            v-on:filter-changed="onFilterChanged"
            :locationsWithoutForecast="locationsWithoutForecast"
            v-on:refetchData="requestRefetch"
            :disabledAddLocationButton="disabledAddLocationButton"
        ></LocationsFilter>
        <!-- TABLE -->
        <LocationsTable
            ref="locationtable"
            :locationsFilter="locationsFilter"
            class="mx-1 mb-3"
            v-on:click:checkDetails="onLocationSelected"
            v-on:locationsWithoutForecast="handleLocationsWithoutForecastEvent"
            v-on:click:disabledAddLocationButton="
                disabledAddLocationButton = true
            "
            v-on:click:enableAddLocationButton="
                disabledAddLocationButton = false
            "
            v-on:last_sync_time="last_refresh_time = $event"
            @locationDeleted="refreshFilter"
        ></LocationsTable>
        <!-- TUTORIAL -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>

        <!-- detail drawer -->
        <v-navigation-drawer
            v-model="locationDetailDrawer"
            :width="locationDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <LocationDetail
                    v-if="selectedLocation"
                    :key="selectedLocation.id"
                    :location="selectedLocation"
                    :isFullScreen="isLocationDetailDrawerFullScreen"
                    v-on:changeFullScreen="
                        onLocationDetailDrawerFullScreenChange
                    "
                    v-on:close="locationDetailDrawer = false"
                    @refreshDataTable="test"
                    v-on:onLocationDeleted="onLocationDeleted"
                ></LocationDetail>
            </div>
        </v-navigation-drawer>
        <AddWeather
            ref="addWeatherDialog"
            :openAddLocationDialog="openAddLocationDialog"
            :locationsDisabledWeatherForecast="locationsFilter"
            v-on:closeMap="openAddLocationDialog = false"
            v-on:refetchData="requestRefetch"
        ></AddWeather>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import LocationsFilter from "@/components/weather/WeathersFilter.vue";
import LocationsTable from "@/components/weather/WeathersTable.vue";
import LocationDetail from "@/components/locations/LocationDetail.vue";
import { eventBus } from "@/main.js";
import AddWeather from "@/components/weather/create/AddWeather.vue";

export default {
    title: "Weather",
    components: {
        TutorialDialog,
        LocationsFilter,
        LocationsTable,
        LocationDetail, 
        AddWeather 
    },

    apollo: {},

    data() {
        return {
            locationDetailDrawer: false,
            locationsFilter: {},
            goTopShow: false,
            isLoading: false,
            openAddLocationDialog: false,
            menu: false,
            selectedLocation: null,
            tutorialDialog: false,
            locationDetailFullScreen: false,
            locationsWithoutForecast: { edges: [] },
            disabledAddLocationButton: false,
            last_sync_time: null
        };
    },

    computed: {
        last_refresh_time: {
            get() {
                return this.last_sync_time;
            },
            set(val) {
                let string = `(${this.$t(
                    "weather.last_refresh"
                )} ${this.formatDateTime(val)})`;
                this.last_sync_time = string;
            }
        },

        me() {
            return helper.me();
        },
        locationDetailDrawerWidth() {
            if (this.locationDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isLocationDetailDrawerFullScreen() {
            return this.locationDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },

    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.formatDateTime = helper.formatDateTime;
    },
    mounted() {},
    methods: {
        onLoading(val) {
            this.isLoading = val;
        },
        onFilterChanged(filter) {
            this.locationsFilter = filter;
        },
        refreshFilter() {
            this.$refs.locationfilter.$refs.addWeatherDialog.$apollo.queries.locations.refetch();
        },
        refreshTable() {
            this.$refs.locationtable.$apollo.queries.locations.refetch();
        },
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        },
        onLocationDetailDrawerFullScreenChange(val) {
            this.locationDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onLocationSelected(location) {
            this.selectedLocation = location.node;
            this.locationDetailDrawer = true;
        },
        onLocationDeleted() {
            this.selectedLocation = null;
            this.locationDetailDrawer = false;
        },
        handleLocationsWithoutForecastEvent(locationsWithoutForecast) {
            this.locationsWithoutForecast = locationsWithoutForecast;
        },
        requestRefetch(callback) {
            eventBus.$emit("refetchData", () => {
                callback();
            });
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
</style>
