<template>
    <v-hover>
        <template v-slot="{ hover }">
            <!---- TOOLBAR LEFT SIDE ---->
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="12" sm="9" lg="7">
                        <v-row>
                            <v-col
                                v-for="f in dynamicFilters"
                                :key="f"
                                :class="getFilterChoiceClassByCode(f)"
                                cols="12"
                                lg="3"
                            >
                                <!---- TEMPLATE NAME ---->
                                <v-text-field
                                    v-if="f === 'name'"
                                    v-model="filter.nameIcontains"
                                    :label="$t('templates.template_name')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                                <!---- DESCRPTION ---->
                                <v-text-field
                                    v-if="f === 'description'"
                                    v-model="filter.descriptionIcontains"
                                    :label="
                                        $t('templates.template_description')
                                    "
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                                <!---- TRANSPORT MODE ---->
                                <v-select
                                    v-if="f === 'transport_mode'"
                                    v-model="filter.transportMode"
                                    :items="transportModeChoices"
                                    :label="$t('headers.transport_mode')"
                                    @change="refreshCarriers"
                                    clearable
                                    hide-details
                                    dense
                                ></v-select>
                                <!---- CARRIER ---->
                                <v-autocomplete
                                    v-if="f === 'carrier'"
                                    v-model="filter.carrier"
                                    :items="carriers"
                                    :no-data-text="$t('loading.no_available')"
                                    item-text="name"
                                    :loading="isLoadingCarriers"
                                    item-value="id"
                                    :label="$t('headers.carrier')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-autocomplete>
                                <!---- GOODS ---->
                                <v-text-field
                                    v-if="f === 'goods'"
                                    v-model="filter.goods"
                                    :label="$t('headers.goods')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                                <!---- TERMS ---->
                                <v-select
                                    v-if="f === 'term'"
                                    v-model="filter.term"
                                    :items="termChoices"
                                    :label="$t('forms.term')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-select>
                                <!---- LOADPOINT ---->
                                <v-text-field
                                    v-if="f === 'loadPoint'"
                                    v-model="filter.loadPoint"
                                    :label="$t('forms.load_point')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-text-field>
                                <!---- CONSIGNEE ---->
                                <v-autocomplete
                                    v-if="f === 'consignee'"
                                    v-model="filter.partner"
                                    :items="consignees"
                                    :no-data-text="$t('loading.no_available')"
                                    item-text="name"
                                    :loading="isLoadingPartners"
                                    item-value="id"
                                    :label="$t('headers.consignee')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-autocomplete>
                                <!---- CONSIGNOR ---->
                                <v-autocomplete
                                    v-if="f === 'consignor'"
                                    v-model="filter.partner"
                                    :items="consignors"
                                    :no-data-text="$t('loading.no_available')"
                                    item-text="name"
                                    :loading="isLoadingPartners"
                                    item-value="id"
                                    :label="$t('headers.consignor')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-autocomplete>
                                <!---- FORWARDER ---->
                                <v-autocomplete
                                    v-if="f === 'forwarder'"
                                    v-model="filter.partner"
                                    :items="forwarders"
                                    :no-data-text="$t('loading.no_available')"
                                    item-text="name"
                                    :loading="isLoadingPartners"
                                    item-value="id"
                                    :label="$t('headers.forwarder')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-autocomplete>
                                <!---- OTHER ---->
                                <v-autocomplete
                                    v-if="f === 'other'"
                                    v-model="filter.partner"
                                    :items="others"
                                    :no-data-text="$t('loading.no_available')"
                                    item-text="name"
                                    :loading="isLoadingPartners"
                                    item-value="id"
                                    :label="$t('transport_mode_choice.other')"
                                    clearable
                                    hide-details
                                    dense
                                ></v-autocomplete>
                            </v-col>

                            <!---- SORT BY ---->
                            <v-col cols="5" sm="4" lg="3">
                                <v-row class="d-flex align-center ma-0 pa-0">
                                    <v-select
                                        v-model="sortBy"
                                        :items="sortByChoices"
                                        :label="$t('general.sort_by')"
                                        :item-text="item => item.name"
                                        :item-value="item => item.code"
                                        clearable
                                        hide-details
                                        dense
                                        color="primary"
                                    >
                                        <!---- SORT BY ICON ---->
                                        <template v-slot:append-outer>
                                            <v-btn
                                                class="mt-n1"
                                                small
                                                icon
                                                :disabled="!sortBy"
                                                @click="sortAsc = !sortAsc"
                                            >
                                                <v-icon color="grey darken-2">{{
                                                    sortAsc ? "north" : "south"
                                                }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- TOOLBAR RIGHT SIDE -->

                    <v-col cols="12" sm="3" lg="5" class="py-2 pr-2 text-right">
                        <v-row
                            no-gutters
                            dense
                            class="d-flex flex-nowrap justify-end"
                        >
                            <!-- ADD BUTTON -->

                          <!--   <AddTrip
                                v-if="!selectMode"
                                :templateMode="true"
                                @template-created="onTemplateCreated"
                            ></AddTrip>

                            <v-btn
                                v-if="!selectMode"
                                color="primary"
                                @click="changeSelectedMode"
                                rounded
                                >{{ $t("general.select") }}</v-btn
                            >
                            <div v-else>
                                <v-btn
                                    ref="deleteBtn"
                                    class="mr-2"
                                    color="primary"
                                    :loading="isLoading"
                                    rounded
                                    @click="deleteSelectedTemplates"
                                >
                                    <v-icon left>
                                        delete
                                    </v-icon>
                                    {{ $t("general.delete") }}
                                </v-btn>
                                <v-btn
                                    @click="changeSelectedMode"
                                    text
                                    rounded
                                    >{{ $t("general.cancel") }}</v-btn
                                >
                            </div> -->

                            <!-- FILTERS MENU -->
                            <FilterMenu
                                :title="$t('dashboard.customize')"
                                :description="$t('dashboard.display')"
                                :choices="filterChoices"
                                :open-on-hover="false"
                                height="500px"
                                width="250px"
                                v-on:changed="onFilterMenuChanged"
                            >
                            </FilterMenu>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import FilterMenu from "@/components/base/FilterMenu.vue";
/* import AddTrip from "@/components/trips/create/AddTrip.vue"; */

export default {
    components: { FilterMenu, /* AddTrip */ },
    props: {},
    apollo: {
        getCarrierPartners: {
            query: gql`
                query getTenantPartners(
                    $partnerType: String
                    $orderBy: String
                    $carrierType: String
                ) {
                    getTenantPartners(
                        partnerType: $partnerType
                        orderBy: $orderBy
                        carrierType: $carrierType
                    ) {
                        edges {
                            node {
                                id
                                name
                                carrierType
                                carrier {
                                    id
                                    isSupportTracking
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    partnerType: "carrier",
                    carrierType: helper.getFilterValues("TemplateTripFilters")
                        ?.transportMode,
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            // this setting can avoid query again after fetchMore
            update: data => data.getTenantPartners,
            watchLoading(isLoading) {
                this.isLoadingCarriers = isLoading;
                this.$emit("loading", isLoading);
            },
            skip() {
                return false;
            }
        },
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $referenceNumberIcontains: String
                    $partnerType: String
                    $carrierType: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        referenceNumber_Icontains: $referenceNumberIcontains
                        partnerType: $partnerType
                        carrierType: $carrierType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantPartners,
            watchLoading(isLoading) {
                this.isLoadingPartners = isLoading;
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    data() {
        return {
            fab: false,
            isLoading: false,
            isLoadingCarriers: false,
            isLoadingPartners: false,
            selectMode: false,
            menu: false,
            dateRange: [],
            savedDateRange: [],
            sortAsc: true,
            shouldStickyFilter: false,
            openAddTripTemplate: false,
            // --------------------> FILTERS <----------------------- \\
            // defaultFilterChoices: [
            //     {
            //         code: "name",
            //         name: "Name",
            //         enabled: true,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "description",
            //         name: "Description",
            //         enabled: true,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "transport_mode",
            //         name: "Transport Mode",
            //         enabled: true,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "carrier",
            //         name: "Carrier",
            //         enabled: true,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "goods",
            //         name: "Goods",
            //         enabled: true,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "term",
            //         name: "Term",
            //         enabled: false,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "loadPoint",
            //         name: "Load Point",
            //         enabled: false,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "consignee",
            //         name: "Consignee",
            //         enabled: false,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "consignor",
            //         name: "Consignor",
            //         enabled: false,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "forwarder",
            //         name: "Forwarder",
            //         enabled: false,
            //         class: "col-auto col-md-3 col-lg-2"
            //     },
            //     {
            //         code: "other",
            //         name: "Other",
            //         enabled: false,
            //         class: "col-auto col-md-3 col-lg-2"
            //     }
            // ],

            filterChoices: null,

            // sortByChoices: [
            //     {
            //         code: "name",
            //         name: this.$t("general.name")
            //     },
            //     {
            //         code: "description",
            //         name: this.$t("general.description")
            //     }
            // ],
            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            // ],
            // termChoices: [
            //     {
            //         value: "DAP",
            //         text: "DAP" + this.$t("term.dap")
            //     },
            //     {
            //         value: "DAT",
            //         text: "DAT" + this.$t("term.dat")
            //     },
            //     {
            //         value: "DDP",
            //         text: "DDP" + this.$t("term.ddp")
            //     },
            //     {
            //         value: "DPU",
            //         text: "DPU" + this.$t("term.dpu")
            //     },
            //     {
            //         value: "CFR",
            //         text: "CFR" + +this.$t("term.cfr")
            //     },
            //     {
            //         value: "CIF",
            //         text: "CIF" + this.$t("term.cif")
            //     },
            //     {
            //         value: "CIP",
            //         text: "CIP" + this.$t("term.cip")
            //     },
            //     {
            //         value: "CPT",
            //         text: "CPT" + this.$t("term.cpt")
            //     },
            //     { value: "EXW", text: "EXW" + this.$t("term.exw") },
            //     { value: "FCA", text: "FCA" + this.$t("term.fca") },
            //     {
            //         value: "FAS",
            //         text: "FAS" + this.$t("term.fas")
            //     },
            //     { value: "FOB", text: "FOB(Free On Board)" }
            // ],

            sortBy: null,

            // graphql query filter
            filter: {
                first: 15,
                orderBy: null,
                nameIcontains: null,
                descriptionIcontains: null,
                transportMode: null,
                carrier: null,
                goods: null,
                loadPoint: null,
                term: null
            }
        };
    },
    beforeCreate() {
        this.me = helper.me();
    },
    computed: {
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },
        dynamicFilters() {
            return this.filterChoices.filter(f => f.enabled).map(f => f.code);
        },
        carriers() {
            return this.getCarrierPartners?.edges?.map(e => e.node) || [];
        },
        consignees() {
            return this.tenantPartners?.edges
                ?.filter(e => e.node.partnerType === "consignee")
                .map(e => e.node);
        },
        consignors() {
            return this.tenantPartners?.edges
                ?.filter(e => e.node.partnerType === "consignor")
                .map(e => e.node);
        },
        forwarders() {
            return this.tenantPartners?.edges
                ?.filter(e => e.node.partnerType === "forwarder")
                .map(e => e.node);
        },
        others() {
            return this.tenantPartners?.edges
                ?.filter(e => e.node.partnerType === "other")
                .map(e => e.node);
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "name",
                    name: this.$t("headers.name")
                },
                {
                    code: "description",
                    name: this.$t("general.description")
                }
            ];
            return _sortByChoices;
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        },
        termChoices: function() {
            let _termChoices = [
                {
                    value: "DAP",
                    text: "DAP" + this.$t("term.dap")
                },
                {
                    value: "DAT",
                    text: "DAT" + this.$t("term.dat")
                },
                {
                    value: "DDP",
                    text: "DDP" + this.$t("term.ddp")
                },
                {
                    value: "DPU",
                    text: "DPU" + this.$t("term.dpu")
                },
                {
                    value: "CFR",
                    text: "CFR" + +this.$t("term.cfr")
                },
                {
                    value: "CIF",
                    text: "CIF" + this.$t("term.cif")
                },
                {
                    value: "CIP",
                    text: "CIP" + this.$t("term.cip")
                },
                {
                    value: "CPT",
                    text: "CPT" + this.$t("term.cpt")
                },
                { value: "EXW", text: "EXW" + this.$t("term.exw") },
                { value: "FCA", text: "FCA" + this.$t("term.fca") },
                {
                    value: "FAS",
                    text: "FAS" + this.$t("term.fas")
                },
                { value: "FOB", text: "FOB(Free On Board)" }
            ];
            return _termChoices;
        },
        defaultFilterChoices: function() {
            let _defaultFilterChoices = [
                {
                    code: "name",
                    name: "Name",
                    tag: "headers.name",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "description",
                    name: "Description",
                    tag: "general.description",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "transport_mode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "goods",
                    name: "Goods",
                    tag: "headers.goods",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "term",
                    name: "Term",
                    tag: "forms.term",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "loadPoint",
                    name: "Load Point",
                    tag: "headers.load_points",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "consignee",
                    name: "Consignee",
                    tag: "headers.consignee",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "consignor",
                    name: "Consignor",
                    tag: "headers.consignor",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "forwarder",
                    name: "Forwarder",
                    tag: "headers.forwarder",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "other",
                    name: "Other",
                    tag: "transport_mode_choice.other",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                }
            ];

            return _defaultFilterChoices;
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                }

                helper.setFilterValues("TemplateTripFilters", value);
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "TemplateTripFilters"
        );
    },
    mounted() {
        this.onResize();
        let _filter = helper.getFilterValues("TemplateTripFilters");
        if (_filter) {
            this.filter = _filter;
            if (_filter.orderBy?.includes("-")) {
                this.sortBy = _filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else {
                this.sortBy = _filter.orderBy;
                this.sortAsc = true;
            }
        }

        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    TemplateTripFilters: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        refreshCarriers() {
            this.$apollo.queries.getCarrierPartners
                .setVariables({
                    partnerType: "carrier",
                    carrierType: this.filter.transportMode,
                    orderBy: "name"
                })
                .then(() => {
                    this.$apollo.queries.getCarrierPartners.refetch();
                });
        },
        deleteSelectedTemplates() {
            this.isLoading = true;
            this.$emit("delete-selected", true);
        },
        changeSelectedMode() {
            this.selectMode = !this.selectMode;
            this.$emit("select-mode", this.selectMode);
        },
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },

        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        },
        onFilterMenuChanged(newChoises) {
            this.filterChoices = newChoises;
        },
        onTemplateCreated() {
            this.$emit("template-created");
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
