<template>
    <v-card flat>
        <v-card-text>
            {{ $t("general.comments") }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        trip: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    },
    methods: {}
};
</script>
