import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c('div',[_c(VRow,{staticClass:"ma-0 px-3 d-flex align-center"},[_c(VCol,{staticClass:"3"},[_c('h2',{staticClass:"ml-3 display-3 font-weight-medium d-flex align-top"},[_vm._v(" "+_vm._s(_vm.$t('home.settings'))+" ")])])],1)],1),(_vm.tenantDetail == null && _vm.$apollo.queries.tenant.loading)?_c('LoadingBar'):_c(VCard,{staticClass:"d-flex justify-center align-center mx-auto mt-5",attrs:{"flat":"","max-width":"90%","elevation":"1"}},[_c(VCardText,[_c(VTabs,{attrs:{"vertical":""}},[_c(VTab,[_c(VIcon,{attrs:{"left":""}},[_vm._v(" flight_takeoff ")]),_vm._v(" "+_vm._s(_vm.$t('headers.trip'))+" ")],1),_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c('SettingsDetails',{staticClass:"mx-3 mt-3",attrs:{"tenant":_vm.tenantDetail}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }