<template>
    <v-container fluid class="pa-0"> </v-container>
</template>

<script>
import helper from "@/utils/helper.js";

export default {
    title: "ColdCha",
    components: {},

    apollo: {},

    data() {
        return {
            tab: 0,
            isLoading: false
        };
    },

    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.routeMe();
    },
    methods: {
        routeMe() {
            if (this.me.isStaff) {
                this.pushTo("staffdashboard");
            } else {
                // normal user
                this.pushTo("dashboard");
            }
        }
    }
};
</script>
