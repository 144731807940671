import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.canAccess)?_c('div',[_c('NoPermission')],1):_c('div',{staticClass:"px-5"},[_c(VCard,{staticClass:"mt-2 mb-5 mt-5 pb-5",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,[_c(VDataTable,{attrs:{"items":_vm.alertRules.edges,"headers":_vm.headerChoices,"disable-pagination":"","loading-text":_vm.$t('loading.loading1'),"loader-height":"2","hide-default-footer":"","mobile-breakpoint":0,"no-data-text":_vm.$t('loading.no_data'),"disable-sort":"","loading":_vm.$apollo.queries.alertRules.loading},scopedSlots:_vm._u([{key:"item.node.tenantAlertRule.isEnabled",fn:function(ref){
var item = ref.item;
return [(item.node.tenantAlertRule.isEnabled)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])]}},{key:"item.node.tenantAlertRule.shouldNotifyPartner",fn:function(ref){
                    var item = ref.item;
return [(item.node.tenantAlertRule.shouldNotifyPartner)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])]}},{key:"item.node.tenantAlertRule.shouldNotifyForEveryRecord",fn:function(ref){
                    var item = ref.item;
return [(
                            item.node.tenantAlertRule
                                .shouldNotifyForEveryRecord
                        )?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])]}}])})],1),_c(VCardActions,{staticClass:"justify-center"},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.alertRules.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }