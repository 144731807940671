<template>
    <div>
        <v-container fluid class="pa-0">
            <!-- tutorial -->
            <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>

            <v-btn
                v-scroll="onScroll"
                v-show="goTopShow"
                fab
                dark
                fixed
                bottom
                right
                style="z-index: 1"
                color="primary"
                @click="toTop"
            >
                <v-icon>keyboard_arrow_up</v-icon>
            </v-btn>

            <!-- title  -->
            <div>
                <v-row class="px-3 mt-2">
                    <v-col cols="auto">
                        <h2
                            class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                        "
                        >
                            {{ $t("home.alert_rules") }}
                        </h2>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-tabs v-model="tab" class="justify-center" background-color="transparent">
                            <v-tab>{{ $t("alert_rules.standard") }}</v-tab>
                            <v-tab>{{ $t("alert_rules.custom") }}</v-tab>
                            <v-tab>{{ $t("alert_rules.action") }}</v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </div>
        </v-container>
        <!-- Overview  body -->
        <v-card flat color="transparent" class="px-3">
            <router-view @loading="onLoading"></router-view>
        </v-card>
    </div>
</template>
<script>
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";

export default {
    title: "Alert Rules",
    components: {
        TutorialDialog
    },

    apollo: {},

    data() {
        return {
            tab: 0,
            isLoading: false,
            goTopShow: false,
            tutorialDialog: false
        };
    },

    computed: {},
    watch: {
        me() {
            return helper.me();
        },
        tab(val) {
            switch (val) {
                case 0:
                    this.pushTo("standardrules");
                    break;
                case 1:
                    this.pushTo("customrules");
                    break;
                case 2:
                    this.pushTo("actiongroups");
                    break;
                default:
                    this.pushTo("standardrules");
            }
        },
        $route(to) {
            if (to.query.tab) {
                this.$router.push({ name: to.query.tab, params: to.params });
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        /*  if (!this.$route.query.id) {
            this.pushTo("standardrules");
        } */

        switch (this.$route.name) {
            case "standardrules":
                this.tab = 0;
                break;
            case "customrules":
                this.tab = 1;
                break;
            case "actiongroups":
                this.tab = 2;
                break;
        }
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        }
    }
};
</script>
