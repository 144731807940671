<template>
    <v-container fluid class="pa-0">
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col>
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                            align-top
                        "
                    >
                        {{ $t("templates.trips_templates") }}
                    </h2>
                </v-col>
                <v-spacer></v-spacer>
                <AddTrip
                    v-if="!selectMode"
                    :templateMode="true"
                    @template-created="onTemplateCreated"
                ></AddTrip>
                <v-btn
                    v-if="!selectMode"
                    color="primary"
                    @click="changeSelectMode"
                    rounded
                    >{{ $t("general.select") }}</v-btn
                >
                <div v-else>
                    <v-btn
                        ref="deleteBtn"
                        class="mr-2"
                        color="primary"
                        :loading="isLoading"
                        rounded
                        @click="onDeleteSelectedTemplates"
                    >
                        <v-icon left>
                            delete
                        </v-icon>
                        {{ $t("general.delete") }}
                    </v-btn>
                    <v-btn
                        @click="changeSelectMode"
                        text
                        rounded
                        >{{ $t("general.cancel") }}</v-btn
                    >
                </div>
            </v-row>
        </div>

        <!-- TEMPLATES FILTER/TOOLBAR -->
        <TripTemplatesFilter
            class="mx-3"
            ref="tripTemplateFilter"
            v-on:filter-changed="onFilterChanged"
        >
        </TripTemplatesFilter>
        <!-- TABLE -->
        <TripTemplatesTable
            ref="tripTemplateTable"
            :tripTemplatesFilter="tripTemplateFilter"
            class="mx-3 mt-3 mb-3"
            v-on:click:row="onTripTemplateSelected"
            :selectMode="selectMode"
            @deleted-templates="deleteFinished"
        ></TripTemplatesTable>
        <!-- <LocationsTable
            ref="locationtable"
            :locationsFilter="locationsFilter"
            class="mx-1 mb-3"
            v-on:click:row="onLocationSelected"
        ></LocationsTable> -->
        <!-- TUTORIAL -->
        <!-- <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog> -->

        <!-- detail drawer -->
        <!-- <v-navigation-drawer
            v-model="locationDetailDrawer"
            :width="locationDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <LocationDetail
                    v-if="selectedLocation"
                    :key="selectedLocation.id"
                    :location="selectedLocation"
                    :isFullScreen="isLocationDetailDrawerFullScreen"
                    v-on:changeFullScreen="
                        onLocationDetailDrawerFullScreenChange
                    "
                    v-on:close="locationDetailDrawer = false"
                    v-on:refreshDataTable="refreshTable"
                    v-on:onLocationDeleted="onLocationDeleted"
                ></LocationDetail>
            </div>
        </v-navigation-drawer> -->
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import TripTemplatesFilter from "@/components/triptemplates/TripTemplatesFilter.vue";
import TripTemplatesTable from "@/components/triptemplates/TripTemplatesTable.vue";
import AddTrip from "@/components/trips/create/AddTrip.vue";
export default {
    title: "Trip Templates",
    components: {
        TripTemplatesFilter,
        TripTemplatesTable, 
        AddTrip
    },
    data() {
        return {
            selectMode: false,
            tripTemplateDetailDrawer: false,
            tripTemplateFilter: {
                first: 10
            },
            goTopShow: false,
            isLoading: false,
            selectedTripTemplate: null,
            tripTemplateDetailFullScreen: false,
            openAddTripTemplate: false,
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },
    watch: {},
    created() {
        //TODO: Permission check
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {},
    methods: {  
        deleteFinished() {
            this.isLoading = false;
            this.selectMode = false;
        },
        onDeleteSelectedTemplates() {
            this.isLoading = false;
            this.$refs.tripTemplateTable.deleteSelected();
            this.changeSelectMode();
        },
        changeSelectMode() {
            this.selectMode = !this.selectMode;
        },
        onLoading(val) {
            this.isLoading = val;
        },
        onFilterChanged(filter) {
            this.tripTemplateFilter = filter;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        loadMore() {
            this.isLoading = false;
        },
        onTripTemplateSelected(tripTemplate) {
            this.selectedTripTemplate = tripTemplate;
            this.tripTemplateDetailDrawer = true;
        },
        onTemplateCreated() {
            this.$refs.tripTemplateTable.refreshTable();
        }
    }
};
</script>

<style scoped></style>
