<template>
    <v-card flat>
        <!--- BODY --->
        <v-card-text class="pl-3 py-0">
            <v-row no-gutters>
                <v-col cols="12" lg="8" md="8" sm="12">
                    <!--- MAP --->
                    <l-map
                        ref="map"
                        :zoom="zoom"
                        :center="map_center"
                        :style="{ height: flexHeight + 'px' }"
                        :options="{
                            attributionControl: false,
                            drawControl: false,
                            zoomControl: false,
                            worldCopyJump: true
                        }"
                    >
                        <!--- TILE LAYER --->
                        <l-tile-layer
                            class="mapEdit"
                            :url="here_map_url"
                        ></l-tile-layer>
                        <!--- ZOOM CONTROL --->
                        <l-control-zoom position="bottomright"></l-control-zoom>
                        <!--- RECENTER BUTTON ---->
                        <l-control position="bottomleft">
                            <v-row dense>
                                <v-col cols="2">
                                    <v-btn
                                        @click="recenterMap"
                                        fab
                                        tile
                                        x-small
                                    >
                                        <v-icon color="primary"
                                            >my_location</v-icon
                                        >
                                    </v-btn>
                                </v-col>
                                <v-col
                                    cols="10"
                                    class="pl-1 d-flex"
                                    v-if="triggerRadius"
                                >
                                    <v-card
                                        width="250"
                                        tile
                                        class="pl-1"
                                        elevation="3"
                                        outlined
                                    >
                                        <v-slider
                                            v-model="radius"
                                            :disabled="!isEditingLocation"
                                            max="100000"
                                            min="1000"
                                            thumb-color="primary"
                                            track-color="primary"
                                            hide-details
                                            class="mt-0"
                                            @change="editCircle(radius)"
                                        >
                                            <template v-slot:label>
                                                <span class="ml-1 pr-0"
                                                    >{{ radius }} m</span
                                                >
                                            </template>
                                        </v-slider>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </l-control>
                    </l-map>
                </v-col>
                <v-col cols="12" lg="4" md="4" sm="12">
                    <v-card flat class="mb-15">
                        <v-card-text>
                            <v-form v-model="validate">
                                <v-row
                                    v-if="
                                        title === $t('home.partners_locations')
                                    "
                                >
                                    <v-col cols="12" class="pb-0">
                                        <v-autocomplete
                                            v-model="partnerType"
                                            :items="partnersTypes"
                                            item-text="text"
                                            item-value="value"
                                            :label="
                                                $t('headers.partner_type') + '*'
                                            "
                                            outlined
                                            dense
                                            clearable
                                            :rules="[rules.required]"
                                            :disabled="!isEditingLocation"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        v-if="partnerType !== null"
                                        class="py-0"
                                    >
                                        <v-autocomplete
                                            v-model="tenantPartnerId"
                                            :items="partners.edges"
                                            :no-data-text="
                                                $t('loading.no_available')
                                            "
                                            item-value="node.id"
                                            item-text="node.name"
                                            :label="partnerTypeText"
                                            outlined
                                            dense
                                            cleareable
                                            :loading="
                                                $apollo.queries.tenantPartners
                                                    .loading
                                            "
                                            :disabled="
                                                $apollo.queries.tenantPartners
                                                    .loading ||
                                                    !isEditingLocation
                                            "
                                            :rules="[rules.required]"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        <v-divider></v-divider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <!--- LOCATION NAME --->
                                    <v-col cols="12">
                                        <v-text-field
                                            dense
                                            v-model="locationName"
                                            :disabled="!isEditingLocation"
                                            :rules="[rules.required]"
                                            outlined
                                            hide-details
                                            :label="$t('headers.name') + '*'"
                                            :placeholder="$t('general.example')"
                                        ></v-text-field>
                                    </v-col>
                                    <!--- LOCATION CITY --->
                                    <v-col cols="12">
                                        <v-text-field
                                            dense
                                            v-model="locationCity"
                                            :disabled="!isEditingLocation"
                                            :rules="[rules.required]"
                                            outlined
                                            hide-details
                                            :label="$t('partners.city') + '*'"
                                        ></v-text-field>
                                    </v-col>
                                    <!--- LOCATION COUNTRY --->
                                    <v-col cols="12">
                                        <v-text-field
                                            dense
                                            v-model="locationCountry"
                                            :disabled="!isEditingLocation"
                                            :rules="[rules.required]"
                                            outlined
                                            hide-details
                                            :label="
                                                $t('partners.country') + '*'
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <!--- LOCATION Address --->
                                    <div
                                        v-if="
                                            title !==
                                                $t('partners.public_locations')
                                        "
                                        style="width: 100%;"
                                    >
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="locationFullAddress"
                                                :rules="[rules.required]"
                                                :disabled="!isEditingLocation"
                                                outlined
                                                hide-details
                                                :label="$t('partners.address')"
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <!--- TAGS --->
                                        <v-col
                                            cols="12"
                                            v-if="
                                                title ===
                                                    $t('home.my_locations')
                                            "
                                        >
                                            <v-combobox
                                                v-model="tags"
                                                :items="tagsChoices"
                                                small-chips
                                                multiple
                                                :return-object="false"
                                                item-text="text"
                                                item-value="value"
                                                deletable-chips
                                                dense
                                                outlined
                                                hide-details
                                                :label="$t('headers.tags')"
                                                :disabled="!isEditingLocation"
                                                :menu-props="{
                                                    maxHeight: '150px'
                                                }"
                                            ></v-combobox>
                                        </v-col>
                                        <!-- IS TRACKER STOCK LOCATION v-if="!partner"-->
                                        <v-col cols="12" sm="12" class="py-0">
                                            <v-switch
                                                v-model="isTrackerStockLocation"
                                                :disabled="!isEditingLocation"
                                                hide-details
                                                :label="
                                                    $t('trackers.tracker_stock')
                                                "
                                                class="mt-0"
                                                dense
                                            ></v-switch>
                                        </v-col>

                                        <v-col cols="12" class="py-0">
                                            <v-row no-gutters class="pr-6">
                                                <v-col cols="8" class="pr-0">
                                                    <v-switch
                                                        v-model="publicLocation"
                                                        dense
                                                        hide-details
                                                        :label="
                                                            $t(
                                                                'partners.public_location'
                                                            )
                                                        "
                                                        class="mt-0"
                                                        :disabled="
                                                            !isEditingLocation
                                                        "
                                                    ></v-switch>
                                                </v-col>
                                                <v-col
                                                    cols="4"
                                                    class="mt-n1 px-0"
                                                >
                                                    <v-tooltip bottom class="">
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-btn
                                                                color="primary"
                                                                dark
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                icon
                                                            >
                                                                <v-icon
                                                                    >info</v-icon
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>{{
                                                            $t(
                                                                "partners.public_examples"
                                                            )
                                                        }}</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="12" class="py-0">
                                            <v-row no-gutters class="pr-6">
                                                <v-col cols="8" class="pr-0">
                                                    <v-switch
                                                        v-model="
                                                            showWeatherForecast
                                                        "
                                                        dense
                                                        hide-details
                                                        :label="
                                                            $t(
                                                                'weather.weather_forecast'
                                                            )
                                                        "
                                                        class="mt-0"
                                                        :disabled="
                                                            !isEditingLocation
                                                        "
                                                    ></v-switch>
                                                </v-col>
                                                <v-col
                                                    cols="4"
                                                    class="mt-n1 px-0"
                                                >
                                                    <v-tooltip bottom class="">
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-btn
                                                                color="primary"
                                                                dark
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                icon
                                                            >
                                                                <v-icon
                                                                    >info</v-icon
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>
                                                            {{
                                                                $t(
                                                                    "partners.public_examples"
                                                                )
                                                            }}
                                                        </span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col
                                            cols="12"
                                            class="py-0 mt-3"
                                            v-if="showWeatherForecast"
                                        >
                                            <v-row no-gutters class="pr-6">
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="
                                                            locationMaxTemperature
                                                        "
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :label="
                                                            $t(
                                                                'general.maximum_temperature'
                                                            )
                                                        "
                                                        :style="{
                                                            display: 'block'
                                                        }"
                                                        type="number"
                                                        step="0.25"
                                                        max="50"
                                                        min="-25"
                                                        :disabled="
                                                            !isEditingLocation
                                                        "
                                                        class="mb-4"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="
                                                            locationMinTemperature
                                                        "
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :label="
                                                            $t(
                                                                'general.minimum_temperature'
                                                            )
                                                        "
                                                        :style="{
                                                            display: 'block'
                                                        }"
                                                        type="number"
                                                        step="0.25"
                                                        max="50"
                                                        min="-25"
                                                        :disabled="
                                                            !isEditingLocation
                                                        "
                                                        class="mb-4"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="d-flex justify-end"
                                        >
                                         
                                            <v-btn
                                                v-if="isEditingLocation"
                                                text
                                                @click="resetLocation"
                                                rounded
                                                class="mr-2"
                                                >{{
                                                    $t("general.cancel")
                                                }}</v-btn
                                            >
                                            <v-btn
                                                color="primary"
                                                v-if="isEditingLocation"
                                                @click="updateLocation"
                                                :disabled="!validate"
                                                rounded
                                                :loading="isLoadingLocationEdit"
                                                >{{ $t("general.save") }}</v-btn
                                            >
                                        </v-col>
                                    </div>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <!--- MAP FORM/INPUTS --->
        </v-card-text>
    </v-card>
</template>
<style scoped>
.cursorPen {
    cursor: url("https://img.icons8.com/material-rounded/24/000000/edit--v1.png"),
        default;
}

.map {
    cursor: not-allowed;
}
</style>
<script>
L;
LDraw;
import L from "leaflet";
import LDraw from "leaflet-draw";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import { LMap, LTileLayer, LControl, LControlZoom } from "vue2-leaflet";
import Config from "@/utils/config.json";

export default {
    components: {
        LMap,
        LTileLayer,
        LControl,
        LControlZoom
    },
    props: {
        viewType: {
            type: String,
            default: null
        },
        location: {
            type: Object,
            required: true
        },
        editLocation: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "location"
        }
    },
    apollo: {
        tenantPartners: {
            query: gql`
                query TenantPartners($partnerType: String) {
                    tenantPartners(partnerType: $partnerType) {
                        edges {
                            node {
                                name
                                id
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    partnerType: this.partnerType
                };
            },
            skip() {
                return true;
            },
            result({ data }) {
                this.partners = data.tenantPartners;
            }
        }
    },
    data() {
        return {
            apikey: Config.VUE_APP_MAP_API_KEY, // You can get the API KEY from developer.here.com
            here_map_url: `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=${Config.VUE_APP_MAP_API_KEY}&ppi=320`,
            map_center: [0, 0],
            circle_center: [0, 0],
            zoom: 2,
            radius: 1000,
            publicLocation: false,
            selectedAutoComplete: null,
            searchBarValue: null,
            showMapSearchBar: false,
            searchSuggestions: null,
            selectedSuggestion: null,
            drawingEnabled: false,
            featureGroup: null,
            layer: null,
            automaticCircle: null,
            drawControl: null,
            drawOptions: {
                polygon: false,
                marker: false,
                polyline: false,
                circlemarker: false,
                rectangle: false,
                circle: {
                    allowIntersection: false,
                    showArea: true,
                    metric: true,
                    showRadius: true,
                    repeatMode: false
                }
            },

            isTrackerStockLocation: false,
            showWeatherForecast: false,
            locationName: null,
            locationCountry: null,
            locationType: null,
            locationCity: null,
            locationCode: null,
            locationFullAddress: null,
            locationMaxTemperature: null,
            locationMinTemperature: null,
            locationLatitude: null,
            locationLongitude: null,
            tags: [],
            tagsChoices: [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ],
            type: "",
            isLoadingLocationEdit: false,
            isEditingLocation: false,
            triggerRadius: false,
            headerChoices: [
                {
                    text: "",
                    align: "center",
                    value: "action",
                    width: "5px",
                    sortable: false
                },
                {
                    text: this.$t("headers.name"),
                    align: "center",
                    value: "name",
                    sortable: false
                },
                {
                    text: this.$t("headers.address"),
                    align: "center",
                    value: "location.address",
                    sortable: false
                },
                {
                    text: this.$t("partners.country"),
                    align: "center",
                    value: "location.country",
                    sortable: false
                },
                {
                    text: this.$t("partners.city"),
                    align: "center",
                    value: "location.city",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "edit",
                    width: "5px",
                    sortable: false
                }
            ],
            partnerType: null,
            partnersTypes: [
                {
                    text: this.$t("headers.consignor"),
                    value: "consignor"
                },
                {
                    text: this.$t("headers.consignee"),
                    value: "consignee"
                },
                {
                    text: this.$t("headers.forwarder"),
                    value: "forwarder"
                },
                {
                    text: this.$t("home.other"),
                    value: "other"
                },
                {
                    text: this.$t("headers.carrier"),
                    value: "carrier"
                }
            ],
            partners: { edges: [] },
            tenantPartnerId: null,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            },
            validate: false
        };
    },
    computed: {
        partnerTypeText() {
            return (
                this.partnersTypes.find(type => type.value === this.partnerType)
                    .text + "*" || ""
            );
        },
        flexHeight() {
            let dimension = 0;
            let breakHeight = this.$vuetify.breakpoint.height;
            dimension = breakHeight * 0.68;

            return dimension;
        },
        screenSize() {
            let size = this.$vuetify.breakpoint.name;
            return size;
        }
    },
    created() {
        if (this.location.locationType?.toLowerCase() === "tenant_location") {
            this.publicLocation = true;
        } else {
            this.publicLocation = false;
        }
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.formatTemperature = helper.formatTemperature;
        this.fahrenheitToCelsius = helper.fahrenheitToCelsius;
    },
    async mounted() {
        this.initializeEditing();
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "location_map_svw"
        });
        this.setUserProperties();
    },
    updated() {
        //fix map render everytime the component updates
        this.resizeMap();
        this.allowDrawing(false);
        /* if (this.drawingEnabled == true) {
            this.allowDrawing(true);
        } */
    },

    watch: {
        partnerType(val) {
            this.partners = { edges: [] };
            if (val) {
                this.$apollo.queries.tenantPartners.skip = false;
                this.$apollo.queries.tenantPartners.setVariables({
                    partnerType: val
                });
                this.$apollo.queries.tenantPartners.refetch();
            } else {
                this.$apollo.queries.tenantPartners.skip = true;
            }
        },
        async selectedSuggestion(id) {
            if (
                this.selectedSuggestion == null ||
                this.selectedSuggestion == undefined
            )
                return;
            /*** IF USER SELECTS ONE OF THE SUGGESTIONS, ASSIGN IT TO MAP LOCATION/CENTER ***/
            let response = await fetch(
                "https://lookup.search.hereapi.com/v1/lookup?id=" +
                    id +
                    "&apiKey=" +
                    this.apikey
            );
            const map = this.$refs.map.mapObject;
            let data = await response.json();

            this.map_center = data.position;
            this.circle_center = data.position;
            this.locationLatitude = data.position.lat;
            this.locationLongitude = data.position.lng;
            this.locationCountry = data.address.countryName;
            this.locationCity = data.address.city;
            this.locationCode = data.address.postalCode;
            this.locationFullAddress = data.address.label;
            map.setZoom(13);
            map.panTo(this.map_center);
            this.allowDrawing(true);
        },
        searchBarValue() {
            this.debounce();
        },
        editLocation(val) {
            this.isEditingLocation = val;
        },
        isEditingLocation(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit("update:editLocation", newVal);
            }
        },
        locationMaxTemperature(newVal) {
            if (newVal > 100) {
                this.locationMaxTemperature = 100;
            }
            if (newVal < -100) {
                this.locationMaxTemperature = -100;
            }
        },
        locationMinTemperature(newVal) {
            if (newVal > 100) {
                this.locationMinTemperature = 100;
            }
            if (newVal < -100) {
                this.locationMinTemperature = -100;
            }
        }
    },
    methods: {
         setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("location_map_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                });
            }else{
                this.$gtag.event("location_map_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        recenterMap() {
            const map = this.$refs.map.mapObject;
            if (this.locationLatitude && this.locationLongitude)
                map.panTo([this.locationLatitude, this.locationLongitude]);
        },
        resizeMap() {
            /*** THIS FUNCTION IS RESPONSIBLE FOR FIXING MAP RENDERING ISSUE(only 1 or 2 tiles in the corner), RUN IT ON THE RIGHT LIFECYCLE HOOK ***/
            setTimeout(() => {
                //mapObject is an object which gives you acess to leaflet methods
                this.$refs.map?.mapObject?.invalidateSize();
            }, 50);
        },
        editCircle(radius) {
            const map = this.$refs.map.mapObject;
            if (this.automaticCircle != null) {
                map.removeLayer(this.automaticCircle);
                this.automaticCircle = null;
            }
            this.drawingEnabled = true;
            this.featureGroup.clearLayers();

            this.layer = L.circle(this.circle_center, {
                radius: radius
            });
            //then add new drawn layer.
            this.featureGroup.addLayer(this.layer);
            //Event listener for when editing:
            this.layer.on("edit", e => {
                this.circle_center = e.target.getLatLng();
                this.locationLatitude = e.target.getLatLng().lat;
                this.locationLongitude = e.target.getLatLng().lng;
                this.radius = e.target.getRadius();
            });
        },
        //update the event listener for the circle when the user drag the circle
        initializeEditing() {
            this.$nextTick(() => {
                this.locationID = this.location.id;
                this.isTrackerStockLocation = this.location.isTrackerStockLocation;
                this.showWeatherForecast =
                    this.location.weatherForecastSubscription?.enabled || false;
                this.locationName = this.location.name;
                this.locationCountry = this.location.country;
                this.locationCity = this.location.city;
                this.locationFullAddress = this.location.fullAddress;
                this.locationMaxTemperature = this.formatTemperature(
                    this.location.weatherForecastSubscription?.maxTemperature ||
                        0
                );
                this.locationMinTemperature = this.formatTemperature(
                    this.location.weatherForecastSubscription?.minTemperature ||
                        0
                );
                this.partnerType =
                    this.location.tenantPartner?.partnerType || null;
                this.tenantPartnerId = this.location.tenantPartner?.id || null;

                this.radius = this.location.radius;
                if (this.location.tags) {
                    this.tags = JSON.parse(
                        this.location.tags.replace(/'/g, '"')
                    );
                }

                if (this.location?.latitude && this.location?.longitude) {
                    this.locationLatitude = this.location.latitude;
                    this.locationLongitude = this.location.longitude;

                    this.map_center = [
                        this.location.latitude,
                        this.location.longitude
                    ];
                    this.circle_center = [
                        this.location.latitude,
                        this.location.longitude
                    ];

                    let _radiusScale = this.radius / 100000;

                    let northEast = new L.latLng(
                        parseFloat(this.location?.latitude) + _radiusScale,
                        parseFloat(this.location?.longitude) + _radiusScale
                    );
                    let southWest = new L.latLng(
                        parseFloat(this.location?.latitude) - _radiusScale,
                        parseFloat(this.location?.longitude) - _radiusScale
                    );
                    let bounds = new L.latLngBounds(northEast, southWest);

                    this.$refs.map?.mapObject?.setZoom(12);
                    this.$refs.map?.mapObject?.fitBounds(bounds, {
                        padding: [50, 50]
                    });
                }

                this.allowDrawing(true);
            });
        },
        resetLocation() {
            // Remove drawn layers //
            const map = this.$refs.map.mapObject;
            if (this.layer != null) map.removeLayer(this.layer);
            if (this.drawingEnabled == true) this.allowDrawing(false);
            if (this.automaticCircle != null) this.automaticCircle = null;
            this.featureGroup?.clearLayers();

            // Reset zoom //
            map.setZoom(2);
            // Reset variables value //
            this.map_center = [this.location.latitude, this.location.longitude];
            this.isTrackerStockLocation = this.location.isTrackerStockLocation;
            this.showWeatherForecast = this.location.showWeatherForecast;
            this.locationName = this.location.name;
            this.locationCountry = this.location.country;
            this.locationCity = this.location.city;
            this.locationFullAddress = this.location.fullAddress;
            this.locationMaxTemperature = this.location.maxTemperature;
            this.locationMinTemperature = this.location.minTemperature;
            this.partnerType = this.location.tenantPartner?.partnerType || null;
            this.tenantPartnerId = this.location.tenantPartner?.id || null;

            if (this.location.tags) {
                this.tags = JSON.parse(this.location.tags.replace(/'/g, '"'));
            } else {
                this.tags = [];
            }
            this.radius = this.location.radius;

            if (this.location?.latitude && this.location?.longitude) {
                this.locationLatitude = this.location.latitude;
                this.locationLongitude = this.location.longitude;
                let _radiusScale = this.radius / 100000;

                this.map_center = [
                    this.location.latitude,
                    this.location.longitude
                ];
                this.circle_center = [
                    this.location.latitude,
                    this.location.longitude
                ];

                let northEast = new L.latLng(
                    parseFloat(this.location?.latitude) + _radiusScale,
                    parseFloat(this.location?.longitude) + _radiusScale
                );
                let southWest = new L.latLng(
                    parseFloat(this.location?.latitude) - _radiusScale,
                    parseFloat(this.location?.longitude) - _radiusScale
                );
                let bounds = new L.latLngBounds(northEast, southWest);

                this.$refs.map?.mapObject?.setZoom(12);
                this.$refs.map?.mapObject?.fitBounds(bounds, {
                    padding: [50, 50]
                });
            }

            this.allowDrawing(true);
            this.drawingEnabled = false;
            this.removeDrawControl();
            this.searchBarValue = null;
            this.showMapSearchBar = false;
            this.selectedSuggestion = null;
            // Close dialog //
            this.isEditingLocation = false;
        },
        allowDrawing(createCircleAuto) {
            if (!this.$refs.map) return;
            const map = this.$refs.map.mapObject;
            if (this.featureGroup == null) {
                // Add new FeatureGroup from leaflet for Draw objects on map. Group together elements of the maps including: markers, geoJSON, polylines and polygon, tooltip and popup.
                this.featureGroup = new window.L.FeatureGroup();
                this.featureGroup.addTo(map);
            }
            // Create leaflet draw control menu
            if (this.drawControl == null && this.isEditingLocation == true) {
                this.drawControl = new window.L.Control.Draw({
                    position: "topright",
                    draw: this.drawOptions,
                    edit: {
                        featureGroup: this.featureGroup,
                        remove: true,
                        edit: {
                            selectedPathOptions: {
                                color: "#000",
                                fillColor: "#000"
                            }
                        }
                    }
                });
                // Add draw control to the map
                map.addControl(this.drawControl);
            }
            if (createCircleAuto) {
                if (this.automaticCircle == null) {
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });
                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                } else {
                    this.drawingEnabled = true;
                    this.featureGroup.clearLayers();
                    this.automaticCircle = L.circle(this.circle_center, {
                        radius: this.radius
                    });
                    //then add new drawn layer.
                    this.featureGroup.addLayer(this.automaticCircle);
                    //Event listener for when editing:
                    this.automaticCircle.on("edit", e => {
                        this.handleEventData(e);
                    });
                }
            } else {
                //edit handles events when the user drags the circle or edit circle radius.
                this.drawingEnabled = true;
                this.featureGroup.clearLayers();
                this.automaticCircle = L.circle(this.circle_center, {
                    radius: this.radius
                });
                //then add new drawn layer.
                this.featureGroup.addLayer(this.automaticCircle);
                //Event listener for when editing:
                this.automaticCircle.on("edit", e => {
                    this.handleEventData(e);
                });
            }

            //capture when the drag and draw circle is clicked
            map.on("draw:created", e => {
                this.triggerRadius = true;
                this.handleEventData(e);
            });
            //capture when the drag circle is edited
            map.on("draw:editstart", () => {
                this.triggerRadius = true;
            });
            //capture when edit stop.
            map.on("draw:editstop", () => {
                this.triggerRadius = false;
            });
            //capture the event when circle is deleted and reset the values.
            map.on("draw:deleted", () => {
                this.triggerRadius = false;
                this.locationLatitude = null;
                this.locationLongitude = null;
                this.radius = null;
                this.circle_center = [12, 30];
                this.automaticCircle = null;
                this.featureGroup.clearLayers();
                this.drawingEnabled = false;
                this.isTrackerStockLocation = false;
                this.showWeatherForecast = false;
                this.locationName = null;
                this.locationCountry = null;
                this.locationCity = null;
                this.locationCode = null;
                this.locationFullAddress = null;
                this.locationMaxTemperature = null;
                this.locationMinTemperature = null;
            });
        },
        handleEventData(event) {
            // HANDLE DATA COMING FROM DRAWING FINISHED AND EDITING CIRCLE
            this.radius = event.layer
                ? event.layer._mRadius
                : event.target?._mRadius;
            this.locationLatitude = event.layer
                ? event.layer._latlng.lat
                : event.target?._latlng?.lat;
            this.locationLongitude = event.layer
                ? event.layer._latlng.lng
                : event.target?._latlng?.lng;
            this.getlocationData(this.locationLatitude, this.locationLongitude);
        },
        async autoCompleteHandler() {
            /*** GENERATE SUGGESTIONS WHILE USER IS TYPING ***/
            if (this.searchBarValue == null || this.searchBarValue == undefined)
                return;
            let formatedInput = this.searchBarValue.replace(/\s/g, "+");
            let response = await fetch(
                "https://autocomplete.search.hereapi.com/v1/autocomplete?q=" +
                    formatedInput +
                    "&apiKey=" +
                    this.apikey
            );
            let data = await response.json();
            this.searchSuggestions = data.items.map(item => {
                return item;
            });
        },
        async mapSearchHandler() {
            /*** RUN A QUERY FOR USER MANUAL/RAW INPUT ***/
            let response = await fetch(
                "https://geocode.search.hereapi.com/v1/geocode?q=" +
                    this.searchBarValue +
                    "&apiKey=" +
                    this.apikey
            );
            let data = await response.json();
            this.map_center = data.items[0].position;
        },
        async getlocationData(lat, lng) {
            /*** GET COORDINATES FROM CLICK EVENT AND ASSIGN THEM TO CIRCLE POSITION ***/
            this.circle_center = [lat, lng];
            /*** GET INFO FROM API WITH THOSE COORDINATES ***/
            let response = await fetch(
                "https://revgeocode.search.hereapi.com/v1/revgeocode?at=" +
                    lat +
                    "%2C" +
                    lng +
                    "&lang=en-US&apiKey=" +
                    this.apikey
            );
            let data = await response.json();
            /*** ASSIGN RECEIVED DATA TO VARIABLES ***/
            this.locationCountry = data?.items[0]?.address?.countryName;
            this.locationCity = data?.items[0]?.address?.city;
            this.locationCode = data?.items[0]?.address?.postalCode;
            this.locationFullAddress = data?.items[0]?.address?.label;
            this.locationLatitude = lat;
            this.locationLongitude = lng;
        },
        debounce(timeout = 500) {
            clearTimeout(this.timeOutID);
            this.timeOutID = setTimeout(() => {
                this.autoCompleteHandler();
            }, timeout);
        },
        updateLocation() {
            let payload;
            this.isLoadingLocationEdit = true;
            let maxTemperatureCalculated = this.calculateTemp(
                this.locationMaxTemperature
            );
            let minTemperatureCalculated = this.calculateTemp(
                this.locationMinTemperature
            );

            if (this.title === this.$t("home.my_locations")) {
                payload = {
                    id: this.locationID,
                    locationType: this.publicLocation
                        ? "tenant_location"
                        : "private_location",
                    isTrackerStockLocation: this.isTrackerStockLocation,
                    showWeatherForecast: this.showWeatherForecast,
                    name: this.locationName,
                    city: this.locationCity,
                    tags: this.tags,
                    state: null,
                    country: this.locationCountry,
                    fullAddress: this.locationFullAddress,
                    maxTemperature: maxTemperatureCalculated,
                    minTemperature: minTemperatureCalculated,
                    geometryType: null,
                    latitude: this.locationLatitude,
                    longitude: this.locationLongitude,
                    radius: this.radius ? this.radius : 1000
                };
            } else if (this.title === this.$t("home.partners_locations")) {
                payload = {
                    id: this.locationID,
                    tenantPartnerId: this.tenantPartnerId,
                    locationType: "partner_location",
                    isTrackerStockLocation: this.isTrackerStockLocation,
                    showWeatherForecast: this.showWeatherForecast,
                    name: this.locationName,
                    city: this.locationCity,
                    state: null,
                    country: this.locationCountry,
                    fullAddress: this.locationFullAddress,
                    maxTemperature: maxTemperatureCalculated,
                    minTemperature: minTemperatureCalculated,
                    geometryType: null,
                    latitude: this.locationLatitude,
                    longitude: this.locationLongitude,
                    radius: this.radius ? this.radius : 1000
                };
            }

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateLocation(
                            $input: UpdateTenantLocationInput!
                        ) {
                            updateTenantLocation(input: $input) {
                                location {
                                    id
                                    name
                                    locationType
                                    isTrackerStockLocation
                                    fullAddress
                                    city
                                    state
                                    tags
                                    country
                                    geometryType
                                    latitude
                                    longitude
                                    radius
                                    polygon
                                    locationType
                                    tenantPartner {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(() => {
                    this.isLoadingLocationEdit = false;
                    this.isEditingLocation = false;
                    this.removeDrawControl();
                    this.$emit("refreshDataTable");
                });
        },
        removeDrawControl() {
            const map = this.$refs.map.mapObject;
            map.removeControl(this.drawControl);
            this.triggerRadius = false;
            this.drawControl = null;
        },
        calculateTemp(temp) {
            if (this.getTemperatureSymbol() === "ºF") {
                temp = this.fahrenheitToCelsius(parseFloat(temp));
            }
            if (this.getTemperatureSymbol() === "ºC") {
                temp = parseFloat(temp);
            }
            return temp;
        }
    }
};
</script>
<style scoped>
.maxHeight {
    min-height: 500px;
    max-height: 1000px;
}
</style>
