<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5 transparent"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                outlined
            >
                <v-row align="start" class="d-flex align-center justify-center transparent">
                    <v-col>
                        <SearchBar v-model="filter.search" :label="label"></SearchBar>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>
<script>
import helper from "@/utils/helper.js";
// import FilterMenu from "@/components/base/FilterMenu.vue";
import SearchBar from "@/components/base/SearchBar.vue";

export default {
    components: { //AddUserDialog, AddStaffDialog 
        SearchBar
    },
    props: {
        tenant: {
            type: Object,
            default: null
        },
        isColdChaStaff: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: "Search"
        }
    },
    apollo: {},
    data() {
        return {
            fab: false,
            isLoading: false,
            shouldStickyFilter: false,
            openAddUser: false,
            openAddStaff: false,

            // graphql query filter
            filter: {
                first: 15,
                after: null,
                search: null,
                tenantId: this.tenant ? this.tenant.id : null,
                orderBy: this.tenant ? "-is_admin" : null
            }
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        // enableOrDisableRemove() {
        //     return this.selectedUsers?.length > 0 ? false : true;
        // },
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                    helper.setFilterValues("userTableFilters", value);
                }
            },
            deep: true
        },
        "filter.search": {
            handler(value) {
                if(value==="") {
                    this.filter.search = null;
                }
                    
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.onResize();
        let _filter = helper.getFilterValues("userTableFilters");
        if (_filter) {
            this.filter.search = _filter.search;
        }
    },
    methods: {
        onResize() {
            // if (this.tenant) {
            //     this.shouldStickyFilter = false;
            // } else
            //     this.shouldStickyFilter =
            //         window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
