<template>
    <v-card flat class="mt-2 mb-1">
        <!-- <v-row dense class="d-flex justify-center px-8" v-if="trigger && !toEdit">
            <TriggerCard
                :trigger="trigger"
                :partnersToApplyAlert="partnersToApplyAlert"
                :notifyForEveryRecord="notifyForEveryRecord"
                :applyAllNewTrips="applyAllNewTrips"
                v-on:removeTrigger="closeAndResetInput"
                v-on:editTrigger="editTrigger"
                :createDialog="true"
            ></TriggerCard>
        </v-row> -->
        <v-row
            dense
            class="d-flex justify-start align-start ml-5 grey lighten-4"
            v-if="!triggerType"
        >
            <v-col cols="12" sm="12" md="3" lg="3">
                <v-card
                    elevation="2"
                    class="my-2 py-5 d-flex align-center justify-center"
                    :color="triggerType === 'sensor' ? 'primary' : ''"
                    @click="updateTriggerType('sensor')"
                >
                    <span class="black--text subtitle-1">
                        <v-icon color="black">settings_remote</v-icon>
                        {{ $t("trips.sensor") }}
                    </span>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3">
                <v-card
                    elevation="2"
                    class="my-2 py-5 d-flex align-center justify-center"
                    :color="triggerType === 'location' ? 'primary' : ''"
                    @click="updateTriggerType('location')"
                >
                    <span class="black--text subtitle-1">
                        <v-icon color="black">share_location</v-icon>
                        {{ $t("trips.geofence") }}
                    </span>
                </v-card>
            </v-col>
        </v-row>
        <div v-else>
            <div v-if="triggerType === 'sensor'">
                <v-stepper v-model="stepNo" flat class="mt-0 px-4">
                    <v-stepper-header class="elevation-0 grey lighten-4">
                        <v-stepper-step
                            :complete="stepNo > 1"
                            step="1"
                            editable
                        >
                            {{ $t("trips.sensor") }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :editable="isValidSensor"
                            :complete="stepNo > 2"
                            step="2"
                        >
                            {{ $t("general.timer") }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="stepNo > 3"
                            :editable="isValidSensor && isValidTimer"
                            step="3"
                        >
                            {{ $t("trips.geofence") }}
                            <small>{{ $t("general.optional") }}</small>
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1" class="pa-0 ma-0">
                            <v-card
                                flat
                                class="pl-0 grey lighten-4"
                                max-height="600"
                            >
                                <v-card-text class=""
                                    ><SensorForm
                                        ref="sensorForm"
                                        :Sensor="Sensor"
                                        v-on:updateSensorType="updateSensorType"
                                    >
                                    </SensorForm>
                                    <v-row class="d-flex mt-4"
                                        ><v-col
                                            cols="6"
                                            v-for="sensor in Sensor"
                                            :key="sensor.Type"
                                        >
                                            <SensorConditionForm
                                                ref="sensorConditionForm"
                                                :type="sensor.Type"
                                                :condition="sensor.Condition"
                                                :sensorValue.sync="sensor.Value"
                                                :sensorValue2.sync="
                                                    sensor.Value2
                                                "
                                                v-on:updateSensor="updateSensor"
                                            >
                                            </SensorConditionForm>
                                        </v-col> </v-row
                                ></v-card-text>
                                <v-card-actions class="mt-0 pt-0"
                                    ><v-btn
                                        text
                                        rounded
                                        @click="closeAndResetInput"
                                        outlined
                                    >
                                        {{ $t("general.reset") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        :disabled="!isSensorTrigger"
                                        @click="stepNo++"
                                    >
                                        {{ $t("general.next") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-content step="2" class="pa-0 ma-0">
                            <v-card
                                max-height="600"
                                flat
                                class="py-0 pl-0 grey lighten-4"
                            >
                                <v-card-text class="py-0"
                                    ><TimerForm
                                        ref="timerForm"
                                        v-on:update-time="updateTimeValidation"
                                        :RemainMinutes="RemainMinutes"
                                        :timerFormat="timerFormat"
                                        :notifyForEveryRecord.sync="
                                            notifyForEveryRecord
                                        "
                                        v-on:updateTimer="updateRemainMinutes"
                                        v-on:updateNotifyForEveryRecord="
                                            updateNotifyForEveryRecord
                                        "
                                    >
                                    </TimerForm
                                ></v-card-text>
                                <v-card-actions class="mt-0 pt-0">
                                    <v-btn
                                        text
                                        rounded
                                        @click="closeAndResetInput"
                                        outlined
                                    >
                                        {{ $t("general.reset") }}
                                    </v-btn>

                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="ml-2"
                                        rounded
                                        text
                                        @click="stepNo--"
                                    >
                                        {{ $t("general.back") }}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        :disabled="
                                            !isValidTimer
                                        "
                                        @click="stepNo++"
                                    >
                                        {{ $t("general.next") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-content step="3" class="pa-0 ma-0">
                            <v-card
                                max-height="800"
                                class="p-0 pl-0 grey lighten-4"
                                flat
                            >
                                <v-card-text class="py-0"
                                    ><LocationForm
                                        ref="locationForm"
                                        :Location.sync="locationTrigger"
                                        :isSensorTrigger.sync="isSensorTrigger"
                                        v-on:updateLocation="updateLocation"
                                        :triggerCopy="triggerCopy.Location"
                                        :editMode="editModeTrigger"
                                    >
                                    </LocationForm
                                ></v-card-text>

                                <v-card-actions class="mt-0 pt-0 justify-end">
                                    <v-btn
                                        text
                                        rounded
                                        @click="closeAndResetInput"
                                        outlined
                                    >
                                        {{ $t("general.reset") }}
                                    </v-btn>

                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="ml-2"
                                        rounded
                                        text
                                        @click="stepNo--"
                                    >
                                        {{ $t("general.back") }}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        :disabled="
                                            !isValidLocation && !isValidSensor
                                        "
                                        :loading="isSaving"
                                        @click="saveTrigger"
                                    >
                                        {{ $t("general.continue") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </div>
            <div v-if="triggerType == 'location'">
                <v-stepper v-model="stepNo" flat class="mt-0 px-4">
                    <v-stepper-header class="elevation-0 grey lighten-4">
                        <v-stepper-step
                            :complete="stepNo > 1"
                            step="1"
                            editable
                        >
                            {{ $t("trips.geofence") }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :editable="isValidSensor"
                            :complete="stepNo > 2"
                            step="2"
                        >
                            {{ $t("general.timer") }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1" class="pa-0 ma-0">
                            <v-card
                                max-height="800"
                                class="py-0 pl-0 grey lighten-4"
                                flat
                            >
                                <v-card-text
                                    ><LocationForm
                                        ref="locationForm"
                                        :Location.sync="locationTrigger"
                                        :isSensorTrigger.sync="isSensorTrigger"
                                        v-on:updateLocation="updateLocation"
                                        :triggerCopy="triggerCopy.Location"
                                        :editMode="editModeTrigger"
                                    >
                                    </LocationForm
                                ></v-card-text>
                                <v-card-actions class="mt-0 pt-0 ">
                                    <v-btn
                                        text
                                        rounded
                                        @click="closeAndResetInput"
                                        outlined
                                    >
                                        {{ $t("general.reset") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        :disabled="!isValidLocation"
                                        @click="stepNo++"
                                    >
                                        {{ $t("general.next") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-content step="2" class="pa-0 ma-0">
                            <v-card
                                max-height="600"
                                class="pt-0 pl-0 grey lighten-4"
                                flat
                            >
                                <v-card-text class=""
                                    ><TimerForm
                                        ref="timerForm"
                                        :RemainMinutes="RemainMinutes"
                                        :timerFormat="timerFormat"
                                        v-on:update-time="updateTimeValidation"
                                        :notifyForEveryRecord.sync="
                                            notifyForEveryRecord
                                        "
                                        v-on:updateTimer="updateRemainMinutes"
                                        v-on:updateNotifyForEveryRecord="
                                            updateNotifyForEveryRecord
                                        "
                                    >
                                    </TimerForm
                                ></v-card-text>
                                <v-card-actions class="mt-0 pt-0 justify-end">
                                    <v-btn
                                        text
                                        rounded
                                        @click="closeAndResetInput"
                                        outlined
                                    >
                                        {{ $t("general.reset") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="ml-2"
                                        text
                                        rounded
                                        @click="stepNo--"
                                    >
                                        {{ $t("general.back") }}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        :disabled="!isValidLocation || !isValidTimer"
                                        :loading="isSaving"
                                        @click="saveTrigger"
                                    >
                                        {{ $t("general.continue") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </div>
        </div>
    </v-card>
</template>
<script>
import helper from "@/utils/helper";
import SensorForm from "@/components/alertrules/forms/secondary/SensorForm";
import SensorConditionForm from "@/components/alertrules/forms/secondary/SensorConditionForm";
import LocationForm from "@/components/alertrules/forms/secondary/LocationForm";
import TimerForm from "@/components/alertrules/forms/secondary/TimerForm";

// import TriggerCard from "@/components/alertrules/other/TriggerCard.vue";

export default {
    name: "AddTriggerForm",
    props: {
        trigger: {
            type: Object,
            default: null
        },
        notifyForEveryRecord: {
            type: Boolean
        },
        applyAllNewTrips: {
            type: Boolean
        },
        partnersToApplyAlert: {
            type: Object
        },
        editModeTrigger: {
            type: Boolean,
            default: false
        }
    },
    components: {
        SensorForm,
        SensorConditionForm,
        LocationForm,
        TimerForm
        // TriggerCard
    },
    data() {
        return {
            toEdit: false,
            editSensor: false,
            triggerType: null,
            stepNo: 1,
            isSensorTrigger: false,
            openSensorConditionForm: false,
            isValidSensor: false,
            isSaving: false,
            isValidLocation: false,
            resetTriggerForm: false,
            triggerCopy: {
                Sensor: null,
                Location: null,
                RemainMinutes: null,
                timerFormat: null
            },
            Sensor: [],
            Location: {
                Partners: [],
                Public: false,
                Origin: false,
                Destination: false,
                Departure: false,
                Arrival: false,
                InTransit: false,
                Custom: [],
                Condition: null
            },
            RemainMinutes: null,
            timerFormat: null,
            isValidTimer: false,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v =>
                    /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    apollo: {},
    computed: {
        me() {
            return helper.me();
        },
        locationTrigger: {
            get() {
                let _triggerLocation = {};
                _triggerLocation = {
                    Partners: this.Location?.Partners || [],
                    Public: this.Location?.Public || false,
                    Origin: this.Location?.Origin || false,
                    Destination: this.Location?.Destination || false,
                    Departure: this.Location?.Departure || false,
                    Arrival: this.Location?.Arrival || false,
                    InTransit: this.Location?.InTransit || false,
                    Custom: this.Location?.Custom || [],
                    Condition: this.Location?.Condition || null
                };
                return _triggerLocation;
            },
            set(val) {
                this.Location = {
                    Partners: val.Partners,
                    Public: val.Public,
                    Origin: val.Origin,
                    Destination: val.Destination,
                    Departure: val.Departure,
                    Arrival: val.Arrival,
                    InTransit: val.InTransit,
                    Custom: val.Custom,
                    Condition: val.Condition
                };
            }
        },
        editSensorTrigger() {
            return this.triggerCopy?.Sensor?.length > 0;
        },
        getSensors() {
            return this.sensor;
        }
    },
    mounted() {
        if (this.editMode) {
            this.editTrigger();
        }
    },
    watch: {
        Sensor(val) {
            if (val.length > 0) {
                this.isSensorTrigger = true;
            } else {
                this.isSensorTrigger = false;
            }
        },
        editModeTrigger: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.triggerCopy = {
                        Sensor: this.sensorToFrontendSensor(
                            this.trigger?.Sensor
                        ),
                        Location: this.trigger?.Location || null,
                        RemainMinutes: this.trigger?.RemainMinutes,
                        timerFormat: this.trigger?.timerFormat
                    };
                    this.editTrigger();
                }
            },
            deep: true
        }
    },
    methods: {
        editTrigger() {
            this.triggerCopy = {
                Sensor: this.sensorToFrontendSensor(this.trigger?.Sensor),
                Location: this.trigger?.Location || null,
                RemainMinutes: this.trigger?.RemainMinutes
            };
            this.stepNo = 1;
            this.Sensor = this.sensorToFrontendSensor(this.trigger?.Sensor);
            this.Location = this.trigger.Location;
            this.triggerType = this.trigger.TriggerType;
            this.RemainMinutes = this.trigger.RemainMinutes;
            this.timerFormat = this.trigger.timerFormat;
            this.isSensorTrigger = this.trigger.Sensor.length > 0;
            this.isValidSensor = this.trigger.Sensor.length > 0;
            this.isValidLocation =
                this.trigger.Location.Partners.length > 0 ||
                this.trigger.Location.Public ||
                this.trigger.Location.Origin ||
                this.trigger.Location.Destination ||
                this.trigger.Location.Departure ||
                this.trigger.Location.Arrival ||
                this.trigger.Location.InTransit ||
                this.trigger.Location.Custom.length > 0;
        },
        updateNotifyForEveryRecord(val) {
            this.$emit("updateNotifyForEveryRecord", val);
        },
        updateApplyAllNewTrips(value) {
            this.$emit("updateApplyAllNewTrips", value);
        },
        updatePartnersToApplyAlert(partners) {
            this.$emit("updatePartnersToApplyAlert", partners);
        },
        closeAndResetInput() {
            if (this.toEdit) {
                this.toEdit = false;
                this.Sensor = this.sensorToFrontendSensor(this.trigger.Sensor);
                //this.Location = this.trigger.Location;
                this.RemainMinutes = this.trigger.RemainMinutes;
                this.timerFormat = this.trigger.timerFormat;
                this.isSensorTrigger = this.trigger.Sensor.length > 0;
                this.isValidSensor = this.trigger.Sensor.length > 0;
                this.isValidLocation =
                    this.trigger.Location.Partners.length > 0 ||
                    this.trigger.Location.Public ||
                    this.trigger.Location.Origin ||
                    this.trigger.Location.Destination ||
                    this.trigger.Location.Departure ||
                    this.trigger.Location.Arrival ||
                    this.trigger.Location.InTransit ||
                    this.trigger.Location.Custom.length > 0 ||
                    this.trigger.Location.Condition != null;

                this.stepNo = 1;
                this.$emit("resetNotifyForEveryRecord");
                this.$emit("resetApplyAllNewTrips");
                this.$emit("resetPartnersToApplyAlert");
            } else {
                this.Sensor = [];
                this.Location.Partners = [];
                this.Location.Public = false;
                this.Location.Origin = false;
                this.Location.Destination = false;
                this.Location.Arrival = false;
                this.Location.InTransit = false;
                this.Location.Departure = false;
                this.Location.Custom = [];
                this.Location.Condition = null;
                this.RemainMinutes = null;
                this.timerFormat = null;
                this.stepNo = 1;
                this.isSensorTrigger = false;
                this.openSensorConditionForm = false;
                this.isValidSensor = false;
                this.isValidLocation = false;
                if (this.$refs.sensorForm) this.$refs.sensorForm.reset();
                this.$refs.locationForm?.reset();
                this.$refs.timerForm?.reset();
                this.triggerType = null;
                this.$emit("resetNotifyForEveryRecord");
                this.$emit("resetApplyAllNewTrips");
                this.$emit("resetPartnersToApplyAlert");
                this.$emit("removeTrigger");
                this.resetTriggerForm = true;
            }
        },
        saveTrigger(param) {
            let _Sensor = [];
            this.Sensor.map(sensor => {
                if (sensor.Condition == "out") {
                    _Sensor.push({
                        Type: sensor.Type,
                        Condition: "out",
                        Value: [sensor.Value, sensor.Value2]
                    });
                } else if (sensor.Condition == "in") {
                    _Sensor.push({
                        Type: sensor.Type,
                        Condition: "in",
                        Value: [sensor.Value, sensor.Value2]
                    });
                } else {
                    _Sensor.push({
                        Type: sensor.Type,
                        Condition: sensor.Condition,
                        Value: [sensor.Value]
                    });
                }
            });
            if (
                this.Location.Partners.length == 0 &&
                !this.Location.Public &&
                !this.Location.Origin &&
                !this.Location.Destination &&
                !this.Location.Departure &&
                !this.Location.Arrival &&
                !this.Location.InTransit &&
                this.Location.Custom.length == 0
            )
                this.Location.Condition == null;

            let trigger = {
                TriggerType: this.triggerType,
                Sensor: _Sensor,
                Location: this.Location,
                RemainMinutes: this.RemainMinutes,
                timerFormat: this.timerFormat
            };
            if (param == "update") {
                this.$emit("updateTrigger", trigger);
            } else {
                this.$emit("saveTrigger", trigger);
            }

            this.toEdit = false;

            // this.$apollo
            //     .mutate({
            //         mutation: gql`
            //             mutation CreateTenantAlertRuleTrigger(
            //                 $input: CreateTenantAlertRuleTriggerInput!
            //             ) {
            //                 createTenantAlertRuleTrigger(input: $input) {
            //                     tenantAlertRule {
            //                         id
            //                     }
            //                 }
            //             }
            //         `,
            //         variables: {
            //             input: {
            //                 alertRuleId: this.alertRule.id,
            //                 triggerJson: JSON.stringify(trigger)
            //             }
            //         }
            //     })
            //     .then(() => {
            //         this.$emit("refreshTriggersTable");
            //         this.closeAndResetInput();
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         this.errorMessage = true;
            //     })
            //     .finally(() => {
            //         this.isSaving = false;
            //     });
        },
        updateSensorType(val) {
            this.Sensor = val;
            if (val.length == 0) {
                this.isValidSensor = false;
            }
            this.saveTrigger("update");
        },
        updateSensor(val) {
            let _hasNullCondition = false;
            this.Sensor.map((sensor, i) => {
                if (sensor.Type === val.type) {
                    this.Sensor[i].Type = val.type;
                    this.Sensor[i].Condition = val.condition;
                    this.Sensor[i].Value = val.value;
                    if(val.hasOwnProperty("value2")){
                        this.Sensor[i].Value2 = val.value2;
                    }
                }
                if (this.Sensor[i].Condition == null) {
                    _hasNullCondition = true;
                }
            });
            this.isValidSensor = !_hasNullCondition;
            this.saveTrigger("update");
        },
        updateLocation(val) {
            this.locationTrigger = val;
            if (
                val.Partners.length > 0 ||
                val.Public ||
                val.Origin ||
                val.Destination ||
                val.Departure ||
                val.Arrival ||
                val.InTransit ||
                val.Custom.length > 0 ||
                val.Condition != null
            ) {
                this.isValidLocation = true;
            } else {
                this.isValidLocation = false;
            }
            this.saveTrigger("update");
        },
        timeValidate(v, format) {
            //parse int
            if (v == null || v == "") return true;
            if (format == "hours") {
                if (v > 24) {
                    return false;
                }
            } else if (format == "days") {
                if (v > 60) {
                    return false;
                }
            } else if (format == "minutes") {
                if (v > 60) {
                    return false;
                }
            }
            return true;
        },
        updateRemainMinutes(val) {
            if (val == null) {
                this.RemainMinutes = null;
                this.timerFormat = null;
                this.saveTrigger("update");
                return;
            }
            this.RemainMinutes = val.RemainMinutes;
            this.timerFormat = val.timerFormat;
            this.saveTrigger("update");
        },
        updateTriggerType(val) {
            this.triggerType = val;
            this.saveTrigger("update");
        },
        sensorToFrontendSensor(sensor) {
            let _sensor = [];
            sensor.map(s => {
                if (s.Condition == "in" || s.Condition == "out") {
                    _sensor.push({
                        Type: s.Type,
                        Condition: s.Condition,
                        Value: s.Value[0],
                        Value2: s.Value[1]
                    });
                } else {
                    _sensor.push({
                        Type: s.Type,
                        Condition: s.Condition,
                        Value: s.Value[0]
                    });
                }
            });

            return _sensor;
        },
        updateTimeValidation(val) {
            this.isValidTimer = val;
        }
    }
};
</script>
<style scoped>
.scroll {
    overflow-y: scroll;
}
.heightCard {
    height: 400px;
}
</style>
