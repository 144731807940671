import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"mb-2"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("headers.notification")))]),_c(VSpacer),(!_vm.editMode)?_c(VBtn,{attrs:{"color":"primary","elevation":"0","icon":""},on:{"click":function($event){_vm.editMode = !_vm.editMode}}},[_c(VIcon,[_vm._v("edit")])],1):_vm._e(),(_vm.editMode)?_c(VBtn,{staticClass:"mr-1",attrs:{"text":""},on:{"click":_vm.cancelActions}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]):_vm._e(),(_vm.editMode)?_c(VBtn,{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("general.save")))]):_vm._e()],1),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"headers d-flex align-center",attrs:{"cols":"4"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("general.email_notification"))+" ")])]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"8"}},[_c(VSwitch,{attrs:{"hide-details":"","inset":"","disabled":!_vm.editMode},model:{value:(_vm.notification.email),callback:function ($$v) {_vm.$set(_vm.notification, "email", $$v)},expression:"notification.email"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDivider)],1)],1),_c(VRow,[_c(VCol,{staticClass:"headers d-flex align-center",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t("general.in_app_notification"))+" ")]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"8"}},[_c(VSwitch,{attrs:{"hide-details":"","inset":"","disabled":!_vm.editMode},model:{value:(_vm.notification.inApp),callback:function ($$v) {_vm.$set(_vm.notification, "inApp", $$v)},expression:"notification.inApp"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }