<template>
    <v-card class="background" elevation="0" >
        <!-- V-CARD -->
        <LoadingBar
            v-if="$apollo.queries.trips.loading && !hasData"
        ></LoadingBar> 
        <PartnerTripForm
            v-else
            :trips="trips.edges"
            :searchInput.sync="searchInput"
        >
            <template v-slot:sortBy>
                <v-select
                    v-model="sortBy"
                    :items="sortByChoices"
                    :label="$t('general.sort_by')"
                    :item-value="item => item.code"
                    :item-text="item => item.name"
                    clearable
                    outlined
                    hide-details
                    dense
                    color="primary"
                >
                    <template v-slot:append-outer>
                        <v-btn
                            class="mt-n1"
                            small
                            icon
                            :disabled="!sortBy"
                            @click="sortAsc = !sortAsc"
                        >
                            <v-icon color="grey darken-2">{{
                                sortAsc ? "north" : "south"
                            }}</v-icon>
                        </v-btn>
                    </template>
                </v-select>
            </template>
            <template v-slot:loadMore>
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.trips.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    {{ $t("loading.load_more") }}
                </v-btn>
            </template>
        </PartnerTripForm>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import PartnerTripForm from "@/components/partners/forms/PartnerTripForm.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";
import helper from "@/utils/helper";

export default {
    name: "PreviewPartnerTripsTable",
    components: { 
        PartnerTripForm, 
        LoadingBar 
    },
    props: {
        partnerTripsFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    apollo: {
        trips: {
            query: gql`
                query trips(
                    $before: String
                    $after: String
                    $first: Int
                    $last: Int
                    $referenceNumberIexact: String
                    $referenceNumberIcontains: String
                    $transportModeIexact: String
                    $transportModeIcontains: String
                    $transportModeIn: [String]
                    $plannedDepartureDate: DateTime
                    $plannedDepartureDateGte: DateTime
                    $plannedDepartureDateLte: DateTime
                    $plannedArrivalDate: DateTime
                    $plannedArrivalDateGte: DateTime
                    $plannedArrivalDateLte: DateTime
                    $orderBy: String
                    $tenantPartnerId: ID
                    $tenantCarrier: ID
                    $status: String
                    $statusIn: [String]
                    $search: String
                ) {
                    trips(
                        before: $before
                        after: $after
                        first: $first
                        last: $last
                        referenceNumber_Iexact: $referenceNumberIexact
                        referenceNumber_Icontains: $referenceNumberIcontains
                        tripPartnerSet_TenantPartner: $tenantPartnerId
                        tenantCarrier: $tenantCarrier
                        transportMode_Iexact: $transportModeIexact
                        transportMode_Icontains: $transportModeIcontains
                        transportMode_In: $transportModeIn
                        plannedDepartureDate: $plannedDepartureDate
                        plannedDepartureDate_Gte: $plannedDepartureDateGte
                        plannedDepartureDate_Lte: $plannedDepartureDateLte
                        plannedArrivalDate: $plannedArrivalDate
                        plannedArrivalDate_Gte: $plannedArrivalDateGte
                        plannedArrivalDate_Lte: $plannedArrivalDateLte
                        orderBy: $orderBy
                        status: $status
                        status_In: $statusIn
                        search: $search
                    ) {
                        edges {
                            node {
                                id
                                plannedArrivalDate
                                plannedDepartureDate
                                referenceNumber
                                status
                                transportMode
                            }
                        }
                        pageInfo {
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                    }
                }
            `,
            variables() {
                let _request={
                    // search: this.searchInput,
                    referenceNumberIcontains: this.searchInput,
                    ...this.filter,
                    ...this.partnerTripsFilter
                };
            
                return _request;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trips,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 800
        }
    },
    data() {
        return {
            loading: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            searchInput: null,
            trips: { edges: [] },
            openAddPartnerUser: false,
            headerChoices: null,
            filter: {
                first: 10,
                orderBy: null,
            },
            sortBy: null,
            sortAsc: true
        };
    }, 
    mounted() {
        //by default sort by status 
        this.sortBy = "status";
    },
    created() {},
    computed: {
        hasData() {
            return this.trips?.edges?.length > 0;
        },
        hasMoreData() {
            return this.trips?.pageInfo?.hasNextPage;
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "referenceNumber",
                    name: this.$t("headers.reference_number")
                },
                {
                    code: "status",
                    name: this.$t("headers.status")
                },
                {
                    code: "plannedDepartureDate",
                    name: this.$t("headers.planned_departure")
                },
                {
                    code: "plannedArrivalDate",
                    name: this.$t("headers.planned_arrival")
                }
            ];
            return _sortByChoices;
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        }
    },
    methods: {
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.trips?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.trips?.pageInfo?.hasNextPage) {
                this.$apollo.queries.trips.fetchMore({
                    variables: {
                        after: this.trips.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
