<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col cols="5" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :hint="this.$t('general.search_module')"
                                v-model="searchInput"
                                append-icon="search"
                                :label="this.$t('general.search')"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>

                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="4" sm="5" lg="4" class="py-2 pr-4 text-right">
                    <v-row class="d-flex align-center justify-end">
                        <!-- ADD -->
                        <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            class="mr-1"
                            @click="savePermissions"
                            :disabled="enableOrDisablePermissionBtn"
                            :loading="isLoadingSavePermission"
                        >
                            <v-icon left small>add_box</v-icon>
                            {{ $t("general.save_permissions") }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :loading="$apollo.queries.partnerPermisssions.loading"
                    :items="computedPermissions"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    hide-default-footer
                    loader-height="2"
                >
                    <!-- CHECKBOX COLUMN -->
                    <template v-slot:item.action="{ item }">
                        <v-checkbox multiple v-model="selectedPermissions" class="mx-0" :value="item"></v-checkbox>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-col></v-col>
    </div>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import {
    TripPermissions,
    // TrackerPermissions,
    CarrierPermissions,
    TenantPermissions,
    BookingPermissions
} from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "PartnerPermissionsTab",
    components: {},
    props: {
        partner: {
            type: Object,
            required: true
        }
    },
    apollo: {
        partnerPermisssions: {
            query: gql`
                query TenantPartner($id: ID!) {
                    tenantPartner(id: $id) {
                        id
                        name
                        organisationPermissions
                    }
                }
            `,
            variables() {
                return {
                    id: this.partner ? this.partner.id : null
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantPartner;
            },
            result({ loading, data }) {
                if (!loading) {
                    let _permissions = JSON.parse(data.tenantPartner.organisationPermissions);

                    this.selectedPermissions = [];
                    if (Array.isArray(_permissions)) {
                        _permissions.forEach(item => {
                            this.permissions.forEach(ele => {
                                if (ele.value === item) {
                                    this.selectedPermissions.push(ele);
                                }
                            });
                        });
                    }

                    this.selectedPermissionsCopy = [...this.selectedPermissions];

                    this.registerWatcher();
                }
            },
            skip: false
        }
    },
    data() {
        return {
            /* --------> USER INPUT DATA <-------- */
            isLoadingSavePermission: false,
            enableOrDisablePermissionBtn: true,
            selectedPermissions: [],
            selectedPermissionsCopy: [],
            searchInput: "",
            /* ---- TABLE HEADERS ---- */
            headerChoices: [
                {
                    text: "",
                    align: "left",
                    value: "action",
                    width: "5px",
                    sortable: false
                },
                {
                    text: this.$t("general.module"),
                    align: "left",
                    value: "module",
                    sortable: false
                },
                {
                    text: this.$t("general.permission"),
                    align: "left",
                    value: "permission",
                    sortable: false
                },
                {
                    text: this.$t("general.description"),
                    align: "left",
                    value: "description",
                    sortable: false
                }
            ],

            /* ---- TABLE DATA ---- */
            permissions: [
                {
                    module: this.$t("partners.trip_carrier"),
                    permission: "View",
                    description: this.$t("partners.carrier_description"),
                    value: CarrierPermissions.VIEW_CARRIER,
                    managePermission: CarrierPermissions.MANAGE_CARRIER
                },
                {
                    module: this.$t("partners.trip_documents"),
                    permission: "View",
                    description: this.$t("partners.documents_description"),
                    value: TripPermissions.VIEW_TRIP_DOCUMENT,
                    managePermission: TripPermissions.MANAGE_TRIP_DOCUMENT
                },
                {
                    module: this.$t("partners.trip_trackers"),
                    permission: "View",
                    description: this.$t("partners.trackers_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT_TRACKER,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT_TRACKER
                },
                {
                    module: this.$t("partners.trip_freights"),
                    permission: "View",
                    description: this.$t("partners.trackers_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT
                },
                {
                    module: this.$t("partners.trip_partners"),
                    permission: "View",
                    description: this.$t("partners.partners_description"),
                    value: TripPermissions.VIEW_TRIP_PARTNER,
                    managePermission: TripPermissions.MANAGE_TRIP_PARTNER
                },
                {
                    module: this.$t("partners.trip_map"),
                    permission: "View",
                    description: this.$t("partners.map_description"),
                    value: TenantPermissions.VIEW_TENANT_LOCATION,
                    managePermission: TenantPermissions.MANAGE_TENANT_LOCATION
                },
                {
                    module: this.$t("partners.trip_sea"),
                    permission: "View",
                    description: this.$t("partners.sea_description"),
                    value: TripPermissions.VIEW_TRIP_SEA_TRANSPORT,
                    managePermission: TripPermissions.MANAGE_TRIP_SEA_TRANSPORT
                },
                {
                    module: this.$t("partners.trip_alert"),
                    permission: "View",
                    description: this.$t("partners.alert_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT_ALERT,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT_ALERT
                },
                {
                    module: this.$t("partners.trip_alert"),
                    permission: "Resolve",
                    description: this.$t("partners.alert_resolve"),
                    value: TripPermissions.EDIT_TRIP_FREIGHT_ALERT
                },
                {
                    module: this.$t("partners.trip_alert"),
                    permission: "Resolve",
                    description: this.$t("partners.alert_resolve"),
                    value: TripPermissions.EDIT_TRIP_FREIGHT_ALERT
                },
                {
                    module: this.$t("partners.trip_timeline"),
                    permission: "View",
                    description: this.$t("partners.timeline_description"),
                    value: TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE,
                    managePermission: TripPermissions.MANAGE_TRIP_FREIGHT_TIMELINE
                },
                {
                    module: this.$t("partners.trip_report"),
                    permission: "View",
                    description: this.$t("partners.trip_report_view_description"),
                    value: TripPermissions.VIEW_TRIP_REPORT,
                    managePermission: TripPermissions.MANAGE_TRIP_REPORT
                },
                {
                    module: this.$t("partners.trip_report"),
                    permission: "Create",
                    description: this.$t("partners.trip_report_create_description"),
                    value: TripPermissions.CREATE_TRIP_REPORT,
                    managePermission: TripPermissions.MANAGE_TRIP_REPORT
                },
                {
                    module: this.$t("partners.qx_booking"),
                    permission: "View",
                    description: this.$t("partners.qx_booking_description"),
                    value: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                },
                {
                    module: this.$t("partners.qx_booking"),
                    permission: "Create",
                    description: this.$t("partners.qx_booking_create_description"),
                    value: BookingPermissions.CREATE_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                },
                {
                    module: this.$t("partners.qx_booking"),
                    permission: "Edit",
                    description: this.$t("partners.qx_booking_edit_description"),
                    value: BookingPermissions.EDIT_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                }
            ]
        };
    },
    beforeCreate() {
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        // this.TrackerPermissions = TrackerPermissions;
        this.TripPermissions = TripPermissions;
        this.CarrierPermissions = CarrierPermissions;
        this.TenantPermissions = TenantPermissions;
    },
    watch: {},
    computed: {
        computedPermissions() {
            // console.log(this.permissions)
            let tt = this.permissions.filter(c => this.hasOrganisationPermission(c?.managePermission))
            console.log(tt)
            return this.permissions.filter(c => this.hasOrganisationPermission(c?.managePermission));
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "partner_locations_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("partner_permissions_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("partner_permissions_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        registerWatcher() {
            this.$watch(
                "selectedPermissions",
                function(newValue) {
                    this.enableOrDisablePermissionBtn = true;
                    let newValueStr = newValue
                        .map(item => {
                            return item.module;
                        })
                        .join("-");
                    let selectedPermissionsCopyStr = this.selectedPermissionsCopy
                        .map(item => {
                            return item.module;
                        })
                        .join("-");
                    if (newValueStr !== selectedPermissionsCopyStr) {
                        this.enableOrDisablePermissionBtn = false;
                    }
                },
                { deep: true }
            );
        },
        savePermissions() {
            // Initialize Loading :
            this.isLoadingSavePermission = true;
            // Initialize Mutation :
            let permissionList = [...new Set(this.selectedPermissions.map(item => item.value))];
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation CreateTenantPartnerPermission($input: CreateTenantPartnerPermissionInput!) {
                            createTenantPartnerPermission(input: $input) {
                                tenantPartner {
                                    id
                                    name
                                    organisationPermissions
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            partnerId: this.partner.id,
                            permissions: JSON.stringify(permissionList)
                        }
                    }
                })
                .then(res => {
                    // Emit event to tabel data and load new partners //
                    this.$apollo.queries.partnerPermisssions.refresh();
                    const payload = {
                        color: "blue",
                        message: this.$t("partners.partner_saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    if (error.message == "INVALID_REFERENCE_NUMBER") this.error = true;
                    // this.isLoadingSavePermission = false;
                })
                .finally(() => {
                    this.enableOrDisablePermissionBtn = true;
                    this.isLoadingSavePermission = false;
                });
        }
    }
};
</script>
