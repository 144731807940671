<template>
    <div v-if="!canAccess">
        <NoPermission></NoPermission>
    </div>
    <div v-else>
        <v-container fluid class="pa-0">
            <!-- USERS FILTER/TOOLBAR -->
            <UsersFilter
                class="mx-3 mt-3"
                v-on:updateUserTable="refetchTableData"
                v-on:filter-changed="onFilterChanged"
                :tenant="tenant"
            ></UsersFilter>
            <!-- TABLE -->
            <TenantUsersTable
                ref="usersTable"
                :usersFilter="usersFilter"
                v-on:click:row="onUserSelected"
                :tenant="tenant"
                class="mx-1"
            ></TenantUsersTable>
        </v-container>
    </div>
</template>
<script>
import helper from "@/utils/helper.js";
import UsersFilter from "@/components/users/UsersFilter.vue";
import TenantUsersTable from "@/components/tenants/other/TenantUsersTable.vue";
import { TenantPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import Config from "@/utils/config.json";

export default {
    components: {
        UsersFilter,
        TenantUsersTable,
        NoPermission
    },
    props: {
        tenant: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            searchInput: null,
            shouldStickyFilter: false,
            userDetailDrawer: false,
            userDetailFullScreen: false,
            usersFilter: {
                first: 20,
                orderBy: "-is_admin"
            },
            goTopShow: false,
            isLoading: false,
            menu: false,
            selectedUser: null
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        userDetailDrawerWidth() {
            if (this.userDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isUserDetailDrawerFullScreen() {
            return this.userDetailDrawerWidth === "100%";
        },
        canAccess() {
            if (this.me?.isSuperuser) return true;

            if (
                !this.hasPermission(TenantPermissions.VIEW_TENANT_USER) ||
                !this.me?.isStaff
            )
                return false;

            return true;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TenantPermissions = TenantPermissions;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.onResize();
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'tenant_users_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("tenant_users_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("tenant_users_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        onUserSelected(user) {
            this.selectedUser = user;
            this.userDetailDrawer = true;
        },

        onUserDetailDrawerFullScreenChange(val) {
            this.userDetailFullScreen = val;
        },

        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },

        onLoading(val) {
            this.isLoading = val;
        },

        onFilterChanged(filter) {
            this.usersFilter = filter;
        },

        refetchTableData() {
            this.$refs.usersTable.$apollo.queries.users.refetch();
        },

        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        }
    }
};
</script>
<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
