<template>
    <LoadingBar
        v-if="tracker == null && $apollo.queries.tracker.loading"
    ></LoadingBar>
    <div v-else>
        <v-card fill-height flat width="100%" v-if="tracker" color="background">
            <!-- top bar -->
            <v-toolbar flat color="transparent">
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
                <v-btn
                    v-if="shouldShowFullScreenButton"
                    icon
                    @click="changeFullScreen"
                >
                    <v-icon>{{
                        isFullScreen ? "fullscreen_exit" : "crop_free"
                    }}</v-icon>
                </v-btn>
                <!-- tracker status -->
                <div class="ml-3">
                    <div class="font-weight-medium d-flex align-center">
                        <v-icon
                            v-if="
                                trackerStatusText ==
                                    this.$t('general.not_activated')
                            "
                            small
                            left
                            color="grey"
                            >circle</v-icon
                        >
                        <v-icon
                            v-else-if="
                                trackerStatusText == this.$t('general.active')
                            "
                            small
                            left
                            color="success"
                            >circle</v-icon
                        >
                        <v-icon v-else small left color="error">circle</v-icon>
                        {{ trackerStatusText }}
                    </div>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon :disabled="!(canDelete && !isTrackerActivated)">
                    <v-icon @click="openDeleteDialog = true">
                        delete
                    </v-icon>
                </v-btn>
                <!-- TAB FILTERS -->
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display_modules')"
                    :choices.sync="filterChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                    class="mr-0"
                >
                </FilterMenu>
            </v-toolbar>

            <!-- tracker header -->
            <v-sheet
                class="
                pt-0
                mb-5
                px-5
                background
                d-flex
                align-center
                justify-space-between
            "
            >
                <h3>{{ tracker ? tracker.serialNumber : "" }}</h3>

                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

                <v-select
                    v-model="viewType"
                    :items="viewTypeChoices"
                    dense
                    item-text="name"
                    item-value="code"
                    return-value
                    outlined
                    hide-details
                    class="fit"
                ></v-select>
            </v-sheet>

            <!---- DELETE PARTNER DIALOG ---->
            <DeleteTrackerDialog
                :openDeleteTrackerDialog="openDeleteDialog"
                :trackerId="tracker.id"
                v-on:refreshDataTable="closeDrawerAndRefresh"
                v-on:closeDeleteTrackerDialog="openDeleteDialog = false"
            >
            </DeleteTrackerDialog>

            <!-- tab view -->
            <TrackerDetailTabView
                v-if="viewType === 'tab'"
                :tracker="tracker"
                :trip="trip"
                :selectedFilters="selectedFilters"
                :disabledEdit="disabledEdit"
                :isAvailable="isAvailable"
                v-on:updateTracker="updateTracker"
            />

            <!-- list view -->
            <TrackerDetailListView
                v-if="viewType === 'list'"
                class="mx-5 pb-10"
                :tracker="tracker"
                :trip="trip"
                :selectedFilters="selectedFilters"
            />
        </v-card>
        <!-- else show a v-card displaying that didnt find what looking for -->
        <v-card fill-height flat width="100%" v-else color="background">
            <v-toolbar flat color="transparent">
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
            </v-toolbar>
            <NotFoundDetailDrawer type="tracker" style="padding-top: 100px;">
            </NotFoundDetailDrawer>
        </v-card>
    </div>
</template>

<script>
import FilterMenu from "@/components/base/FilterMenu.vue";
import NotFoundDetailDrawer from "../base/NotFoundDetailDrawer.vue";
import TrackerDetailTabView from "@/components/trackers/TrackerDetailTabView.vue";
import TrackerDetailListView from "@/components/trackers/TrackerDetailListView.vue";
import DeleteTrackerDialog from "@/components/trackers/delete/DeleteTrackerDialog.vue";
import moment from "moment";
import helper from "../../utils/helper";
import { TrackerPermissions } from "@/core/enum";
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        trackerProp: {
            type: Object
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        trackerId: {
            type: String
        }
    },
    components: {
        FilterMenu,
        TrackerDetailTabView,
        TrackerDetailListView,
        DeleteTrackerDialog,
        LoadingBar,
        NotFoundDetailDrawer
    },
    apollo: {
        tracker: {
            query: gql`
                query Tracker($trackerId: ID!) {
                    tracker(id: $trackerId) {
                        id
                        stockLocation {
                            id
                            name
                        }
                        serialNumber
                        brand
                        model
                        mainTracker
                        isBeacon
                        activatedDate
                        expiryDate
                        packageNumber
                        comment
                        isArchived
                        lastConnectionTime
                        lastBatteryDeviceTime
                        lastBatteryValue
                        lastTemperatureValue
                        lastTemperatureDeviceTime
                        lastExternalTemperatureValue
                        lastExternalTemperatureDeviceTime
                        lastProbeTemperatureValue
                        lastProbeTemperatureDeviceTime
                        lastHumidityDeviceTime
                        lastHumidityValue
                        lastLightDeviceTime
                        lastLightValue
                        lastShockDeviceTime
                        lastShockValue
                        lastLocationDeviceTime
                        tenant {
                            id
                            name
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return { trackerId: this.trackerId };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tracker,
            result(data) {
                if (data.data.tracker) {
                    this.trackerDetail = Object.assign(
                        {},
                        this.trackerProp,
                        data.data.tracker
                    );
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return this.tracker?.id ? true : false;
            }
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TrackerPermissions = TrackerPermissions;
    },
    data() {
        return {
            openDeleteDialog: false,
            viewType: "tab",
            trackerDetail: null,
            isAvailable: false,
            trip: {
                id: null,
                referenceNumber: null,
                loading: false
            },
            disabledEdit: true,
            viewTypeChoices: [
                { code: "tab", name: this.$t("general.tabs") },
                { code: "list", name: this.$t("general.list") }
            ],

            defaultFilterChoices: [
                {
                    //for filter menu
                    code: "details",
                    name: "Details",
                    tag: "headers.details",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "charts",
                    name: "Charts",
                    tag: "headers.charts",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "map",
                    name: "Map",
                    tag: "headers.map",
                    enabled: true
                }
            ],

            filterChoices: []
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        tracker() {
            return this.trackerDetail ? this.trackerDetail : this.trackerProp;
        },
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];
            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        trackerStatusText() {
            if (this.tracker.activatedDate == null) {
                return this.$t("general.not_activated");
            } else {
                return this.$t("general.activated");
            }
        },
        isStaff() {
            return this.me.isStaff || false;
        },
        isTrackerActivated() {
            return (
                this.tracker.lastConnectionTime != null ||
                this.tracker.activatedDate != null
            );
        },
        canDelete() {
            if (this.me?.isSuperuser) return true;

            if (
                this.me?.isStaff &&
                this.hasPermission(TrackerPermissions.DELETE_TRACKER)
            )
                return true;

            return false;
        }
    },
    watch: {
        tracker: {
            immediate: true,
            handler: function(val) {
                if (val != null) {
                    this.getTrackerTrip();
                }
            },
            deep: true
        }
    },
    created() {
        this.viewType = helper.getViewType("trackerDetailViewType");
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "trackerDetailFilterChoices"
        );
    },
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    trackerDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    trackerDetailFilterChoices: value
                });
            },
            { deep: true }
        );
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'tracker_details_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("tracker_details_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("tracker_details_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
            // this.$refs.tabs.callSlider();
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updateTracker(tracker) {
            this.trackerDetail = tracker;
            this.$emit("refreshDataTable");
        },
        getTrackerTrip() {
            this.disabledEdit = true;
            this.trip.loading = true;
            this.$apollo
                .query({
                    query: gql`
                        query trips($tripFreightTrackerSetTracker: ID!) {
                            trips(
                                tripFreightTrackerSet_Tracker: $tripFreightTrackerSetTracker
                            ) {
                                edges {
                                    node {
                                        id
                                        referenceNumber
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        tripFreightTrackerSetTracker:
                            this.trackerDetail?.id || this.trackerProp?.id
                    },
                    fetchPolicy: "no-cache"
                })
                .then(result => {
                    if (result && result?.data) {
                        this.isAvailable = false;

                        let trips = result.data.trips.edges;
                        this.trip.id = trips[0]?.node?.id;
                        this.trip.referenceNumber =
                            trips[0]?.node?.referenceNumber;
                        this.trip.loading = false;
                        this.disabledEdit = false;
                    } else {
                        this.isAvailable = true;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>
<style scoped>
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
</style>
