import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.openAddActionGroupDialog),callback:function ($$v) {_vm.openAddActionGroupDialog=$$v},expression:"openAddActionGroupDialog"}},[_c(VCard,{staticClass:"pb-3"},[_c(VCardTitle,{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.$t("alert_rules.create_action_group"))+" ")]),_c(VCardText,{staticClass:"pt-4 mb-2"},[_c(VForm,{ref:"addActionGroupForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{staticClass:"mt-6",attrs:{"dense":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"mx-2",attrs:{"label":_vm.$t('alert_rules.action_name') + '*',"rules":[_vm.rules.required],"outlined":""},model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=$$v},expression:"groupName"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ActionGroupAddUserForm',{staticClass:"mx-2",model:{value:(_vm.users),callback:function ($$v) {_vm.users=$$v},expression:"users"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"mx-2",attrs:{"label":_vm.$t('headers.external_email_address'),"rules":[
                                _vm.email.length > 0 ? _vm.rules.email : function () { return true; }
                            ],"outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"4"}},[_c(VCheckbox,{staticClass:"mx-4 mt-3 text-no-wrap",attrs:{"label":_vm.$t('headers.email_notification'),"hide-details":""},model:{value:(_vm.shouldEmailNotification),callback:function ($$v) {_vm.shouldEmailNotification=$$v},expression:"shouldEmailNotification"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"4"}},[_c(VCheckbox,{staticClass:"mx-4 mt-3 text-no-wrap",attrs:{"label":_vm.$t('headers.inbox_notification'),"hide-details":""},model:{value:(_vm.shouldInboxNotification),callback:function ($$v) {_vm.shouldInboxNotification=$$v},expression:"shouldInboxNotification"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{staticClass:"mr-3",attrs:{"disabled":!_vm.valid,"color":"primary","rounded":"","loading":_vm.isLoadingAddActionGroup},on:{"click":_vm.addActionGroup}},[_vm._v(_vm._s(_vm.$t("general.add")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }