<template>
    <v-container>
        <h4 class="mb-2">
            {{ $t("alert_rules.would_like") }}
        </h4>
        <v-row dense class="d-flex justify-start">
            <v-col cols="6" sm="6" md="3" lg="3">
                <v-card
                    elevation="2"
                    class="my-2 py-5 d-flex align-center justify-center"
                    @click="isTimer = false"
                    :color="!isTimer ? 'primary' : 'white'"
                >
                    <span :class="!isTimer ? 'white--text' : 'black-text'">
                        {{ $t("general.immediately") }}
                    </span>
                </v-card></v-col
            >
            <v-col cols="6" sm="6" md="3" lg="3">
                <v-card
                    elevation="2"
                    class="my-2 py-5 d-flex align-center justify-center"
                    @click="isTimer = true"
                    :color="isTimer ? 'primary' : 'white'"
                >
                    <span :class="isTimer ? 'white--text' : 'black-text'">
                        {{ $t("alert_rules.persists") }}
                    </span>
                </v-card></v-col
            >
            <v-col cols="12" sm="2" md="4">
                <v-form v-model="isValidTimer">
                    <div class="d-flex mt-5">
                        <v-autocomplete
                            v-model="timerType"
                            :items="timerTypes"
                            :disabled="!isTimer"
                            outlined
                            dense
                            label="Time format"
                            @change="changeTimer"
                        > </v-autocomplete>
                        <v-text-field
                            v-model.number="timerValue"
                            :disabled="!isTimer"
                            :rules="[rules.required]"
                            class="mx-2"
                            outlined
                            persistent-hint
                            dense
                        ></v-text-field>
                    </div > 
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="d-flex justify-start">
            <v-col cols="12" sm="6" md="6">
                <v-checkbox
                    v-model="valueNotifyForEveryRecord"
                    :label="$t('alert_rules.notify_record') + ' '"
                    class="text-no-wrap"
                ></v-checkbox> </v-col
        ></v-row>
    </v-container>
</template>
<script>
import helper from "@/utils/helper";
export default {
    props: ["RemainMinutes", "notifyForEveryRecord", "timerFormat"],
    data() {
        return {
            isTimer: null,
            timerType: null,
            isValidTimer: false,
            timerTypes:["days", "hours", "minutes"],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v =>
                    /.+@.+\..+/.test(v) || this.$t("rules.email"),
                timeValidate: () => {
                    //parse int  
                    if (this.timerType == "hours") {
                        if(this.timerValue>23){
                            return this.$t("rules.hourValidate")
                        }
                    } else if (this.timerType == "minutes") {
                        if(this.timerValue > 59){
                            return this.$t("rules.minuteValidate")
                        }
                    }
                    return true;
                }
            }    
        };
    },
    computed: {
        sensorType() {
            return this.sensorTypeChoices.find(
                sensorTypeChoice => sensorTypeChoice.value === this.type
            );
        },
        timerValue: {
            get() {
                let result = 0; 
                if (this.RemainMinutes) {  
                    switch(this.timerType){
                        case "days":
                            result = this.RemainMinutes / 1440 || 0;
                            break;
                        case "hours":
                            result = this.RemainMinutes / 60 || 0;
                            break;
                        case "minutes":
                            result = this.RemainMinutes || 0;
                            break;
                    }
                }
                return result;
            },
            set(val) {
                
                if (val == null || val == "") {
                    this.$emit("updateTimer", {
                        RemainMinutes: val,
                        timerFormat: this.timerType
                    });
                    return;
                }
                let _timer = 0;
                switch(this.timerType){
                    case "days":
                        _timer = val * 1440;
                        break;
                    case "hours":
                        _timer = val * 60;
                        break;
                    case "minutes":
                        _timer = val;
                        break;
                }
                
                this.$emit("updateTimer", {
                    RemainMinutes: _timer,
                    timerFormat: this.timerType
                });
            }
        },
        valueNotifyForEveryRecord: {
            get() {
                return this.notifyForEveryRecord;
            },
            set(val) {
                this.$emit("updateNotifyForEveryRecord", val); 
            }
        }
    },
    created() {
        this.isTimer = this.RemainMinutes != null && this.RemainMinutes > 0;
        /* this.timerValue = this.RemainMinutes / 60 || null; */
    },
    mounted() {},
    watch: {
        isTimer() {
            if (!this.isTimer) {
                this.$emit("updateTimer", null);
            } else {
                this.$emit("updateTimer", {
                    RemainMinutes: this.RemainMinutes,
                    timerFormat: this.timerFormat || "hours"
                });
            }
        },
        isValidTimer(val) {
            this.$emit("update-time", val)
        },
        timerFormat:{
            handler: function (val) {
                if (val) {
                    this.timerType = val;
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        reset() {
            this.isTimer = false;
            this.timerValue = null;
        },
        changeTimer(val){
           if(val != this.timerFormat){
                this.timerValue = 0;
           }
        } 
    }
};
</script>
