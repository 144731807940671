<template>
    <v-dialog
        max-width="1000"
        v-model="openAddTriggerDialog"
        persistent
        scrollable
    >
        <v-card flat>
            <!--- ICON AND TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("alert_rules.add_trigger") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-4">
                <v-stepper v-model="stepNo" class="elevation-0">
                    <v-stepper-header class="elevation-0">
                        <v-stepper-step
                            :complete="stepNo > 1"
                            step="1"
                            editable
                        >
                            {{ $t("trips.sensor")
                            }}<small>{{ $t("general.optional") }}</small>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="stepNo > 2"
                            step="2"
                            editable
                        >
                            {{ $t("general.optional") }}
                            <small>{{ $t("general.optional") }}</small>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :editable="isValidLocation || isValidSensor"
                            :complete="stepNo > 3"
                            step="3"
                        >
                            {{ $t("general.timer") }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1" class="pa-0">
                            <v-card>
                                <v-card-text
                                    ><SensorForm
                                        ref="sensorForm"
                                        :Sensor="Sensor"
                                        v-on:updateSensorType="updateSensorType"
                                    >
                                    </SensorForm>
                                    <v-row class="d-flex"
                                        ><v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            v-for="sensor in Sensor"
                                            :key="sensor.Type"
                                        >
                                            <SensorConditionForm
                                                ref="sensorConditionForm"
                                                :type="sensor.Type"
                                                :condition="sensor.Condition"
                                                :sensorValue.sync="sensor.Value"
                                                :sensorValue2.sync="
                                                    sensor.Value2
                                                "
                                                v-on:updateSensor="updateSensor"
                                            >
                                            </SensorConditionForm>
                                        </v-col> </v-row
                                ></v-card-text>
                                <v-card-actions class="mt-0 pt-0 justify-end"
                                    ><v-btn
                                        text
                                        rounded
                                        @click="closeAndResetInput"
                                        class="ml-2"
                                    >
                                        {{ $t("general.cancel") }}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        @click="stepNo++"
                                    >
                                        {{
                                            isSensorTrigger
                                                ? this.$t("general.continue")
                                                : this.$t("general.skip")
                                        }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="2" class="pa-0">
                            <v-card max-height="600">
                                <v-card-text
                                    ><LocationForm
                                        ref="locationForm"
                                        :Location="Location"
                                        :isSensorTrigger.sync="isSensorTrigger"
                                        v-on:updateLocation="updateLocation"
                                    >
                                    </LocationForm
                                ></v-card-text>
                                <v-card-actions class="mt-0 pt-0 justify-end">
                                    <v-btn text rounded @click="stepNo--">
                                        {{ $t("general.back") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        rounded
                                        @click="closeAndResetInput"
                                        class="ml-2"
                                    >
                                        {{ $t("general.cancel") }}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        :disabled="
                                            !isValidLocation && !isValidSensor
                                        "
                                        @click="stepNo++"
                                    >
                                        {{ $t("general.continue") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-content step="3" class="pa-0">
                            <v-card max-height="600">
                                <v-card-text>
                                    <TimerForm
                                        ref="timerForm"
                                        :RemainMinutes="RemainMinutes"
                                        v-on:updateTimer="updateRemainMinutes"
                                    >
                                    </TimerForm>
                                </v-card-text>
                                <v-card-actions class="mt-0 pt-0 justify-end"
                                    ><v-btn text rounded @click="stepNo--">
                                        {{ $t("general.back") }}
                                    </v-btn>
                                    <v-spacer></v-spacer
                                    ><v-btn
                                        text
                                        rounded
                                        @click="closeAndResetInput"
                                        class="ml-2"
                                    >
                                        {{ $t("general.cancel") }}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        :disabled="
                                            !isValidLocation && !isValidSensor
                                        "
                                        :loading="isSaving"
                                        @click="saveTrigger"
                                    >
                                        {{ $t("general.save") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import SensorForm from "@/components/alertrules/forms/secondary/SensorForm";
import SensorConditionForm from "@/components/alertrules/forms/secondary/SensorConditionForm";
import LocationForm from "@/components/alertrules/forms/secondary/LocationForm";
import TimerForm from "@/components/alertrules/forms/secondary/TimerForm";

export default {
    name: "AddTriggerDialog",
    props: ["openAddTriggerDialog", "alertRule"],
    components: {
        SensorForm,
        SensorConditionForm,
        LocationForm,
        TimerForm
    },
    data() {
        return {
            stepNo: 1,
            isSensorTrigger: false,
            openSensorConditionForm: false,
            isValidSensor: false,
            isSaving: false,
            isValidLocation: false,
            Sensor: [],
            Location: {
                Partners: [],
                Public: false,
                Origin: false,
                Destination: false,
                Custom: [],
                Condition: null
            },
            RemainMinutes: null,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v =>
                    /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    apollo: {},
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {
        Sensor(val) {
            if (val.length > 0) {
                this.isSensorTrigger = true;
            } else {
                this.isSensorTrigger = false;
            }
        }
    },
    methods: {
        closeAndResetInput() {
            this.Sensor = [];
            this.Location.Partners = [];
            this.Location.Public = false;
            this.Location.Origin = false;
            this.Location.Destination = false;
            this.Location.Custom = [];
            this.Location.Condition = null;
            this.RemainMinutes = null;
            this.stepNo = 1;
            this.isSensorTrigger = false;
            this.openSensorConditionForm = false;
            this.isValidSensor = false;
            this.isValidLocation = false;
            if (this.$refs.sensorForm) this.$refs.sensorForm.reset();
            this.$refs.locationForm.reset();
            this.$refs.timerForm.reset();
            this.$emit("closeAddTriggerDialog");
        },
        saveTrigger() {
            this.isSaving = true;
            let _Sensor = [];
            this.Sensor.map(sensor => {
                if (sensor.Condition == "out") {
                    _Sensor.push({
                        Type: sensor.Type,
                        Condition: "out",
                        Value: [sensor.Value, sensor.Value2]
                    });
                } else if (sensor.Condition == "in") {
                    _Sensor.push({
                        Type: sensor.Type,
                        Condition: "in",
                        Value: [sensor.Value, sensor.Value2]
                    });
                } else {
                    _Sensor.push({
                        Type: sensor.Type,
                        Condition: sensor.Condition,
                        Value: [sensor.Value]
                    });
                }
            });
            if (
                this.Location.Partners.length == 0 &&
                !this.Location.Public &&
                !this.Location.Origin &&
                !this.Location.Destination &&
                this.Location.Custom.length == 0
            )
                this.Location.Condition = null;
            let trigger = {
                Sensor: _Sensor,
                Location: this.Location,
                RemainMinutes: this.RemainMinutes
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation CreateTenantAlertRuleTrigger(
                            $input: CreateTenantAlertRuleTriggerInput!
                        ) {
                            createTenantAlertRuleTrigger(input: $input) {
                                tenantAlertRule {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            alertRuleId: this.alertRule.id,
                            triggerJson: JSON.stringify(trigger)
                        }
                    }
                })
                .then(() => {
                    this.$emit("refreshTriggersTable");
                    this.closeAndResetInput();
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
        updateSensorType(val) {
            this.Sensor = val;
            if (val.length == 0) {
                this.isValidSensor = false;
            }
        },
        updateSensor(val) {
            let _hasNullCondition = false;
            this.Sensor.map((sensor, i) => {
                if (sensor.Type === val.type) {
                    this.Sensor[i].Type = val.type;
                    this.Sensor[i].Condition = val.condition;
                    this.Sensor[i].Value = val.value;
                    if (val.value2) {
                        this.Sensor[i].Value2 = val.value2;
                    }
                }
                if (this.Sensor[i].Condition == null) {
                    _hasNullCondition = true;
                }
            });
            this.isValidSensor = !_hasNullCondition;
        },
        updateLocation(val) {
            this.Location = val;
            if (
                (val.Partners.length > 0 ||
                    val.Public ||
                    val.Origin ||
                    val.Destination ||
                    val.Custom.length > 0) &&
                val.Condition != null
            ) {
                this.isValidLocation = true;
            } else {
                this.isValidLocation = false;
            }
        },
        updateRemainMinutes(val) {
            this.RemainMinutes = val;
        }
    }
};
</script>
