<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="12" sm="9" lg="9">
                        <v-row>
                            <v-col
                                cols="auto"
                                v-for="(f, i) in dynamicFilters"
                                :key="i"
                                :class="getFilterChoiceClassByCode(f)"
                            >
                                <!--!Name -->
                                <div v-if="f">
                                    <v-text-field
                                        v-if="f === 'name'"
                                        v-model="filter.name"
                                        :label="
                                            getFilterChoiceNameByCode('name')
                                        "
                                        clearable
                                        hide-details
                                        dense
                                        class="maxWidth"
                                    ></v-text-field>

                                    <!--!Description -->
                                    <v-text-field
                                        v-if="f === 'description'"
                                        v-model="filter.description"
                                        :label="
                                            getFilterChoiceNameByCode(
                                                'description'
                                            )
                                        "
                                        clearable
                                        hide-details
                                        dense
                                        class="maxWidth"
                                    ></v-text-field>

                                    <!--!Customer -->
                                    <v-autocomplete
                                        v-if="f === 'customer'"
                                        v-model="filter.customerId"
                                        :items="lookupsObj.consignee"
                                        :label="
                                            getFilterChoiceNameByCode(
                                                'customer'
                                            )
                                        "
                                        @click="lookupsQuery('consignee')"
                                        :loading="loading.consignee"
                                        item-text="Name"
                                        item-value="ConsigneeID"
                                        cache-items
                                        flat
                                        dense
                                        clearable
                                        class="maxWidth"
                                    ></v-autocomplete>

                                    <!--! Exporter -->
                                    <v-autocomplete
                                        v-if="f === 'exporter'"
                                        v-model="filter.exporterId"
                                        :items="lookupsObj.shipper"
                                        :label="
                                            getFilterChoiceNameByCode(
                                                'exporter'
                                            )
                                        "
                                        @click="lookupsQuery('shipper')"
                                        :loading="loading.shipper"
                                        item-text="Description"
                                        item-value="Code"
                                        cache-items
                                        flat
                                        dense
                                        clearable
                                        class="maxWidth"
                                    ></v-autocomplete>

                                    <!--! Receiver -->
                                    <v-autocomplete
                                        v-if="f === 'receiver'"
                                        v-model="filter.receiverId"
                                        :items="lookupsObj.consigneeReceiver"
                                        :label="
                                            getFilterChoiceNameByCode(
                                                'receiver'
                                            )
                                        "
                                        @click="
                                            lookupsQuery('consigneeReceiver')
                                        "
                                        :loading="loading.consigneeReceiver"
                                        item-text="Name"
                                        item-value="ReceiverID"
                                        cache-items
                                        flat
                                        dense
                                        clearable
                                        class="maxWidth"
                                    ></v-autocomplete>

                                    <!-- ! Transport type -->
                                    <v-autocomplete
                                        v-if="f === 'transportType'"
                                        v-model="filter.transportType"
                                        :label="
                                            getFilterChoiceNameByCode(
                                                'transportType'
                                            )
                                        "
                                        :items="transportTypeList"
                                        item-text="text"
                                        item-value="value"
                                        flat
                                        hide-no-data
                                        clearable
                                        dense
                                        class="maxWidth"
                                    ></v-autocomplete>
                                    <!-- ! container type -->
                                    <v-autocomplete
                                        v-if="f === 'containerType'"
                                        v-model="filter.containerTypeId"
                                        :items="lookupsObj.containerType"
                                        :label="
                                            getFilterChoiceNameByCode(
                                                'containerType'
                                            )
                                        "
                                        @click="lookupsQuery('containerType')"
                                        :loading="loading.containerType"
                                        item-text="Description"
                                        item-value="Code"
                                        cache-items
                                        clearable
                                        flat
                                        dense
                                        class="maxWidth"
                                    ></v-autocomplete>

                                    <!-- !Inco term -->
                                    <v-text-field
                                        v-if="f === 'incoTerm'"
                                        v-model="filter.incoTerm"
                                        :label="
                                            getFilterChoiceNameByCode(
                                                'incoTerm'
                                            )
                                        "
                                        clearable
                                        hide-details
                                        dense
                                        class="maxWidth"
                                    ></v-text-field>

                                    <!-- ! POL Search -->
                                    <v-autocomplete
                                        v-if="f === 'pol'"
                                        v-model="filter.polId"
                                        :items="lookupsObj.ports"
                                        :label="
                                            getFilterChoiceNameByCode('pol')
                                        "
                                        @click="lookupsQuery('ports')"
                                        :loading="loading.ports"
                                        item-text="Description"
                                        item-value="Code"
                                        cache-items
                                        flat
                                        dense
                                        clearable
                                        class="maxWidth"
                                    ></v-autocomplete>

                                    <!-- ! pod search  -->
                                    <v-autocomplete
                                        v-if="f === 'pod'"
                                        v-model="filter.podId"
                                        :items="lookupsObj.ports"
                                        :label="
                                            getFilterChoiceNameByCode('pod')
                                        "
                                        @click="lookupsQuery('ports')"
                                        :loading="loading.ports"
                                        item-text="Description"
                                        item-value="Code"
                                        cache-items
                                        flat
                                        dense
                                        clearable
                                        class="maxWidth"
                                    ></v-autocomplete>
                                    <!-- ! Shipping Line -->
                                    <v-autocomplete
                                        v-if="f === 'shippingLine'"
                                        v-model="filter.shippingLineId"
                                        :items="lookupsObj.shippingLine"
                                        :label="
                                            getFilterChoiceNameByCode(
                                                'shippingLine'
                                            )
                                        "
                                        @click="lookupsQuery('shippingLine')"
                                        :loading="loading.shippingLine"
                                        item-text="Description"
                                        item-value="Code"
                                        cache-items
                                        flat
                                        clearable
                                        dense
                                        class="maxWidth"
                                    ></v-autocomplete>
                                </div>
                            </v-col>
                            <!-- !Sort By -->
                            <v-col class="col-auto col-md-4 col-lg-3 col-xl-3">
                                <v-row class="d-flex align-center ma-0 pa-0">
                                    <v-select
                                        v-model="sortBy"
                                        :items="sortByChoices"
                                        :label="$t('general.sort_by')"
                                        :item-value="item => item.code"
                                        :item-text="item => item.name"
                                        clearable
                                        hide-details
                                        dense
                                        color="primary"
                                        :menu-props="{
                                            zIndex: 3,
                                            offsetY: true
                                        }"
                                    >
                                        <template v-slot:append-outer>
                                            <v-btn
                                                class="mt-n1"
                                                small
                                                icon
                                                :disabled="!sortBy"
                                                @click="sortAsc = !sortAsc"
                                            >
                                                <v-icon color="grey darken-2">{{
                                                    sortAsc ? "north" : "south"
                                                }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <!-- !Filters Menu -->
                    <v-col cols="auto">
                        <v-row class="d-flex align-center justify-end">
                            <!-- !Filters Menu -->
                            <v-col
                                cols="auto"
                                class="d-flex align-center"
                            >
                                <CreateAndEditBooking
                                    v-if="!selectMode"
                                    :editMode="false"
                                    :templateMode="true"
                                    v-on:template-created="onTemplateCreated"
                                ></CreateAndEditBooking>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn
                                    v-if="!selectMode"
                                    color="primary"
                                    @click="changeSelectedMode"
                                    rounded
                                >{{ $t("general.select") }}</v-btn
                            >
                                    <div v-else
                                        class="d-flex flex-row"
                                    >
                                        <v-btn
                                            ref="deleteBtn"
                                            class="mr-2"
                                            color="primary"
                                            :loading="isLoading"
                                            rounded
                                            @click="deleteSelectedTemplates"
                                        >
                                            <v-icon left>
                                                delete
                                            </v-icon>
                                            {{ $t("general.delete") }}
                                        </v-btn>
                                        <v-btn
                                            @click="changeSelectedMode"
                                            text
                                            rounded
                                            >{{ $t("general.cancel") }}</v-btn
                                        >
                                    </div>
                            </v-col>
                            <v-col cols="auto" class="d-flex justify-end pa-0">
                                <FilterMenu
                                    :title="$t('dashboard.customize')"
                                    :description="$t('dashboard.display')"
                                    :choices="filterChoices"
                                    :open-on-hover="false"
                                    height="500px"
                                    width="250px"
                                    v-on:changed="onFilterMenuChanged"
                                >
                                </FilterMenu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>
<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import CreateAndEditBooking from "@/components/bookings/create/CreateAndEditBooking.vue";

export default {
    name: "BookingTemplatesFilter",
    components: {
        FilterMenu,
        CreateAndEditBooking
    },
    data() {
        return {
            fab: false,
            isLoading: false,
            sortAsc: true,
            selectMode: false,
            shouldStickyFilter: false,
            filterChoices: null,
            filter: {
                first: 10,
                name: null,
                description: null,
                customerId: null,
                exporterId: null,
                receiverId: null,
                incoTerm: null,
                shippingLineId: null,
                transportType: null,
                polId: null,
                podId: null,
                containerTypeId: null,
                orderBy: null,
            },
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "name",
                    name: "Name",
                    tag: "bookings.template_name",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "description",
                    name: "Description",
                    tag: "bookings.template_description",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "customer",
                    name: "Customer",
                    tag: "bookings.customer",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "exporter",
                    name: "Exporter",
                    tag: "bookings.exporter",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "receiver",
                    name: "Receiver",
                    tag: "bookings.receiver",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "pol",
                    name: "POL Code",
                    tag: "bookings.pol",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "pod",
                    name: "POD Code",
                    tag: "bookings.pod",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    // for filter menu
                    code: "transportType",
                    name: "Transport Type",
                    tag: "bookings.transport_type",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "incoTerm",
                    name: "Inco Term",
                    tag: "bookings.inco_term",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "containerType",
                    name: "Container Type",
                    tag: "bookings.container_type",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                }
                /*   
                {
                    // for filter menu
                    code: "shippingLine",
                    name: "Shipping Line",
                    tag: "bookings.shipping_line",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                }, 
                */
                /*  {
                    // for filter menu
                    code: "orderBy",
                    name: "Order By",
                    tag: "bookings.order_by",
                    enabled: false,
                    // for filter layout
                    class: "col-auto col-md-3 col-lg-2"
                } */
            ],
            sortBy: null,
            sortByChoices: [
                {
                    code: "name",
                    name: "Name"
                },
                {
                    code: "description",
                    name: "Description"
                }
            ],
            loading: {
                consignee: false,
                vessel: false,
                shipper: false,
                consigneeReceiver: false,
                carrier: false,
                receiverAgent: false,
                ports: false,
                commodity: false,
                tempCode: false,
                containerType: false,
                vents: false,
                weighBridges: false,
                solasVerificationMethods: false,
                tempDevice: false,
                shippingLine: false
            },
            lookupsObj: {
                consignee: [],
                vessel: [],
                shipper: [],
                consigneeReceiver: [],
                carrier: [],
                receiverAgent: [],
                ports: [],
                commodity: [],
                tempCode: [],
                containerType: [],
                vents: [],
                weighBridges: [],
                solasVerificationMethods: [],
                tempDevice: [],
                shippingLine: []
            },
            transportTypeList: [
                { text: "Contour", value: "Contour" },
                { text: "Carrier", value: "Carrier" },
                { text: "Own", value: "Own" }
            ],
            
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        dynamicFilters() {
            const _filter =
                this.filterChoices?.filter(f => f.enabled).map(f => f.code) ||
                [];
            return _filter;
        }
    },
    watch: {
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                    helper.setFilterValues(
                        "bookingTemplatesTableFilters",
                        value
                    );
                }
            },
            deep: true,
            immediate: true
        },
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "bookingTemplatesTableFilters"
        );
    },
    mounted() {
        this.sortBy = "name";
        let _filter = helper.getFilterValues("bookingTemplatesTableFilters");

        if (_filter && _filter !== null && _filter !== undefined) {
            this.filter = _filter;
        }

        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    bookingTemplatesTableFilters: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        deletedItem() {
            this.isLoading = false;
            this.selectMode = false;
        },
        changeSelectedMode() {
            this.selectMode = !this.selectMode;
            this.$emit("select-mode", this.selectMode);
        },
        deleteSelectedTemplates() {
            this.isLoading = true;
            this.$emit("delete-selected", true);
        },
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        getFilterChoiceNameByCode(code) {
            return this.$t(
                this.defaultFilterChoices.find(item => {
                    return item.code === code;
                })?.tag
            );
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        },
        onTemplateCreated(booking) {
            this.$emit("template-created", booking);
        },
        lookupsQuery(value) {
            let _responseData = [];
            this.loading[value] = true;

            return this.$apollo
                .mutate({
                    mutation: gql`
                        mutation BookingLookupsQx(
                            $input: BookingLookupsQxInput!
                        ) {
                            bookingLookupsQx(input: $input) {
                                response
                            }
                        }
                    `,
                    variables: {
                        input: {
                            lookupField: value
                        }
                    }
                })
                .then(response => {
                    let Data = response.data.bookingLookupsQx.response;
                    this.loading[value] = false;
                    let parseJsonData = JSON.parse(Data);
                    _responseData = parseJsonData;
                    this.lookupsObj[value] = _responseData;
                    // return _responseData;
                })
                .catch(error => {
                    this.loading[value] = false;
                    console.log("error", error);
                });
        }
    }
};
</script>
