<template>
    <v-container fluid>
        <v-card outlined class="rounded-lg">
            <v-card-title class="mb-2">
                <div class="title">{{ $t("headers.notification") }}</div>
                <v-spacer></v-spacer>
                <v-btn color="primary" elevation="0" v-if="!editMode" @click="editMode = !editMode" icon>
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn class="mr-1" text v-if="editMode" @click="cancelActions">{{ $t("general.cancel") }}</v-btn>
                <v-btn color="primary" elevation="0" v-if="editMode" @click="save">{{ $t("general.save") }}</v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4" class="headers d-flex align-center">
                        <div>
                            {{ $t("general.email_notification") }}
                        </div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-end">
                        <v-switch hide-details v-model="notification.email" inset :disabled="!editMode"></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="headers d-flex align-center">
                        {{ $t("general.in_app_notification") }}
                    </v-col>
                    <v-col cols="8" class="d-flex justify-end">
                        <v-switch hide-details v-model="notification.inApp" inset :disabled="!editMode"></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import helper from "@/utils/helper";
import gql from "graphql-tag";

export default {
    name: "NotificationForm",
    data() {
        return {
            editMode: false,
            notification: {
                email: false,
                inApp: false
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.notification.email = this.me.emailNotification;
            this.notification.inApp = this.me.inAppNotification;
        });
    },
    methods: {
        cancelActions() {
            this.editMode = false;
            this.notification.email = this.me.emailNotification;
            this.notification.inApp = this.me.inAppNotification;
        },
        save() {
            //mutation
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateUserNotification($input: UpdateUserNotificationInput!) {
                            updateUserNotification(input: $input) {
                                user {
                                    id
                                    emailNotification
                                    inAppNotification
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.me.id,
                            emailNotification: this.notification.email,
                            inAppNotification: this.notification.inApp
                        }
                    }
                })
                .then(() => {
                    this.editMode = false;
                    const payload = {
                        color: "blue",
                        message: this.$t("general.update_successfully")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>
<style scoped>
.title {
    color: #0e0d35;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
}
.headers {
    color: #868599;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
}
.body {
    color: #0e0d35;
    font-size: 13px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: left;
}
</style>
