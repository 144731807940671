import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c(VForm,{ref:"locationForm",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c(VRow,[(_vm.trip.type === 'Origin')?_c(VCol,{staticClass:"pr-0",attrs:{"cols":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textBodyContent labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("trips.origin"))+" ")])])],1),_c(VAutocomplete,{staticClass:"textBodyContent inputContent mb-n2",attrs:{"loading":_vm.$apollo.queries.tenantLocations
                                            .loading,"items":_vm.tenantLocations
                                            ? _vm.tenantLocations.edges
                                            : [],"no-data-text":_vm.$t('loading.no_available'),"item-text":"node.name","item-value":"node.id","outlined":"","clearable":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2",attrs:{"dense":""}},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")]),_c(VCol,{attrs:{"cols":"3"}},[_c('span',{staticClass:"grey--text mr-0 float-right"},[_c('i',[_vm._v(_vm._s(_vm.selectLabelByType( data.item.node.locationType.toLowerCase() )))])])])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append pa-0",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{staticClass:"text-capitalize textBodyContent",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openCreateLocationDialog(
                                                            'origin'
                                                        )}}},[_vm._v(" "+_vm._s(_vm.$t( "general.create_location" ))+" ")])],1)],1)]},proxy:true}],null,false,3082333282),model:{value:(_vm.trip.origin),callback:function ($$v) {_vm.$set(_vm.trip, "origin", $$v)},expression:"trip.origin"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textBodyContent labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "date.departure_date" ) + "*")+" ")])])],1),_c('DateTimeComponent',{staticClass:"inputContent",attrs:{"icon":'calendar_month',"rules":[_vm.rules.requiredDateTime],"outlined":true,"dense":true,"defaultTime":_vm.trip.plannedDepartureTime || '00:00'},model:{value:(_vm.trip.plannedDepartureDate),callback:function ($$v) {_vm.$set(_vm.trip, "plannedDepartureDate", $$v)},expression:"trip.plannedDepartureDate"}})],1)],1)],1)],1)],1):_vm._e(),(_vm.trip.type === 'Destination')?_c(VCol,{staticClass:"pr-0",attrs:{"cols":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textBodyContent labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("trips.destination"))+" ")])])],1),_c(VAutocomplete,{staticClass:"textBodyContent inputContent mb-n2",attrs:{"loading":_vm.$apollo.queries.tenantLocations
                                            .loading,"no-data-text":_vm.$t('loading.no_available'),"items":_vm.tenantLocations
                                            ? _vm.tenantLocations.edges
                                            : [],"item-text":"node.name","item-value":"node.id","outlined":"","clearable":"","hide-selected":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")]),_c(VCol,{attrs:{"cols":"3"}},[_c('span',{staticClass:"grey--text mr-0 float-right"},[_c('i',[_vm._v(_vm._s(_vm.selectLabelByType( data.item.node.locationType.toLowerCase() )))])])])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{staticClass:"text-capitalize textBodyContent",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openCreateLocationDialog(
                                                            'destination'
                                                        )}}},[_vm._v(" "+_vm._s(_vm.$t( "general.create_location" ))+" ")])],1)],1)]},proxy:true}],null,false,2258164981),model:{value:(_vm.trip.destination),callback:function ($$v) {_vm.$set(_vm.trip, "destination", $$v)},expression:"trip.destination"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textBodyContent labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("date.arrival_date"))+" ")])])],1),_c('DateTimeComponent',{staticClass:"inputContent",attrs:{"icon":'calendar_month',"rules":_vm.trip.plannedArrivalDate !== null &&
                                        _vm.trip.plannedArrivalDate.length > 0
                                            ? [_vm.rules.checkDate]
                                            : [],"outlined":true,"dense":true,"defaultTime":_vm.trip.plannedArrivalTime || '00:00'},model:{value:(_vm.trip.plannedArrivalDate),callback:function ($$v) {_vm.$set(_vm.trip, "plannedArrivalDate", $$v)},expression:"trip.plannedArrivalDate"}})],1)],1)],1)],1)],1):_vm._e()],1),_c('AddLocation',{attrs:{"openAddLocationDialog":_vm.openAddLocation},on:{"closeMap":function($event){_vm.openAddLocation = false},"updateLocationsTable":function($event){return _vm.$apollo.queries.tenantLocations.refetch()},"updateLocation":_vm.updateLocation}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }