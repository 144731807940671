<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col cols="12" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-text-field
                                hint="Search by name"
                                v-model="searchInput"
                                append-icon="search"
                                :label="$t('general.search')"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="12" sm="5" lg="4" class="py-2 pr-4 text-right">
                    <v-row class="d-flex align-center justify-end">
                        <!-- ADD -->
                        <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            class="mr-1"
                            @click="openAddGroupPartnerDialog = true"
                        >
                            <v-icon left small>add_box</v-icon>
                            {{ $t("trips.add_partner") }}
                        </v-btn>
                        <!-- REMOVE -->
                        <!-- <v-btn
                            small
                            outlined
                            rounded
                            color="error"
                            class="mr-1"
                            :disabled="enableOrDisableRemove"
                            @click="openDeleteGroupPartnerDialog = true"
                        >
                            <v-icon left small>remove_circle</v-icon>
                            Remove</v-btn
                        > -->
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :items="groupPartners.edges"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    loader-height="2"
                    hide-default-footer
                    :loading="$apollo.queries.groupPartners.loading"
                >
                    <!-- CHECKBOX COLUMN -->
                    <template v-slot:item.action="{ item }">
                        <v-checkbox
                            v-model="selectedPartners"
                            class="mx-0"
                            :value="item.node.id"
                        ></v-checkbox>
                    </template>

                    <!-- EDIT COLUMN -->
                    <template v-slot:item.edit="{ item }">
                        <v-icon @click="editPartner(item)">edit</v-icon>
                    </template>
                    <!-- REMOVE -->
                    <template v-slot:item.delete="{ item }">
                        <v-icon @click="removePartner(item)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!---- ADD GROUP PARTNER DIALOG ---->
        <AddGroupPartnerDialog
            :openAddGroupPartnerDialog="openAddGroupPartnerDialog"
            :group="group"
            :groupPartners="groupPartners"
            v-on:refreshGroupPartnersTable="
                $apollo.queries.groupPartners.refetch()
            "
            v-on:closeAddGroupPartnerDialog="openAddGroupPartnerDialog = false"
        ></AddGroupPartnerDialog>

        <!---- EDIT GROUP PARTNER DIALOG ---->
        <EditPartnerDialog
            ref="editPartnerDialog"
            :openEditPartnerDialog="openEditPartnerDialog"
            v-on:closeEditPartnerDialog="closeEditPartnerDialog"
            v-on:refreshDataTable="$apollo.queries.groupPartners.refresh()"
        ></EditPartnerDialog>

        <!---- DELETE GROUP PARTNER DIALOG ---->
        <DeleteGroupPartnerDialog
            :openDeleteDialog="openDeleteGroupPartnerDialog"
            :group="group"
            :partnersID="selectedPartners"
            v-on:refreshDataTable="$apollo.queries.groupPartners.refetch()"
            v-on:closeDeleteGroupPartnerDialog="closeAndCleanDeletedPartners"
        >
            <template #title>{{ $t("groups.delete_partner") }}</template>
            <template #content>{{ $t("groups.sure_partner") }}</template>
        </DeleteGroupPartnerDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddGroupPartnerDialog from "@/components/groups/create/AddGroupPartnerDialog.vue";
import DeleteGroupPartnerDialog from "@/components/groups/delete/DeleteGroupPartnerDialog.vue";
import EditPartnerDialog from "@/components/partners/update/EditPartnerDialog.vue";
import Config from "@/utils/config.json";

export default {
    name: "GroupPartnersTab",
    components: {
        AddGroupPartnerDialog,
        DeleteGroupPartnerDialog,
        EditPartnerDialog
    },
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    apollo: {
        groupPartners: {
            query: gql`
                query TenantUserGroupPartner(
                    $tenantUserGroupId: ID
                    $first: Int
                ) {
                    tenantUserGroupPartners(
                        tenantUserGroupId: $tenantUserGroupId
                        first: $first
                    ) {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    name
                                    referenceNumber
                                    partnerType
                                    carrierType
                                    notificationEnabled
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantUserGroupId: this.group.node.id,
                    first: 20
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUserGroupPartners;
            },
            skip: false
        }
    },
    data() {
        return {
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /*********************************/
            openAddGroupPartnerDialog: false,
            openDeleteGroupPartnerDialog: false,
            openEditPartnerDialog: false,
            /******************************/
            /* ---- PARTNER INPUT DATA ---- */
            /****************************/
            selectedPartners: [],
            searchInput: null,
            groupPartners: { edges: [] },
            editPartnerItem: null,
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
            headerChoices: [
                {
                    text: this.$t("headers.reference_number"),
                    align: "center",
                    value: "node.tenantPartner.referenceNumber",
                    sortable: false
                },
                {
                    text: this.$t("headers.name"),
                    align: "center",
                    value: "node.tenantPartner.name",
                    sortable: false
                },
                {
                    text: this.$t("headers.partner_type"),
                    align: "center",
                    value: "node.tenantPartner.partnerType",
                    sortable: false
                },
                {
                    text: this.$t("headers.carrier_type"),
                    align: "center",
                    value: "node.tenantPartner.carrierType",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "edit",
                    width: "5px",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "delete",
                    width: "5px",
                    sortable: false
                }
            ]
        };
    },
    watch: {
        searchInput() {
            this.$apollo.groupPartners
                ? this.$apollo.groupPartners.refetch()
                : null;
        }
    },
    computed: {
        enableOrDisableRemove() {
            return this.removedPartners?.length > 0 ? false : true;
        }
    },
    mounted(){
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'group_partners_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("group_partners_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("group_partners_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editPartner(item) {
            this.editPartnerItem = item.node.tenantPartner;
            this.openEditPartnerDialog = true;
            this.$refs.editPartnerDialog.assignFormValues(this.editPartnerItem);

            // this.$refs.editPartnerDialog.initializePartner(item);
        },
        removePartner(item) {
            this.openDeleteGroupPartnerDialog = true;
            this.selectedPartners.push(item.node.id);
        },
        closeAndCleanDeletedPartners() {
            this.openDeleteGroupPartnerDialog = false;
            this.selectedPartners = [];
        },
        closeEditPartnerDialog() {
            this.openEditPartnerDialog = false;
            this.editPartnerItem = null;
        }
    }
};
</script>
