<template>
    <v-container fluid>
        <v-dialog v-model="dialog" persistent width="800px" height="600px">
            <v-card>
                <v-card-title class="primary white--text">
                    <v-icon color="white" class="mr-2">edit</v-icon>
                    {{ $t("partners.edit_tracker") }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-row dense class="mt-10 px-10">
                            <v-spacer></v-spacer>
                            <v-col cols="12" class="py-0" v-if="me.isSuperuser">
                                <v-autocomplete
                                    v-model="tenant"
                                    :items="tenants ? tenants.edges : []"
                                    :no-data-text="$t('loading.no_available')"
                                    :label="$t('trackers.tenant') + '*'"
                                    item-text="node.name"
                                    item-value="node.id"
                                    outlined
                                    dense
                                    :filled="!isAvailable"
                                    :readonly="!isAvailable"
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="tracker.serialNumber"
                                    :label="$t('headers.serial_number') + '*'"
                                    outlined
                                    dense
                                    required
                                    filled
                                    :readonly="true"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12" class="py-0">
                                <v-autocomplete
                                    v-model="tracker.brand"
                                    :items="brandsChoices"
                                    :label="$t('headers.brand') + '*'"
                                    item-text="text"
                                    item-value="value"
                                    :filled="!isAvailable"
                                    :readonly="!isAvailable"
                                    outlined
                                    dense
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12" class="py-0">
                                <v-text-field
                                    v-model="tracker.model"
                                    :label="$t('headers.model')"
                                    outlined
                                    dense
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12" class="py-0">
                                <v-text-field
                                    v-model="tracker.mainTracker"
                                    :label="$t('headers.main_tracker')"
                                    outlined
                                    dense
                                    required
                                    :rules="[]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12" class="py-0">
                                <v-text-field
                                    v-model="tracker.packageNumber"
                                    :label="$t('headers.package_number')"
                                    outlined
                                    dense
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12" class="py-0">
                                <DateComponent
                                    v-model="tracker.expiryDate"
                                    :label="$t('headers.expiry_date1')"
                                    :outlined="true"
                                    :dense="true"
                                ></DateComponent>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="tracker.comment"
                                    :label="$t('headers.comment')"
                                    outlined
                                    dense
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>
                    <v-btn text @click="resetForm" rounded>
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        rounded
                        @click="updateTracker"
                        :disabled="!valid"
                        :loading="isLoading"
                    >
                        {{ $t("general.save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "../../../utils/helper";
import DateComponent from "@/components/base/DateComponent.vue";

export default {
    components: {
        DateComponent
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        dialog: {
            type: Boolean,
            required: true
        },
        isAvailable: {
            type: Boolean,
            required: false
        }
    },
    apollo: {
        tenants: {
            query: gql`
                query tenants {
                    tenants {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenants,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    data() {
        return {
            tracker: {},
            valid: false,
            isLoading: false,
            brandsChoices: [
                { text: "SensiTech", value: "SensiTech" },
                { text: "Tive", value: "Tive" },
                { text: "DeltaTrak", value: "DeltaTrak" },
                { text: "Emerson", value: "Emerson" },
                { text: "Frigga", value: "Frigga" }
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                alertIsInvalid: v =>
                    v === false || this.$t("rules.ref_no_exists")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        editMode: {
            get() {
                return this.dialog;
            },
            set(value) {
                this.$emit("update:dialog", value);
            }
        },
        tenant: {
            get() {
                let _tenant = this.tracker?.tenant?.id;
                return _tenant ? _tenant : null;
            },
            set(value) {
                if(value !== null){
                    this.tracker.tenant.id = value;
                }
            }
        }
    },
    watch: {
        dialog(val) {
            if (val) {
                let _item = JSON.parse(JSON.stringify(this.item));
                this.tracker = { ..._item };
            }
        }
    },
    methods: {
        resetForm() {
            this.editMode = false;
            this.$refs.form.reset();
            this.tenant = null;
            this.tracker = {};
        },
        updateTracker() {
            this.isLoading = true;
            let date = this.tracker?.expiryDate?.slice(0, 10)
                ? this.tracker.expiryDate.slice(0, 10)
                : null;
            let time = this.tracker?.expiryDate?.slice(11, 19)
                ? this.tracker.expiryDate.slice(11, 19)
                : null;

            let _expiryDate = date
                ? helper.concatDateAndTimeAndConvertToUTC(date, time)
                : null;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTracker($input: UpdateTrackerInput!) {
                            updateTracker(input: $input) {
                                tracker {
                                    id
                                    stockLocation {
                                        id
                                        name
                                    }
                                    serialNumber
                                    brand
                                    activatedDate
                                    expiryDate
                                    packageNumber
                                    comment
                                    isArchived
                                    lastConnectionTime
                                    lastBatteryDeviceTime
                                    lastBatteryValue
                                    lastTemperatureValue
                                    lastTemperatureDeviceTime
                                    lastExternalTemperatureValue
                                    lastExternalTemperatureDeviceTime
                                    lastProbeTemperatureValue
                                    lastProbeTemperatureDeviceTime
                                    lastHumidityDeviceTime
                                    lastHumidityValue
                                    lastLightDeviceTime
                                    lastLightValue
                                    lastShockDeviceTime
                                    lastShockValue
                                    lastLocationDeviceTime
                                    tenant {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantId: this.tenant || this.tracker.tenant.id,
                            trackerId: this.tracker.id,
                            mainTracker: this.tracker.mainTracker,
                            serialNumber: this.tracker.serialNumber,
                            brand: this.tracker.brand,
                            model: this.tracker.model,
                            packageNumber: this.tracker.packageNumber,
                            expiryDate: _expiryDate,
                            comment: this.tracker.comment
                        }
                    }
                })
                .then(response => {
                    this.$emit("onEdited", response.data.updateTracker.tracker);
                    this.editMode = false;
                    this.isLoading = false;
                    //snackbar
                    const payload = {
                        message: "Tracker updated successfully",
                        color: "blue"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(err => {
                    this.$emit("error", err);
                });
        }
    }
};
</script>
