import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"grey lighten-4"},[_c(VRow,{staticClass:"d-flex justify-start",attrs:{"dense":""}},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("alert_rules.apply"))+" ")])]),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"py-5 d-flex align-center justify-center",attrs:{"elevation":"2","color":_vm.valueApplyAllNewTrips ? 'primary' : '',"height":"70"},on:{"click":function($event){return _vm.automateOption('applyAllNewTrips')}}},[_c('span',{class:_vm.valueApplyAllNewTrips
                            ? 'white--text'
                            : 'black--text'},[_vm._v(" "+_vm._s(_vm.$t("general.all"))+" ")])])],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"py-5 d-flex align-center justify-center",attrs:{"elevation":"2","height":"70","color":_vm.applyPartnersAlert ? 'primary' : ''},on:{"click":function($event){return _vm.automateOption('partnersTrips')}}},[_c('span',{class:_vm.applyPartnersAlert ? 'white--text' : 'black--text'},[_vm._v(" "+_vm._s(_vm.$t("alert_rules.with_partners"))+" ")])])],1)],1),_c(VForm,{model:{value:(_vm.validatePartner),callback:function ($$v) {_vm.validatePartner=$$v},expression:"validatePartner"}},[(_vm.applyPartnersAlert)?_c(VRow,{staticClass:"d-flex justify-start",attrs:{"dense":""}},[_c(VCol,{staticClass:"d-flex flex-row",attrs:{"cols":"12"}},_vm._l((_vm.defaultChoices),function(choice){return _c(VCol,{key:choice.value,staticClass:"pl-0"},[_c(VCard,{staticClass:"py-5 d-flex align-center justify-center",attrs:{"elevation":"2","color":_vm.partnerTypeChoices.find(
                                function (e) { return e.value === choice.value; }
                            )
                                ? 'primary'
                                : '',"height":"70"},on:{"click":function($event){return _vm.updatePartnerChoice(choice)}}},[_c('span',{class:_vm.partnerTypeChoices.find(
                                    function (e) { return e.value === choice.value; }
                                )
                                    ? 'white--text'
                                    : 'black--text'},[_vm._v(" "+_vm._s(choice.text)+" ")])])],1)}),1),_vm._l((_vm.partnerTypeChoices),function(partnerTypeChoice){return _c(VCol,{key:partnerTypeChoice.value,attrs:{"cols":"12","sm":"6","md":"6"}},[(partnerTypeChoice.value)?_c(VAutocomplete,{attrs:{"hide-details":"","outlined":"","multiple":"","rules":[_vm.rules.required],"small-chips":"","clearable":"","items":_vm.tenantPartners.edges.filter(
                            function (partner) { return partner.node.partnerType ===
                                partnerTypeChoice.value; }
                        ),"no-data-text":_vm.$t('loading.no_available'),"item-value":function (item) { return (item.node ? item.node.id : null); },"item-text":function (item) { return (item.node ? item.node.name : null); },"label":partnerTypeChoice.text},on:{"change":function($event){return _vm.$emit(
                            'updatePartnersToApplyAlert',
                            _vm.valuePartnersToApplyAlert
                        )}},model:{value:(
                        _vm.valuePartnersToApplyAlert[partnerTypeChoice.value]
                    ),callback:function ($$v) {_vm.$set(_vm.valuePartnersToApplyAlert, partnerTypeChoice.value, $$v)},expression:"\n                        valuePartnersToApplyAlert[partnerTypeChoice.value]\n                    "}}):_vm._e()],1)})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }