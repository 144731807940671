import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"860px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{attrs:{"dark":"","left":""}},[_vm._v("help")]),_vm._v(" HELP "),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("close")])],1)],1),(_vm.knowledgebases.edges.length > 0)?_c(VCardText,{staticClass:"mt-5"},_vm._l((_vm.knowledgebases.edges),function(k){return _c('div',{key:k.node.id,staticClass:"mb-10"},[_c('h3',[_vm._v(" "+_vm._s(k.node.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(k.node.description)+" ")]),_c('iframe',{attrs:{"width":"800","height":"450","src":k.node.videoUrl,"frameborder":"0","allowfullscreen":""}})])}),0):_c(VCardText,{staticClass:"mt-5"},[_vm._v(" Coming soon! ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }