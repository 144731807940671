<template>
    <v-card fill-height flat width="100%" v-if="user.node" color="background">
        <v-toolbar flat color="background">
            <!----- TOP LEFT ICONS ---->
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn v-if="shouldShowFullScreenButton" icon @click="changeFullScreen">
                <v-icon>{{ isFullScreen ? "fullscreen_exit" : "crop_free" }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <!---- TOP RIGHT ICONS ---->
            <v-btn icon :disabled="user.user">
                <v-icon @click="editUser()"> edit </v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon @click="openDeleteUserDialog = true"> delete </v-icon>
            </v-btn>

            <!---- EDIT PARTNER DIALOG ---->
            <EditUserDialog
                ref="editUserDialog"
                :showUserSelect="true"
                :openEditUserDialog="openEditUserDialog"
                v-on:refreshUpdatedUser="updateUserValues"
                v-on:closeEditUserDialog="openEditUserDialog = false"
            ></EditUserDialog>

            <!---- DELETE PARTNER DIALOG ---->
            <DeleteUserDialog
                :openDeleteUserDialog="openDeleteUserDialog"
                :userId="[user.node.id]"
                v-on:refreshDataTable="closeDrawerAndRefresh"
                v-on:closeDeleteDialog="openDeleteUserDialog = false"
            >
                <template v-slot:title>
                    {{ $t("users.delete_user") }}
                </template>
                <template #content>
                    {{ $t("users.sure_user") }}
                </template>
            </DeleteUserDialog>
        </v-toolbar>

        <v-card-title class="pt-0 px-5">
            <!---- USER GENERAL INFO DISPLAY (NAME, USERS...) ---->
            <v-row>
                <!---- NAME ---->
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" class="mb-3">
                    <div class="textHeader text-truncate">{{ $t("headers.name") }}</div>
                    <div class="textBody text-truncate">
                        {{ updatedUser ? updatedUser.fullName : user.node.user.fullName }}
                    </div>
                </v-col>
                <!---- USERS ---->
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" class="mb-3">
                    <div class="textHeader text-truncate">{{ $t("general.ngroups") }}</div>
                    <div v-if="user && user.node && user.node.user && user.node.user.allTenantUserGroups">
                        {{ user.node.user.allTenantUserGroups.totalCount }}
                    </div>
                    <div v-else>
                        0
                    </div>
                </v-col>
            </v-row>
        </v-card-title>

        <!---- tab view ---->
        <PreviewUserDetailTabView v-if="view === 'tab'" :user="user" :selectedFilters="selectedFilters" />
    </v-card>
</template>
<script>
import helper from "@/utils/helper";
//import FilterMenu from "@/components/base/FilterMenu.vue";
import PreviewUserDetailTabView from "@/components/users/PreviewUserDetailTabView.vue";
import DeleteUserDialog from "@/components/users/delete/DeleteUserDialog.vue";
import EditUserDialog from "@/components/users/update/EditUserDialog.vue";
import Config from "@/utils/config.json";

export default {
    name: "UserDetail",
    props: {
        user: {
            type: Object,
            required: true
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        PreviewUserDetailTabView,
        //FilterMenu,
        DeleteUserDialog,
        EditUserDialog
    },
    data() {
        return {
            /**********************/
            /* --- QUERY DATA --- */
            /**********************/
            updatedUser: null,
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /***********************************/
            openDeleteUserDialog: false,
            openEditUserDialog: false,
            /*******************/
            /* --- FILTERS --- */
            /*******************/
            view: "tab",
            viewChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "groups",
                    name: "Groups",
                    tag: "home.groups",
                    enabled: true,
                    order: 1
                },
                {
                    // for filter menu
                    code: "loginHistory",
                    name: "Login History",
                    tag: "headers.login_history",
                    enabled: true,
                    order: 2
                }
            ],
            filterChoices: []
        };
    },
    created() {
        this.viewType = helper.getViewType("userDetailViewType");
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "userDetailFilterChoices");
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];

            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        }
    },
    watch: {},
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    userDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    userDetailFilterChoices: value
                });
            },
            { deep: true }
        );
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "user_details_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("user_details_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("user_details_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        editUser() {
            this.$store.dispatch("staff/updateCreatedStaff", {
                type: "update",
                staff: this.user
            });
            this.$router.push({
                path: "/previewcreateuser",
                query: {
                    type: "edit"
                }
            });
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updateUserValues(tenantUser) {
            // Close edit partner dialog if open
            this.updatedUser = tenantUser.user;
            this.$emit("refreshDataTable");
            this.openEditUserDialog = false;
        }
    }
};
</script>

<style scoped>
.custom-height {
    height: 35px;
}

.v-input--selection-controls {
    margin-top: 0;
}
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}

.labelText {
    font-size: 32px;
    font-weight: 700;
    color: #0e0d35;
    line-height: 38.4px;
}

.titleText {
    font-size: 12px;
    font-weight: 700;
    color: #555464;
    line-height: 14.4px;
}
</style>
