import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10 pt-4"},[_c(VCard,{staticClass:"py-4 mt-4 mb-4",attrs:{"flat":""}},[_c(VRow,{staticClass:"d-flex align-center px-3",attrs:{"align":"start"}},[_c(VCol,{staticClass:"py-2",attrs:{"cols":"12","lg":"12","sm":"12"}},[_c('SearchBar',{attrs:{"label":_vm.$t('general.search')},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1)],1)],1),_c(VCard,{staticClass:"px-3 rounded-lg mb-2",attrs:{"flat":"","outlined":""}},[_c(VCardTitle,[_c('div',[_c(VIcon,{staticClass:"mr-2"},[_vm._v("group")]),_vm._v(" "+_vm._s(_vm.$t("home.groups"))+" ")],1),_c(VSpacer),_c(VBtn,{staticClass:"mr-1",attrs:{"color":"primary"},on:{"click":function($event){_vm.openAddUserGroupDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("users.add_group"))+" ")])],1),_c(VCardText,[(_vm.loading && !_vm.hasData)?_c('div',{staticClass:"px-10 pt-4"},[_c('LoadingBar')],1):(_vm.hasData)?_c('div',_vm._l((_vm.groups),function(group){return _c(VCard,{key:group.node.id,staticClass:"mb-4 rounded-lg px-3 backgroundContent",attrs:{"outlined":"","flat":""}},[_c(VCardText,[_c(VRow,{staticClass:"px-1"},[_c(VCol,{attrs:{"cols":"4"}},[_c('div',[_vm._v(_vm._s(_vm.$t("general.group_name")))]),_c('div',{staticClass:"textBody"},[_vm._v(_vm._s(group.node.name))])]),_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VCard,{staticClass:"d-flex justify-start",attrs:{"flat":"","max-width":"20px","color":"transparent"}},[_c(VMenu,{attrs:{"offset-y":"","transition":"slide-x-reverse-transition","width":"250px ","attach":"","left":"","bottom":"","close-on-click":"","nudge-top":"-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("more_vert")])],1)]}}],null,true)},[_c(VList,{staticClass:"white"},[_c(VListItem,[_c(VBtn,{attrs:{"plain":"","elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.removeUser(group)}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("delete")]),_vm._v(" "+_vm._s(_vm.$t("general.delete"))+" ")],1)],1)],1)],1)],1)],1)],1)],1)],1)}),1):(!_vm.loading && !_vm.hasData)?_c('div',[_vm._v(" No Data ")]):_vm._e()])],1),(_vm.openAddUserGroupDialog)?_c('AddUserGroupDialog',{attrs:{"openAddUserGroupDialog":_vm.openAddUserGroupDialog,"user":_vm.user,"userGroups":_vm.userGroups,"rounded":false},on:{"refreshDataTable":function($event){return _vm.$apollo.queries.userGroups.refetch()},"closeAddDialog":function($event){_vm.openAddUserGroupDialog = false}}}):_vm._e(),_c('DeleteUserGroupDialog',{attrs:{"openDeleteDialog":_vm.openDeleteUserGroupDialog,"group":_vm.selectedGroup,"userIds":_vm.selecteIds},on:{"refreshDataTable":function($event){return _vm.$apollo.queries.userGroups.refetch()},"closeDeleteDialog":_vm.closeAndCleanDeletedGroups},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("users.remove_user")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("users.sure_group")))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }