import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.freights),function(freight,i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"6"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{model:{value:(_vm.formValue),callback:function ($$v) {_vm.formValue=$$v},expression:"formValue"}},[_c(VCard,{staticClass:"pr-3 pt-2 pb-n7",attrs:{"flat":"","outlined":""}},[_c(VCardTitle,{staticClass:"py-0 pr-0"},[_c(VChip,{attrs:{"small":""}},[_c('div',{staticClass:"grey--text title-2"},[_c('i',[_vm._v(_vm._s(_vm.$t("headers.freight"))+" "+_vm._s(i + 1))])])]),_c(VSpacer),_c(VBtn,{attrs:{"rounded":"","icon":"","color":"primary","small":""},on:{"click":_vm.addFreight}},[_c(VIcon,[_vm._v("add")])],1),_c(VBtn,{attrs:{"icon":"","small":"","color":"grey"},on:{"click":function($event){return _vm.deleteItem(i)}}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VCardText,{staticClass:"mb-n3 pa-0"},[_c(VTimeline,{staticClass:"ml-n1 pr-2",attrs:{"dense":"","align-top":""}},[_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("tag")])]},proxy:true}],null,true)},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":_vm.templateMode
                                                        ? _vm.$t(
                                                              'forms.freight_ref'
                                                          )
                                                        : _vm.$t(
                                                              'forms.freight_ref'
                                                          ) + ' *',"outlined":"","dense":"","rules":[_vm.rules.required],"disabled":_vm.templateMode,"filled":_vm.templateMode},model:{value:(
                                                    freight.referenceNumber
                                                ),callback:function ($$v) {_vm.$set(freight, "referenceNumber", $$v)},expression:"\n                                                    freight.referenceNumber\n                                                "}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"5"}},[_c(VTextField,{attrs:{"label":_vm.$t('forms.order_no'),"outlined":"","dense":"","disabled":_vm.templateMode,"filled":_vm.templateMode},model:{value:(
                                                    freight.orderNumber
                                                ),callback:function ($$v) {_vm.$set(freight, "orderNumber", $$v)},expression:"\n                                                    freight.orderNumber\n                                                "}})],1)],1)],1),_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("tag")])]},proxy:true}],null,true)},[_c(VRow,{attrs:{"dense":""}},[(
                                                _vm.trip.transportMode ===
                                                    'sea' ||
                                                    _vm.trip.transportMode ===
                                                        'air'
                                            )?_c(VCol,{staticClass:"py-0",attrs:{"cols":"6"}},[(
                                                    _vm.trip.transportMode ===
                                                        'sea'
                                                )?_c(VTextField,{attrs:{"label":_vm.$t(
                                                        'headers.container_number'
                                                    ),"outlined":"","dense":"","disabled":_vm.templateMode,"filled":_vm.templateMode},model:{value:(
                                                    freight.containerNumber
                                                ),callback:function ($$v) {_vm.$set(freight, "containerNumber", $$v)},expression:"\n                                                    freight.containerNumber\n                                                "}}):_vm._e(),(
                                                    _vm.trip.transportMode ===
                                                        'air'
                                                )?_c(VTextField,{attrs:{"label":_vm.$t('forms.awb'),"outlined":"","dense":"","disabled":_vm.templateMode,"filled":_vm.templateMode},model:{value:(freight.awb),callback:function ($$v) {_vm.$set(freight, "awb", $$v)},expression:"freight.awb"}}):_vm._e()],1):_vm._e(),_c(VCol,{staticClass:"py-0",attrs:{"cols":_vm.trip.transportMode ===
                                                    'sea' ||
                                                _vm.trip.transportMode === 'air'
                                                    ? 5
                                                    : 11}},[_c(VTextField,{attrs:{"label":_vm.$t('forms.bill_lading'),"outlined":"","dense":"","disabled":_vm.templateMode,"filled":_vm.templateMode},model:{value:(
                                                    freight.billsOfLading
                                                ),callback:function ($$v) {_vm.$set(freight, "billsOfLading", $$v)},expression:"\n                                                    freight.billsOfLading\n                                                "}})],1)],1)],1),_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("description")])]},proxy:true}],null,true)},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":_vm.$t('forms.goods'),"outlined":"","dense":""},model:{value:(freight.goods),callback:function ($$v) {_vm.$set(freight, "goods", $$v)},expression:"freight.goods"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"5"}},[_c(VSelect,{attrs:{"items":_vm.termChoices,"label":_vm.$t('forms.term'),"outlined":"","dense":""},model:{value:(freight.term),callback:function ($$v) {_vm.$set(freight, "term", $$v)},expression:"freight.term"}})],1)],1)],1),_c(VTimelineItem,{attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("location_on")])]},proxy:true}],null,true)},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":_vm.$t('forms.load_point'),"outlined":"","dense":"","hide-details":""},model:{value:(freight.loadPoint),callback:function ($$v) {_vm.$set(freight, "loadPoint", $$v)},expression:"freight.loadPoint"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"5"}},[_c('DateComponent',{attrs:{"label":_vm.$t('forms.load_date'),"icon":'calendar_month',"outlined":true,"dense":true,"hideDetails":true,"disabled":_vm.templateMode,"filled":_vm.templateMode},model:{value:(freight.loadDate),callback:function ($$v) {_vm.$set(freight, "loadDate", $$v)},expression:"freight.loadDate"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"11"}},[_c(VTextField,{attrs:{"label":_vm.$t(
                                                        'forms.contact_person'
                                                    ),"outlined":"","dense":"","hide-details":""},model:{value:(freight.contact),callback:function ($$v) {_vm.$set(freight, "contact", $$v)},expression:"freight.contact"}})],1)],1)],1),_c(VTimelineItem,{staticClass:"py-0",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("settings_remote")])]},proxy:true}],null,true)},[_c(VRow,{staticClass:"pb-5",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"11"}},[_c('TrackerSelected',{attrs:{"label":_vm.$t(
                                                        'forms.select_tracker'
                                                    ),"dense":true,"currentTrackers":_vm.currentTrackers,"disabled":_vm.templateMode,"filled":_vm.templateMode},model:{value:(freight.trackers),callback:function ($$v) {_vm.$set(freight, "trackers", $$v)},expression:"freight.trackers"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"11"}},[_c('DateTimeComponent',{attrs:{"label":_vm.$t('trackers.tracker_start_date'),"icon":'calendar_month',"outlined":true,"dense":true,"disabled":_vm.templateMode,"filled":_vm.templateMode,"defaultTime":'00:00',"rules":[_vm.trackerDateChange(freight.startDate, freight.endDate)]},model:{value:(freight.startDate),callback:function ($$v) {_vm.$set(freight, "startDate", $$v)},expression:"freight.startDate"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"11"}},[_c('DateTimeComponent',{attrs:{"label":_vm.$t('trackers.tracker_end_date'),"icon":'calendar_month',"outlined":true,"dense":true,"disabled":_vm.templateMode,"filled":_vm.templateMode,"defaultTime":'00:00',"rules":freight.endDate !== null &&
                                                    freight.endDate.length > 0
                                                    ? [_vm.checkTrackerDate(freight.startDate,freight.endDate)]
                                                    : []},model:{value:(freight.endDate),callback:function ($$v) {_vm.$set(freight, "endDate", $$v)},expression:"freight.endDate"}})],1)],1)],1),_c(VTimelineItem,{attrs:{"color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("notifications")])]},proxy:true}],null,true)},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"11"}},[_c('AlertRuleAutoComplete',{attrs:{"label":_vm.$t(
                                                        'forms.select_alert_rule'
                                                    ),"dense":true,"refresh":_vm.refresh},on:{"update:refresh":function($event){_vm.refresh=$event}},model:{value:(freight.alerts),callback:function ($$v) {_vm.$set(freight, "alerts", $$v)},expression:"freight.alerts"}})],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"mt-2",attrs:{"icon":"","outlined":"","color":"primary","small":""},on:{"click":function($event){return _vm.addAlertRuleMethod(i)}}},[_c(VIcon,[_vm._v(" add")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}),1),_c('AddAlertRuleDialog',{attrs:{"openAddAlertRuleDialog":_vm.openAddAlertRule,"createMode":true},on:{"refreshDataTable":function($event){_vm.refresh = true},"close-dialog":function($event){_vm.openAddAlertRule = false},"item-created":_vm.onItemCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }