import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"mt-5 mb-15 px-5"},[(!_vm.canAccess)?_c('div',[_c('NoPermission')],1):_c('div',[_c(VCard,{staticClass:"mb-5 pb-0",attrs:{"elevation":"1","rounded":"xl"}},[_c(VCardTitle,{staticClass:"background2 py-3"},[_c(VRow,[_c(VCol,{staticClass:"mr-2",attrs:{"cols":"auto"}},[_c(VIcon,[_vm._v("query_stats")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t("headers.timeline_overview")))])])],1),_c(VSpacer),_c('ExportTripTrackerDialog',{attrs:{"selectedFreightId":_vm.tripFreight,"selectedTrackerId":_vm.trackerId,"startTime":_vm.chartStartTime,"endTime":_vm.chartEndTime,"trip":_vm.trip,"selectedTrackerSerialNumber":_vm.selectedTrackerSerialNumber},on:{"update:selectedFreightId":function($event){_vm.tripFreight=$event},"update:selected-freight-id":function($event){_vm.tripFreight=$event},"update:selectedTrackerId":function($event){_vm.trackerId=$event},"update:selected-tracker-id":function($event){_vm.trackerId=$event}}})],1),_c(VCardText,{staticClass:"px-0 py-0"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"px-8 mt-3 d-flex align-center",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"3"}},[_c(VAutocomplete,{attrs:{"items":_vm.trip.tripFreightSet.edges,"no-data-text":_vm.$t('loading.no_available'),"auto-select-first":"","item-text":"node.referenceNumber","item-value":"node.id","hide-details":"","label":_vm.$t('headers.freight') + '*',"dense":"","color":"primary","disabled":_vm.freight ? true : false,"outlined":"","attach":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(
                                                data.item.node
                                                    .containerNumber &&
                                                    _vm.trip.transportMode.toLowerCase() ===
                                                        'sea'
                                            )?_c('span',[_vm._v(_vm._s(data.item.node .containerNumber))]):(
                                                data.item.node
                                                    .airWaybillNumber &&
                                                    _vm.trip.transportMode.toLowerCase() ===
                                                        'air'
                                            )?_c('span',[_vm._v(_vm._s(data.item.node .airWaybillNumber))]):_c('span',[_vm._v("("+_vm._s(data.item.node .referenceNumber)+")")])]}},{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2",attrs:{"dense":""}},[(
                                                    data.item.node
                                                        .containerNumber &&
                                                        _vm.trip.transportMode.toLowerCase() ===
                                                            'sea'
                                                )?_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(data.item.node .containerNumber)+" ")]):(
                                                    data.item.node
                                                        .airWaybillNumber &&
                                                        _vm.trip.transportMode.toLowerCase() ===
                                                            'air'
                                                )?_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(data.item.node .airWaybillNumber)+" ")]):_vm._e(),(
                                                    data.item.node
                                                        .referenceNumber
                                                )?_c(VCol,{attrs:{"cols":"6"}},[_c('span',{staticClass:"grey--text mr-1"},[_c('i',[_vm._v("("+_vm._s(data.item.node .referenceNumber)+")")])])]):_vm._e()],1)]}}]),model:{value:(_vm.tripFreight),callback:function ($$v) {_vm.tripFreight=$$v},expression:"tripFreight"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VAutocomplete,{attrs:{"auto-select-first":"","items":_vm.trackers,"item-text":"serialNumber","item-value":"id","label":_vm.$t('headers.tracker'),"hide-details":"","dense":"","color":"primary","outlined":"","disabled":_vm.trackers.length < 1,"no-data-text":_vm.$t('loading.no_tracker'),"hide-no-data":false,"attach":""},model:{value:(_vm.trackerId),callback:function ($$v) {_vm.trackerId=$$v},expression:"trackerId"}})],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VAutocomplete,{class:_vm.screenSize === 'xl'
                                            ? 'maxScreenWidth'
                                            : 'maxWidth',attrs:{"items":_vm.sensorChoices,"multiple":"","small-chips":"","hide-details":"","label":_vm.$t('trips.sensor'),"dense":"","color":"primary","outlined":"","item-text":"text","item-value":"value","disabled":!_vm.showCharts && !_vm.trackerId,"attach":""},model:{value:(_vm.selectedSensors),callback:function ($$v) {_vm.selectedSensors=$$v},expression:"selectedSensors"}})],1)],1)],1),_c(VCol,{staticClass:"px-6"},[(_vm.isLoadingChart)?_c('LoadingBar'):_vm._e(),(!_vm.isLoadingChart)?_c('SummaryTimelineChart',{attrs:{"minTemperature":_vm.minTemperature,"maxTemperature":_vm.maxTemperature,"minHumidity":_vm.minHumidity,"maxHumidity":_vm.maxHumidity,"minLight":_vm.minLight,"maxLight":_vm.maxLight,"minShock":_vm.minShock,"maxShock":_vm.maxShock,"startTime":_vm.chartStartTime,"endTime":_vm.chartEndTime,"eventsLocations":_vm.eventsLocation,"alertTrigger":_vm.tripAlertTriggers,"tripAlert":_vm.tripAlert,"temperatureSeries":_vm.mergeSensorData.temperature,"probeTemperaturesSeries":_vm.mergeSensorData.probeTemperature,"externalTemperaturesSeries":_vm.mergeSensorData.externalTemperature,"lightSeries":_vm.mergeSensorData.light,"humiditySeries":_vm.mergeSensorData.humidity,"shockSeries":_vm.mergeSensorData.shock,"selected":_vm.selectedSensors,"merge":_vm.mergeSensorData}}):_vm._e()],1)],1),_c(VExpansionPanels,{staticClass:"rounded-b-xl",attrs:{"accordion":"","full-width":"","multiple":"","flat":""},model:{value:(_vm.openPanels),callback:function ($$v) {_vm.openPanels=$$v},expression:"openPanels"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{on:{"click":function($event){$event.preventDefault();return _vm.onExpansionPanelClick($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var open = ref.open;
return [_c(VRow,{class:open,attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(open ? _vm.$t("general.see_less") : _vm.$t("general.see_more")))])])],1)]}}])}),_c(VExpansionPanelContent,[_c(VTabs,{ref:"tabs",attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{key:"timeline",attrs:{"href":"#tab-timeline"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("headers.timeline_history"))+" ")])]),_c(VTab,{key:"geofence",attrs:{"href":"#tab-geofence"}},[_c('span',[_vm._v(_vm._s(_vm.$t("headers.geofence_history")))])])],1),_c(VDivider),_c(VTabsItems,{style:("height=200px"),model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"tab-timeline"}},[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{staticClass:"mr-3",attrs:{"cols":"auto"}}),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"pl-8 mt-3 mb-2 d-flex align-center",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-3 primary elevation-2",attrs:{"small":"","icon":"","outlined":""},on:{"click":function($event){_vm.sortDescTimeline = !_vm.sortDescTimeline}}},on),[(
                                                                        _vm.sortDescTimeline
                                                                    )?_c(VIcon,{attrs:{"color":"white"}},[_vm._v("arrow_downward")]):_c(VIcon,{attrs:{"color":"white"}},[_vm._v("arrow_upward")])],1)]}}])},[_c('span',[_vm._v("Sort "+_vm._s(_vm.sortDescTimeline ? "ascending" : "descending"))])])],1),_c(VCol,{staticClass:"d-flex justify-center align-center",attrs:{"cols":"2","lg":"auto","md":"2","sm":"2","xs":"2"}},[_c(VSwitch,{staticClass:"mt-n1",attrs:{"disabled":!_vm.trackerId,"label":_vm.$t(
                                                                'headers.charts'
                                                            ),"color":"primary","hide-details":""},model:{value:(_vm.showCharts),callback:function ($$v) {_vm.showCharts=$$v},expression:"showCharts"}})],1)],1),_c(VRow,{staticClass:"py-0 mb-1"},[_c(VCol,[(
                                                            _vm.$apollo.queries
                                                                .tripFreightTimelines
                                                                .loading
                                                        )?_c('LoadingBar'):_c(VCard,{attrs:{"flat":""}},[_c(VTimeline,{attrs:{"dense":"","align-top":""}},_vm._l((_vm.timelineEvents),function(item,i){return _c('div',{key:i},[_c(VTimelineItem,{attrs:{"color":_vm.getEventColor(
                                                                            item
                                                                                .node
                                                                                .eventType
                                                                        )},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.getEventIcon( item .node .eventType ))+" ")])]},proxy:true}],null,true)},[_c(VCard,{attrs:{"flat":""}},[_c(VRow,{staticClass:"px-1"},[_c(VCol,{attrs:{"cols":"2"}},[_c('strong',{class:_vm.isFutureDate(
                                                                                            item
                                                                                                .node
                                                                                                .eventTime
                                                                                        )
                                                                                            ? 'grey--text'
                                                                                            : ''},[_vm._v(" "+_vm._s(_vm.getDate( item .node .eventTime ))+" ")]),_c('div',{class:_vm.isFutureDate(
                                                                                            item
                                                                                                .node
                                                                                                .eventTime
                                                                                        )
                                                                                            ? 'grey--text'
                                                                                            : ''},[_vm._v(" "+_vm._s(_vm.getTime( item .node .eventTime ))+" ")])]),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{class:_vm.isFutureDate(
                                                                                            item
                                                                                                .node
                                                                                                .eventTime
                                                                                        )
                                                                                            ? 'grey--text'
                                                                                            : '',domProps:{"innerHTML":_vm._s(
                                                                                        _vm.getEventTitle(
                                                                                            item.node
                                                                                        )
                                                                                    )}})])],1)],1)],1),(
                                                                        !_vm.isFutureDate(
                                                                            item
                                                                                .node
                                                                                .eventTime
                                                                        ) &&
                                                                            _vm.showCharts
                                                                    )?_c(VTimelineItem,{attrs:{"hide-dot":""}},[(
                                                                            _vm.isLoadingChart
                                                                        )?_c('LoadingBar'):_c('div',{staticClass:"chartContainer"},[(
                                                                                _vm.trackerId !==
                                                                                    null &&
                                                                                    _vm.trackerId !==
                                                                                        undefined &&
                                                                                    _vm.showCharts
                                                                            )?_c('TripFreightTimelineChart',{attrs:{"minTemperature":_vm.minTemperature,"maxTemperature":_vm.maxTemperature,"minHumidity":_vm.minHumidity,"maxHumidity":_vm.maxHumidity,"minLight":_vm.minLight,"maxLight":_vm.maxLight,"minShock":_vm.minShock,"maxShock":_vm.maxShock,"chartStartTime":_vm.chartStartTime,"chartEndTime":_vm.chartEndTime,"currentStartTime":_vm.getCurrentStartTime(
                                                                                    i
                                                                                ),"currentEndTime":_vm.getCurrentEndTime(
                                                                                    i
                                                                                ),"alertTrigger":_vm.tripAlertTriggers,"tripAlert":_vm.showCurrentEventAlertTime(
                                                                                    i,
                                                                                    item
                                                                                        .node
                                                                                        .eventTime,
                                                                                    _vm.tripAlert
                                                                                ),"temperatureSeries":_vm.mergeSensorData.temperature,"probeTemperaturesSeries":_vm.mergeSensorData.probeTemperature,"externalTemperaturesSeries":_vm.mergeSensorData.externalTemperature,"lightSeries":_vm.mergeSensorData.light,"humiditySeries":_vm.mergeSensorData.humidity,"shockSeries":_vm.mergeSensorData.shock,"selected":_vm.selectedSensors}}):_vm._e()],1)],1):_vm._e()],1)}),0)],1)],1)],1)],1)],1)],1),_c(VTabItem,{attrs:{"value":"tab-geofence"}},[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{staticClass:"mr-3",attrs:{"cols":"auto"}}),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"pl-8 mt-3 mb-2 d-flex align-center",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-3 primary elevation-2",attrs:{"small":"","icon":"","outlined":""},on:{"click":function($event){_vm.sortDescGeofence = !_vm.sortDescGeofence}}},on),[(
                                                                        _vm.sortDescGeofence
                                                                    )?_c(VIcon,{attrs:{"color":"white"}},[_vm._v("arrow_downward")]):_c(VIcon,{attrs:{"color":"white"}},[_vm._v("arrow_upward")])],1)]}}])},[_c('span',[_vm._v("Sort "+_vm._s(_vm.sortDescGeofence ? "ascending" : "descending"))])])],1)],1),_c(VRow,{staticClass:"py-0 mb-1"},[_c(VCol,[(
                                                            _vm.$apollo.queries
                                                                .tripFreightGeofenceEvents
                                                                .loading
                                                        )?_c('LoadingBar'):_c(VCard,{attrs:{"flat":""}},[_c(VTimeline,{attrs:{"dense":"","align-top":""}},_vm._l((_vm.geofenceEvents),function(item,i){return _c('div',{key:i},[_c(VTimelineItem,{attrs:{"color":_vm.getEventColor(
                                                                            item.eventType
                                                                        )},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VIcon,{attrs:{"small":"","dark":""}},[_vm._v(_vm._s(_vm.getEventIcon( item.eventType )))])]},proxy:true}],null,true)},[_c(VCard,{attrs:{"flat":""}},[_c(VRow,{staticClass:"px-1"},[_c(VCol,{attrs:{"cols":"2"}},[_c('strong',{class:_vm.isFutureDate(
                                                                                            item.eventTime
                                                                                        )
                                                                                            ? 'grey--text'
                                                                                            : ''},[_vm._v(_vm._s(_vm.getDate( item.eventTime )))]),_c('div',{class:_vm.isFutureDate(
                                                                                            item.eventTime
                                                                                        )
                                                                                            ? 'grey--text'
                                                                                            : ''},[_vm._v(" "+_vm._s(_vm.getTime( item.eventTime ))+" ")])]),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-no-wrap"},[_c('div',{staticClass:"d-inline"},[_c('strong',[_vm._v(_vm._s(item.location))])]),(
                                                                                            item.hasEnvironmentData
                                                                                        )?_c('div',{staticClass:"d-inline"},[_c('b',[_vm._v("(")]),(
                                                                                                item.temperature
                                                                                            )?_c('strong',[_vm._v(_vm._s(item.temperature)+" ºC")]):_vm._e(),(
                                                                                                item.humidity
                                                                                            )?_c('strong',[_vm._v(" "+_vm._s(item.humidity)+"%")]):_vm._e(),_c('b',[_vm._v(")")])]):_vm._e(),_c('div',_vm._l((item.emails),function(email,i){return _c(VChip,{key:i,staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(email))])}),1),_c('div',[_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.getEventSubtitle( item.eventType )))])])])])],1)],1)],1)],1)}),0)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }