import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"pa-2 mx-1",attrs:{"label":"","rounded":_vm.rounded,"plain":_vm.plain,"color":_vm.color,"disabled":_vm.selectedTrackerId == null || _vm.selectedFreightId == null}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.iconColor}},[_vm._v("download")]),_vm._v(" "+_vm._s(_vm.$t("general.download"))+" ")],1)]}}]),model:{value:(_vm.exportDataDialog),callback:function ($$v) {_vm.exportDataDialog=$$v},expression:"exportDataDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("download")]),_vm._v(" "+_vm._s(_vm.$t("trips.download_timeline"))+" ")],1),_c(VCardText,[[_c(VRow,{staticClass:"mt-5 align-center"},[_c(VCol,{attrs:{"cols":"4"}},[_c('span',{staticClass:"ml-5 subtitle-1"},[_vm._v(_vm._s(_vm.$t("trips.file_format"))+":")])]),_c(VCol,{attrs:{"cols":"6"}},[_c(VSelect,{attrs:{"auto-select-first":"","items":_vm.fileFormatsAvailable},model:{value:(_vm.fileFormat),callback:function ($$v) {_vm.fileFormat=$$v},expression:"fileFormat"}})],1)],1)]],2),_c(VCardActions,{staticClass:"d-flex justify-end pb-5 pr-5"},[_c(VBtn,{attrs:{"text":"","rounded":_vm.rounded,"disabled":_vm.isLoadingFile},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.close")))]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.isLoadingFile,"rounded":_vm.rounded,"outlined":_vm.outlined,"dense":""},on:{"click":_vm.submitExportData}},[_vm._v(" "+_vm._s(_vm.$t("general.download"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }