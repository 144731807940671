<template>
    <main>
        <LoadingBar
            v-if="$apollo.queries.locations.loading || isLoadingTable"
        ></LoadingBar>

        <div v-if="isChartRendered" class="pb-5">
            <div v-if="locationsShowed.edges.length > 0">
                <div class="row mt-3">
                    <draggable
                        class="row"
                        v-model="locationsShowed.edges"
                        v-bind="this.dragOptions()"
                    >
                        <div
                            v-for="(edge, index) in locationsShowed.edges"
                            :key="index"
                            :class="[
                                'py-0',
                                'col-md-4',
                                index % 3 === 0
                                    ? 'pr-0 pl-6'
                                    : index % 3 === 2
                                    ? 'pl-0 pr-6'
                                    : 'px-0'
                            ]"
                        >
                            <v-card
                                class="pb-2 px-1 mx-2 my-2"
                                elevation="5"
                                outlined
                            >
                                <v-card-actions
                                    class="justify-space-between py-0 d-flex align-items-center"
                                >
                                    <v-card-title color="black">
                                        {{ edge.node.city }} -
                                        {{ edge.node.country }}
                                    </v-card-title>
                                    <v-menu
                                        offset-y
                                        transition="slide-y-transition"
                                        width="250px"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-for="(item,
                                                i) in actionChoices"
                                                :key="i"
                                                @click="handleClick(item, edge)"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon>{{
                                                        item.icon
                                                    }}</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{
                                                        item.text
                                                    }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <!-- <div>
                                        <v-tooltip bottom>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    @click="
                                                        onCheckDetailsButtonClick(
                                                            edge
                                                        )
                                                    "
                                                >
                                                    <v-icon>edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Edit</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    @click="
                                                        onRemoveLocationButtonClick(
                                                            edge
                                                        )
                                                    "
                                                >
                                                    <v-icon
                                                        >visibility_off</v-icon
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Remove Location</span>
                                        </v-tooltip>
                                    </div> -->
                                </v-card-actions>
                                <div>
                                    <WeatherChart
                                        :edge="edge"
                                    ></WeatherChart>
                                    <!-- <apexchart
                                        class="px-1 pb-1"
                                        ref="forecastChart"
                                        :options="getChartOptions(edge)"
                                        :series="getChartSeries(edge)"
                                        type="line"
                                        height="300"
                                    /> -->

                                </div>
                            </v-card>
                        </div>
                    </draggable>
                </div>
            </div>
            <div v-if="locationsShowed.edges.length === 0">
                <div class="row">
                    <div class="col-md-12 text-center mt-10">
                        <span>{{ $t("weather.no_locations") }}</span>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="openEditDialog" persistent max-width="300"
            ><v-card
                ><v-card-title class="primary white--text"
                    >{{ locationtoEdit.city }} -
                    {{ locationtoEdit.country }}</v-card-title
                >
                <v-card-text>
                    <div class="mt-10">
                        <MinMaxTemperature
                            v-model="locationtoEdit"
                        ></MinMaxTemperature>
                    </div>
                </v-card-text>
                <v-card-actions class=" justify-end">
                    <v-btn text @click="resetLocation" rounded>{{
                        $t("general.cancel")
                    }}</v-btn>
                    <v-btn
                        color="primary"
                        @click="editLocation(locationtoEdit)"
                        rounded
                        :loading="isLoadingEdit"
                        :disabled="!formValid"
                        >{{ $t("general.save") }}</v-btn
                    >
                </v-card-actions>
            </v-card></v-dialog
        >
    </main>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import LoadingBar from "@/components/base/LoadingBar.vue";
import MinMaxTemperature from "@/components/weather/forms/MinMaxTemperature.vue";
import { eventBus } from "@/main.js";
import draggable from "vuedraggable";
import WeatherChart from "@/components/weather/chart/WeatherChart.vue";

export default {
    name: "WeatherTable",
    components: {
        WeatherChart,
        LoadingBar,
        draggable,
        MinMaxTemperature
    },
    props: {
        locationsFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    apollo: {
        locations: {
            query: gql`
                query tenantLocations(
                    $orderBy: String
                    $cityIcontains: String
                    $countryIcontains: String
                    $warningCities: Boolean
                ) {
                    tenantLocationsWeather(
                        orderBy: $orderBy
                        city_Icontains: $cityIcontains
                        country_Icontains: $countryIcontains
                        warningCities: $warningCities
                        weatherForecastSubscriptionSet_Enabled: true
                    ) {
                        edges {
                            node {
                                id
                                name
                                locationType
                                isTrackerStockLocation
                                fullAddress
                                city
                                state
                                country
                                latitude
                                longitude
                                radius
                                tags
                                weatherForecastSubscriptionSet {
                                    edges {
                                        node {
                                            minTemperature
                                            maxTemperature
                                            enabled
                                            lastSyncTime
                                            tenantLocationWeatherForecastSet {
                                                edges {
                                                    node {
                                                        id
                                                        maxTemperature
                                                        minTemperature
                                                        forecastDate
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                geometryType
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.locationsFilter;
            },
            pollInterval: 30000,
            fetchPolicy: "cache-and-network",
            debounce: 800,
            update: response => {
                return response.tenantLocationsWeather;
            },
            skip: false,
            result({ data }) {
                if (data) {
                    //parse and stringify to avoid reactivity
                    this.locationsShowed = JSON.parse(
                        JSON.stringify(data.tenantLocationsWeather)
                    );
                    this.locationsShowed.edges = this.locationsShowed.edges.map(
                        ({ __typename, node }) => {
                            return {
                                __typename: __typename,
                                node: {
                                    ...node,
                                    maxTemperature:
                                        node.weatherForecastSubscriptionSet
                                            ?.edges[0].node.maxTemperature,
                                    minTemperature:
                                        node.weatherForecastSubscriptionSet
                                            ?.edges[0].node.minTemperature,
                                    enabled:
                                        node.weatherForecastSubscriptionSet
                                            ?.edges[0].node.enabled,
                                    lastSyncTime:
                                        node.weatherForecastSubscriptionSet
                                            ?.edges[0].node.lastSyncTime,
                                    tenantLocationWeatherForecastSet: 
                                        node.weatherForecastSubscriptionSet
                                            ?.edges[0].node.tenantLocationWeatherForecastSet
                                }
                            };
                        }
                    );
                    if (this.last_sync_time === null) {
                        if (data.tenantLocationsWeather.edges.length > 0) {
                            this.last_sync_time =
                                data.tenantLocationsWeather.edges[0].node.lastSyncTime;
                        }
                    }
                    this.displayedEdgesIds = helper.getWeatherLocationOrder();
                    if (!this.isFilterEnabled) this.orderLocationsToShow();

                    this.isChartRendered = true;
                    this.$nextTick(() => {
                        if (this.$refs.forecastChart) {
                            this.$refs.forecastChart.forEach(chartInstance => {
                                //check if series "Limit Max Temperature" has data to show, if has hideSeries
                                const series = chartInstance.series;
                                const hasDataToShow = series[2].data.some(
                                    data => data !== null
                                );
                                if (hasDataToShow) {
                                    if (this.isWarningCitiesFilterEnabled) {
                                        chartInstance.showSeries(
                                            this.$t("weather.limit_max")
                                        );
                                    } else {
                                        chartInstance.hideSeries(
                                            this.$t("weather.limit_max")
                                        );
                                    }
                                }

                                //check if series "Limit Min Temperature" has data to show, if has hideSeries
                                const hasDataToShow2 = series[3].data.some(
                                    data => data !== null
                                );
                                if (hasDataToShow2) {
                                    if (this.isWarningCitiesFilterEnabled) {
                                        chartInstance.showSeries(
                                            this.$t("weather.limit_min")
                                        );
                                    } else {
                                        chartInstance.hideSeries(
                                            this.$t("weather.limit_min")
                                        );
                                    }
                                }
                            });
                        }
                        if (this.allHaveDataToShow) {
                            this.stopLocationsPolling();
                        } else {
                            this.startLocationsPolling();
                        }
                    });
                }
                this.isLoadingTable = false;
            }
        }
    },
    data() {
        return {
            isLoadingTable: false,
            isLoadingEdit: false,
            openEditDialog: false,
            locationtoEdit: {},
            selectAction: null,
            locations: { edges: [] },
            locationsToShow: { edges: [] },
            isChartRendered: false,
            displayedEdgesIds: [],
            last_sync_time: null,
            actionChoices: [
                {
                    icon: "edit",
                    text: this.$t("general.edit")
                },
                {
                    icon: "delete",
                    text: this.$t("general.delete")
                }
            ]
        };
    },
    watch: {
        "locationsShowed.edges": {
            handler(val) {
                if (!this.isFilterEnabled) {
                    this.displayedEdgesIds = val.map(edge => edge.node.id);
                    helper.updateMyPreferences({
                        weatherLocations: this.displayedEdgesIds
                    });
                }
            }
        },
        last_sync_time(val) {
            if (val !== null) {
                this.$emit("last_sync_time", val);
            }
        }
    },
    computed: {
        formValid() {
            let _max = parseFloat(this.locationtoEdit.maxTemperature);
            let _min = parseFloat(this.locationtoEdit.minTemperature);
            return (
                _max == null ||
                _min == null ||
                _max == "" ||
                _min == "" ||
                _max >= _min
            );
        },
        locationsShowed: {
            get() {
                return this.locationsToShow;
            },
            set(value) {
                if (!this.isFilterEnabled) this.orderLocationsToShow();
                this.locationsToShow = value;
            }
        },
        isFilterEnabled() {
            return (
                (this.locationsFilter.cityIcontains !== undefined &&
                    this.locationsFilter.cityIcontains !== "" &&
                    this.locationsFilter.cityIcontains !== null) ||
                (this.locationsFilter.countryIcontains !== undefined &&
                    this.locationsFilter.countryIcontains !== "" &&
                    this.locationsFilter.countryIcontains !== null) ||
                (this.locationsFilter.orderBy !== undefined &&
                    this.locationsFilter.orderBy !== "" &&
                    this.locationsFilter.orderBy !== null) ||
                this.locationsFilter.warningCities === true
            );
        },
        isWarningCitiesFilterEnabled() {
            return this.locationsFilter?.warningCities === true;
        },
        allHaveDataToShow() {
            return this.locationsToShow.edges.every(edge => {
                return (
                    edge.node.tenantLocationWeatherForecastSet.edges.length > 0
                );
            });
        }
    },
    mounted() {},
    created() {
        this.formatTemperature = helper.formatTemperature;
        this.getDateFormat = helper.getDateFormat;
        this.formatDateTime = helper.formatDateTime;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        eventBus.$on("refetchData", this.refetchData);
    },
    methods: {
        handleClick(item, edge) {
            switch (item.text) {
                case this.$t("general.edit"):
                    this.locationtoEdit = JSON.parse(JSON.stringify(edge.node));
                    this.openEditDialog = true;
                    break;
                case this.$t("general.delete"):
                    this.deleteLocation(edge.node);
                    break;
            }
        },

        resetLocation() {
            this.locationtoEdit = {};
            this.openEditDialog = false;
        },
        editLocation(location) {
            this.isLoadingEdit = true;

            let payload = {
                id: location.id,
                locationType: location.locationType.toLowerCase(),
                isTrackerStockLocation: location.isTrackerStockLocation,
                showWeatherForecast:
                    location.enabled,
                name: location.name,
                city: location.city,
                country: location.country,
                fullAddress: location.fullAddress,
                maxTemperature:
                    location.maxTemperature == ""
                        ? null
                        : location.maxTemperature,
                minTemperature:
                    location.minTemperature == ""
                        ? null
                        : location.minTemperature,
                geometryType: location.geometryType,
                latitude: location.latitude,
                longitude: location.longitude,
                radius: location.radius
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateLocation(
                            $input: UpdateTenantLocationInput!
                        ) {
                            updateTenantLocation(input: $input) {
                                location {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ...payload
                        }
                    }
                })
                .then(() => {
                    this.isLoadingEdit = false;
                })
                .finally(() => {
                    this.resetLocation();
                    this.refetchData();
                });
        },
        deleteLocation(node) {
            let payload = {
                locations: [
                    {
                        id: node.id
                    }
                ],
                isAdding: false
            };
            this.isLoadingTable = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation removeWeatherLocation(
                            $input: UpdateTenantLocationWeatherForecastInput!
                        ) {
                            updateTenantLocationWeatherForecast(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .finally(() => {
                    this.refetchData().finally(() => {
                        this.isLoadingTable = false;
                        this.$emit("locationDeleted");
                    });
                });
        },
        stopLocationsPolling() {
            this.$apollo.queries.locations.stopPolling();
        },
        startLocationsPolling() {
            this.$apollo.queries.locations.startPolling(30000);
        },
        getChartOptions(edge) {
            return {
                chart: {
                    id: "forecast-chart-" + edge.node.id,
                    type: "line",

                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    type: "datetime",
                    categories: edge.node.tenantLocationWeatherForecastSet.edges.map(
                        forecast =>
                            new Date(forecast.node.forecastDate).getTime()
                    ),
                    tickAmount: 2
                },
                tooltip: {
                    x: {
                        format: "dd/MM/yyyy"
                    }
                },
                colors: ["#FC6152", "#5C7FFF", "#F94332", "#3963FF"],
                dataLabels: {
                    enabled: true,
                    formatter: function(val, opts) {
                        if (opts.seriesIndex === 0 || opts.seriesIndex === 1) {
                            return val;
                        }
                        return "";
                    }
                },
                stroke: {
                    width: [3, 3, 2, 2],
                    curve: "smooth",
                    dashArray: [0, 0, 7, 7]
                },
                legend: {
                    show: false,
                    showForNullSeries: false,
                    showForZeroSeries: false
                },
                yaxis: [
                    {
                        title: {
                            text:
                                this.$t("general.temperature") +
                                " " +
                                this.getTemperatureSymbol(),
                            style: {
                                color: "#BDBDBD",
                                fontSize: "14px"
                            }
                        }
                    }
                ]
            };
        },
        getChartSeries(edge) {
            
            let chartSeries = [
                {
                    name: this.$t("weather.limit_max"),
                    data: edge.node.tenantLocationWeatherForecastSet.edges.map(
                        forecast =>
                            parseFloat(
                                this.formatTemperature(
                                    forecast.node?.maxTemperature
                                ) ?? null
                            ).toFixed(2)
                    )
                },
                {
                    name: this.$t("weather.limit_min"),
                    data: edge.node.tenantLocationWeatherForecastSet.edges.map(
                        forecast =>
                            parseFloat(
                                this.formatTemperature(
                                    forecast.node?.minTemperature
                                ) ?? null
                            ).toFixed(2)
                    )
                },
                {
                    name: this.$t("weather.limit_max"),
                    data: edge.node.tenantLocationWeatherForecastSet.edges.map(
                        forecast => {
                            const maxTemperature = edge.node?.maxTemperature;
                            const formattedMinTemperature =
                                maxTemperature !== null
                                    ? parseFloat(
                                          this.formatTemperature(maxTemperature)
                                      ).toFixed(2)
                                    : null;
                            return formattedMinTemperature;
                        }
                    )
                },
                {
                    name: this.$t("weather.limit_min"),
                    data: edge.node.tenantLocationWeatherForecastSet.edges.map(
                        forecast => {
                            const minTemperature = edge.node?.minTemperature;
                            const formattedMinTemperature =
                                minTemperature !== null
                                    ? parseFloat(
                                          this.formatTemperature(minTemperature)
                                      ).toFixed(2)
                                    : null;
                            return formattedMinTemperature;
                        }
                    )
                }
            ];
            return chartSeries;
        },
        onCheckDetailsButtonClick(edge) {
            this.$emit("click:checkDetails", edge);
        },
        onRemoveLocationButtonClick(edge) {
            this.$emit("click:disabledAddLocationButton");

            let payload = {
                locationIds: edge.node.id
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation addNewWeatherLocation(
                            $input: UpdateTenantLocationWeatherForecastInput!
                        ) {
                            updateTenantLocationWeatherForecast(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .finally(() => {
                    this.refetchData(() => {
                        this.$emit("click:enableAddLocationButton");
                    });
                });
        },
        async refetchData(callback) {
            await this.$apollo.queries.locations.refetch();
            if (callback) {
                callback();
            }
        },
        orderLocationsToShow() {
            let length = this.locationsShowed.edges.length;
            if (length !== 0) {
                const edges = this.locationsShowed.edges;
                if (
                    this.displayedEdgesIds !== undefined &&
                    this.displayedEdgesIds.length > 0
                ) {
                    edges.sort((a, b) => {
                        const indexOfA = this.displayedEdgesIds.indexOf(
                            a.node.id
                        );
                        const indexOfB = this.displayedEdgesIds.indexOf(
                            b.node.id
                        );
                        return indexOfA - indexOfB;
                    });
                }
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: "weather-cards",
                disabled: this.isFilterEnabled,
                ghostClass: "ghost"
            };
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
