import gql from "graphql-tag";

// fragment
export const USER_FRAGMENT = gql`
    fragment user on UserNode {
        id
        email
        firstName
        lastName
        phoneNumber
        profilePhoto
        photoUrl
        emailNotification
        inAppNotification
        require2fa
        enabled2fa
        isStaff
        isSuperuser
        isTenantUser
        isTenantAdmin
        tenantUser {
            id
            isAdmin
        }
        allPermissions
        preferences
        tenant {
            id
            name
            organisationPermissions
            logoUrl
            logoText
            themeJson
            organisationPreferences
        }
        isPartnerUser
        partnerOrganisationPermissions
        tenantPartner {
            id
            logoText
            logoUrl
            logo
            themeJson
        }
        allTenantPartners {
            id
            name
        }
    }
`;

export const ME_QUERY = gql`
    query {
        me {
            ...user
        }
    }
    ${USER_FRAGMENT}
`;

export const LOGIN_MUTATION = gql`
    mutation TokenAuth($email: String!, $password: String!, $tfaCode: String) {
        tokenAuth(email: $email, password: $password, tfaCode: $tfaCode) {
            token
            refreshToken
            user {
                id
                email
                firstName
                lastName
                require2fa
                enabled2fa
                isStaff
                isSuperuser
                isTenantUser
                isTenantAdmin
                tenantUser {
                    id
                    isAdmin
                }
                allPermissions
                preferences
                tenant {
                    id
                    name
                    organisationPermissions
                    logoUrl
                    logoText
                    themeJson
                    organisationPreferences
                }
                isPartnerUser
                partnerOrganisationPermissions
                tenantPartner {
                    id
                    name
                    logoText
                    logoUrl
                    logo
                    themeJson
                }
                allTenantPartners {
                    id
                    name
                }
            }
        }
    }
`;

export const UPDATE_MY_PREFERENCES_MUTATION = gql`
    mutation UpdateMyPreferences($input: UpdateMyPreferencesInput!) {
        updateMyPreferences(input: $input) {
            user {
                ...user
            }
        }
    }
    ${USER_FRAGMENT}
`;

export const REFRESH_TOKEN_MUTATION = gql`
    mutation RefreshToken($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
            token
            refreshToken
        }
    }
`;

export const REVOKE_TOKEN_MUTATION = gql`
    mutation RevokeToken($refreshToken: String!) {
        revokeToken(refreshToken: $refreshToken) {
            revoked
        }
    }
`;

export const LOGOUT_MUTATION = gql`
    mutation Logout($input: LogoutInput!) {
        logout(input: $input) {
            revoked
        }
    }
`;

export const LOGIN_AS_OTHER_USER_MUTATION = gql`
    mutation LoginAsOtherUser($input: LoginAsOtherUserInput!) {
        loginAsOtherUser(input: $input) {
            token
            refreshToken
        }
    }
`;

export const LOGOUT_AS_OTHER_USER_MUTATION = gql`
    mutation LogoutAsOtherUser($input: LogoutAsOtherUserInput!) {
        logoutAsOtherUser(input: $input) {
            revoked
        }
    }
`;

export const TENANT_USERS_QUERY = gql`
    query tenantUsersQuery {
        tenantStaffs {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    ...user
                }
            }
        }
    }
    ${USER_FRAGMENT}
`;

// create
export const CREATE_USER_MUTATION = gql`
    mutation createUser($input: CreateUserInput!) {
        createUser(input: $input) {
            user {
                ...user
            }
        }
    }
    ${USER_FRAGMENT}
`;

// update
export const UPDATE_USER_MUTATION = gql`
    mutation updateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            user {
                ...user
            }
        }
    }
    ${USER_FRAGMENT}
`;

// change password
export const CHANGE_PASSWORD_MUTATION = gql`
    mutation changePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            user {
                ...user
            }
        }
    }
    ${USER_FRAGMENT}
`;

// change my password
export const CHANGE_MY_PASSWORD_MUTATION = gql`
    mutation changeMyPassword($input: ChangeMyPasswordInput!) {
        changeMyPassword(input: $input) {
            user {
                ...user
            }
        }
    }
    ${USER_FRAGMENT}
`;

// delete
export const DELETE_USER_MUTATION = gql`
    mutation deleteUser($input: DeleteUserInput!) {
        deleteUser(input: $input) {
            user {
                ...user
            }
        }
    }
    ${USER_FRAGMENT}
`;
