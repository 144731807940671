<template>
    <v-container fluid class="pa-0">
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>

        <!-- title and KPIs -->
        <div>
            <v-row class="ma-0 px-3 ">
                <v-col cols="auto">
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                            align-top
                        "
                    >
                        {{ title }}
                    </h2>
                    
                </v-col>
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    color="primary"
                    class="mr-1 mt-8"
                    @click="openAddPartnerDialog = true"
                >
                    {{ $t("general.add") }}
                    {{ titleChoices(title) }}
                </v-btn>

                
            </v-row>
        </div>

        <!-- FILTER -->
        <PartnerFilter
            v-on:filter-changed="onFilterChanged"
            v-on:partner-created="onPartnerCreated"
            :title="title"
            class="mx-3"
        ></PartnerFilter>
        <!-- LIST -->
        <PartnerTable
            ref="partnerTable"
            v-on:click:row="onPartnerSelected"
            :partnerFilter="partnerFilter"
            class="mx-3 mt-3 mb-3"
        ></PartnerTable>
        <!-- TUTORIAL -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>
        <!-- DETAIL DRAWER -->
        <v-navigation-drawer
            v-model="partnerDetailDrawer"
            :width="partnerDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <PartnerDetail
                    ref="partnerDetails"
                    v-if="selectedPartner"
                    :key="selectedPartner.id"
                    :partner="selectedPartner"
                    :isFullScreen="isPartnerDetailDrawerFullScreen"
                    v-on:changeFullScreen="
                        onPartnerDetailDrawerFullScreenChange
                    "
                    v-on:refreshDataTable="refetchTableData"
                    v-on:close="partnerDetailDrawer = false"
                ></PartnerDetail>
            </div>
        </v-navigation-drawer>
        <AddPartnerDialog
            :openAddPartnerDialog="openAddPartnerDialog"
            :partnerByType.sync="partnerType"
            v-on:refreshPartnersTable="$emit('refreshDataTable')"
            v-on:closeAddPartnerDialog="
                openAddPartnerDialog = false
            "
            v-on:item-created="onPartnerCreated"
        ></AddPartnerDialog>
    </v-container>
</template>

<script>
//import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";
import PartnerFilter from "@/components/partners/PartnerFilter.vue";
import PartnerTable from "@/components/partners/PartnerTable.vue";
import PartnerDetail from "@/components/partners/PartnerDetail.vue";
import AddPartnerDialog from "@/components/partners/create/AddPartnerDialog.vue";
import { eventBus } from "@/main.js";

export default {
    title: "Partners",
    components: {
        TutorialDialog,
        PartnerFilter,
        PartnerTable,
        PartnerDetail, 
        AddPartnerDialog
    },

    apollo: {},
    data() {
        return {
            // --------- PARTNERS DATA --------- \\
            partners: [],
            selectedPartner: null,
            partnerFilter: {
                first: 20
            },
            // --------- DIALOGS/LOADINGS/MENUS --------- \\
            openAddPartnerDialog: false,
            tutorialDialog: false,
            partnerDetailDrawer: false,
            isLoading: false,
            menu: false,
            trackerDetailDrawer: false,
            goTopShow: false,
            partnerDetailFullScreen: false,
            partnerType: null,

           
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        partnerDetailDrawerWidth() {
            if (this.partnerDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isPartnerDetailDrawerFullScreen() {
            return this.partnerDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        routeName() {
            return this.$route.name;
        },
        title() {
            if (this.routeName === "Consignors") {
                return this.$t("home.consignors")
            } else if (this.routeName === "Consignees") {
                return this.$t("home.consignees")
            } else if (this.routeName === "Forwarders") {
                return this.$t("home.forwarders")
            } else if (this.routeName === "Others") {
                return this.$t("home.others")
            } else {
                return this.$t("home.partners")
            }
        }
    },

    watch: {
        routeName: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (val === "Consignors") {
                        this.partnerFilter.partnerType = "consignor";
                        this.partnerType = "consignor";
                    } else if (val === "Consignees") {
                        this.partnerFilter.partnerType = "consignee";
                        this.partnerType = "consignee";
                    } else if (val === "Forwarders") {
                        this.partnerFilter.partnerType = "forwarder";
                        this.partnerType = "forwarder";
                    } else if (val === "Others") {
                        this.partnerFilter.partnerType = "other";
                        this.partnerType = "other";
                    } else {
                        this.partnerFilter.partnerType = null;
                    }
                }
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {},
    methods: {
        onLoading(val) {
            this.isLoading = val;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        },
        // -----> DATA RELOAD OR RESET <----- \\
        refetchTableData() {
            this.$refs.partnerTable.$apollo.queries.tenantPartners.refetch();
        },
        onFilterChanged(filter) {
            this.partnerFilter = filter;
        },
        // -----> EVENT HANDLERS (clicks, onchange, scroll, etc) <------ \\
        onPartnerSelected(partner) {
            this.selectedPartner = partner.node;
            this.partnerDetailDrawer = true;
        },
        onScroll(e) {
            // scroll to top of the page
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        onPartnerDetailDrawerFullScreenChange(val) {
            this.partnerDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onPartnerCreated(val) {
            this.selectedPartner = val.tenantPartner;
            this.partnerDetailDrawer = true;
            this.refetchTableData();
        },
        titleChoices(title) {
            let choices = [
                { text: this.$t("headers.consignor"), value: "consignors" },
                { text: this.$t("headers.consignee"), value: "consignees" },
                { text: this.$t("headers.forwarder"), value: "forwarders" },
                { text: this.$t("headers.carrier"), value: "carriers" },
                { text: this.$t("home.partner"), value: "others" },
                { text: this.$t("home.partner"), value: null }
            ];

            return choices.find(item => {
                return item.value === title.toLowerCase();
            })?.text;
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
</style>
