<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="12" class="d-flex justify-end">
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display')"
                    :choices="filterChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                    class="mr-n2"
                ></FilterMenu>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col
                v-for="kpi in dynamicChoices"
                :key="kpi.name"
                cols="12"
                lg="3"
                md="6"
                sm="12"
            >
                <v-card :loading="loading">
                    <v-card-title class="pa-3">
                        <v-row no-gutters>
                            <v-col cols="6">
                                <div>
                                    <h6
                                        class="subtitle-2 grey--text text-left text-no-wrap"
                                    >
                                        {{ $t(kpi.tag) }}
                                    </h6>
                                </div>
                                <div
                                    class="text-left grey--text text--darken-3 mt-3"
                                >
                                    <h1 class="font-weight-regular text-no-wrap">
                                        {{ kpi.value }}
                                    </h1>
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <div>
                                    <v-btn
                                        :class="kpi.color"
                                        elevation="0"
                                        fab
                                        small
                                    >
                                        <v-icon
                                            class="d-flex align-center justify-center"
                                            color="white"
                                        >
                                            {{ kpi.icon }}
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import FilterMenu from "@/components/base/FilterMenu.vue";

export default {
    props: {
        tenantId: {
            type: String,
            default: null
        },
        choices: {
            type: Array,
            default: () => []
        }
    },
    components: {
        FilterMenu
    },
    apollo: {
        totalTrips: {
            query: gql`
                query totalTrips($first: Int, $after: String, $tenant: ID) {
                    trips(first: $first, after: $after, tenant: $tenant) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    tenant: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.trips.totalCount;
            },
            skip() {
                return false;
            }
        },
        activeTrips: {
            query: gql`
                query activeTrips(
                    $first: Int
                    $after: String
                    $status_In: [String]
                    $tenant: ID
                ) {
                    trips(
                        first: $first
                        after: $after
                        status_In: $status_In
                        tenant: $tenant
                    ) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    status_In: ["ACTIVE"],
                    tenant: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.trips.totalCount;
            },
            skip() {
                return false;
            }
        },
        seaTrips: {
            query: gql`
                query seaTrips(
                    $first: Int
                    $after: String
                    $transportMode_In: [String]
                    $tenant: ID
                ) {
                    trips(
                        first: $first
                        after: $after
                        transportMode_In: $transportMode_In
                        tenant: $tenant
                    ) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    transportMode_In: ["SEA"],
                    tenant: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.trips.totalCount;
            },
            skip() {
                return false;
            }
        },
        airTrips: {
            query: gql`
                query airTrips(
                    $first: Int
                    $after: String
                    $transportMode_In: [String]
                    $tenant: ID
                ) {
                    trips(
                        first: $first
                        after: $after
                        transportMode_In: $transportMode_In
                        tenant: $tenant
                    ) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    transportMode_In: ["AIR"],
                    tenant: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.trips.totalCount;
            },
            skip() {
                return false;
            }
        },
        roadTrips: {
            query: gql`
                query roadTrips(
                    $first: Int
                    $after: String
                    $transportMode_In: [String]
                    $tenant: ID
                ) {
                    trips(
                        first: $first
                        after: $after
                        transportMode_In: $transportMode_In
                        tenant: $tenant
                    ) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    transportMode_In: ["ROAD"],
                    tenant: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.trips.totalCount;
            },
            skip() {
                return false;
            }
        },
        railTrips: {
            query: gql`
                query railTrips(
                    $first: Int
                    $after: String
                    $transportMode_In: [String]
                    $tenant: ID
                ) {
                    trips(
                        first: $first
                        after: $after
                        transportMode_In: $transportMode_In
                        tenant: $tenant
                    ) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    transportMode_In: ["RAIL"],
                    tenant: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.trips.totalCount;
            },
            skip() {
                return false;
            }
        },
        otherTrips: {
            query: gql`
                query otherTrips(
                    $first: Int
                    $after: String
                    $transportMode_In: [String]
                    $tenant: ID
                ) {
                    trips(
                        first: $first
                        after: $after
                        transportMode_In: $transportMode_In
                        tenant: $tenant
                    ) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    transportMode_In: ["OTHER"],
                    tenant: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.trips.totalCount;
            },
            skip() {
                return false;
            }
        },
        tenants: {
            query: gql`
                query tenants($first: Int, $after: String) {
                    tenants(first: $first, after: $after) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.tenants.totalCount;
            },
            skip() {
                return !this.tenantId === null;
            }
        },
        tenantUsers: {
            query: gql`
                query tenantsUsers($first: Int, $after: String, $tenantId: ID) {
                    tenantUsers(
                        first: $first
                        after: $after
                        tenantId: $tenantId
                    ) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    tenantId: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.tenantUsers.totalCount;
            },
            skip() {
                return false;
            }
        },
        tenantPartner: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $after: String
                    $tenantId: ID
                ) {
                    tenantPartners(
                        first: $first
                        after: $after
                        tenantId: $tenantId
                    ) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    tenantId: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.tenantPartners.totalCount;
            },
            skip() {
                return false;
            }
        },
        trackers: {
            query: gql`
                query trackers($first: Int, $after: String, $tenant: ID) {
                    trackers(first: $first, after: $after, tenant: $tenant) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    after: null,
                    tenant: this.tenantId
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data.trackers.totalCount;
            },
            skip() {
                return false;
            }
        }
    },
    computed: {
        loading() {
            return (
                this.$apollo.queries.totalTrips.loading ||
                this.$apollo.queries.activeTrips.loading ||
                this.$apollo.queries.roadTrips.loading ||
                this.$apollo.queries.railTrips.loading ||
                this.$apollo.queries.airTrips.loading ||
                this.$apollo.queries.otherTrips.loading ||
                this.$apollo.queries.tenants.loading ||
                this.$apollo.queries.tenantUsers.loading ||
                this.$apollo.queries.tenantPartner.loading ||
                this.$apollo.queries.trackers.loading
            );
        },
        me() {
            return this.$store.state.user.me || {};
        },
        dashboardKPIs() {
            let _kpi = [];

            _kpi.push({
                name: "No. Trips",
                tag: "dashboard_kpi.no_trips",
                value: this.totalTrips || 0,
                icon: "near_me",
                color: "secondary",
                code: "totalTripskpi",
                enabled: true
            });

            _kpi.push({
                name: "No. Active",
                tag: "dashboard_kpi.no_active",
                value: this.activeTrips || 0,
                icon: "multiple_stop",
                color: "secondary",
                code: "activeTripskpi",
                enabled: true
            });

            _kpi.push({
                name: "No. trackers",
                tag: "dashboard_kpi.no_trackers",
                value: this.trackers || 0,
                icon: "gps_fixed",
                color: "secondary",
                code: "trackerskpi",
                enabled: true
            });

            if (this.tenantId === null) {
                _kpi.push({
                    name: "No. Tenants",
                    tag: "dashboard_kpi.no_tenants",
                    value: this.tenants || 0,
                    icon: "manage_accounts",
                    color: "secondary",
                    code: "tenantskpi",
                    enabled: true
                });
            }

            _kpi.push({
                name: "No. Partners",
                tag: "dashboard_kpi.no_partners",
                value: this.tenantPartner || 0,
                icon: "handshake",
                color: "secondary",
                code: "tenantPartnerkpi",
                enabled: true
            });

            _kpi.push({
                name: "No. Users",
                tag: "dashboard_kpi.no_users",
                value: this.tenantUsers || 0,
                icon: "group",
                color: "secondary",
                code: "tenantUserskpi",
                enabled: true
            });

            _kpi.push({
                name: "No. Of Sea Trips",
                tag: "dashboard_kpi.no_sea",
                value: this.seaTrips || 0,
                icon: "directions_boat",
                color: "secondary",
                code: "seaTripskpi",
                enabled: true
            });

            _kpi.push({
                name: "No. Of Air Trips",
                tag: "dashboard_kpi.no_air",
                value: this.airTrips || 0,
                icon: "flight",
                color: "secondary",
                code: "airTripskpi",
                enabled: true
            });

            _kpi.push({
                name: "No. Of Road Trips",
                tag: "dashboard_kpi.no_road",
                value: this.roadTrips || 0,
                icon: "local_shipping",
                color: "secondary",
                code: "roadTripskpi",
                enabled: true
            });

            _kpi.push({
                name: "No. Of Rail Trips",
                tag: "dashboard_kpi.no_rail",
                value: this.railTrips || 0,
                icon: "directions_railway",
                color: "secondary",
                code: "railTripskpi",
                enabled: true
            });

            _kpi.push({
                name: "No. Of Other Trips",
                tag: "dashboard_kpi.no_other",
                value: this.otherTrips || 0,
                icon: "remove",
                color: "secondary",
                code: "otherTripskpi",
                enabled: true
            });

            return _kpi;
        },
        filterChoices: {
            get() {
                let _filterChoices = helper.getTableHeaders(
                    "staffDashboardKpIs",
                    this.dashboardKPIs
                );
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    staffDashboardKpIs: value
                });
            }
        },
        dynamicChoices() {
            let _choices = [];
            this.filterChoices.forEach(choice => {
                this.dashboardKPIs.find(kpi => {
                    if (kpi.code === choice.code && choice.enabled) {
                        _choices = _choices.map(choice => {
                            return {
                                ...choice,
                                name: this.$t(choice.tag)
                            };
                        });
                        _choices.push(kpi);
                    }
                });
            });

            return _choices;
        }
    },
    watch: {},
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.hasPermission = helper.hasPermission;
        this.getTemperatureUnit = helper.getTemperatureSymbol;
        this.celsiusToFahrenheit = helper.celsiusToFahrenheit;
        this.months = helper.months;
    },
    mounted() {},
    methods: {
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices.map(choice => choice);
        }
    }
};
</script>
