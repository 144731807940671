<template>
    <div> 
        <v-row class="background">
            <v-text-field
                v-model="search"
                outlined
                hide-details
                :label=" label"
                prepend-inner-icon="search"
                background-color="white"
            >
                <template v-slot:append-outer>
                    <slot name="append-outer"></slot>    
                </template>
            </v-text-field>
        </v-row>
        <v-row class="background">
            <slot name="content-below"></slot>
        </v-row>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        value: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },       
    },
    data() {
        return {
            fab: false,
            isLoading: false,
        };
    },
    computed: {   
        search: {
            get() {
                let _search = this.value || null;
                return _search;
            },
            set(val) {
                this.$emit("input", val);
            }
        }, 
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {}
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
.classContent {
    max-height: 400px;
    max-width: 1095px;

    overflow: auto;
}
</style>