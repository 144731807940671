<template>
    <v-card flat class="px-0">
        <!-- -->
        <v-card-text class="px-0" v-if="item">
            <v-row dense>
                <v-col>
                    <v-row dense>
                        <v-col cols="12">
                            <div>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{
                                                item.portTransportType === "POL" || item.portTransportType === "POD"
                                                    ? `${item.portTransportType}` + " " + $t("trips.port_code2")
                                                    : `${item.portTransportType}` + " " + $t("trips.port_code")
                                            }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <!-- Change the filter for by city and save only the code -->
                                <v-autocomplete
                                    class="inputContent mb-n2"
                                    v-model="item.portCode"
                                    :items="seaPorts.edges"
                                    :no-data-text="$t('loading.no_available')"
                                    :item-value="item => item.node.code"
                                    :filter="customFilter"
                                    :item-text="item => item.node.code"
                                    :loading="$apollo.queries.seaPorts.loading"
                                    :search-input="item.portCode"
                                    :return-object="false"
                                    clearable
                                    outlined
                                    dense
                                    :error-messages="item.portCode === null ? 'Port Code is missing' : []"
                                >
                                    <template
                                        v-slot:item="{
                                            item
                                        }"
                                    >
                                        <v-row class="ma-0 body-2">
                                            <v-col cols="4" class="text-truncate pl-0 ml-0">
                                                {{ item.node.code }}
                                            </v-col>
                                            <v-col cols="8" class="d-flex justify-end">
                                                <span class="grey--text body-2 mr-1"
                                                    ><i>{{ item.node.name }}</i></span
                                                >
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-autocomplete>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ $t("trips.vessel_input") }}
                                        </span>
                                    </v-col>
                                </v-row>

                                <v-select
                                    class="inputContent mb-n2"
                                    dense
                                    v-model="item.vesselInputType"
                                    hide-details
                                    :items="type"
                                    outlined
                                    :disabled="item.portTransportType === 'POD'"
                                    :filled="item.portTransportType === 'POD'"
                                    :error-messages="
                                        item.vesselInputType === null ? $t('rules.vessel_input_missing') : []
                                    "
                                ></v-select>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{
                                                item.vesselInputType
                                                    ? `${getInputTypeText(item.vesselInputType)}` + " "
                                                    : $t("trips.vessel_no")
                                            }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <VesselAutoComplete
                                    v-model="item.vesselInputValue"
                                    :vesselType="item.vesselInputType"
                                    :filled="item.portTransportType === 'POD'"
                                    class="inputContent mb-n2 elevation-0"
                                ></VesselAutoComplete>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ $t("trips.voyage") }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    dense
                                    v-model="item.voyage"
                                    class="inputContent"
                                    outlined
                                    :disabled="item.portTransportType === 'POD'"
                                    :filled="item.portTransportType === 'POD'"
                                    :error-messages="item.voyage === null ? 'Voyage is missing' : []"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" v-if="item.portTransportType !== 'POD'">
                            <div>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ $t("headers.vgm_cut_off_date") }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <DateTimeComponent
                                    v-model="item.vgmCutOffDate"
                                    :icon="'calendar_month'"
                                    :rules="[]"
                                    :outlined="true"
                                    :dense="true"
                                    :defaultTime="'00:00'"
                                    class="inputContent"
                                >
                                </DateTimeComponent>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row dense>
                        <v-col cols="12">
                            <div v-if="item.portTransportType === 'POD'">
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ "ETA" }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <DateTimeComponent
                                    v-model="item.estimatedArrivalDate"
                                    :icon="'calendar_month'"
                                    :rules="[]"
                                    :outlined="true"
                                    :dense="true"
                                    :defaultTime="'00:00'"
                                    class="inputContent mb-n2"
                                >
                                </DateTimeComponent>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ "ATA" }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <DateTimeComponent
                                    v-model="item.actualArrivalDate"
                                    :icon="'calendar_month'"
                                    :rules="[]"
                                    :outlined="true"
                                    :dense="true"
                                    :defaultTime="'00:00'"
                                    class="inputContent mb-n2"
                                >
                                </DateTimeComponent>
                            </div>
                            <div v-else>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ "ETD" }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <DateTimeComponent
                                    v-model="item.estimatedDepartureDate"
                                    :icon="'calendar_month'"
                                    :rules="[]"
                                    :outlined="true"
                                    :dense="true"
                                    :defaultTime="'00:00'"
                                    class="inputContent mb-n2"
                                >
                                </DateTimeComponent>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ "ATD" }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <DateTimeComponent
                                    v-model="item.actualDepartureDate"
                                    :icon="'calendar_month'"
                                    :rules="[]"
                                    :outlined="true"
                                    :dense="true"
                                    :defaultTime="'00:00'"
                                    class="inputContent mb-n2"
                                >
                                </DateTimeComponent>
                            </div>
                        </v-col>
                        <v-col v-if="item.portTransportType === 'T/S'" cols="12">
                            <div>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ "ATA" }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <DateTimeComponent
                                    v-model="item.actualArrivalDate"
                                    :icon="'calendar_month'"
                                    :rules="[]"
                                    :outlined="true"
                                    :dense="true"
                                    :defaultTime="'00:00'"
                                    class="inputContent mb-n2"
                                >
                                </DateTimeComponent>
                            </div>
                        </v-col>
                        <v-col cols="12" v-if="item.portTransportType !== 'POD'">
                            <div>
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ $t("headers.booking_confirmation_number") }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    dense
                                    v-model="item.bookingConfirmationNumber"
                                    class="inputContent"
                                    outlined
                                />
                            </div>
                            <div class="mt-2">
                                <v-row dense>
                                    <v-col cols="auto" class="textBodyContent labelContent">
                                        <span>
                                            {{ $t("headers.cut_off_date_time") }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <DateTimeComponent
                                    v-model="item.cutOffDateTime"
                                    :icon="'calendar_month'"
                                    :rules="[]"
                                    :outlined="true"
                                    :dense="true"
                                    :defaultTime="'00:00'"
                                    class="inputContent"
                                >
                                </DateTimeComponent>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import VesselAutoComplete from "@/components/trips/other/VesselAutoComplete.vue";
import gql from "graphql-tag";

export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    components: {
        DateTimeComponent,
        VesselAutoComplete
    },
    apollo: {
        seaPorts: {
            query() {
                return gql`
                    query SeaPorts(
                        $orderBy: String
                        $first: Int
                        $after: String
                        $last: Int
                        $before: String
                        $search: String
                    ) {
                        seaPorts(
                            orderBy: $orderBy
                            first: $first
                            after: $after
                            last: $last
                            before: $before
                            search: $search
                        ) {
                            edges {
                                node {
                                    id
                                    code
                                    name
                                    city
                                    country
                                }
                            }
                        }
                    }
                `;
            },
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update(data) {
                return data.seaPorts;
            }
        }
    },
    data() {
        return {
            vesselInputValueChoices: [],
            seaPorts: {
                edges: []
            },
            filter: {
                shipId_Icontains: null,
                imo_Icontains: null,
                callsign_Icontains: null,
                name_Icontains: null
            },
            item: null,
            search: "",
            vesselValues: {
                shipname: [],
                callsign: [],
                imo: [],
                mmsi: []
            },
            shouldLoadMore: false,
            vessels: { edges: [] },
            type: [
                { text: this.$t("trips.vessel_name"), value: "shipname" },
                { text: this.$t("trips.call_sign"), value: "callsign" },
                { text: "IMO", value: "imo" },
                { text: "MMSI", value: "mmsi" }
            ]
        };
    },
    computed: {
        hasMoreData() {
            return this.vessels?.pageInfo?.hasNextPage;
        }
    },
    watch: {
        value: {
            handler(val) {
                this.item = val;
            },
            deep: true,
            immediate: true
        },
        item: {
            handler(val) {
                this.$emit("input", val);
            },
            deep: true
        }
    },
    methods: {
        customFilter(item, queryText, itemText) {
            const searchTerm = queryText.toLowerCase();
            const code = item.node?.code?.toLowerCase() || "";
            const country = item.node?.country?.toLowerCase() || "";
            const city = item.node?.city?.toLowerCase() || "";

            return code?.includes(searchTerm) || city?.includes(searchTerm) || country?.includes(searchTerm);
        },
        getInputTypeText(value) {
            let result = null;
            if (value === null || value === undefined || value.length === 0) {
                result = "";
            } else {
                let type = this.type.find(type => type.value === value);
                result = type?.text;
            }

            return result;
        },
        getItems(array, value) {
            if (value === null || value === undefined || value.length === "") {
                return [];
            } else {
                return array;
            }
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.vessels?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.vessels?.pageInfo?.hasNextPage) {
                this.$apollo.queries.vessels.fetchMore({
                    variables: {
                        after: this.vessels.pageInfo.endCursor
                    }
                });
            }
        },
        updateVesselInputValueChoices(item) {
            let choices = [];

            return choices;
        }
    }
};
</script>
<style scoped>
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}

.textBodyContent {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}

/* .inputContent {
        height: 52px;
    } */
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 0px;
    padding-right: 0px;
}
.contentDisplay2 {
    padding-left: 0px;
    padding-right: 0px;
}
.contentDisplay3 {
    padding-left: 0px;
    padding-right: 0px;
}
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
</style>
