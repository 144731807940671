<template>
    <v-select
        v-model="sortBy"
        :items="sortByChoices"
        :label="$t('general.sort_by')"
        :item-text="item => item.name"
        :item-value="item => item.code"
        clearable
        hide-details
        outlined
        dense
        color="primary"
        
    >
        <template v-slot:selection="{ item }">
            <div class="classText">
                {{ item.name }}
            </div>
        </template>
        <template v-slot:append-outer>
            <v-btn class="mt-n1" small icon :disabled="!sortBy" @click="sortAsc = !sortAsc">
                <v-icon color="grey darken-2">{{ sortAsc ? "north" : "south" }}</v-icon>
            </v-btn>
        </template>
    </v-select>
</template>

<script>
export default {
    name: "SortBy",
    props: {
        sortByChoices: {
            type: Array,
            required: false,
            default: () => []
        },
        value: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            // Your data properties here
            orderBy: null,
            sortBy: null,
            sortAsc: true
        };
    },
    watch: {
        value: {
            handler(val) {
                if (val?.includes("-")) {
                    this.sortBy = val.replace("-", "");
                    this.sortAsc = false;
                } else {
                    this.sortBy = val;
                    this.sortAsc = true;
                }
            },
            immediate: true,
            deep: true
        },
        sortBy(val) {
            if (val) {
                this.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.orderBy = null;
            }
        },
        orderBy(val) {
            this.$emit("input", val);
            this.$emit("updateFilter");
        }
    },
    methods: {
        // Your component methods here
        updateOrderBy(val) {
            this.$emit("input", val);
        }
    }
};
</script>

<style scoped>
/* Your component styles here */
.classText {
    color: #0e0d35 !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    
}
</style>
