import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"5","outlined":""}},[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c('div',{staticClass:"d-flex justify-end px-5"},[_c('FilterMenu',{staticClass:"mr-n4",attrs:{"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display'),"choices":_vm.headerChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1),_c(VDataTable,{staticClass:"row-pointer",attrs:{"headers":_vm.dynamicHeaders,"items":_vm.alertRules.edges,"item-key":"node.id","loading-text":_vm.$t('loading.loading1'),"loading":_vm.$apollo.queries.alertRules.loading,"hide-default-footer":"","disable-pagination":"","loader-height":"2","mobile-breakpoint":_vm.screenSize === 'xs' || _vm.screenSize === 'sm' ? 800 : 0,"no-data-text":_vm.$t('loading.no_data')},on:{"click:row":function (item) { return this$1.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.node.isEnabled",fn:function(ref){
var item = ref.item;
return [(item.node.isEnabled)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])]}},{key:"item.node.shouldNotifyPartner",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getPartnersToBeNotified(item)),function(partner){return _c(VChip,{key:partner,staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(partner)+" ")])})}},{key:"item.node.shouldNotifyForEveryRecord",fn:function(ref){
var item = ref.item;
return [(item.node.shouldNotifyForEveryRecord)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])]}},{key:"item.node.triggers",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.node.tenantAlertRuleTriggerSet.totalCount))])]}},{key:"item.node.actionGroups",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.node.tenantAlertRuleActionGroupSet.totalCount))])]}},{key:"item.node.alertSeverity",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.alertChoicesTitle(item.node.alertSeverity)))])]}},{key:"item.node.tag",fn:function(ref){
var item = ref.item;
return [_c(VRow,{staticClass:"d-flex justify-center align-center",attrs:{"no-gutters":""}},_vm._l((_vm.alertTags(item.node.tag)),function(tag){return _c(VCol,{key:tag.icon,attrs:{"cols":"2"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":""}},'v-icon',attrs,false),on),[_vm._v(_vm._s(tag.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(tag.text))])])],1)}),1)]}}])})],1),_c(VCardActions,{staticClass:"justify-center"},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.alertRules.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }