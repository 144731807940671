<template>
    <v-container fluid class="px-0">
        <v-form v-model="dialogForm" ref="freightsForm">
            <v-row align="stretch" dense>
                <v-col align-self="stretch">
                    <v-card flat class="rounded-lg contentDisplay">
                        <v-card-title class="px-0 textTitle">
                            {{ $t("general.freight_management") }}
                        </v-card-title>
                        <v-card-text class="px-0">
                            <v-row dense>
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("forms.freight_ref") + " *" }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-model="freight.referenceNumber"
                                outlined
                                dense
                                :rules="[rules.required]"
                                class="inputContent "
                            >
                            </v-text-field>
                            <v-row dense v-if="trip.transportMode === 'sea'">
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("headers.container_number") }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-model="freight.containerNumber"
                                outlined
                                v-if="trip.transportMode === 'sea'"
                                dense
                                class="inputContent"
                            >
                            </v-text-field>
                            <v-row dense v-if="trip.transportMode === 'sea'">
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("forms.bill_lading") }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-if="trip.transportMode === 'sea'"
                                v-model="freight.billOfLading"
                                outlined
                                dense
                                class="inputContent"
                            ></v-text-field>
                            <v-row dense v-if="trip.transportMode === 'air'">
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("forms.awb") }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-if="trip.transportMode === 'air'"
                                v-model="freight.awb"
                                outlined
                                dense
                                class="inputContent"
                            >
                            </v-text-field>
                            <v-row dense>
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("forms.goods") }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-text-field v-model="freight.goods" outlined dense class="inputContent"></v-text-field>
                            <v-row dense>
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("forms.order_no") }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-model="freight.orderNumber"
                                outlined
                                dense
                                class="inputContent"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col align-self="stretch">
                    <v-card flat class="rounded-lg contentDisplay">
                        <v-card-title class="textTitle">
                            {{ $t("general.tracker_alerts_menagement") }}
                        </v-card-title>
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("forms.select_alert_rule") }}
                                    </span>
                                </v-col>
                            </v-row>
                            <AlertRuleAutoComplete
                                v-model="freight.alerts"
                                :dense="true"
                                :hideDetails="false"
                                :refresh.sync="refresh"
                                class="inputContent"
                                :activeSlot="true"
                            >
                                <template #custom-action>
                                    <v-btn color="primary" @click="addAlertRuleMethod()">
                                        {{ $t("general.create_alert") }}
                                    </v-btn>
                                </template>
                            </AlertRuleAutoComplete>
                            <v-row dense>
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("forms.select_tracker") }}
                                    </span>
                                </v-col>
                            </v-row>
                            <TrackerSelected
                                v-model="freight.trackers"
                                class="inputContent"
                                :dense="true"
                                :hideDetails="false"
                                :currentTrackers="currentTrackers"
                            >
                            </TrackerSelected>
                            <v-row dense>
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("trackers.tracker_start_date") }}
                                    </span>
                                </v-col>
                            </v-row>
                            <DateTimeComponent
                                v-model="freight.startDate"
                                :icon="'calendar_month'"
                                :outlined="true"
                                :dense="true"
                                :defaultTime="'00:00'"
                                :rules="[trackerDateChange(freight.startDate, freight.endDate)]"
                                class="mb-n2 inputContent"
                            >
                            </DateTimeComponent>
                            <v-row dense>
                                <v-col cols="auto" class="textBody labelContent">
                                    <span>
                                        {{ $t("trackers.tracker_end_date") }}
                                    </span>
                                </v-col>
                            </v-row>
                            <DateTimeComponent
                                v-model="freight.endDate"
                                class="inputContent"
                                :icon="'calendar_month'"
                                :outlined="true"
                                :dense="true"
                                :defaultTime="'00:00'"
                                :rules="
                                    freight.endDate !== null && freight.endDate.length > 0
                                        ? [checkTrackerDate(freight.startDate, freight.endDate)]
                                        : []
                                "
                            >
                            </DateTimeComponent>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-expansion-panels flat class="rounded-lg px-0" v-model="panel">
                        <v-expansion-panel class="px-0 pt-0">
                            <v-expansion-panel-header class="pt-0">
                                <div class="pl-4">
                                    <div class="textTitle ">
                                        {{ $t("general.adtional_details") }}
                                    </div>
                                    <div class="px-0 textSubTitle1">
                                        {{ $t("general.optional_message_3") }}
                                        <span class="optimization">{{ $t("general.optional_message_4") }}</span
                                        >.
                                    </div>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="mb-4 px-3">
                                <v-row>
                                    <v-col class="contentDisplay2">
                                        <v-row dense>
                                            <v-col cols="auto" class="textBody labelContent">
                                                <span>
                                                    {{ $t("forms.load_point") }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-text-field
                                            v-model="freight.loadPoint"
                                            outlined
                                            dense
                                            class="inputContent"
                                        ></v-text-field>
                                        <v-row dense>
                                            <v-col cols="auto" class="textBody labelContent">
                                                <span>
                                                    {{ $t("forms.load_date") }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <DateTimeComponent
                                            v-model="freight.loadDate"
                                            class="inputContent mb-n2"
                                            :icon="'calendar_month'"
                                            :outlined="true"
                                            :dense="true"
                                            :defaultTime="'00:00'"
                                        >
                                        </DateTimeComponent>
                                    </v-col>
                                    <v-col class="contentDisplay2">
                                        <div class="px-3">
                                            <v-row dense>
                                                <v-col cols="auto" class="textBody labelContent">
                                                    <span>
                                                        {{ $t("forms.contact_person") }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-text-field
                                                v-model="freight.contact"
                                                outlined
                                                dense
                                                class="inputContent"
                                            ></v-text-field>
                                            <v-row dense>
                                                <v-col cols="auto" class="textBody labelContent">
                                                    <span>
                                                        {{ $t("forms.term") }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                            <v-select
                                                class="inputContent"
                                                v-model="freight.term"
                                                :items="termChoices"
                                                outlined
                                                dense
                                            ></v-select>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-form>
        <AddAlertRuleDialog
            :openAddAlertRuleDialog="openAddAlertRule"
            :createMode="true"
            v-on:refreshDataTable="refresh = true"
            v-on:close-dialog="openAddAlertRule = false"
            v-on:item-created="onItemCreated"
        ></AddAlertRuleDialog>
    </v-container>
</template>
<script>
//import DateComponent from "@/components/base/DateComponent.vue";
//import TimeComponent from "@/components/base/TimeComponent.vue";
import TrackerSelected from "@/components/trackers/TrackerSelected.vue";
import helper from "@/utils/helper.js";
import AlertRuleAutoComplete from "@/components/alertrules/AlertRuleAutoComplete.vue";
import AddAlertRuleDialog from "@/components/alertrules/create/AddAlertRuleDialog.vue";
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import moment from "moment-timezone";

export default {
    components: {
        //DateComponent,
        //TimeComponent,
        TrackerSelected,
        AlertRuleAutoComplete,
        AddAlertRuleDialog,
        DateTimeComponent
    },
    props: {
        transport: {
            type: String,
            default: "sea"
        },
        value: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean
        },
        trip: {
            type: Object
        },
        editObj: {
            type: Object
        },
        currentTrackers: {
            type: Array
        }
    },
    data() {
        return {
            dateRangeMenu: false,
            panel: [0],
            freight: {
                containerNumber: "",
                awb: "",
                goods: "",
                term: "",
                orderNumber: "",
                referenceNumber: "",
                contact: "",
                loadPoint: "",
                loadDate: null,
                billOfLading: "",
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                trackers: []
            },
            defaultFreight: {
                containerNumber: "",
                awb: "",
                goods: "",
                term: "",
                orderNumber: "",
                referenceNumber: "",
                billOfLading: "",
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                trackers: []
            },
            refresh: false,
            openAddAlertRule: false,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long"),
                checkTrackerDate: () => {
                    //let _selectedDate = new Date(v)
                    if (this.freight.startDate === null || this.freight.startDate.length < 1) {
                        return true;
                    }
                    if (this.freight.endDate === null || this.freight.endDate.length < 1) {
                        return true;
                    }

                    let [dateA, timeA] = this.freight.startDate.split(" ");
                    let [dateB, timeB] = this.freight.endDate.split(" ");

                    if (this.isEmpty(dateA) || this.isEmpty(timeA)) {
                        return true;
                    }

                    let _newDateA = moment(dateA, helper.getDateFormat());
                    let _DateA = moment(_newDateA, ["DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY"]).toDate();
                    let _dateFormatA = moment(_DateA).format("YYYY-MM-DD");

                    //dateTime B
                    let _newDateB = moment(dateB, helper.getDateFormat());
                    let _DateB = moment(_newDateB, ["DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY"]).toDate();
                    let _dateFormatB = moment(_DateB).format("YYYY-MM-DD");

                    let dateTimeA = _dateFormatA + " " + timeA;
                    let dateTimeB = _dateFormatB + " " + timeB;

                    let validDate = moment(dateTimeA).isSameOrBefore(dateTimeB);

                    return validDate || this.$t("rules.end_date");
                }
            },
            termChoices: [
                {
                    value: "DAP",
                    text: `DAP (${this.$t("term.dap")})`
                },
                {
                    value: "DAT",
                    text: `DAT (${this.$t("term.dat")})`
                },
                {
                    value: "DDP",
                    text: `DDP (${this.$t("term.ddp")})`
                },
                {
                    value: "DPU",
                    text: `DPU (${this.$t("term.dpu")})`
                },
                {
                    value: "CFR",
                    text: `CFR (${this.$t("term.cfr")})`
                },
                {
                    value: "CIF",
                    text: `CIF (${this.$t("term.cif")})`
                },
                {
                    value: "CIP",
                    text: `CIP (${this.$t("term.cip")})`
                },
                {
                    value: "CPT",
                    text: `CPT (${this.$t("term.cpt")})`
                },
                {
                    value: "EXW",
                    text: `EXW (${this.$t("term.exw")})`
                },
                {
                    value: "FCA",
                    text: `FCA (${this.$t("term.fca")})`
                },
                {
                    value: "FAS",
                    text: `FAS (${this.$t("term.fas")})`
                },
                {
                    value: "FOB",
                    text: `FOB (${this.$t("term.fob")})`
                }
            ]
        };
    },
    computed: {
        dialogForm: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    mounted() {},
    watch: {
        editObj: {
            immediate: true,
            handler: function(newVal) {
                let checkObject = Object.keys(newVal).length > 0;
                if (checkObject) {
                    (this.freight = { ...newVal }), (this.defaultFreight = { ...newVal });
                }
            },
            deep: true
        },
        "freight.startDate": function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$refs.freightsForm.validate();
            }
        },
        "freight.endDate": function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$refs.freightsForm.validate();
            }
        }
    },
    methods: {
        addAlertRuleMethod() {
            this.openAddAlertRule = true;
        },
        resetForm() {
            /* this.$refs.form.reset(); */
            /* this.freight.estimatedArrivalDate = "";
            this.freight.estimatedDepartureDate = ""; */
            this.dateRangeMenu = false;
            this.freight = {
                containerNumber: null,
                awb: null,
                goods: null,
                term: null,
                orderNumber: null,
                referenceNumber: null,
                contact: "",
                loadPoint: "",
                loadDate: null,
                billOfLading: "",
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                trackers: []
            };
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        newFreight() {
            this.$emit("create-freight", this.freight);
        },
        editFreight() {
            this.$emit("update-freight", this.freight);
        },
        onItemCreated(item) {
            this.freight.alerts.push(item.id);
        },
        emitFocusTrackerSelected(val) {
            val ? this.$emit("focusSelected", "50rem") : this.$emit("focusSelected", "auto");
        },
        emitFocusAlertRulesSelected(val) {
            val ? this.$emit("focusSelected", "60rem") : this.$emit("focusSelected", "auto");
        },
        disablePastDates(val) {
            return val >= this.trip.plannedDepartureDate;
        },
        checkTrackerDate(startDate, endDate) {
            if (startDate === null || startDate.length < 1) {
                return true;
            }
            if (endDate === null || endDate.length < 1) {
                return true;
            }

            let [dateA, timeA] = startDate.split(" ");
            let [dateB, timeB] = endDate.split(" ");

            if (helper.isEmpty(dateA) || helper.isEmpty(timeA)) {
                return true;
            }

            let _newDateA = moment(dateA, helper.getDateFormat());
            let _DateA = moment(_newDateA, ["DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY"]).toDate();
            let _dateFormatA = moment(_DateA).format("YYYY-MM-DD");

            //dateTime B
            let _newDateB = moment(dateB, helper.getDateFormat());
            let _DateB = moment(_newDateB, ["DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY"]).toDate();
            let _dateFormatB = moment(_DateB).format("YYYY-MM-DD");

            let dateTimeA = _dateFormatA + " " + timeA;
            let dateTimeB = _dateFormatB + " " + timeB;

            let validDate = moment(dateTimeA).isSameOrBefore(dateTimeB);

            return validDate || this.$t("rules.end_date");
        },
        trackerDateChange(startDate, endDate) {
            if (startDate === null || startDate.length < 1) {
                return true;
            }

            if (endDate === null || endDate.length < 1) {
                return true;
            }

            this.checkTrackerDate(startDate, endDate);

            return true;
        },
        isEmpty(val) {
            if (
                val === null ||
                val === undefined ||
                val === "" ||
                val === "null" ||
                val === "undefined" ||
                val === "NaN" ||
                val === "Invalid date-undefined-undefined"
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
<style scoped>
.columns {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 4px;
}
.textSubTitle1 {
    /* font-family: Lato; */
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
    /* font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left; */
}
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: #0e1e46 !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: #0e1e46 !important;
}
.optimization {
    color: #377ef2;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: #868599 !important;
}
.inputContent {
    /* height: 52px; */
    margin-bottom: 1px;
}
.labelContent {
    height: 25px;
}
.contentDisplay {
    padding-left: 16px;
    padding-right: 16px;
}
.contentDisplay2 {
    padding-left: 16px;
    padding-right: 16px;
}
.contentDisplay3 {
    padding-left: 70px;
    padding-right: 70px;
}
.append {
    position: sticky;
    bottom: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
}
.append-divider {
    position: sticky;
}
</style>
