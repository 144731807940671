import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VAutocomplete,{ref:"autocomplete",attrs:{"loading":!_vm.disabled && _vm.trackers.length === 0,"items":_vm.trackers,"no-data-text":_vm.$t('loading.no_available'),"label":_vm.label,"placeholder":_vm.placeholder,"outlined":"","serch-input":_vm.selectedTrackers,"hide-selected":"","hide-details":_vm.hideDetails,"deletable-chips":"","small-chips":"","item-text":"serialNumber","item-value":"id","multiple":"","dense":_vm.dense,"disabled":_vm.disabled || _vm.trackers.length === 0,"filled":_vm.filled,"rules":_vm.rules,"menu-props":{
            maxHeight: '200px'
        }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(data.item.serialNumber)+" ")]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"4"}},[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t("general.reuse")))])])],1)]}},{key:"append-item",fn:function(){return [(_vm.activeSlot)?_c(VDivider,{staticClass:"mt-4 append-divider"}):_vm._e(),(_vm.activeSlot)?_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_vm._t("custom-action")],2)],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedTrackers),callback:function ($$v) {_vm.selectedTrackers=$$v},expression:"selectedTrackers"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }