<template>
    <v-dialog max-width="600" v-model="openAddActionGroupDialog" persistent>
        <v-card>
            <!--- ICON AND TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("alert_rules.add_action_group") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pa-4">
                <v-form ref="actionGroupForm">
                    <v-col cols="12" class="pa-0 ma-0 mt-6">
                        <v-autocomplete
                            dense
                            v-model="selectedGroups"
                            :items="tenantActionGroups.edges"
                            :no-data-text="$t('loading.no_available')"
                            :label="
                                $t('alert_rules.select_action_group') + ' *'
                            "
                            outlined
                            hide-details
                            deletable-chips
                            small-chips
                            item-text="node.name"
                            item-value="node.id"
                            multiple
                        >
                        </v-autocomplete>
                    </v-col>
                </v-form>
            </v-card-text>
            <!--- ACTION BUTTONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    :loading="isLoadingAddActionGroup"
                    @click="createTenantActionGroup"
                    color="primary"
                    rounded
                    outlined
                    dense
                    >{{ $t("general.add") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";

export default {
    name: "AddActionGroupDialog",
    props: ["openAddActionGroupDialog", "alertRule", "actionGroups"],
    apollo: {
        tenantActionGroups: {
            query: gql`
                query TenantActionGroups($tenantId: ID) {
                    tenantActionGroups(tenantId: $tenantId) {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.me?.tenant?.id
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantActionGroups;
            },
            skip: false
        }
    },
    data() {
        return {
            selectedGroups: [],
            tenantActionGroups: { edges: [] },

            isLoadingAddActionGroup: false,

            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v =>
                    /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {
        actionGroups(val) {
            if (val == null || val.length == 0) this.selectedGroups = [];
            else
                this.selectedGroups = this.actionGroups?.map(item => {
                    return item.node.id;
                });
        }
    },
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.$refs.actionGroupForm.resetValidation();
            this.$emit("closeAddActionGroupDialog");
            this.selectedGroups = this.actionGroups?.map(item => {
                return item.node.id;
            });
        },
        /* ---> TENANT ACTION GROUP MUTATION <--- */
        createTenantActionGroup() {
            // validate form input :
            if (!this.$refs.actionGroupForm.validate()) return;

            if (this.alertRule == null) {
                this.$emit("updateActionGroups", this.selectedGroups);
                this.closeAndResetInput();
            } else {
                // Initialize Loading :
                this.isLoadingAddActionGroup = true;
                // Initialize Mutation :
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation UpdateTenantAlertRuleActionGroups(
                                $input: UpdateTenantAlertRuleActionGroupsInput!
                            ) {
                                updateTenantAlertRuleActionGroups(
                                    input: $input
                                ) {
                                    tenantAlertRule {
                                        id
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                id: this.alertRule.id,
                                groups: this.selectedGroups
                            }
                        }
                    })
                    .then(() => {
                        this.$emit("refreshActionGroupsTable");
                        this.closeAndResetInput();
                    })
                    .catch(error => {
                        console.log(error);
                        this.errorMessage = true;
                    })
                    .finally(() => {
                        this.isLoadingAddActionGroup = false;
                    });
            }
        }
    }
};
</script>
