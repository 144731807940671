<template>
    <v-container fluid class="pa-0 ">
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col class="3">
                    <h2
                        class="
                        ml-3
                        display-3
                        font-weight-medium
                        d-flex
                        align-top
                       
                    "
                    >
                        {{ $t("home.profile") }}
                    </h2>
                </v-col>
            </v-row>
        </div>
        <!-- Overview  body -->
        <v-card flat color="grey lighten-4 pt-15" min-height="73vh" fill-width>
            <router-view @loading="onLoading"></router-view>
        </v-card>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";

export default {
    title: "Profile",
    components: {},

    apollo: {},

    data() {
        return {
            tab: 0,
            isLoading: false
        };
    },

    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {
        tab(val) {
            switch (val) {
                case 0:
                    this.pushTo("details");
                    break;
                default:
                    this.pushTo("details");
            }
        }
    },

    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },

    methods: {
        onLoading(val) {
            this.isLoading = val;
        }
    }
};
</script>
