<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card v-resize="onResize" class="py-5 transparent" outlined>
                <v-row dense align="start" class="d-flex align-center py-0">
                    <v-col class="py-0">
                        <SearchBar v-model="search" :label="$t('trips.searchFilter')" ref="searchBar">
                            <template v-slot:append-outer>
                                <v-menu
                                    v-if="!isSmallScreen"
                                    v-model="menu"
                                    :nudge-width="300"
                                    nudge-top="10"
                                    :nudge-left="210"
                                    offset-x
                                    offset-y
                                    bottom
                                    :close-on-click="true"
                                    :close-on-content-click="false"
                                    attach
                                    content-class="content"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            x-large
                                            @click="openFilterMenu"
                                            style="
                                                height: 53px;
                                                margin-top: -1em;
                                            "
                                        >
                                            <v-icon>
                                                filter_alt
                                            </v-icon>
                                            <v-spacer></v-spacer>
                                            {{ $t("trips.filter") }}
                                            <v-spacer></v-spacer>
                                        </v-btn>
                                    </template>
                                    <v-card class="px-5 py-5" height="100%">
                                        <v-card-text class="pa-0">
                                            <v-row align="start" class="px-5 align-center">
                                                <v-col
                                                    cols="12"
                                                    xs="12"
                                                    sm="6"
                                                    md="4"
                                                    lg="4"
                                                    v-for="(f, i) in dynamicFilters"
                                                    :key="i"
                                                >
                                                    <!-- Reference Number -->
                                                    <v-text-field
                                                        v-if="f === 'referenceNumber'"
                                                        v-model="menuObject['referenceNumber_Icontains']"
                                                        :label="getFilterChoiceNameByCode('referenceNumber')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>

                                                    <!--!Freights Reference Number -->
                                                    <v-text-field
                                                        v-if="f === 'freights'"
                                                        v-model="menuObject['tripFreightSet_ReferenceNumber_Icontains']"
                                                        :label="getFilterChoiceNameByCode('freights')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>
                                                    <!--!Trip Trackers -->
                                                    <v-text-field
                                                        v-if="f === 'tracker'"
                                                        v-model="menuObject['trackerNumber']"
                                                        :label="getFilterChoiceNameByCode('tracker')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    >
                                                        <template v-slot:append>
                                                            <v-tooltip bottom content-class="tooltipContent">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="primary">
                                                                        info
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{ $t("general.info_tracker_empty") }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>
                                                    <!--!Trip Container Number -->
                                                    <v-text-field
                                                        v-if="f === 'containerNumber'"
                                                        v-model="menuObject['containerNumber']"
                                                        :label="getFilterChoiceNameByCode('containerNumber')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    >
                                                        <template v-slot:append>
                                                            <v-tooltip bottom content-class="tooltipContent">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="primary">
                                                                        info
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{ $t("general.info_container_empty") }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>
                                                    <!-- !Trip Air Waybill Number -->
                                                    <v-text-field
                                                        v-if="f === 'airWaybillNumber'"
                                                        v-model="menuObject['awbNumber']"
                                                        :label="getFilterChoiceNameByCode('airWaybillNumber')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    >
                                                        <template v-slot:append>
                                                            <v-tooltip bottom content-class="tooltipContent">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="primary">
                                                                        info
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{ $t("general.info_awb_empty") }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>

                                                    <!-- !Trip Bill of lading  -->
                                                    <v-text-field
                                                        v-if="f === 'blNumber'"
                                                        v-model="menuObject['blNumber']"
                                                        :label="getFilterChoiceNameByCode('blNumber')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    >
                                                        <template v-slot:append>
                                                            <v-tooltip bottom content-class="tooltipContent">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="primary">
                                                                        info
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{ $t("general.info_bl_empty") }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>

                                                    <!-- Last Location-->
                                                    <v-text-field
                                                        v-if="f === 'trackerLastLocation'"
                                                        v-model="menuObject['trackerLastLocation_Icontains']"
                                                        :label="getFilterChoiceNameByCode('trackerLastLocation')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>

                                                    <!-- Order Number-->
                                                    <v-text-field
                                                        v-if="f === 'orderNumber'"
                                                        v-model="menuObject['orderNumber']"
                                                        :label="getFilterChoiceNameByCode('orderNumber')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    >
                                                        <template v-slot:append>
                                                            <v-tooltip bottom content-class="tooltipContent">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="primary">
                                                                        info
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{ $t("general.info_order_empty") }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>

                                                    <!-- Trade -->
                                                    <v-text-field
                                                        v-if="f === 'trade'"
                                                        v-model="menuObject['trade_Icontains']"
                                                        :label="getFilterChoiceNameByCode('trade')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>

                                                    <!-- Last Location-->
                                                    <v-text-field
                                                        v-if="f === 'contactPerson'"
                                                        v-model="menuObject['tripFreightSet_Contact_Icontains']"
                                                        :label="getFilterChoiceNameByCode('contactPerson')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>

                                                    <!-- ! POL Search -->
                                                    <PortFilter
                                                        v-if="f === 'polSearch'"
                                                        v-model="menuObject['pol']"
                                                        :label="getFilterChoiceNameByCode('polSearch')"
                                                        :refreshTable="refreshTablePorts"
                                                    >
                                                    </PortFilter>

                                                    <!-- ! Transsshipment Search -->
                                                    <PortFilter
                                                        v-if="f === 'transshipmentSearch'"
                                                        v-model="menuObject['transshipment']"
                                                        :label="getFilterChoiceNameByCode('transshipmentSearch')"
                                                        :refreshTable="refreshTablePorts"
                                                    >
                                                    </PortFilter>

                                                    <!-- ! pod search  -->
                                                    <PortFilter
                                                        v-if="f === 'podsearch'"
                                                        v-model="menuObject['pod']"
                                                        :label="getFilterChoiceNameByCode('podsearch')"
                                                        :refreshTable="refreshTablePorts"
                                                    >
                                                    </PortFilter>

                                                    <!-- Transport Mode -->
                                                    <v-autocomplete
                                                        v-if="f === 'transportMode'"
                                                        v-model="menuObject['transportMode_In']"
                                                        :items="transportModeChoices"
                                                        :label="getFilterChoiceNameByCode('transportMode')"
                                                        clearable
                                                        hide-details
                                                        item-text="text"
                                                        item-value="value"
                                                        dense
                                                        color="primary"
                                                    ></v-autocomplete>

                                                    <!-- !Relative Planned Departure Date -->
                                                    <v-select
                                                        v-if="f === 'relativePlannedDepartureDateRange'"
                                                        v-model="relativePlannedDepartureDateRange"
                                                        :items="relativePlannedDepartureDateRangeChoices"
                                                        :label="
                                                            getFilterChoiceNameByCode(
                                                                'relativePlannedDepartureDateRange'
                                                            )
                                                        "
                                                        clearable
                                                        hide-details
                                                        dense
                                                        color="primary"
                                                    ></v-select>

                                                    <!-- !Consignor -->
                                                    <PartnerTripsConsignor
                                                        v-if="f === 'consignor'"
                                                        ref="consignor"
                                                        :refreshTable="refreshTableConsignor"
                                                        v-model="menuObject['consignor']"
                                                        :label="getFilterChoiceNameByCode('consignor')"
                                                        :name.sync="consignorName"
                                                    ></PartnerTripsConsignor>

                                                    <!-- !Consignee -->
                                                    <PartnerTripsConsignee
                                                        v-if="f === 'consignee'"
                                                        ref="consignee"
                                                        :refreshTable="refreshTableConsignee"
                                                        v-model="menuObject['consignee']"
                                                        :label="getFilterChoiceNameByCode('consignee')"
                                                        :name.sync="consigneeName"
                                                    ></PartnerTripsConsignee>

                                                    <!-- !Forwarder -->
                                                    <PartnerTripsForwarder
                                                        v-if="f === 'forwarder'"
                                                        ref="forwarder"
                                                        :refreshTable="refreshTableForwarder"
                                                        v-model="menuObject['forwarder']"
                                                        :label="getFilterChoiceNameByCode('forwarder')"
                                                        :name.sync="forwarderName"
                                                    ></PartnerTripsForwarder>

                                                    <!-- !Carrier -->
                                                    <PartnerTripsCarrier
                                                        v-if="f === 'carrier'"
                                                        ref="carrier"
                                                        :refreshTable="refreshTableCarrier"
                                                        v-model="menuObject['tenantCarrier']"
                                                        :label="getFilterChoiceNameByCode('carrier')"
                                                        :name.sync="carrierName"
                                                    ></PartnerTripsCarrier>
                                                    <!-- !pol vessel -->
                                                    <VesselFilter
                                                        v-if="f === 'polvessel'"
                                                        v-model="menuObject['polvessel']"
                                                        :refreshTable="refreshTableVessel"
                                                        :label="getFilterChoiceNameByCode('polvessel')"
                                                        :name.sync="polvesselName"
                                                    >
                                                    </VesselFilter>
                                                    <!-- !t/s vessel -->
                                                    <VesselFilter
                                                        v-if="f === 'tsvessel'"
                                                        v-model="menuObject['tsvessel']"
                                                        :refreshTable="refreshTableVessel"
                                                        :label="getFilterChoiceNameByCode('tsvessel')"
                                                        :name.sync="tsvesselName"
                                                    >
                                                    </VesselFilter>
                                                    <!-- Transport Mode -->

                                                    <!-- !Alert Code -->
                                                    <v-select
                                                        v-if="f === 'alertCode'"
                                                        v-model="menuObject['alertCode']"
                                                        :items="alertsType"
                                                        :label="getFilterChoiceNameByCode('alertCode')"
                                                        item-text="name"
                                                        item-value="value"
                                                        hide-details
                                                        dense
                                                        clearable
                                                        color="primary"
                                                    >
                                                    </v-select>

                                                    <v-autocomplete
                                                        v-if="f === 'alertTag'"
                                                        v-model="menuObject['alertTag']"
                                                        :items="alertTags"
                                                        :label="getFilterChoiceNameByCode('alertTag')"
                                                        item-text="text"
                                                        item-value="value"
                                                        hide-details
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>

                                                    <!-- !Tenant -->
                                                    <v-text-field
                                                        v-if="f === 'sharedBy' && isSharedTrip"
                                                        v-model="menuObject['tenant']"
                                                        :label="getFilterChoiceNameByCode('sharedBy')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>

                                                    <!--!Trip Goods -->
                                                    <v-text-field
                                                        v-if="f === 'goods'"
                                                        v-model="menuObject['goods']"
                                                        :label="getFilterChoiceNameByCode('goods')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>

                                                    <!-- !Planned Departure Date  -->
                                                    <v-menu
                                                        v-if="f === 'plannedDepartureDate'"
                                                        ref="plannedDepartureDateMenu"
                                                        v-model="plannedDepartureDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="savedPlannedDepartureDateRange"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="auto"
                                                        min-width="auto"
                                                        :disabled="!!relativePlannedDepartureDateRange"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-text-field
                                                                :value="plannedDepartureDateRangeText"
                                                                :label="
                                                                    getFilterChoiceNameByCode('plannedDepartureDate')
                                                                "
                                                                readonly
                                                                clearable
                                                                hide-details
                                                                dense
                                                                @click:clear="onClearPlannedDepartureDates()"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :disabled="!!relativePlannedDepartureDateRange"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="plannedDepartureDateRange"
                                                            :first-day-of-week="1"
                                                            range
                                                            no-title
                                                            scrollable
                                                            color="primary"
                                                        >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                rounded
                                                                color="primary"
                                                                @click="cancelPlannedDepartureDatePicker"
                                                            >
                                                                {{ $t("general.cancel") }}
                                                            </v-btn>
                                                            <v-btn
                                                                outlined
                                                                rounded
                                                                color="primary"
                                                                :disabled="plannedDepartureDateRange.length < 1"
                                                                @click="
                                                                    $refs.plannedDepartureDateMenu[0].save(
                                                                        plannedDepartureDateRange.sort()
                                                                    )
                                                                "
                                                            >
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>

                                                    <!-- !Planned Arrival Date  -->
                                                    <v-menu
                                                        v-if="f === 'plannedArrivalDate'"
                                                        ref="plannedArrivalDateMenu"
                                                        v-model="plannedArrivalDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="savedPlannedArrivalDateRange"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="auto"
                                                        min-width="auto"
                                                        :disabled="!!relativePlannedArrivalDateRange"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-text-field
                                                                :value="plannedArrivalDateRangeText"
                                                                :label="getFilterChoiceNameByCode('plannedArrivalDate')"
                                                                readonly
                                                                clearable
                                                                hide-details
                                                                dense
                                                                @click:clear="onClearPlannedArrivalDates()"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :disabled="!!relativePlannedArrivalDateRange"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="plannedArrivalDateRange"
                                                            :first-day-of-week="1"
                                                            range
                                                            no-title
                                                            scrollable
                                                            color="primary"
                                                        >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                rounded
                                                                color="primary"
                                                                @click="cancelPlannedArrivalDatePicker"
                                                            >
                                                                {{ $t("general.cancel") }}
                                                            </v-btn>
                                                            <v-btn
                                                                outlined
                                                                rounded
                                                                color="primary"
                                                                :disabled="plannedArrivalDateRange.length < 1"
                                                                @click="
                                                                    $refs.plannedArrivalDateMenu[0].save(
                                                                        plannedArrivalDateRange.sort()
                                                                    )
                                                                "
                                                            >
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>

                                                    <!-- booking -->
                                                    <v-text-field
                                                        v-if="f === 'booking'"
                                                        v-model="
                                                            menuObject[
                                                                'tripSeaTransportSetBookingConfirmationNumberIexact'
                                                            ]
                                                        "
                                                        :label="getFilterChoiceNameByCode('booking')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>
                                                    <!-- vgm -->
                                                    <v-menu
                                                        v-if="f === 'vgm'"
                                                        ref="vgmMenu"
                                                        v-model="vgmMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="savedVgmCutOffDateRange"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="auto"
                                                        min-width="auto"
                                                        :disabled="!!relativeVgmCutOffDateRange"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-text-field
                                                                :value="vgmCutOffDateRangeText"
                                                                :label="getFilterChoiceNameByCode('vgm')"
                                                                readonly
                                                                clearable
                                                                hide-details
                                                                dense
                                                                @click:clear="onClearVgmCutOffDate()"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :disabled="!!relativeVgmCutOffDateRange"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="vgmCutOffDateRange"
                                                            :first-day-of-week="1"
                                                            range
                                                            no-title
                                                            scrollable
                                                            color="primary"
                                                        >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                rounded
                                                                color="primary"
                                                                @click="cancelVgmCutOffDatePicker"
                                                            >
                                                                {{ $t("general.cancel") }}
                                                            </v-btn>
                                                            <v-btn
                                                                outlined
                                                                rounded
                                                                color="primary"
                                                                :disabled="vgmCutOffDateRange.length < 1"
                                                                @click="
                                                                    $refs.vgmMenu[0].save(vgmCutOffDateRange.sort())
                                                                "
                                                            >
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>

                                                    <!-- cut off -->
                                                    <v-menu
                                                        v-if="f === 'cutOff'"
                                                        ref="cutOffMenu"
                                                        v-model="cutOffMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="savedCutOffDateRange"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="auto"
                                                        min-width="auto"
                                                        :disabled="!!relativeCutOffDateTimeRange"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-text-field
                                                                :value="cutOffDateRangeText"
                                                                :label="getFilterChoiceNameByCode('cutOff')"
                                                                readonly
                                                                clearable
                                                                hide-details
                                                                dense
                                                                @click:clear="onClearCutOffDate()"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :disabled="!!relativeCutOffDateTimeRange"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="cutOffDateRange"
                                                            :first-day-of-week="1"
                                                            range
                                                            no-title
                                                            scrollable
                                                            color="primary"
                                                        >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                rounded
                                                                color="primary"
                                                                @click="cancelCutOffDatePicker"
                                                            >
                                                                {{ $t("general.cancel") }}
                                                            </v-btn>
                                                            <v-btn
                                                                outlined
                                                                rounded
                                                                color="primary"
                                                                :disabled="cutOffDateRange.length < 1"
                                                                @click="
                                                                    $refs.cutOffMenu[0].save(cutOffDateRange.sort())
                                                                "
                                                            >
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>

                                                <v-col cols="3">
                                                    <div class="d-flex flex-row align-center">
                                                        <v-checkbox v-model="unresolvedAlerts" hide-details dense>
                                                        </v-checkbox>
                                                        <div class="mt-2 classText">{{ $t("general.alerts") }}</div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="cancelFilter">
                                                Cancel
                                            </v-btn>
                                            <v-btn color="primary" @click="searchFields(menuObject)">
                                                Search
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </template>
                            <template v-slot:content-below>
                                <v-row dense class="mt-1">
                                    <v-col cols="auto" v-for="i in filterValuesObj" :key="i.id">
                                        <v-chip
                                            label
                                            close
                                            small
                                            class="elevation-0 text-capitalize"
                                            @click:close="deleteFilterValue(i.id)"
                                            v-if="
                                                i.id != 'plannedDepartureDate_Gte' &&
                                                    i.id != 'plannedDepartureDate_Lte' &&
                                                    i.id != 'plannedArrivalDate_Gte' &&
                                                    i.id != 'plannedArrivalDate_Lte' &&
                                                    i.id != 'tripSeaTransportSetCutOffDateTimeGte' &&
                                                    i.id != 'tripSeaTransportSetCutOffDateTimeLte' &&
                                                    i.id != 'tripSeaTransportSetVgmCutOffDateGte' &&
                                                    i.id != 'tripSeaTransportSetVgmCutOffDateLte'
                                            "
                                        >
                                            <span v-if="i.id == ''"></span>
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                        <v-chip
                                            label
                                            close
                                            small
                                            class="elevation-0 text-capitalize"
                                            @click:close="deleteFilterValue(i.id)"
                                            v-else
                                        >
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </template>
                        </SearchBar>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import PartnerTripsConsignor from "@/components/partners/PartnerTripsConsignor.vue";
import PartnerTripsConsignee from "@/components/partners/PartnerTripsConsignee.vue";
import PartnerTripsForwarder from "@/components/partners/PartnerTripsForwarder.vue";
import PartnerTripsCarrier from "@/components/partners/PartnerTripsCarrier.vue";
import VesselFilter from "@/components/trips/other/VesselFilter.vue";
import PortFilter from "@/components/trips/other/PortFilter.vue";
import SearchBar from "@/components/base/SearchBar.vue";
import gql from "graphql-tag";

export default {
    components: {
        PartnerTripsConsignor,
        PartnerTripsConsignee,
        PartnerTripsForwarder,
        PartnerTripsCarrier,
        VesselFilter,
        PortFilter,
        SearchBar
    },
    props: {
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        filterCode: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            fab: false,
            isLoading: false,
            plannedDepartureDateMenu: false,
            plannedArrivalDateMenu: false,
            plannedDepartureDateRange: [],
            plannedArrivalDateRange: [],
            vgmCutOffDateRange: [],
            cutOffDateRange: [],
            savedPlannedDepartureDateRange: [],
            savedPlannedArrivalDateRange: [],
            savedVgmCutOffDateRange: [],
            savedCutOffDateRange: [],
            userFavoriteTrip: null,
            sortAsc: true,
            shouldStickyFilter: false,
            menu: false,
            menuObject: {},
            vgmMenu: false,
            cutOffMenu: false,
            filter: {
                first: 15,
                status_In: null,
                orderBy: "-plannedDepartureDate",
                isFavorite: null,
                after: null,
                tenantPartnerId: null,
                transportMode_In: null,
                referenceNumber_Icontains: null,
                tripAlertSetIsResolved: null,
                trackerSerialNumber: null,
                tripFreightSet_ContainerNumber_Icontains: null,
                tripFreightSet_AirWaybillNumber_Icontains: null,
                tripFreightSet_ReferenceNumber_Icontains: null,
                tripFreightSet_OrderNumber_Icontains: null,
                tripFreightSet_Contact_Icontains: null,
                plannedDepartureDate_Gte: null,
                plannedDepartureDate_Lte: null,
                plannedArrivalDate_Gte: null,
                plannedArrivalDate_Lte: null,
                pol: null,
                transshipment: null,
                pod: null,
                alertCode: null,
                tenantCarrier: null,
                consignor: null,
                consignee: null,
                forwarder: null,
                polvessel: null,
                tsvessel: null,
                goods: null,
                tenant: null,
                trackerLastLocation_Icontains: null,
                search: null,
                alertTag: null,
                trade_Icontains: null,
                awbNumber: null,
                containerNumber: null,
                trackerNumber: null,
                blNumber: null,
                orderNumber: null,
                tripSeaTransportSetCutOffDateTimeLte: null,
                tripSeaTransportSetCutOffDateTimeGte: null,
                tripSeaTransportSetVgmCutOffDateGte: null,
                tripSeaTransportSetVgmCutOffDateLte: null,
                tripSeaTransportSetBookingConfirmationNumberIexact: null
            },
            refreshTableCarrier: false,
            refreshTableConsignor: false,
            refreshTableConsignee: false,
            refreshTableForwarder: false,
            refreshTableVessel: false,
            refreshTablePorts: false,
            filterChoices: null,
            widthMenu: 0,
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "referenceNumber",
                    name: "Reference Number",
                    tag: "headers.reference_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "freights",
                    name: "Freight Number",
                    tag: "headers.freight_number",
                    enabled: false
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "tracker",
                    name: "Tracker Number",
                    tag: "headers.tracker_number",
                    enabled: false
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "containerNumber",
                    name: "Container Number",
                    tag: "headers.container_number",
                    enabled: false
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "airWaybillNumber",
                    name: "AWB",
                    tag: "headers.awb_number",
                    enabled: false
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "orderNumber",
                    name: "Order Number",
                    tag: "headers.order_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "blNumber",
                    name: "BL Number",
                    tag: "headers.bill_of_lading",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "plannedDepartureDate",
                    name: "Planned Departure Date",
                    tag: "headers.planned_departure",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "plannedArrivalDate",
                    name: "Planned Arrival Date",
                    tag: "headers.planned_arrival",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "transportMode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "trackerLastLocation",
                    name: "Last Location",
                    tag: "headers.last_location",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    code: "polSearch",
                    name: "POL Code",
                    tag: "headers.pol_code",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "transshipmentSearch",
                    name: "T/S Code",
                    tag: "headers.ts_code",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "podsearch",
                    name: "POD Code",
                    tag: "headers.pod_code",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "alertCode",
                    name: "Alert Name",
                    tag: "headers.alert_name",
                    // class: "col-auto col-md-3 col-lg-2",
                    enabled: true
                },
                {
                    code: "alertTag",
                    name: "Alert Tag",
                    tag: "headers.alert_tag",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: false
                },
                {
                    // for filter menu
                    code: "sharedBy",
                    name: "Shared By",
                    tag: "headers.shared_by",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "consignor",
                    name: "Consignor",
                    tag: "headers.consignor",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "consignee",
                    name: "Consignee",
                    tag: "headers.consignee",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "forwarder",
                    name: "Forwarder",
                    tag: "headers.forwarder",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "polvessel",
                    name: "POL Vessel",
                    tag: "headers.pol_vessel",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "tsvessel",
                    name: "T/S Vessel",
                    tag: "headers.ts_vessel",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "goods",
                    name: "Goods",
                    tag: "headers.goods",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },

                {
                    // for filter menu
                    code: "contactPerson",
                    name: "Contact Person",
                    tag: "forms.contact_person",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "trade",
                    name: "Trade",
                    tag: "headers.trade",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "booking",
                    name: "booking",
                    tag: "headers.booking_confirmation_number",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "vgm",
                    name: "vmg",
                    tag: "headers.vgm_cut_off_date",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                },
                {
                    // for filter menu
                    code: "cutOff",
                    name: "Cut Off",
                    tag: "headers.cut_off_date_time",
                    enabled: true
                    // for filter layout
                    // class: "col-auto col-md-3 col-lg-2"
                }
            ],
            alertTags: [
                { value: "temperature", text: this.$t("general.temperature") },
                { value: "humidity", text: this.$t("general.humidity") },
                { value: "light", text: this.$t("general.light") },
                { value: "temperature_humidity", text: this.$t("general.temperature_humidity") },
                { value: "temperature_humidity_light", text: this.$t("general.temperature_humidity_light") },
                { value: "pressure", text: this.$t("general.pressure") }
            ],
            carrierName: null,
            consignorName: null,
            consigneeName: null,
            forwarderName: null,
            tsvesselName: null,
            polvesselName: null,
            relativePlannedDepartureDateRange: null,
            relativePlannedDepartureDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            relativePlannedArrivalDateRange: null,
            relativePlannedArrivalDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            transportModeChoices: [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ],
            relativeCutOffDateTimeRange: null,
            relativeCutOffDateTimeRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            relativeVgmCutOffDateRange: null,
            relativeVgmCutOffDateRangeChoices: [
                this.$t("date.last30"),
                this.$t("date.last90"),
                this.$t("date.last180"),
                this.$t("date.last360")
            ],
            filterValuesObj: [],
            // Initialize menuWidth with the same width as the text field
            menuWidth: null
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        screen() {
            return this.$vuetify.breakpoint.width;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 700;
        },
        plannedDepartureDateRangeText() {
            if (this.plannedDepartureDateRange.length > 1) {
                let date1 = moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedDepartureDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.plannedDepartureDateRange.length === 1) {
                return moment(this.plannedDepartureDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedDepartureDateRange;
            }
        },
        plannedArrivalDateRangeText() {
            if (this.plannedArrivalDateRange.length > 1) {
                let date1 = moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.plannedArrivalDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.plannedArrivalDateRange.length === 1) {
                return moment(this.plannedArrivalDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.plannedArrivalDateRange;
            }
        },
        vgmCutOffDateRangeText() {
            if (this.vgmCutOffDateRange.length > 1) {
                let date1 = moment(this.vgmCutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.vgmCutOffDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.vgmCutOffDateRange.length === 1) {
                return moment(this.vgmCutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.vgmCutOffDateRange;
            }
        },
        cutOffDateRangeText() {
            if (this.cutOffDateRange.length > 1) {
                let date1 = moment(this.cutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.cutOffDateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.cutOffDateRange.length === 1) {
                return moment(this.cutOffDateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.cutOffDateRange;
            }
        },
        _filterChoices() {
            return this.filterChoices;
        },
        dynamicFilters() {
            let _dynamicFilters = [];
            if (this.defaultFilterChoices) {
                this.defaultFilterChoices.forEach(f => {
                    _dynamicFilters.push(f.code);
                });
            }
            return _dynamicFilters;
        },
        tenantPartners() {
            return this.tripPartnerSet?.edges?.map(e => e.node) || [];
        },
        search: {
            get() {
                let _search = this.filter.search || "";
                return _search;
            },
            set(val) {
                let _filter = helper.getFilterValues("myTripTableFilters");
                if (_filter) {
                    this.filter = _filter;
                }
                this.filter.search = val;
                this.updateFilter("search", this.filter);
            }
        },
        unresolvedAlerts: {
            get() {
                let _unresolved = this.filter.tripAlertSetIsResolved;
                return _unresolved === false ? true : false;
            },
            set(val) {
                this.menuObject.tripAlertSetIsResolved = val;
            }
        },
        statusChoices() {
            let _statusChoices = [
                { text: this.$t("general.all_trips"), value: null },
                { text: this.$t("general.active"), value: "active" },
                { text: this.$t("general.completed"), value: "completed" }
            ];
            return _statusChoices;
        },
        alertsType: function() {
            let _alertsType = [
                {
                    name: this.$t("dashboard.etd_delay"),
                    value: "SEA_ETD_DELAY"
                },
                {
                    name: this.$t("dashboard.eta_delay"),
                    value: "SEA_ETA_DELAY"
                },
                {
                    name: this.$t("dashboard.ts_waiting"),
                    value: "SEA_TRANS_WAIT"
                },
                {
                    name: this.$t("dashboard.pod_waiting"),
                    value: "SEA_DEST_WAIT"
                },
                {
                    name: this.$t("dashboard.custom_alert"),
                    value: "CUSTOM_ALERT"
                }
            ];
            return _alertsType;
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "referenceNumber",
                    name: this.$t("headers.reference_number")
                },
                {
                    code: "status",
                    name: this.$t("headers.status")
                },
                {
                    code: "createdAt",
                    name: this.$t("headers.created_at")
                },
                {
                    code: "modifiedAt",
                    name: this.$t("headers.modified_at")
                },
                {
                    code: "plannedDepartureDate",
                    name: this.$t("headers.planned_departure")
                },
                {
                    code: "consignor",
                    name: this.$t("headers.consignor")
                },
                {
                    code: "consignee",
                    name: this.$t("headers.consignee")
                },
                {
                    code: "forwarder",
                    name: this.$t("headers.forwarder")
                },
                {
                    code: "tenant_carrier",
                    name: this.$t("headers.carrier")
                }
            ];
            return _sortByChoices;
        }
    },
    watch: {
        relativePlannedDepartureDateRange(val) {
            const _index = this.relativePlannedDepartureDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedDepartureDateRange = [_startDate, _endDate];
                    this.savedPlannedDepartureDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedDepartureDateRange = [];
                    this.savedPlannedDepartureDateRange = [];
            }
        },
        relativePlannedArrivalDateRange(val) {
            const _index = this.relativePlannedArrivalDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.plannedArrivalDateRange = [_startDate, _endDate];
                    this.savedPlannedArrivalDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.plannedArrivalDateRange = [];
                    this.savedPlannedArrivalDateRange = [];
            }
        },
        savedPlannedDepartureDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedDepartureDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedDepartureDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedDepartureDate_Gte"] = null;
                this.menuObject["plannedDepartureDate_Lte"] = null;
            }
        },
        savedPlannedArrivalDateRange(val) {
            if (val.length == 1) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["plannedArrivalDate_Gte"] = val[0] + "T00:00:00";
                this.menuObject["plannedArrivalDate_Lte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["plannedArrivalDate_Gte"] = null;
                this.menuObject["plannedArrivalDate_Lte"] = null;
            }
        },
        relativeCutOffDateTimeRange(val) {
            const _index = this.relativeCutOffDateTimeRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.cutOffDateRange = [_startDate, _endDate];
                    this.savedCutOffDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.cutOffDateRange = [];
                    this.savedCutOffDateRange = [];
            }
        },
        relativeVgmCutOffDateRange(val) {
            const _index = this.relativeVgmCutOffDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.vgmCutOffDateRange = [_startDate, _endDate];
                    this.savedVgmCutOffDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.vgmCutOffDateRange = [];
                    this.savedVgmCutOffDateRange = [];
            }
        },
        savedVgmCutOffDateRange(val) {
            if (val.length == 1) {
                this.menuObject["tripSeaTransportSetVgmCutOffDateGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetVgmCutOffDateLte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["tripSeaTransportSetVgmCutOffDateGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetVgmCutOffDateLte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["tripSeaTransportSetVgmCutOffDateGte"] = null;
                this.menuObject["tripSeaTransportSetVgmCutOffDateLte"] = null;
            }
        },
        savedCutOffDateRange(val) {
            if (val.length == 1) {
                this.menuObject["tripSeaTransportSetCutOffDateTimeGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetCutOffDateTimeLte"] = val[0] + "T23:59:59";
            } else if (val.length == 2) {
                this.menuObject["tripSeaTransportSetCutOffDateTimeGte"] = val[0] + "T00:00:00";
                this.menuObject["tripSeaTransportSetCutOffDateTimeLte"] = val[1] + "T23:59:59";
            } else {
                this.menuObject["tripSeaTransportSetCutOffDateTimeGte"] = null;
                this.menuObject["tripSeaTransportSetCutOffDateTimeLte"] = null;
            }
        },

        isSharedTrip: {
            handler(value) {
                if (value) {
                    this.defaultFilterChoices.push({
                        // for filter menu
                        code: "sharedBy",
                        name: "Shared By",
                        tag: "headers.shared_by",
                        enabled: false,
                        // for filter layout
                        class: "col-auto col-md-3 col-lg-2"
                    });
                } else {
                    this.defaultFilterChoices = this.defaultFilterChoices.filter(e => e.code !== "sharedBy");
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        //this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "myTripTableFilters");
    },
    async mounted() {
        this.onResize();
        // watch filterChoices
        this.$nextTick(async () => {
            this.populateFilter();
            // this.resizeMenu();
            //await this.filterValues(this.filter);
        });

        window.addEventListener("resize", this.updateMenuWidth);
    },
    beforeDestroy() {
        // Remove the event listener when the component is destroyed
        window.removeEventListener("resize", this.updateMenuWidth);
    },
    methods: {
        // Method to update the menu width
        removeFilterRoute() {
            this.$router.replace({ query: {} });
        },
        updateMenuWidth() {
            this.menuWidth = this.$refs.searchBar.$el.offsetWidth;
        },
        updateFilter(updateFrom, filter) {
            //trigger filter chips
            const triggerFilter = async val => {
                await this.filterValues(val);
            };

            //update query values
            this.$emit("filter-changed", JSON.parse(JSON.stringify(filter)));

            //update store
            if (updateFrom !== "populateFilter" || updateFrom !== "search") {
                helper.setFilterValues("myTripTableFilters", filter);
                triggerFilter(filter);
            }
        },
        populateFilter() {
            let _filter = helper.getFilterValues("myTripTableFilters") || {};

            //populate filter menu
            if (this.filterCode) {
                let code = this.filterCode;

                if (this.alertsType.find(e => e.value === code)) {
                    _filter.alertCode = code;
                } else if (code === "TOTAL_OPEN_TRIPS") {
                    _filter.status_In = "active";
                } else if (code === "UNRESOLVED_ALERTS") {
                    _filter.tripAlertSetIsResolved = false;
                    this.unresolvedAlerts = true;
                } else if (code === "UNRESOLVED_TAG_TEMPERATURE") {
                    _filter.alertTag = "temperature";
                } else if (code === "UNRESOLVED_TAG_HUMIDITY") {
                    _filter.alertTag = "humidity";
                } else if (code === "UNRESOLVED_TAG_LIGHT") {
                    _filter.alertTag = "light";
                } else if (code === "UNRESOLVED_TAG_SHOCK") {
                    _filter.alertTag = "shock";
                } else if (code === "UNRESOLVED_TAG_PRESSURE") {
                    _filter.alertTag = "pressure";
                } else if (code === "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY") {
                    _filter.alertTag = "temperature_humidity";
                } else if (code === "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY_LIGHT") {
                    _filter.alertTag = "temperature_humidity_light";
                }
                _filter.status_In = "active";
                //remove filter route
                this.removeFilterRoute();
            }

            if (Object.keys(_filter).length > 0) {
                Object.keys(_filter).forEach(key => {
                    if (key) {
                        this.filter[key] = _filter[key];
                        if (this.isSharedTrip) {
                            this.filter["sharedBy"] = _filter["sharedBy"] || null;
                        }
                    }
                });

                const {
                    plannedDepartureDate_Gte,
                    plannedDepartureDate_Lte,
                    plannedArrivalDate_Gte,
                    plannedArrivalDate_Lte,
                    pol,
                    transshipment,
                    pod,
                    tripAlertSetIsResolved,
                    search,
                    tenantCarrier,
                    consignor,
                    consignee,
                    forwarder,
                    polvessel,
                    tsvessel,
                    tripSeaTransportSetCutOffDateTimeLte,
                    tripSeaTransportSetCutOffDateTimeGte,
                    tripSeaTransportSetVgmCutOffDateGte,
                    tripSeaTransportSetVgmCutOffDateLte,
                    tripSeaTransportSetBookingConfirmationNumberIexact
                } = _filter;

                if (plannedDepartureDate_Gte) {
                    this.plannedDepartureDateRange = [
                        plannedDepartureDate_Gte.split("T")[0],
                        plannedDepartureDate_Lte.split("T")[0]
                    ];
                }

                if (plannedArrivalDate_Gte) {
                    this.plannedArrivalDateRange = [
                        plannedArrivalDate_Gte.split("T")[0],
                        plannedArrivalDate_Lte.split("T")[0]
                    ];
                }

                if (forwarder) {
                    this.refreshTableForwarder = true;
                }

                if (tenantCarrier) {
                    this.refreshTableCarrier = true;
                }

                if (consignee) {
                    this.refreshTableConsignee = true;
                }

                if (consignor) {
                    this.refreshTableConsignor = true;
                }

                if (polvessel || tsvessel) {
                    this.refreshTableVessel = true;
                }

                if (pol || pod || transshipment) {
                    this.refreshTablePorts = true;
                }

                if (tripSeaTransportSetCutOffDateTimeLte || tripSeaTransportSetCutOffDateTimeGte) {
                    //time range
                    this.relativeCutOffDateTimeRange = {
                        gte: tripSeaTransportSetCutOffDateTimeGte,
                        lte: tripSeaTransportSetCutOffDateTimeLte
                    };
                }

                if (tripSeaTransportSetVgmCutOffDateGte || tripSeaTransportSetVgmCutOffDateLte) {
                    //time range
                    this.relativeVgmCutOffDateRange = {
                        gte: tripSeaTransportSetVgmCutOffDateGte,
                        lte: tripSeaTransportSetVgmCutOffDateLte
                    };
                }

                if (tripSeaTransportSetBookingConfirmationNumberIexact) {
                    this.filter.tripSeaTransportSetBookingConfirmationNumberIexact = tripSeaTransportSetBookingConfirmationNumberIexact;
                }

                //populate filter menu
                Object.keys(_filter).forEach(key => {
                    if (key !== "status_In") {
                        if (key === "tripAlertSetIsResolved") {
                            this.menuObject[key] = _filter[key] === false ? true : false;
                            if (this.isSharedTrip) {
                                this.menuObject["sharedBy"] = _filter["sharedBy"] || null;
                            }
                        } else {
                            this.menuObject[key] = _filter[key];
                        }
                    }
                });
            }

            //populate and store
            if (this.filterCode) {
                this.updateFilter("updateFilter", this.filter);
            } else {
                this.updateFilter("populateFilter", this.filter);
            }
        },
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        getFilterChoiceClassByCode(code) {
            return this.defaultFilterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        getFilterChoiceNameByCode(code) {
            return this.$t(
                this.defaultFilterChoices.find(item => {
                    return item.code === code;
                })?.tag
            );
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelPlannedDepartureDatePicker() {
            this.plannedDepartureDateMenu = false;
            if (this.savedPlannedDepartureDateRange.length == 0) {
                this.plannedDepartureDateRange = [];
            }
        },
        cancelPlannedArrivalDatePicker() {
            this.plannedArrivalDateMenu = false;
            if (this.savedPlannedArrivalDateRange.length == 0) {
                this.plannedArrivalDateRange = [];
            }
        },
        cancelVgmCutOffDatePicker() {
            this.vgmCutOffDateMenu = false;
            if (this.savedVgmCutOffDateRange.length == 0) {
                this.vgmCutOffDateRange = [];
            }
        },
        cancelCutOffDatePicker() {
            this.cutOffDateMenu = false;
            if (this.savedCutOffDateRange.length == 0) {
                this.cutOffDateRange = [];
            }
        },
        onClearPlannedDepartureDates() {
            this.plannedDepartureDateRange = [];
            this.savedPlannedDepartureDateRange = [];
            this.relativePlannedDepartureDateRange = null;
        },
        onClearPlannedArrivalDates() {
            this.plannedArrivalDateRange = [];
            this.savedPlannedArrivalDateRange = [];
            this.relativePlannedArrivalDateRange = null;
        },
        onClearVgmCutOffDate() {
            this.vgmCutOffDateRange = [];
            this.savedVgmCutOffDateRange = [];
            this.relativeVgmCutOffDateRange = null;
        },
        onClearCutOffDate() {
            this.cutOffDateRange = [];
            this.savedCutOffDateRange = [];
            this.relativeCutOffDateTimeRange = null;
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        },
        onTripCreated(trip) {
            this.$emit("trip-created", trip);
        },
        async searchFields(menuItems) {
            let menuKeys = Object.keys(menuItems);
            let _filter = helper.getFilterValues("myTripTableFilters");

            if (_filter) {
                Object.keys(_filter).forEach(key => {
                    if (key) {
                        this.filter[key] = _filter[key];
                    }
                });
            }

            menuKeys.forEach(key => {
                if (menuItems[key] != null && menuItems[key] != "") {
                    if (key === "tripAlertSetIsResolved") {
                        this.filter[key] = menuItems[key] === true ? false : null;
                    } else {
                        this.filter[key] = menuItems[key];
                    }
                } else {
                    this.filter[key] = null;
                }
            });

            this.menu = false;

            //custom seach reset search
            if (this.filter.search) {
                this.filter.search = null;
            }

            //update filter
            this.updateFilter("customUpdate", this.filter);
        },
        cancelFilter() {
            //clear menuObject
            this.menuObject = {};
            this.menu = false;
        },
        async filterValues(object) {
            const filterKeysToIgnore = ["first", "after", "orderBy", "search", "status_In"];
            const keys = JSON.parse(JSON.stringify(Object.keys(object)));
            let values = [];

            let filteredKeys = keys.filter(key => {
                return object[key] != null && !filterKeysToIgnore.includes(key);
            });

            await Promise.all(
                filteredKeys.map(async key => {
                    if (!values.find(e => e.id === key)) {
                        if (this.replaceName(key))
                            values.push({
                                id: key,
                                name: this.replaceName(key),
                                value: await this.valuePopulate(object[key], key)
                            });
                    }
                })
            );

            //plannedDepartureDate_Gte' and 'plannedDepartureDate_Lte' combine them

            let plannedDepartureDate_Gte = values.find(e => e.id === "plannedDepartureDate_Gte");

            let plannedDepartureDate_Lte = values.find(e => e.id === "plannedDepartureDate_Lte");

            if (plannedDepartureDate_Gte && plannedDepartureDate_Lte) {
                if (plannedDepartureDate_Gte.value !== plannedDepartureDate_Lte.value)
                    plannedDepartureDate_Gte.value =
                        plannedDepartureDate_Gte.value + " ~ " + plannedDepartureDate_Lte.value;
                values = values.filter(e => e.id !== "plannedDepartureDate_Lte");
            }

            let plannedArrivalDate_Gte = values.find(e => e.id === "plannedArrivalDate_Gte");
            let plannedArrivalDate_Lte = values.find(e => e.id === "plannedArrivalDate_Lte");
            if (plannedArrivalDate_Gte && plannedArrivalDate_Lte) {
                if (plannedArrivalDate_Gte.value !== plannedArrivalDate_Lte.value)
                    plannedArrivalDate_Gte.value = plannedArrivalDate_Gte.value + " ~ " + plannedArrivalDate_Lte.value;

                values = values.filter(e => e.id !== "plannedArrivalDate_Lte");
            }

            let tripSeaTransportSetCutOffDateTimeGte = values.find(
                e => e.id === "tripSeaTransportSetCutOffDateTimeGte"
            );
            let tripSeaTransportSetCutOffDateTimeLte = values.find(
                e => e.id === "tripSeaTransportSetCutOffDateTimeLte"
            );
            if (tripSeaTransportSetCutOffDateTimeGte && tripSeaTransportSetCutOffDateTimeLte) {
                if (tripSeaTransportSetCutOffDateTimeGte.value !== tripSeaTransportSetCutOffDateTimeLte.value)
                    tripSeaTransportSetCutOffDateTimeGte.value =
                        tripSeaTransportSetCutOffDateTimeGte.value + " ~ " + tripSeaTransportSetCutOffDateTimeLte.value;

                values = values.filter(e => e.id !== "tripSeaTransportSetCutOffDateTimeLte");
            }

            let tripSeaTransportSetVgmCutOffDateGte = values.find(e => e.id === "tripSeaTransportSetVgmCutOffDateGte");
            let tripSeaTransportSetVgmCutOffDateLte = values.find(e => e.id === "tripSeaTransportSetVgmCutOffDateLte");
            if (tripSeaTransportSetVgmCutOffDateGte && tripSeaTransportSetVgmCutOffDateLte) {
                if (tripSeaTransportSetVgmCutOffDateGte.value !== tripSeaTransportSetVgmCutOffDateLte.value)
                    tripSeaTransportSetVgmCutOffDateGte.value =
                        tripSeaTransportSetVgmCutOffDateGte.value + " ~ " + tripSeaTransportSetVgmCutOffDateLte.value;

                values = values.filter(e => e.id !== "tripSeaTransportSetVgmCutOffDateLte");
            }

            this.filterValuesObj = [...values];
        },
        async deleteFilterValue(key) {
            if (key == "plannedDepartureDate_Gte" || key == "plannedDepartureDate_Lte") {
                this.menuObject = {};
                this.plannedDepartureDateRange = [];
                this.filter["plannedDepartureDate_Gte"] = null;
                this.filter["plannedDepartureDate_Lte"] = null;
            } else if (key == "plannedArrivalDate_Gte" || key == "plannedArrivalDate_Lte") {
                this.menuObject = {};
                this.plannedArrivalDateRange = [];
                this.filter["plannedArrivalDate_Gte"] = null;
                this.filter["plannedArrivalDate_Lte"] = null;
            } else if (key == "tripSeaTransportSetCutOffDateTimeGte" || key == "tripSeaTransportSetCutOffDateTimeLte") {
                this.menuObject = {};
                this.cutOffDateRange = [];
                this.filter["tripSeaTransportSetCutOffDateTimeGte"] = null;
                this.filter["tripSeaTransportSetCutOffDateTimeLte"] = null;
                this.relativeCutOffDateTimeRange = null;
            } else if (key == "tripSeaTransportSetVgmCutOffDateGte" || key == "tripSeaTransportSetVgmCutOffDateLte") {
                this.menuObject = {};
                this.vgmCutOffDateRange = [];
                this.filter["tripSeaTransportSetVgmCutOffDateGte"] = null;
                this.filter["tripSeaTransportSetVgmCutOffDateLte"] = null;
                this.relativeVgmCutOffDateRange = null;
            } else {
                this.menuObject[key] = null;
                this.filter[key] = null;
            }

            this.updateFilter("delete", this.filter);
        },
        replaceName(key) {
            let replace = "";
            switch (key) {
                case "tenantPartnerId":
                    replace = "Tenant";
                    break;
                case "referenceNumber_Icontains":
                    replace = "Trip reference no.";
                    break;
                case "trackerSerialNumber":
                    replace = "Tracker serial no.";
                    break;
                case "tripFreightSet_ContainerNumber_Icontains":
                    replace = "Container no.";
                    break;
                case "tripFreightSet_AirWaybillNumber_Icontains":
                    replace = "Airwaybill no.";
                    break;
                case "tripFreightSet_ReferenceNumber_Icontains":
                    replace = "Freight reference no.";
                    break;
                case "plannedDepartureDate_Gte":
                    replace = "Planned Departure Date";
                    break;
                case "plannedDepartureDate_Lte":
                    replace = "Planned Departure Date";
                    break;
                case "plannedArrivalDate_Gte":
                    replace = "Planned Arrival Date";
                    break;
                case "plannedArrivalDate_Lte":
                    replace = "Planned Arrival Date";
                    break;
                case "tripSeaTransportSetCutOffDateTimeLte":
                    replace = "Cut Off Date";
                    break;
                case "tripSeaTransportSetCutOffDateTimeGte":
                    replace = "Cut Off Date";
                    break;
                case "tripSeaTransportSetVgmCutOffDateGte":
                    replace = "VGM Cut Off Date";
                    break;
                case "tripSeaTransportSetVgmCutOffDateLte":
                    replace = "VGM Cut Off Date";
                    break;
                case "tripSeaTransportSetBookingConfirmationNumberIexact":
                    replace = "Booking Confirmation Number";
                    break;
                case "orderBy":
                    replace = "Sort By";
                    break;
                case "isFavorite":
                    replace = "Favorite";
                    break;
                case "pol":
                    replace = "POL";
                    break;
                case "transshipment":
                    replace = "T/S";
                    break;
                case "pod":
                    replace = "POD";
                    break;
                case "alertCode":
                    replace = "Alert Code";
                    break;
                case "tripAlertSetIsResolved":
                    replace = "Unresolved Alerts";
                    break;
                case "tenantCarrier":
                    replace = "Carrier";
                    break;
                case "consignor":
                    replace = "Consignor";
                    break;
                case "consignee":
                    replace = "Consignee";
                    break;
                case "forwarder":
                    replace = "Forwarder";
                    break;
                case "polvessel":
                    replace = "POL Vessel";
                    break;
                case "tsvessel":
                    replace = "T/S Vessel";
                    break;
                case "goods":
                    replace = "Goods";
                    break;
                case "tenant":
                    replace = "Shared By";
                    break;
                case "trackerLastLocation_Icontains":
                    replace = "Last Location";
                    break;
                case "search":
                    replace = "Search";
                    break;
                case "polSearch":
                    replace = "POL Code";
                    break;
                case "transshipmentSearch":
                    replace = "T/S Code";
                    break;
                case "podsearch":
                    replace = "POD Code";
                    break;
                case "transportMode_In":
                    replace = "Transport";
                    break;
                case "tripFreightSet_OrderNumber_Icontains":
                    replace = "Order Number";
                    break;
                case "tripFreightSet_Contact_Icontains":
                    replace = "Contact Person";
                    break;
                case "alertTag":
                    replace = "Alert Tag";
                    break;
                case "trade_Icontains":
                    replace = "Trade";
                    break;
                case "awbNumber":
                    replace = "AWB Number";
                    break;
                case "containerNumber":
                    replace = "Container Number";
                    break;
                case "trackerNumber":
                    replace = "Tracker Number";
                    break;
                case "blNumber":
                    replace = "BL Number";
                    break;
                case "orderNumber":
                    replace = "Order Number";
                    break;
            }

            return replace;
        },
        async getVesselName(vesselId) {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`
                        query Vessel($vesselId: ID!) {
                            vessel(id: $vesselId) {
                                id
                                name
                            }
                        }
                    `,
                    variables: {
                        vesselId: vesselId
                    }
                });

                return data.vessel.name;
            } catch (error) {
                console.error("Error fetching vessel name:", error);
                // Handle the error appropriately (e.g., returning a default value or rethrowing the error)
                throw error;
            }
        },
        async getPartnerName(partnerIds) {
            let _partner = [];
            try {
                const { data } = await this.$apollo.query({
                    query: gql`
                        query tenantPartners($orderBy: String, $globalIdIn: [ID]) {
                            tenantPartners(orderBy: $orderBy, globalIdIn: $globalIdIn) {
                                edges {
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    fetchPolicy: "no-cache",
                    variables: {
                        globalIdIn: partnerIds
                    }
                });

                //TenantPartner returns an array of partners
                if (data.tenantPartners.edges.length > 0) {
                    data.tenantPartners.edges.forEach(e => {
                        _partner.push(e.node.name);
                    });
                }

                return _partner.join(", ");
            } catch (error) {
                return null;
            }
        },
        async valuePopulate(item, key) {
            let _value = null;
            switch (key) {
                case "tenantPartnerId":
                    _value = item;
                    break;
                case "referenceNumber_Icontains":
                    _value = item;
                    break;
                case "trackerSerialNumber":
                    _value = item;
                    break;
                case "tripFreightSet_ContainerNumber_Icontains":
                    _value = item;
                    break;
                case "tripFreightSet_AirWaybillNumber_Icontains":
                    _value = item;
                    break;
                case "tripFreightSet_ReferenceNumber_Icontains":
                    _value = item;
                    break;
                case "plannedDepartureDate_Gte":
                    _value = item.split("T")[0];
                    break;
                case "plannedDepartureDate_Lte":
                    _value = item.split("T")[0];
                    break;
                case "plannedArrivalDate_Gte":
                    _value = item.split("T")[0];
                    break;
                case "plannedArrivalDate_Lte":
                    _value = item.split("T")[0];
                    break;
                case "tripSeaTransportSetCutOffDateTimeLte":
                    _value = item.split("T")[0];
                    break;
                case "tripSeaTransportSetCutOffDateTimeGte":
                    _value = item.split("T")[0];
                    break;
                case "tripSeaTransportSetVgmCutOffDateGte":
                    _value = item.split("T")[0];
                    break;
                case "tripSeaTransportSetVgmCutOffDateLte":
                    _value = item.split("T")[0];
                    break;
                case "tripSeaTransportSetBookingConfirmationNumberIexact":
                    _value = item;
                    break;
                case "orderBy":
                    _value = item;
                    break;
                case "isFavorite":
                    _value = item;
                    break;
                case "pol":
                    _value = item;
                    break;
                case "transshipment":
                    _value = item;
                    break;
                case "pod":
                    _value = item;
                    break;
                case "alertCode":
                    _value = this.alertsType.find(el => el.value === item).name;
                    break;
                case "tripAlertSetIsResolved":
                    _value = !item;
                    break;
                case "tenantCarrier":
                case "consignor":
                case "consignee":
                case "forwarder":
                    _value = await this.getPartnerName(item);
                    break;
                case "polvessel":
                case "tsvessel":
                    _value = await this.getVesselName(item);
                    break;
                case "goods":
                    _value = item;
                    break;
                case "tenant":
                    _value = item;
                    break;
                case "trackerLastLocation_Icontains":
                    _value = item;
                    break;
                case "search":
                    _value = item;
                    break;
                case "transportMode_In":
                    _value = item;
                    break;
                case "tripFreightSet_OrderNumber_Icontains":
                    _value = item;
                    break;
                case "tripFreightSet_Contact_Icontains":
                    _value = item;
                    break;
                case "alertTag":
                    _value = this.alertTags.find(el => el.value === item).text;
                    break;
                case "trade_Icontains":
                    _value = item;
                    break;
                case "awbNumber":
                    _value = item;
                    break;
                case "containerNumber":
                    _value = item;
                    break;
                case "trackerNumber":
                    _value = item;
                    break;
                case "blNumber":
                    _value = item;
                    break;
                case "orderNumber":
                    _value = item;
                    break;
            }
            return _value;
        },
        openFilterMenu() {
            // this.resizeMenu();
            //if menu is trigger width again
            this.menu = true;
        }
    }
};
</script>
<style scoped>
/* .v-menu__content {
    left: 210px !important;
    top: 60px !important;
}
 */
.content {
    left: 210px !important;
    top: 60px !important;
}
.checkBox {
    position: static !important;
}
.classText {
    color: #0e0d35 !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.tooltipContent {
    max-width: 200px;
}
</style>
