<template>
    <v-dialog v-model="generateReportDialog" max-width="700" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="pa-2 mx-1"
                label
                :rounded="rounded"
                :elevation="elevation"
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
                {{ $t("alert_rules.generate_report") }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="primary white--text">
                <v-img
                    :src="require('@/assets/file_save.png')"
                    width="20"
                    :style="
                        `
                        background-color:transparent;
                        filter: invert(100%);
                        `
                    "
                    max-width="30"
                    max-height="30"
                    class="mr-2"
                ></v-img>  
                {{ $t("alert_rules.generate_trip") }}
            </v-card-title>
            <v-card-text>
                <div class="mt-5 mx-2">
                    <div>
                        <span class="subtitle-1"
                            ><b>{{ $t("headers.report_type") }}</b></span
                        >
                        <v-row dense class="d-flex mt-3">
                            <v-col
                                v-for="reportType in fileFormatsAvailable"
                                :key="reportType.value"
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                            >
                                <v-card
                                    elevation="2"
                                    class="py-5 d-flex align-center justify-center"
                                    @click="changeReportType(reportType.value)"
                                    :color="
                                        fileFormat == reportType.value
                                            ? 'primary'
                                            : ''
                                    "
                                >
                                    <span
                                        :class="
                                            fileFormat == reportType.value
                                                ? 'white--text text-no-wrap pr-1'
                                                : 'black--text text-no-wrap pr-1'
                                        "
                                    >
                                        {{ reportType.text }}
                                    </span>
                                </v-card></v-col
                            >
                        </v-row>
                    </div>
                    <div class="mt-5">
                        <span class="subtitle-1"
                            ><b>{{ $t("headers.date_range") }} </b
                            ><span class="subtitle-2"
                                >({{ $t("general.required") }})</span
                            ></span
                        >
                        <v-row
                            ><v-col cols="12" sm="6" md="6">
                                <v-menu
                                    ref="dateRangeMenu"
                                    v-model="dateRangeMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="dateRangeText"
                                            :label="$t('headers.date_range')"
                                            readonly
                                            hide-details
                                            :loading="
                                                $apollo.queries
                                                    .trackerDeviceTimeRange
                                                    .loading
                                            "
                                            filled
                                            outline
                                            solo
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateRange"
                                        :first-day-of-week="1"
                                        range
                                        :allowed-dates="allowedDates"
                                        no-title
                                        scrollable
                                        color="primary"
                                    >
                                        <v-btn
                                            v-if="
                                                this.trip
                                                    .plannedDepartureDate &&
                                                    this.trip.plannedArrivalDate
                                            "
                                            rounded
                                            outlined
                                            small
                                            color="grey"
                                            @click="setTripDuration"
                                        >
                                            {{ $t("alert_rules.full_trip") }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            rounded
                                            small
                                            color="primary"
                                            @click="cancelDatePicker"
                                        >
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            outlined
                                            rounded
                                            small
                                            color="primary"
                                            :disabled="dateRange.length < 1"
                                            @click="saveDatePicker"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col></v-row
                        >
                    </div>
                    <div class="mt-5">
                        <span class="subtitle-1"
                            ><b>{{ $t("headers.tracker") }} </b
                            ><span class="subtitle-2"
                                >({{ $t("general.required") }})</span
                            ></span
                        >
                        <v-row
                            ><v-col cols="12" sm="5" md="5"
                                ><v-autocomplete
                                    v-model="selectedTracker"
                                    :items="trackers"
                                    :no-data-text="$t('loading.no_available')"
                                    item-text="serialNumber"
                                    item-value="id"
                                    :label="$t('headers.tracker')"
                                    dense
                                    outlined
                                    class="mt-3"
                                    clearable
                                    :rules="[rules.required]"
                                ></v-autocomplete></v-col
                        ></v-row>
                    </div>
                    <div>
                        <span class="subtitle-1"
                            ><b>{{ $t("headers.sensor_data") }} </b
                            ><span class="subtitle-2"
                                >({{ $t("general.required") }})</span
                            ></span
                        >
                        <br />
                        <span class="subtitle-2">{{
                            $t("general.select_options")
                        }}</span>
                        <v-row dense class="d-flex mt-3">
                            <v-col
                                v-for="sensorTypeChoice in sensorTypeChoices"
                                :key="sensorTypeChoice.value"
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                            >
                                <v-card
                                    elevation="2"
                                    class="py-5 d-flex align-center justify-center"
                                    @click="
                                        updateSensorData(sensorTypeChoice.value)
                                    "
                                    :color="
                                        sensorTypes.includes(
                                            sensorTypeChoice.value
                                        )
                                            ? 'primary'
                                            : ''
                                    "
                                >
                                    <span
                                        :class="
                                            sensorTypes.includes(
                                                sensorTypeChoice.value
                                            )
                                                ? 'white--text text-no-wrap pr-1'
                                                : 'black--text text-no-wrap pr-1'
                                        "
                                    >
                                        <v-icon
                                            :color="
                                                sensorTypes.includes(
                                                    sensorTypeChoice.value
                                                )
                                                    ? 'white'
                                                    : 'black'
                                            "
                                            class="pr-0"
                                            >{{ sensorTypeChoice.icon }}</v-icon
                                        >
                                        {{ sensorTypeChoice.text }}
                                    </span>
                                </v-card></v-col
                            >
                        </v-row>
                    </div>
                    <!-- <div class="mt-5">
                        <span class="subtitle-1"><b>Generate</b></span>
                        <v-switch
                            v-model="generateImmediately"
                            row
                            class="mt-3 mx-2"
                        ></v-switch>
                    </div> -->
                    <div class="mt-5">
                        <span class="subtitle-1"
                            ><b>{{ $t("general.send_users") }}</b></span
                        >
                        <div class="mt-3">
                            <!-- <span class="subtitle-2">Users</span> -->
                            <v-row v-if="!me.isPartnerUser && me.tenant"
                                ><v-col cols="10"
                                    ><ActionGroupAddUserForm
                                        v-model="users"
                                    ></ActionGroupAddUserForm></v-col
                            ></v-row>

                            <!-- <span class="subtitle-2">External Emails</span> -->
                            <v-row
                                ><v-col cols="10">
                                    <!-- Imported from Create Action Group -->
                                    <v-text-field
                                        v-model="email"
                                        :label="$t('headers.external_emails')"
                                        :rules="[
                                            email.length > 0
                                                ? rules.email
                                                : () => true
                                        ]"
                                        outlined
                                    ></v-text-field> </v-col
                            ></v-row>
                        </div>
                    </div>
                </div>
            </v-card-text>

            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn
                    text
                    @click="closeAndResetInput"
                    :disabled="isLoadingGenerateReport"
                    >{{ $t("general.close") }}</v-btn
                >
                <v-btn
                    color="primary"
                    :loading="isLoadingGenerateReport"
                    :disabled="!valid"
                    @click="submitGenerateReport"
                    :rounded="rounded"
                    :elevation="elevation"
                >
                    {{ $t("general.generate") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import ActionGroupAddUserForm from "@/components/actiongroups/forms/ActionGroupAddUserForm.vue";
import moment from "moment";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        rounded: {
            type: Boolean,
            default: true
        },
        elevation: {
            type: Number,
            default: 2
        }
    },
    components: {
        ActionGroupAddUserForm
    },
    data() {
        return {
            generateReportDialog: false,
            trackerDeviceTimeRange: [],
            isLoadingGenerateReport: false,
            selectedTracker: null,
            fileFormatsAvailable: [
                {
                    text: "PDF",
                    value: "pdf"
                },
                {
                    text: "CSV",
                    value: "csv"
                }
            ],
            fileFormat: "pdf",
            generateImmediately: true,
            email: "",
            users: [],
            sensorTypeChoices: [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    icon: "lightbulb"
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    icon: "water_drop"
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    icon: "bolt"
                },
                {
                    text: this.$t("general.pressure"),
                    value: "pressure",
                    icon: "tire_repair"
                }
                // { text: "CO2", value: "co2", icon: "air" }
            ],
            sensorTypes: [],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                email: v => /.+@.+\..+/.test(v) || this.$t("rules.email")
            },
            dateRange: [],
            dateRangeMenu: false,
            savedDateRange: []
        };
    },
    created() {
        if (this.trackers && this.trackers.length > 0) {
            // this.selectedTracker = this.trackers.find(tracker => {
            //     if(!tracker.disabled) {
            //         return tracker.id;
            //     }
            // }); 
            this.selectedTracker = this.trackers.find(tracker => !tracker.disabled)?.id; 
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        trackers() {
            let _trackers = [];
            this.trip.tripFreightSet.edges.map(edge =>
                edge.node.tripFreightTrackerSet.edges.map(edge =>
                    _trackers.push({
                        id: edge.node?.tracker?.id,
                        serialNumber: edge.node?.tracker?.serialNumber,
                        activatedDate: edge.node?.tracker?.activatedDate,
                        disabled: edge.node?.tracker?.activatedDate
                            ? false
                            : true
                    })
                )
            );
            return _trackers;
        },
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" to ");
        },
        valid() {
            return (
                this.dateRange.length === 2 &&
                this.sensorTypes.length > 0 &&
                this.fileFormat &&
                this.selectedTracker
            );
        },
        startDate() {
            let _startTime = null;
            let trackerStart = null;
            this.trackers.map(tracker => {
                if (
                    tracker.activatedDate &&
                    tracker.activatedDate < trackerStart
                ) {
                    trackerStart = tracker.activatedDate;
                }
            });
            _startTime = this.getMinDate([
                this.trip?.plannedDepartureDate,
                trackerStart,
                this.trackerDeviceTimeRange?.start_date
            ]);
            return _startTime;
        },
        endDate() {
            let endTime = "";
            if (this.trip?.completedAt) {
                endTime = this.trip?.completedAt;
            } else {
                let now = moment()
                    .add(3, "hours")
                    .toISOString();

                endTime = this.getMaxDate([
                    this.trackerDeviceTimeRange?.end_date,
                    this.trip?.plannedArrivalDate,
                    now
                ]);
            }
            return endTime;
        }
    },
    apollo: {
        trackerDeviceTimeRange: {
            query: gql`
                query trackerDeviceTimeRange($trackerId: ID, $tripId: ID) {
                    trackerDeviceTimeRange(
                        tripId: $tripId
                        trackerId: $trackerId
                    )
                }
            `,
            variables() {
                return {
                    trackerId: this.selectedTracker,
                    tripId: this.trip ? this.trip.id : null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            result({ data }) {
                if (data?.trackerDeviceTimeRange) {
                    this.trackerDeviceTimeRange = JSON.parse(
                        data.trackerDeviceTimeRange
                    );
                }
            },
            debounce: 0,
            skip() {
                return !this.selectedTracker;
            }
        }
    },
    watch: {
        generateReportDialog(val) {
            if (val) {
                this.dateRange = [
                    this.startDate?.split("T")[0], //start date
                    this.endDate?.split("T")[0] //end date
                ];
                this.savedDateRange = this.dateRange;
            }
        },
        dateRange(val) {
            if (val.length === 2) {
                if (this.dateRange[0] > this.dateRange[1]) {
                    this.dateRange = [this.dateRange[1], this.dateRange[0]];
                }
            }
        },
        startDate(val) {
            if (val) {
                this.dateRange = [
                    this.startDate?.split("T")[0], //start date
                    this.endDate?.split("T")[0] //end date
                ];
                this.savedDateRange = this.dateRange;
            }
        },
        endDate(val) {
            if (val) {
                this.dateRange = [
                    this.startDate?.split("T")[0], //start date
                    this.endDate?.split("T")[0] //end date
                ];
                this.savedDateRange = this.dateRange;
            }
        }
    },
    methods: {
        getMinDate(dates) {
            let minDate = null;
            dates.forEach(d => {
                if (!minDate || (d && new Date(d) < new Date(minDate))) {
                    minDate = d;
                }
            });
            return minDate;
        },
        getMaxDate(dates) {
            let maxDate = null;
            dates.forEach(d => {
                if (!maxDate || (d && new Date(d) > new Date(maxDate))) {
                    maxDate = d;
                }
            });
            return maxDate;
        },
        closeAndResetInput() {
            this.generateReportDialog = false;
            this.fileFormat = null;
            this.dateRange = [];
            this.sensorTypes = [];
            this.email = "";
            this.users = [];
            this.dateRangeMenu = false;
            this.selectedTracker = null;
            this.savedDateRange = [];
        },
        cancelDatePicker() {
            this.dateRangeMenu = false;
            this.dateRange = this.savedDateRange;
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        saveDatePicker() {
            this.dateRangeMenu = false;
            this.savedDateRange = this.dateRange;
        },
        submitGenerateReport() {
            this.isLoadingGenerateReport = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation CreateTripReport(
                            $input: CreateTripReportInput!
                        ) {
                            createTripReport(input: $input) {
                                trip {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tripId: this.trip.id,
                            trackerId: this.selectedTracker,
                            reportType: this.fileFormat,
                            sensorData: JSON.stringify(this.sensorTypes),
                            startDate: this.dateRange[0],
                            endDate: this.dateRange[1],
                            externalEmails: this.email,
                            users: this.users,
                            generateImmediately: this.generateImmediately
                        }
                    }
                })
                .then(() => {
                    this.isLoadingGenerateReport = false;
                    this.closeAndResetInput();
                    this.$emit("reportGenerated");
                    this.$store.dispatch("snackbar/showMessage", {
                        message: this.$t("alert_rules.report"),
                        color: "primary"
                    });
                })
                .catch(err => {
                    this.isLoadingGenerateReport = false;
                    this.$store.dispatch("snackbar/showMessage", {
                        message: err.message,
                        color: "error"
                    });
                }); 
        },
        changeReportType(type) {
            this.fileFormat = type;
        },
        updateSensorData(val) {
            if (this.sensorTypes.includes(val)) {
                this.sensorTypes = this.sensorTypes.filter(
                    sensorType => sensorType !== val
                );
            } else {
                this.sensorTypes.push(val);
            }
        },
        setTripDuration() {
            this.dateRange = [
                this.startDate?.split("T")[0], //start date
                this.endDate?.split("T")[0] //end date
            ];
            this.savedDateRange = this.dateRange;
        },
        allowedDates(date) {
            if (this.startDate) {
                //time is removed from date so same day is allowed
                let _startDate = this.startDate.split("T")[0];
                return date >= _startDate;
            } else return date;
        }
    }
};
</script>
