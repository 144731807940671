import * as types from "../types";
import { apolloClient, onLogin, onLogout } from "@/vue-apollo";

const ACCESS_TOKEN_KEY = "your_access_token_key";
const REFRESH_TOKEN_KEY = "your_refresh_token_key";

// Helper functions for localStorage operations
const localStoragePlugin = {
    setItem(key, item) {
        localStorage.setItem(key, JSON.stringify(item));
    },
    getItem(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    },
    removeItem(key) {
        localStorage.removeItem(key);
    }
};

// Initialize state from localStorage if available
const state = {
    createdStaff: localStoragePlugin.getItem("createdStaff") || null,
    cancel: localStoragePlugin.getItem("cancel") || null
};

const mutations = {
    setCreatedStaff(state, { type, staff }) {
        if (type == "update") {
            state.createdStaff = staff;
            state.cancel = null;
            localStoragePlugin.setItem("createdStaff", staff);
            localStoragePlugin.removeItem("cancel"); // Clear 'cancel' in localStorage
        } else if (type == "cancel") {
            state.cancel = staff;
            state.createdStaff = null;
            localStoragePlugin.setItem("cancel", staff);
            localStoragePlugin.removeItem("createdStaff"); // Clear 'createdStaff' in localStorage
        }
    }
};

const actions = {
    updateCreatedStaff({ commit }, { type, staff }) {
        commit("setCreatedStaff", { type, staff });
    }
};

const getters = {
    getCreatedStaff: state => state.createdStaff,
    getCancelStaff: state => state.cancel
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
