<template>
    <div v-if="!canAccess">
        <NoPermission></NoPermission>
    </div>
    <div v-else class="px-0 mx-0">
        <v-card :elevation="cardElevation" outlined>
            <!--  <v-card-title :class="Color" >
                <slot name="content"></slot>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    outlined
                    rounded
                    :color="btnColor"
                    @click="openAddTriggerDialog = true"
                    v-if="canEdit && !editAlert"
                >
                    <v-icon left small>add_box</v-icon>
                    Add Trigger
                </v-btn>
            </v-card-title> -->
            <v-card-text class="py-0 px-1">
                <!-- TABLE -->
                <v-data-table
                    :items="triggers.edges"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    loader-height="2"
                    hide-default-footer
                    :loading="$apollo.queries.triggers.loading"
                >
                    <!-- Sensor -->
                    <template v-slot:item.sensor="{ item }">
                        <div
                            v-for="(sensor, i) in JSON.parse(
                                item.node.triggerJson
                            ).Sensor"
                            :key="i"
                            class="d-flex align-center"
                        >
                            <span v-if="i == 0">
                                <v-icon>{{
                                    sensorTypeChoices.find(
                                        sensorTypeChoice =>
                                            sensorTypeChoice.value ===
                                            sensor.Type
                                    ).icon
                                }}</v-icon>
                                {{ sensorText(sensor) }}
                            </span>
                            <span
                                v-else-if="
                                    JSON.parse(item.node.triggerJson).Sensor[
                                        i - 1
                                    ]
                                "
                                ><span
                                    v-if="
                                        sensor.Type !=
                                            JSON.parse(item.node.triggerJson)
                                                .Sensor[i - 1].Type
                                    "
                                    ><v-icon>{{
                                        sensorTypeChoices.find(
                                            sensorTypeChoice =>
                                                sensorTypeChoice.value ===
                                                sensor.Type
                                        ).icon
                                    }}</v-icon>
                                    {{ sensorText(sensor) }}</span
                                >
                            </span>
                        </div>
                    </template>

                    <!-- Location -->
                    <template v-slot:item.locations="{ item }">
                        <span
                            class="d-flex align-center"
                            v-if="
                                getTriggerLocationPartners(
                                    item.node.triggerJson
                                ).length > 0 ||
                                    locationText(item.node.triggerJson) != ''
                            "
                            >{{ $t("general.when") }} &nbsp;<b>
                                {{
                                    getTriggerLocation(item.node.triggerJson)
                                        .Condition
                                }}</b
                            >:</span
                        >
                        <div
                            v-if="
                                getTriggerLocationPartners(
                                    item.node.triggerJson
                                ).length > 0
                            "
                            class="d-flex align-center"
                        >
                            <v-icon>handshake</v-icon>

                            <span
                                v-for="(partner,
                                index) in getTriggerLocationPartners(
                                    item.node.triggerJson
                                )"
                                :key="partner"
                            >
                                {{
                                    partnerTypeChoices.find(
                                        partnerTypeChoice =>
                                            partnerTypeChoice.value === partner
                                    ).text
                                }}{{
                                    !(
                                        index >=
                                        getTriggerLocationPartners(
                                            item.node.triggerJson
                                        ).length -
                                            1
                                    )
                                        ? ", "
                                        : ""
                                }}
                            </span>
                        </div>
                        <div
                            v-if="locationText(item.node.triggerJson) != ''"
                            class="d-flex align-center"
                        >
                            <v-icon>location_on</v-icon>
                            <span>
                                {{ locationText(item.node.triggerJson) }}
                            </span>
                        </div>
                    </template>

                    <template v-slot:item.timer="{ item }"
                        ><v-icon
                            v-if="
                                JSON.parse(item.node.triggerJson)
                                    .RemainMinutes != null &&
                                    JSON.parse(item.node.triggerJson)
                                        .RemainMinutes != 0
                            "
                            >timer</v-icon
                        ><v-icon v-else>
                            bolt
                        </v-icon>
                        <span> {{ timerText(item.node.triggerJson) }}</span>
                    </template>

                    <!-- EDIT COLUMN -->
                    <!-- <template v-slot:item.edit="{ item }">
                        <v-icon
                            @click="editTrigger(item)"
                            v-if="canEdit && !editAlert"
                            >edit</v-icon
                        >
                    </template> -->

                    <!-- REMOVE -->
                    <!-- <template v-slot:item.delete="{ item }">
                        <v-icon
                            @click="removeTrigger(item)"
                            v-if="canEdit && !editAlert"
                            >delete</v-icon
                        >
                    </template> -->
                </v-data-table>
            </v-card-text>
        </v-card>

        <!---- ADD TRIGGER DIALOG ---->
        <AddAlertRuleTriggerDialog
            :openAddTriggerDialog="openAddTriggerDialog"
            :alertRule="alertRule"
            v-on:refreshTriggersTable="refreshTables()"
            v-on:closeAddTriggerDialog="openAddTriggerDialog = false"
        ></AddAlertRuleTriggerDialog>

        <!---- EDIT TRIGGER DIALOG ---->
        <EditAlertRuleTriggerDialog
            ref="editTriggerDialog"
            :openEditTriggerDialog="openEditTriggerDialog"
            :trigger="trigger"
            :alertRule="alertRule"
            v-on:closeEditTriggerDialog="openEditTriggerDialog = false"
            v-on:refreshTriggersTable="refreshTables()"
        ></EditAlertRuleTriggerDialog>

        <!---- DELETE TRIGGER DIALOG ---->
        <DeleteAlertRuleTriggerDialog
            :openDeleteDialog="openDeleteTriggerDialog"
            :triggerId="triggerToDelete"
            v-on:refreshDataTable="refreshTables()"
            v-on:closeDeleteTriggerDialog="closeAndCleanDeletedTriggers"
        >
        </DeleteAlertRuleTriggerDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddAlertRuleTriggerDialog from "@/components/alertrules/create/AddAlertRuleTriggerDialog.vue";
import DeleteAlertRuleTriggerDialog from "@/components/alertrules/delete/DeleteAlertRuleTriggerDialog.vue";
import EditAlertRuleTriggerDialog from "@/components/alertrules/update/EditAlertRuleTriggerDialog.vue";
import helper from "@/utils/helper.js";
import { TenantPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import Config from "@/utils/config.json";

export default {
    name: "AlertRuleTriggers",
    components: {
        AddAlertRuleTriggerDialog,
        DeleteAlertRuleTriggerDialog,
        EditAlertRuleTriggerDialog,
        NoPermission
    },
    props: {
        alertRule: {
            type: Object,
            required: true
        },
        cardElevation: {
            type: Number,
            default: 0
        },
        btnColor: {
            type: String,
            default: "primary"
        },
        editAlert: {
            type: Boolean,
            default: false
        },
        Color: {
            type: String,
            default: ""
        }
    },
    apollo: {
        triggers: {
            query: gql`
                query TenantAlertRuleTriggers(
                    $tenantAlertRuleId: ID!
                    $first: Int
                ) {
                    tenantAlertRuleTriggers(
                        tenantAlertRuleId: $tenantAlertRuleId
                        first: $first
                    ) {
                        edges {
                            node {
                                id
                                triggerJson
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantAlertRuleId: this.alertRule.id,
                    first: 20
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantAlertRuleTriggers;
            },
            skip: false
        }
    },
    data() {
        return {
            openAddTriggerDialog: false,
            openDeleteTriggerDialog: false,
            openEditTriggerDialog: false,
            trigger: null,
            triggerToDelete: null,
            triggers: { edges: [] },
            headerChoices: [
                {
                    text: this.$t("trips.sensor") + "(s)",
                    align: "left",
                    value: "sensor",
                    //width: "500px",
                    sortable: false
                },
                {
                    text: this.$t("home.locations"),
                    align: "left",
                    value: "locations",
                    sortable: false
                },
                {
                    text: this.$t("general.timer"),
                    align: "left",
                    value: "timer",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "edit",
                    width: "5px",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    value: "delete",
                    width: "5px",
                    sortable: false
                }
            ],
            sensorTypeChoices: [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    icon: "thermostat",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    icon: "thermostat",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    icon: "thermostat",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    icon: "water_drop",
                    unit: "%"
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    icon: "lightbulb",
                    unit: "Lux"
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    icon: "bolt",
                    unit: "G"
                },
                {
                    text: this.$t("general.pressure"),
                    value: "pressure",
                    icon: "tire_repair",
                    unit: "Psi"
                }
            ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.getTemperatureUnit = helper.getTemperatureSymbol;
        this.celsiusToFahrenheit = helper.celsiusToFahrenheit;
    },
    watch: {},
    computed: {
        canAccess() {
            return this.hasPermission(TenantPermissions.VIEW_TENANT_ALERT_RULE);
        },
        canEdit() {
            return this.hasPermission(TenantPermissions.EDIT_TENANT_ALERT_RULE);
        },
        partnerTypeChoices: function() {
            let _partnerTypeChoices = [
                { text: this.$t("headers.consignor"), value: "consignor" },
                { text: this.$t("headers.consignee"), value: "consignee" },
                { text: this.$t("headers.forwarder"), value: "forwarder" },
                { text: this.$t("headers.carrier"), value: "carrier" },
                { text: this.$t("home.other"), value: "other" }
            ];
            return _partnerTypeChoices;
        }
    },
    mounted(){
                //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'alert_rule_triggers_svw',
        });
        this.setUserProperties();
    },
    methods: {
          setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("alert_rule_triggers_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("alert_rule_triggers_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editTrigger(item) {
            this.trigger = item.node;
            this.openEditTriggerDialog = true;
        },
        removeTrigger(item) {
            this.openDeleteTriggerDialog = true;
            this.triggerToDelete = item.node.id;
        },
        closeAndCleanDeletedTriggers() {
            this.openDeleteTriggerDialog = false;
            this.trigger = null;
        },
        refreshTables() {
            this.$apollo.queries.triggers.refetch();
            this.$emit("refreshDataTable");
        },
        getTriggerLocation(triggerJson) {
            return JSON.parse(triggerJson)?.Location || {};
        },
        getTriggerLocationPartners(triggerJson) {
            return JSON.parse(triggerJson)?.Location?.Partners || [];
        },
        sensorText(sensor) {
            let _choice = this.sensorTypeChoices.find(
                sensorTypeChoice => sensorTypeChoice.value === sensor.Type
            );
            let text = _choice.text + " ";

            if (sensor.Condition == "in") {
                text += "between ";
                if (_choice.unit == "temperature") {
                    if (this.getTemperatureUnit() == "ºF")
                        text +=
                            this.celsiusToFahrenheit(sensor.Value[0]) +
                            " " +
                            this.getTemperatureUnit() +
                            " and " +
                            this.celsiusToFahrenheit(sensor.Value[1]) +
                            " " +
                            this.getTemperatureUnit();
                    else
                        text +=
                            sensor.Value[0] +
                            " " +
                            this.getTemperatureUnit() +
                            " and " +
                            sensor.Value[1] +
                            " " +
                            this.getTemperatureUnit();
                } else {
                    text +=
                        sensor.Value[0] +
                        " " +
                        _choice.unit +
                        " and " +
                        sensor.Value[1] +
                        " " +
                        _choice.unit;
                }
            } else if (sensor.Condition == "out") {
                text += "out of range from ";
                if (_choice.unit == "temperature") {
                    if (this.getTemperatureUnit() == "ºF")
                        text +=
                            this.celsiusToFahrenheit(sensor.Value[0]) +
                            " " +
                            this.getTemperatureUnit() +
                            " to " +
                            this.celsiusToFahrenheit(sensor.Value[1]) +
                            " " +
                            this.getTemperatureUnit();
                    else
                        text +=
                            sensor.Value[0] +
                            " " +
                            this.getTemperatureUnit() +
                            " to " +
                            sensor.Value[1] +
                            " " +
                            this.getTemperatureUnit();
                } else {
                    text +=
                        sensor.Value[0] +
                        " " +
                        _choice.unit +
                        " to " +
                        sensor.Value[1] +
                        " " +
                        _choice.unit;
                }
            } else {
                if (sensor.Condition == "gt")
                    text += this.$t("general.above1") + " ";
                else if (sensor.Condition == "lt")
                    text += this.$t("general.below1") + " ";
                if (_choice.unit == "temperature")
                    if (this.getTemperatureUnit() == "ºF")
                        text +=
                            this.celsiusToFahrenheit(sensor.Value[0]) +
                            " " +
                            this.getTemperatureUnit();
                    else
                        text +=
                            sensor.Value[0] + " " + this.getTemperatureUnit();
                else text += sensor.Value[0] + " " + _choice.unit;
            }
            return text;
        },
        locationText(trigger) {
            let _trigger = JSON.parse(trigger);
            let _locations = "";
            if (_trigger?.Location?.Departure) {
                _locations += "Departure";
            }
            if (_trigger?.Location?.InTransit) {
                if (_locations != "") _locations += ", ";
                _locations += "In transit";
            }
            if (_trigger?.Location?.Arrival) {
                if (_locations != "") _locations += ", ";
                _locations += "Arrival";
            }
            if (_trigger?.Location?.Origin) {
                _locations += "Origin";
            }
            if (_trigger?.Location?.Destination) {
                if (_locations != "") _locations += ", ";
                _locations += "Destination";
            }
            if (_trigger?.Location?.Public) {
                if (_locations != "") _locations += ", ";
                _locations += "Public";
            }
            if (_trigger?.Location?.Custom?.length > 0) {
                if (_locations != "") _locations += " and ";
                _locations +=
                    _trigger.Location.Custom.length + " Custom Locations";
            }
            return _locations;
        },
        timerText(trigger) {
            let _trigger = JSON.parse(trigger);
            let timerFormat = _trigger.timerFormat;
            let remainMinutes = _trigger.RemainMinutes;
            let result = "";
            let days = this.$t("date.days");
            let hours = this.$t("date.hours");
            let minutes = this.$t("date.minutes");
            
            if(!timerFormat && !remainMinutes){
                result = "Immediately"
            }else if(timerFormat && !remainMinutes){
                return "Immediately";
            }else if(!timerFormat && remainMinutes){
                return remainMinutes/60 + ` ${hours}`;
            }
            else{
                switch (timerFormat) {
                    case "days":
                        result = `${(_trigger.RemainMinutes / 1440) || 0}` + " " + days;
                        break;
                    case "hours":
                        result = `${_trigger.RemainMinutes / 60 || 0}`+ " " + hours;
                        break;
                    case "minutes":
                        result = _trigger.RemainMinutes + " " + minutes;
                        break;
                    default:
                        result = "Invalid timerFormat";
                }
            }

            
            return result;
        }
    }
        
};
</script>
