<template>
    <v-dialog max-width="700" v-model="openDialog" persistent scrollable
        ><v-card>
            <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-2">send</v-icon>
                {{ $t("partners.report_missing_location") }}
            </v-card-title>
            <v-card-text class="pt-6">
                <v-form ref="locationForm" v-model="formValid">
                    <v-row dense justify="center">
                        <!-- <v-col lg="2" md="8" cols="12">
                            <span class="text-h5">{{
                                $t("general.required")
                            }}</span></v-col
                        > -->
                        <!-- Adress -->
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="address"
                                :label="$t('partners.address') + '*'"
                                :rules="[rules.required]"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <!-- City -->
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="city"
                                :label="$t('partners.city') + '*'"
                                :rules="[rules.required]"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <!-- Country -->
                        <v-col cols="12" clas="py-0">
                            <v-text-field
                                v-model="country"
                                :label="$t('partners.country') + '*'"
                                :rules="[rules.required]"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <!-- Location Type select -->
                        <v-col cols="12" clas="py-0">
                            <v-select
                                v-model="location_type"
                                :items="locationTypes"
                                item-text="text"
                                item-value="value"
                                :label="$t('headers.location_type') + '*'"
                                :rules="[rules.required]"
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
                        <!-- <v-col lg="2" md="8" cols="12">
                            <span class="text-h5">{{
                                $t("general.optional")
                            }}</span></v-col
                        > -->
                        <!-- Description -->
                        <v-col cols="12" class="py-0">
                            <v-textarea
                                v-model="description"
                                :label="$t('general.additional_info')"
                                :rules="[rules.maxLength(200)]"
                                outlined
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end pb-3 pr-5">
                <v-btn text :rounded="rounded" @click="closeAndResetInput">{{ $t("general.cancel") }}</v-btn>
                <v-btn
                    color="primary"
                    :rounded="rounded"
                    :disabled="!formValid"
                    @click="sendReport"
                    :loading="isLoading"
                    >{{ $t("general.send") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    props: {
        openDialog: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: true
        }
    },
    apollo: {},
    data() {
        return {
            formValid: false,
            address: null,
            city: null,
            country: null,
            location_type: null,
            description: null,
            isLoading: false,
            locationTypes: [
                { text: this.$t("headers.airport"), value: "Airport" },
                { text: this.$t("headers.sea_port"), value: "Sea port" }
            ],
            rules: {
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long"),
                required: v => !!v || this.$t("rules.required")
            }
        };
    },
    methods: {
        closeAndResetInput() {
            this.$emit("closeDialog");
            this.formValid = false;
            this.address = null;
            this.city = null;
            this.country = null;
            this.location_type = null;
            this.description = null;
            this.isLoading = false;
        },
        sendReport() {
            this.isLoading = true;
            let payload = {
                address: this.address,
                city: this.city,
                country: this.country,
                description: this.description,
                locationType: this.location_type
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation Mutation($input: CreatePublicLocationRequestInput!) {
                            createPublicLocationRequest(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: payload
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                    this.closeAndResetInput();
                });
        }
    }
};
</script>

<style scoped></style>
