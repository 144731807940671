<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <!---- SEARCH BAR ---->
                    <v-col cols="5" sm="5" lg="5">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    hint="Search by action group name"
                                    v-model="filter.name_Icontains"
                                    append-icon="search"
                                    :label="$t('general.search')"
                                    clearable
                                    hide-details
                                    dense
                                    class="subtitle-2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-spacer></v-spacer>
                   
                </v-row>
                <!-- ADD ACTION GROUP DIALOG -->
               <!--  <AddActionGroupDialog
                    :openAddActionGroupDialog="openAddActionGroup"
                    v-on:refreshDataTable="$emit('updateActionGroupsTable')"
                    v-on:closeAddGroupDialog="openAddActionGroup = false"
                    v-on:item-created="onActionGroupCreated"
                ></AddActionGroupDialog> -->
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
//import AddActionGroupDialog from "@/components/actiongroups/create/AddActionGroupDialog.vue";

export default {
    components: { 
            //AddActionGroupDialog 
        },
    apollo: {},

    data() {
        return {
            fab: false,
            isLoading: false,
            sortAsc: true,
            shouldStickyFilter: false,
            openAddActionGroup: false,

            // graphql query filter
            filter: {
                first: 10,
                name_Icontains: null
            }
        };
    },

    computed: {
        me() {
            return helper.me();
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                    helper.setFilterValues("actionGroupTableFilters", value);
                }
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.onResize();
        let _filter = helper.getFilterValues("actionGroupTableFilters");
        if (_filter) {
            this.filter.name_Icontains = _filter.name_Icontains;
        }
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        },
        onActionGroupCreated(actionGroup) {
            this.$emit("item-created", actionGroup);
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
