<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5 transparent"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                outlined
            >
                <v-row align="start" class="d-flex align-center justify-center transparent">
                    <v-col>
                        <SearchBar v-model="filter.search" :label="label"></SearchBar>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import SearchBar from "@/components/base/SearchBar.vue";

export default {
    components: {
        SearchBar
    },
    props: {
        title: {
            type: String,
            default: "Partner"
        },
        label: {
            type: String,
            default: null
        },
        partnerByType: {
            type: String,
            default: null
        }
    },
    apollo: {},

    data() {
        return {
            openAddPartnerDialog: false,
            isLoading: false,
            fab: false,
            menu: false,
            dateRange: [],
            savedDateRange: [],
            sortAsc: true,
            shouldStickyFilter: false,

            // --------------------> FILTERS <----------------------- \\
            // defaultFilterChoices: [
            //     {
            //         code: "partnerName",
            //         name: "Partner Name",
            //         tag: 'headers.partner_name',
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: true,
            //         order: 0
            //     },
            //     {
            //         code: "referenceNumber",
            //         name: "Reference Number",
            //         tag: 'headers.reference_number',
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: true,
            //         order: 1
            //     },
            //     {
            //         code: "partnerType",
            //         name: "Partner Type",
            //         tag: 'headers.partner_type',
            //         class: "col-auto col-md-3 col-lg-2",
            //         enabled: true,
            //         order: 2
            //     }
            // ],

            filterChoices: null,

            // sortByChoices: [
            //     {
            //         code: "partnerType",
            //         name: this.$t("headers.partner_type")
            //     },
            //     {
            //         code: "name",
            //         name: this.$t("headers.partner_name")
            //     },
            //     {
            //         code: "openTrips",
            //         name: this.$t("headers.open_trips")
            //     },
            //     {
            //         code: "totalTrips",
            //         name: this.$t("headers.total_trips")
            //     }
            // ],

            sortBy: null,

            // partnerTypeChoices: [
            //     { text: this.$t("headers.consignor"), value: "consignor" },
            //     { text: this.$t("headers.consignee"), value: "consignee" },
            //     { text: this.$t("headers.forwarder"), value: "forwarder" },
            //     { text: this.$t("headers.carrier"), value: "carrier" },
            //     { text: this.$t("home.other"), value: "other" }
            // ],
            relativeDateRange: null,
            relativeDateRangeChoices: ["Last 30 days", "Last 90 days", "Last 180 days", "Last 360 days"],

            // graphql query filter
            filter: {
                search: ""
            },
            defaultFilter: {
                first: 15,
                after: null,
                nameIcontains: null,
                referenceNumberIcontains: null,
                partnerType: null,
                orderBy: null
            }
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },
        dynamicFilters() {
            return this.filterChoices.filter(f => f.enabled).map(f => f.code);
        },
        isFromSubMenu() {
            return this.$route.name !== "partners";
        },
        /*  filterBytype(){
            let _filterSwitch = `${this.$route.name}Filter`;
            return _filterSwitch ;
        } */
        defaultFilterChoices: function() {
            let _defaultFilterChoices = [
                {
                    code: "partnerName",
                    name: "Partner Name",
                    tag: "headers.partner_name",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: true,
                    order: 0
                },
                {
                    code: "referenceNumber",
                    name: "Reference Number",
                    tag: "headers.reference_number",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: true,
                    order: 1
                },
                {
                    code: "partnerType",
                    name: "Partner Type",
                    tag: "headers.partner_type",
                    class: "col-auto col-md-3 col-lg-2",
                    enabled: true,
                    order: 2
                }
            ];
            return _defaultFilterChoices;
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "partnerType",
                    name: this.$t("headers.partner_type")
                },
                {
                    code: "name",
                    name: this.$t("headers.partner_name")
                },
                {
                    code: "openTrips",
                    name: this.$t("headers.open_trips")
                },
                {
                    code: "totalTrips",
                    name: this.$t("headers.total_trips")
                }
            ];
            return _sortByChoices;
        },
        partnerTypeChoices: function() {
            let _partnerTypeChoices = [
                { text: this.$t("headers.consignor"), value: "consignor" },
                { text: this.$t("headers.consignee"), value: "consignee" },
                { text: this.$t("headers.forwarder"), value: "forwarder" },
                { text: this.$t("headers.carrier"), value: "carrier" },
                { text: this.$t("home.other"), value: "other" }
            ];
            return _partnerTypeChoices;
        }
    },
    watch: {
        filter: {
            handler(value) {
                if (value) {
                    // value.partnerType = this.partnerType.toLowerCase();
                    this.$emit("filter-changed", JSON.parse(JSON.stringify(value)));
                    helper.setFilterValues(`${this.partnerByType}TableFilters`, value);
                }
            },
            deep: true
        },
        partnerByType: {
            handler(value) {
                if (value) {
                    this.filter.search = null;
                }
            },
            deep: true,
            immediate: true
        },
       /*  sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        }, */
        /* isFromSubMenu: {
            immediate: true,
            handler(val) {
                if (val) {
                    let route = this.$route.name;

                    //this.filter = this.defaultFilter;

                    if (route !== null) {
                        let _filter = helper.getFilterValues(`${route}TableFilters`);

                        if (_filter !== undefined) {
                            if (_filter !== null) this.filter = _filter;
                        }

                        this.partnerType = this.formatType(route);
                        this.filter.partnerType = this.formatType(route);
                    }

                    this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "partnerTableFilters");
                    this.filterChoices = this.filterChoices?.filter(f => f.code !== "partnerType");
                } else {
                    this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "partnerTableFilters");

                    this.filter.partnerType = null;
                }
            },
            deep: true
        } */
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "partnerTableFilters");
        if (this.isFromSubMenu) {
            this.filterChoices = this.filterChoices?.filter(f => f.code !== "partnerType");
        }
    },
    mounted() {
        this.onResize();
      /*   let _filter = null;

        if (this.partnerByType !== null) {
            _filter = helper.getFilterValues(`${this.partnerByType}TableFilters`);
        }

        if (_filter) {
            this.filter = _filter;
            if (_filter.orderBy?.includes("-")) {
                this.sortBy = _filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else {
                this.sortBy = _filter.orderBy;
                this.sortAsc = true;
            }
        }

        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    partnerTableFilters: value
                });
            },
            { deep: true }
        ); */
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        // dynamic filter
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        formatType(type) {
            type = type.toLowerCase();
            if (type === "consignors") {
                return "consignor";
            } else if (type === "consignees") {
                return "consignee";
            } else if (type === "forwarders") {
                return "forwarder";
            } else if (type === "others") {
                return "other";
            }
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        },
        onClearDates() {
            this.dateRange = [];
            this.savedDateRange = [];
            this.relativeDateRange = null;
        },
        onFilterMenuChanged(newChoises) {
            this.filterChoices = newChoises;
        },
        onPartnerCreated(val) {
            this.$emit("partner-created", val);
        },
        titleChoices(title) {
            let choices = [
                { text: this.$t("headers.consignor"), value: "consignors" },
                { text: this.$t("headers.consignee"), value: "consignees" },
                { text: this.$t("headers.forwarder"), value: "forwarders" },
                { text: this.$t("headers.carrier"), value: "carriers" },
                { text: this.$t("home.partner"), value: "others" },
                { text: this.$t("home.partner"), value: null }
            ];

            return choices.find(item => {
                return item.value === title.toLowerCase();
            })?.text;
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
