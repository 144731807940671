import * as types from "../types";
import { apolloClient, onLogin, onLogout } from "@/vue-apollo";

const ACCESS_TOKEN_KEY = "your_access_token_key";
const REFRESH_TOKEN_KEY = "your_refresh_token_key";

// Manage localStorage
const localStoragePlugin = {
    setItem(key, item) {
        localStorage.setItem(key, JSON.stringify(item));
    },
    getItem(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    },
    removeItem(key) {
        localStorage.removeItem(key);
    }
};

const state = {
    createdTrip: localStoragePlugin.getItem('createdTrip') || null,
    cancel: localStoragePlugin.getItem('cancel') || null,
    draft: localStoragePlugin.getItem('draft') || null,
};

const mutations = {
    setCreatedTrip(state, { type, trip }) {
        if (type == "update") {
            state.createdTrip = trip;
            state.cancel = null;
            state.draft = null;
            localStoragePlugin.setItem('createdTrip', trip);
            localStoragePlugin.removeItem('cancel');
            localStoragePlugin.removeItem('draft');
        } else if (type == "cancel") {
            state.cancel = trip;
            state.createdTrip = null;
            state.draft = null;
            localStoragePlugin.setItem('cancel', trip);
            localStoragePlugin.removeItem('createdTrip');
            localStoragePlugin.removeItem('draft');
        } else {
            state.draft = trip;
            state.createdTrip = null;
            state.cancel = null;
            localStoragePlugin.setItem('draft', trip);
            localStoragePlugin.removeItem('createdTrip');
            localStoragePlugin.removeItem('cancel');
        }
    }
};

const actions = {
    updateCreatedTrip({ commit }, { type, trip }) {
        commit("setCreatedTrip", { type, trip });
    },
    destroyDraft({commit}) {
        // Clear specific local storage items
        localStoragePlugin.removeItem('draft');
    },
    destroyStorage({ commit }) {
        // Clear specific local storage items
        localStoragePlugin.removeItem('createdTrip');
        localStoragePlugin.removeItem('cancel');
        localStoragePlugin.removeItem('draft');

        // Reset Vuex state
        commit('setCreatedTrip', { type: null, trip: null });
    }
};

const getters = {
    getCreatedTrip: state => state.createdTrip,
    getCancelTrip: state => state.cancel,
    getDraftTrip: state => state.draft,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
