<template>
    <v-dialog max-width="600" v-model="openAddActionGroupDialog" persistent>
        <!--- MAIN CARD --->
        <v-card class="pb-3">
            <!--- TITLE --->
            <v-card-title class="primary white--text">
                {{ $t("alert_rules.create_action_group") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pt-4 mb-2">
                <v-form ref="addActionGroupForm" v-model="valid">
                    <v-row dense class="mt-6">
                        <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="groupName"
                                :label="$t('alert_rules.action_name') + '*'"
                                :rules="[rules.required]"
                                outlined
                                class="mx-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <ActionGroupAddUserForm
                                v-model="users"
                                class="mx-2"
                            ></ActionGroupAddUserForm>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <!-- rules activate just if email length greater than zero -->
                            <v-text-field
                                v-model="email"
                                :label="$t('headers.external_email_address')"
                                :rules="[
                                    email.length > 0 ? rules.email : () => true
                                ]"
                                outlined
                                class="mx-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="py-0">
                            <v-checkbox
                                v-model="shouldEmailNotification"
                                :label="$t('headers.email_notification')"
                                class="mx-4 mt-3 text-no-wrap"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="4" class="py-0">
                            <v-checkbox
                                v-model="shouldInboxNotification"
                                :label="$t('headers.inbox_notification')"
                                class="mx-4 mt-3 text-no-wrap"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTIONS --->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeAndResetInput">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    class="mr-3"
                    :disabled="!valid"
                    color="primary"
                    rounded
                    :loading="isLoadingAddActionGroup"
                    @click="addActionGroup"
                    >{{ $t("general.add") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ActionGroupAddUserForm from "@/components/actiongroups/forms/ActionGroupAddUserForm.vue";

export default {
    name: "AddActionGroup",
    props: ["openAddActionGroupDialog"],
    components: {
        ActionGroupAddUserForm
    },
    data() {
        return {
            error: false,
            isLoadingAddActionGroup: false,
            groupName: null,
            email: "",
            users: [],
            groupId: null,
            editMode: false,
            shouldEmailNotification: true,
            shouldInboxNotification: true,
            valid: false,
            /***********************************/
            /* --------- FORM DATA --------- */
            /*********************************/
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                email: v => /.+@.+\..+/.test(v) || this.$t("rules.email")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        }
    },
    methods: {
        editGroup(item){
            let myEmail = ""
            if(item.externalEmails != null){
                let emails = item.externalEmails.replace(/'/g, '"')
                let _emails = JSON.parse(emails)
                if (Array.isArray(_emails) && _emails.length > 0) {
                    _emails.forEach((element, index) => {
                        myEmail += element;

                        if (index < _emails.length - 1) {
                            myEmail += ", ";
                        }
                    });
                }            
            } 

            this.groupId = item.id;
            this.email = myEmail; 
            this.groupName = item.name;
            this.users = item.tenantActionGroupUserSet.edges.map(
                edge => edge.node.user.id
            );
            this.shouldEmailNotification = item.shouldEmailNotification;
            this.shouldInboxNotification = item.shouldInboxNotification;
            this.editMode = true;
        },
        closeAndResetInput() {
            this.groupName = null;
            this.error = false;
            this.users = [];
            this.email = [];
            this.shouldEmailNotification = true;
            this.shouldInboxNotification = true;
            this.$refs.addActionGroupForm.resetValidation();
            this.$emit("closeAddGroupDialog");
        },
        /* ---> TENANT PARTNER MUTATION <--- */
        addActionGroup() {
            // validate form input :
            if (!this.$refs.addActionGroupForm.validate()) return;
            // Initialize Loading :
            this.isLoadingAddActionGroup = true;

            let _emails = this.email.split(/[,\\s]+/);

            //Initialize Mutation :
            if(!this.editMode){
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CreateTenantActionGroup(
                                $input: CreateTenantActionGroupInput!
                            ) {
                                createTenantActionGroup(input: $input) {
                                    tenantActionGroup {
                                        id
                                        name
                                        userEmails
                                        externalEmails
                                        shouldEmailNotification
                                        shouldInboxNotification
                                        tenantActionGroupUserSet {
                                            edges {
                                                node {
                                                    user {
                                                        id
                                                        fullName
                                                        firstName
                                                        lastName
                                                        email
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                tenantId: this.me.tenant.id,
                                name: this.groupName,
                                usersIds: this.users,
                                externalMails:_emails,
                                shouldEmailNotification: this
                                    .shouldEmailNotification,
                                shouldInboxNotification: this
                                    .shouldInboxNotification
                            }
                        }
                    })
                    .then(response => {
                        // Emit event to tabel data and load new groups //
                        this.$emit("refreshDataTable");
                        this.$emit(
                            "item-created",
                            response.data.createTenantActionGroup.tenantActionGroup
                        );
    
                        const payload = {
                            color: "blue",
                            message: this.$t("alert_rules.group_saved")
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .catch(error => {
                        console.log(error);
                        this.error = true;
                        this.isLoadingAddActionGroup = false;
                    })
                    .finally(() => {
                        this.isLoadingAddActionGroup = false;
                        this.closeAndResetInput();
                    });
            }else{
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation UpdateTenantActionGroup(
                                $input: UpdateTenantActionGroupInput!
                            ) {
                                updateTenantActionGroup(input: $input) {
                                    tenantActionGroup {
                                        id
                                        name
                                        externalEmails
                                        shouldEmailNotification
                                        shouldInboxNotification
                                        tenantActionGroupUserSet {
                                            edges {
                                                node {
                                                    user {
                                                        id
                                                        fullName
                                                        firstName
                                                        lastName
                                                        email
                                                    }
                                                }
                                            }
                                        }

                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                id: this.groupId,
                                name: this.groupName,
                                usersIds: this.users,
                                externalMails:this.email,
                                shouldEmailNotification: this
                                    .shouldEmailNotification,
                                shouldInboxNotification: this
                                    .shouldInboxNotification
                            }
                        }
                    })
                    .then(response => {
                        // Emit event to tabel data and load new groups //
                        this.$emit("refreshGroupsTable");
                        this.$emit(
                            "item-updated",
                            response.data.updateTenantActionGroup.tenantActionGroup
                        );
                        const payload = {
                            color: "blue",
                            message: this.$t("alert_rules.group_saved")
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .catch(error => {
                        if (error.message == "INVALID_REFERENCE_NUMBER")
                            this.error = true;
                        this.isLoadingEditGroup = false;
                    })
                    .finally(() => {
                        this.isLoadingAddActionGroup = false;
                        this.closeAndResetInput();
                    });

            }
        }
    }
};
</script>
