<template>
    <v-card flat>
        <!-- <v-alert dense type="info" elevation="2">
            Alert Rules to be applied for all new trips or by partner will be
            added after the creation of the trip
        </v-alert> -->
        <v-row dense>
            <v-col cols="12" md="6" v-for="(freight, i) in freights" :key="i">
                <v-container fluid>
                    <v-form v-model="formValue">
                        <v-card flat outlined class="pr-3 pt-2 pb-n7">
                            <v-card-title class="py-0 pr-0">
                                <v-chip small>
                                    <div class="grey--text title-2">
                                        <i
                                            >{{ $t("headers.freight") }}
                                            {{ i + 1 }}</i
                                        >
                                    </div>
                                </v-chip>
                                <v-spacer></v-spacer>
                                <v-btn
                                    rounded
                                    icon
                                    color="primary"
                                    small
                                    @click="addFreight"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    small
                                    color="grey"
                                    @click="deleteItem(i)"
                                    ><v-icon>close</v-icon></v-btn
                                >
                            </v-card-title>
                            <v-card-text class="mb-n3 pa-0">
                                <v-timeline dense align-top class="ml-n1 pr-2">
                                    <v-timeline-item color="white" class="py-0">
                                        <template v-slot:icon>
                                            <v-icon color="primary">tag</v-icon>
                                        </template>
                                        <v-row dense>
                                            <v-col cols="6" class="py-0">
                                                <v-text-field
                                                    v-model="
                                                        freight.referenceNumber
                                                    "
                                                    :label="
                                                        templateMode
                                                            ? $t(
                                                                  'forms.freight_ref'
                                                              )
                                                            : $t(
                                                                  'forms.freight_ref'
                                                              ) + ' *'
                                                    "
                                                    outlined
                                                    dense
                                                    :rules="[rules.required]"
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="5" class="py-0">
                                                <v-text-field
                                                    v-model="
                                                        freight.orderNumber
                                                    "
                                                    :label="
                                                        $t('forms.order_no')
                                                    "
                                                    outlined
                                                    dense
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-timeline-item>
                                    <v-timeline-item color="white" class="py-0">
                                        <template v-slot:icon>
                                            <v-icon color="primary">tag</v-icon>
                                        </template>
                                        <v-row dense>
                                            <v-col
                                                cols="6"
                                                class="py-0"
                                                v-if="
                                                    trip.transportMode ===
                                                        'sea' ||
                                                        trip.transportMode ===
                                                            'air'
                                                "
                                            >
                                                <v-text-field
                                                    v-model="
                                                        freight.containerNumber
                                                    "
                                                    :label="
                                                        $t(
                                                            'headers.container_number'
                                                        )
                                                    "
                                                    outlined
                                                    v-if="
                                                        trip.transportMode ===
                                                            'sea'
                                                    "
                                                    dense
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                >
                                                </v-text-field>
                                                <v-text-field
                                                    v-if="
                                                        trip.transportMode ===
                                                            'air'
                                                    "
                                                    v-model="freight.awb"
                                                    :label="$t('forms.awb')"
                                                    outlined
                                                    dense
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col
                                                :cols="
                                                    trip.transportMode ===
                                                        'sea' ||
                                                    trip.transportMode === 'air'
                                                        ? 5
                                                        : 11
                                                "
                                                class="py-0"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        freight.billsOfLading
                                                    "
                                                    :label="
                                                        $t('forms.bill_lading')
                                                    "
                                                    outlined
                                                    dense
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-timeline-item>
                                    <v-timeline-item color="white" class="py-0">
                                        <template v-slot:icon>
                                            <v-icon color="primary"
                                                >description</v-icon
                                            >
                                        </template>
                                        <v-row dense>
                                            <v-col cols="6" class="py-0">
                                                <v-text-field
                                                    v-model="freight.goods"
                                                    :label="$t('forms.goods')"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="5" class="py-0">
                                                <v-select
                                                    v-model="freight.term"
                                                    :items="termChoices"
                                                    :label="$t('forms.term')"
                                                    outlined
                                                    dense
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-timeline-item>
                                    <v-timeline-item color="white" class="">
                                        <template v-slot:icon>
                                            <v-icon color="primary"
                                                >location_on</v-icon
                                            >
                                        </template>
                                        <v-row dense>
                                            <v-col cols="6" class="py-0">
                                                <v-text-field
                                                    v-model="freight.loadPoint"
                                                    :label="
                                                        $t('forms.load_point')
                                                    "
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="5" class="py-0">
                                                <DateComponent
                                                    v-model="freight.loadDate"
                                                    :label="
                                                        $t('forms.load_date')
                                                    "
                                                    :icon="'calendar_month'"
                                                    :outlined="true"
                                                    :dense="true"
                                                    :hideDetails="true"
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                ></DateComponent>
                                            </v-col>
                                            <v-col cols="11" class="py-0">
                                                <v-text-field
                                                    v-model="freight.contact"
                                                    :label="
                                                        $t(
                                                            'forms.contact_person'
                                                        )
                                                    "
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-timeline-item>
                                    <v-timeline-item color="white" class="py-0">
                                        <template v-slot:icon>
                                            <v-icon color="primary"
                                                >settings_remote</v-icon
                                            >
                                        </template>
                                        <v-row dense class="pb-5">
                                            <v-col cols="11">
                                                <TrackerSelected
                                                    v-model="freight.trackers"
                                                    :label="
                                                        $t(
                                                            'forms.select_tracker'
                                                        )
                                                    "
                                                    :dense="true"
                                                    :currentTrackers="
                                                        currentTrackers
                                                    "
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                >
                                                </TrackerSelected>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col cols="11" class="py-0">
                                                <DateTimeComponent
                                                    v-model="freight.startDate"
                                                    :label="$t('trackers.tracker_start_date')"
                                                    :icon="'calendar_month'"
                                                    :outlined="true"
                                                    :dense="true"
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                    :defaultTime="'00:00'"
                                                    :rules="[trackerDateChange(freight.startDate, freight.endDate)]"
                                                >
                                                </DateTimeComponent>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col cols="11">
                                                <DateTimeComponent
                                                    v-model="freight.endDate"
                                                    :label="$t('trackers.tracker_end_date')"
                                                    :icon="'calendar_month'"
                                                    :outlined="true"
                                                    :dense="true"
                                                    :disabled="templateMode"
                                                    :filled="templateMode"
                                                    :defaultTime="'00:00'"
                                                    :rules="freight.endDate !== null &&
                                                        freight.endDate.length > 0
                                                        ? [checkTrackerDate(freight.startDate,freight.endDate)]
                                                        : []"
                                                >
                                                </DateTimeComponent>
                                            </v-col>
                                        </v-row>
                                    </v-timeline-item>
                                    <v-timeline-item color="white">
                                        <template v-slot:icon>
                                            <v-icon color="primary"
                                                >notifications</v-icon
                                            >
                                        </template>
                                        <v-row dense>
                                            <v-col cols="11" class="pt-0">
                                                <AlertRuleAutoComplete
                                                    v-model="freight.alerts"
                                                    :label="
                                                        $t(
                                                            'forms.select_alert_rule'
                                                        )
                                                    "
                                                    :dense="true"
                                                    :refresh.sync="refresh"
                                                >
                                                </AlertRuleAutoComplete>
                                            </v-col>
                                            <v-col cols="1" class="pt-0">
                                                <v-btn
                                                    icon
                                                    outlined
                                                    color="primary"
                                                    small
                                                    class="mt-2"
                                                    @click="
                                                        addAlertRuleMethod(i)
                                                    "
                                                >
                                                    <v-icon> add</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-timeline-item>
                                </v-timeline>
                            </v-card-text>
                        </v-card>
                    </v-form>
                </v-container>
            </v-col>
        </v-row>
        <AddAlertRuleDialog
            :openAddAlertRuleDialog="openAddAlertRule"
            :createMode="true"
            v-on:refreshDataTable="refresh = true"
            v-on:close-dialog="openAddAlertRule = false"
            v-on:item-created="onItemCreated"
        ></AddAlertRuleDialog>
    </v-card>
</template>
<script>
//import FilterHeaders from '../../base/FilterHeaders.vue';
//import FreightsForm from './FreightsForm.vue';
import DateComponent from "@/components/base/DateComponent.vue";
//import TimeComponent from "@/components/base/TimeComponent.vue";
import helper from "@/utils/helper.js";
import TrackerSelected from "@/components/trackers/TrackerSelected.vue";
import AlertRuleAutoComplete from "@/components/alertrules/AlertRuleAutoComplete.vue";
import AddAlertRuleDialog from "@/components/alertrules/create/AddAlertRuleDialog.vue";
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import moment from "moment-timezone";

export default {
    props: {
        trip: {
            type: Object
        },
        value: {
            type: Array
        },
        validate: {
            type: Boolean,
            default: false
        },
        currentTrackers: {
            type: Array
        },
        templateMode: {
            type: Boolean,
            default: false
        },
        plannedDepartureDate: {
            type: String
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TrackerSelected,
        AlertRuleAutoComplete,
        DateComponent,
        //TimeComponent,
        AddAlertRuleDialog,
        DateTimeComponent
        //FreightsForm,
    },
    data() {
        return {
            icons: {
                air: "flight",
                road: "local_shipping",
                rail: "train",
                ship: "directions_boat"
            },
            dialogForm: false,
            validateForm: false,
            toggleTitle: false,
            openAddAlertRule: false,
            freightIndex: 0,
            refresh: false,
            item: {},
            index: 0,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.invalid") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                alertIsInvalid: v =>
                    v === false || this.$t("rules.ref_no_exists")
            },
            termChoices: [
                {
                    value: "DAP",
                    text: "DAP" + this.$t("term.dap")
                },
                {
                    value: "DAT",
                    text: "DAT" + this.$t("term.dat")
                },
                {
                    value: "DDP",
                    text: "DDP" + this.$t("term.ddp")
                },
                {
                    value: "DPU",
                    text: "DPU" + this.$t("term.dpu")
                },
                {
                    value: "CFR",
                    text: "CFR" + +this.$t("term.cfr")
                },
                {
                    value: "CIF",
                    text: "CIF" + this.$t("term.cif")
                },
                {
                    value: "CIP",
                    text: "CIP" + this.$t("term.cip")
                },
                {
                    value: "CPT",
                    text: "CPT" + this.$t("term.cpt")
                },
                { value: "EXW", text: "EXW" + this.$t("term.exw") },
                { value: "FCA", text: "FCA" + this.$t("term.fca") },
                {
                    value: "FAS",
                    text: "FAS" + this.$t("term.fas")
                },
                { value: "FOB", text: "FOB" + this.$t("term.fob") }
            ]
        };
    },
    computed: {
        freights: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        // check v-model form value and emit to update to parent
        formValue: {
            get() {
                return this.validate;
            },
            set(value) {
                this.$emit("update:validate", value);
            }
        }
    },
    mounted() {},
    watch: {
        /* 'trip.transportMode': {
            immediate: true,
            handler: function (newVal) {
                if (newVal === 'sea') {
                    console.log("sea")
                }
                   
            },
            deep: true
        }, */
    },
    methods: {
        addAlertRuleMethod(index) {
            this.openAddAlertRule = true;
            this.freightIndex = index;
        },
        clickAdd() {
            this.toggleTitle = false;
            this.dialogForm = true;
        },
        addFreight() {
            this.freights.push({
                referenceNumber: null,
                containerNumber: null,
                awb: null,
                orderNumber: null,
                billsOfLading: null,
                contact: "",
                loadPoint: "",
                loadDate: null,
                startDate: null,
                endDate: null,
               /*  startTime: null,
                endTime: null, */
                goods: null,
                term: null,
                trackers: [],
                alerts: []
            });
        },
        deleteItem(index) {
            this.freights.splice(index, 1);
        },
        editItem(item, index) {
            this.item = { ...item };
            this.index = index;
            this.toggleTitle = true;
            this.dialogForm = true;
        },
        aditFreigth(value) {
            this.freights.splice(this.index, 1, { ...value });
            this.index = 0;
            this.item = {};
            this.closeFreightForm();
        },
        closeFreightForm() {
            this.dialogForm = false;
            this.$refs.freightForm.resetForm();
        },
        clearCache() {
            this.freights = [];
        },
        onItemCreated(item) {
            this.freights[this.freightIndex].alerts.push(item.id);
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        disablePastDates(val) {
            return val >= this.plannedDepartureDate;
        },
        checkTrackerDate(startDate, endDate) {

            if (
                startDate === null ||
                startDate.length < 1
            ) {
                return true;
            }
            if (
                endDate === null ||
                endDate.length < 1
            ) {
                return true;
            }

            let [dateA, timeA] = startDate.split(" ");
            let [dateB, timeB] = endDate.split(" ");
            
            if (helper.isEmpty(dateA) || helper.isEmpty(timeA)) {
                return true;
            }

            let _newDateA = moment(dateA, helper.getDateFormat());
            let _DateA = moment(_newDateA, [
                "DD/MM/YYYY",
                "YYYY/MM/DD",
                "MM/DD/YYYY"
            ]).toDate();
            let _dateFormatA = moment(_DateA).format("YYYY-MM-DD");

            //dateTime B
            let _newDateB = moment(dateB, helper.getDateFormat());
            let _DateB = moment(_newDateB, [
                "DD/MM/YYYY",
                "YYYY/MM/DD",
                "MM/DD/YYYY"
            ]).toDate();
            let _dateFormatB = moment(_DateB).format("YYYY-MM-DD");

            let dateTimeA = _dateFormatA + " " + timeA;
            let dateTimeB = _dateFormatB + " " + timeB;

            let validDate = moment(dateTimeA).isSameOrBefore(dateTimeB);
            
            return validDate || this.$t("rules.end_date");
        },
        trackerDateChange(startDate, endDate){

            if (startDate === null || startDate.length < 1) {
                return true;
            }

            if (endDate === null || endDate.length < 1) {
                return true;
            }

            this.checkTrackerDate(startDate, endDate);

            return true;
        }

    }
};
</script>
<style scoped>
.columns {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 4px;
}
</style>
