import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,{staticClass:"subtitle-2 grey--text"},[_vm._v(" "+_vm._s(_vm.$t("headers.trips"))+" ")]),_c(VCardText,[_c('apexcharts',{attrs:{"type":"bar","height":"350","options":_vm.chartOptions,"series":_vm.series}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }