import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('FilterMenu',{staticClass:"mr-n2",attrs:{"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display'),"choices":_vm.filterChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1)],1),_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.dynamicChoices),function(kpi){return _c(VCol,{key:kpi.name,attrs:{"cols":"12","lg":"3","md":"6","sm":"12"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,{staticClass:"pa-3"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c('div',[_c('h6',{staticClass:"subtitle-2 grey--text text-left text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$t(kpi.tag))+" ")])]),_c('div',{staticClass:"text-left grey--text text--darken-3 mt-3"},[_c('h1',{staticClass:"font-weight-regular text-no-wrap"},[_vm._v(" "+_vm._s(kpi.value)+" ")])])]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_c('div',[_c(VBtn,{class:kpi.color,attrs:{"elevation":"0","fab":"","small":""}},[_c(VIcon,{staticClass:"d-flex align-center justify-center",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(kpi.icon)+" ")])],1)],1)])],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }