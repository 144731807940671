<template>
    <div class="px-5 mb-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <v-col cols="12" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-btn
                                small
                                outlined
                                color="primary"
                                @click="expand"
                            >
                                {{
                                    isAllExpanded
                                        ? "- " + $t("general.collapse")
                                        : "+ " + $t("general.expand")
                                }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="12" sm="5" lg="4" class="py-2 pr-4 text-right">
                    <v-row class="d-flex align-center justify-end">
                        <!-- ADD -->
                        <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            class="mr-1"
                            :loading="isLoadingUpdateGroupPermissions"
                            @click="updateUserGroupPermission"
                            :disabled="enableOrDisablePermissionBtn"
                        >
                            <v-icon left small>add_box</v-icon>
                            {{ $t("general.save_permissions") }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <!-- <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined> -->
        <v-expansion-panels
            v-model="panel"
            multiple
            focusable
            class="mt-2 mb-5 pb-5"
        >
            <v-expansion-panel
                v-for="(item, index) in computedPermissionsObject"
                :key="index"
            >
                <v-expansion-panel-header>
                    <h5>{{ item.name }}</h5>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                    <v-lazy>
                        <!-- TABLE -->
                        <GroupPermissionTable
                            :tableData.sync="item.options"
                            :isLoadingTable="
                                $apollo.queries.groupPermissions.loading
                            "
                            :permissions.sync="permissions"
                            v-on:updateTableData="updateSelectedPermissions"
                        ></GroupPermissionTable>
                    </v-lazy>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <!-- </v-card> -->
    </div>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import GroupPermissionTable from "@/components/groups/tabs/GroupPermissionTabTable.vue";
import {
    TripPermissions,
    TrackerPermissions,
    CarrierPermissions,
    UserPermissions,
    TenantPermissions,
    BookingPermissions
} from "@/core/enum";
import Config from "@/utils/config.json";

export default {
    name: "GroupPermissionsTab",
    components: { GroupPermissionTable },
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    apollo: {
        groupPermissions: {
            query: gql`
                query TenantUserGroup($tenantUserGroupId: ID!) {
                    tenantUserGroup(id: $tenantUserGroupId) {
                        id
                        name
                        permissions
                    }
                }
            `,
            variables() {
                return {
                    tenantUserGroupId: this.group.node.id
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUserGroup.permissions;
            },
            result({ loading, data }) {
                if (!loading) {
                    let tenantUserGroupPermissions =
                        data.tenantUserGroup.permissions;
                    let temp = [...tenantUserGroupPermissions];
                    this.permissionStr = temp.sort().join("-");
                    this.permissions = [...tenantUserGroupPermissions];
                }
            },
            skip: false
        }
    },
    data() {
        return {
            panel: [0],
            permissions: [],
            isLoadingUpdateGroupPermissions: false,
            enableOrDisablePermissionBtn: true,
            permissionStr: "",
            selectedPermissions: [],
            permissionsObject: [
                {
                    name: this.$t("permissions.trackers_management"),
                    options: [
                        {
                            module: this.$t("home.trackers"),
                            view: {
                                value: TrackerPermissions.VIEW_TRACKER,
                                isChecked: false
                            },
                            create: {
                                value: TrackerPermissions.CREATE_TRACKER,
                                isChecked: false
                            },
                            edit: {
                                value: TrackerPermissions.EDIT_TRACKER,
                                isChecked: false
                            },
                            delete: {
                                value: TrackerPermissions.DELETE_TRACKER,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.trips_management"),
                    options: [
                        {
                            module: this.$t("headers.trips"),
                            view: {
                                value: TripPermissions.VIEW_TRIP,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_partner"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_PARTNER,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_PARTNER,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_PARTNER,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_PARTNER,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_freight"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_FREIGHT,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_FREIGHT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_FREIGHT,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_FREIGHT,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_carrier"),
                            view: {
                                value: CarrierPermissions.VIEW_CARRIER,
                                isChecked: false
                            },
                            create: {
                                value: CarrierPermissions.CREATE_CARRIER,
                                isChecked: false
                            },
                            edit: {
                                value: CarrierPermissions.EDIT_CARRIER,
                                isChecked: false
                            },
                            delete: {
                                value: CarrierPermissions.DELETE_CARRIER,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_freight_alert"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_FREIGHT_ALERT,
                                isChecked: false
                            },
                            create: {
                                value:
                                    TripPermissions.CREATE_TRIP_FREIGHT_ALERT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_FREIGHT_ALERT,
                                isChecked: false
                            },
                            delete: {
                                value:
                                    TripPermissions.DELETE_TRIP_FREIGHT_ALERT,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_freight_tracker"),
                            view: {
                                value:
                                    TripPermissions.VIEW_TRIP_FREIGHT_TRACKER,
                                isChecked: false
                            },
                            create: {
                                value:
                                    TripPermissions.CREATE_TRIP_FREIGHT_TRACKER,
                                isChecked: false
                            },
                            edit: {
                                value:
                                    TripPermissions.EDIT_TRIP_FREIGHT_TRACKER,
                                isChecked: false
                            },
                            delete: {
                                value:
                                    TripPermissions.DELETE_TRIP_FREIGHT_TRACKER,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_document"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_DOCUMENT,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_DOCUMENT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_DOCUMENT,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_DOCUMENT,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_sea_transport"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_SEA_TRANSPORT,
                                isChecked: false
                            },
                            create: {
                                value:
                                    TripPermissions.CREATE_TRIP_SEA_TRANSPORT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_SEA_TRANSPORT,
                                isChecked: false
                            },
                            delete: {
                                value:
                                    TripPermissions.DELETE_TRIP_SEA_TRANSPORT,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t(
                                "permissions.trip_freight_timeline"
                            ),
                            view: {
                                value:
                                    TripPermissions.VIEW_TRIP_FREIGHT_TIMELINE,
                                isChecked: false
                            },
                            create: {
                                value:
                                    TripPermissions.CREATE_TRIP_FREIGHT_TIMELINE,
                                isChecked: false
                            },
                            edit: {
                                value:
                                    TripPermissions.EDIT_TRIP_FREIGHT_TIMELINE,
                                isChecked: false
                            },
                            delete: {
                                value:
                                    TripPermissions.DELETE_TRIP_FREIGHT_TIMELINE,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("permissions.trip_report"),
                            view: {
                                value: TripPermissions.VIEW_TRIP_REPORT,
                                isChecked: false
                            },
                            create: {
                                value: TripPermissions.CREATE_TRIP_REPORT,
                                isChecked: false
                            },
                            edit: {
                                value: TripPermissions.EDIT_TRIP_REPORT,
                                isChecked: false
                            },
                            delete: {
                                value: TripPermissions.DELETE_TRIP_REPORT,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.user_group_management"),
                    options: [
                        {
                            module: this.$t("home.groups"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_USER_GROUP,
                                isChecked: false
                            },
                            create: {
                                value:
                                    TenantPermissions.CREATE_TENANT_USER_GROUP,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_USER_GROUP,
                                isChecked: false
                            },
                            delete: {
                                value:
                                    TenantPermissions.DELETE_TENANT_USER_GROUP,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("home.users"),
                            view: {
                                value: UserPermissions.VIEW_USER,
                                isChecked: false
                            },
                            create: {
                                value: UserPermissions.CREATE_USER,
                                isChecked: false
                            },
                            edit: {
                                value: UserPermissions.EDIT_USER,
                                isChecked: false
                            },
                            delete: {
                                value: UserPermissions.DELETE_USER,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("home.partners"),
                            view: {
                                value:
                                    TenantPermissions.VIEW_TENANT_USER_GROUP_PARTNER,
                                isChecked: false
                            },
                            create: {
                                value:
                                    TenantPermissions.CREATE_TENANT_USER_GROUP_PARTNER,
                                isChecked: false
                            },
                            edit: {
                                value:
                                    TenantPermissions.EDIT_TENANT_USER_GROUP_PARTNER,
                                isChecked: false
                            },
                            delete: {
                                value:
                                    TenantPermissions.DELETE_TENANT_USER_GROUP_PARTNER,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.alerts_management"),
                    options: [
                        {
                            module: this.$t("home.action_groups"),
                            view: {
                                value:
                                    TenantPermissions.VIEW_TENANT_ACTION_GROUP,
                                isChecked: false
                            },
                            create: {
                                value:
                                    TenantPermissions.CREATE_TENANT_ACTION_GROUP,
                                isChecked: false
                            },
                            edit: {
                                value:
                                    TenantPermissions.EDIT_TENANT_ACTION_GROUP,
                                isChecked: false
                            },
                            delete: {
                                value:
                                    TenantPermissions.DELETE_TENANT_ACTION_GROUP,
                                isChecked: false
                            }
                        },
                        {
                            module: this.$t("home.alert_rules"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_ALERT_RULE,
                                isChecked: false
                            },
                            create: {
                                value:
                                    TenantPermissions.CREATE_TENANT_ALERT_RULE,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_ALERT_RULE,
                                isChecked: false
                            },
                            delete: {
                                value:
                                    TenantPermissions.DELETE_TENANT_ALERT_RULE,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.location_management"),
                    options: [
                        {
                            module: this.$t("home.locations"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_LOCATION,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_LOCATION,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_LOCATION,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_LOCATION,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.settings_management"),
                    options: [
                        {
                            module: this.$t("home.settings"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_SETTINGS,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_SETTINGS,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_SETTINGS,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_SETTINGS,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.partner_management"),
                    options: [
                        {
                            module: this.$t("home.partners"),
                            view: {
                                value: TenantPermissions.VIEW_TENANT_PARTNER,
                                isChecked: false
                            },
                            create: {
                                value: TenantPermissions.CREATE_TENANT_PARTNER,
                                isChecked: false
                            },
                            edit: {
                                value: TenantPermissions.EDIT_TENANT_PARTNER,
                                isChecked: false
                            },
                            delete: {
                                value: TenantPermissions.DELETE_TENANT_PARTNER,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: ""
                },
                {
                    name: this.$t("permissions.booking_management"),
                    options: [
                        {
                            module: this.$t("permissions.qx_booking"),
                            view: {
                                value: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                                isChecked: false
                            },
                            create: {
                                value: BookingPermissions.CREATE_BOOKING_ORDER_QX,
                                isChecked: false
                            },
                            edit: {
                                value: BookingPermissions.EDIT_BOOKING_ORDER_QX,
                                isChecked: false
                            },
                            delete: {
                                value: BookingPermissions.DELETE_BOOKING_ORDER_QX,
                                isChecked: false
                            }
                        }
                    ],
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX
                }
            ]
        };
    },
    beforeCreate() {
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TrackerPermissions = TrackerPermissions;
        this.TripPermissions = TripPermissions;
        this.CarrierPermissions = CarrierPermissions;
        this.UserPermissions = UserPermissions;
        this.TenantPermissions = TenantPermissions;
    },
    computed: {
        isAllExpanded() {
            return this.panel.length === this.permissionsObject.length;
        },
        computedPermissionsObject(){
            return this.permissionsObject.filter(
                        c => c.managePermission === "" || this.hasOrganisationPermission(c?.managePermission)
                    );
        }
    },
    watch: {},
    mounted(){
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'group_permissions_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
         setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("group_permissions_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("group_permissions_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        updateSelectedPermissions(value) {
            this.permissions = [];
            this.permissions = [...value];

            this.enableOrDisablePermissionBtn = true;
            let selectedPermissionStr = this.permissions.sort().join("-");
            if (selectedPermissionStr !== this.permissionStr)
                this.enableOrDisablePermissionBtn = false;
        },
        updateUserGroupPermission() {
            // Initialize Loading :
            this.isLoadingUpdateGroupPermissions = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTenantUserGroupPermissions(
                            $input: UpdateTenantUserGroupPermissionsInput!
                        ) {
                            updateTenantUserGroupPermissions(input: $input) {
                                tenantStaffGroup {
                                    id
                                    permissions
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.group.node.id,
                            permissions: this.permissions
                        }
                    }
                })
                .then(() => {
                    // Reload permissions data//
                    this.$apollo.queries.groupPermissions.refresh();

                    const payload = {
                        color: "blue",
                        message: this.$t("alert_rules.permission_saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.isLoadingUpdateGroupPermissions = false;
                });
        },
        expand() {
            if (this.isAllExpanded) {
                this.panel = [];
            } else {
                this.panel = [
                    ...this.permissionsObject.map((item, index) => index)
                ];
            }
        }
    }
};
</script>
