<template>
    <v-btn
        class="menu-item outlineD"
        :class="{ 'is-active': isActive ? isActive() : null }"
        @click="action"
        :title="title"
        icon
    >
        <v-icon color="primary">{{ icon }}</v-icon>
    </v-btn>
</template>

<script>
//   import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'

export default {
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        action: {
            type: Function,
            required: true
        },
        isActive: {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            // remixiconUrl,
        };
    }
};
</script>

<style lang="scss">
.menu-item {
    background: transparent;
    border: none;
    border-radius: 0.4rem;
    color: #fff;
    cursor: pointer;
    height: 1.75rem;
    padding: 0.25rem;
    margin-right: 0.25rem;
    width: 1.75rem;

    svg {
        fill: currentColor;
        height: 100%;
        width: 100%;
    }

    &.is-active,
    &:hover {
        background-color: #808080;
    }
}

.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}
</style>
