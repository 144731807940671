const BookingPermissionsEnum = Object.freeze({
    //Booking Order Qx
    VIEW_BOOKING_ORDER_QX: "bookings.view_bookingorderqx",
    CREATE_BOOKING_ORDER_QX : "bookings.add_bookingorderqx",
    EDIT_BOOKING_ORDER_QX : "bookings.change_bookingorderqx",
    DELETE_BOOKING_ORDER_QX : "bookings.delete_bookingorderqx",
    MANAGE_BOOKING_ORDER_QX : "bookings.manage_bookingorderqx",

    //Booking Order Change Request Qx
    VIEW_BOOKING_ORDER_CHANGE_REQUEST_QX : "bookings.view_bookingorderchangerequestqx",
    CREATE_BOOKING_ORDER_CHANGE_REQUEST_QX : "bookings.add_bookingorderchangerequestqx",
    EDIT_BOOKING_ORDER_CHANGE_REQUEST_QX : "bookings.change_bookingorderchangerequestqx",
    DELETE_BOOKING_ORDER_CHANGE_REQUEST_QX : "bookings.delete_bookingorderchangerequestqx",
    MANAGE_BOOKING_ORDER_CHANGE_REQUEST_QX : "bookings.manage_bookingorderchangerequestqx",

    //Booking Transport Order Qx
    VIEW_BOOKING_TRANSPORT_ORDER_QX : "bookings.view_bookingtransportorderqx",
    CREATE_BOOKING_TRANSPORT_ORDER_QX : "bookings.add_bookingtransportorderqx",
    EDIT_BOOKING_TRANSPORT_ORDER_QX : "bookings.change_bookingtransportorderqx",
    DELETE_BOOKING_TRANSPORT_ORDER_QX : "bookings.delete_bookingtransportorderqx",
    MANAGE_BOOKING_TRANSPORT_ORDER_QX : "bookings.manage_bookingtransportorderqx",
});

export default BookingPermissionsEnum;