import * as types from "../types";
import { apolloClient, onLogin, onLogout } from "@/vue-apollo";

const ACCESS_TOKEN_KEY = "your_access_token_key";
const REFRESH_TOKEN_KEY = "your_refresh_token_key";

// Helper functions for localStorage operations
const localStoragePlugin = {
    setItem(key, item) {
        localStorage.setItem(key, JSON.stringify(item));
    },
    getItem(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    },
    removeItem(key) {
        localStorage.removeItem(key);
    }
};

// Initialize state from localStorage if available
const state = {
    createdGroup: localStoragePlugin.getItem("createdGroup") || null,
    cancel: localStoragePlugin.getItem("cancel") || null
};

const mutations = {
    setCreatedGroup(state, { type, group }) {
        if (type == "update") {
            state.createdGroup = group;
            state.cancel = null;
            localStoragePlugin.setItem("createdGroup", group);
            localStoragePlugin.removeItem("cancel"); // Clear 'cancel' in localStorage
        } else if (type == "cancel") {
            state.cancel = group;
            state.createdGroup = null;
            localStoragePlugin.setItem("cancel", group);
            localStoragePlugin.removeItem("createdGroup"); // Clear 'createdGroup' in localStorage
        }
    }
};

const actions = {
    updateCreatedGroup({ commit }, { type, group }) {
        commit("setCreatedGroup", { type, group });
    }
};

const getters = {
    getCreatedGroup: state => state.createdGroup,
    getCancelGroup: state => state.cancel
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
