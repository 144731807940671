<template>
    <v-container fluid class="pa-0">
        <v-row dense>
            <v-col cols="12">
                <h4>
                    {{ $t("alert_rules.at_what") }}
                    <b>{{ sensorType.text }}</b>
                    {{ $t("alert_rules.would_like") }}
                </h4>
            </v-col>
            <v-col cols="12" sm="12" md="5" lg="5" class="py-0 px-0">
                <v-select :items="sensorConditionChoices" v-model="sensorCondition" outlined dense class="body-2">
                </v-select>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3" class="py-0">
                <v-text-field
                    ref="min"
                    v-if="sensorType.unit != '%'"
                    v-model="value"
                    type="text"
                    :hint="sensorCondition == 'in' || sensorCondition == 'out' ? 'Min' : null"
                    :persistent-hint="sensorCondition == 'in' || sensorCondition == 'out'"
                    dense
                    outlined
                    :suffix="sensorType.unit == 'temperature' ? getTemperatureUnit() : sensorType.unit"
                    :rules="[rules.number, rules.greaterLimit]"
                    class=""
                ></v-text-field>

                <v-text-field
                    ref="HumidityMin"
                    v-if="sensorType.unit == '%'"
                    v-model="value"
                    type="text"
                    dense
                    :hint="sensorCondition == 'in' || sensorCondition == 'out' ? 'Min' : null"
                    :persistent-hint="sensorCondition == 'in' || sensorCondition == 'out'"
                    min="0"
                    max="100"
                    @input="validateDecimalInput(value)"
                    @change="humidityOnChange()"
                    outlined
                    :suffix="sensorType.unit == 'temperature' ? getTemperatureUnit() : sensorType.unit"
                    :rules="[rules.number, rules.greaterLimit]"
                ></v-text-field>
            </v-col>
            <v-col
                cols="6"
                sm="6"
                md="3"
                lg="3"
                class="py-0"
                v-if="sensorCondition == 'in' || sensorCondition == 'out'"
            >
                <v-text-field
                    v-if="sensorType.unit != '%' && (sensorCondition == 'in' || sensorCondition == 'out')"
                    v-model="value2"
                    type="text"
                    hint="Max"
                    persistent-hint
                    dense
                    outlined
                    :rules="[rules.number, rules.lowerLimit]"
                    @input="validateDecimalInput(value2)"
                    :suffix="sensorType.unit == 'temperature' ? getTemperatureUnit() : sensorType.unit"
                ></v-text-field>
                <v-text-field
                    v-if="sensorType.unit == '%' && (sensorCondition == 'in' || sensorCondition == 'out')"
                    v-model="value2"
                    type="text"
                    hint="Max"
                    persistent-hint
                    dense
                    min="0"
                    max="100"
                    @input="validateDecimalInput(value2)"
                    @change="humidityOnChange()"
                    outlined
                    :suffix="sensorType.unit == 'temperature' ? getTemperatureUnit() : sensorType.unit"
                    :rules="[rules.number, rules.lowerLimit]"
                ></v-text-field>
            </v-col>
            <v-col cols="1" v-if="sensorType.text == this.$t('general.light')">
                <v-tooltip bottom content-class="px-0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="grey darken-2" dark v-bind="attrs" v-on="on">
                            help_outline
                        </v-icon>
                    </template>
                    <v-row dense align="stretch" class="minWidth">
                        <!-- create a table -->
                        <v-col cols="12">
                            <table style="width:100%">
                                <colgroup>
                                    <col span="1" style="width: 50%;" />
                                    <col span="1" style="width: 50%;" />
                                </colgroup>
                                <thead style="text-align:center">
                                    <tr>
                                        <th>
                                            {{ $t("alert_rules.illuminance") }}
                                            (Lux)
                                        </th>
                                        <th>{{ $t("general.examples") }}</th>
                                    </tr>
                                </thead>
                                <tbody style="text-align:center">
                                    <tr>
                                        <td>0 - 10</td>
                                        <td>
                                            {{ $t("alert_rules.pitch_black") }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11 - 50</td>
                                        <td>
                                            {{ $t("alert_rules.very_dark") }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>51 - 200</td>
                                        <td>
                                            {{ $t("alert_rules.indoor_home") }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>201 - 400</td>
                                        <td>
                                            {{ $t("alert_rules.indoor_office") }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1000 - 5000</td>
                                        <td>
                                            {{ $t("alert_rules.overcast_daylight") }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10000 - 25000</td>
                                        <td>
                                            {{ $t("alert_rules.ambient_daylight") }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>> 32000</td>
                                        <td>
                                            {{ $t("alert_rules.direct_daylight") }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-col>
                        <v-col cols="auto">
                            <div class="caption ml-1">* {{ $t("alert_rules.default_threshold") }}</div>
                        </v-col>
                    </v-row>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import helper from "@/utils/helper";
export default {
    props: {
        type: {
            type: String,
            required: true
        },
        condition: {
            type: String
        },
        sensorValue: {
            type: Number
        },
        sensorValue2: {
            type: Number
        }
    },
    data() {
        return {
            sensorTypeChoices: [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    unit: "temperature"
                },
                { text: this.$t("general.light"), value: "light", unit: "Lux" },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    unit: "%"
                },
                { text: this.$t("general.shock"), value: "shock", unit: "G" },
                {
                    text: this.$t("general.pressure"),
                    value: "pressure",
                    unit: "Psi"
                }

                // { text: "CO2", value: "co2" }
            ],
            sensorConditionChoices: [
                { text: this.$t("general.above"), value: "gt" },
                { text: this.$t("general.between"), value: "in" },
                { text: this.$t("general.out_of_range"), value: "out" },
                { text: this.$t("general.below"), value: "lt" }
            ],
            initialLoad: true,
            value2: null,
            validate: true,
            rules: {
                number: value => {
                    if (isNaN(value)) return "Please enter a valid number";
                    return true;
                },
                lowerLimit: inputValue => {
                    let reqRule = this.sensorCondition == "in" || this.sensorCondition == "out";
                    if (reqRule && (inputValue == null || inputValue === ""))
                        return "Input value must be greater than the minimum value";
                    if (reqRule && inputValue <= this.value)
                        return "Input value must be greater than the minimum value";
                    return true;
                },
                greaterLimit: inputValue => {
                    let reqRule = this.sensorCondition == "in" || this.sensorCondition == "out";
                    if (reqRule && (inputValue == null || inputValue === ""))
                        return "Input value must be less than the maximum value";
                    if (reqRule && inputValue >= this.value2) return "Input value must be less than the maximum value";
                    return true;
                }
            }
        };
    },
    computed: {
        sensorType() {
            return this.sensorTypeChoices.find(sensorTypeChoice => sensorTypeChoice.value === this.type);
        },
        value: {
            get() {
                let getTemperatureUnit = helper.getTemperatureSymbol() || "ºC";

                if (this.sensorValue == null) return 0;
                if (this.sensorType.value == "temperature" && this.initialLoad)
                    if (getTemperatureUnit == "ºF") {
                        this.changeInitalLoad();
                        return helper.celsiusToFahrenheit(this.sensorValue);
                    } else return this.sensorValue;
                else if (this.sensorType.value == "humidity") {
                    if (this.sensorValue < 0) return 0;
                    else if (this.sensorValue > 100) return 100;
                    else return this.sensorValue;
                } else return this.sensorValue;
            },
            set(val) {
                if (val === "" || val === null) {
                    this.updateSensor(0, this.sensorCondition);
                } else {
                    let _sensorNumber = Number(val);
                    if (!isNaN(_sensorNumber)) {
                        this.updateSensor(_sensorNumber, this.sensorCondition);
                    } else {
                        this.value = ""; // Handle invalid conversion
                    }
                }
            }
        },
        sensorCondition: {
            get() {
                return this.condition == null ? "gt" : this.condition;
            },
            set(val) {
                this.updateSensor(this.value, val);
            }
        }
    },
    created() {
        this.getTemperatureUnit = helper.getTemperatureSymbol;
        this.value2 = this.sensorValue2 == null ? 0 : this.sensorValue2;
    },
    mounted() {},
    watch: {
        sensorCondition(val) {
            this.reValidate(val);
        },
        value2: {
            handler(val) {
                let _sensorNumber = !isNaN(val) && val !== "" ? Number(val) : 0;
                this.value2 = _sensorNumber;
                this.updateSensor(this.value, this.sensorCondition);
            },
            deep: true
        }
    },
    methods: {
        validateDecimalInput(value) {
            // Validate if the input is a valid number with decimals
            const regex = /^-?[0-9]*\.?[0-9]*$/;
            if (!regex.test(value)) {
                // Handle invalid input (optional)
                value = ""; // Clear input or handle validation error
            }

            return value;
        },
        updateSensor(val, condition) {
            if (this.sensorType.value == "temperature")
                if (this.getTemperatureUnit() == "ºF") val = helper.fahrenheitToCelsius(val);

            if (!this.value2) {
                this.value2 = 0;
            }

            //validate the input
            this.reValidate(condition);

            if (this.value2 != null) {
                if (this.sensorType.value == "temperature")
                    if (this.getTemperatureUnit() == "ºF") this.value2 = helper.fahrenheitToCelsius(this.value2);
                if (this.sensorType.value == "humidity") {
                    if (this.value2 < 0) this.value2 = 0;
                    else if (this.value2 > 100) this.value2 = 100;
                }
                if (condition == "out" || condition == "in") {
                    this.$emit("updateSensor", {
                        type: this.type,
                        condition: condition,
                        value: val,
                        value2: this.value2
                    });
                } else {
                    this.$emit("updateSensor", {
                        type: this.type,
                        condition: condition,
                        value: val
                    });
                }
            } else {
                this.$emit("updateSensor", {
                    type: this.type,
                    condition: condition,
                    value: val
                });
            }
        },
        changeInitalLoad() {
            this.initialLoad = false;
        },
        humidityOnChange() {
            if (this.value > 100) this.value = 100;
            if (this.value < 0) this.value = 0;
        },
        reset() {
            this.value = 0;
            this.value2 = null;
        },
        reValidate(condition) {
            if (condition === "in" || condition === "out") {
                this.$nextTick(() => {
                    if (this.$refs.max) this.$refs.max.validate();
                    if (this.$refs.HumidityMax) this.$refs.HumidityMax.validate();
                    if (this.$refs.min) this.$refs.min.validate();
                    if (this.$refs.HumidityMin) this.$refs.HumidityMin.validate();
                });
            } else {
                this.$nextTick(() => {
                    if (this.$refs.min) this.$refs.min.validate();
                    if (this.$refs.HumidityMin) this.$refs.HumidityMin.validate();
                });
            }
        }
    }
};
</script>
<style scoped>
table,
th,
td {
    border: 1px solid white;
}
.minWidth {
    width: 390px;
}
</style>
