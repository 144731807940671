<template>
    <v-dialog v-model="openDeleteDialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="primary">
                <v-icon color="white" class="mr-2">warning</v-icon>
                <div class="white--text">
                    {{ $t("users.delete_tenant") }}
                </div>
            </v-card-title>
            <v-card-text class="pt-2">
                <div>
                    <h4>
                        {{ $t("users.sure_tenant") }}
                    </h4>
                </div>
            </v-card-text>
            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn text rounded @click="$emit('closeDeleteTenantDialog')">{{
                    $t("general.cancel")
                }}</v-btn>
                <v-btn
                    color="primary"
                    rounded
                    :loading="isDeleting"
                    @click="deleteTenant"
                    >{{ $t("general.delete") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "delete-dialog",
    props: ["openDeleteDialog", "tenantId"],
    data: function() {
        return {
            show: false,
            isDeleting: false
        };
    },
    watch: {},
    methods: {
        deleteTenant() {
            this.isDeleting = true;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTenant($input: DeleteTenantInput!) {
                            deleteTenant(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.tenantId
                        }
                    }
                })
                .then(() => {})
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isDeleting = false;
                    this.$emit("refreshDataTable");
                    this.$emit("closeDeleteTenantDialog");
                });
        }
    }
};
</script>
