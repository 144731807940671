import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-y-auto",style:(("height: " + _vm.heightScreen + "px;"))},[_c(VSheet,{staticClass:"pb-2",attrs:{"color":"transparent"}},[_c(VSpacer),_c(VBtn,{attrs:{"small":"","outlined":"","color":"primary"},on:{"click":_vm.expand}},[_vm._v(" "+_vm._s(_vm.isAllExpanded ? "- " + _vm.$t("general.collapse") : "+ " + _vm.$t("general.expand"))+" ")])],1),_c(VExpansionPanels,{staticClass:"mb-5",attrs:{"multiple":"","focusable":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.selectedFilters),function(item,index){return _c(VExpansionPanel,{key:index},[_c(VExpansionPanelHeader,[_c('h5',[_vm._v(_vm._s(item.name))])]),_c(VExpansionPanelContent,{staticClass:"px-0"},[_c(VLazy,[(item.code === 'users')?_c('GroupUsersTab',{attrs:{"group":_vm.group}}):_vm._e(),(item.code === 'permissions')?_c('GroupPermissionsTab',{attrs:{"group":_vm.group}}):_vm._e(),(item.code === 'partners')?_c('GroupPartnersTab',{attrs:{"group":_vm.group}}):_vm._e()],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }