import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"loading":_vm.loading,"items":_vm.consignee,"label":_vm.label,"dense":"","multiple":"","hide-selected":"","hide-details":"","outlined":_vm.outLined,"clearable":"","item-text":"node.name","item-value":"node.id","no-data-text":_vm.loading
                ? this.$t('loading.loading1')
                    : this.$t('loading.no_available')},on:{"focus":function($event){_vm.openPartnersList = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var attrs = ref.attrs;
                    var item = ref.item;
return [_c(VChip,_vm._b({attrs:{"close":"","small":""},on:{"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.node.name)+" ")])]}}]),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }