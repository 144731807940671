<template>
    <div v-if="!canAccess">
        <NoPermission></NoPermission>
    </div>
    <div class="px-5" v-else>
        <v-card class="mt-2 mb-5 mt-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :items="alertRules.edges"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    loader-height="2"
                    hide-default-footer
                    :mobile-breakpoint="0"
                    :no-data-text="$t('loading.no_data')"
                    disable-sort
                    :loading="$apollo.queries.alertRules.loading"
                >
                    <!-- Enabled -->
                    <template
                        v-slot:item.node.tenantAlertRule.isEnabled="{ item }"
                    >
                        <v-icon
                            v-if="item.node.tenantAlertRule.isEnabled"
                            color="success"
                            >check_circle</v-icon
                        >
                        <v-icon color="grey lighten-2" v-else
                            >check_circle</v-icon
                        >
                    </template>

                    <!-- Notify Partner -->
                    <template
                        v-slot:item.node.tenantAlertRule.shouldNotifyPartner="{
                            item
                        }"
                    >
                        <v-icon
                            v-if="item.node.tenantAlertRule.shouldNotifyPartner"
                            color="success"
                            >check_circle</v-icon
                        >
                        <v-icon color="grey lighten-2" v-else
                            >check_circle</v-icon
                        >
                    </template>

                    <!-- Notify every record -->
                    <template
                        v-slot:item.node.tenantAlertRule.shouldNotifyForEveryRecord="{
                            item
                        }"
                    >
                        <v-icon
                            v-if="
                                item.node.tenantAlertRule
                                    .shouldNotifyForEveryRecord
                            "
                            color="success"
                            >check_circle</v-icon
                        >
                        <v-icon color="grey lighten-2" v-else
                            >check_circle</v-icon
                        >
                    </template>
                </v-data-table>
            </v-card-text>
            <!-- infinit loading trigger -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.alertRules.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    {{ $t("loading.load_more") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import { TenantPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import Config from "@/utils/config.json";

export default {
    name: "ActionGroupUsers",
    components: {
        NoPermission
    },
    props: {
        actionGroup: {
            type: Object,
            required: true
        }
    },
    apollo: {
        alertRules: {
            query: gql`
                query TenantAlertRuleActionGroups(
                    $tenantActionGroupId: ID
                    $first: Int
                    $after: String
                    $last: Int
                    $before: String
                ) {
                    tenantAlertRuleActionGroups(
                        tenantActionGroupId: $tenantActionGroupId
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                tenantAlertRule {
                                    id
                                    description
                                    isEnabled
                                    name
                                    shouldNotifyForEveryRecord
                                    shouldNotifyPartner
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantActionGroupId: this.actionGroup.id,
                    first: 10
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantAlertRuleActionGroups;
            },
            skip: false
        }
    },
    data() {
        return {
            alertRules: { edges: [] },
            shouldLoadMore: false,
            isInitialLoad: true,
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
            headerChoices: [
                {
                    text: this.$t("headers.name"),
                    align: "left",
                    value: "node.tenantAlertRule.name",
                    sortable: false
                },
                {
                    text: this.$t("general.description"),
                    align: "left",
                    value: "node.tenantAlertRule.description",
                    sortable: false
                },
                {
                    text: this.$t("headers.notify_partner") + "(s)",
                    align: "center",
                    value: "node.tenantAlertRule.shouldNotifyPartner",
                    sortable: false
                },
                {
                    text: this.$t("headers.notify_record"),
                    align: "center",
                    value: "node.tenantAlertRule.shouldNotifyForEveryRecord",
                    sortable: false
                },
                {
                    text: this.$t("headers.enabled"),
                    align: "center",
                    value: "node.tenantAlertRule.isEnabled",
                    sortable: false
                }
            ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        this.loadMore();
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'action_group_alerts_svw',
        });
        this.setUserProperties();
    },
    watch: {},
    computed: {
        canAccess() {
            return this.hasPermission(
                TenantPermissions.VIEW_TENANT_ACTION_GROUP
            );
        },
        // infinite
        hasMoreData() {
            return this.alertRules?.pageInfo?.hasNextPage;
        }
    },
    methods: {
      setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("action_group_alerts_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("action_group_alerts_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.alertRules?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.alertRules.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.alertRules?.pageInfo?.hasNextPage) {
                this.$apollo.queries.alertRules.fetchMore({
                    variables: {
                        after: this.alertRules.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
