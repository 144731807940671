<template>
    <div>
        <v-card elevation="5" outlined v-resize="onResize">
            <v-card-text class="pb-3 pr-3">
                <div class="d-flex justify-end">
                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    @click="exportDataDialog ? (exportDataDialog = false) : (exportDataDialog = true)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>download</v-icon>
                                    <!-- {{ $t("general.download") }} -->
                                </v-btn>
                            </template>
                            <span>Download loads for tomorrow</span>
                        </v-tooltip>
                        <v-btn icon>
                            <v-icon @mouseenter="startScrolling('left')" @mouseleave="stopScrolling">arrow_back</v-icon>
                        </v-btn>
                        <v-btn icon>
                            <v-icon @mouseenter="startScrolling('right')" @mouseleave="stopScrolling"
                                >arrow_forward</v-icon
                            >
                        </v-btn>
                    </div>
                    <!-- filter menu -->
                    <FilterMenu
                        :choices="headerChoices"
                        title="Customize"
                        description="Select columns to display"
                        :open-on-hover="false"
                        height="500px"
                        width="250px"
                        v-on:changed="onFilterMenuChanged"
                    >
                    </FilterMenu>
                </div>
                <v-data-table
                    :headers="dynamicHeaders"
                    :items="bookingOrderQxs.edges"
                    item-key="node.id"
                    loading-text="Loading..."
                    :loading="$apollo.queries.bookingOrderQxs.loading"
                    hide-default-footer
                    disable-pagination
                    disable-sort
                    loader-height="2"
                    :mobile-breakpoint="0"
                    no-data-text="No data"
                    fixed-header
                    :height="tableHeight"
                    @click:row="item => this.$emit('click:row', item)"
                    class="row-pointer caption"
                >
                    <template v-slot:item.node.exporterReferenceNumbers="{ item, index }">
                        <div v-html="getExportRefNumbers(item)"></div>
                    </template>
                    <template v-slot:item.node.etd="{ item }">
                        <span v-if="item.node.etd">{{ qxFormatDateTime(item.node.etd) }}</span>
                    </template>
                    <template v-slot:item.node.eta="{ item }">
                        <span v-if="item.node.eta">{{ qxFormatDateTime(item.node.eta) }}</span>
                    </template>
                    <template v-slot:item.node.orderDate="{ item }">
                        <span v-if="item.node.orderDate">{{ qxFormatDateTime(item.node.orderDate) }}</span>
                    </template>
                    <template v-slot:item.node.orderStatus="{ item }">
                        <span v-if="item.node.orderStatus">
                            <v-chip>{{ getStatus("orderStatus", item.node.orderStatus) }}</v-chip>
                            <!-- <v-chip>{{ item.node.orderStatus }}</v-chip> -->
                        </span>
                    </template>
                    <template v-slot:item.node.latestChangeRequest.status="{ item }">
                        <span v-if="item.node.latestChangeRequest">
                            <v-chip>{{
                                getStatus("changeRequestStatus", item.node.latestChangeRequest.status)
                            }}</v-chip>
                            <!-- <v-chip>{{ item.node.latestChangeRequest.status }}</v-chip> -->
                        </span>
                    </template>
                    <template v-slot:item.numberOfPalletsPerShipment="{ item }">
                        <div
                            v-for="transportOrder in item.node.bookingTransportOrderQxSet.edges"
                            :key="transportOrder.node.id"
                        >
                            <span v-if="transportOrder.node.numberOfPalletsPerShipment">
                                {{ transportOrder.node.numberOfPalletsPerShipment }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:item.loadPointOne="{ item }">
                        <div v-for="loadPoint in item.node.bookingTransportOrderQxSet.edges" :key="loadPoint.node.id">
                            <span v-if="loadPoint.node.loadPoint1">
                                {{ loadPoint.node.loadPoint1 }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:item.loadPointTwo="{ item }">
                        <div v-for="loadPoint in item.node.bookingTransportOrderQxSet.edges" :key="loadPoint.node.id">
                            <span v-if="loadPoint.node.loadPoint2">
                                {{ loadPoint.node.loadPoint2 }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:item.loadDate="{ item }">
                        <div v-for="loadPoint in item.node.bookingTransportOrderQxSet.edges" :key="loadPoint.node.id">
                            <span v-if="loadPoint.node.loadDate">
                                {{ qxFormatDateTime(loadPoint.node.loadDate) }}
                            </span>
                        </div>
                    </template>
                    <!-- infinit loading trigger -->
                    <template slot="body.append">
                        <tr>
                            <td :colspan="2" :style="centralizeLoadBtnClass">
                                <v-btn
                                    v-if="hasMoreData"
                                    v-intersect="onLoadMoreTriggerIntersect"
                                    :disabled="!hasMoreData"
                                    :loading="$apollo.queries.bookingOrderQxs.loading"
                                    plain
                                    class="mb-5"
                                    @click="loadMore"
                                >
                                    Load more
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="exportDataDialog" max-width="500" persistent>
            <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="pa-2 mx-1"
                    label
                    rounded
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="
                        selectedTrackerId == null || selectedFreightId == null
                    "
                >
                    {{ $t("general.download") }}
                    <v-icon color="white">download</v-icon>
                </v-btn>
            </template> -->

            <v-card>
                <v-card-title class="primary white--text">
                    <v-icon color="white" class="mr-2">download</v-icon>
                    {{ $t("trips.download_timeline") }}
                </v-card-title>
                <v-card-text>
                    <template>
                        <v-row class="mt-5 align-center">
                            <v-col cols="4">
                                <span class="ml-5 subtitle-1">{{ $t("trips.file_format") }}:</span>
                            </v-col>
                            <v-col cols="6"
                                ><v-select auto-select-first :items="fileFormatsAvailable" v-model="fileFormat">
                                </v-select
                            ></v-col>
                        </v-row>
                    </template>
                </v-card-text>

                <v-card-actions class="d-flex justify-end pb-5 pr-5">
                    <v-btn text @click="closeAndResetInput" rounded :disabled="isLoadingFile">{{
                        $t("general.close")
                    }}</v-btn>
                    <v-btn
                        color="primary"
                        text
                        :loading="isLoadingFile"
                        @click="submitExportData"
                        rounded
                        outlined
                        dense
                    >
                        {{ $t("general.download") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";
//import { BookingPermissions } from "@/core/enum";
import { write, utils } from "xlsx";

export default {
    props: {
        bookingFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    components: { FilterMenu },
    apollo: {
        bookingOrderQxs: {
            query: gql`
                query BookingOrderQxs(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $trip: ID
                    $polId: String
                    $pol: String
                    $podId: String
                    $pod: String
                    $voyageNoIcontains: String
                    $vesselNameId: String
                    $vesselNameIcontains: String
                    $customerOrderNumberIcontains: String
                    $customerId: String
                    $customerIcontains: String
                    $exporterIcontains: String
                    $exporterId: String
                    $receiverIcontains: String
                    $receiverId: String
                    $contractNumberIcontains: String
                    $transportType: String
                    $finalDestinationIcontains: String
                    $finalDestinationId: String
                    $orderStatus: String
                    $stackStatus: String
                    $steri: String
                    $incoTerm: String
                    $commodityId: String
                    $containerTypeId: String
                    $containerType: String
                    $orderByIcontains: String
                    $orderDateLte: DateTime
                    $orderDateGte: DateTime
                    $loadDateLte: DateTime
                    $loadDateGte: DateTime
                    $shippingLineId: String
                    $agentCodeId: String
                    $containerNumberIcontains: String
                    $ctoNumberIcontains: String
                    $trackerNumberIcontains: String
                    $sealNumberIcontains: String
                    $bookingReferenceIcontains: String
                    $exporterReferenceNumberIcontains: String
                ) {
                    bookingOrderQxs(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        trip: $trip
                        polId: $polId
                        pol: $pol
                        podId: $podId
                        pod: $pod
                        voyageNo_Icontains: $voyageNoIcontains
                        vesselNameId: $vesselNameId
                        vesselName_Icontains: $vesselNameIcontains
                        contractNumber_Icontains: $contractNumberIcontains
                        customerOrderNumber_Icontains: $customerOrderNumberIcontains
                        customerId: $customerId
                        customer_Icontains: $customerIcontains
                        exporterId: $exporterId
                        exporter_Icontains: $exporterIcontains
                        receiverId: $receiverId
                        receiver_Icontains: $receiverIcontains
                        transportType: $transportType
                        finalDestination_Icontains: $finalDestinationIcontains
                        finalDestinationId: $finalDestinationId
                        orderStatus: $orderStatus
                        orderDate_Lte: $orderDateLte
                        orderDate_Gte: $orderDateGte
                        stackStatus: $stackStatus
                        steri: $steri
                        incoTerm: $incoTerm
                        commodityId: $commodityId
                        containerTypeId: $containerTypeId
                        containerType: $containerType
                        orderBy_Icontains: $orderByIcontains
                        shippingLineId: $shippingLineId
                        agentCodeId: $agentCodeId
                        bookingTransportOrderQxSet_ContainerNumber_Icontains: $containerNumberIcontains
                        bookingTransportOrderQxSet_CtoNumber_Icontains: $ctoNumberIcontains
                        bookingTransportOrderQxSet_TrackerNumber_Icontains: $trackerNumberIcontains
                        bookingTransportOrderQxSet_SealNumber_Icontains: $sealNumberIcontains
                        bookingTransportOrderQxSet_BookingReference_Icontains: $bookingReferenceIcontains
                        bookingTransportOrderQxSet_ExporterReferenceNumber_Icontains: $exporterReferenceNumberIcontains
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                contractNumber
                                customerOrderNumber
                                importPermitNumber
                                commodity
                                containerType
                                customer
                                exporter
                                receiver
                                containerInstructions
                                finalDestination
                                freightPayLocation
                                incoTerm
                                logisticContact
                                approxGrossWeight
                                numberOfProbes
                                numberOfShipments
                                orderBy
                                orderDate
                                orderStatus
                                pod
                                pol
                                ppecbTempCode
                                shippedOnBoard
                                shippingLine
                                shippingLineComment
                                shippingLineStatus
                                stackClose
                                stackOpen
                                stackStatus
                                steri
                                steriProtocol
                                transportType
                                vents
                                vesselName
                                voyageNo
                                weighBridge
                                eta
                                etd
                                commodityId
                                containerTypeId
                                customerId
                                exporterId
                                finalDestinationId
                                podId
                                polId
                                ppecbTempCodeId
                                receiverId
                                shippingLineId
                                steriId
                                steriProtocolId
                                ventsId
                                vesselNameId
                                agentCode
                                agentCodeId
                                logisticContactId
                                weighBridgeId
                                vesselBookingId
                                vesselBookingPolId
                                vesselBookingPodId
                                imo
                                containerNumbers
                                ctoNumbers
                                trackerNumbers
                                sealNumbers
                                latestChangeRequest {
                                    id
                                    status
                                }
                                tenant {
                                    id
                                    name
                                }
                                tenantPartner {
                                    id
                                    name
                                }
                                trip {
                                    id
                                    referenceNumber
                                }
                                bookingTransportOrderQxSet(loadDate_Lte: $loadDateLte, loadDate_Gte: $loadDateGte) {
                                    edges {
                                        node {
                                            id
                                            loadPoint1
                                            loadPoint2
                                            loadDate
                                            containerNumber
                                            ctoNumber
                                            sealNumber
                                            trackerNumber
                                            plugInDepot
                                            transportComment
                                            depotDateIn
                                            depotDateOut
                                            transportInstruction
                                            orderStatus
                                            solasMethod
                                            tempRecorderType
                                            gensetIsRequired
                                            numberOfPalletsPerShipment
                                            bookingReference
                                            emptyLoadPoint
                                            emptyLoadPointId
                                            exporterReferenceNumber
                                        }
                                    }
                                }
                                bookingOrderChangeRequestQxSet {
                                    edges {
                                        node {
                                            id
                                            content
                                            reason
                                            status
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    ...this.bookingFilter
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.bookingOrderQxs,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
                this.loadHeaders();
            },
            debounce: 800
        }
    },
    created() {
        this.qxFormatDateTime = helper.qxFormatDateTime;
        this.formatDateTime = helper.formatDateTime;
    },
    data() {
        return {
            shouldLoadMore: false,
            isInitialLoad: true,
            selectedHeaders: ["node.referenceNumber", "node.status"],
            bookingOrderQxs: { edges: [] },
            tableHeight: 800,
            headerChoices: [],
            defaultHeaderChoices: [
                {
                    // for filter menu
                    code: "node.bookingReferenceNumbers",
                    name: "Booking Reference",
                    tag: "bookings.booking_reference",
                    enabled: true,
                    // for table header
                    text: "Booking Reference",
                    align: "left",
                    value: "node.bookingReferenceNumbers"
                },
                // {
                //     // for filter menu
                //     code: "node.customerOrderNumber",
                //     name: "Order No.",
                //     tag: "bookings.order_no",
                //     enabled: true,
                //     // for table header
                //     text: "Order No.",
                //     align: "left",
                //     value: "node.customerOrderNumber"
                // },
                {
                    // for filter menu
                    code: "exporterReferenceNumbers",
                    name: "Exporter Reference No.",
                    tag: "bookings.exporter_ref_no",
                    enabled: true,
                    // for table header
                    text: "Exporter Reference No.",
                    align: "left",
                    value: "node.exporterReferenceNumbers"
                },
                {
                    // for filter menu
                    code: "node.customer",
                    name: "Customer",
                    tag: "bookings.customer",
                    enabled: true,
                    // for table header
                    text: "Customer",
                    align: "left",
                    value: "node.customer"
                },
                {
                    // for filter menu
                    code: "node.exporter",
                    name: "Exporter",
                    tag: "bookings.exporter",
                    enabled: true,
                    // for table header
                    text: "Exporter",
                    align: "left",
                    value: "node.exporter"
                },
                {
                    // for filter menu
                    code: "node.receiver",
                    name: "Receiver",
                    tag: "bookings.receiver",
                    enabled: true,
                    // for table header
                    text: "Receiver",
                    align: "left",
                    value: "node.receiver"
                },
                {
                    // for filter menu
                    code: "vessel",
                    name: "Vessel",
                    tag: "bookings.vessel",
                    enabled: true,
                    // for table header
                    text: "Vessel",
                    align: "left",
                    value: "node.vesselName"
                },
                {
                    // for filter menu
                    code: "node.voyageNo",
                    name: "Voyage No.",
                    tag: "bookings.voyage",
                    enabled: true,
                    // for table header
                    text: "Voyage No.",
                    align: "left",
                    value: "node.voyageNo"
                },
                {
                    // for filter menu
                    code: "node.commodity",
                    name: "commodity",
                    tag: "bookings.commodity",
                    enabled: true,
                    // for table header
                    text: "Commodity",
                    align: "left",
                    value: "node.commodity"
                },
                {
                    // for filter menu
                    code: "node.stackStatus",
                    name: "Stack Status",
                    tag: "bookings.stack_status",
                    enabled: true,
                    // for table header
                    text: "Stack Status",
                    align: "left",
                    value: "node.stackStatus"
                },
                {
                    // for filter menu
                    code: "node.orderStatus",
                    name: "Order Status",
                    tag: "bookings.order_status",
                    enabled: true,
                    // for table header
                    text: "Order Status",
                    align: "left",
                    value: "node.orderStatus"
                },
                {
                    // for filter menu
                    code: "node.latestChangeRequest.status",
                    name: "Change Request Status",
                    tag: "bookings.change_request_status",
                    enabled: true,
                    // for table header
                    text: "Change Request Status",
                    align: "left",
                    value: "node.latestChangeRequest.status"
                },
                {
                    // for filter menu
                    code: "node.shippingLine",
                    name: "Shipping Line",
                    tag: "bookings.shipping_line",
                    enabled: true,
                    // for table header
                    text: "Shipping Line",
                    align: "left",
                    value: "node.shippingLine"
                },
                {
                    // for filter menu
                    code: "node.shippingLineStatus",
                    name: "Shipping Line Status",
                    tag: "bookings.shipping_line_status",
                    enabled: true,
                    // for table header
                    text: "Shipping Line Status",
                    align: "left",
                    value: "node.shippingLineStatus"
                },
                {
                    // for filter menu
                    code: "node.orderDate",
                    name: "Order Date",
                    tag: "bookings.order_date",
                    enabled: true,
                    // for table header
                    text: "Order Date",
                    align: "left",
                    value: "node.orderDate"
                },
                {
                    // for filter menu
                    code: "node.finalDestination",
                    name: "Final Destination",
                    tag: "bookings.final_destination",
                    enabled: true,
                    // for table header
                    text: "Final Destination",
                    align: "left",
                    value: "node.finalDestination"
                },
                {
                    // for filter menu
                    code: "node.contractNumber",
                    name: "Contract No.",
                    tag: "bookings.contract_no",
                    enabled: true,
                    // for table header
                    text: "Contract No.",
                    align: "left",
                    value: "node.contractNumber"
                },
                {
                    // for filter menu
                    code: "node.containerType",
                    name: "Container Type",
                    tag: "bookings.container_type",
                    enabled: true,
                    // for table header
                    text: "Container Type",
                    align: "left",
                    value: "node.containerType"
                },
                {
                    // for filter menu
                    code: "node.steri",
                    name: "Steri",
                    tag: "bookings.steri",
                    enabled: true,
                    // for table header
                    text: "Steri",
                    align: "left",
                    value: "node.steri"
                },
                {
                    // for filter menu
                    code: "node.incoTerm",
                    name: "Inco Term",
                    tag: "bookings.inco_term",
                    enabled: true,
                    // for table header
                    text: "Inco Term",
                    align: "left",
                    value: "node.incoTerm"
                },
                {
                    // for filter menu
                    code: "node.orderBy",
                    name: "Order By",
                    tag: "bookings.order_by",
                    enabled: true,
                    // for table header
                    text: "Order By",
                    align: "left",
                    value: "node.orderBy"
                },
                {
                    // for filter menu
                    code: "node.transportType",
                    name: "Transport Type",
                    tag: "bookings.transport_type",
                    enabled: true,
                    // for table header
                    text: "Transport Type",
                    align: "left",
                    value: "node.transportType"
                },
                {
                    // for filter menu
                    code: "POL",
                    name: "POL",
                    tag: "bookings.pol",
                    enabled: false,
                    // for table header
                    text: "POL",
                    align: "left",
                    value: "node.pol"
                },
                {
                    // for filter menu
                    code: "POD",
                    name: "POD",
                    tag: "bookings.pod",
                    enabled: false,
                    // for table header
                    text: "POD",
                    align: "left",
                    value: "node.pod"
                },
                {
                    // for filter menu
                    code: "node.numberOfProbes",
                    name: "#Probes",
                    tag: "bookings.number_of_probes",
                    enabled: true,
                    // for table header
                    text: "#Probes",
                    align: "right",
                    value: "node.numberOfProbes"
                },
                {
                    // for filter menu
                    code: "node.numberOfShipments",
                    name: "#Shipments",
                    tag: "bookings.number_of_shipments",
                    enabled: true,
                    // for table header
                    text: "#Shipments",
                    align: "right",
                    value: "node.numberOfShipments"
                },
                {
                    // for filter menu
                    code: "numberOfPalletsPerShipment",
                    name: "#Pallets per Shipment",
                    tag: "bookings.pallets_per_shipment",
                    enabled: true,
                    // for table header
                    text: "#Pallets per Shipment",
                    align: "right",
                    value: "numberOfPalletsPerShipment"
                },
                {
                    // for filter menu
                    code: "node.loadPoint1",
                    name: "Load Point 1",
                    tag: "bookings.load_point_1",
                    enabled: true,
                    // for table header
                    text: "Load Point 1",
                    align: "right",
                    value: "loadPointOne"
                },
                {
                    // for filter menu
                    code: "loadPoint2",
                    name: "Load Point 2",
                    tag: "bookings.load_point_2",
                    enabled: true,
                    // for table header
                    text: "Load Point 2",
                    align: "right",
                    value: "loadPointTwo"
                },
                {
                    // for filter menu
                    code: "node.loadDate",
                    name: "Load Date",
                    tag: "bookings.load_date",
                    enabled: true,
                    // for table header
                    text: "Load Date",
                    align: "right",
                    value: "loadDate"
                },
                {
                    // for filter menu
                    code: "node.etd",
                    name: "ETD",
                    tag: "bookings.etd",
                    enabled: true,
                    // for table header
                    text: "ETD",
                    align: "right",
                    value: "node.etd"
                },
                {
                    // for filter menu
                    code: "node.eta",
                    name: "ETA",
                    tag: "bookings.eta",
                    enabled: true,
                    // for table header
                    text: "ETA",
                    align: "right",
                    value: "node.eta"
                },
                {
                    // for filter menu
                    code: "node.containerNumbers",
                    name: "Container Numbers",
                    tag: "bookings.container_number",
                    enabled: true,
                    // for table header
                    text: "Container Numbers",
                    align: "right",
                    value: "node.containerNumbers"
                },
                {
                    // for filter menu
                    code: "node.ctoNumbers",
                    name: "CTO Numbers",
                    tag: "bookings.cto_number",
                    enabled: true,
                    // for table header
                    text: "CTO Numbers",
                    align: "right",
                    value: "node.ctoNumbers"
                },
                {
                    // for filter menu
                    code: "node.trackerNumbers",
                    name: "Tracker Numbers",
                    tag: "bookings.tracker_number",
                    enabled: true,
                    // for table header
                    text: "Tracker Numbers",
                    align: "right",
                    value: "node.trackerNumbers"
                }
            ],
            exportDataDialog: false,
            fileData: [],
            fileFormatsAvailable: [
                {
                    text: ".csv",
                    value: "csv"
                },
                {
                    text: ".xlsx",
                    value: "xlsx"
                }
            ],
            fileFormat: "csv",
            isLoadingFile: false,
            statusList: [
                { text: "Pending", value: "pending" },
                { text: "Approved", value: "approved" },
                { text: "Declined", value: "declined" }
            ],
            orderStatusList: [
                { text: "Draft", value: "draft" },
                { text: "Confirmed", value: "confirmed" },
                { text: "Cancelled", value: "cancelled" },
                { text: "Pending", value: "pending" }
            ],
            changeRequestStatus: [
                { text: "Pending", value: "pending" },
                { text: "Approved", value: "approved" },
                { text: "Cancelled", value: "cancelled" },
                { text: "Declined", value: "delined" }
            ]
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        // infinite
        hasMoreData() {
            return this.bookingOrderQxs?.pageInfo?.hasNextPage;
        },
        centralizeLoadBtnClass() {
            let _centralizeLoadBtnClass = {
                position: "sticky",
                left: "50%"
            };
            return _centralizeLoadBtnClass;
        },
        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            // put header filter in the last column
            _headers.push({
                text: "",
                align: "right",
                value: "headerFilter",
                sortable: false
            });
            return _headers;
        }
    },
    methods: {
        loadHeaders() {
            let _default = [...this.defaultHeaderChoices];
            this.headerChoices = helper.getTableHeaders("bookingTableHeaders", _default);
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices.map(choice => choice);
            helper.updateMyPreferences({
                bookingTableHeaders: newChoices
            });
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 180 + "px";
            });
        },
        startScrolling(direction) {
            this.scrollInterval = setInterval(() => {
                const tableContainer = document.querySelector(".v-data-table__wrapper");
                const scrollAmount = direction === "left" ? -10 : 10;
                tableContainer.scrollLeft += scrollAmount;
            }, 10);
        },
        stopScrolling() {
            clearInterval(this.scrollInterval);
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;
            if (this.shouldLoadMore && this.bookingOrderQxs?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.bookingOrderQxs.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.bookingOrderQxs?.pageInfo?.hasNextPage) {
                this.$apollo.queries.bookingOrderQxs.fetchMore({
                    variables: {
                        after: this.bookingOrderQxs.pageInfo.endCursor
                    }
                });
            }
        },
        closeAndResetInput() {
            this.exportDataDialog = false;
            this.fileFormat = null;
        },
        submitExportData() {
            this.isLoadingFile = true;
            let currentDate = new Date();

            let tomorrowMin = new Date(currentDate);
            tomorrowMin.setDate(currentDate.getDate() + 1);
            tomorrowMin.setHours(0, 0, 0, 0);

            let tomorrowMax = new Date(currentDate);
            tomorrowMax.setDate(currentDate.getDate() + 1);
            tomorrowMax.setHours(23, 59, 59, 999);

            this.fileData = [];

            this.$apollo
                .query({
                    query: gql`
                        query BookingTransportOrderQxs(
                            $loadDateLte: DateTime
                            $loadDateGte: DateTime
                            $bookingOrderQxOrderStatus: String
                        ) {
                            bookingTransportOrderQxs(
                                loadDate_Lte: $loadDateLte
                                loadDate_Gte: $loadDateGte
                                bookingOrderQx_OrderStatus: $bookingOrderQxOrderStatus
                            ) {
                                edges {
                                    node {
                                        id
                                        containerNumber
                                        sealNumber
                                        ctoNumber
                                        trackerNumber
                                        loadPoint1
                                        loadPoint2
                                        loadDate
                                        numberOfPalletsPerShipment
                                        solasMethod
                                        tempRecorderType
                                        transporter
                                        transportType
                                        transportStatus
                                        transportInstruction
                                        depotDateIn
                                        depotDateOut
                                        estimatedGrossWeight
                                        gensetIsRequired
                                        plugInDepot
                                        bookingOrderQx {
                                            id
                                            bookingReference
                                            contractNumber
                                            customerBookingNumber
                                            customerOrderNumber
                                            commodity
                                            containerType
                                            emptyLoadPoint
                                            customer
                                            exporter
                                            receiver
                                            containerInstructions
                                            finalDestination
                                            freightPayLocation
                                            incoTerm
                                            importPermitNumber
                                            logisticContact
                                            numberOfProbes
                                            numberOfShipments
                                            orderDate
                                            orderStatus
                                            pod
                                            pol
                                            ppecbTempCode
                                            sailingWeek
                                            shippingLine
                                            shippingNo
                                            voyageNo
                                            weighBridge
                                            vesselName
                                            vents
                                            transportType
                                            steri
                                            steriProtocol
                                            stackOpen
                                            stackClose
                                            stackStatus
                                            eta
                                            etd
                                            ata
                                            atd
                                            approxGrossWeight
                                            agentCode
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        bookingOrderQxOrderStatus: "confirmed",
                        loadDate_Lte: tomorrowMax,
                        loadDate_Gte: tomorrowMin
                    }
                })
                .then(response => {
                    response.data.bookingTransportOrderQxs.edges.forEach(booking => {
                        this.fileData.push({
                            ...booking.node,
                            ...booking.node.bookingOrderQx
                        });
                    });
                    this.fileData = this.mergeToOneObject(this.fileData);
                    if (this.fileData.length < 1) {
                        const payload = {
                            color: "blue",
                            message: this.$t("No loads for tomorrow.")
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    } else {
                        try {
                            this.exportData(this.fileData, `Orders_Loading_on_${currentDate}`);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoadingFile = false;
                    this.closeAndResetInput();
                });
        },
        exportData(data, filename) {
            if (this.fileFormat === "csv") {
                const csv = this.convertToCSV(data);
                this.download(csv, `${filename}.csv`, "text/csv;charset=utf-8;");
            } else if (this.fileFormat === "xlsx") {
                const workbook = this.convertToXLSX(data);
                const excelBuffer = write(workbook, {
                    bookType: "xlsx",
                    type: "buffer"
                });
                this.download(
                    excelBuffer,
                    `${filename}.xlsx`,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
                );
            } else {
                console.error("Invalid format");
            }
        },
        convertToCSV(data) {
            const delimiter = ",";
            const keys = Object.keys(data[0]);
            const headers = keys.join(delimiter);
            const values = data.map(obj => keys.map(key => obj[key]).join(delimiter));
            return [headers, ...values].join("\n");
        },
        convertToXLSX(data) {
            const worksheet = utils.json_to_sheet(data);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"]
            };
            return workbook;
        },
        download(data, filename, type) {
            const blob = new Blob([data], { type });
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        getExportRefNumbers(booking) {
            let refNumbers = [];
            const { bookingTransportOrderQxSet } = booking.node;
            if (bookingTransportOrderQxSet.edges.length > 0) {
                bookingTransportOrderQxSet.edges.forEach(bookingTransportOrderQx => {
                    const { exporterReferenceNumber } = bookingTransportOrderQx.node;
                    if (exporterReferenceNumber) {
                        refNumbers.push(exporterReferenceNumber);
                    }
                });
            }

            let joinedRefNumbers = refNumbers.map(ref => ref).join(", ");

            return `
                <span>${joinedRefNumbers}</span>
            `;
        },
        getStatus(type, value) {
            switch (type) {
                case "orderStatus":
                    return this.orderStatusList.filter(c => c.value === value.toLowerCase())[0]?.text;
                case "changeRequestStatus":
                    return this.changeRequestStatus.filter(c => c.value === value.toLowerCase())[0]?.text;
                default:
                    return value;
            }
        },
        mergeToOneObject(data) {
            data.forEach((item, index) => {
                const mergedData = {
                    // "Booking Reference": "",
                    "customer Booking Number": "",
                    // "Customer Order Number": "",
                    Commodity: "",
                    "Conatiner Type": "",
                    Customer: "",
                    Exporter: "",
                    Receiver: "",
                    Agent: "",
                    "Contract Number": "",
                    "Final Destination": "",
                    "Order Date": "",
                    POL: "",
                    POD: "",
                    "Vessel Name": "",
                    Voyage: "",
                    "Weigh Bridge": "",
                    Vents: "",
                    "Transport Type": "",
                    "Container Number": "",
                    "Seal Number": "",
                    "CTO Number": "",
                    "Tracker ID": "",
                    "Load Point 1": "",
                    "Load Point 2": "",
                    "Load Date": "",
                    ETD: "",
                    ETA: "",
                    ATD: "",
                    ATA: "",
                    "Stack Open": "",
                    "Stack Close": "",
                    "Stack Status": "",
                    "Shipping Line": "",
                    "Shipping No": "",
                    "Number of Pallets": "",
                    "Solas Method": "",
                    "Temp Code": "",
                    "Temp Recorder Type": "",
                    Transporter: "",
                    "Transport Status": "",
                    "Estimated Gross Weight": "",
                    Steri: "",
                    "Steri Protocol": "",
                    "Freight Pay Location": "",
                    "Inco Term": ""
                };
                // mergedData["Booking Reference"]= item.bookingReference,
                (mergedData["customer Booking Number"] = item.customerBookingNumber),
                    // mergedData["Customer Order Number"]= item.customerOrderNumber,
                    (mergedData["Commodity"] = item.commodity),
                    (mergedData["Conatiner Type"] = item.containerType),
                    (mergedData["Customer"] = item.customer),
                    (mergedData["Exporter"] = item.exporter),
                    (mergedData["Receiver"] = item.receiver),
                    (mergedData["Agent"] = item.agent),
                    (mergedData["Contract Number"] = item.contractNumber),
                    (mergedData["Final Destination"] = item.finalDestination),
                    (mergedData["Order Date"] = item.orderDate),
                    (mergedData["POL"] = item.pol),
                    (mergedData["POD"] = item.pod),
                    (mergedData["Vessel Name"] = item.vesselName),
                    (mergedData["Voyage"] = item.voyage),
                    (mergedData["Weigh Bridge"] = item.weighBridge),
                    (mergedData["Vents"] = item.vents),
                    (mergedData["Transport Type"] = item.transportType),
                    (mergedData["Container Number"] = item.containerNumber),
                    (mergedData["Seal Number"] = item.sealNumber),
                    (mergedData["CTO Number"] = item.ctoNumber),
                    (mergedData["Tracker ID"] = item.trackerNumber),
                    (mergedData["Load Point 1"] = item.loadPoint1),
                    (mergedData["Load Point 2"] = item.loadPoint2),
                    (mergedData["Load Date"] = item.loadDate),
                    (mergedData["ETD"] = item.etd),
                    (mergedData["ETA"] = item.eta),
                    (mergedData["ATD"] = item.atd),
                    (mergedData["ATA"] = item.ata),
                    (mergedData["Stack Open"] = item.stackOpen),
                    (mergedData["Stack Close"] = item.stackClose),
                    (mergedData["Stack Status"] = item.stackStatus),
                    (mergedData["Shipping Line"] = item.shippingLine),
                    (mergedData["Shipping No"] = item.shippingNo),
                    (mergedData["Number of Pallets"] = item.numberOfPalletsPerShipment),
                    (mergedData["Solas Method"] = item.solasMethod),
                    (mergedData["Temp Code"] = item.ppecbTempCode),
                    (mergedData["Temp Recorder Type"] = item.tempRecorderType),
                    (mergedData["Transporter"] = item.transporter),
                    (mergedData["Transport Status"] = item.transportStatus),
                    (mergedData["Estimated Gross Weight"] = item.approxGrossWeight),
                    (mergedData["Steri"] = item.steri),
                    (mergedData["Steri Protocol"] = item.steriProtocol),
                    (mergedData["Freight Pay Location"] = item.freightPayLocation),
                    (mergedData["Inco Term"] = item.incoTerm);

                data[index] = { ...mergedData };
            });
            return data;
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
