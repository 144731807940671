import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"form"},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('div',[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textHeader labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("home.forwarders"))+" ")])])],1),_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.tenantPartners.loading,"items":_vm.forwarder,"no-data-text":_vm.$t('loading.no_available'),"placeholder":_vm.$t('home.forwarders'),"outlined":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":"","dense":_vm.dense,"menu-props":{
                            maxWidth: '350px'
                        }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 caption"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createNewPartner(
                                                'forwarder'
                                            )}}},[_vm._v(" "+_vm._s(_vm.$t( "general.add_forwarder" ))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}})],1),_c('div',[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textHeader labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("home.consignors"))+" ")])])],1),_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.tenantPartners.loading,"items":_vm.consignor,"no-data-text":_vm.$t('loading.no_available'),"placeholder":_vm.$t('home.consignors'),"outlined":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":"","dense":_vm.dense,"menu-props":{
                            maxWidth: '350px'
                        }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 caption"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createNewPartner(
                                                'consignor'
                                            )}}},[_vm._v(" "+_vm._s(_vm.$t( "general.add_consignor" ))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}})],1)]),_c(VCol,{attrs:{"cols":"6"}},[_c('div',[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textHeader labelContent",attrs:{"cols":"auto"}},[_c('span',{staticClass:"textHeader"},[_vm._v(" "+_vm._s(_vm.$t("home.consignees"))+" ")])])],1),_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.tenantPartners.loading,"items":_vm.consignee,"no-data-text":_vm.$t('loading.no_available'),"placeholder":_vm.$t('home.consignees'),"outlined":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":"","dense":_vm.dense,"menu-props":{
                            maxWidth: '350px'
                        }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 caption"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createNewPartner(
                                                'consignee'
                                            )}}},[_vm._v(" "+_vm._s(_vm.$t( "general.add_consignee" ))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}})],1),_c('div',[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"textHeader labelContent",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("home.others"))+" ")])])],1),_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.tenantPartners.loading,"items":_vm.other,"no-data-text":_vm.$t('loading.no_available'),"placeholder":_vm.$t('home.others'),"outlined":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":"","dense":_vm.dense,"menu-props":{
                            maxWidth: '350px'
                        }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 caption"},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")])],1)]}},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append py-0",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createNewPartner(
                                                'other'
                                            )}}},[_vm._v(" "+_vm._s(_vm.$t( "general.add_other" ))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.partners),callback:function ($$v) {_vm.partners=$$v},expression:"partners"}})],1)])],1)],1),_c('AddPartnerDialog',{attrs:{"openAddPartnerDialog":_vm.openAddPartnerDialog,"partnerByType":_vm.partnerByType},on:{"refreshPartnersTable":function($event){_vm.newPartner = true},"closeAddPartnerDialog":function($event){_vm.openAddPartnerDialog = false},"item-created":_vm.onPartnerCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }