<template>
    <v-sheet class="mx-auto d-flex justify-center align-center" fill-height>
        <v-row dense>
            <v-col cols="12" class="shrink d-flex justify-center">
                <div class="grey--text text--darken-2">
                    {{ $t("general.permission1") }} {{ type }}
                    {{ $t("general.permission2") }}
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: "not-found-error",
    props: {
        type: {
            type: String,
            required: true
        }
    }
};
</script>
