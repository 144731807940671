<template>
    <v-row dense>
        <v-col cols="12">
            <v-autocomplete
                v-model="seaportsToFilter"
                :loading="$apollo.queries.seaPorts.loading"
                :items="seaPorts.edges"
                :label="label"
                @click="openSeaPortsList = true"
                :search-input.sync="search"
                dense
                hide-selected
                hide-details
                no-filter
                clearable
                item-text="node.code"
                item-value="node.code"
                :no-data-text="
                    $apollo.queries.seaPorts.loading
                        ? this.$t('loading.loading1')
                        : this.$t('loading.no_available')
                "
                
                
            >
                <template v-slot:item="data">
                    <v-row class="ma-0 body-2">
                        <v-col cols="4" class="text-truncate pl-0 ml-0">
                            {{ data.item.node.code }}
                        </v-col>
                        <v-col cols="8" class="d-flex justify-end">
                            <span class="grey--text body-2 mr-1"
                                ><i>{{ data.item.node.name }}</i></span
                            >
                        </v-col>
                    </v-row>
                </template>
            </v-autocomplete>
        </v-col>
    </v-row>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "SeaPortFilter",
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        refreshTable: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        seaPorts: {
            query: gql`
                query SeaPorts($orderBy: String, $first: Int, $search: String) {
                    seaPorts(
                        orderBy: $orderBy
                        first: $first
                        search: $search
                    ) {
                        edges {
                            node {
                                code
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    search: this.search,
                    first: 20,
                    orderBy: "code"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.seaPorts,
            skip() {
                return this.shouldSkip;
            }
        }
    },
    data() {
        return {
            loading: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            seaPorts: { edges: [] },
            openSeaPortsList: false,
            search: null
        };
    },
    watch: {},
    computed: {
        hasMoreData() {
            return this.seaPorts?.pageInfo?.hasNextPage;
        },
        seaportsToFilter: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        shouldSkip() {
            return !this.openSeaPortsList && !this.refreshTable;
        }
    },
    methods: {}
};
</script>
