<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col cols="12" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :hint="$t('general.search_name')"
                                v-model="searchInput"
                                append-icon="search"
                                :label="$t('general.search')"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
                <v-col cols="auto">
                    <v-row dense>
                        <!-- ADD -->
                        <v-col cols="auto" v-if="!isSelectorEnabled">
                            <v-btn
                                v-if="!isSelectorEnabled"
                                small
                                outlined
                                rounded
                                color="primary"
                                @click="openAddPartnerUserDialog = true"
                            >
                                <v-icon left small>add_box</v-icon>
                                {{ $t("partners.add_user1") }}
                            </v-btn>
                        </v-col>
                        <!-- REMOVE -->
                        <v-col cols="auto" v-if="isSelectorEnabled">
                            <v-btn
                                small
                                outlined
                                rounded
                                color="error"
                                v-if="isSelectorEnabled"
                                :disabled="enableOrDisableRemove"
                                @click="openDeletePartnerDialog = true"
                            >
                                <v-icon left small>remove_circle</v-icon>
                                {{ $t("general.remove") }}</v-btn
                            >
                        </v-col>
                        <v-col cols="auto" v-if="!isSelectorEnabled">
                            <v-btn
                                :value="isSelectorEnabled"
                                v-if="!isSelectorEnabled"
                                color="primary"
                                @click="$emit('updateSelector', !isSelectorEnabled)"
                                rounded
                                outlined
                                small
                                >{{ $t("general.select") }}</v-btn
                            >
                        </v-col>
                        <v-col cols="auto" v-if="isSelectorEnabled">
                            <v-btn
                                :value="isSelectorEnabled"
                                v-if="isSelectorEnabled"
                                color="error"
                                @click="$emit('updateSelector', !isSelectorEnabled)"
                                rounded
                                outlined
                                small
                                >{{ $t("general.cancel") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :items="partnerUsers"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    :no-data-text="$t('loading.no_available')"
                    loader-height="2"
                    hide-default-footer
                    :loading="$apollo.queries.partnerUsers.loading"
                >
                    <!-- CHECKBOX COLUMN -->
                    <template v-slot:item.action="{ item }">
                        <v-checkbox
                            v-model="selectedUsers"
                            v-if="isSelectorEnabled"
                            class="mx-0"
                            :value="item.node.id"
                        ></v-checkbox>
                    </template>

                    <!-- Notifications Column -->
                    <template v-slot:item.node.user.require2fa="{ item }">
                        <v-icon v-if="item.node.user.require2fa == true" color="success">check_circle</v-icon>
                        <v-icon color="grey lighten-2" v-else>check_circle</v-icon>
                    </template>
                    <template v-slot:item.node.user.lastLogin="{ item }">
                        <div>
                           
                            {{ item.node.user.lastLogin ? formatDateTime(item.node.user.lastLogin) : "" }}
                        </div>
                    </template>

                    <!-- EDIT COLUMN -->
                    <template v-slot:item.edit="{ item }">
                        <v-icon @click="editUser(item)">edit</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!---- ADD PARTNER USER DIALOG ---->
        <AddPartnerUserDialog
            :openAddPartnerUserDialog="openAddPartnerUserDialog"
            :partner="partner"
            v-on:refreshPartnerUsersTable="$apollo.queries.partnerUsers.refetch()"
            v-on:closeAddPartnerUserDialog="openAddPartnerUserDialog = false"
        ></AddPartnerUserDialog>

        <!---- EDIT PARTNER USER DIALOG ---->
        <EditPartnerUserDialog
            ref="editPartnerUserDialog"
            :openEditPartnerUserDialog="openEditUserDialog"
            :partner="partner"
            v-on:refreshPartnerUsersTable="$apollo.queries.partnerUsers.refetch()"
            v-on:closeAddPartnerUserDialog="openEditUserDialog = false"
        ></EditPartnerUserDialog>

        <!---- DELETE PARTNER USER DIALOG ---->
        <DeletePartnerUserDialog
            :openDeleteDialog="openDeletePartnerDialog"
            :usersID="selectedUsers"
            v-on:refreshDataTable="$apollo.queries.partnerUsers.refetch()"
            v-on:closeDeletePartnerUserDialog="closeAndCleanDeletedUsers"
        >
            <template #title>{{ $t("partners.delete_partner_user") }}</template>
            <template #content>{{ $t("partners.sure_partner_user") }}</template>
        </DeletePartnerUserDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddPartnerUserDialog from "@/components/partners/create/AddPartnerUserDialog.vue";
import DeletePartnerUserDialog from "@/components/partners/delete/DeletePartnerUserDialog.vue";
import EditPartnerUserDialog from "@/components/partners/update/EditPartnerUserDialog.vue";
import Config from "@/utils/config.json";
import helper from "@/utils/helper.js";

export default {
    name: "PartnerUsersTab",
    components: {
        AddPartnerUserDialog,
        DeletePartnerUserDialog,
        EditPartnerUserDialog
    },
    props: {
        partner: {
            type: Object,
            required: true
        },
        isSelectorEnabled: Boolean
    },
    apollo: {
        partnerUsers: {
            query: gql`
                query getTenantPartnerUsers($tenantPartner: ID, $search: String) {
                    tenantPartnerUsers(tenantPartner: $tenantPartner, search: $search) {
                        edges {
                            node {
                                id
                                notificationEnabled
                                user {
                                    id
                                    email
                                    firstName
                                    lastName
                                    require2fa
                                    lastLogin
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantPartner: this.partner ? this.partner.id : null,
                    search: this.searchInput ? this.searchInput : null
                };
            },
            fetchPolicy: "network-only",
            update: response => {
                return response.tenantPartnerUsers.edges;
            },
            skip: false
        }
    },
    data() {
        return {
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /*********************************/
            openAddPartnerUserDialog: false,
            openDeletePartnerDialog: false,
            openEditUserDialog: false,
            /******************************/
            /* ---- USER INPUT DATA ---- */
            /****************************/
            selectedUsers: [],
            searchInput: null,
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
            headerChoices: [
                {
                    text: "",
                    align: "center",
                    value: "action",
                    width: "5px",
                    sortable: false
                },
                {
                    text: this.$t("headers.first_name"),
                    align: "left",
                    value: "node.user.firstName",
                    sortable: false
                },
                {
                    text: this.$t("headers.last_name"),
                    align: "left",
                    value: "node.user.lastName",
                    sortable: false
                },
                {
                    text: "E-mail",
                    align: "left",
                    value: "node.user.email",
                    sortable: false
                },
                {
                    text: this.$t("headers.2fa"),
                    align: "left",
                    value: "node.user.require2fa",
                    sortable: false
                },
                {
                    text: this.$t("headers.last_login"),
                    align: "left",
                    value: "node.user.lastLogin",
                    sortable: false
                },
                {
                    text: "",
                    align: "left",
                    value: "edit",
                    width: "5px",
                    sortable: false
                }
            ]
        };
    },
    watch: {
        searchInput() {
            this.$apollo.partnerUsers ? this.$apollo.partnerUsers.refetch() : null;
        }
    },
    computed: {
        enableOrDisableRemove() {
            return this.selectedUsers?.length > 0 ? false : true;
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "partner_users_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("partner_users_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("partner_users_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        editUser(item) {
            this.openEditUserDialog = true;
            this.$refs.editPartnerUserDialog.fillEditForm(item);
        },
        closeAndCleanDeletedUsers() {
            this.openDeletePartnerDialog = false;
            this.selectedUsers = [];
        },
        onClearReferenceNumber() {
            this.referenceNumberSearch = "";
        }
    }
};
</script>
