<template>
    <v-container fluid class="pa-0">
        <!-- USERS FILTER/TOOLBAR -->
        <UsersFilter
            class="mx-3"
            v-on:updateUserTable="refetchTableData"
            v-on:filter-changed="onFilterChanged"
            :isColdChaStaff="true"
        ></UsersFilter>
        <!-- TABLE -->
        <StaffUsersTable
            ref="staffUsersTable"
            :usersFilter="usersFilter"
            v-on:click:row="onUserSelected"
            class="mx-1"
        ></StaffUsersTable>
        <!-- DETAIL DRAWER -->
        <v-navigation-drawer
            v-model="userDetailDrawer"
            :width="userDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <StaffUserDetail
                    ref="userDetail"
                    v-if="selectedUser"
                    :key="selectedUser.id"
                    :user="selectedUser"
                    :isFullScreen="isUserDetailDrawerFullScreen"
                    v-on:changeFullScreen="onUserDetailDrawerFullScreenChange"
                    v-on:refreshDataTable="refetchTableData"
                    v-on:close="userDetailDrawer = false"
                ></StaffUserDetail>
            </div>
        </v-navigation-drawer>
    </v-container>
</template>
<script>
import helper from "@/utils/helper.js";
import UsersFilter from "@/components/users/UsersFilter.vue";
import StaffUsersTable from "@/components/staff/users/StaffUsersTable.vue";
import StaffUserDetail from "@/components/staff/users/StaffUserDetail.vue";

export default {
    title: "Users",
    components: {
        UsersFilter,
        StaffUsersTable,
        StaffUserDetail
    },
    data() {
        return {
            searchInput: null,
            shouldStickyFilter: false,
            userDetailDrawer: false,
            userDetailFullScreen: false,
            usersFilter: {
                first: 20
            },
            goTopShow: false,
            isLoading: false,
            menu: false,
            selectedUser: null
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        userDetailDrawerWidth() {
            if (this.userDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isUserDetailDrawerFullScreen() {
            return this.userDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.onResize();
    },
    methods: {
        onUserSelected(user) {
            this.selectedUser = user.node;
            this.userDetailDrawer = true;
        },

        onUserDetailDrawerFullScreenChange(val) {
            this.userDetailFullScreen = val;
        },

        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },

        onLoading(val) {
            this.isLoading = val;
        },

        onFilterChanged(filter) {
            this.usersFilter = filter;
        },

        refetchTableData() {
            this.$refs.staffUsersTable.$apollo.queries.users.refetch();
        },

        // scroll to top of the page
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },

        loadMore() {
            this.isLoading = false;
        }
    }
};
</script>
<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
