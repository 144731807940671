<template>
    <main>
        <v-card class="py-3 px-3 mx-2 my-2" elevation="5" outlined>
            <v-card-text class="pb-3 pr-3">
                <!-- TABLE -->
                <v-data-table
                    :headers="headerChoices"
                    :items="users.edges"
                    item-key="node.id"
                    :loading-text="$t('loading.loading1')"
                    :loading="$apollo.queries.users.loading"
                    hide-default-footer
                    disable-pagination
                    disable-sort
                    loader-height="2"
                    :mobile-breakpoint="
                        screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                    "
                    :no-data-text="$t('loading.no_data')"
                    @click:row="item => this.$emit('click:row', item)"
                >
                    <template v-slot:item.node.lastLogin="{ item }">
                        <span>{{ formatDateTime(item.node.lastLogin) }}</span>
                    </template>
                    <template v-slot:item.node.isSuperuser="{ item }">
                        <div class="align-self-center d-flex">
                            <v-icon v-if="item.node.isSuperuser" color="success"
                                >check_circle</v-icon
                            >
                            <v-icon color="grey lighten-2" v-else
                                >check_circle</v-icon
                            >
                        </div>
                    </template>
                    <template v-slot:item.node.require2fa="{ item }">
                        <div class="align-self-center d-flex">
                            <v-icon v-if="item.node.require2fa" color="success"
                                >check_circle</v-icon
                            >
                            <v-icon color="grey lighten-2" v-else
                                >check_circle</v-icon
                            >
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            <!-- LOADING TRIGGER -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.users.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    {{ $t("loading.load_more") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </main>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";

export default {
    name: "UsersTable",
    components: {},
    props: {
        usersFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    apollo: {
        users: {
            query: gql`
                query Users(
                    $last: Int
                    $first: Int
                    $after: String
                    $before: String
                    $orderBy: String
                    $search: String
                    $isStaff: Boolean
                ) {
                    users(
                        last: $last
                        first: $first
                        after: $after
                        before: $before
                        orderBy: $orderBy
                        search: $search
                        isStaff: $isStaff
                    ) {
                        totalCount
                        pageInfo {
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                        edges {
                            node {
                                id
                                firstName
                                lastName
                                fullName
                                email
                                isStaff
                                isSuperuser
                                lastLogin
                                require2fa
                                groups {
                                    totalCount
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    isStaff: true,
                    ...this.usersFilter
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.users;
            },
            skip: false
        }
    },
    data() {
        return {
            users: { edges: [] }

            // headerChoices: [
            //     {
            //         text: this.$t("headers.first_name"),
            //         align: "left",
            //         value: "node.firstName"
            //     },
            //     {
            //         text: this.$t("headers.last_name"),
            //         align: "left",
            //         value: "node.lastName"
            //     },
            //     {
            //         text: "Email",
            //         align: "left",
            //         value: "node.email"
            //     },
            //     {
            //         text: this.$t("headers.superuser"),
            //         align: "left",
            //         value: "node.isSuperuser"
            //     },

            //     {
            //         text: this.$t("headers.2fa_enabled"),
            //         align: "left",
            //         value: "node.require2fa"
            //     },
            //     {
            //         text: this.$t("headers.last_login"),
            //         align: "left",
            //         value: "node.lastLogin"
            //     }
            // ]
        };
    },
    watch: {},
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        }, 
        me() {
            return helper.me();
        },
        hasMoreData() {
            return this.users?.pageInfo?.hasNextPage;
        },
        headerChoices: function() {
            let _headerChoices = [
                {
                    text: this.$t("headers.first_name"),
                    align: "left",
                    value: "node.firstName"
                },
                {
                    text: this.$t("headers.last_name"),
                    align: "left",
                    value: "node.lastName"
                },
                {
                    text: "Email",
                    align: "left",
                    value: "node.email"
                },
                {
                    text: this.$t("headers.superuser"),
                    align: "left",
                    value: "node.isSuperuser"
                },

                {
                    text: this.$t("headers.2fa_enabled"),
                    align: "left",
                    value: "node.require2fa"
                },
                {
                    text: this.$t("headers.last_login"),
                    align: "left",
                    value: "node.lastLogin"
                }
            ];
            return _headerChoices;
        }
    },
    methods: {
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        loadMore() {
            if (this.users?.pageInfo?.hasNextPage) {
                this.$apollo.queries.users.fetchMore({
                    variables: {
                        after: this.users.pageInfo.endCursor
                    }
                });
            }
        },

        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.users?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        }
    }
};
</script>
