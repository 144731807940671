<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card outlined class="rounded-lg">
                    <v-card-title class="mb-2">
                        <div class="title">
                            {{ $t("general.password_management") }}
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-btn @click="dialog = true" color="primary" elevation="0">
                                    {{ $t("general.change_password") }}
                                </v-btn>
                                <ChangePasswordForm :dialog.sync="dialog"></ChangePasswordForm>
                            </v-col>
                            <v-col> </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="rounded-lg">
                    <v-card-title class="mb-2">
                        <div class="title">{{ $t("general.two_factor_authentication") }}</div>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-btn @click="dialog2 = true" color="primary" elevation="0">
                                    {{ $t("general.enable_2fa") }}
                                </v-btn>
                                <Enable2faForm :dialog.sync="dialog2"></Enable2faForm>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ChangePasswordForm from "./ChangePasswordForm.vue";
import Enable2faForm from "./Enable2faForm.vue";

export default {
    components: {
        ChangePasswordForm,
        Enable2faForm
    },
    data() {
        return {
            dialog: false,
            dialog2: false
        };
    },
    methods: {
        save() {}
    }
};
</script>
<style scoped>
.title {
    color: #0e0d35;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
}
.headers {
    color: #868599;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
}
.body {
    color: #0e0d35;
    font-size: 13px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: left;
}
</style>
