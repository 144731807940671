import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary py-3 mb-2 white--text"},[_c(VIcon,{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("done")]),_vm._v(" "+_vm._s(_vm.$t("trips.confirm_action"))+" ")],1),_c(VCardText,[_c('div',[_c('span',[_c('h4',[_vm._v(" "+_vm._s(_vm.trip.status == "COMPLETED" ? this.$t("trips.confirm_reopen") : this.$t("trips.confirm_mark"))+" ")])])]),_c('p')]),_c(VCardActions,{staticClass:"pb-5 pr-5"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","rounded":"","loading":_vm.isUpdating},on:{"click":_vm.updateTripStatus}},[_vm._v(_vm._s(_vm.trip.status == "COMPLETED" ? this.$t("trips.reopen") : this.$t("trips.complete")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }