import { render, staticRenderFns } from "./PartnerDetailListView.vue?vue&type=template&id=1682462d&scoped=true&"
import script from "./PartnerDetailListView.vue?vue&type=script&lang=js&"
export * from "./PartnerDetailListView.vue?vue&type=script&lang=js&"
import style0 from "./PartnerDetailListView.vue?vue&type=style&index=0&id=1682462d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1682462d",
  null
  
)

export default component.exports