<template>
    <v-container fluid>
        <v-row class="d-flex align-center justify-center px-10 pt-4">
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <div class="textHeader">
                    {{ $t("home.settings") }}
                </div>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <!-- Custom tabs -->
                <CustomTabs v-model="tab" :tabs="tabs" :droggable="false"></CustomTabs>
                <LoadingBar v-if="!tenantDetail && $apollo.queries.tenant.loading"></LoadingBar>
                <v-tabs-items v-model="tab" v-else>
                    <v-tab-item
                        v-for="(item, i) in tabs"
                        :key="i"
                        :value="item.value"
                        class="background"
                        :transition="false"
                    >
                        <v-row v-if="tab === 'personal'" class="py-6">
                            <v-col cols="12">
                                <v-card outlined rouned-lg>
                                    <v-card-title>
                                        <v-row dense>
                                            <v-card v-if="!isMobile" width="220" color="transparent" flat></v-card>
                                            <v-col
                                                cols="12"
                                                xs="12"
                                                sm="12"
                                                md="6"
                                                lg="3"
                                                xl="3"
                                                class="d-flex flex-row"
                                            >
                                                <div>
                                                    <v-icon class="mr-2" size="24" :color="'#0e0d35'">{{
                                                        title.icon
                                                    }}</v-icon>
                                                    <span class="title">{{ $t(title.header) }}</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-tabs :vertical="!isMobile" v-model="verticalTab">
                                            <v-tab
                                                v-for="(personalTab, i) in personalTabs"
                                                :key="i"
                                                class="d-flex justify-start"
                                                :value="personalTab.value"
                                            >
                                                <div class="tabsHeader text-capitalize ">
                                                    {{ $t(personalTab.name) }}
                                                </div>
                                            </v-tab>
                                            <v-tab-item :value="verticalTab">
                                                <v-row>
                                                    <v-col cols="12" v-if="verticalTab === 0">
                                                        <ProfileForm
                                                            :tenant="tenant"
                                                            @loading="onLoading"
                                                        ></ProfileForm>
                                                    </v-col>
                                                    <v-col cols="12" v-if="verticalTab === 1">
                                                        <PreferencesForm :tenant="tenant"></PreferencesForm>
                                                        <NotificationForm
                                                            :user="me"
                                                            @loading="onLoading"
                                                        ></NotificationForm>
                                                    </v-col>
                                                    <v-col cols="12" v-if="verticalTab === 2">
                                                        <PreviewPasswordForm
                                                            :tenant="tenant"
                                                            @loading="onLoading"
                                                        ></PreviewPasswordForm>
                                                    </v-col>
                                                </v-row>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-if="tab === 'global'" class="py-6">
                            <v-col cols="12">
                                <v-card outlined rouned-lg>
                                    <v-card-title>
                                        <v-row dense>
                                            <v-card v-if="!isMobile" width="190" color="transparent" flat></v-card>
                                            <v-col
                                                cols="12"
                                                xs="12"
                                                sm="12"
                                                md="auto"
                                                lg="auto"
                                                xl="auto"
                                                class="d-flex flex-row"
                                            >
                                                <div>
                                                    <v-icon class="mr-2" size="24" :color="'#0e0d35'"
                                                        >local_shipping</v-icon
                                                    >
                                                    <span class="title">{{ $t("general.trip_preferences") }}</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-row dense align="center">
                                                    <v-col cols="auto">
                                                        <v-icon color="primary">info</v-icon>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <div class="warningMessage">
                                                            {{ $t("general.only_you_as_admin_user_can_see") }}
                                                        </div>
                                                        <div class="warningMessage">
                                                            {{ $t("general.and_make_changes_in_these_settings") }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-tabs :vertical="!isMobile">
                                            <v-tab>
                                                <div class="tabsHeader text-capitalize">
                                                    {{ $t("general.trip_preferences") }}
                                                </div>
                                            </v-tab>
                                            <v-tab-item>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <PreviewSettingsDetails
                                                            :tenant="tenant"
                                                        ></PreviewSettingsDetails>
                                                    </v-col>
                                                </v-row>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import PreviewSettingsDetails from "@/components/adminsettings/PreviewSettingsDetails.vue";
import CustomTabs from "@/components/base/CustomTabs.vue";
import ProfileForm from "@/components/userSettings/forms/ProfileForm.vue";
import PreferencesForm from "@/components/userSettings/forms/PreferencesForm.vue";
import PreviewPasswordForm from "../components/userSettings/forms/PreviewPasswordForm.vue";
import NotificationForm from "../components/userSettings/forms/NotificationForm.vue";

export default {
    title: "Profile",
    components: {
        LoadingBar,
        PreviewSettingsDetails,
        CustomTabs,
        ProfileForm,
        PreferencesForm,
        PreviewPasswordForm,
        NotificationForm
    },
    apollo: {
        tenant: {
            query: gql`
                query Tenant($tenantId: ID!) {
                    tenant(id: $tenantId) {
                        id
                        name
                        logo
                        logoText
                        organisationPermissions
                        organisationPreferences
                        tenantPartnerSet {
                            totalCount
                        }
                        tenantUserSet {
                            totalCount
                        }
                        tenantAddressSet {
                            edges {
                                node {
                                    id
                                    street
                                    houseNumber
                                    suffix
                                    zipCode
                                    city
                                    aptSuiteBldg
                                    state
                                    country
                                    phone
                                    mobile
                                    website
                                    tag
                                    alias
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return { tenantId: this.tenantId };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenant,
            result(data) {
                if (data.data.tenant) {
                    this.tenantDetail = data.data.tenant;
                }
            },
            skip() {
                return !this.tenantId ? true : false;
            }
        }
    },
    data() {
        return {
            isLoading: false,
            tenantDetail: null,
            tabs: [
                {
                    id: 0,
                    text: this.$t("general.personal_settings"),
                    value: "personal"
                },
                {
                    id: 1,
                    text: this.$t("general.global_settings"),
                    value: "global"
                }
            ],
            personalTabs: [
                { name: "general.general_info", value: "general" },
                { name: "general.preferences", value: "preferences" },
                { name: "general.security", value: "security" }
            ],
            tab: "personal",
            verticalTab: 0
        };
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        me() {
            return helper.me();
        },
        tenantId() {
            return this.me?.tenant?.id;
        },

        title() {
            let title = {
                header: "general.general_info",
                icon: "manage_accounts"
            };

            if (this.verticalTab === 0) {
                title = {
                    header: "general.general_info",
                    icon: "manage_accounts"
                };
            }
            if (this.verticalTab === 1) {
                title = {
                    header: "general.preferences",
                    icon: "tune"
                };
            }
            if (this.verticalTab === 2) {
                title = {
                    header: "general.security",
                    icon: "verified_user"
                };
            }
            return title;
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    watch: {
        tab(val) {
            if (val === "global") {
                this.verticalTab = 0;
            }
        }
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        }
    }
};
</script>

<style scoped>
.max-width-settings-details {
    max-width: 800px;
    margin: 0 auto;
}

.tabsHeader {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
}

.textHeader {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    width: 291px;
    height: 58px;
    top: 141px;
    left: 376px;
}
.title {
    color: #0e0d35 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 21.6px !important;
    text-align: left !important;
}
.warningMessage {
    color: #868599;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
}
</style>
