<template>
    <div :style="`height: ${heightScreen}px;`" class="overflow-y-auto">
        <v-sheet class="pb-2 d-flex justify-space-between" color="transparent">
            <v-btn small outlined color="primary" @click="expand">
                {{
                    isAllExpanded
                        ? "- " + $t("general.collapse")
                        : "+ " + $t("general.expand")
                }}
            </v-btn>
            <FilterMenu
                :title="$t('dashboard.customize')"
                :description="$t('dashboard.display_modules')"
                :choices.sync="filterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
            ></FilterMenu>
        </v-sheet>
        <v-expansion-panels v-model="panel" multiple focusable class="mb-5">
            <v-expansion-panel
                v-for="(item, index) in selectedFilters"
                :key="index"
            >
                <v-expansion-panel-header>
                    <h4
                        v-if="
                            trip.transportMode === 'SEA' &&
                                item.code === 'seaTransport'
                        "
                    >
                        {{ item.name }}
                    </h4>
                    <h4 v-if="item.code !== 'seaTransport'">{{ item.name }}</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                    <v-lazy>
                        <TripFreights
                            v-if="item.code === 'freights'"
                            :trip="trip"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripFreights>
                        <!-- <TripTrackers
                            v-if="item.code === 'trackerscharts'"
                            :trip="trip"
                            :organisationPermissions.sync="organisationPermissions"
                        ></TripTrackers>-->
                        <TripCarrierEvents
                            v-if="item.code === 'carrierEvents'"
                            :trip="trip"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripCarrierEvents>
                        <TripPartners
                            v-if="item.code === 'partners'"
                            :trip="trip"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripPartners>
                        <TripDocuments
                            v-if="item.code === 'documents'"
                            :trip="trip"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripDocuments>
                        <TripAlerts
                            v-if="item.code === 'alerts'"
                            :trip="trip"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripAlerts>
                        <TripComments
                            v-if="item.code === 'comments'"
                            :trip="trip"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripComments>
                        <TripMap
                            v-if="item.code === 'map'"
                            :trip="trip"
                            viewType="list"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripMap>
                        <TripTimeline
                            v-if="item.code === 'timeline'"
                            :trip="trip"
                            viewType="list"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripTimeline>
                        <TripSeaTransport
                            v-if="
                                trip.transportMode === 'SEA' &&
                                    item.code === 'seaTransport'
                            "
                            :trip="trip"
                            viewType="tab"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripSeaTransport>
                        <TripReports
                            v-if="item.code === 'reports'"
                            :trip="trip"
                            :isSharedTrip="isSharedTrip"
                            :organisationPermissions.sync="
                                organisationPermissions
                            "
                        ></TripReports>
                    </v-lazy>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import TripFreights from "@/components/trips/details/TripFreights.vue";
import TripCarrierEvents from "@/components/trips/details/TripCarrierEvents.vue";
import TripAlerts from "@/components/trips/details/TripAlerts.vue";
import TripComments from "@/components/trips/details/TripComments.vue";
import TripDocuments from "@/components/trips/details/TripDocuments.vue";
import TripPartners from "@/components/trips/details/TripPartners.vue";
import TripMap from "@/components/trips/details/TripMap.vue";
// import TripTrackers from "@/components/trips/details/TripTrackers.vue";
import TripSeaTransport from "@/components/trips/details/TripSeaTransport.vue";
import TripTimeline from "@/components/trips/details/TripTimeline.vue";
import TripReports from "@/components/trips/details/TripReports.vue";
import helper from "../../utils/helper";
import FilterMenu from "../base/FilterMenu.vue";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        // selectedFilters: {
        //     type: Array,
        //     required: true
        // },
        defaultFilterChoices: {
            type: Array,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    components: {
        TripFreights,
        TripCarrierEvents,
        TripAlerts,
        TripComments,
        TripDocuments,
        TripPartners,
        TripMap,
        // TripTrackers,
        TripSeaTransport,
        TripTimeline,
        TripReports,
        FilterMenu
    },
    data() {
        return {
            panel: [0]
        };
    },
    computed: {
        isAllExpanded() {
            return this.panel.length === this.selectedFilters.length;
        },
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 174;
            return _break;
        },
        routeTab() {
            return this.$route.query.tab;
        },
        filterChoices: {
            get() {
                let _filterChoices = helper.getFilterChoices(
                    this.defaultFilterChoices,
                    "tripDetailFilterChoices"
                );
                _filterChoices.filter(c => {
                    if (
                        c.code === "freights" &&
                        c.name.toLowerCase() === "freights"
                    )
                        c.name = "Summary";
                });
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    tripDetailFilterChoices: value
                });
            }
        },
        selectedFilters() {
            let _filters = [];

            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        }
    },
    watch: {
        routeTab: {
            immediate: true,
            handler() {
                if (this.routeTab) {
                    let index = this.selectedFilters.findIndex(
                        item => item.code === this.routeTab
                    );
                    if (index === -1) {
                        index = 0;
                    }
                    this.panel = [index];
                }
            }
        },
        panel: {
            immediate: true,
            handler(val) {
                if (val.length > 1) {
                    if (!this.routeTab) return;
                    this.$router.push({
                        name: "trips",
                        query: {
                            id: this.trip.id
                        }
                    });
                } else if (val.length === 0) {
                    this.$router.push({
                        name: "trips",
                        query: {
                            id: this.trip.id
                        }
                    });
                } else if (val.length === 1) {
                    if (this.routeTab === this.selectedFilters[val[0]].code)
                        return;
                    this.$router.push({
                        name: "trips",
                        query: {
                            id: this.trip.id,
                            tab: this.selectedFilters[val[0]].code,
                            freight: this.$route.query.freight
                        }
                    });
                }
            }
        }
    },
    mounted() {},
    methods: {
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        expand() {
            if (this.isAllExpanded) {
                this.panel = [];
            } else {
                this.panel = [
                    ...this.selectedFilters.map((item, index) => index)
                ];
            }
        }
    }
};
</script>

<style scoped lang="scss">
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>
