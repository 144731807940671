<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="background"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                {{ t.name }}
            </v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <!-- GROUP USERS TAB -->
            <v-tab-item value="tab-alertDetails" :transition="false">
                <AlertRuleDetailsComponent
                    :alertRule="alertRule"
                ></AlertRuleDetailsComponent>

            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>

//import AlertRuleActionGroups from "@/components/alertrules/details/AlertRuleActionGroups.vue";
import AlertRuleDetailsComponent from "@/components/alertrules/AlertRuleDetailsComponent.vue";

export default {
    props: {
        alertRule: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        AlertRuleDetailsComponent
        //AlertRuleTriggers,
        //AlertRuleActionGroups,
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225 ;
            return  _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    mounted() {},
    methods: {
        refreshDataTable() {
            this.$emit("refreshDataTable");
        }
    }

};
</script>
