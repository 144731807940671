<template>
    <div>
        <v-container fluid class="pa-0">
            <v-btn
                v-show="goTopShow"
                fab
                dark
                fixed
                bottom
                right
                small
                style="z-index: 1"
                color="primary"
                @click="toTop"
            >
                <v-icon>keyboard_arrow_up</v-icon>
            </v-btn>
            <!-- title and KPIs -->
            <div>
                <v-row class="ma-0 px-3 d-flex align-center">
                    <v-col cols="auto">
                        <h2
                            class="
                                ml-3
                                display-3
                                font-weight-medium
                                d-flex
                                align-top
                            "
                        >
                            {{ $t("home.users") }}
                        </h2>
                    </v-col>
                    <v-col cols="auto" class="px-0"> </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-tabs
                            v-model="tab"
                            class="justify-center"
                            background-color="transparent"
                            ><v-tab>{{ $t("home.staffs") }}</v-tab>
                            <v-tab>{{ $t("home.groups") }}</v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </div>
        </v-container>
        <!-- TUTORIAL -->
        <TutorialDialog :dialog.sync="tutorialDialog"></TutorialDialog>
        <v-card
            flat
            min-height="73vh"
            style="background-color: #F6F8FA;"
            fill-width
        >
            <router-view @loading="onLoading"></router-view>
        </v-card>
    </div>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TutorialDialog from "@/components/support/TutorialDialog.vue";

export default {
    title: "users",
    components: {
        TutorialDialog
    },
    data() {
        return {
            tutorialDialog: false,
            tab: 0,
            goTopShow: false,
            isLoading: false
        };
    },
    watch: {
        tab(val) {
            switch (val) {
                case 0:
                    this.pushTo("usersStaff");
                    break;
                case 1:
                    this.pushTo("groups");
                    break;
                default:
                    this.pushTo("usersStaff");
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.pushTo("usersStaff");
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        }
    }
};
</script>
