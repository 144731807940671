import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.openAddExternalEmailDialog),callback:function ($$v) {_vm.openAddExternalEmailDialog=$$v},expression:"openAddExternalEmailDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t("users.add_email"))+" ")],1),_c(VCardText,{staticClass:"pa-6"},[_c(VForm,{ref:"addExternalEmailForm"},[_c(VRow,[_c(VTextField,{staticClass:"mx-4 mt-3",attrs:{"label":_vm.$t('headers.email_address') + '*',"rules":[_vm.rules.required, _vm.rules.invalidEmail],"outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end pb-5 pr-5"},[_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.close")))]),_c(VBtn,{attrs:{"color":"primary","rounded":"","outlined":"","loading":_vm.isLoadingAddExternalEmail},on:{"click":_vm.addExternalEmail}},[_vm._v(_vm._s(_vm.$t("general.add")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }