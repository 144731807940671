import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t("templates.name_description"))+" ")],1),_c(VCardText,{staticClass:"pt-6"},[_c(VForm,{ref:"partnerForm",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('templates.template_name') + '*',"rules":_vm.nameRules,"outlined":"","dense":""},model:{value:(_vm.template_name),callback:function ($$v) {_vm.template_name=$$v},expression:"template_name"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":_vm.$t('general.description'),"rules":[_vm.rules.maxLength(200)],"outlined":"","dense":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end pb-3 pr-5"},[_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"color":"primary","rounded":"","disabled":!_vm.formValid,"loading":_vm.isLoading},on:{"click":_vm.saveNameDescription}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }