<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <!---- SEARCH BAR ---->
                    <v-col cols="5" sm="5" lg="5">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :hint="$t('general.search_name')"
                                    v-model="filter.search"
                                    append-icon="search"
                                    :label="$t('general.search')"
                                    clearable
                                    hide-details
                                    dense
                                    class="subtitle-2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <!---- ADD AND REMOVE BUTTONS ---->
                    <v-btn
                        rounded
                        color="primary"
                        @click="
                            isColdChaStaff
                                ? (openAddStaff = true)
                                : (openAddUser = true)
                        "
                    >
                        {{$t("partners.add_user1")}}
                    </v-btn>
                </v-row>
                <!---- ADD USER DIALOG ---->
                <AddUserDialog
                    ref="addUserDialog"
                    :openAddUserDialog="openAddUser"
                    v-on:refreshDataTable="$emit('updateUserTable')"
                    v-on:closeAddUserDialog="openAddUser = false"
                    :tenant="tenant"
                >
                </AddUserDialog>
                <!---- ADD STAFF DIALOG ---->
                <AddStaffDialog
                    ref="addStaffDialog"
                    :openAddUserDialog="openAddStaff"
                    v-on:refreshDataTable="$emit('updateUserTable')"
                    v-on:closeAddUserDialog="openAddStaff = false"
                >
                </AddStaffDialog>
            </v-card>
        </template>
    </v-hover>
</template>
<script>
import helper from "@/utils/helper.js";
// import FilterMenu from "@/components/base/FilterMenu.vue";
import AddUserDialog from "@/components/users/create/AddUserDialog.vue";
import AddStaffDialog from "@/components/staff/users/create/AddStaffDialog.vue";

export default {
    components: { AddUserDialog, AddStaffDialog },
    props: {
        tenant: {
            type: Object,
            default: null
        },
        isColdChaStaff: {
            type: Boolean,
            default: false
        }
    },
    apollo: {},
    data() {
        return {
            fab: false,
            isLoading: false,
            shouldStickyFilter: false,
            openAddUser: false,
            openAddStaff: false,

            // graphql query filter
            filter: {
                first: 15,
                after: null,
                search: null,
                tenantId: this.tenant ? this.tenant.id : null,
                orderBy: this.tenant ? "-is_admin" : null
            }
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        // enableOrDisableRemove() {
        //     return this.selectedUsers?.length > 0 ? false : true;
        // },
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                    helper.setFilterValues("userTableFilters", value);
                }
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.onResize();
        let _filter = helper.getFilterValues("userTableFilters");
        if (_filter) {
            this.filter.search = _filter.search;
        }
    },
    methods: {
        onResize() {
            // if (this.tenant) {
            //     this.shouldStickyFilter = false;
            // } else
            //     this.shouldStickyFilter =
            //         window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}
</style>
