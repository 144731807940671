import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c(VCard,{staticClass:"py-4 mt-4",attrs:{"elevation":"5","outlined":""}},[_c(VRow,{staticClass:"px-5 d-flex align-center",attrs:{"align":"start"}},[_c(VCol,{attrs:{"cols":"12","sm":"5","lg":"5"}},[_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"subtitle-2",attrs:{"hint":_vm.$t('general.search_name'),"append-icon":"search","label":_vm.$t('general.search'),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1)],1)],1),_c(VSpacer),_c(VCol,{staticClass:"py-2 pr-4 text-right",attrs:{"cols":"12","sm":"5","lg":"4"}},[_c(VRow,{staticClass:"d-flex align-center justify-end"},[_c(VBtn,{staticClass:"mr-1",attrs:{"small":"","outlined":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.openAddUserGroupDialog = true}}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("add_box")]),_vm._v(" "+_vm._s(_vm.$t("users.add_group"))+" ")],1)],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2 mb-5 pb-5",attrs:{"elevation":"5","outlined":""}},[_c(VCardText,[_c(VDataTable,{attrs:{"items":_vm.userGroups.edges,"headers":_vm.headerChoices,"disable-pagination":"","loading-text":_vm.$t('loading.loading1'),"no-data-text":_vm.$t('loading.no_data'),"loader-height":"2","hide-default-footer":"","loading":_vm.$apollo.queries.userGroups.loading},scopedSlots:_vm._u([{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{on:{"click":function($event){return _vm.removeUser(item)}}},[_vm._v("delete")])]}}])})],1),_c(VCardActions,{staticClass:"justify-center"},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.userGroups.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)],1),_c('AddUserGroupDialog',{attrs:{"openAddUserGroupDialog":_vm.openAddUserGroupDialog,"user":_vm.user,"userGroups":_vm.userGroups},on:{"refreshDataTable":function($event){return _vm.$apollo.queries.userGroups.refetch()},"closeAddDialog":function($event){_vm.openAddUserGroupDialog = false}}}),_c('DeleteUserGroupDialog',{attrs:{"openDeleteDialog":_vm.openDeleteUserGroupDialog,"group":_vm.selectedGroup,"userIds":_vm.selecteIds},on:{"refreshDataTable":function($event){return _vm.$apollo.queries.userGroups.refetch()},"closeDeleteDialog":_vm.closeAndCleanDeletedGroups},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("users.remove_user")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("users.sure_group")))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }