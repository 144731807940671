<template>
    <v-hover>
        <template v-slot="{ hover }">
            <v-card
                v-resize="onResize"
                class="py-5"
                :class="shouldStickyFilter ? 'sticky-card' : ''"
                :elevation="hover ? 5 : 3"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center">
                    <v-col cols="12" sm="7" lg="9">
                        <v-row>
                            <v-col v-for="f in dynamicFilters" :key="f" :class="getFilterChoiceClassByCode(f)">
                                <!--Serial Number-->
                                <v-text-field
                                    v-if="f === 'serialNumber'"
                                    v-model="filter.serialNumber_Icontains"
                                    :label="$t('headers.serial_number')"
                                    clearable
                                    hide-details
                                    dense
                                >
                                </v-text-field>

                                <!--Brand-->
                                <v-text-field
                                    v-if="f === 'brand'"
                                    v-model="filter.brand_Icontains"
                                    :label="$t('headers.brand')"
                                    clearable
                                    hide-details
                                    dense
                                >
                                </v-text-field>

                                <!--Package Number-->
                                <v-text-field
                                    v-if="f === 'packageNumber'"
                                    v-model="filter.packageNumberIcontains"
                                    :label="$t('headers.package_number')"
                                    clearable
                                    hide-details
                                    dense
                                >
                                </v-text-field>

                                <!--Comment-->
                                <v-text-field
                                    v-if="f === 'comment'"
                                    v-model="filter.commentIcontains"
                                    :label="$t('headers.comment')"
                                    clearable
                                    hide-details
                                    dense
                                >
                                </v-text-field>

                                <!--Last connection date-->
                                <v-menu
                                    v-if="f === 'lastConnectionTime'"
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="dateRangeText"
                                            :label="$t('headers.last_date')"
                                            readonly
                                            clearable
                                            hide-details
                                            dense
                                            @click:clear="onClearDates()"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateRange"
                                        :first-day-of-week="1"
                                        range
                                        no-title
                                        scrollable
                                        color="primary"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text rounded color="primary" @click="cancelDatePicker">
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            outlined
                                            rounded
                                            color="primary"
                                            :disabled="dateRange.length < 1"
                                            @click="saveDatePicker"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>

                                <!-- !Expiration Date List -->
                                <v-select
                                    v-if="f === 'relativeExpiryDateRange'"
                                    v-model="relativeExpiryDateRange"
                                    :items="relativeExpiryDateRangeChoices"
                                    :label="$t('headers.expiry_date')"
                                    clearable
                                    hide-details
                                    class="maxWidth"
                                    dense
                                    color="primary"
                                ></v-select>

                                <!--TODO Battery Level NOT WORKING TODO
                                <v-menu v-if="f === 'lastBatteryValue'" ref="menu" v-model="menu1"
                                    :close-on-content-click="false"  offset-y min-width="auto">
                                    <template v-slot:activator="{ on1, attrs1 }">
                                        <v-text-field :value="batteryRange" label="Battery Level" readonly
                                            clearable hide-details dense 
                                            v-bind="attrs1" v-on="on1"></v-text-field>
                                    </template>
                                    <v-range-slider dense :value="[0, 100]" min="0" step="10" max="100">
                                    <template v-slot: ="props">
                                        <span>1</span>
                                    </template>
                                </v-range-slider>
                                </v-menu>-->

                                <!--Archived-->
                                <v-autocomplete
                                    v-if="f === 'isArchived'"
                                    v-model="filter.isArchived"
                                    :items="archivedChoices"
                                    :label="$t('headers.archived')"
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                    attach
                                ></v-autocomplete>

                                <v-select v-if="f === 'kpi'" label="KPI" clearable hide-details dense color="primary">
                                </v-select>
                                <v-select
                                    v-if="f === 'status'"
                                    v-model="filter.status"
                                    :items="statusSelection"
                                    item-text="text"
                                    item-value="value"
                                    :label="$t('headers.status')"
                                    style="width
                                    : 200px;"
                                    hide-details
                                    dense
                                    color="primary"
                                >
                                </v-select>

                                <v-autocomplete
                                    v-if="f === 'tenant' && isStaff"
                                    v-model="filter.tenant"
                                    :items="tenants.edges"
                                    :no-data-text="$t('loading.no_available')"
                                    :item-text="item => item.node.name"
                                    :item-value="item => item.node.id"
                                    :label="$t('trackers.tenant')"
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                    attach
                                ></v-autocomplete>

                                <v-autocomplete
                                    v-if="f === 'tenantIsnull' && isStaff"
                                    v-model="filter.tenant_Isnull"
                                    :items="archivedChoices"
                                    :label="$t('trackers.not_assigned')"
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                    attach
                                ></v-autocomplete>
                            </v-col>

                            <!-- sort by -->
                            <v-col class="col-auto col-md-4 col-lg-3 col-xl-3">
                                <v-select
                                    v-model="sortBy"
                                    :items="sortByChoices"
                                    :label="$t('general.sort_by')"
                                    :item-value="item => item.code"
                                    :item-text="item => item.name"
                                    clearable
                                    hide-details
                                    dense
                                    color="primary"
                                >
                                    <template v-slot:append-outer>
                                        <v-btn class="mt-n1" small icon :disabled="!sortBy" @click="sortAsc = !sortAsc">
                                            <v-icon color="grey darken-2">{{ sortAsc ? "north" : "south" }}</v-icon>
                                        </v-btn>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="auto" class="py-0">
                                <v-switch label="Beacon Tracker" v-model="filter.isBeaconTracker"></v-switch>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- filters menu -->
                    <v-col cols="12" sm="5" lg="3" class="py-2 pr-2 text-right">
                        <v-row dense class="d-flex align-center justify-end">
                            <FilterMenu
                                :title="$t('dashboard.customize')"
                                :description="$t('dashboard.display_filters')"
                                :choices="filterChoices"
                                :open-on-hover="false"
                                height="500px"
                                width="250px"
                                v-on:changed="onFilterMenuChanged"
                            >
                            </FilterMenu>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
import moment from "moment";
import { TrackerPermissions } from "@/core/enum";
import FilterMenu from "@/components/base/FilterMenu.vue";

/* import AddTrackerDialog from "@/components/trackers/create/AddTrackerDialog.vue"; */
import gql from "graphql-tag";
import _default from "vuex";

export default {
    props: ["selector", "selectedTrackers", "filterCode"],
    components: {
        FilterMenu
    },
    apollo: {
        tenants: {
            query: gql`
                query tenants {
                    tenants {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenants,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },

    data() {
        return {
            openAddTrackerDialog: false,
            fab: false,
            isLoading: false,
            menu: false,
            menu1: false,
            dateRange: [],
            savedExpiryDateRange: [],
            batteryRange: [],
            savedDateRange: [],
            savedDateExpiry: [],
            savedBatteryRange: [],
            sortAsc: false,
            shouldStickyFilter: false,
            openTrackerChangeLocationDialog: false,
            openTrackerChangeTenantDialog: false,
            tenants: { edges: [] },
            relativeExpiryDateRange: null,
            // relativeExpiryDateRangeChoices: [
            //     this.$t("general.expires7"),
            //     this.$t("general.expires14"),
            //     this.$t("general.expires30")
            // ],

            // graphql query filter
            filter: {
                first: 25,
                after: null,
                serialNumber_Icontains: null,
                isArchived: false,
                lastConnectionTime_Gte: null,
                lastConnectionTime_Lte: null,
                orderBy: "-lastConnectionTime",
                tenant: null,
                tenant_Isnull: null,
                expiryDateGte: null,
                expiryDateLte: null,
                activatedDateGte: null,
                activatedDateLte: null,
                packageNumberIcontains: null,
                commentIcontains: null,
                status: "all",
                isBeaconTracker: false
            },
            // defaultFilterChoices: [
            //     {
            //         // for filter menu
            //         code: "serialNumber",
            //         name: this.$t("headers.serial_number"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-auto"
            //     },
            //     {
            //         // for filter menu
            //         code: "brand",
            //         name: this.$t("headers.brand"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-auto"
            //     },
            //     {
            //         // for filter menu
            //         code: "packageNumber",
            //         name: this.$t("headers.package_number"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-auto"
            //     },
            //     {
            //         // for filter menu
            //         code: "comment",
            //         name: this.$t("headers.comment"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-auto"
            //     },
            //     {
            //         // for filter menu
            //         code: "status",
            //         name: this.$t("headers.status"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-auto"
            //     },
            //     {
            //         // for filter menu
            //         code: "lastConnectionTime",
            //         name: this.$t("headers.last_connection"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-auto"
            //     },
            //     {
            //         // for filter menu
            //         code: "relativeExpiryDateRange",
            //         name: this.$t("headers.expiry_date"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-auto"
            //     },
            //     /*  {
            //         // for filter menu
            //         code: "lastBatteryValue",
            //         name: "Battery Level",
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-auto"
            //     }, */
            //     {
            //         // for filter menu
            //         code: "isArchived",
            //         name: this.$t("headers.archived"),
            //         enabled: true,
            //         // for filter layout
            //         class: "col-auto col-md-3 col-lg-2"
            //     }
            //     // {
            //     //     // for filter menu
            //     //     code: "kpi",
            //     //     name: "KPI",
            //     //     enabled: true,
            //     //     // for filter layout
            //     //     class: "col-auto col-md-3 col-lg-2"
            //     // }
            // ],
            filterChoices: null,
            // sortByChoices: [
            //     {
            //         code: "serialNumber",
            //         name: this.$t("headers.serial_number")
            //     },
            //     {
            //         code: "brand",
            //         name: this.$t("headers.brand")
            //     },
            //     // {
            //     //     code: "stockLocation",
            //     //     name: "Stock Location"
            //     // },
            //     {
            //         code: "isArchived",
            //         name: this.$t("headers.archived")
            //     },
            //     {
            //         code: "lastConnectionTime",
            //         name: this.$t("headers.last_connection")
            //     },
            //     {
            //         code: "activatedDate",
            //         name: "Activation Date"
            //     },
            //     {
            //         code: "expiryDate",
            //         name: this.$t("headers.expiry_date")
            //     }
            //     // {
            //     //     code: "lastBatteryValue",
            //     //     name: "Battery Level"
            //     // }
            // ],

            sortBy: "lastConnectionTime"
            // statusSelection: [
            //     { text: this.$t("general.all"), value: "all" },
            //     { text: this.$t("general.activated"), value: "active" },
            //     { text: this.$t("general.not_activated"), value: "inactive" }
            // ],
            // archivedChoices: [
            //     { text: this.$t("general.yes"), value: true },
            //     { text: this.$t("general.no"), value: false }
            // ]
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        dateRangeText() {
            if (this.dateRange.length > 1) {
                let date1 = moment(this.dateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
                let date2 = moment(this.dateRange[1], "YYYY-MM-DD").format(helper.getDateFormat());
                return date1 + " ~ " + date2;
            } else if (this.dateRange.length === 1) {
                return moment(this.dateRange[0], "YYYY-MM-DD").format(helper.getDateFormat());
            } else {
                return this.dateRange;
            }
        },
        dynamicFilters() {
            let _filters = this.filterChoices.filter(f => f.enabled).map(f => f.code);
            return _filters;
        },
        isStaff() {
            return this.me.isStaff || false;
        },
        canEdit() {
            if (this.me?.isSuperuser) {
                return true;
            }
            return this.hasPermission(TrackerPermissions.EDIT_TRACKER);
        },
        canCreate() {
            if (this.me?.isSuperuser) {
                return true;
            }
            return this.hasPermission(TrackerPermissions.CREATE_TRACKER);
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "serialNumber",
                    name: this.$t("headers.serial_number")
                },
                {
                    code: "brand",
                    name: this.$t("headers.brand")
                },
                // {
                //     code: "stockLocation",
                //     name: "Stock Location"
                // },
                {
                    code: "isArchived",
                    name: this.$t("headers.archived")
                },
                {
                    code: "lastConnectionTime",
                    name: this.$t("headers.last_connection")
                },
                {
                    code: "activatedDate",
                    name: "Activation Date"
                },
                {
                    code: "expiryDate",
                    name: this.$t("headers.expiry_date")
                }
                // {
                //     code: "lastBatteryValue",
                //     name: "Battery Level"
                // }
            ];
            return _sortByChoices;
        },
        statusSelection: function() {
            let _statusSelection = [
                { text: this.$t("general.all"), value: "all" },
                { text: this.$t("general.activated"), value: "active" },
                { text: this.$t("general.not_activated"), value: "inactive" }
            ];
            return _statusSelection;
        },
        archivedChoices: function() {
            let _archivedChoices = [
                { text: this.$t("general.yes"), value: true },
                { text: this.$t("general.no"), value: false }
            ];
            return _archivedChoices;
        },
        defaultFilterChoices: function() {
            let _defaultFilterChoices;

            if (this.me.isStaff) {
                _defaultFilterChoices = [
                    {
                        // for filter menu
                        code: "serialNumber",
                        name: "Serial Number",
                        tag: "headers.serial_number",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "brand",
                        name: "Brand",
                        tag: "headers.brand",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "packageNumber",
                        name: "Package Number",
                        tag: "headers.package_number",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "comment",
                        name: "Comment",
                        tag: "headers.comment",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "status",
                        name: "Status",
                        tag: "headers.status",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "lastConnectionTime",
                        name: "Last Connection",
                        tag: "headers.last_connection",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "relativeExpiryDateRange",
                        name: "Expiry Date",
                        tag: "headers.expiry_date",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    /*  {
                    // for filter menu
                    code: "lastBatteryValue",
                    name: "Battery Level",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                }, */
                    {
                        // for filter menu
                        code: "isArchived",
                        name: "Archived",
                        tag: "headers.archived",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-3 col-lg-2"
                    },
                    {
                        code: "tenant",
                        name: "Tenant",
                        tag: "trackers.tenant",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    }
                    // {
                    //     code: "tenantIsnull",
                    //     name: "Not Assigned",
                    //     tag: "trackers.not_assigned",
                    //     enabled: true,
                    //     // for filter layout
                    //     class: "col-auto col-md-auto"
                    // }
                    // {
                    //     // for filter menu
                    //     code: "kpi",
                    //     name: "KPI",
                    //     enabled: true,
                    //     // for filter layout
                    //     class: "col-auto col-md-3 col-lg-2"
                    // }
                ];
            } else {
                _defaultFilterChoices = [
                    {
                        // for filter menu
                        code: "serialNumber",
                        name: "Serial Number",
                        tag: "headers.serial_number",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "brand",
                        name: "Brand",
                        tag: "headers.brand",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "packageNumber",
                        name: "Package Number",
                        tag: "headers.package_number",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "comment",
                        name: "Comment",
                        tag: "headers.comment",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "status",
                        name: "Status",
                        tag: "headers.status",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "lastConnectionTime",
                        name: "Last Connection",
                        tag: "headers.last_connection",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    {
                        // for filter menu
                        code: "relativeExpiryDateRange",
                        name: "Expiry Date",
                        tag: "headers.expiry_date",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-auto"
                    },
                    /*  {
                    // for filter menu
                    code: "lastBatteryValue",
                    name: "Battery Level",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                }, */
                    {
                        // for filter menu
                        code: "isArchived",
                        name: "Archived",
                        tag: "headers.archived",
                        enabled: true,
                        // for filter layout
                        class: "col-auto col-md-3 col-lg-2"
                    }
                    // {
                    //     // for filter menu
                    //     code: "kpi",
                    //     name: "KPI",
                    //     enabled: true,
                    //     // for filter layout
                    //     class: "col-auto col-md-3 col-lg-2"
                    // }
                ];
            }
            return _defaultFilterChoices;
        },
        relativeExpiryDateRangeChoices: function() {
            let _relativeExpiryDateRangeChoices = [
                this.$t("general.expires7"),
                this.$t("general.expires14"),
                this.$t("general.expires30")
            ];
            return _relativeExpiryDateRangeChoices;
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        filter: {
            handler(value) {
                if (value) {
                    this.$emit("filter-changed", JSON.parse(JSON.stringify(value)));

                   /*  if (this.me.isSuperuser) {
                        helper.setFilterValues("trackerTableFiltersSupport", value);
                    } else {
                        } */
                   helper.setFilterValues("trackerTableFilters", value);
                }
            },
            deep: true
        },
        filterCode: function(code) {
            if (code === "AVAILABLE_TRACKERS") {
                this.tableFilter.status = "inactive";
            }
        },
        relativeExpiryDateRange(val) {
            const _index = this.relativeExpiryDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last 7 days
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(-8, "day")
                        .format("YYYY-MM-DD");
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last 14 days
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(-15, "day")
                        .format("YYYY-MM-DD");
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last 30 days
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(-31, "day")
                        .format("YYYY-MM-DD");
                    this.savedExpiryDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.savedExpiryDateRange = [];
            }
        },
        savedExpiryDateRange(val) {
            if (val.length == 1) {
                this.filter.expiryDateLte = val[0] + "T00:00:00";
                this.filter.expiryDateGte = val[0] + "23:59:59";
            } else if (val.length == 2) {
                this.filter.expiryDateLte = val[0] + "T00:00:00";
                this.filter.expiryDateGte = val[1] + "T23:59:59";
            } else {
                this.filter.expiryDateGte = null;
                this.filter.expiryDateLte = null;
            }
        }
    },
    created() {
        this.TrackerPermissions = TrackerPermissions;
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        if (this.isStaff) {
            this.defaultFilterChoices.push(
                {
                    // for filter menu
                    code: "tenant",
                    name: this.$t("trackers.tenant"),
                    tag: "trackers.tenant",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                },
                {
                    // for filter menu
                    code: "tenantIsnull",
                    name: this.$t("trackers.not_assigned"),
                    tag: "trackers.not_assigned1",
                    enabled: true,
                    // for filter layout
                    class: "col-auto col-md-auto"
                }
            );
        }
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "trackersTableFilters");
    },
    mounted() {
        this.onResize();
        let _filter = null;

       /*  if (this.me.isSuperuser) {
            _filter = helper.getFilterValues("trackerTableFiltersSupport");
        } else {
            } */
       _filter = helper.getFilterValues("trackerTableFilters");

        if (_filter) {
            this.filter = _filter;

            if (_filter && _filter.orderBy && _filter.orderBy.includes("-")) {
                this.sortBy = _filter.orderBy.replace("-", "");
                this.sortAsc = false;
            } else if (_filter && _filter.orderBy) {
                this.sortBy = _filter.orderBy;
                this.sortAsc = true;
            }

            if (_filter.lastConnectionTime_Gte) {
                this.dateRange = [
                    _filter.lastConnectionTime_Gte.split("T")[0],
                    _filter.lastConnectionTime_Lte.split("T")[0]
                ];
            }

            if (_filter.expiryDateGte) {
                const _startDate = moment(_filter.expiryDateGte);
                const _endDate = moment(_filter.expiryDateLte);
                const _diff = _endDate.diff(_startDate, "days");

                if (_diff === 7) {
                    this.relativeExpiryDateRange = "Expires in 7 days";
                } else if (_diff === 14) {
                    this.relativeExpiryDateRange = "Expires in 14 days";
                } else if (_diff === 30) {
                    this.relativeExpiryDateRange = "Expires in 30 days";
                }
            }
        }

        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    trackersTableFilters: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            // window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        // dynamic filter
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        saveDatePicker() {
            if (this.dateRange.length == 1) {
                this.filter.lastConnectionTime_Gte = this.dateRange[0] + "T00:00:00";
                this.filter.lastConnectionTime_Lte = this.dateRange[0] + "23:59:59";
            } else if (this.dateRange.length == 2) {
                this.filter.lastConnectionTime_Gte = this.dateRange[0] + "T00:00:00";
                this.filter.lastConnectionTime_Lte = this.dateRange[1] + "T23:59:59";
            } else {
                this.filter.lastConnectionTime_Gte = null;
                this.filter.lastConnectionTime_Lte = null;
            }
            this.menu = false;
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        },
        onClearDates() {
            this.dateRange = [];
            this.savedDateRange = [];
            this.savedDateExpiry = [];
            this.relativeDateRange = null;
            this.filter.lastConnectionTime_Gte = null;
            this.filter.lastConnectionTime_Lte = null;
            this.filter.expiryDateGte = null;
            this.filter.expiryDateLte = null;
            this.filter.activatedDateGte = null;
            this.filter.activatedDateLte = null;
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices;
        }
    }
};
</script>

<style scoped>
.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 46px;
    z-index: 2;
}

.maxWidth {
    max-width: 210px;
}
</style>
