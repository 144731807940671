<template>
    <v-container fluid class="pa-0">
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col class="3">
                    <h2
                        class="
                        ml-3
                        display-3
                        font-weight-medium
                        d-flex
                        align-top
                    "
                    >
                        {{ $t('home.settings') }}
                    </h2>
                </v-col>
            </v-row>
        </div>
        <!-- Overview  body -->
        <LoadingBar
            v-if="tenantDetail == null && $apollo.queries.tenant.loading"
        ></LoadingBar>
        <v-card flat
            v-else
            max-width="90%"
            class="d-flex justify-center align-center mx-auto mt-5"
            elevation="1"
        >
            <v-card-text>
                <v-tabs vertical>
                    <v-tab>
                        <v-icon left>
                            flight_takeoff
                        </v-icon>
                        {{ $t('headers.trip') }} 
                    </v-tab>
                    <!-- <v-tab>
                        <v-icon left>
                            admin_panel_settings
                        </v-icon>
                        Profile Settings
                    </v-tab> -->
                    <v-tab-item>
                        <v-card flat>
                            <SettingsDetails 
                                :tenant="tenantDetail"
                                class="mx-3 mt-3"  
                            />
                        </v-card>
                    </v-tab-item>
                    <!-- <v-tab-item>
                        <v-card flat>
                            <TenantAdminSettings
                                :tenant="tenant"
                            /> 
                        </v-card>
                    </v-tab-item> -->
                </v-tabs>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import SettingsDetails from "@/components/adminsettings/SettingsDetails.vue";
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";

export default {
    title: "Profile",
    components: {
        SettingsDetails, LoadingBar
    },
    apollo: {
        tenant: {
            query: gql`
                query Tenant($tenantId: ID!) {
                    tenant(id: $tenantId) {
                        id
                        name
                        organisationPermissions
                        organisationPreferences
                        tenantPartnerSet {
                            totalCount
                        }
                        tenantUserSet {
                            totalCount
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return { tenantId: this.tenantId };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenant,
            result(data) {
                if (data.data.tenant) {
                    this.tenantDetail = data.data.tenant;
                }
            },
            skip() {
                return !this.tenantId ? true : false;
            }
        }
    },
    data() {
        return {
            isLoading: false,
            tenantDetail: null,
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        tenantId() {
            return this.me?.tenant?.id;
        }      
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },

    methods: {
        onLoading(val) {
            this.isLoading = val;
        }
    }
};
</script>

<style scoped>
.max-width-settings-details {
    max-width: 800px;
    margin: 0 auto;
}
</style>
