<template>
    <v-container fluid>
        <v-form v-model="valid" ref="locationForm">
            <v-card flat outlined class="pr-3 pt-3 pb-5">
                <v-timeline align-top dense>
                    <v-timeline-item color="white" class="py-0">
                        <template v-slot:icon>
                            <v-icon color="primary">
                                location_on
                            </v-icon>
                        </template>
                        <v-row dense>
                            <v-col cols="11">
                                <v-autocomplete
                                    v-model="trip.origin"
                                    :loading="
                                        $apollo.queries.tenantLocations.loading
                                    "
                                    :items="
                                        tenantLocations
                                            ? tenantLocations.edges
                                            : []
                                    "
                                    :no-data-text="$t('loading.no_available')"
                                    :label="$t('trips.origin')"
                                    item-text="node.name"
                                    item-value="node.id"
                                    outlined
                                    clearable
                                    dense
                                    hide-selected
                                >
                                    <template v-slot:item="data">
                                        <v-row class="ma-0 body-2" dense>
                                            <v-col
                                                cols="9"
                                                class="text-truncate pl-0 ml-0"
                                            >
                                                {{ data.item.node.name }}
                                            </v-col>
                                            <v-col cols="3">
                                                <span
                                                    class="grey--text mr-0 float-right"
                                                    ><i>{{
                                                        selectLabelByType(
                                                            data.item.node.locationType.toLowerCase()
                                                        )
                                                    }}</i></span
                                                >
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1">
                                <v-btn
                                    small
                                    rounded
                                    outlined
                                    color="primary"
                                    @click="openCreateLocationDialog('origin')"
                                    icon
                                    class="mt-2"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item color="white" class="py-0">
                        <template v-slot:icon>
                            <v-icon color="primary">calendar_month</v-icon>
                        </template>
                        <v-row dense>
                            <v-col cols="11" v-if="!templateMode">
                                <DateTimeComponent
                                    v-model="trip.plannedDepartureDate"
                                    :label="
                                        templateMode
                                            ? $t('date.departure_date')
                                            : $t('date.departure_date') + '*'
                                    "
                                    :icon="'calendar_month'"
                                    :rules="[rules.requiredDateTime]"
                                    :outlined="true"
                                    :dense="true"
                                    :disabled="templateMode"
                                    :filled="templateMode"
                                    :defaultTime="
                                        trip.plannedDepartureTime || '00:00'
                                    "
                                >
                                </DateTimeComponent>
                            </v-col>
                            <v-col cols="6" v-if="templateMode">
                                <DateComponent
                                    v-model="trip.plannedDepartureDate"
                                    :label="
                                        templateMode
                                            ? $t('date.departure_date')
                                            : $t('date.departure_date') + '*'
                                    "
                                    :icon="'calendar_month'"
                                    :rules="[rules.required]"
                                    :outlined="true"
                                    :dense="true"
                                    :disabled="templateMode"
                                    :filled="templateMode"
                                ></DateComponent>
                            </v-col>
                            <v-col cols="5" v-if="templateMode">
                                <!--  <TimeComponent
                                    :disabled="
                                        (trip.plannedDepartureDate === null ||
                                            trip.plannedDepartureDate.length <
                                                1) &&
                                            !templateMode
                                    "
                                    v-model="trip.plannedDepartureTime"
                                    :label="
                                        templateMode
                                            ? $t('date.time')
                                            : $t('date.time') + '*'
                                    "
                                    :icon="'more_time'"
                                    :rules="
                                        templateMode ? [] : [rules.required]
                                    "
                                    :dense="true"
                                    :templateMode="templateMode"
                                ></TimeComponent> -->
                                <v-autocomplete
                                    v-model="trip.plannedDepartureTime"
                                    :items="system24"
                                    auto-select-first
                                    :label="$t('date.time')"
                                    outlined
                                    dense
                                    :menu-props="{ maxHeight: '200' }"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item color="white" class="py-0">
                        <template v-slot:icon>
                            <v-icon color="primary">
                                location_on
                            </v-icon>
                        </template>
                        <v-row dense>
                            <v-col cols="11">
                                <v-autocomplete
                                    v-model="trip.destination"
                                    :loading="
                                        $apollo.queries.tenantLocations.loading
                                    "
                                    :no-data-text="$t('loading.no_available')"
                                    :items="
                                        tenantLocations
                                            ? tenantLocations.edges
                                            : []
                                    "
                                    :label="$t('trips.destination')"
                                    item-text="node.name"
                                    item-value="node.id"
                                    outlined
                                    clearable
                                    hide-selected
                                    dense
                                >
                                    <template v-slot:item="data">
                                        <v-row class="ma-0 body-2">
                                            <v-col
                                                cols="9"
                                                class="text-truncate pl-0 ml-0"
                                            >
                                                {{ data.item.node.name }}
                                            </v-col>
                                            <v-col cols="3">
                                                <span
                                                    class="grey--text mr-0 float-right"
                                                    ><i>{{
                                                        selectLabelByType(
                                                            data.item.node.locationType.toLowerCase()
                                                        )
                                                    }}</i></span
                                                >
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1">
                                <v-btn
                                    small
                                    rounded
                                    outlined
                                    color="primary"
                                    @click="
                                        openCreateLocationDialog('destination')
                                    "
                                    icon
                                    class="mt-2"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item color="white" class="py-0">
                        <template v-slot:icon>
                            <v-icon color="primary">calendar_month</v-icon>
                        </template>
                        <v-row dense>
                            <v-col cols="11" v-if="!templateMode">
                                <DateTimeComponent
                                    v-model="trip.plannedArrivalDate"
                                    :label="$t('date.arrival_date')"
                                    :icon="'calendar_month'"
                                    :rules="
                                        trip.plannedArrivalDate !== null &&
                                        trip.plannedArrivalDate.length > 0
                                            ? [rules.checkDate]
                                            : []
                                    "
                                    :outlined="true"
                                    :dense="true"
                                    :disabled="templateMode"
                                    :filled="templateMode"
                                    :defaultTime="
                                        trip.plannedArrivalTime || '00:00'
                                    "
                                >
                                </DateTimeComponent>
                            </v-col>

                            <v-col cols="6" v-if="templateMode">
                                <DateComponent
                                    v-model="trip.plannedArrivalDate"
                                    :label="$t('date.arrival_date') + '*'"
                                    :icon="'calendar_month'"
                                    :outlined="true"
                                    :dense="true"
                                    :disabled="
                                        trip.plannedDepartureDate === null ||
                                            trip.plannedDepartureDate.length <
                                                1 ||
                                            templateMode
                                    "
                                    :filled="templateMode"
                                ></DateComponent>
                            </v-col>
                            <v-col cols="5" v-if="templateMode">
                                <!-- <TimeComponent
                                    :disabled="
                                        (trip.plannedArrivalDate === null ||
                                            trip.plannedArrivalDate.length <
                                                1) &&
                                            !templateMode
                                    "
                                    v-model="trip.plannedArrivalTime"
                                    :label="$t('date.time')"
                                    :icon="'more_time'"
                                    :dense="true"
                                ></TimeComponent> -->
                                <v-autocomplete
                                    v-model="trip.plannedArrivalTime"
                                    :items="system24"
                                    auto-select-first
                                    :label="$t('date.time')"
                                    outlined
                                    dense
                                    :menu-props="{ maxHeight: '200' }"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </v-card>
            <AddLocation
                :openAddLocationDialog="openAddLocation"
                v-on:closeMap="openAddLocation = false"
                v-on:updateLocationsTable="
                    $apollo.queries.tenantLocations.refetch()
                "
                v-on:updateLocation="updateLocation"
            >
            </AddLocation>
        </v-form>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import AddLocation from "@/components/locations/create/AddLocation.vue";
import DateComponent from "@/components/base/DateComponent.vue";
//import TimeComponent from "@/components/base/TimeComponent.vue";
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import helper from "@/utils/helper.js";
import moment from "moment-timezone";

export default {
    props: {
        value: {
            type: Object
        },
        validate: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: ""
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        templateMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AddLocation,
        DateComponent,
        //TimeComponent,
        DateTimeComponent
    },
    apollo: {
        tenantLocations: {
            query: gql`
                query tenantLocations(
                    $search: String
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $tenantPartnerNameIcontains: String
                    $nameIcontains: String
                    $cityIcontains: String
                    $countryIcontains: String
                ) {
                    tenantLocations(
                        search: $search
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: "name"
                        tenantPartner_Name_Icontains: $tenantPartnerNameIcontains
                        name_Icontains: $nameIcontains
                        city_Icontains: $cityIcontains
                        country_Icontains: $countryIcontains
                        locationType_In: [
                            "tenant_location"
                            "partner_location"
                            "private_location"
                        ]
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                locationType
                                isTrackerStockLocation
                                fullAddress
                                city
                                state
                                country
                            }
                        }
                    }
                }
            `,
            variables() {},
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            debounce: 800,
            update: data => {
                return data.tenantLocations;
            },
            skip: false
        }
    },
    data() {
        return {
            openAddLocation: false,
            hide: false,
            locationType: "",
            system24: [
                "00:00",
                "00:30",
                "01:00",
                "01:30",
                "02:00",
                "02:30",
                "03:00",
                "03:30",
                "04:00",
                "04:30",
                "05:00",
                "05:30",
                "06:00",
                "06:30",
                "07:00",
                "07:30",
                "08:00",
                "08:30",
                "09:00",
                "09:30",
                "10:00",
                "10:30",
                "11:00",
                "11:30",
                "12:00",
                "12:30",
                "13:00",
                "13:30",
                "14:00",
                "14:30",
                "15:00",
                "15:30",
                "16:00",
                "16:30",
                "17:00",
                "17:30",
                "18:00",
                "18:30",
                "19:00",
                "19:30",
                "20:00",
                "20:30",
                "21:00",
                "21:30",
                "22:00",
                "22:30",
                "23:00",
                "23:30"
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                requiredDateTime: v => {
                    if (v === null || v === undefined || v === "") {
                        return this.$t("rules.required");
                    }
                    let [date, time] = v.split(" ");
                    return (
                        (!this.isEmpty(date) && !this.isEmpty(time)) ||
                        this.$t("rules.required")
                    );
                },
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.invalid") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                alertIsInvalid: v =>
                    v === false || this.$t("rules.ref_no_exists"),
                //check if planned arrival date is not less than planned departure date
                checkDate: () => {
                    //let _selectedDate = new Date(v)
                    if (
                        this.trip.plannedDepartureDate === null ||
                        this.trip.plannedDepartureDate.length < 1
                    ) {
                        return true;
                    }
                    if (
                        this.trip.plannedArrivalDate === null ||
                        this.trip.plannedArrivalDate.length < 1
                    ) {
                        return true;
                    }

                    let [dateA, timeA] = this.trip.plannedDepartureDate.split(
                        " "
                    );
                    let [dateB, timeB] = this.trip.plannedArrivalDate.split(
                        " "
                    );

                    if (this.isEmpty(dateA) || this.isEmpty(timeA)) {
                        return true;
                    }

                    //dateTime A
                    let _newDateA = moment(dateA, helper.getDateFormat());
                    let _DateA = moment(_newDateA, [
                        "DD/MM/YYYY",
                        "YYYY/MM/DD",
                        "MM/DD/YYYY"
                    ]).toDate();
                    let _dateFormatA = moment(_DateA).format("YYYY-MM-DD");

                    //dateTime B
                    let _newDateB = moment(dateB, helper.getDateFormat());
                    let _DateB = moment(_newDateB, [
                        "DD/MM/YYYY",
                        "YYYY/MM/DD",
                        "MM/DD/YYYY"
                    ]).toDate();
                    let _dateFormatB = moment(_DateB).format("YYYY-MM-DD");

                    let dateTimeA = _dateFormatA + " " + timeA;
                    let dateTimeB = _dateFormatB + " " + timeB;

                    let validDate = moment(dateTimeA).isSameOrBefore(dateTimeB);

                    /*  let _date1 = moment(this.trip.plannedDepartureDate)
                    let _date2 = moment(this.trip.plannedArrivalDate)
                    let dateDiff = _date2.diff(_date1,'days') */

                    return validDate || this.$t("rules.diff");
                }
            }
        };
    },
    computed: {
        trip: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        valid: {
            get() {
                return this.validate;
            },
            set(value) {
                this.$emit("update:validate", value);
            }
        }
    },
    watch: {
        /*  isOpen:function(newVal){
            if(newVal) this.trip.plannedDepartureTime='00:00'
            if(newVal) this.trip.plannedArrivalTime='00:00'
        }, */
        "trip.plannedDepartureDate": function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$refs.locationForm.validate();
            }
        },
        "trip.plannedArrivalDate": function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$refs.locationForm.validate();
            }
        }
    },
    methods: {
        openCreateLocationDialog(type) {
            this.openAddLocation = true;
            this.locationType = type;
        },
        selectLabelByType(val) {
            if (val === "tenant_location") return this.$t("alert_rules.public");
            if (val === "partner_location") return this.$t("home.partner");
            if (val === "private_location") return "";
        },
        resetForm() {
            this.$refs.locationForm.reset();
        },
        updateLocation(id) {
            if (this.locationType === "origin") {
                this.trip.origin = id;
            } else if (this.locationType === "destination") {
                this.trip.destination = id;
            }
        },
        isEmpty(val) {
            if (
                val === null ||
                val === undefined ||
                val === "" ||
                val === "null" ||
                val === "undefined" ||
                val === "NaN" ||
                val === "Invalid date-undefined-undefined"
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
