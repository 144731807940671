import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"elevation":"5","outlined":""}},[_c(VCardText,{staticClass:"pb-3 pr-3"},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('left')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_back")])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"mouseenter":function($event){return _vm.startScrolling('right')},"mouseleave":_vm.stopScrolling}},[_vm._v("arrow_forward")])],1)],1),_c('FilterMenu',{attrs:{"title":_vm.$t('dashboard.customize'),"description":_vm.$t('dashboard.display'),"choices":_vm.defaultHeaderChoices,"open-on-hover":false,"height":"500px","width":"250px"},on:{"changed":_vm.onFilterMenuChanged}})],1),_c(VDataTable,{staticClass:"row-pointer",attrs:{"show-select":_vm.selector,"headers":_vm.dynamicHeaders,"items":_vm.trackers.edges,"item-key":"node.id","loading-text":_vm.$t('loading.loading1'),"loading":_vm.$apollo.queries.trackers.loading,"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":_vm.screenSize === 'xs' || _vm.screenSize === 'sm' ? 800 : 0,"loader-height":"2","no-data-text":_vm.$t('loading.no_data'),"fixed-header":"","height":_vm.tableHeight},on:{"click:row":function (item) { return this$1.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.node.serialNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.node.serialNumber))])]}},{key:"item.node.isArchived",fn:function(ref){
var item = ref.item;
return [(item.node.isArchived == true)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("check_circle")])]}},{key:"item.node.lastConnectionTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.lastConnectionTime)))])]}},{key:"item.node.expiryDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.expiryDate)))])]}},{key:"item.node.lastBatteryValue",fn:function(ref){
var item = ref.item;
return [(
                        item.node.lastBatteryValue &&
                        item.node.brand.toLowerCase() !=
                            'sensitech'
                    )?_c('span',[_vm._v(_vm._s(item.node.lastBatteryValue))]):_vm._e()]}},{key:"item.node.tenant.name",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.node.tenant ? item.node.tenant.name : ""))])]}},{key:"item.node.activatedDate",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.activatedDate)))])]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [(_vm.getTrackerStatus(item) == 'Not Activated')?_c(VChip,{attrs:{"outlined":"","small":"","color":""}},[_vm._v(_vm._s(_vm.$t("general.not_activated")))]):(_vm.getTrackerStatus(item) == 'Activated')?_c(VChip,{attrs:{"outlined":"","small":"","color":"primary"}},[_vm._v(_vm._s(_vm.$t("general.activated")))]):_vm._e()]}},{key:"item.node.createdAt",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.createdAt)))])]}},{key:"item.node.createdBy",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.node.createdBy ? item.node.createdBy.fullName : ""))])]}},{key:"loading",fn:function(){return [_c('tr',[_c('td',{style:(_vm.centralizeLoadBtnClass),attrs:{"colspan":2}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('loading.loading1'))+" ")])])])]},proxy:true}]),model:{value:(_vm.selectedTrackers),callback:function ($$v) {_vm.selectedTrackers=$$v},expression:"selectedTrackers"}},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(_vm._s(_vm.$t("loading.no_data")))])]),_c('template',{slot:"body.append"},[_c('tr',[_c('td',{style:(_vm.centralizeLoadBtnClass),attrs:{"colspan":2}},[(_vm.hasMoreData)?_c(VBtn,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.trackers.loading,"plain":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("loading.load_more"))+" ")]):_vm._e()],1)])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }