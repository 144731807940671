<template>
    <v-card fill-height flat width="100%" v-if="group.node" color="background">
        <v-toolbar flat color="background">
            <!----- TOP LEFT ICONS ---->
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn
                v-if="shouldShowFullScreenButton"
                icon
                @click="changeFullScreen"
            >
                <v-icon>{{
                    isFullScreen ? "fullscreen_exit" : "crop_free"
                }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <!---- TOP RIGHT ICONS ---->
            <v-btn icon>
                <v-icon @click="editGroupDialogHandler(group)"> edit </v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon @click="openDeleteGroupDialog = true"> delete </v-icon>
            </v-btn>

            <!---- EDIT PARTNER DIALOG ---->
            <EditGroupDialog
                ref="editGroupDialog"
                :showGroupSelect="true"
                :openEditGroupDialog="openEditGroupDialog"
                v-on:updatedGroup="updateGroupValues"
                v-on:closeEditGroupDialog="openEditGroupDialog = false"
                :isStaffGroup="true"
            ></EditGroupDialog>

            <!---- DELETE PARTNER DIALOG ---->
            <DeleteGroupDialog
                :openDeleteGroupDialog="openDeleteGroupDialog"
                :groupId="[group.node.id]"
                v-on:refreshDataTable="closeDrawerAndRefresh"
                v-on:closeDeleteDialog="openDeleteGroupDialog = false"
                :isStaffGroup="true"
            >
            </DeleteGroupDialog>

            <!---- FILTER DIALOG ---->
            <FilterMenu
                :title="$t('dashboard.customize')"
                :description="$t('dashboard.display_tabs')"
                :choices="filterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
                class="mr-n2"
            >
            </FilterMenu>
        </v-toolbar>

        <v-card-title class="pt-0 px-5">
            <!---- GROUP GENERAL INFO DISPLAY (NAME, USERS...) ---->
            <v-row class="d-flex align-center pt-2 mx-2">
                <!---- NAME ---->
                <v-col cols="6" sm="3" class="py-0 px-0 mb-1">
                    <span class="text-caption">{{ $t("headers.name") }}</span
                    ><br />
                    <span class="font-weight-medium">
                        {{ updatedGroup ? updatedGroup.name : group.node.name }}
                    </span>
                </v-col>
                <!---- USERS ---->
                <v-col cols="6" sm="3" class="py-0 px-0 mb-1">
                    <span class="text-caption">{{ $t("general.nusers") }}</span
                    ><br />
                    <span class="font-weight-medium">
                        {{
                            updatedGroup
                                ? updatedGroup.group.userSet.totalCount
                                : group.node.group.userSet.totalCount
                        }}
                    </span>
                </v-col>
                <v-spacer></v-spacer>

                <!---- SELECT TAB OR LIST VIEW ---->
                <v-col cols="4" sm="3" class="d-flex justify-end">
                    <v-select
                        v-model="view"
                        :items="viewChoices"
                        dense
                        item-text="name"
                        item-value="code"
                        return-value
                        outlined
                        hide-details
                        class="fit"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-title>

        <!---- tab view ---->
        <StaffGroupDetailTabView
            v-if="view === 'tab'"
            :group="group"
            :selectedFilters="selectedFilters"
            v-on:updatedGroup="updateGroupValues"
        />

        <!---- list view ---->
        <StaffGroupDetailListView
            v-if="view === 'list'"
            class="mx-5 pb-10"
            :group="group"
            :selectedFilters="selectedFilters"
            v-on:updatedGroup="updateGroupValues"
        />
    </v-card>
</template>
<script>
import helper from "@/utils/helper";
import FilterMenu from "@/components/base/FilterMenu.vue";
import StaffGroupDetailTabView from "@/components/staff/groups/StaffGroupDetailTabView.vue";
import StaffGroupDetailListView from "@/components/staff/groups/StaffGroupDetailListView.vue";
import DeleteGroupDialog from "@/components/groups/delete/DeleteGroupDialog.vue";
import EditGroupDialog from "@/components/groups/update/EditGroupDialog.vue";
import Config from "@/utils/config.json";

export default {
    name: "StaffGroupDetail",
    props: {
        group: {
            type: Object,
            required: true
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        StaffGroupDetailTabView,
        StaffGroupDetailListView,
        FilterMenu,
        DeleteGroupDialog,
        EditGroupDialog
    },
    data() {
        return {
            updatedGroup: null,

            openDeleteGroupDialog: false,
            openEditGroupDialog: false,

            view: "tab",
            viewChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "users",
                    name: "Users",
                    tag: "home.users",
                    enabled: true
                },
                {
                    // for filter menu
                    code: "permissions",
                    name: "Permissions",
                    tag: "headers.permissions",
                    enabled: true
                }
            ],
            filterChoices: []
        };
    },
    created() {
        this.viewType = helper.getViewType("staffGroupDetailViewType");
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "staffGroupDetailFilterChoices"
        );
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];

            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        }
    },
    watch: {},
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    staffGroupDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    staffGroupDetailFilterChoices: value
                });
            },
            { deep: true }
        );
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'staff_group_details_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("staff_group_details_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("staff_group_details_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        editGroupDialogHandler(item) {
            this.openEditGroupDialog = true;
            this.$refs.editGroupDialog.initializeEditing(item);
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updateGroupValues(val) {
            // Close edit partner dialog if open
            this.$emit("refreshDataTable");
            this.openEditGroupDialog = false;
            this.updatedGroup = val.coldchaStaffGroup;
        }
    }
};
</script>
<style scoped>
.v-select.fit {
  max-width: min-content;
  padding: 10px;
}
.v-select.fit  .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
</style>