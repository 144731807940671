<template>
    <v-sheet
        color="transparent"
        class="mx-auto d-flex justify-center align-center"
        fill-height
        :width="width"
        :min-height="height"
    >
        <v-row dense>
            <v-col cols="12" class="shrink d-flex justify-center">
                <div class="grey--text text--darken-2 subtitle-2">{{ text }}</div>
            </v-col>
            <v-col cols="12" class="shrink">
                <v-progress-linear
                    :color="color"
                    indeterminate
                    rounded
                    height="3"
                    width="80%"
                    min-width="150"
                ></v-progress-linear>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: "loading-bar",

    props: {
        text: {
            type: String,
            default() {
                return this.$t("loading.loading1");
            }
        },
        color: {
            type: String,
            default: "primary"
        },
        height: {
            type: Number,
            default: 100
        },
        width: {
            type: Number,
            default: 200
        },
        fontSize: {
            type: String,
            default: ""
        }
    }
};
</script>
