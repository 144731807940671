<template>
    <v-dialog max-width="600" v-model="openAddGroupUserDialog" persistent>
        <v-card class="pb-4">
            <!--- ICON AND TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">add_circle</v-icon>
                {{ $t("groups.add_user") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text>
                <v-form ref="groupUserForm">
                    <v-col cols="12" class="pa-0 mt-6">
                        <v-autocomplete
                            dense
                            v-model="selectedUsers"
                            :items="users.edges"
                            :no-data-text="$t('loading.no_available')"
                            :label="$t('groups.select_user') + '*'"
                            outlined
                            hide-selected
                            hide-details
                            deletable-chips
                            small-chips
                            item-text="node.fullName"
                            item-value="node.id"
                            multiple
                            attach
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    x-small
                                    outlined
                                    fab
                                    color="primary"
                                    @click="createNewUser"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-form>
            </v-card-text>
            <!--- ACTION BUTTONS --->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeAndResetInput">Cancel</v-btn>
                <v-btn
                    :loading="isLoadingAddGroupUser"
                    @click="createTenantGroupUser"
                    color="primary"
                    rounded
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
        <AddStaffDialog
            :openAddUserDialog="openCreateNewUserDialog"
            v-on:refreshDataTable="$apollo.queries.users.refresh()"
            v-on:closeAddUserDialog="openCreateNewUserDialog = false"
        ></AddStaffDialog>
    </v-dialog>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import AddStaffDialog from "@/components/staff/users/create/AddStaffDialog.vue";

export default {
    name: "AddGroupUserDialog",
    props: ["openAddGroupUserDialog", "group", "groupUsers"],
    components: { AddStaffDialog },
    apollo: {
        users: {
            query: gql`
                query Users($isStaff: Boolean) {
                    users(isStaff: $isStaff) {
                        edges {
                            node {
                                id
                                firstName
                                lastName
                                fullName
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    isStaff: true
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.users;
            },
            skip: false
        }
    },
    data() {
        return {
            selectedUsers: [],
            users: { edges: [] },
            firstName: null,
            lastName: null,
            email: null,
            requires2fa: false,

            isLoadingAddGroupUser: false,
            openCreateNewUserDialog: false,

            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                invalidEmail: v =>
                    /.+@.+\..+/.test(v) || this.$t("rules.email"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {},
    beforeCreate() {
        this.me = helper.me();
    },
    watch: {
        groupUsers() {
            this.selectedUsers = this.groupUsers.edges.map(item => {
                return item.node.id;
            });
        }
    },
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.selectedUsers = this.groupUsers.edges.map(item => {
                return item.node.id;
            });
            this.$refs.groupUserForm.resetValidation();
            this.$emit("closeAddGroupUserDialog");
        },
        createNewUser() {
            this.openCreateNewUserDialog = true;
        },
        /* ---> TENANT GROUP USER MUTATION <--- */
        createTenantGroupUser() {
            // validate form input :
            if (!this.$refs.groupUserForm.validate()) return;
            // Initialize Loading :
            this.isLoadingAddGroupUser = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateStaffGroupUsers(
                            $input: UpdateStaffGroupUsersInput!
                        ) {
                            updateStaffGroupUsers(input: $input) {
                                coldchaStaffGroup {
                                    id
                                    name
                                    group {
                                        id
                                        userSet {
                                            totalCount
                                            edges {
                                                node {
                                                    id
                                                    firstName
                                                    lastName
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.group.node.id,
                            users: this.selectedUsers
                        }
                    }
                })
                .then(data => {
                    // // Stop Loading //
                    // this.isLoadingAddGroupUser = false;
                    // Emit event to signal user is added and reload table showing users //
                    this.$emit("refreshGroupUsersTable");
                    this.$emit("updatedGroup", data.data.updateStaffGroupUsers);
                    // Close the dialog and reset form fields //
                    this.closeAndResetInput();
                })
                .catch(error => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.isLoadingAddGroupUser = false;
                });
        }
    }
};
</script>
