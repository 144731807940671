<template>
    <v-container class="px-5">
        <!-- Body Content -->
        <LoadingBar v-if="$apollo.queries.tenantLocations.loading && !hasData"></LoadingBar>
        <PartnerLocationForm
            v-else
            :locations="tenantLocations"
            :searchInput.sync="searchInput"
            v-on:update-location="editLocation"
            v-on:delete-location="deleteLocation"
        >
            <template v-slot:addPartnerLocation>
                <v-btn elevation="0" class="mt-2" color="primary" @click="createLocation">
                    {{ $t("partners.add_location1") }}
                </v-btn>
            </template>
        </PartnerLocationForm>
        <!-- Create Location -->
        <AddPartnerLocation
            ref="addPartnerLocation"
            :partner="partner"
            :openAddLocationDialog="openAddLocation"
            v-on:updateLocationsTable="$apollo.queries.tenantLocations.refetch()"
            v-on:closeMap="openAddLocation = false"
        >
        </AddPartnerLocation>
        <!-- Edit Location -->
        <EditLocation
            ref="editLocationDialog"
            :openEditLocationDialog="openEditLocation"
            :showPartnerSelect="false"
            v-on:closeMap="openEditLocation = false"
        ></EditLocation>
        <!-- Delete location -->
        <DeleteLocationDialog
            :openDeleteDialog="openDeleteDialog"
            :locationID="selectedLocations"
            v-on:closeDeleteDialog="closeDeleteDialog"
            v-on:refreshDataTable="$apollo.queries.tenantLocations.refetch()"
        >
            <template #title>{{ $t("partners.delete_location") }}</template>
            <template #content
                ><b>{{ $t("partners.sure_location") }}</b></template
            >
        </DeleteLocationDialog>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import PartnerLocationForm from "@/components/partners/forms/PartnerLocationForm.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";
import AddPartnerLocation from "@/components/partners/create/AddPartnerLocation.vue";
import EditLocation from "@/components/locations/update/EditLocation.vue";
import DeleteLocationDialog from "@/components/locations/delete/DeleteLocationDialog.vue";
import Config from "@/utils/config.json";

export default {
    name: "PreviewPartnerLocationsTab",
    // Your component logic here
    props: {
        partner: {
            type: Object,
            required: true
        }
    },
    components: {
        PartnerLocationForm,
        LoadingBar,
        AddPartnerLocation,
        EditLocation,
        DeleteLocationDialog
    },
    apollo: {
        tenantLocations: {
            query: gql`
                query tenantLocations($search: String, $first: Int, $after: String, $tenantPartnerId: [ID]) {
                    tenantLocations(search: $search, first: $first, after: $after, tenantPartnerId: $tenantPartnerId) {
                        edges {
                            node {
                                id
                                name
                                fullAddress
                                locationType
                                isTrackerStockLocation
                                city
                                state
                                country
                                latitude
                                longitude
                                tags
                                radius
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantPartnerId: [this.partner.id],
                    search: this.searchInput,
                    first: null,
                    after: null
                };
            },
            fetchPolicy: "network-only",
            update: response => {
                return response.tenantLocations.edges;
            },
            skip: false
        }
    },
    data() {
        return {
            openAddLocation: false,
            openDeleteDialog: false,
            openEditLocation: false,
            selectedLocations: [],
            tenantLocations: { edges: [] },
            searchInput: ""
        };
    },
    computed: {
        hasData() {
            return this.tenantLocations.length > 0;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "partner_locations_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("partner_locations_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("partner_locations_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        createLocation() {
            /* update the vuex */
            this.$store.dispatch("partner/updateCreatedPartner", {
                type: "cancel",
                partner: this.partner
            });

            this.$store.dispatch("location/updateCreatedLocation", {
                type: "update",
                location: null
            });

            /* Navigate to create location */
            if (this.partner.partnerType !== "carrier") {
                this.$router.push({
                    path: "/createlocation",
                    query: {
                        id: this.partner.id,
                        type: `createPartnerLocation-${this.partner.partnerType}`,
                        from: "/previewpartners-tab/locations"
                    }
                });
            } else {
                this.$router.push({
                    path: "/createlocation",
                    query: {
                        id: this.partner.id,
                        type: `createPartnerLocation-${this.partner.partnerType}`,
                        from: "/previewcarriers-tab/locations"
                    }
                });
            }
        },
        editLocation(item) {
            /* update the partner vuex */
            this.$store.dispatch("partner/updateCreatedPartner", {
                type: "cancel",
                partner: this.partner
            });

            /* Update edit location */
            this.$store.dispatch("location/updateCreatedLocation", {
                type: "update",
                location: item.node
            });

            /* navigate to edit location */
            this.$router.push({
                path: "/createlocation",
                query: {
                    id: this.partner.id,
                    type: `editPartnerLocation-${this.partner.partnerType}`,
                    from: "/previewpartners-tab/locations"
                }
            });
        },
        deleteLocation(item) {
            this.selectedLocations.push(item.node.id);
            this.openDeleteDialog = true;
        },
        closeDeleteDialog() {
            this.openDeleteDialog = false;
            this.selectedLocations = [];
        }
    }
};
</script>

<style scoped>
/* Your component styles here */
</style>
