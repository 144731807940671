<template>
    <v-container fluid>
        <v-card class="px-6 py-1" outlined>
            <apexcharts ref="overviewChart" type="line" height="270" :options="options" :series="series"></apexcharts>
        </v-card>
    </v-container>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import helper from "@/utils/helper.js";

export default {
    props: {
        startTime: {
            type: String
        },
        endTime: {
            type: String
        },
        eventsLocations: {
            type: Array,
            default: () => []
        },
        currentStartTime: {
            type: String
        },
        currentEndTime: {
            type: String
        },
        temperatureSeries: {
            type: Array,
            default: () => []
        },
        lightSeries: {
            type: Array,
            default: () => []
        },
        humiditySeries: {
            type: Array,
            default: () => []
        },
        shockSeries: {
            type: Array,
            default: () => []
        },
        trackerTemperatures: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Array,
            default: () => []
        },
        probeTemperaturesSeries: {
            type: Array,
            default: () => []
        },
        externalTemperaturesSeries: {
            type: Array,
            default: () => []
        },
        alertTrigger: {
            type: Array,
            default: () => []
        },
        tripAlert: {
            type: Array,
            default: () => []
        },
        minTemperature: {
            type: Number,
            default: 0
        },
        maxTemperature: {
            type: Number,
            default: 100
        },
        minHumidity: {
            type: Number,
            default: 0
        },
        maxHumidity: {
            type: Number,
            default: 100
        },
        minLight: {
            type: Number,
            default: 0
        },
        maxLight: {
            type: Number,
            default: 100
        },
        minShock: {
            type: Number,
            default: 0
        },
        maxShock: {
            type: Number,
            default: 100
        }
    },
    components: {
        apexcharts: VueApexCharts
    },
    data() {
        return {
            warning: require("@/assets/warning2.png")
        };
    },
    computed: {
        temperatureSerie() {
            return this.temperatureSeries || [];
        },
        lightSerie() {
            return this.lightSeries || [];
        },
        humiditySerie() {
            return this.humiditySeries || [];
        },
        shockSerie() {
            return this.shockSeries || [];
        },
        probeTemperaturesSerie() {
            return this.probeTemperaturesSeries || [];
        },
        externalTemperaturesSerie() {
            return this.externalTemperaturesSeries || [];
        },
        sensorSettings() {
            let _sensors = [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    serieColor: "#1B6A99FF",
                    strokeColorOne: "#1B6A99FF",
                    series: [
                        {
                            name: this.$t("general.temperature"),
                            data: this.temperatureSerie
                        }
                    ],
                    yaxis: [
                        {
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            seriesName: this.$t("general.temperature"),
                            title: {
                                text: this.$t("general.temperature") + " " + this.getTemperatureSymbol(),
                                style: {
                                    color: "#1B6A99FF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) + " " + this.getTemperatureSymbol() : "";
                                }.bind(this),
                                style: {
                                    colors: "#1B6A99FF"
                                }
                            },
                            axisBorder: {
                                show: false,
                                color: "#1B6A99FF"
                            }
                        }
                    ]
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    serieColor: "#07A98BFF",
                    strokeColorOne: "#07A98BFF",
                    series: [
                        {
                            name: this.$t("general.humidity"),
                            data: this.humiditySerie
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.humidity"),
                            min: this.minHumidity,
                            max: this.maxHumidity,
                            title: {
                                text: this.$t("general.humidity") + "",
                                style: {
                                    color: "#07A98BFF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) + "" + "%" : "";
                                },
                                style: {
                                    colors: "#07A98BFF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#07A98BFF"
                            }
                        }
                    ],
                    annotations: {
                        yaxis: []
                    }
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    serieColor: "#DEBA04FF",
                    strokeColorOne: "#DEBA04FF",
                    series: [
                        {
                            name: this.$t("general.light"),
                            data: this.lightSerie
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.light"),
                            min: this.minLight,
                            max: this.maxLight,
                            opposite: false,
                            title: {
                                text: this.$t("general.light") + " (Lux)",
                                style: {
                                    color: "#DEBA04FF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) + "" + "Lux" : "";
                                },
                                style: {
                                    colors: "#DEBA04FF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#DEBA04FF"
                            }
                        }
                    ]
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    serieColor: "#FF0000FF",
                    strokeColorOne: "#FF0000FF",
                    series: [
                        {
                            name: this.$t("general.shock"),
                            data: this.shockSerie
                        }
                    ],
                    yaxis: [
                        {
                            min: this.minShock,
                            max: this.maxShock,
                            seriesName: this.$t("general.shock"),
                            opposite: false,
                            title: {
                                text: this.$t("general.shock") + " (G)",
                                style: {
                                    color: "#FF0000FF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) + "" + "G" : "";
                                },
                                style: {
                                    colors: "#FF0000FF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#FF0000FF"
                            }
                        }
                    ]
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    serieColor: "#71C4ECFF",
                    strokeColorOne: "#71C4ECFF",
                    series: [
                        {
                            name: this.$t("general.probe_temperature"),
                            data: this.probeTemperaturesSerie
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.probe_temperature"),
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            title: {
                                text: this.$t("general.probe_temperature") + "" + this.getTemperatureSymbol(),
                                style: {
                                    color: "#71C4ECFF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) + "" + this.getTemperatureSymbol() : "";
                                }.bind(this),
                                style: {
                                    colors: "#71C4ECFF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#71C4ECFF"
                            }
                        }
                    ]
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    serieColor: "#71C4ECFF",
                    strokeColorOne: "#71C4ECFF",
                    series: [
                        {
                            name: this.$t("general.external_temperature"),
                            data: this.externalTemperaturesSerie
                        }
                    ],
                    yaxis: [
                        {
                            seriesName: this.$t("general.external_temperature"),
                            min: this.minTemperature,
                            max: this.maxTemperature,
                            title: {
                                text: this.$t("general.external_temperature") + "" + this.getTemperatureSymbol(),
                                style: {
                                    color: "#71C4ECFF"
                                }
                            },
                            labels: {
                                formatter: function(value) {
                                    return value ? value?.toFixed(0) + "" + this.getTemperatureSymbol() : "";
                                }.bind(this),
                                style: {
                                    colors: "#71C4ECFF"
                                }
                            },
                            axisBorder: {
                                show: true,
                                color: "#71C4ECFF"
                            }
                        }
                    ]
                }
            ];

            return _sensors;
        },
        yaxis() {
            let _yaxis = [];
            this.sensorSettings
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        i.yaxis[0].opposite = false;
                        _yaxis.unshift(i.yaxis[0]);
                    } else {
                        i.yaxis[0].opposite = true;
                        _yaxis.push(i.yaxis[0]);
                    }
                });

            return _yaxis;
        },
        colors() {
            let _colors = [];

            this.sensorSettings
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        _colors.unshift(i.serieColor);
                    } else {
                        _colors.push(i.serieColor);
                    }
                });

            return _colors;
        },
        strokeColors() {
            let _strokeColors = [];

            this.sensorSettings
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        _strokeColors.unshift(i.strokeColorOne);
                    } else {
                        _strokeColors.push(i.strokeColorOne);
                    }
                });

            return _strokeColors;
        },
        annotations() {
            let _annotations = {
                yaxis: [],
                xaxis: [],
                points: []
            };
            let _yaxisIndex = 0;

            let alerts = this.alertTrigger.map(alert => {
                let _trigger = null;
                if (typeof alert == "string") {
                    _trigger = JSON.parse(alert);
                } else _trigger = JSON.parse(alert?.triggerJson);
                return _trigger;
            });

            let alertDisplay = [
                {
                    type: "temperature",
                    symbol: this.getTemperatureSymbol(),
                    position: "left",
                    textAnchor: "start",
                    color: "#1B6A99FF",
                    highestValue: this.maxTemperature
                },
                {
                    type: "humidity",
                    symbol: "%",
                    position: "right",
                    textAnchor: "end",
                    color: "#07A98BFF",
                    highestValue: this.maxHumidity
                },
                {
                    type: "light",
                    symbol: "Lux",
                    position: "right",
                    textAnchor: "end",
                    color: "#DEBA04FF",
                    highestValue: this.maxLight
                },
                {
                    type: "shock",
                    symbol: "G",
                    position: "right",
                    textAnchor: "end",
                    color: "#FF0000FF",
                    highestValue: this.maxShock
                },
                {
                    type: "probeTemperature",
                    symbol: this.getTemperatureSymbol(),
                    position: "left",
                    textAnchor: "start",
                    color: "#71C4ECFF",
                    highestValue: this.maxTemperature
                },
                {
                    type: "externalTemperature",
                    symbol: this.getTemperatureSymbol(),
                    position: "left",
                    textAnchor: "start",
                    color: "#71C4ECFF",
                    highestValue: this.maxTemperature
                },
                {
                    type: "location",
                    symbol: "",
                    position: "left",
                    textAnchor: "start",
                    color: "#71C4ECFF",
                    highestValue: this.maxTemperature || this.maxHumidity || this.maxLight || this.maxShock || 100
                }
            ];

            alerts.forEach(alert => {
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        this.yaxis.findIndex((e, i) => {
                            if (e.seriesName.toLowerCase() == sensor.Type.toLowerCase()) {
                                _yaxisIndex = i;
                            }
                        });

                        if (this.selected.includes(sensor.Type)) {
                            alertDisplay.forEach(display => {
                                if (display.type == sensor.Type) {
                                    if (sensor.Condition == "in") {
                                        let minValue =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value[0])
                                                : sensor.Value[0];
                                        let maxValue =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value[1])
                                                : sensor.Value[1];

                                        //Annotation yaxis
                                        _annotations.yaxis.push(
                                            {
                                                y: minValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: _yaxisIndex,
                                                label: {
                                                    offsetY: 15,
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#64B5F6",
                                                        background: "transparent"
                                                    },
                                                    text: minValue + " " + display.symbol
                                                }
                                            },
                                            {
                                                position: "back",
                                                y: maxValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: _yaxisIndex,
                                                label: {
                                                    offsetY: 15,
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#FF4560",
                                                        background: "transparent"
                                                    },
                                                    text: maxValue + " " + display.symbol
                                                }
                                            }
                                        );
                                    } else if (sensor.Condition == "out") {
                                        let minValue = this.formatTemperature(sensor.Value[0]);
                                        let maxValue = this.formatTemperature(sensor.Value[1]);

                                        //Annotation yaxis
                                        _annotations.yaxis.push(
                                            {
                                                y: minValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: _yaxisIndex,
                                                label: {
                                                    offsetY: 15,
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#64B5F6",
                                                        background: "transparent"
                                                    },
                                                    text: minValue + " " + display.symbol
                                                }
                                            },
                                            {
                                                position: "back",
                                                y: maxValue,
                                                borderColor: display.color,
                                                strokeDashArray: 3,
                                                yAxisIndex: 0,
                                                label: {
                                                    textAnchor: display.textAnchor,
                                                    position: display.position,
                                                    borderColor: undefined,
                                                    style: {
                                                        color: "#FF4560",
                                                        background: "transparent"
                                                    },
                                                    text: maxValue + " " + display.symbol
                                                }
                                            }
                                        );
                                    } else if (sensor.Condition === "gt") {
                                        let value =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value)
                                                : sensor.Value;
                                        //Annotation yaxis
                                        _annotations.yaxis.push({
                                            position: "back",
                                            y: value,
                                            borderColor: display.color,
                                            strokeDashArray: 3,
                                            yAxisIndex: _yaxisIndex,
                                            label: {
                                                offsetY: 0,
                                                textAnchor: display.textAnchor,
                                                position: display.position,
                                                borderColor: undefined,
                                                style: {
                                                    color: "#FF4560",
                                                    background: "transparent"
                                                },
                                                text: value + " " + display.symbol
                                            }
                                        });
                                    } else if (sensor.Condition === "lt") {
                                        let value =
                                            sensor.Type == "temperature"
                                                ? this.formatTemperature(sensor.Value)
                                                : sensor.Value;

                                        //Annotation yaxis
                                        _annotations.yaxis.push({
                                            position: "back",
                                            y: value,
                                            borderColor: display.color,
                                            strokeDashArray: 3,
                                            yAxisIndex: _yaxisIndex,
                                            label: {
                                                offsetY: 15,
                                                textAnchor: display.textAnchor,
                                                position: display.position,
                                                borderColor: undefined,
                                                style: {
                                                    color: "#64B5F6",
                                                    background: "transparent"
                                                },
                                                text: value + " " + display.symbol
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
            });

            if (Array.isArray(this.tripAlert)) {
                this.tripAlert.forEach(tripAlert => {
                    alertDisplay.forEach(display => {
                        if (tripAlert.type.includes(display.type)) {
                            //Annotation xaxis
                            _annotations.xaxis.push({
                                x: tripAlert.deviceTime,
                                borderColor: "#FF4560",
                                strokeDashArray: 0,
                                label: {
                                    borderColor: undefined,
                                    text: this.annotationText,
                                    offsetX: 0,
                                    offsetY: 0,
                                    orientation: "vertical",
                                    position: "bottom",
                                    textAnchor: "middle",
                                    width: 100,
                                    height: 50,
                                    style: {
                                        color: "#BDBDBD",
                                        background: "transparent",
                                        fontSize: "11px",
                                        cssClass: "apexcharts-xaxis-label"
                                    }
                                }
                            });
                        }
                    });
                });
            }
            if (Array.isArray(this.tripAlert)) {
                this.tripAlert.forEach(tripAlert => {
                    alertDisplay.forEach(display => {
                        if (tripAlert.type.includes(display.type)) {
                            //Annotation point
                            _annotations.points.push({
                                x: tripAlert.deviceTime,
                                y: tripAlert.alertValue,
                                marker: {
                                    size: 4,
                                    fillColor: "#FF4560",
                                    strokeColor: "#fff",
                                    radius: 1,
                                    cssClass: "apexcharts-custom-class"
                                },
                                label: {
                                    offsetY: 0,
                                    offsetX: 0,
                                    textAnchor: "middle",
                                    text: null,
                                    borderColor: "trasparent"
                                },
                                image: {
                                    path: this.warning,
                                    width: 21,
                                    height: 21,
                                    offsetX: 0,
                                    offsetY: 0,
                                    style: {
                                        cssClass: "apexcharts-custom-class"
                                    }
                                }
                            });
                        }
                    });
                });
            }
            //annotations location
            let textDetail = [
                {
                    type: "flight_depart",
                    text: this.$t("trips.flight")
                },
                {
                    type: "flight_arrive",
                    text: this.$t("trips.arrived")
                },
                {
                    type: "geofence_exit",
                    text: this.$t("general.exits")
                },
                {
                    type: "geofence_enter",
                    text: this.$t("general.enter")
                }
            ];

            /* event location */
            this.eventsLocations?.forEach((event, index) => {
                if (Object.keys(event).length > 1) {
                    let startEventTime = this.updateDateAndGetTime(event[0].eventTime);
                    let endEventTime = this.updateDateAndGetTime(event[1].eventTime);
                    _annotations.xaxis.push({
                        x: startEventTime,
                        x2: endEventTime,
                        fillColor:
                            event[0].eventType === "flight_depart" ? "#CB5858AD" : this.getColorByEventType(index),
                        opacity: event[0].eventType === "flight_depart" ? 0.35 : 0.25,
                        strokeDashArray: 0,
                        label: {
                            borderColor: undefined,
                            text:
                                event[0].eventType === "flight_depart"
                                    ? this.textTruncate(
                                          textDetail.find(e => e.type == event[0].eventType)?.text +
                                              " " +
                                              this.textTruncate(event[0].eventName)
                                      )
                                    : this.textTruncate(event[0].eventName),
                            offsetX: 15,
                            offsetY: 0,
                            orientation: "vertical",
                            position: "bottom",
                            textAnchor: "middle",
                            width: 100,
                            height: 50,
                            style: {
                                color: "#686868",
                                background: "transparent",
                                fontSize: "11px",
                                cssClass: "apexcharts-xaxis-label"
                            }
                        }
                    });
                } else {
                    /*  let startEventTime = this.updateDateAndGetTime(
                        event[0].eventTime
                    ); */
                    /* _annotations.xaxis.push({
                        x: startEventTime,
                        fillColor: this.getColorByEventType(index),
                        borderColor: this.getColorByEventType(index),
                        opacity: 0.25,
                        strokeDashArray: 0,
                        label: {
                            borderColor: undefined,
                            text:
                                textDetail.find(
                                    e => e.type == event[0].eventType
                                )?.text +
                                " " +
                                this.textTruncate(event[0].eventName),
                            offsetX: 15,
                            offsetY: 0,
                            orientation: "vertical",
                            position: "bottom",
                            textAnchor: "middle",
                            width: 100,
                            height: 50,
                            style: {
                                color: "#000000",
                                background: "transparent",
                                fontSize: "11px",
                                cssClass: "apexcharts-xaxis-label",
                                fontWeight: 400
                            }
                        }
                    }); */
                }
            });

            return _annotations;
        },
        annotationText() {
            let text = "";

            let alertDisplay = [
                {
                    type: "temperature",
                    name: this.$t("general.temperature"),
                    symbol: this.getTemperatureSymbol()
                },
                {
                    type: "humidity",
                    name: this.$t("general.humidity"),
                    symbol: "%"
                },
                {
                    type: "light",
                    name: this.$t("general.light"),
                    symbol: "Lux"
                },
                {
                    type: "shock",
                    name: this.$t("general.shock"),
                    symbol: "G"
                },
                {
                    type: "probeTemperature",
                    name: this.$t("general.probe_temperature"),
                    symbol: this.getTemperatureSymbol()
                },
                {
                    type: "externalTemperature",
                    name: this.$t("general.external_temperature"),
                    symbol: this.getTemperatureSymbol()
                },
                {
                    type: "location",
                    name: this.$t("general.location"),
                    symbol: ""
                }
            ];

            if (Array.isArray(this.tripAlert)) {
                this.tripAlert.forEach(tripAlert => {
                    alertDisplay.forEach(display => {
                        if (tripAlert.type.includes(display.type)) {
                            for (let index = 0; index < tripAlert.type.length; index++) {
                                if (
                                    tripAlert.type[index] != tripAlert.type[index - 1] &&
                                    !text.toLocaleLowerCase().includes(tripAlert.type[index])
                                ) {
                                    //capilize first letter
                                    let firstLetter = tripAlert.type[index].charAt(0).toUpperCase();
                                    let newText = firstLetter + tripAlert.type[index].slice(1);
                                    text = text + newText + " ";

                                    //if text have than 3 word then break loop
                                    if (text.split(" ").length > 2) {
                                        break;
                                    }
                                }
                            }
                            //text = display.name +" "+"Alert " ;
                        }
                    });
                });
            }

            text = text + this.$t("headers.alert");
            return text;
        },

        series() {
            let _series = [];

            this.sensorSettings
                .filter(e => this.selected.includes(e.value))
                .forEach(i => {
                    if (this.selected[0] === i.value) {
                        _series.unshift({
                            name: i.series[0].name,
                            data: i.series[0].data ? i.series[0].data : []
                        });
                    } else {
                        _series.push({
                            name: i.series[0].name,
                            data: i.series[0].data ? i.series[0].data : []
                        });
                    }
                });
            return _series;
        },
        minX() {
            return new Date(this.startTime).getTime();
        },
        maxX() {
            if (this.endTime) return new Date(this.endTime).getTime();
            else return new Date().getTime();
        },
        options() {
            let _options = {
                chart: {
                    id: "temperature-chart",
                    type: "line",
                    height: "100%",
                    width: "100%",
                    animations: {
                        enabled: false
                    },
                    /* selection: {
                        enabled: true
                    },  */
                    toolbar: {
                        show: true,
                        offsetX: -80,
                        offsetY: 0,
                        tools: {
                            download: false,
                            selection: true,
                            zoom: true,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            customIcons: [
                                {
                                    icon: `<i class='material-icons px-1'
                                            style='
                                                font-size: 19px; 
                                                color:#90A4AE !important; 
                                                margin-top: 2px;
                                            '
                                        >
                                            filter_center_focus 
                                        </i>`,
                                    index: 1,
                                    title: "Reset",
                                    class: "custom-icon",
                                    click: () => {
                                        this.handleResetZoom();
                                    }
                                }
                            ],
                            reset: false
                        }
                    }
                },
                legend: {
                    show: false
                },
                annotations: {
                    yaxis: []
                },
                dataLabels: {
                    enabled: false
                },
                noData: {
                    text: this.$t("loading.no_available"),
                    align: "center",
                    verticalAlign: "middle"
                },
                zoom: {
                    autoScaleYaxis: true
                },
                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"],
                        opacity: 0.5
                    }
                },
                xaxis: {
                    type: "datetime",
                    min: this.minX,
                    max: this.maxX,
                    labels: {
                        minHeight: 70,
                        style: {
                            fontSize: "12px",
                            fontWeight: 600,
                            lineHeight: "13px",
                            letterSpacing: "0em",
                            textAlign: "left"
                        },
                        offsetX: 0,
                        offsetY: 15,
                        datetimeUTC: false,
                        datetimeFormatter: {
                            year: "yyyy",
                            month: "MMM 'yy",
                            day: "dd MMM",
                            hour: "HH:mm"
                        }
                    },
                },
                yaxis: [],
                colors: [],
                markers: {
                    // size: [0, 0.5, 0, 0.5],
                    strokeColors: [],
                    showNullDataPoints: true,
                    hover: { sizeOffset: 3 }
                },
                tooltip: {
                    x: {
                        show: false,
                        format: "dd MMM yyyy HH:mm"
                    }
                },
                stroke: {
                    curve: "smooth",
                    width: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                    dashArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                fill: {
                    opacity: 1
                }
            };

            _options.yaxis = [...this.yaxis];
            _options.colors = [...this.colors];
            _options.markers.strokeColors = [...this.strokeColors];
            _options.annotations = { ...this.annotations };

            return _options;
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.convertDateTimeTimezoneToMilliseconds = helper.convertDateTimeTimezoneToMilliseconds;
    },
    methods: {
        getColorByEventType(index) {
            //create a theme index color
            let themeIndex = index % 10;
            let color = "";
            switch (themeIndex) {
                case 0:
                    color = "#4B0082";
                    break;
                case 1:
                    color = "#FFA500";
                    break;
                case 2:
                    color = "#FFFF00";
                    break;
                case 3:
                    color = "#008000";
                    break;
                case 4:
                    color = "#0000FF";
                    break;
                case 5:
                    color = "#00897B";
                    break;
                case 6:
                    color = "#EE82EE";
                    break;
                case 7:
                    color = "#FFC0CB";
                    break;
                case 8:
                    color = "#1A237E";
                    break;
                case 9:
                    color = "#80DEEAFF";
                    break;
                case 10:
                    color = "#512DA8";
                    break;
                case 11:
                    color = "#FF5252";
                    break;
                case 12:
                    color = "#C0CA33";
                    break;
                case 13:
                    color = "#FF6E40";
                    break;
                case 14:
                    color = "#69F0AE";
                    break;
                case 15:
                    color = "#E040FB";
                    break;
                case 16:
                    color = "#448AFF";
                    break;
                case 17:
                    color = "#FFAB00";
                    break;
                case 18:
                    color = "#43A047FF";
                    break;
                case 19:
                    color = "#AD1457";
                    break;
                case 20:
                    color = "#6D4C41";
                    break;
                default:
                    color = "#FF0000";
                    break;
            }
            return color;
        },
        updateDateAndGetTime(date) {
            let updateDate = this.convertDateTimeTimezoneToMilliseconds(date);
            return updateDate;
        },
        handleResetZoom() {
            const apexChart = this.$refs.overviewChart;
            apexChart.refresh();
        },
        textTruncate(text) {
            if (text.length > 30) {
                return text.substring(0, 30) + "...";
            }
            return text;
        }
    }
};
</script>
<style scoped></style>
