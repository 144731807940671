<template>
    <v-card elevation="0" flat v-resize="onResize" class="background">
        <!-- BODY-->
        <v-card-text class="pb-3 px-0">
            <v-row>
                <v-col cols="12">
                    <div v-if="tableLoading && !hasData">
                        <LoadingBar></LoadingBar>
                    </div>
                    <div>
                        <div v-if="hasData">
                            <v-card
                                v-for="location in locationTableItems"
                                :key="location.node.id"
                                class="mb-4 rounded-lg px-3"
                                outlined
                                flat
                                @click.stop="selectLocation(location)"
                            >
                                <v-card-text>
                                    <v-row>
                                        <v-col
                                            v-for="item in tableCard(location)"
                                            :key="item.text"
                                            :cols="item.cols.xs"
                                            :xs="item.cols.xs"
                                            :sm="item.cols.sm"
                                            :md="item.cols.md"
                                            :lg="item.cols.lg"
                                            :xl="item.cols.xl"
                                        >
                                            <div v-if="item.tag === 'tags'">
                                                <div class="textHeader text-truncate">
                                                    {{ item.title }}
                                                </div>
                                                <v-row class="d-flex flex-row justify-start align-center" dense>
                                                    <v-col
                                                        v-for="(tag, i) in item.value"
                                                        :key="i"
                                                        class="textBody text-truncate"
                                                        :style="item.style"
                                                        cols="5"
                                                    >
                                                        <v-icon
                                                            v-if="item.icon"
                                                            size="8"
                                                            class="mr-1"
                                                            :color="item.colorIcon"
                                                            >{{ item.icon }}</v-icon
                                                        >
                                                        <span>
                                                            {{ tag.text }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div v-else>
                                                <div class="textHeader text-truncate">
                                                    {{ item.title }}
                                                </div>
                                                <div class="textBody text-truncate" :style="item.style">
                                                    <v-icon
                                                        v-if="item.icon"
                                                        size="8"
                                                        class="mr-1"
                                                        :color="item.colorIcon"
                                                        >{{ item.icon }}</v-icon
                                                    >
                                                    <span>
                                                        {{ item.value }}
                                                    </span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div v-else-if="!tableLoading && !hasData">
                            <v-row class="d-flex align-center justify-center">
                                <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="text-center">
                                    <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')"></v-img>
                                    <v-card
                                        flat
                                        color="transparent"
                                        v-if="tabFilter !== 'publicLocations'"
                                        class="text-center"
                                    >
                                        <v-card-title>
                                            <div class="textTitle">
                                                {{ !hasData ? $t("general.empty_data_title") : "No history" }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text class="text-center">
                                            <div class="textSubtitle">
                                                {{ !hasData ? $t("general.empty_data_subtitle_1") : "No history" }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="px-10">
                                            <v-btn color="primary" @click="createLocation" block elevation="0">
                                                {{ $t("partners.add_location1") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    <v-card v-else flat color="transparent" class="text-center">
                                        <v-card-title>
                                            <div class="textTitle">
                                                {{ !hasData ? $t("general.empty_data_title") : "No history" }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text v-if="tabFilter === 'publicLocations'" class="text-center">
                                            <div class="textSubtitle">
                                                {{ !hasData ? $t("general.report_empty_data_subtitle") : "No history" }}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="tableLoading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                {{ $t("loading.load_more") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import gql from "graphql-tag";
//
import LoadingBar from "@/components/base/LoadingBar.vue";
import helper from "@/utils/helper.js";
export default {
    name: "PartnerUsersTab",
    components: {
        //CustomTabs,
        LoadingBar
    },
    props: {
        locationsFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        search: {
            type: String,
            required: false,
            default: null
        },
        tabFilter: {
            type: String,
            default: "locations"
        },
        orderBy: {
            type: String,
            required: false,
            default: null
        },
        publicLocationType: {
            type: String,
            required: false,
            default: "air_port"
        }
    },
    apollo: {
        locations: {
            query: gql`
                query tenantLocations(
                    $search: String
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $tenantPartnerNameIcontains: String
                    $tenantPartnerPartnerTypeIexact: String
                    $nameIcontains: String
                    $cityIcontains: String
                    $countryIcontains: String
                    $locationType_In: [String]
                    $tagsIcontains: String
                ) {
                    tenantLocations(
                        search: $search
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        tenantPartner_Name_Icontains: $tenantPartnerNameIcontains
                        name_Icontains: $nameIcontains
                        city_Icontains: $cityIcontains
                        country_Icontains: $countryIcontains
                        locationType_In: $locationType_In
                        tags_Icontains: $tagsIcontains
                        tenantPartner_PartnerType_Iexact: $tenantPartnerPartnerTypeIexact
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                city
                                state
                                country
                                locationType
                                isTrackerStockLocation
                                fullAddress
                                latitude
                                longitude
                                radius
                                tags
                                weatherForecastSubscriptionSet {
                                    edges {
                                        node {
                                            minTemperature
                                            maxTemperature
                                            enabled
                                            lastSyncTime
                                            tenantLocationWeatherForecastSet {
                                                edges {
                                                    node {
                                                        id
                                                        maxTemperature
                                                        minTemperature
                                                        forecastDate
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                let request = {
                    ...this.filterTable,
                    ...this.locationsFilter,
                    search: this.search
                };
                return request;
            },
            skip() {
                let _skip = this.tabFilter !== "myLocations" && this.tabFilter !== "partnerLocations";
                return _skip;
            },
            fetchPolicy: "network-only",
            debounce: 800,
            watchLoading(isLoading) {
                if (this.tabFilter !== "publicLocations") {
                    this.tableLoading = isLoading;
                }

                this.$emit("loading", isLoading);
            },
            update: response => {
                return response.tenantLocations;
            }
        },
        airports: {
            query: gql`
                query Airports(
                    $search: String
                    $first: Int
                    $last: Int
                    $after: String
                    $before: String
                    $orderBy: String
                    $cityIcontains: String
                    $countryIcontains: String
                    $nameIcontains: String
                ) {
                    airports(
                        search: $search
                        first: $first
                        last: $last
                        after: $after
                        before: $before
                        orderBy: $orderBy
                        city_Icontains: $cityIcontains
                        country_Icontains: $countryIcontains
                        name_Icontains: $nameIcontains
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                code
                                name
                                city
                                country
                                locationType
                                longitude
                                latitude
                                radius
                            }
                        }
                    }
                }
            `,
            variables() {
                let request = {
                    ...this.filterTable,
                    ...this.locationsFilter,
                    search: this.search
                };

                return request;
            },
            skip() {
                // return ;
                let _skip =
                    this.title !== this.$t("partners.public_locations") && this.publicLocationType === "sea_port";

                return _skip;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            debounce: 800,
            watchLoading(isLoading) {
                if (this.tabFilter === "publicLocations" && this.publicLocationType === "air_port") {
                    this.tableLoading = isLoading;
                }
                this.$emit("loading", isLoading);
            },
            update: response => {
                return response.airports;
            }
        },
        seaports: {
            query: gql`
                query SeaPorts(
                    $search: String
                    $first: Int
                    $last: Int
                    $after: String
                    $before: String
                    $orderBy: String
                    $cityIcontains: String
                    $countryIcontains: String
                    $nameIcontains: String
                ) {
                    seaPorts(
                        search: $search
                        first: $first
                        last: $last
                        after: $after
                        before: $before
                        orderBy: $orderBy
                        city_Icontains: $cityIcontains
                        country_Icontains: $countryIcontains
                        name_Icontains: $nameIcontains
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                code
                                name
                                city
                                country
                                locationType
                                longitude
                                latitude
                                radius
                            }
                        }
                    }
                }
            `,
            variables() {
                let request = {
                    ...this.filterTable,
                    ...this.locationsFilter,
                    search: this.search
                };
                return request;
            },
            skip() {
                let _skip =
                    this.title !== this.$t("partners.public_locations") && this.publicLocationType === "air_port";
                return _skip;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            debounce: 800,
            watchLoading(isLoading) {
                if (this.tabFilter === "publicLocations" && this.publicLocationType === "sea_port") {
                    this.tableLoading = isLoading;
                }
                this.$emit("loading", isLoading);
            },
            update: response => {
                return response.seaPorts;
            }
        }
    },
    data() {
        return {
            locations: { edges: [] },
            airports: { edges: [] },
            seaports: { edges: [] },
            tableHeight: 800,
            shouldLoadMore: false,
            filterTable: {
                first: 45,
                last: null,
                before: null,
                after: null,
                orderBy: null,
                tenantPartnerNameIcontains: null,
                tenantPartnerPartnerTypeIexact: null,
                nameIcontains: null,
                cityIcontains: null,
                countryIcontains: null,
                locationType_In: null,
                tagsIcontains: null
            },
            tableLoading: true
        };
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        locationTableItems() {
            switch (this.tabFilter) {
                case "publicLocations":
                    if (this.publicLocationType === "sea_port") {
                        return this.seaports?.edges;
                    } else {
                        return this.airports?.edges;
                    }
                default:
                    return this.locations?.edges;
            }
        },
        hasData() {
            if (this.tabFilter === "publicLocations") {
                if (this.publicLocationType === "sea_port") {
                    return this.seaports?.edges?.length > 0;
                } else {
                    return this.airports?.edges?.length > 0;
                }
            } else {
                return this.locations?.edges?.length > 0;
            }
        },
        hasMoreData() {
            if (this.tabFilter === "publicLocations") {
                if (this.publicLocationType === "sea_port") {
                    return this.seaports?.pageInfo?.hasNextPage;
                } else {
                    return this.airports?.pageInfo?.hasNextPage;
                }
            } else return this.locations?.pageInfo?.hasNextPage;
        },
        tagsChoices: function() {
            let _tagsChoices = [
                {
                    text: this.$t("headers.warehouse"),
                    value: "warehouse"
                },
                {
                    text: this.$t("headers.border_post"),
                    value: "border_post"
                },
                {
                    text: this.$t("headers.cold_store"),
                    value: "cold_store"
                },
                {
                    text: this.$t("headers.empty_depot"),
                    value: "empty_depot"
                }
            ];
            return _tagsChoices;
        }
    },
    mounted() {
        this.$nextTick(() => {
            let _filter = null;

            _filter = helper.getFilterValues(`${this.tabFilter}TableFilters`);
            switch (this.tabFilter) {
                case "myLocations":
                    if (!_filter) _filter = this.filter;
                    _filter.locationType_In = ["tenant_location", "private_location"];
                    break;
                case "partnerLocations":
                    if (!_filter) _filter = this.filter;
                    _filter.locationType_In = ["partner_location"];
                    break;
                case "publicLocations":
                    if (!_filter) _filter = this.filter;
                    _filter.locationType_In = null;
                    break;
                case "weather":
                    if (!_filter) _filter = this.filter;
                    break;
            }

            //merge filter with locationsFilter
            this.filterTable = _filter;
        });
    },
    watch: {
        orderBy: {
            handler(val) {
                this.filterTable.orderBy = val;
            },
            immediate: true,
            deep: true
        },
        tabFilter: {
            handler: function(val) {
                this.tableLoading = true;
                this.refreshLocations();
                this.refreshPorts();
                this.locations = { edges: [] };
                this.airports = { edges: [] };
                this.seaports = { edges: [] };

                switch (val) {
                    case "myLocations":
                        this.filterTable.locationType_In = ["tenant_location", "private_location"];
                        break;
                    case "partnerLocations":
                        this.filterTable.locationType_In = ["partner_location"];
                        break;
                    case "publicLocations":
                        this.filterTable.locationType_In = null;
                        break;
                    case "weather":
                        this.filterTable.locationType_In = null;
                        break;
                }
            },
            deep: true,
            immediate: true
        },
        filterTable: {
            handler: function(val) {
                this.tableLoading = true;
                this.refreshLocations();
                this.$emit("update:filter", val);
                if (this.tabFilter) {
                    helper.setFilterValues(`${this.tabFilter}TableFilters`, val);
                }
            },
            deep: true
        },
        locationsFilter: {
            handler(newValue, oldValue) {
                let newValueString = JSON.parse(JSON.stringify(newValue));
                let oldValueString = JSON.parse(JSON.stringify(oldValue));
                if (newValueString !== oldValueString) {
                    this.tableLoading = true;
                    this.refreshLocations();
                    this.refreshPorts();
                }
            },
            deep: true
        }
    },
    methods: {
        createLocation() {
            if (this.tabFilter === "myLocations") {
                this.$router.push({
                    path: "/createlocation",
                    query: {
                        type: "createLocation"
                    }
                });
            } else if (this.tabFilter === "partnerLocations") {
                this.$router.push({
                    path: "/createlocation",
                    query: {
                        type: "createPartnerLocation"
                    }
                });
            }
        },
        weatherForecast(location) {
            let weather = location.node.weatherForecastSubscriptionSet.edges;
            if (weather.length > 0) {
                return weather[0].node;
            }
            return null;
        },
        selectLocation(location) {
            this.$emit("click:row", location);
        },
        tableCard(location) {
            const containerStyle = tag => {
                return `
                    background-color: ${this.statusColor(tag).color1};
                    border-radius: 4px;
                    padding:2px;
                    margin: 3px 4px 0px 0px;
                    width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `;
            };

            const containerStyle2 = tag => {
                return `
                    background-color: ${this.statusColor(tag).color1};
                    border-radius: 4px;
                    padding:2px;
                    width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `;
            };

            const getPayload = item => {
                if (this.tabFilter === "myLocations") {
                    const { name, city, country, fullAddress, locationType, tags } = item.node;
                    let _tags = this.convertJsonToArray(tags);

                    return [
                        {
                            title: this.$t("headers.name"),
                            tag: "locationName",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: name ? name : "N/A",
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("partners.city"),
                            tag: "city",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: `${city}, ${country}`,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 3,
                                xl: 3
                            }
                        },
                        {
                            title: this.$t("headers.address"),
                            tag: "address",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: fullAddress,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 4,
                                xl: 4
                            }
                        },
                        // {
                        //     title: this.$t("partners.country"),
                        //     tag: "country",
                        //     icon: null,
                        //     colorIcon: null,
                        //     style: "",
                        //     align: "left",
                        //     value: country,
                        //     cols: 2
                        // },
                        {
                            title: this.$t("headers.tags"),
                            tag: "tags",
                            color: "primary",
                            icon: null,
                            colorIcon: this.statusColor(tags).color2,
                            style: containerStyle(tags),
                            align: "center",
                            value: _tags,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.scope"),
                            tag: "scope",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "center",
                            value: this.replaceScopeName(locationType),
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 1,
                                xl: 1
                            }
                        }
                    ];
                } else if (this.tabFilter === "partnerLocations") {
                    const { name, city, country, fullAddress, isTrackerStockLocation, tenantPartner } = item.node;
                    return [
                        {
                            title: this.$t("headers.name"),
                            tag: "locationName",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: name,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("partners.city"),
                            tag: "city",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: `${city}, ${country}`,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.address"),
                            tag: "address",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: fullAddress,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 3,
                                xl: 3
                            }
                        },
                        // {
                        //     title: this.$t("partners.country"),
                        //     tag: "country",
                        //     icon: null,
                        //     colorIcon: null,
                        //     style: "",
                        //     align: "left",
                        //     value: country,
                        //     cols: 1
                        // },
                        {
                            title: this.$t("headers.collaborator"),
                            tag: "partnerName",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: tenantPartner?.name,
                            cols: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.collaborator_type1"),
                            tag: "partnerType",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: tenantPartner?.partnerType,
                            cols: {
                                xs: 6,
                                sm: 6,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("trackers.stock_location"),
                            tag: "stockLocation",
                            color: "primary",
                            icon: null,
                            colorIcon: this.statusColor(isTrackerStockLocation).color2,
                            style: containerStyle2(isTrackerStockLocation),
                            align: "center",
                            value: isTrackerStockLocation ? "Yes" : "No",
                            cols: {
                                xs: 6,
                                sm: 6,
                                md: 4,
                                lg: 1,
                                xl: 1
                            }
                        }
                    ];
                } else if (this.tabFilter === "publicLocations") {
                    const { name, city, country, code, locationType } = item.node;
                    return [
                        {
                            title: this.$t("headers.name"),
                            tag: "locationName",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: name,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 4,
                                xl: 4
                            }
                        },
                        {
                            title: this.$t("partners.city"),
                            tag: "city",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: city,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 3,
                                xl: 3
                            }
                        },
                        {
                            title: this.$t("partners.country"),
                            tag: "country",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: country,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.code"),
                            tag: "code",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "left",
                            value: code,
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 2,
                                xl: 2
                            }
                        },
                        {
                            title: this.$t("headers.location_type"),
                            tag: "locationType",
                            icon: null,
                            colorIcon: null,
                            style: "",
                            align: "center",
                            value: this.replaceScopeName(locationType),
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 4,
                                lg: 1,
                                xl: 1
                            }
                        }
                    ];
                } else if (this.tabFilter === "weather") {
                    const { name, city, country, code, locationType } = item.node;
                    return {
                        name,
                        city,
                        country,
                        code,
                        locationType
                    };
                } else {
                    return [];
                }
            };

            const payload = getPayload(location);

            return payload;
        },
        convertJsonToArray(tags) {
            if (tags) {
                try {
                    let tagsArray = JSON.parse(tags.replace(/'/g, '"'));
                    let _tagsArray = this.tagsChoices.reduce((acc, tag) => {
                        let isUnique = !acc.find(t => t.value === tag.value);
                        let isPresent = tagsArray.find(t => t === tag.value);
                        if (isUnique && isPresent) {
                            acc.push(tag);
                        }
                        return acc;
                    }, []);

                    return _tagsArray;
                } catch (error) {
                    console.log(error);
                    return null;
                }
            }
            return null;
        },
        statusColor(status) {
            if (status) {
                return {
                    color1: "#C8E6C9",
                    color2: "#1B5E20"
                };
            } else {
                return {
                    color1: "#FFCDD2",
                    color2: "#F44336"
                };
            }
        },
        statusText(status) {
            if (status) {
                return this.$t("headers.enabled");
            } else {
                return this.$t("headers.disabled");
            }
        },
        customCol(item) {
            if (item.tag === "tags") {
                return 2;
            } else if (item.tag === "scope") {
                return 2;
            } else if (item.tag === "partnerType") {
                return 1;
            } else if (item.tag === "stockLocation") {
                return 1;
            } else {
                return 2;
            }
        },
        replaceScopeName(item) {
            if (item === "TENANT_LOCATION") {
                return this.$t("alert_rules.public");
            } else if (item === "PRIVATE_LOCATION") {
                return this.$t("alert_rules.nonpublic");
            } else if (item.trim() === "AIR_PORT") {
                return this.$t("headers.airport");
            } else if (item.trim() === "SEA_PORT") {
                return this.$t("headers.sea_port");
            } else {
                return item;
            }
        },
        refreshLocations() {
            this.locations = { edges: [] };
            // this.$apollo?.queries?.locations?.refetch();
        },
        refreshPorts() {
            this.airports = { edges: [] };
            this.seaports = { edges: [] };
            this.$apollo?.queries?.airports?.refetch();
            this.$apollo?.queries?.seaports?.refetch();
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 170 + "px";
            });
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;
            if (
                this.shouldLoadMore &&
                (this.locations?.pageInfo?.hasNextPage ||
                    /* this.publicLocationType === "air_port" */ this.airports?.pageInfo?.hasNextPage ||
                    /* this.publicLocationType === "sea_port" */ this.seaports?.pageInfo?.hasNextPage)
            ) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.tabFilter === "publicLocations") {
                if (this.publicLocationType === "sea_port" && this.seaports?.pageInfo?.hasNextPage) {
                    this.$apollo.queries.seaports.fetchMore({
                        variables: {
                            after: this.seaports.pageInfo.endCursor
                        }
                    });
                } else if (this.publicLocationType === "air_port" && this.airports?.pageInfo?.hasNextPage) {
                    this.$apollo.queries.airports.fetchMore({
                        variables: {
                            after: this.airports.pageInfo.endCursor
                        }
                    });
                }
            } else if (this.locations?.pageInfo?.hasNextPage) {
                this.$apollo.queries.locations.fetchMore({
                    variables: {
                        after: this.locations.pageInfo.endCursor
                    }
                });
            }
        },
        getTags(tags) {
            if (tags) {
                try {
                    let tagsArray = JSON.parse(tags.replace(/'/g, '"'));
                    return tagsArray.map(tag => {
                        return this.$t(`headers.${tag}`);
                    });
                } catch (error) {
                    console.log(error);
                    return null;
                }
            }
            return null;
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
    word-break: normal !important;
    line-height: inherit !important;
    text-align: center !important;
}
.textSubtitle {
    font-size: 13px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
