import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VBtn,{attrs:{"color":_vm.defaultColor,"depressed":"","rounded":"","icon":_vm.displayFormat === 'icon',"text":_vm.displayFormat === 'text'},on:{"click":function($event){_vm.whatsNewDialog = !_vm.whatsNewDialog}}},[(_vm.showWhatsNewBadge())?_c(VBadge,{attrs:{"dot":"","borded":"","color":"success"}},[(_vm.displayFormat === 'icon')?_c('div',[_c(VIcon,[_vm._v("new_releases")])],1):_c('div',[(_vm.screenSize === 'lg' || _vm.screenSize === 'xl')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.new"))+" ")]):_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" new_releases ")])]}}],null,false,1345685734)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.new")))])])],1)]):_c('span',[(_vm.displayFormat === 'icon')?_c('div',[_c(VIcon,[_vm._v("new_releases")])],1):_c('div',[(_vm.screenSize === 'lg' || _vm.screenSize === 'xl')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("general.new"))+" ")]):_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" new_releases ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("general.new")))])])],1)])],1),_c(VDialog,{attrs:{"max-width":"1200px","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.whatsNewDialog = !_vm.whatsNewDialog}},model:{value:(_vm.whatsNewDialog),callback:function ($$v) {_vm.whatsNewDialog=$$v},expression:"whatsNewDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.$t("general.new1"))+" "),_c(VSpacer),_c(VBtn,{staticClass:"mr-n3",attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.whatsNewDialog = !_vm.whatsNewDialog}}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VCardText,[_vm._l((_vm.releases),function(release){return [_c(VCard,{key:release.version,staticClass:"elevation-0",attrs:{"tile":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(release.title)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(release.version)+" - "+_vm._s(release.date)+" "),(_vm.isNewRelease(release))?_c(VChip,{staticClass:"mr-1 mb-1",attrs:{"x-small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.$t("headers.new"))+" ")]):_vm._e()],1),_c(VCardText,{domProps:{"innerHTML":_vm._s(release.content)}}),_c(VDivider)],1)]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }