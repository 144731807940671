<template>
    <v-card outlined class="rounded-lg mb-5 pa-3" height="350">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <!-- <LoadingBar v-if="tripIsLoading"></LoadingBar> -->
            <div>
                <v-card flat>
                    <!-- card title (air) -->
                    <v-card-title>
                        <div class="mr-2">
                            <v-img :src="require('@/assets/file_save.png')" width="25" class="iconColor"></v-img>
                        </div>
                        <span>{{ $t("headers.reports") }}</span>
                        <v-spacer></v-spacer>
                        <GenerateReportDialog
                            v-if="canCreate"
                            :trip="trip"
                            v-on:reportGenerated="refetch"
                            :rounded="false"
                            :elevation="0"
                        ></GenerateReportDialog>
                    </v-card-title>
                    <v-card-text>
                        <LoadingBar
                            v-if="tripIsLoading"
                        ></LoadingBar>
                        <div v-if="!tripIsLoading && tripReports.edges.length === 0">
                            <NoData></NoData>
                        </div>
                        <v-card
                            v-for="item in tripReports.edges"
                            :key="item.node.id"
                            class="contentBg pb-2 rounded-lg mb-2"
                            height="auto"
                            outlined
                        >
                            <v-row align="stretch" class="px-3 mt-1">
                                <v-col cols="6" xs="6" sm="6" md="4" lg="4" xl="3">
                                    <div class="textHeader">
                                        {{ $t("headers.request_date") }}
                                    </div>
                                    <div class="textBody">
                                        {{ formatDateTime(item.node.createdAt) }}
                                    </div>
                                </v-col>
                                <v-col cols="6" xs="6" sm="6" md="4" lg="4" xl="4">
                                    <div class="textHeader">
                                        {{ $t("headers.date_range") }}
                                    </div>
                                    <div class="textBody">
                                        <span>{{ formatDateTime(item.node.startDate).split(" ")[0] }}</span>
                                        <span> - </span>
                                        <span>{{ formatDateTime(item.node.endDate).split(" ")[0] }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="6" xs="6" sm="6" md="4" lg="2" xl="2">
                                    <div class="textHeader">
                                        {{ $t("headers.sensor_data") }}
                                    </div>
                                    <div class="textBody">
                                        <v-tooltip
                                            bottom
                                            v-for="sensorType in getSensorTypes(item.node.sensorData)"
                                            :key="sensorType.text"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-icon v-bind="attrs" v-on="on" size="20" class="iconColor">
                                                    {{ sensorType.icon }}
                                                </v-icon>
                                            </template>
                                            <span>{{ sensorType.text }}</span>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                                <v-col cols="6" xs="6" sm="6" md="4" lg="2" xl="2">
                                    <div class="textHeader">
                                        {{ $t("headers.report_type") }}
                                    </div>
                                    <div class="textBody">
                                        <span>{{ item.node.reportType }}</span>
                                    </div>
                                </v-col>
                                <v-spacer v-if="isSmallScreen"></v-spacer>
                                <v-col cols="1">
                                    <v-menu
                                        offset-y
                                        transition="slide-x-reverse-transition"
                                        width="250px"
                                        attach
                                        left
                                        bottom
                                        close-on-click
                                        nudge-top="-3"
                                    >
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="white">
                                            <v-list-item>
                                                <v-btn
                                                    small
                                                    plain
                                                    :disabled="!item.node.isCompleted"
                                                    @click="downloadReport(item.node.document.url)"
                                                >
                                                    <v-icon>download</v-icon>
                                                    <span>{{ $t("general.download") }}</span>
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-card>
</template>
<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import GenerateReportDialog from "@/components/trips/other/GenerateReportDialog.vue";
import Config from "@/utils/config.json";
import NoData from "@/components/base/NoData.vue";

export default {
    components: {
        LoadingBar,
        NoPermission,
        GenerateReportDialog,
        NoData
    },
    props: {
        trip: {
            type: Object,
            required: true
        },
        tripIsLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    apollo: {
        // tripReports: {
        //     query: gql`
        //         query TripReports($tripId: ID, $orderBy: String) {
        //             tripReports(tripId: $tripId, orderBy: $orderBy) {
        //                 edges {
        //                     node {
        //                         id
        //                         createdAt
        //                         isCompleted
        //                         reportType
        //                         sendToUsers
        //                         sensorData
        //                         startDate
        //                         endDate
        //                         generateImmediately
        //                         document {
        //                             description
        //                             file
        //                             format
        //                             id
        //                             name
        //                             size
        //                             url
        //                         }
        //                         tracker {
        //                             id
        //                             serialNumber
        //                         }
        //                         trip {
        //                             id
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     `,
        //     variables() {
        //         return {
        //             tripId: this.trip.id,
        //             orderBy: "-createdAt"
        //         };
        //     },
        //     fetchPolicy: "no-cache",
        //     // pollInterval: 3000, // ms
        //     pollInterval() {
        //         return this.pollInterval;
        //     },
        //     update(data) {
        //         return data.tripReports;
        //     },
        //     // result({ data }) {
        //     //     let isLoadingReport = false;
        //     //     if (data.tripReports.edges.length > 0) {
        //     //         data.tripReports.edges.map(item => {
        //     //             if (item.node.isCompleted == false) {
        //     //                 isLoadingReport = true;
        //     //             }
        //     //         });
        //     //     }
        //     // },
        //     skip() {
        //         return !this.canAccess;
        //         // ||
        //         // this.isLoadingReport == null ||
        //         // this.isLoadingReport == undefined
        //     }
        // }
    },
    data: function() {
        return {
            createReportDialog: false,
            // tripReports: { edges: [] },
            expanded: [],
            headers: [
                {
                    text: this.$t("headers.request_date"),
                    align: "left",
                    value: "node.createdAt"
                },
                {
                    text: this.$t("headers.date_range"),
                    align: "center",
                    value: "dateRange"
                },
                {
                    text: this.$t("headers.sensor_data"),
                    align: "center",
                    value: "node.sensorData"
                },
                {
                    text: this.$t("headers.report_type"),
                    align: "center",
                    value: "node.reportType"
                },
                {
                    text: this.$t("general.download"),
                    align: "center",
                    value: "action"
                }
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v => (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            },
            sensorTypeChoices: [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    icon: "thermostat"
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    icon: "lightbulb"
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    icon: "water_drop"
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    icon: "bolt"
                },
                {
                    text: this.$t("general.pressure"),
                    value: "pressure",
                    icon: "tire_repair"
                }

                // { text: "CO2", value: "co2", icon: "air" }
            ],
            pollInterval: 0
        };
    },
    watch: {
        "$route.query.tab": {
            handler: function(value) {
                this.pollInterval = value === "reports" ? 3000 : 0;
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        tripReports() {
            let local = {edges:[]};
            if(!this.tripIsLoading)
                local = this.trip?.tripReportSet;

            // if(this.isEmpty(local)) return {edges:[]};
            return local;
        },
        primaryColor() {
            return this.$vuetify.theme.themes.light.primary;
        },
        hasManagePermission() {
            return this.hasOrganisationPermission(TripPermissions.MANAGE_TRIP_REPORT);
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1400;
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (!this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_REPORT, this.organisationPermissions))
                    return false;
            } else {
                // console.log(this.me)
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_REPORT, this.organisationPermissions)
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_REPORT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;

                if (this.me?.tenant && this.me?.isTenantAdmin) return true;

                if (!this.hasPermission(TripPermissions.VIEW_TRIP_REPORT) && this.me?.tenant) return false;
            }
            return true;
        },
        canCreate() {
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (!this.hasOrganisationPermission(TripPermissions.CREATE_TRIP_REPORT, this.organisationPermissions))
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(TripPermissions.CREATE_TRIP_REPORT, this.organisationPermissions)
                )
                    return false;

                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_REPORT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.CREATE_TRIP_REPORT) && this.me?.tenant) return false;
            }

            return true;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
        this.formatDateTime = helper.formatDateTime;
    },
    created() {
        this.bytesToSize = helper.bytesToSize;
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_report_tab_svw"
        });
        this.setUserProperties();
        this.updateScrollbarColor(this.primaryColor);
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_report_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_report_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        /* General section */
        formatType(format) {
            let newFormat = format.split(".");
            return newFormat[newFormat.length - 1];
        },
        updateScrollbarColor(color) {
            document.documentElement.style.setProperty("--scrollbar-color", color);
        },
        expandRow(item) {
            if (item.node && this.expanded.filter(val => val["node"]["id"] === item.node.id).length > 0) {
                this.expanded = [];
            } else {
                this.expanded = [];
                this.expanded.push(item);
            }
        },
        refetch() {
            this.$emit("refresh-trip")
            // return this.$apollo.queries.tripReports.refetch();
        },
        reportGenerated() {
            this.$emit("refresh-trip")
            // this.$apollo.queries.tripReports.refresh();
        },
        getSensorTypes(sensorData) {
            let sensorTypes = [];
            if (sensorData) {
                let sensorArray = JSON.parse(sensorData.replace(/'/g, '"'));
                sensorArray.forEach(sensor => {
                    let sensorType = this.sensorTypeChoices.find(type => type.value === sensor);
                    if (sensorType) {
                        sensorTypes.push(sensorType);
                    }
                });
            }
            return sensorTypes;
        },
        downloadReport(url) {
            window.open(`${url}`, "_blank");
        }
    }
};
</script>
<style scoped>
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    color: #e6e9f5;
}
.contentBg {
    background-color: #f9fafe;
}
.cardContainer {
    height: 250px;
    overflow-y: auto;
    margin-bottom: 12px;
}
.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #555464;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
.iconColor {
    color: #0e0d35;
}
</style>
