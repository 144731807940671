import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500","persistent":"","scrollable":""},model:{value:(_vm.openAddTrackerColumnsSelectorDialog),callback:function ($$v) {_vm.openAddTrackerColumnsSelectorDialog=$$v},expression:"openAddTrackerColumnsSelectorDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("edit")]),_vm._v(" "+_vm._s(_vm.$t("trackers.excel"))+" ")],1),_c(VCardText,{staticClass:"pa-6 text-center"},_vm._l((_vm.defaultHeaders),function(item,i){return _c(VRow,{key:i},[_c(VCol,{staticClass:"mt-3",staticStyle:{"text-align":"left"},attrs:{"cols":"5"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(item.text))])]),_c(VCol,{attrs:{"cols":"7"}},[(item.value == 'serialNumber')?_c(VForm,{ref:"headerFormSerialNumber",refInFor:true},[_c(VAutocomplete,{attrs:{"outlined":"","dense":"","items":_vm.headers,"no-data-text":_vm.$t('loading.no_available'),"rules":[_vm.rules.required]},model:{value:(_vm.finalHeaders[item.value]),callback:function ($$v) {_vm.$set(_vm.finalHeaders, item.value, $$v)},expression:"finalHeaders[item.value]"}})],1):_c(VAutocomplete,{attrs:{"outlined":"","dense":"","items":_vm.headers,"no-data-text":_vm.$t('loading.no_available')},model:{value:(_vm.finalHeaders[item.value]),callback:function ($$v) {_vm.$set(_vm.finalHeaders, item.value, $$v)},expression:"finalHeaders[item.value]"}})],1)],1)}),1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"color":"primary","rounded":""},on:{"click":_vm.updateTrackers}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }