import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[(_vm.$apollo.queries.locations.loading || _vm.isLoadingTable)?_c('LoadingBar'):_vm._e(),(_vm.isChartRendered)?_c('div',{staticClass:"pb-5"},[(_vm.locationsShowed.edges.length > 0)?_c('div',[_c('div',{staticClass:"row mt-3"},[_c('draggable',_vm._b({staticClass:"row",model:{value:(_vm.locationsShowed.edges),callback:function ($$v) {_vm.$set(_vm.locationsShowed, "edges", $$v)},expression:"locationsShowed.edges"}},'draggable',this.dragOptions(),false),_vm._l((_vm.locationsShowed.edges),function(edge,index){return _c('div',{key:index,class:[
                            'py-0',
                            'col-md-4',
                            index % 3 === 0
                                ? 'pr-0 pl-6'
                                : index % 3 === 2
                                ? 'pl-0 pr-6'
                                : 'px-0'
                        ]},[_c(VCard,{staticClass:"pb-2 px-1 mx-2 my-2",attrs:{"elevation":"5","outlined":""}},[_c(VCardActions,{staticClass:"justify-space-between py-0 d-flex align-items-center"},[_c(VCardTitle,{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(edge.node.city)+" - "+_vm._s(edge.node.country)+" ")]),_c(VMenu,{attrs:{"offset-y":"","transition":"slide-y-transition","width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("more_vert")])],1)]}}],null,true)},[_c(VList,_vm._l((_vm.actionChoices),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.handleClick(item, edge)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)],1)}),1)],1)],1),_c('div',[_c('WeatherChart',{attrs:{"edge":edge}})],1)],1)],1)}),0)],1)]):_vm._e(),(_vm.locationsShowed.edges.length === 0)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center mt-10"},[_c('span',[_vm._v(_vm._s(_vm.$t("weather.no_locations")))])])])]):_vm._e()]):_vm._e(),_c(VDialog,{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.openEditDialog),callback:function ($$v) {_vm.openEditDialog=$$v},expression:"openEditDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_vm._v(_vm._s(_vm.locationtoEdit.city)+" - "+_vm._s(_vm.locationtoEdit.country))]),_c(VCardText,[_c('div',{staticClass:"mt-10"},[_c('MinMaxTemperature',{model:{value:(_vm.locationtoEdit),callback:function ($$v) {_vm.locationtoEdit=$$v},expression:"locationtoEdit"}})],1)]),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.resetLocation}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"color":"primary","rounded":"","loading":_vm.isLoadingEdit,"disabled":!_vm.formValid},on:{"click":function($event){return _vm.editLocation(_vm.locationtoEdit)}}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }