<template>
    <div class="text-right">
        <div v-if="templateMode && !canCreateTemplate">
            <NoPermission></NoPermission>
        </div>
        <v-dialog v-model="dialog" max-width="1200" persistent scrollable>
            <template
                v-if="!(editMode && templateMode)"
                v-slot:activator="{ on, attrs }"
            >
                <v-btn
                    v-if="editMode"
                    rounded
                    icon
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="edit(tripObj)"
                    :disabled="disabled"
                    small
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">edit</v-icon>
                        </template>
                        <span>{{ $t("trips.edit_trip") }}</span>
                    </v-tooltip>
                </v-btn>
                <v-btn
                    v-else-if="copyMode"
                    plain
                    v-bind="attrs"
                    v-on="on"
                    @click="copy(tripObj)"
                    :disabled="disabled"
                >
                    <v-icon left>file_copy</v-icon>
                    <span>{{ $t("trips.copy_trip") }}</span>
                </v-btn>
                <div v-else-if="templateMode">
                    <v-btn
                        v-if="addFromTrip"
                        plain
                        v-bind="attrs"
                        v-on="on"
                        @click="populateTemplate(tripObj)"
                        :disabled="disabled"
                        class=""
                    >
                        <v-icon left>description</v-icon>
                        <span>{{ $t("templates.create_new_template") }}</span>
                    </v-btn>
                    <v-btn
                        v-else
                        rounded
                        color="primary"
                        class="mr-1"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ $t("templates.create_new_template") }}
                    </v-btn>
                </div>
                <v-btn
                    v-else
                    :rounded="rounded"
                    :block="block" 
                    color="primary"
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                    :small="screenSize === 'xs' ? true : false"
                >
                    {{$t("trips.create_trip")}}
                </v-btn>
            </template>
            <v-card flat>
                <v-card-title
                    class="primary white--text d-flex align-center justify-space-between"
                >
                    <div class="d-flex align-center justify-space-between">
                        <v-icon color="white" class="mr-2">flight</v-icon>
                        <div v-if="copyBool">
                            {{ "Copy Trip" }}
                        </div>
                        <div
                            v-else-if="!(templateMode && editMode)"
                            class="d-flex align-center"
                        >
                            <span>{{
                                editMode
                                    ? $t("trips.edit_trip")
                                    : templateMode
                                    ? $t("templates.create_new_template")
                                    : $t("trips.create_new_trip")
                            }}</span>
                            <span
                                v-if="templateMode"
                                class="text-body-2 ml-5 mt-1"
                                >({{ $t("templates.template_explain") }})</span
                            >
                        </div>
                        <div
                            v-else
                            class="d-flex align-center justify-space-around"
                        >
                            <span style="font-size: 30px;">{{
                                this.templateNameDescription.name
                            }}</span>
                            <v-btn
                                rounded
                                icon
                                color="primary"
                                @click="openNameDescriptionDialog = true"
                                ><v-icon color="white" class="ml-2"
                                    >edit</v-icon
                                ></v-btn
                            >
                        </div>
                    </div>
                    <v-autocomplete
                        ref="templateAutocomplete"
                        v-if="showCreateFromTemplate"
                        v-model="templateSelected"
                        style="max-width: 300px; margin-bottom: -1rem;"
                        :loading="$apollo.queries.tripTemplates.loading"
                        :disabled="$apollo.queries.tripTemplates.loading"
                        :items="autocompleteItems"
                        :no-data-text="$t('loading.no_available')"
                        item-text="name"
                        return-object
                        color="white"
                        dense
                        outlined
                        dark
                        clearable
                        :label="$t('templates.create_from_template')"
                        @change="populateTrip"
                    >
                    </v-autocomplete>
                </v-card-title>
                <v-alert v-if="error" tile type="error" class="mb-0">{{
                    error
                }}</v-alert>
                <v-card-text class="px-0 py-0 mt-1">
                    <v-stepper v-model="stepNo">
                        <v-stepper-header class="elevation-0 px-1">
                            <v-stepper-step
                                :complete="stepNo > 1"
                                step="1"
                                editable
                            >
                                {{ $t("trips.basic_info") }}
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                                :complete="stepNo > 2"
                                step="2"
                                :editable="
                                    (validateTripStep &&
                                        validateTripLocation) ||
                                        templateMode
                                "
                            >
                                {{ $t("home.freights") }}
                                <small>{{ $t("general.optional") }}</small>
                            </v-stepper-step>
                            <v-divider v-if="isSeaTransport"></v-divider>
                            <v-stepper-step
                                :editable="
                                    !templateMode
                                        ? validateTripStep &&
                                          validateTripLocation &&
                                          validateFreights
                                        : true
                                "
                                :complete="stepNo > 3"
                                step="3"
                                v-if="isSeaTransport"
                            >
                                {{ $t("alert_rules.sea_transport") }}
                                <small>{{ $t("general.optional") }}</small>
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                                :editable="
                                    !templateMode
                                        ? validateTripStep &&
                                          validateTripLocation &&
                                          validateFreights
                                        : true
                                "
                                :complete="
                                    isSeaTransport ? stepNo > 4 : stepNo > 3
                                "
                                :step="isSeaTransport ? 4 : 3"
                            >
                                {{ $t("home.partners") }}
                                <small>{{ $t("general.optional") }}</small>
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1" class="pt-1">
                                <v-card>
                                    <v-card-text class="px-0">
                                        <v-row dense>
                                            <v-col cols="12" md="6">
                                                <TripForm
                                                    ref="tripCard"
                                                    v-model="validateTripStep"
                                                    :isOpen="dialog"
                                                    :getTrip.sync="trip"
                                                    :isSeaTransport.sync="
                                                        isSeaTransport
                                                    "
                                                    :editMode="editMode"
                                                    :templateMode="templateMode"
                                                    :addFromTrip="addFromTrip"
                                                >
                                                </TripForm>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <TripLocationForm
                                                    v-if="dialog"
                                                    ref="tripLocation"
                                                    v-model="location"
                                                    :mode="trip.transportMode"
                                                    :validate.sync="
                                                        validateTripLocation
                                                    "
                                                    :templateMode="templateMode"
                                                ></TripLocationForm>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            rounded
                                            @click="cancelTrip"
                                            class="ml-2"
                                        >
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            rounded
                                            @click="stepNo = 2"
                                            :disabled="
                                                !(
                                                    validateTripStep &&
                                                    validateTripLocation
                                                ) && !templateMode
                                            "
                                        >
                                            {{ $t("general.continue") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content step="2" class="pt-1">
                                <v-card>
                                    <v-card-text class="px-0">
                                        <TripTrackerFreightForm
                                            ref="freightTracker"
                                            :trip="trip"
                                            :validate.sync="validateFreights"
                                            v-model="freights"
                                            :currentTrackers="currentTrackers"
                                            :templateMode="templateMode"
                                            :plannedDepartureDate="
                                                location.plannedDepartureDate
                                            "
                                            :editMode="editMode"
                                        >
                                        </TripTrackerFreightForm>
                                    </v-card-text>
                                    
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-btn
                                            rounded
                                            outlined
                                            color="primary"
                                            text
                                            @click="goBack()"
                                            >{{ $t("general.back") }}</v-btn
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn rounded text @click="cancelTrip">
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            rounded
                                            @click="stepNo = 3"
                                            :disabled="
                                                !validFreightsForm &&
                                                    !templateMode
                                            "
                                        >
                                            {{ $t("general.continue") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content
                                step="3"
                                class="pt-1"
                                v-if="isSeaTransport"
                            >
                                <v-card>
                                    <v-card-text class="px-0">
                                        <TripSeaTransportForm
                                            ref="seaTransportForm"
                                            v-model="seaLoadPoint"
                                            :tripSeaTransport.sync="
                                                seaTransport
                                            "
                                            :isSeaTransport="isSeaTransport"
                                            :tripContainers.sync="
                                                tripContainers
                                            "
                                            :tripPartnerCarrierId.sync="
                                                tripPartnerCarrierId
                                            "
                                            :templateMode="templateMode"
                                            :editMode="editMode"
                                        >
                                        </TripSeaTransportForm>
                                    </v-card-text>
                                    <v-card-actions class="pt-3">
                                        <v-btn
                                            rounded
                                            outlined
                                            color="primary"
                                            text
                                            @click="goBack()"
                                            >{{ $t("general.back") }}</v-btn
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn rounded text @click="cancelTrip">
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            rounded
                                            @click="
                                                isSeaTransport
                                                    ? (stepNo = 4)
                                                    : (stepNo = 3)
                                            "
                                        >
                                            {{ $t("general.continue") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content
                                :step="isSeaTransport ? 4 : 3"
                                class="pt-1"
                            >
                                <v-card>
                                    <v-card-text class="px-0">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <PartnerList
                                                    ref="partnerList"
                                                    :getPartners.sync="partners"
                                                    :partnersOnBoard="[]"
                                                    :dense="true"
                                                >
                                                </PartnerList>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-btn
                                            rounded
                                            outlined
                                            color="primary"
                                            text
                                            @click="goBack()"
                                            >{{ $t("general.back") }}</v-btn
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn rounded text @click="cancelTrip">
                                            {{ $t("general.cancel") }}
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            @click="
                                                if (templateMode) {
                                                    if (editMode)
                                                        saveTemplate();
                                                    else
                                                        openNameDescriptionDialog = true;
                                                } else {
                                                    saveTrip();
                                                }
                                            "
                                            :loading="isSaving"
                                            rounded
                                        >
                                            {{ $t("general.save") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-card>
        </v-dialog>
        <NameDescriptionDialog
            ref="nameDescriptionDialog"
            :openDialog="openNameDescriptionDialog"
            :editMode="editMode"
            :templateObj.sync="templateNameDescription"
            @closeDialog="closeNameDescriptionDialog"
            @saveNameDescription="saveNameDescription"
        >
        </NameDescriptionDialog>
    </div>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TripForm from "@/components/trips/forms/TripForm.vue";
import PartnerList from "@/components/partners/PartnerList.vue";
import TripTrackerFreightForm from "@/components/trips/forms/TripTrackerFreightForm.vue";
import TripSeaTransportForm from "@/components/trips/forms/TripSeaTransportForm.vue";
import TripLocationForm from "@/components/trips/forms/TripLocationForm.vue";
import NameDescriptionDialog from "../../triptemplates/create/NameDescriptionDialog.vue";
import moment from "moment-timezone";

export default {
    components: {
        TripForm,
        PartnerList,
        TripTrackerFreightForm,
        TripSeaTransportForm,
        TripLocationForm,
        NameDescriptionDialog
    },
    props: {
        tripObj: {
            type: Object,
            default: () => {}
        },
        editMode: {
            type: Boolean,
            default: false
        },
        copyMode: {
            type: Boolean,
            default: false
        },
        templateMode: {
            type: Boolean,
            default: false
        },
        addFromTrip: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        openDialog: {
            type: Boolean,
            default: false
        },
        createTrip: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: true
        }
    },

    apollo: {
        tripTemplates: {
            query: gql`
                query tripTemplates($orderBy: String) {
                    tripTemplates(orderBy: $orderBy) {
                        edges {
                            node {
                                id
                                lockTemplate
                                name
                                tripJson
                                description
                                origin
                                destination
                                carrier
                                partners
                                alertRules
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "network-only",
            debounce: 800,
            update: response => response.tripTemplates,
            watchLoading(isLoading) {
                this.isLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            skip: true
        }
    },

    data() {
        return {
            templateSelected: null,
            tripTemplates: { edges: [] },
            copyBool: false,
            openNameDescriptionDialog: false,
            templateName: null,
            templateDescription: null,
            templateNameValid: false,
            dialog: false,
            isSaving: false,
            invalid: false,
            tenantPartners: {},
            validateTripStep: false,
            validateFreights: false,
            validateTripLocation: false,
            validateAlert: false,
            dialogForm: false,
            tripContainers: [],
            tripPartnerCarrierId: null,
            currentTrackers: [],
            templateNameDescription: {
                name: null,
                description: null
            },
            trip: {
                id: null,
                referenceNumber: "",
                transportMode: "",
                shipmentFileNumber: "",
                carrier: ""
            },
            location: {
                origin: "",
                destination: "",
                plannedDepartureDate: null,
                plannedDepartureTime: null,
                plannedArrivalDate: null,
                plannedArrivalTime: null,
                plannedDeparture: null
            },
            seaLoadPoint: {
                trade: ""
            },
            seaTransport: [
                {
                    id: null,
                    sequence: 0,
                    voyage: "",
                    portCode: "",
                    portTransportType: "POL",
                    vesselInputType: "",
                    vesselInputValue: "",
                    estimatedDepartureDate: ""
                },
                {
                    id: null,
                    sequence: 1,
                    portCode: "",
                    portTransportType: "POD",
                    estimatedArrivalDate: ""
                }
            ],
            partners: [],
            freights: [
                {
                    id: null,
                    referenceNumber: null,
                    containerNumber: null,
                    awb: null,
                    orderNumber: null,
                    billsOfLading: null,
                    goods: null,
                    term: null,
                    contact: "",
                    loadPoint: "",
                    loadDate: null,
                    trackers: [],
                    startDate: null,
                    startTime: null,
                    endDate: null,
                    endTime: null,
                    alerts: []
                }
            ],
            error: null,
            stepNo: 1,
            isSeaTransport: false,
            editable: false,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        validFreightsForm() {
            return this.validateFreights; 
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        canCreateTemplate() {
            return true;
        },
        autocompleteItems() {
            let items = [];
            this.tripTemplates.edges.forEach(edge => {
                let seatransport = JSON.parse(edge.node.tripJson);
                let goods = [];
                let terms = [];
                let loadPoint = [];
                seatransport.freights.forEach(freight => {
                    if (freight.goods != null && freight.goods != "")
                        goods.push(freight.goods);
                    if (freight.term != null && freight.term != "")
                        terms.push(freight.term);
                    if (freight.loadPoint != null && freight.loadPoint != "")
                        loadPoint.push(freight.loadPoint);
                });
                let departureTime = seatransport.plannedDepartureTime;
                let arrivalTime = seatransport.plannedArrivalTime;
                let item = {
                    id: edge.node.id,
                    name: edge.node.name,
                    description: edge.node.description,
                    transportMode: seatransport.transportMode,
                    carrier: {
                        id: seatransport.carrier,
                        name: edge.node.carrier
                    },
                    origin: { id: seatransport.origin, name: edge.node.origin },
                    departureTime: departureTime,
                    destination: {
                        id: seatransport.destination,
                        name: edge.node.destination
                    },
                    arrivalTime: arrivalTime,
                    goods: goods,
                    terms: terms,
                    loadPoint: loadPoint,
                    partners: edge.node.partners,
                    partnersIds: seatransport.partners,
                    alertRules: edge.node.alertRules,
                    trade: seatransport.trade,
                    freights: seatransport.freights,
                    seaTransport: seatransport.seaTransports
                };
                items.push(item);
            });
            return items;
        },
        showCreateFromTemplate() {
            let show = false;
            if (!this.templateMode) show = true;
            if (this.editMode) show = false;
            return show;
        }
    },
    created() {
        if (this.createTrip) {
            this.$apollo.queries.tripTemplates.skip = false;
        }
        this.formatDateTime = helper.formatDateTime;
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    watch: {
        openDialog: {
            immediate: true,
            handler: function(newVal) {
                this.dialog = newVal;
            }
        },
        trip: {
            handler: function(newVal) {
                if (newVal.carrier) {
                    this.tripPartnerCarrierId = newVal.carrier;
                }
            },
            deep: true
        },
        freights: {
            handler: function(newVal) {
                if (newVal.length !== 0) {
                    this.tripContainers = newVal.map(i => {
                        return i.containerNumber;
                    });
                }
            },
            deep: true
        },
        isSeaTransport: {
            immediate: true,
            handler: function(newVal) {
                if (newVal === false) {
                    this.tripSeaLoadPoint = {
                        trade: "",
                        logco: "",
                        loadPoint: "",
                        loadDate: null
                    };
                    this.seaTransport = [
                        {
                            id: null,
                            sequence: 0,
                            voyage: "",
                            portCode: "",
                            portTransportType: "POL",
                            vesselInputType: "",
                            vesselInputValue: "",
                            estimatedDepartureDate: ""
                        },
                        {
                            id: null,
                            sequence: 1,
                            portCode: "",
                            portTransportType: "POD",
                            estimatedArrivalDate: ""
                        }
                    ];
                }
            },
            deep: true
        }
    },
    methods: {
        clearTrip() {
            this.stepNo = 1;
            this.templateSelected = null;
            this.$refs.tripCard.reset();
            this.$refs.tripLocation.resetForm();

            this.trip = {
                id: null,
                referenceNumber: "",
                transportMode: "",
                shipmentFileNumber: "",
                carrier: ""
            };
            this.location = {
                origin: "",
                destination: "",
                plannedDepartureDate: null,
                plannedDepartureTime: null,
                plannedArrivalDate: null,
                plannedArrivalTime: null
            };

            this.seaLoadPoint = {
                trade: ""
            };
            this.seaTransport = [
                {
                    id: null,
                    sequence: 0,
                    voyage: "",
                    portCode: "",
                    portTransportType: "POL",
                    vesselInputType: "",
                    vesselInputValue: "",
                    estimatedDepartureDate: ""
                },
                {
                    id: null,
                    sequence: 1,
                    portCode: "",
                    portTransportType: "POD",
                    estimatedArrivalDate: ""
                }
            ];
            this.partners = [];
            this.freights = [
                {
                    id: null,
                    referenceNumber: null,
                    containerNumber: null,
                    awb: null,
                    orderNumber: null,
                    billsOfLading: null,
                    goods: null,
                    term: null,
                    contact: "",
                    loadPoint: "",
                    loadDate: null,
                    trackers: [],
                    startDate: null,
                    //startTime: null,
                    endDate: null,
                    //endTime: null,
                    alerts: []
                }
            ];
        },
        populateTrip() {
            this.stepNo = 1;
            if (this.templateSelected) this.editTemplate(this.templateSelected);
            else this.clearTrip();
        },
        populateTemplate(tripObj) {
            this.trip.transportMode = tripObj.transportMode.toLowerCase();
            this.trip.carrier = tripObj?.tenantCarrier?.id || null;
            this.isSeaTransport = tripObj.transportMode.toLowerCase() === "sea";

            const plannedDepartureTime = tripObj.plannedDepartureDate
                ? this.getTime(tripObj.plannedDepartureDate)
                : null;

            const plannedArrivalTime = tripObj.plannedArrivalDate
                ? this.getTime(tripObj.plannedArrivalDate)
                : null;

            this.location.origin = tripObj?.originLocation?.id || null;
            this.location.destination =
                tripObj?.destinationLocation?.id || null;

            this.location.plannedDepartureTime = plannedDepartureTime;
            this.location.plannedArrivalTime = plannedArrivalTime;

            //trip sea load point
            this.seaLoadPoint = {
                trade: tripObj?.trade || null
            };

            //trip freight
            this.freights = tripObj?.tripFreightSet?.edges.map(i => {
                let alerts = [];

                alerts = i.node?.tripFreightAlertRuleSet?.edges.map(
                    a => a.node.tenantAlertRule.id
                );

                return {
                    id: i.node.id,
                    goods: i.node.goods,
                    term: i.node.term,
                    contact: i.node.contact,
                    loadPoint: i.node.loadPoint,
                    alerts: alerts
                };
            });

            this.seaTransport = tripObj?.tripSeaTransportSet?.edges
                .map(i => {
                    return {
                        id: i.node.id,
                        sequence: i.node.sequence,
                        portCode: i.node.portCode,
                        portTransportType: i.node.portTransportType
                    };
                })
                .sort((a, b) => a.sequence - b.sequence);

            //trip partners
            this.partners = tripObj?.tripPartnerSet?.edges.map(i => {
                return i.node?.tenantPartner?.id;
            });
        },
        edit(tripObj) {
            this.copyBool = false;
            //trip
            this.trip.id = tripObj.id || null;
            this.trip.referenceNumber = tripObj.referenceNumber || null;
            this.trip.transportMode = tripObj.transportMode.toLowerCase();
            this.trip.shipmentFileNumber = tripObj.shipmentFileNumber || null;
            this.trip.carrier = tripObj?.tenantCarrier?.id || null;
            this.isSeaTransport = tripObj.transportMode.toLowerCase() === "sea";
            //trip location

            this.location.origin = tripObj?.originLocation?.id || null;
            this.location.destination =
                tripObj?.destinationLocation?.id || null;
            this.location.plannedDepartureDate =
                this.formatDateTime(tripObj?.plannedDepartureDate) || null;

            this.location.plannedArrivalDate =
                this.formatDateTime(tripObj?.plannedArrivalDate) || null;

            //trip sea load point
            this.seaLoadPoint = {
                trade: tripObj?.trade || null
            };

            //trip freight
            this.freights = tripObj?.tripFreightSet?.edges.map(i => {
                let trackers = [];
                let alerts = [];

                trackers = i.node?.tripFreightTrackerSet?.edges.map(
                    t => t.node.tracker.id
                );

                i.node?.tripFreightTrackerSet?.edges.forEach(e => {
                    this.currentTrackers.push({
                        id: e.node.tracker.id,
                        serialNumber: e.node.tracker.serialNumber
                    });
                });

                alerts = i.node?.tripFreightAlertRuleSet?.edges.map(
                    a => a.node.tenantAlertRule.id
                );

                /* let startDate = this.getDate(i.node.startDate) || null;
                let endDate = this.getDate(i.node.endDate) || null;
                let startTime = this.getTime(i.node.startDate) || null;
                let endTime = this.getTime(i.node.endDate) || null;
 */ 
                
                return {
                    id: i.node.id,
                    referenceNumber: i.node.referenceNumber,
                    containerNumber: i.node.containerNumber,
                    awb: this.formatAirwaybillNumber(i.node.airWaybillNumber),
                    orderNumber: i.node.orderNumber,
                    billsOfLading: i.node.billsOfLading,
                    goods: i.node.goods,
                    term: i.node.term,
                    contact: i.node.contact,
                    loadPoint: i.node.loadPoint,
                    loadDate: this.getDate(i.node.loadDate),
                    startDate: this.formatDateTime(i.node.startDate),
                    endDate: this.formatDateTime(i.node.endDate),
                    trackers: trackers,
                    alerts: alerts
                };
            });

            //trip sea transport
            this.seaTransport = tripObj?.tripSeaTransportSet?.edges
                .map(i => i.node)
                .sort((a, b) => a.sequence - b.sequence);

            //trip partners
            this.partners = tripObj?.tripPartnerSet?.edges.map(i => {
                return i.node?.tenantPartner?.id;
            });
        },
        copy(tripObj) {
            this.copyBool = true;

            //trip

            this.trip.transportMode = tripObj.transportMode.toLowerCase();
            this.trip.carrier = tripObj?.tenantCarrier?.id || null;
            this.location.origin = tripObj?.originLocation?.id || null;
            this.location.destination =
                tripObj?.destinationLocation?.id || null;
            this.location.destination =
                tripObj?.destinationLocation?.id || null;
            this.isSeaTransport = tripObj.transportMode.toLowerCase() === "sea";

            //trip sea load point
            this.seaLoadPoint = {
                trade: tripObj?.trade || null
            };

            //trip freight
            this.freights = tripObj?.tripFreightSet?.edges.map(i => {
                let alerts = [];

                alerts = i.node?.tripFreightAlertRuleSet?.edges.map(
                    a => a.node.tenantAlertRule.id
                );

                return {
                    id: i.node.id,
                    referenceNumber: null,
                    containerNumber: i.node.containerNumber,
                    awb: i.node.airWaybillNumber,
                    orderNumber: null,
                    billsOfLading: null,
                    goods: i.node.goods,
                    term: i.node.term,
                    contact: null,
                    loadPoint: i.node.loadPoint,
                    loadDate: null,
                    trackers: null,
                    alerts: alerts
                };
            });

            //trip sea transport

            this.seaTransport = tripObj?.tripSeaTransportSet?.edges
                .map(i => {
                    return {
                        portCode: i.node.portCode,
                        portTransportType: i.node.portTransportType,
                        sequence: i.node.sequence,
                        vesselInputType: null
                    };
                })
                .sort((a, b) => a.sequence - b.sequence);

            //trip partners
            this.partners = tripObj?.tripPartnerSet?.edges.map(i => {
                return i.node?.tenantPartner?.id;
            });
        },
        editTemplate(templateObj) {
            if (templateObj) {
                this.trip.id = templateObj.id || null;
                this.templateNameDescription.name = templateObj.name || null;
                this.templateNameDescription.description =
                    templateObj.description || null;
                this.trip.transportMode = templateObj.transportMode.toLowerCase();
                this.trip.carrier = templateObj.carrier.id || null;
                this.isSeaTransport =
                    templateObj.transportMode.toLowerCase() === "sea";

                this.location.origin = templateObj.origin.id || null;
                this.location.plannedDepartureTime =
                    templateObj.departureTime || null;
                this.location.destination = templateObj.destination.id || null;
                this.location.plannedArrivalTime =
                    templateObj.arrivalTime || null;
                this.freights =
                    templateObj.freights.map(f => {
                        return {
                            id: f.id,
                            referenceNumber: f.referenceNumber || null,
                            containerNumber: f.containerNumber || null,
                            awb: f.awb || null,
                            orderNumber: f.orderNumber || null,
                            billsOfLading: f.billsOfLading || null,
                            goods: f.goods || null,
                            term: f.term || null,
                            contact: f.contact || null,
                            loadPoint: f.loadPoint || null,
                            loadDate: f.loadDate || null,
                            startDate: f.startDate || null,
                            endDate: f.endDate || null,
                            //startTime: f.startTime || null,
                            //endTime: f.endTime || null,
                            trackers: f.trackers || [],
                            alerts: f.alerts || []
                        };
                    }) || [];
                this.seaLoadPoint.trade = templateObj.trade || null;
                this.seaTransport = templateObj.seaTransport || [];
                this.partners = templateObj.partnersIds || [];
            }
        },
        saveTrip() {
            this.isSaving = true;
            let freightInputFields = [];
            let seaTransportsInput = [];

            this.seaTransport.forEach(i => {
                seaTransportsInput.push({
                    voyage: i.voyage,
                    sequence: i.sequence,
                    portCode: i.portCode,
                    portTransportType: i.portTransportType,
                    vesselInputType: i.vesselInputType,
                    vesselInputValue: i.vesselInputValue,
                    originalEstimatedDepartureDate:
                        i.portTransportType === "POL"
                            ? helper.convertDateToUTC(i.estimatedDepartureDate)
                            : null,
                    originalEstimatedArrivalDate:
                        i.portTransportType === "POD"
                            ? helper.convertDateToUTC(i.estimatedArrivalDate)
                            : null,
                    estimatedDepartureDate: helper.convertDateToUTC(
                        i.estimatedDepartureDate
                    ),
                    estimatedArrivalDate: helper.convertDateToUTC(
                        i.estimatedArrivalDate
                    )
                });
            });

            this.freights.forEach(e => {
                //normalized e.aw remove the hifen, space or other special characters
                let freightInputField = {
                    referenceNumber: e.referenceNumber,
                    billOfLadingNumber: e.billsOfLading,
                    containerNumber: e.containerNumber,
                    airWaybillNumber: this.removeHifenFromAirwaybill(e.awb),
                    goods: e.goods,
                    term: e.term,
                    estimatedDepartureDate: e.estimatedDepartureDate
                        ? helper.concatDateAndTimeAndConvertToUTC(
                              e.estimatedDepartureDate,
                              e.estimatedDepartureTime
                          )
                        : null,
                    estimatedArrivalDate: e.estimatedArrivalDate
                        ? helper.concatDateAndTimeAndConvertToUTC(
                              e.estimatedArrivalDate,
                              e.estimatedArrivalTime
                          )
                        : null,
                    trackers: e.trackers,
                    alerts: e.alerts,
                    contact: e.contact,
                    loadPoint: e.loadPoint,
                    startDate: e.startDate
                        ? helper.convertDateTimeToUTC(e.startDate)
                        : null,
                    endDate: e.endDate
                        ? helper.convertDateTimeToUTC(e.endDate)
                        : null,
                    loadDate: helper.convertDateToUTC(e.loadDate)
                };
                if(this.editMode){
                    freightInputField.id = e.id;
                }
                freightInputFields.push(freightInputField);
            });

            if (!this.editMode || this.copyBool) {
                let payload = {
                    referenceNumber: this.trip.referenceNumber,
                    transportMode: this.trip.transportMode,
                    shipmentFileNumber: this.trip.shipmentFileNumber,
                    tenantCarrier: this.trip.carrier,
                    plannedDepartureDate: this.location.plannedDepartureDate
                        ? helper.convertDateTimeToUTC(
                              this.location.plannedDepartureDate
                          )
                        : null,
                    plannedArrivalDate: this.location.plannedArrivalDate
                        ? helper.convertDateTimeToUTC(
                              this.location.plannedArrivalDate
                          )
                        : null,
                    trade: this.seaLoadPoint.trade,
                    tenantPartners: this.partners,
                    originLocationId: this.location.origin,
                    destinationLocationId: this.location.destination,
                    freights: freightInputFields,
                    seaTransport: seaTransportsInput
                };

                this.$apollo
                    .mutate({
                        // Query
                        mutation: gql`
                            mutation createTrip($input: CreateTripInput!) {
                                createTrip(input: $input) {
                                    trip {
                                        id
                                        createdAt
                                        completedAt
                                        tenant {
                                            id
                                            organisationPermissions
                                        }
                                        tenantCarrier {
                                            id
                                            name
                                        }
                                        originLocation {
                                            id
                                            name
                                            city
                                            state
                                            country
                                            fullAddress
                                        }
                                        destinationLocation {
                                            id
                                            name
                                            city
                                            state
                                            country
                                            fullAddress
                                        }
                                        referenceNumber
                                        shipmentFileNumber
                                        transportMode
                                        plannedDepartureDate
                                        plannedArrivalDate
                                        status
                                        isArchived
                                        isPrivate
                                        numberOfFreights
                                        numberOfPartners
                                        numberOfDocuments
                                        numberOfComments
                                        numberOfUnresolvedAlerts
                                        isUserFavorite
                                        tripPartnerSet {
                                            edges {
                                                node {
                                                    id
                                                    tenantPartner {
                                                        id
                                                        name
                                                        partnerType
                                                        organisationPermissions
                                                    }
                                                }
                                            }
                                        }
                                        tripSeaTransportSet {
                                            edges {
                                                node {
                                                    id
                                                    portCode
                                                    portName
                                                    sequence
                                                    portTransportType
                                                    vesselInputType
                                                    vesselInputValue
                                                    voyage
                                                    vessel {
                                                        id
                                                        name
                                                        lastRouteJson
                                                    }
                                                    portTransportType
                                                    vesselInputType
                                                    vesselInputValue
                                                    actualDepartureDate
                                                    actualArrivalDate
                                                    estimatedDepartureDate
                                                    estimatedArrivalDate
                                                    originalEstimatedDepartureDate
                                                    originalEstimatedArrivalDate
                                                }
                                            }
                                        }
                                        tripFreightSet {
                                            edges {
                                                node {
                                                    id
                                                    referenceNumber
                                                    orderNumber
                                                    containerNumber
                                                    airWaybillNumber
                                                    billOfLadingNumber
                                                    loadDate
                                                    loadPoint
                                                    contact
                                                    goods
                                                    term
                                                    estimatedDepartureDate
                                                    estimatedArrivalDate
                                                    actualDepartureDate
                                                    actualArrivalDate
                                                    startDate
                                                    endDate
                                                    tripFreightAlertRuleSet {
                                                        edges {
                                                            node {
                                                                id
                                                                tenantAlertRule {
                                                                    id
                                                                    name
                                                                }
                                                            }
                                                        }
                                                    }
                                                    tripFreightTrackerSet {
                                                        edges {
                                                            node {
                                                                id
                                                                tracker {
                                                                    id
                                                                    serialNumber
                                                                    activatedDate
                                                                    lastConnectionTime
                                                                    lastBatteryDeviceTime
                                                                    lastBatteryValue
                                                                    lastTemperatureDeviceTime
                                                                    lastTemperatureValue
                                                                    lastExternalTemperatureDeviceTime
                                                                    lastExternalTemperatureValue
                                                                    lastProbeTemperatureDeviceTime
                                                                    lastProbeTemperatureValue
                                                                    lastHumidityDeviceTime
                                                                    lastHumidityValue
                                                                    lastLightDeviceTime
                                                                    lastLightValue
                                                                    lastShockDeviceTime
                                                                    lastShockValue
                                                                    lastLocationDeviceTime
                                                                    lastLatitude
                                                                    lastLongitude
                                                                    lastLocationDescription
                                                                }
                                                                name
                                                                lowTemperature
                                                                highTemperature
                                                                lowHumidity
                                                                highHumidity
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        // Parameters
                        variables: {
                            input: { ...payload }
                        }
                    })
                    .then(response => {
                        const payload = {
                            color: "blue",
                            message: "Trip was created successfully"
                        };

                        this.$store.dispatch("snackbar/showMessage", payload);

                        if (this.copyBool) {
                            this.cancelTrip();
                            this.$emit(
                                "trip-copy",
                                response.data.createTrip.trip
                            );
                        } else {
                            this.$emit(
                                "trip-created",
                                response.data.createTrip.trip
                            );
                        }
                    })
                    .catch(error => {
                        if (error.graphQLErrors) {
                            // this.error = error.graphQLErrors[0].message;
                            console.error(error.graphQLErrors[0].message);
                            // this.error =
                            //     "Er is iets misgegaan, probeer het later opnieuw.";
                            this.error = error.graphQLErrors[0].message;
                            const payload = {
                                color: "red",
                                message:
                                    "Something went wrong. Please review the form."
                            };
                            this.$store.dispatch(
                                "snackbar/showMessage",
                                payload
                            );
                        }
                    })
                    .finally(() => {
                        this.isSaving = false;
                        if (!this.copyBool) this.cancelTrip();
                        this.dialog = false;
                        //goto copied trip
                    });
            } else {
                let payload = {
                    tripId: this.trip.id,
                    referenceNumber: this.trip.referenceNumber,
                    transportMode: this.trip.transportMode,
                    shipmentFileNumber: this.trip.shipmentFileNumber,
                    carrier: this.trip.carrier,
                    plannedDepartureDate: this.location.plannedDepartureDate
                        ? helper.convertDateTimeToUTC(
                              this.location.plannedDepartureDate
                          )
                        : null,
                    plannedArrivalDate: this.location.plannedArrivalDate
                        ? helper.convertDateTimeToUTC(
                              this.location.plannedArrivalDate
                          )
                        : null,
                    trade: this.seaLoadPoint.trade,
                    tenantPartners: this.partners,
                    originLocationId: this.location.origin,
                    destinationLocationId: this.location.destination,
                    freights: freightInputFields,
                    seaTransports: seaTransportsInput
                };
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation UpdateTrip($input: UpdateTripInput!) {
                                updateTrip(input: $input) {
                                    trip {
                                        id
                                        createdAt
                                        completedAt
                                        referenceNumber
                                        shipmentFileNumber
                                        transportMode
                                        plannedDepartureDate
                                        plannedArrivalDate
                                        status
                                        isArchived
                                        isPrivate
                                        numberOfFreights
                                        numberOfPartners
                                        numberOfDocuments
                                        numberOfComments
                                        numberOfUnresolvedAlerts
                                        isUserFavorite
                                        trade
                                        tenantCarrier {
                                            id
                                            name
                                        }
                                        originLocation {
                                            id
                                            name
                                            city
                                            state
                                            country
                                            fullAddress
                                        }
                                        destinationLocation {
                                            id
                                            name
                                            city
                                            state
                                            country
                                            fullAddress
                                        }
                                        tripPartnerSet {
                                            edges {
                                                node {
                                                    id
                                                    tenantPartner {
                                                        id
                                                        name
                                                        partnerType
                                                        organisationPermissions
                                                    }
                                                }
                                            }
                                        }
                                        tripSeaTransportSet {
                                            edges {
                                                node {
                                                    id
                                                    portCode
                                                    portName
                                                    sequence
                                                    portTransportType
                                                    vesselInputType
                                                    vesselInputValue
                                                    voyage
                                                    vessel {
                                                        id
                                                        name
                                                        lastRouteJson
                                                    }
                                                    portTransportType
                                                    vesselInputType
                                                    vesselInputValue
                                                    actualDepartureDate
                                                    actualArrivalDate
                                                    estimatedDepartureDate
                                                    estimatedArrivalDate
                                                    originalEstimatedDepartureDate
                                                    originalEstimatedArrivalDate
                                                }
                                            }
                                        }
                                        tripFreightSet {
                                            edges {
                                                node {
                                                    id
                                                    referenceNumber
                                                    orderNumber
                                                    containerNumber
                                                    airWaybillNumber
                                                    billOfLadingNumber
                                                    loadDate
                                                    loadPoint
                                                    contact
                                                    goods
                                                    term
                                                    estimatedDepartureDate
                                                    estimatedArrivalDate
                                                    actualDepartureDate
                                                    actualArrivalDate
                                                    startDate
                                                    endDate
                                                    tripFreightAlertRuleSet {
                                                        edges {
                                                            node {
                                                                id
                                                                tenantAlertRule {
                                                                    id
                                                                    name
                                                                }
                                                            }
                                                        }
                                                    }
                                                    tripFreightTrackerSet {
                                                        edges {
                                                            node {
                                                                id
                                                                tracker {
                                                                    id
                                                                    serialNumber
                                                                    activatedDate
                                                                    lastConnectionTime
                                                                    lastBatteryDeviceTime
                                                                    lastBatteryValue
                                                                    lastTemperatureDeviceTime
                                                                    lastTemperatureValue
                                                                    lastExternalTemperatureDeviceTime
                                                                    lastExternalTemperatureValue
                                                                    lastProbeTemperatureDeviceTime
                                                                    lastProbeTemperatureValue
                                                                    lastHumidityDeviceTime
                                                                    lastHumidityValue
                                                                    lastLightDeviceTime
                                                                    lastLightValue
                                                                    lastShockDeviceTime
                                                                    lastShockValue
                                                                    lastLocationDeviceTime
                                                                    lastLatitude
                                                                    lastLongitude
                                                                    lastLocationDescription
                                                                }
                                                                name
                                                                lowTemperature
                                                                highTemperature
                                                                lowHumidity
                                                                highHumidity
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(response => {
                        this.$emit(
                            "trip-update",
                            response.data.updateTrip.trip
                        );
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.isSaving = false;
                        this.cancelTrip();
                        this.dialog = false;
                    });
            }
        },
        cancelTrip() {
            this.$refs.tripCard.reset();
            this.$refs.tripLocation.resetForm();
            if (this.templateMode) this.$refs.templateAutocomplete?.reset();
            this.location = {
                origin: null,
                destination: null,
                plannedDepartureDate: null,
                plannedDepartureTime: "00:00",
                plannedArrivalDate: null,
                plannedArrivalTime: "00:00"
            };
            this.seaLoadPoint = {
                trade: ""
            };
            this.seaTransport = [
                {
                    id: null,
                    sequence: 0,
                    voyage: "",
                    portCode: "",
                    portTransportType: "POL",
                    vesselInputType: "",
                    vesselInputValue: "",
                    estimatedDepartureDate: ""
                },
                {
                    id: null,
                    sequence: 1,
                    portCode: "",
                    portTransportType: "POD",
                    estimatedArrivalDate: ""
                }
            ];
            this.partners = [];
            (this.freights = [
                {
                    id: null,
                    referenceNumber: null,
                    containerNumber: null,
                    awb: null,
                    orderNumber: null,
                    billsOfLading: null,
                    goods: null,
                    term: null,
                    contact: "",
                    loadPoint: "",
                    loadDate: null,
                    startDate: null,
                    endDate: null,
                    startTime: null,
                    endTime: null,
                    trackers: [],
                    alerts: []
                }
            ]),
                (this.stepNo = 1);
            this.dialog = false;
            this.$emit("close-dialog");
        },
        closeNameDescriptionDialog() {
            this.openNameDescriptionDialog = false;
            this.$refs.nameDescriptionDialog.isLoading = false;
        },
        saveNameDescription() {
            if (!this.editMode) {
                this.saveTemplate();
            } else {
                this.$refs.nameDescriptionDialog.isLoading = false;
                this.openNameDescriptionDialog = false;
            }
        },
        saveTemplate() {
            this.isSaving = true;
            let freightInputFields = [];
            let seaTransportsInput = [];

            this.seaTransport.forEach(i => {
                seaTransportsInput.push({
                    sequence: i.sequence,
                    portCode: i.portCode,
                    portTransportType: i.portTransportType
                });
            });
            this.freights.forEach(e => {
                freightInputFields.push({
                    goods: e.goods,
                    term: e.term,
                    alerts: e.alerts,
                    contact: e.contact,
                    loadPoint: e.loadPoint
                });
            });

            let tripjson = JSON.stringify({
                transportMode: this.trip.transportMode,
                carrier: this.trip.carrier,

                origin: this.location.origin,
                destination: this.location.destination,
                plannedDepartureTime: this.location.plannedDepartureTime,
                plannedArrivalTime: this.location.plannedArrivalTime,

                freights: freightInputFields,
                seaTransports: seaTransportsInput,
                trade: this.seaLoadPoint.trade,

                partners: this.partners
            });
            if (!this.editMode) {
                let payload = {
                    description: this.templateNameDescription.description,
                    lockTemplate: false,
                    name: this.templateNameDescription.name,
                    tenant: this.me.tenant.id,
                    tripJson: tripjson
                };

                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation createTripTemplate(
                                $input: CreateTripTemplateInput!
                            ) {
                                createTripTemplate(input: $input) {
                                    tripTemplate {
                                        id
                                        name
                                        tripJson
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(() => {
                        this.$emit("template-created");
                    })
                    .finally(() => {
                        this.isSaving = false;
                        this.cancelTrip();
                        this.openNameDescriptionDialog = false;
                        this.dialog = false;
                        this.$refs.nameDescriptionDialog.isLoading = false;
                        this.templateNameDescription = {
                            name: null,
                            description: null
                        };
                    });
            } else {
                let payload = {
                    id: this.trip.id,
                    description: this.templateNameDescription.description,
                    lockTemplate: false,
                    name: this.templateNameDescription.name,
                    tenant: this.me.tenant.id,
                    tripJson: tripjson
                };

                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation editTripTemplate(
                                $input: UpdateTripTemplateInput!
                            ) {
                                editTripTemplate(input: $input) {
                                    tripTemplate {
                                        id
                                        name
                                        tripJson
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(() => {
                        this.$emit("template-edited");
                    })
                    .finally(() => {
                        this.isSaving = false;
                        this.cancelTrip();
                        this.$refs.nameDescriptionDialog.isLoading = false;
                    });
            }
        },
        getTime(date) {
            let _time = this.formatDateTime(date);
            return _time?.slice(11, 16);
        },
        getDate(date) {
            let _date = this.updateDateTimeTimezone(date);
            return _date?.slice(0, 10);
        },
        goBack() {
            if (this.stepNo > 1 && this.stepNo <= 4) this.stepNo--;
        }
    }
};
</script>
<style scoped>
.maxHeight {
    max-height: 460px;
}
.form-input >>> .error--text {
    color: yellow !important;
}
.v-application .error--text {
    color: yellow !important;
}
</style>
