<template>
    <v-container fluid>
        <v-form v-model="dialogForm" ref="freightsForm">
            <v-row dense class="mt-3">
                <v-col cols="12">
                    <v-card flat outlined>
                        <v-timeline align-top dense class="ml-n3 pr-4">
                            <v-timeline-item color="white" class="py-0">
                                <template v-slot:icon>
                                    <v-icon color="primary">tag</v-icon>
                                </template>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="freight.referenceNumber"
                                            :label="
                                                $t('forms.freight_ref') + '*'
                                            "
                                            outlined
                                            :rules="[rules.required]"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5">
                                        <v-text-field
                                            v-model="freight.orderNumber"
                                            :label="$t('forms.order_number')"
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                            <v-timeline-item color="white" class="py-0">
                                <template v-slot:icon>
                                    <v-icon color="primary">description</v-icon>
                                </template>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="freight.billsOfLading"
                                            :label="$t('forms.bill_lading')"
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="5"
                                        v-if="
                                            transport === 'sea' ||
                                                transport === 'air'
                                        "
                                    >
                                        <v-text-field
                                            v-model="freight.containerNumber"
                                            :label="$t('forms.container')"
                                            outlined
                                            v-if="transport === 'sea'"
                                            dense
                                        ></v-text-field>
                                        <v-text-field
                                            v-if="transport === 'air'"
                                            v-model="freight.awb"
                                            :label="$t('forms.awb')"
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                            <v-timeline-item color="white" class="py-0">
                                <template v-slot:icon>
                                    <v-icon color="primary">inventory_2</v-icon>
                                </template>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="freight.goods"
                                            :label="$t('forms.goods')"
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5">
                                        <v-select
                                            v-model="freight.term"
                                            :items="termChoices"
                                            :label="$t('forms.term')"
                                            outlined
                                            dense
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                            <v-timeline-item color="white" class="py-0">
                                <template v-slot:icon>
                                    <v-icon color="primary">location_on</v-icon>
                                </template>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="freight.loadPoint"
                                            :label="$t('forms.load_point')"
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5">
                                        <DateComponent
                                            v-model="freight.loadDate"
                                            :label="$t('forms.load_date')"
                                            :icon="'calendar_month'"
                                            :outlined="true"
                                            :dense="true"
                                            :hideDetails="true"
                                        ></DateComponent>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-text-field
                                            v-model="freight.contact"
                                            :label="$t('forms.contact_person')"
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                            <v-timeline-item color="white" class="py-0">
                                <template v-slot:icon>
                                    <v-icon color="primary"
                                        >settings_remote</v-icon
                                    >
                                </template>
                                <v-row dense class="pb-5">
                                    <v-col cols="11">
                                        <TrackerSelected
                                            v-model="freight.trackers"
                                            :label="$t('forms.select_tracker')"
                                            :placeholder="''"
                                            :dense="true"
                                            :tripId="trip.id"
                                            :currentTrackers="currentTrackers"
                                            @focusTrackerSelected="
                                                val =>
                                                    emitFocusTrackerSelected(
                                                        val
                                                    )
                                            "
                                        >
                                        </TrackerSelected>
                                    </v-col>
                                </v-row>
                                <v-row dense
                                    ><v-col cols="11" class="py-0">
                                        <DateTimeComponent
                                            v-model="freight.startDate"
                                            :label="
                                                $t(
                                                    'trackers.tracker_start_date'
                                                )
                                            "
                                            :icon="'calendar_month'"
                                            :outlined="true"
                                            :dense="true"
                                            :defaultTime="'00:00'"
                                        >
                                        </DateTimeComponent>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="11">
                                        <DateTimeComponent
                                            v-model="freight.endDate"
                                            :label="
                                                $t('trackers.tracker_end_date')
                                            "
                                            :icon="'calendar_month'"
                                            :outlined="true"
                                            :dense="true"
                                            :defaultTime="'00:00'"
                                            :clearable="false"
                                            :rules="freight.endDate !== null &&
                                                freight.endDate.length > 0
                                                ? [rules.checkTrackerDate]
                                                : []"
                                        >
                                        </DateTimeComponent>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                            <v-timeline-item color="white">
                                <template v-slot:icon>
                                    <v-icon color="primary"
                                        >notifications</v-icon
                                    >
                                </template>
                                <v-row dense>
                                    <v-col cols="11">
                                        <AlertRuleAutoComplete
                                            v-model="freight.alerts"
                                            :refresh.sync="refresh"
                                            @focusAlertRuleSelected="
                                                val =>
                                                    emitFocusAlertRulesSelected(
                                                        val
                                                    )
                                            "
                                        >
                                        </AlertRuleAutoComplete>
                                    </v-col>
                                    <v-col>
                                        <v-btn
                                            icon
                                            outlined
                                            color="primary"
                                            small
                                            class="mt-2"
                                            @click="addAlertRuleMethod"
                                        >
                                            <v-icon> add</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <AddAlertRuleDialog
            :openAddAlertRuleDialog="openAddAlertRule"
            :createMode="true"
            v-on:refreshDataTable="refresh = true"
            v-on:close-dialog="openAddAlertRule = false"
            v-on:item-created="onItemCreated"
        ></AddAlertRuleDialog>
    </v-container>
</template>
<script>
import DateComponent from "@/components/base/DateComponent.vue";
//import TimeComponent from "@/components/base/TimeComponent.vue";
import TrackerSelected from "@/components/trackers/TrackerSelected.vue";
import helper from "@/utils/helper.js";
import AlertRuleAutoComplete from "@/components/alertrules/AlertRuleAutoComplete.vue";
import AddAlertRuleDialog from "@/components/alertrules/create/AddAlertRuleDialog.vue";
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import moment from "moment-timezone";

export default {
    components: {
        DateComponent,
        //TimeComponent,
        TrackerSelected,
        AlertRuleAutoComplete,
        AddAlertRuleDialog,
        DateTimeComponent
    },
    props: {
        transport: {
            type: String,
            default: "sea"
        },
        value: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean
        },
        trip: {
            type: Object
        },
        editObj: {
            type: Object
        },
        currentTrackers: {
            type: Array
        }
    },
    data() {
        return {
            dateRangeMenu: false,
            freight: {
                /*  estimatedDepartureDate: "",
                estimatedArrivalDate: "", */
                containerNumber: "",
                awb: "",
                goods: "",
                term: "",
                orderNumber: "",
                referenceNumber: "",
                /*  estimatedDepartureTime: "00:00",
                estimatedArrivalTime: "00:00", */
                contact: "",
                loadPoint: "",
                loadDate: null,
                billsOfLading: "",
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                trackers: []
            },
            defaultFreight: {
                /* estimatedDepartureDate: "",
                estimatedArrivalDate: "", */
                containerNumber: "",
                awb: "",
                goods: "",
                term: "",
                orderNumber: "",
                referenceNumber: "",
                /* estimatedDepartureTime: "00:00",
                estimatedArrivalTime: "00:00", */
                billsOfLading: "",
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                trackers: []
            },
            refresh: false,
            openAddAlertRule: false,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long"),
                checkTrackerDate: () => {
                    //let _selectedDate = new Date(v)
                    if (
                        this.freight.startDate === null ||
                        this.freight.startDate.length < 1
                    ) {
                        return true;
                    }
                    if (
                        this.freight.endDate === null ||
                        this.freight.endDate.length < 1
                    ) {
                        return true;
                    }

                    let [dateA, timeA] = this.freight.startDate.split(" ");
                    let [dateB, timeB] = this.freight.endDate.split(" ");
                    
                    if (this.isEmpty(dateA) || this.isEmpty(timeA)) {
                        return true;
                    }

                    let _newDateA = moment(dateA, helper.getDateFormat());
                    let _DateA = moment(_newDateA, [
                        "DD/MM/YYYY",
                        "YYYY/MM/DD",
                        "MM/DD/YYYY"
                    ]).toDate();
                    let _dateFormatA = moment(_DateA).format("YYYY-MM-DD");

                    //dateTime B
                    let _newDateB = moment(dateB, helper.getDateFormat());
                    let _DateB = moment(_newDateB, [
                        "DD/MM/YYYY",
                        "YYYY/MM/DD",
                        "MM/DD/YYYY"
                    ]).toDate();
                    let _dateFormatB = moment(_DateB).format("YYYY-MM-DD");

                    let dateTimeA = _dateFormatA + " " + timeA;
                    let dateTimeB = _dateFormatB + " " + timeB;

                    let validDate = moment(dateTimeA).isSameOrBefore(dateTimeB);

                    return validDate || this.$t("rules.end_date");
                },    
            },
            termChoices: [
                {
                    value: "DAP",
                    text: `DAP (${this.$t("term.dap")})` 
                },
                {
                    value: "DAT",
                    text: `DAT (${this.$t("term.dat")})`
                },
                {
                    value: "DDP",
                    text: `DDP (${this.$t("term.ddp")})`
                },
                {
                    value: "DPU",
                    text: `DPU (${this.$t("term.dpu")})`
                },
                {
                    value: "CFR",
                    text: `CFR (${this.$t("term.cfr")})`
                },
                {
                    value: "CIF",
                    text: `CIF (${this.$t("term.cif")})`
                },
                {
                    value: "CIP",
                    text: `CIP (${this.$t("term.cip")})`
                },
                {
                    value: "CPT",
                    text: `CPT (${this.$t("term.cpt")})`
                },
                { 
                    value: "EXW", 
                    text: `EXW (${this.$t("term.exw")})` 
                },
                { 
                    value: "FCA", 
                    text: `FCA (${this.$t("term.fca")})`
                },
                {
                    value: "FAS",
                    text: `FAS (${this.$t("term.fas")})`
                },
                { 
                    value: "FOB", 
                    text: `FOB (${this.$t("term.fob")})`
                }
            ]
        };
    },
    computed: {
        dialogForm: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    mounted() {},
    watch: {
        editObj: {
            immediate: true,
            handler: function(newVal) {
                let checkObject = Object.keys(newVal).length > 0;
                if (checkObject) {
                    (this.freight = { ...newVal }),
                        (this.defaultFreight = { ...newVal });
                }
            },
            deep: true
        },
        "freight.startDate": function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$refs.freightsForm.validate();
            }
        },
        "freight.endDate": function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$refs.freightsForm.validate();
            }
        }
    },
    methods: {
        addAlertRuleMethod() {
            this.openAddAlertRule = true;
        },
        resetForm() {
            /* this.$refs.form.reset(); */
            this.dateRangeMenu = false;
            /* this.freight.estimatedArrivalDate = "";
            this.freight.estimatedDepartureDate = ""; */
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        newFreight() {
            this.$emit("create-freight", this.freight);
        },
        editFreight() {
            this.$emit("update-freight", this.freight);
        },
        onItemCreated(item) {
            this.freight.alerts.push(item.id);
        },
        emitFocusTrackerSelected(val) {
            val
                ? this.$emit("focusSelected", "50rem")
                : this.$emit("focusSelected", "auto");
        },
        emitFocusAlertRulesSelected(val) {
            val
                ? this.$emit("focusSelected", "60rem")
                : this.$emit("focusSelected", "auto");
        },
        disablePastDates(val) {
            return val >= this.trip.plannedDepartureDate;
        },
        isEmpty(val) {
            if(
                val === null || val === undefined || val === '' || 
                val === 'null' || val === 'undefined' || val === 'NaN' ||
                val === 'Invalid date-undefined-undefined'
            ) {
                return true;
            }else {
                return false;
            } 
        }
    }
};
</script>
