<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <!-- loading progress bar -->

            <!-- freights -->
            <!-- freight cards -->

            <LoadingBar v-if="$apollo.queries.tripFreights.loading && !hasTripFreight"></LoadingBar>
            <!-- No data  -->
            <NoData v-else-if="!$apollo.queries.tripFreights.loading && !hasTripFreight">
                <template v-slot:content>{{ $t("trips.no_freights") }}</template>
            </NoData>
            <div v-else>
                <LoadingBar v-if="$apollo.queries.tripFreights.loading && hasTripFreight"></LoadingBar>
                <v-expansion-panels v-model="panelFreight" multiple>
                    <v-expansion-panel
                        v-for="(freight, index) in tripFreight.edges"
                        :key="freight.node.id"
                        elevation="5"
                        class="mb-5 pb-0 rounded-xl"
                    >
                        <v-expansion-panel-header
                            :class="panelFreight.includes(index) ? 'background2 py-3' : 'background2 py-3 rounded-xl'"
                        >
                            <template v-slot:default>
                                <v-row no-gutters>
                                    <v-col class="d-flex">
                                        <div class="mr-5">
                                            <v-img :src="require('@/assets/container.png')" width="30"></v-img>
                                        </div>
                                        <span class="mt-2">{{ getFreightTitle(freight) }}</span>

                                        <v-col> <v-spacer></v-spacer></v-col>
                                        <v-btn
                                            v-if="canEdit"
                                            color="primary"
                                            class="mr-5"
                                            icon
                                            @click.stop.prevent="editItem(freight.node)"
                                        >
                                            <v-icon>edit</v-icon>
                                        </v-btn></v-col
                                    >
                                </v-row>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-sheet class="px-2 mb-3 pt-1 mt-2" elevation="0">
                                <!-- freight info -->
                                <v-row class="px-1 py-1  d-flex justify-space-between" align="stretch">
                                    <!-- container number -->
                                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="elevation-0">
                                        <table style="width:100%">
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr v-if="freightProp && trip.tripFreightSet.edges.length > 1">
                                                <td class="txtIndex text-start">
                                                    {{ $t("trips.related_freights") }}
                                                </td>
                                                <td class="txtBody text-end">
                                                    <a
                                                        v-for="relatedFreight in getRelatedFreights()"
                                                        :key="relatedFreight.id"
                                                        @click="redirectFreight(relatedFreight.id)"
                                                        class="txtBody"
                                                    >
                                                        {{ relatedFreight.referenceNumber }}
                                                    </a>
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr v-if="trip.transportMode.toLowerCase() === 'sea'">
                                                <td class="txtIndex text-start">
                                                    {{ $t("headers.container_number") }}
                                                </td>
                                                <td class="txtBody text-end">
                                                    {{
                                                        freight.node.containerNumber ? freight.node.containerNumber : ""
                                                    }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr v-if="trip.transportMode.toLowerCase() === 'air'">
                                                <td class="txtIndex text-start">
                                                    {{ $t("trips.awb") }}
                                                </td>
                                                <td class="txtBody text-end">
                                                    <div v-if="freight.node.airWaybillNumber">
                                                        {{ formatAirwaybillNumber(freight.node.airWaybillNumber) }} |
                                                        {{ freight.node.airWaybillNumber }}
                                                    </div>
                                                    <div v-else></div>
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <td class="txtIndex text-start">
                                                    {{ $t("forms.order_number") }}
                                                </td>
                                                <td class="txtBody text-end">
                                                    {{ freight.node.orderNumber ? freight.node.orderNumber : "" }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <td class="txtIndex text-start">
                                                    {{ $t("headers.bill") }}
                                                </td>
                                                <td class="txtBody text-end">
                                                    {{
                                                        freight.node.billOfLadingNumber
                                                            ? freight.node.billOfLadingNumber
                                                            : ""
                                                    }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <td class="txtIndex text-start">
                                                    {{ $t("headers.goods") }}
                                                </td>
                                                <td class="txtBody text-end">
                                                    {{ freight.node.goods ? freight.node.goods : "" }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <td class="txtIndex text-start">
                                                    {{ $t("forms.term") }}
                                                </td>
                                                <td class="txtBody text-end">
                                                    {{ freight.node.term ? freight.node.term : "" }}
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="elevation-0">
                                        <table style="width: 100%;">
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("forms.contact") }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{ freight.node.contact ? freight.node.contact : "" }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("forms.load_point") }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{ freight.node.loadPoint ? freight.node.loadPoint : "" }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("forms.load_date") }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{
                                                        freight.node.loadDate
                                                            ? formatDateTime(freight.node.loadDate)
                                                            : ""
                                                    }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("headers.last_connection") }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{
                                                        freight.node.trackerLastConnectionTime
                                                            ? formatDateTime(freight.node.trackerLastConnectionTime)
                                                            : ""
                                                    }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("headers.last_location") }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{
                                                        freight.node.trackerLastLocation
                                                            ? freight.node.trackerLastLocation
                                                            : ""
                                                    }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <td class="txtIndex text-start" style="width: 50px;">
                                                    {{ $t("general.tracker_range") }}
                                                </td>
                                                <td class="txtBody text-end">
                                                    {{
                                                        !$apollo.queries.tripFreights.loading
                                                            ? trackerRangeDate.find(item => item.id === freight.node.id)
                                                                  .range
                                                            : ""
                                                    }}
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="elevation-0">
                                        <table style="width:100%">
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr v-if="freightProp && trip">
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("headers.trip") }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    <a @click="redirectTrip" class="txtBody">
                                                        {{ trip.referenceNumber }}
                                                    </a>
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("trips.origin") }}
                                                    {{ originWasAvailable ? "" : "(POL)" }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{ tripDescription.origin ? tripDescription.origin : "" }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("trips.destination") }}
                                                    {{ destinationWasAvailable ? "" : "(POD)" }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{ tripDescription.destination ? tripDescription.destination : "" }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("headers.carrier") }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{ tripDescription.carrier ? tripDescription.carrier : "" }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ $t("headers.consignee") }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{ tripDescription.consignee ? tripDescription.consignee : "" }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{
                                                        tripSeaTransportData.actualDepartureDate
                                                            ? $t("trips.actual_departure")
                                                            : $t("trips.estimated_departure")
                                                    }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{
                                                        tripSeaTransportData
                                                            ? formatDateTime(
                                                                  tripSeaTransportData.actualDepartureDate
                                                                      ? tripSeaTransportData.actualDepartureDate
                                                                      : tripSeaTransportData.estimatedDepartureDate
                                                                      ? tripSeaTransportData.estimatedDepartureDate
                                                                      : tripSeaTransportData.originalEstimatedDepartureDate
                                                              )
                                                            : ""
                                                    }}
                                                </td>
                                            </tr>
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{
                                                        tripSeaTransportData.actualArrivalDate
                                                            ? $t("trips.actual_arrival")
                                                            : $t("trips.estimated_arrival")
                                                    }}
                                                </th>
                                                <td class="txtBody text-end">
                                                    {{
                                                        tripSeaTransportData
                                                            ? formatDateTime(
                                                                  tripSeaTransportData.actualArrivalDate
                                                                      ? tripSeaTransportData.actualArrivalDate
                                                                      : tripSeaTransportData.estimatedArrivalDate
                                                                      ? tripSeaTransportData.estimatedArrivalDate
                                                                      : tripSeaTransportData.originalEstimatedArrivalDate
                                                              )
                                                            : ""
                                                    }}
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                            <v-expansion-panels v-model="panel" accordion full-width multiple class="rounded-b-xl">
                                <v-expansion-panel
                                    v-for="(item, i) in freight.node.tripFreightTrackerSet.edges"
                                    :key="item.node.id"
                                    :class="
                                        i + 1 === freight.node.tripFreightTrackerSet.edges.length
                                            ? 'mx-3 my-0 py-1 '
                                            : 'mx-3 mb-n1 mt-0'
                                    "
                                >
                                    <v-expansion-panel-header>
                                        <template v-slot:default="{ open }">
                                            <v-row no-gutters>
                                                <v-col>
                                                    <v-chip
                                                        :color="
                                                            alertTracker(item.node.tracker.lastConnectionTime)
                                                                ? 'primary'
                                                                : ''
                                                        "
                                                        class="mr-3 pr-3"
                                                    >
                                                        <v-icon>settings_remote</v-icon>
                                                        <div class="mr-1">
                                                            {{ item.node.tracker.serialNumber }}
                                                        </div>
                                                        <v-btn
                                                            icon
                                                            color="white"
                                                            small
                                                            @click="copyToClipboard(item.node.tracker.serialNumber)"
                                                            @click.stop=""
                                                        >
                                                            <v-icon small>
                                                                content_copy
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-chip>
                                                    <v-chip v-if="item.node.tracker.model" outlined>
                                                        <div class="">
                                                            {{ item.node.tracker.model }}
                                                        </div>
                                                    </v-chip>
                                                </v-col>
                                                <v-spacer></v-spacer>
                                                <v-col class="pr-10">
                                                    <v-fade-transition leave-absolute>
                                                        <div
                                                            class="
                                                    grey--text
                                                    d-flex
                                                    justify-end
                                                    align-center
                                                    mt-2
                                                    caption
                                                "
                                                            v-if="item.node.tracker.lastConnectionTime"
                                                        >
                                                            <div>
                                                                <i
                                                                    >{{ $t("trips.last_connection") }}
                                                                    {{ $t("general.at") }}
                                                                    {{
                                                                        relativeTime(
                                                                            item.node.tracker.lastConnectionTime
                                                                        )
                                                                    }}</i
                                                                >
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="
                                                    grey--text
                                                    d-flex  
                                                    justify-end
                                                    align-center
                                                    mt-2
                                                    caption
                                                "
                                                            v-else
                                                        >
                                                            <div>
                                                                <i>{{ $t("loading.no_available") }}</i>
                                                            </div>
                                                        </div>
                                                    </v-fade-transition>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="my-2 px-5 py-2">
                                        <v-row dense>
                                            <!-- Temperature -->
                                            <v-col cols="3" v-if="item.node.tracker.lastTemperatureValue">
                                                <v-card class="pa-1">
                                                    <v-card-title style="height: 90px">
                                                        <v-row dense>
                                                            <v-col>
                                                                <div
                                                                    class="
                                                            font-weight-medium
                                                        "
                                                                >
                                                                    {{
                                                                        Math.floor(
                                                                            formatTemperature(
                                                                                item.node.tracker.lastTemperatureValue
                                                                            )
                                                                        )
                                                                    }}
                                                                    {{ getTemperatureSymbol() }}
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-btn
                                                                            class="indigo"
                                                                            elevation="0"
                                                                            fab
                                                                            small
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-icon
                                                                                class="
                                                                        d-flex
                                                                        align-center
                                                                        justify-center
                                                                    "
                                                                                color="white"
                                                                            >
                                                                                thermostat
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{ $t("general.temperature") }}</span>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="text-caption">
                                                            {{
                                                                formatDateTime(
                                                                    item.node.tracker.lastTemperatureDeviceTime
                                                                )
                                                            }}
                                                        </div>
                                                        <!-- <div class="text-caption">{{formatDateTime(item.node.tracker.lastConnectionTime)}}</div> -->
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <!-- External Temperature -->
                                            <v-col cols="3" v-if="item.node.tracker.lastExternalTemperatureValue">
                                                <v-card class="pa-1">
                                                    <v-card-title style="height: 90px">
                                                        <v-row dense>
                                                            <v-col>
                                                                <div
                                                                    class="
                                                            font-weight-medium
                                                        "
                                                                >
                                                                    {{
                                                                        Math.floor(
                                                                            formatTemperature(
                                                                                item.node.tracker
                                                                                    .lastExternalTemperatureValue
                                                                            )
                                                                        )
                                                                    }}
                                                                    {{ getTemperatureSymbol() }}
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-btn
                                                                            class="indigo"
                                                                            elevation="0"
                                                                            fab
                                                                            small
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-icon
                                                                                class="
                                                                        d-flex
                                                                        align-center
                                                                        justify-center
                                                                    "
                                                                                color="white"
                                                                            >
                                                                                thermostat
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{
                                                                        $t("general.external_temperature")
                                                                    }}</span>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="text-caption">
                                                            {{
                                                                formatDateTime(
                                                                    item.node.tracker.lastExternalTemperatureDeviceTime
                                                                )
                                                            }}
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <!-- Probe Temperature -->
                                            <v-col cols="3" v-if="item.node.tracker.lastProbeTemperatureValue">
                                                <v-card class="pa-1">
                                                    <v-card-title style="height: 90px">
                                                        <v-row dense>
                                                            <v-col>
                                                                <div
                                                                    class="
                                                            font-weight-medium
                                                        "
                                                                >
                                                                    {{
                                                                        Math.floor(
                                                                            formatTemperature(
                                                                                item.node.tracker
                                                                                    .lastProbeTemperatureValue
                                                                            )
                                                                        )
                                                                    }}
                                                                    {{ getTemperatureSymbol() }}
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-btn
                                                                            class="indigo"
                                                                            elevation="0"
                                                                            fab
                                                                            small
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-icon
                                                                                class="
                                                                        d-flex
                                                                        align-center
                                                                        justify-center
                                                                    "
                                                                                color="white"
                                                                            >
                                                                                thermostat
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{ $t("general.probe_temperature") }}</span>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="text-caption">
                                                            {{
                                                                formatDateTime(
                                                                    item.node.tracker.lastProbeTemperatureDeviceTime
                                                                )
                                                            }}
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <!-- Humidity -->
                                            <v-col cols="3" v-if="item.node.tracker.lastHumidityValue">
                                                <v-card class="pa-1">
                                                    <v-card-title style="height: 90px">
                                                        <v-row dense>
                                                            <v-col>
                                                                <div
                                                                    class="
                                                            font-weight-medium
                                                        "
                                                                >
                                                                    {{
                                                                        Math.floor(item.node.tracker.lastHumidityValue)
                                                                    }}
                                                                    %
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-btn
                                                                            class="cyan"
                                                                            elevation="0"
                                                                            fab
                                                                            small
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-icon
                                                                                class="
                                                                        d-flex
                                                                        align-center
                                                                        justify-center
                                                                    "
                                                                                color="white"
                                                                            >
                                                                                water_drop
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{ $t("general.humidity") }}</span>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="text-caption">
                                                            {{
                                                                formatDateTime(item.node.tracker.lastHumidityDeviceTime)
                                                            }}
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="3" v-if="item.node.tracker.lastLightValue">
                                                <v-card class="pa-1">
                                                    <v-card-title style="height: 90px">
                                                        <v-row dense>
                                                            <v-col>
                                                                <div
                                                                    class="
                                                            font-weight-medium
                                                        "
                                                                >
                                                                    {{ item.node.tracker.lastLightValue.toFixed(2) }}
                                                                    Lux
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-btn
                                                                            class="amber"
                                                                            elevation="0"
                                                                            fab
                                                                            small
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-icon
                                                                                class="
                                                                        d-flex
                                                                        align-center
                                                                        justify-center
                                                                    "
                                                                                color="white"
                                                                            >
                                                                                lightbulb
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{ $t("general.light") }}</span>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="text-caption">
                                                            {{ formatDateTime(item.node.tracker.lastLightDeviceTime) }}
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="3" v-if="item.node.tracker.lastShockValue">
                                                <v-card class="pa-1">
                                                    <v-card-title style="height: 90px">
                                                        <v-row dense>
                                                            <v-col>
                                                                <div
                                                                    class="
                                                            font-weight-medium
                                                        "
                                                                >
                                                                    {{ Math.floor(item.node.tracker.lastShockValue) }}
                                                                    G
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-btn
                                                                            class="error"
                                                                            elevation="0"
                                                                            fab
                                                                            small
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-icon
                                                                                class="
                                                                        d-flex
                                                                        align-center
                                                                        justify-center
                                                                    "
                                                                                color="white"
                                                                            >
                                                                                bolt
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{ $t("general.shock") }}</span>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="text-caption">
                                                            {{ formatDateTime(item.node.tracker.lastShockDeviceTime) }}
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <!-- Battery -->
                                            <v-col
                                                cols="3"
                                                v-if="item.node.tracker.brand.toLowerCase() !== 'sensitech'"
                                            >
                                                <v-card class="pa-1">
                                                    <v-card-title style="height: 90px">
                                                        <v-row dense>
                                                            <v-col>
                                                                <div
                                                                    class="
                                                                        font-weight-medium
                                                                    "
                                                                >
                                                                    {{
                                                                        item.node.tracker.lastBatteryValue !==
                                                                            undefined &&
                                                                        item.node.tracker.lastBatteryValue !== null
                                                                            ? Math.floor(
                                                                                  item.node.tracker.lastBatteryValue
                                                                              )
                                                                            : 0
                                                                    }}
                                                                    {{ unitPerBrand(item.node.tracker.brand) }}
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-btn
                                                                            class="success"
                                                                            elevation="0"
                                                                            fab
                                                                            small
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-icon
                                                                                class="
                                                                        d-flex
                                                                        align-center
                                                                        justify-center
                                                                    "
                                                                                color="white"
                                                                            >
                                                                                battery_charging_full
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{ $t("general.battery") }}</span>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="text-caption">
                                                            {{
                                                                formatDateTime(item.node.tracker.lastBatteryDeviceTime)
                                                            }}
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>

                                            <!-- <v-col cols="3" v-if="
                                item.node.tracker.lastLatitude &&
                                item.node.tracker.lastLongitude
                            ">
                            <v-card>
                                    <v-card-title style="height:90px">
                                        <v-row dense>
                                            <v-col>
                                                <div class="font-weight-medium">
                                                    {{
                                                        item.node.tracker
                                                                .lastLatitude
                                                    }}
                                                </div>
                                                <div class="font-weight-medium">
                                                    {{
                                                            item.node.tracker
                                                                .lastLongitude
                                                            }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-btn class="deep-purple  mt-3" elevation="0" fab small>
                                                            <v-icon class="d-flex align-center justify-center" color="white">
                                                                public
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-caption">
                                            {{
                                                formatDateTime(
                                                    item.node.tracker
                                                    .lastLocationDeviceTime
                                                    )
                                                }}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                            </v-col>
                            <v-col cols="3" v-if="item.node.tracker.lastLocationDescription">
                                <v-card>
                                    <v-card-title style="height:90px">
                                        <v-row dense>
                                            <v-col>
                                                <div class="font-weight-medium">
                                                    {{
                                                        item.node.tracker
                                                        .lastLocationDescription
                                                    }}
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn class="primary" elevation="0" fab small>
                                                    <v-icon class="d-flex align-center justify-center" color="white">
                                                        location_on
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-caption">
                                            {{
                                                formatDateTime(
                                                        item.node.tracker
                                                        .lastLocationDeviceTime
                                                        )
                                                    }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col> -->
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-icon>notifications</v-icon>
                                                {{ $t("home.alert_rules") }} ({{
                                                    freight.node.tripFreightAlertRuleSet.edges.length
                                                }})
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <!-- <v-col class="pr-10">
                                    <v-fade-transition leave-absolute >
                                        <div class="
                                                grey--text
                                                d-flex
                                                justify-end
                                                align-center
                                                mt-2
                                                caption
                                            ">
                                            <div>
                                                <i>{{ freight.node.tripFreightAlertRuleSet.edges
                                .length }} alert rules</i>
                                            </div>
                                        </div>
                                    </v-fade-transition>
                                </v-col> -->
                                        </v-row>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="pb-3 px-5">
                                        <v-row
                                            dense
                                            class="mt-1 mx-0"
                                            v-if="freight.node.tripFreightAlertRuleSet.edges.length > 0"
                                        >
                                            <div
                                                dense
                                                v-for="(item, i) in freight.node.tripFreightAlertRuleSet.edges"
                                                :key="i"
                                            >
                                                <v-row v-if="item.node.tenantAlertRule">
                                                    <v-col class="mr-1">
                                                        <v-chip
                                                            outlined
                                                            label
                                                            @click="redirectToAlertRule(item.node.tenantAlertRule.id)"
                                                        >
                                                            {{ item.node.tenantAlertRule.name }}
                                                        </v-chip>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-row>
                                        <v-row v-else>
                                            <v-col class="mr-1">
                                                <div class="caption grey--text">
                                                    <i>{{ $t("loading.no_available") }}</i>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>

            <!-- Create and Edit Dialog -->
            <v-dialog v-model="createDialog" max-width="800px">
                <v-card class="overflow-hidden" :height="FreightsFormCardHeight">
                    <v-card-title class="">
                        {{ toggleTitle ? this.$t("trips.edit_freight") : this.$t("trips.add_freight") }}
                    </v-card-title>
                    <v-card-text>
                        <FreightsForm
                            v-model="validateForm"
                            ref="freightForm"
                            :transport="trip.transportMode.toLowerCase()"
                            :trip="tripObj"
                            :editObj="item"
                            v-on:create-freight="addFreight"
                            v-on:update-freight="editFreight"
                            v-on:close-dialog="closeFreightForm"
                            :isOpen="createDialog"
                            :currentTrackers="currentTrackers"
                            class="overflow-visible"
                            @focusSelected="val => resizeFreightsFormCard(val)"
                        >
                        </FreightsForm>
                    </v-card-text>
                    <v-card-actions height="100">
                        <v-spacer></v-spacer>
                        <v-btn text rounded @click="closeFreightForm">{{ $t("general.cancel") }} </v-btn>
                        <v-btn
                            color="primary"
                            :loading="loadingCreateEditBtn"
                            rounded
                            @click="toggleTitle ? $refs.freightForm.editFreight() : $refs.freightForm.newFreight()"
                            :disabled="!validateForm"
                            >{{ toggleTitle ? this.$t("general.save") : this.$t("general.add") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Dialog -->
            <v-dialog v-model="deleteDialog" max-width="300px" height="500px" transition="dialog-bottom-transition">
                <v-card>
                    <v-card-title class="background2 py-3 mb-2">
                        <div class="mr-2">
                            <v-icon>warning</v-icon>
                        </div>
                        <span>
                            <h5>{{ $t("trips.delete_freight") }}</h5>
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <div>
                            <span>
                                <h4>
                                    {{ $t("trips.confirm_freight") }}
                                </h4>
                            </span>
                        </div>
                        <div class="mt-2">
                            <span>{{ deletedItem.referenceNumber }}</span>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn width="80px" rounded outlined @click="cancelDeleteDialog">{{
                            $t("general.cancel")
                        }}</v-btn>
                        <v-btn
                            width="80px"
                            color="error"
                            rounded
                            outlined
                            :loading="loadingDeleteBtn"
                            @click="deleteFreight(deletedItem.id)"
                            >{{ $t("general.delete") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-row dense class="mb-4 mt-2 d-flex justify-center">
                <v-col cols="2" class="d-flex justify-center">
                    <v-btn v-if="!freightProp && canEdit" @click="clickAdd()" rounded color="primary" class="mt-n1">{{
                        $t("trips.add_freight")
                    }}</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";
import LoadingBar from "@/components/base/LoadingBar.vue";
import helper from "@/utils/helper.js";
import FreightsForm from "@/components/trips/forms/FreightsForm.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import NoData from "@/components/base/NoData.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        freightProp: {
            type: Object,
            required: false,
            default: function() {
                return null;
            }
        }
    },
    components: { LoadingBar, FreightsForm, NoPermission, NoData },
    apollo: {
        tripFreights: {
            query: gql`
                query trip($tripId: ID!, $globalIdIn: [ID]) {
                    trip(id: $tripId) {
                        id
                        tripSeaTransportSet {
                            edges {
                                node {
                                    id
                                    sequence
                                    actualDepartureDate
                                    actualArrivalDate
                                    estimatedDepartureDate
                                    estimatedArrivalDate
                                    originalEstimatedDepartureDate
                                    originalEstimatedArrivalDate
                                }
                            }
                        }
                        tripFreightSet(globalIdIn: $globalIdIn) {
                            edges {
                                node {
                                    id
                                    referenceNumber
                                    orderNumber
                                    containerNumber
                                    airWaybillNumber
                                    billOfLadingNumber
                                    goods
                                    term
                                    contact
                                    loadPoint
                                    loadDate
                                    startDate
                                    endDate
                                    trackerLastConnectionTime
                                    trackerLastLocation
                                    tripFreightAlertRuleSet {
                                        edges {
                                            node {
                                                id
                                                tenantAlertRule {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                    tripFreightTrackerSet {
                                        edges {
                                            node {
                                                id
                                                tracker {
                                                    id
                                                    serialNumber
                                                    activatedDate
                                                    model
                                                    brand
                                                    lastConnectionTime
                                                    lastBatteryDeviceTime
                                                    lastBatteryValue
                                                    lastTemperatureDeviceTime
                                                    lastTemperatureValue
                                                    lastExternalTemperatureDeviceTime
                                                    lastExternalTemperatureValue
                                                    lastProbeTemperatureDeviceTime
                                                    lastProbeTemperatureValue
                                                    lastHumidityDeviceTime
                                                    lastHumidityValue
                                                    lastLightDeviceTime
                                                    lastLightValue
                                                    lastShockDeviceTime
                                                    lastShockValue
                                                    lastLocationDeviceTime
                                                    lastLatitude
                                                    lastLongitude
                                                    lastLocationDescription
                                                }
                                                name
                                                lowTemperature
                                                highTemperature
                                                lowHumidity
                                                highHumidity
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    tripId: this.trip?.id,
                    globalIdIn: this.freightProp?.id ? [this.freightProp?.id] : null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trip?.tripFreightSet,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ data }) {
                if (data) {
                    this.tripFreight = { ...data.trip?.tripFreightSet };
                    if (this.$route.query.freight) {
                        if (this.tripFreight?.edges.length === 0) return;
                        let index = this.tripFreight.edges.findIndex(x => x.node.id === this.$route.query.freight);

                        let temp = this.tripFreight.edges[index];
                        let newEdges = [...this.tripFreight.edges];
                        newEdges.splice(index, 1);
                        newEdges.unshift(temp);
                        this.tripFreight = { edges: newEdges };

                        this.panelFreight = [0];
                    } else {
                        if (this.tripFreight?.edges?.length > 0) {
                            this.panelFreight = [0];
                        }
                    }
                    this.trackerRangeDate;
                }
            },
            // debounce: 800,
            skip() {
                return !this.canAccess;
            }
            // pollInterval: 60000 // ms
        }
    },
    data() {
        return {
            panel: [0, 1],
            panelFreight: [],
            tripFreights: {},
            createDialog: false,
            deleteDialog: false,
            item: {},
            deletedItem: {},
            validateForm: false,
            toggleTitle: false,
            loadingDeleteBtn: false,
            loadingCreateEditBtn: false,
            carousel: 0,
            tripFreight: { edges: [] },
            currentTrackers: [],
            FreightsFormCardHeight: "auto"
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.relativeTime = helper.relativeTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.unitPerBrand = helper.unitPerBrand;
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (to.query.freight) {
                    if (this.tripFreight.edges.length === 0) return;
                    let index = this.tripFreight.edges.findIndex(x => x.node.id === to.query.freight);

                    let temp = this.tripFreight.edges[index];
                    let newEdges = [...this.tripFreight.edges];
                    newEdges.splice(index, 1);
                    newEdges.unshift(temp);
                    this.tripFreight = { edges: newEdges };

                    this.panelFreight = [0];
                }
            }
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        trackerRangeDate() {
            let _range = [];
            this.tripFreights?.edges.forEach(edge => {
                if (edge.node.startDate && edge.node.endDate) {
                    let _start = this.formatDateTime(edge.node.startDate)?.slice(0, 10);
                    let _end = this.formatDateTime(edge.node.endDate)?.slice(0, 10);
                    _range.push({
                        id: edge.node.id,
                        range: `${_start} - ${_end}`
                    });
                } else if (edge.node.startDate) {
                    let _start = this.formatDateTime(edge.node.startDate)?.slice(0, 10);
                    _range.push({
                        id: edge.node.id,
                        range: `${_start}`
                    });
                } else if (edge.node.endDate) {
                    let _end = this.formatDateTime(edge.node.endDate)?.slice(0, 10);
                    _range.push({
                        id: edge.node.id,
                        range: `${_end}`
                    });
                } else {
                    _range.push({
                        id: edge.node.id,
                        range: ``
                    });
                }
            });
            return _range || [];
        },
        hasTripFreight() {
            return this.tripFreights?.edges?.length > 0;
        },
        originWasAvailable() {
            let isAvailable = true;
            if (!this.trip?.originLocation && this.trip?.transportMode?.toLowerCase() === "sea") isAvailable = false;
            return isAvailable;
        },
        destinationWasAvailable() {
            let isAvailable = true;
            if (!this.trip?.destinationLocation && this.trip?.transportMode?.toLowerCase() === "sea")
                isAvailable = false;
            return isAvailable;
        },
        getOrigin() {
            let _origin = null;
            if (this.trip?.originLocation) {
                if (this.trip?.originLocation?.city && this.trip?.originLocation?.city !== "")
                    _origin = `${this.trip?.originLocation?.city}`;

                if (this.trip?.originLocation?.country && this.trip?.originLocation?.country !== "")
                    if (_origin) _origin = `${_origin},${this.trip?.originLocation?.country}`;
                    else _origin = `${this.trip?.originLocation?.country}`;

                if (!_origin) _origin = `${this.trip?.originLocation?.name}`;
            } else if (!this.trip?.originLocation && this.trip?.transportMode?.toLowerCase() === "sea") {
                _origin = this.trip?.tripSeaTransportSet?.edges.find(edge => edge?.node?.portTransportType == "POL")
                    ?.node?.portName;
            }

            return _origin;
        },
        getDestination() {
            let _origin = null;
            if (this.trip?.destinationLocation) {
                if (this.trip?.destinationLocation?.city && this.trip?.destinationLocation?.city !== "")
                    _origin = `${this.trip?.destinationLocation?.city}`;

                if (this.trip?.destinationLocation?.country && this.trip?.destinationLocation?.country !== "")
                    if (_origin) _origin = `${_origin},${this.trip?.destinationLocation?.country}`;
                    else _origin = `${this.trip?.destinationLocation?.country}`;

                if (!_origin) _origin = `${this.trip?.destinationLocation?.name}`;
            } else if (!this.trip?.destinationLocation && this.trip?.transportMode?.toLowerCase() === "sea") {
                _origin = this.trip?.tripSeaTransportSet?.edges.find(edge => edge?.node?.portTransportType == "POD")
                    ?.node?.portName;
            }

            return _origin;
        },
        tripDescription() {
            let _trip = {};

            let consignee = this.trip?.tripPartnerSet?.edges.filter(x => {
                return x.node.tenantPartner.partnerType === "consignee";
            });

            _trip = {
                origin: this.getOrigin,
                destination: this.getDestination,
                carrier: this.trip?.tenantCarrier ? this.trip?.tenantCarrier?.name : null,
                consignee: consignee?.length > 0 ? consignee[0]?.node?.tenantPartner?.name : null
            };

            return _trip;
        },
        tripSeaTransportData() {
            let dates = {
                actualDepartureDate: null,
                actualArrivalDate: null,
                estimatedDepartureDate: null,
                estimatedArrivalDate: null,
                originalEstimatedDepartureDate: null,
                originalEstimatedArrivalDate: null
            };
            let tripseatransport = this.trip?.tripSeaTransportSet?.edges;
            tripseatransport.sort((a, b) => (a.node.sequence > b.node.sequence ? 1 : -1));

            tripseatransport.forEach((item, index) => {
                if (item.node.sequence === 0) {
                    dates.actualDepartureDate = item.node.actualDepartureDate;
                    dates.estimatedDepartureDate = item.node.estimatedDepartureDate;
                    dates.originalEstimatedDepartureDate = item.node.originalEstimatedDepartureDate;
                }
                if (index === tripseatransport.length - 1) {
                    dates.actualArrivalDate = item.node.actualArrivalDate;
                    dates.estimatedArrivalDate = item.node.estimatedArrivalDate;
                    dates.originalEstimatedArrivalDate = item.node.originalEstimatedArrivalDate;
                }
            });

            return dates;
        },
        canAccess() {
            //Partner who is not a tenannt
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (!this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_FREIGHT, this.organisationPermissions))
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_FREIGHT, this.organisationPermissions)
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.VIEW_TRIP_FREIGHT) && this.me?.tenant) return false;
            }

            return true;
        },
        tripObj() {
            let trip = {};
            trip = {
                id: this.trip.id,
                referenceNumber: this.trip.referenceNumber,
                status: this.trip.status,
                transportMode: this.trip?.transportMode?.toLowerCase(),
                plannedDepartureDate: this.getDate(this.trip.plannedDepartureDate)
            };
            return trip;
        },
        canEdit() {
            if (this.trip?.status === "COMPLETED") return false;

            if (this.isSharedTrip) return false;

            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (!this.hasOrganisationPermission(TripPermissions.EDIT_TRIP_FREIGHT, this.organisationPermissions))
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(TripPermissions.EDIT_TRIP_FREIGHT, this.organisationPermissions)
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.EDIT_TRIP_FREIGHT) && this.me?.tenant) return false;
            }

            return true;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_summary_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_summary_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_summary_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        copyToClipboard(text) {
            let dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            const payload = {
                color: "primary",
                message: this.$t("general.copied")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        redirectToAlertRule(id) {
            this.$router.push({
                name: "customrules",
                query: { id: id }
            });
        },
        alertTracker(value) {
            let relativeTime = moment(value)
                .locale("en")
                .startOf("hour")
                .fromNow();
            let x = relativeTime.split(" ");

            if (x[2] === "minutes" || x[1] === "minutes" || x[1] === "minute" || x[1] === "hour" || x[1] === "hours") {
                return true;
            } else {
                return false;
            }
        },
        convertTrip(obj) {
            let trip = {};
            trip = {
                id: obj.id,
                /*  plannedArrivalDate: obj.plannedArrivalDate
                    ? moment(obj.plannedArrivalDate, "YYYY-MM-DD").format(
                          "YYYY-MM-DD"
                      )
                    : "",
                plannedArrivalTime: obj.plannedArrivalDate
                    ? moment(obj.plannedArrivalDate, "hh:mm").format("hh:mm")
                    : "",
                plannedDepartureDate: obj.plannedDepartureDate
                    ? moment(obj.plannedDepartureDate, "YYYY-MM-DD").format(
                          "YYYY-MM-DD"
                      )
                    : "",
                plannedDepartureTime: obj.plannedDepartureDate
                    ? moment(obj.plannedDepartureDate, "hh:mm").format("hh:mm")
                    : "", */
                referenceNumber: obj.referenceNumber,
                status: obj.status,
                transportMode: obj.transportMode.toLowerCase(),
                plannedDepartureDate: this.getDate(obj.plannedDepartureDate)
            };
            return trip;
        },
        fixedNumber(x) {
            let fix = x.toFixed(2);
            return fix;
        },
        clickAdd() {
            this.toggleTitle = false;
            this.createDialog = true;
        },
        addFreight(value) {
            this.loadingCreateEditBtn = true;

            let payload = {
                tripId: this.trip.id,
                referenceNumber: value.referenceNumber,
                orderNumber: value.orderNumber,
                billOfLadingNumber: value.billsOfLading,
                containerNumber: value.containerNumber,
                airWaybillNumber: this.removeHifenFromAirwaybill(value.awb),
                contact: value.contact,
                loadPoint: value.loadPoint,
                loadDate: helper.convertDateToUTC(value.loadDate),
                startDate: helper.convertDateTimeToUTC(value.startDate),
                endDate: helper.convertDateTimeToUTC(value.endDate),
                goods: value.goods,
                term: value.term,
                /* estimatedDepartureDate: helper.concatDateAndTimeAndConvertToUTC(
                    value.estimatedDepartureDate,
                    value.estimatedDepartureTime
                ),
                estimatedArrivalDate: helper.concatDateAndTimeAndConvertToUTC(
                    value.estimatedArrivalDate,
                    value.estimatedArrivalTime
                ), */
                trackers: value.trackers,
                alerts: value.alerts ? value.alerts : null
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation createTripFreight($input: CreateTripFreightInput!) {
                            createTripFreight(input: $input) {
                                trip {
                                    id
                                    tripFreightSet {
                                        edges {
                                            node {
                                                id
                                                referenceNumber
                                                orderNumber
                                                containerNumber
                                                airWaybillNumber
                                                billOfLadingNumber
                                                contact
                                                loadPoint
                                                loadDate
                                                startDate
                                                endDate
                                                goods
                                                term
                                                tripFreightAlertRuleSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tenantAlertRule {
                                                                id
                                                                name
                                                            }
                                                        }
                                                    }
                                                }
                                                tripFreightTrackerSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tracker {
                                                                id
                                                                serialNumber
                                                                activatedDate
                                                                lastConnectionTime
                                                                lastBatteryDeviceTime
                                                                lastBatteryValue
                                                                lastTemperatureDeviceTime
                                                                lastTemperatureValue
                                                                lastExternalTemperatureDeviceTime
                                                                lastExternalTemperatureValue
                                                                lastProbeTemperatureDeviceTime
                                                                lastProbeTemperatureValue
                                                                lastHumidityDeviceTime
                                                                lastHumidityValue
                                                                lastLightDeviceTime
                                                                lastLightValue
                                                                lastShockDeviceTime
                                                                lastShockValue
                                                                lastLocationDeviceTime
                                                                lastLatitude
                                                                lastLongitude
                                                                lastLocationDescription
                                                            }
                                                            name
                                                            lowTemperature
                                                            highTemperature
                                                            lowHumidity
                                                            highHumidity
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("trips.freight_add")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingCreateEditBtn = false;
                    this.closeFreightForm();
                    this.$apollo.queries.tripFreights.refetch();
                });
        },
        deleteItem(e) {
            this.deletedItem = {
                id: e.id,
                referenceNumber: e.referenceNumber
            };
            this.deleteDialog = true;
        },
        cancelDeleteDialog() {
            this.deleteDialog = false;
            this.deletedItem = {};
        },
        deleteFreight(id) {
            this.loadingDeleteBtn = true;
            let payload = {
                id: id
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTripFreight($input: DeleteTripFreightInput!) {
                            deleteTripFreight(input: $input) {
                                trip {
                                    id
                                    tripFreightSet {
                                        edges {
                                            node {
                                                id
                                                referenceNumber
                                                orderNumber
                                                containerNumber
                                                airWaybillNumber
                                                billOfLadingNumber
                                                goods
                                                term
                                                estimatedDepartureDate
                                                estimatedArrivalDate
                                                actualDepartureDate
                                                actualArrivalDate
                                                tripFreightAlertRuleSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tenantAlertRule {
                                                                id
                                                                name
                                                            }
                                                        }
                                                    }
                                                }
                                                tripFreightTrackerSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tracker {
                                                                id
                                                                serialNumber
                                                                activatedDate
                                                                lastConnectionTime
                                                                lastBatteryDeviceTime
                                                                lastBatteryValue
                                                                lastTemperatureDeviceTime
                                                                lastTemperatureValue
                                                                lastExternalTemperatureDeviceTime
                                                                lastExternalTemperatureValue
                                                                lastProbeTemperatureDeviceTime
                                                                lastProbeTemperatureValue
                                                                lastHumidityDeviceTime
                                                                lastHumidityValue
                                                                lastLightDeviceTime
                                                                lastLightValue
                                                                lastShockDeviceTime
                                                                lastShockValue
                                                                lastLocationDeviceTime
                                                                lastLatitude
                                                                lastLongitude
                                                                lastLocationDescription
                                                            }
                                                            name
                                                            lowTemperature
                                                            highTemperature
                                                            lowHumidity
                                                            highHumidity
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "error",
                        message: this.$t("trips.freight_delete")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingDeleteBtn = false;
                    this.cancelDeleteDialog();
                    this.closeFreightForm();
                });
        },
        editItem(item) {
            let trackers = item.tripFreightTrackerSet.edges.map(e => e.node.tracker.id);
            let alertRules = item.tripFreightAlertRuleSet.edges.map(e => e.node?.tenantAlertRule?.id);

            this.currentTrackers = item.tripFreightTrackerSet.edges.map(e => {
                return {
                    id: e.node.tracker.id,
                    serialNumber: e.node.tracker.serialNumber
                };
            });

            // awb number replace to xxx-xxxxxxx
            let awbNum = item.airWaybillNumber ? item.airWaybillNumber.replace(/(.{3})(.{0})/, "$1-$2") : null;

            this.item = {
                id: item.id,
                estimatedArrivalDate: this.getDate(item.estimatedArrivalDatel) || null,
                estimatedArrivalTime: item.estimatedArrivalDate ? this.getTime(item.estimatedArrivalDate) : null,
                estimatedDepartureDate: this.getDate(item.estimatedDepartureDate) || null,
                estimatedDepartureTime: item.estimatedDepartureDate ? this.getTime(item.estimatedDepartureDate) : null,
                referenceNumber: item.referenceNumber,
                orderNumber: item.orderNumber,
                awb: awbNum,
                containerNumber: item.containerNumber,
                goods: item.goods,
                term: item.term,
                contact: item.contact,
                loadPoint: item.loadPoint,
                startDate: this.formatDateTime(item.startDate) || null,
                endDate: this.formatDateTime(item.endDate) || null,
                /* startTime: this.getTime(item.startDate) || null,
                endTime: this.getTime(item.endDate) || null, */
                loadDate: this.getDate(item.loadDate) || null,
                billsOfLanding: item.billsOfLanding,
                trackers: trackers,
                alerts: alertRules
            };
            this.toggleTitle = true;
            this.createDialog = true;
        },
        editFreight(value) {
            this.loadingCreateEditBtn = true;

            let payload = {
                id: value.id,
                referenceNumber: value.referenceNumber,
                billOfLadingNumber: value.billsOfLading,
                orderNumber: value.orderNumber,
                containerNumber: value.containerNumber,
                airWaybillNumber: this.removeHifenFromAirwaybill(value.awb),
                contact: value.contact,
                loadPoint: value.loadPoint,
                loadDate: helper.convertDateToUTC(value.loadDate),
                startDate: helper.convertDateTimeToUTC(value.startDate),
                endDate: helper.convertDateTimeToUTC(value.endDate),
                goods: value.goods,
                term: value.term,
                estimatedDepartureDate: helper.concatDateAndTimeAndConvertToUTC(
                    value.estimatedDepartureDate,
                    value.estimatedDepartureTime
                ),
                estimatedArrivalDate: helper.concatDateAndTimeAndConvertToUTC(
                    value.estimatedArrivalDate,
                    value.estimatedArrivalTime
                ),
                trackers: value.trackers,
                alerts: value.alerts ? value.alerts : null
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation editTripFreight($input: EditTripFreightInput!) {
                            editTripFreight(input: $input) {
                                trip {
                                    id
                                    tripFreightSet {
                                        edges {
                                            node {
                                                id
                                                referenceNumber
                                                orderNumber
                                                containerNumber
                                                airWaybillNumber
                                                billOfLadingNumber
                                                goods
                                                term
                                                contact
                                                loadPoint
                                                loadDate
                                                startDate
                                                endDate
                                                trackerLastConnectionTime
                                                trackerLastLocation
                                                tripFreightAlertRuleSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tenantAlertRule {
                                                                id
                                                                name
                                                            }
                                                        }
                                                    }
                                                }
                                                tripFreightTrackerSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tracker {
                                                                id
                                                                serialNumber
                                                                activatedDate
                                                                model
                                                                brand
                                                                lastConnectionTime
                                                                lastBatteryDeviceTime
                                                                lastBatteryValue
                                                                lastTemperatureDeviceTime
                                                                lastTemperatureValue
                                                                lastExternalTemperatureDeviceTime
                                                                lastExternalTemperatureValue
                                                                lastProbeTemperatureDeviceTime
                                                                lastProbeTemperatureValue
                                                                lastHumidityDeviceTime
                                                                lastHumidityValue
                                                                lastLightDeviceTime
                                                                lastLightValue
                                                                lastShockDeviceTime
                                                                lastShockValue
                                                                lastLocationDeviceTime
                                                                lastLatitude
                                                                lastLongitude
                                                                lastLocationDescription
                                                            }
                                                            name
                                                            lowTemperature
                                                            highTemperature
                                                            lowHumidity
                                                            highHumidity
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(response => {
                    const payload = {
                        color: "blue",
                        message: this.$t("trips.freight_edit")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                    //this.$apollo.queries.tripFreights.refetch();
                    //update the tripFreightSet
                    this.tripFreight = response.data.editTripFreight.trip.tripFreightSet;
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.item = {};
                    this.loadingCreateEditBtn = false;
                    this.closeFreightForm();
                });
        },
        closeFreightForm() {
            this.createDialog = false;
            this.$refs.freightForm.resetForm();
        },
        getDate(date) {
            return date?.slice(0, 10);
        },
        getTime(date) {
            return this.formatDateTime(date)?.slice(11, 16);
        },
        getFreightTitle(freight) {
            let _title = ""; //freight.node.referenceNumber;
            let connect = ". ";

            if (freight.node.airWaybillNumber) {
                let airWaybillNumber = freight.node.airWaybillNumber.replace(/(.{3})(.{0})/, "$1-$2");
                _title = _title.concat("Air Waybill #: ", airWaybillNumber);
            }

            if (freight.node.containerNumber) {
                _title = _title.concat("Container #: ", freight.node.containerNumber, connect);
            } else if (freight.node.billOfLadingNumber) {
                _title = _title.concat("Bill of Lading #: ", freight.node.billOfLadingNumber, connect);
            }

            _title = _title.concat(" Ref: ", freight.node.referenceNumber);
            return _title;
        },
        redirectTrip() {
            this.$router.push({
                name: "trips",
                query: { id: this.trip.id }
            });
        },
        redirectFreight(id) {
            this.$router.push({
                name: "freights",
                query: { id: id }
            });
        },
        getRelatedFreights() {
            let _freights = [];
            this.trip.tripFreightSet.edges.forEach(item => {
                if (item.node.id !== this.freightProp?.id) {
                    _freights.push(item.node);
                }
            });
            return _freights;
        },
        resizeFreightsFormCard(value) {
            this.FreightsFormCardHeight = value;
        }
    }
};
</script>

<style scope>
.v-expansion-panel-content__wrap {
    padding: 0% !important;
}
.v-expansion-panel:not(:first-child)::after {
    border-top: none !important;
}
.txtIndex {
    max-height: 19px;
    font-size: 12.4px;
    font-weight: 720;
    color: #a4a4a8;
}
.txtBody {
    max-height: 19px;
    font-size: 12.3px;
    font-weight: 650;
}
.maxHeight {
    max-height: 18px;
}
</style>
