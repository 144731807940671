<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="background"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                {{ t.name }}
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab"
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <!-- PARTNER USERS TAB -->
            <v-tab-item value="tab-users" :transition="false"
                ><PartnerUsersTab
                    :partner="partner"
                    :isSelectorEnabled="selector"
                    @updateSelector="selector = $event"
                ></PartnerUsersTab
            ></v-tab-item>
            <!-- PARTNER LOCATIONS TAB -->
            <v-tab-item value="tab-locations" :transition="false">
                <PartnerLocationsTab :partner="partner"></PartnerLocationsTab>
            </v-tab-item>
            <!-- PARTNER PERMISSION TAB -->
            <v-tab-item value="tab-permissions" :transition="false">
                <PartnerPermissionsTab
                    :partner="partner"
                ></PartnerPermissionsTab>
            </v-tab-item>
            <!-- PARTNER TRIPS TAB -->
            <v-tab-item value="tab-trips" :transition="false">
                <PartnerTripsTab :partner="partner"></PartnerTripsTab>
            </v-tab-item>
            <!-- PARTNER MAP TAB -->
            <v-tab-item value="tab-map" :transition="false">
                SEP...
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import PartnerUsersTab from "@/components/partners/tabs/PartnerUsersTab.vue";
import PartnerLocationsTab from "@/components/partners/tabs/PartnerLocationsTab.vue";
import PartnerPermissionsTab from "@/components/partners/tabs/PartnerPermissionsTab.vue";
import PartnerTripsTab from "@/components/partners/tabs/PartnerTripsTab.vue";

export default {
    props: {
        partner: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        PartnerUsersTab,
        PartnerLocationsTab,
        PartnerPermissionsTab,
        PartnerTripsTab
    },
    data() {
        return {
            tab: null,
            testebton: false,
            mapDialog: false,
            selector: false
        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225 ;
            return  _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    mounted() {}
};
</script>
