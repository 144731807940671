import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1200","max-height":"800","persistent":"","scrollable":""},model:{value:(_vm.openTrackerUploadHistoryDialog),callback:function ($$v) {_vm.openTrackerUploadHistoryDialog=$$v},expression:"openTrackerUploadHistoryDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("history")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.upload_history")))]),_c(VSpacer),_c(VBtn,{staticClass:"grey lighten-4",attrs:{"text":"","rounded":""},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t("general.close")))])],1),_c(VCardText,{staticClass:"px-6 pt-6"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4","lg":"4","xl":"4"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.dateRangeText,"label":_vm.$t('general.filter_upload'),"readonly":"","clearable":"","outlined":"","hide-details":"","dense":""},on:{"click:clear":function($event){return _vm.onClearDates()}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"first-day-of-week":1,"range":"","no-title":"","scrollable":"","color":"primary"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":"","color":"primary"},on:{"click":_vm.cancelDatePicker}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VBtn,{attrs:{"outlined":"","rounded":"","color":"primary","disabled":_vm.dateRange.length < 1},on:{"click":_vm.saveDatePicker}},[_vm._v(" OK ")])],1)],1),(_vm.$apollo.queries.trackerUploadLogs.loading)?_c('LoadingBar'):_vm._l((_vm.trackerUploadLogs.edges),function(edge){return _c(VCard,{key:edge.node.id,staticClass:"mb-5 mt-3",attrs:{"elevation":"4","color":_vm.selectedLog == edge.node.id
                                ? 'primary lighten-4'
                                : 'white'},on:{"click":function($event){_vm.selectedLog = edge.node.id}}},[_c(VRow,{staticClass:"pt-5 ml-3",attrs:{"dense":""}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("settings_remote")]),_c('span',{staticClass:"ml-2"},[_c('b',[_vm._v(_vm._s(edge.node.fileName ? edge.node.fileName : _vm.$t("general.manually_created")))])])],1),_c('div',{staticClass:"px-5 py-5"},[_c(VRow,{attrs:{"dense":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.uploaded_at"))+": "),_c('b',[_vm._v(_vm._s(_vm.formatDateTime(edge.node.createdAt)))])])]),_c(VRow,{attrs:{"dense":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.uploaded_by"))+": "),_c('b',[_vm._v(_vm._s(edge.node.createdBy ? edge.node.createdBy.fullName ? edge.node.createdBy .fullName : edge.node.createdBy.email : ""))])])]),_c(VRow,{attrs:{"dense":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("home.trackers"))+":"),_c('b',[_vm._v(" "+_vm._s(edge.node.numberOfTrackers))])])]),_c(VRow,{attrs:{"dense":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("headers.brand"))+": "),_c('b',[_vm._v(_vm._s(edge.node.brand))])])])],1)],1)})],2),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"12","md":"8","lg":"8","xl":"8"}},[_c(VCard,[_c(VDataTable,{staticClass:"row-pointer",attrs:{"headers":_vm.defaultHeaderChoices,"items":_vm.trackersForUploadLogs.edges,"item-key":"node.id","loading-text":"Loading...","loading":_vm.$apollo.queries.trackersForUploadLogs
                                    .loading,"mobile-breakpoint":0,"loader-height":"2","no-data-text":_vm.$t('loading.no_data'),"fixed-header":""},scopedSlots:_vm._u([{key:"item.serialNumber",fn:function(ref){
                                    var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.node.serialNumber))])]}},{key:"item.model",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.node.model ? item.node.model : ""))])]}},{key:"item.lastConnectionTime",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime( item.node.lastConnectionTime )))])]}},{key:"item.expiryDate",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.node.expiryDate)))])]}},{key:"item.comment",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.node.comment ? item.node.comment : ""))])]}},(_vm.me.isStaff)?{key:"item.tenant.name",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.node.tenant.name ? item.node.tenant.name : ""))])]}}:null],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("No data")])])],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }