<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" elevation="5" outlined>
            <v-row align="start" class="px-5 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col cols="12" sm="5" lg="5">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :hint="$t('general.search_name')"
                                v-model="searchInput"
                                append-icon="search"
                                :label="$t('general.search')"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <!---- ADD AND REMOVE BUTTONS ---->
            </v-row>
        </v-card>

        <v-card class="mt-2 mb-5 pb-5" elevation="5" outlined>
            <v-card-text>
                <!-- TABLE -->
                <v-data-table
                    :items="authLogs.edges"
                    :headers="headerChoices"
                    disable-pagination
                    :loading-text="$t('loading.loading1')"
                    :no-data-text="$t('loading.no_data')"
                    loader-height="2"
                    hide-default-footer
                    :loading="$apollo.queries.authLogs.loading"
                >
                    <template v-slot:item.node.action="{ item }">
                        <span class="text-capitalize">{{
                            item.node.action
                        }}</span>
                    </template>
                    <template v-slot:item.node.actionTime="{ item }">
                        <span>{{ formatDateTime(item.node.actionTime) }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
            <!-- infinit loading trigger -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.authLogs.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    {{ $t("loading.load_more") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import Config from "@/utils/config.json";

export default {
    name: "UserGroupsTab",
    components: {},
    props: {
        user: {
            type: Object,
            required: true
        },
        isStaff: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        authLogs: {
            query: gql`
                query AuthLogs(
                    $userId: ID
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $search: String
                ) {
                    authLogs(
                        userId: $userId
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        search: $search
                    ) {
                        totalCount
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                action
                                actionIp
                                actionTime
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    userId: this.isStaff
                        ? this.user.id
                        : this.user.node.user.id,
                    ...this.authLogsFilter,
                    search: this.searchInput
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.authLogs;
            },
            skip: false
        }
    },
    data() {
        return {
            searchInput: null,
            authLogs: { edges: [] },
            authLogsFilter: {
                first: 10
            },
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
            headerChoices: [
                {
                    text: this.$t("headers.action"),
                    align: "left",
                    value: "node.action"
                },
                {
                    text: this.$t("headers.time"),
                    align: "left",
                    value: "node.actionTime"
                },
                {
                    text: this.$t("headers.ip"),
                    align: "left",
                    value: "node.actionIp"
                }
            ],
            shouldLoadMore: false
        };
    },
    watch: {
        searchInput() {
            this.$apollo.userGroups ? this.$apollo.userGroups.refetch() : null;
        }
    },
    computed: {
        // infinite
        hasMoreData() {
            return this.authLogs?.pageInfo?.hasNextPage;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'user_login_history_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("user_login_history_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("user_login_history_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.authLogs?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.authLogs.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.authLogs?.pageInfo?.hasNextPage) {
                this.$apollo.queries.authLogs.fetchMore({
                    variables: {
                        after: this.authLogs.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
