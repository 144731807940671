<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <LoadingBar v-if="$apollo.queries.tripAlerts.loading"></LoadingBar>
            <v-card v-else elevation="5" class="mb-5 pb-0" rounded="xl">
                <v-card-title class="background2 py-3">
                    <v-icon>
                        notifications
                    </v-icon>
                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="canEdit && toggle === 'all'"
                        rounded
                        color="primary"
                        @click="toggle = 'unresolved'"
                        :disabled="!validateList(fullList)"
                    >
                        {{ $t("trips.unresolved_alerts") }}
                    </v-btn>
                    <v-btn
                        v-if="toggle === 'unresolved'"
                        rounded
                        color="primary"
                        @click="toggle = 'all'"
                        :disabled="!validateList(fullList)"
                    >
                        {{ $t("trips.show_alerts") }}
                    </v-btn>

                    <v-btn
                        v-if="canEdit"
                        :disabled="!(unresolved.length > 0)"
                        rounded
                        color="primary"
                        class="ml-1"
                        @click="unresolvedDialog = true"
                    >
                        {{ $t("trips.mark_all") }}
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :disable-sort="false"
                        :items="toggle === 'all' ? fullList : unresolved"
                        item-key="node.id"
                        :loading="$apollo.queries.tripAlerts.loading"
                        hide-default-footer
                        disable-pagination
                        sort-by="node.alertTime"
                        sort-desc
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        @click:row="expandRow"
                    >
                        <template
                            v-slot:item.node.tenantAlertRule.name="{ item }"
                        >
                            <v-badge
                                color="error"
                                v-if="!item.node.isResolved"
                                dot
                                left
                            >
                                <span class="text-truncate pl-2">
                                    {{ item.node.tenantAlertRule.name }}
                                </span>
                            </v-badge>
                            <div v-else>
                                {{ item.node.tenantAlertRule.name }}
                            </div>
                        </template>
                        <template v-slot:item.node.deviceTime="{ item }">
                            <div>
                                {{ formatDateTime(item.node.deviceTime) }}
                            </div>
                        </template>
                        <template v-slot:item.node.alertTime="{ item }">
                            <div>
                                {{ formatDateTime(item.node.alertTime) }}
                            </div>
                        </template>
                        <template v-slot:item.node.resolvedTime="{ item }">
                            <span v-if="item.node.resolvedTime">
                                {{ formatDateTime(item.node.resolvedTime) }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </template>
                        <template v-slot:item.resolvedBy="{ item }">
                            <span v-if="item.node.resolvedBy">
                                {{ item.node.resolvedBy.fullName }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <span v-if="item.node.note" class="caption">
                                    {{ item.node.note }}
                                </span>
                                <span v-else class="caption grey--text">
                                    {{ $t("trips.no_notes") }}
                                </span>
                            </td>
                        </template>

                        <template v-slot:item.actionGroup="{ item }">
                            <div
                                v-if="
                                    item.node.tenantAlertRule
                                        .tenantAlertRuleActionGroupSet.edges
                                "
                            >
                                <div
                                    v-for="item in item.node.tenantAlertRule
                                        .tenantAlertRuleActionGroupSet.edges"
                                    :key="item.node.id"
                                >
                                    <span class="text-truncate">
                                        {{ item.node.tenantActionGroup.name }}
                                    </span>
                                </div>
                            </div>
                            <div v-else>
                                -
                            </div>
                        </template>
                        <template v-slot:item.alertDescription="{ item }">
                            <v-row>
                                <v-col
                                    cols="1"
                                    v-for="(alert, i) in checkAlert(
                                        item.node.alertJson
                                    )"
                                    :key="i"
                                >
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                small
                                                class="mr-6"
                                                v-bind="attrs"
                                                v-on="on"
                                                v-if="alert === 'temperature'"
                                            >
                                                thermostat
                                            </v-icon>
                                        </template>
                                        <span>{{
                                            $t("general.temperature")
                                        }}</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                small
                                                v-if="alert === 'humidity'"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                water_drop
                                            </v-icon>
                                        </template>
                                        <span>{{
                                            $t("general.humidity")
                                        }}</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                small
                                                v-if="alert === 'light'"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                lightbulb
                                            </v-icon>
                                        </template>
                                        <span>{{ $t("general.light") }}</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                small
                                                v-if="alert === 'shock'"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                bolt
                                            </v-icon>
                                        </template>
                                        <span>{{ $t("general.shock") }}</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                small
                                                v-if="alert === 'location'"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                location_on
                                            </v-icon>
                                        </template>
                                        <span>{{
                                            $t("general.location")
                                        }}</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex">
                                <AlertRuleDetailDialog
                                    :tripAlertId="item.node.id"
                                    :alertTime="item.node.alertTime"
                                    :deviceTime="item.node.deviceTime"
                                ></AlertRuleDetailDialog>
                                <v-btn
                                    small
                                    icon
                                    @click="editedItem(item.node.id)"
                                    v-if="!item.node.isResolved && canEdit"
                                >
                                    <v-icon>
                                        done
                                    </v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <!-- <v-card-actions>
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.tripAlertSet.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    Load more
                </v-btn>

            </v-card-actions> -->
            </v-card>
        </div>
        <v-dialog v-model="editAlertsDialog" width="500">
            <v-card>
                <v-card-title class="primary">
                    <v-icon color="white">notifications</v-icon>
                    <div class="white--text">
                        {{ $t("trips.edit_alerts") }}
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="editAlertsValidate">
                        <h3 class="mt-2">
                            {{ $t("trips.sure_alerts") }}
                        </h3>
                        <v-textarea
                            v-model="editItem.note"
                            counter="500"
                            outlined
                            :placeholder="$t('trips.add_notes')"
                            :rules="[rules.maxLength(500)]"
                        >
                        </v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn rounded text @click="editAlertsDialog = false">{{
                        $t("general.cancel")
                    }}</v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="!editAlertsValidate"
                        rounded
                        color="primary"
                        @click="save(editItem)"
                        >{{ $t("general.save") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="unresolvedDialog" width="500">
            <v-card>
                <v-card-title class="primary">
                    <v-icon color="white">notifications</v-icon>
                    <div class="white--text">
                        {{ $t("trips.edit_alerts") }}
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="editAlertsValidate">
                        <h3 class="mt-2">
                            {{ $t("trips.sure_all_alerts") }}
                        </h3>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn rounded text @click="unresolvedDialog = false">{{
                        $t("general.cancel")
                    }}</v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="!editAlertsValidate"
                        rounded
                        color="primary"
                        @click="saveAll()"
                        >{{ $t("general.save") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../../utils/helper";
import AlertRuleDetailDialog from "@/components/alertrules/other/AlertRuleDetailDialog.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        freight: {
            type: Object,
            required: false
        }
    },
    components: {
        NoPermission,
        LoadingBar,
        AlertRuleDetailDialog
    },
    apollo: {
        tripAlerts: {
            query: gql`
                query TripAlerts($trip: ID, $allAlerts: Boolean) {
                    tripAlerts(trip: $trip, allAlerts: $allAlerts) {
                        edges {
                            node {
                                id
                                deviceTime
                                alertTime
                                resolvedTime
                                isResolved
                                note
                                resolvedBy {
                                    id
                                    fullName
                                    __typename
                                }
                                alertJson
                                tenantAlertRule {
                                    id
                                    name
                                    isStandardAlert
                                    standardAlertCode
                                    shouldNotifyPartner
                                    shouldNotifyForEveryRecord
                                    tenantAlertRuleActionGroupSet {
                                        edges {
                                            node {
                                                id
                                                tenantActionGroup {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                                trackerMergedSensorData {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    trip: this.trip?.id,
                    allAlerts: true
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tripAlerts,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading) {
                    if (data.tripAlerts)
                        this.hasTripAlertSet = data.tripAlerts.edges.length > 1;
                }
            },
            // debounce: 800,
            skip() {
                return !this.canAccess;
            }
        }
    },
    data() {
        return {
            toggle: "all",
            hasTripAlertSet: false,
            editAlertsValidate: false,
            editAlertsDialog: false,
            unresolvedDialog: false,
            alertSensor: null,
            loading: false,
            singleExpand: true,
            expanded: [],
            alertRule: {},
            editItem: {
                id: "",
                note: ""
            },
            detailDialog: false,
            headers: [
                {
                    text: this.$t("headers.alert"),
                    align: "left",
                    value: "node.tenantAlertRule.name",
                    sortable: true
                },
                {
                    text: this.$t("headers.device_time"),
                    align: "left",
                    value: "node.deviceTime",
                    sortable: true
                },
                {
                    text: this.$t("headers.trigger_time"),
                    align: "left",
                    value: "node.alertTime",
                    sortable: true
                },
                {
                    text: this.$t("headers.action_group"),
                    align: "left",
                    value: "actionGroup",
                    sortable: false
                },
                {
                    text: this.$t("headers.alert_trigger"),
                    align: "left",
                    value: "alertDescription",
                    sortable: false
                },
                {
                    text: this.$t("headers.resolved_time"),
                    align: "left",
                    value: "node.resolvedTime",
                    sortable: true
                },
                {
                    text: this.$t("headers.resolved_by"),
                    align: "left",
                    value: "resolvedBy",
                    sortable: false
                },
                {
                    text: this.$t("headers.actions"),
                    align: "left",
                    value: "actions",
                    sortable: false
                }
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.require_at_least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_ALERT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_ALERT,
                        this.organisationPermissions
                    )
                )
                    return false;
                //check tenant has permission
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT_ALERT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                // check is tenant admin
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                //check is tenant staff has permission
                if (
                    !this.hasPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_ALERT
                    ) &&
                    this.me?.tenant
                )
                    return false;
            }
            return true;
        },
        canEdit() {
            if (this.trip?.status === "COMPLETED") return false;

            // if (this.isSharedTrip) return false;

            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_FREIGHT_ALERT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_FREIGHT_ALERT,
                        this.organisationPermissions
                    )
                )
                    return false;
                //check tenant has permission
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT_ALERT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                // check is tenant admin
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                //check is tenant staff has permission
                if (
                    !this.hasPermission(
                        TripPermissions.EDIT_TRIP_FREIGHT_ALERT
                    ) &&
                    this.me?.tenant
                )
                    return false;
            }
            return true;
        },
        fullList() {
            let all = this.tripAlerts?.edges?.filter(e => e.node);
            return all;
        },
        unresolved() {
            let unresolve = this.tripAlerts?.edges?.filter(
                e => e.node.isResolved === false
            );
            return unresolve;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
    },
    created() {
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.getTemperatureUnit = helper.getTemperatureSymbol;
        this.celsiusToFahrenheit = helper.celsiusToFahrenheit;
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_alert_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_alert_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_alert_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        checkAlert(item) {
            let alert = [];
            let newObj = JSON.parse(item);

            if (newObj?.Location?.Condition) alert.push("location");

            if (newObj?.Sensor?.length > 0) {
                newObj.Sensor.forEach(item => {
                    alert.push(item.Type);
                });
            }

            return alert;
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        alertRuleDetail(item) {
            this.alertRule = { ...item };
            this.detailDialog = true;
        },
        editedItem(id) {
            this.editItem = {
                id: id,
                note: ""
            };
            this.editAlertsDialog = true;
        },
        expandRow(item) {
            if (
                item.node &&
                this.expanded.filter(val => val["node"]["id"] === item.node.id)
                    .length > 0
            ) {
                this.expanded = [];
            } else {
                this.expanded = [];
                this.expanded.push(item);
            }
        },
        save(item) {
            this.loading = true;

            let payload = {
                alertId: item.id,
                note: item ? item.note : null
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTripAlerts(
                            $input: UpdateTripAlertsInput!
                        ) {
                            updateTripAlerts(input: $input) {
                                trip {
                                    id
                                    status
                                    tripAlertSet {
                                        edges {
                                            node {
                                                id
                                                deviceTime
                                                alertTime
                                                resolvedTime
                                                isResolved
                                                alertJson
                                                note
                                                resolvedBy {
                                                    id
                                                    fullName
                                                }
                                                tenantAlertRule {
                                                    id
                                                    name
                                                    isStandardAlert
                                                }
                                                trackerMergedSensorData {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "primary",
                        message: this.$t("general.saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.editAlertsDialog = false;
                });
        },
        saveAll() {
            this.loading = true;

            let payload = {
                tripId: this.trip?.id
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateUnresolvedTripAlerts(
                            $input: UpdateUnresolvedTripAlertsInput!
                        ) {
                            updateUnresolvedTripAlerts(input: $input) {
                                trip {
                                    id
                                    status
                                    tripAlertSet {
                                        edges {
                                            node {
                                                id
                                                deviceTime
                                                alertTime
                                                resolvedTime
                                                isResolved
                                                alertJson
                                                note
                                                resolvedBy {
                                                    id
                                                    fullName
                                                }
                                                tenantAlertRule {
                                                    id
                                                    name
                                                    isStandardAlert
                                                }
                                                trackerMergedSensorData {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "primary",
                        message: this.$t("general.saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.unresolvedDialog = false;
                });
        },
        validateList(list) {
            let find = list.filter(item => item.node.isResolved === true);
            if (find.length > 0) return true;
            if (find.length === 0) return false;
        }
    }
};
</script>
