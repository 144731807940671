<template>
    <v-card elevation="5" outlined>
        <v-card-text class="pb-3 pr-3">
            <div style="position:absolute; right:25px">
                <!-- filter menu -->
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display')"
                    :choices="headerChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                    class="mr-n4"
                >
                </FilterMenu>
            </div>
            <v-data-table
                :headers="dynamicHeaders"
                :items="actionGroups.edges"
                item-key="node.id"
                :loading-text="$t('loading.loading1')"
                :loading="$apollo.queries.actionGroups.loading"
                hide-default-footer
                disable-pagination
                loader-height="2"
                :mobile-breakpoint="
                    screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                "
                :no-data-text="$t('loading.no_data')"
                @click:row="item => this.$emit('click:row', item)"
                class="row-pointer"
            >
                <!-- Email Notification -->
                <template v-slot:item.node.shouldEmailNotification="{ item }">
                    <v-icon
                        v-if="item.node.shouldEmailNotification"
                        color="success"
                        >check_circle</v-icon
                    >
                    <v-icon color="grey lighten-2" v-else>check_circle</v-icon>
                </template>

                <!-- Inbox Notification -->
                <template v-slot:item.node.shouldInboxNotification="{ item }">
                    <v-icon
                        v-if="item.node.shouldInboxNotification"
                        color="success"
                        >check_circle</v-icon
                    >
                    <v-icon color="grey lighten-2" v-else>check_circle</v-icon>
                </template>

                <!-- TotalCount of Users -->
                <template v-slot:item.node.users="{ item }">
                    <span>{{
                        item.node.tenantActionGroupUserSet.totalCount
                    }}</span>
                </template>

                <!-- TotalCount of External Emails -->
                <template v-slot:item.node.externalEmails="{ item }">
                    <span>{{ calculateNumberOfEmails(item) }}</span>
                </template>
            </v-data-table>
        </v-card-text>

        <!-- infinit loading trigger -->
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.actionGroups.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                {{ $t("loading.load_more") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";

export default {
    props: {
        actionGroupsFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    components: { FilterMenu },

    apollo: {
        actionGroups: {
            query: gql`
                query tenantActionGroups(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $name_Icontains: String
                    $orderBy: String
                ) {
                    tenantActionGroups(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        name_Icontains: $name_Icontains
                        orderBy: $orderBy
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                tenant {
                                    id
                                }
                                tenantActionGroupUserSet {
                                    totalCount
                                    edges {
                                        node {
                                            user {
                                                email
                                                firstName
                                                id
                                                fullName
                                            }
                                        }
                                    }
                                }
                                name
                                userEmails
                                externalEmails
                                shouldEmailNotification
                                shouldInboxNotification
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return this.actionGroupsFilter;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantActionGroups,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        }
    },

    data() {
        return {
            shouldLoadMore: false,
            isInitialLoad: true,
            actionGroups: { edges: [] },

            // defaultHeaderChoices: [
            //     {
            //         // for filter menu
            //         code: "node.name",
            //         name: this.$t("headers.name"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.name"),
            //         align: "left",
            //         value: "node.name"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.users",
            //         name: this.$t("home.users"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("home.users"),
            //         align: "right",
            //         value: "node.users"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.externalEmails",
            //         name: this.$t("general.external_emails"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("general.external_emails"),
            //         align: "right",
            //         value: "node.externalEmails"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.shouldEmailNotification",
            //         name: this.$t("general.email_notification"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("general.email_notification"),
            //         align: "center",
            //         value: "node.shouldEmailNotification"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.shouldInboxNotification",
            //         name: this.$t("general.inbox_notification"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("general.inbox_notification"),
            //         align: "center",
            //         value: "node.shouldInboxNotification"
            //     }
            // ],

            headerChoices: null
        };
    },
    created() {
        this.headerChoices = helper.getTableHeaders(
            "ActionGroupsTableHeaders",
            this.defaultHeaderChoices
        );
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        // infinite
        hasMoreData() {
            return this.actionGroups?.pageInfo?.hasNextPage;
        },

        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            // put header filter in the last column
            _headers.push({
                text: "",
                align: "right",
                value: "headerFilter",
                sortable: false
            });

            return _headers;
        },

        defaultHeaderChoices: function() {
            let _defaultHeaderChoices = [
                {
                    // for filter menu
                    code: "node.name",
                    name: "Name",
                    tag: "headers.name",
                    enabled: true,
                    // for table header
                    text: "Name",
                    align: "left",
                    value: "node.name"
                },
                {
                    // for filter menu
                    code: "node.users",
                    name: "Users",
                    tag: "home.users",
                    enabled: true,
                    // for table header
                    text: "Users",
                    align: "right",
                    value: "node.users"
                },
                {
                    // for filter menu
                    code: "node.externalEmails",
                    name: "External Emails",
                    tag: "general.external_emails",
                    enabled: true,
                    // for table header
                    text: "External Emails",
                    align: "right",
                    value: "node.externalEmails"
                },
                {
                    // for filter menu
                    code: "node.shouldEmailNotification",
                    name: "Email Notification",
                    tag: "general.email_notification",
                    enabled: true,
                    // for table header
                    text: "Email Notification",
                    align: "center",
                    value: "node.shouldEmailNotification"
                },
                {
                    // for filter menu
                    code: "node.shouldInboxNotification",
                    name: "Inbox Notification",
                    tag: "general.inbox_notification",
                    enabled: true,
                    // for table header
                    text: "Inbox Notification",
                    align: "center",
                    value: "node.shouldInboxNotification"
                }
            ];
            return _defaultHeaderChoices;
        }
    },
    watch: {
        headerChoices: {
            handler(newChoices) {
                helper.updateMyPreferences({
                    ActionGroupsTableHeaders: newChoices
                });
            },
            deep: true
        }
    },
    mounted() {
        this.loadMore();
    },
    methods: {
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (
                this.shouldLoadMore &&
                this.actionGroups?.pageInfo?.hasNextPage
            ) {
                this.loadMore();
            }
        },
        refetchDate() {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.actionGroups.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.actionGroups?.pageInfo?.hasNextPage) {
                this.$apollo.queries.actionGroups.fetchMore({
                    variables: {
                        after: this.actionGroups.pageInfo.endCursor
                    }
                });
            }
        },
        calculateNumberOfEmails(actionGroup) {
            if (!actionGroup.node.externalEmails) return 0;
            else if (actionGroup.node.externalEmails === "[]") return 0;
            else return actionGroup.node.externalEmails.split(",").length;
        }
    }
};
</script>

<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
