<template>
    <div class="px-10 pt-4">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4 mb-4" flat>
            <v-row align="start" class="d-flex align-center px-3">
                <!---- SEARCH BAR ---->
                <v-col cols="12" lg="12" sm="12" class="py-2">
                    <SearchBar v-model="searchInput" :label="$t('general.search')"></SearchBar>
                </v-col>
            </v-row>
        </v-card>

        <v-card flat class="px-3 rounded-lg mb-2" outlined>
            <v-card-title>
                <div>
                    <v-icon class="mr-2">group</v-icon>
                    {{ $t("home.groups") }}
                </div>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mr-1" @click="openAddUserGroupDialog = true">
                    {{ $t("users.add_group") }}
                </v-btn>
            </v-card-title>
            <v-card-text>
                <!-- TABLE -->
                <div class="px-10 pt-4" v-if="loading && !hasData">
                    <LoadingBar></LoadingBar>
                </div>
                <div v-else-if="hasData">
                    <v-card
                        v-for="group in groups"
                        :key="group.node.id"
                        class="mb-4 rounded-lg px-3 backgroundContent"
                        outlined
                        flat
                    >
                        <v-card-text>
                            <!-- TABLE -->
                            <v-row class="px-1">
                                <v-col cols="4">
                                    <div>{{ $t("general.group_name") }}</div>
                                    <div class="textBody">{{ group.node.name }}</div>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                    <v-card flat max-width="20px" class="d-flex justify-start" color="transparent">
                                        <v-menu
                                            offset-y
                                            transition="slide-x-reverse-transition"
                                            width="250px "
                                            attach
                                            left
                                            bottom
                                            close-on-click
                                            nudge-top="-3"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon>more_vert</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list class="white">
                                                <v-list-item>
                                                    <v-btn plain elevation="0" @click.stop="removeUser(group)">
                                                        <v-icon class="mr-2">delete</v-icon>
                                                        {{ $t("general.delete") }}
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
                <div v-else-if="!loading && !hasData">
                    No Data
                </div>
            </v-card-text>
        </v-card>

        <!---- ADD USER GROUP DIALOG ---->
        <AddUserGroupDialog
            v-if="openAddUserGroupDialog"
            :openAddUserGroupDialog="openAddUserGroupDialog"
            :user="user"
            :userGroups="userGroups"
            :rounded="false"
            v-on:refreshDataTable="$apollo.queries.userGroups.refetch()"
            v-on:closeAddDialog="openAddUserGroupDialog = false"
            
        ></AddUserGroupDialog>

        <!---- DELETE USER GROUP DIALOG ---->
        <DeleteUserGroupDialog
            :openDeleteDialog="openDeleteUserGroupDialog"
            :group="selectedGroup"
            :userIds="selecteIds"
            v-on:refreshDataTable="$apollo.queries.userGroups.refetch()"
            v-on:closeDeleteDialog="closeAndCleanDeletedGroups"
        >
            <template #title>{{ $t("users.remove_user") }}</template>
            <template #content>{{ $t("users.sure_group") }}</template>
        </DeleteUserGroupDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddUserGroupDialog from "@/components/users/create/AddUserGroupDialog.vue";
import DeleteUserGroupDialog from "@/components/users/delete/DeleteUserGroupDialog.vue";
import Config from "@/utils/config.json";
import SearchBar from "@/components/base/SearchBar.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";

export default {
    name: "UserGroupsTab",
    components: {
        AddUserGroupDialog,
        DeleteUserGroupDialog,
        SearchBar,
        LoadingBar
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    apollo: {
        userGroups: {
            query: gql`
                query TenantUser(
                    $tenantUserId: ID!
                    $first: Int
                    $before: String
                    $after: String
                    $last: Int
                    $orderBy: String
                    
                ) {
                    tenantUser(id: $tenantUserId) {
                        id
                        user {
                            id
                            allTenantUserGroups(
                                first: $first
                                before: $before
                                after: $after
                                last: $last
                                orderBy: $orderBy
                            ) {
                                totalCount
                                pageInfo {
                                    startCursor
                                    endCursor
                                    hasPreviousPage
                                    hasNextPage
                                }
                                edges {
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantUserId: this.user.node.id,
                    
                };
            },
            watchLoading(isLoading) {
                this.loading = isLoading;
                this.$emit("loading", isLoading);
            },
            update: data => {
                return data.tenantUser.user.allTenantUserGroups;
            },
            skip: false
        }
    },
    data() {
        return {
            openAddUserGroupDialog: false,
            openDeleteUserGroupDialog: false,
            openEditUserDialog: false,
            selectedGroup: {},
            selecteIds: [],
            searchInput: null,
            loading: false,
            userGroups: { edges: [] },
            filter: {
                first: 20
            },
            shouldLoadMore: false
        };
    },
    watch: {
        
    },
    computed: {
        enableOrDisableRemove() {
            return this.removedUsers?.length > 0 ? false : true;
        },
        // infinite
        hasMoreData() {
            return this.userGroups?.pageInfo?.hasNextPage;
        },
        groups() {
            let _groups = [];
            if (this.userGroups?.edges?.length > 0) {
                _groups = this.userGroups.edges;
            }

            if(this.searchInput){
                _groups = _groups.filter(group => {
                    return group.node.name.toLowerCase().includes(this.searchInput.toLowerCase());
                });
            }
            return _groups;
        },
        hasData() {
            let _data = this.userGroups?.edges.length > 0 ? true : false;
            return _data;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "user_groups_tab_svw"
        });
        this.setUserProperties();

        if(this.$route?.query?.tabDetails){
            this.openAddUserGroupDialog = true;
        }
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("user_groups_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("user_groups_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        removeUser(item) {
            this.openDeleteUserGroupDialog = true;
            this.selectedGroup = item;
            this.selecteIds.push(this.user.node.user.id);
        },
        closeAndCleanDeletedGroups() {
            this.openDeleteUserGroupDialog = false;
            this.selectedGroup = {};
            this.selecteIds = [];
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.userGroups?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.userGroups.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.userGroups?.pageInfo?.hasNextPage) {
                this.$apollo.queries.userGroups.fetchMore({
                    variables: {
                        after: this.userGroups.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
<style scoped>
.textLeft {
    text-align: left !important;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 18px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 18px;
}
.outline {
    border: 1px solid #ebebf5;
    box-sizing: border-box;
    border-radius: 4px;
}
.backgroundContent {
    background: #f9fafe;
}
</style>
