import * as types from "../types";
import { apolloClient, onLogin, onLogout } from "@/vue-apollo";

const ACCESS_TOKEN_KEY = "your_access_token_key";
const REFRESH_TOKEN_KEY = "your_refresh_token_key";

// Helper functions to manage localStorage interactions
const localStoragePlugin = {
    setItem(key, item) {
        localStorage.setItem(key, JSON.stringify(item));
    },
    getItem(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    },
    removeItem(key) {
        localStorage.removeItem(key);
    }
};

const state = {
    createdPartner: localStoragePlugin.getItem('createdPartner') || null,
    cancel: localStoragePlugin.getItem('cancel') || null,
};

const mutations = {
    setCreatedPartner(state, { type, partner }) {
        if (type == "update") {
            state.createdPartner = partner;
            state.cancel = null;
            localStoragePlugin.setItem('createdPartner', partner);
            localStoragePlugin.removeItem('cancel');
        } else if (type == "cancel") {
            state.cancel = partner;
            state.createdPartner = null;
            localStoragePlugin.setItem('cancel', partner);
            localStoragePlugin.removeItem('createdPartner');
        }
    },
};

const actions = {
    updateCreatedPartner({ commit }, { type, partner }) {
        commit("setCreatedPartner", { type, partner });
    },
    
};

const getters = {
    getCreatedPartner: state => state.createdPartner,
    getCancelPartner: state => state.cancel,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
