<template>
    <v-container class="fill-height" fluid>
        <!-- Login Dialog -->
        <v-row class="d-flex justify-center">
            <v-col cols="12" v-if="!isEmbedded">
                <div class="d-flex justify-center mb-5">
                    <a href="">
                        <v-img
                            :src="require('../assets/new_coldcha_logo.png')"
                            max-height="83px"
                            max-width="352px"
                            contain
                            v-on:click="goToColdCha"
                        ></v-img>
                    </a>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="8"
                md="6"
                lg="5"
                xl="4"
                class="d-flex justify-center"
            >
                <v-card class="elevation-10 pt-3" width="520px">
                    <!-- <v-row class="justify-end pr-5 pt-3">
                        <LangDropDown />
                    </v-row> -->
                    <v-card-text class="mt-10 pb-0 px-10">
                        <v-form ref="loginForm" @submit.prevent>
                            <v-text-field
                                placeholder="E-mail"
                                name="login"
                                type="email"
                                :rules="[rules.required]"
                                :error-messages="error"
                                outlined
                                prepend-inner-icon="person"
                                v-model="email"
                            ></v-text-field>

                            <v-text-field
                                id="password"
                                :placeholder="$t('login.password')"
                                name="password"
                                :type="showpassword ? 'text' : 'password'"
                                :rules="[rules.required]"
                                :error-messages="error"
                                outlined
                                v-model="password"
                                prepend-inner-icon="lock"
                                @keydown.enter="login"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        :color="
                                            isEmbedded && hasColor
                                                ? defaultPrimaryColor
                                                : 'primary'
                                        "
                                        @click="showpassword = !showpassword"
                                        tabindex="-1"
                                        >{{
                                            showpassword
                                                ? "visibility"
                                                : "visibility_off"
                                        }}</v-icon
                                    >
                                </template>
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <p class="text-center pb-0 px-10">
                        <v-btn
                            :color="
                                isEmbedded && hasColor
                                    ? defaultPrimaryColor
                                    : 'primary'
                            "
                            block
                            rounded
                            :disabled="email.length < 2 || password.length < 2"
                            :loading="isLoggingIn"
                            @click="login"
                            class="white--text"
                            >{{ $t("home.login") }}</v-btn
                        >
                        <br />
                        <a
                            @click="forgotPasswordDialog = true"
                            :style="
                                isEmbedded && hasColor
                                    ? 'color:' + defaultPrimaryColor + ';'
                                    : 'color:primary;'
                            "
                        >
                            {{ $t("login.forgot") }}
                        </a>
                    </p>
                    <v-divider></v-divider>
                    <!-- <v-row class="d-flex justify-center ma-0 py-2">
                        <v-col cols="auto" class="py-1">
                            <v-btn color="primary" rounded outlined>
                                <v-img
                                    class="mr-1"
                                    width="20"
                                    :src="
                                        require('../assets/microsoft-logo.svg')
                                    "
                                ></v-img>
                                Login With Microsoft</v-btn
                            >
                        </v-col>
                        <v-col cols="auto" class="py-1">
                            <v-btn color="primary" rounded outlined>
                                <v-img
                                    class="mr-1"
                                    width="20"
                                    :src="require('../assets/google-logo.svg')"
                                ></v-img>
                                Login With Google
                            </v-btn>
                        </v-col>
                    </v-row> -->
                </v-card>
            </v-col>
        </v-row>

        <!-- 2FA dialog -->
        <v-dialog v-model="tfaDialog" persistent max-width="400px">
            <v-card>
                <v-card-title
                    :class="
                        isEmbedded && hasColor
                            ? defaultPrimaryColor + ' white--text'
                            : 'primary white--text'
                    "
                >
                    {{ $t("login.2fa") }}
                    <v-spacer></v-spacer>
                    <v-btn class="mr-n3" dark icon @click="close2FADialog()">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-5 pb-0">
                    <v-form ref="tfaForm" @submit.prevent>
                        <v-text-field
                            v-model="tfaCode"
                            counter="6"
                            :rules="[rules.required, rules.maxLength(6)]"
                            :label="$t('login.2facode')"
                            type="number"
                            outlined
                            autofocus
                            class="headline"
                            :error-messages="tfaCodeError"
                            @keydown.enter="loginWith2FA"
                            hint="Google Authenticator Code"
                        ></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions class="pb-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        rounded
                        :disabled="tfaCode.length != 6"
                        :loading="isLoggingInWith2FA"
                        :color="
                            isEmbedded && hasColor
                                ? defaultPrimaryColor
                                : 'primary'
                        "
                        @click="loginWith2FA()"
                        >{{ $t("login.verify") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Forgot Password dialog -->
        <v-dialog v-model="forgotPasswordDialog" persistent max-width="500px">
            <!-- <v-card :color="isEmbedded ? defaultPrimaryColor: 'primary'"> -->
            <v-card>
                <v-card-title
                    class="white--text"
                    v-if="isEmbedded && hasColor"
                    :style="
                        'background-color:' +
                            defaultPrimaryColor +
                            ';border-color:' +
                            defaultPrimaryColor +
                            ';'
                    "
                >
                    {{ $t("login.forgot") }}
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-n3"
                        dark
                        icon
                        @click="closeForgotPasswordDialog()"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-title class="primary white--text" v-else>
                    {{ $t("login.forgot") }}
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-n3"
                        dark
                        icon
                        @click="closeForgotPasswordDialog()"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text v-if="!isResetPasswordEmailSent" class="pt-5 pb-0">
                    <p>{{ $t("login.enter_email") }}</p>
                    <v-form ref="forgotPasswordForm" @submit.prevent>
                        <v-text-field
                            v-model="forgotPasswordEmail"
                            :rules="[rules.required]"
                            label="E-mail"
                            type="email"
                            outlined
                            autofocus
                            :color="
                                isEmbedded && hasColor
                                    ? defaultPrimaryColor
                                    : 'primary'
                            "
                            :error-messages="forgotPasswordEmailError"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-text v-else>
                    <h1 class="text-center my-5">{{ $t("login.inbox") }}</h1>
                    <p>
                        {{ $t("login.email_sent") }}
                    </p>
                </v-card-text>

                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!isResetPasswordEmailSent"
                        outlined
                        rounded
                        :disabled="!forgotPasswordEmail.includes('@')"
                        :loading="isRequestingPasswordReset"
                        :color="
                            isEmbedded && hasColor
                                ? defaultPrimaryColor
                                : 'primary'
                        "
                        @click="requestPasswordReset()"
                        >{{ $t("general.continue") }}</v-btn
                    >
                    <v-btn
                        v-else
                        outlined
                        rounded
                        :color="
                            isEmbedded && hasColor
                                ? defaultPrimaryColor
                                : 'primary'
                        "
                        @click="closeForgotPasswordDialog()"
                        >{{ $t("general.close") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
// import Config from "@/utils/config.json";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import jwt from 'jsonwebtoken';

export default {
    title: "Login ColdCha",
    components: {
        // LangDropDown
    },
    data: function() {
        return {
            isLoggingIn: false,
            isLoggingInWith2FA: false,
            showpassword: false,
            tfaDialog: false,

            forgotPasswordDialog: false,
            isRequestingPasswordReset: false,
            forgotPasswordEmail: "",
            forgotPasswordEmailError: "",
            isResetPasswordEmailSent: false,

            email: "",
            password: "",
            tfaCode: "",
            error: "",
            tfaCodeError: "",
            mess: "",
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v =>
                    (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v =>
                    (v || "").length >= len ||
                    this.$t("rules.at)least") + ` ${len}`,
                maxLength: len => v =>
                    (v || "").length <= len || this.$t("rules.too_long")
                // nameRules: [
                //     v => !!v || "Name is required",
                //     v =>
                //         v.length <= 50 || "Name must be less than 50 characters"
                // ]
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        isEmbedded() {
            return this.$route.query.embedded;
        },
        hasColor() {
            if (this.$route.query.color !== undefined) return true;

            return false;
        },
        defaultPrimaryColor() {
            if (this.$route.query.color !== undefined)
                //|| this.$route.query.color !== '')
                return "#" + this.$route.query.color;
            return "primary";
        }
    },
    created() {
        this.pushTo = helper.pushTo;
        this.setRouteColor();
    },
    methods: {
        setRouteColor(){
            let routeColor = this.$route?.query?.color;
            if(routeColor != undefined){
                if(routeColor != null)
                    localStorage.setItem("routeColor", routeColor);
            }
        },
        goToColdCha() {
            window.location.href = "https://www.coldcha.com/";
        },
        requestPasswordReset() {
            // check if form valid
            if (!this.$refs.forgotPasswordForm.validate()) {
                return;
            }
            this.isRequestingPasswordReset = true;
            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation requestPasswordReset(
                            $input: RequestPasswordResetInput!
                        ) {
                            requestPasswordReset(input: $input) {
                                success
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            email: this.forgotPasswordEmail
                        }
                    }
                })
                .then(() => {
                    this.isResetPasswordEmailSent = true;
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            this.forgotPasswordEmailError = err.message;
                        }
                    }
                })
                .finally(() => {
                    this.isRequestingPasswordReset = false;
                });
        },

        closeForgotPasswordDialog() {
            this.isResetPasswordEmailSent = false;
            this.forgotPasswordEmail = "";
            this.forgotPasswordEmailError = "";
            if (this.$refs.forgotPasswordForm) {
                this.$refs.forgotPasswordForm.resetValidation();
            }
            this.forgotPasswordDialog = false;
        },
        async login() {
            this.error = null;

            // check if form valid
            if (!this.$refs.loginForm.validate()) {
                return;
            }

            this.isLoggingIn = true;
            const payload = { email: this.email, password: this.password };
            
            try {
                const data = await this.$store.dispatch("user/login", payload);
                this.requestThemeColors()

                // if (this.$route.query.redirect) {
                //     this.$router.push(this.$route.query.redirect);
                // } else {
                //     this.pushTo("home");
                // }

                /*This check if token is not exired*/
                let decodedToken = jwt.decode(data.tokenAuth.token);
                let isTokenExpired = Date.now() >= decodedToken.exp * 1000

                if (isTokenExpired) {
                    try {
                        await this.$store.dispatch("user/logout")
                    } catch (error) {
                        console.log(error)
                    } finally {
                        //refresh the page to clear the cache
                        window.location.reload();
                        this.$router.push({ name: "login" });
                    } 
                }

            } catch (error) {
                if (error.graphQLErrors) {
                    for (let err of error.graphQLErrors) {
                        /*2fa*/
                        if (err.message === "2FA") {
                            this.tfaDialog = true;
                        } else {
                            this.error = err.message;
                        }
                    }
                }
            } finally {
                this.isLoggingIn = false;
            }
        },
        requestThemeColors() {
            this.$apollo
                .query({
                    // Query
                    query: gql`
                        query {
                            me {
                                tenant {
                                    themeJson
                                }
                            }
                        }
                    `
                })
                .then(response => {
                    if (response.data?.me?.tenant) {
                        let _themeColor = JSON.parse(
                            response.data?.me?.tenant?.themeJson
                        );
                        Object.entries(_themeColor).forEach(([key, value]) => {
                            this.$vuetify.theme.themes.light[key] = value;
                        });
                    } else {
                        this.$vuetify.theme.themes.light.primary = "#1B6A99";
                        this.$vuetify.theme.themes.light.secondary = "#154D79";
                        this.$vuetify.theme.themes.light.accent = "#B05C4B";
                        this.$vuetify.theme.themes.light.error = "#CB5858";
                        this.$vuetify.theme.themes.light.info = "#3D9BC8";
                        this.$vuetify.theme.themes.light.success = "#C5E17A";
                        this.$vuetify.theme.themes.light.warning = "#ED8E83";
                    }
                }).finally(()=>{
                    if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect || "/");
                    } else {
                        this.pushTo("home");
                    }
                });
        },
        close2FADialog() {
            this.tfaCode = "";
            this.$refs.tfaForm.resetValidation();
            this.tfaDialog = false;
        },

        loginWith2FA() {
            // check if form valid
            if (!this.$refs.tfaForm.validate()) {
                return;
            }

            this.isLoggingInWith2FA = true;

            const payload = {
                email: this.email,
                password: this.password,
                tfaCode: this.tfaCode
            };

            this.$store
                .dispatch("user/login", payload)
                .then(() => {
                    // // ColdCha Staff
                    // if (response.data?.tokenAuth?.user?.isStaff) {
                    //     this.pushTo("accounts");
                    // } else {
                    //     // normal user
                    //     this.pushTo("overzicht");
                    // }

                    this.pushTo("home");
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            /*2fa*/
                            if (err.message === "INVALID_GACODE") {
                                this.tfaCodeError = "Invalide 2FA-Code";
                            } else {
                                this.tfaCodeError = err.message;
                            }
                        }
                    }
                })
                .finally(() => {
                    this.isLoggingInWith2FA = false;
                });
        }
    }
};
</script>
<style scoped>
.default_card_title {
    background-color: var(--v-primary-base) !important;
    border-color: var(--v-primary-base) !important;
    color: #ffffff !important;
    caret-color: #ffffff !important;
}
</style>
