import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VDialog,{attrs:{"max-width":"700","persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.editMode)?_c(VBtn,_vm._g(_vm._b({attrs:{"rounded":"","icon":"","disabled":!_vm.me.isTenantUser},on:{"click":_vm.enableEditObj}},'v-btn',attrs,false),on),[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("edit")])]}}],null,true)},[_c('span',[_vm._v("Edit Change Request")])])],1):_c(VBtn,_vm._g(_vm._b({attrs:{"rounded":"","color":"primary","disabled":_vm.me.isTenantUser || _vm.booking.orderStatus === 'draft'}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s("Change request")+" ")])]}}]),model:{value:(_vm.requestDialog),callback:function ($$v) {_vm.requestDialog=$$v},expression:"requestDialog"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.editMode ? "Edit" : "Submit")+" Change Request ")]),_c(VCardText,[(_vm.obj)?_c(VRow,{staticClass:"mt-6 pt-4 px-8"},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"text",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" Title ")])])],1),_c(VTextField,{attrs:{"outlined":"","dense":"","placeholder":"Title","disabled":_vm.isLogco},model:{value:(_vm.obj.title),callback:function ($$v) {_vm.$set(_vm.obj, "title", $$v)},expression:"obj.title"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"text",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" Request ")])])],1),_c(VTextarea,{attrs:{"placeholder":"Write your request here...","outlined":"","dense":"","no-resize":"","disabled":_vm.isLogco},model:{value:(_vm.obj.content),callback:function ($$v) {_vm.$set(_vm.obj, "content", $$v)},expression:"obj.content"}})],1),(_vm.me.isTenantUser && _vm.editMode)?_c(VCol,{staticClass:"py-0",attrs:{"cols":"4"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"text",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" Status ")])])],1),_c(VSelect,{attrs:{"items":_vm.changeRequestStatus,"item-text":"text","item-value":"value","outlined":"","dense":"","no-resize":""},model:{value:(_vm.obj.status),callback:function ($$v) {_vm.$set(_vm.obj, "status", $$v)},expression:"obj.status"}})],1):_vm._e(),(
                            _vm.obj.status === 'cancelled' ||
                                _vm.obj.status === 'delined'
                        )?_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"text",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" Reason ")])])],1),_c(VTextarea,{attrs:{"placeholder":"Write your reason here...","outlined":"","dense":"","no-resize":"","disabled":!_vm.isLogco},model:{value:(_vm.obj.reason),callback:function ($$v) {_vm.$set(_vm.obj, "reason", $$v)},expression:"obj.reason"}})],1):_vm._e()],1):_vm._e()],1),_c(VCardActions,{staticClass:"pt-0 pr-12"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"primary","rounded":""},on:{"click":_vm.createEdit}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }