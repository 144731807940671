import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"mb-2"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("general.password_management"))+" ")])]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("general.change_password"))+" ")]),_c('ChangePasswordForm',{attrs:{"dialog":_vm.dialog},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1),_c(VCol)],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"mb-2"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("general.two_factor_authentication")))])]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.dialog2 = true}}},[_vm._v(" "+_vm._s(_vm.$t("general.enable_2fa"))+" ")]),_c('Enable2faForm',{attrs:{"dialog":_vm.dialog2},on:{"update:dialog":function($event){_vm.dialog2=$event}}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }