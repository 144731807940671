import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import Print from "vue-print-nb";
import "./utils/filters";
import titleMixin from "./mixins/titleMixin";
import VueGtag from "vue-gtag";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { Icon } from 'leaflet';
import i18n from "./plugins/i18n";
import Config from "@/utils/config.json";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.mixin(titleMixin);
Vue.use(Print);

Vue.config.productionTip = false;

Vue.use(
    VueGtag,
    {
        appName: Config.VUE_APP_APPLICATION_NAME,
        pageTrackerScreenviewEnabled: true,
        config: {
            id: Config.VUE_APP_GOOGLE_ANALYTICS_API_KEY
        }
    },
    router
);

const moment = require("moment");
require("moment/locale/nl");
Vue.use(require("vue-moment"), {
    moment
});

export const eventBus = new Vue();

new Vue({
    router,
    store,
    vuetify,
    apolloProvider: createProvider(),
    i18n,
    render: h => h(App)
}).$mount("#app");


