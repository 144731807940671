<template>
    <!-- userInboxNotifications -->
    <v-menu
        v-model="isUserInboxNotificationCenterOpen"
        :offset-y="offsetIsY" 
        :offset-x="!offsetIsY" 
        :right="!offsetIsY"
        :left="offsetIsY"
        bottom 
        :close-on-content-click="false"
        origin="center center"
        :nudge-bottom="10"
    >
        <template v-slot:activator="{ on }">
            <div v-if="displayFormat === 'text'">
                <div v-on="on" class="">
                    {{ $t('headers.notifications') }}
                </div>   
            </div>
            <div v-else-if="displayFormat === 'list-item'">
                <v-list-item
                    v-on="on"                                 
                    link
                    v-ripple="{ center: true }"
                >
                    <v-list-item-action>
                        <v-badge
                            :content="unreadNotificationCount > 9 ? '9+' : unreadNotificationCount"
                            :value="unreadNotificationCount"
                            color="warning"
                            overlap
                        >
                            <v-icon>notifications</v-icon>
                        </v-badge>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span>{{ $t('headers.notifications') }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>                 
            </div>
            <div v-else>
                <v-btn icon v-on="on" class="mr-1">
                    <v-badge
                        :content="
                            unreadNotificationCount > 9
                                ? '9+'
                                : unreadNotificationCount
                        "
                        :value="unreadNotificationCount"
                        color="warning"
                        overlap
                    >
                        <v-icon>notifications</v-icon>
                    </v-badge>
                </v-btn>
            </div>
        </template>
        <v-card class="elevation-0" style="width: 450px">
            <v-card-title class="pb-0">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            :color="
                                isUnreadNotificationFilterEnabled
                                    ? 'primary'
                                    : 'grey'
                            "
                            v-on="on"
                            :disabled="
                                $apollo.queries.userInboxNotifications.loading
                            "
                            @click="filterNotifications()"
                            ><v-icon>filter_list</v-icon></v-btn
                        >
                    </template>
                    <span>{{
                        isUnreadNotificationFilterEnabled
                            ? this.$t("general.show_all")
                            : this.$t("general.show_unread")
                    }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-on="on"
                            :disabled="
                                unreadNotificationCount == 0 ||
                                    isMarkingAllNotificationsAsRead
                            "
                            @click="markAllUserInboxNotificationAsRead()"
                            ><v-icon>drafts</v-icon></v-btn
                        >
                    </template>
                    <span>{{ $t("general.mark_all") }}</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-toolbar class="elevation-0">
                    <v-row>
                        <!-- Create v-btn-toggle like v-tab style -->
                        <v-btn-toggle
                            v-model="toggle"
                            class="mt-2"
                            color="primary"
                            group
                            max
                        >
                            <v-btn
                                value="general"
                                class="text-capitalize white"
                                text
                            >
                                <span> {{ $t("general.general") }}</span>
                            </v-btn>
                            <v-btn
                                value="alert"
                                class="text-capitalize white"
                                text
                            >
                                <span>{{ $t("general.notifications") }}</span>
                            </v-btn>
                        </v-btn-toggle>
                    </v-row>
                </v-toolbar>
                <v-virtual-scroll
                    v-if="toggle == 'general'"
                    :items="[]"
                    :item-height="80"
                    height="400"
                >
                    <template v-slot:default="{ index, item }">
                        <v-row no-gutters class="d-flex justify-center">
                            <v-col cols="auto">
                                <span>
                                    {{ item }}
                                </span>
                            </v-col>
                        </v-row>
                    </template>
                </v-virtual-scroll>
                <v-virtual-scroll
                    v-if="toggle == 'alert'"
                    :items="
                        userInboxNotifications
                            ? userInboxNotifications.edges
                            : []
                    "
                    :item-height="80"
                    height="400"
                >
                    <template v-slot:default="{ index, item }">
                        <v-divider></v-divider>
                        <v-list-item three-line dense>
                            <v-list-item-content
                                class="row-pointer elevation-0"
                                tile
                            >
                                <v-list-item-title
                                    class="subtitle-2"
                                    v-text="item.node.subject"
                                ></v-list-item-title>

                                <v-list-item-subtitle>{{
                                    formatDateTime(item.node.createdAt)
                                }}</v-list-item-subtitle>

                                <!-- <v-list-item-title
                                    v-html="item.node.message"
                                ></v-list-item-title> -->
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-tooltip bottom v-if="item.node.isRead">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-on="on"
                                            v-model="isRead"
                                            @click="
                                                updateUserInboxNotificationAsRead(
                                                    item.node.id,
                                                    false
                                                )
                                            "
                                            ><v-icon>drafts</v-icon></v-btn
                                        >
                                    </template>
                                    <span>{{
                                        $t("general.mark_unread")
                                    }}</span> </v-tooltip
                                ><v-tooltip bottom v-if="!item.node.isRead">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-on="on"
                                            v-model="isRead"
                                            @click="
                                                updateUserInboxNotificationAsRead(
                                                    item.node.id,
                                                    true
                                                )
                                            "
                                            ><v-icon>email</v-icon></v-btn
                                        >
                                    </template>
                                    <span>{{ $t("general.mark_read") }}</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>

                        <!-- infinit loading trigger -->
                        <v-card
                            tile
                            v-if="
                                index == userInboxNotifications.edges.length - 1
                            "
                            class="elevation-0"
                        >
                            <v-card-actions class="justify-center">
                                <v-btn
                                    v-if="hasMoreData"
                                    v-intersect="onLoadMoreTriggerIntersect"
                                    :disabled="!hasMoreData"
                                    :loading="
                                        $apollo.queries.userInboxNotifications
                                            .loading
                                    "
                                    plain
                                    class="mb-5"
                                    @click="loadMore"
                                >
                                    {{ $t("loading.load_more") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-virtual-scroll>
            </v-card-text>
            <v-progress-linear
                :active="$apollo.queries.userInboxNotifications.loading"
                :indeterminate="$apollo.queries.userInboxNotifications.loading"
                absolute
                bottom
                color="primary"
                height="3"
            ></v-progress-linear>
        </v-card>
    </v-menu>
</template>

<script>
import gql from "graphql-tag";
// import moment from "moment";
import helper from "@/utils/helper.js";

export default {
    name: "userInboxNotifications",
    components: {},
    props: {
        offset: {
            type: String,
            default: "y"
        },
        format: {
            type: String,
            default: "icon"
        }
    },
    apollo: {
        unreadUserInboxNotifications: {
            query: gql`
                query unreadUserInboxNotifications($isRead: Boolean) {
                    unreadUserInboxNotifications(isRead: $isRead) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    isRead: false
                };
            },
            fetchPolicy: "cache-and-network",
            update: data => data.unreadUserInboxNotifications,
            pollInterval: 60000 // ms
        },
        userInboxNotifications: {
            query: gql`
                query userInboxNotifications(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $isRead: Boolean
                    $search: String #this variable name must by $search
                ) {
                    userInboxNotifications(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        search: $search
                        isRead: $isRead
                        orderBy: $orderBy
                    ) {
                        edgeCount
                        totalCount
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                notificationType
                                subject
                                message
                                readAt
                                createdAt
                                isRead
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    first: 10,
                    search: this.search,
                    isRead: this.isUnreadNotificationFilterEnabled
                        ? false
                        : null
                };
            },
            fetchPolicy: "cache-and-network",
            update: data => data.userInboxNotifications,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return (
                    !this.isLoggedIn || !this.isUserInboxNotificationCenterOpen
                );
            }
        }
    },
    data() {
        return {
            isInitialLoad: true,
            shouldLoadMore: false,

            isUserInboxNotificationCenterOpen: false,
            isMarkingAllNotificationsAsRead: false,
            isUnreadNotificationFilterEnabled: true,
            isRead: false,
            userInboxNotifications: { edges: [] },
            toggle: "alert"
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        // infinite
        hasMoreData() {
            return this.userInboxNotifications?.pageInfo?.hasNextPage;
        },
        isLoggedIn() {
            return this.$store.state.user.loggedIn;
        },
        today() {
            var _today = new Date();
            _today.setHours(23, 59, 59, 59); // remove time

            return _today;
        },
        unreadNotificationCount() {
            return this.unreadUserInboxNotifications
                ? this.unreadUserInboxNotifications.totalCount
                : 0;
        },
        offsetIsY() {
            return this.offset === "y" ? true : false;
        },
        displayFormat() {
            switch(this.format) {
                case "icon":
                    return "icon";
                case "text":
                    return "text";
                case "list-item":
                    return "list-item";
                default:
                    return "icon";
            }
        },
        positionX() {
            return this.offset === "x" ? 23 : undefined;
        }
    },
    watch: {
        isUserInboxNotificationCenterOpen(val) {
            if (val) {
                this.fetchUnreadUserInboxNotificationsCount();
            }
        }
    },
    created() {
        // copy hasPermission from helper
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        this.loadMore();
    },
    methods: {
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (
                this.shouldLoadMore &&
                this.userInboxNotifications?.pageInfo?.hasNextPage
            ) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.userInboxNotifications?.pageInfo?.hasNextPage) {
                this.$apollo.queries.userInboxNotifications.fetchMore({
                    variables: {
                        after: this.userInboxNotifications.pageInfo.endCursor
                    }
                });
            }
        },

        fetchUnreadUserInboxNotificationsCount() {
            this.$apollo.queries.unreadUserInboxNotifications.refresh();
        },

        fetchNotifications() {
            this.$apollo.queries.userInboxNotifications.setVariables({
                first: 10,
                isRead: this.isUnreadNotificationFilterEnabled ? false : null
            });
            this.$apollo.queries.userInboxNotifications.refresh();
        },

        filterNotifications() {
            this.isUnreadNotificationFilterEnabled = !this
                .isUnreadNotificationFilterEnabled;

            this.fetchNotifications();
        },

        loadMoreNotifications() {
            if (
                this.userInboxNotifications &&
                this.userInboxNotifications.pageInfo.hasNextPage
            ) {
                this.$apollo.queries.userInboxNotifications.fetchMore({
                    // New variables
                    variables: {
                        after: this.userInboxNotifications.pageInfo.endCursor
                    }
                });
            }
        },

        markAllUserInboxNotificationAsRead() {
            this.isMarkingAllNotificationsAsRead = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation markAllUserInboxNotificationAsRead {
                            markAllUserInboxNotificationAsRead(input: {}) {
                                userInboxNotificationCount
                            }
                        }
                    `,
                    // Parameters
                    variables: {}
                })
                .then(() => {
                    // // Result
                    this.fetchUnreadUserInboxNotificationsCount();
                    this.fetchNotifications();
                })
                .catch(error => {
                    // Error
                    console.error(error);
                })
                .finally(() => {
                    this.isMarkingAllNotificationsAsRead = false;
                });
        },

        updateUserInboxNotificationAsRead(id, isRead) {
            this.isMarkingAllNotificationsAsRead = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation updateUserInboxNotificationAsRead(
                            $input: UpdateUserInboxNotificationAsReadInput!
                        ) {
                            updateUserInboxNotificationAsRead(input: $input) {
                                userInboxNotification {
                                    id
                                    isRead
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            id: id,
                            isRead: isRead
                        }
                    }
                })
                .then(() => {
                    // // Result
                    this.fetchNotifications();
                    this.fetchUnreadUserInboxNotificationsCount();
                })
                .catch(error => {
                    // Error
                    console.error(error);
                })
                .finally(() => {
                    this.isMarkingAllNotificationsAsRead = false;
                });
        },

        goToNotificationUrl(url) {
            // redirect to the url from the notifcation
            this.$router.push(url);
        }
    }
};
</script>
