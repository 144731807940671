import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.openAddGroupUserDialog),callback:function ($$v) {_vm.openAddGroupUserDialog=$$v},expression:"openAddGroupUserDialog"}},[_c(VCard,{staticClass:"pb-4"},[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t("groups.add_user"))+" ")],1),_c(VCardText,[_c(VForm,{ref:"groupUserForm"},[_c(VCol,{staticClass:"pa-0 mt-6",attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"dense":"","items":_vm.users.edges,"no-data-text":_vm.$t('loading.no_available'),"label":_vm.$t('groups.select_user') + '*',"outlined":"","hide-selected":"","hide-details":"","deletable-chips":"","small-chips":"","item-text":"node.fullName","item-value":"node.id","multiple":"","attach":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VBtn,{attrs:{"x-small":"","outlined":"","fab":"","color":"primary"},on:{"click":_vm.createNewUser}},[_c(VIcon,[_vm._v("add")])],1)]},proxy:true}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.closeAndResetInput}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"loading":_vm.isLoadingAddGroupUser,"color":"primary","rounded":""},on:{"click":_vm.createTenantGroupUser}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1),_c('AddStaffDialog',{attrs:{"openAddUserDialog":_vm.openCreateNewUserDialog},on:{"refreshDataTable":function($event){return _vm.$apollo.queries.users.refresh()},"closeAddUserDialog":function($event){_vm.openCreateNewUserDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }