import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"py-0",attrs:{"fluid":""}},[_c(VForm,{ref:"loadForm"},[(_vm.disabled)?_c(VRow,{staticClass:"pa-3 d-flex",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('forms.trade'))+" ")]),(_vm.tripLoadPoint.trade)?_c('div',[_vm._v(" "+_vm._s(_vm.tripLoadPoint.trade)+" ")]):_c('div',[_vm._v(" - ")])]),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"6","lg":"6"}},[(_vm.hasMissingFields && _vm.disabled)?_c(VAlert,{attrs:{"outlined":"","type":"warning","border":"left","dense":""}},[_vm._v(" This information is needed in order to track the vessel. ")]):_vm._e()],1)],1):_c(VRow,{staticClass:"mt-3 pl-1",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6","sm":"6","md":"3","lg":"3"}},[_c(VTextField,{attrs:{"label":_vm.$t('forms.trade'),"outlined":"","dense":""},model:{value:(_vm.tripLoadPoint.trade),callback:function ($$v) {_vm.$set(_vm.tripLoadPoint, "trade", $$v)},expression:"tripLoadPoint.trade"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }