import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c(VSpacer)],1),(_vm.error)?_c(VAlert,{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c(VCardText,{staticClass:"pt-10"},[_c(VForm,{ref:"form"},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"subtitle-2",attrs:{"rules":[_vm.rules.required, _vm.rules.maxLength(100)],"label":_vm.$t('headers.first_name') + '*',"outlined":""},model:{value:(_vm.editedItem.firstName),callback:function ($$v) {_vm.$set(_vm.editedItem, "firstName", $$v)},expression:"editedItem.firstName"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"subtitle-2",attrs:{"rules":[_vm.rules.required, _vm.rules.maxLength(100)],"label":_vm.$t('headers.last_name') + '*',"outlined":""},model:{value:(_vm.editedItem.lastName),callback:function ($$v) {_vm.$set(_vm.editedItem, "lastName", $$v)},expression:"editedItem.lastName"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"subtitle-2",attrs:{"rules":[_vm.rules.required, _vm.rules.maxLength(100)],"label":"E-mail*","type":"email","outlined":"","disabled":""},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"pb-5 pr-5"},[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.isSaving,"text":"","rounded":""},on:{"click":_vm.closeUserDialog}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"outlined":"","rounded":"","loading":_vm.isSaving,"color":"primary"},on:{"click":_vm.saveUser}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }