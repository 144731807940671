<template>
    <div
        :style="`height: ${heightScreen}px;`"
        class="overflow-y-auto"
    >
        <v-sheet class="pb-2" color="transparent">
            <v-spacer />
            <v-btn small outlined color="primary" @click="expand">
                {{ isAllExpanded ? "- Collapse" : "+ Expand" }}
            </v-btn>
        </v-sheet>
        <v-expansion-panels v-model="panel" multiple focusable class="mb-5">
            <v-expansion-panel
                v-for="(item, index) in selectedFilters"
                :key="index"
            >
                <v-expansion-panel-header>
                    <h5>{{ item.name }}</h5>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                    <v-lazy>
                        
                       <!-- Add components -->
                    </v-lazy>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>


export default {
    props: {
        booking: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
       
    },
    data() {
        return {
            panel: [0]
        };
    },
    computed: {
        isAllExpanded() {
            return this.panel.length === this.selectedFilters.length;
        },
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 174  ;
            return  _break;
        }
    },
    watch: {},
    mounted() {},
    methods: {
        expand() {
            if (this.isAllExpanded) {
                this.panel = [];
            } else {
                this.panel = [
                    ...this.selectedFilters.map((item, index) => index)
                ];
            }
        }
    }
};
</script>

<style scoped lang="scss">
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>