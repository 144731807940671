<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <v-card elevation="5" class="mb-5 pb-0" rounded="xl">
                <v-card-title class="background2 py-3">
                    <div class="mr-2">
                        <v-icon>
                            settings
                        </v-icon>
                    </div>
                    <v-spacer></v-spacer>
                    <div v-if="canEdit">
                        <v-btn v-if="!isEditing" class="primary--text" rounded small icon @click="isEditing = true">
                            <v-icon>edit</v-icon></v-btn
                        >
                        <v-btn v-if="isEditing" class="mx-2" rounded outlined small @click="cancelChanges">{{
                            $t("general.cancel")
                        }}</v-btn>
                        <v-btn
                            small
                            rounded
                            outlined
                            v-if="isEditing"
                            color="primary"
                            @click="saveChanges"
                            :loading="isLoadingEditServices"
                        >
                            {{ $t("general.save") }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-row class="ml-15 mb-10">
                        <v-col
                            cols="6"
                            sm="12"
                            md="6"
                            lg="6"
                            v-for="service in servicesChoices"
                            :key="service.value"
                            class="mt-5"
                        >
                            <v-switch
                                v-model="service.enabled"
                                :label="service.text"
                                :disabled="!isEditing"
                                hide-details
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-sheet>
</template>

<script>
import gql from "graphql-tag";
import { StaffPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import helper from "@/utils/helper.js";
import Config from "@/utils/config.json";

export default {
    props: {
        tenant: {
            type: Object,
            required: true
        }
    },
    components: {
        NoPermission
    },
    data() {
        return {
            isEditing: false,
            isLoadingServices: false,
            isLoadingEditServices: false,
            servicesChoices: [
                {
                    text: this.$t("home.carriers"),
                    value: "carriers.manage_carrier",
                    enabled: false
                },
                {
                    text: this.$t("home.locations"),
                    value: "tenants.manage_tenantlocation",
                    enabled: false
                },
                {
                    text: this.$t("partners.trip_partners"),
                    value: "trips.manage_trippartner",
                    enabled: false
                },
                {
                    text: this.$t("permissions.trip_freight"),
                    value: "trips.manage_tripfreight",
                    enabled: false
                },
                {
                    text: this.$t("permissions.trip_freight_trackers"),
                    value: "trips.manage_tripfreighttracker",
                    enabled: false
                },
                {
                    text: this.$t("permissions.trip_document"),
                    value: "trips.manage_tripdocument",
                    enabled: false
                },
                {
                    text: this.$t("permissions.trip_sea_transport"),
                    value: "trips.manage_tripseatransport",
                    enabled: false
                },
                {
                    text: this.$t("permissions.trip_alert"),
                    value: "trips.manage_tripalert",
                    enabled: false
                },
                {
                    text: this.$t("permissions.trip_freight_timeline"),
                    value: "trips.manage_tripfreighttimeline",
                    enabled: false
                },
                {
                    text: this.$t("permissions.trip_reports"),
                    value: "trips.manage_tripreport",
                    enabled: false
                },
                {
                    text: this.$t("permissions.qx_booking"),
                    value: "bookings.manage_bookingorderqx",
                    enabled: false
                },
                {
                    text: this.$t("permissions.portbase"),
                    value: "portbase.view_info",
                    enabled: false
                }
            ]
        };
    },
    created() {
        if (this.tenant.organisationPermissions != null && this.tenant.organisationPermissions != "")
            this.servicesChoices.forEach(service => {
                let permissions = JSON.parse(this.tenant.organisationPermissions);
                service.enabled = permissions.includes(service.value.trim());
            });
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.StaffPermissions = StaffPermissions;
    },
    computed: {
        me() {
            return helper.me();
        },
        canAccess() {
            if (this.me?.isSuperuser) return true;

            if (!this.hasPermission(StaffPermissions.VIEW_TENANT_SERVICES) || !this.me?.isStaff) return false;

            return true;
        },
        canEdit() {
            if (this.me?.isSuperuser) return true;

            if (!this.hasPermission(StaffPermissions.EDIT_TENANT_SERVICES) || !this.me?.isStaff) return false;

            return true;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "tenant_services_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("tenant_services_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("tenant_services_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        cancelChanges() {
            this.isEditing = false;
            // reset services
            this.servicesChoices.forEach(service => {
                let permissions = JSON.parse(this.tenant.organisationPermissions);
                service.enabled = permissions.includes(service.value.trim());
            });
        },
        saveChanges() {
            this.isLoadingEditServices = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTenantServices($input: UpdateTenantServicesInput!) {
                            updateTenantServices(input: $input) {
                                tenant {
                                    id
                                    organisationPermissions
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantId: this.tenant.id,
                            services: this.servicesChoices
                                .filter(service => service.enabled)
                                .map(service => service.value)
                        }
                    }
                })
                .then(response => {
                    this.isLoadingEditServices = false;
                    this.isEditing = false;
                    //update services choices
                    this.servicesChoices.forEach(service => {
                        let permissions = JSON.parse(response.data.updateTenantServices.tenant.organisationPermissions);
                        service.enabled = permissions.includes(service.value.trim());
                    });
                })
                .catch(error => {
                    this.isLoadingEditServices = false;
                    console.log(error);
                });
        }
    }
};
</script>
