<template>
    <div class="px-5">
        <!-- SEARCH BAR -->
        <v-card class="py-4 mt-4" flat>
            <v-row align="start" class="px-3 d-flex align-center">
                <!---- SEARCH BAR ---->
                <v-col>
                    <v-row>
                        <v-col>
                            <SearchBar v-model="searchInput" :label="$t('general.search')"> </SearchBar>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="mt-2 mb-5 pb-5" outlined rounded>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mr-1" @click="openAddGroupPartnerDialog = true">
                    {{ $t("trips.add_partner") }}
                </v-btn>
            </v-card-title>
            <v-card-text>
                <!-- TABLE -->
                <div class="px-10 pt-4" v-if="loading && !hasData">
                    <LoadingBar></LoadingBar>
                </div>
                <div v-else-if="hasData">
                    <v-card
                        v-for="partner in groupData"
                        :key="partner.node.id"
                        class="mb-4 rounded-lg px-3 backgroundContent"
                        outlined
                        flat
                    >
                        <v-card-title>
                            <v-row class="d-flex justify-center py-1">
                                <v-col
                                    v-for="item in mainCard(partner)"
                                    :key="item.text"
                                    :cols="item.cols.xs"
                                    :xs="item.cols.xs"
                                    :sm="item.cols.sm"
                                    :md="item.cols.md"
                                    :lg="item.cols.lg"
                                    :xl="item.cols.xl"
                                >
                                    <div class="">
                                        <div class="textHeader text-truncate">
                                            {{ item.title }}
                                        </div>
                                        <div class="textBody text-truncate" :style="item.style">
                                            <v-icon
                                                v-if="item.icon"
                                                class="mr-1"
                                                :size="item.iconSize"
                                                :color="item.colorIcon"
                                                >{{ item.icon }}</v-icon
                                            >
                                            <span v-if="item.value || item.value > -1">
                                                {{ item.value }}
                                            </span>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>
                </div>
                <div v-else-if="!loading && !hasData">
                    No Data
                </div>
            </v-card-text>
        </v-card>

        <!---- ADD GROUP PARTNER DIALOG ---->
        <AddGroupPartnerDialog
            :openAddGroupPartnerDialog="openAddGroupPartnerDialog"
            :group="group"
            :groupPartners="groupPartners"
            v-on:refreshGroupPartnersTable="$apollo.queries.groupPartners.refetch()"
            v-on:closeAddGroupPartnerDialog="openAddGroupPartnerDialog = false"
        ></AddGroupPartnerDialog>

        <!---- EDIT GROUP PARTNER DIALOG ---->
        <EditPartnerDialog
            ref="editPartnerDialog"
            :openEditPartnerDialog="openEditPartnerDialog"
            v-on:closeEditPartnerDialog="closeEditPartnerDialog"
            v-on:refreshDataTable="$apollo.queries.groupPartners.refresh()"
        ></EditPartnerDialog>

        <!---- DELETE GROUP PARTNER DIALOG ---->
        <DeleteGroupPartnerDialog
            :openDeleteDialog="openDeleteGroupPartnerDialog"
            :group="group"
            :partnersID="selectedPartners"
            v-on:refreshDataTable="$apollo.queries.groupPartners.refetch()"
            v-on:closeDeleteGroupPartnerDialog="closeAndCleanDeletedPartners"
        >
            <template #title>{{ $t("groups.delete_partner") }}</template>
            <template #content>{{ $t("groups.sure_partner") }}</template>
        </DeleteGroupPartnerDialog>
    </div>
</template>
<script>
import gql from "graphql-tag";
import AddGroupPartnerDialog from "@/components/groups/create/AddGroupPartnerDialog.vue";
import DeleteGroupPartnerDialog from "@/components/groups/delete/DeleteGroupPartnerDialog.vue";
import EditPartnerDialog from "@/components/partners/update/EditPartnerDialog.vue";
import Config from "@/utils/config.json";
import SearchBar from "@/components/base/SearchBar.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";

export default {
    name: "GroupPartnersTab",
    components: {
        AddGroupPartnerDialog,
        DeleteGroupPartnerDialog,
        EditPartnerDialog,
        SearchBar,
        LoadingBar
    },
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    apollo: {
        groupPartners: {
            query: gql`
                query TenantUserGroupPartner($tenantUserGroupId: ID, $first: Int) {
                    tenantUserGroupPartners(tenantUserGroupId: $tenantUserGroupId, first: $first) {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    name
                                    referenceNumber
                                    partnerType
                                    carrierType
                                    notificationEnabled
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantUserGroupId: this.group.node.id,
                    first: 20
                };
            },
            watchLoading(isLoading) {
                this.loading = isLoading;
                this.$emit("loading", isLoading);
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantUserGroupPartners;
            },
            skip: false
        }
    },
    data() {
        return {
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /*********************************/
            openAddGroupPartnerDialog: false,
            openDeleteGroupPartnerDialog: false,
            openEditPartnerDialog: false,
            /******************************/
            /* ---- PARTNER INPUT DATA ---- */
            /****************************/
            selectedPartners: [],
            searchInput: null,
            groupPartners: { edges: [] },
            editPartnerItem: null,
            loading: false
            /****************************/
            /* ---- TABLE HEADERS ---- */
            /**************************/
        };
    },
    watch: {
        searchInput() {
            this.$apollo.groupPartners ? this.$apollo.groupPartners.refetch() : null;
        }
    },
    computed: {
        enableOrDisableRemove() {
            return this.removedPartners?.length > 0 ? false : true;
        },
        hasData() {
            return this.groupPartners?.edges?.length > 0;
        },
        groupData() {
            if (this.searchInput) {
                return this.groupPartners?.edges.filter(c =>
                    c.node.tenantPartner.name.toLowerCase().includes(this.searchInput.toLowerCase())
                );
            }
            return this.groupPartners?.edges;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "group_partners_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("group_partners_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("group_partners_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        editPartner(item) {
            this.editPartnerItem = item.node.tenantPartner;
            this.openEditPartnerDialog = true;
            this.$refs.editPartnerDialog.assignFormValues(this.editPartnerItem);

            // this.$refs.editPartnerDialog.initializePartner(item);
        },
        removePartner(item) {
            this.openDeleteGroupPartnerDialog = true;
            this.selectedPartners.push(item.node.id);
        },
        closeAndCleanDeletedPartners() {
            this.openDeleteGroupPartnerDialog = false;
            this.selectedPartners = [];
        },
        closeEditPartnerDialog() {
            this.openEditPartnerDialog = false;
            this.editPartnerItem = null;
        },
        mainCard(partner) {
            const { name, referenceNumber, partnerType, carrierType } = partner.node.tenantPartner;
            const _mainCard = [
                {
                    title: this.$t("headers.reference_number"),
                    tag: "referenceNumber",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: referenceNumber,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        xl: "3"
                    }
                },
                {
                    title: this.$t("headers.name"),
                    tag: "name",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: name,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        xl: "3"
                    }
                },
                {
                    title: this.$t("headers.partner_type"),
                    tag: "partnerType",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: partnerType,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        xl: "3"
                    }
                },
                {
                    title: this.$t("headers.carrier_type"),
                    tag: "carrierType",
                    icon: null,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: carrierType,
                    cols: {
                        xs: "12",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        xl: "3"
                    }
                }
            ];
            return _mainCard;
        }
    }
};
</script>
