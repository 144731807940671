const TenantPermissionsEnum = Object.freeze({
    //Tenant
    VIEW_TENANT: "tenants.view_tenant",
    CREATE_TENANT: "tenants.add_tenant",
    EDIT_TENANT: "tenants.change_tenant",
    DELETE_TENANT: "tenants.delete_tenant",
    MANAGE_TENANT: "tenants.manage_tenant",

    //Tenant User Group
    VIEW_TENANT_USER_GROUP: "tenants.view_tenantusergroup",
    CREATE_TENANT_USER_GROUP: "tenants.add_tenantusergroup",
    EDIT_TENANT_USER_GROUP: "tenants.change_tenantusergroup",
    DELETE_TENANT_USER_GROUP: "tenants.delete_tenantusergroup",
    MANAGE_TENANT_USER_GROUP: "tenants.manage_tenantusergroup",

    //Tenant Location
    VIEW_TENANT_LOCATION: "tenants.view_tenantlocation",
    CREATE_TENANT_LOCATION: "tenants.add_tenantlocation",
    EDIT_TENANT_LOCATION: "tenants.change_tenantlocation",
    DELETE_TENANT_LOCATION: "tenants.delete_tenantlocation",
    MANAGE_TENANT_LOCATION: "tenants.manage_tenantlocation",

    //Tenant Partner
    VIEW_TENANT_PARTNER: "tenants.view_tenantpartner",
    CREATE_TENANT_PARTNER: "tenants.add_tenantpartner",
    EDIT_TENANT_PARTNER: "tenants.change_tenantpartner",
    DELETE_TENANT_PARTNER: "tenants.delete_tenantpartner",
    MANAGE_TENANT_PARTNER: "tenants.manage_tenantpartner",

    // Tenant User
    VIEW_TENANT_USER: "tenants.view_tenantuser",
    CREATE_TENANT_USER: "tenants.add_tenantuser",
    EDIT_TENANT_USER: "tenants.change_tenantuser",
    DELETE_TENANT_USER: "tenants.delete_tenantuser",
    MANAGE_TENANT_USER: "tenants.manage_tenantuser",

    // Tenant Partner User
    VIEW_TENANT_PARTNER_USER: "tenants.view_tenantpartneruser",
    CREATE_TENANT_PARTNER_USER: "tenants.add_tenantpartneruser",
    EDIT_TENANT_PARTNER_USER: "tenants.change_tenantpartneruser",
    DELETE_TENANT_PARTNER_USER: "tenants.delete_tenantpartneruser",
    MANAGE_TENANT_PARTNER_USER: "tenants.manage_tenantpartneruser",

    // Tenant User Group Partner
    VIEW_TENANT_USER_GROUP_PARTNER: "tenants.view_tenantusergrouppartner",
    CREATE_TENANT_USER_GROUP_PARTNER: "tenants.add_tenantusergrouppartner",
    EDIT_TENANT_USER_GROUP_PARTNER: "tenants.change_tenantusergrouppartner",
    DELETE_TENANT_USER_GROUP_PARTNER: "tenants.delete_tenantusergrouppartner",
    MANAGE_TENANT_USER_GROUP_PARTNER: "tenants.manage_tenantusergrouppartner",

    // Tenant Action Groups
    VIEW_TENANT_ACTION_GROUP: "tenants.view_tenantactiongroup",
    CREATE_TENANT_ACTION_GROUP: "tenants.add_tenantactiongroup",
    EDIT_TENANT_ACTION_GROUP: "tenants.change_tenantactiongroup",
    DELETE_TENANT_ACTION_GROUP: "tenants.delete_tenantactiongroup",
    MANAGE_TENANT_ACTION_GROUP: "tenants.manage_tenantactiongroup",

    // Tenant Alert Rules
    VIEW_TENANT_ALERT_RULE: "tenants.view_tenantalertrule",
    CREATE_TENANT_ALERT_RULE: "tenants.add_tenantalertrule",
    EDIT_TENANT_ALERT_RULE: "tenants.change_tenantalertrule",
    DELETE_TENANT_ALERT_RULE: "tenants.delete_tenantalertrule",
    MANAGE_TENANT_ALERT_RULE: "tenants.manage_tenantalertrule",

    // Tenant Dashboard
    VIEW_TENANT_DASHBOARD: "tenants.view_tenantdashboard",

    // Tenant Settings
    VIEW_TENANT_SETTINGS: "tenants.view_tenantsettings",
    EDIT_TENANT_SETTINGS: "tenants.change_tenantsettings",
    CREATE_TENANT_SETTINGS: "tenants.add_tenantsettings",
    DELETE_TENANT_SETTINGS: "tenants.delete_tenantsettings",

    // Tenant Location Weather Forecast
    VIEW_TENANT_LOCATION_WEATHER_FORECAST: "tenants.view_tenantlocationweatherforecast",
    CREATE_TENANT_LOCATION_WEATHER_FORECAST: "tenants.add_tenantlocationweatherforecast",
    EDIT_TENANT_LOCATION_WEATHER_FORECAST: "tenants.change_tenantlocationweatherforecast",
    DELETE_TENANT_LOCATION_WEATHER_FORECAST: "tenants.delete_tenantlocationweatherforecast",
    MANAGE_TENANT_LOCATION_WEATHER_FORECAST: "tenants.manage_tenantlocationweatherforecast",
});

export default TenantPermissionsEnum;
