<template>
    <v-hover>
        <template>
            <v-card
                v-resize="onResize"
                class="py-4 mt-4"
                elevation="5"
                outlined
            >
                <v-row align="start" class="px-5 d-flex align-center" dense>
                    <v-col
                        cols="5"
                        sm="5"
                        lg="5"
                        v-for="f in dynamicFilters"
                        :key="f"
                        :class="getFilterChoiceClassByCode(f)"
                    >
                        <v-text-field
                            v-if="f === 'referenceNumber'"
                            v-model="filter.referenceNumberIcontains"
                            :label="$t('headers.reference_number')"
                            clearable
                            hide-details
                            append-icon="search"
                            dense
                            class="subtitle-2"
                        ></v-text-field>
                    </v-col>

                    <!-- !Sort By -->
                    <v-col class="col-auto col-md-4 col-lg-3 col-xl-3">
                        <v-row class="px-5 d-flex align-center">
                            <v-select
                                v-model="sortBy"
                                :items="sortByChoices"
                                :label="$t('general.sort_by')"
                                :item-value="item => item.code"
                                :item-text="item => item.name"
                                clearable
                                hide-details
                                dense
                                color="primary"
                            >
                                <template v-slot:append-outer>
                                    <v-btn
                                        class="mt-n1"
                                        small
                                        icon
                                        :disabled="!sortBy"
                                        @click="sortAsc = !sortAsc"
                                    >
                                        <v-icon color="grey darken-2">{{
                                            sortAsc ? "north" : "south"
                                        }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-select>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import helper from "@/utils/helper.js";
// import FilterMenu from "@/components/base/FilterMenu.vue";

export default {
    props: {
        partner: {
            type: Object,
            required: true
        }
    },
    // components: { FilterMenu },
    apollo: {},

    data() {
        return {
            openAddPartnerDialog: false,

            sortAsc: true,

            isLoading: false,
            fab: false,
            menu: false,
            shouldStickyFilter: false,

            // --------------------> FILTERS <----------------------- \\
            defaultFilterChoices: [
                {
                    code: "referenceNumber",
                    name: this.$t("headers.reference_number"),
                    class: "cols=12 sm=5 lg=5",
                    enabled: true,
                    order: 0
                }
            ],

            filterChoices: null,

            // graphql query filter
            filter: {
                first: 15,
                after: null,
                orderBy: null,
                referenceNumberIcontains: null,
                tenantPartnerId: [this.partner.id]
            },

            // sortByChoices: [
            //     {
            //         code: "referenceNumber",
            //         name: this.$t("headers.reference_number")
            //     },
            //     {
            //         code: "status",
            //         name: this.$t("headers.status")
            //     },
            //     {
            //         code: "plannedDepartureDate",
            //         name: this.$t("headers.planned_departure")
            //     },
            //     {
            //         code: "plannedArrivalDate",
            //         name: this.$t("headers.planned_arrival")
            //     }
            // ],

            sortBy: null
        };
    },

    computed: {
        dynamicFilters() {
            return this.filterChoices.filter(f => f.enabled).map(f => f.code);
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "referenceNumber",
                    name: this.$t("headers.reference_number")
                },
                {
                    code: "status",
                    name: this.$t("headers.status")
                },
                {
                    code: "plannedDepartureDate",
                    name: this.$t("headers.planned_departure")
                },
                {
                    code: "plannedArrivalDate",
                    name: this.$t("headers.planned_arrival")
                }
            ];
            return _sortByChoices;
        }
    },
    watch: {
        filter: {
            handler(value) {
                if (value) {
                    this.$emit(
                        "filter-changed",
                        JSON.parse(JSON.stringify(value))
                    );
                }
            },
            deep: true
        },
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(
            this.defaultFilterChoices,
            "partnerTripsSearchTableFilters"
        );
        this.me = helper.me();
    },
    mounted() {
        this.sortBy = "plannedDepartureDate";
        this.onResize();

        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    partnerTripsSearchTableFilters: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        // dynamic filter
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        getFilterChoiceNameByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.name;
        },

        onFilterMenuChanged(newChoises) {
            this.filterChoices = newChoises;
        }
    }
};
</script>
