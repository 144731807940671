<template>
    <v-container fluid>
        <v-card class="mb-6 mt-2 rounded-b-xl rounded-t-xl" elevation="5">
            <v-row dense>
                <v-col cols="12">
                    <v-card flat class="rounded-t-xl">
                        <v-card-text>
                            <v-row dense class="px-3">
                                <v-col cols="3">
                                    <div
                                        v-if="alertRule.alertSeverity"
                                        class="font-weight-medium text-capitalize"
                                    >
                                        {{
                                            alertChoicesTitle(
                                                alertRule.alertSeverity
                                            )
                                        }}
                                    </div>
                                    <div v-else>
                                        <span>-</span>
                                    </div>
                                    <div class="caption">
                                        {{ $t("alert_rules.alert_severity") }}
                                    </div>
                                </v-col>
                                <v-col cols="3">
                                    <div
                                        v-if="alertRule.tag"
                                        class="font-weight-medium"
                                    >
                                        {{alertTags(alertRule.tag)}}
                                    </div>
                                    <div v-else>
                                        <span>-</span>
                                    </div>
                                    <div class="caption">
                                        {{ $t("alert_rules.tag") }}
                                    </div>
                                </v-col>
                                <v-col cols="6">
                                    <div
                                        v-if="alertRule.description"
                                        class="font-weight-medium text-capitalize"
                                    >
                                        {{ alertRule.description }}
                                    </div>
                                    <div v-else>
                                        <span>-</span>
                                    </div>
                                    <div class="caption">
                                        {{ $t("headers.alert_description") }}
                                    </div>
                                </v-col>
                                
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" class="pb-0">
                    <v-expansion-panels
                        v-model="panel"
                        multiple
                        accordion
                        class="rounded-b-xl"
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-icon class="mr-2">notifications</v-icon>
                                    <span class="mt-1">{{
                                        $t("headers.alerts")
                                    }}</span>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="py-0">
                                <AlertRuleTriggers
                                    :alertRule="alertRule"
                                ></AlertRuleTriggers>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row class="">
                                    <v-icon class="mr-2">group</v-icon>
                                    <span class="mt-1">{{
                                        $t("home.action_groups")
                                    }}</span>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <LoadingBar
                                    v-if="$apollo.queries.actionGroups.loading"
                                ></LoadingBar>
                                <v-card v-else class="py-3" flat>
                                    <v-row dense class="px-2">
                                        <v-col
                                            cols="3"
                                            v-for="group in groups"
                                            :key="group.node.id"
                                        >
                                            <!-- <v-chip class="mr-3 pr-3">{{ group.node.tenantActionGroup.name  }}</v-chip> -->
                                            <v-card
                                                class="d-flex py-4 px-3 align-center "
                                            >
                                                <v-row dense>
                                                    <v-col
                                                        cols="12"
                                                        class=" subtitle-1"
                                                    >
                                                        <span
                                                            >{{
                                                                group.node
                                                                    .tenantActionGroup
                                                                    .name
                                                            }}
                                                        </span>
                                                    </v-col>

                                                    <v-col cols="3">
                                                        <v-badge
                                                            bordered
                                                            bottom
                                                            color="primary"
                                                            :content="
                                                                group.node
                                                                    .tenantActionGroup
                                                                    .tenantActionGroupUserSet
                                                                    .totalCount >
                                                                0
                                                                    ? group.node
                                                                          .tenantActionGroup
                                                                          .tenantActionGroupUserSet
                                                                          .totalCount
                                                                    : '0'
                                                            "
                                                            overlap
                                                            offset-x="10"
                                                            offset-y="10"
                                                        >
                                                            <v-tooltip bottom>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        v-bind="
                                                                            attrs
                                                                        "
                                                                        v-on="
                                                                            on
                                                                        "
                                                                    >
                                                                        person
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{
                                                                    $t(
                                                                        "home.users"
                                                                    )
                                                                }}</span>
                                                            </v-tooltip>
                                                        </v-badge>
                                                        <!--  <span>{{ group.node.tenantActionGroup.tenantActionGroupUserSet.totalCount }}</span> -->
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-badge
                                                            bordered
                                                            bottom
                                                            color="primary"
                                                            :content="
                                                                convertString(
                                                                    group.node
                                                                        .tenantActionGroup
                                                                        .externalEmails
                                                                ).length > 0
                                                                    ? convertString(
                                                                          group
                                                                              .node
                                                                              .tenantActionGroup
                                                                              .externalEmails
                                                                      ).length
                                                                    : '0'
                                                            "
                                                            overlap
                                                            offset-x="10"
                                                            offset-y="10"
                                                        >
                                                            <v-tooltip bottom>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        v-bind="
                                                                            attrs
                                                                        "
                                                                        v-on="
                                                                            on
                                                                        "
                                                                    >
                                                                        email
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{
                                                                    $t(
                                                                        "general.external_emails"
                                                                    )
                                                                }}</span>
                                                            </v-tooltip>
                                                        </v-badge>
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-badge
                                                            bordered
                                                            bottom
                                                            :color="
                                                                group.node
                                                                    .tenantActionGroup
                                                                    .shouldEmailNotification
                                                                    ? 'green'
                                                                    : 'red lighten-3'
                                                            "
                                                            :icon="
                                                                group.node
                                                                    .tenantActionGroup
                                                                    .shouldEmailNotification
                                                                    ? 'done'
                                                                    : 'close'
                                                            "
                                                            overlap
                                                            offset-x="10"
                                                            offset-y="10"
                                                        >
                                                            <v-tooltip bottom>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        v-bind="
                                                                            attrs
                                                                        "
                                                                        v-on="
                                                                            on
                                                                        "
                                                                    >
                                                                        warning
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{
                                                                    $t(
                                                                        "general.email_notification"
                                                                    )
                                                                }}</span>
                                                            </v-tooltip>
                                                        </v-badge>
                                                        <!-- v-icon :color="group.node.tenantActionGroup.shouldEmailNotification ? 'green': 'grey'" >warning</v-icon> -->
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-badge
                                                            bordered
                                                            bottom
                                                            :color="
                                                                group.node
                                                                    .tenantActionGroup
                                                                    .shouldInboxNotification
                                                                    ? 'green'
                                                                    : 'red lighten-3'
                                                            "
                                                            :icon="
                                                                group.node
                                                                    .tenantActionGroup
                                                                    .shouldInboxNotification
                                                                    ? 'done'
                                                                    : 'close'
                                                            "
                                                            overlap
                                                            offset-x="10"
                                                            offset-y="10"
                                                        >
                                                            <v-tooltip bottom>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        v-bind="
                                                                            attrs
                                                                        "
                                                                        v-on="
                                                                            on
                                                                        "
                                                                    >
                                                                        notifications
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{
                                                                    $t(
                                                                        "general.inbox_notification"
                                                                    )
                                                                }}</span>
                                                            </v-tooltip>
                                                        </v-badge>
                                                        <!-- <v-icon :color="group.node.tenantActionGroup.shouldInboxNotification ? 'green': 'grey'">notifications</v-icon> -->
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="validAutomateStep">
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-icon class="mr-2">alarm_on</v-icon>
                                    <span class="mt-1">{{
                                        $t("alert_rules.alert_automation")
                                    }}</span>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="py-0">
                                <v-row dense class="px-2">
                                    <v-col
                                        cols="auto"
                                        v-if="alertRule.applyToAllNewTrips"
                                    >
                                        <v-card class="py-5 px-3">
                                            <div class="d-flex flex-row">
                                                <div class="mr-2">
                                                    <span>{{
                                                        $t(
                                                            "alert_rules.apply_trips"
                                                        )
                                                    }}</span>
                                                </div>
                                                <v-spacer></v-spacer>
                                                <v-badge
                                                    bordered
                                                    bottom
                                                    :color="
                                                        alertRule.applyToAllNewTrips
                                                            ? 'green'
                                                            : 'red lighten-3'
                                                    "
                                                    :icon="
                                                        alertRule.applyToAllNewTrips
                                                            ? 'done'
                                                            : 'close'
                                                    "
                                                    overlap
                                                    offset-x="10"
                                                    offset-y="10"
                                                    class="mr-2"
                                                >
                                                    <v-tooltip bottom>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                notifications
                                                            </v-icon>
                                                        </template>
                                                        <span>{{
                                                            $t(
                                                                "alert_rules.notify_enable"
                                                            )
                                                        }}</span>
                                                    </v-tooltip>
                                                </v-badge>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="auto" v-if="validPartners">
                                        <v-card class="py-5 px-3">
                                            <div class="d-flex flex-row">
                                                <div class="mr-2">
                                                    <span>{{
                                                        $t(
                                                            "alert_rules.apply_new"
                                                        )
                                                    }}</span>
                                                </div>
                                                <v-spacer></v-spacer>
                                                <v-badge
                                                    bordered
                                                    bottom
                                                    :color="
                                                        validPartners
                                                            ? 'green'
                                                            : 'red lighten-3'
                                                    "
                                                    :icon="
                                                        validPartners
                                                            ? 'done'
                                                            : 'close'
                                                    "
                                                    overlap
                                                    offset-x="10"
                                                    offset-y="10"
                                                    class="mr-2"
                                                >
                                                    <v-tooltip bottom>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                notifications
                                                            </v-icon>
                                                        </template>
                                                        <span>{{
                                                            $t(
                                                                "alert_rules.notify_enable"
                                                            )
                                                        }}</span>
                                                    </v-tooltip>
                                                </v-badge>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row dense class="my-2">
                                            <v-col
                                                cols="auto"
                                                v-if="
                                                    validArray(
                                                        automate.carrier
                                                    ) && validPartners
                                                "
                                                class="py-0"
                                            >
                                                <v-card
                                                    elevation="0"
                                                    class="py-1 "
                                                >
                                                    <div class="px-3">
                                                        <span>{{
                                                            $t("home.carriers")
                                                        }}</span>
                                                    </div>
                                                    <div
                                                        class="d-flex flex-column"
                                                    >
                                                        <v-chip
                                                            v-for="(carrier,
                                                            index) in automate.carrier"
                                                            :key="index"
                                                            class="ma-1"
                                                            color=""
                                                            text-color="white"
                                                            small
                                                        >
                                                            {{ carrier.name }}
                                                        </v-chip>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col
                                                cols="auto"
                                                v-if="
                                                    validArray(
                                                        automate.consignor
                                                    ) && validPartners
                                                "
                                                class="py-0"
                                            >
                                                <v-card
                                                    elevation="0"
                                                    class="py-1"
                                                >
                                                    <div class="px-3">
                                                        <span>
                                                            {{
                                                                $t(
                                                                    "headers.consignor"
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="d-flex flex-column"
                                                    >
                                                        <v-chip
                                                            v-for="(consignor,
                                                            index) in automate.consignor"
                                                            :key="index"
                                                            class="ma-1"
                                                            color=""
                                                            text-color="white"
                                                            small
                                                        >
                                                            {{ consignor.name }}
                                                        </v-chip>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col
                                                cols="auto"
                                                v-if="
                                                    validArray(
                                                        automate.forwarder
                                                    ) && validPartners
                                                "
                                                class="py-0"
                                            >
                                                <v-card
                                                    elevation="0"
                                                    class="py-1"
                                                >
                                                    <div class="px-3">
                                                        {{
                                                            $t(
                                                                "headers.forwarder"
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="d-flex flex-column"
                                                    >
                                                        <v-chip
                                                            v-for="(forwarder,
                                                            index) in automate.forwarder"
                                                            :key="index"
                                                            class="ma-1"
                                                            color=""
                                                            text-color="white"
                                                            small
                                                        >
                                                            {{ forwarder.name }}
                                                        </v-chip>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col
                                                cols="auto"
                                                class="py-0"
                                                v-if="
                                                    validArray(
                                                        automate.consignee
                                                    ) && validPartners
                                                "
                                            >
                                                <v-card
                                                    elevation="0"
                                                    class="py-1"
                                                >
                                                    <div class="px-3">
                                                        {{
                                                            $t(
                                                                "headers.consignee"
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="d-flex flex-column"
                                                    >
                                                        <v-chip
                                                            v-for="(consignee,
                                                            index) in automate.consignee"
                                                            :key="index"
                                                            class="ma-1"
                                                            color=""
                                                            text-color="white"
                                                            small
                                                        >
                                                            {{ consignee.name }}
                                                        </v-chip>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col
                                                cols="auto"
                                                v-if="
                                                    validArray(
                                                        automate.other
                                                    ) && validPartners
                                                "
                                                class="py-0"
                                            >
                                                <v-card
                                                    elevation="0"
                                                    class="py-1"
                                                >
                                                    <div class="px-2">
                                                        {{$t('home.others')}}
                                                    </div>
                                                    <div
                                                        class="d-flex flex-column"
                                                    >
                                                        <v-chip
                                                            v-for="(other,
                                                            index) in automate.other"
                                                            :key="index"
                                                            class="ma-1"
                                                            color=""
                                                            text-color="white"
                                                            small
                                                        >
                                                            {{ other.name }}
                                                        </v-chip>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import AlertRuleTriggers from "@/components/alertrules/details/AlertRuleTriggers.vue";
//import AlertRuleActionGroups from "@/components/alertrules/details/AlertRuleActionGroups.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";
import gql from "graphql-tag";

export default {
    props: {
        alertRule: {
            type: Object,
            required: true
        }
    },
    apollo: {
        actionGroups: {
            query: gql`
                query TenantAlertRuleActionGroups(
                    $tenantAlertRuleId: ID!
                    $first: Int
                    $search: String
                ) {
                    tenantAlertRuleActionGroups(
                        tenantAlertRuleId: $tenantAlertRuleId
                        first: $first
                        search: $search
                    ) {
                        edges {
                            node {
                                id
                                tenantActionGroup {
                                    id
                                    name
                                    externalEmails
                                    shouldEmailNotification
                                    shouldInboxNotification
                                    tenantActionGroupUserSet {
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantAlertRuleId: this.alertRule.id,
                    first: 20,
                    search: this.searchInput
                };
            },
            fetchPolicy: "network-only",
            update: data => {
                return data.tenantAlertRuleActionGroups;
            },
            skip: false
        },
        getTenantPartners: {
            query: gql`
                query getTenantPartners(
                    $orderBy: String
                    $idIn: [String]
                    $first: Int
                ) {
                    getTenantPartners(
                        orderBy: $orderBy
                        id_In: $idIn
                        first: $first
                    ) {
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name",
                    idIn: this.partnerListIds,
                    first: 80
                };
            },
            fetchPolicy: "no-cache",
            update: data => data.getTenantPartners,
            result({ data }) {
                let _partners = {
                    consignee: [],
                    consignor: [],
                    carrier: [],
                    forwarder: [],
                    other: []
                };

                data.getTenantPartners.edges.forEach(edge => {
                    _partners[edge.node.partnerType].push(edge.node);
                });

                for (let key in _partners) {
                    if (_partners[key].length == 0) {
                        delete _partners[key];
                    }
                }

                this.automate = _partners;
            },
            skip() {
                return !(this.partnerListIds.length > 0);
            }
        }
    },
    components: {
        AlertRuleTriggers,
        LoadingBar
    },
    data() {
        return {
            panel: [0],
            automate: {}
        };
    },
    computed: {
        groups() {
            //filter the alertrule.actionsgroups to only include the ones that are in the actionGroups
            let _actionGroups = this.actionGroups.edges.filter(
                group => group.node
            );
            return _actionGroups;
        },
        validAutomateStep() {
            if (
                this.alertRule.applyToAllNewTrips ||
                this.convertString(this.alertRule.applyToCarriers).length > 0 ||
                this.convertString(this.alertRule.applyToConsignors).length >
                    0 ||
                this.convertString(this.alertRule.applyToForwarders).length >
                    0 ||
                this.convertString(this.alertRule.applyToConsignees).length >
                    0 ||
                this.convertString(this.alertRule.applyToOthers).length > 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        validPartners() {
            if (
                (!this.alertRule.applyToAllNewTrips &&
                    this.convertString(this.alertRule.applyToCarriers).length >
                        0) ||
                this.convertString(this.alertRule.applyToConsignors).length >
                    0 ||
                this.convertString(this.alertRule.applyToForwarders).length >
                    0 ||
                this.convertString(this.alertRule.applyToConsignees).length >
                    0 ||
                this.convertString(this.alertRule.applyToOthers).length > 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        partnerListIds() {
            let _partners = [];
            if (this.alertRule.applyToCarriers) {
                _partners = _partners.concat(
                    this.convertString(this.alertRule.applyToCarriers)
                );
            }
            if (this.alertRule.applyToConsignors) {
                _partners = _partners.concat(
                    this.convertString(this.alertRule.applyToConsignors)
                );
            }
            if (this.alertRule.applyToForwarders) {
                _partners = _partners.concat(
                    this.convertString(this.alertRule.applyToForwarders)
                );
            }
            if (this.alertRule.applyToConsignees) {
                _partners = _partners.concat(
                    this.convertString(this.alertRule.applyToConsignees)
                );
            }
            if (this.alertRule.applyToOthers) {
                _partners = _partners.concat(
                    this.convertString(this.alertRule.applyToOthers)
                );
            }
            return _partners;
        }
    },
    watch: {
        validPartners(val) {
            if (!val) {
                this.automate = {};
            }
        }
    },
    methods: {
        convertString(array) {
            let _array = [];
            if (array?.length > 0 && Array.isArray(array) === false) {
                _array = JSON.parse(array.replace(/'/g, '"')).map(str => str);
            }
            return _array || [];
        },
        alertChoicesTitle(alert) {
            let choices = [
                { text: this.$t("general.informational"), value: "info" },
                { text: this.$t("general.warning"), value: "warning" },
                { text: this.$t("general.critical"), value: "critical" }
            ];

            if (alert !== null) {
                let choice = choices.find(
                    choice => choice.value === alert.toLowerCase()
                );
                return choice.text;
            } else {
                return "";
            }
        },
        validArray(array) {
            if (array === null || array === undefined) {
                return false;
            } else if (Array.isArray(array) && array.length > 0) {
                return true;
            }
        },
        alertTags(tag) {
            let choices = "";
            if (tag === null) {
                return "";
            }

            switch (tag) {
                case "temperature":
                    choices = "Temperature";
                    break;
                case "light":
                    choices = "Light";
                    break;
                case "humidity":
                    choices="Humidity"
                    break;
                case "pressure":
                    choices = "Pressure";
                    break;
                case "temperature_humidity":
                    choices = "Temperature and Humidity";
                    break;
                case "temperature_humidity_light":
                    choices = "Temperature, Humidity and Light";
                    break;
            }
            return choices;
        },
    }
};
</script>
