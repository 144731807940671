<template>
    <v-container fluid v-scroll="handleScroll">
        <v-row align="center" class="d-flex justify-center align-center">
            <v-col md="10" cols="12">
                <v-toolbar flat color="transparent">
                    <v-btn icon @click="cancelTrip">
                        <v-icon>arrow_back_ios_new</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-col>
            <v-col cols="10">
                <v-card class="d-flex align-end" height="40" color="transparent" flat>
                    <div class="textTitle">
                        {{ viewTitle }}
                    </div>
                </v-card>
            </v-col>
            <v-col cols="10">
                <v-tabs v-model="tab" background-color="transparent">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab v-for="tab in getTabs" :key="tab.id">
                        <template v-slot:default>
                            <div class="text-capitalize ">
                                {{ tab.name }}
                            </div>
                        </template>
                    </v-tab>
                </v-tabs>
                <v-divider></v-divider>
                <v-tabs-items v-model="tab" style="background-color: transparent;">
                    <v-tab-item>
                        <div v-if="templateMode && !canCreateTemplate">
                            <NoPermission></NoPermission>
                        </div>
                        <v-card flat v-else color="transparent" ref="mainCard">
                            <v-alert v-if="error" tile type="error" class="mb-0">{{ error }}</v-alert>
                            <v-card-text class="px-0 mt-1">
                                <v-stepper v-model="stepNo" style="background-color: transparent;" flat>
                                    <v-stepper-header class="elevation-0 px-1">
                                        <v-stepper-step
                                            :complete="stepNo > 1"
                                            step="1"
                                            editable
                                            class="outLined stepperContent mr-6 mt-5"
                                        >
                                            <span class="primary--text">
                                                {{ $t("trips.trip_info") }}
                                            </span>
                                        </v-stepper-step>
                                        <v-divider></v-divider>
                                        <v-stepper-step
                                            :complete="stepNo > 2"
                                            step="2"
                                            :editable="!disabledStep1"
                                            class="outLined stepperContent ml-6 mt-5"
                                        >
                                            {{ $t("trips.freight_info") }}
                                        </v-stepper-step>
                                    </v-stepper-header>
                                    <v-stepper-items class="px-0">
                                        <v-stepper-content step="1" class="pt-1 px-0">
                                            <v-card color="transparent" flat>
                                                <v-card-text class="px-0">
                                                    <v-row dense>
                                                        <v-col cols="12">
                                                            <PreviewTripLocationForm
                                                                ref="tripLocation"
                                                                v-model="location"
                                                                :transportMode="location.transportMode"
                                                                :validate.sync="validateTripLocation"
                                                                :templateMode="templateMode"
                                                                v-on:createLocation="saveBeforeNavigate"
                                                            >
                                                                <template v-slot:templateSlot>
                                                                    <div>
                                                                        <v-autocomplete
                                                                            ref="templateAutocomplete"
                                                                            v-if="showCreateFromTemplate"
                                                                            v-model="templateSelected"
                                                                            :loading="
                                                                                $apollo.queries.tripTemplates.loading
                                                                            "
                                                                            :disabled="
                                                                                $apollo.queries.tripTemplates.loading
                                                                            "
                                                                            :items="autocompleteItems"
                                                                            :no-data-text="$t('loading.no_available')"
                                                                            item-text="name"
                                                                            return-object
                                                                            hide-details
                                                                            dense
                                                                            outlined
                                                                            clearable
                                                                            :label="
                                                                                $t('templates.create_from_template')
                                                                            "
                                                                            @change="populateTrip"
                                                                        >
                                                                        </v-autocomplete>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:alertSlot>
                                                                    <div>
                                                                        <span
                                                                            v-if="!isValidTransport"
                                                                            class="red--text caption"
                                                                        >
                                                                            {{
                                                                                isValidTransport
                                                                                    ? ""
                                                                                    : $t("rules.required")
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </template>
                                                            </PreviewTripLocationForm>
                                                        </v-col>
                                                        <v-col cols="12" v-if="location.transportMode">
                                                            <PreviewTripForm
                                                                ref="tripCard"
                                                                v-model="validateTripStep"
                                                                :getTrip.sync="trip"
                                                                :transportMode="location.transportMode"
                                                                :isSeaTransport.sync="isSeaTransport"
                                                                :editMode="editMode"
                                                                :templateMode="templateMode"
                                                                v-on:createPartner="saveBeforeNavigate"
                                                            >
                                                            </PreviewTripForm>
                                                        </v-col>
                                                        <v-col
                                                            v-if="location.transportMode.toLowerCase() === 'sea'"
                                                            cols="12"
                                                        >
                                                            <PreviewTripSeaTransportForm
                                                                ref="seaTransportForm"
                                                                v-model="seaLoadPoint"
                                                                :tripSeaTransport.sync="seaTransport"
                                                                :isSeaTransport="isSeaTransport"
                                                                :tripContainers.sync="tripContainers"
                                                                :tripPartnerCarrierId.sync="tripPartnerCarrierId"
                                                                :templateMode="templateMode"
                                                                :editMode="editMode"
                                                            ></PreviewTripSeaTransportForm>
                                                        </v-col>
                                                        <v-col cols="12" v-if="location.transportMode">
                                                            <PreviewTripAdvanceInfoForm
                                                                ref="partnerList"
                                                                :getPartners.sync="partners"
                                                                :partnersOnBoard="[]"
                                                                :dense="true"
                                                                v-on:createPartner="saveBeforeNavigate"
                                                            >
                                                                <template v-slot:saveTemplateSlot>
                                                                    <!-- <div>
                                                                        <v-checkbox
                                                                            v-model="templateIsSave"
                                                                            class="inputContent"
                                                                            label="Create template from the trip"
                                                                            @change="openTemplate()"
                                                                        ></v-checkbox>
                                                                    </div> -->
                                                                </template>
                                                            </PreviewTripAdvanceInfoForm>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-stepper-content>
                                        <v-stepper-content step="2" class="px-0">
                                            <v-card color="transparent" flat>
                                                <v-card-text class="px-0">
                                                    <v-row dense>
                                                        <v-col cols="12" v-if="freights">
                                                            <PreviewTripFreightForm
                                                                ref="freightTracker"
                                                                :trip="location"
                                                                :validate.sync="validateFreights"
                                                                v-model="freights"
                                                                :currentTrackers="currentTrackers"
                                                                :templateMode="templateMode"
                                                                :plannedDepartureDate="location.plannedDepartureDate"
                                                                :editMode="editMode"
                                                                v-on:createLocation="saveBeforeNavigate"
                                                            ></PreviewTripFreightForm>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-card-text>
                            <v-card-actions
                                ref="overlapCard"
                                v-show="!isCardHidden && location.transportMode"
                                class="fixed-bottom-card containerFooter px-8 outlineD "
                                :style="`width: ${parentWidth}px`"
                            >
                                <v-btn text @click="cancelTrip" outlined>
                                    {{ $t("general.discard") }}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn v-if="stepNo == 2" outlined color="primary" text @click="goBack()">{{
                                    $t("general.back")
                                }}</v-btn>
                                <v-btn
                                    v-if="stepNo == 1"
                                    elevation="0"
                                    color="primary"
                                    @click="stepNo = 2"
                                    :disabled="disabledStep1"
                                >
                                    {{ $t("general.next") }}
                                </v-btn>
                                <v-btn
                                    v-if="stepNo == 2"
                                    elevation="0"
                                    color="primary"
                                    :disabled="disabledStep2"
                                    @click="generalSave()"
                                    :loading="isSaving"
                                >
                                    {{ $t("general.save") }}
                                </v-btn>
                            </v-card-actions>
                            <v-card-actions ref="card" class="card-container rounded-lg px-8">
                                <v-btn text @click="cancelTrip" outlined>
                                    {{ $t("general.discard") }}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn v-if="stepNo == 2" outlined color="primary" text @click="goBack()">{{
                                    $t("general.back")
                                }}</v-btn>
                                <v-btn
                                    v-if="stepNo == 1"
                                    elevation="0"
                                    color="primary"
                                    @click="stepNo = 2"
                                    :disabled="disabledStep1"
                                >
                                    {{ $t("general.next") }}
                                </v-btn>
                                <v-btn
                                    v-if="stepNo == 2"
                                    elevation="0"
                                    color="primary"
                                    :disabled="disabledStep2"
                                    @click="generalSave()"
                                    :loading="isSaving"
                                >
                                    {{ $t("general.save") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                        <NameDescriptionDialog
                            ref="nameDescriptionDialog"
                            :openDialog="openNameDescriptionDialog"
                            :editMode="editMode"
                            :templateObj.sync="templateNameDescription"
                            @closeDialog="closeNameDescriptionDialog"
                            @saveNameDescription="saveNameDescription"
                        >
                        </NameDescriptionDialog>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row justify="center">
                            <v-col cols="12">
                                <v-card height="300px" class="d-flex justify-center align-center">
                                    <div>
                                        {{ $t("general.coming") }}
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import PreviewTripForm from "@/components/trips/forms/PreviewTripForm.vue";
import PreviewTripSeaTransportForm from "@/components/trips/forms/PreviewTripSeaTransportForm.vue";
import PreviewTripLocationForm from "@/components/trips/forms/PreviewTripLocationForm.vue";
import PreviewTripAdvanceInfoForm from "@/components/trips/forms/PreviewTripAdvanceInfoForm.vue";
import PreviewTripFreightForm from "@/components/trips/forms/PreviewTripFreightForm.vue";
import NameDescriptionDialog from "../../triptemplates/create/NameDescriptionDialog.vue";

export default {
    components: {
        PreviewTripForm,
        PreviewTripLocationForm,
        PreviewTripSeaTransportForm,
        PreviewTripAdvanceInfoForm,
        PreviewTripFreightForm,
        NameDescriptionDialog
    },
    props: {},
    apollo: {
        tripTemplates: {
            query: gql`
                query tripTemplates($orderBy: String) {
                    tripTemplates(orderBy: $orderBy) {
                        edges {
                            node {
                                id
                                lockTemplate
                                name
                                tripJson
                                description
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "network-only",
            debounce: 800,
            update: response => response.tripTemplates,
            watchLoading(isLoading) {
                this.isLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            skip: false
        },
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "no-cache",
            update: data => data.tenantPartners,
            skip() {
                return false;
            }
        }
    },

    data() {
        return {
            tabs: [
                {
                    id: 1,
                    name: this.$t(`trips.create_trip_2`)
                },
                {
                    id: 2,
                    name: this.$t(`trips.create_trip_xls`)
                }
            ],
            isCardHidden: false,
            storeCardCoordinates: null,
            templateMode: false,
            templateIsSave: false,
            templateDialogActivated: false,
            tab: null,
            templateSelected: null,
            tripTemplates: { edges: [] },
            copyMode: false,
            openNameDescriptionDialog: false,
            templateName: null,
            templateDescription: null,
            templateNameValid: false,
            dialog: false,
            isSaving: false,
            invalid: false,
            tenantPartners: {},
            validateTripStep: false,
            validateFreights: false,
            validateTripLocation: false,
            validateAlert: false,
            dialogForm: false,
            tripContainers: [],
            tripPartnerCarrierId: null,
            currentTrackers: [],
            templateNameDescription: {
                name: null,
                description: null
            },
            trip: {
                id: null,
                referenceNumber: "",
                shipmentFileNumber: "",
                carrier: "",
                consignee: []
            },
            location: {
                origin: null,
                destination: null,
                transportMode: "",
                plannedDepartureDate: null,
                plannedDepartureTime: null,
                plannedArrivalDate: null,
                plannedArrivalTime: null,
                plannedDeparture: null
            },
            seaLoadPoint: {
                trade: ""
            },
            parentWidth: 0,
            editMode: false,
            seaTransport: [
                {
                    id: null,
                    sequence: 0,
                    voyage: "",
                    portCode: "",
                    portTransportType: "POL",
                    vesselInputType: "",
                    vesselInputValue: "",
                    estimatedDepartureDate: "",
                    originalEstimatedDepartureDate: "",
                    actualDepartureDate: null,
                    vgmCutOffDate: "",
                    bookingConfirmationNumber: "",
                    cutOffDateTime: ""
                },
                {
                    id: null,
                    sequence: 1,
                    portCode: "",
                    portTransportType: "POD",
                    originalEstimatedArrivalDate: "",
                    estimatedArrivalDate: "",
                    actualArrivalDate: null
                }
            ],
            partners: [],
            freights: [
                {
                    id: null,
                    referenceNumber: null,
                    containerNumber: null,
                    awb: null,
                    orderNumber: null,
                    billsOfLading: null,
                    goods: null,
                    term: null,
                    contact: "",
                    loadPoint: "",
                    loadDate: null,
                    trackers: [],
                    startDate: null,
                    startTime: null,
                    endDate: null,
                    endTime: null,
                    alerts: []
                }
            ],
            error: null,
            stepNo: 1,
            isSeaTransport: false,
            editable: false,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        getTabs() {
            if (this.editMode) {
                return [
                    {
                        id: 1,
                        name: this.$t(`trips.create_trip_2`)
                    }
                ];
            }
            return this.tabs;
        },
        screen() {
            return this.$vuetify.breakpoint.width;
        },
        disabledStep1() {
            let disabled = false;
            if (this.templateMode) {
                disabled = false;
            } else {
                disabled = !this.validateTripStep || !this.validateTripLocation || !this.isValidTransport;
            }

            return disabled;
        },
        disabledStep2() {
            let disabled = false;

            if (this.templateMode) {
                disabled = false;
            } else {
                disabled =
                    !this.validateFreights ||
                    !this.isValidTransport ||
                    !this.validateTripStep ||
                    !this.validateTripLocation;
            }

            return disabled;
        },
        validFreightsForm() {
            return this.validateFreights;
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        isValidTransport() {
            return (
                this.location.transportMode.toLowerCase() === "sea" ||
                this.location.transportMode.toLowerCase() === "air" ||
                this.location.transportMode.toLowerCase() === "road" ||
                this.location.transportMode.toLowerCase() === "rail"
            );
        },
        canCreateTemplate() {
            return true;
        },
        primaryColor() {
            let primary = this.$vuetify.theme.themes.light.primary;
            return primary;
        },
        autocompleteItems() {
            let items = [];
            this.tripTemplates.edges.forEach(edge => {
                let seatransport = JSON.parse(edge.node.tripJson);
                let goods = [];
                let terms = [];
                let loadPoint = [];
                seatransport.freights.forEach(freight => {
                    if (freight.goods != null && freight.goods != "") goods.push(freight.goods);
                    if (freight.term != null && freight.term != "") terms.push(freight.term);
                    if (freight.loadPoint != null && freight.loadPoint != "") loadPoint.push(freight.loadPoint);
                });
                let departureTime = seatransport.plannedDepartureTime;
                let arrivalTime = seatransport.plannedArrivalTime;
                let item = {
                    id: edge.node.id,
                    name: edge.node.name,
                    description: edge.node.description,
                    transportMode: seatransport.transportMode,
                    carrier: {
                        id: seatransport.carrier,
                        name: edge.node.carrier
                    },
                    origin: { id: seatransport.origin, name: edge.node.origin },
                    departureTime: departureTime,
                    destination: {
                        id: seatransport.destination,
                        name: edge.node.destination
                    },
                    arrivalTime: arrivalTime,
                    goods: goods,
                    terms: terms,
                    loadPoint: loadPoint,
                    partners: edge.node.partners,
                    partnersIds: seatransport.partners,
                    alertRules: edge.node.alertRules,
                    trade: seatransport.trade,
                    freights: seatransport.freights,
                    seaTransport: seatransport.seaTransports
                };
                items.push(item);
            });
            return items;
        },
        showCreateFromTemplate() {
            return !this.templateMode && !this.editMode && !this.copyMode;
        },
        viewTitle() {
            let title = "";
            if (this.editMode) {
                title = this.$t("trips.edit_trip");
            } else if (this.templateMode) {
                title = this.$t("templates.create_new_template");
            } else if (this.copyMode) {
                title = this.$t("trips.copy_trip");
            } else {
                title = this.$t("trips.create_trip");
            }
            return title;
        },
        snackMessage() {
            let message = "";
            if (this.editMode) {
                message = this.$t("general.trip_edited");
            } else if (this.templateMode && !this.editMode) {
                message = this.$t("general.template_created");
            } else if (this.templateMode && this.editMode) {
                message = this.$t("general.template_edited");
            } else if (this.copyMode) {
                message = this.$t("general.trip_copied");
            } else {
                message = this.$t("general.trip_created");
            }
            return message;
        },
        modeType() {
            let _mode = "create";
            if (this.$route.query?.type) {
                _mode = this.$route.query.type;
            }
            return _mode;
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    mounted() {
        this.$nextTick(() => {
            switch (this.modeType) {
                case "copy":
                    this.copyMode = true;
                    this.editMode = false;
                    this.templateMode = false;
                    this.edit();
                    break;
                case "edit":
                    this.editMode = true;
                    this.copyMode = false;
                    this.templateMode = false;
                    this.edit();
                    break;
                case "template":
                    this.templateMode = true;
                    this.copyMode = false;
                    this.editMode = false;
                    this.edit();
                    break;
                case "editTemplate":
                    this.templateMode = true;
                    this.editMode = true;
                    this.copyMode = false;
                    this.editTemplate();
                    break;
                default:
                    if (this.$route.query?.typeDetails === "tripDraft") {
                        this.edit();
                    }
                    break;
            }
            this.overlapWidthCard();
        });
    },
    watch: {
        screen() {
            this.overlapWidthCard();
        },
        openDialog: {
            immediate: true,
            handler: function(newVal) {
                this.dialog = newVal;
            }
        },
        trip: {
            handler: function(newVal) {
                if (newVal.carrier) {
                    this.tripPartnerCarrierId = newVal.carrier;
                }
                if (newVal.consignee) {
                    newVal.consignee.forEach(element => {
                        if (this.partners.indexOf(element) === -1) {
                            this.partners.push(element);
                        }
                    });
                }
            },
            deep: true
        },
        freights: {
            handler: function(newVal) {
                if (newVal.length !== 0) {
                    this.tripContainers = newVal.map(i => {
                        return i.containerNumber;
                    });
                }
            },
            deep: true
        },
        isSeaTransport: {
            immediate: true,
            handler: function(newVal) {
                if (newVal === false) {
                    this.tripSeaLoadPoint = {
                        trade: "",
                        logco: "",
                        loadPoint: "",
                        loadDate: null
                    };
                    this.seaTransport = [
                        {
                            id: null,
                            sequence: 0,
                            voyage: "",
                            portCode: "",
                            portTransportType: "POL",
                            vesselInputType: "",
                            vesselInputValue: "",
                            originalEstimatedDepartureDate: "",
                            estimatedDepartureDate: "",
                            actualDepartureDate: "",
                            vgmCutOffDate: "",
                            bookingConfirmationNumber: "",
                            cutOffDateTime: ""
                        },
                        {
                            id: null,
                            sequence: 1,
                            portCode: "",
                            portTransportType: "POD",
                            originalEstimatedArrivalDate: "",
                            estimatedArrivalDate: "",
                            actualArrivalDate: ""
                        }
                    ];
                }
            },
            deep: true
        }
    },
    methods: {
        saveBeforeNavigate(route) {
            /*This store trip info the user already fill in the vuex before navigate to other component (perform action to create location, partner)*/
            let _tripDraft = {
                id: this.trip.id,
                referenceNumber: this.trip.referenceNumber,
                shipmentFileNumber: this.trip.shipmentFileNumber,
                tenantCarrier: {
                    id: this.trip.carrier
                },
                transportMode: this.location.transportMode,
                originLocation: {
                    id: this.location.origin
                },
                destinationLocation: {
                    id: this.location.destination
                },
                plannedDepartureDate: this.location.plannedDepartureDate
                    ? helper.convertDateTimeToUTC(this.location.plannedDepartureDate)
                    : null,
                plannedArrivalDate: this.location.plannedArrivalDate
                    ? helper.convertDateTimeToUTC(this.location.plannedArrivalDate)
                    : null,
                tripFreightSet: {
                    edges: this.freights.map((freight, index) => {
                        return {
                            node: {
                                id: freight?.id,
                                referenceNumber: freight.referenceNumber,
                                containerNumber: freight.containerNumber,
                                airWaybillNumber: freight.awb,
                                orderNumber: freight.orderNumber,
                                billOfLadingNumber: freight.billsOfLading,
                                goods: freight.goods,
                                term: freight.term,
                                contact: freight.contact,
                                loadPoint: freight.loadPoint,
                                loadDate: freight.loadDate ? helper.convertDateTimeToUTC(freight.loadDate) : null,
                                startDate: freight.startDate ? helper.convertDateTimeToUTC(freight.startDate) : null,
                                endDate: freight.endDate ? helper.convertDateTimeToUTC(freight.endDate) : null,
                                tripFreightTrackerSet: {
                                    edges: freight.trackers.map(tracker => {
                                        return {
                                            node: {
                                                tracker: {
                                                    id: tracker
                                                }
                                            }
                                        };
                                    })
                                },
                                tripFreightAlertRuleSet: {
                                    edges: freight.alerts.map(alert => {
                                        return {
                                            node: {
                                                tenantAlertRule: {
                                                    id: alert
                                                }
                                            }
                                        };
                                    })
                                }
                            }
                        };
                    })
                },
                tripSeaTransportSet: {
                    edges: this.seaTransport.map((transport, index) => {
                        return {
                            node: {
                                id: transport.id,
                                sequence: index,
                                portCode: transport.portCode,
                                portTransportType: transport.portTransportType,
                                vesselInputType: transport.vesselInputType,
                                vesselInputValue: transport.vesselInputValue,
                                vgmCutOffDate: transport.vgmCutOffDate,
                                bookingConfirmationNumber: transport.bookingConfirmationNumber,
                                cutOffDateTime: transport.cutOffDateTime,
                                estimatedDepartureDate: transport.estimatedDepartureDate
                                    ? helper.convertDateTimeToUTC(transport.estimatedDepartureDate)
                                    : null,
                                estimatedArrivalDate: transport.estimatedArrivalDate
                                    ? helper.convertDateTimeToUTC(transport.estimatedArrivalDate)
                                    : null
                            }
                        };
                    })
                },
                tripPartnerSet: {
                    edges: this.partners.map(partner => {
                        return {
                            node: {
                                tenantPartner: {
                                    id: partner
                                }
                            }
                        };
                    })
                }
            };
            /* Store trip info vuex*/
            this.$store.dispatch("trip/updateCreatedTrip", {
                type: "draft",
                trip: _tripDraft
            });

            /* Navigate to other component */
            this.$router.push({
                path: route.path,
                query: {
                    id: null,
                    type: `${route.query.type}-tripDraft`,
                    from: `/newtrips-${this.modeType}/${route.query.field}`
                }
            });
        },
        overlapWidthCard() {
            /* This set width ovelap component base on the parent component width  */
            setTimeout(() => {
                let set = this.$refs.mainCard.$el.clientWidth;
                if (set) {
                    this.parentWidth = set;
                }
            }, 300);
        },
        handleScroll() {
            const overlapCard = this.$refs.overlapCard;
            const otherCard = this.$refs.card;
            const overlapRect = overlapCard.getBoundingClientRect();
            const cardRect = otherCard.getBoundingClientRect();

            let _overlapRect = overlapRect.top > 0 ? overlapRect : this.storeCardCoordinates;

            if (overlapRect.top > 0) {
                this.storeCardCoordinates = overlapRect;
            }

            if (this.isLessThan(_overlapRect, cardRect)) {
                this.isCardHidden = false;
            } else if (this.isMoreThan(_overlapRect, cardRect)) {
                this.isCardHidden = true;
            }
        },
        isLessThan(rect1, rect2) {
            let rectTop = rect2.top - 20;
            return rect1?.bottom <= rectTop;
        },
        isMoreThan(rect1, rect2) {
            return rect1?.bottom > rect2.top;
        },
        generalSave() {
            if (this.modeType === "editTemplate") {
                this.saveTrip();
            } else if (this.modeType === "template") {
                this.openNameDescriptionDialog = true;
            } else {
                this.saveTrip();
            }
        },
        openTemplate() {
            this.openNameDescriptionDialog = true;
            this.templateDialogActivated = true;
        },
        resetTripFields() {
            this.stepNo = 1;
            this.templateSelected = null;

            this.trip = {
                id: null,
                referenceNumber: "",
                consignee: [],
                shipmentFileNumber: "",
                carrier: ""
            };
            this.location = {
                origin: "",
                destination: "",
                transportMode: "",
                plannedDepartureDate: null,
                plannedDepartureTime: null,
                plannedArrivalDate: null,
                plannedArrivalTime: null
            };

            this.seaLoadPoint = {
                trade: ""
            };
            this.seaTransport = [
                {
                    id: null,
                    sequence: 0,
                    voyage: "",
                    portCode: "",
                    portTransportType: "POL",
                    vesselInputType: "",
                    vesselInputValue: "",
                    originalEstimatedDepartureDate: null,
                    estimatedDepartureDate: null,
                    actualDepartureDate: null
                },
                {
                    id: null,
                    sequence: 1,
                    portCode: "",
                    portTransportType: "POD",
                    originalEstimatedArrivalDate: null,
                    estimatedArrivalDate: null,
                    actualArrivalDate: null
                }
            ];
            this.partners = [];
            this.freights = [
                {
                    id: null,
                    referenceNumber: null,
                    containerNumber: null,
                    awb: null,
                    orderNumber: null,
                    billsOfLading: null,
                    goods: null,
                    term: null,
                    contact: "",
                    loadPoint: "",
                    loadDate: null,
                    trackers: [],
                    startDate: null,
                    //startTime: null,
                    endDate: null,
                    //endTime: null,
                    alerts: []
                }
            ];
        },
        populateTrip() {
            if (this.templateSelected) this.editTemplate(this.templateSelected);
            else this.resetTripFields();
        },
        edit() {
            const _tripState =
                this.editMode || this.copyMode || this.templateMode
                    ? this.$store.getters["trip/getCreatedTrip"]
                    : this.$store.getters["trip/getDraftTrip"];

            const {
                id,
                referenceNumber,
                shipmentFileNumber,
                tenantCarrier,
                transportMode,
                originLocation,
                destinationLocation,
                plannedDepartureDate,
                plannedArrivalDate,
                trade,
                tripFreightSet,
                tripSeaTransportSet,
                tripPartnerSet
            } = _tripState;

            const formatIfNotTemplate = date => (!this.templateMode ? this.formatDateTime(date) : null);
            const isNotCopyOrTemplate = value => (!this.copyMode && !this.templateMode ? value : null);

            // Trip
            this.trip.id = id || null;
            this.trip.referenceNumber = isNotCopyOrTemplate(referenceNumber);
            this.trip.shipmentFileNumber = isNotCopyOrTemplate(shipmentFileNumber) || null;
            this.trip.carrier = tenantCarrier?.id || null;
            this.isSeaTransport = transportMode === "SEA";

            // Trip location
            this.location.transportMode = transportMode.toLowerCase() || "";
            this.location.origin = originLocation?.id || null;
            this.location.destination = destinationLocation?.id || null;
            this.location.plannedDepartureDate = formatIfNotTemplate(plannedDepartureDate);
            this.location.plannedArrivalDate = formatIfNotTemplate(plannedArrivalDate);

            // Trip sea load point
            this.seaLoadPoint = { trade: trade || null };

            // Trip freight
            this.freights = this.getFreightsFormat(tripFreightSet);

            // Trip sea transport
            this.seaTransport = this.getSeaTransportFormat(tripSeaTransportSet);

            // Trip partners
            this.partners = tripPartnerSet?.edges.map(i => i.node?.tenantPartner?.id);
            this.trip.consignee = this.partners || [];
        },
        getFreightsFormat(freights) {
            const formatIfNotTemplate = date => (!this.templateMode ? this.formatDateTime(date) : null);
            const isNotTemplate = value => (!this.templateMode ? value : null);

            let _freights = freights?.edges.map(({ node }) => {
                // Assuming this.currentTrackers should not have duplicates.
                const currentTrackerIds = this.currentTrackers.map(t => t.id);

                let trackers =
                    node?.tripFreightTrackerSet?.edges.map(t => {
                        const { id, serialNumber } = t.node.tracker;

                        if (!currentTrackerIds.includes(id)) {
                            this.currentTrackers.push({ id, serialNumber });
                        }
                        return id;
                    }) || [];

                let alerts = node?.tripFreightAlertRuleSet?.edges.map(a => a?.node?.tenantAlertRule?.id) || [];

                return {
                    id: node.id,
                    referenceNumber: isNotTemplate(node.referenceNumber),
                    containerNumber: isNotTemplate(node.containerNumber),
                    awb: isNotTemplate(node.airWaybillNumber, this.formatAirwaybillNumber),
                    orderNumber: isNotTemplate(node.orderNumber),
                    billsOfLading: isNotTemplate(node.billOfLadingNumber),
                    goods: node.goods,
                    term: node.term,
                    contact: node.contact || null,
                    loadPoint: node.loadPoint || null,
                    loadDate: node.loadDate ? formatIfNotTemplate(node.loadDate) : null,
                    startDate: node.startDate ? formatIfNotTemplate(node.startDate) : null,
                    endDate: node.endDate ? formatIfNotTemplate(node.endDate) : null,
                    trackers: isNotTemplate(trackers),
                    alerts: alerts
                };
            });

            return _freights;
        },
        getSeaTransportFormat(seaTransport) {
            const formatIfNotTemplate = date => (!this.templateMode ? this.formatDateTime(date) : null);
            const isNotTemplate = value => (!this.templateMode ? value : null);

            let _seaTransport = seaTransport.edges
                .map(({ node }) => {
                    const {
                        id,
                        sequence,
                        portCode,
                        portTransportType,
                        vesselInputType,
                        vesselInputValue,
                        estimatedDepartureDate,
                        estimatedArrivalDate,
                        actualDepartureDate,
                        actualArrivalDate,
                        originalEstimatedDepartureDate,
                        originalEstimatedArrivalDate,
                        vgmCutOffDate,
                        bookingConfirmationNumber,
                        cutOffDateTime
                    } = node;

                    return {
                        id,
                        sequence,
                        portCode,
                        portTransportType,
                        vesselInputType: isNotTemplate(vesselInputType),
                        vesselInputValue: isNotTemplate(vesselInputValue),
                        estimatedDepartureDate: estimatedDepartureDate
                            ? formatIfNotTemplate(estimatedDepartureDate)
                            : null,
                        estimatedArrivalDate: estimatedArrivalDate ? formatIfNotTemplate(estimatedArrivalDate) : null,
                        actualDepartureDate: actualDepartureDate ? formatIfNotTemplate(actualDepartureDate) : null,
                        actualArrivalDate: actualArrivalDate ? formatIfNotTemplate(actualArrivalDate) : null,
                        vgmCutOffDate: vgmCutOffDate ? formatIfNotTemplate(vgmCutOffDate) : null,
                        bookingConfirmationNumber: isNotTemplate(bookingConfirmationNumber),
                        cutOffDateTime: cutOffDateTime ? formatIfNotTemplate(cutOffDateTime) : null,
                        originalEstimatedDepartureDate: originalEstimatedDepartureDate
                            ? formatIfNotTemplate(originalEstimatedDepartureDate)
                            : null,
                        originalEstimatedArrivalDate: originalEstimatedArrivalDate
                            ? formatIfNotTemplate(originalEstimatedArrivalDate)
                            : null
                    };
                })
                .sort((a, b) => a.sequence - b.sequence);

            return _seaTransport;
        },
        editTemplate(templateObj) {
            if (templateObj) {
                this.trip.id = templateObj.id || null;
                this.templateNameDescription.name = templateObj.name || null;
                this.templateNameDescription.description = templateObj.description || null;
                this.location.transportMode = templateObj.transportMode.toLowerCase();
                this.trip.consignee = templateObj.partnersIds;
                this.trip.carrier = templateObj.carrier.id || null;
                this.isSeaTransport = templateObj.transportMode.toLowerCase() === "sea";

                this.location.origin = templateObj.origin.id || null;
                this.location.plannedDepartureTime = templateObj.departureTime || null;
                this.location.destination = templateObj.destination.id || null;
                this.location.plannedArrivalTime = templateObj.arrivalTime || null;
                this.freights =
                    templateObj.freights.map(f => {
                        return {
                            id: f.id,
                            referenceNumber: f.referenceNumber || null,
                            containerNumber: f.containerNumber || null,
                            awb: f.awb || null,
                            orderNumber: f.orderNumber || null,
                            billsOfLading: f.billsOfLading || null,
                            goods: f.goods || null,
                            term: f.term || null,
                            contact: f.contact || null,
                            loadPoint: f.loadPoint || null,
                            loadDate: f.loadDate || null,
                            startDate: f.startDate || null,
                            endDate: f.endDate || null,
                            //startTime: f.startTime || null,
                            //endTime: f.endTime || null,
                            trackers: f.trackers || [],
                            alerts: f.alerts || []
                        };
                    }) || [];
                this.seaLoadPoint.trade = templateObj.trade || null;
                this.seaTransport = templateObj.seaTransport || [];
                this.partners = templateObj.partnersIds || [];
            }
        },
        saveTrip() {
            this.isSaving = true;
            let payload = this.getPayload();

            this.updateMutation(payload)
                .then(response => {
                    let message = {
                        color: "blue",
                        message: this.snackMessage
                    };

                    this.$store.dispatch("snackbar/showMessage", message);
                    this.$router.push({ path: "/previewtrips" });
                    if (this.modeType !== "template" && this.modeType !== "editTemplate") {
                        let _trip = { ...response.data.createTrip.trip };
                        this.$store.dispatch("trip/updateCreatedTrip", {
                            type: "update",
                            trip: _trip
                        });
                    }
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        console.error(error.graphQLErrors[0].message);
                        this.error = error.graphQLErrors[0].message;
                        const payload = {
                            color: "red",
                            message: "Something went wrong. Please review the form."
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    }
                })
                .finally(() => {
                    this.isSaving = false;
                    this.resetTripFields();
                    this.$store.dispatch("trip/destroyDraft");
                });
        },
        cancelTrip() {
            let _trip = this.$store.getters["trip/getCreatedTrip"];

            this.resetTripFields();
            this.$router.push({
                path: "/previewtrips"
            });

            this.$store.dispatch("trip/updateCreatedTrip", {
                type: "cancel",
                trip: _trip
            });

            this.$store.dispatch("trip/destroyDraft");
        },
        closeNameDescriptionDialog() {
            this.templateDialogActivated = false;
            this.openNameDescriptionDialog = false;
            this.$refs.nameDescriptionDialog.isLoading = false;
        },
        saveNameDescription() {
            if (!this.editMode) {
                this.saveTrip();
            } else {
                this.$refs.nameDescriptionDialog.isLoading = false;
                this.openNameDescriptionDialog = false;
            }
        },
        getTime(date) {
            let _time = this.formatDateTime(date);
            return _time?.slice(11, 16);
        },
        getDate(date) {
            let _date = this.updateDateTimeTimezone(date);
            return _date?.slice(0, 10);
        },
        goBack() {
            if (this.stepNo > 1 && this.stepNo <= 4) this.stepNo--;
        },
        getPayload() {
            let payload = null;
            let _freightInputFields = [];
            let _seaTransportsInput = [];

            if (this.modeType === "template" || this.modeType === "editTemplate") {
                _seaTransportsInput = this.seaTransport.map(i => {
                    return {
                        sequence: i.sequence,
                        portCode: i.portCode,
                        portTransportType: i.portTransportType
                    };
                });

                _freightInputFields = this.freights.map(e => {
                    return {
                        goods: e.goods,
                        term: e.term,
                        alerts: e.alerts,
                        contact: e.contact,
                        loadPoint: e.loadPoint
                    };
                });

                let tripJson = JSON.stringify({
                    transportMode: this.trip.transportMode,
                    carrier: this.trip.carrier,
                    origin: this.location.origin,
                    destination: this.location.destination,
                    plannedDepartureTime: this.location.plannedDepartureTime,
                    plannedArrivalTime: this.location.plannedArrivalTime,
                    freights: _freightInputFields,
                    seaTransports: _seaTransportsInput,
                    partners: this.partners
                });

                payload = {
                    description: this.templateNameDescription.description,
                    lockTemplate: false,
                    name: this.templateNameDescription.name,
                    tenant: this.me.tenant.id,
                    tripJson: tripJson
                };

                if (this.modeType === "editTemplate") {
                    payload.id = this.trip.id;
                }
            } else {
                _seaTransportsInput = this.seaTransport.map(i => {
                    let item = {
                        sequence: i.sequence,
                        portCode: i.portCode,
                        portTransportType: i.portTransportType,
                        vesselInputType: i.vesselInputType,
                        vesselInputValue: i.vesselInputValue,
                        estimatedDepartureDate: helper.convertDateTimeToUTC(i.estimatedDepartureDate),
                        estimatedArrivalDate: helper.convertDateTimeToUTC(i.estimatedArrivalDate),
                        originalEstimatedDepartureDate: helper.convertDateTimeToUTC(i.originalEstimatedDepartureDate),
                        originalEstimatedArrivalDate: helper.convertDateTimeToUTC(i.originalEstimatedArrivalDate),
                        actualArrivalDate: helper.convertDateTimeToUTC(i.actualArrivalDate),
                        actualDepartureDate: helper.convertDateTimeToUTC(i.actualDepartureDate),
                        vgmCutOffDate: helper.convertDateTimeToUTC(i.vgmCutOffDate),
                        bookingConfirmationNumber: i.bookingConfirmationNumber,
                        cutOffDateTime: helper.convertDateTimeToUTC(i.cutOffDateTime)
                    };

                    if (this.editMode) {
                        item.actualDepartureDate = helper.convertDateTimeToUTC(i.actualDepartureDate);
                        item.actualArrivalDate = helper.convertDateTimeToUTC(i.actualArrivalDate);
                    }

                    return item;
                });

                _freightInputFields = this.freights.map(e => {
                    let _freightInputField = {
                        referenceNumber: e.referenceNumber,
                        billOfLadingNumber: e.billsOfLading,
                        containerNumber: e.containerNumber,
                        orderNumber: e.orderNumber,
                        airWaybillNumber: this.removeHifenFromAirwaybill(e.awb),
                        goods: e.goods,
                        term: e.term,
                        trackers: e.trackers,
                        alerts: e.alerts,
                        contact: e.contact,
                        loadPoint: e.loadPoint,
                        startDate: e.startDate ? helper.convertDateTimeToUTC(e.startDate) : null,
                        endDate: e.endDate ? helper.convertDateTimeToUTC(e.endDate) : null,
                        loadDate: helper.convertDateTimeToUTC(e.loadDate)
                    };

                    if (this.editMode) {
                        _freightInputField.id = e.id;
                    }

                    return _freightInputField;
                });

                payload = {
                    referenceNumber: this.trip.referenceNumber,
                    transportMode: this.location.transportMode,
                    shipmentFileNumber: this.trip.shipmentFileNumber,
                    plannedDepartureDate: this.location.plannedDepartureDate
                        ? helper.convertDateTimeToUTC(this.location.plannedDepartureDate)
                        : null,
                    plannedArrivalDate: this.location.plannedArrivalDate
                        ? helper.convertDateTimeToUTC(this.location.plannedArrivalDate)
                        : null,
                    trade: this.seaLoadPoint.trade,
                    tenantPartners: this.partners,
                    originLocationId: this.location.origin,
                    destinationLocationId: this.location.destination,
                    freights: _freightInputFields
                };

                if (this.editMode) {
                    payload.tripId = this.trip.id;
                    payload.carrier = this.trip.carrier;
                    payload.seaTransports = _seaTransportsInput;
                } else {
                    payload.tenantCarrier = this.trip.carrier;
                    payload.seaTransport = _seaTransportsInput;
                }
            }

            return payload;
        },
        updateMutation(payload) {
            const tripFields = `
                trip {
                    id
                    createdAt
                    completedAt
                    tenant {
                        id
                        organisationPermissions
                    }
                    tenantCarrier {
                        id
                        name
                    }
                    originLocation {
                        id
                        name
                        city
                        state
                        country
                        fullAddress
                        latitude
                        longitude
                        radius
                    }
                    destinationLocation {
                        id
                        name
                        city
                        state
                        country
                        fullAddress
                        latitude
                        longitude
                        radius
                    }
                    referenceNumber
                    shipmentFileNumber
                    transportMode
                    plannedDepartureDate
                    plannedArrivalDate
                    status
                    isArchived
                    isPrivate
                    numberOfFreights
                    numberOfPartners
                    numberOfDocuments
                    numberOfComments
                    numberOfUnresolvedAlerts
                    isUserFavorite
                    tripPartnerSet {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                    organisationPermissions
                                }
                            }
                        }
                    }
                    tripSeaTransportSet {
                        edges {
                            node {
                                id
                                portCode
                                portName
                                sequence
                                portTransportType
                                vesselInputType
                                vesselInputValue
                                voyage
                                vessel {
                                    id
                                    name
                                    lastRouteJson
                                }
                                portTransportType
                                vesselInputType
                                vesselInputValue
                                actualDepartureDate
                                actualArrivalDate
                                estimatedDepartureDate
                                estimatedArrivalDate
                                originalEstimatedDepartureDate
                                originalEstimatedArrivalDate
                                vgmCutOffDate
                                bookingConfirmationNumber
                                cutOffDateTime
                            }
                        }
                    }
                    tripFreightSet {
                        edges {
                            node {
                                id
                                referenceNumber
                                orderNumber
                                containerNumber
                                airWaybillNumber
                                billOfLadingNumber
                                loadDate
                                loadPoint
                                contact
                                goods
                                term
                                estimatedDepartureDate
                                estimatedArrivalDate
                                actualDepartureDate
                                actualArrivalDate
                                startDate
                                endDate
                                tripFreightAlertRuleSet {
                                    edges {
                                        node {
                                            id
                                            tenantAlertRule {
                                                id
                                                name
                                            }
                                        }
                                    }
                                }
                                tripFreightTrackerSet {
                                    edges {
                                        node {
                                            id
                                            tracker {
                                                id
                                                serialNumber
                                                activatedDate
                                                lastConnectionTime
                                                lastBatteryDeviceTime
                                                lastBatteryValue
                                                lastTemperatureDeviceTime
                                                lastTemperatureValue
                                                lastExternalTemperatureDeviceTime
                                                lastExternalTemperatureValue
                                                lastProbeTemperatureDeviceTime
                                                lastProbeTemperatureValue
                                                lastHumidityDeviceTime
                                                lastHumidityValue
                                                lastLightDeviceTime
                                                lastLightValue
                                                lastShockDeviceTime
                                                lastShockValue
                                                lastLocationDeviceTime
                                                lastLatitude
                                                lastLongitude
                                                lastLocationDescription
                                            }
                                            name
                                            lowTemperature
                                            highTemperature
                                            lowHumidity
                                            highHumidity
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            `;
            let mutation = null;
            switch (this.modeType) {
                case "edit":
                    mutation = gql`
                        mutation UpdateTrip($input: UpdateTripInput!) {
                            updateTrip(input: $input) {
                                ${tripFields}
                            }
                        }
                    `;
                    break;
                case "copy":
                    mutation = gql`
                        mutation createTrip($input: CreateTripInput!) {
                            createTrip(input: $input) {
                                ${tripFields}
                            }
                        }
                    `;
                    break;
                case "template":
                    mutation = gql`
                        mutation createTripTemplate($input: CreateTripTemplateInput!) {
                            createTripTemplate(input: $input) {
                                tripTemplate {
                                    id
                                    name
                                    tripJson
                                }
                            }
                        }
                    `;
                    break;
                case "editTemplate":
                    mutation = gql`
                        mutation editTripTemplate($input: UpdateTripTemplateInput!) {
                            editTripTemplate(input: $input) {
                                tripTemplate {
                                    id
                                    name
                                    tripJson
                                }
                            }
                        }
                    `;

                    break;
                default:
                    mutation = gql`
                        mutation createTrip($input: CreateTripInput!) {
                            createTrip(input: $input) {
                                ${tripFields}
                            }
                        }
                    `;
                    break;
            }

            return this.$apollo.mutate({
                mutation,
                variables: { input: payload }
            });
        }
    }
};
</script>
<style scoped>
.maxHeight {
    max-height: 460px;
}
.form-input >>> .error--text {
    color: yellow !important;
}
.v-application .error--text {
    color: yellow !important;
}

.primaryText {
    font-size: 12px !important;
    color: "#868599" !important;
    height: 23px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.secondaryText {
    font-size: 10px !important;
    color: "#0e1e46" !important;
    height: 21px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
}
/* .inputContent {
    height: 52px;
} */
.textSubtitle {
    font-size: 12px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outLined {
    border: 1px solid #9fa5b5 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
}
.backgroundColor {
    background-color: #f9fafe !important;
}

.stepperContent {
    height: 30px;
    padding: 16px 8px 16px 8px !important;
}

.textLeft {
    text-align: left !important;
}
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}

.card-container {
    position: relative;
    height: 80px;
    background-color: #ffffff;
    border-radius: 5px;
}

.fixed-bottom-card {
    position: fixed;
    bottom: 1.2px;
    z-index: 9999;
}

.fixed-bottom-card-1 {
    bottom: 50px;
}

.fixed-bottom-card {
    bottom: 1.2px;
}

.containerFooter {
    height: 80px;
    background-color: #ffffff;
    border-radius: 5px;
}
.disabledClass {
    background-color: #f9fafe !important;
    color: #9fa5b5 !important;
}
</style>
