<template>
    <div color="background2">
        <!-- TABS -->
        <v-tabs
            v-model="tab"
            grow
            color="primary"
            show-arrows
            ref="tabs"
            background-color="background"
        >
            <v-tab
                v-for="t in selectedFilters"
                :key="t.code"
                :href="`#tab-${t.code}`"
            >
                {{ t.name }}
            </v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <!-- TAB CONTENT -->
        <v-tabs-items v-model="tab" 
            :style="`height: ${heightScreen}px;`"
            class="overflow-y-auto"
        >
            <!-- GROUP USERS TAB -->
            <v-tab-item value="tab-users" :transition="false"
                ><ActionGroupUsers
                    :actionGroup="actionGroup"
                    v-on:refreshDataTable="refreshDataTable()"
                ></ActionGroupUsers
            ></v-tab-item>
            <!-- GROUP EXTERNAL MAILS TAB -->
            <v-tab-item value="tab-externalEmails" :transition="false">
                <ActionGroupExternalEmails
                    :actionGroup="actionGroup"
                    v-on:refreshDataTable="refreshDataTable()"
                ></ActionGroupExternalEmails>
            </v-tab-item>
            <!-- GROUP ALERTS TAB -->
            <v-tab-item value="tab-alerts" :transition="false">
                <ActionGroupAlerts
                    :actionGroup="actionGroup"
                ></ActionGroupAlerts>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import ActionGroupUsers from "@/components/actiongroups/details/ActionGroupUsers.vue";
import ActionGroupExternalEmails from "@/components/actiongroups/details/ActionGroupExternalEmails.vue";
import ActionGroupAlerts from "@/components/actiongroups/details/ActionGroupAlerts.vue";

export default {
    props: {
        actionGroup: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        }
    },
    components: {
        ActionGroupUsers,
        ActionGroupExternalEmails,
        ActionGroupAlerts
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225 ;
            return  _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    mounted() {},
    methods: {
        refreshDataTable() {
            this.$emit("refreshDataTable");
        }
    }

};
</script>
