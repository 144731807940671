import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.openAddUserGroupDialog),callback:function ($$v) {_vm.openAddUserGroupDialog=$$v},expression:"openAddUserGroupDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t("users.add_user"))+" ")],1),_c(VCardText,{staticClass:"pt-3"},[_c(VForm,{ref:"userGroupForm"},[_c(VCol,{staticClass:"mx-0 px-0 mt-6",attrs:{"cols":"12"}},[_c(VAutocomplete,{staticClass:"mx-3",attrs:{"dense":"","items":_vm.tenantUserGroups.edges,"no-data-text":_vm.$t('loading.no_available'),"label":_vm.$t('users.select_group') + '*',"outlined":"","hide-selected":"","hide-details":"","deletable-chips":"","small-chips":"","item-text":"node.name","item-value":"node.id","multiple":""},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mt-4 append-divider"}),_c(VListItem,{staticClass:"append",attrs:{"dense":""}},[_c(VListItemContent,[_c(VBtn,{staticClass:"mt-4",attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.createGroup()}}},[_vm._v(" "+_vm._s(_vm.$t("general.create_group"))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end pb-5 pr-5"},[_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.closeAndResetInput}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"loading":_vm.isLoadingAddUserGroup,"color":"primary","rounded":_vm.borderRounded},on:{"click":_vm.addTenantUserGroup}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }