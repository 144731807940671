<template>
    <main>
        <v-card class="py-2 px-3 mx-2 mt-0 mb-4" flat>
            <!-- <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div> -->
            <v-card-text class="text-left pt-2 pb-3 pr-3">
                <v-row
                    ><span class="mt-3 subtitle-1"
                        ><b>{{ $t("trips.create_trip") }}</b></span
                    ></v-row
                >

                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="4"
                        class="d-flex align-center subtitle-1"
                    >
                        <span>{{ $t("trips.automatic_trip") }}</span>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex align-center justify-end py-0"
                    >
                        <v-switch
                            :value="tripSettings.autoCreateTrip"
                            class="switch-margin"
                            color="primary"
                            hide-details
                            @change="updateTripSettings('auto', $event)"
                            :disabled="!editMode"
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                <v-row class="align-center pt-5">
                    <v-col
                        cols="12"
                        sm="12"
                        md="5"
                        lg="5"
                        xl="4"
                        class="d-flex align-center py-0 subtitle-1"
                    >
                        <span>{{ $t("trips.default_transport") }}</span>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="3"
                        class="d-flex align-center justify-end py-0"
                    >
                        <v-select
                            :value="tripSettings.transportModeChoice"
                            :items="transportModeChoices"
                            clearable
                            dense
                            hide-details
                            :disabled="!editMode"
                            color="primary"
                            outlined
                            @change="updateTripSettings('mode', $event)"
                            :placeholder="$t('trips.default_transport')"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row class="pt-5">
                    <v-col cols="12" md="9" lg="9" xl="7">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>

                <v-row class="pt-5">
                    <span class="subtitle-1"
                        ><b>{{ $t("trips.auto_complete") }}</b></span
                    ></v-row
                >

                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="7"
                        lg="7"
                        xl="5"
                        class="d-flex align-center py-3 subtitle-1"
                    >
                        <span>{{ $t("trips.tracker_arrives") }}</span>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        class="d-flex align-center justify-end py-0"
                    >
                        <v-switch
                            :value="
                                tripSettings.autoCompleteTripTracker.enabled
                            "
                            class="switch-margin"
                            color="primary"
                            hide-details
                            @change="
                                updateTripSettings('trackerEnabled', $event)
                            "
                            :disabled="!editMode"
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                <div v-if="tripSettings.autoCompleteTripTracker.enabled">
                    <v-row class="align-center pt-5">
                        <v-col
                            cols="12"
                            sm="12"
                            md="5"
                            lg="5"
                            xl="4"
                            class="d-flex align-center pt-0 subtitle-1"
                        >
                            <span class="ml-5"
                                >{{ $t("trips.automatically_complete") }}
                            </span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            xl="1"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-text-field
                                :value="
                                    tripSettings.autoCompleteTripTracker.offset
                                "
                                hide-details
                                min="0"
                                type="number"
                                :disabled="!editMode"
                                color="primary"
                                outlined
                                dense
                                @change="
                                    updateTripSettings('trackerOffset', $event)
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-select
                                :value="
                                    tripSettings.autoCompleteTripTracker.unit
                                "
                                :items="unitChoices"
                                hide-details
                                :disabled="!editMode"
                                color="primary"
                                dense
                                outlined
                                @change="
                                    updateTripSettings('trackerUnit', $event)
                                "
                            ></v-select>
                        </v-col>
                    </v-row>
                </div>

                <!-- Auto Complete Trip Vessel ETA -->
                <v-row class="pt-5">
                    <v-col
                        cols="12"
                        sm="12"
                        md="7"
                        lg="7"
                        xl="5"
                        class="d-flex align-center subtitle-1"
                    >
                        <span>{{ $t("trips.vessel_later") }} </span>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        class="d-flex align-center justify-end py-0"
                    >
                        <v-switch
                            :value="
                                tripSettings.autoCompleteTripVesselETA.enabled
                            "
                            class="switch-margin"
                            color="primary"
                            hide-details
                            @change="
                                updateTripSettings('vesselETAEnabled', $event)
                            "
                            :disabled="!editMode"
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                <div v-if="tripSettings.autoCompleteTripVesselETA.enabled">
                    <v-row class="align-center pt-5">
                        <v-col
                            cols="12"
                            sm="12"
                            md="5"
                            lg="5"
                            xl="4"
                            class="d-flex align-center pt-0 subtitle-1"
                        >
                            <span class="ml-5"
                                >{{ $t("trips.automatically_completes") }}
                            </span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            xl="1"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-text-field
                                :value="
                                    tripSettings.autoCompleteTripVesselETA
                                        .offset
                                "
                                hide-details
                                min="0"
                                type="number"
                                dense
                                :disabled="!editMode"
                                color="primary"
                                outlined
                                @change="
                                    updateTripSettings(
                                        'vesselETAOffset',
                                        $event
                                    )
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-select
                                :value="
                                    tripSettings.autoCompleteTripVesselETA.unit
                                "
                                :items="unitChoices"
                                hide-details
                                dense
                                :disabled="!editMode"
                                color="primary"
                                outlined
                                @change="
                                    updateTripSettings('vesselETAUnit', $event)
                                "
                            ></v-select>
                        </v-col>
                    </v-row>
                </div>

                <!-- Auto Complete Trip Vessel ATA -->
                <v-row class="pt-5">
                    <v-col
                        cols="12"
                        sm="12"
                        md="7"
                        lg="7"
                        xl="5"
                        class="d-flex align-center subtitle-1"
                    >
                        <span>{{ $t("trips.vessel_laters") }} </span>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        class="d-flex align-center justify-end py-0"
                    >
                        <v-switch
                            :value="
                                tripSettings.autoCompleteTripVesselATA.enabled
                            "
                            class="switch-margin"
                            color="primary"
                            hide-details
                            @change="
                                updateTripSettings('vesselATAEnabled', $event)
                            "
                            :disabled="!editMode"
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                <div v-if="tripSettings.autoCompleteTripVesselATA.enabled">
                    <v-row class="align-center pt-5">
                        <v-col
                            cols="12"
                            sm="12"
                            md="5"
                            lg="5"
                            xl="4"
                            class="d-flex align-center pt-0 subtitle-1"
                        >
                            <span class="ml-5"
                                >{{ $t("trips.automatically_completes") }}
                            </span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            xl="1"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-text-field
                                :value="
                                    tripSettings.autoCompleteTripVesselATA
                                        .offset
                                "
                                hide-details
                                min="0"
                                type="number"
                                dense
                                :disabled="!editMode"
                                color="primary"
                                outlined
                                @change="
                                    updateTripSettings(
                                        'vesselATAOffset',
                                        $event
                                    )
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-select
                                :value="
                                    tripSettings.autoCompleteTripVesselATA.unit
                                "
                                :items="unitChoices"
                                hide-details
                                dense
                                :disabled="!editMode"
                                color="primary"
                                outlined
                                @change="
                                    updateTripSettings('vesselATAUnit', $event)
                                "
                            ></v-select>
                        </v-col>
                    </v-row>
                </div>

                <!-- Auto Complete Trip Carrier -->
                <v-row class="pt-5">
                    <v-col
                        cols="12"
                        sm="12"
                        md="7"
                        lg="7"
                        xl="5"
                        class="d-flex align-center subtitle-1"
                    >
                        <span>{{ $t("trips.carrier_reports") }} </span>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        class="d-flex align-center justify-end py-0"
                    >
                        <v-switch
                            :value="
                                tripSettings.autoCompleteTripCarrier.enabled
                            "
                            class="switch-margin"
                            color="primary"
                            hide-details
                            @change="
                                updateTripSettings('carrierEnabled', $event)
                            "
                            :disabled="!editMode"
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                <div v-if="tripSettings.autoCompleteTripCarrier.enabled">
                    <v-row class="align-center pt-5">
                        <v-col
                            cols="12"
                            sm="12"
                            md="5"
                            lg="5"
                            xl="4"
                            class="d-flex align-center pt-0 subtitle-1"
                        >
                            <span class="ml-5"
                                >{{ $t("trips.automatically_completes") }}
                            </span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            xl="1"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-text-field
                                :value="
                                    tripSettings.autoCompleteTripCarrier.offset
                                "
                                hide-details
                                min="0"
                                type="number"
                                dense
                                :disabled="!editMode"
                                color="primary"
                                outlined
                                @change="
                                    updateTripSettings('carrierOffset', $event)
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-select
                                :value="
                                    tripSettings.autoCompleteTripCarrier.unit
                                "
                                :items="unitChoices"
                                hide-details
                                dense
                                :disabled="!editMode"
                                color="primary"
                                outlined
                                @change="
                                    updateTripSettings('carrierUnit', $event)
                                "
                            ></v-select>
                        </v-col>
                    </v-row>
                </div>

                <!-- Auto Complete Trip Tracker Activated -->
                <v-row class="pt-5">
                    <v-col
                        cols="12"
                        sm="12"
                        md="7"
                        lg="7"
                        xl="5"
                        class="d-flex align-center subtitle-1"
                    >
                        <span>{{ $t("trips.tracker_activate") }} </span>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        class="d-flex align-center justify-end py-0"
                    >
                        <v-switch
                            :value="
                                tripSettings.autoCompleteTripTrackerActivated
                                    .enabled
                            "
                            class="switch-margin"
                            color="primary"
                            hide-details
                            @change="
                                updateTripSettings(
                                    'trackerActivatedEnabled',
                                    $event
                                )
                            "
                            :disabled="!editMode"
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                <div
                    v-if="tripSettings.autoCompleteTripTrackerActivated.enabled"
                >
                    <v-row class="align-center pt-5">
                        <v-col
                            cols="12"
                            sm="12"
                            md="5"
                            lg="5"
                            xl="4"
                            class="d-flex align-center pt-0 subtitle-1"
                        >
                            <span class="ml-5"
                                >{{ $t("trips.automatically_completes") }}
                            </span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            xl="1"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-text-field
                                :value="
                                    tripSettings
                                        .autoCompleteTripTrackerActivated.offset
                                "
                                hide-details
                                min="0"
                                type="number"
                                dense
                                :disabled="!editMode"
                                color="primary"
                                outlined
                                @change="
                                    updateTripSettings(
                                        'trackerActivatedOffset',
                                        $event
                                    )
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            lg="2"
                            class="d-flex align-center justify-end py-0"
                        >
                            <v-select
                                :value="
                                    tripSettings
                                        .autoCompleteTripTrackerActivated.unit
                                "
                                :items="unitChoices"
                                hide-details
                                dense
                                :disabled="!editMode"
                                color="primary"
                                outlined
                                @change="
                                    updateTripSettings(
                                        'trackerActivatedUnit',
                                        $event
                                    )
                                "
                            ></v-select>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions class="mt-7">
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!editMode"
                    color="primary"
                    class="white--text mr-12"
                    @click="editMode = true"
                    rounded
                    >{{ $t("general.edit") }}</v-btn
                >
                <v-btn
                    v-if="editMode"
                    color=""
                    @click="resetEditMode"
                    rounded
                    text
                    >{{ $t("general.cancel") }}</v-btn
                >
                <v-btn
                    v-if="editMode"
                    color="primary"
                    class="white--text"
                    @click="updateOrganisationPreferences"
                    rounded
                    :loading="loadingBtn"
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </main>
</template>

<script>
// import NoPermission from "@/components/base/NoPermission.vue";
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import Config from "@/utils/config.json";

export default {
    title: "Admin Settings",
    props: {
        tenant: {
            type: Object
        }
    },
    data() {
        return {
            selectedTransportModes: [],
            // transportModeChoices: [
            //     { text: this.$t("transport_mode_choice.air"), value: "air" },
            //     { text: this.$t("transport_mode_choice.road"), value: "road" },
            //     { text: this.$t("transport_mode_choice.rail"), value: "rail" },
            //     { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            // ],
            // unitChoices: [
            //     { text: this.$t("general.hours"), value: "hours" },
            //     { text: this.$t("date.days"), value: "days" }
            // ],
            defaultPreferences: {
                autoCreateTrip: false,
                transportModeChoice: "",
                autoCompleteTripTracker: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripVesselETA: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripVesselATA: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripCarrier: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripTrackerActivated: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                }
            },
            editMode: false,
            loadingBtn: false
        };
    },

    computed: {
        me() {
            return helper.me();
        },
        tripSettings: {
            get() {
                return this.defaultPreferences;
            },
            set(value) {
                this.defaultPreferences = value;
            }
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        },
        unitChoices: function() {
            let _unitChoices = [
                { text: this.$t("general.hours"), value: "hours" },
                { text: this.$t("date.days"), value: "days" }
            ];
            return _unitChoices;
        }
    },
    watch: {},
    mounted() {
        if (this.isValidObject(this.tenant?.organisationPreferences)) {
            this.tripSettings = this.addAutoCompleteIfMissing(
                JSON.parse(this.tenant?.organisationPreferences)
            );
        }
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'settings_details_svw',
        });
        this.setUserProperties();
    },
    methods: {
          setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("settings_details_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("settings_details_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        resetEditMode() {
            this.editMode = false;
            this.defaultPreferences = {
                autoCreateTrip: false,
                transportModeChoice: [],
                autoCompleteTripTracker: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripVesselETA: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripVesselATA: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripCarrier: {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                },
                autoCompleteTripTrackerActivated: {
                    enabled: false,
                    unit: "days",
                    offset: 0
                }
            };
            this.tripSettings = this.isValidObject(
                this.tenant?.organisationPreferences
            )
                ? this.addAutoCompleteIfMissing(
                      JSON.parse(this.tenant?.organisationPreferences)
                  )
                : this.defaultPreferences;
        },
        updateTripSettings(key, value) {
            let _preferences = {
                autoCreateTrip: this.tripSettings.autoCreateTrip,
                transportModeChoice: this.tripSettings.transportModeChoice,
                autoCompleteTripTracker: {
                    enabled: this.tripSettings.autoCompleteTripTracker.enabled,
                    unit: this.tripSettings.autoCompleteTripTracker.unit,
                    offset: this.tripSettings.autoCompleteTripTracker.offset
                },
                autoCompleteTripVesselETA: {
                    enabled: this.tripSettings.autoCompleteTripVesselETA
                        .enabled,
                    unit: this.tripSettings.autoCompleteTripVesselETA.unit,
                    offset: this.tripSettings.autoCompleteTripVesselETA.offset
                },
                autoCompleteTripVesselATA: {
                    enabled: this.tripSettings.autoCompleteTripVesselATA
                        .enabled,
                    unit: this.tripSettings.autoCompleteTripVesselATA.unit,
                    offset: this.tripSettings.autoCompleteTripVesselATA.offset
                },
                autoCompleteTripCarrier: {
                    enabled: this.tripSettings.autoCompleteTripCarrier.enabled,
                    unit: this.tripSettings.autoCompleteTripCarrier.unit,
                    offset: this.tripSettings.autoCompleteTripCarrier.offset
                },
                autoCompleteTripTrackerActivated: {
                    enabled: this.tripSettings.autoCompleteTripTrackerActivated
                        .enabled,
                    unit: this.tripSettings.autoCompleteTripTrackerActivated
                        .unit,
                    offset: this.tripSettings.autoCompleteTripTrackerActivated
                        .offset
                }
            };

            switch (key) {
                case "auto":
                    _preferences.autoCreateTrip = value;
                    this.tripSettings = _preferences;
                    break;
                case "mode":
                    _preferences.transportModeChoice = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerUnit":
                    _preferences.autoCompleteTripTracker.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripTracker.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerEnabled":
                    _preferences.autoCompleteTripTracker.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripTracker.offset = 0;
                        this.tripSettings.autoCompleteTripTracker.unit =
                            "hours";
                    }
                    break;
                case "vesselETAUnit":
                    _preferences.autoCompleteTripVesselETA.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "vesselETAOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripVesselETA.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "vesselETAEnabled":
                    _preferences.autoCompleteTripVesselETA.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripVesselETA.offset = 0;
                        this.tripSettings.autoCompleteTripVesselETA.unit =
                            "hours";
                    }
                    break;
                case "vesselATAUnit":
                    _preferences.autoCompleteTripVesselATA.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "vesselATAOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripVesselATA.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "vesselATAEnabled":
                    _preferences.autoCompleteTripVesselATA.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripVesselATA.offset = 0;
                        this.tripSettings.autoCompleteTripVesselATA.unit =
                            "hours";
                    }
                    break;
                case "carrierUnit":
                    _preferences.autoCompleteTripCarrier.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "carrierOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripCarrier.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "carrierEnabled":
                    _preferences.autoCompleteTripCarrier.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripCarrier.offset = 0;
                        this.tripSettings.autoCompleteTripCarrier.unit =
                            "hours";
                    }
                    break;
                case "trackerActivatedUnit":
                    _preferences.autoCompleteTripTrackerActivated.unit = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerActivatedOffset":
                    if (value < 0 || value == null || value == "") {
                        value = 0;
                    }
                    _preferences.autoCompleteTripTrackerActivated.offset = value;
                    this.tripSettings = _preferences;
                    break;
                case "trackerActivatedEnabled":
                    _preferences.autoCompleteTripTrackerActivated.enabled = value;
                    this.tripSettings = _preferences;
                    if (!value) {
                        this.tripSettings.autoCompleteTripTrackerActivated.offset = 0;
                        this.tripSettings.autoCompleteTripTrackerActivated.unit =
                            "hours";
                    }
                    break;
                default:
                    break;
            }
        },
        updateOrganisationPreferences() {
            this.loadingBtn = true;
            let _preferences = JSON.stringify(this.tripSettings);

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateOrganisationPreferences(
                            $input: UpdateOrganisationPreferencesInput!
                        ) {
                            updateOrganisationPreferences(input: $input) {
                                tenant {
                                    id
                                    name
                                    organisationPermissions
                                    organisationPreferences
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantId: this.me?.tenant
                                ? this.me?.tenant.id
                                : this.tenant.id,
                            preferences: _preferences
                        }
                    }
                })
                .then(() => {
                    this.editMode = false;
                    this.loadingBtn = false;
                    const payload = {
                        color: "blue",
                        message: this.$t("general.saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                });
        },
        isValidObject(obj) {
            if (obj == null || obj == undefined || obj == "") {
                return false;
            } else {
                let _obj = JSON.parse(obj);
                if (Object.keys(_obj).length == 0) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        addAutoCompleteIfMissing(obj) {
            if (obj.autoCompleteTripTracker == undefined) {
                obj.autoCompleteTripTracker = {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                };
            }
            if (obj.autoCompleteTripVesselETA == undefined) {
                obj.autoCompleteTripVesselETA = {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                };
            }
            if (obj.autoCompleteTripVesselATA == undefined) {
                obj.autoCompleteTripVesselATA = {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                };
            }
            if (obj.autoCompleteTripCarrier == undefined) {
                obj.autoCompleteTripCarrier = {
                    enabled: false,
                    unit: "hours",
                    offset: 0
                };
            }
            if (obj.autoCompleteTripTrackerActivated == undefined) {
                obj.autoCompleteTripTrackerActivated = {
                    enabled: false,
                    unit: "days",
                    offset: 0
                };
            }

            return obj;
        }
    }
};
</script>

<style scoped>
/* .custom-select {
    font-size: 0.8rem;
    height: 40px;
    width: auto;
    width: 300px;
    max-width: 300px;
} */

/* .switch-margin {
    margin-right: 24px;
} */
</style>
