<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <div v-else>
            <LoadingBar
                v-if="$apollo.queries.tripPartners.loading"
            ></LoadingBar>
            <v-card v-else elevation="5" class="mb-0 pb-0" rounded="xl">
                <v-card-title class="background2 py-3">
                    <div class="mr-2">
                        <v-icon>apartment</v-icon>
                    </div>
                    <span>{{ $t("home.partners") }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="canEdit"
                        rounded
                        color="primary"
                        @click="addPartnerDialog = true"
                    >
                        <div class="mr-1">{{ $t("trips.add_partner") }}</div>
                        <v-icon>handshake</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="tripPartnersResult.edges"
                        item-key="node.id"
                        :loading-text="$t('loading.loading1')"
                        hide-default-footer
                        disable-pagination
                        disable-sort
                        :mobile-breakpoint="800"
                        :no-data-text="$t('loading.no_data')"
                    >
                        <template
                            v-slot:item.node.tenantPartner.notificationEnabled="{
                                item
                            }"
                        >
                            <v-icon
                                v-if="
                                    item.node.tenantPartner
                                        .notificationEnabled == true
                                "
                                color="success"
                                >check_circle
                            </v-icon>
                            <v-icon color="grey lighten-2" v-else
                                >check_circle</v-icon
                            >
                        </template>
                        <!-- Actions Column -->
                        <template v-slot:item.action="{ item }" v-if="canEdit">
                            <v-btn
                                icon
                                @click="
                                    onPartnerSelected(item.node.tenantPartner)
                                "
                            >
                                <v-icon>visibility</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                @click="
                                    deletedDialog({
                                        id: item.node.id,
                                        partnerId: item.node.tenantPartner.id,
                                        partnerName:
                                            item.node.tenantPartner.name
                                    })
                                "
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
            <v-dialog v-model="addPartnerDialog" width="600" height="800">
                <v-card>
                    <v-card-title class="primary white--text py-3 mb-2">
                        <div class="mr-2">
                            <v-icon color="white">handshake</v-icon>
                        </div>
                        <span>
                            <h5>{{ $t("trips.add_partner") }}</h5>
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <PartnerList
                            ref="partnerList"
                            v-on:addNewPartner="save"
                            :getPartners.sync="partners"
                            :partnersOnBoard="partnersOnBoard"
                        >
                        </PartnerList>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeAddPartner" rounded text>{{
                            $t("general.cancel")
                        }}</v-btn>
                        <v-btn
                            rounded
                            color="primary"
                            @click="$refs.partnerList.partnersAdded()"
                            :loading="saveLoadingBtn"
                            >{{ $t("general.save") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="deletePartnerDialog"
                max-width="300px"
                height="500px"
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-card-title class="primary py-3 mb-2">
                        <div class="mr-2">
                            <v-icon color="white">warning</v-icon>
                        </div>
                        <span class="white--text">
                            <h5>{{ $t("trips.remove_partner") }}</h5>
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <div>
                            <span>
                                <h4>
                                    {{ $t("trips.confirm_partner") }}
                                </h4>
                            </span>
                        </div>
                        <div class="mt-2">
                            <span>{{ deleteItem.partnerName }}</span>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="closeDeleteDialog"
                            width="80px"
                            rounded
                            text
                            >{{ $t("general.cancel") }}</v-btn
                        >
                        <v-btn
                            width="80px"
                            color="primary"
                            rounded
                            @click="deletePartner"
                            :loading="loadingDeleteBtn"
                            >{{ $t("general.remove") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="partnerDetailsDialog"
                max-width="800px"
                class="overflow-hidden"
                content-class="overflow-hidden"
                persistent
            >
                <v-card flat>
                    <v-card-title class="primary white--text py-3 mb-0 ">
                        <div class="mr-2">
                            <v-icon color="white">visibility</v-icon>
                        </div>
                        <span>
                            <h5>{{ $t("general.partner_details") }}</h5>
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn rounded @click="partnerDetailsDialog = false">{{
                            $t("general.close")
                        }}</v-btn>
                        <!-- <v-spacer></v-spacer> -->
                    </v-card-title>
                    <v-card-text
                        class="pa-0 ma-0 overflow-y-auto"
                        style="max-width: 800px; max-height: 600px;"
                    >
                        <PartnerDetail
                            ref="partnerDetails"
                            v-if="selectedPartner"
                            :isDialog="true"
                            :key="selectedPartner.id"
                            :partner="selectedPartner"
                            v-on:refreshDataTable="refetchTableData"
                            v-on:close="partnerDetailDrawer = false"
                        >
                        </PartnerDetail>
                    </v-card-text>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            rounded
                            @click="partnerDetailsDialog = false"
                            >{{ $t("general.close") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions> -->
                </v-card>
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import PartnerList from "@/components/partners/PartnerList.vue";
import PartnerDetail from "@/components/partners/PartnerDetail.vue";
import helper from "@/utils/helper.js";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import Config from "@/utils/config.json";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        }
    },
    components: {
        PartnerList,
        LoadingBar,
        PartnerDetail,
        NoPermission
    },
    apollo: {
        tripPartners: {
            query: gql`
                query trip($tripId: ID!) {
                    trip(id: $tripId) {
                        id
                        tripPartnerSet {
                            edges {
                                node {
                                    id
                                    tenantPartner {
                                        id
                                        name
                                        partnerType
                                        referenceNumber
                                        notificationEnabled
                                        carrierType
                                        tenantPartnerUserSet {
                                            totalCount
                                            edges {
                                                node {
                                                    id
                                                    user {
                                                        id
                                                        firstName
                                                        lastName
                                                        email
                                                        lastLogin
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return { tripId: this.trip?.id };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trip?.tripPartnerSet,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading) {
                    this.tripPartnersResult = { ...data.trip?.tripPartnerSet };
                    this.partnersOnBoard = data.trip?.tripPartnerSet.edges.map(
                        item => item
                    );
                }
            },
            // debounce: 800,
            skip() {
                return !this.canAccess;
            }
            // pollInterval: 60000 // ms
        }
    },
    data() {
        return {
            tripPartnersResult: { edges: [] },
            addPartnerDialog: false,
            deletePartnerDialog: false,
            saveLoadingBtn: false,
            deleteItem: {},
            openEditPartnerDialog: false,
            editPartner: {},
            updatedPartner: null,
            loadingDeleteBtn: false,
            selectedPartner: null,
            partnerDetailDrawer: false,
            partnerDetailsDialog: false,
            partnersOnBoard: [],
            partners: [],
            headers: [
                {
                    text: this.$t("headers.reference_number"),
                    align: "left",
                    value: "node.tenantPartner.referenceNumber"
                },
                {
                    text: this.$t("headers.partner_name"),
                    align: "left",
                    value: "node.tenantPartner.name"
                },
                {
                    text: this.$t("headers.partner_type"),
                    align: "left",
                    value: "node.tenantPartner.partnerType"
                },
                {
                    text: this.$t("headers.enable_notification"),
                    align: "left",
                    value: "node.tenantPartner.notificationEnabled"
                },
                {
                    text: this.$t("headers.actions"),
                    align: "center",
                    value: "action"
                }
            ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
    },
    computed: {
        me() {
            return helper.me();
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_PARTNER,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_PARTNER,
                        this.organisationPermissions
                    )
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_PARTNER,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (
                    !this.hasPermission(TripPermissions.VIEW_TRIP_PARTNER) &&
                    this.me?.tenant
                )
                    return false;
            }
            return true;
        },
        canEdit() {
            if (this.trip?.status === "COMPLETED") return false;

            if (this.isSharedTrip) return false;

            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_PARTNER,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_PARTNER,
                        this.organisationPermissions
                    )
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_PARTNER,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (
                    !this.hasPermission(TripPermissions.EDIT_TRIP_PARTNER) &&
                    this.me?.tenant
                )
                    return false;
            }
            return true;
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: 'trip_partners_tab_svw',
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties(){
            if(this.me?.isPartnerUser){
                this.me?.allTenantPartners.forEach(c =>{
                    this.$gtag.event("trip_partners_tab_svw",{
                        'tenant_id': this.me?.tenant?.id,
                        'tenant_name': this.me?.tenant?.name,
                        'partner_id': c.id,
                        'user_id': this.me?.id
                    });
                })
            }else{
                this.$gtag.event("trip_partners_tab_svw", {
                    'tenant_id': this.me?.tenant?.id,
                    'tenant_name': this.me?.tenant?.name,
                    'partner_id': null,
                    'user_id': this.me?.id
                });
            }
        },
        onPartnerSelected(partner) {
            this.selectedPartner = partner;
            this.partnerDetailsDialog = true;
        },
        closeAddPartner() {
            this.$refs.partnerList.clearCache();
            this.addPartnerDialog = false;
        },
        save(partnersId) {
            this.saveLoadingBtn = true;
            // prepare api call payload
            let payload = {
                tripId: this.trip.id,
                partnersId: [...partnersId]
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation addTripPartner($input: AddTripPartnerInput!) {
                            addTripPartner(input: $input) {
                                trip {
                                    id
                                    tripPartnerSet {
                                        edges {
                                            node {
                                                id
                                                tenantPartner {
                                                    id
                                                    name
                                                    partnerType
                                                    referenceNumber
                                                    notificationEnabled
                                                    carrierType
                                                    tenantPartnerUserSet {
                                                        totalCount
                                                        edges {
                                                            node {
                                                                id
                                                                user {
                                                                    id
                                                                    firstName
                                                                    lastName
                                                                    email
                                                                    lastLogin
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("trips.partner_saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.saveLoadingBtn = false;
                    this.closeAddPartner();
                    this.refetchTableData();
                });
        },
        deletedDialog(item) {
            this.deletePartnerDialog = true;
            this.deleteItem = { ...item };
        },
        refetchTableData() {
            return this.$apollo.queries.tripPartners.refetch();
        },
        closeDeleteDialog() {
            this.deletePartnerDialog = false;
            this.deleteItem = {};
        },
        deletePartner() {
            this.loadingDeleteBtn = true;
            let payload = {
                id: this.deleteItem.id
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTripPartner(
                            $input: DeleteTripPartnerInput!
                        ) {
                            deleteTripPartner(input: $input) {
                                trip {
                                    id
                                    tripPartnerSet {
                                        edges {
                                            node {
                                                id
                                                tenantPartner {
                                                    id
                                                    name
                                                    partnerType
                                                    referenceNumber
                                                    notificationEnabled
                                                    carrierType
                                                    tenantPartnerUserSet {
                                                        totalCount
                                                        edges {
                                                            node {
                                                                id
                                                                user {
                                                                    id
                                                                    firstName
                                                                    lastName
                                                                    email
                                                                    lastLogin
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    // show snackbar
                    const payload = {
                        color: "primary",
                        message: this.$t("trips.partner_removed")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.refetchTableData();
                    this.loadingDeleteBtn = false;
                    this.deletePartnerDialog = false;
                });
        }
    }
};
</script>
<style scoped>
/* hide the scrollbar of the v-dialog */
</style>
