<template>
    <v-dialog max-width="600" v-model="openEditPartnerUserDialog" persistent>
        <v-card>
            <!--- ICON AND TITLE --->
            <v-card-title class="primary white--text"
                ><v-icon color="white" class="mr-2">edit</v-icon>
                {{ $t("partners.edit_partner") }}
            </v-card-title>
            <!--- BODY --->
            <v-card-text class="pb-2">
                <v-form ref="partnerUserForm">
                    <!--- NAME --->
                    <v-col cols="12" class="pa-0 ma-0 mt-6">
                        <v-text-field
                            v-model="first_name"
                            :label="$t('headers.first_name')"
                            outlined
                            class="mx-6"
                        ></v-text-field>
                    </v-col>

                    <!--- LAST NAME --->
                    <v-col cols="12" class="pa-0 ma-0">
                        <v-text-field
                            v-model="last_name"
                            :label="$t('headers.last_name')"
                            outlined
                            class="mx-6"
                        ></v-text-field>
                    </v-col>

                    <!--- EMAIL --->
                    <v-col cols="12" class="pa-0 ma-0">
                        <v-text-field
                            label="Email*"
                            v-model="email"
                            :rules="emailRules"
                            outlined
                            class="mx-6"
                        ></v-text-field>
                    </v-col>

                    <v-row class="mx-6">
                        <!--- NOTIFICATION CHECKBOX --->
                        <v-checkbox
                            v-model="notification_checkbox"
                            :label="$t('headers.enable_notifications')"
                        ></v-checkbox>
                    </v-row>
                    <v-row class="mx-6">
                        <!--- 2FA CHECKBOX --->
                        <v-checkbox
                            v-model="requires_2fa"
                            :label="$t('headers.2fa')"
                        ></v-checkbox>
                    </v-row>
                </v-form>
            </v-card-text>
            <!--- ACTION BUTTONS --->
            <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn text @click="closeAndResetInput">Close</v-btn>
                <v-btn
                    :loading="isLoadingPartnerEdit"
                    @click="editTenantPartnerUser"
                    color="primary"
                    rounded
                    >{{ $t("general.save") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "AddPartnerUserDialog",
    props: ["openEditPartnerUserDialog", "partner"],
    data() {
        return {
            /**************************************/
            /* --------- FORM VARIABLES --------- */
            /**************************************/
            partner_user_id: null,
            user_id: null,
            first_name: null,
            last_name: null,
            email: null,
            notification_checkbox: false,
            requires_2fa: false,
            /**********************************************/
            /* --------- DIALOGS/LOADINGS/MENUS --------- */
            /**********************************************/
            isLoadingPartnerEdit: false,
            /*****************************/
            /* --------- RULES --------- */
            /*****************************/
            emailRules: [
                v => !!v || this.$t("rules.required"),
                v => /.+@.+\..+/.test(v) || this.$t("rules.email")
            ]
        };
    },
    watch: {},
    methods: {
        /* ---> FORM RELATED FUNCTIONS <--- */
        closeAndResetInput() {
            this.partner_user_id = null;
            this.user_id = null;
            this.first_name = null;
            this.last_name = null;
            this.email = null;
            this.notification_checkbox = false;
            this.requires_2fa = false;
            this.$emit("closeAddPartnerUserDialog");
        },
        fillEditForm(user) {
            this.partner_user_id = user.node.id;
            this.user_id = user.node.user.id;
            this.first_name = user.node.user.firstName;
            this.last_name = user.node.user.lastName;
            this.email = user.node.user.email;
            this.requires_2fa = user.node.user.require2fa;
            this.notification_checkbox = user.node.notificationEnabled;
        },
        /* ---> TENANT PARTNER USER MUTATION <--- */
        editTenantPartnerUser() {
            // validate form input :
            if (!this.$refs.partnerUserForm.validate()) return;
            // Initialize Loading :
            this.isLoadingPartnerEdit = true;
            // Initialize Mutation :
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation editPartnerUser(
                            $input: UpdateTenantPartnerUserInput!
                        ) {
                            updateTenantPartnerUser(input: $input) {
                                partnerUser {
                                    id
                                    user {
                                        id
                                        firstName
                                        lastName
                                        email
                                        require2fa
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.partner_user_id,
                            userId: this.user_id,
                            firstName: this.first_name,
                            lastName: this.last_name,
                            email: this.email,
                            require2fa: this.requires_2fa,
                            notificationEnabled: this.notification_checkbox
                        }
                    }
                })
                .then(() => {
                    // Stop Loading //
                    this.isLoadingPartnerEdit = false;
                    // Emit event to signal user is added and reload table showing users //
                    this.$emit("refreshPartnerUsersTable");
                    // Close the dialog and reset form fields //
                    this.closeAndResetInput();
                })
                .catch(() => {
                    this.errorMessage = true;
                    this.isLoadingPartnerEdit = false;
                });
        }
    }
};
</script>
